import { DataStore } from "@aws-amplify/datastore";
import { Carousel } from 'primereact/carousel';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import BoxPlanning from '../../../../assets/images/dashboard_box_planning_new.png';
import LeftNav from "../../../../components/common/employee/leftnav.component";
import TopNav from "../../../../components/common/employee/topnav.component";
import { GetArrayItemIndexFromKeyValue, GetArrayNextItemFromKeyValue, GetArrayPreviousItemFromKeyValue, GetSingleValueFromResponse,checkDuplicates } from "../../../../components/helper/commonfunctions";
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { Employee, FinancialYear, Parameters, Roles, TargetDetails, Targets, Relationship, RelationshipDetails, SBU } from "../../../../models";
import EmpLogin from "../../../../setup/auth/emp.component";
import AnnualSaleIcon from '../../../../assets/svg/annual_sale_icon.svg';
import { PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES } from '../../../../components/helper/enum';
import { ShowInMillion, commaSeparated } from '../../../../components/helper/maths';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../src/components/helper/graphQLFunctions'

export default function ReviewCommit_AnnualTarget() {
    let navigateTo = useNavigate();

    let { financialYearId, parameterId } = useParams();

    const [financialYear, setFinancialYear] = useState("");
    const [parameter, setParameter] = useState("");
    const [parameterList, setParameterList] = useState([]);

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //
    const [totalOfAnnualTarget, setTotalOfAnnualTarget] = useState();
    const [targetsList, setTargetsList] = useState([]);

    const [markAsIncompleteParameters, setMarkAsIncompleteParameters] = useState(0);

    const [currentTargetDetailId, setCurrentTargetDetailId] = useState("");
    const [prevParameterId, setPrevParameterId] = useState("");
    const [nextParameterId, setNextParameterId] = useState("");

    const [parametersCarousel, setParametersCarousel] = useState([]);
    const [carouselActivePageNo, setCarouselActivePageNo] = useState(0);
    const [parameterType, setParameterType] = useState("");


    useEffect(() => {
        bindList()
        onLoad();
    }, []);

    const parametersCarouselResponsiveOptions = [
        {
            breakpoint: '1600px',
            numVisible: 3,
            numScroll: 1,
        },
        {
            breakpoint: '1280px',
            numVisible: 4,
            numScroll: 1,
        },
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 1,
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1,
        }

    ];

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    async function bindList() {
        try {
            let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
            var loggedEmployeeRoleId = reactLocalStorage.get('loggedEmployeeRoleId');

            // var selectedParameters = await DataStore.query(Parameters, parameterId)
            var selectedParameters = await graphQLFindRecordById('getParameters', parameterId);
            // var getFinancialYear = await DataStore.query(FinancialYear, financialYearId)
            var getFinancialYear = await graphQLFindRecordById('getFinancialYear', financialYearId);
            setFinancialYear(getFinancialYear.name)
            setParameter({ label: selectedParameters.name, value: selectedParameters.id })
            setParameterType(selectedParameters.type);


            //Target Details
            // var currentTargets = await DataStore.query(Targets, (c) => c.and(c => [
            //     c.financial_year_id.eq(financialYearId),
            //     c.report_manager_employee_id.eq(loggedEmployeeId),
            // ]));

            var currentTargets = await graphQLGetAllData('listTargets', [
                { financial_year_id: { eq:financialYearId } },
                { report_manager_employee_id: { eq: loggedEmployeeId } },
            ]);


            let _tempData = []
            let totalTargetValue = 0
            currentTargets.forEach(async (target) => {
                // var currentTargetRole = await DataStore.query(Roles, (c) => c.id.eq(target.role_id))
                var currentTargetRole = await graphQLFindRecordById('getRoles', target.role_id);
                // var currentTargetEmployee = await DataStore.query(Employee, (c) => c.id.eq(target.employee_id))
                var currentTargetEmployee = await graphQLFindRecordById('getEmployee', target.employee_id);

                // var currentTarget = await DataStore.query(TargetDetails, (c) => c.and(c => [
                //     c.target_id.eq(target.id),
                //     c.parameter_id.eq(parameterId),
                // ]));

                var currentTarget = await graphQLGetAllData('listTargetDetails', [
                    { target_id: { eq: target.id } },
                    { parameter_id: { eq: parameterId } }
                ]);

                let status = ""

                if(currentTarget.length){
                    if (currentTarget[0].is_accepted_by_employee !== null && currentTarget[0].is_accepted_by_employee && !currentTarget[0].is_approve) { status = "Accepted" }
                    else if (currentTarget[0].is_approve && currentTarget[0].is_accepted_by_employee !== null) { status = "Approved" }
                    else if (currentTarget[0].isSendForRevisionByReportingManage) { status = "Revision" }
                    else if (currentTarget[0].is_revision_by_employee) { status = "Requested for revision" }
                    else { status = "Open" }
                }
                
                //get sbu
                let sbu = "-"
                if (currentTargetEmployee.param_sbu_id !== "") {
                    // let getEmployeeSBU = await DataStore.query(SBU, currentTargetEmployee.param_sbu_id)
                  
                    let getEmployeeSBU = await graphQLFindRecordById('getSBU', currentTargetEmployee.param_sbu_id);
                    sbu = getEmployeeSBU ?getEmployeeSBU.name : '-';

                }

                if (currentTarget.length) {
                    totalTargetValue = totalTargetValue + parseInt(currentTarget[0].target_value)
                    let data = {
                        "targetId": target.id,
                        "empId": currentTargetEmployee.employeeId,
                        "empName": currentTargetEmployee.firstName + " " + currentTargetEmployee.lastName,
                        "empRole": currentTargetRole.name,
                        "vertical": "Distribution",
                        "sbu": sbu,
                        // "percentage": "10%",
                        "annualTarget": currentTarget[0].target_value !== "NaN" ? commaSeparated(parseFloat(currentTarget[0].target_value).toFixed(2)) : "",
                        "status": status,
                        //"status": currentTarget[0].is_accepted_by_employee ? "Accepted" : currentTarget[0].is_revision_by_employee ? "Requested for revision" : currentTarget[0].isMarkAsComplete ? "Completed" : "Open",
                        "approveStatus": currentTarget[0].is_approve !== null && currentTarget[0].is_approve ? "Approved" : "Open",
                        "employeeId": currentTargetEmployee.id,
                    }
                    _tempData.push(data)
                }
            })

            // var currentTarget = await DataStore.query(Targets, (c) => c.and(c => [
            //     c.financial_year_id.eq(financialYearId),
            //     c.employee_id.eq(loggedEmployeeId),
            //     c.report_manager_employee_id.notContains(loggedEmployeeId),
            // ]));

            var currentTarget = await graphQLGetAllData('listTargets', [
                { employee_id: { eq: loggedEmployeeId } },
                { report_manager_employee_id: { notContains: loggedEmployeeId } },
                { financial_year_id: { eq: financialYearId } },
            ]);

            var myTargetId = GetSingleValueFromResponse(currentTarget, 'id')
            //Target Details
            // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
            //     c.target_id.eq(myTargetId),
            //     c.is_approve.eq(true)
            // ]));

            var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                { target_id: { eq: myTargetId } },
                { is_approve: { eq: true } }
            ]);

            //Get Relationship
            // var currentRelationship = await DataStore.query(Relationship, (c) => c.and(c => [
            //     c.financial_year_id.eq(financialYearId),
            //     c.role_id.eq(loggedEmployeeRoleId)
            // ]));

            var currentRelationship = await graphQLGetAllData('listRelationships', [
                { role_id: { eq: loggedEmployeeRoleId } },
                { financial_year_id: { eq: financialYearId } }
            ]);

            var relationship_id = GetSingleValueFromResponse(currentRelationship, 'id');

            var _tempArray = []; let _tempInCompleteParamArray = [];
            currentTargetDetails.map(async (currentItem, currentIndex, currentArray) => {

                //Now we are going to check is this parameter belongs to this role and financial year
                //------------------------------------
                var isPlanMandatory = 0;
                if (relationship_id !== '') {
                    // var currentRelationshipDetails = await DataStore.query(RelationshipDetails, (c) => c.and(c => [
                    //     c.relationship_id.eq(relationship_id),
                    //     c.parameter_id.eq(currentItem.parameter_id)
                    // ]));
                    var currentRelationshipDetails = await graphQLGetAllData('listRelationshipDetails', [
                        { relationship_id: { eq: relationship_id } },
                        { parameter_id: { eq: currentItem.parameter_id } }
                ]);


                    if (currentRelationshipDetails.length > 0) {
                        isPlanMandatory = 1;
                    }
                }
                //------------------------------------

                // const loopParameters = await DataStore.query(Parameters, currentItem.parameter_id);
                const loopParameters = await graphQLFindRecordById('getParameters', currentItem.parameter_id);

                if (currentItem.parameter_id === parameterId) {
                    setCurrentTargetDetailId(currentItem.id);
                }

                _tempArray.push({
                    'parameterIndex': currentIndex, 'parameterId': currentItem.parameter_id, 'parameterName': loopParameters.name,
                    'targetValue': loopParameters.type === PARAMETERS_TYPES.Amount ? ShowInMillion(currentItem.target_value) : currentItem.target_value,
                    'parameterUnit': loopParameters.type, 'isPlanMandatory': isPlanMandatory
                })

                //Check for last Record
                if (_tempArray.length === currentArray.length) {
                    _tempArray.sort(function (a, b) {
                        return a.parameterName.toLowerCase().localeCompare(b.parameterName.toLowerCase());
                    });
                    setParametersCarousel(_tempArray);

                    _tempInCompleteParamArray.sort(function (a, b) {
                        return a.parameterName.toLowerCase().localeCompare(b.parameterName.toLowerCase());
                    });
                    setMarkAsIncompleteParameters(_tempInCompleteParamArray);

                    //Set Previous 
                    var currentPreviousParameter = GetArrayPreviousItemFromKeyValue(_tempArray, "parameterId", parameterId)
                    if (currentPreviousParameter['parameterId'] != null) {
                        setPrevParameterId(currentPreviousParameter['parameterId'])
                    }

                    //Set Next 
                    var currentNextParameter = GetArrayNextItemFromKeyValue(_tempArray, "parameterId", parameterId)
                    if (currentNextParameter['parameterId'] != null) {
                        setNextParameterId(currentNextParameter['parameterId'])
                    }

                    //Set Selected Carousel Page
                    var currentParameterArrayItem = GetArrayItemIndexFromKeyValue(_tempArray, "parameterId", parameterId);
                    if (currentParameterArrayItem > 2) {
                        setCarouselActivePageNo(currentParameterArrayItem - 2);
                    }


                }

                return currentItem;

            })

            setTimeout(() => {
                if (selectedParameters.type === PARAMETERS_TYPES.Days || selectedParameters.type === PARAMETERS_TYPES.Percentage) {
                    let totalAnnualTarget = _tempData.map((data, i) => data.annualTarget)
                    let newTotalAnnualTarget = checkDuplicates(totalAnnualTarget)
                    setTotalOfAnnualTarget(commaSeparated(parseFloat(newTotalAnnualTarget).toFixed(2)) )
                }
                else {
                    setTotalOfAnnualTarget(commaSeparated(totalTargetValue.toFixed(2)))
                } 
                setTargetsList(_tempData)
            }, 500);
        }
        catch (e) {
            console.log("eror", e)
        }
    }


    //Parameters Carousel
    var parametersCarouselIndex = 0;
    const parametersCarouselTemplate = (currentRequestParameterList) => {
        parametersCarouselIndex++;

        return (
            <div className="product-item">
                <div className="product-item-content">
                    {
                        <div key={currentRequestParameterList['parameterId']}>

                            <div className={` h-38 relative gap-20 items-center rounded-lg p-3 content-center flex ${currentRequestParameterList['parameterId'] === parameterId ? "activegreen shadow-lg" : "bg-[#fff] shadow-lg border border-[#E4E7EC] dark:bg-[#212529] dark:border-[#2A2E32]"}`} >

                                <div className="">
                                    {
                                        currentRequestParameterList['parameterId'] === parameterId ?
                                            <div className="dashbord_box_shape_review_green"></div>
                                            :
                                            <div className="dashbord_box_shape_review_green_regular"></div>
                                    }
                                </div>
                                <div></div>
                                <div className="">
                                    <div className='flex-col'>
                                        <div className='flex items-center gap-3'>
                                            <div>
                                                <h6 className={`${currentRequestParameterList['parameterId'] === parameterId ? "text-white text-[18px] text-left" : "text-[#667085] text-[18px] font-light text-left"}`} >{currentRequestParameterList['parameterName']}</h6>
                                                <h2 className={`${currentRequestParameterList['parameterId'] === parameterId ? "text-white text-[22px] font-medium text-left" : "text-[#101828] text-[30px] font-medium dark:text-white text-left"}`} >
                                                    {currentRequestParameterList['parameterUnit'] === 'A' ? PARAMETERS_TYPES_NAMES[currentRequestParameterList['parameterUnit']] : ""}{currentRequestParameterList['targetValue']}{currentRequestParameterList['parameterUnit'] !== 'A' ? PARAMETERS_TYPES_NAMES[currentRequestParameterList['parameterUnit']] : ""}
                                                </h2>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="absolute top-4 right-5  font-medium text-center text-[#fff]">{currentRequestParameterList['isPlanMandatory'] === 1 ? <i className='icon s_icon'></i> : <i className='icon t_icon'></i>}</div>
                                </div>

                            </div>
                        </div>

                    }

                </div>
            </div>
        );
    }

    const showPlanReview = (rowData) => {
        return (
            <button onClick={() =>
                navigateTo("/employee/planning/team/reviewcommit/annualtarget/planreview/" + financialYearId + "/" + parameterId + "/" + rowData.employeeId + "/" + rowData.targetId)
            }>
                {
                    rowData.approveStatus === "Open" ?
                        <p className="bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center "> <div className='w-2 h-2 bg-[#1B55AF] rounded-full'></div>{rowData.approveStatus}</p>
                        :
                        <p className="bg-[#E5F3EC] text-[#4FB155] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center"> <div className='w-2 h-2 bg-[#4FB155] rounded-full'></div>{rowData.approveStatus}</p>
                }
            </button>
        )
    }

    const showStatus = (rowData) => {
        return (
            rowData.status === "Open" ?
                <p className="bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center w-16"> <div className='w-2 h-2 bg-[#1B55AF] rounded-full'></div>{rowData.status}</p>
                : rowData.status === "Accepted" ?
                    <p className="bg-[#E5F3EC] text-[#4FB155] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center w-24"> <div className='w-2 h-2 bg-[#4FB155] rounded-full'></div>{rowData.status}</p>
                    :
                    rowData.status === "Approved" ?
                        <p className="bg-[#E5F3EC] text-[#4FB155] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center w-24"> <div className='w-2 h-2 bg-[#4FB155] rounded-full'></div>{rowData.status}</p>
                        :
                        rowData.status === "Revision" ?
                            <div className="flex items-center gap-2 p-1.5 px-2 text-[12px] rounded-full text-[#FF4537] bg-[#FEE4E2] justify-center w-24" ><div className="h-2 w-2 rounded-full bg-[#FF4537]"></div>Revision</div>
                            :
                            <p className="bg-[#FFF2E5] text-[#FF7F01] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center w-30"> <div className='w-2 h-2 bg-[#FF7F01] rounded-full'></div>{rowData.status}</p>
        )
    }

    const ShowNextParameter = async () => {
        var navigateURL = "/employee/planning/team/reviewcommit/annualtarget/" + financialYearId + "/" + nextParameterId;
        navigateTo(navigateURL);
        navigateTo(0);
    }

    const ShowPreviousParameter = () => {
        if (prevParameterId !== '') {
            var navigateURL = "/employee/planning/team/reviewcommit/annualtarget/" + financialYearId + "/" + prevParameterId;
            navigateTo(navigateURL);
            navigateTo(0);
        }

    }

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNav />
            <LeftNav />
            <div className="target-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">

                    <div className="flex justify-between">
                        <div className="sm:flex items-center space-x-4">
                            <Link
                                to="/employee/planning/team/reviewcommit"
                                className="text-[#344054] text-[14px] dark:text-white py-1.5 px-3 rounded-md border border-[#D0D5DD]"
                            >
                                <i className="icon arrow_left_double mr-6 "></i>Back
                            </Link>
                            <div className="text-2xl font-semibold dark:text-white">
                                {" "}
                                Annual Target
                            </div>
                            <div className="text-xs border text-[#667085] border-[#667085] rounded p-1">
                                FY: {financialYear}
                            </div>
                        </div>
                        <div className="mt-5 lg:mt-0 flex items-center gap-4"></div>

                    </div>

                    <div className="col-span-9 mt-1 silder_managetarget">
                        <div className="carousel-demo">
                            <div className="card">
                                <Carousel value={parametersCarousel} page={carouselActivePageNo} numVisible={3} numScroll={1} responsiveOptions={parametersCarouselResponsiveOptions}
                                    itemTemplate={parametersCarouselTemplate} />
                            </div>
                        </div>
                    </div>

                    {/* <div className={`justify-between items-center rounded-lg p-3 content-center flex bg-[#4FB155] dark:bg-[#212529] dark:border-[#2A2E32]"}`} >
                        <div className='flex-col'>

                        </div>

                        <div style={{ width: 120, height: 150, alignSelf: "flex-end", position: 'relative' }} className="">
                            <img src={BoxPlanning} alt="" />
                            <div className="absolute top-0 right-2 rounded-full bg-[#333] h-6 w-6 font-medium text-center text-[#fff]"></div>
                        </div>
                    </div> */}
                </div>

                <div>
                    <div className="flex justify-center item-center">
                        <div className="sm:flex items-center ">
                            {
                                targetsList.length > 0 ?
                                    <DataTable
                                        paginator value={targetsList} responsiveLayout="scroll" style={{ width: '93vw' }}
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                    >
                                        <Column field="empId" header="Employee ID" sortable></Column>
                                        <Column field="empName" header="Name" sortable></Column>
                                        <Column field="empRole" header="Role" sortable></Column>
                                        <Column field="vertical" header="Vertical" sortable></Column>
                                        <Column field="sbu" header="SBU" sortable></Column>
                                        {/* <Column field="percentage" header="Percentage" sortable></Column> */}
                                        <Column field="annualTarget" header="Annual Target" footer={totalOfAnnualTarget}></Column>
                                        <Column field="status" header="Plan Status" sortable body={showStatus}></Column>
                                        <Column field="approveStatus" header="Approval Status" body={showPlanReview} sortable></Column>
                                    </DataTable> : ""
                            }
                        </div>
                    </div>


                    <div className="flex flex-1 justify-between gap-3 px-4 mt-7 mb-5 ">

                        <div>
                            <Link onClick={() => ShowPreviousParameter()} className="py-3 px-4 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">Previous</Link>
                        </div>

                        <div className="flex flex-1 justify-end gap-3">
                            {
                                nextParameterId !== '' ?
                                    <div><Link onClick={() => ShowNextParameter()} className="py-3 px-4 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">{"Next"}</Link></div>
                                    : ""
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    ) : (
        <EmpLogin sessionExpired={1} />
    );
}
