import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteButton from '../../../../../components/common/buttons/DeleteButton';
import EditButton from '../../../../../components/common/buttons/EditButton';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import ImportDimension from '../../../../../components/common/import/dimensionsimport.component';
import { ConvertResponseForId } from '../../../../../components/helper/commonfunctions';
import { DataTableCustomStyles } from '../../../../../components/helper/dataTable.config';
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import { BIUnit, Brand, ProductGroup, SBU } from '../../../../../models';
import HRLogin from "../../../../../setup/auth/hr.component";
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';

export default function Brands() {

    let navigateTo = useNavigate();
    const [checkProductList, setCheckProductList] = useState([]);
    const [isPageLoaded, setPageLoaded] = useState(false);

    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/hr/settings/dimensions/createbrand/' + id);
    };
    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        // const objDeleteBrand = await DataStore.query(Brand, id);
        const objDeleteBrand = await graphQLFindRecordById('getBrand', id);

        alertify.confirm('Confirmation', 'Do you want to delete this record?', async function () {
            //Delete brand
            // DataStore.delete(objDeleteBrand);

            await API.graphql({
                query: mutations.deleteBrand,
                variables: {
                    input: {
                        id: objDeleteBrand.id,
                        _version: objDeleteBrand._version,
                    }
                }
            });

            BindList();
            toast.success("Deleted");
        }
            , function () { });
    };

    // //*Column Name
    const DataTableColumns = [
        {
            name: 'Brand',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Ref Code',
            selector: row => row.REF_CODE,
            sortable: true,
        },
        {
            name: 'SBUs',
            selector: row => row.sbu,
            sortable: true,
        },
        {
            name: 'Business Unit',
            selector: row => row.bu,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <div className="flex gap-8">
                        <Link onClick={(e) => handleEditClick(e, row.id)} ><EditButton /></Link>
                        {
                            ConvertResponseForId(checkProductList, row.id) === row.id ?
                                <Link className="disabledCursor opacity-50"><DeleteButton /></Link> :
                                <Link onClick={(e) => handleDeleteClick(e, row.id)} ><DeleteButton /></Link>
                        }

                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true
        }
    ];


    // //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    // //State
    const [BrandList, SetBrandList] = useState([]);
    const [brandFilterList, setBrandFilterList] = useState([]);
    const [isShowImportPopup, setIsShowImportPopup] = useState(false);


    async function BindList() {
        let finalData = []
        // var BrandResponses = await DataStore.query(Brand, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        var BrandResponses = await graphQLGetAllData('listBrands');
        BrandResponses = BrandResponses.sort((a, b) => a.name.localeCompare(b.name));

        //product group data for delete enanble
        let productGroupLength = []
        // var productGroupResponses = await DataStore.query(ProductGroup);
        var productGroupResponses = await graphQLGetAllData('listProductGroups');


        productGroupResponses.map((res) => {
            let data = { id: res.brand_id }
            productGroupLength.push(data)
        })
        setCheckProductList(productGroupLength)

        BrandResponses.forEach(async (item, i) => {

            let Sbu_Name = ""
            if (item.SBU_id) {
                // var SBUResponses = await DataStore.query(SBU, (c) => c.id.eq(item.SBU_id));
                var SBUResponses = await graphQLGetAllData('listSBUS', [{ id: { eq: item.SBU_id } }]);


                if (SBUResponses) { Sbu_Name = SBUResponses[0]?.name + " (" + SBUResponses[0]?.REF_CODE + ")" } else { Sbu_Name = "" }
            }
            else { Sbu_Name = "" }

            let bu_Name = ""
            if (item.bu_id && item.bu_id !== null) {
                // var BUResponses = await DataStore.query(BIUnit, (c) => c.id.eq(item.bu_id));
                var BUResponses = await graphQLGetAllData('listBIUnits', [{ id: { eq: item.bu_id } }]);

                if (BUResponses) { bu_Name = BUResponses[0]?.name + " (" + BUResponses[0]?.REF_CODE + ")" } else { bu_Name = "" }
            }
            else {
                bu_Name = ""
            }

            let data = {
                "id": item.id,
                "name": item.name,
                "REF_CODE": item.REF_CODE,
                "sbu": Sbu_Name,
                "bu": bu_Name
            }
            finalData.push(data)

            if (i === BrandResponses.length - 1) {
                SetBrandList(finalData)
                setBrandFilterList(finalData)
                setPageLoaded(true)
            }
        })
    }

    //Search Employee
    const searchBrand = (searchedText) => {
        if (searchedText.length > 1) {
            let data = [...brandFilterList]
            let filteredData = data.filter(e =>
                e.name.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.bu.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.REF_CODE.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.sbu.toLowerCase().includes(searchedText.toLowerCase())
            )
            SetBrandList(filteredData)
        } else {
            let data = [...brandFilterList]
            SetBrandList(data)
        }
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //onClick Close popup
    async function onClickCloseImportPopup() {
        setIsShowImportPopup(false);
        BindList();
    }

    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    return (
        isAuthenticated ? (

            <div>
                <div className="pt-16">
                    <TopNavBar />
                    <LeftSideBar />

                    <div className="dashboard-main-wrapper pl-20 pr-4 mt-3">
                        <div className="flex flex-1 flex-wrap p-5 pb-4 px-0 top-action-wrapper">
                            <div className="md:flex xs:w-full sm:w-full md:w-auto flex-row align-center">
                                <div className="md:flex block md:col-span-2 mb-4">
                                </div>
                                <div className="md:flex block mb-4">
                                    <h2 className="page-title">Brands</h2>
                                </div>
                            </div>

                            <div className="md:flex flex-1 flex-row xs:w-full sm:w-full md:w-auto align-center justify-end md:ml-auto gap-3 right-side">
                                <div className="relative">
                                    <input onChange={(e) => searchBrand(e.target.value)} type="text" id="required-search" className="rounded-md border border-[#D0D5DD] focus:border-transparent w-full py-2.5 pl-3 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#403E3C] dark:text-[#FFFFFF] dark:border-[#403E3C]" placeholder="Search..." />
                                </div>
                                <div className="md:flex block mb-4 gap-4">
                                    <Link to="/hr/settings" className="flex py-2 px-5 bg-[#FFF] hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-black transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] dark:border-[#4A5056] dark:bg-[#4A5056] dark:text-white">
                                        Back
                                    </Link>
                                    <Link onClick={() => {
                                        setIsShowImportPopup(true);
                                    }} className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] bg-black">
                                        Import
                                    </Link>
                                    <Link to="/hr/settings/dimensions/createbrand" className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF]">
                                        Create
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div>
                            <div className={"block w-full pt-5"}>
                                {
                                    isPageLoaded &&
                                    <DataTable
                                        columns={DataTableColumns}
                                        data={BrandList}
                                        customStyles={DataTableCustomStyles}
                                        pagination
                                        highlightOnHover
                                        paginationPerPage={50}
                                    />
                                }
                            </div>

                            {
                                !isPageLoaded &&
                                <div className='text-center py-10' >
                                    <ProgressSpinner style={{ width: '80px', height: '80px', zIndex: '9999' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".8s" />
                                </div>
                            }
                        </div>
                    </div>
                    {
                        isShowImportPopup &&
                        <ImportDimension
                            closePopup={onClickCloseImportPopup}
                            type={"Brand"}
                        />
                    }
                </div>
            </div>

        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}
