import { Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { reactLocalStorage } from 'reactjs-localstorage';
import loginImage from '../../assets/images/login-img.png';
import logo from '../../assets/images/logo.png';
import ValidateEmail from '../../components/common/ValidateEmail';
import { USER_TYPES } from '../../components/helper/enum';
import { ValidateHRRole } from '../../components/helper/validateRole';
import { BIUnit, Brand, Country, Customer, CustomerCategory, Employee, FinancialYear, GTM, HRAssigTargets, HRAssignTargetDetails, Parameters, Plan, ProductGroup, Region, Relationship, RelationshipDetails, Roles, SBU, SubGTM, TargetDetails, Targets } from '../../models';
import HrDashboard from '../../pages/hr/landing.component';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import moment from 'moment';
var xlsx = require("xlsx")
export default function Test() {


    const [sbuList, SetSbuList] = useState([]);
    const [buList, SetBuList] = useState([]);
    const [regionList, SetRegionList] = useState([]);
    const [countryList, SetCountryList] = useState([]);
    const [brandList, SetBrandList] = useState([]);
    const [productGroupList, SetProductGroupList] = useState([]);
    const [financialYearList, setFinancialYearList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [SBUList, setSBUList] = useState([]);
    const [customerGroupList, SetCustomerGroupList] = useState([]);
    const [customerList, SetCustomerList] = useState([]);
    const [parameterList, SetParametersList] = useState([]);
    const [gtmList, setGtmList] = useState([]);
    const [subGtmList, setSubGTMList] = useState([]);

    async function bindData() {
        //SBU
        var SBUResponses = await DataStore.query(SBU, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        SetSbuList(SBUResponses)

        //Region
        var regionResponses = await DataStore.query(Region, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        SetRegionList(regionResponses)

        //Country
        var countryResponses = await DataStore.query(Country, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        SetCountryList(countryResponses)

        //Brand
        var brandResponses = await DataStore.query(Brand, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        SetBrandList(brandResponses)

        //Product Group
        var pGResponses = await DataStore.query(ProductGroup, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        SetProductGroupList(pGResponses)

        //Customer Group
        var customerGroupResponses = await DataStore.query(CustomerCategory, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        SetCustomerGroupList(customerGroupResponses)

        //Customer
        var customerResponses = await DataStore.query(Customer, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        SetCustomerList(customerResponses)

        var buResponses = await DataStore.query(BIUnit, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        SetBuList(buResponses)

        var parameterResponses = await DataStore.query(Parameters, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        SetParametersList(parameterResponses)

        var financialYearResponses = await DataStore.query(FinancialYear, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        setFinancialYearList(financialYearResponses)

        var roleResponses = await DataStore.query(Roles, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        setRoleList(roleResponses)

        var SUBResponses = await DataStore.query(SBU, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        setSBUList(SUBResponses)

        var gtmResponses = await DataStore.query(GTM, Predicates.ALL); setGtmList(gtmResponses)
        var subGtmResponses = await DataStore.query(SubGTM, Predicates.ALL); setSubGTMList(subGtmResponses)

    }

    useEffect(() => {
        bindData()
    }, []);

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                let EmpName = worksheet['B3'].v; //console.log(EmpName);
                let EmpId = worksheet['B4'].v; //console.log(EmpId);
                let DOJ = worksheet['B5'].v; //console.log(DOJ);
                let EmpDesign = worksheet['B6'].v; //console.log(EmpDesign);
                let EmpReportingManager = worksheet['B11'].v; //console.log(EmpReportingManager);
                let FinancialYearValue = worksheet['B14'].v; //console.log(EmpReportingManager);
                let empData = { "EmpName": EmpName, "EmpId": EmpId, "DOJ": DOJ, "EmpDesignation": EmpDesign, "ReportingManager": EmpReportingManager, "FinancialYearValue": FinancialYearValue }

                const importRange = "A16:G30";
                const headers = ["parameter", "weightage", "q1_target", "q2_target", "q3_target", "q4_target", "year_target"];
                const json = xlsx.utils.sheet_to_json(worksheet, { range: importRange, header: headers });
                if (json) {
                    json.forEach((obj, index) => {
                        obj["key"] = "Parameter " + (index + 1);
                        const worksheet = workbook.Sheets["Parameter " + (index + 1)];
                        const getParameterData = xlsx.utils.sheet_to_json(worksheet);
                        if (getParameterData.length) {
                            const range = xlsx.utils.decode_range(worksheet['!ref']);
                            const endRow = range.e.r;
                            const endColumn = range.e.c;

                            // console.log(`A${5}:${xlsx.utils.encode_col(endColumn)}${endRow + 1}`)
                            const importRange = `A${6}:${xlsx.utils.encode_col(endColumn)}${endRow + 1}`;
                            const headers = ["SBU", "BU", "Cluster", "Country", "GTM", "SubGTM", "Brand", "ProdGrp", "CustomerGrp", "Customer", "Q1", "Q2", "Q3", "Q4", "AllTarget"];
                            const json = xlsx.utils.sheet_to_json(worksheet, { range: importRange, header: headers });
                            obj["parameterData"] = json
                        }
                    });
                    saveData(json, empData)
                }
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    const saveData = async (empTargetData, empPersonalData) => {
        if (empPersonalData) {
            //Check User
            let checkFinancialYear = await DataStore.query(FinancialYear, (c) => c.name.contains("2022-2023"))

            let empDetails = await DataStore.query(Employee, (c) => c.employeeId.eq(empPersonalData.EmpId))
            if (empDetails && checkFinancialYear) {

                //Check weightage Relationship
                var checkWeightageRelation = await DataStore.query(Relationship, (c) => c.and(c => [
                    c.role_id.eq(empDetails[0].role_id),
                    c.financial_year_id.eq(checkFinancialYear[0].id)
                ]));
                if (checkWeightageRelation.length == 0) {
                    await DataStore.save(
                        new Relationship({
                            "financial_year_id": checkFinancialYear[0].id,
                            "role_id": empDetails[0].role_id,
                        })
                    ).then(async (response) => { await saveWeightage(response.id, empTargetData) })
                }

                let checkTarget = await DataStore.query(Targets, (c) => c.and(c => [
                    c.employee_id.eq(empDetails[0].id),
                    c.report_manager_employee_id.eq(empDetails[0].id),
                    c.financial_year_id.eq(checkFinancialYear[0].id)
                ]));

                console.log(checkTarget.length)
                if (checkTarget.length) {
                    saveTargetDetails(checkTarget[0].id, empTargetData)
                } else {
                    await DataStore.save(
                        new Targets({
                            "financial_year_id": checkFinancialYear[0].id,
                            "employee_id": empDetails[0].id,
                            "role_id": empDetails[0].role_id,
                            "report_manager_employee_id": empDetails[0].id,
                            "isAddedByHRAdmin": false,
                            "isAssignedToEmployee": true,
                            "isSubmittedByEmployee": true
                        })
                    ).then((response) => { saveTargetDetails(response.id, empTargetData) })
                }
            }

        }
    }

    const saveWeightage = async (relationId, empTargetData) => {
        let checkRelationExist = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationId))
        try {
            if (checkRelationExist.length == 0) {
                for (var i = 0; i < empTargetData.length; i++) {
                    if (empTargetData[i].parameterData.length > 0) {
                        let parameterId = getParameterId(empTargetData[i].parameter.trim())

                        await DataStore.save(
                            new RelationshipDetails({
                                "relationship_id": relationId,
                                "parameter_id": parameterId,
                                "weightage": parseFloat(empTargetData[i].weightage) * 100
                            })
                        ).then((response) => { })
                    }
                }
            }

        } catch (e) { }
    }

    const saveTargetDetails = async (targetId, empTargetData) => {
        try {
            for (var i = 0; i < empTargetData.length; i++) {
                if (empTargetData[i].parameterData.length > 0) {
                    let parameterId = getParameterId(empTargetData[i].parameter.trim())
                    console.log(empTargetData[i].parameter.trim() + "  = parameterId==== " + parameterId)
                    await DataStore.save(
                        new TargetDetails({
                            "target_id": targetId,
                            "parameter_id": parameterId,
                            "target_value": empTargetData[i].year_target ? String(empTargetData[i].year_target) : "0",
                            "q1_target_value": empTargetData[i].q1_target ? String(empTargetData[i].q1_target) : "0",
                            "q2_target_value": empTargetData[i].q2_target ? String(empTargetData[i].q2_target) : "0",
                            "q3_target_value": empTargetData[i].q3_target ? String(empTargetData[i].q3_target) : "0",
                            "q4_target_value": empTargetData[i].q4_target ? String(empTargetData[i].q4_target) : "0",
                            "target_assigned_date": moment().format(),
                            "is_accepted_by_employee": true,
                            "is_approve": true,
                            "approved_date": moment().format(),
                            "accepted_by_employee_date": moment().format(),
                            "isMarkAsComplete": true,
                            "isMarkAsComplete_AssignTarget": true,
                        })
                    ).then((result) => {
                        savePlan(result.id, empTargetData[i].parameterData)
                    })

                    // if(targetDetailsId){ await savePlan(targetDetailsId.id, empTargetData[i])}
                }
            }
        } catch (e) { }
    }

    var savePlan = async (targetDetailId, findData) => {
        console.log("targetDetailId  ++ + " + JSON.stringify(targetDetailId))
        console.log("PlanData  ++ + " + JSON.stringify(findData))

        for (var i = 0; i < findData.length; i++) {
            let sbuId; if (findData[i].SBU) { sbuId = getSBUId(findData[i].SBU) } else { sbuId = "" }

            let buId; let buREF_CODE;
            if (findData[i].BU) {
                buId = getBUId(findData[i].BU)
                buREF_CODE = getBuREF_CODE(findData[i].BU)
            } else { buId = ""; buREF_CODE = ""; }

            let regionId;
            if (findData[i].Cluster) { regionId = getRegionId(sbuId, findData[i].Cluster) } else { regionId = "" }

            let countryId;
            if (regionId) { countryId = getCountryId(regionId, findData[i].Country) } else { countryId = "" }

            let brandId;
            if (findData[i].Brand) { brandId = getBrandId(sbuId, buId, findData[i].Brand) } else { brandId = "" }

            let productGroupId;
            if (findData[i].ProdGrp) { productGroupId = getProductGroupId(findData[i].ProdGrp) } else { productGroupId = "" }

            let gtmId;
            if (findData[i].GTM) { gtmId = getGtmId(findData[i].GTM) } else { gtmId = "" }

            let subGtmId;
            if (findData[i].SubGTM) { subGtmId = getSubGtmId(findData[i].SubGTM) } else { subGtmId = "" }

            await DataStore.save(
                new Plan({
                    "target_detail_id": targetDetailId,
                    "bu_REF_CODE": buREF_CODE,
                    "sbu_id": sbuId,
                    "bu_id": buId,
                    "region_id": regionId,
                    "country_id": countryId,
                    "brand_id": brandId,
                    "product_group_id": productGroupId,
                    "customer_group_id": "0",
                    "customer_id": "0",
                    // "target_value": String(findData[i].annualTarget),
                    "q1_target_value": findData[i].Q1 ? String(findData[i].Q1) : "0",
                    "q2_target_value": findData[i].Q2 ? String(findData[i].Q2) : "0",
                    "q3_target_value": findData[i].Q3 ? String(findData[i].Q3) : "0",
                    "q4_target_value": findData[i].Q4 ? String(findData[i].Q4) : "0",
                })
            ).then((res)=> console.log(res))
        }
    }


    //// ------------------------------------------------------------ MASTER DATA  **** START ***** --------------------------------------------------------------


    //*get SBUId________
    const getSBUId = (name) => {
        let _sbuList = [...sbuList];
        var returnRecord = _sbuList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*get BuId________
    const getBUId = (name) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*get Bu REF_CODE________
    const getBuREF_CODE = (name) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.REF_CODE; } else { return ""; }
    }

    //*get RegionId________
    const getRegionId = (sbuId, regionId) => {
        let _regionList = [...regionList];
        if (regionId) {
            var returnRecord = _regionList.find(o => (o.SBU_id.toLowerCase().trim() === sbuId.toLowerCase().trim() && o.name.toLowerCase().trim() === regionId.toLowerCase().trim()));
            if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
        }
    }

    //*get CountryId________
    const getCountryId = (regionId, name) => {
        let _countryList = [...countryList];
        var returnRecord = _countryList.find(o => o.region_id.toLowerCase().trim() === regionId.toLowerCase().trim() && o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*get CountryId________
    const getBrandId = (sbuId, buId, brandName) => {
        let _brandList = [...brandList];
        var returnRecord = _brandList.find(o => (o.SBU_id.toLowerCase().trim() === sbuId.toLowerCase().trim() && o.bu_id.toLowerCase().trim() === buId.toLowerCase().trim() && o.name.toLowerCase().trim() === brandName.toLowerCase().trim()));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //get Product Group________
    const getProductGroupId = (name) => {
        let _productGroupList = [...productGroupList];
        var returnRecord = _productGroupList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //get Financial Year_______
    const getRoleId = (name) => {
        let _roleList = [...roleList];
        var returnRecord = _roleList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //get Financial Year_______
    const getFinancialYearId = (name) => {
        let _financialYearList = [...financialYearList];
        var returnRecord = _financialYearList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //get Financial Year_______
    const getParameterId = (name) => {
        let _parameterList = [...parameterList];
        var returnRecord = _parameterList.find(o => o.name.toLowerCase().trim().includes(name.toLowerCase().trim()));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    const getParameterType = (name) => {
        let _parameterList = [...parameterList];
        var returnRecord = _parameterList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord; } else { return ""; }
    }

    //get GtmId
    const getGtmId = (name) => {
        let _gtmList = [...gtmList];
        var returnRecord = _gtmList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //get GtmId
    const getSubGtmId = (name) => {
        let _subGtmList = [...subGtmList];
        var returnRecord = _subGtmList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //// ------------------------------------------------------------ MASTER DATA  **** END ***** --------------------------------------------------------------


    // const ALL_DATA_ReadUploadFile = (e) => {
    //     let newUpdatedData = []
    //     e.preventDefault();
    //     if (e.target.files) {
    //         const reader = new FileReader();
    //         reader.onload = (e) => {
    //             const data = e.target.result;
    //             const workbook = xlsx.read(data, { type: "array" });
    //             console.log(workbook)
    //             if (workbook.SheetNames.length) {
    //                 for (var i = 0; i < workbook.SheetNames.length; i++) {
    //                     console.log(workbook.SheetNames[i])
    //                     const sheetName = workbook.SheetNames[i];
    //                     const worksheet = workbook.Sheets[sheetName];
    //                     const json = xlsx.utils.sheet_to_json(worksheet);
    //                     console.log(JSON.stringify(json))
    //                     newUpdatedData.push(json)

    //                     if (i === workbook.SheetNames.length - 1) {
    //                         console.log(JSON.stringify(newUpdatedData));
    //                     }
    //                 }
    //             }
    //         };
    //         reader.readAsArrayBuffer(e.target.files[0]);
    //     }
    // }

    return (

        <div>
            <div className="min-h-screen flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:grid-cols-1 gap-0">
                <input
                    type="file"
                    name="upload"
                    id="upload"
                    onChange={readUploadFile}
                />
            </div>
        </div>
    )
}