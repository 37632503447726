import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { reactLocalStorage } from 'reactjs-localstorage';
import BoxPlanning from '../../../../assets/images/dashboard_box_planning.png';
import LeftNav from '../../../../components/common/employee/leftnav.component';
import TopNav from '../../../../components/common/employee/topnav.component';
import { ConvertResponseForSelect } from '../../../../components/helper/commonfunctions';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../components/helper/graphQLFunctions';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import EmpLogin from "../../../../setup/auth/emp.component";
import { ShowInMillion } from '../../../../components/helper/maths';
import { PARAMETERS_TYPES } from '../../../../components/helper/enum';
import NoDataFound from '../../../../components/common/nodatafound';
// import { queries } from '@testing-library/react';

export default function ManagePlan() {
    let isReportingManager = reactLocalStorage.get('isReportingManager')
    let getEmployeeId = reactLocalStorage.get("loggedEmployeeId")
    reactLocalStorage.get("loggedEmployeeReportingManagerId")

    let navigateTo = useNavigate();

    const [financialYear, setFinancialYear] = useState("");
    const [financialYearList, setFinancialYearList] = useState([]);
    const [planningPeriod, setPlanningPeriod] = useState([]);

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //Manage Targets
    const [targetsList, setTargetsList] = useState([]);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            console.log("error", e)
            userHasAuthenticated(false);
        }
    }

    //onclick financial year
    const onFinancialSelectChange = async (e) => {
        setTargetsList([])
        reactLocalStorage.setObject('selectedYear', e);
        setFinancialYear({ label: e.label, value: e.value })
        getTargets(e)
    }

    const bindList = async () => {

        try {
            //set financial year
            let selectedYear = reactLocalStorage.getObject('selectedYear');
            if (Object.keys(selectedYear).length !== 0) {
                setFinancialYear(selectedYear)
                getTargets(selectedYear)
            }
            else {
                // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
                var getFinancialYear = [];

                getFinancialYear = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }])

                getTargets({ label: getFinancialYear[0].name, value: getFinancialYear[0].id })
                setFinancialYear({ label: getFinancialYear[0].name, value: getFinancialYear[0].id })
            }

            //get financial year
            var responses = [];

            responses = await graphQLGetAllData('listFinancialYears')

            /* var responses = await DataStore.query(FinancialYear, Predicates.ALL, {
                sort: s => s.name(SortDirection.ASCENDING)
            }); */

            var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYearList(myArray);
        }
        catch (e) {
            console.log("error", e)
        }

    }

    //get targets
    const getTargets = async (selectedYear) => {


        let planningResponses = [];

        planningResponses = await graphQLGetAllData('listPlannings', [{ financial_year_id: { eq: selectedYear.value } }])
        planningResponses = planningResponses.sort((a, b) => a.name.localeCompare(b.name));
        // let planningResponses = await DataStore.query(Planning, (c) => c.financial_year_id.eq(selectedYear.value));

        console.log("getting target", planningResponses)

        if (planningResponses.length > 0) {
            setPlanningPeriod(moment(planningResponses[0].planning_from_date).format("Do MMM YY") + " - " + moment(planningResponses[0].planning_to_date).format("Do MMM YY"))
        } else { setPlanningPeriod("") }

        try {

            var currentEmployee = await graphQLGetAllData('listEmployees', [{ parentEmployeeId: { eq: getEmployeeId } }])
            // var currentEmployee = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(getEmployeeId))

            let targetsResponses = ""
            if (currentEmployee.length > 0) {

                // report_manager_employee_id = loggedEmployeeReportingManagerId was not getting data in myself for Sherif so removed it and added getEmployeeId it return data 25th OCT 2023 17:47
                if (getEmployeeId) {
                    targetsResponses = await graphQLGetAllData('listTargets', [{ employee_id: { eq: getEmployeeId }, report_manager_employee_id: { eq: getEmployeeId }, financial_year_id: { eq: selectedYear.value }, isAddedByHRAdmin: { eq: false }, isAssignedToEmployee: { eq: true } }])
                }
                else {
                    targetsResponses = await graphQLGetAllData('listTargets', [{ employee_id: { eq: getEmployeeId }, financial_year_id: { eq: selectedYear.value }, isAddedByHRAdmin: { eq: false }, isAssignedToEmployee: { eq: true } }])
                }

                /*  targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
                     c.employee_id.eq(getEmployeeId),
                     c.report_manager_employee_id.eq(getEmployeeId),
                     c.financial_year_id.eq(selectedYear.value),
                     c.isAddedByHRAdmin.eq(false),
                     c.isAssignedToEmployee.eq(true)
                 ])); */

            } else {
                targetsResponses = await graphQLGetAllData('listTargets', [{ employee_id: { eq: getEmployeeId }, financial_year_id: { eq: selectedYear.value }, isAddedByHRAdmin: { eq: false }, isAssignedToEmployee: { eq: true } }])

                /* targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
                    c.employee_id.eq(getEmployeeId),
                    //c.report_manager_employee_id.eq(getEmployeeId),
                    c.financial_year_id.eq(selectedYear.value),
                    c.isAddedByHRAdmin.eq(false),
                    c.isAssignedToEmployee.eq(true)
                ])); */

            }

            var relationshipResponse = [];
            //get parameters weightage and return only relationship was latest created for the role id
            if (targetsResponses.length > 0) {
                relationshipResponse = await graphQLGetAllData('listRelationships', [{ role_id: { eq: targetsResponses[0].role_id }, financial_year_id: { eq: selectedYear.value } }])
            }

            relationshipResponse.reduce((prev, current) => {
                return prev._lastChangedAt > current._lastChangedAt ? prev : current;
            }, {})

            /* var relationshipResponse = await DataStore.query(Relationship, (c) => c.and(c => [
                c.role_id.eq(targetsResponses[0].role_id),
                c.financial_year_id.eq(selectedYear.value)
            ])).then(response => response.reduce((prev, current) => {
                return prev._lastChangedAt > current._lastChangedAt ? prev : current;
            }, {})); */

            var relationshipDetailResponses = []

            if (relationshipResponse.length > 0) {

                relationshipDetailResponses = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: relationshipResponse[0].id } }])

                // relationshipDetailResponses = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationshipResponse.id));
            }

            console.log("pratap221", targetsResponses)
            var _tempArray = [];
            // targetsResponses.map(async (target) => {
            //Target Details

            if (targetsResponses.length > 0) {
                var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: targetsResponses[0].id } }])

                /*  var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
                     c.target_id.eq(target.id),
                 ])); */

                if (currentTargetDetails.parameter_id !== "") {
                    currentTargetDetails.map(async (currentItem, i) => {

                        let status = ""
                        if (currentItem.is_accepted_by_employee && !currentItem.is_approve) { status = "Awaiting approval" }
                        else if (currentItem.isSendForRevisionByReportingManage) { status = "Revision" }
                        else if (currentItem.is_approve) { status = "Approved" }
                        else if (currentItem.is_revision_by_employee) { status = "Requested for Revision" }
                        else { status = "Open" }

                        //get parameters details


                        // var parameterResponses = await graphQLGetAllData('listParameters', [{ id: { eq: currentItem.parameter_id } }])

                        const parameterResponses = await graphQLFindRecordById('getParameters', currentItem.parameter_id)


                        // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(currentItem.parameter_id));


                        var getWeightage = relationshipDetailResponses.filter((e) => e.parameter_id === currentItem.parameter_id)

                        let data = {}
                        if (parameterResponses) {
                            data = {
                                "financial_year_id": selectedYear.value,
                                "financial_year": selectedYear.label,
                                "parameter_id": currentItem.parameter_id,
                                "parameter_name": parameterResponses.name,
                                "parameter_type": parameterResponses.type,
                                //"target_value": parseInt(currentItem.target_value),
                                "target_value": parameterResponses.type === PARAMETERS_TYPES.Days ? Math.floor(currentItem.target_value) : parameterResponses.type === PARAMETERS_TYPES.Amount ? ShowInMillion(parseInt(currentItem.target_value)) : currentItem.target_value,
                                "isMarkAsComplete": currentItem.isMarkAsComplete,
                                "is_accepted_by_employee": currentItem.is_accepted_by_employee,
                                "isSendForRevisionByReportingManage": currentItem.isSendForRevisionByReportingManage,
                                "is_approve": currentItem.is_approve,
                                "is_revision_by_employee": currentItem.is_revision_by_employee,
                                "parameter_weightage": getWeightage.length > 0 ? getWeightage[0].weightage : "",
                                "isPlanMandatory": getWeightage.length > 0 ? 1 : 0,
                                "status": status
                            }
                        }

                        // This need to uncomment for hiding blocks with 0 weightage
                        if (data.target_value !== 0 && data.target_value !== '0')
                            _tempArray.push(data)

                        // const uniqueArray = [...new Set(_tempArray)];

                        let sortArray = _tempArray.sort((a, b) => (a.parameter_name > b.parameter_name ? 1 : -1))
                        // const filteredArray = sortArray.filter(item => {
                        //     return item !== null && item !== undefined && item !== '' && !(Array.isArray(item) && item.length === 0) && !(typeof item === 'object' && Object.keys(item).length === 0);
                        // });
                        // const uniqueFilteredArray = [];
                        // const seen = new Set();

                        // filteredArray.forEach(item => {
                        //     if (!seen.has(item)) {
                        //         seen.add(item);
                        //         uniqueFilteredArray.push(item);
                        //     }
                        // });

                        const filteredArray = sortArray.filter((item, idx, array) => {
                            if (
                                item !== null &&
                                item !== undefined &&
                                item !== '' &&
                                !(Array.isArray(item) && item.length === 0) &&
                                !(typeof item === 'object' && Object.keys(item).length === 0)
                            ) {
                                if (array.indexOf(item, idx + 1) === -1) {
                                    return true;
                                }
                            }
                            return false;
                        });

                        console.log("filteredArray", filteredArray)
                        setTargetsList(filteredArray)
                    })
                }
            }
            // })
        } catch (e) {
            console.log("error", e)

        }
    }

    /* const _uniqueElm = targetsList.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      let finalData = [];
      let deleteData = [];
      _uniqueElm.map((item) => {
        let obj = finalData.find(o => o.parameter_id.trim() == item.parameter_id.trim());
        if (!obj) {
            finalData.push(item)
        }
        else {
            deleteData.push(item)
        }
    })
      console.log("_uniqueElm",_uniqueElm)
      console.log("finalData",finalData) */

    useEffect(() => {
        bindList()
        onLoad();
    }, []);


    const navigateToAnnualTarget = async (item) => {

        var currentEmployee = await graphQLGetAllData('listEmployees', [{ parentEmployeeId: { eq: getEmployeeId } }])

        // var currentEmployee = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(getEmployeeId))
        if (currentEmployee.length > 0) {
            navigateTo("/employee/planning/team/managetarget/annualtarget/" + item.financial_year_id + "/" + item.parameter_id + "/" + "self",)
        } else {
            navigateTo("/employee/planning/team/managetarget/annualtarget/" + item.financial_year_id + "/" + item.parameter_id + "/" + "self",)
        }
    }

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNav />
            <LeftNav />

            <div className="dashboard-main-wrapper pl-16 target-main-wrapper" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="sm:flex items-center space-x-8">
                        <div className="text-2xl font-semibold dark:text-white">Goals Setting</div>
                        <div className="tabs_one mt-5 sm:mt-0">
                            <ul className="flex">
                                {
                                    isReportingManager === "1" ?
                                        <li><Link to="/employee/planning/team/managetarget" >My Team</Link></li> : ""
                                }
                                <li><Link className='active'  >Myself</Link></li>
                            </ul>
                        </div>
                    </div>

                    <>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link className='active' >Manage Plan</Link></li>
                                    {/* <li><Link to="/employee/planning/self/managecommit">Manage Commit</Link></li> */}
                                </ul>
                            </div>
                            <div className="mt-5 lg:mt-0 flex items-center gap-4">
                                <div>
                                    {
                                        planningPeriod !== "" ?
                                            <label className='text-sm px-2 py-1.5 border rounded-md border-[#4FB155] text-[#4FB155] dark:text-[#4FB155]'>Goal Setting Timeline: {planningPeriod} </label>
                                            : ""
                                    }
                                </div>
                                <div className='flex items-center'>
                                    <div className="text-md min-w-fit dark:text-white mr-3">Financial Year</div>
                                    <div className="w-full min-w-[200px]">
                                        <Select
                                            options={financialYearList}
                                            value={financialYear}
                                            onChange={e => {
                                                onFinancialSelectChange(e)
                                                reactLocalStorage.setObject('selectedYear', e);
                                            }}
                                            className="mt-2 my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        {
                            targetsList.length > 0 ?
                                <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3 gap-4">
                                    {
                                        targetsList.map((item, i) => {
                                            return (
                                                <div key={i.toString()} className="bg-white py-4 rounded-xl dashbord_box_shape boxShadow boxH relative dark:bg-[#2A2E32]">
                                                    <div className="grid grid-cols-1">
                                                        <div className="px-4">
                                                            <div className="text-[#344054] text-md mb-1 dark:text-[#98A2B3]">{item.parameter_name}</div>
                                                            {
                                                                item.parameter_type === "D" ?
                                                                    <div className="text-2xl font-semibold mb-1  dark:text-[#FFFF]">{item.target_value} Days</div> :
                                                                    item.parameter_type === "A" ?
                                                                        <div className="text-2xl font-semibold mb-1  dark:text-[#FFFF]">${item.target_value}</div> :
                                                                        item.parameter_type === "P" ?
                                                                            <div className="text-2xl font-semibold mb-1  dark:text-[#FFFF]">{parseFloat(item.target_value) < 1 ? (parseFloat(item.target_value) * 100).toFixed(2) : item.target_value}%</div> :
                                                                            <div className="text-2xl font-semibold mb-1  dark:text-[#FFFF]">{item.target_value}</div>
                                                            }
                                                            {
                                                                parseInt(item.parameter_weightage) > 0 ?
                                                                    <div className="text-[#344054] text-xs dark:text-[#98A2B3] mb-2 mt-2">Weightage: {item.parameter_weightage.toFixed(0)}%</div>
                                                                    :
                                                                    <div className="text-[#344054] text-xs dark:text-[#98A2B3] mb-2 mt-2">Weightage: 0%</div>
                                                            }
                                                            <div className='mt-5'>
                                                                {
                                                                    item.status === "Revision" ?
                                                                        <label className="text-sm px-2 py-1.5 border rounded-md border-[red] text-[red] dark:text-[#4FB155] w-30">Status: {item.status}</label> :
                                                                        item.status === "Requested for Revision" ?
                                                                            <label className="text-sm px-2 py-1.5 border rounded-md border-[#FF7F01] text-[#FF7F01] dark:text-[#FF7F01] ">Status: {item.status}</label> :
                                                                            <label className="text-sm px-2 py-1.5 border rounded-md border-[#4FB155] text-[#4FB155] dark:text-[#4FB155] ">Status: {item.status}</label>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-4 mt-8">
                                                        <div className="">
                                                            <ul className="flex gap-16 xl:mt-14 2xl:mt-20" >
                                                                <li><button
                                                                    onClick={() => navigateToAnnualTarget(item)}
                                                                    className="bg-[#029046] py-2 px-5 text-white text-[16px] rounded-md active addClass">View</button></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="absolute top-3 right-2 bottom-0 flex items-center dashbord_box_img1">
                                                        <img src={BoxPlanning} alt="" />
                                                        <div
                                                            className="absolute top-0 right-2">{item.isPlanMandatory == 1 ? <i className='icon s_icon'></i> : <i className='icon t_icon'></i>}</div>
                                                    </div>
                                                </div>
                                            )

                                        })
                                    }
                                </div>
                                :
                                <NoDataFound />
                        }

                    </>


                </div>
            </div>

        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}