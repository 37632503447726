import React, { useState, useEffect } from 'react';
import LeftSideBar from '../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../components/common/hr/topnav.component';
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import EditButton from '../../../components/common/buttons/EditButton';
import DeleteButton from '../../../components/common/buttons/DeleteButton';
import ViewButton from '../../../components/common/buttons/ViewButton';
import ViewButton2 from '../../../components/common/buttons/ViewButtonNew';
import { Auth } from 'aws-amplify';
import { ValidateHRRole } from '../../../components/helper/validateRole';
import HRLogin from '../../../setup/auth/hr.component';
import moment from 'moment';
import { toast } from "react-toastify";
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries'
import * as mutations from '../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../src/components/helper/graphQLFunctions'
import { ProgressSpinner } from 'primereact/progressspinner';
import popupPlanningHeader from '../../../assets/images/planning_header_img.png'
import Select from 'react-select';
import { validateFileExtension, ConvertResponseForSelect } from '../../../components/helper/commonfunctions';
import { PARAMETER_IDs } from '../../../components/helper/enum';
import { ExcelRenderer } from 'react-excel-renderer';
import { GetAWSObjectURL } from '../../../components/helper/aws';

export default function NWC_Days_Actuals() {

    let navigateTo = useNavigate();

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [fileObj, setFileObj] = useState("");
    const [fieldtoupdate, setFieldToUpdate] = useState("")
    const [dataLoaded, setDataLoaded] = useState(false);
    const [nwcDaysLogsList, setNwcDaysLogList] = useState([]);
    const [isShowUploadPopUp, setShowUploadPopUp] = useState(0);
    const [financialYear, setFinancialYear] = useState("");
    const [financialYearList, setFinancialYearList] = useState([]);
    const [quarter, setQuarter] = useState("");
    const [quarterName, setQuarterName] = useState("");
    const [quarterList, setQuarterList] = useState([
        {
            "label": "Q1",
            "value": "1"
        },
        {
            "label": "Q2",
            "value": "2"
        },
        {
            "label": "Q3",
            "value": "3"
        },
        {
            "label": "Q4",
            "value": "4"
        }

    ]);
    const [isUpdateMode, setUpdateMode] = useState(false);
    const [emplist, setEmpList] = useState([]);
    const [isDynamic, setIsDynamic] = useState(false);
    const [dynamicQuarterList, setDynamicQuarterList] = useState([]);
    const [dynamicYearsList, setDynamicYearsList] = useState([]);

    //function for Actions
    const actionsOnLogs = (rowdata) => {
        return (
            <React.Fragment >
                <div className="flex justify-center gap-8 ">
                    <Link to={{ pathname: `/hr/evaluation/viewnwcdaysactuals/${rowdata.financial_year_id}`, }}> <ViewButton2  /></Link>
                    <Link onClick={() => { handleEditClick(rowdata) }}  ><EditButton /></Link>
                </div>

            </React.Fragment>
        );
    }


    const onFinancialSelectChange = async (e) => {
        setFinancialYear({ label: e.label, value: e.value })
    }

    const handleEditClick = (rowdata) => {
        setShowUploadPopUp(1);
        let year = financialYearList.find(c => c.value === rowdata.financial_year_id)
        // let quarter = quarterList.find(c => c.value === rowdata.quarterNo.toString())
        setFinancialYear({ label: year.label, value: year.value })
        // setQuarterName(quarter)
        // setQuarter(quarter.value)
        setUpdateMode(true);
    };

    const uploadFile = async () => {

        if (financialYear === "" || financialYear === undefined) {
            toast.error('Please select financial year'); return;
        }
        if (!fileObj) {
            toast.error('Please select a CSV file'); return;
        }

        var targetResponses = await graphQLGetAllData('listTargets', [{ financial_year_id: { eq: financialYear.value } }]);

        var targetDetailResponses = await graphQLGetAllData('listTargetDetails', [{ parameter_id: { eq: PARAMETER_IDs['NWC DAYS'] } }]);

        if (fileObj.size === 0) {
            toast.error('Do not select empty file.');
        } else {
            ExcelRenderer(fileObj, async (err, resp) => {
                if (err) {
                    console.log(err);
                }
                else {
                    var parameterData = resp.rows
                    var newParameterArray = parameterData.slice(1);

                    for (let i = 0; i < newParameterArray.length; i++) {

                        let empDetails = emplist.find(e => e.employeeId === newParameterArray[i][0].toString());

                        if (empDetails) {
                            let emptargetResponses = targetResponses.filter(c => c.employee_id === empDetails.id)

                            for (var j = 0; j < emptargetResponses.length; j++) {
                                let empTargetDetails = targetDetailResponses.find(c => c.target_id === emptargetResponses[j].id)


                                if (empTargetDetails) {
                                    var returnUpdateData = await API.graphql({
                                        query: mutations.updateTargetDetails,
                                        variables: {
                                            input: {
                                                id: empTargetDetails.id,
                                                m1_actual_value: newParameterArray[i][2] !== undefined || newParameterArray[i][2] !== "" ? newParameterArray[i][2] : "",
                                                m2_actual_value: newParameterArray[i][3] !== undefined || newParameterArray[i][3] !== "" ? newParameterArray[i][3] : "",
                                                m3_actual_value: newParameterArray[i][4] !== undefined || newParameterArray[i][4] !== "" ? newParameterArray[i][4] : "",
                                                m4_actual_value: newParameterArray[i][5] !== undefined || newParameterArray[i][5] !== "" ? newParameterArray[i][5] : "",
                                                m5_actual_value: newParameterArray[i][6] !== undefined || newParameterArray[i][6] !== "" ? newParameterArray[i][6] : "", m6_actual_value: newParameterArray[i][7] !== undefined || newParameterArray[i][7] !== "" ? newParameterArray[i][7] : "",
                                                m7_actual_value: newParameterArray[i][8] !== undefined || newParameterArray[i][8] !== "" ? newParameterArray[i][8] : "",
                                                m8_actual_value: newParameterArray[i][9] !== undefined || newParameterArray[i][9] !== "" ? newParameterArray[i][9] : "",
                                                m9_actual_value: newParameterArray[i][10] !== undefined || newParameterArray[i][10] !== "" ? newParameterArray[i][10] : "",
                                                m10_actual_value: newParameterArray[i][11] !== undefined || newParameterArray[i][11] !== "" ? newParameterArray[i][11] : "",
                                                m11_actual_value: newParameterArray[i][12] !== undefined || newParameterArray[i][12] !== "" ? newParameterArray[i][12] : "",
                                                m12_actual_value: newParameterArray[i][13] !== undefined || newParameterArray[i][13] !== "" ? newParameterArray[i][13] : "",
                                                _version: empTargetDetails._version,
                                            }
                                        }
                                    });

                                }
                            }
                        }
                    }



                }
            });
        }

        // let currentDate = moment().format('YYYY-MM-DD')
        const lastUpdatedDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');

        const checkExistingLog = nwcDaysLogsList.find(c => c.financial_year_id === financialYear.value.toString());
        if (!checkExistingLog) {
            try {
                await API.graphql(
                    graphqlOperation(mutations.createNWCDaysUploadLogs, {
                        input: {
                            financial_year_id: financialYear.value,
                            last_Updated_Date: lastUpdatedDate
                        }
                    })
                );
            } catch (error) {
                console.log(error);
            }

            toast.success('File Uploaded Successfully');

        } else {
            try {
                let returnData = await API.graphql({
                    query: mutations.updateNWCDaysUploadLogs,
                    variables: {
                        input: {
                            id: checkExistingLog.id,
                            last_Updated_Date: lastUpdatedDate,
                            _version: checkExistingLog._version,
                        }
                    }
                });
            } catch (error) {
                console.log(error);
            }

            toast.success('File Uploaded Successfully');

        }

        setShowUploadPopUp(0)
        setFinancialYear("")
        setQuarter("")
        setQuarterName("")
        setUpdateMode(false);
        BindList();
    }

    async function fileHandler(event) {
        let fileObjData = event.target.files[0];

        let file_field = fileObjData.name;
        let allowedExtensions = ['csv'];
        let flag = false;
        flag = validateFileExtension(file_field, allowedExtensions);

        if (flag) {
            event.target.value = null;
            toast.error('Please select valid file format. Only ' + allowedExtensions.join(', ') + ' are allowed.'); return;
        } else {
            setIsDisabled(false)
            setFileObj(event.target.files[0])
        }

    }

    // const getQuarterName = (rowdata) => {
    //     let name = 'Q' + rowdata.quarterNo;
    //     return name;
    // }

    const getLastUpadated = (rowdata) => {
        const formattedDateTime = moment(rowdata.last_Updated_Date).format('Do MMM, YYYY HH:mm');
        return formattedDateTime;
    }

    const downloadDocument = async (filePath) => {
        let filePathURL = await GetAWSObjectURL(filePath);
        const link = document.createElement("a");
        link.href = filePathURL;
        link.download = "";
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }



    async function BindList() {
        setDataLoaded(false);

        let financialYearResponses = await graphQLGetAllData('listFinancialYears');
        financialYearResponses = financialYearResponses.sort((a, b) => a.name.localeCompare(b.name));
        var myArray = []; myArray = ConvertResponseForSelect(financialYearResponses); setFinancialYearList(myArray);

        let employeeResponses = await graphQLGetAllData('listEmployees');
        setEmpList(employeeResponses);

        let logsData = [];
        var nwcDaysLogsResponses = await graphQLGetAllData('listNWCDaysUploadLogs');
        for (var i = 0; i < nwcDaysLogsResponses.length; i++) {
            let obj = { ...nwcDaysLogsResponses[i] };

            let data = financialYearResponses.find(c => c.id === nwcDaysLogsResponses[i].financial_year_id)

            obj['financialYearLabel'] = data.name;
            logsData.push(obj);
        }

        logsData = logsData.sort((a, b) => a.financialYearLabel.localeCompare(b.financialYearLabel));
        setNwcDaysLogList(logsData);

        let dynamicYears = financialYearResponses.filter(c =>
            !logsData.find(l => l.financial_year_id === c.id));


        var myArray = []; myArray = ConvertResponseForSelect(dynamicYears);
        setDynamicYearsList(myArray);
        setDataLoaded(true)
    }

    useEffect(() => {
        onLoad()
        BindList()
    }, []);

    return (
        isAuthenticated ? (
            <div className="pt-16 ">
                <TopNavBar />
                <LeftSideBar />

                <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Goals Evaluation</div>
                        </div>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/hr/evaluation">Initiate Evaluation</Link></li>
                                    <li><Link to="/hr/evaluation/nwcdaysactuals" className={"active"}>NWC Days Actuals</Link></li>
                                    <li><Link to="/hr/evaluation/companysbu" >Company & SBU Actuals</Link></li>
                                    <li><Link to="/hr/evaluation/ebitpbt">EBIT/PBT/Bad Debts Actuals</Link></li>
                                </ul>
                            </div>

                            <div className="mt-5 lg:mt-0">
                                <Link className="bg-[#029046] text-white rounded-md px-4 py-2.5 lg:py-2.5 text-sm lg:text-md shadow-md addclassName" onClick={() => { setShowUploadPopUp(1) }}>Import</Link>
                            </div>

                        </div>

                        <div className=" rounded-lg mt-5">
                            {nwcDaysLogsList && dataLoaded ?
                                <DataTable
                                    paginator
                                    value={nwcDaysLogsList}
                                    responsiveLayout="scroll"
                                    style={{ width: '93vw' }}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                >
                                    <Column field="financialYearLabel" header="Financial Year" sortable></Column>
                                    {/* <Column field="quarterNo" header="Quarter" sortable body={getQuarterName}></Column> */}
                                    <Column field="last_Updated_Date" header="Last Updated On" sortable body={getLastUpadated}></Column>
                                    <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnLogs} ></Column>
                                </DataTable> :
                                <div className='text-center my-auto py-8' ><ProgressSpinner /></div>
                            }
                        </div>

                    </div>
                </div>
                <div id="selector" className={isShowUploadPopUp === 1 ? 'overlay' : ''}></div>

                {
                    isShowUploadPopUp == 1 ?
                        <div style={{ width: "34.271vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="editPopupCont" className="sidenav">
                            <div className="popup_header relative">
                                <img src={popupPlanningHeader} alt="" />
                                <div className="absolute right-0 left-0 top-0 w-full text-white">
                                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                        <div className="text-md">Actuals</div>
                                        <div><Link onClick={() => setShowUploadPopUp(0)} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                    </div>
                                    <div className="px-6 mt-8">
                                        <div className="text-sm">Upload NWC Days Actuals</div>
                                        <div className="2xl:text-[32px] xl:text-[28px] lg:xl:text-[20px] md:xl:text-[18px]">{isUpdateMode ? "NWC Days Actuals" : "Uploading NWC Days Actuals"}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-6">
                                <div>
                                    <label className="text-[#344054] text-[13px] font-medium dark:text-white">Financial Year</label>
                                    <Select
                                        isDisabled={isUpdateMode === true ? true : false}
                                        value={financialYear}
                                        options={dynamicYearsList}
                                        onChange={e => { onFinancialSelectChange(e) }}
                                        className="mt-2 my-react-select-container"
                                        classNamePrefix="my-react-select"
                                    />
                                </div>
                                {/* <div className='mt-4'>
                                    <label className="text-[#344054] text-[13px] font-medium dark:text-white">Quarter</label>
                                    <Select
                                        isDisabled={isUpdateMode === true ? true : false}
                                        placeholder='Select'
                                        options={isDynamic === true ? dynamicQuarterList : quarterList}
                                        value={quarterName}
                                        onChange={e => {
                                            setQuarterName(e)
                                            setQuarter(e.value)
                                        }} className="mt-2 my-react-select-container"
                                        classNamePrefix="my-react-select"
                                    />
                                </div> */}

                                <div className='mt-4 '>
                                    <div className='flex justify-between'>
                                        <span className='text-[#333] dark:text-[#fff]'>Browse File</span>
                                        <a href="#" onClick={(e) => downloadDocument('public/samplefiles/NWC_Actuals_Import_Template.csv')}
                                            className="text-sm text-[#0000FF] px-4 py-2 dark:text-white" rel="noreferrer">
                                            Download Template </a>
                                    </div>

                                    <div>
                                        <button className="border text-[Black] flex bg-[white] rounded-md p-2  border-[#D0D5DD] dark:border-[#131516] dark:bg-[#131516] dar:text-[#fff] pr-3 cursor-pointer">
                                            <input
                                                onChange={(e) => { fileHandler(e,) }}
                                                accept=".csv" id="fileImport"
                                                multiple="multiple" type="file" className="cursor-pointer" />
                                        </button>
                                    </div>
                                    <span className='text-muted font-size-muted text-[#333] dark:text-[#fff]'>Note: Please select .csv file</span>
                                </div>

                            </div>
                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">
                                {
                                    <div className="flex justify-end gap-3">
                                        <div><Link onClick={() => {
                                            setShowUploadPopUp(0)
                                            setFinancialYear("")
                                            setQuarter("")
                                            setQuarterName("")
                                            setUpdateMode(false);
                                        }} className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Cancel</Link></div>
                                        <div><Link onClick={() => uploadFile()} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">{"Upload"}</Link></div>
                                    </div>
                                }
                            </div>
                        </div> : null
                }


            </div >
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}
