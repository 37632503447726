
import moment from "moment";
import { FinancialYear } from "../../models";
import { ObjectToArray } from "./commonfunctions";
import { EVALUATION_STATUS } from "./enum";
import { DataStore } from "aws-amplify";

const EvaluationStatus = (startDate, endDate, todaysDate) => {
    let checkIsBefore = moment(todaysDate).isBefore(startDate, endDate, null, "()")
    let checkIsAfter = moment(todaysDate).isAfter(startDate, endDate, null, "()")
    let checkBetween = moment(todaysDate).isBetween(startDate, endDate, null, "()")

    if (checkBetween) {
        return EVALUATION_STATUS[1]
    } else {
        if (!checkIsBefore && checkIsAfter) { return "Completed" }
        else if (checkIsBefore && !checkIsAfter) { return "Upcoming" }
        else { return "Open" }
    }
};

const Check_TodaysDate_isAfter = (endDate, todaysDate) => {
    let checkIsAfter = moment(todaysDate).isAfter(endDate)
    return checkIsAfter
};


const GetFinancialYear = async (todaysDate) => {
    //let checkBetween = moment(todaysDate).isBetween(startDate, endDate, null, "()")
    const financialYearList = await DataStore.query(FinancialYear);
    Object.keys(financialYearList).map(async (currentItem, i) => {

        let loopItem = financialYearList[currentItem];
        let data = ObjectToArray(loopItem);

        let checkBetween = moment(todaysDate).isBetween(data["fromDate"], data["toDate"], null, "()")

        if (checkBetween) {
            return data["name"];
        }
    })



};

export { EvaluationStatus, GetFinancialYear, Check_TodaysDate_isAfter }
