import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import moment from "moment";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Select from "react-select";
import { reactLocalStorage } from 'reactjs-localstorage';
import EditButton from '../../../../components/common/buttons/EditButton';
import ViewButton from '../../../../components/common/buttons/ViewButton';
import LeftSideBar from '../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../components/common/employee/topnav.component';
import CircularProgressBar from '../../../../components/common/progressbar/CircularProgressbar';
import { ConvertResponseForSelect, ConvertResponseForSelectTwoNames, ObjectToArray } from '../../../../components/helper/commonfunctions';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { CHANGE_REQUEST_TYPES_NAMES } from "../../../../components/helper/enum";
import { BIUnit, Employee, Evaluation, FinancialYear, Region, Roles, SBU, TargetDetails, Targets, ChangeRequest, TeamType, InitiateEvaluation } from "../../../../models";
import EmpLogin from "../../../../setup/auth/emp.component";
import ChatButton from '../../../../components/common/buttons/ChatButton';
import { calculatePercentage } from "../../../../../src/components/helper/dashboardCalCulations";
import { evaluationFilter } from "../../../../components/helper/getFilterData"
import { useNavigate } from 'react-router-dom'
import { checkEmployeeTeamType } from '../../helper';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../components/helper/graphQLFunctions';
import { getRoles } from '@testing-library/react';




export default function BusinessEvaluationDashboard() {

    const navigateTo = useNavigate()
    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //state
    const [financialYear, setFinancialYear] = useState("");
    const [sBU, setSBU] = useState("");
    const [bU, setBU] = useState("");
    const [region, setRegion] = useState("");
    const [employee, setEmployee] = useState("");
    const [totalOverrideActualsRequest, setTotalOverrideActualsRequest] = useState("0");
    const [totalBusinessInPer, setTotalBusinessInPer] = useState("0");
    const [totalSalesInPer, setTotalSalesInPer] = useState("0");
    const [businessCount, setBusinessCount] = useState("");
    const [salesCount, setSalesCount] = useState("");
    const [eligibleEmployeeCount, setEligibleEmployeeCount] = useState("");
    const [eligibleEmployeeBusinessCount, setEligibleEmployeeBusinessCount] = useState("");
    const [eligibleEmployeeSalesCount, setEligibleEmployeeSalesCount] = useState("");
    const [eligibleEmployeeBusinessCountPer, setEligibleEmployeeBusinessCountPer] = useState("");
    const [eligibleEmployeeSalesCountPer, setEligibleEmployeeSalesCountPer] = useState("");
    const [evaluationInitiatedCount, setEvaluationInitiatedCount] = useState("");
    const [evaluationInitiatedBusinessCount, setEvaluationInitiatedBusinessCount] = useState("");
    const [evaluationInitiatedSalesCount, setEvaluationInitiatedSalesCount] = useState("");
    const [evaluationInitiatedBusinessCountPer, setEvaluationInitiatedCountPer] = useState("");
    const [evaluationInitiatedSalesCountPer, setEvaluationInitiatedSalesCountPer] = useState("");
    const [quarter, setQuarter] = useState("");




    //list
    const [financialYearList, setFinancialYearList] = useState([]);
    const [sBUList, setSBUList] = useState([]);
    const [bUList, setBUList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);

    //quarterList
    var quarterList = [
        { label: "All Quarter", value: "4" },
        { label: "Quarter 1", value: "1" },
        { label: "Quarter 2", value: "2" },
        { label: "Quarter 3", value: "3" },
        { label: "Quarter 4", value: "4" },
    ]


    //Edit View Button
    const actionsOnRoles = () => {
        return (
            <React.Fragment >
                <div className="flex gap-8 ">
                    {/* <Link
                        onClick={() => {
                            // setViewPopUp(1)
                        }}
                    ><ViewButton /></Link> */}
                    <Link onClick={() => {
                        // setEditPopUp(1)
                    }}  ><ChatButton /></Link>
                </div>

            </React.Fragment>
        );
    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    async function bindList() {
        //set financial year
        // let selectedYear = reactLocalStorage.getObject('selectedYear');
        // if (Object.keys(selectedYear).length !== 0) {
        //     setFinancialYear(selectedYear)
        //     bindEvaluationReport(selectedYear.value, "1")
        //     setQuarter({ label: "Quarter 1", value: "1" })
        // }
        // else {
        //     // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));

        //     var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);
        //     setFinancialYear({ label: responses[0].name, value: responses[0].id })
        //     bindEvaluationReport(responses[0].id, "1")
        //     setQuarter({ label: "Quarter 1", value: "1" })
        // }

        //get financial year
        // var financialYearResponses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        var financialYearResponses = await graphQLGetAllData('listFinancialYears');
        financialYearResponses = financialYearResponses.sort((a, b) => a.name.localeCompare(b.name));
        var myArray = []; myArray = ConvertResponseForSelect(financialYearResponses); setFinancialYearList(myArray);

        //get SBU list
        // var sBUResponses = await DataStore.query(SBU, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        // var sBUResponses = await graphQLGetAllData('listSBUS');
        // sBUResponses = sBUResponses.sort((a, b) => a.name.localeCompare(b.name));
        // var mySBUArray = []; mySBUArray = ConvertResponseForSelect(sBUResponses);
        // let zeroPositionSBUObject = { label: "All SBU", value: 0 }; mySBUArray.unshift(zeroPositionSBUObject);
        // setSBU({ label: "All SBU", value: 0 })
        // setSBUList(mySBUArray);

        //get employee list
        // var employeeResponses = await DataStore.query(Employee, (c) => c.or(c => [
        //     c.id.eq(loggedEmployeeId),
        //     c.parentEmployeeId.eq(loggedEmployeeId)
        // ]));
        let employeeResponses = employees;
        let employeeSelfData = await graphQLFindRecordById('getEmployee', loggedEmployeeId);

        var myEmployeeArray = []; myEmployeeArray = ConvertResponseForSelectTwoNames(employeeResponses);
        let name = employeeSelfData.firstName + " " + employeeSelfData.lastName;
        let role = await graphQLFindRecordById('getRoles', employeeSelfData.role_id);

        let obj = { label: name + " (" + role?.name + ")", value: employeeSelfData.id }
        setEmployee(obj)
        handleClickEmployeeDropdown(obj)
        setEmployeeList(myEmployeeArray);
    }

    const [isDataLoaded, setDataLoaded] = useState(false);
    const [employees, setAllEmployees] = useState([]);
    const getMasters = async () => {
        setDataLoaded(false);
        var employeeResponses = [];
        var employeeResponses = await graphQLGetAllData('listEmployees', [
            { parentEmployeeId: { eq: loggedEmployeeId } }
        ]);

        let employeeSelfData = await graphQLFindRecordById('getEmployee', loggedEmployeeId);
        employeeResponses.push(employeeSelfData);

        employeeResponses = employeeResponses.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));

        setAllEmployees(employeeResponses);

        let selectedYear = reactLocalStorage.getObject('selectedYear');
        if (Object.keys(selectedYear).length !== 0) {
            setFinancialYear(selectedYear)
            bindEvaluationReport(selectedYear.value, "1")
            setQuarter({ label: "Quarter 1", value: "1" })
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));

            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);
            setFinancialYear({ label: responses[0].name, value: responses[0].id })
            bindEvaluationReport(responses[0].id, "1")
            setQuarter({ label: "Quarter 1", value: "1" })
        }

        var sBUResponses = await graphQLGetAllData('listSBUS');
        sBUResponses = sBUResponses.sort((a, b) => a.name.localeCompare(b.name));
        var mySBUArray = []; mySBUArray = ConvertResponseForSelect(sBUResponses);
        let zeroPositionSBUObject = { label: "All SBU", value: 0 }; mySBUArray.unshift(zeroPositionSBUObject);
        setSBU({ label: "All SBU", value: 0 })
        setSBUList(mySBUArray);

        setDataLoaded(true);
    };

    useEffect(() => {
        if (isDataLoaded === true) {
            onLoad();
            bindList()
        }
    }, [isDataLoaded]);

    useEffect(() => {
        getMasters()
    }, []);

    const [employeeEvaluationList, SetEmployeeEvaluationList] = useState([]);

    const bindEvaluationReport = async (financialYearId, quaterNo) => {
        var newResponse = [];

        // var EvaluationResponses = await DataStore.query(Evaluation, (c) => c.and(c => [
        //     c.financial_year_id.eq(financialYearId),
        //     c.quaterNo.eq(quaterNo)
        // ]));


        var EvaluationResponses = await graphQLGetAllData('listEvaluations', [
            { financial_year_id: { eq: financialYearId } },
            { quaterNo: { eq: quaterNo } }
        ]);


        if (EvaluationResponses.length) {
            let employeeResponse = []
            // employeeResponse = await DataStore.query(Employee, (c) => c.or(c => [
            //     c.parentEmployeeId.eq(loggedEmployeeId),
            //     c.id.eq(loggedEmployeeId)
            // ]));
            employeeResponse = await graphQLGetAllData('listEmployees', [
                { parentEmployeeId: { eq: loggedEmployeeId } }
            ]);

            let employeeSelfData = await graphQLFindRecordById('getEmployee', loggedEmployeeId);
            employeeResponse.push(employeeSelfData);

            employeeResponse = employeeResponse.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));


            if (employeeResponse.length) {
                // Object.keys(employeeResponse).map(async (currentItem, i) => {
                //     let loopItem = employeeResponse[currentItem];
                //     let data = ObjectToArray(loopItem);
                //     data["index"] = parseInt(i) + 1;
                //     data["name"] = data["firstName"] + " " + data["lastName"];
                //     data["employeeId"] = data["employeeId"];

                //     //*get role
                //     // var roleResponse = await DataStore.query(Roles, data["role_id"]);
                //     var roleResponse = await graphQLFindRecordById('getRoles', data["role_id"]);
                //     data["teamType"] = roleResponse.teamType;

                //     //Parent Employee List
                //     // var parentResponse = await DataStore.query(Employee, (c) => c.id.eq(data["parentEmployeeId"]));
                //     var parentResponse = await graphQLFindRecordById('getEmployee', data["parentEmployeeId"]);

                //     if (parentResponse.length > 0) {
                //         data["reportingManager"] = parentResponse[0].firstName + " " + parentResponse[0].lastName;
                //     }

                //     // var initiateEvaluationResponses = await DataStore.query(InitiateEvaluation, (c) => c.and(c => [
                //     //     c.employee_id.eq(data["id"]),
                //     //     c.evaluation_id.eq(EvaluationResponses[0].id)
                //     // ]));
                //     var initiateEvaluationResponses = await graphQLGetAllData('listInitiateEvaluations', [
                //         { employee_id: { eq: data["id"] } },
                //         { evaluation_id: { eq: EvaluationResponses[0].id } }
                //     ]);

                //     if (initiateEvaluationResponses.length > 0) { data["initiated"] = "Yes"; } else { data["initiated"] = "No"; }

                //     data["eligible"] = "Yes";
                //     data["totalWeightage"] = "100%";
                //     data["wtWeightage"] = "80%";
                //     data["status"] = "Completed";


                //     newResponse.push(data);
                //     if (i === employeeResponse.length - 1) {
                //         let sortArray = newResponse.sort((a, b) => (a.index > b.index ? 1 : -1))
                //         SetEmployeeEvaluationList(sortArray);

                //         var eligibleEmployeeCount = sortArray.filter((e) => e.eligible == "Yes")
                //         setEligibleEmployeeCount(eligibleEmployeeCount.length)

                //         var evaluationInitiatedCount = sortArray.filter((e) => e.initiated == "Yes")
                //         setEvaluationInitiatedCount(evaluationInitiatedCount.length)

                //         if (eligibleEmployeeCount.length > 0) {

                //             var eligibleEmployeeCountBusiness = sortArray.filter((e) => e.teamType == TeamType.B)
                //             setEligibleEmployeeBusinessCount(eligibleEmployeeCountBusiness.length)

                //             var eligibleEmployeeCountSales = sortArray.filter((e) => e.teamType == TeamType.S)
                //             setEligibleEmployeeSalesCount(eligibleEmployeeCountSales.length)

                //             let getEligibleEmployeeBusinessPer = await calculatePercentage(eligibleEmployeeCountBusiness.length, eligibleEmployeeCount.length);
                //             let getEligibleEmployeeSalesPer = await calculatePercentage(eligibleEmployeeCountSales.length, eligibleEmployeeCount.length);
                //             setEligibleEmployeeBusinessCountPer(getEligibleEmployeeBusinessPer); setEligibleEmployeeSalesCountPer(getEligibleEmployeeSalesPer)

                //         } else {
                //             setEligibleEmployeeBusinessCount(0); setEligibleEmployeeSalesCount(0); setEligibleEmployeeBusinessCountPer(0); setEligibleEmployeeSalesCountPer(0);
                //         }

                //         if (evaluationInitiatedCount.length > 0) {

                //             var evaluationInitiatedCountBusiness = sortArray.filter((e) => e.teamType == TeamType.B)
                //             setEvaluationInitiatedBusinessCount(evaluationInitiatedCountBusiness.length)

                //             var evaluationInitiatedCountSales = sortArray.filter((e) => e.teamType == TeamType.S)
                //             setEvaluationInitiatedSalesCount(evaluationInitiatedCountSales.length)

                //             let getEvaluationIntiBusinessPer = await calculatePercentage(evaluationInitiatedCountBusiness.length, evaluationInitiatedCount.length);
                //             let getEvaluationIntiSalesPer = await calculatePercentage(evaluationInitiatedCountSales.length, evaluationInitiatedCount.length);
                //             setEvaluationInitiatedCountPer(getEvaluationIntiBusinessPer); setEvaluationInitiatedSalesCountPer(getEvaluationIntiSalesPer)
                //         } else {
                //             setEvaluationInitiatedBusinessCount(0); setEvaluationInitiatedSalesCount(0); setEvaluationInitiatedCountPer(0); setEvaluationInitiatedSalesCountPer(0);
                //         }
                //     }
                // })

                for (let i = 0; i < employeeResponse.length; i++) {
                    const currentItem = employeeResponse[i];
                    const loopItem = currentItem;
                    const data = ObjectToArray(loopItem);
                    data["index"] = i + 1;
                    data["name"] = data["firstName"] + " " + data["lastName"];
                    data["employeeId"] = data["employeeId"];

                    // Get role
                    const roleResponse = await graphQLFindRecordById('getRoles', data["role_id"]);
                    data["teamType"] = roleResponse.teamType;

                    // Parent Employee List
                    const parentResponse = await graphQLFindRecordById('getEmployee', data["parentEmployeeId"]);

                    if (parentResponse.length > 0) {
                        data["reportingManager"] = parentResponse[0].firstName + " " + parentResponse[0].lastName;
                    }

                    const initiateEvaluationResponses = await graphQLGetAllData('listInitiateEvaluations', [
                        { employee_id: { eq: data["id"] } },
                        { evaluation_id: { eq: EvaluationResponses[0].id } }
                    ]);

                    if (initiateEvaluationResponses.length > 0) {
                        data["initiated"] = "Yes";
                    } else {
                        data["initiated"] = "No";
                    }

                    data["eligible"] = "Yes";
                    data["totalWeightage"] = "100%";
                    data["wtWeightage"] = "80%";
                    data["status"] = "Completed";

                    newResponse.push(data);

                    if (i === employeeResponse.length - 1) {
                        const sortArray = newResponse.sort((a, b) => (a.index > b.index ? 1 : -1));
                        SetEmployeeEvaluationList(sortArray);

                        const eligibleEmployeeCount = sortArray.filter((e) => e.eligible == "Yes");
                        setEligibleEmployeeCount(eligibleEmployeeCount.length);

                        const evaluationInitiatedCount = sortArray.filter((e) => e.initiated == "Yes");
                        setEvaluationInitiatedCount(evaluationInitiatedCount.length);

                        if (eligibleEmployeeCount.length > 0) {
                            const eligibleEmployeeCountBusiness = sortArray.filter((e) => e.teamType == TeamType.B);
                            setEligibleEmployeeBusinessCount(eligibleEmployeeCountBusiness.length);

                            const eligibleEmployeeCountSales = sortArray.filter((e) => e.teamType == TeamType.S);
                            setEligibleEmployeeSalesCount(eligibleEmployeeCountSales.length);

                            const getEligibleEmployeeBusinessPer = await calculatePercentage(eligibleEmployeeCountBusiness.length, eligibleEmployeeCount.length);
                            const getEligibleEmployeeSalesPer = await calculatePercentage(eligibleEmployeeCountSales.length, eligibleEmployeeCount.length);
                            setEligibleEmployeeBusinessCountPer(getEligibleEmployeeBusinessPer);
                            setEligibleEmployeeSalesCountPer(getEligibleEmployeeSalesPer);
                        } else {
                            setEligibleEmployeeBusinessCount(0);
                            setEligibleEmployeeSalesCount(0);
                            setEligibleEmployeeBusinessCountPer(0);
                            setEligibleEmployeeSalesCountPer(0);
                        }

                        if (evaluationInitiatedCount.length > 0) {
                            const evaluationInitiatedCountBusiness = sortArray.filter((e) => e.teamType == TeamType.B);
                            setEvaluationInitiatedBusinessCount(evaluationInitiatedCountBusiness.length);

                            const evaluationInitiatedCountSales = sortArray.filter((e) => e.teamType == TeamType.S);
                            setEvaluationInitiatedSalesCount(evaluationInitiatedCountSales.length);

                            const getEvaluationIntiBusinessPer = await calculatePercentage(evaluationInitiatedCountBusiness.length, evaluationInitiatedCount.length);
                            const getEvaluationIntiSalesPer = await calculatePercentage(evaluationInitiatedCountSales.length, evaluationInitiatedCount.length);
                            setEvaluationInitiatedCountPer(getEvaluationIntiBusinessPer);
                            setEvaluationInitiatedSalesCountPer(getEvaluationIntiSalesPer);
                        } else {
                            setEvaluationInitiatedBusinessCount(0);
                            setEvaluationInitiatedSalesCount(0);
                            setEvaluationInitiatedCountPer(0);
                            setEvaluationInitiatedSalesCountPer(0);
                        }
                    }
                }

            }
        }
        else { SetEmployeeEvaluationList([]) }
        getChangeRequestResponse(0, financialYearId)

    }

    const getChangeRequestResponse = async (employeeId, financialYearId) => {

        var getChangeRequestCount = [];
        if (employeeId !== 0) {
            //*get Change Request count for employee
            // getChangeRequestCount = await DataStore.query(ChangeRequest, (c) => c.and(c => [
            //     c.requestedEmployeeId.eq(employeeId),
            //     c.requestType.eq("OA"),
            //     c.financial_year_id.eq(financialYearId)
            // ]));
            try {
                getChangeRequestCount = await graphQLGetAllData('listChangeRequests', [
                    { requestedEmployeeId: { eq: employeeId } },
                    { requestType: { eq: "OA" } },
                    { financial_year_id: { eq: financialYearId } }
                ]);
            } catch (error) {
                console.log('error', error);
            };


        } else {
            //*get Change Request count for all
            // getChangeRequestCount = await DataStore.query(ChangeRequest, (c) => c.and(c => [
            //     c.reportingManagerEmployeeId.eq(loggedEmployeeId),
            //     c.requestType.eq("OA"),
            //     c.financial_year_id.eq(financialYearId)
            // ]));

            getChangeRequestCount = await graphQLGetAllData('listChangeRequests', [
                { reportingManagerEmployeeId: { eq: loggedEmployeeId } },
                { requestType: { eq: "OA" } },
                { financial_year_id: { eq: financialYearId } }
            ]);
        }

        if (getChangeRequestCount.length > 0) {
            let businessTypeEMP = [];
            let salesTypeEMP = [];

            // getChangeRequestCount.map(async (item, index) => {
            //     // var employeeResponse = await DataStore.query(Employee, (c) => c.id.eq(item.requestedEmployeeId));
            //     var employeeResponse = await graphQLFindRecordById('getEmployee', item.requestedEmployeeId);

            //     if (employeeResponse.length > 0) {
            //         // var roleResponse = await DataStore.query(Roles, (c) => c.id.eq(employeeResponse[0].role_id));
            //         var roleResponse = await graphQLFindRecordById('getRoles', employeeResponse[0].role_id);

            //         if (roleResponse.length) {
            //             if (roleResponse[0].teamType === "B") {
            //                 businessTypeEMP.push(item);
            //             } else if (roleResponse[0].teamType === "S") {
            //                 salesTypeEMP.push(item);
            //             }
            //         }
            //     }
            //     if (index === getChangeRequestCount.length - 1) {
            //         let businessTypeCount = businessTypeEMP.length
            //         let salesTypeCount = salesTypeEMP.length
            //         let getBusinessPer = await calculatePercentage(businessTypeCount, getChangeRequestCount.length)
            //         let getSalesPer = await calculatePercentage(salesTypeCount, getChangeRequestCount.length)
            //         setTotalOverrideActualsRequest(getChangeRequestCount.length)
            //         setBusinessCount(businessTypeCount)
            //         setSalesCount(salesTypeCount)
            //         setTotalBusinessInPer(getBusinessPer)
            //         setTotalSalesInPer(getSalesPer)
            //     }
            // })
            for (let index = 0; index < getChangeRequestCount.length; index++) {
                const item = getChangeRequestCount[index];
                const employeeResponse = await graphQLFindRecordById('getEmployee', item.requestedEmployeeId);

                if (employeeResponse.length > 0) {
                    const roleResponse = await graphQLFindRecordById('getRoles', employeeResponse[0].role_id);

                    if (roleResponse.length > 0) {
                        if (roleResponse[0].teamType === "B") {
                            businessTypeEMP.push(item);
                        } else if (roleResponse[0].teamType === "S") {
                            salesTypeEMP.push(item);
                        }
                    }
                }

                if (index === getChangeRequestCount.length - 1) {
                    const businessTypeCount = businessTypeEMP.length;
                    const salesTypeCount = salesTypeEMP.length;
                    const getBusinessPer = await calculatePercentage(businessTypeCount, getChangeRequestCount.length);
                    const getSalesPer = await calculatePercentage(salesTypeCount, getChangeRequestCount.length);
                    setTotalOverrideActualsRequest(getChangeRequestCount.length);
                    setBusinessCount(businessTypeCount);
                    setSalesCount(salesTypeCount);
                    setTotalBusinessInPer(getBusinessPer);
                    setTotalSalesInPer(getSalesPer);
                }
            }

        } else {
            setTotalOverrideActualsRequest("0")
            setBusinessCount("0")
            setSalesCount("0")
            setTotalBusinessInPer("0")
            setTotalSalesInPer("0")
        }
    }

    //*onClick Financial Year
    const handleClickFinanCialYearDropdown = async (event) => {
        setFinancialYear(event)
        reactLocalStorage.setObject('selectedYear', event);

        //*get bind Evaluation data
        const evaluationData = await evaluationFilter(employee.value, quarter.value, event.value, sBU.value)

        if (evaluationData.length > 0) {
            SetEmployeeEvaluationList(evaluationData)

            var eligibleEmployeeCount = evaluationData.filter((e) => e.eligible == "Yes")
            setEligibleEmployeeCount(eligibleEmployeeCount.length)

            var evaluationInitiatedCount = evaluationData.filter((e) => e.initiated == "Yes")
            setEvaluationInitiatedCount(evaluationInitiatedCount.length)

            if (eligibleEmployeeCount.length > 0) {

                var eligibleEmployeeCountBusiness = eligibleEmployeeCount.filter((e) => e.teamType == TeamType.B)
                setEligibleEmployeeBusinessCount(eligibleEmployeeCountBusiness.length)

                var eligibleEmployeeCountSales = eligibleEmployeeCount.filter((e) => e.teamType == TeamType.S)
                setEligibleEmployeeSalesCount(eligibleEmployeeCountSales.length)

                let getEligibleEmployeeBusinessPer = await calculatePercentage(eligibleEmployeeCountBusiness.length, eligibleEmployeeCount.length);
                let getEligibleEmployeeSalesPer = await calculatePercentage(eligibleEmployeeCountSales.length, eligibleEmployeeCount.length);
                setEligibleEmployeeBusinessCountPer(getEligibleEmployeeBusinessPer); setEligibleEmployeeSalesCountPer(getEligibleEmployeeSalesPer)

            } else {
                setEligibleEmployeeBusinessCount(0); setEligibleEmployeeSalesCount(0); setEligibleEmployeeBusinessCountPer(0); setEligibleEmployeeSalesCountPer(0);
            }

            if (evaluationInitiatedCount.length > 0) {

                var evaluationInitiatedCountBusiness = evaluationInitiatedCount.filter((e) => e.teamType == TeamType.B)
                setEvaluationInitiatedBusinessCount(evaluationInitiatedCountBusiness.length)

                var evaluationInitiatedCountSales = evaluationInitiatedCount.filter((e) => e.teamType == TeamType.S)
                setEvaluationInitiatedSalesCount(evaluationInitiatedCountSales.length)

                let getEvaluationIntiBusinessPer = await calculatePercentage(evaluationInitiatedCountBusiness.length, evaluationInitiatedCount.length);
                let getEvaluationIntiSalesPer = await calculatePercentage(evaluationInitiatedCountSales.length, evaluationInitiatedCount.length);
                setEvaluationInitiatedCountPer(getEvaluationIntiBusinessPer); setEvaluationInitiatedSalesCountPer(getEvaluationIntiSalesPer)
            } else {
                setEvaluationInitiatedBusinessCount(0); setEvaluationInitiatedSalesCount(0); setEvaluationInitiatedCountPer(0); setEvaluationInitiatedSalesCountPer(0);
            }

        } else {
            SetEmployeeEvaluationList([]); setEligibleEmployeeCount(0); setEvaluationInitiatedCount(0); setEligibleEmployeeBusinessCount(0); setEligibleEmployeeSalesCount(0);
            setEvaluationInitiatedBusinessCount(0); setEvaluationInitiatedSalesCount(0); setEligibleEmployeeBusinessCountPer(0); setEvaluationInitiatedCountPer(0);
        }
        getChangeRequestResponse(employee.value, event.value)
    }

    //onClick Employee
    const handleClickEmployeeDropdown = async (event) => {
        setEmployee(event)
        //*get bind Evaluation data
        const evaluationData = await evaluationFilter(event.value, quarter.value, financialYear.value, sBU.value)
        if (evaluationData.length > 0) {
            SetEmployeeEvaluationList(evaluationData)
            var eligibleEmployeeCount = evaluationData.filter((e) => e.eligible == "Yes")
            setEligibleEmployeeCount(eligibleEmployeeCount.length)

            var evaluationInitiatedCount = evaluationData.filter((e) => e.initiated == "Yes")
            setEvaluationInitiatedCount(evaluationInitiatedCount.length)

            if (eligibleEmployeeCount.length > 0) {

                var eligibleEmployeeCountBusiness = eligibleEmployeeCount.filter((e) => e.teamType == TeamType.B)
                setEligibleEmployeeBusinessCount(eligibleEmployeeCountBusiness.length)

                var eligibleEmployeeCountSales = eligibleEmployeeCount.filter((e) => e.teamType == TeamType.S)
                setEligibleEmployeeSalesCount(eligibleEmployeeCountSales.length)

                let getEligibleEmployeeBusinessPer = await calculatePercentage(eligibleEmployeeCountBusiness.length, eligibleEmployeeCount.length);
                let getEligibleEmployeeSalesPer = await calculatePercentage(eligibleEmployeeCountSales.length, eligibleEmployeeCount.length);
                setEligibleEmployeeBusinessCountPer(getEligibleEmployeeBusinessPer); setEligibleEmployeeSalesCountPer(getEligibleEmployeeSalesPer)

            } else {
                setEligibleEmployeeBusinessCount(0); setEligibleEmployeeSalesCount(0); setEligibleEmployeeBusinessCountPer(0); setEligibleEmployeeSalesCountPer(0);
            }

            if (evaluationInitiatedCount.length > 0) {

                var evaluationInitiatedCountBusiness = evaluationInitiatedCount.filter((e) => e.teamType == TeamType.B)
                setEvaluationInitiatedBusinessCount(evaluationInitiatedCountBusiness.length)

                var evaluationInitiatedCountSales = evaluationInitiatedCount.filter((e) => e.teamType == TeamType.S)
                setEvaluationInitiatedSalesCount(evaluationInitiatedCountSales.length)

                let getEvaluationIntiBusinessPer = await calculatePercentage(evaluationInitiatedCountBusiness.length, evaluationInitiatedCount.length);
                let getEvaluationIntiSalesPer = await calculatePercentage(evaluationInitiatedCountSales.length, evaluationInitiatedCount.length);
                setEvaluationInitiatedCountPer(getEvaluationIntiBusinessPer); setEvaluationInitiatedSalesCountPer(getEvaluationIntiSalesPer)
            } else {
                setEvaluationInitiatedBusinessCount(0); setEvaluationInitiatedSalesCount(0); setEvaluationInitiatedCountPer(0); setEvaluationInitiatedSalesCountPer(0);
            }

        } else {
            SetEmployeeEvaluationList([]); setEligibleEmployeeCount(0); setEvaluationInitiatedCount(0); setEligibleEmployeeBusinessCount(0); setEligibleEmployeeSalesCount(0);
            setEvaluationInitiatedBusinessCount(0); setEvaluationInitiatedSalesCount(0); setEligibleEmployeeBusinessCountPer(0); setEvaluationInitiatedCountPer(0);
        }
        getChangeRequestResponse(event.value, financialYear.value)
    }

    //onClick Employee
    const handleClickSBUDropdown = async (event) => {

        setSBU(event)
        //*get bind Evaluation data
        const evaluationData = await evaluationFilter(employee.value, quarter.value, financialYear.value, event.value)
        if (evaluationData.length > 0) {
            SetEmployeeEvaluationList(evaluationData)

            var eligibleEmployeeCount = evaluationData.filter((e) => e.eligible == "Yes")
            setEligibleEmployeeCount(eligibleEmployeeCount.length)

            var evaluationInitiatedCount = evaluationData.filter((e) => e.initiated == "Yes")
            setEvaluationInitiatedCount(evaluationInitiatedCount.length)

            if (eligibleEmployeeCount.length > 0) {

                var eligibleEmployeeCountBusiness = eligibleEmployeeCount.filter((e) => e.teamType == TeamType.B)
                setEligibleEmployeeBusinessCount(eligibleEmployeeCountBusiness.length)

                var eligibleEmployeeCountSales = eligibleEmployeeCount.filter((e) => e.teamType == TeamType.S)
                setEligibleEmployeeSalesCount(eligibleEmployeeCountSales.length)

                let getEligibleEmployeeBusinessPer = await calculatePercentage(eligibleEmployeeCountBusiness.length, eligibleEmployeeCount.length);
                let getEligibleEmployeeSalesPer = await calculatePercentage(eligibleEmployeeCountSales.length, eligibleEmployeeCount.length);
                setEligibleEmployeeBusinessCountPer(getEligibleEmployeeBusinessPer); setEligibleEmployeeSalesCountPer(getEligibleEmployeeSalesPer)

            } else {
                setEligibleEmployeeBusinessCount(0); setEligibleEmployeeSalesCount(0); setEligibleEmployeeBusinessCountPer(0); setEligibleEmployeeSalesCountPer(0);
            }

            if (evaluationInitiatedCount.length > 0) {

                var evaluationInitiatedCountBusiness = evaluationInitiatedCount.filter((e) => e.teamType == TeamType.B)
                setEvaluationInitiatedBusinessCount(evaluationInitiatedCountBusiness.length)

                var evaluationInitiatedCountSales = evaluationInitiatedCount.filter((e) => e.teamType == TeamType.S)
                setEvaluationInitiatedSalesCount(evaluationInitiatedCountSales.length)

                let getEvaluationIntiBusinessPer = await calculatePercentage(evaluationInitiatedCountBusiness.length, evaluationInitiatedCount.length);
                let getEvaluationIntiSalesPer = await calculatePercentage(evaluationInitiatedCountSales.length, evaluationInitiatedCount.length);
                setEvaluationInitiatedCountPer(getEvaluationIntiBusinessPer); setEvaluationInitiatedSalesCountPer(getEvaluationIntiSalesPer)
            } else {
                setEvaluationInitiatedBusinessCount(0); setEvaluationInitiatedSalesCount(0); setEvaluationInitiatedCountPer(0); setEvaluationInitiatedSalesCountPer(0);
            }

        } else {
            SetEmployeeEvaluationList([]); setEligibleEmployeeCount(0); setEvaluationInitiatedCount(0); setEligibleEmployeeBusinessCount(0); setEligibleEmployeeSalesCount(0);
            setEvaluationInitiatedBusinessCount(0); setEvaluationInitiatedSalesCount(0); setEligibleEmployeeBusinessCountPer(0); setEvaluationInitiatedCountPer(0);
        }
        getChangeRequestResponse(employee.value, financialYear.value)
    }

    //onClick Quarter
    const handleClickQuarterDropdown = async (event) => {
        setQuarter(event)
        //*get bind Evaluation data
        const evaluationData = await evaluationFilter(employee.value, event.value, financialYear.value, sBU.value)
        if (evaluationData.length > 0) {
            SetEmployeeEvaluationList(evaluationData)

            var eligibleEmployeeCount = evaluationData.filter((e) => e.eligible == "Yes")
            setEligibleEmployeeCount(eligibleEmployeeCount.length)

            var evaluationInitiatedCount = evaluationData.filter((e) => e.initiated == "Yes")
            setEvaluationInitiatedCount(evaluationInitiatedCount.length)

            if (eligibleEmployeeCount.length > 0) {

                var eligibleEmployeeCountBusiness = eligibleEmployeeCount.filter((e) => e.teamType == TeamType.B)
                setEligibleEmployeeBusinessCount(eligibleEmployeeCountBusiness.length)

                var eligibleEmployeeCountSales = eligibleEmployeeCount.filter((e) => e.teamType == TeamType.S)
                setEligibleEmployeeSalesCount(eligibleEmployeeCountSales.length)

                let getEligibleEmployeeBusinessPer = await calculatePercentage(eligibleEmployeeCountBusiness.length, eligibleEmployeeCount.length);
                let getEligibleEmployeeSalesPer = await calculatePercentage(eligibleEmployeeCountSales.length, eligibleEmployeeCount.length);
                setEligibleEmployeeBusinessCountPer(getEligibleEmployeeBusinessPer); setEligibleEmployeeSalesCountPer(getEligibleEmployeeSalesPer)

            } else {
                setEligibleEmployeeBusinessCount(0); setEligibleEmployeeSalesCount(0); setEligibleEmployeeBusinessCountPer(0); setEligibleEmployeeSalesCountPer(0);
            }

            if (evaluationInitiatedCount.length > 0) {

                var evaluationInitiatedCountBusiness = evaluationInitiatedCount.filter((e) => e.teamType == TeamType.B)
                setEvaluationInitiatedBusinessCount(evaluationInitiatedCountBusiness.length)

                var evaluationInitiatedCountSales = evaluationInitiatedCount.filter((e) => e.teamType == TeamType.S)
                setEvaluationInitiatedSalesCount(evaluationInitiatedCountSales.length)

                let getEvaluationIntiBusinessPer = await calculatePercentage(evaluationInitiatedCountBusiness.length, evaluationInitiatedCount.length);
                let getEvaluationIntiSalesPer = await calculatePercentage(evaluationInitiatedCountSales.length, evaluationInitiatedCount.length);
                setEvaluationInitiatedCountPer(getEvaluationIntiBusinessPer); setEvaluationInitiatedSalesCountPer(getEvaluationIntiSalesPer)
            } else {
                setEvaluationInitiatedBusinessCount(0); setEvaluationInitiatedSalesCount(0); setEvaluationInitiatedCountPer(0); setEvaluationInitiatedSalesCountPer(0);
            }

        } else {
            SetEmployeeEvaluationList([]); setEligibleEmployeeCount(0); setEvaluationInitiatedCount(0); setEligibleEmployeeBusinessCount(0); setEligibleEmployeeSalesCount(0);
            setEvaluationInitiatedBusinessCount(0); setEvaluationInitiatedSalesCount(0); setEligibleEmployeeBusinessCountPer(0); setEvaluationInitiatedCountPer(0);
        }
        getChangeRequestResponse(employee.value, financialYear.value)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="flex justify-center gap-8">
                    <Link onClick={() => {
                        reactLocalStorage.setObject('selectedEmployeeOnEvaluation', { "value": rowData.id, "label": rowData.name });
                    }}
                        to={"/employee/dashboard/business/performance/"}
                    ><ViewButton /></Link>
                </div>
            </React.Fragment>
        );
    }

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            <div className="dashboard-main-wrapper dashboard-new pl-16" x-data="{tab: 1}">
                <div className="p-5 pb-0">
                    <div className="sm:flex items-center space-x-8">
                        <div className="text-2xl font-semibold dark:text-white">Dashboard</div>
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link className="active">Business</Link></li>
                                {!checkEmployeeTeamType() && <li><Link to="/employee/dashboard/sales/planning">Sales</Link></li>}
                            </ul>
                        </div>
                    </div>
                    <div className="lg:flex justify-between my-6 items-center align-center">
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link to="/employee/dashboard/business/planning" >Planning</Link></li>
                                <li><Link to="/employee/dashboard/business/evaluation" className="active">Evaluation</Link></li>
                                <li><Link onClick={() => {
                                    reactLocalStorage.setObject('selectedEmployeeOnEvaluation', { "value": 0, "label": "All Employee" });
                                }} to="/employee/dashboard/business/performance">Performance</Link></li>
                            </ul>
                        </div>
                        <div className="mt-5 lg:mt-0 flex justify-end gap-2 items-center">
                            <div className="">
                                <Select
                                    options={employeeList}
                                    value={employee}
                                    onChange={e => {
                                        handleClickEmployeeDropdown(e);
                                    }}
                                    maxMenuHeight={200}
                                    placeholder="Employee Name"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                            {/* <div className="">
                                <Select
                                    options={regionList}
                                    value={region}
                                    onChange={e => { setRegion(e) }}
                                    maxMenuHeight={200} placeholder="Region"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div> */}
                            {/* <div className="">
                                <Select
                                    options={bUList}
                                    value={bU}
                                    onChange={e => { setBU(e) }}
                                    maxMenuHeight={200} placeholder="BU"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div> */}
                            <div className="">
                                <Select
                                    options={sBUList}
                                    value={sBU}
                                    onChange={e => {
                                        handleClickSBUDropdown(e)
                                    }}
                                    maxMenuHeight={200} placeholder="SBU"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>

                            <div className="">
                                <Select
                                    options={financialYearList}
                                    value={financialYear}
                                    onChange={e => {
                                        handleClickFinanCialYearDropdown(e);
                                    }}
                                    maxMenuHeight={200} placeholder="Select Year"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                            <div className="w-50">
                                <Select
                                    options={quarterList}
                                    value={quarter}
                                    onChange={(e) => {
                                        setQuarter(e)
                                        handleClickQuarterDropdown(e);
                                    }}
                                    maxMenuHeight={200} placeholder="Select Quarter"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                        </div>
                    </div>


                    <div className='mt-10 flex-row grid 2xl:grid-cols-6 xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-1  gap-3 mb-10'>

                        <div className='bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-5 dark:text-white'>Eligible Employee</h6>
                            <div className='flex gap-3'>
                                <div className='min-h-[30px] mx-auto' style={{ width: 120, height: 120, }}>
                                    <CircularProgressBar value={eligibleEmployeeBusinessCount === 0 && eligibleEmployeeSalesCount === 0 ? 50 : eligibleEmployeeBusinessCountPer} text={eligibleEmployeeBusinessCount} pathColor="#2D638C" trailColor="#9FBED6" />
                                </div>
                                <div className=''>
                                    <div className='bg-[#F9FAFB] p-2  border-b-4  border-[#2D638C] rounded-b-md mb-2 dark:bg-[#171717]'>
                                        <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                        <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>{eligibleEmployeeBusinessCount}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>{eligibleEmployeeBusinessCountPer}%</span></h3>
                                    </div>
                                    <div className='bg-[#F9FAFB] p-2 border-b-4  border-[#9FBED6] rounded-b-md mb-3 dark:bg-[#171717]'>
                                        <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                        <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>{eligibleEmployeeSalesCount}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>{eligibleEmployeeSalesCountPer}%</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-5 dark:text-white'>Evaluation Initiated</h6>
                            <div className='flex gap-3'>
                                <div className='min-h-[30px] mx-auto' style={{ width: 120, height: 120, }}>
                                    <CircularProgressBar value={evaluationInitiatedBusinessCount === 0 && evaluationInitiatedSalesCount === 0 ? 50 : evaluationInitiatedBusinessCountPer} text={evaluationInitiatedCount} pathColor="#F76208" trailColor="#F5DCCC" />
                                </div>
                                <div className=''>
                                    <div className='bg-[#F9FAFB] p-2 border-b-4 rounded-b-md border-[#F76208] mb-2 dark:bg-[#171717]'>
                                        <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                        <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>{evaluationInitiatedBusinessCount}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>{evaluationInitiatedBusinessCountPer}%</span></h3>
                                    </div>
                                    <div className='bg-[#F9FAFB] p-2 border-b-4 rounded-b-md border-[#F5DCCC] mb-1 dark:bg-[#171717]'>
                                        <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                        <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>{evaluationInitiatedSalesCount}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>{evaluationInitiatedSalesCountPer}%</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='bg-[#fff] p-3 dashboard-box-shadow dark:bg-[#2A2E32]'>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-5 dark:text-white'>Manager Review Pending</h6>
                            <div className='flex gap-3'>
                                <div className='min-h-[30px] mx-auto' style={{ width: 120, height: 120, }}>
                                    <CircularProgressBar value="50" text="800" pathColor="#EAADF3" trailColor="#AB4ABA" />
                                </div>
                                <div className=''>
                                    <div className='bg-[#F9FAFB] p-1.5  border-b-4 rounded-b-md border-[#AB4ABA] mb-3  dark:bg-[#171717]'>
                                        <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Brand</p>
                                        <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>550<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>70%</span></h3>
                                    </div>
                                    <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#EAADF3] mb-1 dark:bg-[#171717]'>
                                        <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                        <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>250<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>30%</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className='bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-5 dark:text-white'>Manager Review Complete</h6>
                            <div className='flex gap-3'>
                                <div className='min-h-[30px] mx-auto' style={{ width: 120, height: 120, }}>
                                    <CircularProgressBar value="50" text="250" pathColor="#F3D8B2" trailColor="#FF9500" />
                                </div>
                                <div className=''>
                                    <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#FF9500] mb-3 dark:bg-[#171717]'>
                                        <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Brand</p>
                                        <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>550<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>70%</span></h3>
                                    </div>
                                    <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#F3D8B2] mb-1 dark:bg-[#171717]'>
                                        <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                        <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>250<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>30%</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className='bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]' >
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-5 dark:text-white'>Override Actuals Request</h6>
                            <div className='flex gap-3'>
                                <div className='min-h-[30px] mx-auto' style={{ width: 120, height: 120, }}>
                                    <CircularProgressBar value={totalBusinessInPer} text={totalOverrideActualsRequest} pathColor="#146A60" trailColor="#CCEBE7" />
                                </div>
                                <div className='mt-3'>
                                    <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md  border-[#146A60] mb-3 dark:bg-[#171717]'>
                                        <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                        <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>{businessCount}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>{totalBusinessInPer}%</span></h3>
                                    </div>
                                    <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#CCEBE7] mb-1 dark:bg-[#171717]'>
                                        <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                        <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>{salesCount}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>{totalSalesInPer}%</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white '>Company/ SBU Actual</h6>

                            <div className='mt-3 text-center'>
                                <div className='bg-[#E5F3EC] p-6 mb-3 rounded-md flex items-center gap-2 justify-center mt-14'>
                                    <h2 className='w-3 h-3 bg-[#029046] rounded-full'></h2>
                                    <p className='text-[#029046] text-[16px] font-medium my-2'>
                                        Completed</p>

                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="bg-white rounded-lg mt-10 p-3 px-3 dark:bg-[#2A2E32] mt-15  border border-[#E4E7EC] dark:border-[#344054]">
                        <div>
                            <div className=" rounded-lg mt-5 ">
                                <DataTable paginator value={employeeEvaluationList} responsiveLayout="scroll"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={50} rowsPerPageOptions={[50, 100, 150]}
                                >
                                    <Column field="employeeId" header="Emp ID" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                    <Column field="name" header="Name" sortable filter filterPlaceholder="Search by Name"></Column>
                                    <Column field="reportingManager" header="Reporting Manager" sortable filter filterPlaceholder="Search by Reporting Manager"></Column>
                                    <Column field="eligible" header="Eligible" sortable filter filterPlaceholder="Search by Role"></Column>
                                    <Column field="initiated" header="Initiated" sortable filter filterPlaceholder="Search by Vertical"></Column>
                                    <Column field="totalWeightage" header="Total Weightage" sortable filter filterPlaceholder="Search by SBU"></Column>
                                    <Column field='wtWeightage' header="Wt. Achievement" filter filterPlaceholder="Search by Submitted Date" ></Column>
                                    <Column field='status' header="Status" filter  ></Column>
                                    <Column alignHeader={'center'} header="Action" body={actionBodyTemplate} ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>




                </div>
            </div>

            <div id="selector"></div>


        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}