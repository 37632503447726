import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Avatar } from "@mui/material";
import { Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import UserImg from '../../assets/images/user.png';
import LeftSideBar from '../../components/common/employee/leftnav.component';
import TopNavBar from '../../components/common/employee/topnav.component';
import { ObjectToArray } from '../../components/helper/commonfunctions';
import { ValidateEmployeeRole } from "../../components/helper/validateRole";
import { Employee } from '../../models';
import EmpLogin from "../../setup/auth/emp.component";
import { reactLocalStorage } from 'reactjs-localstorage';

// import { useMsal, useIsAuthenticated } from "@azure/msal-react";
// import { callMsGraph, loginRequest, graphConfig } from "../../authConfig";

export default function EmployeeReports() {

    const [isAuthenticated, userHasAuthenticated] = useState(true);
    // const isMicrosoftAuthenticated = useIsAuthenticated();
    // const { instance, accounts } = useMsal();
    const [employeeList, SetEmployeeList] = useState([]);

    //chat window
    const [fullName, setFullName] = useState("");
    const [profilePic, setProfilePic] = useState("");
    const [text, setText] = useState("");

    const doAuth = () => {
        // instance.loginRedirect(loginRequest).catch(e => {
        //     console.log(e);
        // });
    }

    const doAuthLogout = () => {
        // instance.logoutRedirect({
        //     postLogoutRedirectUri: 'http://localhost:3000/employee/message',
        // });
    }


    const doLoadUsers = () => {
        // instance.acquireTokenSilent({
        //     ...loginRequest,
        //     account: accounts[0]
        // }).then((response) => {
        //     console.log(response)
        //     callMsGraph(response.accessToken, graphConfig.graphMeAllUsers).then(currentUsers => {
        //         if (currentUsers != null) {
        //             console.log(currentUsers);
        //             SetEmployeeList(currentUsers.value);
        //         }

        //     });
        // });
    }

    useEffect(() => {
        // loadEmployees()
        onLoad()
        const el = document.getElementById('messages')
        el.scrollTop = el.scrollHeight
        onLoad();
    }, []);


    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    function handleOnEnter(text) {
        console.log("enter", text);
    }

    //load employee
    async function loadEmployees() {

        let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')

        var employeeResponse = await DataStore.query(Employee, (c) => c.id.notContains(loggedEmployeeId), {
            sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
        });

        if (employeeResponse.length > 0) {
            var newResponse = [];
            Object.keys(employeeResponse).map(async (currentItem, i) => {
                let profilePic = ""
                let loopEmployeeItem = employeeResponse[currentItem];
                let data = ObjectToArray(loopEmployeeItem);
                await Storage.get(employeeResponse[currentItem]["profilePic"]).then(result => { profilePic = result });
                data['profilePic'] = profilePic


                newResponse.push(data);
            })
            setTimeout(() => {
                SetEmployeeList(newResponse)
                //set fullName and profile pic
                setFullName(newResponse[0].firstName + " " + newResponse[0].lastName)
                setProfilePic(newResponse[0].profilePic)
            }, 500);
        }

    }

    //onclick employee
    async function onclickEmployees(firstName, lastName, profile) {
        setFullName(firstName + " " + lastName)
        setProfilePic(profile)
    }


    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            <div className="pl-16 dashboard-main-wrapper chat-wrapper" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="items-center space-x-8 sm:flex">
                        <div className="text-2xl font-semibold dark:text-white">Messages</div>
                    </div>


                    {/* {isMicrosoftAuthenticated === true
                        ?
                        <div>
                            <button onClick={() => doLoadUsers()} className="border bg-[#FFF]  py-1.5 px-5 h-10 text-[#029046] text-[15px] ml-2 rounded-md active addClass dark:border-[#029046]"><i className='pi pi-refresh'></i>  Refresh</button>
                            <button onClick={() => doAuthLogout()} className="border bg-[#FFF]  py-1.5 px-5 h-10 text-[#029046] text-[15px] ml-2 rounded-md active addClass dark:border-[#029046]"><i className='pi pi-sign-out mr-1'></i> Logout</button>
                        </div>
                        :
                        <button onClick={() => doAuth()} className="border bg-[#029046] py-1.5 px-5 h-10 text-white text-[15px] rounded-md active addClass dark:border-[#029046]"><i className='pi pi-plus'></i>  Login</button>
                    } */}

                </div>
                <div className='px-5 pt-2 pb-0'>
                    <div className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-2 align-center 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-1 setting-wrapper">

                        <div className="col-span-4">
                            <div className="p-2 px-4 bg-white border border-[#E4E7EC] rounded-lg dark:bg-[#2A2E32] dark:border-[#414548]">
                                <div className="tabs_chat mt-5 sm:mt-0">
                                    <ul className="flex mb-3 mt-1">
                                        {/* <li><Link className='active' >User</Link></li> */}

                                    </ul>
                                    <div className=" chat-list scroll-smooth scroll-pr-px overflow-auto h-[30rem]  xl:h-[30rem] lg:h-[26rem] 2xl:[35rem]">
                                        {
                                            employeeList.map((item) => {
                                                return (
                                                    <Link key={item.id} onClick={() => {
                                                        onclickEmployees(item.givenName, item.surname, "")
                                                    }} className='mt-3' to="">
                                                        <div className='bg-[#FCFCFD] rounded-md shadow-chatbox p-3 mb-2 dark:bg-[#44494E] dark:text-[#FFFFFF]'>
                                                            <div className='flex flex-1 gap-3'>
                                                                <div className='relative w-16'>
                                                                    <Avatar
                                                                        alt={item.givenName + "" + item.surname}
                                                                        src="" />
                                                                    <div className='absolute right-0 bottom-1 h-3 w-3 bg-[#36CE00] border border-[#fff] rounded-full'></div>
                                                                </div>
                                                                <div className='w-full'>
                                                                    <div className='flex justify-between'>
                                                                        <h3 className="chatname">{item.givenName + " " + item.surname}</h3>
                                                                        <div className='time'>12:35 PM</div>
                                                                    </div>
                                                                    <p className='text-[#98A2B3] text-[12px]'>Lorem Ipsum is simply dummy text of the....</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-span-8">
                            <div className="p-3 px-3 bg-white border border-[#E4E7EC] rounded-lg dark:bg-[#2A2E32] dark:border-[#414548]">
                                <div className="chat-header">
                                    <div className='flex justify-between gap-5'>
                                        <div className='flex gap-3'>
                                            <Avatar
                                                alt={fullName}
                                                src={profilePic} />
                                            <div> <h5>{fullName}</h5>
                                                <h6>online</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Link to='' x-data="{ open: false }"> <i className="oicon option_icon"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat-body" id="messages">
                                    <div className="relative w-full p-2 overflow-y-auto h-[24rem]  xl:h-[24rem] lg:h-[20rem] 2xl:[29rem]">
                                        <div className="flex flex-wrap flex-row justify-evenly space-x-2 w-100 mb-4 px-32 mt-5">
                                            <span className="bg-[#C6CBD2]  h-px flex-grow t-2 relative mt-3 dark:bg-[#464b50]"></span>
                                            <span className="flex-none bg-[#E4E7EC] text-[#667085] px-2.5 py-1 rounded-full text-[12px] dark:bg-[#353535] ">Today</span>
                                            <span className="bg-[#C6CBD2] h-px flex-grow t-2 relative mt-3  dark:bg-[#464b50]"></span>
                                        </div>

                                        <ul className="space-y-2">
                                            <li>
                                                <div className="flex justify-start">
                                                    <div className="relative max-w-xl px-4 py-2 text-gray-700 receive-message">
                                                        <span className="block ">Lorem Ipsum is simply dummy text <i className="checkmarkicon readmark"></i></span>
                                                    </div>
                                                </div>
                                                <div className='time flex justify-start mt-1'>12.45 PM</div>
                                            </li>
                                            <li>
                                                <div className="flex justify-start">
                                                    <div className="relative max-w-xl px-4 py-2 text-gray-700 receive-message">
                                                        <span className="block ">Lorem Ipsum is simply dummy text <i className="checkmarkicon readmark"></i></span>
                                                    </div>
                                                </div>
                                                <div className='time flex justify-start mt-1'>12.20 PM</div>
                                            </li>
                                            <li>
                                                <div className="flex justify-end">
                                                    <div className="relative max-w-xl px-4 py-2 text-gray-700 send-message">
                                                        <span className="block ">Lorem Ipsum is simply dummy text of the printing and typesetting industry <i className="checkmarkicon readmark_white"></i></span>
                                                    </div>
                                                </div>
                                                <div className='time flex justify-end mt-2'>12.20 PM</div>
                                            </li>
                                            <li>
                                                <div className="flex justify-start">
                                                    <div className="relative max-w-xl px-4 py-2 text-gray-700 receive-message">
                                                        <span className="block ">Lorem Ipsum is simply dummy text <i className="checkmarkicon readmark"></i></span>
                                                    </div>
                                                </div>
                                                <div className='time flex justify-start mt-1'>12.20 PM</div>
                                            </li>
                                            <li>
                                                <div className="flex justify-start">
                                                    <div className="relative max-w-xl px-4 py-2 text-gray-700 receive-message">
                                                        <span className="block ">Lorem Ipsum is simply dummy text of the printing and typesetting industry. <i className="checkmarkicon readmark"></i></span>
                                                    </div>
                                                </div>
                                                <div className='time flex justify-start mt-1'>12.20 PM</div>
                                            </li>
                                            <li>
                                                <div className="flex justify-end">
                                                    <div className="relative max-w-xl px-4 py-2 text-gray-700 send-message">
                                                        <span className="block ">Lorem Ipsum is simply dummy text of the printing and typesetting industry <i className="checkmarkicon readmark_white"></i></span>
                                                    </div>
                                                </div>
                                                <div className='time flex justify-end mt-2'>12.20 PM</div>
                                            </li>
                                            <li>
                                                <div className="flex justify-end">
                                                    <div className="relative max-w-xl px-4 py-2 text-gray-700 send-message">
                                                        <span className="block ">Lorem Ipsum  <i className="checkmarkicon readmark_white"></i></span>
                                                    </div>
                                                </div>
                                                <div className='time flex justify-end mt-2'>12.20 PM</div>
                                            </li>
                                            <li>
                                                <div className="flex justify-start">
                                                    <div className="relative max-w-xl px-4 py-2 text-gray-700 receive-message">
                                                        <span className="block ">Lorem Ipsum is simply dummy text of the printing and typesetting industry. <i className="checkmarkicon readmark"></i></span>
                                                    </div>
                                                </div>
                                                <div className='time flex justify-start mt-1'>12.20 PM</div>
                                            </li>
                                            <li>
                                                <div className="flex justify-start">
                                                    <div className="relative max-w-xl px-4 py-2 text-gray-700 receive-message">
                                                        <span className="block ">Lorem Ipsum is simply dummy  <i className="checkmarkicon readmark"></i></span>
                                                    </div>
                                                </div>
                                                <div className='time flex justify-start mt-1'>12.20 PM</div>
                                            </li>


                                        </ul>
                                    </div>
                                    <div className="flex items-center justify-between w-full p-2 gap-2 msg-send-section">
                                        <div className="relative w-11/12">
                                            <button className="absolute right-[21px] top-[6px]">
                                                <i className="icon attach_icon"></i>
                                            </button>
                                        </div>
                                        <button className="media">
                                            <i className="icon chat_mic_icon"></i>
                                        </button>
                                        <button className="media">
                                            <i className="icon chat_video_icon"></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>





                </div>
            </div>

        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}



