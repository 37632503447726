import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Brand, Region, SBU } from '../../../../../models'
import HRLogin from '../../../../../setup/auth/hr.component';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Auth } from 'aws-amplify';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import EditButton from '../../../../../components/common/buttons/EditButton';
import DeleteButton from '../../../../../components/common/buttons/DeleteButton';
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import { ConvertResponseForId } from '../../../../../components/helper/commonfunctions'

import { DataTableCustomStyles } from '../../../../../components/helper/dataTable.config';
import ImportDimension from '../../../../../components/common/import/dimensionsimport.component';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

export default function SBUList() {

    let navigateTo = useNavigate();
    const [checkSbuIDList, setCheckSbuIDList] = useState([]);

    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/hr/setting/dimensions/sbu/createsbu/' + id);
    };

    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        // const objDeleteSBU = await DataStore.query(SBU, id);
        const objDeleteSBU = await graphQLFindRecordById('getSBU', id);

        alertify.confirm('Confirmation', 'Do you want to delete this record?', async function () {
            // DataStore.delete(objDeleteSBU);

            await API.graphql({
                query: mutations.deleteSBU,
                variables: {
                    input: {
                        id: objDeleteSBU.id,
                        _version: objDeleteSBU._version,
                    }
                }
            });
            BindList();
            toast.success("Deleted");
        }
            , function () { });

    };


    const DataTableColumns = [
        {
            name: 'SBU',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Ref Code',
            selector: row => row.REF_CODE,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <div className="flex gap-8">
                        <Link onClick={(e) => handleEditClick(e, row.id)} ><EditButton /></Link>
                        {
                            ConvertResponseForId(checkSbuIDList, row.id) === row.id ?
                                <Link className="disabledCursor opacity-50"  ><DeleteButton /></Link> :
                                <Link className="notDisabled" onClick={(e) => handleDeleteClick(e, row.id)} ><DeleteButton /></Link>
                        }
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true
        }
    ];



    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //State
    const [sbuList, setSbuList] = useState([]);
    const [sbuFilterList, setSbuFilterList] = useState([]);
    const [isShowImportPopup, setIsShowImportPopup] = useState(false);

    //Search Employee
    const searchSbu = (searchedText) => {
        if (searchedText.length > 1) {
            let data = [...sbuFilterList]
            let filteredData = data.filter(e =>
                e.name.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.REF_CODE.toLowerCase().includes(searchedText.toLowerCase())
            )
            setSbuList(filteredData)
        } else {
            let data = [...sbuFilterList]
            setSbuList(data)
        }
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    async function BindList() {
        // var SBUResponses = await DataStore.query(SBU, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        var SBUResponses = await graphQLGetAllData('listSBUS');
        SBUResponses = SBUResponses.sort((a, b) => a.name.localeCompare(b.name));
        setSbuList(SBUResponses)
        setSbuFilterList(SBUResponses)

        //brand data for delete enable
        let brandLength = []
        let regionLength = []
        // var brandResponses = await DataStore.query(Brand);
        var brandResponses = await graphQLGetAllData('listBrands');

        brandResponses.map((response) => {
            brandLength.push({ id: response.SBU_id })
        })
        //region data for delete enable
        //var regionResponses = await DataStore.query(Region);
        var regionResponses = await graphQLGetAllData('listRegions');

        regionResponses.map((response) => {
            regionLength.push({ id: response.SBU_id })
        })
        //concat both array
        const mainArray = brandLength.concat(regionLength);
        setCheckSbuIDList(mainArray)

    }

    //onClick Close popup
    async function onClickCloseImportPopup() {
        debugger;
        setIsShowImportPopup(false);
        BindList();
    }

    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    return (
        isAuthenticated ? (

            <div>
                <div className="pt-16">
                    <TopNavBar />
                    <LeftSideBar />
                    <div className="dashboard-main-wrapper pl-20 pr-4 mt-3">
                        <div className="flex flex-1 flex-wrap p-5 pb-4 px-0 top-action-wrapper">
                            <div className="md:flex xs:w-full sm:w-full md:w-auto flex-row align-center">
                                <div className="md:flex block md:col-span-2 mb-4">
                                </div>
                                <div className="md:flex block mb-4">
                                    <h2 className="page-title">SBUs</h2>
                                </div>
                            </div>

                            <div className="md:flex flex-1 flex-row xs:w-full sm:w-full md:w-auto align-center justify-end md:ml-auto gap-3 right-side">
                                <div className="relative">
                                    <input onChange={(e) => searchSbu(e.target.value)} type="text" id="required-search" className="rounded-md border border-[#D0D5DD] focus:border-transparent w-full py-2.5 pl-3 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#403E3C] dark:text-[#FFFFFF] dark:border-[#403E3C]" placeholder="Search..." />
                                </div>
                                <div className="md:flex block mb-4 gap-4">
                                    <Link to="/hr/settings" className="flex py-2 px-5 bg-[#FFF] hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-black transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] dark:border-[#4A5056] dark:bg-[#4A5056] dark:text-white">
                                        Back
                                    </Link>
                                    <Link onClick={() => {
                                        setIsShowImportPopup(true);
                                    }} className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] bg-black">
                                        Import
                                    </Link>
                                    <Link to="/hr/setting/dimensions/sbu/createsbu" className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] bg-black">
                                        Create
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div>

                            <div className={"block w-full pt-5"}>
                                {
                                    <DataTable
                                        columns={DataTableColumns}
                                        data={sbuList}
                                        customStyles={DataTableCustomStyles}
                                        pagination
                                        highlightOnHover
                                        paginationPerPage={10}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    {
                        isShowImportPopup &&
                        <ImportDimension
                            closePopup={onClickCloseImportPopup}
                            type={"SBU"}
                        />
                    }
                </div>
            </div>

        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}



