import { DataStore } from '@aws-amplify/datastore';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { reactLocalStorage } from 'reactjs-localstorage';
import BoxPlanning from '../../../../assets/images/dashboard_box_planning.png';
import LeftNav from '../../../../components/common/employee/leftnav.component';
import TopNav from '../../../../components/common/employee/topnav.component';
import NoDataFound from '../../../../components/common/nodatafound';
import { ConvertResponseForSelect, ObjectToArray } from '../../../../components/helper/commonfunctions';
import { EVALUATION_STATUS } from '../../../../components/helper/enum';
import { EvaluationStatus } from '../../../../components/helper/getEvaluationStatus';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { Evaluation, FinancialYear, InitiateEvaluation, Employee, Targets, TargetDetails, Parameters } from "../../../../models";
import EmpLogin from "../../../../setup/auth/emp.component";
import ProgressBac_CircularProgressWithChildren from '../../../../components/common/progressbar/CircularProgressbarWithChildren';
import { PARAMETERS_TYPES_NAMES } from "../../../../components/helper/enum";
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../src/components/helper/graphQLFunctions'
import { listEvaluations, listFinancialYears, listTargetDetails, listTargets } from '../../../../graphql/queries';


export default function EvaluationSelfHistorical() {
    const navigateTo = useNavigate()

    let isReportingManager = reactLocalStorage.get('isReportingManager')
    var loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId');


    const [financialYear, setFinancialYear] = useState("");
    const [financialYearList, setFinancialYearList] = useState([]);

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //list
    const [evaluationList, setEvaluationList] = useState([]);
    const [allFinancialYearList, setAllFinancialYearList] = useState("");

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    const onFinancialSelectChange = async (e) => {
        setFinancialYear({ label: e.label, value: e.value })
        reactLocalStorage.setObject('selectedYear', e);
        getHistoricalList(e)
    }

    async function BindFinancialYearList() {
        var selectedYear = reactLocalStorage.getObject('selectedYear');
        if (selectedYear.value !== "") {
            setFinancialYear(selectedYear)
            getHistoricalList(selectedYear)
        } else {
            let date = moment().subtract(1, "year").format("YYYY")
            // const previousFinancialYear = await DataStore.query(FinancialYear, (c) => c.toDate.contains(date));
            const previousFinancialYear = await graphQLGetAllData('listFinancialYears', [{ toDate: { contains: date } }]);

            setFinancialYear({ label: previousFinancialYear[0].name, value: previousFinancialYear[0].id })
            getHistoricalList({ label: previousFinancialYear[0].name, value: previousFinancialYear[0].id })
        }


        //set Financial Year
        // const financialYearList = await DataStore.query(FinancialYear);
        const financialYearList = await graphQLGetAllData('listFinancialYears')
        let allFinalYearData = [];
        for (var i = 0; i < financialYearList.length; i++) {
            let data = {
                "id": financialYearList[i].id,
                "fromDate": financialYearList[i].fromDate,
                "toDate": financialYearList[i].toDate,
                "name": financialYearList[i].name,
                "FinancialYearStart": moment(financialYearList[i].toDate).format('YYYY'),
            }
            allFinalYearData.push(data)

            if (i === financialYearList.length - 1) {
                let sortedArray = allFinalYearData.sort(function (a, b) { return a.FinancialYearStart - b.FinancialYearStart });
                var myArray = []; myArray = ConvertResponseForSelect(sortedArray);
                var myArrayForAllYears = []; myArrayForAllYears = ConvertResponseForSelect(sortedArray);
                setFinancialYearList(myArray);
                setAllFinancialYearList(myArrayForAllYears);
            }
        }
    }

    const getHistoricalList = async (financialYear) => {
        // var currentYearResponses = await DataStore.query(FinancialYear, financialYear.value);
        var currentYearResponses = await graphQLFindRecordById('getFinancialYear', financialYear.value);

        // var evaluationResponses = await DataStore.query(Evaluation, (c) => c.and(c => [
        //     c.financial_year_id.eq(currentYearResponses.id),
        //     // c.id.eq(data["evaluation_id"]),
        // ]));
        var evaluationResponses = await graphQLGetAllData('listEvaluations', [{ financial_year_id: { eq: currentYearResponses.id } }]);


        if (evaluationResponses.length > 0) {
            var newResponse = [];
            for (var j = 0; j < evaluationResponses.length; j++) {
                let getStatus = EvaluationStatus(moment(evaluationResponses[j].startDate).format("YYYY-MM-DD"), moment(evaluationResponses[j].endDate).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))
                // var financialYearResponses = await DataStore.query(FinancialYear, evaluationResponses[j].financial_year_id);
                 var financialYearResponses = await graphQLFindRecordById('getFinancialYear', evaluationResponses[j].financial_year_id);


                let getQValue = await getHistoricalCount(evaluationResponses[j]["financial_year_id"], evaluationResponses[j]["quaterNo"])

                let data = {
                    "financial_year_id": evaluationResponses[j]["financial_year_id"],
                    "quarterName": "Quarter" + " " + evaluationResponses[j]["quaterNo"],
                    "quaterNo": evaluationResponses[j]["quaterNo"],
                    "startDate": evaluationResponses[j]["startDate"],
                    "endDate": evaluationResponses[j]["endDate"],
                    "status": getStatus,
                    "financial_year_name": financialYearResponses.name,
                    "qPer": getQValue,
                }
                newResponse.push(data)

                if (j === evaluationResponses.length - 1) {
                    let sortArray = newResponse.sort((a, b) => (b.quaterNo > a.quaterNo ? 1 : -1))
                    setEvaluationList(sortArray)
                }
            }
        } else {
            setEvaluationList([])
        }
        // var checkInitEvaluation = await DataStore.query(InitiateEvaluation, (c) => c.employee_id.eq(loggedEmployeeId));
        // if (checkInitEvaluation.length > 0) {
        //     var newResponse = [];
        //     Object.keys(checkInitEvaluation).map(async (currentItem, i) => {

        //         let loopItem = checkInitEvaluation[currentItem];
        //         let data = ObjectToArray(loopItem);
        //         //data["evaluation_id"]

        //         var evaluationResponses = await DataStore.query(Evaluation, (c) => c.and(c => [
        //             c.financial_year_id.eq(financialYear.value),
        //             c.id.eq(data["evaluation_id"]),
        //         ]));
        //         if (evaluationResponses.length > 0) {

        //             data["financial_year_id"] = evaluationResponses[0]["financial_year_id"]
        //             data["quarterName"] = "Quarter" + " " + evaluationResponses[0]["quaterNo"]
        //             data["quaterNo"] = evaluationResponses[0]["quaterNo"]
        //             data["startDate"] = evaluationResponses[0]["startDate"]
        //             data["endDate"] = evaluationResponses[0]["endDate"]

        //             let getStatus = EvaluationStatus(moment(evaluationResponses[0].startDate).format("YYYY-MM-DD"), moment(evaluationResponses[0].endDate).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))
        //             data["status"] = getStatus

        //             var financialYearResponses = await DataStore.query(FinancialYear, evaluationResponses[0].financial_year_id);
        //             data["financial_year_name"] = financialYearResponses.name
        //             newResponse.push(data)
        //         }

        //         if (i === checkInitEvaluation.length - 1) {
        //             let sortArray = newResponse.sort((a, b) => (b.quaterNo > a.quaterNo ? 1 : -1))
        //             setEvaluationList(sortArray)
        //         }
        //     })
        // }
        // else {
        //     setEvaluationList([])
        // }
    }

    //get quarter value in %
    const getHistoricalCount = async (financialYear, quaterNo) => {

        let validQ1Count = 0;
        let validQ2Count = 0;
        let validQ3Count = 0;
        let validQ4Count = 0;
        let q1 = ""; let q2 = ""; let q3 = ""; let q4 = "";

        // var checkTarget = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.financial_year_id.eq(financialYear),
        //     c.employee_id.eq(loggedEmployeeId),
        //     c.report_manager_employee_id.eq(loggedEmployeeId)
        // ]));

        var checkTarget = await graphQLGetAllData('listTargets', [
        { financial_year_id: { eq: financialYear } },
        { employee_id: { eq: loggedEmployeeId } },
        // { report_manager_employee_id: { eq: loggedEmployeeId } }
    ]);


        if (checkTarget.length > 0) {

            // let currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
            //     c.target_id.eq(checkTarget[0].id),
            // ]));

            let currentTargetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: checkTarget[0].id } }]);

            currentTargetDetails.map((currentItem, index) => {

                //check Q1
                if ((currentItem.m4_actual_value !== null && currentItem.m4_actual_value !== undefined) || (currentItem.m5_actual_value !== null && currentItem.m5_actual_value !== undefined) || (currentItem.m6_actual_value !== null && currentItem.m6_actual_value !== undefined)) {
                    validQ1Count = validQ1Count + 1
                }

                //check Q2
                if ((currentItem.m7_actual_value !== null && currentItem.m7_actual_value !== undefined) || (currentItem.m8_actual_value !== null && currentItem.m8_actual_value !== undefined) || (currentItem.m9_actual_value !== null && currentItem.m9_actual_value !== undefined)) {
                    validQ2Count = validQ2Count + 1
                }

                //check Q3
                if ((currentItem.m10_actual_value !== null && currentItem.m10_actual_value !== undefined) || (currentItem.m11_actual_value !== null && currentItem.m11_actual_value !== undefined) || (currentItem.m12_actual_value !== null && currentItem.m12_actual_value !== undefined)) {
                    validQ3Count = validQ3Count + 1
                }

                //check Q4
                if ((currentItem.m1_actual_value !== null && currentItem.m1_actual_value !== undefined) || (currentItem.m2_actual_value !== null && currentItem.m2_actual_value !== undefined) || (currentItem.m3_actual_value !== null && currentItem.m3_actual_value !== undefined)) {
                    validQ4Count = validQ4Count + 1
                }

                if (index === currentTargetDetails.length - 1) {
                    if (validQ1Count === 0) { q1 = 0 } else { q1 = 100 }
                    if (validQ2Count === 0) { q2 = 0 } else { q2 = 100 }
                    if (validQ3Count === 0) { q3 = 0 } else { q3 = 100 }
                    if (validQ4Count === 0) { q4 = 0 } else { q4 = 100 }
                }
            })
        }
        if (quaterNo === "1") { return q1 } else if (quaterNo === "2") { return q2 } else if (quaterNo === "3") { return q3 } else if (quaterNo === "4") { return q4 }

    }


    useEffect(() => {
        BindFinancialYearList();
        onLoad();
    }, []);

    const selectQuarter = (quarter) => {
        reactLocalStorage.set("selectedTabEvaluation", "Historical")
        reactLocalStorage.setObject("selectedQuarter", { label: quarter.quarterName, value: quarter.quarterNo })
        navigateTo("/employee/evaluation/self/currentyear/report")
        navigateTo(0)
    }

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNav />
            <LeftNav />
            <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="sm:flex items-center space-x-8">
                        <div className="text-2xl font-semibold dark:text-white">Goals Evaluation</div>
                        <div className="tabs_one mt-5 sm:mt-0">
                            {
                                isReportingManager === "0" ?
                                    <ul className="flex">
                                        <li><Link to="/employee/evaluation/self/currentyear" className='active' >Myself</Link></li>
                                    </ul> :
                                    <ul className="flex">
                                        <li><Link to="/employee/evaluation/team/currentyear"  >My Team</Link></li>
                                        <li><Link to="/employee/evaluation/self/currentyear" className='active' >Myself</Link></li>
                                    </ul>
                            }
                        </div>
                    </div>

                    <>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/employee/evaluation/self/currentyear" >Current Year</Link></li>
                                    <li><Link className='active' >Historical</Link></li>
                                </ul>
                            </div>
                            <div className="mt-5 lg:mt-0 flex items-center gap-4">
                                <div className="relative">
                                    <input type="text" id="required-search" className=" rounded-lg border border-[#D0D5DD] focus:border-transparent w-250 py-2.5 pr-5 pl-9 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#2A2E32] dark:text-[#FFFFFF] dark:border-[#4E5668] dark:placeholder-[#ffff]" placeholder="Search..." />
                                    <button type="submit" className="absolute left-3 top-1/2 translate-y-[-50%] dark ">
                                        <i className="icon search_icon "></i>
                                    </button>
                                </div>
                                <div className="w-full min-w-[200px]">
                                    <Select
                                        options={allFinancialYearList}
                                        value={financialYear}
                                        placeholder="Select Financial Year"
                                        onChange={e => { onFinancialSelectChange(e); }}
                                        className="mt-2 mb-2 my-react-select-container"
                                        classNamePrefix="my-react-select"
                                    />
                                </div>
                            </div>
                        </div>

                        {
                            evaluationList.length > 0 ?
                                <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3 gap-2">
                                    {
                                        evaluationList.map((item, index) => {
                                            return (
                                                item.status !== EVALUATION_STATUS[3] ?
                                                    <div key={index.toString()} className="bg-white py-4 rounded-xl dashbord_box_shape boxShadow boxH relative dark:border-[green] dark:text-white dark:bg-[#2A2E32]">
                                                        <div className="grid grid-cols-1">
                                                            <div className="px-4">
                                                                <div className="flex items-center space-x-3 text-[#667085] mb-2">
                                                                    <div className="text-xs border border-[#E4E7EC] rounded p-1 dark:text-[#667085] dark:border-[#667085]">FY: {item.financial_year_name}</div>

                                                                </div>
                                                                <div className="text-2xl font-semibold mb-1">{item.quarterName}</div>
                                                                <div className="text-[#344054] text-xs mb-5 dark:text-[#98A2B3]">Evaluation Period: {moment(item.startDate).format("Do MMM, YY") + " - " + moment(item.endDate).format("Do MMM, YY")}</div>
                                                                {
                                                                    EVALUATION_STATUS[1] === item.status ?
                                                                        <label className=" text-sm px-2 py-1.5 border rounded-md border-[#1B55AF] text-[#1B55AF] dark:border-[green] dark:text-white dark:bg-[#029046]">Status: {item.status}</label>
                                                                        :
                                                                        EVALUATION_STATUS[2] === item.status ?

                                                                            <label className=" text-sm px-2 py-1.5 border rounded-md border-[#FDB022] rounded-md text-[#FDB022]  dark:border-[green] dark:text-white dark:bg-[#029046]">Status: {item.status}</label> :
                                                                            <label className=" text-sm px-2 py-1.5 border rounded-md border-[#029046] text-[#029046] dark:border-[green] dark:text-white dark:bg-[#029046]">Status: {item.status}</label>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="px-4 absolute left-0 bottom-[30px]">
                                                            <div className="">
                                                                <ul className="flex gap-2 items-center">
                                                                    {
                                                                        EVALUATION_STATUS[2] !== item.status ?
                                                                            <li><button type='button' onClick={() => selectQuarter(item)} className="bg-[#029046] py-2.5 px-3 text-white text-[15px] rounded-md active addClass">View</button></li>
                                                                            : ""
                                                                    }
                                                                    {
                                                                        EVALUATION_STATUS[2] !== item.status && EVALUATION_STATUS[3] !== item.status ?
                                                                            <li><button type='button' onClick={() => selectQuarter(item)} className="bg-[#FFFFFF] border border-[#C6CBD2] py-2.5 px-3 text-black text-[15px] rounded-md active addClass dark:bg-[#44494E] dark:text-[#FFFFFF]">Edit</button></li>
                                                                            : ""
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="absolute top-0 right-0 bottom-0 flex items-center dashbord_box_img1">
                                                            <img src={BoxPlanning} alt="" />
                                                        </div>
                                                    </div> :
                                                    <div className="bg-white py-4 rounded-xl boxShadow boxH relative dark:border-[green] dark:text-white dark:bg-[#2A2E32]">
                                                        <div className="grid grid-cols-12">
                                                            <div className="col-span-7">
                                                                <div className="px-4">
                                                                    <div className="flex items-center space-x-3 text-[#667085] mb-2">
                                                                        <div className="text-xs border border-[#E4E7EC] rounded p-1 dark:text-[#667085] dark:border-[#667085]">FY: {item.financial_year_name}</div>
                                                                    </div>
                                                                    <div className="flex items-center gap-1">
                                                                        <div className="text-2xl font-semibold mb-1">{item.quarterName}</div>
                                                                    </div>
                                                                    <div className="text-[#344054] text-xs mb-5 dark:text-[#98A2B3]">Evaluation Period: {moment(item.startDate).format("Do MMM, YY") + " - " + moment(item.endDate).format("Do MMM, YY")}</div>
                                                                    <Link to="" className=" text-sm px-2 py-1.5 border rounded-md border-[#4FB155] text-[#4FB155] ">Status: {item.status}</Link>
                                                                </div>
                                                            </div>
                                                            {
                                                                item.status === EVALUATION_STATUS[3] &&
                                                                <div className="col-span-5 border-l border-[#E4E7EC]">
                                                                    <div className='mx-auto' style={{ width: 120, height: 120 }}>
                                                                        <ProgressBac_CircularProgressWithChildren value={item.qPer} text="Completed" />
                                                                    </div>

                                                                    <div className="flex items-center gap-2 justify-center mt-3">
                                                                        <div>
                                                                            <ul className="flex tag_i">
                                                                            </ul>
                                                                        </div>
                                                                        {/* <div className="text-xs text-[#667085]">{item.team_size} Employee</div> */}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="p-4 mt-1">
                                                            <div className="bg-[#F2F4F7] rounded-lg px-4 py-2 mt-3 flex items-center justify-between dark:border-[green] dark:text-white dark:bg-[#212529]">
                                                                <button type='button' onClick={() => selectQuarter(item)} className="bg-[#fff] rounded border border-[#C6CBD2] p-3 py-2  text-sm text-[#344054] dark:border-[green] dark:text-white dark:bg-[#029046]">View</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                            )
                                        })
                                    }
                                </div>
                                : <NoDataFound />
                        }
                    </>
                </div>
            </div>
        </div>

    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}
