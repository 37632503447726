import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import moment from "moment";
import 'primeicons/primeicons.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { reactLocalStorage } from 'reactjs-localstorage';
import LeftSideBar from '../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../components/common/hr/topnav.component';
import { ConvertResponseForSelect } from '../../../../components/helper/commonfunctions';
import { sumFromArray } from '../../../../components/helper/maths';
import { ValidateHRRole } from '../../../../components/helper/validateRole';
import { Employee, FinancialYear, Planning, SBU, TargetDetails, Targets } from "../../../../models";
import HRLogin from '../../../../setup/auth/hr.component';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../../graphql/queries'
import * as mutations from '../../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../src/components/helper/graphQLFunctions'


export default function ReportsGoalSettingSummary() {

    const [financialYear, setFinancialYear] = useState("");
    const [financialYearList, setFinancialYearList] = useState([]);

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showData, setShowData] = useState(false);

    const [allSBUs, setAllSBUs] = useState([]);
    const [allTargets, setAllTargets] = useState([]);
    const [allTargetDetails, setAllTargetDetails] = useState([]);
    const [isDataLoaded, setDataLoaded] = useState(false);

    async function onLoad() {
        try {
            if (!ValidateHRRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //onclick financial year
    const onFinancialSelectChange = async (e) => {
        reactLocalStorage.setObject('selectedYearEmployee', e);
        setFinancialYear({ label: e.label, value: e.value })
        bindSummaryReport(e.value)
    }


    //get financial year
    const bindList = async () => {
        //set financial year
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        if (Object.keys(selectedYear).length !== 0) {
            setFinancialYear(selectedYear)
            bindSummaryReport(selectedYear.value)
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));

            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

            setFinancialYear({ label: responses[0].name, value: responses[0].id })
            bindSummaryReport(responses[0].id)
        }

        // var responses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var responses = await graphQLGetAllData('listFinancialYears');
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));
        var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYearList(myArray);



    }

    const getMasterData = async () => {
        setDataLoaded(false)
        var sbuResponse = await graphQLGetAllData('listSBUS')
        setAllSBUs(sbuResponse);

        var targetResponses = await graphQLGetAllData('listTargets')
        setAllTargets(targetResponses);

        var targetDetailResponse = await graphQLGetAllData('listTargetDetails')
        setAllTargetDetails(targetDetailResponse);
        setDataLoaded(true)
    }

    useEffect(() => {
        if (isDataLoaded === true) {
            bindList()
            onLoad()
        }
    }, [allTargetDetails, allSBUs]);

    useEffect(() => {
        getMasterData();
    }, [])

    const [summaryReport, setSummaryReport] = useState([]);

    //footer
    const [totalHeadCount_footer, setTotalHeadCount_footer] = useState(0);
    const [avgPlanningCompletion_footer, setAvgPlanningCompletion_footer] = useState(0);
    const [onTimeCompletion_footer, setOnTimeCompletion_footer] = useState(0);

    const bindSummaryReport = async (financialYearId) => {
        setShowData(false)
        let newResponse = []

        // var employeesList = await DataStore.query(Employee);
        var employeesList = await graphQLGetAllData('listEmployees');


        // var getPlan = await DataStore.query(Planning, (c) => c.financial_year_id.eq(financialYearId));
        var getPlan = await graphQLGetAllData('listPlannings', [{ financial_year_id: { eq: financialYearId } }]);

        if (getPlan) {
            try {
                for (var i = 0; i < employeesList.length; i++) {
                    
                    // var sbuResponse = await DataStore.query(SBU, employeesList[i].param_sbu_id)
                    // var sbuResponse = await graphQLFindRecordById('getSBU', employeesList[i].param_sbu_id);
                    var sbuResponse = allSBUs.find(c => c.id === employeesList[i].param_sbu_id)
                   

                    // if (sbuResponse) {
                    if (1) {
                        // var targetResponse = await DataStore.query(Targets, (c) => c.and(c => [
                        //     c.employee_id.eq(employeesList[i].id),
                        //     c.report_manager_employee_id.eq(employeesList[i].id),
                        //     c.financial_year_id.eq(financialYearId)
                        // ]));

                        // var targetResponse = await graphQLGetAllData('listTargets', [
                        //     { employee_id: { eq: employeesList[i].id } },
                        //     // { report_manager_employee_id: { eq: employeesList[i].id } },
                        //     { financial_year_id: { eq: financialYearId } }
                        // ]);

                        var targetResponse = allTargets.filter(c => c.employee_id === employeesList[i].id && c.financial_year_id === financialYearId);

                        // var targetResponse2 = allTargets.find((c)=> 
                        // c.employee_id === employeesList[i].id && !c.report_manager_employee_id.includes(employeesList[i].id) && c.financial_year_id === financialYearId
                        // )

                        if (targetResponse.length) {
                            // var targetRes = await DataStore.query(TargetDetails, (c) => c.target_id.eq(targetResponse[0]["id"]))
                            // var targetRes = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: targetResponse[0]["id"] } }]);
                            var targetRes = allTargetDetails.filter(c =>
                                c.target_id === targetResponse[0]["id"])

                            let checkStatus = targetRes.every((item) => item.is_approve)
                            let checkApprovedDate = targetRes.every((item) => moment(moment(item.approved_date).format("YYYY-MM-DD")).isAfter(getPlan[0].planning_to_date))
                            let data = {
                                "sbuId": sbuResponse ? sbuResponse.id : '-',
                                "sbu": sbuResponse ? sbuResponse.name : 'Other SBU',
                                "planningCompletionStatus": checkStatus ? 1 : 0,
                                "planningOnTimeComplete": checkApprovedDate === false ? 1 : 0,
                            }
                            newResponse.push(data)
                        }
                        
                    }
                    

                    if (i === employeesList.length - 1) {
                        const groupedData = [];
                        newResponse.forEach(item => {
                            const { sbu, planningCompletionStatus, planningOnTimeComplete } = item;
                            if (!groupedData.hasOwnProperty(sbu)) {
                                groupedData[sbu] = {
                                    planningCompletionStatusCount: 0,
                                    planningOnTimeCompleteCount: 0,
                                    totalHeadCount: 0
                                };
                            }
                            groupedData[sbu].planningCompletionStatusCount += planningCompletionStatus;
                            groupedData[sbu].planningOnTimeCompleteCount += planningOnTimeComplete;
                            groupedData[sbu].totalHeadCount++;
                        });
                        const result = Object.keys(groupedData).map(sbu => {
                            return {
                                sbu,
                                planningCompletionStatusCount: groupedData[sbu].planningCompletionStatusCount,
                                planningOnTimeCompleteCount: groupedData[sbu].planningOnTimeCompleteCount,
                                totalHeadCount: groupedData[sbu].totalHeadCount
                            };
                        });

                        // Percentage Calculations
                        const updatedData = result.map(obj => {
                            const planningCompletion = Math.round((obj.planningCompletionStatusCount * 100) / obj.totalHeadCount);
                            const percentageCompletion = Math.round((obj.planningOnTimeCompleteCount * 100) / obj.totalHeadCount);
                            return {
                                ...obj,
                                planningCompletion,
                                percentageCompletion
                            };
                        });


                        var sumOfTotalHeadCount = sumFromArray('totalHeadCount', updatedData);
                        setTotalHeadCount_footer(sumOfTotalHeadCount)

                        var avgOfPlanningCompletion = sumFromArray('planningCompletion', updatedData);
                        setAvgPlanningCompletion_footer(Math.round(avgOfPlanningCompletion / updatedData.length))

                        var avgOfOnTimeCompletion = sumFromArray('percentageCompletion', updatedData);
                        setOnTimeCompletion_footer(Math.round(avgOfOnTimeCompletion / updatedData.length))

                        setSummaryReport(updatedData)
                        setShowData(true)
                    }
                }
            } catch (e) {

            }
        }
        else {
            setSummaryReport([])
            setShowData(true)
        }



        // .-------------------------------  OLD CODE BASED ON SBU --- Targets  18 MAY --------
        // var targetsList = await DataStore.query(Targets, (c) => c.financial_year_id.eq(financialYearId));
        // if (targetsList.length) {
        //     Object.keys(targetsList).map(async (currentItem, i) => {
        //         let loopItem = targetsList[currentItem];
        //         let data = ObjectToArray(loopItem);

        //         var employeeRes = await DataStore.query(Employee, data["employee_id"])

        //         var sbuResponse = await DataStore.query(SBU, employeeRes.param_sbu_id)
        //         if (sbuResponse) { data["sbu"] = sbuResponse.name } else { data["sbu"] = "-" }

        //         data["planningCompletionStatus"] = "100%"
        //         data["onTimeCompletionPercentage"] = "100%"

        //         newResponse.push(data)
        //         if (i === targetsList.length - 1) {
        //             //Counting duplicates in object array and storing the count as a new object
        //             const count = [...newResponse.reduce((mp, o) => {
        //                 if (!mp.has(o.sbu)) mp.set(o.sbu, { ...o, totalHeadCount: 0 });
        //                 mp.get(o.sbu).totalHeadCount++;
        //                 return mp;
        //             }, new Map).values()];

        //             var sumOfTotalHeadCount = sumFromArray('totalHeadCount', count);
        //             setTotalHeadCount_footer(sumOfTotalHeadCount)

        //             var avgOfPlanningCompletion = sumFromArray('planningCompletionStatus', count);
        //             setAvgPlanningCompletion_footer(avgOfPlanningCompletion / count.length)

        //             var avgOfOnTimeCompletion = sumFromArray('onTimeCompletionPercentage', count);
        //             setOnTimeCompletion_footer(avgOfOnTimeCompletion / count.length)

        //             setSummaryReport(count)
        //             setShowData(true)
        //         }
        //     })
        // } else {
        //     setTotalHeadCount_footer("")
        //     setAvgPlanningCompletion_footer("")
        //     setOnTimeCompletion_footer("")
        //     setSummaryReport([])
        //     setShowData(true)
        // }
        // .-------------------------------  OLD CODE BASED ON SBU --- Targets  18 MAY --------


        // .-------------------------------  OLD CODE BASED ON SBU --------

        // var sbuList = await DataStore.query(SBU, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        // Object.keys(sbuList).map(async (currentItem, i) => {
        //     let loopItem = sbuList[currentItem];
        //     let data = ObjectToArray(loopItem);

        //     // total HeadCount
        //     var employeeRes = await DataStore.query(Employee, (c) => c.param_sbu_id.eq(data["id"]))
        //     data["totalHeadCount"] = employeeRes.length

        //     data["planningCompletionStatus"] = "100%"
        //     data["onTimeCompletionPercentage"] = "100%"

        //     newResponse.push(data)

        //     if (i === sbuList.length - 1) {
        //         var sumOfTotalHeadCount = sumFromArray('totalHeadCount', newResponse);
        //         setTotalHeadCount_footer(sumOfTotalHeadCount)

        //         var avgOfPlanningCompletion = sumFromArray('planningCompletionStatus', newResponse);
        //         setAvgPlanningCompletion_footer(avgOfPlanningCompletion / newResponse.length)

        //         var avgOfOnTimeCompletion = sumFromArray('onTimeCompletionPercentage', newResponse);
        //         setOnTimeCompletion_footer(avgOfOnTimeCompletion / newResponse.length)

        //         setSummaryReport(newResponse)
        //     }
        // })

    }


    const downloadReport = async () => {
        const unit = "pt";
        const size = "A2"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);

        //target Data
        const title = "Goals Setting - Summary";
        const headers = [["SBU", "Total Headcount", "Planning Completion Status", "On-Time Completion Percentage"]];
        const data = summaryReport.map(elt => [elt.sbu, elt.totalHeadCount, elt.planningCompletion,]);
        let content = { startY: 50, head: headers, body: data };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Goals Setting - Summary")
    }

    const bodyShowPercentageCompletion = (rowData) => {
        return rowData.percentageCompletion + "%";
    }

    const bodyShowPlanningCompletion = (rowData) => {
        return rowData.planningCompletion + "%";
    }

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            {
                // showData &&
                <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Reports</div>
                            <div className="tabs_one mt-5 sm:mt-0">
                                <ul className="flex">
                                    <li><Link className='active' >Goal Setting</Link></li>
                                    <li><Link to="/hr/reports/goalevaluation/summary" >Goals Evaluation</Link></li>
                                </ul>
                            </div>
                        </div>

                        <>
                            <div className="lg:flex justify-between my-6">
                                <div className="tabs_two">
                                    <ul className="flex">
                                        <li><Link className='active'>Summary</Link></li>
                                        <li><Link to="/hr/reports/goalsetting/detailed" >Detailed</Link></li>
                                    </ul>
                                </div>
                                <div className="mt-5 lg:mt-0 flex items-center gap-4">
                                    <div className="text-md min-w-fit dark:text-white">Select Year</div>
                                    <div className="w-full min-w-[200px]">
                                        <Select
                                            options={financialYearList}
                                            value={financialYear}
                                            onChange={e => {
                                                onFinancialSelectChange(e);
                                                reactLocalStorage.setObject('selectedYear', e);
                                            }}
                                            className="my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* code here */}

                            <div className={"block w-full pt-5"}>
                                <div className="lg:flex justify-between my-6">
                                    <div className="tabs_two">
                                        <ul className="flex font-semibold dark:text-white">
                                            Goals Setting - Summary
                                        </ul>
                                    </div>
                                    {
                                        summaryReport.length > 0 ?
                                            <div className="mt-5 lg:mt-0 flex items-center gap-4">
                                                <Link onClick={() => window.print()} className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" ><i className="pi pi-print style={{'fontSize': '2px'}}"></i> Print</Link>
                                                <Link onClick={() => downloadReport()} className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" ><i className="pi pi-download style={{'fontSize': '2px'}}"></i> Download</Link>
                                            </div> : ""
                                    }
                                </div>

                                {!showData ?
                                    <div className='text-center py-8' ><ProgressSpinner /></div>
                                    :
                                    <DataTable
                                        paginator value={summaryReport} responsiveLayout="scroll" style={{ width: '93vw' }}
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                    >
                                        <Column field="sbu" header="SBU" footer="Total" sortable ></Column>
                                        <Column field="totalHeadCount" footer={totalHeadCount_footer} header="Total Headcount" sortable></Column>
                                        <Column field="planningCompletion" body={bodyShowPlanningCompletion} footer={avgPlanningCompletion_footer > 0 ? avgPlanningCompletion_footer + "%" : ""} header="Planning Completion Status" sortable></Column>
                                        <Column field="percentageCompletion" body={bodyShowPercentageCompletion} footer={onTimeCompletion_footer > 0 ? onTimeCompletion_footer + "%" : ""} header="On-time Completion Percentage" sortable></Column>

                                    </DataTable>
                                }

                            </div>
                        </>
                    </div>
                </div>
            }

            {/* {
                !showData &&
                <div className='text-center py-8' ><ProgressSpinner /></div>
            } */}
        </div>
    )
        : (
            <HRLogin sessionExpired={1} />
        );
}