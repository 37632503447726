import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteButton from '../../../../../components/common/buttons/DeleteButton';
import EditButton from '../../../../../components/common/buttons/EditButton';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import { ObjectToArray } from '../../../../../components/helper/commonfunctions';
import { DataTableCustomStyles } from '../../../../../components/helper/dataTable.config';
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import { Country, Customer, CustomerCategory } from '../../../../../models';
import HRLogin from '../../../../../setup/auth/hr.component';
import ImportDimension from '../../../../../components/common/import/dimensionsimport.component';
import { ProgressSpinner } from 'primereact/progressspinner';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

export default function CustomerList() {

    let navigateTo = useNavigate();
    const [isPageLoaded, setPageLoaded] = useState(false);
    const [countries, setAllCountries] = useState([]);
    const [customerCategories, setCustomerCategories] = useState([]);
    const [dataLoaded,setDataLoaded]=useState(false);

    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/hr/setting/dimensions/customer/createCustomer/' + id);
    };

    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        // const objDeleteCustomer = await DataStore.query(Customer, id);
        const objDeleteCustomer = await graphQLFindRecordById('getCustomer', id);

        alertify.confirm('Confirmation', 'Do you want to delete this record?', async function () {
            //Delete customer
            // DataStore.delete(objDeleteCustomer);
            await API.graphql({
                query: mutations.deleteCustomer,
                variables: {
                    input: {
                        id: objDeleteCustomer.id,
                        _version: objDeleteCustomer._version,
                    }
                }
            });
            BindList();
            toast.success("Deleted");
            // navigateTo("/hr/setting/dimensions/customer");
        }
            , function () { });

    };

    // const handleDeleteAllRecords = async()=>{
    //     const deleteAll = await DataStore.query(Customer);
    //     console.log("Here")
    //     await Promise.all(
    //         deleteAll.map(async (record) => {
    //           await DataStore.delete(record);
    //         })
    //       );
    // }

    // //*Column Name
    const DataTableColumns = [
        {
            name: 'Customer',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Ref Code',
            selector: row => row.REF_CODE,
            sortable: true,
        },
        {
            name: 'Group',
            selector: row => row.customerCategory,
            sortable: true,
        },
        {
            name: 'Country',
            selector: row => row.countryName,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <div className="flex gap-8">
                        <Link onClick={(e) => handleEditClick(e, row.id)} ><EditButton /></Link>
                        <Link onClick={(e) => handleDeleteClick(e, row.id)} ><DeleteButton /></Link>
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true
        }
    ];


    // //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    // //State
    const [customerList, SetCustomerList] = useState([]);
    const [customerFilterList, SetCustomerFilterList] = useState([]);
    const [renderItems, setRenderItems] = useState(false);
    const [isShowImportPopup, setIsShowImportPopup] = useState(false);


    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //onClick Close popup
    async function onClickCloseImportPopup() {
        setRenderItems(false)
        setIsShowImportPopup(false);
        BindList();
    }

    //Search Customer
    const searchCustomer = (searchedText) => {
        if (searchedText.length > 1) {
            let data = [...customerFilterList]
            let filteredData = data.filter(e =>
                e.name.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.REF_CODE.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.countryName.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.customerCategory.toLowerCase().includes(searchedText.toLowerCase())
            )
            SetCustomerList(filteredData)
        } else {
            let data = [...customerFilterList]
            SetCustomerList(data)
        }
    }

    const getMasterData = async () => {
        let countryResponses = await graphQLGetAllData('listCountries');
        let customerCategoryResponses = await graphQLGetAllData('listCustomerCategories');

        setDataLoaded(true);
        setAllCountries(countryResponses)
        setCustomerCategories(customerCategoryResponses);
    }

    async function BindList() {
        // var customerResponses = await DataStore.query(Customer, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING),
        // });
        var customerResponses = await graphQLGetAllData('listCustomers');
        customerResponses = customerResponses.sort((a, b) => a.name.localeCompare(b.name));

        // console.log("customerResponses", customerResponses)

        // if (customerResponses.length > 0) {
        //     var newResponse = [];
        //     Object.keys(customerResponses).map(async (currentItem, i) => {
        //         let loopCustomerItem = customerResponses[currentItem];
        //         let data = ObjectToArray(loopCustomerItem);

        //         if (data["country_id"] !== null) {
        //             // let countryResponses = await DataStore.query(Country, (c) => c.id.eq(data["country_id"]));
        //             let countryResponses = await graphQLFindRecordById('getCountry', data["country_id"]);

        //             data["countryName"] = countryResponses ? countryResponses.name + " (" + countryResponses.name + ")" : "-"
        //         }

        //         if (data["customer_category_id"] !== null) {
        //             let customerCategoryResponses = await DataStore.query(CustomerCategory, (c) => c.id.eq(data["customer_category_id"]));

        //             data["customerCategory"] = customerCategoryResponses.length > 0 ? customerCategoryResponses[0].name + " (" + customerCategoryResponses[0].REF_CODE + ")" : "-"
        //         }

        //         newResponse.push(data);
        //         // console.log("newResponse",newResponse)
        //         if (i === customerResponses.length - 1) {
        //             SetCustomerList(newResponse)
        //             SetCustomerFilterList(newResponse)
        //             setPageLoaded(true)
        //         }
        //     })
        // } else {
        //     SetCustomerList([])
        //     SetCustomerFilterList([])
        //     setPageLoaded(true)
        // }
        var newResponse = [];
        if (customerResponses.length > 0 && dataLoaded === true) {

            // console.log('countries',countries)
            // console.log('categories',customerCategories)

            customerResponses.forEach(async (c, i) => {

                let obj = {};
                let country = countries.find(country => country.id === c.country_id);
                let customerCategory = customerCategories.find(category => category.id === c.customer_category_id)

                let countryName = country.name;
                let customerCategoryName = customerCategory.name;

                obj = { ...c, countryName: countryName, customerCategory: customerCategoryName }
                // obj={...c};

                newResponse.push(obj);

                if (i === customerResponses.length - 1) {
                    SetCustomerList(newResponse)
                    SetCustomerFilterList(newResponse)
                    setPageLoaded(true)
                    // console.log('newResponse', newResponse)
                }

            })
        }
        else {
            SetCustomerList([])
            SetCustomerFilterList([])
            setPageLoaded(true)
        }
    }

    useEffect(() => {
        onLoad();
        getMasterData();
        BindList();
    }, []);

    useEffect(()=>{
        if(dataLoaded === true){
          BindList();
        }
    },[dataLoaded])


    return (
        isAuthenticated ? (

            <div>
                <div className="pt-16">
                    <TopNavBar />
                    <LeftSideBar />
                    <div className="dashboard-main-wrapper pl-20 pr-4 mt-3">
                        <div className="flex flex-1 flex-wrap p-5 pb-4 px-0 top-action-wrapper">
                            <div className="md:flex xs:w-full sm:w-full md:w-auto flex-row align-center">
                                <div className="md:flex block md:col-span-2 mb-4">
                                </div>
                                <div className="md:flex block mb-4">
                                    <h2 className="page-title">Customers</h2>
                                </div>
                            </div>

                            <div className="md:flex flex-1 flex-row xs:w-full sm:w-full md:w-auto align-center justify-end md:ml-auto gap-3 right-side">
                                <div className="relative">
                                    <input onChange={(e) => searchCustomer(e.target.value)} type="text" id="required-search" className="rounded-md border border-[#D0D5DD] focus:border-transparent w-full py-2.5 pl-3 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#403E3C] dark:text-[#FFFFFF] dark:border-[#403E3C]" placeholder="Search..." />
                                </div>
                                <div className="md:flex block mb-4 gap-4">
                                    <Link to="/hr/settings" className="flex py-2 px-5 bg-[#FFF] hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-black transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] dark:border-[#333231] dark:text-[#FFFFFF] dark:bg-[#4A5056]">
                                        Back
                                    </Link>
                                    <Link onClick={() => {
                                        setIsShowImportPopup(true);
                                    }} className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] bg-black">
                                        Import
                                    </Link>
                                    <Link to="/hr/setting/dimensions/customer/createCustomer" className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] bg-black">
                                        Create
                                    </Link>
                                    {/* <Link onClick={()=>{
                                        handleDeleteAllRecords();
                                    }} className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] bg-black">
                                        Delete
                                    </Link> */}
                                </div>

                            </div>
                        </div>
                        <div>
                            <div className={"block w-full pt-5"}>
                                {isPageLoaded &&
                                    <DataTable
                                        columns={DataTableColumns}
                                        data={customerList}
                                        customStyles={DataTableCustomStyles}
                                        pagination
                                        highlightOnHover
                                        paginationPerPage={10}
                                    />
                                }
                                {
                                    !isPageLoaded &&
                                    <div className='text-center py-10' >
                                        <ProgressSpinner style={{ width: '80px', height: '80px', zIndex: '9999' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".8s" />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        isShowImportPopup &&
                        <ImportDimension
                            closePopup={() => {
                                onClickCloseImportPopup();
                            }}
                            type={"Customer"}
                        />
                    }
                </div>
            </div>

        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}



