import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataTableCustomStyles } from "../../../../../components/helper/dataTable.config";
import HRLogin from "../../../../../setup/auth/hr.component";
import { Auth } from "aws-amplify";
import * as alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import AWS from "aws-sdk";
import awsmobile from "../../../../../aws-exports";
import { GetValueFromArray } from "../../../../../components/helper/commonfunctions";
import moment from "moment";
import { USER_TYPES, USER_TYPES_NAMES } from "../../../../../components/helper/enum";
import Select from "react-select";
import DeleteButton from '../../../../../components/common/buttons/DeleteButton'
import ResetPasswordButton from '../../../../../components/common/buttons/ResetPasswordButton'
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import PermissionButton from "../../../../../components/common/buttons/PermissionButton";

export default function Users() {
  let navigateTo = useNavigate();

  //SET AWS Configuration
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
    secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey,
    region: process.env.REACT_APP_AWS_Region,
  });


  const statusList = [
    { value: "", label: "Status" },
    { value: "CONFIRMED", label: "Confirmed" },
    { value: "RESET_REQUIRED", label: "Reset Required" },
    { value: "UNCONFIRMED", label: "Unconfirmed" },
    { value: "FORCE_CHANGE_PASSWORD", label: "Force Change Password" },
  ];


  const defaultSearchFields = [
    { value: "", label: "Search On" },
    { value: "email", label: "Email" },
    { value: "name", label: "Name" },
  ];

  const [searchStatus, setSelectedStatus] = useState("");
  const [selectedSearchBy, setSelectedSearchBy] = useState("");
  const [searchExpression, setSearchExpression] = useState("");

  const handleResetPasswordClick = (e, id) => {
    e.preventDefault();

    const cognito = new AWS.CognitoIdentityServiceProvider();
    var params = { UserPoolId: awsmobile.aws_user_pools_id, Username: id };

    alertify.confirm(
      "Confirmation",
      "Do you want to reset this user password?",
      function () {
        cognito.adminResetUserPassword(params, (err, data) => {
          if (err) {
            toast.error(err.message);
          } else {
            toast.success("New password email has been send successfully.");
            LoadUsers();
          }
        });
      },
      function () { }
    );
  };

  const handleDeleteClick = async (e, id) => {
    e.preventDefault();

    const cognito = new AWS.CognitoIdentityServiceProvider();
    var params = { UserPoolId: awsmobile.aws_user_pools_id, Username: id };

    alertify.confirm(
      "Confirmation",
      "Do you want to delete this user?",
      async function () {
        cognito.adminDeleteUser(params, (err, data) => {
          if (err) {
            toast.error(err.message);
          } else {
            toast.success("Deleted");
            LoadUsers();
          }
        });
      },
      function () { }
    );
  };

  //*Column Name
  const DataTableColumns = [
    {
      name: "First Name",
      selector: (row) => GetValueFromArray(row.Attributes, "custom:firstName"),
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => GetValueFromArray(row.Attributes, "custom:lastName"),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => GetValueFromArray(row.Attributes, "email"),
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        row.UserStatus === "CONFIRMED" ? <p className="badge-approved">{row.UserStatus}</p> :
          <p className="badge-others">{row.UserStatus}</p>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Created",
      selector: (row) =>
        moment(row.UserCreateDate).format("Do MMM, YYYY hh:mm A").toString(),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className="flex gap-8">
            {
              row.UserStatus === "CONFIRMED" ?
                <Link onClick={(e) => handleResetPasswordClick(e, row.Username)}>
                  <ResetPasswordButton />
                </Link>
                : <Link />
            }
            <Link onClick={(e) => handleDeleteClick(e, row.Username)}>
              <DeleteButton />
            </Link>
          </div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      center: true,
    },
  ];

  //Validate User Logged In
  const [isAuthenticated, userHasAuthenticated] = useState(true);

  //State
  const [users, SetUsersList] = useState([]);

  async function onLoad() {
    try {
      if (!ValidateHRRole()) { userHasAuthenticated(false); }
    } catch (e) {
      userHasAuthenticated(false);
    }
  }

  async function LoadUsers() {

    try {
      const cognito = new AWS.CognitoIdentityServiceProvider();
      var params =
      {
        UserPoolId: awsmobile.aws_user_pools_id,
        Limit: 60
      };

      //Status
      if (searchStatus !== '') {
        params['Filter'] = "cognito:user_status='" + searchStatus + "'";
      }
      else if (searchExpression !== '' && selectedSearchBy !== '') //Global Search 
      {
        console.log(selectedSearchBy);
        params['Filter'] = selectedSearchBy + "='" + searchExpression + "'";
      }

      cognito.listUsers(params, (err, data) => {
        if (err) {
          toast.error(err.message);
        } else {
          SetUsersList(data.Users);
        }
      });


    } catch (e) {
      toast.error(e.message);
    }

  }

  useEffect(() => {
    onLoad();
    LoadUsers();
  }, [searchExpression, searchStatus]);

  return isAuthenticated ? (
    <div>
      <div className="pt-16">
        <TopNavBar />
        <LeftSideBar />
        <div className="dashboard-main-wrapper pl-20 pr-4 mt-3">
          <div className="flex flex-1 flex-wrap p-5 pb-4 px-0 top-action-wrapper">
            <div className="md:flex xs:w-full sm:w-full md:w-auto flex-row align-center">
              <div className="md:flex block md:col-span-2 mb-4"></div>
              <div className="md:flex block mb-4">
                <h2 className="page-title">HR</h2>
              </div>
            </div>

            <div className="md:flex flex-1 flex-row xs:w-full sm:w-full md:w-auto align-center justify-end md:ml-auto gap-3 right-side">
              <div className="md:flex block mb-4 gap-4">
                <Link
                  to="/hr/settings"
                  className="flex py-2 px-5 bg-[#FFF] hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-black transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white"
                >
                  Back
                </Link>
                <Link
                  to="/hr/settings/createuser"
                  className="flex py-2 px-5 bg-[#029046] hover:bg-green-900 focus:ring-green-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md"
                  
                >
                  Create
                </Link>
              </div>
            </div>
          </div>

          <div className="md:flex align-center justify-end md:ml-auto gap-1.5 col-span-6">

            <div className="">
              <Select options={defaultSearchFields} maxMenuHeight={200} placeholder="Search On"
               className="mt-1.5 my-react-select-container"
               classNamePrefix="my-react-select"
              onChange={(e) => {
                setSelectedSearchBy(e.value);
              }} />
            </div>

            <div >
              <input
                type="text"
                onChange={(e) => {
                  setSearchExpression(e.target.value);
                }}
                className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                placeholder="Search"
              />
            </div>

          

            <div className="">
              <Select options={statusList} width='200px' maxMenuHeight={300} 
              className="mt-1.5 my-react-select-container"
              classNamePrefix="my-react-select" 
              placeholder="Status" onChange={(e) => {
                setSelectedStatus(e.value);
              }} />
            </div>
          </div>

          <div>
            <div className={"block w-full pt-5"}>
              {
                <DataTable
                  columns={DataTableColumns}
                  data={users}
                  customStyles={DataTableCustomStyles}
                  pagination
                  highlightOnHover
                  paginationPerPage={10}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <HRLogin sessionExpired={1} />
  );
}
