import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import HRLogin from "../../../../../setup/auth/hr.component";
import { SendWelcomeEmailToHRUser } from "../../../../../components/helper/mail";
import capitalizeFirst from "../../../../../components/common/capitalizeFirst"
import { ValidateHRRole } from '../../../../../components/helper/validateRole';

export default function HrCreateUser() {

  //Validate User Logged In
  const [isAuthenticated, userHasAuthenticated] = useState(true);
  const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  let navigateTo = useNavigate();


  //Create Record
  const saveUser = async () => {
    if (email === "") {
      toast.error("Please enter Email.");
      return;
    } else if (firstName === "") {
      toast.error("Please enter First Name.");
      return;
    } else if (lastName === "") {
      toast.error("Please enter Last Name.");
      return;
    } else if (password === "") {
      toast.error("Please enter Create Password.");
      return;
    } else if (confirmPassword === "") {
      toast.error("Please enter Confirm Password.");
      return;
    } else if (password !== confirmPassword) {
      toast.error("Confirm Password must match with Create Password.");
      return;
    }

    setPleaseWaitButton(true);

    try {
      setPleaseWaitButton(true);
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          name: capitalizeFirst(firstName) + ' ' + capitalizeFirst(lastName),
          "custom:firstName": capitalizeFirst(firstName),
          "custom:lastName": capitalizeFirst(lastName),
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      });

      //Here we are going to send welcome email
      SendWelcomeEmailToHRUser(firstName, email, password);
      toast.success("Email verification sent to user.");
      navigateTo("/hr/settings/users");

    } catch (e) {
      setPleaseWaitButton(false);
      switch (e.message) {
        case 'Password did not conform with policy: Password not long enough':
          toast.error(e.message);
          break;
        default:
          toast.error(e.message);
      }
    }




  };

  async function onLoad() {
    try {
      if (!ValidateHRRole()) { userHasAuthenticated(false); }


    } catch (e) {
      userHasAuthenticated(false);
    }
  }

  useEffect(() => {
    onLoad();
  }, []);

  return isAuthenticated ? (
    <div>
      <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full ">
        <TopNavBar />
        <LeftSideBar />

        <div className="pl-16">
          <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
            <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
              <div className="p-3 py-5 bg-white dark:bg-[#2A2E32] rounded-md shadow ">
                <div className="flex justify-center mt-3 mb-2">
                  <h2 className="page-title">
                    Create HR
                  </h2>
                </div>
                <div x-data="{tab: 1}"></div>
                <div className={"block w-full"}>
                  <div className="px-4" x-show="tab === 1">
                    <div className="flex grid flex-row flex-wrap flex-1 mb-2 align-center 2xl:grid-cols-1 xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-x-4">
                      <div className="relative mb-4">
                        <label
                          className="text-[#344054] text-[14px] font-medium "
                        >
                          Email
                          <label
                            className="text-[#FF0000] text-[14px] font-medium"
                          >
                            *{" "}
                          </label>
                        </label>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                          className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                          placeholder="Enter Email"
                        />
                      </div>
                    </div>

                    <div className="flex grid flex-row flex-wrap flex-1 mb-2 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                      <div className="relative mb-4">
                        <label className="text-[#344054] text-[14px] font-medium ">
                          First Name<label className="text-[#FF0000] text-[14px] font-medium">*{" "}</label>
                        </label>
                        <input
                          
                          value={(firstName)}
                          onChange={(e) => setFirstName(e.target.value)}
                          type="text"
                          className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                          placeholder="Enter First Name"
                        />
                      </div>
                      <div className="relative mb-4">
                        <label className="text-[#344054] text-[14px] font-medium ">
                          Last Name
                          <label className="text-[#FF0000] text-[14px] font-medium">*{" "}</label>
                        </label>
                        <input
                          
                          value={(lastName)}
                          onChange={(e) => setLastName(e.target.value)}
                          type="text"
                          className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                          placeholder="Enter Last Name"
                        />
                      </div>
                    </div>

                    <div className="flex grid flex-row flex-wrap flex-1 mb-2 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                      <div className="relative mb-4">
                        <label

                          className="text-[#344054] text-[14px] font-medium "
                        >
                          Create Password
                          <label

                            className="text-[#FF0000] text-[14px] font-medium"
                          >
                            *{" "}
                          </label>
                        </label>
                        <input
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type="text"
                          className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                          placeholder="Enter Create Password"
                        />
                      </div>
                      <div className="relative mb-4">
                        <label

                          className="text-[#344054] text-[14px] font-medium "
                        >
                          Confirm Password
                          <label

                            className="text-[#FF0000] text-[14px] font-medium"
                          >
                            *{" "}
                          </label>
                        </label>
                        <input
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          type="text"
                          className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                          placeholder="Enter Password"
                        />
                      </div>
                    </div>

                    <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center ">
                      <small className="text-[#999]">Note: Minimum password length of 8 characters. Minimum of three of  uppercase, lowercase, numbers and special characters.</small>
                    </div>

                    <div className="grow flex justify-center gap-3 mb-3">
                      <Link
                        to="/hr/settings/users"
                        className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md  dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white"
                      >
                        Cancel
                      </Link>
                      <button
                        onClick={() => saveUser()}
                        className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md "
                      >
                        {showPleaseWaitButton ? "Please wait..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <HRLogin sessionExpired={1} />
  );
}
