
import React, { useEffect, useState } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-google-autocomplete";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import capitalizeFirst from '../../../../../components/common/capitalizeFirst';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNav from '../../../../../components/common/hr/topnav.component';
import ValidateEmail from "../../../../../components/common/ValidateEmail";
import { Google_API_KEY } from '../../../../../components/helper/enum';
import { getArea, getCity, getCountry, getState, getZipCode } from '../../../../../components/helper/getAutoCompleteAddress';
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import { Employee, Roles, SBU } from "../../../../../models";
import HRLogin from '../../../../../setup/auth/hr.component';
import { Auth, Storage } from 'aws-amplify';
import Select from 'react-select';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { ConvertResponseForSelect, ConvertResponseForSelectTwoNames } from '../../../../../components/helper/commonfunctions';
import defaultProfilePath from '../../../../../assets/images/defaultProfile.jpg'
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';



export default function HrCreateEmployee() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editEmployeeId } = useParams();
    const [isEditMode, setEditMode] = useState(false);
    const [employeeIDForEdit, setEmployeeIDForEdit] = useState("");
    let navigateTo = useNavigate();

    //Employee Info
    const [roleName, setRoleName] = useState("");
    const [roleId, setRoleId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [parentEmployeeName, setParentEmployeeName] = useState("");
    const [sbuName, setSBUName] = useState("");
    const [parentEmployeeId, setParentEmployeeId] = useState("");
    const [sbuId, setSbuId] = useState("");
    const [employeeId, setEmployeeId] = useState("");

    const [phoneNo, setPhoneNo] = useState("");
    const [email, setEmail] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [profilePicPath, setProfilePicPath] = useState("");
    const [updateProfilePicPath, setUpdateProfilePicPath] = useState("");
    const [localPicturePath, setLocalPicturePath] = useState("");
    const [isAssignDirectTarget, setIsAssignDirectTarget] = useState(false);
    const [isHRAccess, setIsHRAccess] = useState(false);
    const [isFinanceAccess, setIsFinanceAccess] = useState(false);
    const [joiningDate, setJoiningDate] = useState(null);
    const [confirmationDate, setConfirmationDate] = useState(null);
    const [employeeClass, setEmployeeClass] = useState("");
    const [employeeType, setEmployeeType] = useState("");
    const [grade, setGrade] = useState("");
    const [band, setBand] = useState("");
    const [lwd, setLwd] = useState(null);

    //lists
    const [roles, setRoles] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [sbuList, setSbuList] = useState([]);

    //Create New Employee
    const saveEmployee = async () => {

        if (firstName === '') { toast.error('Please enter First Name.'); return; }
        else if (lastName === '') { toast.error('Please enter Last Name.'); return; }
        else if (roleId === '') { toast.error('Please select Role.'); return; }
        else if (!ValidateEmail(email)) { toast.error('Invalid email.'); return; }

        setPleaseWaitButton(true);
        if (isEditMode) {
            // const original = await DataStore.query(Employee, editEmployeeId);
            const original = await graphQLFindRecordById('getEmployee', editEmployeeId);

            const checkDuplicateEmail = await graphQLGetAllData('listEmployees', [{ email: { eq: email } }, { id: { ne: editEmployeeId } }]);

            if (checkDuplicateEmail.length == 1) {
                toast.error('Email already exists.');
                // setEmail("")
                setPleaseWaitButton(false);
                return;
            }

            // console.log('lastname',lastName);
            // await DataStore.save(
            //     Employee.copyOf(original, (updated) => {
            //         updated.role_id = roleId;
            //         updated.firstName = firstName;
            //         updated.lastName = lastName;
            //         updated.email = email;
            //         updated.parentEmployeeId = parentEmployeeId ? parentEmployeeId : "";
            //         updated.address1 = addressOne ? addressOne : "";
            //         updated.address2 = addressTwo ? addressTwo : "";
            //         updated.phoneNo = phoneNo;
            //         updated.city = city;
            //         updated.zip = zipCode;
            //         updated.state = state;
            //         updated.country = country;
            //         updated.employeeId = employeeId;
            //         updated.isAssignDirectTarget = isAssignDirectTarget;
            //         updated.param_sbu_id = sbuId;
            //         updated.IsHRAccess = isHRAccess;
            //         updated.IsFinanceAccess = isFinanceAccess;
            //         updated.joining_date = joiningDate === null || joiningDate === undefined || joiningDate === "" ? "1970-05-07" : moment(joiningDate).format('YYYY-MM-DD');
            //         updated.confirmation_date = confirmationDate === null || confirmationDate === undefined || confirmationDate === "" ? "1970-05-07" : moment(confirmationDate).format('YYYY-MM-DD');
            //         updated.employee_class = employeeClass;
            //         updated.employee_type = employeeType;
            //         updated.grade = grade;
            //         updated.band = band;
            //         updated.lwd = lwd === null || lwd === undefined || lwd === "" ? "1970-05-07" : moment(lwd).format('YYYY-MM-DD');
            //     })
            // );

            await API.graphql({
                query: mutations.updateEmployee,
                variables: {
                    input: {
                        id: original.id,
                        role_id: roleId,
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        parentEmployeeId: parentEmployeeId ? parentEmployeeId : "",
                        address1: addressOne ? addressOne : "",
                        address2: addressTwo ? addressTwo : "",
                        phoneNo: phoneNo,
                        city: city,
                        zip: zipCode,
                        state: state,
                        country: country,
                        employeeId: employeeId,
                        isAssignDirectTarget: isAssignDirectTarget,
                        param_sbu_id: sbuId,
                        IsHRAccess: isHRAccess,
                        IsFinanceAccess: isFinanceAccess,
                        joining_date: joiningDate === null || joiningDate === undefined || joiningDate === "" ? "1970-05-07" : moment(joiningDate).format('YYYY-MM-DD'),
                        confirmation_date: confirmationDate === null || confirmationDate === undefined || confirmationDate === "" ? "1970-05-07" : moment(confirmationDate).format('YYYY-MM-DD'),
                        employee_class: employeeClass,
                        employee_type: employeeType,
                        grade: grade,
                        band: band,
                        lwd: lwd === null || lwd === undefined || lwd === "" ? "1970-05-07" : moment(lwd).format('YYYY-MM-DD'),
                        _version: original._version,
                    }
                }
            });
            toast.success('Updated');
            updateProfilePicture(original.id)
            if (profilePicPath === "") { goBack() }
        } else {
            //const checkDuplicateEmail = await DataStore.query(Employee, (c) => c.email.eq(email));
            const checkDuplicateEmail = await graphQLGetAllData('listEmployees', [{ email: { eq: email } }]);

            if (checkDuplicateEmail.length == 1) {
                toast.error('Email already exists.');
                setEmail("")
                setPleaseWaitButton(false);
            } else {
                //Save Employee
                // await DataStore.save(
                //     new Employee({
                //         "role_id": roleId,
                //         "firstName": firstName,
                //         "lastName": lastName,
                //         "email": email,
                //         "parentEmployeeId": parentEmployeeId,
                //         "address1": addressOne,
                //         "address2": addressTwo,
                //         "phoneNo": phoneNo,
                //         "city": city,
                //         "zip": zipCode,
                //         "state": state,
                //         "country": country,
                //         "employeeId": employeeId,
                //         "isAssignDirectTarget": isAssignDirectTarget,
                //         "param_sbu_id": sbuId,
                //         "IsHRAccess": isHRAccess,
                //         "IsFinanceAccess": isFinanceAccess,
                //         "joining_date": joiningDate === null || joiningDate === undefined || joiningDate === "" ? "1970-05-07" : moment(joiningDate).format('YYYY-MM-DD'),
                //         "confirmation_date": confirmationDate === null || confirmationDate === undefined || confirmationDate === "" ? "1970-05-07" : moment(confirmationDate).format('YYYY-MM-DD'),
                //         "employee_class": employeeClass,
                //         "employee_type": employeeType,
                //         "grade": grade,
                //         "band": band,
                //         "lwd": lwd === null || lwd === undefined || lwd === "" ? "1970-05-07" : moment(lwd).format('YYYY-MM-DD')
                //     })
                // )
                // .then((response) => {
                //     toast.success('Added');
                //     if (profilePicPath) { updateProfilePicture(response.id) } else { goBack() }
                // })

                let responseCreateEmp = await API.graphql(
                    graphqlOperation(mutations.createEmployee, {
                        input: {
                            role_id: roleId,
                            firstName: firstName,
                            lastName: lastName,
                            email: email,
                            parentEmployeeId: parentEmployeeId ? parentEmployeeId : "",
                            address1: addressOne ? addressOne : "",
                            address2: addressTwo ? addressTwo : "",
                            phoneNo: phoneNo,
                            city: city,
                            zip: zipCode,
                            state: state,
                            country: country,
                            employeeId: employeeId,
                            isAssignDirectTarget: isAssignDirectTarget,
                            param_sbu_id: sbuId,
                            IsHRAccess: isHRAccess,
                            IsFinanceAccess: isFinanceAccess,
                            joining_date: joiningDate === null || joiningDate === undefined || joiningDate === "" ? "1970-05-07" : moment(joiningDate).format('YYYY-MM-DD'),
                            confirmation_date: confirmationDate === null || confirmationDate === undefined || confirmationDate === "" ? "1970-05-07" : moment(confirmationDate).format('YYYY-MM-DD'),
                            employee_class: employeeClass,
                            employee_type: employeeType,
                            grade: grade,
                            band: band,
                            lwd: lwd === null || lwd === undefined || lwd === "" ? "1970-05-07" : moment(lwd).format('YYYY-MM-DD')
                        }
                    })
                );

                toast.success('Added');
                responseCreateEmp = responseCreateEmp.data.createEmployee;
                if (profilePicPath) { updateProfilePicture(responseCreateEmp.id) } else { goBack() }
                if (profilePicPath === "") { goBack() }
            }
        }
    }

    const updateProfilePicture = async (newEmployeeId) => {
        if (profilePicPath) {
            //const original = await DataStore.query(Employee, newEmployeeId);
            const original = await graphQLFindRecordById('getEmployee', newEmployeeId);

            if (original.profilePic === updateProfilePicPath) {
                // await DataStore.save(
                //     Employee.copyOf(original, (updated) => {
                //         updated.profilePic = updateProfilePicPath
                //     })
                // ).then((response) => {
                //     goBack()
                // })

                await API.graphql({
                    query: mutations.updateEmployee,
                    variables: {
                        input: {
                            id: original.id,
                            profilePic: updateProfilePicPath,
                            _version: original._version,
                        }
                    }
                });

                goBack();
            }
            else {
                const fileName = 'employee/' + newEmployeeId + '/profile.jpeg';
                try {
                    await Storage.put(fileName, profilePicPath, {
                        resumable: true,
                        completeCallback: async (event) => {
                            //const original = await DataStore.query(Employee, newEmployeeId);
                            const original = await graphQLFindRecordById('getEmployee', newEmployeeId);
                            // await DataStore.save(
                            //     Employee.copyOf(original, (updated) => {
                            //         updated.profilePic = fileName
                            //     })
                            // ).then((response) => {
                            //     goBack()
                            // })

                            await API.graphql({
                                query: mutations.updateEmployee,
                                variables: {
                                    input: {
                                        id: original.id,
                                        profilePic: fileName,
                                        _version: original._version,
                                    }
                                }
                            });
                            goBack()
                        },
                        errorCallback: (err) => {
                            toast.error(err.message)
                        }
                    })
                } catch (error) {
                    goBack()
                    console.log("Error uploading file: ", error);
                }

            }

        }
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }


    async function BindExistingData() {

        // Edit existing record
        if (editEmployeeId != null) {
            setEmployeeIDForEdit(editEmployeeId);
            // const original = await DataStore.query(Employee, editEmployeeId);
            const original = await graphQLFindRecordById('getEmployee', editEmployeeId);

            console.log('original', original);
            if (original != null) {
                setEditMode(true);
                if (original.profilePic) {
                    await Storage.get(original.profilePic)
                        .then(result => {
                            setProfilePicPath(result)
                        });
                }

                if (original.isAssignDirectTarget !== null) { setIsAssignDirectTarget(original.isAssignDirectTarget) }
                if (original.IsHRAccess !== null) { setIsHRAccess(original.IsHRAccess) }
                if (original.IsFinanceAccess !== null) { setIsFinanceAccess(original.IsFinanceAccess) }

                setUpdateProfilePicPath(original.profilePic)
                // var roleResponses = await DataStore.query(Roles, original.role_id);
                var roleResponses = await graphQLFindRecordById('getRoles', original.role_id);

                setRoleName({ label: roleResponses.name, value: roleResponses.id })
                setRoleId(roleResponses.id)

                setFirstName(original.firstName);
                setLastName(original.lastName);
                setEmail(original.email);
                setBand(original?.band)
                setGrade(original?.grade)


                if (original.param_sbu_id !== "" && original.param_sbu_id !== null) {
                    // var sbuResponses = await DataStore.query(SBU, (c) => c.id.eq(original.param_sbu_id));
                    // var sbuResponses = await graphQLGetAllData('listSBUS', [{ id: { eq: original.param_sbu_id } }]);
                    var sbuResponses = await graphQLFindRecordById('getSBU', original.param_sbu_id);

                    console.log('sbuResponses', sbuResponses);
                    setSBUName({ label: sbuResponses?.name });
                    setSbuId(original.param_sbu_id);
                }

                if (original.parentEmployeeId !== "") {
                    // var parentEmployeeResponses = await DataStore.query(Employee, (c) => c.id.eq(original.parentEmployeeId));
                    var parentEmployeeResponses = await graphQLGetAllData('listEmployees', [{ id: { eq: original.parentEmployeeId } }]);

                    // var employeeResponses = await DataStore.query(Employee, (c) => c.id.notContains(editEmployeeId));

                    var employeeResponses = await graphQLGetAllData('listEmployees', [{ id: { notContains: editEmployeeId } }]);

                    var myEmployeeArray = []; myEmployeeArray = ConvertResponseForSelectTwoNames(employeeResponses); setEmployeeList(myEmployeeArray);

                    if (parentEmployeeResponses.length > 0) {
                        setParentEmployeeName({ label: parentEmployeeResponses[0].firstName + " " + parentEmployeeResponses[0].lastName, value: parentEmployeeResponses.id })
                        setParentEmployeeId(original.parentEmployeeId);
                    } else {
                        setParentEmployeeName({ label: "Select", value: "" })
                    }
                }

                if (original.joining_date !== '') {
                    setJoiningDate(moment(original.joining_date).toDate())
                }

                if (original.confirmation_date !== '') {
                    setConfirmationDate(moment(original.confirmation_date).toDate())
                }
                if (original.employee_class) {
                    setEmployeeClass(original.employee_class)
                }
                if (original.employee_type) {
                    setEmployeeType(original.employee_type)
                }
                setAddressOne(original.address1);
                setAddressTwo(original.address2);
                setPhoneNo(original.phoneNo);
                setCity(original.city)
                setZipCode(original.zip);
                setState(original.state);
                setCountry(original.country);
                setEmployeeId(original.employeeId)


            }
            else {
                toast.error('Invalid record.');
                goBack()
            }
        }
    }

    //Get Address
    const onPlaceSelected = (place) => {
        const address = place.formatted_address,
            addressArray = place.address_components,
            city = getCity(addressArray),
            area = getArea(addressArray),
            country = getCountry(addressArray),
            zipCode = getZipCode(addressArray),
            state = getState(addressArray);
        // Set these values in the state.
        setCountry(country)
        setState(state)
        setZipCode(zipCode)
        setCity(city)
        setAddressOne(address)
    };

    const browseProfilePic = () => {
        document.getElementById('fileProfilePic').click()
    }

    async function changeProfilePicture(e) {
        let file = e.target.files[0];
        setProfilePicPath(file)
        setUpdateProfilePicPath(file)
        setLocalPicturePath(URL.createObjectURL(file))
    }

    async function BindList() {
        //Roles List
        // var roleResponses = await DataStore.query(Roles, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var roleResponses = await graphQLGetAllData('listRoles');
        roleResponses = roleResponses.sort((a, b) => a.name.localeCompare(b.name))

        var myRoleArray = []; myRoleArray = ConvertResponseForSelect(roleResponses); setRoles(myRoleArray);

        //Employee List
        // var employeeResponses = await DataStore.query(Employee, Predicates.ALL, {
        //     sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
        // });
        var employeeResponses = await graphQLGetAllData('listEmployees');

        employeeResponses = employeeResponses.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));

        var myEmployeeArray = []; myEmployeeArray = ConvertResponseForSelectTwoNames(employeeResponses);
        setEmployeeList(myEmployeeArray);

        //Employee List
        // var sbuResponses = await DataStore.query(SBU, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        var sbuResponses = await graphQLGetAllData('listSBUS');
        sbuResponses = sbuResponses.sort((a, b) => a.name.localeCompare(b.name));
        var mySbuArray = []; mySbuArray = ConvertResponseForSelect(sbuResponses);
        setSbuList(mySbuArray);

    }

    useEffect(() => {
        onLoad();
        BindList()
        BindExistingData();

    }, []);

    const goBack = () => {
        setPleaseWaitButton(false);
        navigateTo("/hr/settings/employee");
    }

    const setSBUIdOnEdit = (e) => {
        setSbuId(e.value)
    }

    return isAuthenticated ? (
        <div>
            <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full ">
                <TopNav />
                <LeftSideBar />

                <div className="pl-16">
                    <div
                        className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                        <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                            <div className="p-3 py-5 bg-white rounded-md shadow dark:bg-[#252423]">
                                <div className="flex justify-center mt-3 mb-2">
                                    <h2 className="page-title">
                                        {isEditMode ? "Edit" : "Create"} Employee
                                    </h2>
                                </div>
                                <div x-data="{tab: 1}"></div>
                                <div className={"block w-full"}>
                                    <div className="px-4" x-show="tab === 1">
                                        <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Employee ID</label>
                                                <input value={(employeeId)} onChange={e => setEmployeeId(e.target.value)} type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" placeholder="Employee ID" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium ">
                                                    SBU
                                                </label>

                                                <Select
                                                    value={sbuName}
                                                    options={sbuList}
                                                    onChange={e => {
                                                        setSBUName(e)
                                                        setSBUIdOnEdit(e)
                                                    }}
                                                    className="mt-2 my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    First Name <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label></label>
                                                <input value={(firstName)} onChange={e => setFirstName(e.target.value)} type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" placeholder="Enter First Name" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Last Name <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label></label>
                                                <input value={(lastName)} onChange={e => setLastName(e.target.value)} type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" placeholder="Enter Last Name" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium ">
                                                    Role<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label>
                                                </label>

                                                <Select
                                                    value={roleName}
                                                    options={roles}
                                                    onChange={e => {
                                                        setRoleName(e)
                                                        setRoleId(e.value)
                                                    }}
                                                    className="mt-2 my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium ">
                                                    Reporting Manager
                                                </label>

                                                <Select
                                                    value={parentEmployeeName}
                                                    options={employeeList}
                                                    onChange={e => {
                                                        setParentEmployeeName(e)
                                                        setParentEmployeeId(e.value)
                                                    }}
                                                    className="mt-2 my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Email <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label> </label>

                                                {/* As per requirement from Selva email disabled removed */}
                                                {/*  disabled={isEditMode ? true : false} */}
                                                <input value={email} onChange={e => setEmail(e.target.value)} type="text"
                                                    className={isEditMode ? "disabled bg-[#e1e1e1] text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" : "text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"}
                                                    placeholder="Enter Email" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Phone No
                                                </label>
                                                <PhoneInput
                                                    enableSearch
                                                    buttonStyle={{ background: 'transparent', borderWidth: 0 }}
                                                    inputStyle={{ borderWidth: 0, fontSize: 16, background: 'transparent' }}
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-1 mt-1 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E]"
                                                    country={'us'}
                                                    value={phoneNo}
                                                    onChange={(phone) => setPhoneNo("+" + phone)}
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Address 1
                                                </label>
                                                <Autocomplete
                                                    defaultValue={addressOne}
                                                    apiKey={Google_API_KEY}
                                                    onPlaceSelected={(place) => onPlaceSelected(place)}
                                                    options={{
                                                        types: ["establishment"],
                                                        componentRestrictions: { country: ["fr", "ch", "be", "us", "in"] },
                                                    }}
                                                    className="text-[16px] rounded-lg   flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Address 2
                                                </label>
                                                <input
                                                    value={addressTwo}
                                                    onChange={(e) => setAddressTwo(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="Address 2"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    City
                                                </label>
                                                <input
                                                    value={city}
                                                    onChange={(e) => setCity(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="City"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Zip Code
                                                </label>
                                                <input
                                                    value={zipCode}
                                                    onChange={(e) => setZipCode(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="Zip Code"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Country
                                                </label>
                                                <CountryDropdown
                                                    value={country}
                                                    onChange={(val) => setCountry(val)}
                                                    className="rounded-lg border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    State
                                                </label>
                                                <RegionDropdown
                                                    country={country}
                                                    value={state}
                                                    onChange={(val) => setState(val)}
                                                    className="rounded-lg border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label htmlFor="" className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Joining Date
                                                </label>
                                                <Calendar placeholder="Joining Date" className="mt-2" value={joiningDate} onChange={(e) => setJoiningDate(e.value)} id="icon" showIcon />
                                            </div>

                                            <div className="relative mb-4">
                                                <label htmlFor="" className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Confirmation Date
                                                </label>
                                                <Calendar placeholder="Joining Date" className="mt-2" value={confirmationDate} onChange={(e) => setConfirmationDate(e.value)} id="icon" showIcon />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Employee Class
                                                </label>
                                                <input
                                                    value={employeeClass}
                                                    onChange={(e) => setEmployeeClass(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="Employee Class"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Employee Type
                                                </label>
                                                <input
                                                    value={employeeType}
                                                    onChange={(e) => setEmployeeType(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="Employee Type"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Grade
                                                </label>
                                                <input
                                                    value={grade}
                                                    onChange={(e) => setGrade(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="Grade"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Band
                                                </label>
                                                <input
                                                    value={band}
                                                    onChange={(e) => setBand(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="Band"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label htmlFor="" className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    LWD
                                                </label>
                                                <Calendar placeholder="LWD" className="mt-2" value={lwd} onChange={(e) => setLwd(e.value)} id="icon" showIcon />
                                            </div>

                                            <div className="relative mb-4 flex items-center mt-3 ">
                                                <input
                                                    id="assignTargetFromHR"
                                                    defaultChecked={isAssignDirectTarget}
                                                    key={isAssignDirectTarget}
                                                    onChange={(e) => setIsAssignDirectTarget(e.target.checked)}
                                                    type="checkbox"
                                                    className="rounded mr-1 border-[#C8CBD0]" />

                                                <label htmlFor='assignTargetFromHR' className="text-[#344054] text-[16px] font-medium dark:text-[#FFFFFF] block ">
                                                    Assign Target from HR Admin Portal</label>
                                            </div>

                                            <div className="relative mb-4 flex items-center mt-3 justify-between">
                                                <div className='flex'>
                                                    <input
                                                        id='hrAccess'
                                                        defaultChecked={isHRAccess}
                                                        key={isHRAccess}
                                                        onChange={(e) => {
                                                            if (!e.target.checked) { setIsFinanceAccess(false) }
                                                            setIsHRAccess(e.target.checked)
                                                        }}
                                                        type="checkbox"
                                                        className="rounded mr-1 border-[#C8CBD0]" />

                                                    <label htmlFor="hrAccess" className="text-[#344054] text-[16px] font-medium dark:text-[#FFFFFF] block ">
                                                        HR Access
                                                    </label>
                                                </div>
                                                {
                                                    isHRAccess &&
                                                    <div className='flex'>
                                                        <input
                                                            id='financeAccess'
                                                            defaultChecked={isFinanceAccess}
                                                            key={isFinanceAccess}
                                                            onChange={(e) => setIsFinanceAccess(e.target.checked)}
                                                            type="checkbox"
                                                            className="rounded mr-1 border-[#C8CBD0]" />

                                                        <label htmlFor='financeAccess' className="text-[#344054] text-[16px] font-medium dark:text-[#FFFFFF] block ">
                                                            Finance Access
                                                        </label>
                                                    </div>
                                                }
                                                <div />
                                            </div>

                                            <div className="mt-2 col-span-2 text-center mb-5">

                                                <img className="mt-5 m-auto border border-[#029046] dark:border-[#000]" alt="" src={localPicturePath ? localPicturePath : profilePicPath ? profilePicPath : defaultProfilePath} style={myProfileImageStyle} />
                                                <button
                                                    onClick={browseProfilePic}
                                                    className=" mt-2 py-1 px-3  text-[#029046] transition ease-in duration-200 text-[14px] text-center font-normal  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#029046] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF] "
                                                >
                                                    {"Browse Picture"}
                                                    <input hidden id="fileProfilePic" type="file" onChange={changeProfilePicture} />
                                                </button>
                                            </div>

                                        </div>
                                        <div className="grow flex justify-center gap-3 mb-3">
                                            <Link to="/hr/settings/employee" className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]">Cancel</Link>
                                            <button onClick={() => saveEmployee()} className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">{showPleaseWaitButton ? 'Please wait...' : 'Submit'}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    ) : (
        <HRLogin sessionExpired={1} />
    );
}

const myProfileImageStyle = {
    borderRadius: "50%",
    width: '100px',
    height: '100px',
    marginTop: "5px"

};
