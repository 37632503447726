import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNav from '../../../../../components/common/hr/topnav.component';
import { json, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataTableCustomStyles } from '../../../../../components/helper/dataTable.config';
import { FinancialYear } from '../../../../../models';
import HRLogin from '../../../../../setup/auth/hr.component';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Auth } from 'aws-amplify';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import EditButton from '../../../../../components/common/buttons/EditButton';
import DeleteButton from '../../../../../components/common/buttons/DeleteButton';
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import moment from 'moment';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

export default function HrFinancialYear() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //list
    const [financialYearList, setFinancialYearList] = useState([]);
    const [financialYearFilterList, setFinancialYearFilterList] = useState([]);

    let navigateTo = useNavigate();

    //onclick Edit
    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/hr/settings/createfinancialyear/' + id);
    };

    //onclick Delete
    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        //const objDeleteFinancialYear = await DataStore.query(FinancialYear, id);
        const objDeleteFinancialYear = await graphQLFindRecordById('getFinancialYear', id);
        alertify.confirm('Confirmation', 'Do you want to delete this Financial Year?', async function () {
            //Delete Existing InterpreterRequest for this language
            //DataStore.delete(FinancialYear, (c) => c.id("eq", id))
            //Delete Financial Year
            //DataStore.delete(objDeleteFinancialYear);

            await API.graphql({
                query: mutations.deleteFinancialYear,
                variables: {
                    input: {
                        id: objDeleteFinancialYear.id,
                        _version: objDeleteFinancialYear._version,
                    }
                }
            });
            BindList();
            toast.success("Deleted");
        }
            , function () { });

    };

    //*Table Column Name
    const DataTableColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'From Date',
            selector: row => moment(row.fromDate).format("MM/DD/YYYY"),
            sortable: true,
        },
        {
            name: 'To Date',
            selector: row => moment(row.toDate).format("MM/DD/YYYY"),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <div className="flex gap-8">
                        <Link onClick={(e) => handleEditClick(e, row.id)} ><EditButton /></Link>
                        <Link onClick={(e) => handleDeleteClick(e, row.id)} ><DeleteButton /></Link>
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true
        }
    ];

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //Search Employee
    const searchFinancialYear = (searchedText) => {
        if (searchedText.length > 1) {
            let data = [...financialYearFilterList]
            let filteredData = data.filter(e =>
                e.name.toLowerCase().includes(searchedText.toLowerCase())
            )
            setFinancialYearList(filteredData)
        } else {
            let data = [...financialYearFilterList]
            setFinancialYearList(data)
        }
    }


    //BindList
    async function BindList() {
        // var financialYearResponses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        
        var financialYearResponses = await graphQLGetAllData('listFinancialYears');
        financialYearResponses = financialYearResponses.sort((a, b) => a.name.localeCompare(b.name));
        setFinancialYearList(financialYearResponses)
        setFinancialYearFilterList(financialYearResponses)
    }

    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    return (
        isAuthenticated ? (

            <div>
                <div className="pt-16">
                    <TopNav />
                    <LeftSideBar />
                    <div className="dashboard-main-wrapper pl-20 pr-4 mt-3">
                        <div className="flex flex-1 flex-wrap p-5 pb-4 px-0 top-action-wrapper">
                            <div className="md:flex xs:w-full sm:w-full md:w-auto flex-row align-center">
                                <div className="md:flex block md:col-span-2 mb-4">
                                </div>
                                <div className="md:flex block mb-4">
                                    <h2 className="page-title">Financial Years</h2>
                                </div>
                            </div>

                            <div className="md:flex flex-1 flex-row xs:w-full sm:w-full md:w-auto align-center justify-end md:ml-auto gap-3 right-side">
                                <div className="relative">
                                    <input onChange={(e) => searchFinancialYear(e.target.value)} type="text" id="required-search" className="rounded-md border border-[#D0D5DD] focus:border-transparent w-full py-2.5 pl-3 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#403E3C] dark:text-[#FFFFFF] dark:border-[#403E3C]" placeholder="Search..." />
                                </div>
                                <div className="md:flex block mb-4 gap-4">
                                    <Link to="/hr/settings" className="flex py-2 px-5 bg-[#FFF] hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-black transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]">
                                        Back
                                    </Link>
                                    <Link to="/hr/settings/createfinancialyear" className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF]">
                                        Create
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div>
                            <div className={"block w-full pt-5"}>
                                {
                                    <DataTable
                                        columns={DataTableColumns}
                                        data={financialYearList}
                                        customStyles={DataTableCustomStyles}
                                        pagination
                                        highlightOnHover
                                        paginationPerPage={10}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        ) : (
            <HRLogin sessionExpired={1} />
        )

    )
}



