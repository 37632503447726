import { DataStore } from '@aws-amplify/datastore';
import 'alertifyjs/build/css/alertify.css';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import capitalizeFirst from '../../../../../components/common/capitalizeFirst';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNav from '../../../../../components/common/hr/topnav.component';
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import { Parameters } from '../../../../../models';
import HRLogin from '../../../../../setup/auth/hr.component';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';

export default function CreateParameter() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [isEditMode, setEditMode] = useState(false);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editParameterId } = useParams()

    let navigateTo = useNavigate();

    //state
    const [parameterName, setParameterName] = useState("")
    const [definition, setDefinition] = useState("")
    const [criteria, setCriteria] = useState("")
    const [type, setType] = useState("")
    const [referenceID, setReferenceId] = useState("");

    //list
    const [base_type, setBaseType] = useState([])
    const types = [
        { "label": "Amount", "value": "A" },
        { "label": "Days", "value": "D" },
        { "label": "Percentage", "value": "P" },
        { "label": "Number", "value": "N" },
    ]
    const basetypes = [
        { "label": "Sale", "value": "S" },
        { "label": "Business", "value": "B" },
    ]

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }

            //Edit existing record
            if (editParameterId != null) {
                //const original = await DataStore.query(Parameters, editParameterId);
                const original = await graphQLFindRecordById('getParameters', editParameterId);

                if (original != null) {
                    setParameterName(original.name);
                    setDefinition(original.definition)
                    setCriteria(original.criteria)
                    setReferenceId(original.REF_CODE);
                    let findType = types.filter((e) => e.value === original.type)
                    setType({ label: findType[0]["label"], value: findType[0]["value"] })

                    if (original.base_type) {
                        let baseTypes = original.base_type.split(",")
                        let bindBaseTypes = []
                        baseTypes.forEach((baseTypeValue) => {
                            let findbaseType = basetypes.filter((e) => e.value === baseTypeValue)
                            if (findbaseType) {
                                bindBaseTypes.push(findbaseType[0])
                            }
                        })
                        setBaseType(bindBaseTypes)
                    }
                    setEditMode(true);
                }
                else {
                    toast.error('Invalid record.');
                    navigateTo("/admin/settings/languages");
                }

            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
    }, []);


    const saveParameter = async () => {
        if (parameterName === "") { toast.error("Please enter name."); return; }
        else if (base_type.length < 1) { toast.error("Please select type."); return; }
        else if (type === "") { toast.error("Please select unit."); return; }

        var selectedTypes = base_type.map(function (el) { return el.value; });
        if (isEditMode) {

            // const original = await DataStore.query(Parameters, editParameterId);
            const original =  await graphQLFindRecordById('getParameters', editParameterId);

            // await DataStore.save(
            //     Parameters.copyOf(original, (updated) => {
            //         updated.name = capitalizeFirst(parameterName);
            //         updated.definition = definition !== null ? capitalizeFirst(definition) : "";
            //         updated.criteria = criteria !== null ? capitalizeFirst(criteria) : "";
            //         updated.type = type.value;
            //         updated.base_type = selectedTypes.toString().replace(/[\]']+/g, '')
            //         updated.REF_CODE = (referenceID);
            //     })
            // );

            await API.graphql({
                query: mutations.updateParameters,
                variables: {
                    input: {
                        id: original.id,
                        name: capitalizeFirst(parameterName),
                        definition: definition !== null ? capitalizeFirst(definition) : "",
                        criteria: criteria !== null ? capitalizeFirst(criteria) : "",
                        type: type.value,
                        base_type: selectedTypes.toString().replace(/[\]']+/g, ''),
                        REF_CODE: (referenceID),
                        _version: original._version,
                    }
                }
            });

            toast.success("Updated");
        } else {
            //Save Interpreter
            // await DataStore.save(
            //     new Parameters({
            //         "name": capitalizeFirst(parameterName),
            //         "definition": capitalizeFirst(definition),
            //         "criteria": capitalizeFirst(criteria),
            //         "type": (type.value),
            //         "base_type": selectedTypes.toString().replace(/[\]']+/g, ''),
            //         " REF_CODE": (referenceID),

            //     })
            // );

            await API.graphql(
                graphqlOperation(mutations.createParameters, {
                    input: {
                        name: capitalizeFirst(parameterName),
                    definition: capitalizeFirst(definition),
                    criteria: capitalizeFirst(criteria),
                    type: (type.value),
                    base_type: selectedTypes.toString().replace(/[\]']+/g, ''),
                     REF_CODE: (referenceID),
                    }
                })
            );
            toast.success("Added");
        }
        navigateTo("/hr/settings/parameters")
    }

    return isAuthenticated ?
        <div>
            <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full">
                <TopNav />
                <LeftSideBar />
                <div
                    className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                    <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                        <div className="p-3 py-5 bg-white dark:bg-[#2A2E32] rounded-md shadow">
                            <div className="flex justify-center mt-3 mb-2">
                                <h2 className="page-title">
                                    {isEditMode ? "Edit" : "Create"} Parameter
                                </h2>
                            </div>
                            <div x-data="{tab: 1}"></div>
                            <div className={"block w-full"}>
                                <div className="px-4" x-show="tab === 1">
                                    <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">

                                        <div className="relative mb-4 col-span-1">
                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                Name<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                    * </label></label>
                                            <input
                                                value={parameterName}
                                                onChange={e => setParameterName(e.target.value)}
                                                type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]  " placeholder="Name" />
                                        </div>

                                        <div className="relative mb-4 col-span-1">
                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                Ref_Code<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                </label></label>
                                            <input
                                                value={referenceID}
                                                onChange={e => setReferenceId(e.target.value)}
                                                type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]  " placeholder="Ref Code" />
                                        </div>

                                        <div className="relative mb-4">

                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                Type <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                    * </label>
                                            </label>

                                            <Select
                                                isMulti
                                                value={base_type}
                                                options={basetypes}
                                                onChange={e => { setBaseType(e) }}
                                                className="mt-2 my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>

                                        <div className="relative mb-4">

                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                Unit <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                    * </label>
                                            </label>

                                            <Select
                                                value={type}
                                                options={types}
                                                onChange={e => { setType(e) }}
                                                className="mt-2 my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />


                                        </div>

                                        <div className="relative mb-4">
                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                Definition </label>
                                            <textarea
                                                value={definition}
                                                rows="7"
                                                onChange={e => setDefinition(e.target.value)}
                                                type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]"
                                                placeholder="Definition" />

                                        </div>

                                        <div className="relative mb-4">
                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                Criteria
                                            </label>

                                            <textarea
                                                value={criteria}
                                                onChange={(e) => setCriteria(e.target.value)}
                                                type="text"
                                                rows="7"
                                                className="text-[16px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]  "
                                                placeholder="Criteria"
                                            />
                                        </div>
                                    </div>
                                    <div className="grow flex justify-center gap-3 mb-3">
                                        <Link to="/hr/settings/parameters" className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#4A5056] dark:text-white">Cancel</Link>
                                        <button onClick={() => saveParameter()} className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">{showPleaseWaitButton ? 'Please wait...' : 'Submit'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
        : <HRLogin sessionExpired={1} />
}
