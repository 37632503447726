import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { json, Link, useNavigate, useParams } from 'react-router-dom';
import PlanReviewTopImage from '../../../../assets/svg/Plan_Review.svg';
import LeftNav from '../../../../components/common/employee/leftnav.component';
import TopNav from '../../../../components/common/employee/topnav.component';
import {
    ConvertResponseForSelect, GetTotalPercentageFromPlanArray,
    GetTotalTargetValueFromPlanArray, RemoveItemFromArrayByIdValue, SetBrandAggregateValueFromPlanArray, SetCountryAggregateValueFromPlanArray, SetCustomerAggregateValueFromPlanArray, SetCustomerSegmentAggregateValueFromPlanArray, SetProductGroupAggregateValueFromPlanArray, SetRegionAggregateValueFromPlanArray, SetBuAggregateValueFromPlanArray, SetGTMAggregateValueFromPlanArray, SetSubGTMAggregateValueFromPlanArray
} from '../../../../components/helper/commonfunctions';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import EmpLogin from "../../../../setup/auth/emp.component";

import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';
import uniqId from 'uniqid';

import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Storage } from 'aws-amplify';
import moment from 'moment';
import { Accordion, AccordionTab } from 'primereact/accordion';
import defaultImagePath from '../../../../assets/images/defaultProfile.jpg';
import { Brand, Country, Customer, CustomerCategory, Employee, Parameters, Plan, RelationshipDetails, ProductGroup, Region, Roles, SBU, TargetDetails, TargetMessage, Targets, Relationship, FinancialYear, BIUnit, GTM, SubGTM } from '../../../../models';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { Avatar } from '@mui/material';
import { sendNotification } from '../../../../components/helper/notification';
import { NOTIFICATIONS_TYPES, PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES, EMAIL_SUBJECTS } from '../../../../components/helper/enum'
import { ShowInMillion, commaSeparated, sumFromArray } from '../../../../components/helper/maths';
import { ProgressSpinner } from 'primereact/progressspinner';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../src/components/helper/graphQLFunctions'
import * as mutations from '../../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';


export default function ManageCommit_AnnualTarget_PlanReview() {

    let { financialYearId, employeeId, parameterId } = useParams();
    var loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId');
    const navigateTo = useNavigate()

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //states employee
    const [employeeName, setEmployeeName] = useState('');
    const [employeeID, setEmployeeID] = useState('');
    const [employeeValue, setEmployeeValue] = useState('');
    const [employeeRole, setEmployeeRol] = useState('');
    const [profilePicPath, setProfilePicPath] = useState('');
    const [parametersList, setParametersList] = useState([]);
    const [renderItems, setRenderItems] = useState(false);
    const [hideApproveAll, setHideApproveAll] = useState(false);

    //On Load Functions
    const [financialYear, setFinancialYear] = useState("");

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    const sbuBodyTemplate = (rowData) => {
        return getSBULabel(rowData.sbuId);
    }
    //Region
    //----------------------
    const [regionList, SetRegionList] = useState([]);
    const [regionDropdownList, setRegionDropdownList] = useState([]);

    const getRegionLabel = (id) => {
        let _regionList = [...regionList];
        var returnRecord = _regionList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const regionBodyTemplate = (rowData) => {
        // return getRegionLabel(rowData.regionId);
        return <label className={rowData.isPresentInOtherSubArray ? "strike" : ""} >{getRegionLabel(rowData.regionId)}</label>

    }

    //Country
    //----------------------
    const [countryList, SetCountryList] = useState([]);
    const [countryDropdownList, setCountryDropdownList] = useState([]);


    const getCountryLabel = (id) => {
        let _countryList = [...countryList];
        var returnRecord = _countryList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }
    const countryBodyTemplate = (rowData) => {
        // return getCountryLabel(rowData.countryId);
        return <label className={rowData.isPresentInOtherSubArray ? "strike" : ""}>{getCountryLabel(rowData.countryId)}</label>

    }
    //----------------------

    //Brand
    //----------------------
    const [brandList, SetBrandList] = useState([]);
    const [brandDropdownList, setBrandDropdownList] = useState([]);

    const getBrandLabel = (id) => {
        let _brandList = [...brandList];
        var returnRecord = _brandList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const brandBodyTemplate = (rowData) => {
        return <label className={rowData.isPresentInOtherSubArray ? "strike" : ""}>{getBrandLabel(rowData.brandId)}</label>
    }
    //----------------------

    //Product Group
    //----------------------
    const [productGroupList, SetProductGroupList] = useState([]);
    const [productGroupDropdownList, setProductGroupDropdownList] = useState([]);

    const getProductGroupLabel = (id) => {
        let _productGroupList = [...productGroupList];
        var returnRecord = _productGroupList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const productGroupBodyTemplate = (rowData) => {
        return <label className={rowData.isPresentInOtherSubArray ? "strike" : ""}>{getProductGroupLabel(rowData.productGroupId)}</label>
    }
    //----------------------

    //Customer Group
    //----------------------
    const [customerGroupList, SetCustomerGroupList] = useState([]);
    const [customerGroupDropdownList, setCustomerGroupDropdownList] = useState([]);

    const getCustomerGroupLabel = (id) => {
        let _customerGroupList = [...customerGroupList];
        var returnRecord = _customerGroupList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const customerGroupBodyTemplate = (rowData) => {
        return <label className={rowData.isPresentInOtherSubArray ? "strike" : ""}>{getCustomerGroupLabel(rowData.customerGroupId)}</label>
    }
    //----------------------

    //Customer
    //----------------------
    const [customerList, SetCustomerList] = useState([]);
    const [customerDropdownList, setCustomerDropdownList] = useState([]);

    const getCustomerLabel = (id) => {
        let _customerList = [...customerList];
        var returnRecord = _customerList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const customerBodyTemplate = (rowData) => {
        return <label className={rowData.isPresentInOtherSubArray ? "strike" : ""} >{getCustomerLabel(rowData.customerId)}</label>
    }
    //----------------------

    //SBU
    //----------------------
    const [sbuList, SetSbuList] = useState([]);
    const [sbuDropdownList, setSbuDropdownList] = useState([]);

    const getSBULabel = (id) => {
        let _sbuList = [...sbuList];
        var returnRecord = _sbuList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }
    //----------------------

    //BU
    //----------------------
    const [buList, SetBuList] = useState([]);
    const [buDropdownList, setBuDropdownList] = useState([]);


    const getBULabel = (id) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const buBodyTemplate = (rowData) => {
        // let buName = getBULabel(rowData.buId);
        return <label className={rowData.isPresentInOtherSubArray ? "strike" : ""}>{getBULabel(rowData.buId)}</label>
        // return <label>{buName}</label>
    }

    //----------------------

    //GTM
    //----------------------
    const [gtmList, setGtmList] = useState([]);


    const getGTMLabel = (id) => {
        let _buList = [...gtmList];
        var returnRecord = _buList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const gtmBodyTemplate = (rowData) => {
        // let buName = getBULabel(rowData.buId);
        return <label className={rowData.isPresentInOtherSubArray ? "strike" : ""}>{getGTMLabel(rowData.gtmId)}</label>
        // return <label>{buName}</label>
    }

    //----------------------

    //SubGTM
    //----------------------
    const [subGtmList, setSubGTMList] = useState([]);


    const getSubGTMLabel = (id) => {
        let _buList = [...subGtmList];
        var returnRecord = _buList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const subGtmBodyTemplate = (rowData) => {
        // let buName = getBULabel(rowData.buId);
        return <label className={rowData.isPresentInOtherSubArray ? "strike" : ""}>{getSubGTMLabel(rowData.subGtmId)}</label>
        // return <label>{buName}</label>
    }

    //----------------------







    const id = useParams();

    async function LoadPageData() {
        //set logged Employee id
        let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')

        //Financial Year
        // const currentFinancialYear = await DataStore.query(FinancialYear, financialYearId);
        const currentFinancialYear = await graphQLFindRecordById('getFinancialYear', financialYearId);
        setFinancialYear(currentFinancialYear.name)

        //set employee data
        // var employeeDetails = await DataStore.query(Employee, (c) => c.id.eq(employeeId), {
        //     sort: s => s.firstName(SortDirection.ASCENDING)
        // })
        var employeeDetails = await graphQLFindRecordById('getEmployee', employeeId);
        employeeDetails = employeeDetails.sort((a,b)=>a.firstName.localeCompare(b.firstName));
        setEmployeeName(employeeDetails.firstName + " " + employeeDetails.lastName)
        setEmployeeID(employeeDetails.employeeId)
        setEmployeeValue(employeeDetails.id)
        await Storage.get(employeeDetails.profilePic)
            .then(result => {
                setProfilePicPath(result)
            });

        // set employee role
        // var roleDetails = await DataStore.query(Roles, employeeDetails.role_id)
        var roleDetails = await graphQLFindRecordById('getRoles', employeeDetails.role_id);

        setEmployeeRol(roleDetails.name)

        //get target response
        // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.employee_id.eq(employeeId),
        //     c.financial_year_id.eq(financialYearId),
        //     c.report_manager_employee_id.eq(loggedEmployeeId)
        // ]));

        var targetsResponses = await graphQLGetAllData('listTargets', [
            { employee_id: { eq: employeeId } },
            { report_manager_employee_id: { eq: loggedEmployeeId } },
            { financial_year_id: { eq: financialYearId } }
        ]);

        // var relationshipResponse = await DataStore.query(Relationship, (c) => c.and(c => [
        //     c.role_id.eq(targetsResponses[0].role_id),
        //     c.financial_year_id.eq(financialYearId)
        // ]));

        var relationshipResponse = await graphQLGetAllData('listRelationships', [
            { role_id: { eq: targetsResponses[0].role_id } },
            { financial_year_id: { eq: financialYearId } }
        ]);

        var relationshipDetailResponses = []
        if (relationshipResponse.length > 0) {
            // relationshipDetailResponses = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationshipResponse[0].id));
            relationshipDetailResponses = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: relationshipResponse[0].id } }
            ]);
        }

        // var targetDetails = await DataStore.query(TargetDetails, (c) => c.target_id.eq(targetsResponses[0].id))

        var targetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: targetsResponses[0].id } }]);

        //declare final array
        let finalArray = []
        for (let i = 0; i < targetDetails.length; i++) {
            //set local arrays
            let buDimensionArray = []
            let regionDimensionArray = []
            let countryDimensionArray = []
            let brandDimensionArray = []
            let pGDimensionArray = []
            let customerSegmentDimensionArray = []
            let customerDimensionArray = []

            // var parametersDetails = await DataStore.query(Parameters, (c) => c.id.eq(targetDetails[i].parameter_id))
            var parametersDetails = await graphQLFindRecordById('getParameters', targetDetails[i].parameter_id);

            // let planData = await DataStore.query(Plan, (c) => c.target_detail_id.eq(targetDetails[i].id))

            let planData = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: targetDetails[i].id } }]);


            //bind plan data
            let currentPlanArray = []
            var totalAddedTarget = 0, q1Total = 0, q2Total = 0, q3Total = 0, q4Total = 0;
            if (planData.length > 0) {
                //calculate current plan
                planData.map((currentItem) => {
                    if (currentItem.target_value > 0 && currentItem.q1_target_value > 0 && currentItem.q2_target_value > 0 && currentItem.q3_target_value > 0 && currentItem.q4_target_value > 0) {
                        if (parseFloat(currentItem.target_value) > 0) { totalAddedTarget = totalAddedTarget + parseFloat(currentItem.target_value); }
                        if (parseFloat(currentItem.q1_target_value) > 0) { q1Total = q1Total + parseFloat(currentItem.q1_target_value); }
                        if (parseFloat(currentItem.q2_target_value) > 0) { q2Total = q2Total + parseFloat(currentItem.q2_target_value); }
                        if (parseFloat(currentItem.q3_target_value) > 0) { q3Total = q3Total + parseFloat(currentItem.q3_target_value); }
                        if (parseFloat(currentItem.q4_target_value) > 0) { q4Total = q4Total + parseFloat(currentItem.q4_target_value); }
                    }

                    let currentPlanData = {
                        "id": currentItem.id, "sbuId": currentItem.sbu_id, "buId": currentItem.bu_id, "regionId": currentItem.region_id, "countryId": currentItem.country_id,
                        "brandId": currentItem.brand_id, "productGroupId": currentItem.product_group_id,
                        "customerGroupId": currentItem.customer_group_id, "customerId": currentItem.customer_id, "targetValue": commaSeparated(currentItem.target_value),
                        "Q1TargetValue": commaSeparated(currentItem.q1_target_value), "Q2TargetValue": commaSeparated(currentItem.q2_target_value), "Q3TargetValue": commaSeparated(currentItem.q3_target_value),
                        "Q4TargetValue": commaSeparated(currentItem.q4_target_value), "disEqually": 0,
                        "gtmId": currentItem.gtm_Id, "subGtmId": currentItem.sub_gtm_id
                    }
                    currentPlanArray.push(currentPlanData)
                })
            }
            else {

                let currentPlanData = {
                    "id": uniqId(), "sbuId": "", "buId": "", "regionId": "", "countryId": "", "brandId": "", "productGroupId": "", "customerGroupId": "", "customerId": "", "targetValue": "",
                    "Q1TargetValue": "", "Q2TargetValue": "", "Q3TargetValue": "", "Q4TargetValue": "", "disEqually": 0,
                }
                currentPlanArray.push(currentPlanData)
                totalAddedTarget = 0
                q1Total = 0
                q2Total = 0
                q3Total = 0
                q4Total = 0
            }

            ///Get Bu Dimension
            let totalBuDimension = ""
            var buDimension = SetBuAggregateValueFromPlanArray(currentPlanArray)
            var sumOfRegion = sumFromArray('targetValue', buDimension);
            if (buDimension.length > 0) {
                for (let j = 0; j < buDimension.length; j++) {
                    // var buResponses = await DataStore.query(BIUnit, Predicates.ALL, {
                    //     sort: s => s.name(SortDirection.ASCENDING)
                    // });
                    var buResponses = await graphQLGetAllData('listBIUnits')
                    buResponses = buResponses.sort((a, b) => a.name.localeCompare(b.name));
                    var returnRecord = buResponses.find(o => o.id === buDimension[j].buId);
                    if (returnRecord != null) {
                        let Percentage = (buDimension[j].targetValue * 100) / sumOfRegion;
                        let data = {
                            "BU": returnRecord.name,
                            "Percentage": Percentage,
                            "DisplayPercentage": Percentage.toFixed(2) + '%',
                        }
                        buDimensionArray.push(data)
                    }
                }
            }
            totalBuDimension = GetTotalPercentageFromPlanArray(buDimensionArray)

            ///Get Region Dimension
            let totalRegionDimension = ""

            var regionDimension = SetRegionAggregateValueFromPlanArray(currentPlanArray)
            var sumOfRegion = sumFromArray('targetValue', regionDimension);
            if (regionDimension.length > 0) {
                for (let j = 0; j < regionDimension.length; j++) {
                    // var regionResponses = await DataStore.query(Region, Predicates.ALL, {
                    //     sort: s => s.name(SortDirection.ASCENDING)
                    // });
                    var regionResponses = await graphQLGetAllData('listRegions')
                    regionResponses = regionResponses.sort((a, b) => a.name.localeCompare(b.name));

                    var returnRecord = regionResponses.find(o => o.id === regionDimension[j].regionId);
                    if (returnRecord != null) {
                        let Percentage = (regionDimension[j].targetValue * 100) / sumOfRegion;
                        let data = {
                            "Region": returnRecord.name,
                            "Percentage": Percentage,
                            "DisplayPercentage": Percentage.toFixed(2) + '%',
                        }
                        regionDimensionArray.push(data)
                    }
                }
            }
            totalRegionDimension = GetTotalPercentageFromPlanArray(regionDimensionArray)

            ///Get Country Dimension
            let totalCountryDimension = ""
            var countryDimension = SetCountryAggregateValueFromPlanArray(currentPlanArray)
            var sumOfCountry = sumFromArray('targetValue', countryDimension);
            if (countryDimension.length > 0) {
                for (let j = 0; j < countryDimension.length; j++) {
                    // var countryResponses = await DataStore.query(Country, Predicates.ALL, {
                    //     sort: s => s.name(SortDirection.ASCENDING)
                    // });
                    var countryResponses = await graphQLGetAllData('listCountries')
                    countryResponses = countryResponses.sort((a, b) => a.name.localeCompare(b.name));

                    var returnRecord = countryResponses.find(o => o.id === countryDimension[j].countryId);
                    if (returnRecord != null) {
                        let Percentage = (countryDimension[j].targetValue * 100) / sumOfCountry;
                        let data = {
                            "Country": returnRecord.name,
                            "Percentage": Percentage,
                            "DisplayPercentage": Percentage.toFixed(2) + '%',
                        }
                        countryDimensionArray.push(data)
                    }
                }
            }
            totalCountryDimension = GetTotalPercentageFromPlanArray(countryDimensionArray)

            ///Get Brand Dimension
            let totalBrandDimension = ""
            var brandDimension = SetBrandAggregateValueFromPlanArray(currentPlanArray)
            var sumOfBrand = sumFromArray('targetValue', brandDimension);
            if (brandDimension.length > 0) {
                for (let j = 0; j < brandDimension.length; j++) {
                    // var brandResponses = await DataStore.query(Brand, Predicates.ALL, {
                    //     sort: s => s.name(SortDirection.ASCENDING)
                    // });
                    var brandResponses = await graphQLGetAllData('listBrands')
                    brandResponses = brandResponses.sort((a, b) => a.name.localeCompare(b.name));
                    var returnRecord = brandResponses.find(o => o.id === brandDimension[j].brandId);
                    if (returnRecord != null) {
                        let Percentage = (brandDimension[j].targetValue * 100) / sumOfBrand;
                        let data = {
                            "Brand": returnRecord.name,
                            "Percentage": Percentage,
                            "DisplayPercentage": Percentage.toFixed(2) + '%',
                        }
                        brandDimensionArray.push(data)
                    }
                }
            }
            totalBrandDimension = GetTotalPercentageFromPlanArray(brandDimensionArray)

            //Get GTM Dimension
            let totalGTMDimension = ""
            var gtmDimensionArray = [];
            var GtmDimension = SetGTMAggregateValueFromPlanArray(currentPlanArray)
            var sumOfGTM = sumFromArray('targetValue', GtmDimension);
            if (GtmDimension.length > 0) {
                for (let j = 0; j < GtmDimension.length; j++) {
                    // var brandResponses = await DataStore.query(GTM, Predicates.ALL);
                    var brandResponses = await graphQLGetAllData('listGTMS')
                    var returnRecord = brandResponses.find(o => o.id === GtmDimension[j].gtmId);
                    if (returnRecord != null) {
                        let Percentage = (GtmDimension[j].targetValue * 100) / sumOfGTM;
                        let data = {
                            "GTM": returnRecord.name,
                            "Percentage": Percentage,
                            "DisplayPercentage": Percentage.toFixed(2) + '%',
                        }
                        gtmDimensionArray.push(data)
                    }
                }
            }
            totalGTMDimension = GetTotalPercentageFromPlanArray(gtmDimensionArray)

            //Get GTM Dimension
            let totalSubGTMDimension = ""
            var subGtmDimensionArray = [];
            var SubGtmDimension = SetSubGTMAggregateValueFromPlanArray(currentPlanArray)
            var sumOfSubGTM = sumFromArray('targetValue', SubGtmDimension);
            if (SubGtmDimension.length > 0) {
                for (let j = 0; j < SubGtmDimension.length; j++) {
                    // var brandResponses = await DataStore.query(SubGTM, Predicates.ALL);

                    var brandResponses = await graphQLGetAllData('listSubGTMS')
                    var returnRecord = brandResponses.find(o => o.id === SubGtmDimension[j].subGtmId);
                    if (returnRecord != null) {
                        let Percentage = (SubGtmDimension[j].targetValue * 100) / sumOfSubGTM;
                        let data = {
                            "GTM": returnRecord.name,
                            "Percentage": Percentage,
                            "DisplayPercentage": Percentage.toFixed(2) + '%',
                        }
                        subGtmDimensionArray.push(data)
                    }
                }
            }
            totalSubGTMDimension = GetTotalPercentageFromPlanArray(subGtmDimensionArray)


            ///Get Product Group Dimension
            let totalPGDimension = ""
            var pGDimension = SetProductGroupAggregateValueFromPlanArray(currentPlanArray)
            var sumOfPG = sumFromArray('targetValue', pGDimension);
            if (pGDimension.length > 0) {
                for (let j = 0; j < pGDimension.length; j++) {
                    // var pGResponses = await DataStore.query(ProductGroup, Predicates.ALL, {
                    //     sort: s => s.name(SortDirection.ASCENDING)
                    // });
                    var pGResponses = await graphQLGetAllData('listProductGroups')
                    pGResponses = pGResponses.sort((a, b) => a.name.localeCompare(b.name));

                    var returnRecord = pGResponses.find(o => o.id === pGDimension[j].productGroupId);
                    if (returnRecord != null) {
                        let Percentage = (pGDimension[j].targetValue * 100) / sumOfPG;
                        let data = {
                            "ProductGroup": returnRecord.name,
                            "Percentage": Percentage,
                            "DisplayPercentage": Percentage.toFixed(2) + '%',
                        }
                        pGDimensionArray.push(data)
                    }
                }
            }
            totalPGDimension = GetTotalPercentageFromPlanArray(pGDimensionArray)

            ///Get Customer Segment Dimension
            let totalCustomerSegmentDimension = ""
            var customerSegmentDimension = SetCustomerSegmentAggregateValueFromPlanArray(currentPlanArray)
            var sumOfCustomerSegment = sumFromArray('targetValue', customerSegmentDimension);
            if (customerSegmentDimension.length > 0) {
                for (let j = 0; j < customerSegmentDimension.length; j++) {

                    // var customerSegmentResponses = await DataStore.query(CustomerCategory, Predicates.ALL, {
                    //     sort: s => s.name(SortDirection.ASCENDING)
                    // });
                    var customerSegmentResponses = await graphQLGetAllData('listCustomerCategories')
                    customerSegmentResponses = customerSegmentResponses.sort((a, b) => a.name.localeCompare(b.name));
                    var returnRecord = customerSegmentResponses.find(o => o.id === customerSegmentDimension[j].customerGroupId);
                    if (returnRecord != null) {
                        let Percentage = (customerSegmentDimension[j].targetValue * 100) / sumOfCustomerSegment;
                        let data = {
                            "CustomerSegment": returnRecord.name,
                            "Percentage": Percentage,
                            "DisplayPercentage": Percentage.toFixed(2) + '%',
                        }
                        customerSegmentDimensionArray.push(data)
                    }
                }
            }
            totalCustomerSegmentDimension = GetTotalPercentageFromPlanArray(customerSegmentDimensionArray)

            ///Get Customer Segment Dimension
            let totalCustomerDimension = ""
            var customerDimension = SetCustomerAggregateValueFromPlanArray(currentPlanArray)
            var sumOfCustomer = sumFromArray('targetValue', customerDimension);
            if (customerDimension.length > 0) {
                for (let j = 0; j < customerDimension.length; j++) {
                    // var customerResponses = await DataStore.query(Customer, Predicates.ALL, {
                    //     sort: s => s.name(SortDirection.ASCENDING)
                    // });
                    var customerResponses = await graphQLGetAllData('listCustomers')
                    customerResponses = customerResponses.sort((a, b) => a.name.localeCompare(b.name));
                    var returnRecord = customerResponses.find(o => o.id === customerDimension[j].customerId);
                    if (returnRecord != null) {
                        let Percentage = (customerDimension[j].targetValue * 100) / sumOfCustomer;
                        let data = {
                            "Customer": returnRecord.name,
                            "Percentage": Percentage,
                            "DisplayPercentage": Percentage.toFixed(2) + '%',
                        }
                        customerDimensionArray.push(data)
                    }
                }
            }
            totalCustomerDimension = GetTotalPercentageFromPlanArray(customerDimensionArray)

            let findParameter = relationshipDetailResponses.find((e) => e.parameter_id === parametersDetails.id)

            //Target Message list
            // var targetMessageResponses = await DataStore.query(TargetMessage, (c) => c.target_detail_id.eq(targetDetails[i].id), {
            //     sort: s => s.messageDateTime(SortDirection.ASCENDING)
            // });

            var targetMessageResponses = await graphQLGetAllData('listTargetMessages', [{ target_detail_id: { eq: targetDetails[i].id } }]);


            //final data
            let data = {
                "id": targetDetails[i].id,
                "targetDetailId": targetDetails[i].id,
                "parameterId": parametersDetails.id,
                "parameterName": parametersDetails.name,
                "parameterDefinition": parametersDetails.definition,
                "parameterCriteria": parametersDetails.criteria,
                "parameterType": parametersDetails.type === PARAMETERS_TYPES.Amount ? PARAMETERS_TYPES_NAMES.A : parametersDetails.type === PARAMETERS_TYPES.Days ? PARAMETERS_TYPES_NAMES.D : parametersDetails.type === PARAMETERS_TYPES.Percentage ? PARAMETERS_TYPES_NAMES.P : PARAMETERS_TYPES_NAMES.N,
                "parameterBase_type": parametersDetails.base_type,
                "planData": currentPlanArray,
                "totalAddedTarget": parametersDetails.type === PARAMETERS_TYPES.Amount ? commaSeparated(totalAddedTarget.toFixed(2)) : (totalAddedTarget.toFixed(2) / currentPlanArray.length).toFixed(2),
                "q1Total": parametersDetails.type === PARAMETERS_TYPES.Amount ? commaSeparated(q1Total.toFixed(2)) : (q1Total / currentPlanArray.length).toFixed(2),
                "q2Total": parametersDetails.type === PARAMETERS_TYPES.Amount ? commaSeparated(q2Total.toFixed(2)) : (q2Total / currentPlanArray.length).toFixed(2),
                "q3Total": parametersDetails.type === PARAMETERS_TYPES.Amount ? commaSeparated(q3Total.toFixed(2)) : (q3Total / currentPlanArray.length).toFixed(2),
                "q4Total": parametersDetails.type === PARAMETERS_TYPES.Amount ? commaSeparated(q4Total.toFixed(2)) : (q4Total / currentPlanArray.length).toFixed(2),
                //"targetValue": targetDetails[i].target_value,
                "targetValue": parametersDetails.type === PARAMETERS_TYPES.Days ? Math.floor(targetDetails[i].target_value) : parametersDetails.type === PARAMETERS_TYPES.Amount ? ShowInMillion(parseInt(targetDetails[i].target_value)) : targetDetails[i].target_value,
                "is_approve": targetDetails[i].is_approve,
                "is_revision_by_employee": targetDetails[i].is_revision_by_employee,
                "revisionValue": targetDetails[i].revision_value,
                "is_accepted_by_employee": targetDetails[i].is_accepted_by_employee,
                "isSendForRevisionByReportingManage": targetDetails[i].isSendForRevisionByReportingManage,
                "messagingData": targetMessageResponses,
                "buDimensionArray": buDimensionArray,
                "gtmDimensionArray": gtmDimensionArray,
                "totalGTMDimension": totalGTMDimension,
                "subGtmDimensionArray": subGtmDimensionArray,
                "totalSubGTMDimension": totalSubGTMDimension,
                "totalBuDimension": totalBuDimension,
                "regionDimensionArray": regionDimensionArray,
                "totalRegionDimension": totalRegionDimension,
                "countryDimensionArray": countryDimensionArray,
                "totalCountryDimension": totalCountryDimension,
                "brandDimensionArray": brandDimensionArray,
                "totalBrandDimension": totalBrandDimension,
                "pGDimensionArray": pGDimensionArray,
                "totalPGDimension": totalPGDimension,
                "customerSegmentDimensionArray": customerSegmentDimensionArray,
                "totalCustomerSegmentDimension": totalCustomerSegmentDimension,
                "customerDimensionArray": customerDimensionArray,
                "totalCustomerDimension": totalCustomerDimension,
                "isPlanMandatory": findParameter ? 1 : 0,
                "openRemark": false,
                "isSendForRevisionByReportingManage_Checkbox": targetDetails[i].isSendForRevisionByReportingManage ? true : false,
                "markForApprove": false,
            }
            finalArray.push(data)
            if (i === targetDetails.length - 1) {
                let sortArray = finalArray.sort((a, b) => (a.parameterName > b.parameterName ? 1 : -1))
                var checkForApprove = sortArray.some(e => e.is_approve)
                if (checkForApprove) { setHideApproveAll(true) }
                setParametersList(sortArray)
                setRenderItems(true)
            }
        }
    }

    async function BindList() {

        //SBU
        // var SBUResponses = await DataStore.query(SBU, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var SBUResponses = await graphQLGetAllData('listSBUS')
        SBUResponses = SBUResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetSbuList(SBUResponses)
        var myArray = []; myArray = ConvertResponseForSelect(SBUResponses); setSbuDropdownList(myArray);

        //Region
        // var regionResponses = await DataStore.query(Region, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var regionResponses = await graphQLGetAllData('listRegions')
        regionResponses = regionResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetRegionList(regionResponses)
        myArray = []; myArray = ConvertResponseForSelect(regionResponses); setRegionDropdownList(myArray);

        //Country
        // var countryResponses = await DataStore.query(Country, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var countryResponses = await graphQLGetAllData('listCountries')
        countryResponses = countryResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetCountryList(countryResponses)
        myArray = []; myArray = ConvertResponseForSelect(countryResponses); setCountryDropdownList(myArray);

        //Brand
        // var brandResponses = await DataStore.query(Brand, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var brandResponses = await graphQLGetAllData('listBrands')
        brandResponses = brandResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetBrandList(brandResponses)
        myArray = []; myArray = ConvertResponseForSelect(brandResponses); setBrandDropdownList(myArray);

        //Product Group
        // var pGResponses = await DataStore.query(ProductGroup, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var pGResponses = await graphQLGetAllData('listProductGroups')
        pGResponses = pGResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetProductGroupList(pGResponses)
        myArray = []; myArray = ConvertResponseForSelect(pGResponses); setProductGroupDropdownList(myArray);

        //Customer Group
        // var customerGroupResponses = await DataStore.query(CustomerCategory, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var customerGroupResponses = await graphQLGetAllData('listCustomerCategories')
        customerGroupResponses = customerGroupResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetCustomerGroupList(customerGroupResponses)
        myArray = []; myArray = ConvertResponseForSelect(customerGroupResponses); setCustomerGroupDropdownList(myArray);

        //Customer
        // var customerResponses = await DataStore.query(Customer, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var customerResponses = await graphQLGetAllData('listCustomers')
        customerResponses = customerResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetCustomerList(customerResponses)
        myArray = []; myArray = ConvertResponseForSelect(customerResponses); setCustomerDropdownList(myArray);

        // var buResponses = await DataStore.query(BIUnit, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var buResponses = await graphQLGetAllData('listBIUnits')
        buResponses = buResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetBuList(buResponses)
        var myArray = []; myArray = ConvertResponseForSelect(buResponses); setBuDropdownList(myArray);

        // var gtmResponses = await DataStore.query(GTM, Predicates.ALL);
        var gtmResponses = await graphQLGetAllData('listGTMS')
        setGtmList(gtmResponses)
        // var subGtmResponses = await DataStore.query(SubGTM, Predicates.ALL); 
        var subGtmResponses = await graphQLGetAllData('listSubGTMS')
        setSubGTMList(subGtmResponses)

        LoadPageData()
    }

    //----------------------

    //Message
    //--------------------------------------------
    const [message, setMessage] = useState('');
    const [msgUnreadCount, setMsgUnreadCount] = useState('');

    async function SendMessage(targetDetailId, data) {
        //Check Blank - message
        if (message === "") {
            toast.error('Please Enter Message.');
            return;
        }

        // await DataStore.save(
        //     new TargetMessage({
        //         "target_detail_id": targetDetailId,
        //         "sender_id": loggedEmployeeId,
        //         "receiver_id": employeeId,
        //         "messageDateTime": moment().toDate().toISOString(),
        //         "isRead": false,
        //         "message": message
        //     })
        // ).then(() => {
        //     data.messagingData.push(
        //         {
        //             "sender_id": loggedEmployeeId,
        //             "receiver_id": employeeId,
        //             "messageDateTime": moment().toDate().toISOString(),
        //             "message": message
        //         }
        //     )
        // })

        await API.graphql(
            graphqlOperation(mutations.createTargetMessage, {
                input: {
                    target_detail_id: targetDetailId,
                    sender_id: loggedEmployeeId,
                    receiver_id: employeeId,
                    messageDateTime: moment().toDate().toISOString(),
                    isRead: false,
                    message: message
                }
            })
        );
        data.messagingData.push(
            {
                "sender_id": loggedEmployeeId,
                "receiver_id": employeeId,
                "messageDateTime": moment().toDate().toISOString(),
                "message": message
            }
        )


        setMessage("")
    }

    //--------------------------------------------

    useEffect(() => {
        onLoad();
        BindList();
    }, []);



    //Select All
    const selectAll = (e) => {
        setRenderItems(false)

        if (e.target.checked) {
            let selectParameterListForApprove = parametersList.filter(e => ((!e.isPlanMandatory && !e.is_approve) || e.is_accepted_by_employee))
            setSelectedData(selectParameterListForApprove)

            //compare two array and update state 
            let result = parametersList.map(e => {
                let matching_result = selectParameterListForApprove.filter(f => f.id == e.id);
                return matching_result[0] ? { ...e, markForApprove: true } : e;
            });

            setParametersList(result)
            setIsChecked(true)
            setRenderItems(true)

        } else {
            parametersList.map(e => e.markForApprove = false);
            setIsChecked(false)
            setRenderItems(true)
        }

    }

    //Approve and Approve all


    const approvePlan = async (planDetail, e) => {
        alertify.confirm('Confirmation', 'Do you want to Approve?', async function () {

            if (planDetail.planData.length > 0) {
                planDetail.planData.forEach(async (plan) => {

                    let Q1MonthlyTarget = ((plan.Q1TargetValue / 3).toFixed(2)).toString()
                    let Q2MonthlyTarget = ((plan.Q2TargetValue / 3).toFixed(2)).toString()
                    let Q3MonthlyTarget = ((plan.Q3TargetValue / 3).toFixed(2)).toString()
                    let Q4MonthlyTarget = ((plan.Q4TargetValue / 3).toFixed(2)).toString()

                    // let original = await DataStore.query(Plan, plan.id)
                    let original = await graphQLFindRecordById('getPlan', plan.id);


                    // await DataStore.save(
                    //     Plan.copyOf(original, (updated) => {
                    //         //m1 , m2, m3 target
                    //         updated.m1_target_value = String(Q1MonthlyTarget);
                    //         updated.m2_target_value = String(Q1MonthlyTarget);
                    //         updated.m3_target_value = String(Q1MonthlyTarget);

                    //         //m4,m5,m6 target
                    //         updated.m4_target_value = String(Q2MonthlyTarget);
                    //         updated.m5_target_value = String(Q2MonthlyTarget);
                    //         updated.m6_target_value = String(Q2MonthlyTarget);

                    //         //m7,m8,m9 target
                    //         updated.m7_target_value = String(Q3MonthlyTarget);
                    //         updated.m8_target_value = String(Q3MonthlyTarget);
                    //         updated.m9_target_value = String(Q3MonthlyTarget);

                    //         //m10,m11, m12 target
                    //         updated.m10_target_value = String(Q4MonthlyTarget);
                    //         updated.m11_target_value = String(Q4MonthlyTarget);
                    //         updated.m12_target_value = String(Q4MonthlyTarget);
                    //     })
                    // ).then((response) => {
                    // })

                    await API.graphql({
                        query: mutations.updatePlan,
                        variables: {
                            input: {
                                id: original.id,
                                m1_target_value: String(Q1MonthlyTarget),
                                m2_target_value: String(Q1MonthlyTarget),
                                m3_target_value: String(Q1MonthlyTarget),

                                m4_target_value: String(Q2MonthlyTarget),
                                m5_target_value: String(Q2MonthlyTarget),
                                m6_target_value: String(Q2MonthlyTarget),

                                m7_target_value: String(Q3MonthlyTarget),
                                m8_target_value: String(Q3MonthlyTarget),
                                m9_target_value: String(Q3MonthlyTarget),

                                m10_target_value: String(Q4MonthlyTarget),
                                m11_target_value: String(Q4MonthlyTarget),
                                m12_target_value: String(Q4MonthlyTarget),
                                _version: original._version,
                            }
                        }
                    });
                })
            }
            // let original = await DataStore.query(TargetDetails, planDetail.targetDetailId)
            let original = await graphQLFindRecordById('getTargetDetails', planDetail.targetDetailId);

            // await DataStore.save(
            //     TargetDetails.copyOf(original, (updated) => {
            //         updated.is_approve = true;
            //         updated.approved_date = moment().format();
            //         updated.is_revision_by_employee = false
            //     })
            // ).then(async () => {
            //     LoadPageData()
            // })

            await API.graphql({
                query: mutations.updateTargetDetails,
                variables: {
                    input: {
                        id: original.id,
                        is_approve: true,
                        approved_date: moment().format(),
                        is_revision_by_employee: false,
                        _version: original._version,
                    }
                }
            });
            LoadPageData()
            toast.success('Approved')
        }
            , function () {
            });
    }

    const approveAll = () => {
        if (selectedData.length > 0) {
            alertify.confirm('Confirmation', 'Do you want to Approve?', async function () {
                selectedData.forEach(data => {
                    approveMultiple(data)
                })
                LoadPageData().then(() => toast.success('Approved.'))
            }
                , function () { });
        }
    }

    const approveMultiple = async (planDetail) => {
        if (planDetail.planData.length > 0) {
            planDetail.planData.forEach(async (plan) => {

                let Q1MonthlyTarget = ((plan.Q1TargetValue / 3).toFixed(2)).toString()
                let Q2MonthlyTarget = ((plan.Q2TargetValue / 3).toFixed(2)).toString()
                let Q3MonthlyTarget = ((plan.Q3TargetValue / 3).toFixed(2)).toString()
                let Q4MonthlyTarget = ((plan.Q4TargetValue / 3).toFixed(2)).toString()

                // let original = await DataStore.query(Plan, plan.id)
                let original = await graphQLFindRecordById('getPlan', plan.id);


                // await DataStore.save(
                //     Plan.copyOf(original, (updated) => {
                //         //m1 , m2, m3 target
                //         updated.m1_target_value = String(Q1MonthlyTarget);
                //         updated.m2_target_value = String(Q1MonthlyTarget);
                //         updated.m3_target_value = String(Q1MonthlyTarget);

                //         //m4,m5,m6 target
                //         updated.m4_target_value = String(Q2MonthlyTarget);
                //         updated.m5_target_value = String(Q2MonthlyTarget);
                //         updated.m6_target_value = String(Q2MonthlyTarget);

                //         //m7,m8,m9 target
                //         updated.m7_target_value = String(Q3MonthlyTarget);
                //         updated.m8_target_value = String(Q3MonthlyTarget);
                //         updated.m9_target_value = String(Q3MonthlyTarget);

                //         //m10,m11, m12 target
                //         updated.m10_target_value = String(Q4MonthlyTarget);
                //         updated.m11_target_value = String(Q4MonthlyTarget);
                //         updated.m12_target_value = String(Q4MonthlyTarget);
                //     })
                // ).then((response) => {
                // })

                await API.graphql({
                    query: mutations.updatePlan,
                    variables: {
                        input: {
                            id: original.id,
                            m1_target_value: String(Q1MonthlyTarget),
                            m2_target_value: String(Q1MonthlyTarget),
                            m3_target_value: String(Q1MonthlyTarget),

                            m4_target_value: String(Q2MonthlyTarget),
                            m5_target_value: String(Q2MonthlyTarget),
                            m6_target_value: String(Q2MonthlyTarget),

                            m7_target_value: String(Q3MonthlyTarget),
                            m8_target_value: String(Q3MonthlyTarget),
                            m9_target_value: String(Q3MonthlyTarget),

                            m10_target_value: String(Q4MonthlyTarget),
                            m11_target_value: String(Q4MonthlyTarget),
                            m12_target_value: String(Q4MonthlyTarget),
                            _version: original._version,
                        }
                    }
                });
            })
        }

        // let original = await DataStore.query(TargetDetails, planDetail.targetDetailId)
        let original = await graphQLFindRecordById('getTargetDetails', planDetail.targetDetailId);

        // await DataStore.save(
        //     TargetDetails.copyOf(original, (updated) => {
        //         updated.is_approve = true;
        //         updated.approved_date = moment().format();
        //         updated.is_revision_by_employee = false
        //     })
        // )

        await API.graphql({
            query: mutations.updateTargetDetails,
            variables: {
                input: {
                    id: original.id,
                    is_approve: true,
                    approved_date: moment().format(),
                    is_revision_by_employee: false,
                    _version: original._version,
                }
            }
        });
    }


    let [selectedData, setSelectedData] = useState([]);
    let [isChecked, setIsChecked] = useState(false);
    const checkBox_Plans = (planDetail, checkbox) => {
        if (checkbox.target.checked) {
            setIsChecked(true)
            selectedData.push(planDetail)
        } else {
            let filteredArray = RemoveItemFromArrayByIdValue(selectedData, planDetail.targetDetailId)
            selectedData = filteredArray
            if (selectedData.length === 0) {
                setIsChecked(false)
            }
        }
    }


    //Send for revision
    const sendForRevision = async (planDetail, e) => {
        alertify.confirm('Confirmation', 'Do you want send for Revision?', async function () {
            // let original = await DataStore.query(TargetDetails, planDetail.targetDetailId)
            let original = await graphQLFindRecordById('getTargetDetails', planDetail.targetDetailId);

            // await DataStore.save(
            //     TargetDetails.copyOf(original, (updated) => {
            //         updated.is_accepted_by_employee = false;
            //         updated.isMarkAsComplete = false;
            //         updated.isSendForRevisionByReportingManage = true
            //     })
            // ).then(async (response) => {})
            await API.graphql({
                query: mutations.updateTargetDetails,
                variables: {
                    input: {
                        id: original.id,
                        is_accepted_by_employee: false,
                        isMarkAsComplete: false,
                        isSendForRevisionByReportingManage: true,
                        _version: original._version,
                    }
                }
            });

            let requestTypeName = NOTIFICATIONS_TYPES.SendForRevision
            let emailSubject = EMAIL_SUBJECTS.SendForRevision
            let notificationMsg = employeeName + " (" + employeeID + ") " + "has been send for revision the " + planDetail.parameterName + ", " + financialYear
            await sendNotification(loggedEmployeeId, employeeValue, requestTypeName, notificationMsg, emailSubject);
            toast.success('Send for Revision')
            LoadPageData()

        }
            , function () {
            });
    }

    const openRemark = (item) => {
        setRenderItems(false)
        const updatedData = parametersList.map(x => (x.id === item.id ? { ...x, openRemark: !item.openRemark } : x));
        setParametersList(updatedData)
        setRenderItems(true)
    }

    function isMatch(obj1, obj2) {
        for (let prop in obj1) {
            if (obj1.hasOwnProperty(prop) && obj2.hasOwnProperty(prop)) {
                if (obj1[prop] !== obj2[prop]) {
                    return false;
                }
            }
        }
        return true;
    }

    // ---------------------- SHOW CONFLICT  ----------------------
    const [isShowConflict, setShowConflict] = useState(false);
    const showConflict = (val) => {
        // iterate over each object in the input array
        if (val == 1) { setShowConflict(true) } else { setShowConflict(false) }
        let myParameterList = [...parametersList]
        myParameterList.forEach(obj => {
            // iterate over each item in the subarray of the current object
            obj.planData.forEach(item => {
                // check if the current item is present in any other subarray of the input array
                const isPresent = myParameterList.some(otherObj => {
                    // ignore the current object we are iterating over
                    if (otherObj.id === obj.id) {
                        return false;
                    }
                    // check if the other object's subarray contains the same item
                    return otherObj.planData.some(otherItem => {
                        // return otherItem.buId === item.buId && otherItem.regionId === item.regionId && otherItem.countryId === item.countryId ;
                        return otherItem.buId === item.buId && otherItem.regionId === item.regionId && otherItem.countryId === item.countryId && otherItem.brandId === item.brandId && otherItem.productGroupId === item.productGroupId && otherItem.customerGroupId === item.customerGroupId && otherItem.customerId === item.customerId;
                    });
                });
                // add a new property to the current item indicating whether it was found in another subarray
                item.isPresentInOtherSubArray = val == 1 ? isPresent : false;
            });
        });
        setParametersList(myParameterList)
    }
    // ---------------------- SHOW CONFLICT  ----------------------

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNav />
            <LeftNav />

            {
                !renderItems &&
                <div className='absolute top-[18%] inset-0 flex items-center justify-center z-50'>
                    <ProgressSpinner style={{ width: '80px', height: '80px', zIndex: '9999' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".8s" />
                </div>
            }
            {
                renderItems &&
                <div className="target-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className="flex justify-between">
                            <div className="sm:flex items-center space-x-4">
                                <Link to={"/employee/planning/team/reviewcommit/annualtarget/" + financialYearId + "/" + parameterId} className="text-[#344054] text-[14px] dark:text-white py-1.5 px-3 rounded-md border border-[#D0D5DD]"><i className="icon arrow_left_double mr-6 "></i>Back</Link>
                                <div className="text-2xl font-semibold dark:text-white"> Plan Review</div>
                                <div className="text-xs border text-[#667085] border-[#667085] rounded p-1">FY: {financialYear}</div>
                            </div>

                            <div className='flex items-center dark:text-white gap-3'>
                                <Link onClick={() => !isShowConflict ? showConflict(1) : showConflict(0)} className='py-1.5 px-4 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]'>
                                    <i className="icon conflict mr-6 relative -top-0.5"></i> {!isShowConflict ? "Show Conflict" : "Hide Conflict"}
                                </Link>

                                {
                                    isChecked ?
                                        <Link onClick={() => approveAll()} className="py-1.5 px-4 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">Approve All</Link>
                                        : ""
                                }
                            </div>
                        </div>

                        <div className="bg-white rounded-lg mt-5 border border-[#E4E7EC] p-4 px-3 dark:bg-[#2A2E32] dark:border-[#2A2E32]">
                            <div className="grid grid-cols-8 lg:grid-cols-10 2xl:grid-cols-10 flex flex-1 content-center items-center gap-3">
                                <div className="col-span-10">
                                    <div className="grid grid-cols-6 lg:grid-cols-6 2xl:grid-cols-6 gap-3 h-auto">
                                        <div className="   content-center flex col-span-3">
                                            <div className='flex justify-between items-center gap-5 mb-3'>
                                                <div className="">
                                                    <img src={PlanReviewTopImage} alt=""></img>
                                                </div>
                                                <div className="">
                                                    <Avatar style={{ width: 80, height: 80 }} src={profilePicPath} />
                                                </div>
                                                <div>
                                                    <h6 className="text-[#101828] text-[18px] font-semibold dark:text-[#FFFFFF] mb-2">{employeeName}</h6>
                                                    <div className="text-xs border text-[#667085] border-[#C8CBD0] rounded p-1 mb-2">Role: {employeeRole}</div>
                                                    <div className="text-xs border text-[#667085] border-[#C8CBD0] rounded p-1">{employeeID}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            !hideApproveAll &&
                            <div className='my-6'>
                                <label htmlFor="checkboxAllSelection" onClick={(e) => selectAll(e)} className="bg-white py-3 w-[160px] px-4 transition ease-in duration-200 text-[16px] text-center font-medium  focus:outline-none leading-6  justify-center rounded-lg border border-[green] ">
                                    <input
                                        onChange={(e) => selectAll(e)}
                                        id="checkboxAllSelection"
                                        type="checkbox"
                                        className="rounded mr-3 border-[#C8CBD0] h-5 w-5 relative"
                                    />
                                    Select All
                                </label>
                            </div>
                        }
                        {
                            parametersList.map((item) =>
                                <div className="bg-white rounded-lg mt-5 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                    <div className='flex justify-between items-center'>
                                        <div className="relative ml-2 flex items-center">
                                            {
                                                (item.planData[0]['sbuId'] !== "" && !item.is_approve) || (!item.isPlanMandatory && !item.is_approve && !item.isSendForRevisionByReportingManage) ?
                                                    <input
                                                        checked={item.markForApprove}
                                                        id={item.id}
                                                        onChange={(e) => checkBox_Plans(item, e)}
                                                        type="checkbox"
                                                        className="rounded mr-1  border-[#C8CBD0] h-4 w-4"
                                                    /> : ""
                                            }

                                            <label
                                                htmlFor={item.id}
                                                className="text-[#101828] text-[16px] dark:text-white font-medium"
                                            > {item.parameterName}
                                            </label>
                                            {
                                                item.parameterType === PARAMETERS_TYPES_NAMES.A ?
                                                    <label
                                                        htmlFor=""
                                                        className="ml-2 text-[#101828] text-[16px] dark:text-white font-medium"
                                                    >(Target : {item.parameterType}{item.targetValue})
                                                    </label>
                                                    :
                                                    <label
                                                        htmlFor=""
                                                        className="ml-2 text-[#101828] text-[16px] dark:text-white font-medium"
                                                    >(Target : {item.targetValue}{item.parameterType})
                                                    </label>
                                            }
                                        </div>

                                        <div className='flex justify-between items-center gap-1'>
                                            {
                                                item.isPlanMandatory ?
                                                    <i className='icon s_icon'></i>
                                                    :
                                                    <i className='icon t_icon'></i>
                                            }
                                            {
                                                item.is_approve ?
                                                    <p className="bg-[#E5F3EC] text-[#4FB155] px-3 py-1.5 rounded-full text-[12px] flex items-center gap-1 w-24 justify-center "> <div className="w-2 h-2 bg-[#4FB155] rounded-full"></div>Approved</p>
                                                    :
                                                    (item.isSendForRevisionByReportingManage && !item.is_approve) ?
                                                        <p className="flex items-center gap-2 p-1.5 px-2 text-[12px] rounded-full text-[#FF4537] bg-[#FEE4E2]"> <div className='w-2 h-2 bg-[#FF4537] rounded-full'></div>Revision</p> :
                                                        <p className="bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 "> <div className='w-2 h-2 bg-[#1B55AF] rounded-full'></div>Open</p>
                                            }
                                        </div>
                                    </div>
                                    {
                                        item.is_accepted_by_employee ?
                                            <div className='flex items-center ml-2'>
                                                <label>Target Status: </label> <p className="ml-3 bg-[#E5F3EC] text-[#4FB155] px-3 py-1.5 rounded-full text-[12px] flex items-center gap-1 w-24 justify-center ">  <div className="w-2 h-2 bg-[#4FB155] rounded-full"></div>Accepted</p>
                                            </div>
                                            :
                                            <div className='flex items-center ml-2'>
                                                <label>Target Status: </label><p className="ml-3 bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 "> <div className='w-2 h-2 bg-[#1B55AF] rounded-full'></div>Open</p>
                                            </div>
                                    }

                                    <div className='my-5 accordionsingle'>
                                        <Accordion activeIndex={1}>
                                            <AccordionTab header="Dimensions">
                                                <div className='grid grid-cols-6 lg:grid-cols-6 2xl:grid-cols-6 flex flex-1 content-start items-start gap-2'>
                                                    <DataTable value={item.buDimensionArray} scrollable scrollHeight="flex" resizableColumns columnResizeMode="fit" emptyMessage="&nbsp;" >
                                                        <Column field="BU" sortable header="BU" footer="Total" ></Column>
                                                        <Column field="DisplayPercentage" header="" footer={item.totalBuDimension} align="right" ></Column>
                                                    </DataTable>
                                                    <DataTable value={item.regionDimensionArray} scrollable scrollHeight="flex" resizableColumns columnResizeMode="fit" emptyMessage="&nbsp;" >
                                                        <Column field="Region" sortable header="Region" footer="Total" ></Column>
                                                        <Column field="DisplayPercentage" header="" footer={item.totalRegionDimension} align="right" ></Column>
                                                    </DataTable>
                                                    <DataTable value={item.countryDimensionArray} scrollable scrollHeight="flex" resizableColumns columnResizeMode="fit" emptyMessage="&nbsp;">
                                                        <Column field="Country" sortable header="Country" footer="Total" ></Column>
                                                        <Column field="DisplayPercentage" footer={item.totalCountryDimension} align="right"  ></Column>
                                                    </DataTable>
                                                    <DataTable value={item.brandDimensionArray} scrollable scrollHeight="flex" resizableColumns emptyMessage="&nbsp;" >
                                                        <Column field="Brand" sortable header="Brand" footer="Total" ></Column>
                                                        <Column field="DisplayPercentage" header="" footer={item.totalBrandDimension} align="right"  ></Column>
                                                    </DataTable>
                                                    <DataTable value={item.gtmDimensionArray} scrollable scrollHeight="flex" resizableColumns emptyMessage="&nbsp;" >
                                                        <Column field="GTM" sortable header="GTM" footer="Total" ></Column>
                                                        <Column field="DisplayPercentage" header="" footer={item.totalGTMDimension} align="right"  ></Column>
                                                    </DataTable>
                                                    <DataTable value={item.subGtmDimensionArray} scrollable scrollHeight="flex" resizableColumns emptyMessage="&nbsp;" >
                                                        <Column field="GTM" sortable header="Sub GTM" footer="Total" ></Column>
                                                        <Column field="DisplayPercentage" header="" footer={item.totalSubGTMDimension} align="right"  ></Column>
                                                    </DataTable>
                                                    <DataTable value={item.pGDimensionArray} scrollable scrollHeight="flex" resizableColumns columnResizeMode="fit" emptyMessage="&nbsp;">
                                                        <Column field="ProductGroup" sortable header="Product Group" footer="Total" ></Column>
                                                        <Column field="DisplayPercentage" header="" footer={item.totalPGDimension} align="right"  ></Column>
                                                    </DataTable>
                                                    <DataTable value={item.customerSegmentDimensionArray} scrollable scrollHeight="flex" resizableColumns columnResizeMode="fit" emptyMessage="&nbsp;" >
                                                        <Column field="CustomerSegment" sortable header="Cust. Group" footer="Total" ></Column>
                                                        <Column field="DisplayPercentage" header="" footer={item.totalCustomerSegmentDimension} align="right"  ></Column>
                                                    </DataTable>
                                                    <DataTable value={item.customerDimensionArray} scrollable scrollHeight="flex" resizableColumns columnResizeMode="fit" emptyMessage="&nbsp;">
                                                        <Column field="Customer" sortable header="Customer" footer="Total" ></Column>
                                                        <Column field="DisplayPercentage" header="" footer={item.totalCustomerDimension} align="right"  ></Column>
                                                    </DataTable>

                                                </div>
                                            </AccordionTab>
                                        </Accordion>
                                    </div>

                                    <div className="bg-white rounded-lg mt-5 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                        <div className='flex  items-center justify-between'>
                                            <div className='flex'>
                                                <h2 className='text-[16px] text-[#101828] font-meduim dark:text-white'>Target</h2>
                                            </div>
                                        </div>

                                        <div className="dashboard-main-wrapper rounded-lg mt-5 divSmallTableControls ">
                                            {
                                                <React.Fragment>
                                                    <DataTable value={item.planData}
                                                        showGridlines editMode="cell" editable="true" responsiveLayout="scroll">
                                                        <Column field="buId" header="BU" body={buBodyTemplate} style={{ width: '8%' }}></Column>
                                                        <Column field="regionId" header="Region" body={regionBodyTemplate} style={{ width: '8%' }}></Column>
                                                        <Column field="countryId" header="Country" body={countryBodyTemplate} style={{ width: '8%' }}></Column>
                                                        <Column field="brandId" header="Brand" body={brandBodyTemplate} style={{ width: '8%' }}></Column>
                                                        <Column field="gtmId" header="GTM" body={gtmBodyTemplate} style={{ width: '8%' }}></Column>
                                                        <Column field="subGtmId" header="Sub GTM" body={subGtmBodyTemplate} style={{ width: '8%' }}></Column>
                                                        <Column field="productGroupId" header="Product Group" footer="Total" body={productGroupBodyTemplate} style={{ width: '8%' }}></Column>
                                                        <Column field="customerGroupId" header="Cust. Group" body={customerGroupBodyTemplate} style={{ width: '8%' }}></Column>
                                                        <Column field="customerId" header="Customer Name" body={customerBodyTemplate} style={{ width: '8%' }}></Column>
                                                        <Column field="targetValue" header="Annual" footer={item.totalAddedTarget === 0 ? "" : item.totalAddedTarget} style={{ width: '8%' }}></Column>
                                                        <Column field="Q1TargetValue" header="Q1" footer={item.q1Total === 0 ? "" : item.q1Total} style={{ width: '5%' }}></Column>
                                                        <Column field="Q2TargetValue" header="Q2" footer={item.q2Total === 0 ? "" : item.q2Total} style={{ width: '5%' }}></Column>
                                                        <Column field="Q3TargetValue" header="Q3" footer={item.q3Total === 0 ? "" : item.q3Total} style={{ width: '5%' }}></Column>
                                                        <Column field="Q4TargetValue" header="Q4" footer={item.q4Total === 0 ? "" : item.q4Total} style={{ width: '5%' }}></Column>
                                                    </DataTable>
                                                </React.Fragment>
                                            }
                                        </div>

                                        {
                                            <div className='flex  items-center justify-between mt-5  text-[14px] gap-2 dark:text-[#fff]'>
                                                {
                                                    item.is_revision_by_employee ?
                                                        <div>
                                                            <p className="bg-[#FFF2E5] text-[#FF7F01] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 "> <div className='w-2 h-2 bg-[#FF7F01] rounded-full'></div>Requested for revision</p>
                                                            <h2 className='mt-2'>Requested Value : {item.revisionValue} {item.parameterType}</h2>
                                                        </div> : <div />
                                                }

                                                <div className='flex gap-2'>
                                                    {
                                                        (employeeId !== loggedEmployeeId) &&
                                                        <Link onClick={() => openRemark(item)} className='text-[#01813F]'><i className='icon messages_icon mr-6'></i>{item.openRemark ? "Close" : "Open"} Remarks</Link>
                                                    }
                                                    {
                                                        (item.planData[0]['sbuId'] !== "" && !item.is_approve) || (!item.isPlanMandatory && !item.is_approve) ?
                                                            <div className='flex items-center'>
                                                                {
                                                                    employeeId !== loggedEmployeeId &&
                                                                    < div className='flex items-center'>
                                                                        <input id="sendRevision" checked={item.isSendForRevisionByReportingManage_Checkbox ? true : false} type={"radio"} onClick={(e) => sendForRevision(item, e)} className="ml-5" />
                                                                        <label htmlFor='sendRevision' className='ml-1'>Send for Revision</label>
                                                                    </div>
                                                                }
                                                                {
                                                                    !item.isSendForRevisionByReportingManage_Checkbox &&
                                                                    <div className='flex items-center'>
                                                                        <input id="approve" checked={item.is_approve ? true : false} type={"radio"} onClick={(e) => approvePlan(item, e)} className="ml-5" />
                                                                        <label htmlFor='approve' className='ml-1'>Approve</label>
                                                                    </div>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        }

                                    </div>

                                    {
                                        msgUnreadCount > 0 ?
                                            <div className='flex justify-between items-center'>
                                                <h3 className='text-[#101828] text-[18px] dark:text-white font-medium mt-5'>Remarks</h3>
                                                <h3 className='text-[#101828] text-[16px] dark:text-white font-medium mt-5 px-4'>{"Unread: " + msgUnreadCount}</h3>
                                            </div> : ""

                                    }


                                    {
                                        item.openRemark ?
                                            <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#181b1e]">
                                                <div className="py-6 px-5 rounded-md chat-height overflow-auto" >
                                                    {
                                                        item.messagingData.map((item) => (
                                                            <div className=''>
                                                                <div className=" rounded-lg space-y-6 mt-5 '">

                                                                    {
                                                                        item.sender_id !== loggedEmployeeId ?
                                                                            <div className="flex space-x-2 justify-start">
                                                                                <div className="w-20">
                                                                                    <img
                                                                                        src={profilePicPath}
                                                                                        className="rounded-full w-12 h-12 mx-auto object-cover" alt="" />
                                                                                </div>
                                                                                <div className="bg-[#F2F4F7] max-w-xl p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]">
                                                                                    <div className="flex items-center justify-between ">
                                                                                        <div className="font-medium text-xs text-[#101828] mb-2 dark:text-white">{employeeName}</div>
                                                                                        <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                    </div>
                                                                                    <div className="text-sm  text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                </div>
                                                                            </div> :
                                                                            <div className="flex space-x-2 justify-end">
                                                                                <div className="bg-[#F2F4F7] max-w-xl  p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]" >
                                                                                    <div className="flex items-center justify-between">
                                                                                        <div className="font-medium text-xs text-[#101828] mb-2 dark:text-white">{reactLocalStorage.get('loggedEmployeeFirstName') + ' ' + reactLocalStorage.get('loggedEmployeeLastName')}</div>
                                                                                        <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                    </div>
                                                                                    <div className="text-md text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                </div>
                                                                                <div className="w-20">
                                                                                    <img
                                                                                        src={reactLocalStorage.get('loggedEmployeeProfilePic')}
                                                                                        className="rounded-full w-12 h-12 mx-auto object-cover" alt="" />
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>

                                                        ))

                                                    }
                                                </div>

                                                <div className='relative px-5'>
                                                    <textarea
                                                        type="text"
                                                        value={(message)}
                                                        onChange={event => setMessage(event.target.value)}
                                                        placeholder='Type here...'
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-3 pl-4 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"></textarea>
                                                    <Link onClick={() => {
                                                        SendMessage(item.targetDetailId, item)
                                                    }} className='absolute right-[30px] top-[25px] text-[16px] bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block'>
                                                        <i className="pi pi-send mr-2 relative top-0.5"></i>Send </Link>
                                                </div>
                                            </div> : ""
                                    }

                                </div>
                            )
                        }

                    </div>
                </div>
            }

        </div >
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}