import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import moment from 'moment';
import 'primeicons/primeicons.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { toast } from 'react-toastify';
import parameterComplete from '../../assets/images/assignParameterComplete.png';
import parameterPending from '../../assets/images/assignParameterPending.png';
import imageDeployedLarge from '../../assets/images/deployedImg.png';
import popupHeaderImg from "../../assets/images/popup_header_img.png";
import imageDeployed from '../../assets/svg/check_small_icon.svg';
import EditButton from '../../components/common/buttons/EditButton';
import SubmitButton from '../../components/common/buttons/SubmitButton';
import ViewButton from '../../components/common/buttons/ViewButton';
import LeftSideBar from '../../components/common/hr/leftnav.component';
import TopNavBar from '../../components/common/hr/topnav.component';
import NoDataFound from '../../components/common/nodatafound';
import { ConvertResponseForSelect, ConvertResponseForSelectAttributes, ObjectToArray } from '../../components/helper/commonfunctions';
import { sumFromArray } from '../../components/helper/maths';
import { ValidateHRRole } from '../../components/helper/validateRole';
import { FinancialParameterDetails, FinancialParameters, FinancialYear, Parameters, Relationship, RelationshipDetails, Roles } from '../../models';
import HRLogin from '../../setup/auth/hr.component';
import { ProgressSpinner } from 'primereact/progressspinner';
import { reactLocalStorage } from 'reactjs-localstorage';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../src/components/helper/graphQLFunctions'

export default function HrAssignParameters() {

    var loggedHREmployeeId = reactLocalStorage.get('loggedHREmployeeId');

    let navigateTo = useNavigate();

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    const [renderItems, SetRenderItems] = useState(false);
    const [financialParametersList, SetFinancialParametersList] = useState([]);
    const [financialYear, setFinancialYear] = useState([])
    const [selectedFinancialYear, setSelectedFinancialYear] = useState(null)
    const [selectedFinancialYearData, setSelectedFinancialYearData] = useState(null)
    const [financialYearStartDate, setDisplayFinancialYearStartDate] = useState('MM/DD/YYYY')
    const [financialYearEndDate, setDisplayFinancialYearEndDate] = useState('MM/DD/YYYY')
    const [dbRoles, setDBRoles] = useState([])
    const [dbRole, setDBRole] = useState(null)

    const [items, setItems] = useState([]);
    const [itemsForView, setItemsForView] = useState([]);
    const [viewDetailTable, setViewDetailTable] = useState(false);
    const [itemsForView_weightage, setItemsForView_Weightage] = useState([]);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [allRelationshipDetails, setAllRelationshipDetails] = useState([]);
    const [allRelationships, setAllRelationships] = useState([]);

    //Parameters popup
    const [isAssignPopUp, setAssignPopUp] = useState(0);
    const [showFromFinancialYear, setShowFromFinancialYear] = useState(false);

    const [isEditRelationship, setEditRelationship] = useState(false);
    const [relationshipId, setRelationshipId] = useState(0);

    //View Record
    const [isViewParameterPopUp, setViewParameterPopUp] = useState(0);
    const [role_View, setRole_View] = useState("");

    //deployed
    const [isViewDeployedPopUp, setViewDeployedPopUp] = useState(0);


    // -------------- Clone --------------
    const [isViewClonePopUp, setViewClonePopUp] = useState(0);

    const [fromFinancialYearList, setFromFinancialYearList] = useState([]);
    const [selectedFromFinancialYear, setSelectedFromFinancialYear] = useState([]);

    const [toFinancialYearList, setToFinancialYearList] = useState([]);
    const [selectedToFinancialYear, setSelectedToFinancialYear] = useState([]);

    const [getRoleList_Selection_FromYear, setRoleList_Selection_FromYear] = useState([]);
    const [selectedRolesForClone, setRolesForClone] = useState([]);


    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    async function BindFinancialParametersList() {
        // var financialYearResponses = await DataStore.query(FinancialYear);
        var financialYearResponses = await graphQLGetAllData('listFinancialYears');

        let finalData = []
        var counter = 0;
        Object.keys(financialYearResponses).map(async (currentItem, i) => {


            let loopItem = financialYearResponses[currentItem];
            let data = ObjectToArray(loopItem);

            // var relationshipResponses = await DataStore.query(Relationship, (c) => c.financial_year_id.eq(data["id"]), {
            //     sort: s => s.updatedAt(SortDirection.DESCENDING)
            // });
            // var relationshipResponses = await graphQLGetAllData('listRelationships', [{ financial_year_id: { eq: data["id"] } }]);
            var relationshipResponses = allRelationships.filter(c => c.financial_year_id === data["id"])

            relationshipResponses = relationshipResponses.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

            if (relationshipResponses.length > 0) {
                data["FinancialYearName"] = data["name"];
                data["FinancialYearPeriod"] = moment(data["fromDate"]).format("DD MMM, YYYY") + "-" + moment(data["toDate"]).format("DD MMM, YYYY");

                let checkIfWeightageEmpty = relationshipResponses.every(item => item.isDeployed);
                data["TotalRoles"] = relationshipResponses.length
                data["isDeployed"] = checkIfWeightageEmpty

                data['FinancialYearStart'] = moment(data["fromDate"]).format('YYYY');


                // //For last updated Date
                // let sortDates = []
                // for (var i = 0; i < relationshipResponses.length; i++) {
                //     var relationshipDetails = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.contains(relationshipResponses[i]["id"]), {
                //         sort: s => s.updatedAt(SortDirection.DESCENDING), limit: 1
                //     });
                //     sortDates.push(relationshipDetails[0].updatedAt)
                // }
                // let sortedDATE = sortDates.sort(function (a, b) {
                //     return Date.parse(b) - Date.parse(a);
                // });
                // data["LastUpdatedDate"] = moment(sortedDATE[0]).format("DD MMM, YYYY")

                finalData.push(data)
            } else {
                finalData.push([])
            }

            counter++;
            if (counter === financialYearResponses.length) {
                var newArray = finalData.filter(value => value.id !== undefined);
                let sortedArray = newArray.sort(function (a, b) { return b.FinancialYearStart - a.FinancialYearStart });
                SetFinancialParametersList(sortedArray);
                SetRenderItems(true)
            }
        })
    }

    async function BindFinancialYear() {
        // var checkFinancialYearHaveParameters = await DataStore.query(FinancialParameters, (c) => c.isDeployed.eq(true));
        var checkFinancialYearHaveParameters = await graphQLGetAllData('listFinancialParameters', [{ isDeployed: { eq: true } }]);

        let bindData = []
        for (let i = 0; i < checkFinancialYearHaveParameters.length; i++) {
            // var responses = await DataStore.query(FinancialYear, checkFinancialYearHaveParameters[i]["financial_year_id"]);
            var responses = await graphQLFindRecordById('getFinancialYear', checkFinancialYearHaveParameters[i]["financial_year_id"]);

            if (responses) {
                let data = {
                    "id": responses.id,
                    "fromDate": responses.fromDate,
                    "toDate": responses.toDate,
                    "name": responses.name,
                }
                bindData.push(data)
            }
        }
        let sortArray = bindData.sort((a, b) => (a.name > b.name ? 1 : -1))
        var myArray = []; myArray = ConvertResponseForSelectAttributes(sortArray); setFinancialYear(myArray);
    }

    async function BindRoles() {
        // var responses = await DataStore.query(Roles, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var responses = await graphQLGetAllData('listRoles')
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));

        var myArray = []; myArray = ConvertResponseForSelect(responses); setDBRoles(myArray);
    }

    async function loadPage() {
        onLoad();
        BindFinancialYear();
        BindRoles()
        await BindFinancialParametersList();
    }

    const getMasterData = async () => {
        setDataLoaded(false);

        let relationshipDetailsResponses = await graphQLGetAllData('listRelationshipDetails');
        setAllRelationshipDetails(relationshipDetailsResponses);

        let relationshipResponses = await graphQLGetAllData('listRelationships');
        setAllRelationships(relationshipResponses);

        setDataLoaded(true);
    }

    useEffect(() => {
        if (isDataLoaded === true) { loadPage(); }
    }, [allRelationshipDetails, allRelationships]);

    useEffect(() => {
        getMasterData()
    }, [])


    async function viewRelation(selectedRecord) {
        // var responses = await DataStore.query(FinancialYear, (c) => c.name.contains(selectedRecord.FinancialYearName));
        var responses = await graphQLGetAllData('listFinancialYears', [{ name: { contains: selectedRecord.FinancialYearName } }]);

        setDisplayFinancialYearStartDate(moment(responses[0].fromDate).format("MM/DD/YYYY"));
        setDisplayFinancialYearEndDate(moment(responses[0].toDate).format("MM/DD/YYYY"));
        setSelectedFinancialYear({ label: responses[0].name, value: responses[0].id });

        // var relationshipResponses = await DataStore.query(Relationship, (c) => c.financial_year_id.eq(selectedRecord.id));

        var relationshipResponses = allRelationships.filter(c => c.financial_year_id === selectedRecord.id)

        let finalData = []

        // Object.keys(relationshipResponses).map(async (currentItem, i) => {
        //     let loopItem = relationshipResponses[currentItem];
        //     let data = ObjectToArray(loopItem);

        //     let getRole = await DataStore.query(Roles, data["role_id"]);
        //     data["name"] = getRole.name
        //     data["sortOrder"] = getRole.sortOrder
        //     var relationshipDetails = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.contains(data["id"]), {
        //         sort: s => s.updatedAt(SortDirection.DESCENDING)
        //     });

        //     data["createdAt"] = moment(data["createdAt"]).format("DD MMM, YYYY");
        //     data["updatedAt"] = moment(relationshipDetails[0].updatedAt).format("DD MMM, YYYY");

        //     //var relationshipResponses = await DataStore.query(RelationshipDetails, (c) => c.financial_year_id.eq(data["cb5c2082-0043-4a72-a60a-0d7ac50b1154"]));

        //     finalData.push(data)
        //     if (i === relationshipResponses.length - 1) {
        //         console.log("finalData----" + JSON.stringify(finalData))
        //         let sortedData = finalData.sort(function (a, b) { return b.sortOrder - a.sortOrder });
        //         console.log("sortedData----" + JSON.stringify(sortedData))
        //         setItemsForView(sortedData)
        //         setViewParameterPopUp(1)
        //     }
        // })

        for (let i = 0; i < relationshipResponses.length; i++) {
            let name = ""
            let sortOrder = ""
            if (relationshipResponses[i].role_id) {
                // let getRole = await DataStore.query(Roles, relationshipResponses[i].role_id);
                let getRole = await graphQLFindRecordById('getRoles', relationshipResponses[i].role_id);

                if (getRole) {
                    name = getRole.name
                    sortOrder = getRole.sortOrder
                } else {
                    name = ""
                    sortOrder = ""
                }
            } else {
                name = ""
                sortOrder = ""
            }

            let updated_Date = "-"
            if (relationshipResponses[i].id) {
                // var relationshipDetails = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.contains(relationshipResponses[i].id), {
                //     sort: s => s.updatedAt(SortDirection.DESCENDING)
                // });

                var relationshipDetails = allRelationshipDetails.filter(c => c.relationship_id.toString().includes(relationshipResponses[i].id))

                relationshipDetails = relationshipDetails.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

                // console.log(relationshipDetails[0].updatedAt)
                if (relationshipDetails.length) {
                    updated_Date = moment(relationshipDetails[0].updatedAt).format("DD MMM, YYYY");
                }
            }

            let data = {
                "id": relationshipResponses[i].id,
                "financial_year_id": relationshipResponses[i].financial_year_id,
                "role_id": relationshipResponses[i].role_id,
                "isDeployed": relationshipResponses[i].isDeployed,
                "createdAt": moment(relationshipResponses[i].createdAt).format("DD MMM, YYYY"),
                "updatedAt": updated_Date,
                "name": name,
                "sortOrder": sortOrder,
                "isChecked": false,
            }

            finalData.push(data)

            if (i === relationshipResponses.length - 1) {
                let sortedData = finalData.sort(function (a, b) { return b.sortOrder - a.sortOrder });
                setItemsForView(sortedData)
                setViewParameterPopUp(1)
            }

        }
    }

    const onFinancialSelectChange = async (e) => {
        setSelectedFinancialYearData(e)
        var fromDate = (e.fromDate && e.fromDate) || '';
        var endDate = (e.toDate && e.toDate) || '';
        fromDate = moment(fromDate).format("MM/DD/YYYY").toString();
        endDate = moment(endDate).format("MM/DD/YYYY").toString();
        setDisplayFinancialYearStartDate(fromDate);
        setDisplayFinancialYearEndDate(endDate);
        setSelectedFinancialYear({ label: e.name, value: e.id });

        //Remaining Roles depends on Selection of financial Year
        // var RolesResponse = await DataStore.query(Roles, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        var RolesResponse = await graphQLGetAllData('listRoles');
        RolesResponse = RolesResponse.sort((a, b) => a.name.localeCompare(b.name));

        // var RelationshipResponses = await DataStore.query(Relationship, (c) => c.financial_year_id.eq(e.id));

        var RelationshipResponses = allRelationships.filter(c => c.financial_year_id === e.id)

        var roleIds = RelationshipResponses.map(function (el) { return el.role_id; });
        const newArray = RolesResponse.filter(obj => !roleIds.some(toRemove => toRemove === obj.id));
        var myArray = []; myArray = ConvertResponseForSelect(newArray); setDBRoles(myArray);

        //get parameter based on manage parameter
        let finalData = []
        // let getFinancialParameters = await DataStore.query(FinancialParameters, (c) => c.and(c => [
        //     c.financial_year_id.eq(e.id)
        //     //c.user_id.eq(loggedHREmployeeId)
        // ]));

        let getFinancialParameters = await graphQLGetAllData('listFinancialParameters', [{ financial_year_id: { eq: e.id } }]);


        console.log('getFinancialParameters' + JSON.stringify(getFinancialParameters))

        // let getFinancialParametersDetails = await DataStore.query(FinancialParameterDetails, (c) => c.financial_parameter_id.eq(getFinancialParameters[0].id));

        let getFinancialParametersDetails = await graphQLGetAllData('listFinancialParameterDetails', [{ financial_parameter_id: { eq: getFinancialParameters[0].id } }]);


        for (var i = 0; i < getFinancialParametersDetails.length; i++) {
            // let getParameter = await DataStore.query(Parameters, getFinancialParametersDetails[i].parameter_id);
            let getParameter = await graphQLFindRecordById('getParameters', getFinancialParametersDetails[i].parameter_id);

            let data = {
                parameterId: getParameter.id,
                name: getParameter.name,
                type: '%',//getParameter[0].type === "A" ? "Amount" : getParameter[0].type === "P" ? "Percentage" : getParameter[0].type === "D" ? "Days" : "Number",
                weightage: 0,
            }
            finalData.push(data)
        }
        let sortArray = finalData.sort((a, b) => (a.name > b.name ? 1 : -1))
        setItems(sortArray)
    }

    const updateWeightage = (rowData, weightageCount) => {
        //if (weightageCount === "") { weightageCount = 0 } else 
        // weightageCount = weightageCount;
        const updatedData = items.map(x => (x.parameterId === rowData.parameterId ? { ...x, weightage: weightageCount } : x));
        //const updatedData = items.map(x => (x.id === rowData.role_id ? { ...x, weightage: weightageCount } : x));
        setItems(updatedData);

        let sumOfWeightage = sumFromArray("weightage", updatedData)
        if (sumOfWeightage >= 0) { setTotalOfWeightage(sumOfWeightage) }
    }

    const actionBodyTemplate = (rowData) => {
        console.log(rowData.weightage)
        return (
            <React.Fragment>
                <input
                    type={"number"}
                    value={rowData.weightage}
                    min="0"
                    className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                    onChange={(e) => updateWeightage(rowData, e.target.value)}
                />
            </React.Fragment>
        );
    }

    const multipleDeploy = (rowData) => {
        return (
            <React.Fragment >
                {
                    !rowData.isDeployed ?
                        <div>
                            <input
                                type="checkbox"
                                onChange={(e) => {
                                    selectRoleForMultipleDeploy(e, rowData)
                                }}
                                className="rounded mr-1 border-[#C8CBD0] h-4 w-4"
                            />
                        </div>
                        : ""
                }
            </React.Fragment>
        )
    }

    //
    let [selectedEmployees, setSelectedEmployees] = useState([]);
    let [isButtonDeployedShow, setIsButtonDeployedShow] = useState(false);

    const selectRoleForMultipleDeploy = (e, employee) => {
        if (e.target.checked) {
            let data = [...itemsForView]
            let newResponse = []
            data.map((item, index) => {
                if (item.id === employee.id) {
                    item.isChecked = true
                }
                newResponse.push(item)
                if (index === data.length - 1) {
                    setItemsForView(newResponse)
                    setIsButtonDeployedShow(true)
                }
            })
        } else {
            let data = [...itemsForView]
            let newResponse = []
            data.map((item, index) => {
                if (item.id === employee.id) {
                    item.isChecked = false
                }
                newResponse.push(item)
                if (index === data.length - 1) {
                    setItemsForView(newResponse)
                    setIsButtonDeployedShow(false)
                }
            })
        }
    }


    let [totalOfWeightage, setTotalOfWeightage] = useState("");
    const actionsOnRoles = (rowData) => {
        return (
            <React.Fragment >
                <div className="flex justify-center gap-8 ">
                    <Link onClick={async () => {
                        setViewDetailTable(true)
                        let finalData = []

                        //get parameter based on manage parameter
                        let getParametersList = []

                        // let getFinancialParameters = await DataStore.query(FinancialParameters, (c) => c.and(c => [
                        //     c.financial_year_id.eq(selectedFinancialYear.value)
                        //     //c.user_id.eq(loggedHREmployeeId)
                        // ]));
                        let getFinancialParameters = await graphQLGetAllData('listFinancialParameters', [{ financial_year_id: { eq: selectedFinancialYear.value } }]);


                        // let getFinancialParametersDetails = await DataStore.query(FinancialParameterDetails, (c) => c.financial_parameter_id.eq(getFinancialParameters[0].id));

                        let getFinancialParametersDetails = await graphQLGetAllData('listFinancialParameterDetails', [{ financial_parameter_id: { eq: getFinancialParameters[0].id } }]);

                        for (var i = 0; i < getFinancialParametersDetails.length; i++) {
                            // let getParameter = await DataStore.query(Parameters, getFinancialParametersDetails[i].parameter_id);
                            let getParameter = await graphQLFindRecordById('getParameters', getFinancialParametersDetails[i].parameter_id);

                            let data = {
                                parameterId: getParameter.id,
                                name: getParameter.name,
                            }
                            getParametersList.push(data)
                        }

                        // let sortArray = getParametersList.sort((a, b) => (a.name > b.name ? 1 : -1))


                        for (var j = 0; j < getParametersList.length; j++) {
                            //let getParameter = await DataStore.query(Parameters, (c) => c.id.eq(financialParameterDetailResponses[i]["parameter_id"]));
                            try {
                                // const checkIfRelationExists = await DataStore.query(Relationship, (c) => c.and(c => [
                                //     c.financial_year_id.eq(selectedFinancialYear.value),
                                //     c.role_id.eq(rowData.role_id)
                                // ]));


                                const checkIfRelationExists = allRelationships.filter(c => c.financial_year_id === selectedFinancialYear.value && c.role_id === rowData.role_id)

                                setRelationshipId(checkIfRelationExists[0].id)

                                // let getParameterWeightage = await DataStore.query(RelationshipDetails, (c) => c.and(c => [
                                //     c.relationship_id.eq(checkIfRelationExists[0].id),
                                //     c.parameter_id.eq(getParametersList[j].parameterId)
                                // ]));

                                let getParameterWeightage = allRelationshipDetails.filter(c => c.relationship_id === checkIfRelationExists[0].id && c.parameter_id === getParametersList[j].parameterId)

                                if (getParameterWeightage[0].weightage > 0) {
                                    let data = {
                                        "parameterId": getParametersList[j].parameterId,
                                        "name": getParametersList[j].name,
                                        "weightage": getParameterWeightage[0].weightage + "%",
                                    }
                                    finalData.push(data)
                                }
                            } catch (e) { }
                        }

                        setRole_View(rowData.name)

                        setItemsForView_Weightage(finalData)
                        setViewParameterPopUp(1)

                        // let sumOfWeightage = sumFromArray("weightage", finalData)
                        let getWeightageValues = finalData.map((data) => data.weightage)
                        let totalOfTargets = getWeightageValues.reduce((total, num) => total + parseInt(num), 0);
                        let total = totalOfTargets
                        setTotalOfWeightage(total)

                    }} ><ViewButton /></Link>

                    {
                        <Link onClick={() => editRelationShip(rowData)}  > <EditButton /> </Link>
                    }
                    {
                        rowData.isDeployed == true ?
                            <Link><i className='icon check_circle_green_icon'></i></Link>
                            :
                            <Link onClick={() => updateStatusOfDeployed(rowData)}  > <SubmitButton /> </Link>
                    }
                </div>

            </React.Fragment>
        );
    }

    //Edit RelationShip
    const editRelationShip = async (selectedRole_Data) => {
        setEditRelationship(true)
        setRelationshipId(selectedRole_Data.id)
        setViewParameterPopUp(0)
        setDBRole({ label: selectedRole_Data.name, value: selectedRole_Data.role_id })

        let finalData = []

        //get parameter based on manage parameter
        let getParametersList = []

        // let getFinancialParameters = await DataStore.query(FinancialParameters, (c) => c.and(c => [
        //     c.financial_year_id.eq(selectedFinancialYear.value)
        //     //c.user_id.eq(loggedHREmployeeId)
        // ]));

        let getFinancialParameters = await graphQLGetAllData('listFinancialParameters', [{ financial_year_id: { eq: selectedFinancialYear.value } }]);


        console.log('getFinancialParameters' + JSON.stringify(getFinancialParameters))

        // let getFinancialParametersDetails = await DataStore.query(FinancialParameterDetails, (c) => c.financial_parameter_id.eq(getFinancialParameters[0].id));

        let getFinancialParametersDetails = await graphQLGetAllData('listFinancialParameterDetails', [{ financial_parameter_id: { eq: getFinancialParameters[0].id } }]);

        for (var i = 0; i < getFinancialParametersDetails.length; i++) {
            // let getParameter = await DataStore.query(Parameters, getFinancialParametersDetails[i].parameter_id);
            let getParameter = await graphQLFindRecordById('getParameters', getFinancialParametersDetails[i].parameter_id);

            let data = {
                parameterId: getParameter.id,
                name: getParameter.name,
            }
            getParametersList.push(data)
        }

        for (var i = 0; i < getParametersList.length; i++) {

            // const checkIfRelationExists = await DataStore.query(Relationship, (c) => c.and(c => [
            //     c.financial_year_id.eq(selectedFinancialYear.value),
            //     c.role_id.eq(selectedRole_Data.role_id)
            // ]));

            // const checkIfRelationExists = await graphQLGetAllData('listRelationships', [
            //     { financial_year_id: { eq: selectedFinancialYear.value } },
            //     { role_id: { eq: selectedRole_Data.role_id } }
            // ]);
            const checkIfRelationExists = allRelationships.filter(c => c.financial_year_id === selectedFinancialYear.value && c.role_id === selectedRole_Data.role_id)

            // let getParameterWeightage = await DataStore.query(RelationshipDetails, (c) => c.and(c => [
            //     c.relationship_id.eq(checkIfRelationExists[0].id),
            //     c.parameter_id.eq(getParametersList[i].parameterId)
            // ]));


            let getParameterWeightage = allRelationshipDetails.filter(c => c.relationship_id === checkIfRelationExists[0].id && c.parameter_id === getParametersList[i].parameterId)

            let data = {
                "parameterId": getParametersList[i].parameterId,
                "name": getParametersList[i].name,
                "weightage": getParameterWeightage.length ? getParameterWeightage[0].weightage : 0,
                "type": "%"
            }
            finalData.push(data)

            if (i === getParametersList.length - 1) {
                let sumOfWeightage = sumFromArray("weightage", finalData)
                setTotalOfWeightage(sumOfWeightage)
                setItems(finalData)
                setAssignPopUp(1)
            }
        }
    }

    //Update RelationShip
    const updateRelationship = async () => {
        if (totalOfWeightage > 100) {
            toast.error('Total weightage should not be more than 100%');
        } else {
            // await DataStore.delete(RelationshipDetails, (c) => c.relationship_id.eq(relationshipId)).then(() => {
            //     if (relationshipId != null) {
            //         //Save Parameters Details
            //         Object.keys(items).map(async (value, i) => {
            //             if (parseInt(items[value]['weightage']) > 0) {
            //                 await DataStore.save(
            //                     new RelationshipDetails({
            //                         "relationship_id": relationshipId,
            //                         "parameter_id": items[value]['parameterId'],
            //                         "weightage": parseInt(items[value]['weightage']),
            //                     }));
            //             }
            //         })
            //     }
            // });

            // let toDeleteRelationshipDetails = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: relationshipId } }]);
            let toDeleteRelationshipDetails = allRelationshipDetails.filter(c => c.relationship_id === relationshipId);
            for (var i = 0; i < toDeleteRelationshipDetails.length; i++) {

                await API.graphql({
                    query: mutations.deleteRelationshipDetails,
                    variables: {
                        input: {
                            id: toDeleteRelationshipDetails[i].id,
                            _version: toDeleteRelationshipDetails[i]._version,
                        }
                    }
                });
            }

            if (relationshipId != null) {
                //Save Parameters Details
                Object.keys(items).map(async (value, i) => {
                    if (parseInt(items[value]['weightage']) > 0) {
                        // await DataStore.save(
                        //     new RelationshipDetails({
                        //         "relationship_id": relationshipId,
                        //         "parameter_id": items[value]['parameterId'],
                        //         "weightage": parseInt(items[value]['weightage']),
                        //     }));


                        await API.graphql(
                            graphqlOperation(mutations.createRelationshipDetails, {
                                input: {
                                    relationship_id: relationshipId,
                                    parameter_id: items[value]['parameterId'],
                                    weightage: parseInt(items[value]['weightage']),
                                }
                            })
                        );
                    }
                })
            }

            clearAll()
            toast.success('Updated.');
            setAssignPopUp(0);
            //setViewParameterPopUp(1)
        }
    }

    const assignRelationship = async () => {
        //const checkIfWeightageEmpty = items.every(item => item.weightage);

        // console.log(selectedFromFinancialYear.id)
        // console.log(showFromFinancialYear)

        if (selectedFinancialYear === null) {
            toast.error('Please select Financial Year.');
            return;
        }
        else if (dbRole === null) {
            toast.error('Please select Role.');
            return;
        }
        else if (showFromFinancialYear && selectedFromFinancialYear.id === undefined) {
            toast.error('Please select financial year.');
            return;
        }

        //Assign FinancialParameters
        if (totalOfWeightage > 100) {
            toast.error('Total weightage should not be more than 100%');
        } else {
            SetRenderItems(false)
            // var newParameterRelationshipId = await DataStore.save(
            //     new Relationship({
            //         "financial_year_id": selectedFinancialYear.value,
            //         "role_id": dbRole.value,
            //         "isDeployed": false
            //     })
            // );

            var returnData = await API.graphql(
                graphqlOperation(mutations.createRelationship, {
                    input: {
                        financial_year_id: selectedFinancialYear.value,
                        role_id: dbRole.value,
                        isDeployed: false
                    }
                })
            );
            var newParameterRelationshipId = returnData.data.createEmployeeRegions;
            console.log('newParameterRelationshipId', newParameterRelationshipId);
            if (newParameterRelationshipId.id != null) {
                //Save Parameters Details
                Object.keys(items).map(async (value, i) => {
                    if (parseInt(items[value]['weightage']) > 0) {
                        // await DataStore.save(
                        //     new RelationshipDetails({
                        //         "relationship_id": newParameterRelationshipId.id,
                        //         "parameter_id": items[value]['parameterId'],
                        //         "weightage": parseInt(items[value]['weightage']),
                        //     })
                        // );

                        await API.graphql(
                            graphqlOperation(mutations.createRelationshipDetails, {
                                input: {
                                    relationship_id: newParameterRelationshipId.id,
                                    parameter_id: items[value]['parameterId'],
                                    weightage: parseInt(items[value]['weightage'])
                                }
                            })
                        );
                    }
                })
            }
            clearAll()
            toast.success('Parameter assign successfully.');
            setAssignPopUp(0);
            setTimeout(() => {
                BindFinancialParametersList()
            }, 1000);
        }

    }

    const updateFinancialYearDetails = async (selectedYear) => {
        // let getFinancialYear = await DataStore.query(FinancialYear, selectedYear);
        let getFinancialYear = await graphQLFindRecordById('getFinancialYear', selectedYear);

        // await DataStore.save(
        //     FinancialYear.copyOf(getFinancialYear, (updated) => {
        //         updated.updatedAt = moment().toDate();
        //     })
        // );

        await API.graphql({
            query: mutations.updateFinancialYear,
            variables: {
                input: {
                    id: getFinancialYear.id,
                    updatedAt: moment().toDate(),
                    _version: getFinancialYear._version,
                }
            }
        });
    }
    const clearAll = () => {
        setTotalOfWeightage(0)
        setSelectedFinancialYear(null)
        setDBRole(null)
        setDisplayFinancialYearStartDate('MM/DD/YYYY');
        setDisplayFinancialYearEndDate('MM/DD/YYYY')
        setItems([]);
        setShowFromFinancialYear(false)

        //Clone
        setSelectedFromFinancialYear([])
        setSelectedToFinancialYear([])
        setRoleList_Selection_FromYear([])
        setRolesForClone([])
    }

    const updateStatusOfDeployed = async (rowData) => {

        // const checkIfRelationExists = await DataStore.query(Relationship, (c) => c.and(c => [
        //     c.financial_year_id.eq(selectedFinancialYear.value),
        //     c.role_id.eq(rowData.role_id)
        // ]));

        // const checkIfRelationExists = await graphQLGetAllData('listRelationships', [
        //     { financial_year_id: { eq: selectedFinancialYear.value } },
        //     { role_id: { eq: rowData.role_id } }
        // ]);

        const checkIfRelationExists = allRelationships.filter(c => c.financial_year_id === selectedFinancialYear.value && c.role_id === rowData.role_id)



        if (checkIfRelationExists.length > 0) {
            setRole_View(rowData.name)
            alertify.confirm('Confirmation', 'Do you want to deploy?', async function () {
                // await DataStore.save(
                //     Relationship.copyOf(checkIfRelationExists[0], (updated) => {
                //         updated.isDeployed = true;
                //     })
                // );

                await API.graphql({
                    query: mutations.updateRelationship,
                    variables: {
                        input: {
                            id: checkIfRelationExists[0].id,
                            isDeployed: true,
                            _version: checkIfRelationExists[0]._version,
                        }
                    }
                });
                setViewParameterPopUp(0)
                setViewDeployedPopUp(1)
            }
                , function () { });
        }

    }

    //update multi Deployed
    const updateMultiStatusOfDeployed = async () => {
        let selectedRoles = itemsForView.filter((item) => item.isChecked === true);
        if (selectedRoles.length > 0) {
            alertify.confirm('Confirmation', 'Do you want to deploy all?', async function () {
                for (let i = 0; i < selectedRoles.length; i++) {
                    setRole_View(selectedRoles[i].name)
                    // const original = await DataStore.query(Relationship, (c) => c.and(c => [
                    //     c.financial_year_id.eq(selectedFinancialYear.value),
                    //     c.role_id.eq(selectedRoles[i].role_id)
                    // ]));

                    const original = allRelationships.filter(c => c.financial_year_id === selectedFinancialYear.value && c.role_id === selectedRoles[i].role_id)

                    // await DataStore.save(
                    //     Relationship.copyOf(original[0], (updated) => {
                    //         updated.isDeployed = true;
                    //     })
                    // );


                    await API.graphql({
                        query: mutations.updateRelationship,
                        variables: {
                            input: {
                                id: original[0].id,
                                isDeployed: true,
                                _version: original[0]._version,
                            }
                        }
                    });

                    if (i === selectedRoles.length - 1) {
                        setViewParameterPopUp(0)
                        setViewDeployedPopUp(1)
                        setIsButtonDeployedShow(false)
                        toast.success('Deployed.');
                    }
                }
            }
                , function () { });
        }
        else {
            toast.error("Please select Role.");
        }
    }

    // ------------------- Clone R2P Functionality ------------------- 

    const LoadDataForCloneR2PMapping = async () => {
        let todaysDate = moment().format('YYYY-MM-DD')

        // var responsesFrom = await DataStore.query(FinancialYear, (c) => c.fromDate.lt(todaysDate));
        var responsesFrom = await graphQLGetAllData('listFinancialYears', [{ fromDate: { lt: todaysDate } }]);


        var myFromArray = []; myFromArray = ConvertResponseForSelectAttributes(responsesFrom); setFromFinancialYearList(myFromArray);

        // var checkFinancialYearHaveParameters = await DataStore.query(FinancialParameters, (c) => c.isDeployed.eq(true));
        var checkFinancialYearHaveParameters = await graphQLGetAllData('listFinancialParameters', [{ isDeployed: { eq: true } }]);


        let bindData = []
        for (let i = 0; i < checkFinancialYearHaveParameters.length; i++) {
            // var responses = await DataStore.query(FinancialYear, checkFinancialYearHaveParameters[i]["financial_year_id"]);
            var responses = await graphQLFindRecordById('getFinancialYear', checkFinancialYearHaveParameters[i]["financial_year_id"]);

            if (responses) {
                let data = {
                    "id": responses.id,
                    "fromDate": responses.fromDate,
                    "toDate": responses.toDate,
                    "name": responses.name,
                }
                bindData.push(data)
            }
        }

        let sortArray = bindData.sort((a, b) => (a.name > b.name ? 1 : -1))
        var myArray = []; myArray = ConvertResponseForSelectAttributes(sortArray); setToFinancialYearList(myArray);

        setViewClonePopUp(1)
    }

    const onFromFinancialYearChange = async (e) => {
        setRolesForClone([])
        setSelectedFromFinancialYear({ label: e.label, id: e.id })
        // var relationshipResponse = await DataStore.query(Relationship, (c) => c.financial_year_id.eq(e.id));

        // var relationshipResponse = await graphQLGetAllData('listRelationships', [{ financial_year_id: { eq: e.id } }]);

        const relationshipResponse = allRelationships.filter(c => c.financial_year_id === e.id)


        var response = [];
        // relationshipResponse.forEach(async (e, i) => {
        //     // var getRoles = await DataStore.query(Roles, (c) => c.id.eq(e.role_id));
        //     var getRoles = await graphQLFindRecordById('getRoles', e.role_id);

        //     var newResponse = [];
        //     newResponse['roleId'] = getRoles[0].id;
        //     newResponse['roleName'] = getRoles[0].name;
        //     newResponse['isSelected'] = false;

        //     response.push(newResponse);
        //     if (i === relationshipResponse.length - 1) {
        //         setRoleList_Selection_FromYear(response)
        //     }
        // })

        for (var i = 0; i < relationshipResponse.length; i++) {
            // var getRoles = await DataStore.query(Roles, (c) => c.id.eq(e.role_id));
            var getRoles = await graphQLFindRecordById('getRoles', e.role_id);

            var newResponse = [];
            newResponse['roleId'] = getRoles[0].id;
            newResponse['roleName'] = getRoles[0].name;
            newResponse['isSelected'] = false;

            response.push(newResponse);
            if (i === relationshipResponse.length - 1) {
                setRoleList_Selection_FromYear(response)
            }
        }

    }

    const cloneData = async () => {
        if (selectedFromFinancialYear.length === 0) { toast.error('Please select From Financial Year.'); return; }
        else if (selectedToFinancialYear.length === 0) { toast.error('Please select To Financial Year.'); return; }
        else if (selectedRolesForClone.length < 1) { toast.error('Please select Roles.'); return; }
        // selectedRolesForClone.forEach(async e => {

        //     // const checkIfRelationExists = await DataStore.query(Relationship, (c) => c.and(c => [
        //     //     c.financial_year_id.eq(selectedFromFinancialYear.id),
        //     //     c.role_id.eq(e.roleId)
        //     // ]));

        //     const checkIfRelationExists = await graphQLGetAllData('listRelationships', [
        //         { financial_year_id: { eq: selectedFromFinancialYear.id } },
        //         { role_id: { eq: e.roleId } }
        //     ]);

        //     // var getRelationshipDetails = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(checkIfRelationExists[0].id));
        //     var getRelationshipDetails = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: checkIfRelationExists[0].id } }]);

        //     if (checkIfRelationExists) {
        //         // var newParameterRelationshipId = await DataStore.save(
        //         //     new Relationship({
        //         //         "financial_year_id": selectedToFinancialYear.value,
        //         //         "role_id": e["roleId"],
        //         //         "isDeployed": false
        //         //     })
        //         // );

        //         var returnData = await API.graphql(
        //             graphqlOperation(mutations.createRelationship, {
        //                 input: {
        //                     financial_year_id: selectedToFinancialYear.value,
        //                     role_id: e["roleId"],
        //                     isDeployed: false
        //                 }
        //             })
        //         );

        //         var newParameterRelationshipId = returnData.data.createRelationship;

        //         if (newParameterRelationshipId.id != null) {
        //             //Save Parameters Details
        //             Object.keys(getRelationshipDetails).map(async (value, i) => {
        //                 // await DataStore.save(
        //                 //     new RelationshipDetails({
        //                 //         "relationship_id": newParameterRelationshipId.id,
        //                 //         "parameter_id": getRelationshipDetails[value]['parameter_id'],
        //                 //         "weightage": parseInt(getRelationshipDetails[value]['weightage']),
        //                 //     })
        //                 // );
        //                 await API.graphql(
        //                     graphqlOperation(mutations.createRelationshipDetails, {
        //                         input: {
        //                             relationship_id: newParameterRelationshipId.id,
        //                             parameter_id: getRelationshipDetails[value]['parameter_id'],
        //                             weightage: parseInt(getRelationshipDetails[value]['weightage']),
        //                         }
        //                     })
        //                 );
        //             })
        //         }
        //     }
        // })

        for (var i = 0; i < selectedRolesForClone.length; i++) {

            // const checkIfRelationExists = await DataStore.query(Relationship, (c) => c.and(c => [
            //     c.financial_year_id.eq(selectedFromFinancialYear.id),
            //     c.role_id.eq(e.roleId)
            // ]));

            let e = selectedRolesForClone[i];

            // const checkIfRelationExists = await graphQLGetAllData('listRelationships', [
            //     { financial_year_id: { eq: selectedFromFinancialYear.id } },
            //     { role_id: { eq: e.roleId } }
            // ]);

            const checkIfRelationExists = allRelationships.filter(c => c.financial_year_id === selectedFromFinancialYear.id && c.role_id === e.roleId)

            // var getRelationshipDetails = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(checkIfRelationExists[0].id));

            var getRelationshipDetails = allRelationshipDetails.filter(c => c.relationship_id === checkIfRelationExists[0].id)

            if (checkIfRelationExists) {
                // var newParameterRelationshipId = await DataStore.save(
                //     new Relationship({
                //         "financial_year_id": selectedToFinancialYear.value,
                //         "role_id": e["roleId"],
                //         "isDeployed": false
                //     })
                // );

                var returnData = await API.graphql(
                    graphqlOperation(mutations.createRelationship, {
                        input: {
                            financial_year_id: selectedToFinancialYear.value,
                            role_id: e["roleId"],
                            isDeployed: false
                        }
                    })
                );

                var newParameterRelationshipId = returnData.data.createRelationship;

                if (newParameterRelationshipId.id != null) {
                    //Save Parameters Details
                    Object.keys(getRelationshipDetails).map(async (value, i) => {
                        // await DataStore.save(
                        //     new RelationshipDetails({
                        //         "relationship_id": newParameterRelationshipId.id,
                        //         "parameter_id": getRelationshipDetails[value]['parameter_id'],
                        //         "weightage": parseInt(getRelationshipDetails[value]['weightage']),
                        //     })
                        // );
                        await API.graphql(
                            graphqlOperation(mutations.createRelationshipDetails, {
                                input: {
                                    relationship_id: newParameterRelationshipId.id,
                                    parameter_id: getRelationshipDetails[value]['parameter_id'],
                                    weightage: parseInt(getRelationshipDetails[value]['weightage']),
                                }
                            })
                        );
                    })
                }
            }
        }

        setTimeout(() => {
            SetRenderItems(false)
            BindFinancialParametersList()
        }, 100);
        toast.success('Clone completed.');
        setViewClonePopUp(0)
    }

    // ------------------- Copy Parameters from previous year  ------------------- 
    const setRole = async (e) => {
        setDBRole(e)
        let todaysDate = moment().format('YYYY-MM-DD')

        // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.lt(todaysDate));
        var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { lt: todaysDate } }]);

        var myArray = []; myArray = ConvertResponseForSelectAttributes(responses); setFromFinancialYearList(myArray);
    }

    const onFromFinancialYearChange_Create = async (e) => {
        try {
            setSelectedFromFinancialYear({ label: e.label, id: e.id })

            // const checkRelation = await DataStore.query(Relationship, (c) => c.and(c => [
            //     c.financial_year_id.eq(e.id),
            //     c.role_id.eq(dbRole.value)
            // ]));

            // const checkRelation = await graphQLGetAllData('listRelationships', [
            //     { financial_year_id: { eq: e.id } },
            //     { role_id: { eq: dbRole.value } }
            // ]);

            const checkRelation = allRelationships.filter(c => c.financial_year_id === e.id && c.role_id === dbRole.value)

            if (checkRelation.length > 0) {

                // var getRelationshipDetails = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(checkRelation[0]["id"]));


                var getRelationshipDetails = allRelationshipDetails.filter(c => c.relationship_id === checkRelation[0]["id"]);


                if (getRelationshipDetails.length) {
                    const map = new Map(getRelationshipDetails.map(({ parameter_id, ...rest }) => [parameter_id, rest]));
                    const result = items.map(o => ({ ...o, ...map.get(o.parameterId) }));

                    setItems(result)
                    let sumOfWeightage = sumFromArray("weightage", result)
                    if (sumOfWeightage >= 0) { setTotalOfWeightage(sumOfWeightage) }
                }
                else {
                    setTotalOfWeightage(0)
                }

            } else {
                setTotalOfWeightage(0)
                onFinancialSelectChange(selectedFinancialYearData)
            }

        } catch (e) {
            console.log(e)
        }

    }


    return (
        isAuthenticated ?
            <div className="pt-16 ">
                <TopNavBar />
                <LeftSideBar />

                <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5 ">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Parameters</div>
                        </div>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/hr/manageparameters">Manage Parameters</Link></li>
                                    <li><Link className={"active"}>Assign Parameters</Link></li>
                                </ul>
                            </div>

                            <div className="mt-5 lg:mt-0">
                                <Link onClick={() => {
                                    LoadDataForCloneR2PMapping()
                                }} className="text-[#029046] border border-[#029046] rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName mr-5" >Clone New R2P Mapping</Link>
                                <Link onClick={() => {
                                    setEditRelationship(false)
                                    clearAll()
                                    setAssignPopUp(1)
                                }} className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" >Add New R2P Mapping</Link>
                            </div>
                        </div>


                        {/* Listing */}

                        {
                            renderItems &&
                                financialParametersList.length > 0 ?
                                <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                                    {
                                        financialParametersList.map(function (item, i) {
                                            return <div key={i}>
                                                <div className="bg-white dark:bg-[#2A2E32] py-4 px-3  rounded-xl dashbord_box_shape" style={{ boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.1)" }}>
                                                    <div className="flex justify-between">
                                                        <div className="px-2">
                                                            <div className="text-xl font-semibold mb-1 dark:text-white">Financial Year<br /> {item['FinancialYearName']}</div>
                                                            <div className="text-[#667085] text-xs mb-2 dark:text-[#98A2B3]">Period: {item['FinancialYearPeriod']}</div>
                                                            <div className="inline-block border border-[#4FB155] rounded-md text-md font-semibold text-[#4FB155] px-3 py-1 mb-3 ">{item['TotalParameters']}{item["TotalRoles"]} Roles</div>
                                                        </div>
                                                        <div className='flex justify-end'>
                                                            <img className='w-58' src={item['isDeployed'] === true ? parameterComplete : parameterPending} alt="" />
                                                        </div>
                                                    </div>

                                                    <div className="sm:flex items-center justify-between px-1 mt-3">
                                                        <div className="box_btns">
                                                            <ul className="flex gap-2">
                                                                {
                                                                    item['isDeployed'] ?
                                                                        <li><Link className="text-[#029046] flex text-[13px] outlined "><i className="icon check_circle_green_icon_small"> </i>Deployed</Link></li> :
                                                                        <li><Link className="bg-[#029046] flex text-[13px] text-[#fff] active addclassName"><img src={imageDeployed} className="inline-block mr-1" alt="" /> Deploy</Link></li>
                                                                }
                                                                <li><Link onClick={() => {
                                                                    viewRelation(item)
                                                                }} className="addclassName flex text-[13px] text-[#344054] border-[#C6CBD2] dark:bg-[#44494E] dark:text-white dark:border-[#44494E]"><i className="icon eye_small_icon"></i> View</Link></li>
                                                            </ul>
                                                        </div>
                                                        {/* <div className="text-xs text-[#667085] text-end mt-3 md:mt-0">Last Updated on: {item['LastUpdatedDate']}</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                :
                                renderItems &&
                                <NoDataFound />

                        }

                    </div>
                </div>

                {
                    !renderItems &&
                    <div className='flex justify-center items-center'>
                        <ProgressSpinner />
                    </div>
                }

                <div id="selector" className={isAssignPopUp === 1 ? 'overlay' : ''}></div>

                {
                    isAssignPopUp === 1 ?
                        <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                            <div className="relative">
                                <img src={popupHeaderImg} alt="" className='w-full' />
                                <div className="absolute right-0 left-0 top-0 w-full text-white">
                                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                        <div className="text-md">Create New</div>
                                        <div><Link onClick={() => { clearAll(); setAssignPopUp(0) }} className="closebtn text-2xl leading-none removeClass" >&times;</Link></div>
                                    </div>
                                    <div className="px-6 mt-8">
                                        <div className="text-sm">Relationship</div>
                                        <div className="2xl:text-[32px] xl:text-[28px] lg:xl:text-[20px] md:xl:text-[18px]">{!isEditRelationship ? "Add New Relationship" : "Edit Relationship"}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-6 height-custtom">
                                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-4 mb-6">
                                    <div>
                                        <label className="text-[#344054] dark:text-[#fff] text-[14px] font-medium">Financial Year</label>
                                        <Select
                                            isDisabled={isEditRelationship ? true : false}
                                            options={financialYear}
                                            onChange={e => { setDBRole(null); onFinancialSelectChange(e) }}
                                            value={selectedFinancialYear}
                                            className="mt-2 my-react-select-container"
                                            classNamePrefix={isEditRelationship ? "my-react-select disabled bg-[#f9f9f9]" : "my-react-select"}
                                        />
                                    </div>
                                    <div>
                                        <label className="text-[#344054] dark:text-[#fff] text-[14px] font-medium">Role</label>
                                        <Select
                                            isDisabled={isEditRelationship ? true : false}
                                            options={dbRoles}
                                            onChange={e => {
                                                if (selectedFinancialYear) { setRole(e) } else { toast.error("Select financial year.") }
                                            }}
                                            value={dbRole}
                                            className="mt-2 my-react-select-container"
                                            classNamePrefix={isEditRelationship ? "my-react-select disabled bg-[#f9f9f9]" : "my-react-select"}
                                        />
                                    </div>
                                    <div>
                                        <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">Start Date</label>
                                        <div className="disabled bg-[#f9f9f9] mt-1 block w-full flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm text-[16px] focus:outline-none focus:ring-1 focus:ring-blue-500 mt-2 placeholder:text-[#667085] rounded-lg dark:bg-[#2A2E32] dark:text-white dark:border-[#4E5668]">
                                            <div className=" flex items-center justify-between">
                                                <div className=''>{financialYearStartDate}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">End Date</label>
                                        <div className="disabled bg-[#f9f9f9] mt-1 block w-full flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm text-[16px] focus:outline-none focus:ring-1 focus:ring-blue-500 mt-2 placeholder:text-[#667085] rounded-lg dark:bg-[#2A2E32] dark:text-white dark:border-[#4E5668]">
                                            <div className="flex items-center justify-between">
                                                <div>{financialYearEndDate}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    items.length > 0 &&
                                    <div>
                                        <label className='font-semibold'>Total Weightage: </label>
                                        <label className={totalOfWeightage > 100 ? 'text-[red] font-semibold' : "text-[green] font-semibold"} >{totalOfWeightage + "%"}</label>
                                    </div>
                                }

                                {
                                    dbRole !== null && !isEditRelationship ?
                                        <div className="flex items-center justify-between bg-[#EEF8F4] my-6 text-[#344054] text-sm p-4 rounded-lg dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white">
                                            <div className='h-12 flex items-center'>
                                                <input type="checkbox" onChange={(e) => setShowFromFinancialYear(e.target.checked)} className="rounded mr-1 border-[#C8CBD0]" /> Would you Like to copy the Weightage from previous Financial Year?
                                            </div>
                                            {
                                                showFromFinancialYear ?
                                                    <Select
                                                        options={fromFinancialYearList}
                                                        onChange={e => { onFromFinancialYearChange_Create(e) }}
                                                        value={selectedFromFinancialYear}
                                                        className="my-react-select-container"
                                                        classNamePrefix="my-react-select"
                                                        styles={{
                                                            container: base => ({
                                                                ...base,
                                                                flex: 0.5
                                                            })
                                                        }}
                                                    />
                                                    : null
                                            }

                                        </div> : null
                                }
                                {
                                    items.length > 0 ?
                                        <div className='mt-4 height-custtom'>
                                            <DataTable value={items} emptyMessage=" " >
                                                <Column headerStyle={{ width: '40rem' }} field="name" header="Parameters" filter filterPlaceholder="Search by name" />
                                                <Column field="weightage" header="Weightage" body={actionBodyTemplate}></Column>
                                                <Column field="type" header=""></Column>
                                            </DataTable>
                                        </div> : null
                                }


                            </div>
                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">
                                <div className="flex justify-end gap-3">
                                    <div><Link onClick={() => {
                                        clearAll()
                                        setAssignPopUp(0)
                                    }} className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Cancel</Link></div>
                                    <div><Link onClick={() => {
                                        if (isEditRelationship) {
                                            updateRelationship()
                                        } else {
                                            assignRelationship()
                                        }
                                    }} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">{isEditRelationship ? "Update" : "Create New"}</Link></div>
                                </div>
                            </div>
                        </div> : null
                }


                <div id="selector" className={isViewParameterPopUp === 1 ? 'overlay' : ''}></div>

                {
                    isViewParameterPopUp === 1 ?
                        <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">

                            <div className=''>
                                <div className="relative">
                                    <img src={popupHeaderImg} alt="" className='w-full' />
                                    <div className="absolute right-0 left-0 top-0 w-full text-white">
                                        <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                            <div className="text-md">Relationship</div>
                                            <div><Link onClick={() => {
                                                setViewDetailTable(false)
                                                setRole_View("")
                                                if (!viewDetailTable) {
                                                    setViewParameterPopUp(0)
                                                }
                                            }} className="closebtn text-2xl leading-none removeClass" >&times;</Link></div>
                                        </div>
                                        <div className="px-6 mt-8">
                                            <div className="text-sm"> Roles & Relationships</div>
                                            {
                                                role_View !== "" ?
                                                    <div className="2xl:text-[32px] xl:text-[28px] lg:xl:text-[20px] md:xl:text-[18px]">View Relationship - {role_View}</div>
                                                    :
                                                    <div className="2xl:text-[32px] xl:text-[28px] lg:xl:text-[20px] md:xl:text-[18px]">View Roles & Relationships</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="p-6 height-custtom">
                                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-6">
                                        <div>
                                            <label className="text-[#344054] dark:text-[#fff] text-[14px] font-medium">Financial Year</label>
                                            <Select
                                                isDisabled={true}
                                                options={financialYear}
                                                onChange={e => onFinancialSelectChange(e)}
                                                value={selectedFinancialYear}
                                                className="mt-2 my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>
                                        <div>
                                            <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">Start Date</label>
                                            <div style={{ backgroundColor: "#80808015" }} className="mt-1 block w-full flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm text-[16px] focus:outline-none focus:ring-1 focus:ring-blue-500 mt-2 placeholder:text-[#667085] rounded-lg dark:bg-[#2A2E32] dark:text-white dark:border-[#4E5668]">
                                                <div className="flex items-center justify-between">
                                                    <div>{financialYearStartDate}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">End Date</label>
                                            <div style={{ backgroundColor: "#80808015" }} className="mt-1 block w-full flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm text-[16px] focus:outline-none focus:ring-1 focus:ring-blue-500 mt-2 placeholder:text-[#667085] rounded-lg dark:bg-[#2A2E32] dark:text-white dark:border-[#4E5668]">
                                                <div className="flex items-center justify-between">
                                                    <div>{financialYearEndDate}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='mt-4 mb-5'>
                                        {
                                            !viewDetailTable ?
                                                <DataTable value={itemsForView}>
                                                    <Column
                                                        field="name" header="" body={multipleDeploy} />
                                                    <Column field="name" header="Role" ></Column>
                                                    <Column field="createdAt" header="Created" ></Column>
                                                    <Column field="updatedAt" header="Last Updated" ></Column>
                                                    <Column alignHeader={'center'} header="Action" body={actionsOnRoles}></Column>
                                                </DataTable> :
                                                <DataTable value={itemsForView_weightage} emptyMessage=" " >
                                                    <Column headerStyle={{ width: '40rem' }} field="name" header="Parameter" footer="Total" />
                                                    <Column alignHeader={'center'} field="weightage" header="Weightage" bodyStyle={{ textAlign: "center" }} footerStyle={totalOfWeightage > 100 ? { textAlign: "center", color: "red" } : { textAlign: "center", color: "green" }} footer={totalOfWeightage + "%"} ></Column>
                                                </DataTable>
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">

                                <div className="flex justify-end gap-3">
                                    {
                                        isButtonDeployedShow ?
                                            <div><Link onClick={() => { updateMultiStatusOfDeployed() }} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2" >Deploy</Link></div>
                                            : null
                                    }
                                    <div><Link onClick={() => {
                                        setViewDetailTable(false)
                                        setRole_View("")
                                        setSelectedEmployees([])
                                        if (!viewDetailTable) {
                                            setViewParameterPopUp(0)
                                        }
                                    }} className="bg-white text-[#344054] border  border-[#C6CBD2] rounded-md text-sm px-4 py-2 dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white">{!viewDetailTable ? "Close" : "Back"}</Link></div>
                                </div>
                            </div>
                        </div> : null
                }


                <div id="selector" className={isViewDeployedPopUp === 1 ? 'overlay' : ''}></div>
                {
                    isViewDeployedPopUp === 1 ?
                        <div style={{ width: "45vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="displayPopupCont" className="sidenav">
                            <div className="popup_display_img"><img src={imageDeployedLarge} className="mx-auto mt-8" alt="" /></div>
                            <div className="text-xl text-center my-6 dark:text-white">The parameters have been successfully deployed.</div>
                            <div className="px-10 2xl:px-14">

                                <div className="grid grid-cols-2 xl:grid-cols-4 successfully_box py-3 gap-y-3 xl:gap-y-0">
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">Financial Year</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{selectedFinancialYear.label}</div>
                                    </div>
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">Role</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{role_View}</div>
                                    </div>
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">Start Date</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{moment(financialYearStartDate).format("DD MMM, YYYY")}</div>
                                    </div>
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">End Date</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{moment(financialYearEndDate).format("DD MMM,YYYY")}</div>
                                    </div>
                                </div>
                                <div className="block text-center my-6">
                                    <Link onClick={() => { setViewDeployedPopUp(0); navigateTo("/hr/assignparameters"); navigateTo(0) }} className="bg-[#029046] text-white rounded-md px-6 py-2 text-sm shadow-md inline-block removeClass">Go to Assign Parameters</Link>
                                </div>
                            </div>
                        </div> : null
                }



                {/* -------------------------------- Clone R2P Mapping -------------------------------- */}
                <div id="selector" className={isViewClonePopUp === 1 ? 'overlay' : ''}></div>
                {
                    isViewClonePopUp === 1 ?
                        <div style={{ width: "45vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="displayPopupCont" className="sidenav">
                            <div className="relative">
                                <img src={popupHeaderImg} alt="" className='w-full' />
                                <div className="absolute right-0 left-0 top-0 w-full text-white">
                                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                        <div className="text-md">Clone</div>
                                        <div><Link onClick={() => { setViewClonePopUp(0) }} className="closebtn text-2xl leading-none removeClass" >&times;</Link></div>
                                    </div>
                                    <div className="px-6 mt-6 lg:mt-8 2xl:mt-10 xl:mt-8">
                                        <div className="text-sm">Relationship</div>
                                        <div className="2xl:text-[32px] xl:text-[28px] lg:xl:text-[20px] md:xl:text-[18px]">{"Clone R2P Mapping"}</div>
                                    </div>
                                </div>
                            </div>

                            <div className='height-custtom'>
                                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 mb-6 px-5 py-3">
                                    <div>
                                        <label className="text-[#344054] dark:text-[#fff] text-[14px] font-medium">From Financial Year</label>
                                        <Select
                                            options={fromFinancialYearList}
                                            onChange={e => onFromFinancialYearChange(e)}
                                            value={selectedFromFinancialYear}
                                            className="mt-2 my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>
                                    <div>
                                        <label className="text-[#344054] dark:text-[#fff] text-[14px] font-medium">To Financial Year</label>
                                        <Select
                                            options={toFinancialYearList}
                                            onChange={e => setSelectedToFinancialYear({ label: e.name, value: e.id })}
                                            value={selectedToFinancialYear}
                                            className="mt-2 my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>

                                </div>

                                <div className='px-5 py-0 '>
                                    {
                                        getRoleList_Selection_FromYear.length > 0 ?
                                            <DataTable
                                                selection={selectedRolesForClone}
                                                onSelectionChange={e => setRolesForClone(e.value)}
                                                dataKey="roleId" value={getRoleList_Selection_FromYear} emptyMessage=" " >
                                                <Column selectionMode="multiple"
                                                    field="roleName" header="" />
                                                <Column headerStyle={{ width: '40rem' }} field="roleName" header="Role" />
                                            </DataTable> : null
                                    }

                                </div>
                            </div>
                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">
                                <div className="flex justify-end gap-3">
                                    <div><Link onClick={() => { clearAll(); setViewClonePopUp(0) }} className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Cancel</Link></div>
                                    <div><Link onClick={() => { cloneData() }} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">{"Clone"}</Link></div>
                                </div>
                            </div>
                        </div> : null
                }


            </div >
            :
            <HRLogin sessionExpired={1} />
    )
}
