
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';

const GaugeChart = (props) => {

    const gaugeOption = {
        series: [{
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            data: [{ value: props.value, name: props.name }],
            axisLine: {
                lineStyle: {
                    width: 10,
                    color: [[0.50, '#D92D20'], [0.75, '#FDB022'], [1, '#60B866']],
                }
            },
            axisLabel: {
                fontSize: 12,
                fontWeight: 'bold'
            },
            detail: {
                formatter: '{value}',
                fontSize: 24,
                fontWeight: 'bold'
            },
            pointer: {
                width: 5
            }
        }]
    };

    return (
        <div>
            <ReactEcharts
                option={gaugeOption}
            />
        </div>
    );

}
export default GaugeChart;