
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { commaSeparated } from '../../helper/maths';

const SmoothLineChart = (props) => {

  const option = {
    tooltip: {
      trigger: 'axis'
    },
    
    toolbox: {
      show: false,
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        magicType: { type: ['line'] },
        restore: {},
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: ['Quarter 1', 'Quarter 2', 'Quarter 3', 'Quarter 4'],
      show: false,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value} °C'
      },
      show: false,
    },
    series: [
      {
        type: 'line',
        color: 'red',
        data: props.data,
        // markPoint: {
        //   data: [{ name: '周最低', value: props.markPointValue ? props.markPointValue:'99', xAxis: 1, yAxis: -1.5, color: 'red' }]
        // },
        yAxis: {
          type: 'value',
          show: false
        },  
        showSymbol: false,
        endLabel: {
          show: true,
          formatter: function (params) {
            return props.totalValue ? props.unit + '' + commaSeparated(props.totalValue) + '' + props.perUnit : commaSeparated(props.data[3]);
          }
        },

      }
    ]
  };

  return (
    <div>
      <ReactEcharts
        option={option}
      />
    </div>
  );

}
export default SmoothLineChart;