import React, { useState, useEffect } from 'react';
import LeftSideBar from '../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../components/common/hr/topnav.component';
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import EditButton from '../../../components/common/buttons/EditButton';
import DeleteButton from '../../../components/common/buttons/DeleteButton';
import { DataStore } from '@aws-amplify/datastore';
import { FinancialYear, CompanyActuals, Evaluation } from '../../../models';
import { Auth } from 'aws-amplify';
import { ValidateHRRole } from '../../../components/helper/validateRole';
import HRLogin from '../../../setup/auth/hr.component';
import moment from 'moment';
import { toast } from "react-toastify";
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries'
import * as mutations from '../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../src/components/helper/graphQLFunctions'
import { ProgressSpinner } from 'primereact/progressspinner';


export default function Compony_SBU_Actual() {

    let navigateTo = useNavigate();

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    const [actualList, setActualList] = useState([])
    const [quarterList, setQuarterList] = useState([
        {
            "label": "Q1",
            "value": "1"
        },
        {
            "label": "Q2",
            "value": "2"
        },
        {
            "label": "Q3",
            "value": "3"
        },
        {
            "label": "Q4",
            "value": "4"
        }

    ]);


    //function for Actions
    const actionsOnComponySbu = (row) => {
        return (
            <React.Fragment >
                <div className="flex justify-center gap-8 ">
                    <Link onClick={(e) => {
                        handleEditClick(e, row.id)
                    }}  ><EditButton /></Link>
                    <Link onClick={(e) => handleDeleteClick(e, row.id)} ><DeleteButton /></Link>
                </div>

            </React.Fragment>
        );
    }

    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        // const objDeleteRole = await DataStore.query(CompanyActuals, id);
        const objDeleteRole = await graphQLFindRecordById('getCompanyActuals', id);

        alertify.confirm('Confirmation', 'Do you want to delete this Company & SBU Actuals?', async function () {
            //Delete Existing InterpreterRequest for this language
            // DataStore.delete(CompanyActuals, (c) => c.id("eq", id))
            // //Delete language
            // DataStore.delete(objDeleteRole);

            await API.graphql({
                query: mutations.deleteCompanyActuals,
                variables: {
                    input: {
                        id: objDeleteRole.id,
                        _version: objDeleteRole._version,
                    }
                }
            });


            BindList();
            toast.success("Deleted");
        }
            , function () { });

    };

    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/hr/evaluation/createcompanyactuals/' + id);
    };

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }


    async function BindList() {

        //get Financial Year List
        // var actualResponse = await DataStore.query(CompanyActuals);
        var actualResponse = await graphQLGetAllData('listCompanyActuals');

        let finalData = [];
        for (let i = 0; i < actualResponse.length; i++) {
            //set Financial Year
            let financialYearName = ""
            if (actualResponse[i].financial_year_id) {
                // var financialYearResponse = await DataStore.query(FinancialYear, actualResponse[i].financial_year_id);
                var financialYearResponse = await graphQLFindRecordById('getFinancialYear', actualResponse[i].financial_year_id);


                if (financialYearResponse) {
                    financialYearName = financialYearResponse.name
                } else { financialYearName = "" }
            } else { financialYearName = "" }

            //set Quarter
            var QuarterValue = ""
            if (actualResponse[i].quarterNo) {
                let filterValue = quarterList.filter((response) => String(actualResponse[i].quarterNo) === response.value)
                QuarterValue = filterValue[0].label
            } else {
                QuarterValue = ""
            }

            //Get Evaluation Period
            // const EvaluationResponses = await DataStore.query(Evaluation, (c) => c.and(c => [
            //     c.financial_year_id.eq(actualResponse[i].financial_year_id),
            //     c.quaterNo.eq(actualResponse[i].quarterNo.toString())
            // ]));
            const EvaluationResponses = await graphQLGetAllData('listEvaluations', [
                { financial_year_id: { eq: actualResponse[i].financial_year_id } },
                { quaterNo: { eq: actualResponse[i].quarterNo.toString() } }
            ]);

            let startDate = ''; let endDate = '';
            if (EvaluationResponses.length > 0) {
                startDate = moment(EvaluationResponses[0].startDate).format('Do MMM, YYYY');
                endDate = moment(EvaluationResponses[0].endDate).format('Do MMM, YYYY');
            }

            let data = {
                "id": actualResponse[i].id,
                "financialYearName": financialYearName,
                "quarter": QuarterValue,
                "startDate": startDate,
                "endDate": endDate,
                "lastUpdatedOn": moment(actualResponse[i].updatedAt).format("Do MMM, YYYY")
            }
            finalData.push(data)

        }

        setActualList(finalData);

    }

    useEffect(() => {
        onLoad()
        BindList()
    }, []);



    return (
        isAuthenticated ? (
            <div className="pt-16 ">
                <TopNavBar />
                <LeftSideBar />

                <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Goals Evaluation</div>
                        </div>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/hr/evaluation">Initiate Evaluation</Link></li>
                                    <li><Link to="/hr/evaluation/nwcdaysactuals">NWC Days Actuals</Link></li>
                                    <li><Link to="/hr/evaluation/companysbu" className={"active"}>Company & SBU Actuals</Link></li>
                                    <li><Link to="/hr/evaluation/ebitpbt">EBIT/PBT/Bad Debts Actuals</Link></li>
                                </ul>
                            </div>

                            <div className="mt-5 lg:mt-0">
                                <Link to="/hr/evaluation/createcompanyactuals" className="bg-[#029046] text-white rounded-md px-4 py-2.5 lg:py-2.5 text-sm lg:text-md shadow-md addclassName" >Add New</Link>
                            </div>

                        </div>

                        <div className=" rounded-lg mt-5">
                            {   actualList.length > 0 ?
                                <DataTable
                                    paginator
                                    value={actualList}
                                    responsiveLayout="scroll"
                                    style={{ width: '93vw' }}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                >
                                    <Column field="financialYearName" header="Financial Year"></Column>
                                    <Column field="quarter" header="Quarter"></Column>
                                    <Column field="startDate" header="Start Date"></Column>
                                    <Column field="endDate" header="End Date"></Column>
                                    <Column field="lastUpdatedOn" header="Last Updated On"></Column>
                                    <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnComponySbu} ></Column>
                                </DataTable> :
                             <div className='text-center my-auto py-8' ><ProgressSpinner /></div>
                            }
                        </div>

                    </div>
                </div>


            </div >
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}
