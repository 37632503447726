import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import moment from "moment";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState, useCallback } from 'react';
import { json, Link } from "react-router-dom";
import Select from "react-select";
import { reactLocalStorage } from 'reactjs-localstorage';
import LeftSideBar from '../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../components/common/employee/topnav.component';
import CircularProgressBar from '../../../../components/common/progressbar/CircularProgressbar';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { BIUnit, Employee, FinancialYear, Region, Roles, SBU, Targets, Relationship, RelationshipDetails, Parameters, Plan, TargetDetails, Country, Brand, ProductGroup, CustomerCategory, Customer, TargetMessage, ActualDetails, GTM, SubGTM } from "../../../../models";
import EmpLogin from "../../../../setup/auth/emp.component";
import { Storage, API, graphqlOperation } from 'aws-amplify';
import { commaSeparated, isPositiveInteger, ShowInMillion, sumFromArray } from '../../../../components/helper/maths';
import uniqId from 'uniqid';
import {
    ConvertResponseForSelect, GetTotalPercentageFromPlanArray, ConvertResponseForSelectTwoNames,
    GetTotalTargetValueFromPlanArray, RemoveItemFromArrayByIdValue, SetBrandAggregateValueFromPlanArray, SetCountryAggregateValueFromPlanArray, SetCustomerAggregateValueFromPlanArray, SetCustomerSegmentAggregateValueFromPlanArray, SetProductGroupAggregateValueFromPlanArray, SetRegionAggregateValueFromPlanArray, SetBuAggregateValueFromPlanArray, convertArrayToObject, GetSingleValueFromResponse, ObjectToArray, convertNumberToShortNumber, getCurrentQuater, SetBrandAggregateValueFromPlanDashBoard
} from '../../../../components/helper/commonfunctions';
import { PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES, PARAMETER_IDs } from '../../../../components/helper/enum'
import { detailViewFilter, summaryFilter, drillDownFilter } from "../../../../components/helper/getFilterData"
import { GetAWSObjectURL } from '../../../../components/helper/aws';

import { TabView, TabPanel } from 'primereact/tabview';
import { TabMenu } from 'primereact/tabmenu';
import UserImg from '../../../../assets/images/user.png';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import GaugeChart from '../../../../components/common/charts/gaugeChart';
import StackedLineChart from '../../../../components/common/charts/stackedLineChart';
import BarChart from '../../../../components/common/charts/barChart';
import SmoothLineChart from '../../../../components/common/charts/smoothLineChart';
import { ProgressBar } from 'primereact/progressbar';
import { getActualValue } from '../../evaluation/getActualValue';
import { getTargetBasedOnParameterType, getTotalTarget, getTargetBasedOnParameterTypeForEvalPopup } from '../../../../components/common/getTargetBasedOnParameterType';
import { ProgressSpinner } from 'primereact/progressspinner';
import { toast } from 'react-toastify';
import { filterArrayOnQuarter } from '../../../../components/common/filterArrayOnQuarter';
import { checkEmployeeTeamType } from '../../helper';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../components/helper/graphQLFunctions';
import * as queries from '../../../../graphql/queries';
import {
    calculateConsolidatedScore,
    calculateWeightageAchievement,
    checkIfNegativeValue,
} from "../../../../components/helper/dashboardCalCulations";

export default function BusinessPerformanceDashboard() {


    let isReportingManager = reactLocalStorage.get('isReportingManager')

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //state
    const [financialYear, setFinancialYear] = useState("");
    const [financialYearId, setFinancialYearId] = useState("");
    const [employee, setEmployee] = useState("");
    const [employeeId, setEmployeeId] = useState("");
    const [quarter, setQuarter] = useState("");
    const [loggedEmployeeName, setLoggedEmployeeName] = useState("");
    const [reportingManagerId, setReportingManagerId] = useState("");
    const [loggedEmployeeCode, setLoggedEmployeeCode] = useState("");
    const [loggedEmployeeProfile, setLoggedEmployeeProfile] = useState("");
    const [loggedEmployeeRole, setLoggedEmployeeRole] = useState("");
    const [sbu, setSbu] = useState("");
    const [parameter, setParameter] = useState("");
    const [quarterDropdownValue, setQuarterDropdownValue] = useState("");
    const [parameterId, setParameterId] = useState("");
    const [renderItems, setRenderItems] = useState(false);
    const [isParameterDropdown, setIsParameterDropdown] = useState(false);
    const [allQCheck, setAllQCheck] = useState(true);
    const [Q1Check, setQ1Check] = useState(true);
    const [Q2Check, setQ2Check] = useState(true);
    const [Q3Check, setQ3Check] = useState(true);
    const [Q4Check, setQ4Check] = useState(true);
    const [parameterName, setParameterName] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [consolidatedScore, setConsolidatedScore] = useState("");
    const [consolidatedValue, setConsolidatedValue] = useState("");
    const [dropDownValue, setDropDownValue] = useState("");
    const [employeeTeam, setEmployeeTeam] = useState([]);
    const [showParameterAnalysisData, setShowParameterAnalysisData] = useState(false);
    const [NWCValue, setNWCValue] = useState("");

    const [parameterAnalysisData, setParameterAnalysisData] = useState("");
    const [employeeDropdownTeam, setEmployeeDropdownTeam] = useState([]);
    const [parameterAnalysisYearValue, setParameterAnalysisYearValue] = useState("");
    const [parameterAnalysisQuarterValue, setParameterAnalysisQuarterValue] = useState("");

    //list
    const [financialYearList, setFinancialYearList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [parameterList, setParameterList] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [parameterDetailViewList, setParameterDetailViewList] = useState([]);
    const [quarterTargetValue, setQuarterTargetValue] = useState([]);
    const [quarterActualsValue, setQuarterActualsValue] = useState([]);
    const [reporteeAlert, setReporteeAlert] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(0);

    const setTopList = [
        { label: 'All', value: "0" },
        { label: 'Top 5', value: "1" }
    ];

    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')

    //
    const [parametersForSelect, setParametersForSelect] = useState([]);
    const [selectedParameter, setSelectedParameter] = useState("");
    const [current_Target_Details, setCurrentTargetDetails] = useState([]);
    const [brandsData, setBrandsData] = useState([]);
    const [allParameters, setAllParameters] = useState([]);
    const [isDetailedViewLoaded, setIsDetailedViewLoaded] = useState(false);


    //quarterList
    var quarterList = [
        { label: "All Quarter", value: "Quarter 4" },
        { label: "Quarter 1", value: "Quarter 1" },
        { label: "Quarter 2", value: "Quarter 2" },
        { label: "Quarter 3", value: "Quarter 3" },
        { label: "Quarter 4", value: "Quarter 4" },
    ]


    var monthList = [
        // { label: "All Months", value: "All" },
        { label: "Jan", value: "1" },
        { label: "Feb", value: "2" },
        { label: "March", value: "3" },
        { label: "April", value: "4" },
        { label: "May", value: "5" },
        { label: "Jun", value: "6" },
        { label: "Jul", value: "7" },
        { label: "Aug", value: "8" },
        { label: "Sept", value: "9" },
        { label: "Oct", value: "10" },
        { label: "Nov", value: "11" },
        { label: "Dec", value: "12" },
    ]

    const getParameterName = (rowdata) => {
        let data = allParameters.find(c => c.id === rowdata.parameterName)
        return data ? data.name : '-';
    }

    //Level List
    var levelList = [
        { label: "Select", value: "" },
        { label: "BU", value: "BU" },
        { label: "Region", value: "Region" },
        { label: "Country", value: "Country" },
        { label: "Brand", value: "Brand" },
        { label: "Product Group", value: "ProductGroup" },
        { label: "Customer", value: "Customer" },
    ]

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }
    const items = [
        { label: 'Top 3' },
        { label: 'Bottom 3' }

    ];

    async function loadSummaryData() {

        //load Logged employee response
        var getTargetOfEmp = ""
        let selectedEmployeeOnEvaluation = reactLocalStorage.getObject('selectedEmployeeOnEvaluation');

        if (selectedEmployeeOnEvaluation.value) {
            setEmployee(selectedEmployeeOnEvaluation)
            if (!selectedEmployeeOnEvaluation.value) {
                getTargetOfEmp = loggedEmployeeId
            } else {
                getTargetOfEmp = selectedEmployeeOnEvaluation.value
            }
        } else {
            // setEmployee({ label: "All Employee", value: 0 })
            getTargetOfEmp = loggedEmployeeId
        }

        // var loggedEmployeeResponse = await DataStore.query(Employee, (c) => c.id.eq(getTargetOfEmp));
        var loggedEmployeeResponse = await graphQLFindRecordById('getEmployee', getTargetOfEmp);

        if (loggedEmployeeResponse) {

            setReportingManagerId(loggedEmployeeResponse.parentEmployeeId)
            let fullName = loggedEmployeeResponse.firstName + ' ' + loggedEmployeeResponse.lastName
            setLoggedEmployeeName(fullName)
            let employeeCode = loggedEmployeeResponse.employeeId === null ? "" : loggedEmployeeResponse.employeeId
            setLoggedEmployeeCode(employeeCode)


            //set Profile
            if (loggedEmployeeResponse.profilePic) {
                await Storage.get(loggedEmployeeResponse.profilePic)
                    .then(result => {
                        setLoggedEmployeeProfile(result)
                    });
            } else { setLoggedEmployeeProfile(UserImg) }

            //set role
            let employeeRole = ""
            if (loggedEmployeeResponse.role_id !== null) {
                // var loggedEmployeeRole = await DataStore.query(Roles, (c) => c.id.eq(loggedEmployeeResponse.role_id));
                var loggedEmployeeRole = await graphQLFindRecordById('getRoles', loggedEmployeeResponse.role_id);

                if (loggedEmployeeRole) { employeeRole = loggedEmployeeRole.name } else { employeeRole = "" }
            } else { employeeRole = "" }
            setLoggedEmployeeRole(employeeRole)

            //set SBU
            let employeeSBU = ""
            if (loggedEmployeeResponse.param_sbu_id !== null) {
                // var loggedEmployeeSbu = await DataStore.query(SBU, (c) => c.id.eq(loggedEmployeeResponse.param_sbu_id));
                var loggedEmployeeSbu = await graphQLFindRecordById('getSBU', loggedEmployeeResponse.param_sbu_id);

                if (loggedEmployeeSbu) { employeeSBU = loggedEmployeeSbu.name } else { employeeSBU = "" }
            } else { employeeSBU = "" }
            setSbu(employeeSBU)

        }
        //load Summary Parameters
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        setQuarter({ label: "Quarter 1", value: "Quarter 1" })

        LoadSummaryParametersData(selectedYear.value, getTargetOfEmp, "Quarter 1", loggedEmployeeResponse.parentEmployeeId)
        getParameterAnalysisData(parameter, selectedYear, "Quarter 1")

        //load detail view data
        loadSummaryDetailViewData()
    }

    //Load Summary Parameters Data
    async function LoadSummaryParametersData(financialYearId, employeeId, quarterValue, reportManagerEmployeeId) {
        // if (employeeId === 0) { employeeId = loggedEmployeeId } else { employeeId = employeeId }

        if (financialYearId && employeeId && quarterValue) {

            //get FY
            // var FYResponse = await DataStore.query(FinancialYear, financialYearId);
            var FYResponse = await graphQLFindRecordById('getFinancialYear', financialYearId);

            // //get parameters
            // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
            //     c.employee_id.eq(employeeId),
            //     c.financial_year_id.eq(financialYearId),
            //     c.report_manager_employee_id.eq(reportManagerEmployeeId)
            // ]));

            var targetsResponses = await graphQLGetAllData('listTargets', [
                { employee_id: { eq: employeeId } },
                { financial_year_id: { eq: financialYearId } },
                // { report_manager_employee_id: { eq: reportManagerEmployeeId } }
            ]);

            if (targetsResponses.length > 0) {

                //get parameters weightage
                // var relationshipResponse = await DataStore.query(Relationship, (c) => c.and(c => [
                //     c.role_id.eq(targetsResponses[0].role_id),
                //     c.financial_year_id.eq(financialYearId)
                // ]));

                var relationshipResponse = await graphQLGetAllData('listRelationships', [
                    { role_id: { eq: targetsResponses[0].role_id } },
                    { financial_year_id: { eq: financialYearId } }
                ]);


                var relationshipDetailResponses = []
                if (relationshipResponse.length > 0) {
                    // relationshipDetailResponses = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationshipResponse[0].id));
                    relationshipDetailResponses = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: relationshipResponse[0].id } }]);
                }

                var _tempArray = [];

                // //Target Details
                // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.target_id.eq(targetsResponses[0].id));

                // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
                //     c.target_id.eq(targetsResponses[0].id),
                //     c.parameter_id.notContains(PARAMETER_IDs.DIO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DPO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DVRO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DSO),
                // ]));

                var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                    { target_id: { eq: targetsResponses[0].id } },
                    { parameter_id: { notContains: PARAMETER_IDs.DIO } },
                    { parameter_id: { notContains: PARAMETER_IDs.DPO } },
                    { parameter_id: { notContains: PARAMETER_IDs.DVRO } },
                    { parameter_id: { notContains: PARAMETER_IDs.DSO } }
                ]);


                // currentTargetDetails.forEach(async (currentItem, i) => {
                for (var i = 0; i < currentTargetDetails.length; i++) {
                    //Status
                    let status = ""
                    if (currentTargetDetails[i].is_accepted_by_employee && !currentTargetDetails[i].is_approve) { status = "Awaiting approval" }
                    else if (currentTargetDetails[i].isSendForRevisionByReportingManage) { status = "Revision" }
                    else if (currentTargetDetails[i].is_approve) { status = "Approved" }
                    else if (currentTargetDetails[i].is_revision_by_employee) { status = "Requested for Revision" }
                    else { status = "Open" }

                    //get parameters details
                    // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(currentTargetDetails[i].parameter_id));
                    var parameterResponses = await graphQLFindRecordById('getParameters', currentTargetDetails[i].parameter_id);

                    var parameterResponses2 = await graphQLFindRecordById('getParameters', currentTargetDetails[i].parameter_id);
                    // var parameterResponses = await graphQLFindRecordById(

                    var getWeightage = relationshipDetailResponses.filter((e) => e.parameter_id === currentTargetDetails[i].parameter_id)

                    var weightageForWtAch = getWeightage.length > 0 ? getWeightage[0].weightage : 1;

                    var parameterBaseType = parameterResponses.type === PARAMETERS_TYPES.Days ? PARAMETERS_TYPES_NAMES.D : parameterResponses.type === PARAMETERS_TYPES.Amount ? PARAMETERS_TYPES_NAMES.A : parameterResponses.type === PARAMETERS_TYPES.Number ? PARAMETERS_TYPES_NAMES.N : PARAMETERS_TYPES_NAMES.P

                    var planActual = getActualValue(
                        currentTargetDetails[i],
                        quarterValue,
                        parameterBaseType,
                    );


                    var actualPerformance = 0;
                    // var actualPerformance = 0;
                    var actualAch = 0; var wtAch = 0;



                    var planActual = getActualValue(currentTargetDetails[i], quarterValue, parameterBaseType)


                    if (planActual) {
                        if (parameterBaseType === PARAMETERS_TYPES_NAMES.D) {
                            actualPerformance = parseFloat(planActual).toFixed(2);
                        } else {
                            actualPerformance =
                                parseInt(planActual) < 0
                                    ? Math.round(planActual)
                                    : Math.round(planActual);
                        }

                    } else {
                        actualPerformance = 0;
                    }


                    // var getTargetOnParameterType = getTotalTarget(currentTargetDetails[i], quarterValue, parameterBaseType)
                    // var getPlanDetails = await DataStore.query(Plan, (c) => c.target_detail_id.eq(currentTargetDetails[i].id));

                    var getPlanDetails = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: currentTargetDetails[i].id } }]);


                    var getTargetOnParameterType = getTargetBasedOnParameterTypeForEvalPopup(
                        getPlanDetails,
                        quarterValue,
                        parameterBaseType,
                        currentTargetDetails[i]
                    );


                    actualAch = await calculateConsolidatedScore(
                        planActual,
                        getTargetOnParameterType,
                    );
                    wtAch = await calculateWeightageAchievement(weightageForWtAch, actualAch);

                    //Check iF Negative
                    actualAch = await checkIfNegativeValue(actualAch);
                    wtAch = await checkIfNegativeValue(wtAch);
                    let originalWtAch = wtAch

                    // actualPerformance = await checkIfNegativeValue(actualPerformance)


                    // if (getWeightage.length > 0) {

                    if (parameterResponses) {
                        let data = {
                            "target_detail_id": currentTargetDetails[i].id,
                            "financial_year_id": FYResponse.value,
                            "financial_year": FYResponse.label,
                            "parameter_id": currentTargetDetails[i].parameter_id,
                            "parameter_name": parameterResponses.name,
                            "parameter_type": parameterResponses.type === "A" ? "$" : parameterResponses.type === "D" ? "Days" : parameterResponses.type === "P" ? "%" : parameterResponses.type === "N" ? "Number" : "",
                            "target_value": getTargetOnParameterType
                                ? getTargetOnParameterType
                                : 0,
                            "actualPerformance":
                                actualPerformance !== undefined ? actualPerformance : 0,
                            actualPerformanceValue: actualPerformance,
                            actualAch: actualAch ? actualAch + "%" : "0%",
                            wtAch: isFinite(wtAch) ? wtAch + "%" : "0%",
                            "isMarkAsComplete": currentTargetDetails[i].isMarkAsComplete,
                            "is_accepted_by_employee": currentTargetDetails[i].is_accepted_by_employee,
                            "is_approve": currentTargetDetails[i].is_approve,
                            "is_revision_by_employee": currentTargetDetails[i].is_revision_by_employee,
                            "isSendForRevisionByReportingManage": currentTargetDetails[i].isSendForRevisionByReportingManage,
                            "parameter_weightage": weightageForWtAch ? weightageForWtAch : "0",
                            "isPlanMandatory": getWeightage.length > 0 ? 1 : 0,
                            //"planning_period": moment(financialYearResponses[0].fromDate).format("Do MMM YY") + " - " + moment(financialYearResponses[0].toDate).format("Do MMM YY")
                            "status": status,
                            "q1_target_value": currentTargetDetails[i].q1_target_value,
                            "q2_target_value": currentTargetDetails[i].q2_target_value,
                            "q3_target_value": currentTargetDetails[i].q3_target_value,
                            "q4_target_value": currentTargetDetails[i].q4_target_value,
                            "q1_actual_value": getActualValue(currentTargetDetails[i], "Quarter 1", parameterBaseType),
                            "q2_actual_value": getActualValue(currentTargetDetails[i], "Quarter 2", parameterBaseType),
                            "q3_actual_value": getActualValue(currentTargetDetails[i], "Quarter 3", parameterBaseType),
                            "q4_actual_value": getActualValue(currentTargetDetails[i], "Quarter 4", parameterBaseType),
                            "totalWeightage": actualAch ? parseFloat(actualAch).toFixed(2) : 0,
                            "originalWtAch": originalWtAch,
                        }
                        _tempArray.push(data)
                    }
                    // }
                }
                let sortArray = _tempArray.sort((a, b) => (a.parameter_name > b.parameter_name ? 1 : -1));
                // let getFinalTargetTotalArray = getTargetTotalArray.filter(x => !Number.isNaN(x));
                // const targetTotalArrayData = await calculateTargetTotal(getFinalTargetTotalArray)
                // const calculateConsolidatedScoreData = await calculateConsolidatedScore(targetTotalArrayData, targetTotalArrayData)


                let dollerValue = sortArray.find(o => o.parameter_id === PARAMETER_IDs["Gross Margin $"])
                let revenueValue = sortArray.find(o => o.parameter_id === PARAMETER_IDs.Revenue)
                let gmPercentageValue = revenueValue.actualPerformanceValue ? (parseInt(dollerValue ? dollerValue?.actualPerformanceValue :0) / parseInt(revenueValue.actualPerformanceValue)) * 100 : 0;
                let filteredData = sortArray.filter(e => e.isPlanMandatory)

                let objIndex = filteredData.findIndex((obj => obj.parameter_id == PARAMETER_IDs["Gross Margin %"]));

                if (objIndex >= 0) {
                    filteredData[objIndex].actualPerformance = gmPercentageValue.toFixed(2) * -1;
                    filteredData[objIndex].q1_actual_value = filteredData[objIndex].q1_actual_value * -1;
                    filteredData[objIndex].q2_actual_value = filteredData[objIndex].q2_actual_value * -1;
                    filteredData[objIndex].q3_actual_value = filteredData[objIndex].q3_actual_value * -1;
                    filteredData[objIndex].q4_actual_value = filteredData[objIndex].q4_actual_value * -1;
                }

                let objIndexforGM$ = filteredData.findIndex((obj => obj.parameter_id == PARAMETER_IDs["Gross Margin $"]));

                if (objIndexforGM$ >= 0) {
                    filteredData[objIndexforGM$].actualPerformance = filteredData[objIndexforGM$].actualPerformance * -1;
                    filteredData[objIndexforGM$].q1_actual_value = filteredData[objIndexforGM$].q1_actual_value * -1;
                    filteredData[objIndexforGM$].q2_actual_value = filteredData[objIndexforGM$].q2_actual_value * -1;
                    filteredData[objIndexforGM$].q3_actual_value = filteredData[objIndexforGM$].q3_actual_value * -1;
                    filteredData[objIndexforGM$].q4_actual_value = filteredData[objIndexforGM$].q4_actual_value * -1;
                }

                let filteredArray = sortArray.filter(e => e.parameter_id === PARAMETER_IDs.Revenue || e.parameter_id === PARAMETER_IDs['NWC DAYS'] || e.parameter_id === PARAMETER_IDs['Gross Margin %'] || e.parameter_id === PARAMETER_IDs['Gross Margin $']);

                let totalConsolidatedValue = filteredData.reduce((acc, book) => acc + book.parameter_weightage, 0);
                // let totalConsolidatedScore = filteredData.reduce((acc, book) => acc + parseFloat(book.originalWtAch), 0);
                // setConsolidatedScore(!isNaN(totalConsolidatedScore) ? parseFloat(totalConsolidatedScore).toFixed(2) : 0)

                setConsolidatedValue(totalConsolidatedValue)


                let checkNWCIsPrsesnt = filteredData.find(c => c.parameter_id === PARAMETER_IDs["NWC DAYS"])
                if (checkNWCIsPrsesnt) {
                    filteredData = filteredData.filter(c => !(c.parameter_id === PARAMETER_IDs.DIO || c.parameter_id === PARAMETER_IDs.DPO || c.parameter_id === PARAMETER_IDs.DSO || c.parameter_id === PARAMETER_IDs.DVRO))
                } else {
                    filteredData = filteredData.filter(c =>
                        !(c.parameter_id === PARAMETER_IDs.DIO && Number(c.target_value) === 0 ||
                        c.parameter_id === PARAMETER_IDs.DPO && Number(c.target_value) === 0 ||
                        c.parameter_id === PARAMETER_IDs.DSO && Number(c.target_value) === 0 ||
                        c.parameter_id === PARAMETER_IDs.DVRO && Number(c.target_value) === 0)
                    )
                }

                setSummaryData(filteredData)

                var totalConsolidatedScore = 0;
                for (var i = 0; i < filteredData.length; i++) {
                    let weightPercentage = consolidatedScoreFormatter(filteredData[i]);
                    weightPercentage = weightPercentage.replace('%', '')
                    weightPercentage = Number(parseFloat(weightPercentage).toFixed(2));
                    totalConsolidatedScore = totalConsolidatedScore + weightPercentage;

                    if (i === filteredData.length - 1) {
                        setConsolidatedScore(totalConsolidatedScore);
                    }
                }



                let initialParameter = sortArray.find(c => c.parameter_name === "Revenue");

                if (initialParameter) {

                    setQuarterTargetValue([parseFloat(initialParameter.q1_target_value).toFixed(2), parseFloat(initialParameter.q2_target_value).toFixed(2), parseFloat(initialParameter.q3_target_value).toFixed(2), parseFloat(initialParameter.q4_target_value).toFixed(2)])

                    setQuarterActualsValue([parseFloat(initialParameter.q1_actual_value).toFixed(2), parseFloat(initialParameter.q2_actual_value).toFixed(2), parseFloat(initialParameter.q3_actual_value).toFixed(2), parseFloat(initialParameter.q4_actual_value).toFixed(2)])

                    setParameterName(initialParameter.parameter_name)
                    await LoadBrandsData(initialParameter)
                }
                else {
                    setQuarterTargetValue([parseFloat(filteredData[0].q1_target_value).toFixed(2), parseFloat(filteredData[0].q2_target_value).toFixed(2), parseFloat(filteredData[0].q3_target_value).toFixed(2), parseFloat(filteredData[0].q4_target_value).toFixed(2)])

                    setQuarterActualsValue([parseFloat(filteredData[0].q1_actual_value).toFixed(2), parseFloat(filteredData[0].q2_actual_value).toFixed(2), parseFloat(filteredData[0].q3_actual_value).toFixed(2), parseFloat(filteredData[0].q4_actual_value).toFixed(2)])

                    setParameterName(filteredData[0].parameter_name)
                    await LoadBrandsData(filteredData[0])
                }
                //})
            }
            else {
                setSummaryData([]);
                setQuarterTargetValue([]);
                setQuarterActualsValue([]);
                setParameterName("");
                LoadBrandsData([]);
            }
        }
    }

    // async function LoadBrandsData(data) {
    //     console.log('data',data);
    //     // let planData = await DataStore.query(Plan, (c) => c.target_detail_id.eq(data.target_detail_id));
    //     let planData = [];
    //     if (data.target_detail_id) {
    //         planData = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: data.target_detail_id } }]);
    //     }

    //     if (planData.length > 0) {
    //         var brandDimensionArray = [];
    //         var brandDimension = await SetBrandAggregateValueFromPlanDashBoard(planData)
    //         var sumOfBrand = await GetTotalTargetValueFromPlanArray(brandDimension)
    //         brandDimension.map(async currentItem => {
    //             let _brandList = []
    //             if (brandList.length > 0) {
    //                 _brandList = [...brandList]
    //             } else {
    //                 // _brandList = await DataStore.query(Brand, Predicates.ALL);
    //                 _brandList = await graphQLGetAllData('listBrands')

    //             }
    //             var returnRecord = _brandList.find(o => o.id === currentItem.brandId);
    //             if (returnRecord != null) {
    //                 let Percentage = (currentItem.targetValue * 100) / sumOfBrand;
    //                 brandDimensionArray.push({ "Brand": returnRecord.name, "Percentage": Percentage.toFixed(2) })
    //                 console.log('Percentage',Percentage);
    //             }
    //             return currentItem;
    //         });
    //         setBrandsData(brandDimensionArray);
    //     }
    // }

    async function LoadBrandsData(data) {

        // let ActualsOfParameters = await DataStore.query(ActualDetails, (c) => c.target_details_id.eq(data.target_detail_id))
        let ActualsOfParameters = await graphQLGetAllData("listActualDetails", [{ target_details_id: { eq: data.target_detail_id } }])
        if (ActualsOfParameters.length > 0) {
            const brandTotals = [];
            ActualsOfParameters.forEach(transaction => {
                const brand = transaction.brand;
                const actualValue = parseFloat(transaction.actual_value);
                const brandIndex = brandTotals.findIndex(item => item.brand === brand);
                if (brandIndex !== -1) {
                    brandTotals[brandIndex].total += actualValue;
                } else {
                    // Add a new brand object to the array
                    brandTotals.push({
                        brand: brand,
                        total: actualValue
                    });
                }
            });

            if (data.parameter_name === "GM $" || data.parameter_name === "GM %") {
                if (brandTotals) {
                    const updatedData = brandTotals.map(item => ({
                        Brand: item.brand,
                        Percentage: Math.round(item.total) * -1
                    }));
                    setBrandsData(updatedData);
                }
            } else {
                if (brandTotals) {
                    const updatedData = brandTotals.map(item => ({
                        Brand: item.brand,
                        Percentage: Math.round(item.total)
                    }));
                    setBrandsData(updatedData);
                }
            }


        }
    }

    //Load Detail View Data
    let loadSummaryDetailViewData = async () => {

        let financialYearId = ""
        let employeeID = ""

        if (employee.value) { employeeID = employee.value } else { employeeID = loggedEmployeeId }
        if (financialYear.value) { financialYearId = financialYear.value } else {
            let selectedYear = reactLocalStorage.getObject('selectedYear');
            financialYearId = selectedYear.value
        }

        var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

        setFinancialYear({ label: responses[0].name.toString(), value: responses[0].id.toString() })

        //get target response
        // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.employee_id.eq(employeeID),
        //     c.financial_year_id.eq(financialYearId),
        //     c.report_manager_employee_id.notContains(employeeID)
        // ]));

        // var targetsResponses = await graphQLGetAllData('listTargets', [
        //     { employee_id: { eq: employeeID } },
        //     { financial_year_id: { eq: financialYearId } },
        //     // { report_manager_employee_id: { notContains: employeeID } }
        // ]);


        // if (targetsResponses.length > 0) {

        //     // var targetDetails = await DataStore.query(TargetDetails, (c) => c.target_id.eq(targetsResponses[0].id))

        //     var targetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: targetsResponses[0].id } }]);


        //     let finalData = [];

        //     if (targetDetails.length > 0) {

        //         for (let i = 0; i < targetDetails.length; i++) {

        //             // let planData = await DataStore.query(Plan, (c) => c.target_detail_id.eq(targetDetails[i].id))
        //             let planData = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: targetDetails[i].id } }]);

        //             let planDataArray = []
        //             //bind plan data
        //             if (planData.length > 0) {

        //                 for (let j = 0; j < planData.length; j++) {

        //                     //get BU
        //                     let buId = ""
        //                     let buName = ""
        //                     if (planData[j].bu_id !== null) {
        //                         // let buResponses = await DataStore.query(BIUnit, (c) => c.id.eq(planData[j].bu_id))
        //                         let buResponses = await graphQLFindRecordById('getBIUnit', planData[j].bu_id);

        //                         if (buResponses) { buId = buResponses.id; buName = buResponses.name } else { buId = ""; buName = ""; }
        //                     } else { buId = ""; buName = ""; }

        //                     //get Region
        //                     let regionId = ""
        //                     let regionName = ""
        //                     if (planData[j].region_id !== null) {
        //                         // let regionResponses = await DataStore.query(Region, (c) => c.id.eq(planData[j].region_id))
        //                         let regionResponses = await graphQLFindRecordById('getRegion', planData[j].region_id);

        //                         if (regionResponses) { regionId = regionResponses.id; regionName = regionResponses.name } else { regionId = ""; regionName = ""; }
        //                     } else { regionId = ""; regionName = ""; }

        //                     //get country
        //                     let countryId = ""
        //                     let countryName = ""
        //                     if (planData[j].country_id !== null) {
        //                         // let countryResponses = await DataStore.query(Country, (c) => c.id.eq(planData[j].country_id))
        //                         let countryResponses = await graphQLFindRecordById('getCountry', planData[j].country_id);

        //                         if (countryResponses) { countryId = countryResponses.id; countryName = countryResponses.name } else { countryId = ""; countryName = ""; }
        //                     } else { countryId = ""; countryName = ""; }

        //                     //get Brand
        //                     let brandId = ""
        //                     let brandName = ""
        //                     if (planData[j].brand_id !== null) {
        //                         // let brandResponses = await DataStore.query(Brand, (c) => c.id.eq(planData[j].brand_id))
        //                         let brandResponses = await graphQLFindRecordById('getBrand', planData[j].brand_id);

        //                         if (brandResponses) { brandId = brandResponses.id; brandName = brandResponses.name } else { brandId = ""; brandName = ""; }
        //                     } else { brandId = ""; brandName = ""; }

        //                     //get PG
        //                     let pGId = ""
        //                     let pGName = ""
        //                     if (planData[j].product_group_id !== null) {
        //                         // let productGroupResponses = await DataStore.query(ProductGroup, (c) => c.id.eq(planData[j].product_group_id))
        //                         let productGroupResponses = await graphQLFindRecordById('getProductGroup', planData[j].product_group_id);
        //                         if (productGroupResponses) { pGId = productGroupResponses.id; pGName = productGroupResponses.name } else { pGId = ""; pGName = ""; }
        //                     } else { pGId = ""; pGName = ""; }

        //                     //get customer Group
        //                     let custCatId = ""
        //                     let custCatName = ""
        //                     if (planData[j].customer_group_id !== null) {
        //                         // let customerGroupResponses = await DataStore.query(CustomerCategory, (c) => c.id.eq(planData[j].customer_group_id))
        //                         let customerGroupResponses = await graphQLFindRecordById('getCustomerCategory', planData[j].customer_group_id);
        //                         if (customerGroupResponses) { custCatId = customerGroupResponses.id; custCatName = customerGroupResponses.name } else { custCatId = ""; custCatName = ""; }
        //                     } else { custCatId = ""; custCatName = ""; }

        //                     //get Customer
        //                     let customerId = ""
        //                     let customerName = ""
        //                     if (planData[j].customer_id !== null) {
        //                         // let customerResponses = await DataStore.query(Customer, (c) => c.id.eq(planData[j].customer_id))
        //                         let customerResponses = await graphQLFindRecordById('getCustomer', planData[j].customer_id);
        //                         if (customerResponses) { customerId = customerResponses.id; customerName = customerResponses.name } else { customerId = ""; customerName = ""; }
        //                     } else { customerId = ""; customerName = ""; }

        //                     let gtmName = ""
        //                     if (planData[j].gtm_Id !== null) {
        //                         // let gtmResponses = await DataStore.query(GTM, planData[j].gtm_Id)
        //                         let gtmResponses = await graphQLFindRecordById('getGTM', planData[j].gtm_Id);
        //                         if (gtmResponses) { gtmName = gtmResponses.name }
        //                     }

        //                     let subGtmName = ""
        //                     if (planData[j].sub_gtm_id !== null) {
        //                         // let gtmResponses = await DataStore.query(SubGTM, planData[j].sub_gtm_id)
        //                         let gtmResponses = await graphQLFindRecordById('getSubGTM', planData[j].sub_gtm_id);
        //                         if (gtmResponses) { subGtmName = gtmResponses.name }
        //                     }

        //                     let data = {
        //                         "id": planData[j].id, "sbuId": planData[j].sbu_id, "buId": buId, "buName": buName, "regionId": regionId, "regionName": regionName,
        //                         "countryId": countryId, "countryName": countryName, "brandId": brandId, "brandName": brandName, "pGId": pGId, "pGName": pGName,
        //                         "custCatId": custCatId, "custCatName": custCatName, "customerId": customerId, "customerName": customerName,
        //                         "target_value": commaSeparated(planData[j].target_value), "target_Actual_value": "",
        //                         "gtmId": planData[j].gtm_Id, "gtmName": gtmName,
        //                         "subGtmId": planData[j].sub_gtm_id, "subGtmName": subGtmName,
        //                         "target_Actual_value_Ach": "", "q1_target_value": commaSeparated(planData[j].q1_target_value), "q1_target_Actual_value": "", "q1_target_Actual_value_Ach": "", "q2_target_value": commaSeparated(planData[j].q2_target_value), "q2_target_Actual_value": "", "q2_target_Actual_value_Ach": "",
        //                         "q3_target_value": commaSeparated(planData[j].q3_target_value), "q3_target_Actual_value": "", "q3_target_Actual_value_Ach": "", "q4_target_value": commaSeparated(planData[j].q4_target_value), "q4_target_Actual_value": "", "q4_target_Actual_value_Ach": "",
        //                     }
        //                     planDataArray.push(data)

        //                     if (j === planData.length - 1) {
        //                         finalData.push(planDataArray)
        //                     }
        //                 }
        //             }

        //             if (i === targetDetails.length - 1) {
        //                 let finalParameterData = convertArrayToObject(finalData)
        //                 setParameterDetailViewList(finalParameterData)
        //                 setRenderItems(true)
        //             }

        //         }
        //     } 
        //     else {
        //         setParameterDetailViewList([])
        //     }

        // } else {
        //     setParameterDetailViewList([])
        // }

        setSelectedMonth({ label: 'April', value: '4' });
        setParameter({ label: 'Revenue', value: '3fc887f7-2cf9-430a-8d8f-c48c8299431b' })

        const data = await detailViewFilter(loggedEmployeeId, PARAMETER_IDs.Revenue, responses[0].id, 4);


        const finalDetailViewResult = Array.from(data)
        if (finalDetailViewResult.length > 0) {
            setParameterDetailViewList(finalDetailViewResult)
            setIsDetailedViewLoaded(true)
        }
        else {
            setParameterDetailViewList([]);
            setIsDetailedViewLoaded(true)
        }
    }

    var loadEmployeeData = async (selectedEmployeeId) => {
        // var loggedEmployeeResponse = await DataStore.query(Employee, (c) => c.id.eq(selectedEmployeeId));
        var loggedEmployeeResponse = await graphQLFindRecordById('getEmployee', selectedEmployeeId);

        if (loggedEmployeeResponse) {

            let fullName = loggedEmployeeResponse.firstName + ' ' + loggedEmployeeResponse.lastName
            setLoggedEmployeeName(fullName)
            let employeeCode = loggedEmployeeResponse.employeeId === null ? "" : loggedEmployeeResponse.employeeId
            setLoggedEmployeeCode(employeeCode)

            //set Profile
            if (loggedEmployeeResponse.profilePic) {
                await Storage.get(loggedEmployeeResponse.profilePic)
                    .then(result => {
                        setLoggedEmployeeProfile(result)
                    });
            } else { setLoggedEmployeeProfile(UserImg) }

            //set role
            let employeeRole = ""
            if (loggedEmployeeResponse.role_id !== null) {
                // var loggedEmployeeRole = await DataStore.query(Roles, (c) => c.id.eq(loggedEmployeeResponse.role_id));
                var loggedEmployeeRole = await graphQLFindRecordById('getRoles', loggedEmployeeResponse.role_id);
                if (loggedEmployeeRole) { employeeRole = loggedEmployeeRole.name } else { employeeRole = "" }
            } else { employeeRole = "" }
            setLoggedEmployeeRole(employeeRole)

            //set SBU
            let employeeSBU = ""
            if (loggedEmployeeResponse.param_sbu_id !== null) {
                // var loggedEmployeeSbu = await DataStore.query(SBU, (c) => c.id.eq(loggedEmployeeResponse.param_sbu_id));
                var loggedEmployeeSbu = await graphQLFindRecordById('getSBU', loggedEmployeeResponse.param_sbu_id);
                if (loggedEmployeeSbu) { employeeSBU = loggedEmployeeSbu.name } else { employeeSBU = "" }
            } else { employeeSBU = "" }
            setSbu(employeeSBU)
        }
    }

    //onClick Employee
    const handleClickEmployeeDropdown = async (event) => {
        setIsDetailedViewLoaded(false)
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        setEmployee(event)
        setEmployeeId(event.value)

        let reportingManagerId = "";
        if (event.value === 0) {
            event.value = loggedEmployeeId
            // var loggedEmployeeResponse = await DataStore.query(Employee, (c) => c.id.eq(event.value));
            var loggedEmployeeResponse = await graphQLFindRecordById('getEmployee', event.value);

            reportingManagerId = loggedEmployeeResponse.parentEmployeeId
            setReportingManagerId(reportingManagerId)
        } else if (event.value === loggedEmployeeId) {
            event.value = event.value
            reportingManagerId = event.value
            setReportingManagerId(reportingManagerId)
        }
        else {
            event.value = event.value
            // var loggedEmployeeResponse = await DataStore.query(Employee, (c) => c.id.eq(event.value));
            var loggedEmployeeResponse = await graphQLFindRecordById('getEmployee', event.value);

            reportingManagerId = loggedEmployeeResponse.parentEmployeeId
            setReportingManagerId(reportingManagerId)
        }

        LoadSummaryParametersData(selectedYear.value, event.value, quarter.value, reportingManagerId)

        await loadEmployeeData(event.value)

        //_________________________set drilldown analysis_______________________//

        const drillDownData = await drillDownFilter(event.value, financialYear.value)
        setDrillDownData([])
        setLevel1("");
        setSelectedParameter("");
        setShowDrillDownChart(false);
        setLevel2("");
        setLevel3("");
        setLevel4("");

        setCurrentTargetDetails(drillDownData)

        let parametersForSelectedYear = []

        for (var i = 0; i < drillDownData.length; i++) {
            // var parameterResponses = await DataStore.query(Parameters, drillDownData[i]["parameter_id"])
            var parameterResponses = await graphQLFindRecordById('getParameters', drillDownData[i]["parameter_id"]);

            let data = { "label": parameterResponses.name, "value": parameterResponses.id }
            //* Hide NWC days  07-06-2023
            if (parameterResponses.name !== "NWC Days") {
                parametersForSelectedYear.push(data)
            }

            if (i === drillDownData.length - 1) {
                setParametersForSelect(parametersForSelectedYear)
            }
        }

        if (drillDownData.length === 0) {
            setParametersForSelect([])
        }

        //_________________________set drilldown analysis end_______________________//

        //_________________________set detail view_______________________//


        const data = await detailViewFilter(event.value, parameter.value, financialYear.value, selectedMonth.value)
        const finalDetailViewResult = Array.from(data)
        if (finalDetailViewResult.length > 0) {
            setParameterDetailViewList(finalDetailViewResult)
            setIsDetailedViewLoaded(true)
        }
        else {
            setParameterDetailViewList([]);
            setIsDetailedViewLoaded(true)
        }
        //_________________________set detail view end_______________________//
    }

    //onClick Financial Year
    const handleClickFinanCialYearDropdown = async (event) => {
        setIsDetailedViewLoaded(false)

        // loadDrillDownData(event.value)
        reactLocalStorage.setObject('selectedYear', event);
        LoadSummaryParametersData(event.value, employeeId, quarter.value, reportingManagerId)

        setFinancialYear(event);
        getParameterAnalysisData(parameter, event, "Quarter 1")
        await loadEmployeeData(employeeId)

        const data = await detailViewFilter(employee.value, parameter.value, event.value, selectedMonth.value)
        const finalDetailViewResult = Array.from(data)
        if (finalDetailViewResult.length > 0) {
            setParameterDetailViewList(finalDetailViewResult)
            setIsDetailedViewLoaded(true)
        }
        else {
            setParameterDetailViewList([]);
            setIsDetailedViewLoaded(true)
        }

        //_________________________set drilldown analysis_______________________//


        const drillDownData = await drillDownFilter(employee.value, event.value)
        setDrillDownData([])
        setLevel1("");
        setSelectedParameter("");
        setShowDrillDownChart(false);
        setLevel2("");
        setLevel3("");
        setLevel4("");

        setCurrentTargetDetails(drillDownData)

        let parametersForSelectedYear = []

        for (var i = 0; i < drillDownData.length; i++) {
            // var parameterResponses = await DataStore.query(Parameters, drillDownData[i]["parameter_id"])
            var parameterResponses = await graphQLFindRecordById('getParameters', drillDownData[i]["parameter_id"]);
            let data = { "label": parameterResponses.name, "value": parameterResponses.id }
            //* Hide NWC days  07-06-2023
            if (parameterResponses.name !== "NWC Days") {
                parametersForSelectedYear.push(data)
            }
            if (i === drillDownData.length - 1) {
                setParametersForSelect(parametersForSelectedYear)
            }
        }

        if (drillDownData.length === 0) {
            setParametersForSelect([])
        }

        //_________________________set drilldown analysis end_______________________//
    }

    //onClick Parameters
    const handleClickParameterDropdown = async (event) => {
        setIsDetailedViewLoaded(false)
        setParameter(event)
        const data = await detailViewFilter(employee.value, event.value, financialYear.value, selectedMonth.value)
        if (data) {
            const finalDetailViewResult = Array.from(data)
            if (finalDetailViewResult.length > 0) {
                setParameterDetailViewList(finalDetailViewResult)
                setIsDetailedViewLoaded(true)
            }
            else {
                setParameterDetailViewList([]);
                setIsDetailedViewLoaded(true)
            }
        }

        getParameterAnalysisData(event, financialYear, "Quarter 1")
    }

    const handleClickMonthDropDown = async (event) => {
        setIsDetailedViewLoaded(false)
        const data = await detailViewFilter(loggedEmployeeId, parameter.value, financialYear.value, event.value)
        const finalDetailViewResult = Array.from(data)
        if (finalDetailViewResult.length > 0) {
            setParameterDetailViewList(finalDetailViewResult)
            setIsDetailedViewLoaded(true)
        }
        else {
            setParameterDetailViewList([]);
            setIsDetailedViewLoaded(true)
        }
    }


    //onClick Financial Year
    const handleClickQuarterDropdown = async (event) => {
        getParameterAnalysisData(parameter, financialYear, event.value)
        LoadSummaryParametersData(financialYear.value, employeeId, event.value, reportingManagerId)
        await loadEmployeeData(employeeId)

        setRenderItems(false)
        setQuarterDropdownValue(event.label)
        if (event.label === "All") {
            setAllQCheck(true)
            setQ1Check(false)
            setQ2Check(false)
            setQ3Check(false)
            setQ4Check(false)
        }
        else if (event.label === "Q1") {
            setQ1Check(true)
            setQ2Check(false)
            setQ3Check(false)
            setQ4Check(false)
            setAllQCheck(false)
        } else if (event.label === "Q2") {
            setQ1Check(false)
            setQ2Check(true)
            setQ3Check(false)
            setQ4Check(false)
            setAllQCheck(false)

        } else if (event.label === "Q3") {
            setQ1Check(false)
            setQ2Check(false)
            setQ3Check(true)
            setQ4Check(false)
            setAllQCheck(false)

        } else if (event.label === "Q4") {
            setQ1Check(false)
            setQ2Check(false)
            setQ3Check(false)
            setQ4Check(true)
            setAllQCheck(false)
        }

    }

    // getParameterAnalysis---------------------  starts
    const getParameterAnalysisData = async (parameterData, financialYear, selectedQuarter) => {
        setShowParameterAnalysisData(false)
        // var loggedEmployeeTeam = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(loggedEmployeeId))
        var loggedEmployeeTeam = await graphQLGetAllData('listEmployees', [{ parentEmployeeId: { eq: loggedEmployeeId } }]);


        var myArray = [];
        var myDataLabelArray = [];
        var myDataArray = [];
        var currentQuater = getCurrentQuater();

        if (parameterData) {
            try {
                for (let i = 0; i < loggedEmployeeTeam.length; i++) {
                    // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
                    //     c.employee_id.eq(loggedEmployeeTeam[i].id),
                    //     c.financial_year_id.eq(financialYear.value),
                    //     c.report_manager_employee_id.notContains(loggedEmployeeTeam[i].id),
                    // ]));
                    var targetsResponses = await graphQLGetAllData('listTargets', [
                        { employee_id: { eq: loggedEmployeeTeam[i].id } },
                        { financial_year_id: { eq: financialYear.value } },
                        // { report_manager_employee_id: { notContains: loggedEmployeeTeam[i].id } }
                    ]);
                    var currentTargetDetails = [];
                    if (targetsResponses.length > 0) {
                        // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
                        //     c.target_id.eq(targetsResponses[0].id),
                        //     c.parameter_id.eq(parameterData.value)
                        // ]));
                        var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                            {
                                target_id: { eq: targetsResponses[0].id },
                                parameter_id: { eq: parameterData.value }
                            }
                        ]);

                        myDataLabelArray.push(loggedEmployeeTeam[i].firstName + ' ' + loggedEmployeeTeam[i].lastName)
                        myDataArray.push(currentTargetDetails.length > 0 ? currentTargetDetails[0].target_value : 0)

                        var actualAch = 0; var wtAch = 0;
                        // let parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(parameterData.value));
                        let parameterResponses = await graphQLFindRecordById('getParameters', parameterData.value);

                        var parameterBaseType = parameterResponses.type === PARAMETERS_TYPES.Days ? PARAMETERS_TYPES_NAMES.D : parameterResponses.type === PARAMETERS_TYPES.Amount ? PARAMETERS_TYPES_NAMES.A : parameterResponses.type === PARAMETERS_TYPES.Number ? PARAMETERS_TYPES_NAMES.N : PARAMETERS_TYPES_NAMES.P


                        // var getPlanDetails = await DataStore.query(Plan, (c) => c.target_detail_id.eq(currentTargetDetails[0].id));
                        var getPlanDetails = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: currentTargetDetails[0].id } }]);

                        var getTargetParameterType = getTargetBasedOnParameterTypeForEvalPopup(getPlanDetails, selectedQuarter, parameterBaseType)

                        var planActual = getActualValue(currentTargetDetails[0], selectedQuarter, parameterBaseType)
                        actualAch = await calculateConsolidatedScore(planActual, getTargetParameterType)

                        var empProgress = []
                        if (currentTargetDetails.length > 0) {
                            for (let i = 1; i < 5; i++) {
                                var getTargetParameterTypeForChart = getTargetBasedOnParameterTypeForEvalPopup(getPlanDetails, "Quarter " + i, parameterBaseType)
                                var planActualForChart = getActualValue(currentTargetDetails[0], "Quarter " + i, parameterBaseType)
                                var actualAchForChart = await calculateConsolidatedScore(planActualForChart, getTargetParameterTypeForChart)
                                empProgress.push(Math.round(actualAchForChart))
                            }
                        }

                        myArray.push({
                            name: loggedEmployeeTeam[i].firstName + ' ' + loggedEmployeeTeam[i].lastName,
                            empId: loggedEmployeeTeam[i].employeeId,
                            empQuarterWiseProgress: empProgress,
                            parameterType: parameterResponses != '' ? parameterResponses.type : '',
                            thisYearTarget: currentTargetDetails.length > 0 ? Math.round(currentTargetDetails[0].target_value) : 0,
                            currentQuaterTarget: getTargetParameterType ? Math.round(getTargetParameterType) : 0,
                            planActual: planActual ? Math.round(planActual) : 0,
                            actualAch: actualAch ? Math.round(actualAch) : 0,
                        })


                        if (i === (loggedEmployeeTeam.length - 1)) {
                            setEmployeeTeam(myArray)
                            setShowParameterAnalysisData(true)
                        }
                    } else {
                        setEmployeeTeam([])
                        setShowParameterAnalysisData(true)
                    }
                }
            }
            catch (e) { }


            // //* get Team data
            // var myTargetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
            //     c.employee_id.eq(loggedEmployeeId),
            //     c.financial_year_id.eq(financialYear.value),
            //     c.report_manager_employee_id.notContains(loggedEmployeeId),
            // ]));
            var myTargetsResponses = await graphQLGetAllData('listTargets', [
                {
                    employee_id: { eq: loggedEmployeeId },
                    financial_year_id: { eq: financialYear.value },
                    // report_manager_employee_id: { notContains: loggedEmployeeId }
                }]);


            if (myTargetsResponses.length > 0) {
                let myCurrentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
                    c.target_id.eq(myTargetsResponses[0].id),
                    c.parameter_id.eq(parameterData.value)
                ]));
                // let myCurrentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                //     { target_id: { eq: myTargetsResponses[0].id },
                //     parameter_id: { eq: parameterData.value } }
                // ]);


                if (myCurrentTargetDetails.length > 0) {
                    // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(myCurrentTargetDetails[0].parameter_id));
                    var parameterResponses = await graphQLFindRecordById('getParameters', myCurrentTargetDetails[0].parameter_id);

                    let yearTargetValue = parameterResponses.type === PARAMETERS_TYPES.Days ? Math.floor(myCurrentTargetDetails[0].target_value) : parameterResponses.type === PARAMETERS_TYPES.Amount ? (parseInt(myCurrentTargetDetails[0].target_value)) : myCurrentTargetDetails[0].target_value;
                    setParameterAnalysisYearValue(yearTargetValue)
                    if (currentQuater === 1) {
                        // var getPlanDetails = await DataStore.query(Plan, (c) => c.target_detail_id.eq(myCurrentTargetDetails[0].id));
                        var getPlanDetails = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: myCurrentTargetDetails[0].id } }]);

                        var getTargetParameterType = getTargetBasedOnParameterTypeForEvalPopup(getPlanDetails, selectedQuarter, parameterBaseType)
                        setParameterAnalysisQuarterValue(Math.round(getTargetParameterType))
                    } else if (currentQuater === 2) {
                        let TargetValue = parameterResponses.type === PARAMETERS_TYPES.Days ? Math.floor(myCurrentTargetDetails[0].q2_target_value) : parameterResponses.type === PARAMETERS_TYPES.Amount ? (parseInt(myCurrentTargetDetails[0].q2_target_value)) : myCurrentTargetDetails[0].q2_target_value;
                        setParameterAnalysisQuarterValue(TargetValue)
                    } else if (currentQuater === 3) {
                        let TargetValue = parameterResponses.type === PARAMETERS_TYPES.Days ? Math.floor(myCurrentTargetDetails[0].q3_target_value) : parameterResponses.type === PARAMETERS_TYPES.Amount ? (parseInt(myCurrentTargetDetails[0].q3_target_value)) : myCurrentTargetDetails[0].q3_target_value;
                        setParameterAnalysisQuarterValue(TargetValue)
                    } else if (currentQuater === 4) {
                        let TargetValue = parameterResponses.type === PARAMETERS_TYPES.Days ? Math.floor(myCurrentTargetDetails[0].q4_target_value) : parameterResponses.type === PARAMETERS_TYPES.Amount ? (parseInt(myCurrentTargetDetails[0].q4_target_value)) : myCurrentTargetDetails[0].q4_target_value;
                        setParameterAnalysisQuarterValue(TargetValue)
                    }

                } else {
                    setParameterAnalysisYearValue("");
                    setParameterAnalysisQuarterValue("")
                }

                // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(parameterData.value));
                var parameterResponses = await graphQLFindRecordById('getParameters', parameterData.value);

                let linechartData = [];
                let linechartActualData = [];
                if (myCurrentTargetDetails.length > 0 && myCurrentTargetDetails[0] !== null) {
                    for (let i = 1; i <= 4; i++) {
                        linechartData.push(myCurrentTargetDetails[0]['q' + i + '_target_value'])
                        linechartActualData.push(myCurrentTargetDetails[0]['q' + i + '_achievement'])
                    }

                    var empProgressForTeam = []
                    var planActualForTeamChart = []
                    for (let i = 1; i < 5; i++) {
                        // var getPlanDetails = await DataStore.query(Plan, (c) => c.target_detail_id.eq(myCurrentTargetDetails[0].id));
                        var getPlanDetails = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: myCurrentTargetDetails[0].id } }]);

                        var getTargetParameterTypeForChart = getTargetBasedOnParameterTypeForEvalPopup(getPlanDetails, "Quarter " + i, parameterBaseType)

                        var planActualForChart = getActualValue(myCurrentTargetDetails[0], "Quarter " + i, parameterBaseType)
                        var actualAchForChart = await calculateConsolidatedScore(planActualForChart, getTargetParameterTypeForChart)
                        empProgressForTeam.push(Math.round(actualAchForChart))
                        planActualForTeamChart.push(Math.round(planActualForChart))
                    }

                    var chartDataArary = [];
                    chartDataArary['label'] = myDataLabelArray ? myDataLabelArray : "";
                    chartDataArary['data'] = myDataArray;
                    chartDataArary['parameter'] = parameterData.label;
                    chartDataArary['teamTrendsTarget'] = linechartData;
                    chartDataArary['teamTrendsActual'] = empProgressForTeam;
                    chartDataArary['planActualForTeamChart'] = planActualForTeamChart;
                    chartDataArary['parameterType'] = parameterResponses.type;
                    chartDataArary['myData'] = [{ thisYear: myCurrentTargetDetails ? myCurrentTargetDetails[0].target_value : 0, currentQuater: myCurrentTargetDetails[0]['q' + currentQuater + '_target_value'] }];
                    setParameterAnalysisData(chartDataArary)
                    setShowParameterAnalysisData(true)
                }
            }
        } else {
            setEmployeeTeam(myArray)
            setShowParameterAnalysisData(true)
        }
    }

    // getParameterAnalysis-------------------- Ends

    //onClick Financial Year
    const handleClickTopList = async (event) => {
        if (event.value === "0") {
            setEmployeeDropdownTeam(employeeTeam.length)
        } else if (event.value === "1") {
            setEmployeeDropdownTeam(5)
        }
    }

    async function bindList() {
        loadEmployeeData(loggedEmployeeId);

        var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

        let event = { label: responses[0].name, value: responses[0].id };
        reactLocalStorage.setObject('selectedYear', event);
        setFinancialYear(event);

        //SBUResponses
        // var SBUResponses = await DataStore.query(SBU, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var SBUResponses = await graphQLGetAllData('listSBUS')
        SBUResponses = SBUResponses.sort((a, b) => a.name.localeCompare(b.name));
        setSbuList(SBUResponses)

        //get BU
        // var buResponses = await DataStore.query(BIUnit, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var buResponses = await graphQLGetAllData('listBIUnits')
        buResponses = buResponses.sort((a, b) => a.name.localeCompare(b.name));
        setBuList(buResponses)

        //get Region
        // var regionResponses = await DataStore.query(Region, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var regionResponses = await graphQLGetAllData('listRegions')
        regionResponses = regionResponses.sort((a, b) => a.name.localeCompare(b.name));
        setRegionList(regionResponses)

        //get Country
        // var countryResponses = await DataStore.query(Country, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var countryResponses = await graphQLGetAllData('listCountries')
        countryResponses = countryResponses.sort((a, b) => a.name.localeCompare(b.name));
        setCountryList(countryResponses)

        //get Brand
        // var brandResponses = await DataStore.query(Brand, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var brandResponses = await graphQLGetAllData('listBrands')
        brandResponses = brandResponses.sort((a, b) => a.name.localeCompare(b.name));
        setBrandList(brandResponses)

        //get PG
        // var pgResponses = await DataStore.query(ProductGroup, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var pgResponses = await graphQLGetAllData('listProductGroups')
        pgResponses = pgResponses.sort((a, b) => a.name.localeCompare(b.name));
        setProductGroupList(pgResponses)

        //get Customer Category
        // var customerCategoryResponse = await DataStore.query(CustomerCategory, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var customerCategoryResponse = await graphQLGetAllData('listCustomerCategories')
        customerCategoryResponse = customerCategoryResponse.sort((a, b) => a.name.localeCompare(b.name));
        setCustomerCategoryList(customerCategoryResponse)

        //get Customer 
        // var customerResponse = await DataStore.query(Customer, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var customerResponse = await graphQLGetAllData('listCustomers')
        customerResponse = customerResponse.sort((a, b) => a.name.localeCompare(b.name));
        setCustomerList(customerResponse)

        setEmployeeId(loggedEmployeeId)


        setEmployeeDropdownTeam(employeeTeam.length)
        //set financial year
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        if (Object.keys(selectedYear).length !== 0) {
            setFinancialYear(selectedYear)
            setFinancialYearId(selectedYear.value)
            loadDrillDownData(selectedYear.value)
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

            setFinancialYear({ label: responses[0].name, value: responses[0].id })
            setFinancialYearId(responses[0].id)
            loadDrillDownData(responses[0].id)
        }

        //get financial year
        // var financialYearResponses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var financialYearResponses = await graphQLGetAllData('listFinancialYears');
        financialYearResponses = financialYearResponses.sort((a, b) => a.name.localeCompare(b.name));

        var myArray = []; myArray = ConvertResponseForSelect(financialYearResponses); setFinancialYearList(myArray);

        //get employee list
        // var employeeResponse = await DataStore.query(Employee, (c) => c.or(c => [
        //     c.id.eq(loggedEmployeeId),
        //     c.parentEmployeeId.eq(loggedEmployeeId)
        // ]), {
        //     sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
        // });

        loadEmployeeData(loggedEmployeeId);

        let getTargetOfEmployee = ""
        let selectedEmployeeOnEvaluation = reactLocalStorage.getObject('selectedEmployeeOnEvaluation');
        if (selectedEmployeeOnEvaluation.value) {
            setEmployee(selectedEmployeeOnEvaluation)
            if (!selectedEmployeeOnEvaluation.value) {
                getTargetOfEmployee = loggedEmployeeId
            } else {
                getTargetOfEmployee = selectedEmployeeOnEvaluation.value
            }
        } else {
            // setEmployee({ label: "All Employee", value: 0 })
            getTargetOfEmployee = loggedEmployeeId
        }

        //get Parameters List
        // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.employee_id.eq(getTargetOfEmployee),
        //     c.report_manager_employee_id.notContains(loggedEmployeeId),
        //     c.financial_year_id.eq(selectedYear.value)
        // ]));

        var targetsResponses = await graphQLGetAllData('listTargets', [
            { employee_id: { eq: getTargetOfEmployee } },
            { financial_year_id: { eq: selectedYear.value } },
            // { report_manager_employee_id: { notContains: loggedEmployeeId } }
        ]);

        if (targetsResponses.length) {
            // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
            //     c.target_id.eq(targetsResponses[0].id),
            //     c.parameter_id.notContains(PARAMETER_IDs.DIO),
            //     c.parameter_id.notContains(PARAMETER_IDs.DSO),
            //     c.parameter_id.notContains(PARAMETER_IDs.DVRO),
            //     c.parameter_id.notContains(PARAMETER_IDs.DPO),
            // ]));

            var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                { target_id: { eq: targetsResponses[0].id } },
                { parameter_id: { notContains: PARAMETER_IDs.DIO } },
                { parameter_id: { notContains: PARAMETER_IDs.DPO } },
                { parameter_id: { notContains: PARAMETER_IDs.DVRO } },
                { parameter_id: { notContains: PARAMETER_IDs.DSO } }
            ]);

            let _tempArray = [];
            if (currentTargetDetails.length > 0) {
                //  currentTargetDetails.forEach(async (currentItem, i) => {
                for (var i = 0; i < currentTargetDetails.length; i++) {
                    //get parameters details
                    // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(currentTargetDetails[i].parameter_id));
                    var parameterResponses = await graphQLFindRecordById('getParameters', currentTargetDetails[i].parameter_id);


                    // let data = { "label": parameterResponses.name, "value": parameterResponses.id }
                    // setParameter(data)
                    // console.log('setting parameter here')

                    if (parameterResponses) {
                        let data = {
                            "id": currentTargetDetails[i].parameter_id,
                            "name": parameterResponses.name,
                        }
                        _tempArray.push(data)
                    }

                    if (i === currentTargetDetails.length - 1) {
                        let sortArray = _tempArray.sort((a, b) => (a.name > b.name ? 1 : -1))
                        var myParameterArray = []; myParameterArray = ConvertResponseForSelect(sortArray);
                        let zeroPositionParameterObject = { label: "Select Parameter", value: parameterResponses.id }; myParameterArray.unshift(zeroPositionParameterObject);
                        setParameterList(myParameterArray);
                    }
                }
                //})


                // let initailParameter = currentTargetDetails.find(c => c.parameter_id === PARAMETER_IDs.Revenue);
                // if (initailParameter) {
                //     var parameterResponses = await graphQLFindRecordById('getParameters', initailParameter.parameter_id);
                //     let data = { "label": parameterResponses.name, "value": parameterResponses.id }
                //     setParameter(data)
                // } else {
                //     var parameterResponses = await graphQLFindRecordById('getParameters', currentTargetDetails[0].parameter_id);
                //     let data = { "label": parameterResponses.name, "value": parameterResponses.id }
                //     setParameter(data)
                // }

            } else {
                setParameterList([])
            }

        }
    }

    const [employees, setAllEmployees] = useState([]);
    const [isDataLoaded, setDataLoaded] = useState(false)
    const getMasterData = async () => {

        let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
        setDataLoaded(false);
        var employeeResponse = [];
        var employeeResponse = await graphQLGetAllData('listEmployees', [{ parentEmployeeId: { eq: loggedEmployeeId } }]);

        let empSelfData = await graphQLFindRecordById('getEmployee', loggedEmployeeId);
        employeeResponse.push(empSelfData);

        var myEmployeeArray = [];

        for (var i = 0; i < employeeResponse.length; i++) {
            let obj = {};

            let roleName = await graphQLFindRecordById('getRoles', employeeResponse[i].role_id);

            obj.label = employeeResponse[i].firstName + ' ' + employeeResponse[i].lastName + " (" + roleName?.name + ")";
            obj.value = employeeResponse[i].id

            myEmployeeArray.push(obj);
        }
        myEmployeeArray = ConvertResponseForSelectTwoNames(employeeResponse);

        let employeeSelfData = await graphQLFindRecordById('getEmployee', loggedEmployeeId);
        let name = employeeSelfData.firstName + " " + employeeSelfData.lastName;
        let role = await graphQLFindRecordById('getRoles', employeeSelfData.role_id);
        let obj = { label: name + " (" + role?.name + ")", value: employeeSelfData.id }
        setEmployee(obj)


        // myEmployeeArray = myEmployeeArray.filter(c => c.value !== loggedEmployeeId);
        // myEmployeeArray.unshift(initialEmployee);
        setEmployeeList(myEmployeeArray);

        let allParameterResponses = await graphQLGetAllData("listParameters");
        setAllParameters(allParameterResponses)

        setDataLoaded(true);
    }

    useEffect(() => {
        if (isDataLoaded === true) {
            onLoad();
            bindList()
            loadSummaryData()
        }
    }, [activeIndex, isDataLoaded]);

    useEffect(() => {
        getMasterData();
    }, []);


    const showActualTarget = (rowData) => {
        if (rowData.actualPerformance !== "") {
            return (
                <React.Fragment>
                    {rowData.parameter_type === PARAMETERS_TYPES_NAMES.A ? (
                        <label>
                            {rowData.parameter_type}
                            {commaSeparated(parseFloat(rowData.actualPerformance))}{" "}
                        </label>
                    ) : rowData.parameter_type === PARAMETERS_TYPES_NAMES.P ? (
                        <label>
                            {rowData.actualPerformance}
                            {rowData.parameter_type}
                        </label>
                    ) : (
                        <label>
                            {rowData.actualPerformance} {rowData.parameter_type}
                        </label>
                    )}
                </React.Fragment>
            );
        }
    };

    const showAchiievementWeightPercentage = (rowData) => {
        let actualPerformance = rowData.actualPerformance.toString().replace(/[\(\)]/g, "");
        let targetValue = rowData.target_value;

        let achievementPercentatage = 0;
        if (rowData.parameter_id === PARAMETER_IDs["NWC DAYS"]) {
            achievementPercentatage = parseInt(targetValue) ? (100 * ((parseInt(targetValue) * 2) - parseInt(actualPerformance))) / parseInt(targetValue) : 0;
        }
        else {
            achievementPercentatage = targetValue ? (100 * parseInt(actualPerformance)) / parseInt(targetValue) : 0;
        }

        if (achievementPercentatage > 200) {
            achievementPercentatage = 200;
        } else if (achievementPercentatage < 50) {
            achievementPercentatage = 0;
        }

        let weightPercentage = (rowData.parameter_weightage * achievementPercentatage) / 100;

        if (!weightPercentage) {
            weightPercentage = 0;
        }
        //parseFloat(NWCDaysValue).toFixed(2)
        return (
            <React.Fragment>
                <label>
                    {/* {isFinite(rowData.actualAch) ? rowData.actualAch : "0%"} */}
                    {parseFloat(weightPercentage).toFixed(2)}%
                </label>
            </React.Fragment>
        );
    }

    const showPlanActual_valuePercentage = (rowData) => {

        let actualPerformance = rowData.actualPerformance.toString().replace(/[\(\)]/g, "");
        let targetValue = rowData.target_value;

        let achievementPercentatage = 0;

        if (rowData.parameter_id === PARAMETER_IDs["NWC DAYS"]) {
            achievementPercentatage = parseFloat(targetValue) ? (100 * ((parseFloat(targetValue) * 2) - parseFloat(actualPerformance))) / parseFloat(targetValue) : 0;
        }
        else {
            achievementPercentatage = targetValue ? (100 * parseFloat(actualPerformance)) / parseFloat(targetValue) : 0;
        }

        if (rowData.parameter_id === PARAMETER_IDs["Overdue Receivable"]) {
            if (achievementPercentatage <= 0) {
                achievementPercentatage = 120
            } else if (achievementPercentatage <= 5) {
                achievementPercentatage = 100
            } else if (achievementPercentatage > 5 && achievementPercentatage < 10) {
                achievementPercentatage = 80
            } else {
                achievementPercentatage = 0
            }

        }
        else {
            if (achievementPercentatage > 200) {
                achievementPercentatage = 200;
            } else if (achievementPercentatage < 50) {
                achievementPercentatage = 0;
            }
        }


        if (!achievementPercentatage) {
            achievementPercentatage = 0;
        }

        return (
            <React.Fragment>
                <label>
                    {/* {isFinite(rowData.actualAch) ? rowData.actualAch : "0%"} */}
                    {/* {achievementPercentatage}% */}
                    {parseFloat(achievementPercentatage).toFixed(2)}%
                </label>
            </React.Fragment>
        );
    };

    const consolidatedScoreFormatter = (rowData) => {
        let actualPerformance = rowData.actualPerformance.toString().replace(/[\(\)]/g, "");
        let targetValue = rowData.target_value;

        let achievementPercentatage = 0;
        if (rowData.parameter_id === PARAMETER_IDs["NWC DAYS"]) {
            achievementPercentatage = parseInt(targetValue) ? (100 * ((parseInt(targetValue) * 2) - parseInt(actualPerformance))) / parseInt(targetValue) : 0;
        }
        else {
            achievementPercentatage = targetValue ? (100 * parseInt(actualPerformance)) / parseInt(targetValue) : 0;
        }

        if (achievementPercentatage > 200) {
            achievementPercentatage = 200;
        } else if (achievementPercentatage < 50) {
            achievementPercentatage = 0;
        }

        let weightPercentage = (rowData.parameter_weightage * achievementPercentatage) / 100;

        if (!weightPercentage) {
            weightPercentage = 0;
        }
        //parseFloat(NWCDaysValue).toFixed(2)
        return parseFloat(weightPercentage).toFixed(2) + '%';
    }

    const loadDrillDownData = async (financialYearId) => {
        setSelectedParameter("")

        // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.employee_id.eq(loggedEmployeeId),
        //     c.report_manager_employee_id.notContains(loggedEmployeeId),
        //     c.financial_year_id.eq(financialYearId),
        //     c.isAssignedToEmployee.eq(true)
        // ]));
        var targetsResponses = await graphQLGetAllData('listTargets', [
            {
                employee_id: { eq: loggedEmployeeId },
                // report_manager_employee_id: { notContains: loggedEmployeeId },
                financial_year_id: { eq: financialYearId },
                isAssignedToEmployee: { eq: true }
            }]);


        //Target Details
        var currentTargetDetails = [];
        if (targetsResponses.length > 0) {
            // currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
            //     c.target_id.eq(targetsResponses[0].id),
            //     c.parameter_id.notContains(PARAMETER_IDs.DIO),
            //     c.parameter_id.notContains(PARAMETER_IDs.DSO),
            //     c.parameter_id.notContains(PARAMETER_IDs.DVRO),
            //     c.parameter_id.notContains(PARAMETER_IDs.DPO),
            // ]));
            var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                { target_id: { eq: targetsResponses[0].id } },
                { parameter_id: { notContains: PARAMETER_IDs.DIO } },
                { parameter_id: { notContains: PARAMETER_IDs.DPO } },
                { parameter_id: { notContains: PARAMETER_IDs.DVRO } },
                { parameter_id: { notContains: PARAMETER_IDs.DSO } }
            ]);
            setCurrentTargetDetails(currentTargetDetails)
        }

        let parametersForSelectedYear = []
        for (var i = 0; i < currentTargetDetails.length; i++) {
            // var parameterResponses = await DataStore.query(Parameters, currentTargetDetails[i]["parameter_id"])
            var parameterResponses = await graphQLFindRecordById('getParameters', currentTargetDetails[i]["parameter_id"]);


            let data = { "label": parameterResponses.name, "value": parameterResponses.id }
            //* Hide NWC days  07-06-2023
            if (parameterResponses.name !== "NWC Days") {
                parametersForSelectedYear.push(data)
            }

            if (i === currentTargetDetails.length - 1) {
                setParametersForSelect(parametersForSelectedYear)
            }
        }
    }

    //Show DrillDown Chart
    var [drillDownData, setDrillDownData] = useState([]);
    var [showDrillDownChart, setShowDrillDownChart] = useState(false);

    var [level1, setLevel1] = useState("");
    var [level2, setLevel2] = useState("");
    var [level3, setLevel3] = useState("");
    var [level4, setLevel4] = useState("");

    var excludeValues = [];

    if (level1) {
        excludeValues.push(level1.label);
    }
    if (level2) {
        excludeValues.push(level2.label);
    }
    if (level3) {
        excludeValues.push(level3.label);
    }
    if (level4) {
        excludeValues = [level4.label];
    }
    if (excludeValues) {
        let levelValue = excludeValues[0]
        if (levelValue !== "Select") {
            levelList = levelList.filter(function (option) {
                return excludeValues.indexOf(option.label) === -1;
            });
        }
    }

    //get sbu
    var [sbuList, setSbuList] = useState([]);
    const getSBULabel = (id) => {
        let _sbuList = [...sbuList];
        var returnRecord = _sbuList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get bu
    var [buList, setBuList] = useState([]);
    const getBULabel = (id) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get region 
    var [regionList, setRegionList] = useState([]);
    const getRegionLabel = (id) => {
        let _regionList = [...regionList];
        var returnRecord = _regionList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get country
    var [countryList, setCountryList] = useState([]);
    const getCountryLabel = (id) => {
        let _countryList = [...countryList];
        var returnRecord = _countryList.find(o => o.REF_CODE === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get Brand 
    var [brandList, setBrandList] = useState([]);
    const getBrandLabel = (id) => {
        let _brandList = [...brandList];
        var returnRecord = _brandList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get PG
    var [productGroupList, setProductGroupList] = useState([]);
    const getProductGroupLabel = (id) => {
        let _productGroupList = [...productGroupList];
        var returnRecord = _productGroupList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get Customer Category
    var [customerCategoryList, setCustomerCategoryList] = useState([]);
    const getCustomerCategoryLabel = (id) => {
        let _productGroupList = [...customerCategoryList];
        var returnRecord = _productGroupList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get customer
    var [customerList, setCustomerList] = useState([]);
    const getCustomerLabel = (id) => {
        let _customerList = [...customerList];
        var returnRecord = _customerList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const show_DrillDownChart = async () => {
        if (level1.value === "" || level1.value === undefined) {
            toast.error('Please Select Level One.');
            let convertTree = {
                "name": selectedParameter.label,
                "children": [],
            }
            setDrillDownData(convertTree)
            setShowDrillDownChart(true)
        } else {
            let getTargetDetailId = current_Target_Details.find(e => e.parameter_id === selectedParameter.value)
            // var getPlanDetails = await DataStore.query(ActualDetails, (c) => c.target_details_id.eq(getTargetDetailId.id))
            var getPlanDetails = await graphQLGetAllData('listActualDetails', [{ target_details_id: { eq: getTargetDetailId.id } }]);


            if (getPlanDetails.length > 0) {
                // var loggedEmployeeResponse = await DataStore.query(Employee, (c) => c.id.eq(loggedEmployeeId));
                var loggedEmployeeResponse = await graphQLFindRecordById('getEmployee', loggedEmployeeId);

                if (loggedEmployeeResponse) { var param_sbu_id = loggedEmployeeResponse.param_sbu_id; }
                let updateData = []

                for (let i = 0; i < getPlanDetails.length; i++) {
                    let Country = ""
                    var getCountry = await getCountryLabel(getPlanDetails[i].country);
                    if (getCountry !== "" && getCountry !== undefined) { Country = getCountry }

                    let data = {
                        "id": getPlanDetails[i].id,
                        "target_detail_id": getPlanDetails[i].target_details_id,
                        "target_value": getPlanDetails[i].actual_value,
                        "SBU": getPlanDetails[i].SBU,
                        "BU": getPlanDetails[i].BU,
                        "Region": getPlanDetails[i].region,
                        "Country": Country,
                        "Brand": getPlanDetails[i].brand,
                        "ProductGroup": getPlanDetails[i].productGroup,
                        "CustomerGroup": getPlanDetails[i].customerGroupCode,
                        "Customer": getPlanDetails[i].customerCode,
                    }
                    updateData.push(data)

                    if (i === getPlanDetails.length - 1) {
                        let getFilterArrayOnQuarters = filterArrayOnQuarter(updateData, quarter.value)
                        const tree = await buildTree(getFilterArrayOnQuarters, [level1.value, level2.value, level3.value, level4.value]);
                        let convertTree = {
                            "name": selectedParameter.label,
                            "children": tree,
                        }
                        // console.log(JSON.stringify(convertTree))
                        setDrillDownData(convertTree)
                        setShowDrillDownChart(true)
                    }
                }
            }
            else {
                toast.error("No data found.")
            }
        }
    }

    //First Level Tree
    const buildTree = async (treeData, keys) => {

        let newResponse = [];

        let drillDownDataColor = {
            borderColor: '#1B55AF',
            round: "full",
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#1B55AF',
            symbolSize: 7,
            lineStyle: {
                width: 15,
                curveness: 0.6,
                opacity: 0.8,
                color: '#1B55AF',
                borderColor: '30px solid rgba(27, 85, 175, 0.8)'
            }
        }
        let drillDownDataColorItemStyle = {
            shadowColor: "#1B55AF",
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            borderColor: '#1B55AF',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#1B55AF',
        }

        let sumFromArr = sumFromArray("target_value", treeData)
        for (let i = 0; i < treeData.length; i++) {
            let checkDuplicate = [...newResponse]
            const response = treeData[i][level1.value]
            if (checkDuplicate.length > 0) {
                let check_duplicateData = checkDuplicate.every((e) => e.name !== response)

                if (check_duplicateData === true) {
                    let levelTwoResponse = await buildSecondTree(treeData, response)
                    let data = {
                        name: response,
                        totalTargetValue: Math.round(sumFromArr),
                        itemStyle: drillDownDataColorItemStyle,
                        "lineStyle": drillDownDataColor,
                        label: {
                            normal: {
                                verticalAlign: 'middle',
                                fontSize: 14,
                                formatter: function (d) {
                                    if (d.value) {
                                        return (`val`)
                                    }
                                }
                            }
                        },
                        children: levelTwoResponse,

                    }
                    newResponse.push(data)
                }
            }
            else {
                let levelTwoResponse = await buildSecondTree(treeData, response)
                let data = {
                    name: response,
                    totalTargetValue: Math.round(sumFromArr),
                    itemStyle: drillDownDataColorItemStyle,
                    "lineStyle": drillDownDataColor,
                    label: {
                        normal: {
                            verticalAlign: 'middle',
                            fontSize: 14,
                            formatter: function (d) {
                                if (d.value) {
                                    return (`val`)
                                }
                            }
                        }
                    },
                    children: levelTwoResponse,

                }
                newResponse.push(data)
            }
            if (i === treeData.length - 1) {
                newResponse.map((item, index) => {
                    // let getChildData = item.children
                    // let getChildDataValues = getChildData.map((data) => data.totalTargetValue)
                    // let totalOfTargets = getChildDataValues.reduce((total, num) => total + parseInt(num), 0);
                    if ((level2.value === "" || level2.value === undefined) && (level3.value === "" || level3.value === undefined) && (level4.value === "" || level4.value === undefined)) {
                        item.children = [];
                        item.totalTargetValue = item.totalTargetValue;
                        item.name = item.name + " (" + commaSeparated(Math.round(item.totalTargetValue)) + ")"
                    } else {
                        item.children = item.children;
                        item.totalTargetValue = item.totalTargetValue;
                        item.name = item.name + " (" + commaSeparated(Math.round(item.totalTargetValue)) + ")"
                    }
                })

            }
        }
        return newResponse
    }

    //Second Level Tree
    const buildSecondTree = async (secondTreeData, levelOneValue) => {
        let drillDownDataColor = {
            borderColor: '#136EF8',
            round: "full",
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#136EF8',
            symbolSize: 7,
            lineStyle: {
                width: 15,
                curveness: 0.6,
                opacity: 0.8,
                color: '#136EF8',
                borderColor: '30px solid rgba(19, 110, 248, 0.75)'
            }
        }
        let drillDownDataColorItemStyle = {
            shadowColor: "#136EF8",
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            borderColor: '#136EF8',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#136EF8',
        }

        let newResponse = [];

        for (let i = 0; i < secondTreeData.length; i++) {
            let checkDuplicate = [...newResponse]
            const response = secondTreeData[i][level2.value]

            let firstLevelKeyValue;
            if (level1.value === "BU") { firstLevelKeyValue = secondTreeData[i].BU; } else if (level1.value === "Region") { firstLevelKeyValue = secondTreeData[i].Region; }
            else if (level1.value === "Country") { firstLevelKeyValue = secondTreeData[i].Country; } else if (level1.value === "Brand") { firstLevelKeyValue = secondTreeData[i].Brand; } else if (level1.value === "ProductGroup") { firstLevelKeyValue = secondTreeData[i].ProductGroup; }
            else if (level1.value === "CustomerGroup") { firstLevelKeyValue = secondTreeData[i].CustomerGroup; } else if (level1.value === "Customer") { firstLevelKeyValue = secondTreeData[i].Customer; }

            // const key = Object.keys(secondTreeData[i]).find(key => secondTreeData[i][key] === levelOneValue);

            if (firstLevelKeyValue === levelOneValue) {
                if (checkDuplicate.length > 0) {
                    let check_duplicateData = checkDuplicate.every((e) => e.name !== response)
                    if (check_duplicateData === true) {
                        let getTargetValue = secondTreeData.filter((e) => e[level2.value] === response)
                        let sumFromArr = sumFromArray("target_value", getTargetValue)

                        let levelThreeResponse = await buildThirdTree(secondTreeData, levelOneValue, response)
                        let data = {
                            name: response,
                            totalTargetValue: Math.round(sumFromArr),
                            itemStyle: drillDownDataColorItemStyle,
                            "lineStyle": drillDownDataColor,
                            label: {
                                normal: {
                                    verticalAlign: 'middle',
                                    fontSize: 14,
                                    formatter: function (d) {
                                        if (d.value) {
                                            return (`val`)
                                        }
                                    }
                                }
                            },
                            children: levelThreeResponse,

                        }
                        newResponse.push(data)
                    }
                }
                else {
                    let getTargetValue = secondTreeData.filter((e) => e[level2.value] === response)
                    let sumFromArr = sumFromArray("target_value", getTargetValue)
                    let levelThreeResponse = await buildThirdTree(secondTreeData, levelOneValue, response)
                    let data = {
                        name: response,
                        totalTargetValue: Math.round(sumFromArr),
                        itemStyle: drillDownDataColorItemStyle,
                        "lineStyle": drillDownDataColor,
                        label: {
                            normal: {
                                verticalAlign: 'middle',
                                fontSize: 14,
                                formatter: function (d) {
                                    if (d.value) {
                                        return (`val`)
                                    }
                                }
                            }
                        },
                        children: levelThreeResponse
                    }
                    newResponse.push(data)
                }
            }

            if (i === secondTreeData.length - 1) {
                newResponse.map((item, index) => {
                    // let getChildData = item.children
                    // let getChildDataValues = getChildData.map((data) => data.totalTargetValue)
                    // let totalOfTargets = getChildDataValues.reduce((total, num) => total + parseInt(num), 0);
                    // item.name = item.name + "(" + totalOfTargets + ")"
                    if ((level3.value === "" || level3.value === undefined) && (level4.value === "" || level4.value === undefined)) {
                        item.children = [];
                        // item.totalTargetValue = item.target_value;
                        item.name = item.name + " (" + commaSeparated(Math.round(item.totalTargetValue)) + ")"

                    } else {
                        item.children = item.children;
                        // item.totalTargetValue = totalOfTargets;
                        item.name = item.name + " " + commaSeparated(Math.round(item.totalTargetValue))
                    }
                })
            }
        }
        return newResponse
    }

    //Third Level Tree
    const buildThirdTree = async (thirdTreeData, levelOneValue, levelTwoValue) => {

        let drillDownDataColor = {
            borderColor: '#F0074D',
            round: "full",
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 20,
            symbolSize: 7,
            color: '#F0074D',
            lineStyle: {
                width: 15,
                curveness: 0.6,
                opacity: 0.8,
                color: '#F0074D',
                borderColor: '20px solid rgba(240, 7, 77, 0.75)'
            }
        }
        let drillDownDataColorItemStyle = {
            shadowColor: "#F0074D",
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            borderColor: '#F0074D',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#F0074D',
        }

        let newResponse = [];
        for (let i = 0; i < thirdTreeData.length; i++) {
            let checkDuplicate = [...newResponse]
            const response = thirdTreeData[i][level3.value]

            let firstLevelKeyValue;
            if (level1.value === "BU") { firstLevelKeyValue = thirdTreeData[i].BU; } else if (level1.value === "Region") { firstLevelKeyValue = thirdTreeData[i].Region; }
            else if (level1.value === "Country") { firstLevelKeyValue = thirdTreeData[i].Country; } else if (level1.value === "Brand") { firstLevelKeyValue = thirdTreeData[i].Brand; } else if (level1.value === "ProductGroup") { firstLevelKeyValue = thirdTreeData[i].ProductGroup; }
            else if (level1.value === "CustomerGroup") { firstLevelKeyValue = thirdTreeData[i].CustomerGroup; } else if (level1.value === "Customer") { firstLevelKeyValue = thirdTreeData[i].Customer; }

            let secondLevelKeyValue;
            if (level2.value === "BU") { secondLevelKeyValue = thirdTreeData[i].BU; } else if (level2.value === "Region") { secondLevelKeyValue = thirdTreeData[i].Region; }
            else if (level2.value === "Country") { secondLevelKeyValue = thirdTreeData[i].Country; } else if (level2.value === "Brand") { secondLevelKeyValue = thirdTreeData[i].Brand; } else if (level2.value === "ProductGroup") { secondLevelKeyValue = thirdTreeData[i].ProductGroup; }
            else if (level2.value === "CustomerGroup") { secondLevelKeyValue = thirdTreeData[i].CustomerGroup; } else if (level2.value === "Customer") { secondLevelKeyValue = thirdTreeData[i].Customer; }

            // const firstLevelKey = Object.keys(thirdTreeData[i]).find(key => thirdTreeData[i][key] === levelOneValue);
            // const secondLevelKey = Object.keys(thirdTreeData[i]).find(key => thirdTreeData[i][key] === levelTwoValue);

            if (firstLevelKeyValue === levelOneValue && secondLevelKeyValue === levelTwoValue) {
                if (checkDuplicate.length > 0) {
                    let check_duplicateData = checkDuplicate.every((e) => e.name !== response)
                    if (check_duplicateData === true) {
                        let getTargetValue = thirdTreeData.filter((e) => e[level3.value] === response)
                        let sumFromArr = sumFromArray("target_value", getTargetValue)

                        let levelForthResponse = await buildForthTree(thirdTreeData, levelOneValue, levelTwoValue, response)
                        let data = {
                            name: response,
                            totalTargetValue: Math.round(sumFromArr),
                            itemStyle: drillDownDataColorItemStyle,
                            "lineStyle": drillDownDataColor,
                            label: {
                                normal: {
                                    verticalAlign: 'middle',
                                    fontSize: 14,
                                    formatter: function (d) {
                                        if (d.value) {
                                            return (`val`)
                                        }
                                    }
                                }
                            },
                            children: levelForthResponse,
                        }
                        newResponse.push(data)
                    }
                }
                else {
                    let getTargetValue = thirdTreeData.filter((e) => e[level3.value] === response)
                    let sumFromArr = sumFromArray("target_value", getTargetValue)
                    let levelForthResponse = await buildForthTree(thirdTreeData, levelOneValue, levelTwoValue, response)
                    let data = {
                        name: response,
                        totalTargetValue: Math.round(sumFromArr),
                        itemStyle: drillDownDataColorItemStyle,
                        "lineStyle": drillDownDataColor,
                        label: {
                            normal: {
                                verticalAlign: 'middle',
                                fontSize: 14,
                                formatter: function (d) {
                                    if (d.value) {
                                        return (`val`)
                                    }
                                }
                            }
                        },
                        children: levelForthResponse,
                    }
                    newResponse.push(data)
                }
            }

            if (i === thirdTreeData.length - 1) {
                newResponse.map((item, index) => {
                    // let getChildData = item.children
                    // let getChildDataValues = getChildData.map((data) => data.totalTargetValue)
                    // let totalOfTargets = getChildDataValues.reduce((total, num) => total + parseInt(num), 0);
                    // item.name = item.name + "(" + totalOfTargets + ")"
                    if ((level4.value === "" || level4.value === undefined)) {
                        item.children = [];
                        item.name = item.name + " (" + commaSeparated(item.totalTargetValue) + ")"
                    } else {
                        item.children = item.children;
                        item.name = item.name + " (" + commaSeparated(Math.round(item.totalTargetValue)) + ")"
                    }
                })
            }
        }
        return newResponse
    }

    //Forth Level Tree
    const buildForthTree = async (forthTreeData, levelOneValue, levelTwoValue, levelThreeValue) => {

        let drillDownDataColor = {
            borderColor: '#FF7F01',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#FF7F01',
            lineStyle: {
                width: 15,
                curveness: 0.6,
                opacity: 0.8,
                color: '#FF7F01',
                borderColor: '30px solid #FF7F01'
            }
        }
        let drillDownDataColorItemStyle = {
            shadowColor: "#FF7F01",
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            borderColor: '#FF7F01',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#FF7F01',
            width: 20,
        }

        let newResponse = [];

        for (let i = 0; i < forthTreeData.length; i++) {
            let checkDuplicate = [...newResponse]
            const response = forthTreeData[i][level4.value]

            let firstLevelKeyValue;
            if (level1.value === "BU") { firstLevelKeyValue = forthTreeData[i].BU; } else if (level1.value === "Region") { firstLevelKeyValue = forthTreeData[i].Region; }
            else if (level1.value === "Country") { firstLevelKeyValue = forthTreeData[i].Country; } else if (level1.value === "Brand") { firstLevelKeyValue = forthTreeData[i].Brand; } else if (level1.value === "ProductGroup") { firstLevelKeyValue = forthTreeData[i].ProductGroup; }
            else if (level1.value === "CustomerGroup") { firstLevelKeyValue = forthTreeData[i].CustomerGroup; } else if (level1.value === "Customer") { firstLevelKeyValue = forthTreeData[i].Customer; }

            let secondLevelKeyValue;
            if (level2.value === "BU") { secondLevelKeyValue = forthTreeData[i].BU; } else if (level2.value === "Region") { secondLevelKeyValue = forthTreeData[i].Region; }
            else if (level2.value === "Country") { secondLevelKeyValue = forthTreeData[i].Country; } else if (level2.value === "Brand") { secondLevelKeyValue = forthTreeData[i].Brand; } else if (level2.value === "ProductGroup") { secondLevelKeyValue = forthTreeData[i].ProductGroup; }
            else if (level2.value === "CustomerGroup") { secondLevelKeyValue = forthTreeData[i].CustomerGroup; } else if (level2.value === "Customer") { secondLevelKeyValue = forthTreeData[i].Customer; }

            let thirdLevelKeyValue;
            if (level3.value === "BU") { thirdLevelKeyValue = forthTreeData[i].BU; } else if (level3.value === "Region") { thirdLevelKeyValue = forthTreeData[i].Region; }
            else if (level3.value === "Country") { thirdLevelKeyValue = forthTreeData[i].Country; } else if (level3.value === "Brand") { thirdLevelKeyValue = forthTreeData[i].Brand; } else if (level3.value === "ProductGroup") { thirdLevelKeyValue = forthTreeData[i].ProductGroup; }
            else if (level3.value === "CustomerGroup") { thirdLevelKeyValue = forthTreeData[i].CustomerGroup; } else if (level3.value === "Customer") { thirdLevelKeyValue = forthTreeData[i].Customer; }


            if (firstLevelKeyValue === levelOneValue && secondLevelKeyValue === levelTwoValue && thirdLevelKeyValue === levelThreeValue) {
                if (checkDuplicate.length > 0) {
                    let check_duplicateData = checkDuplicate.every((e) => e.name !== response)
                    if (check_duplicateData === true) {
                        let data = {
                            name: response,
                            targetValue: [forthTreeData[i].target_value],
                            totalTargetValue: "",
                            itemStyle: drillDownDataColorItemStyle,
                            "lineStyle": drillDownDataColor,
                            label: {
                                normal: {
                                    verticalAlign: 'middle',
                                    fontSize: 14,
                                    formatter: function (d) {
                                        if (d.value) {
                                            return (`val`)
                                        }
                                    }
                                }
                            },
                            children: [],

                        }
                        newResponse.push(data)
                    } else {
                        checkDuplicate[0].targetValue.push(forthTreeData[i].target_value)
                    }
                }
                else {
                    let data = {
                        name: response,
                        targetValue: [forthTreeData[i].target_value],
                        totalTargetValue: "",
                        itemStyle: drillDownDataColorItemStyle,
                        "lineStyle": drillDownDataColor,
                        label: {
                            normal: {
                                verticalAlign: 'middle',
                                fontSize: 14,
                                formatter: function (d) {
                                    if (d.value) {
                                        return (`val`)
                                    }
                                }
                            }
                        },
                        children: [],
                    }
                    newResponse.push(data)
                }
            }

            if (i === forthTreeData.length - 1) {
                newResponse.map((item, index) => {
                    let totalOfTargets = item.targetValue.reduce((total, num) => total + parseInt(num), 0);
                    // item.name = item.name + "(" + totalOfTargets + ")"
                    item.name = item.name + " " + commaSeparated(Math.round(totalOfTargets))
                    item.totalTargetValue = totalOfTargets;
                })
            }
        }
        return newResponse
    }

    const option = {
        tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
        },
        series: [
            {
                type: 'tree',
                data: [drillDownData],
                top: '1%',
                left: '7%',
                bottom: '1%',
                edgeShape: 'curve',
                right: '20%',
                symbolSize: 80,
                height: 500,
                label: {
                    normal: {
                        verticalAlign: 'middle',
                        align: 'middle',
                        fontSize: 14,
                        formatter: function (d) {
                            if (d.value) {
                                return (`val`)
                            }
                        }
                    }
                },
                lineStyle: {
                    width: 20,
                    color: '#FF7F01CC'
                },
                leaves: {
                    label: {
                        position: 'right',
                        verticalAlign: 'middle',
                        align: 'left'
                    }
                },
                initialTreeDepth: 1,
                expandAndCollapse: true,
                animationDuration: 550,
                animationDurationUpdate: 750
            },

        ]
    };

    //Bar chart
    const barChartOption = {
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
        },
        yAxis: {
            type: 'category',
            data: ['Qtr 1', 'Qtr 2', 'Qtr 3', 'Qtr 4']
        },
        series: [
            {
                name: 'Target',
                type: 'bar',
                data: quarterTargetValue,
                itemStyle: {
                    color: '#4FB155' // Change the color to blue
                }
            },
            {
                name: 'Actuals',
                type: 'bar',
                data: quarterActualsValue,
                itemStyle: {
                    color: '#268AFF' // Change the color to blue
                }
            }
        ]
    };

    const targetBodyTemplate = (rowData) => {
        // return !isNaN(rowData.target_value) ? rowData.target_value : '0';
        if (rowData.target_value !== "") {
            return (
                <React.Fragment>
                    {rowData.parameter_type === PARAMETERS_TYPES_NAMES.A ? (
                        <label>
                            {rowData.parameter_type}
                            {commaSeparated(
                                parseFloat(Math.round(rowData.target_value)),
                            )}{" "}
                        </label>
                    ) : rowData.parameter_type === PARAMETERS_TYPES_NAMES.P ? (
                        <label>
                            {parseFloat(Math.round(rowData.target_value))}
                            {rowData.parameter_type}
                        </label>
                    ) : (
                        <label>
                            {parseFloat(Math.round(rowData.target_value))}{" "}
                            {rowData.parameter_type}
                        </label>
                    )}
                </React.Fragment>
            );
        }
    }

    function onClickParameter(data) {
        setQuarterTargetValue([data.q1_target_value, data.q2_target_value, data.q3_target_value, data.q4_target_value])
        setQuarterActualsValue([data.q1_actual_value, data.q2_actual_value, data.q3_actual_value, data.q4_actual_value])
        setParameterName(data.parameter_name)
        // LoadReporteeAlertData(financialYear.value, employee.value, data.parameter_id)
        LoadBrandsData(data)
    }

    return isAuthenticated ? (
        <div className="pt-16">
            <React.StrictMode>
                <TopNavBar />
                <LeftSideBar />

                <div className="dashboard-main-wrapper dashboard-new pl-16" x-data="{tab: 1}">
                    <div className="p-5 pb-5">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Dashboard</div>
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link className="active">Business</Link></li>
                                    {!checkEmployeeTeamType() && <li><Link to="/employee/dashboard/sales/planning">Sales</Link></li>}
                                </ul>
                            </div>
                        </div>
                        <div className="lg:flex justify-between my-6 items-center align-center">
                            {
                                isReportingManager === "1" ?
                                    <div className="tabs_two">
                                        <ul className="flex">
                                            <li><Link to="/employee/dashboard/business/planning" >Planning</Link></li>
                                            <li><Link to="/employee/dashboard/business/evaluation" >Evaluation</Link></li>
                                            <li><Link to="/employee/dashboard/business/performance" className="active">Performance</Link></li>
                                        </ul>
                                    </div> : <div className="tabs_two" />
                            }
                            <div className="mt-5 lg:mt-0 flex justify-end gap-2 items-center">
                                {
                                    activeIndex !== 1 &&
                                        isReportingManager === "1" ?
                                        <div className="w-40">
                                            <Select
                                                options={employeeList}
                                                value={employee}
                                                onChange={(e) => {
                                                    handleClickEmployeeDropdown(e);
                                                }}
                                                maxMenuHeight={200}
                                                placeholder="Employee Name"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div> : ""
                                }
                                {
                                    activeIndex === 1 || activeIndex === 3 ?
                                        <div className="w-40">
                                            <Select
                                                options={parameterList}
                                                value={parameter}
                                                onChange={(e) => {
                                                    handleClickParameterDropdown(e);
                                                }}
                                                maxMenuHeight={200} placeholder="Parameters"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div> : null
                                }
                                {

                                    <div className="w-40">
                                        <Select
                                            options={financialYearList}
                                            value={financialYear}
                                            onChange={(e) => {
                                                handleClickFinanCialYearDropdown(e);
                                            }}
                                            maxMenuHeight={200} placeholder="Select Year"
                                            className="my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>
                                }
                                {activeIndex !== 3 &&
                                    <div className="w-40">
                                        <Select
                                            options={quarterList}
                                            value={quarter}
                                            onChange={(e) => {
                                                setQuarter(e)
                                                handleClickQuarterDropdown(e)
                                            }}
                                            maxMenuHeight={200} placeholder="Select Quarter"
                                            className="my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>
                                }
                                {

                                    activeIndex === 3 &&
                                    <div className="w-40">
                                        <Select
                                            options={monthList}
                                            value={selectedMonth}
                                            onChange={(e) => {
                                                setSelectedMonth(e)
                                                handleClickMonthDropDown(e)
                                            }}
                                            maxMenuHeight={200} placeholder="Select Month"
                                            className="my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                        <div className='circulartab'>
                            <TabView activeIndex={activeIndex} onTabChange={(e) => {
                                setActiveIndex(e.index)
                            }}>
                                <TabPanel header="Summary">
                                    <div className="bg-white rounded-lg p-3 px-5 dark:bg-[#2A2E32] shadow-lg">
                                        <div className="flex gap-8 h-auto divide-x-2 divide-[#E4E7EC] dark:divide-[#1b1f22]">
                                            <div className='flex  items-center gap-4'>
                                                <img src={loggedEmployeeProfile} alt="" className='w-14 h-14 rounded-full' />
                                                <div>
                                                    <p className="text-[#667085] text-[13px] font-normal"> Emp ID: {loggedEmployeeCode}</p>
                                                    <h3 className="text-[#101828] text-[16px] font-semibold dark:text-white">{loggedEmployeeName}</h3>
                                                </div>
                                            </div>
                                            <div className='flex  items-center gap-3 px-4'>
                                                <div>
                                                    <p className="text-[#667085] text-[13px] font-normal"> Role</p>
                                                    <h3 className="text-[#101828] text-[16px] font-semibold  dark:text-white">{loggedEmployeeRole}</h3>
                                                </div>
                                            </div>
                                            <div className='flex  items-center gap-3 px-4'>
                                                <div>
                                                    <p className="text-[#667085] text-[13px] font-normal"> SBU</p>
                                                    <h3 className="text-[#101828] text-[16px] font-semibold  dark:text-white">{sbu}</h3>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {
                                        summaryData.length > 0 ?
                                            <div>
                                                <div className="bg-white rounded-lg p-4 px-5 dark:bg-[#2A2E32] shadow-lg mt-5">
                                                    <div className="flex-row grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4">

                                                        <div className='border border-[#E4E7EC] py-4 px-5 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                            <h4 className='text-[#101828] text-[#101828] text-[16px] mb-2  font-medium dark:text-[#fff]'>{"Consolidated Score"}</h4>
                                                            <div className='flex mt-5 gap-6 items-center'>
                                                                <div className='grow'>
                                                                    <h3 className='text-[#344054] text-[#101828] text-[30px] font-semibold leading-none dark:text-[#fff]'>
                                                                        {consolidatedValue + "%"}
                                                                    </h3>
                                                                    <h6 className='text-[#667085] text-[14px] text-[14px] dark:text-[#f2f2f2]'>
                                                                        Weightage
                                                                    </h6>
                                                                </div>
                                                                <div className='w-24 h-24 mx-auto'>
                                                                    <CircularProgressBar value={parseInt(consolidatedScore)} text={consolidatedScore + "%"} pathColor="#1882FF" trailColor="#83C888" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='border border-[#E4E7EC] py-4 px-5 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                            <h4 className='text-[#101828] text-[#101828] text-[14px] mb-2  font-medium dark:text-[#fff]'>{"Overall Score (Capped to 80)"}</h4>
                                                            <div className='flex mt-5 gap-6 items-center'>
                                                                {/* <div className='grow'>
                                                                        <h3 className='text-[#344054] text-[#101828] text-[30px] font-semibold leading-none dark:text-[#fff]'>
                                                                            {consolidatedScore >= 80 ? 80 : consolidatedScore + "%"}
                                                                        </h3>
                                                                        <h6 className='text-[#667085] text-[14px] text-[14px] dark:text-[#f2f2f2]'>
                                                                            Weightage
                                                                        </h6>
                                                                    </div> */}
                                                                <div className='w-24 h-24 mx-auto'>
                                                                    <CircularProgressBar value={parseInt(consolidatedScore)} text={consolidatedScore >= 80 ? 80 : consolidatedScore + "%"} pathColor="#1882FF" trailColor="#83C888" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            summaryData.map((item, index) => {
                                                                return (
                                                                    <Link id={index.toString()} onClick={() => { onClickParameter(item); }}
                                                                        className={
                                                                            item.parameter_name !== parameterName ?
                                                                                "border border-[#E4E7EC]  py-4 px-5 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]" :
                                                                                "border border-[green] border-2 bg-[#f3fffd] py-4 px-5 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]"
                                                                        }>
                                                                        <h4 className='  text-[16px] mb-2  font-medium dark:text-[#fff]'>{item.parameter_name}</h4>
                                                                        <div className='flex mt-5 gap-6 items-center'>
                                                                            <div className='grow'>
                                                                                <h3 className='text-[#344054]  text-[30px] font-semibold leading-none dark:text-[#fff]'>
                                                                                    {item.parameter_weightage === "-" ? "-" : item.parameter_weightage + "%"}
                                                                                </h3>
                                                                                <h6 className='text-[14px] dark:text-[#f2f2f2]'>
                                                                                    Weightage
                                                                                </h6>
                                                                            </div>
                                                                            <div className='w-24 h-24 mx-auto'>
                                                                                <CircularProgressBar value={item.originalWtAch} text={consolidatedScoreFormatter(item)} pathColor={"#1882FF"} trailColor="#83C888" />
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                )

                                                            })

                                                        }
                                                    </div>
                                                </div>
                                                <div className='bg-white rounded-lg p-4 px-5 dark:bg-[#2A2E32] shadow-lg mt-5'>
                                                    <div className="flex-row grid 2xl:grid-cols-7 xl:grid-cols-7 lg:grid-cols-7 md:grid-cols-6 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4">
                                                        <div className='bg-white col-span-3 border border-[#E4E7EC] py-4 px-4 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                            <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'> Actual vs Target - {parameterName}</h6>
                                                            <div>
                                                                <ReactEcharts option={barChartOption} />
                                                            </div>
                                                        </div>
                                                        <div className='col-span-4 bg-white border border-[#E4E7EC] py-4 px-4 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                            <div className='flex justify-between'>
                                                                <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'> Brands (Actual Ach.%) - {parameterName}</h6>
                                                                {/* <TabMenu model={items} /> */}
                                                            </div>

                                                            <div className={'flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap- gap-y-4'}>
                                                                {

                                                                    brandsData.map((item, index) => {
                                                                        if (index >= 3) {
                                                                            return null
                                                                        } else {
                                                                            return (
                                                                                <div>
                                                                                    <GaugeChart
                                                                                        key={index}
                                                                                        name={item.Brand}
                                                                                        value={isNaN(item.Percentage) ? '0' : item.Percentage}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })

                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='accordionmulti mt-5'>
                                                    <div className='bg-white rounded-lg p-4 px-5 dark:bg-[#2A2E32] shadow-lg mt-5'>
                                                        <div className='overflow-auto'>
                                                            <DataTable
                                                                paginator value={summaryData} responsiveLayout="scroll" style={{ width: '93vw' }}
                                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                                            >
                                                                <Column field="parameter_name" footer="Total" header="Parameter" sortable filter filterPlaceholder="Search by Name"></Column>
                                                                <Column field="parameter_type" header="Unit" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                                                <Column field="parameter_weightage" header="Weightage" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                                                <Column field="target_value" body={targetBodyTemplate} header="Target" sortable filter filterPlaceholder="Search by Name"></Column>
                                                                <Column header="Actual Performance"
                                                                    body={showActualTarget} sortable filter filterPlaceholder="Search by Role"></Column>
                                                                <Column body={showPlanActual_valuePercentage} header="Actual %Ach" sortable filter filterPlaceholder="Search by Role"></Column>
                                                                <Column body={showAchiievementWeightPercentage} header="Wt Ach(%)" sortable filter filterPlaceholder="Search by Role"></Column>
                                                            </DataTable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ flex: 1, paddingTop: 20 }}>
                                                <div className='text-center py-8' ><ProgressSpinner /></div>
                                            </div>
                                    }

                                </TabPanel>


                                <TabPanel header="Parameter Analysis">
                                    {
                                        showParameterAnalysisData &&
                                        <div className='rounded-lg p-4 px-2 dark:bg-[#2A2E32] mt-5'>
                                            {
                                                parameterAnalysisData !== "" ?
                                                    <div className="flex-row grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4">
                                                        <div className='shadow-lg bg-white border border-[#E4E7EC] py-4 px-4 pb-5  rounded-xl dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                            <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'>Team {parameterAnalysisData.parameter}</h6>

                                                            <div className='bg-white rounded-md border border-[#E4E7EC] p-2 px-3 dark:bg-[#2A2E32] teamrevenueboxshadow mt-5 mb-3'>
                                                                <div className='flex mt-3 gap-6 items-center mb-4'>
                                                                    <div className="">
                                                                        <h6 className='text-[#667085] text-[14px] text-[12px] font-medium dark:text-[#f2f2f2]'>
                                                                            This
                                                                            year
                                                                        </h6>
                                                                        <h3 className='text-[#344054] text-[#101828] text-[24px] font-semibold leading-none dark:text-[#fff]'>
                                                                            {parameterAnalysisData.parameterType === "A" ? "$" : parameterAnalysisData.parameterType === "D" ? "Days " : parameterAnalysisData.parameterType === "N" ? "Number " : ""}
                                                                            {commaSeparated(parameterAnalysisYearValue)}
                                                                            {parameterAnalysisData.parameterType === "P" ? " %" : ""}

                                                                        </h3>

                                                                    </div>
                                                                    <div className='w-full smoothlinechart'>
                                                                        <SmoothLineChart
                                                                            data={parameterAnalysisData.teamTrendsTarget}
                                                                        />
                                                                        {/* <SmoothLineChart
                                                                            data={[1, -2, 2, 5, 3, 2, 100]}
                                                                            markPointValue={parameterAnalysisData.myData ? convertNumberToShortNumber(parameterAnalysisData.myData[0].thisYear) : "0"} unit={parameterAnalysisData.parameterType === "A" ? "$" : parameterAnalysisData.parameterType === "D" ? "Days " : parameterAnalysisData.parameterType === "N" ? "Number " : ""} perUnit={parameterAnalysisData.parameterType === "P" ? " %" : ""} totalValue={parameterAnalysisData.myData ? convertNumberToShortNumber(parameterAnalysisData.myData[0].thisYear) : "0"}
                                                                        /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='bg-white rounded-md border border-[#E4E7EC] p-2 px-3 dark:bg-[#2A2E32] teamrevenueboxshadow mt-5 mb-0'>
                                                                <div className='flex mt-3 gap-6 items-center mb-4'>
                                                                    <div className="">
                                                                        <h6 className='text-[#667085] text-[14px] text-[12px] font-medium dark:text-[#f2f2f2]'>
                                                                            Current Quarter
                                                                        </h6>
                                                                        <h3 className='text-[#344054] text-[#101828] text-[24px] font-semibold leading-none dark:text-[#fff]'>
                                                                            {parameterAnalysisData.parameterType === "A" ? "$" : parameterAnalysisData.parameterType === "D" ? "Days " : parameterAnalysisData.parameterType === "N" ? "Number " : ""}
                                                                            {commaSeparated(parameterAnalysisQuarterValue)}
                                                                            {parameterAnalysisData.parameterType === "P" ? " %" : ""}
                                                                        </h3>
                                                                    </div>
                                                                    <div className='w-full smoothlinechart'>
                                                                        <SmoothLineChart
                                                                            data={parameterAnalysisData.teamTrendsActual}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className='col-span-2'>
                                                            <div className="flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4">
                                                                <div className='col-span-1 shadow-lg bg-white border border-[#E4E7EC] py-4 px-4  rounded-xl dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                                    {/* <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'>Team Trend</h6> */}
                                                                    <div>
                                                                        <StackedLineChart
                                                                            title='Team Trends'
                                                                            data={parameterAnalysisData}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-span-1 border shadow-lg bg-white border border-[#E4E7EC] py-4 px-4  rounded-xl dark:border-[#1b1f22] dark:bg-[#212529]'>

                                                                    <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'>Leaderboard</h6>

                                                                    <BarChart
                                                                        data={parameterAnalysisData}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ flex: 1 }}>
                                                        <h1 style={{ textAlign: "center" }}>No Data Found</h1>
                                                    </div>
                                            }

                                            {/* <div className="flex-row grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4 mt-5">
                                            <div className="">
                                                <Select
                                                    options={setTopList}
                                                    onChange={(e) => {
                                                        handleClickTopList(e);
                                                    }}
                                                    maxMenuHeight={200} placeholder="Select Year"
                                                    className="my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>
                                        </div> */}

                                            <div className="flex-row grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4 mt-5">

                                                {
                                                    employeeTeam &&
                                                    employeeTeam.map((user, index) => {
                                                        return <div className='shadow-lg bg-white border border-[#E4E7EC] py-4 px-4 pb-5  rounded-lg dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                            <div className="flex  items-center gap-4">
                                                                <img src={UserImg} alt="" className="w-12 h-12 rounded-full" />
                                                                <div>
                                                                    <h3 className="text-[#101828] text-[16px] font-semibold dark:text-white">{user.name}</h3>
                                                                    <p className="text-[#667085] text-[13px] font-normal"> Emp ID: {user.empId}</p>
                                                                </div>
                                                            </div>
                                                            <div className='bg-white rounded-md border border-[#E4E7EC] p-2 px-3 dark:bg-[#2A2E32] teamrevenueboxshadow mt-5 mb-2'>
                                                                <div className='flex mt-3 gap-6 items-center mb-4'>
                                                                    <div className="">
                                                                        <h6 className='text-[#667085] text-[14px] text-[12px] font-medium dark:text-[#f2f2f2]'>
                                                                            This year
                                                                        </h6>
                                                                        <h3 className='text-[#344054] text-[#101828] text-[24px] font-semibold leading-none dark:text-[#fff]'>
                                                                            {
                                                                                user.parameterType === PARAMETERS_TYPES.Amount ? "$" + commaSeparated("1200000") : user.thisYearTarget
                                                                            }
                                                                        </h3>
                                                                    </div>
                                                                    <div className='w-full smoothlinechart'>
                                                                        {
                                                                            user.parameterType === PARAMETERS_TYPES.Amount ? "$" + commaSeparated(user.planActual) : user.planActual
                                                                        }
                                                                        <ProgressBar value={user.actualAch}></ProgressBar></div>
                                                                </div>
                                                            </div>
                                                            <div className='bg-white rounded-md border border-[#E4E7EC] p-2 px-3 dark:bg-[#2A2E32] teamrevenueboxshadow mt-2 '>
                                                                <div className='flex mt-3 gap-6 items-center mb-4'>
                                                                    <div className="">
                                                                        <h6 className='text-[#667085] text-[14px] text-[12px] font-medium dark:text-[#f2f2f2]'>
                                                                            Current Quarter
                                                                        </h6>
                                                                        <h3 className='text-[#344054] text-[#101828] text-[24px] font-semibold leading-none dark:text-[#fff]'>
                                                                            {
                                                                                user.parameterType === PARAMETERS_TYPES.Amount ? "$" + commaSeparated(user.currentQuaterTarget) : user.currentQuaterTarget
                                                                            }
                                                                        </h3>
                                                                    </div>
                                                                    <div className='w-full smoothlinechart'>
                                                                        <SmoothLineChart
                                                                            data={user.empQuarterWiseProgress}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }

                                            </div>
                                        </div>
                                    }


                                    {
                                        !showParameterAnalysisData &&
                                        <div className='text-center py-8' ><ProgressSpinner /></div>
                                    }

                                </TabPanel>


                                <TabPanel header="Drilldown Analysis" >
                                    <div className='flex gap-2 justify-end'>
                                        <div className='width-20'>
                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'>Choose Parameter</label>
                                            {/* /*********** dummy select2 ********** */}
                                            <Select
                                                options={parametersForSelect}
                                                value={selectedParameter}
                                                onChange={e => { setSelectedParameter(e) }}
                                                maxMenuHeight={200}
                                                placeholder="Select Parameter"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>
                                        <div className='width-20'>
                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'> Level 1</label>
                                            <Select
                                                options={levelList}
                                                value={level1}
                                                onChange={e => { setLevel1(e) }}
                                                maxMenuHeight={200}
                                                placeholder="Select"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>

                                        <div className='width-20'>
                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'> Level 2</label>
                                            <Select
                                                options={levelList}
                                                value={level2}
                                                onChange={e => { setLevel2(e) }}
                                                maxMenuHeight={200}
                                                placeholder="Select"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>

                                        <div className='width-20'>
                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'> Level 3</label>
                                            <Select
                                                options={levelList}
                                                value={level3}
                                                onChange={e => { setLevel3(e) }}
                                                maxMenuHeight={200}
                                                placeholder="Select"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>

                                        <div className='width-20'>
                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'> Level 4</label>
                                            <Select
                                                options={levelList}
                                                value={level4}
                                                onChange={e => { setLevel4(e) }}
                                                maxMenuHeight={200}
                                                placeholder="Select"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>

                                        <div className='mt-8'>
                                            <Link onClick={() => { show_DrillDownChart() }} className={"bg-[#029046] border border-[#029046] rounded-md text-white px-4 py-2"}>
                                                Go
                                            </Link>
                                        </div>

                                    </div>
                                    <div className='drilldown'>
                                        {
                                            showDrillDownChart &&
                                            <ReactEcharts option={option} />
                                        }
                                    </div>

                                </TabPanel>


                                {/* // LAST TAB */}
                                <TabPanel header="Detailed View">
                                    <div className='accordionmulti mt-5'>
                                        <div className='bg-white rounded-lg p-4 px-5 dark:bg-[#2A2E32] shadow-lg mt-5'>
                                            <div className='overflow-auto'>
                                                {
                                                    isDetailedViewLoaded === true ?
                                                        <DataTable
                                                            paginator value={parameterDetailViewList} style={{ width: '93vw' }}
                                                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={50} rowsPerPageOptions={[50, 100, 200]}
                                                        >
                                                            {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
                                                            <Column field="buName" header="BU" sortable filter filterPlaceholder="Search by BU"></Column>
                                                            <Column field="regionName" header="Region" sortable filter filterPlaceholder="Search by Region Name"></Column>
                                                            <Column field="countryName" header="Country" sortable filter filterPlaceholder="Search by Country Name"></Column>
                                                            <Column field="brandName" header="Brand" sortable filter filterPlaceholder="Search by Brand Name"></Column>
                                                            <Column field="gtmName" header="GTM" sortable filter filterPlaceholder="Search by GTM Name"></Column>
                                                            <Column field="subGtmName" header="Sub GTM" sortable filter filterPlaceholder="Search by Sub GTM Name"></Column>
                                                            <Column field="pGName" header="Product Group" sortable filter filterPlaceholder="Search by Product Group"></Column>
                                                            <Column field="custCatName" header="Customer Group" sortable filter filterPlaceholder="Search by Customer Cat"></Column>
                                                            <Column field="customerName" header="Customer" sortable filter filterPlaceholder="Search by Customer"></Column>

                                                            <Column field="target_value" header="Target" sortable filter filterPlaceholder="Search by Target"></Column>
                                                            <Column field="target_Actual_value" header="Actual" sortable filter filterPlaceholder="Search by Actual"></Column>
                                                            <Column field="target_Actual_value_Ach" header="% Ach." sortable filter filterPlaceholder="Search by % Ach."></Column>
                                                            {
                                                                Q1Check === true || allQCheck === true ?
                                                                    <Column field="q1_target_value" header="Q1 Target" sortable filter filterPlaceholder="Search by Q1 Target"></Column> : null
                                                            }

                                                            {
                                                                Q1Check === true || allQCheck === true ?
                                                                    <Column field="q1_target_Actual_value" header="Q1 Actual" sortable filter filterPlaceholder="Search by Q1 Actual"></Column> : null
                                                            }

                                                            {
                                                                Q1Check === true || allQCheck === true ?
                                                                    <Column field="q1_target_Actual_value_Ach" header="% Ach." sortable filter filterPlaceholder="Search by % Ach."></Column> : null
                                                            }

                                                            {
                                                                Q2Check === true || allQCheck === true ?
                                                                    <Column field="q2_target_value" header="Q2 Target" sortable filter filterPlaceholder="Search by Q2 Target"></Column> : null
                                                            }

                                                            {
                                                                Q2Check === true || allQCheck === true ?
                                                                    <Column field="q2_target_Actual_value" header="Q2 Actual" sortable filter filterPlaceholder="Search by Q2 Actual"></Column> : null
                                                            }

                                                            {
                                                                Q2Check === true || allQCheck === true ?
                                                                    <Column field="q2_target_Actual_value_Ach" header="% Ach." sortable filter filterPlaceholder="Search by % Ach."></Column> : null
                                                            }

                                                            {
                                                                Q3Check === true || allQCheck === true ?
                                                                    <Column field="q3_target_value" header="Q3 Target" sortable filter filterPlaceholder="Search by Q3 Target"></Column> : null
                                                            }

                                                            {
                                                                Q3Check === true || allQCheck === true ?
                                                                    <Column field="q3_target_Actual_value" header="Q3 Actual" sortable filter filterPlaceholder="Search by Q3 Actual"></Column> : null
                                                            }

                                                            {
                                                                Q3Check === true || allQCheck === true ?
                                                                    <Column field="q3_target_Actual_value_Ach" header="% Ach." sortable filter filterPlaceholder="Search by % Ach."></Column> : null
                                                            }

                                                            {
                                                                Q4Check === true || allQCheck === true ?
                                                                    <Column field="q4_target_value" header="Q4 Target" sortable filter filterPlaceholder="Search by Q4 Target"></Column> : null
                                                            }
                                                            {
                                                                Q4Check === true || allQCheck === true ?
                                                                    <Column field="q4_target_Actual_value" header="Q4 Actual" sortable filter filterPlaceholder="Search by Q4 Actual"></Column> : null
                                                            }
                                                            {
                                                                Q4Check === true || allQCheck === true ?
                                                                    <Column field="q4_target_Actual_value_Ach" header="% Ach." sortable filter filterPlaceholder="Search by % Ach."></Column> : null
                                                            }
                                                            <Column field="month" header="Month" sortable filter filterPlaceholder="Search by Month"></Column>
                                                            <Column body={getParameterName} header="Parameter Name" sortable filter filterPlaceholder="Search by Parameter Name"></Column>

                                                        </DataTable>
                                                        :
                                                        <div className='text-center py-8' ><ProgressSpinner /></div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </TabPanel>
                            </TabView>
                        </div>


                    </div>
                </div>


            </React.StrictMode>
        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}