import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

const graphQLGetAllData = async (tableName, filter = []) => {
    filter.push({ _deleted: { attributeExists: false } })
    let allData = [];
    let nextToken4 = null;
    let hasMoreData = true;
    while (hasMoreData) {
        const result2 = await API.graphql(graphqlOperation(queries[tableName], { filter: { and: filter }, limit: 3000, nextToken: nextToken4 }));
        const { items, nextToken: newNextToken } = result2.data[tableName];
        allData = [...allData, ...items];
        // console.log("tableName", tableName)
        nextToken4 = newNextToken;
        hasMoreData = nextToken4 !== null && nextToken4 !== undefined ? nextToken4 : false;
    }
    return allData;
}

const graphQLGetAllDataByOR = async (tableName, filter = []) => {
    // filter.push({ _deleted: { attributeExists: false } })
    let allData = [];
    let nextToken4 = null;
    let hasMoreData = true;
    while (hasMoreData) {
        const result2 = await API.graphql(graphqlOperation(queries[tableName], { filter: { or: filter }, limit: 3000, nextToken: nextToken4 }));
        const { items, nextToken: newNextToken } = result2.data[tableName];
        allData = [...allData, ...items];
        // console.log("tableName", tableName)
        nextToken4 = newNextToken;
        hasMoreData = nextToken4 !== null && nextToken4 !== undefined ? nextToken4 : false;
    }
    return allData;
}

const graphQLFindRecordById = async (tableName, id) => {
    var result = []
    if (id) {
        result = await API.graphql({ query: queries[tableName], variables: { id: id } });
        result = result.data ? result.data[tableName] : []
    }
    return result;
}

export {
    graphQLGetAllData, graphQLFindRecordById, graphQLGetAllDataByOR
};