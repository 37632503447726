import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Customer, CustomerCategory, Country } from '../../../../../models'
import { DataStore } from "@aws-amplify/datastore";
import capitalizeFirst from "../../../../../components/common/capitalizeFirst";
import { ValidateHRRole } from "../../../../../components/helper/validateRole";
import { ConvertResponseForSelect } from '../../../../../components/helper/commonfunctions'
import Select from 'react-select'
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import HRLogin from '../../../../../setup/auth/hr.component';
import { Predicates, SortDirection } from '@aws-amplify/datastore';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';


export default function CreateCustomer() {

    const [customerCategoryList, setCustomerCategoryList] = useState([]);
    const [countryList, setCountryList] = useState([]);

    const [customerCategory, setCustomerCategory] = useState("");
    const [country, setCountry] = useState("");

    const [customerCategoryId, setCustomerCategoryId] = useState("");
    const [countryId, setCountryId] = useState("");

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editCustomerId } = useParams();
    const [isEditMode, setEditMode] = useState(false);
    const [customerId, setCustomerId] = useState("");
    let navigateTo = useNavigate();
    const [refCode, SetRefCode] = useState("");



    //Save Fields
    const [customerList, SetCustomerList] = useState("");


    //Create Record
    const saveCustomer = async () => {

        if (customerList === "") { toast.error("Please enter Customer."); return; }
        if (customerCategoryId === "") { toast.error("Please select Customer Group."); return; }
        if (countryId === "") { toast.error("Please select Country."); return; }

        setPleaseWaitButton(true);
        if (isEditMode) {
            // const original = await DataStore.query(Customer, customerId);
            const original = await graphQLFindRecordById('getCustomer', customerId);

            // await DataStore.save(
            //     Customer.copyOf(original, (updated) => {
            //         updated.name = capitalizeFirst(customerList);
            //         updated.customer_category_id = customerCategoryId;
            //         updated.country_id = countryId;
            //         updated.REF_CODE = refCode;
            //     })
            // );

            await API.graphql({
                query: mutations.updateCustomer,
                variables: {
                    input: {
                        id: original.id,
                        name: capitalizeFirst(customerList),
                        customer_category_id: customerCategoryId,
                        country_id: countryId,
                        REF_CODE: refCode,
                        _version: original._version,
                    }
                }
            });
            toast.success('Updated');
        } else {
            //     await DataStore.save(new Customer({ 
            //     name: capitalizeFirst(customerList), 
            //     customer_category_id: customerCategoryId, 
            //     country_id: countryId, 
            //     REF_CODE: capitalizeFirst(refCode) 
            // }));
            await API.graphql(
                graphqlOperation(mutations.createCustomer, {
                    input: {
                        name: capitalizeFirst(customerList),
                        customer_category_id: customerCategoryId,
                        country_id: countryId,
                        REF_CODE: capitalizeFirst(refCode)
                    }
                })
            );
            toast.success('Added');
        }

        setPleaseWaitButton(false);
        navigateTo("/hr/setting/dimensions/customer");
    };

    async function BindList() {
        // var customersCategoryList = await DataStore.query(CustomerCategory, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });


        var customersCategoryList = await graphQLGetAllData('listCustomerCategories');
        customersCategoryList = customersCategoryList.sort((a,b)=>a.name.localeCompare(b.name));

        var custCategoryArray = []; custCategoryArray = ConvertResponseForSelect(customersCategoryList); setCustomerCategoryList(custCategoryArray);

        // var custCountryList = await DataStore.query(Country, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var custCountryList = await graphQLGetAllData('listCountries');
        custCountryList = custCountryList.sort((a,b)=>a.name.localeCompare(b.name));

        var myCustCountryArray = []; myCustCountryArray = ConvertResponseForSelect(custCountryList); setCountryList(myCustCountryArray)
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }

            //Edit existing record
            if (editCustomerId != null) {
                setCustomerId(editCustomerId);

                // const original = await DataStore.query(Customer, editCustomerId);
                const original = await graphQLFindRecordById('getCustomer', editCustomerId);

                if (original != null) {
                    // const Country_original = await DataStore.query(Country, original.country_id);
                    const Country_original = await graphQLFindRecordById('getCountry', original.country_id);

                    // const customerCategory_original = await DataStore.query(CustomerCategory, original.customer_category_id);
                    const customerCategory_original = await graphQLFindRecordById('getCustomerCategory', original.customer_category_id);

                    setCountry({ label: Country_original.name + " (" + Country_original.REF_CODE + ")", value: Country_original.id })
                    SetCustomerList(original.name);
                    setCustomerCategory({ label: customerCategory_original.name + " (" + customerCategory_original.REF_CODE + ")", value: customerCategory_original.id });
                    setCustomerCategoryId(customerCategory_original.id)
                    setCountryId(original.country_id)
                    setEditMode(true);
                    SetRefCode(original.REF_CODE);
                }
                else {
                    toast.error('Invalid record.');
                    navigateTo("/hr/setting/dimensions/customer");
                }
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
        BindList()
    }, []);

    return (
        isAuthenticated ? (
            <div>
                <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full">
                    <TopNavBar />
                    <LeftSideBar />

                    <div className="pl-16">
                        <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                            <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                                <div className="p-3 py-5 bg-white rounded-md shadow dark:bg-[#252423]">
                                    <div className="flex justify-center mt-3 mb-2">
                                        <h2 className="page-title">
                                            {isEditMode ? "Edit" : "Create"} Customer
                                        </h2>
                                    </div>
                                    <div x-data="{tab: 1}"></div>
                                    <div className={"block w-full"}>
                                        <div className="px-4" x-show="tab === 1">
                                            <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-x-4">


                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Customer
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *{" "}
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(customerList)}
                                                        onChange={(e) => SetCustomerList(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter Customer"
                                                    />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Ref_Code
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >

                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(refCode)}
                                                        onChange={(e) => SetRefCode(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Ref Code"
                                                    />
                                                </div>

                                                {/* //dropdown for customer category */}
                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Select Group
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *{" "}
                                                        </label>
                                                    </label>
                                                    <Select placeholder='Select' options={customerCategoryList} value={customerCategory} onChange={e => {
                                                        setCustomerCategory(e)
                                                        setCustomerCategoryId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Select Country
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *{" "}
                                                        </label>
                                                    </label>
                                                    <Select placeholder='Select' options={countryList} value={country} onChange={e => {
                                                        setCountry(e)
                                                        setCountryId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>


                                            </div>


                                            <div className="grow flex justify-center gap-3 mb-3">
                                                <Link
                                                    to="/hr/setting/dimensions/customer"
                                                    className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                >
                                                    Cancel
                                                </Link>
                                                <button
                                                    onClick={() => saveCustomer()}
                                                    className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                                                >
                                                    {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <HRLogin sessionExpired={1} />
        )

    );
}
