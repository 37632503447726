import React, { useState, useEffect } from 'react';
import LeftSideBar from '../../components/common/hr/leftnav.component';
import TopNavBar from '../../components/common/hr/topnav.component';
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ViewButton from '../../components/common/buttons/ViewButton';
import EditButton from '../../components/common/buttons/EditButton';
import DeleteButton from '../../components/common/buttons/DeleteButton';
import Select from 'react-select';
import popupHeader from '../../assets/images/Evaluation_header_img.png'
import imageDeployedLarge from '../../assets/images/deployedImg.png';
import { Auth } from 'aws-amplify';
import { ValidateHRRole } from '../../components/helper/validateRole';
import HRLogin from '../../setup/auth/hr.component';
import { FinancialYear, BIUnit, SBU, FinanceActuals, Employee, Roles, InitiatePlanning, Planning } from '../../models';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { ObjectLength, ObjectToArray } from "../../components/helper/commonfunctions";
import { sendNotification } from '../../components/helper/notification';
import { NOTIFICATIONS_TYPES, EMAIL_SUBJECTS } from '../../components/helper/enum'
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';
import { toast } from "react-toastify";
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { current } from '@reduxjs/toolkit';
import { getFinanceActuals } from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../src/components/helper/graphQLFunctions'



export default function HrPlanning_ViewInitiatePlanning() {

    let navigateTo = useNavigate();

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const { financialInitiateId, planningId } = useParams();
    let loggedHREmployeeId = reactLocalStorage.get('loggedHREmployeeId')

    //state 
    const [financialYear, setFinancialYear] = useState("")
    const [startAndEndDate, setStartAndEndDate] = useState("")

    //list
    const [employeeList, setEmployeeList] = useState([])
    const [selectedEmployeeForInitiation, setSelectedEmployeeForInitiation] = useState([]);

    //function for Actions
    const actionsOnComponySbu = (row) => {
        return (
            <React.Fragment >
                <div className="flex justify-center gap-8 ">
                    <Link onClick={(e) => {
                        handleEditClick(e, row.id)
                    }}  ><EditButton /></Link>
                    <Link onClick={(e) => handleDeleteClick(e, row.id)} ><DeleteButton /></Link>
                </div>

            </React.Fragment>
        );
    }

    // TABLES  - Show status 
    const requestsStatus = (rowData) => {
        return (
            rowData.status === "Initiated" ?
                <p className="bg-[#E5F3EC] text-[#4FB155] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center"> <div className='w-2 h-2 bg-[#4FB155] rounded-full'></div>{"Initiated"}</p>
                :
                <p className="bg-[#FEF3F2] text-[#FDA29B] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center "> <div className='w-2 h-2 bg-[#FDA29B] rounded-full'></div>{"Not Initiated"}</p>
        )
    }

    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        // const objDeleteRole = await DataStore.query(FinanceActuals, id);
        const objDeleteRole = await graphQLFindRecordById('getFinanceActuals', id);

        alertify.confirm('Confirmation', 'Do you want to delete this Finance Actual?', async function () {
            //Delete Existing InterpreterRequest for this language
            // DataStore.delete(FinanceActuals, (c) => c.id("eq", id))
            // //Delete language
            // DataStore.delete(objDeleteRole);
            await API.graphql({
                query: mutations.deleteFinanceActuals,
                variables: {
                    input: {
                        id: objDeleteRole.id,
                        _version: objDeleteRole._version,
                    }
                }
            });
            BindList();
            toast.success("Deleted");
        }
            , function () { });

    };

    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/hr/evaluation/createebitpbt/' + id);
    };

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //Initiate Employee
    async function onclickInitiate() {
        if (selectedEmployeeForInitiation.length === 0) { toast.error('Please select Employee.'); return; }

        alertify.confirm('Confirmation', 'Do you want to initiate goals setting for the selected employee(s)?', async function () {

            selectedEmployeeForInitiation.map(async (currentItem, i) => {

                // var currentInitiatePlanningDetails = await DataStore.query(InitiatePlanning, (c) => c.and(c => [
                //     c.planning_id.eq(planningId),
                //     c.employee_id.eq(currentItem.id)
                // ]));
                // var currentInitiatePlanningDetails = await graphQLGetAllData('listInitiatePlannings', [
                //     { planning_id: { eq: planningId }, employee_id: { eq: currentItem.id } }
                // ]);
                var currentInitiatePlanningDetails = initiatePlanningsData.find( p =>
                    p.planning_id === planningId  && p.employee_id === currentItem.id)

                if (currentInitiatePlanningDetails) {
                    // const original = await DataStore.query(InitiatePlanning, currentInitiatePlanningDetails[0].id);
                    const original = await graphQLFindRecordById('getInitiatePlanning', currentInitiatePlanningDetails[0].id);

                    // await DataStore.save(
                    //     InitiatePlanning.copyOf(original, (updated) => {
                    //         updated.planning_id = planningId;
                    //         updated.employee_id = currentItem.id;
                    //     })
                    // ).then(async (response) => {  })

                    await API.graphql({
                        query: mutations.updateInitiatePlanning,
                        variables: {
                            input: {
                                id: original.id,
                                planning_id: planningId,
                                employee_id: currentItem.id,
                                _version: original._version,
                            }
                        }
                    });

                    let requestTypeName = NOTIFICATIONS_TYPES.InitiatePlan
                    let emailSubject = EMAIL_SUBJECTS.InitiatePlan
                    let notificationMsg = currentItem.employeeName + " (" + currentItem.employeeId + ")" + " Your goals setting for the FY " + financialYear + " has been initiated, Planning Period: " + startAndEndDate
                    console.log(notificationMsg)
                    await sendNotification(loggedHREmployeeId, currentItem.id, requestTypeName, notificationMsg, emailSubject);
                    setSelectedEmployeeForInitiation([])
                    BindList();


                }
                else {
                    // await DataStore.save(
                    //     new InitiatePlanning({
                    //         "planning_id": planningId,
                    //         "employee_id": currentItem.id
                    //     })
                    // ).then(async (response) => { })

                    await API.graphql(
                        graphqlOperation(mutations.createInitiatePlanning, {
                            input: {
                                planning_id: planningId,
                                employee_id: currentItem.id
                            }
                        })
                    );

                    let requestTypeName = NOTIFICATIONS_TYPES.InitiatePlan
                    let emailSubject = EMAIL_SUBJECTS.InitiatePlan
                    let notificationMsg = currentItem.employeeName + " (" + currentItem.employeeId + ")" + " Your goals setting for the FY " + financialYear + " has been initiated, Planning Period:" + startAndEndDate
                    // console.log(notificationMsg)
                    await sendNotification(loggedHREmployeeId, currentItem.id, requestTypeName, notificationMsg, emailSubject);
                    setSelectedEmployeeForInitiation([])
                    BindList();

                }
            })
            toast.success(' Goals Setting for the selected employee(s) has been initiated.');

        }
            , function () { });
    }

    const [initiatePlanningsData, setInitiatePlanningsData]=useState([]);
    //Bind List
    async function BindList() {

        let initaiatePlanningsResponses = await graphQLGetAllData('listInitiatePlannings')
        setInitiatePlanningsData(initaiatePlanningsResponses);
        //set Financial Year
        // var employeeResponse = await DataStore.query(FinancialYear, financialInitiateId);
        var employeeResponse = await graphQLFindRecordById('getFinancialYear', financialInitiateId);

        if (employeeResponse) { setFinancialYear(employeeResponse.name) } else { setFinancialYear("") }

        //set start date and end date
        // var PlanningResponses = await DataStore.query(Planning, planningId);
        var PlanningResponses = await graphQLFindRecordById('getPlanning', planningId);

        let start_And_END_Date = moment(PlanningResponses.planning_from_date).format("Mo MMM YYYY") + " - " + moment(PlanningResponses.planning_to_date).format("Mo MMM YYYY")
        setStartAndEndDate(start_And_END_Date)

        //get Employee Response
        // var employeeResponse = await DataStore.query(Employee, Predicates.ALL);
        var employeeResponse = await graphQLGetAllData('listEmployees');
        if (employeeResponse.length > 0) {
            var newResponse = [];

            Object.keys(employeeResponse).map(async (currentItem, i) => {
                let loopEmployeeItem = employeeResponse[currentItem];
                let data = ObjectToArray(loopEmployeeItem);

                data['employeeName'] = employeeResponse[i].firstName + " " + employeeResponse[i].lastName

                //Role List
                // var roleResponse = await DataStore.query(Roles, employeeResponse[i].role_id)
                var roleResponse = await graphQLFindRecordById('getRoles', employeeResponse[i].role_id);

                data['role'] = roleResponse ? roleResponse.name : ''

                data['vertical'] = "Distribution"

                //get SBU
                if (employeeResponse[i].param_sbu_id) {
                    // var SBUResponse = await DataStore.query(SBU, employeeResponse[i].param_sbu_id)
                    var SBUResponse = await graphQLFindRecordById('getSBU', employeeResponse[i].param_sbu_id);
                    data['sbuName'] = SBUResponse ? SBUResponse.name : ''
                }
                else {
                    data['sbuName'] = "-"
                }

                //check Status
                // var currentInitiatePlanningDetails = await DataStore.query(InitiatePlanning, (c) => c.and(c => [
                //     c.planning_id.eq(planningId),
                //     c.employee_id.eq(employeeResponse[i].id)
                // ]));

                // var currentInitiatePlanningDetails = await graphQLGetAllData('listInitiatePlannings', [
                //     { planning_id: { eq: planningId } }, { employee_id: { eq: employeeResponse[i].id } }]);

                var currentInitiatePlanningDetails = initiatePlanningsData.find( p =>
                    p.planning_id === planningId  && p.employee_id === employeeResponse[i].id)


                //set Status
                if (currentInitiatePlanningDetails) {
                    data["status"] = "Initiated"
                } else {
                    data["status"] = "Not Initiated"
                }

                data["eligibility"] = "Yes";
                data["dateOfJoining"] = moment(employeeResponse[i].createdAt).format("MMM D, YYYY");
                data["startDate"] = moment(PlanningResponses.planning_from_date).format("MMM D, YYYY");
                data["endDate"] = moment(PlanningResponses.planning_to_date).format("MMM D, YYYY");

                newResponse.push(data);

                //On Last Record assign to state
                if (i === employeeResponse.length - 1) {
                    let sortArray = newResponse.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
                    setEmployeeList(sortArray)
                }
            })

        } else {
            setEmployeeList([]);
        }


    }

    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    return (
        isAuthenticated ? (
            <div className="pt-16 ">
                <TopNavBar />
                <LeftSideBar />

                <div className="dashboard-main-wrapper target-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className='flex justify-between'>
                            <div className="sm:flex items-center space-x-8 justify-between">
                                <div className="mt-5 lg:mt-0">
                                    <Link to={"/hr/planning"}
                                        className="py-2 px-3 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:border-[#4A5056] dark:bg-[#4A5056] dark:text-white" ><i className="icon arrow_left_double mr-6 "></i> Back</Link>
                                </div>
                                <div className="text-2xl font-semibold dark:text-white">Goals Setting, Initiate Planning: FY {financialYear}</div>
                            </div>
                            <div className="mt-5 lg:mt-0">
                                <Link onClick={() => {
                                    onclickInitiate()
                                }}
                                    className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" >Initiate</Link>
                            </div>
                        </div>

                        <div className=" rounded-lg mt-5 bg-white">
                            <div>
                                <h6 className="text-[#101828] text-[18px]   px-4 py-3 ">Total: {employeeList.length} Employee</h6>
                            </div>
                            <DataTable
                                paginator
                                value={employeeList}
                                responsiveLayout="scroll"
                                selection={selectedEmployeeForInitiation}
                                onSelectionChange={e => setSelectedEmployeeForInitiation(e.value)}
                                dataKey="employeeId"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                            >
                                <Column selectionMode="multiple"
                                    field="employeeName" header="" />
                                <Column field="employeeId" filter sortable header="Employee ID"></Column>
                                <Column field="employeeName" filter sortable header="Name"></Column>
                                <Column field="role" filter sortable header="Role"></Column>
                                <Column field="vertical" filter sortable header="Vertical"></Column>
                                <Column field="sbuName" filter sortable header="SBU"></Column>
                                <Column field="dateOfJoining" sortable header="DOJ"></Column>
                                <Column field="eligibility" filter sortable alignHeader={'center'} header="Eligibility"></Column>
                                <Column field="startDate" filter sortable header="Start Date"></Column>
                                <Column field="endDate" filter sortable header="End Date"></Column>
                                <Column field="status" body={requestsStatus} sortable filter alignHeader={'center'} header="Status"></Column>
                            </DataTable>
                        </div>

                    </div>
                </div>
            </div >
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}
