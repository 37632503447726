import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataTableCustomStyles } from '../../../../../components/helper/dataTable.config';
import { Country, Region, Customer } from '../../../../../models'
import HRLogin from "../../../../../setup/auth/hr.component";
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import EditButton from '../../../../../components/common/buttons/EditButton';
import DeleteButton from '../../../../../components/common/buttons/DeleteButton';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import { Auth } from 'aws-amplify';
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import { ConvertResponseForId, ObjectToArray } from '../../../../../components/helper/commonfunctions'
import ImportDimension from '../../../../../components/common/import/dimensionsimport.component';
import { ProgressSpinner } from 'primereact/progressspinner';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';

export default function HrCountry() {

    let navigateTo = useNavigate();
    const [checkCustomerList, setCheckCustomerList] = useState([]);
    const [isPageLoaded, setPageLoaded] = useState(false);

    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/hr/settings/dimensions/createcountry/' + id);
    };

    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        // const objDeleteCountry = await DataStore.query(Country, id);
        const objDeleteCountry = await graphQLFindRecordById('getCountry', id);

        alertify.confirm('Confirmation', 'Do you want to delete this record?', async function () {
            // //Delete language
            // DataStore.delete(objDeleteCountry);


            await API.graphql({
                query: mutations.deleteCountry,
                variables: {
                    input: {
                        id: objDeleteCountry.id,
                        _version: objDeleteCountry._version,
                    }
                }
            });

            BindList();
            toast.success("Deleted");
        }
            , function () { });

    };

    // //*Column Name
    const DataTableColumns = [
        {
            name: 'Country',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Ref Code',
            selector: row => row.REF_CODE,
            sortable: true,
        },
        {
            name: 'VAT',
            selector: row => row.VAT ? row.VAT + '%' : 0,
            sortable: true,
        },
        {
            name: 'Cluster',
            selector: row => row.region,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <div className="flex gap-8">
                        <Link onClick={(e) => handleEditClick(e, row.id)} ><EditButton /></Link>
                        {
                            ConvertResponseForId(checkCustomerList, row.id) === row.id ?
                                <Link className="disabledCursor opacity-50" ><DeleteButton /></Link> :
                                <Link onClick={(e) => handleDeleteClick(e, row.id)} ><DeleteButton /></Link>
                        }
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true
        }
    ];


    // //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    // //State
    const [CountryList, setCountryList] = useState([]);
    const [CountryFilterList, setCountryFilterList] = useState([]);
    const [isShowImportPopup, setIsShowImportPopup] = useState(false);
    const [regions,setRegions]=useState([]);
    const [isDataLoaded,setIsDataLoaded] = useState(false);

    const getMasterData = async () => {
        setIsDataLoaded(false)
        let regionResponses = await graphQLGetAllData('listRegions');
        setRegions(regionResponses);
        console.log('hi')
        setIsDataLoaded(true)
    }

    async function BindList() {
        // var CountryResponses = await DataStore.query(Country, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });

        var CountryResponses = await graphQLGetAllData('listCountries');
        CountryResponses = CountryResponses.sort((a, b) => a.name.localeCompare(b.name));

        //product group data for delete enable
        let customerLength = []
        // var customerResponses = await DataStore.query(Customer);
        var customerResponses = await graphQLGetAllData('listCustomers');

        customerResponses.map((resource) => {
            let data = { id: resource.country_id }
            customerLength.push(data)
        })
        setCheckCustomerList(customerLength)

        var newResponse = [];
        if (CountryResponses.length > 0) {

            // Object.keys(CountryResponses).map(async (currentItem, i) => {
            //     let loopItem = CountryResponses[currentItem];
            //     let data = ObjectToArray(loopItem);

            //     if (data["region_id"] !== "") {
            //         // var regionResponses = await DataStore.query(Region, data["region_id"]);
            //         var regionResponses = await graphQLFindRecordById('getRegion', data["region_id"]);

            //         data["region"] = regionResponses != undefined ? regionResponses.name + " (" + regionResponses.REF_CODE + ")" : "-"
            //     }
            //     newResponse.push(data);

            //     //On Last Record assign to state
            //     if (i === CountryResponses.length - 1) {
            //         setCountryList(newResponse)
            //         setCountryFilterList(newResponse)
            //         setPageLoaded(true)
            //     }
            // })

            // CountryResponses.forEach(async (c, index) => {

            //     let obj = {...c};
            //     if (c.region_id !== '' || c.region_id !== null) {
            //         var regionResponses = regions.find( r => r.id === c.region_id)
            //         obj['region'] = regionResponses != undefined ? regionResponses.name + " (" + regionResponses.REF_CODE + ")" : "-";
            //     }
            //     newResponse.push(obj);

            //     //On Last Record assign to state
            //     if (index === CountryResponses.length - 1) {
            //          console.log('newResponse',newResponse)
            //         setCountryList(newResponse)
            //         setCountryFilterList(newResponse)
            //         setPageLoaded(true)
            //     }
            // })

            for( var i=0;i<CountryResponses.length;i++){

                let obj ={...CountryResponses[i]};
                if (CountryResponses[i].region_id !== '' || CountryResponses[i].region_id !== null) {
                    var regionResponses = regions.find( r => r.id === CountryResponses[i].region_id)
                    obj['region'] = regionResponses != undefined ? regionResponses.name + " (" + regionResponses.REF_CODE + ")" : "-";
                }
                newResponse.push(obj);

            }

            console.log('newResponse',newResponse)
            setCountryList(newResponse)
            setCountryFilterList(newResponse)
            setPageLoaded(true)


        } else {
            setCountryList([])
            setCountryFilterList([])
            setPageLoaded(true)
        }

    }

    //onClick Close popup
    async function onClickCloseImportPopup() {
        setIsShowImportPopup(false);
        BindList();
    }

    //Search Country
    const searchCountry = (searchedText) => {
        if (searchedText.length > 1) {
            let data = [...CountryFilterList]
            let filteredData = data.filter(e =>
                e.name.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.REF_CODE.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.region.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.VAT.includes(searchedText.toLowerCase())
            )
            setCountryList(filteredData)
        } else {
            let data = [...CountryFilterList]
            setCountryList(data)
        }
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }


    useEffect(() => {
        onLoad();
        getMasterData();
        // BindList();
    }, []);

    useEffect(()=>{
        if( isDataLoaded ===  true){
            BindList();
        }
    },[regions])

    return (
        isAuthenticated ? (

            <div>
                <div className="pt-16">
                    <TopNavBar />
                    <LeftSideBar />
                    <div className="dashboard-main-wrapper pl-20 pr-4 mt-3">
                        <div className="flex flex-1 flex-wrap p-5 pb-4 px-0 top-action-wrapper">
                            <div className="md:flex xs:w-full sm:w-full md:w-auto flex-row align-center">
                                <div className="md:flex block md:col-span-2 mb-4">
                                </div>
                                <div className="md:flex block mb-4">
                                    <h2 className="page-title">Countries</h2>
                                </div>
                            </div>

                            <div className="md:flex flex-1 flex-row xs:w-full sm:w-full md:w-auto align-center justify-end md:ml-auto gap-3 right-side">
                                <div className="relative">
                                    <input onChange={(e) => searchCountry(e.target.value)} type="text" id="required-search" className="rounded-md border border-[#D0D5DD] focus:border-transparent w-full py-2.5 pl-3 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#403E3C] dark:text-[#FFFFFF] dark:border-[#403E3C]" placeholder="Search..." />
                                </div>
                                <div className="md:flex block mb-4 gap-4">
                                    <Link to="/hr/settings" className="flex py-2 px-5 bg-[#FFF] hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-black transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] dark:bg-[#4A5056] dark:border-[#333231] dark:text-[#FFFFFF]">
                                        Back
                                    </Link>
                                    <Link onClick={() => {
                                        setIsShowImportPopup(true);
                                    }} className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] bg-black">
                                        Import
                                    </Link>
                                    <Link to="/hr/settings/dimensions/createcountry" className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] dark:border-[#333231]">
                                        Create
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div>
                            <div className={"block w-full pt-5"}>
                                {
                                    isPageLoaded &&
                                    <DataTable
                                        columns={DataTableColumns}
                                        data={CountryList}
                                        customStyles={DataTableCustomStyles}
                                        pagination
                                        highlightOnHover
                                        paginationPerPage={10}
                                    />
                                }
                                {
                                    !isPageLoaded &&
                                    <div className='text-center py-10' >
                                        <ProgressSpinner style={{ width: '80px', height: '80px', zIndex: '9999' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".8s" />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        isShowImportPopup &&
                        <ImportDimension
                            closePopup={onClickCloseImportPopup}
                            type={"Country"}
                        />
                    }
                </div>
            </div>

        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}
