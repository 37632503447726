import { DataStore } from "aws-amplify";
import {
    BIUnit, Country, Employee, FinancialYear, InitiatePlanning, Planning, Region, Roles, SBU, TargetDetails, Targets, Customer, CustomerCategory,
    Plan, Brand, ProductGroup, Parameters, Relationship, RelationshipDetails, Evaluation, InitiateEvaluation, ActualDetails
} from "../../models";
import { reactLocalStorage } from 'reactjs-localstorage';
import { convertArrayToObject } from '../../components/helper/commonfunctions';
import { PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES } from '../../components/helper/enum'
import { isPositiveInteger, ShowInMillion } from '../../components/helper/maths';
import { calculateTargetTotal, calculateConsolidatedScore } from "../../components/helper/dashboardCalCulations"
import { graphQLGetAllData, graphQLFindRecordById } from '../../components/helper/graphQLFunctions';


//*Planning Filter
const planningFilter = async (employeeId, regionId, sBUId, FinancialYearId) => {

    //set local variables
    let filterEmployeeId = ""
    let filterFyId = ""
    let isSbuCheck = false
    let isRegionCheck = false
    var newResponse = [];

    //get logged EmployeeId and Financial Year
    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
    let selectedYear = reactLocalStorage.getObject('selectedYear');

    //check empty condition
    if (employeeId === undefined || employeeId === 0) { filterEmployeeId = loggedEmployeeId } else { filterEmployeeId = employeeId }
    if (FinancialYearId === undefined) { filterFyId = selectedYear.value } else { filterFyId = FinancialYearId }
    if (sBUId === undefined || sBUId === 0) { isSbuCheck = false } else { isSbuCheck = true }
    if (regionId === undefined) { isRegionCheck = false } else { isRegionCheck = true }


    var employeeResponse = await DataStore.query(Targets, (c) => c.and(c => [
        c.report_manager_employee_id.eq(filterEmployeeId),
        c.financial_year_id.eq(filterFyId)
    ]));


    let isPlanInitiated = false
    var checkIsPlanInit = await DataStore.query(Planning, (c) => c.financial_year_id.eq(filterFyId))
    if (checkIsPlanInit) { isPlanInitiated = true }

    if (employeeResponse.length > 0) {

        for (var i = 0; i < employeeResponse.length; i++) {

            //check sbu 
            if (isSbuCheck) {

                let employeeId = ""; let name = "";
                var empResponse = await DataStore.query(Employee, employeeResponse[i].employee_id);
                if (empResponse !== undefined) {
                    if (empResponse) { name = empResponse.firstName + " " + empResponse.lastName; employeeId = empResponse.employeeId } else { name = ""; employeeId = ""; }

                    //employee Sbu equal to sbu id
                    if (sBUId === empResponse.param_sbu_id) {

                        let role = "";
                        let roleTeamType = "";
                        var roleResponse = await DataStore.query(Roles, employeeResponse[i].role_id);
                        if (roleResponse) {
                            role = roleResponse.name;
                            roleTeamType = roleResponse.teamType;
                        } else { role = ""; roleTeamType = ""; }

                        let sbu = "";
                        var sbuResponse = await DataStore.query(SBU, empResponse.param_sbu_id);
                        if (sbuResponse) { sbu = sbuResponse.name } else { sbu = ""; }

                        let reportingManagerId = employeeResponse[i].report_manager_employee_id
                        let RMemployeeId = employeeResponse[i].employee_id
                        let reportingManager = "";
                        var parentResponse;
                        if (reportingManagerId === RMemployeeId) {
                            let employeeResponse = await DataStore.query(Employee, RMemployeeId);
                            parentResponse = await DataStore.query(Employee, employeeResponse.parentEmployeeId);
                        } else {
                            parentResponse = await DataStore.query(Employee, employeeResponse[i].report_manager_employee_id);
                        }
                        if (parentResponse) { reportingManager = parentResponse.firstName + " " + parentResponse.lastName; } else { reportingManager = ""; }

                        var TargetDetailResponse = await DataStore.query(TargetDetails, (c) => c.target_id.eq(employeeResponse[i].id));
                        let checkIsPlanSubmitted = TargetDetailResponse.every((item) => item.isMarkAsComplete_AssignTarget)
                        let checkIsApproved = TargetDetailResponse.every((item) => item.is_approve)

                        let planSubmitted = checkIsPlanSubmitted ? "Yes" : "No"
                        let targetApproved = checkIsApproved ? "Yes" : "No"

                        let checkYetToStart = TargetDetailResponse.every((item) => !item.is_accepted_by_employee)
                        let yetToStart = checkYetToStart ? "Yes" : "No"

                        let checkPlanInDraft = TargetDetailResponse.every((item) => item.is_accepted_by_employee && item.isMarkAsComplete)
                        let planInDraft = checkPlanInDraft ? "Yes" : "No"

                        //PlanInit
                        let isPlanInitiatedForEmployee = 0
                        if (isPlanInitiated) {
                            var checkIsPlanInit = await DataStore.query(InitiatePlanning, (c) => c.employee_id.eq(employeeResponse[i].employee_id))
                            if (checkIsPlanInit) { isPlanInitiatedForEmployee = checkIsPlanInit.length ? 1 : 0 }
                        } else {
                            isPlanInitiatedForEmployee = 0
                        }

                        let data = {
                            "id": employeeResponse[i].id,
                            "financial_year_id": employeeResponse[i].financial_year_id,
                            "employee_id": employeeResponse[i].employee_id,
                            "report_manager_employee_id": employeeResponse[i].report_manager_employee_id,
                            "role_id": employeeResponse[i].role_id,
                            "isAddedByHRAdmin": employeeResponse[i].isAddedByHRAdmin,
                            "isSubmittedByEmployee": employeeResponse[i].isSubmittedByEmployee,
                            "isAssignedToEmployee": employeeResponse[i].isAssignedToEmployee,
                            "createdAt": employeeResponse[i].createdAt,
                            "updatedAt": employeeResponse[i].updatedAt,
                            "name": name,
                            "employeeId": employeeId,
                            "role": role,
                            "roleTeamType": roleTeamType,
                            "sbu": sbu,
                            "vertical": "Distribution",
                            "reportingManager": reportingManager,
                            "targetAssigned": employeeResponse[i].isAssignedToEmployee ? "Yes" : "No",
                            "planSubmitted": planSubmitted,
                            "targetApproved": targetApproved,
                            "yetToStart": yetToStart,
                            "planInDraft": planInDraft,
                            "isPlanInitiatedForEmployee": isPlanInitiatedForEmployee
                        }
                        newResponse.push(data)
                    }
                }
            }
            else {

                let employeeId = ""; let name = "";
                var empResponse = await DataStore.query(Employee, employeeResponse[i].employee_id);
                if (empResponse !== undefined) {

                    if (empResponse) { name = empResponse.firstName + " " + empResponse.lastName; employeeId = empResponse.employeeId } else { name = ""; employeeId = ""; }
                    let role = "";
                    let roleTeamType = "";
                    var roleResponse = await DataStore.query(Roles, employeeResponse[i].role_id);
                    if (roleResponse) {
                        role = roleResponse.name;
                        roleTeamType = roleResponse.teamType;
                    } else { role = ""; roleTeamType = ""; }

                    let sbu = "";
                    var sbuResponse = await DataStore.query(SBU, empResponse.param_sbu_id);
                    if (sbuResponse) { sbu = sbuResponse.name } else { sbu = ""; }


                    let reportingManagerId = employeeResponse[i].report_manager_employee_id
                    let RMemployeeId = employeeResponse[i].employee_id
                    let reportingManager = "";
                    var parentResponse;
                    if (reportingManagerId === RMemployeeId) {
                        let employeeResponse = await DataStore.query(Employee, RMemployeeId);
                        parentResponse = await DataStore.query(Employee, employeeResponse.parentEmployeeId);
                    } else {
                        parentResponse = await DataStore.query(Employee, employeeResponse[i].report_manager_employee_id);
                    }
                    if (parentResponse) { reportingManager = parentResponse.firstName + " " + parentResponse.lastName; } else { reportingManager = ""; }

                    var TargetDetailResponse = await DataStore.query(TargetDetails, (c) => c.target_id.eq(employeeResponse[i].id));
                    let checkIsPlanSubmitted = TargetDetailResponse.every((item) => item.isMarkAsComplete_AssignTarget)
                    let checkIsApproved = TargetDetailResponse.every((item) => item.is_approve)

                    let planSubmitted = checkIsPlanSubmitted ? "Yes" : "No"
                    let targetApproved = checkIsApproved ? "Yes" : "No"

                    let checkYetToStart = TargetDetailResponse.every((item) => !item.is_accepted_by_employee)
                    let yetToStart = checkYetToStart ? "Yes" : "No"

                    let checkPlanInDraft = TargetDetailResponse.every((item) => item.is_accepted_by_employee && item.isMarkAsComplete)
                    let planInDraft = checkPlanInDraft ? "Yes" : "No"

                    //PlanInit
                    let isPlanInitiatedForEmployee = 0
                    if (isPlanInitiated) {
                        var checkIsPlanInit = await DataStore.query(InitiatePlanning, (c) => c.employee_id.eq(employeeResponse[i].employee_id))
                        if (checkIsPlanInit) { isPlanInitiatedForEmployee = checkIsPlanInit.length ? 1 : 0 }
                    } else {
                        isPlanInitiatedForEmployee = 0
                    }

                    let data = {
                        "id": employeeResponse[i].id,
                        "financial_year_id": employeeResponse[i].financial_year_id,
                        "employee_id": employeeResponse[i].employee_id,
                        "report_manager_employee_id": employeeResponse[i].report_manager_employee_id,
                        "role_id": employeeResponse[i].role_id,
                        "isAddedByHRAdmin": employeeResponse[i].isAddedByHRAdmin,
                        "isSubmittedByEmployee": employeeResponse[i].isSubmittedByEmployee,
                        "isAssignedToEmployee": employeeResponse[i].isAssignedToEmployee,
                        "createdAt": employeeResponse[i].createdAt,
                        "updatedAt": employeeResponse[i].updatedAt,
                        "name": name,
                        "employeeId": employeeId,
                        "role": role,
                        "roleTeamType": roleTeamType,
                        "sbu": sbu,
                        "vertical": "Distribution",
                        "reportingManager": reportingManager,
                        "targetAssigned": "Yes",
                        "planSubmitted": planSubmitted,
                        "targetApproved": targetApproved,
                        "yetToStart": yetToStart,
                        "planInDraft": planInDraft,
                        "isPlanInitiatedForEmployee": isPlanInitiatedForEmployee
                    }
                    newResponse.push(data)
                }
            }
        }
    }
    else {
        newResponse = [];
    }

    return newResponse
}

function getMonthName(monthNumber) {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
        return monthNames[monthNumber - 1];
    } else {
        return "Invalid month number. Please provide a number between 1 and 12.";
    }
}

//*Detail View Filter
const detailViewFilter = async (employeeId, parameterId, FinancialYearId, selectedMonth) => {

    //set local variables
    let filterFinancialYearId = ""
    let filterEmployeeId = ""
    let filterParameterId = ""
    var newResponse = [];

    //get logged EmployeeId and Financial Year
    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
    let selectedYear = reactLocalStorage.getObject('selectedYear');

    //Empty Check
    if (employeeId === undefined) { filterEmployeeId = loggedEmployeeId } else { filterEmployeeId = employeeId }
    if (FinancialYearId === undefined) { filterFinancialYearId = selectedYear.value } else { filterFinancialYearId = FinancialYearId }
    if (parameterId === undefined || parameterId === 0) { filterParameterId = "" } else { filterParameterId = parameterId }

    //get target response
    // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
    //     c.employee_id.eq(filterEmployeeId),
    //     c.financial_year_id.eq(filterFinancialYearId),
    //     c.report_manager_employee_id.notContains(filterEmployeeId)
    // ]));

    // var targetsResponses = await graphQLGetAllData("listTargets", [{ employee_id: { eq: filterEmployeeId }, financial_year_id: { eq: filterFinancialYearId }, report_manager_employee_id: { notContains: filterEmployeeId } }])
    var targetsResponses = await graphQLGetAllData("listTargets", [{ employee_id: { eq: filterEmployeeId }, financial_year_id: { eq: filterFinancialYearId } }])

    var targetDetails = '';

    if (targetsResponses.length > 0) {
        // targetDetails = await DataStore.query(TargetDetails, (c) => c.target_id.eq(targetsResponses[0].id))
        targetDetails = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: targetsResponses[0].id } }])
        let finalData = [];
        let actualsDetails = [];
        for (let i = 0; i < targetDetails.length; i++) {

            if (selectedMonth !== "" && parameterId !== "" && selectedMonth !== undefined && parameterId !== undefined) {
                // actualsDetails = await DataStore.query(ActualDetails, (c) => c.and(c => [
                //     c.target_details_id.eq(targetDetails[i].id),
                //     c.monthNo.eq(selectedMonth),
                //     c.parameter_id.eq(parameterId)
                // ]))

                actualsDetails = await graphQLGetAllData("listActualDetails", [{ target_details_id: { eq: targetDetails[i].id }, monthNo: { eq: selectedMonth }, parameter_id: { eq: parameterId } }])

            }
            else if (parameterId !== "" && parameterId !== undefined) {

                if (parameterId === "All") {
                    // actualsDetails = await DataStore.query(ActualDetails, (c) => c.and(c => [
                    //     c.target_details_id.eq(targetDetails[i].id),
                    // ]))
                    actualsDetails = await graphQLGetAllData("listActualDetails", [{ target_details_id: { eq: targetDetails[i].id } }])
                } else {
                    // actualsDetails = await DataStore.query(ActualDetails, (c) => c.and(c => [
                    //     c.target_details_id.eq(targetDetails[i].id),
                    //     c.parameter_id.eq(parameterId)
                    // ]))
                    actualsDetails = await graphQLGetAllData("listActualDetails", [{ target_details_id: { eq: targetDetails[i].id }, parameter_id: { eq: parameterId } }])
                }
            }
            else if (selectedMonth !== "" && selectedMonth !== undefined) {
                if (selectedMonth === "All") {
                    // actualsDetails = await DataStore.query(ActualDetails, (c) => c.and(c => [
                    //     c.target_details_id.eq(targetDetails[i].id),
                    // ]))
                    actualsDetails = await graphQLGetAllData("listActualDetails", [{ target_details_id: { eq: targetDetails[i].id } }])
                } else {
                    // actualsDetails = await DataStore.query(ActualDetails, (c) => c.and(c => [
                    //     c.target_details_id.eq(targetDetails[i].id),
                    //     c.monthNo.eq(selectedMonth)
                    // ]))
                    actualsDetails = await graphQLGetAllData("listActualDetails", [{ target_details_id: { eq: targetDetails[i].id }, monthNo: { eq: selectedMonth } }])
                }
            }

            if (actualsDetails.length > 0) {
                let final_ActualData = []
                for (let j = 0; j < actualsDetails.length; j++) {
                    let CountryName = ""
                    // let countryRes = await DataStore.query(Country, (c) => c.REF_CODE.eq(actualsDetails[j].country))
                    let countryRes = await graphQLGetAllData("listCountries", [{ REF_CODE: { eq: actualsDetails[j].country } }])

                    if (countryRes) { CountryName = countryRes[0].name }
                    let data = {
                        "id": actualsDetails[j].id,
                        "buName": actualsDetails[j].BU,
                        "regionName": actualsDetails[j].region,
                        "countryName": CountryName, "brandName": actualsDetails[j].brand,
                        "pGName": actualsDetails[j].productGroup,
                        "custCatName": actualsDetails[j].customerGroupCode, "customerName": actualsDetails[j].customerGroupCode,
                        "target_Actual_value": actualsDetails[j].actual_value,
                        // "month": actualsDetails[j].monthNo,
                        "month": getMonthName(actualsDetails[j].monthNo),
                        "parameterName": parameterId
                    }
                    final_ActualData.push(data)
                    if (j === actualsDetails.length - 1) {
                        finalData.push(final_ActualData)
                    }
                }
            }
            if (i === targetDetails.length - 1) {
                newResponse = convertArrayToObject(finalData)
                return newResponse;
            }
        }
    } else {
        newResponse = [];
        return newResponse;
    }
}

//*summary Filter
const summaryFilter = async (employeeId, FinancialYearId, quarterValue) => {


    //set local variables
    var newResponse = [];
    let sortArray;
    let filterFinancialYearId = ""
    let filterEmployeeId = ""

    //get logged EmployeeId and Financial Year
    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
    let selectedYear = reactLocalStorage.getObject('selectedYear');

    if (employeeId === undefined || employeeId === 0) { filterEmployeeId = loggedEmployeeId } else { filterEmployeeId = employeeId }
    if (FinancialYearId === undefined) { filterFinancialYearId = selectedYear.value } else { filterFinancialYearId = FinancialYearId }


    //get FY
    var FYResponse = await DataStore.query(FinancialYear, filterFinancialYearId);

    // //get parameters
    var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
        c.employee_id.eq(filterEmployeeId),
        c.report_manager_employee_id.notContains(filterEmployeeId),
        c.financial_year_id.eq(filterFinancialYearId)
    ]));

    if (targetsResponses.length > 0) {

        //get parameters weightage
        var relationshipResponse = await DataStore.query(Relationship, (c) => c.and(c => [
            c.role_id.eq(targetsResponses[0].role_id),
            c.financial_year_id.eq(filterFinancialYearId)
        ]));


        var relationshipDetailResponses = []
        if (relationshipResponse.length > 0) {
            relationshipDetailResponses = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationshipResponse[0].id));
        }

        //Target Details
        var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
            c.target_id.eq(targetsResponses[0].id),
        ]));


        for (let i = 0; i < currentTargetDetails.length; i++) {

            let status = ""
            if (currentTargetDetails[i].is_accepted_by_employee && !currentTargetDetails[i].is_approve) { status = "Awaiting approval" }
            else if (currentTargetDetails[i].isSendForRevisionByReportingManage) { status = "Revision" }
            else if (currentTargetDetails[i].is_approve) { status = "Approved" }
            else if (currentTargetDetails[i].is_revision_by_employee) { status = "Requested for Revision" }
            else { status = "Open" }

            //get parameters details
            var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(currentTargetDetails[i].parameter_id));

            var getWeightage = relationshipDetailResponses.filter((e) => e.parameter_id === currentTargetDetails[i].parameter_id)

            const totalWeightage = await calculateConsolidatedScore(currentTargetDetails[i].target_value, currentTargetDetails[i].target_value)

            const actualAch = await calculateConsolidatedScore(currentTargetDetails[i].target_value, currentTargetDetails[i].target_value)

            var weightageForWtAch = getWeightage.length > 0 ? getWeightage[0].weightage : 1;

            const wtAch = await calculateConsolidatedScore(actualAch, weightageForWtAch)

            let targetValue = "";
            if (quarterValue === "All") {
                let total = currentTargetDetails[i].is_revision_by_employee ? currentTargetDetails[i].revision_value : parameterResponses[0].type === PARAMETERS_TYPES.Days ? Math.floor(currentTargetDetails[i].q1_target_value) + Math.floor(currentTargetDetails[i].q2_target_value) + Math.floor(currentTargetDetails[i].q3_target_value) + Math.floor(currentTargetDetails[i].q4_target_value) : parameterResponses[0].type === PARAMETERS_TYPES.Amount ? ShowInMillion(parseInt(currentTargetDetails[i].q1_target_value) + parseInt(currentTargetDetails[i].q2_target_value) + parseInt(currentTargetDetails[i].q3_target_value) + parseInt(currentTargetDetails[i].q4_target_value)) : parseInt(currentTargetDetails[i].q1_target_value) + parseInt(currentTargetDetails[i].q2_target_value) + parseInt(currentTargetDetails[i].q3_target_value) + parseInt(currentTargetDetails[i].q4_target_value)
                targetValue = total
            }
            else if (quarterValue === "Q1") {
                targetValue = currentTargetDetails[i].is_revision_by_employee ? currentTargetDetails[i].revision_value : parameterResponses[0].type === PARAMETERS_TYPES.Days ? Math.floor(currentTargetDetails[i].q1_target_value) : parameterResponses[0].type === PARAMETERS_TYPES.Amount ? ShowInMillion(parseInt(currentTargetDetails[i].q1_target_value)) : parseInt(currentTargetDetails[i].q1_target_value)
            }
            else if (quarterValue === "Q2") {
                let total = currentTargetDetails[i].is_revision_by_employee ? currentTargetDetails[i].revision_value : parameterResponses[0].type === PARAMETERS_TYPES.Days ? Math.floor(currentTargetDetails[i].q1_target_value) + Math.floor(currentTargetDetails[i].q2_target_value) : parameterResponses[0].type === PARAMETERS_TYPES.Amount ? ShowInMillion(parseInt(currentTargetDetails[i].q1_target_value) + parseInt(currentTargetDetails[i].q2_target_value)) : parseInt(currentTargetDetails[i].q1_target_value) + parseInt(currentTargetDetails[i].q2_target_value)
                targetValue = total
            }
            else if (quarterValue === "Q3") {
                let total = currentTargetDetails[i].is_revision_by_employee ? currentTargetDetails[i].revision_value : parameterResponses[0].type === PARAMETERS_TYPES.Days ? Math.floor(currentTargetDetails[i].q1_target_value) + Math.floor(currentTargetDetails[i].q2_target_value) + Math.floor(currentTargetDetails[i].q3_target_value) : parameterResponses[0].type === PARAMETERS_TYPES.Amount ? ShowInMillion(parseInt(currentTargetDetails[i].q1_target_value) + parseInt(currentTargetDetails[i].q2_target_value) + parseInt(currentTargetDetails[i].q3_target_value)) : parseInt(currentTargetDetails[i].q1_target_value) + parseInt(currentTargetDetails[i].q2_target_value) + parseInt(currentTargetDetails[i].q3_target_value)
                targetValue = total
            }
            else if (quarterValue === "Q4") {
                let total = currentTargetDetails[i].is_revision_by_employee ? currentTargetDetails[i].revision_value : parameterResponses[0].type === PARAMETERS_TYPES.Days ? Math.floor(currentTargetDetails[i].q1_target_value) + Math.floor(currentTargetDetails[i].q2_target_value) + Math.floor(currentTargetDetails[i].q3_target_value) + Math.floor(currentTargetDetails[i].q4_target_value) : parameterResponses[0].type === PARAMETERS_TYPES.Amount ? ShowInMillion(parseInt(currentTargetDetails[i].q1_target_value) + parseInt(currentTargetDetails[i].q2_target_value) + parseInt(currentTargetDetails[i].q3_target_value) + parseInt(currentTargetDetails[i].q4_target_value)) : parseInt(currentTargetDetails[i].q1_target_value) + parseInt(currentTargetDetails[i].q2_target_value) + parseInt(currentTargetDetails[i].q3_target_value) + parseInt(currentTargetDetails[i].q4_target_value)
                targetValue = total
            }
            else {
                targetValue = currentTargetDetails[i].is_revision_by_employee ? currentTargetDetails[i].revision_value : parameterResponses[0].type === PARAMETERS_TYPES.Days ? Math.floor(currentTargetDetails[i].target_value) : parameterResponses[0].type === PARAMETERS_TYPES.Amount ? ShowInMillion(parseInt(currentTargetDetails[i].target_value)) : parseInt(currentTargetDetails[i].target_value)
            }

            if (targetValue === "NaN") {
                targetValue = 0
            }
            else if (targetValue === "NaNM") {
                targetValue = 0
            }

            if (getWeightage.length > 0) {

                let data = {
                    "financial_year_id": FYResponse.value,
                    "financial_year": FYResponse.label,
                    "parameter_id": currentTargetDetails[i].parameter_id,
                    "parameter_name": parameterResponses[0].name,
                    "parameter_type": parameterResponses[0].type === "A" ? "$" : parameterResponses[0].type === "D" ? "Days" : parameterResponses[0].type === "P" ? "%" : parameterResponses[0].type === "N" ? "Number" : "",
                    "target_value": targetValue === null ? 0 : targetValue,
                    // "target_value": targetValue === null ? 0 : isNaN(targetValue) === true ? 0 : targetValue,
                    "isMarkAsComplete": currentTargetDetails[i].isMarkAsComplete,
                    "is_accepted_by_employee": currentTargetDetails[i].is_accepted_by_employee,
                    "is_approve": currentTargetDetails[i].is_approve,
                    "is_revision_by_employee": currentTargetDetails[i].is_revision_by_employee,
                    "isSendForRevisionByReportingManage": currentTargetDetails[i].isSendForRevisionByReportingManage,
                    "parameter_weightage": getWeightage.length > 0 ? getWeightage[0].weightage : "-",
                    "isPlanMandatory": getWeightage.length > 0 ? 1 : 0,
                    //"planning_period": moment(financialYearResponses[0].fromDate).format("Do MMM YY") + " - " + moment(financialYearResponses[0].toDate).format("Do MMM YY")
                    "status": status,
                    "q1_target_value": currentTargetDetails[i].q1_target_value === null ? "-" : currentTargetDetails[i].q1_target_value,
                    "q2_target_value": currentTargetDetails[i].q2_target_value === null ? "-" : currentTargetDetails[i].q2_target_value,
                    "q3_target_value": currentTargetDetails[i].q3_target_value === null ? "-" : currentTargetDetails[i].q3_target_value,
                    "q4_target_value": currentTargetDetails[i].q4_target_value === null ? "-" : currentTargetDetails[i].q4_target_value,
                    "q1_actual_value": currentTargetDetails[i].q1_target_value === null ? "-" : currentTargetDetails[i].q1_target_value,
                    "q2_actual_value": currentTargetDetails[i].q2_target_value === null ? "-" : currentTargetDetails[i].q2_target_value,
                    "q3_actual_value": currentTargetDetails[i].q3_target_value === null ? "-" : currentTargetDetails[i].q3_target_value,
                    "q4_actual_value": currentTargetDetails[i].q4_target_value === null ? "-" : currentTargetDetails[i].q4_target_value,
                    "totalWeightage": "50",
                    "actualAch": actualAch,
                    "wtAch": wtAch,
                }
                newResponse.push(data)
            }

            if (i === currentTargetDetails.length - 1) {
                sortArray = newResponse.sort((a, b) => (a.parameter_name > b.parameter_name ? 1 : -1))
            }
        }

    }
    else {
        sortArray = [];
    }
    return sortArray
}

//*drillDown Filter
const drillDownFilter = async (employeeId, FinancialYearId) => {
    //set local variables
    let filterFinancialYearId = ""
    let filterEmployeeId = ""

    //get logged EmployeeId and Financial Year
    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
    let selectedYear = reactLocalStorage.getObject('selectedYear');

    if (employeeId === undefined) { filterEmployeeId = loggedEmployeeId } else { filterEmployeeId = employeeId }
    if (FinancialYearId === undefined) { filterFinancialYearId = selectedYear.value } else { filterFinancialYearId = FinancialYearId }

    // //get parameters
    // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
    //     c.employee_id.eq(filterEmployeeId),
    //     c.report_manager_employee_id.notContains(filterEmployeeId),
    //     c.financial_year_id.eq(filterFinancialYearId)
    // ]));

    // var targetsResponses = await graphQLGetAllData("listTargets", [{ employee_id: { eq: filterEmployeeId }, report_manager_employee_id: { notContains: filterEmployeeId }, financial_year_id: { eq: filterFinancialYearId } }])
    var targetsResponses = await graphQLGetAllData("listTargets", [{ employee_id: { eq: filterEmployeeId }, financial_year_id: { eq: filterFinancialYearId } }])

    if (targetsResponses.length > 0) {
        //Target Details
        // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
        //     c.target_id.eq(targetsResponses[0].id),
        // ]));
        var currentTargetDetails = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: targetsResponses[0].id } }])
    }
    else {
        currentTargetDetails = [];
    }
    return currentTargetDetails
}

//*Evaluation Filter
const evaluationFilter = async (employeeId, quaterNo, FinancialYearId, sBUId) => {

    //set local variables
    let filterFinancialYearId = ""
    let filterEmployeeId = ""
    let finalResponse = [];

    //get logged EmployeeId and Financial Year
    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
    let selectedYear = reactLocalStorage.getObject('selectedYear');

    if (employeeId === undefined || employeeId === 0) { filterEmployeeId = loggedEmployeeId } else { filterEmployeeId = employeeId }
    if (FinancialYearId === undefined) { filterFinancialYearId = selectedYear.value } else { filterFinancialYearId = FinancialYearId }


    var newResponse = [];
    // var checkEvaluationYearly = await DataStore.query(Evaluation, (c) => c.financial_year_id.eq(filterFinancialYearId));
    var checkEvaluationYearly = await DataStore.query(Evaluation, (c) => c.and(c => [
        c.financial_year_id.eq(filterFinancialYearId),
        c.quaterNo.eq(quaterNo)
    ]));

    if (checkEvaluationYearly.length) {

        let employeeResponse = []
        // var isParentEmployee = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(filterEmployeeId));
        // if (isParentEmployee.length) {
        employeeResponse = await DataStore.query(Employee, (c) => c.or(c => [
            c.parentEmployeeId.eq(filterEmployeeId),
            c.id.eq(filterEmployeeId)
        ]));
        // } else {
        //     employeeResponse = await DataStore.query(Employee, (c) => c.id.eq(filterEmployeeId));
        // }

        if (employeeResponse.length) {

            for (let i = 0; i < employeeResponse.length; i++) {

                let index = parseInt(i) + 1
                let name = employeeResponse[i].firstName + " " + employeeResponse[i].lastName
                let employeeId = employeeResponse[i].employeeId

                //Parent Employee List
                let reportingManager = ""
                var parentResponse = await DataStore.query(Employee, (c) => c.id.eq(employeeResponse[i].parentEmployeeId));
                if (parentResponse.length > 0) {
                    reportingManager = parentResponse[0].firstName + " " + parentResponse[0].lastName;
                } else { reportingManager = "" }

                //*get role
                var roleResponse = await DataStore.query(Roles, employeeResponse[i].role_id);
                let teamType = roleResponse.teamType;

                let initiated;
                var initiateEvaluationResponses = await DataStore.query(InitiateEvaluation, (c) => c.and(c => [
                    c.employee_id.eq(employeeResponse[i].id),
                    c.evaluation_id.eq(checkEvaluationYearly[0].id)
                ]));
                if (initiateEvaluationResponses.length > 0) { initiated = "Yes"; } else { initiated = "No"; }

                let eligible = "Yes";
                let totalWeightage = "100%";
                let wtWeightage = "80%";
                let status = "Completed";

                newResponse.push({
                    "id": employeeResponse[i].id, "role_id": employeeResponse[i].role_id, "firstName": employeeResponse[i].firstName, "lastName": employeeResponse[i].lastName,
                    "email": employeeResponse[i].email, "parentEmployeeId": employeeResponse[i].parentEmployeeId, "address1": employeeResponse[i].address1, "address2": employeeResponse[i].address2, "phoneNo": employeeResponse[i].phoneNo, "city": employeeResponse[i].city,
                    "zip": employeeResponse[i].zip, "state": employeeResponse[i].state, "country": employeeResponse[i].country, "employeeId": employeeResponse[i].employeeId, "profilePic": employeeResponse[i].profilePic, "isAssignDirectTarget": employeeResponse[i].isAssignDirectTarget, "param_sbu_id": employeeResponse[i].param_sbu_id, "IsHRAccess": false,
                    "IsFinanceAccess": false, "index": index, "name": name, "employeeId": employeeId, "reportingManager": reportingManager, "eligible": eligible, "initiated": initiated, "totalWeightage": totalWeightage, "wtWeightage": wtWeightage, "status": status, "teamType": teamType
                });


                if (i === employeeResponse.length - 1) {
                    //Sbu Filter
                    if (sBUId !== undefined && sBUId !== 0) {
                        let sbuFilterResponse = newResponse.filter((item, index) => item.param_sbu_id === sBUId)
                        finalResponse = sbuFilterResponse
                    } else {
                        finalResponse = newResponse
                    }
                }
            }
        }
    }
    else {
        finalResponse = newResponse
    }

    return finalResponse

}

export { planningFilter, detailViewFilter, summaryFilter, drillDownFilter, evaluationFilter }
