import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Auth } from 'aws-amplify';
import moment from "moment";
import "primeicons/primeicons.css";
import { Calendar } from 'primereact/calendar';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import { reactLocalStorage } from 'reactjs-localstorage';
import imageDeployedLarge from '../../../assets/images/deployedImg.png';
import evaluationImage from '../../../assets/images/evaluation1.png';
import popupEvaluationHeader from '../../../assets/images/Evaluation_header_img.png';
import imageDeployed from '../../../assets/svg/check_icon.svg';
import LeftSideBar from '../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../components/common/hr/topnav.component';
import NoDataFound from '../../../components/common/nodatafound';
import { ConvertResponseForSelect } from '../../../components/helper/commonfunctions';
import { EVALUATION_STATUS } from '../../../components/helper/enum';
import { EvaluationStatus } from '../../../components/helper/getEvaluationStatus';
import { ValidateHRRole } from '../../../components/helper/validateRole';
import { Evaluation, FinancialYear } from '../../../models';
import HRLogin from '../../../setup/auth/hr.component';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries'
import * as mutations from '../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../src/components/helper/graphQLFunctions'


export default function HrEvaluation() {

    let isFinanceAccess = reactLocalStorage.get('IsFinanceAccess')

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    const [isShowCreatePopUp, setShowCreatePopUp] = useState(0);

    // variables for data set
    const [financialYear, setFinancialYear] = useState("");
    const [quarter, setQuarter] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [financialYearList, setFinancialYearList] = useState("");
    const [allFinancialYearList, setAllFinancialYearList] = useState("");

    const [evaluations, setEvaluations] = useState([]);

    const [evaluationId, setEvaluationId] = useState("");
    const [isDisableMode, setDisableMode] = useState(false);
    const [isUpdateMode, setUpdateMode] = useState(false);
    const [isDynamic, setIsDynamic] = useState(false);
    const [dynamicQuarterList, setDynamicQuarterList] = useState([]);

    var quarterList = [
        { label: "Q1", value: "1" },
        { label: "Q2", value: "2" },
        { label: "Q3", value: "3" },
        { label: "Q4", value: "4" },
    ]

    //deployed
    const [isViewDeployedPopUp, setViewDeployedPopUp] = useState(0);

    async function BindFinancialYearList() {
        //set financial year
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        // if (Object.keys(selectedYear).length !== 0) {
        //     setFinancialYear(selectedYear)
        //     BindList(selectedYear)
        // }
        // else { }
        // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
        var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);
        setFinancialYear({ label: responses[0].name, value: responses[0].id })
        BindList({ label: responses[0].name, value: responses[0].id })


        //set Financial Year
        // const financialYearList = await DataStore.query(FinancialYear);
        const financialYearList = await graphQLGetAllData('listFinancialYears');
        let allFinalYearData = [];
        for (var i = 0; i < financialYearList.length; i++) {
            let data = {
                "id": financialYearList[i].id,
                "fromDate": financialYearList[i].fromDate,
                "toDate": financialYearList[i].toDate,
                "name": financialYearList[i].name,
                "FinancialYearStart": moment(financialYearList[i].toDate).format('YYYY'),
            }
            allFinalYearData.push(data)

            if (i === financialYearList.length - 1) {
                let sortedArray = allFinalYearData.sort(function (a, b) { return a.FinancialYearStart - b.FinancialYearStart });
                var myArray = []; myArray = ConvertResponseForSelect(sortedArray);
                var myArrayForAllYears = []; myArrayForAllYears = ConvertResponseForSelect(sortedArray);
                setFinancialYearList(myArray);
                setAllFinancialYearList(myArrayForAllYears);
            }
        }
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    const BindList = async (selectedYear) => {
        let requestData = []
        // var EvaluationResponses = await DataStore.query(Evaluation, (c) => c.financial_year_id.eq(selectedYear.value));

        var EvaluationResponses = []
        if (selectedYear) {
            try {
                EvaluationResponses = await graphQLGetAllData('listEvaluations', [{ financial_year_id: { eq: selectedYear.value } }]);
            } catch (error) {
                console.log('error', error);
            }

        }

        if (EvaluationResponses.length > 0) {
            for (let i = 0; i < EvaluationResponses.length; i++) {
                // var getYearDetails = await DataStore.query(FinancialYear, EvaluationResponses[i].financial_year_id);
                var getYearDetails = await graphQLFindRecordById('getFinancialYear', EvaluationResponses[i].financial_year_id);

                let getStatus = EvaluationStatus(moment(EvaluationResponses[i].startDate).format("YYYY-MM-DD"), moment(EvaluationResponses[i].endDate).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))
                try {
                    requestData.push({
                        id: EvaluationResponses[i].id,
                        quaterNo: EvaluationResponses[i].quaterNo,
                        startDate: EvaluationResponses[i].startDate,
                        endDate: EvaluationResponses[i].endDate,
                        financialYearName: getYearDetails.name,
                        financialYearId: getYearDetails.id,
                        updatedDate: moment(EvaluationResponses[i].updatedAt).format("DD MMM, YYYY"),
                        status: getStatus,
                    })

                    if (i === EvaluationResponses.length - 1) {
                        let sortedArray = requestData.sort(function (a, b) { return b.quaterNo - a.quaterNo; });
                        setEvaluations(sortedArray);
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        } else {
            setEvaluations([]);
        }

    }

    const saveEvaluation = async () => {
        if (financialYear === "") { toast.error("Please select financial year."); return; }
        else if (quarter === "") { toast.error("Please select quarter."); return; }
        else if (startDate === "") { toast.error("Please select start date."); return; }
        else if (endDate === "") { toast.error("Please select end date."); return; }

        if (isUpdateMode) {
            // var selectedEvaluationObj = await DataStore.query(Evaluation, evaluationId);
            var selectedEvaluationObj = await graphQLFindRecordById('getEvaluation', evaluationId);

            // await DataStore.save(
            //     Evaluation.copyOf(selectedEvaluationObj, (updated) => {
            //         updated.financial_year_id = financialYear.value;
            //         updated.quaterNo = quarter.value;
            //         updated.startDate = moment(startDate).format('YYYY-MM-DD');
            //         updated.endDate = moment(endDate).format('YYYY-MM-DD');
            //     })
            // );
            await API.graphql({
                query: mutations.updateEvaluation,
                variables: {
                    input: {
                        id: selectedEvaluationObj.id,
                        financial_year_id: financialYear.value,
                        quaterNo: quarter.value,
                        startDate: moment(startDate).format('YYYY-MM-DD'),
                        endDate: moment(endDate).format('YYYY-MM-DD'),
                        _version: selectedEvaluationObj._version,
                    }
                }
            });
            // toast.success("updated");
        }
        else {
            // const checkIfPlanningExits = await DataStore.query(Evaluation, (c) => c.and(c => [
            //     c.financial_year_id.eq(financialYear.value),
            //     c.quaterNo.eq(quarter.value)
            // ]));
            const checkIfPlanningExits = await graphQLGetAllData('listEvaluations', [
                { financial_year_id: { eq: financialYear.value } },
                { quaterNo: { eq: quarter.value } }
            ]);


            if (checkIfPlanningExits.length > 0) {
                toast.error("Evaluation is already initialized for the selected year and quarter.");
                return true
            }
            else {
                // await DataStore.save(
                //     new Evaluation({
                //         "financial_year_id": financialYear.value,
                //         "quaterNo": quarter.value,
                //         "startDate": moment(startDate).format('YYYY-MM-DD'),
                //         "endDate": moment(endDate).format('YYYY-MM-DD')
                //     })
                // );
                await API.graphql(
                    graphqlOperation(mutations.createEvaluation, {
                        input: {
                            financial_year_id: financialYear.value,
                            quaterNo: quarter.value,
                            startDate: moment(startDate).format('YYYY-MM-DD'),
                            endDate: moment(endDate).format('YYYY-MM-DD')
                        }
                    })
                );
                //  toast.success("Saved");
            }
        }

        BindList();
        setShowCreatePopUp(0);
        setViewDeployedPopUp(1);

    }
    const viewEditEvaluation = async (type, id) => {
        setEvaluationId(id);

        // var selectedEvaluationDetails = await DataStore.query(Evaluation, id);
        var selectedEvaluationDetails = await graphQLFindRecordById('getEvaluation', id);

        // var financialYearDetails = await DataStore.query(FinancialYear, selectedEvaluationDetails.financial_year_id);
        var financialYearDetails = await graphQLFindRecordById('getFinancialYear', selectedEvaluationDetails.financial_year_id);
        setShowCreatePopUp(1)
        if (type === "v") { setDisableMode(true); } else { setDisableMode(false); setUpdateMode(true) }
        setFinancialYear({ label: financialYearDetails.name, value: selectedEvaluationDetails.financial_year_id })

        quarterList.map((value) => {
            if (value.value === selectedEvaluationDetails.quaterNo) {
                setQuarter({ label: value.label, value: value.value })
            }
        })

        setStartDate(moment(selectedEvaluationDetails.startDate).toDate())
        setEndDate(moment(selectedEvaluationDetails.endDate).toDate())
    }
    const checkQuarter = async (e) => {
        setFinancialYear(e)
        // let evaluationResponse = await DataStore.query(Evaluation, (c) => c.financial_year_id.eq(e.value));
        let evaluationResponse = await graphQLGetAllData('listEvaluations', [{ financial_year_id: { eq: e.value } }]);

        if (evaluationResponse.length > 0) {
            setIsDynamic(true)
            let evaluationIds = evaluationResponse.map((response) => response.quaterNo)
            let quaterIds = quarterList.map((response) => response.value)
            //compare two arrays
            let differenceArray = quaterIds.filter(x => !evaluationIds.includes(x)).concat(evaluationIds.filter(x => !quaterIds.includes(x)));
            let data = []
            for (let i = 0; i < differenceArray.length; i++) {
                const quarterArray = [...quarterList];
                quarterArray.filter((filterValue) => {
                    if (filterValue.value === differenceArray[i]) {
                        data.push(filterValue)
                    }
                })
            }
            setDynamicQuarterList(data)
        }
        else {
            setIsDynamic(false)
        }
    }

    useEffect(() => {
        onLoad();
        BindFinancialYearList();
        BindList();
    }, [isShowCreatePopUp]);

    return (
        isAuthenticated ? (
            <div className="pt-16">
                <TopNavBar />
                <LeftSideBar />

                <div className="evalution-main-wrapper planning-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Goals Evaluation</div>
                            {/* <div className="tabs_one mt-5 sm:mt-0">
                            <ul className="flex">
                                <li><Link className="active">Parameters</Link></li>
                                <li><Link >Planning</Link></li>
                                <li><Link >Evaluation</Link></li>
                            </ul>
                        </div> */}
                        </div>
                        <div className="lg:flex justify-between my-6 items-center">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/hr/evaluation" className="active">Initiate Evaluation</Link></li>
                                    <li><Link to="/hr/evaluation/nwcdaysactuals">NWC Days Actuals</Link></li>
                                    {
                                        isFinanceAccess == 1 &&
                                        <li>
                                            <li><Link to="/hr/evaluation/companysbu">Company & SBU Actuals</Link></li>
                                            <li><Link to="/hr/evaluation/ebitpbt">EBIT/PBT/Bad Debts Actuals</Link></li>
                                        </li>
                                    }
                                </ul>
                            </div>

                            <div className="flex">
                                <div className="mr-5">
                                    <Select
                                        options={allFinancialYearList}
                                        value={financialYear}
                                        onChange={e => {
                                            BindList(e)
                                            setFinancialYear(e);
                                            reactLocalStorage.setObject('selectedYear', e);
                                        }}
                                        maxMenuHeight={200} placeholder="Select Year"
                                        className="my-react-select-container"
                                        classNamePrefix="my-react-select"
                                    />
                                </div>
                                <div className="mt-5 lg:mt-0">
                                    <Link onClick={() => {
                                        setDisableMode(false);
                                        setShowCreatePopUp(1)
                                        setUpdateMode(false)
                                        setFinancialYear("");
                                        setQuarter("");
                                        setStartDate("");
                                        setEndDate("");
                                    }} className="bg-[#029046] text-white rounded-md px-4 py-2.5 lg:py-2.5 text-sm lg:text-md shadow-md inline-block addclassName" >Creating New Evaluation Period</Link>
                                </div>
                            </div>
                        </div>
                        {
                            evaluations.length > 0 ?
                                <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                                    {
                                        evaluations.map((item) => {
                                            return (
                                                <div className="bg-white dark:bg-[#2A2E32] py-4 px-3 rounded-xl dashbord_box_shape" style={{ boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.1)" }}>
                                                    <div className="flex justify-between">
                                                        <div className="px-4">
                                                            <div className="text-xl font-semibold mb-2 dark:text-white">Quarter {item.quaterNo}</div>
                                                            <div className="text-[#667085] text-sm mb-0 dark:text-[#98A2B3]">Evaluation Period:</div>
                                                            <div className="text-[#667085] text-sm mb-2 dark:text-[#98A2B3]"> {moment(item.startDate).format("Do MMM, YYYY")} - {moment(item.endDate).format("Do MMM, YYYY")}</div>
                                                        </div>

                                                        <div className='flex justify-end'>
                                                            <img className='w-52' src={evaluationImage} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="sm:flex items-center justify-between px-2 mt-3">
                                                        <div className="box_btns">
                                                            <ul className="flex gap-2">
                                                                {
                                                                    EVALUATION_STATUS[2] !== item.status ?
                                                                        <li ><Link to={"/hr/evaluation/viewinitiateevaluation/" + item.financialYearId + "/" + item.quaterNo + "/" + item.id} className="addclassName text-[13px] text-[#344054] border-[#C6CBD2] dark:bg-[#44494E] dark:text-white dark:border-[#44494E]">
                                                                            <i className="icon eye_small_icon"></i>  View</Link></li> : ""
                                                                }

                                                                {
                                                                    EVALUATION_STATUS[1] === item.status || EVALUATION_STATUS[2] === item.status ?
                                                                        <li onClick={() => viewEditEvaluation("e", item.id)}><Link className="addclassName flex text-[13px] text-[#344054] border-[#C6CBD2] dark:text-[#fff]"> <i className="icon edit_small_icon"></i> Edit</Link></li>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    EVALUATION_STATUS[1] === item.status ?
                                                                        <label className=" text-sm px-2 py-1 border rounded-md border-[#1B55AF] text-[#1B55AF] dark:border-[green] dark:text-white dark:bg-[#029046]">{item.status}</label>
                                                                        :
                                                                        EVALUATION_STATUS[2] === item.status ?
                                                                            <label className=" text-sm px-2 py-1 border rounded-md border-[#FDB022] rounded-md text-[#FDB022]  dark:border-[green] dark:text-white dark:bg-[#029046]">{item.status}</label> :
                                                                            <label className=" text-sm px-2 py-1 border rounded-md border-[green] text-[green] dark:border-[green] dark:text-white dark:bg-[#029046]">{item.status}</label>
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="text-xs text-[#667085] text-end mt-3 md:mt-0">Last Updated on: {item.updatedDate}</div>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                </div> :
                                <NoDataFound />
                        }
                    </div>
                </div>

                <div id="selector" className={isShowCreatePopUp === 1 ? 'overlay' : ''}></div>
                {/* <!-- popup one --> */}
                {
                    isShowCreatePopUp == 1 ?
                        <div style={{ width: "44.271vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="editPopupCont" className="sidenav">
                            <div className="popup_header relative">
                                <img src={popupEvaluationHeader} alt="" />
                                <div className="absolute right-0 left-0 top-0 w-full text-white">
                                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                        <div className="text-md">{isUpdateMode ? "Edit" : "Create New"}</div>
                                        <div><Link onClick={() => setShowCreatePopUp(0)} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                    </div>
                                    <div className="px-6 mt-8">
                                        <div className="text-sm">Initiate Evaluation</div>
                                        <div className="2xl:text-[32px] xl:text-[28px] lg:xl:text-[20px] md:xl:text-[18px]">{isDisableMode ? "Record" : isUpdateMode ? "Edit Evaluation Period" : "Creating New Evaluation Period"}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-6">
                                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-4">

                                    <div>
                                        <label className="text-[#344054] text-[13px] font-medium">Financial Year</label>
                                        <Select
                                            isDisabled={isDisableMode}
                                            value={financialYear}
                                            options={financialYearList}
                                            onChange={e => {
                                                checkQuarter(e)
                                            }}
                                            className="mt-2 my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>

                                    <div>
                                        <label className="text-[#344054] text-[13px] font-medium">Quarter</label>
                                        <Select
                                            isDisabled={isDisableMode}
                                            value={quarter}
                                            options={isDynamic === true ? dynamicQuarterList : quarterList}
                                            onChange={e => {
                                                setQuarter(e)
                                            }}
                                            className="mt-2 my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <label className="text-[#344054] text-[13px] font-medium dark:text-white mb-2">Evaluation Period</label>
                                    </div>
                                    <div>
                                        <label className="text-[#344054] text-[13px] font-medium">Start Date</label>
                                        <Calendar placeholder='MM/DD/YYYY' disabled={isDisableMode} dateFormat="mm/dd/yy" value={startDate} onChange={(e) => setStartDate(e.value)} id="icon" showIcon />
                                    </div>

                                    <div style={{ flexDirection: "column" }}>
                                        <label className="text-[#344054] text-[13px] font-medium">End Date</label>
                                        <Calendar placeholder='MM/DD/YYYY' disabled={isDisableMode} dateFormat="mm/dd/yy" value={endDate} onChange={(e) => setEndDate(e.value)} id="icon" showIcon />
                                    </div>

                                </div>
                                <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] p-6 bg-[#E5F3EC] dark:bg-[#071008]">
                                    {
                                        isDisableMode ?
                                            <div className="flex justify-end gap-3">
                                                <div><Link onClick={() => setShowCreatePopUp(0)} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Close</Link></div>
                                            </div>
                                            :
                                            <div className="flex justify-end gap-3">
                                                <div><Link onClick={() => setShowCreatePopUp(0)} className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Cancel</Link></div>
                                                <div><Link onClick={() => saveEvaluation()} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">{isUpdateMode ? "Update" : "Create"}</Link></div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div> : null
                }

                {/* Thank you popup */}
                <div id="selector" className={isViewDeployedPopUp === 1 ? 'overlay' : ''}></div>
                {
                    isViewDeployedPopUp === 1 ?
                        <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="displayPopupCont" className="sidenav">
                            <div className="popup_display_img"><img src={imageDeployedLarge} className="mx-auto mt-8" alt="" /></div>
                            {
                                isUpdateMode === true ?
                                    <div className="text-xl text-center my-6 dark:text-white">Successfully Updated!!!</div> :
                                    <div className="text-xl text-center my-6 dark:text-white">Successfully Created!!!</div>
                            }

                            <div className="px-10 2xl:px-14">

                                <div className="grid grid-cols-2 xl:grid-cols-4 successfully_box py-3 gap-y-3 xl:gap-y-0">
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">Financial Year</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{financialYear.label}</div>
                                    </div>
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">Quarter</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{quarter.value}</div>
                                    </div>
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">Start Date</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{moment(startDate).format("DD MMM, YYYY")}</div>
                                    </div>
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">End Date</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{moment(endDate).format("DD MMM,YYYY")}</div>
                                    </div>
                                </div>
                                <div className="block text-center my-6">
                                    <Link to="/hr/evaluation" onClick={() => {
                                        setViewDeployedPopUp(0);
                                    }} className="bg-[#029046] text-white rounded-md px-6 py-2 text-sm shadow-md inline-block removeClass">Go to Initiate Evaluation Page</Link>
                                </div>
                            </div>
                        </div> : null
                }

            </div>
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}