import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { reactLocalStorage } from 'reactjs-localstorage';
import LeftSideBar from '../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../components/common/hr/topnav.component';
import { calculateIndividualScore } from '../../../../components/helper/calculateIndividualScore';
import { ConvertResponseForSelect } from '../../../../components/helper/commonfunctions';
import { ValidateHRRole } from '../../../../components/helper/validateRole';
import HRLogin from '../../../../setup/auth/hr.component';
import { graphQLGetAllData, } from '../../../../../src/components/helper/graphQLFunctions'
import { InputText } from 'primereact/inputtext';
import { listTargetDetails } from "../../../../graphql/queries";
import { PARAMETER_IDs } from "../../../../components/helper/enum";

export default function ReportsGoalEvaluationDetailed() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //*State
    const [financialYear, setFinancialYear] = useState("");
    const [quarter, setQuarter] = useState("");

    //*List
    const [financialYearList, setFinancialYearList] = useState([]);
    const [evaluationDetailList, setEvaluationDetailList] = useState([]);

    const [showData, setShowData] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [rows, setRows] = useState(10); // Number of rows per page
    const [allSBUs, setAllSBUs] = useState([]);
    const [allRoles, setAllRoles] = useState([]);
    const [allCompanyActuals, setAllCompanyActuals] = useState([]);
    const [allRelationships, setAllRelationships] = useState([]);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [allRelationshipDetails, setAllRrealationshipDetails] = useState([]);
    const [allEmployees, setAllEmployees] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });

    //quarterList
    var quarterList = [
        { label: "All Quarter", value: "4" },
        { label: "Quarter 1", value: "1" },
        { label: "Quarter 2", value: "2" },
        { label: "Quarter 3", value: "3" },
        { label: "Quarter 4", value: "4" },
    ]


    const [isPageChanged, setPageChanged] = useState('false');

    const onPageChange = (event) => {
        setRows(event.rows)
        setlazyState({
            first: event.first,
            rows: event.rows,
            page: event.page
        })
        setPageChanged(true);

        if (searchTerm.length > 0) {
            let filteredEmp = allEmployees.filter(c => (c.firstName + " " + c.lastName).toLowerCase().includes(searchTerm.toLowerCase()));
            const empArray = filteredEmp.slice(event.page * event.rows, event.page * event.rows + event.rows)
            getEvaluationDetailReport(financialYear.value, quarter.value, empArray, filteredEmp.length);
        }
        else {
            const empArray = allEmployees.slice(event.page * event.rows, event.page * event.rows + event.rows)
            getEvaluationDetailReport(financialYear.value, quarter.value, empArray, allEmployees.length);
        }

    }

    const goSearch = () => {

        setlazyState({
            first: 0,
            rows: rows,
            page: 1,
        })

        let filteredEmp = allEmployees.filter(c => (c.firstName + " " + c.lastName).toLowerCase().includes(searchTerm.toLowerCase()));
        if(filteredEmp.length === 0){
            setEvaluationDetailList([]);
            setTotalRecords(0);
            setlazyState({
                first: 0,
                rows: rows,
                page: 1,
            })
            setShowData(true)
        }else{
            let slicefilteredEmp = filteredEmp.slice(0, rows);
            getEvaluationDetailReport(financialYear.value, quarter.value, slicefilteredEmp, filteredEmp.length);
    
        }
       
    }

    //get financial year
    const bindList = async () => {
        //set financial year
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        if (Object.keys(selectedYear).length !== 0) {
            getEvaluationDetailReport(selectedYear.value, "1", [])
            setFinancialYear(selectedYear)
            setQuarter({ label: "Quarter 1", value: "1" })
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);
            setFinancialYear({ label: responses[0].name, value: responses[0].id })
            getEvaluationDetailReport(responses[0].id, "1", [])
            setQuarter({ label: "Quarter 1", value: "1" })
        }


        // var responses = await DataStore.query(FinancialYear, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        var responses = await graphQLGetAllData('listFinancialYears');
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));
        var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYearList(myArray);


    }

    const getCappedIndivisualScore = (rowdata) => {
        if (parseFloat(rowdata.individualScore) < 80) {
            return rowdata.individualScore;
        }
        else {
            return '80'
        }
    };

    const getH1EligiblePayoutScore = (rowdata) => {
        let cappedIndScore = 0;
        if (parseFloat(rowdata.individualScore) < 80) {
            cappedIndScore = rowdata.individualScore;
        }
        else {
            cappedIndScore = '80'
        }

        if (cappedIndScore < 50) { return '0' }
        else if (cappedIndScore > 50 && cappedIndScore < 80) { return '50' }
        else { return cappedIndScore }
    }

    //*get Evaluation - Detailed
    const getEvaluationDetailReport = async (financialYearId, quarterNo, employees, withoutFilterCount) => {
        setShowData(false)
        // var evaluationResponses = await DataStore.query(Evaluation, (c) => c.and(c => [
        //     c.financial_year_id.eq(financialYearId),
        //     c.quaterNo.eq(quarterNo)
        // ]));

        var employeeResponse = [];
        if(employees.length === 0){
            employeeResponse = await setEmployeesOnQuarter(financialYearId,quarterNo)
            employeeResponse = employeeResponse.slice(0 * rows, 0 * rows + rows)
        }else{
            employeeResponse = employees
        }

       
        var evaluationResponses = await graphQLGetAllData('listEvaluations', [
            { financial_year_id: { eq: financialYearId } },
            { quaterNo: { eq: quarterNo } }
        ]);

        if (evaluationResponses.length > 0) {
            try {
                // var employeeResponse = await DataStore.query(Employee, Predicates.ALL, {
                //     sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
                // });
              
                // console.log('allEmployees',allEmployees);
                // if (allEmployees) {
                //     for (var i = 0; i < rows; i++) {
                //         employeeResponse.push(allEmployees[i])
                //     }
                // }

                if (withoutFilterCount) {
                    setTotalRecords(withoutFilterCount);
                } 

                if (!isPageChanged) {
                    setlazyState({
                        first: 0,
                        rows: rows,
                        page: 1,
                    })
                }

                if (employeeResponse.length > 0) {
                    var newResponse = [];
                    for (var i = 0; i < employeeResponse.length; i++) {
                        try {
                            let index = i + 1;
                            let employeeName = employeeResponse[i].firstName + " " + employeeResponse[i].lastName
                            let employeeId = employeeResponse[i].employeeId

                            //Role List
                            let role = '';
                            try {
                                if (employeeResponse[i].role_id) {
                                    // var roleResponse = await DataStore.query(Roles, employeeResponse[i].role_id)
                                    // var roleResponse = await graphQLFindRecordById('getRoles', employeeResponse[i].role_id);
                                    var roleResponse = allRoles.find(c => c.id === employeeResponse[i].role_id)

                                    if (roleResponse) { role = roleResponse.name } else { role = "-" }
                                } else { role = "-" }
                            } catch (e) {
                                console.log('e', e);
                            }

                            //get SBU
                            let sbuName = '';
                            try {
                                if (employeeResponse[i].param_sbu_id !== undefined && employeeResponse[i].param_sbu_id !== "" && employeeResponse[i].param_sbu_id !== null) {
                                    // var SBUResponse = await DataStore.query(SBU, employeeResponse[i].param_sbu_id)
                                    // var SBUResponse = await graphQLFindRecordById('getSBU', employeeResponse[i].param_sbu_id);
                                    var SBUResponse = allSBUs.find(c => c.id === employeeResponse[i].param_sbu_id)

                                    if (SBUResponse !== undefined) { sbuName = SBUResponse.name } else { sbuName = "-" }
                                }
                                else {
                                    sbuName = "-"
                                }
                            } catch (e) {
                                console.log('e', e);
                            }

                            let gJD = employeeResponse[i].joining_date !== null ? moment(employeeResponse[i].joining_date).format("MMM D, YYYY") : "-";
                            let confirmationDate = employeeResponse[i].confirmation_date !== null ? moment(employeeResponse[i].confirmation_date).format("MMM D, YYYY") : "-";
                            let vCEligibleStatus = "Yes";
                            let h1EligibleStatus = "No";
                            let OfficeLocation = employeeResponse[i].country !== null && employeeResponse[i].country !== "" ? employeeResponse[i].country : "-";
                            let employeeClass = employeeResponse[i].employee_class !== null && employeeResponse[i].employee_class !== "" ? employeeResponse[i].employee_class : "-";
                            let employeeStatus = "Active"
                            let employeeType = employeeResponse[i].employee_type !== null && employeeResponse[i].employee_type !== "" ? employeeResponse[i].employee_type : "-";
                            let grade = employeeResponse[i].grade !== null && employeeResponse[i].grade !== "" ? employeeResponse[i].grade : "-";
                            let brand = employeeResponse[i].band !== null && employeeResponse[i].band !== "" ? employeeResponse[i].band : "-";
                            let lwd = employeeResponse[i].lwd !== null && employeeResponse[i].lwd !== "" ? employeeResponse[i].lwd : "-";
                            let companyScore = 0; let sBUPerformance;
                            let h1EligiblePayoutScore;
                            let cappedIndividualScore;
                            // var companyActualsResponses = await DataStore.query(CompanyActuals, (c) => c.and(c => [
                            //     c.financial_year_id.eq(financialYearId),
                            //     c.quarterNo.eq(Number(quarterNo))
                            // ]));
                            // var companyActualsResponses = await graphQLGetAllData('listCompanyActuals', [
                            //     { financial_year_id: { eq: financialYearId } },
                            //     { quarterNo: { eq: quarterNo } }
                            // ]);

                            var companyActualsResponses = allCompanyActuals.filter(c => c.financial_year_id === financialYearId && c.quarterNo === quarterNo)

                            // const checkIfRelationExists = await DataStore.query(Relationship, (c) => c.and(c => [
                            //     c.financial_year_id.eq(financialYearId),
                            //     c.role_id.eq(employeeResponse[i].role_id)
                            // ]));
                            // const checkIfRelationExists = await graphQLGetAllData('listRelationships', [
                            //     { financial_year_id: { eq: financialYearId } },
                            //     { role_id: { eq: employeeResponse[i].role_id } }
                            // ]);

                            const checkIfRelationExists = allRelationships.filter(c => c.financial_year_id === financialYearId && c.role_id === employeeResponse[i].role_id)

                            if (checkIfRelationExists.length > 0) {
                                // let getParameterWeightage = await DataStore.query(RelationshipDetails, (c) => c.and(c => [
                                //     c.relationship_id.eq(checkIfRelationExists[0].id),
                                // ]));
                                // let getParameterWeightage = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: checkIfRelationExists[0].id } }]);
                                let getParameterWeightage = allRelationshipDetails.filter(c => c.id === checkIfRelationExists[0].id)

                                let weightageArray = getParameterWeightage.reduce((acc, book) => acc + parseFloat(book.weightage), 0);
                                cappedIndividualScore = weightageArray;
                            } else { cappedIndividualScore = 0; }

                            if (companyActualsResponses) {
                                companyScore = companyActualsResponses[0]?.companyPerformance; sBUPerformance = companyActualsResponses[0]?.sbuPerformanceValue;
                            }
                            else { companyScore = 0; sBUPerformance = 0; }
                            const individualScore = await calculateIndividualScore(financialYearId, employeeResponse[i].id, quarterNo, employeeResponse[i].parentEmployeeId);

                            let totalScore = cappedIndividualScore +
                                parseFloat(companyScore === undefined ? 0 : companyScore) +
                                parseFloat(sBUPerformance === undefined ? 0 : sBUPerformance);

                            if (totalScore > 100) { h1EligiblePayoutScore = 100; }
                            else if (totalScore > 80) { h1EligiblePayoutScore = totalScore }
                            else if (totalScore < 80) { h1EligiblePayoutScore = 50 }


                            newResponse.push({
                                "index": index, "employeeName": employeeName, "employeeId": employeeId, "role": role, "sbuName": sbuName, "gJD": gJD,
                                "confirmationDate": confirmationDate, "vCEligibleStatus": vCEligibleStatus, "h1EligibleStatus": h1EligibleStatus, "OfficeLocation": OfficeLocation,
                                "employeeClass": employeeClass, "employeeStatus": employeeStatus, "employeeType": employeeType, "grade": grade, "brand": brand, "lwd": lwd,
                                "individualScore": individualScore.totalConsolidatedScore, "cappedIndividualScore": cappedIndividualScore, "companyScore": companyScore, "sBUPerformance": sBUPerformance, "totalScore": totalScore, "h1EligiblePayoutScore": h1EligiblePayoutScore
                            });

                            //On Last Record assign to state`
                            // if (i === employeeResponse.length - 1) {
                            //     setEvaluationDetailList(newResponse)
                            //     setShowData(true)
                            // }
                        } catch (e) { console.log('e', e); }
                    }

                    setEvaluationDetailList(newResponse)
                    setShowData(true)


                } else {
                    setEvaluationDetailList([]);
                    setShowData(true)
                }
            } catch (e) {

            }
        } else {
            setEvaluationDetailList([]);
            setTotalRecords(0);
            setlazyState({
                first: 0,
                rows: rows,
                page: 1,
            })
            setShowData(true)
        }
    }

    const [evaluationDetailListForDownload, setEvaluationDetailListForDownload] = useState([]);
    const [showDownloadButton, setShowDownloadButton] = useState(true);

    const getEvaluationDetailReportForDownLoad = async (financialYearId, quarterNo,) => {
        setShowDownloadButton(false);
        var evaluationResponses = await graphQLGetAllData('listEvaluations', [
            { financial_year_id: { eq: financialYearId } },
            { quaterNo: { eq: quarterNo } }
        ]);

        if (evaluationResponses.length > 0) {
            try {
                var employeeResponse = await graphQLGetAllData('listEmployees');
                employeeResponse = employeeResponse.sort((a, b) => a.firstName.localeCompare(b.firstName) ||
                    a.lastName.localeCompare(b.lastName));

                if (employeeResponse.length > 0) {
                    var newResponse = [];
                    for (var i = 0; i < 50; i++) {
                        try {
                            let index = i + 1;
                            let employeeName = employeeResponse[i].firstName + " " + employeeResponse[i].lastName
                            let employeeId = employeeResponse[i].employeeId

                            //Role List
                            let role = '';
                            try {
                                if (employeeResponse[i].role_id) {
                                    // var roleResponse = await DataStore.query(Roles, employeeResponse[i].role_id)
                                    // var roleResponse = await graphQLFindRecordById('getRoles', employeeResponse[i].role_id);
                                    var roleResponse = allRoles.find(c => c.id === employeeResponse[i].role_id)

                                    if (roleResponse) { role = roleResponse.name } else { role = "-" }
                                } else { role = "-" }
                            } catch (e) {
                                console.log('e', e);
                            }

                            //get SBU
                            let sbuName = '';
                            try {
                                if (employeeResponse[i].param_sbu_id !== undefined && employeeResponse[i].param_sbu_id !== "" && employeeResponse[i].param_sbu_id !== null) {
                                    // var SBUResponse = await DataStore.query(SBU, employeeResponse[i].param_sbu_id)
                                    // var SBUResponse = await graphQLFindRecordById('getSBU', employeeResponse[i].param_sbu_id);
                                    var SBUResponse = allSBUs.find(c => c.id === employeeResponse[i].param_sbu_id)

                                    if (SBUResponse !== undefined) { sbuName = SBUResponse.name } else { sbuName = "-" }
                                }
                                else {
                                    sbuName = "-"
                                }
                            } catch (e) {
                                console.log('e', e);
                            }

                            let gJD = employeeResponse[i].joining_date !== null ? moment(employeeResponse[i].joining_date).format("MMM D, YYYY") : "-";
                            let confirmationDate = employeeResponse[i].confirmation_date !== null ? moment(employeeResponse[i].confirmation_date).format("MMM D, YYYY") : "-";
                            let vCEligibleStatus = "Yes";
                            let h1EligibleStatus = "No";
                            let OfficeLocation = employeeResponse[i].country !== null && employeeResponse[i].country !== "" ? employeeResponse[i].country : "-";
                            let employeeClass = employeeResponse[i].employee_class !== null && employeeResponse[i].employee_class !== "" ? employeeResponse[i].employee_class : "-";
                            let employeeStatus = "Active"
                            let employeeType = employeeResponse[i].employee_type !== null && employeeResponse[i].employee_type !== "" ? employeeResponse[i].employee_type : "-";
                            let grade = employeeResponse[i].grade !== null && employeeResponse[i].grade !== "" ? employeeResponse[i].grade : "-";
                            let brand = employeeResponse[i].band !== null && employeeResponse[i].band !== "" ? employeeResponse[i].band : "-";
                            let lwd = employeeResponse[i].lwd !== null && employeeResponse[i].lwd !== "" ? employeeResponse[i].lwd : "-";
                            let companyScore = 0; let sBUPerformance;
                            let h1EligiblePayoutScore;
                            let cappedIndividualScore;
                            // var companyActualsResponses = await DataStore.query(CompanyActuals, (c) => c.and(c => [
                            //     c.financial_year_id.eq(financialYearId),
                            //     c.quarterNo.eq(Number(quarterNo))
                            // ]));
                            // var companyActualsResponses = await graphQLGetAllData('listCompanyActuals', [
                            //     { financial_year_id: { eq: financialYearId } },
                            //     { quarterNo: { eq: quarterNo } }
                            // ]);

                            var companyActualsResponses = allCompanyActuals.filter(c => c.financial_year_id === financialYearId && c.quarterNo === quarterNo)

                            // const checkIfRelationExists = await DataStore.query(Relationship, (c) => c.and(c => [
                            //     c.financial_year_id.eq(financialYearId),
                            //     c.role_id.eq(employeeResponse[i].role_id)
                            // ]));
                            // const checkIfRelationExists = await graphQLGetAllData('listRelationships', [
                            //     { financial_year_id: { eq: financialYearId } },
                            //     { role_id: { eq: employeeResponse[i].role_id } }
                            // ]);

                            const checkIfRelationExists = allRelationships.filter(c => c.financial_year_id === financialYearId && c.role_id === employeeResponse[i].role_id)

                            if (checkIfRelationExists.length > 0) {
                                // let getParameterWeightage = await DataStore.query(RelationshipDetails, (c) => c.and(c => [
                                //     c.relationship_id.eq(checkIfRelationExists[0].id),
                                // ]));
                                // let getParameterWeightage = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: checkIfRelationExists[0].id } }]);
                                let getParameterWeightage = allRelationshipDetails.filter(c => c.id === checkIfRelationExists[0].id)

                                let weightageArray = getParameterWeightage.reduce((acc, book) => acc + parseFloat(book.weightage), 0);
                                cappedIndividualScore = weightageArray;
                            } else { cappedIndividualScore = 0; }

                            if (companyActualsResponses) {
                                companyScore = companyActualsResponses[0]?.companyPerformance; sBUPerformance = companyActualsResponses[0]?.sbuPerformanceValue;
                            }
                            else { companyScore = 0; sBUPerformance = 0; }
                            const individualScore = await calculateIndividualScore(financialYearId, employeeResponse[i].id, quarterNo, employeeResponse[i].parentEmployeeId);

                            let totalScore = cappedIndividualScore +
                                parseFloat(companyScore === undefined ? 0 : companyScore) +
                                parseFloat(sBUPerformance === undefined ? 0 : sBUPerformance);

                            if (totalScore > 100) { h1EligiblePayoutScore = 100; }
                            else if (totalScore > 80) { h1EligiblePayoutScore = totalScore }
                            else if (totalScore < 80) { h1EligiblePayoutScore = 50 }


                            newResponse.push({
                                "index": index, "employeeName": employeeName, "employeeId": employeeId, "role": role, "sbuName": sbuName, "gJD": gJD,
                                "confirmationDate": confirmationDate, "vCEligibleStatus": vCEligibleStatus, "h1EligibleStatus": h1EligibleStatus, "OfficeLocation": OfficeLocation,
                                "employeeClass": employeeClass, "employeeStatus": employeeStatus, "employeeType": employeeType, "grade": grade, "brand": brand, "lwd": lwd,
                                "individualScore": individualScore.totalConsolidatedScore, "cappedIndividualScore": cappedIndividualScore, "companyScore": companyScore, "sBUPerformance": sBUPerformance, "totalScore": totalScore, "h1EligiblePayoutScore": h1EligiblePayoutScore
                            });
                        } catch (e) { console.log('e', e); }
                    }

                    setEvaluationDetailListForDownload(newResponse)
                    setShowDownloadButton(true)
                    if (newResponse.length > 0) {
                        actualDownload()
                        setDataLoaded(true)
                    }
                } else {
                    setEvaluationDetailListForDownload([]);
                    setShowDownloadButton(false)
                }
            } catch (e) {

            }
        } else {
            setEvaluationDetailListForDownload([]);
            setShowDownloadButton(false)
        }

    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //onclick financial year
    const onFinancialSelectChange = async (e) => {
        reactLocalStorage.setObject('selectedYearEmployee', e);
        setFinancialYear({ label: e.label, value: e.value })
    }


    const setEmployeesOnQuarter = async (financialYearId, quarter) => {

        var targetsResponses = await graphQLGetAllData('listTargets', [{ financial_year_id: { eq: financialYearId } }]);

        var targetDetailsResponses = await graphQLGetAllData('listTargetDetails');

        let targetResponsesWithActuals = [];

        for (var i = 0; i < targetsResponses.length; i++) {

            let flag = false;
            var targetDetail = targetDetailsResponses.find(e => e.target_id === targetsResponses[i].id && e.parameter_id === PARAMETER_IDs.Revenue);

            if (targetDetail) {
                switch (quarter.toString()) {
                    case "1":
                        if (targetDetail.m4_actual_value !== null) {
                            flag = true;
                        }
                        break;
                    case "2":
                        if (targetDetail.m7_actual_value !== null) {
                            flag = true;
                        }
                        break;
                    case "3":
                        if (targetDetail.m10_actual_value !== null) {
                            flag = true;
                        }
                        break;
                    case "4":
                        if (targetDetail.m1_actual_value !== null) {
                            flag = true;
                        }
                        break;

                    default:
                        break;
                }

                if (flag === true) {
                    targetResponsesWithActuals.push(targetsResponses[i]);
                }
            }
        }

        let uniqueData = [];
        let dupliactes = [];

        targetResponsesWithActuals.forEach((item, i) => {
            let obj = uniqueData.find(o => o.employee_id === item.employee_id);
            if (!obj) {
                uniqueData.push(item)
            }
            else {
                dupliactes.push(item)
            }
        })

        let employeeResponses = await graphQLGetAllData('listEmployees');

        employeeResponses = employeeResponses.filter(c =>
            (uniqueData.find(e => e.employee_id === c.id))
        )

        employeeResponses = employeeResponses.sort((a, b) => a.firstName.localeCompare(b.firstName) ||
            a.lastName.localeCompare(b.lastName));
        setAllEmployees(employeeResponses);
        setTotalRecords(employeeResponses.length);
        return employeeResponses;

    }

    const getMasterData = async () => {
        setDataLoaded(false)

        var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);
        setFinancialYear({ label: responses[0].name, value: responses[0].id })


        // var targetsResponses = await graphQLGetAllData('listTargets', [{ financial_year_id: { eq: responses[0].id } }]);

        // var targetDetailsResponses = await graphQLGetAllData('listTargetDetails');
        // let targetResponsesWithActuals = [];

        // for (var i = 0; i < targetsResponses.length; i++) {

        //     let flag = false;
        //     var targetDetail = targetDetailsResponses.find(e => e.target_id === targetsResponses[i].id)

        //     if (targetDetail) {
        //         if (targetDetail.m4_actual_value !== null || targetDetail.m7_actual_value !== null || targetDetail.m10_actual_value !== null || targetDetail.m1_actual_value !== null) {
        //             flag = true;
        //         }

        //         if (flag === true) {
        //             targetResponsesWithActuals.push(targetsResponses[i]);
        //         }
        //     }
        // }

        // let uniqueData = [];
        // let dupliactes = [];

        // targetResponsesWithActuals.forEach((item, i) => {
        //     let obj = uniqueData.find(o => o.employee_id === item.employee_id);
        //     if (!obj) {
        //         uniqueData.push(item)
        //     }
        //     else {
        //         dupliactes.push(item)
        //     }
        // })

        // let employeeResponses = await graphQLGetAllData('listEmployees');

        // employeeResponses = employeeResponses.filter(c =>
        //     (uniqueData.find(e => e.employee_id === c.id))
        // )

        // employeeResponses = employeeResponses.sort((a, b) => a.firstName.localeCompare(b.firstName) ||
        //     a.lastName.localeCompare(b.lastName));
        // setAllEmployees(employeeResponses);
        // setTotalRecords(employeeResponses.length);

        // let data = await setEmployeesOnQuarter(responses[0].id,1);

        let SBUResponse = await graphQLGetAllData('listSBUS')
        setAllSBUs(SBUResponse);

        let RoleResponses = await graphQLGetAllData('listRoles')
        setAllRoles(RoleResponses)

        let companyActualsResponses = await graphQLGetAllData('listCompanyActuals')
        setAllCompanyActuals(companyActualsResponses)

        let relationshipResponses = await graphQLGetAllData('listRelationships')
        setAllRelationships(relationshipResponses)

        let relationshipDetalsResponses = await graphQLGetAllData('listRelationshipDetails')
        setAllRrealationshipDetails(relationshipDetalsResponses);

        setDataLoaded(true)
    }



    useEffect(() => {
        if (isDataLoaded === true) {
            bindList()
            onLoad();
        }
    }, [isDataLoaded]);

    useEffect(() => {
        getMasterData()
    }, [])

    const downloadReport = () => {
        if (evaluationDetailListForDownload.length > 0) {
            actualDownload()
        } else {
            getEvaluationDetailReportForDownLoad(financialYear.value, quarter.value);
        }
    }

    const actualDownload = () => {
        const unit = "pt";
        const size = "A2"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);

        //target Data
        const title = "Goals Evaluation - Detailed";
        const headers = [["Employee ID", "Name", "Role", "Strategic Business Unit", "Joining Date", "Confirmation Date", "VC Eligibility Status", "H1 Eligibility Status",
            "Office Location", "Employee Class", "Employee Status", "Employee Type", "Individual Score", "Capped Individual Score", "Company Score", "SBU Performance", "Total Score",
            "H1 Eligible Payout Score"
        ]];

        setTimeout(() => {
            const data = evaluationDetailListForDownload.map(elt => [elt.employeeId, elt.employeeName, elt.role, elt.sbuName, elt.gJD,
            elt.confirmationDate, elt.vCEligibleStatus, elt.h1EligibleStatus, elt.OfficeLocation, elt.employeeClass, elt.employeeStatus,
            elt.employeeType, elt.grade, elt.belowFifty, elt.brand, elt.lwd, elt.individualScore, elt.cappedIndividualScore, elt.companyScore,
            elt.sBUPerformance, elt.totalScore, elt.h1EligiblePayoutScore
            ]);
            let content = { startY: 50, head: headers, body: data };
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save(title)
        }, 2000)

    }

    return isAuthenticated ? (

        <div>
            <div className="pt-16">
                <TopNavBar />
                <LeftSideBar />
                <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Reports</div>
                            <div className="tabs_one mt-5 sm:mt-0">
                                <ul className="flex">
                                    <li><Link to="/hr/reports/goalsetting/summary" >Goal Setting</Link></li>
                                    <li><Link to="/hr/reports/goalevaluation/summary" className='active' >Goals Evaluation</Link></li>
                                </ul>
                            </div>
                        </div>

                        {

                            <>
                                <div className="lg:flex justify-between my-6">
                                    <div className="tabs_two">
                                        <ul className="flex">
                                            <li><Link to="/hr/reports/goalevaluation/summary">Summary</Link></li>
                                            <li><Link className='active' >Detailed</Link></li>
                                        </ul>
                                    </div>
                                    <div className="mt-5 lg:mt-0 flex items-center gap-4">
                                        <div className="text-md min-w-fit dark:text-white">Select Year</div>
                                        <div className="w-full min-w-[200px]">
                                            <Select
                                                options={financialYearList}
                                                value={financialYear}
                                                onChange={e => {
                                                    onFinancialSelectChange(e);
                                                    setEvaluationDetailListForDownload([])
                                                    reactLocalStorage.setObject('selectedYear', e);
                                                    getEvaluationDetailReport(e.value, quarter.value,[])
                                                }}
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>
                                        <div className="w-full min-w-[200px]">
                                            <Select
                                                options={quarterList}
                                                value={quarter}
                                                onChange={(e) => {
                                                    setQuarter(e)
                                                    setEvaluationDetailListForDownload([])
                                                    getEvaluationDetailReport(financialYear.value, e.value, [])
                                                }}
                                                maxMenuHeight={200} placeholder="Select Quarter"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* code here */}

                                <div className={"block w-full"}>
                                    <div className="lg:flex justify-between my-6">
                                        <div className="tabs_two">
                                            <ul className="flex font-semibold dark:text-[white]">
                                                Goals Evaluation - Detailed
                                            </ul>
                                        </div>
                                        <div className="mt-5 lg:mt-0 flex items-center gap-4">
                                            <div>
                                                <span className="p-input-icon-left">
                                                    <i className="pi pi-search" />
                                                    <InputText
                                                        placeholder="Search"
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                        className='dark:text-[white]' />
                                                </span>
                                            </div>
                                            <Link onClick={goSearch} className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" >Go</Link>
                                            {/* <Link onClick={() => window.print()} className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" ><i className="pi pi-print style={{'fontSize': '2px'}}"></i> Print</Link> */}
                                            {showDownloadButton && <Link onClick={() => downloadReport()} className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" ><i className="pi pi-download style={{'fontSize': '2px'}}"></i> Download</Link>}
                                            {!showDownloadButton && <Link to="#" className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" ><i className="pi pi-download style={{'fontSize': '2px'}}"></i> Downloading</Link>}
                                        </div>
                                    </div>
                                    <div className=" rounded-lg mt-5 flex overflow-auto ">
                                        {showData === true ? <DataTable paginator value={evaluationDetailList} style={{ width: '93vw' }}
                                            lazy
                                            first={lazyState.first}
                                            totalRecords={totalRecords}
                                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={lazyState.rows} rowsPerPageOptions={[10, 20, 50]}

                                            onPage={onPageChange}
                                        >
                                            <Column field="index" header="Index" sortable></Column>
                                            <Column field="employeeId" header="Employee ID" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                            <Column field="employeeName" header="Name" sortable filter filterPlaceholder="Search by Name"></Column>
                                            <Column field="role" header="Role" sortable filter filterPlaceholder="Search by Role"></Column>
                                            <Column field="sbuName" header="Strategic Business Unit" sortable filter filterPlaceholder="Search by Role"></Column>
                                            <Column field="gJD" header="Joining Date" sortable filter ></Column>
                                            <Column field="confirmationDate" header="Confirmation Date" sortable filter ></Column>
                                            <Column field='vCEligibleStatus' header="VC Eligibility Status" filter  ></Column>
                                            <Column field='h1EligibleStatus' header="H1 Eligibility Status" filter   ></Column>
                                            <Column field='OfficeLocation' header="Office Location" filter ></Column>
                                            <Column field='employeeClass' header="Employee Class" ></Column>
                                            <Column field='employeeStatus' header="Employee Status" ></Column>
                                            <Column field='employeeType' header="Employee Type" ></Column>
                                            <Column field='grade' header="Grade" ></Column>
                                            <Column field='brand' header="Brand" ></Column>
                                            <Column field='lwd' header="LWD     " ></Column>
                                            <Column field='individualScore' sortable header="Individual Score" ></Column>
                                            {/* AS PER PREVIOUS LOGIC */}
                                            {/* <Column field='cappedIndividualScore' header="Capped Individual Score" ></Column> */}
                                            <Column body={getCappedIndivisualScore} header="Capped Individual Score" ></Column>
                                            <Column field='companyScore' header="Company Score" ></Column>
                                            <Column field='sBUPerformance' header="SBU Performance" ></Column>
                                            {/* AS PER PREVIOUS LOGIC */}
                                            {/* <Column field='totalScore' header="Total Score" ></Column> */}
                                            <Column body={getCappedIndivisualScore} header="Total Score" ></Column>
                                            {/* AS PER PREVIOUS LOGIC */}
                                            {/* <Column field='h1EligiblePayoutScore' header="H1 Eligible Payout Score" ></Column> */}
                                            <Column body={getH1EligiblePayoutScore} header="H1 Eligible Payout Score" ></Column>
                                        </DataTable> : null}
                                    </div>
                                </div>
                            </>
                        }


                        {
                            !showData &&
                            <div className='text-center py-8' ><ProgressSpinner /></div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
        : (
            <HRLogin sessionExpired={1} />
        );
}

