import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Menu } from "@headlessui/react";
import moment from "moment";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { reactLocalStorage } from 'reactjs-localstorage';
import "../../../../src/App.css";
import popupHeaderImg from "../../../assets/images/business-people-casual-meeting 1.png";
import imageDeployedLarge from '../../../assets/images/deployedImg.png';
import EditButton from '../../../components/common/buttons/EditButton';
import ViewButton from '../../../components/common/buttons/ViewButton';
import { getTargetBasedOnParameterType } from "../../../components/common/getTargetBasedOnParameterType";
import LeftSideBar from '../../../components/common/hr/leftnav.component';
import TopNavBar from "../../../components/common/hr/topnav.component";
import { ConvertResponseForSelect } from '../../../components/helper/commonfunctions';
import { calculateConsolidatedScore, calculateWeightageAchievement, checkIfNegativeValue } from "../../../components/helper/dashboardCalCulations";
import { CHANGE_REQUEST_TYPES, HR_CHANGE_REQUEST_STATUS, PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES, PARAMETER_IDs } from "../../../components/helper/enum";
import { commaSeparated } from '../../../components/helper/maths';
import { ValidateHRRole } from '../../../components/helper/validateRole';
import { ChangeRequest, Employee, FinancialYear, Parameters, Plan, Relationship, RelationshipDetails, Roles, TargetDetails, Targets } from "../../../models";
import HRLogin from '../../../setup/auth/hr.component';
import { getActualValue } from "../../employee/evaluation/getActualValue";
import { toast } from "react-toastify";
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries'
import * as mutations from '../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../src/components/helper/graphQLFunctions'
import { ProgressSpinner } from 'primereact/progressspinner';

export default function OverrideActuals() {

    //get Local Storage data
    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
    let selectedYear = reactLocalStorage.getObject('selectedYear');

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //state
    const [openOASummary, setOpenOASummary] = useState(false);
    const [financialYear, setFinancialYear] = useState("");
    const [financialYearList, setFinancialYearList] = useState([]);

    //checkbox
    const [selectedOverrideActual, setSelectedOverrideActual] = useState(null);

    //popup
    const [isEditPopUp, setAssignPopUp] = useState(0);
    const [isViewPopUp, setViewPopUp] = useState(0);
    const [isViewApprovePopUp, setViewApprovePopUp] = useState(0);
    const [isViewDeclinedPopUp, setViewDeclinedPopUp] = useState(0);

    //list
    const [myRequestList, setMyRequestList] = useState([]);
    const [performanceSummaryList, setPerformanceSummaryList] = useState([]);

    //onclick check box
    function checkBoxChecked() { setOpenOASummary(!openOASummary); }

    //on click clear all
    const clearAll = () => {
        setAssignPopUp(0);
        setViewApprovePopUp(0);
        setViewDeclinedPopUp(0);
    }


    //action roles
    const actionsOnRoles = (row) => {
        return (
            <React.Fragment >
                <div className="flex justify-center gap-8 ">
                    <Link onClick={() => {
                        LoadSummaryParametersData(financialYear.value, row.requestedEmployeeId, row.selectedQuarter)
                        openRequestDetails(row);
                        setViewPopUp(1);
                    }} ><ViewButton /></Link>
                    <Link onClick={() => {
                        if (row.Status !== HR_CHANGE_REQUEST_STATUS.Declined && row.Status !== HR_CHANGE_REQUEST_STATUS.Approved) {
                            LoadSummaryParametersData(financialYear.value, row.requestedEmployeeId, row.selectedQuarter)
                            openRequestDetails(row);
                            setAssignPopUp(1)
                        } else {
                            toast.error("Request is " + row.Status)
                        }
                    }}  ><EditButton /></Link>
                </div>
            </React.Fragment>
        );
    }


    //popup state
    const [requestorName, setRequestorName] = useState("");
    const [reportingManagerName, setReportingManagerName] = useState("");
    const [employeeNo, setEmployeeNo] = useState("");
    const [employeeRequestorId, setEmployeeRequestorId] = useState("");
    const [employeeRequestorCode, setEmployeeRequestorCode] = useState("");
    const [requestedDate, setRequestedDate] = useState("");
    const [hRApproveDate, setHRApproveDate] = useState("");
    const [reportingManagerApprovedDate, setReportingManagerApprovedDate] = useState("");
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isShowHrApproveDateValidation, setIsShowHrApproveDateValidation] = useState(false);
    const [quarter, setQuarter] = useState("");

    async function openRequestDetails(row) {
        let requestedId = row.id
        setEmployeeRequestorId(requestedId)
        setEmployeeRequestorCode(row.requestNo)
        setIsReadOnly(true)
        setRequestorName(row.Name)
        setReportingManagerName(row.ReportingManager)
        setEmployeeNo(row.EmployeeID)
        // setComments(row.comments)

        //Dates
        setRequestedDate(row.DateSubmitted)
        setHRApproveDate(row.DateApproved)
        setReportingManagerApprovedDate(row.reportingManagerApprovedDate)
        if (row.Status === HR_CHANGE_REQUEST_STATUS.Approved || row.Status === HR_CHANGE_REQUEST_STATUS.Declined) {
            setIsShowHrApproveDateValidation(true)
        } else {
            setIsShowHrApproveDateValidation(false)
        }

    }

    const [initialYear, setInitialYear] = useState({});

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }

        var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

        setInitialYear({ label: responses[0].name, value: responses[0].id });
    }

    //TABLES  - Show status 
    const requestsStatus = (rowData) => {
        return (
            rowData.Status === null ?
                <p className="bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center"> <div className='w-2 h-2 bg-[#1B55AF] rounded-full'></div>{HR_CHANGE_REQUEST_STATUS.Open}</p>
                : rowData.Status === HR_CHANGE_REQUEST_STATUS.Approved ?
                    <p className="bg-[#E5F3EC] text-[#4FB155] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center"> <div className='w-2 h-2 bg-[#4FB155] rounded-full'></div>{rowData.Status}</p>
                    :
                    <p className="bg-[#FEF3F2] text-[#FDA29B] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center "> <div className='w-2 h-2 bg-[#FDA29B] rounded-full'></div>{rowData.Status}</p>
        )
    }

    const bindList = async () => {

        if (Object.keys(selectedYear).length !== 0) {
            setFinancialYear(selectedYear)
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);
            setFinancialYear({ label: responses[0].name, value: responses[0].id })
        }

        //get financial year
        // var responses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var responses = await graphQLGetAllData('listFinancialYears');
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));
        var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYearList(myArray);
    }

    //get my request
    const myRequests = async (financialYear) => {
        //get logged user request
        // var responses = await DataStore.query(ChangeRequest, (c) => c.and(c => [
        //     c.financial_year_id.eq(financialYear.value),
        //     //c.reportingManagerEmployeeId.eq(""),
        //     c.requestType.eq(CHANGE_REQUEST_TYPES.OverrideActual),
        //     //c.requestStatus.eq(CHANGE_REQUEST_STATUS.REPORTING_MANAGER)
        // ]));
        var responses = await graphQLGetAllData('listChangeRequests', [
            { financial_year_id: { eq: financialYear.value } },
            { requestType: { eq: CHANGE_REQUEST_TYPES.OverrideActual } }
        ]);


        if (responses.length > 0) {
            let finalRequestData = []
            for (let i = 0; i < responses.length; i++) {
                //get current employee 
                let employeeId = ""
                let employeeName = ""
                if (responses[i].requestedEmployeeId) {
                    // var employeeResponses = await DataStore.query(Employee, (c) => c.id.eq(responses[i].requestedEmployeeId));
                    var employeeResponses = await graphQLFindRecordById('getEmployee', responses[i].requestedEmployeeId);

                    employeeId = employeeResponses ? employeeResponses.employeeId : '';
                    employeeName = employeeResponses ? employeeResponses.firstName + " " + employeeResponses.lastName : '';
                } else {
                    employeeId = "-"
                    employeeName = "-"
                }

                //get current role 
                let role = ""
                if (responses[i].requestedEmployeeRoleId) {
                    // var roleResponses = await DataStore.query(Roles, (c) => c.id.eq(responses[i].requestedEmployeeRoleId));
                    var roleResponses = await graphQLFindRecordById('getRoles', responses[i].requestedEmployeeRoleId);
                    role = roleResponses ? roleResponses.name : '';
                } else {
                    role = "-"
                }

                //get current Reporting Manager 
                let reportingManager = ""
                if (responses[i].reportingManagerEmployeeId) {
                    // var reportingManagerResponses = await DataStore.query(Employee, (c) => c.id.eq(responses[i].reportingManagerEmployeeId));
                    var reportingManagerResponses = await graphQLFindRecordById('getEmployee', responses[i].reportingManagerEmployeeId);

                    reportingManager = reportingManagerResponses ? reportingManagerResponses.firstName + " " + reportingManagerResponses.lastName : '';
                } else {
                    reportingManager = "-"
                }

                //bind data
                let data = {
                    "id": responses[i].id,
                    "requestedEmployeeId": responses[i].requestedEmployeeId,
                    "requestNo": responses[i].requestNo,
                    "EmployeeID": employeeId,
                    "Name": employeeName,
                    "Role": role,
                    "vertical": "Distribution",
                    "SBU": "Value MEA",
                    "ReportingManager": reportingManager,
                    "DateSubmitted": responses[i].requestedDate === null ? "-" : moment(responses[i].requestedDate).format("Do MMM, YYYY"),
                    "DateApproved": responses[i].hrManagerApprovedDate === null ? "-" : moment(responses[i].hrManagerApprovedDate).format("Do MMM, YYYY"),
                    "reportingManagerApprovedDate": responses[i].reportingManagerApprovedDate === null ? "-" : moment(responses[i].reportingManagerApprovedDate).format("Do MMM, YYYY"),
                    "extendStartPeriod": responses[i].ExtendStartPeriod,
                    "extendEndPeriod": responses[i].ExtendEndPeriod,
                    "comments": responses[i].comments,
                    "Status": responses[i].hrRequestStatus,
                    "selectedQuarter": responses[i].selectedQuarter,
                    "Action": "Action"
                }
                finalRequestData.push(data)
                // }

                if (i === responses.length - 1) {
                    setMyRequestList(finalRequestData);
                }
            }
        }
        else {
            setMyRequestList([])
        }
    }

    //Load Summary Parameters Data
    async function LoadSummaryParametersData(financialYearId, employeeId, selectedQuarter) {

        if (financialYearId && employeeId && parseInt(selectedQuarter) > 0) {
            setQuarter(selectedQuarter)
            //get FY
            // var FYResponse = await DataStore.query(FinancialYear, financialYearId);
            var FYResponse = await graphQLFindRecordById('getFinancialYear', financialYearId);


            // //get parameters
            // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
            //     c.employee_id.eq(employeeId),
            //     c.report_manager_employee_id.notContains(employeeId),
            //     c.financial_year_id.eq(financialYearId)
            // ]));
            var targetsResponses = await graphQLGetAllData('listTargets', [
                { employee_id: { eq: employeeId } },
                { report_manager_employee_id: { notContains: employeeId } },
                { financial_year_id: { eq: financialYearId } }
            ]);

            //get parameters weightage
            var relationshipResponse = []
            var currentTargetDetails = []
            if (targetsResponses.length > 0) {
                // var relationshipResponse = await DataStore.query(Relationship, (c) => c.and(c => [
                //     c.role_id.eq(targetsResponses[0].role_id),
                //     c.financial_year_id.eq(financialYearId)
                // ]));
                var relationshipResponse = await graphQLGetAllData('listRelationships', [
                    { role_id: { eq: targetsResponses[0].role_id } },
                    { financial_year_id: { eq: financialYearId } },
                ]);


                // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
                //     c.target_id.eq(targetsResponses[0].id),
                //     c.parameter_id.notContains(PARAMETER_IDs.DIO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DPO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DSO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DVRO),
                // ]));
                var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                    { target_id: { eq: targetsResponses[0].id } },
                    { parameter_id: { notContains: PARAMETER_IDs.DIO } },
                    { parameter_id: { notContains: PARAMETER_IDs.DPO } },
                    { parameter_id: { notContains: PARAMETER_IDs.DSO } },
                    { parameter_id: { notContains: PARAMETER_IDs.DVRO } }
                ]);


            }

            var relationshipDetailResponses = []
            if (relationshipResponse.length > 0) {
                // relationshipDetailResponses = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationshipResponse[0].id));
                relationshipDetailResponses = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: relationshipResponse[0].id } }]);

            }

            var _tempArray = [];

            //Annual Target Details

            var month1 = ""; var month2 = ""; var month3 = "";
            currentTargetDetails.forEach(async (currentItem, i) => {
                //Status
                let status = ""
                if (currentItem.is_accepted_by_employee && !currentItem.is_approve) { status = "Awaiting approval" }
                else if (currentItem.isSendForRevisionByReportingManage) { status = "Revision" }
                else if (currentItem.is_approve) { status = "Approved" }
                else if (currentItem.is_revision_by_employee) { status = "Requested for Revision" }
                else { status = "Open" }

                //get parameters details
                // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(currentItem.parameter_id));
                var parameterResponses = await graphQLFindRecordById('getParameters', currentItem.parameter_id);


                var getWeightage = relationshipDetailResponses.filter((e) => e.parameter_id === currentItem.parameter_id)

                var actualPerformance = 0; var actualAch = 0; var wtAch = 0;
                var actualPerformance = 0;
                var parameterBaseType = parameterResponses.type === PARAMETERS_TYPES.Days ? PARAMETERS_TYPES_NAMES.D : parameterResponses.type === PARAMETERS_TYPES.Amount ? PARAMETERS_TYPES_NAMES.A : parameterResponses.type === PARAMETERS_TYPES.Number ? PARAMETERS_TYPES_NAMES.N : PARAMETERS_TYPES_NAMES.P

                var planActual = getActualValue(currentItem, "Quarter 4", parameterBaseType)

                if (planActual) { actualPerformance = parseInt(planActual) < 0 ? "(" + Math.abs(Math.round(planActual)) + ")" : Math.round(planActual) } else { actualPerformance = 0 }


                // var getPlanDetails = await DataStore.query(Plan, (c) => c.target_detail_id.eq(currentItem.id));
                var getPlanDetails = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: currentItem.id } }]);


                var getTargetOnParameterType = getTargetBasedOnParameterType(getPlanDetails, "Quarter 4", parameterBaseType)

                actualAch = await calculateConsolidatedScore(planActual, getTargetOnParameterType)
                if (getWeightage[0]) {
                    wtAch = await calculateWeightageAchievement(getWeightage[0]?.weightage, actualAch)
                }

                //Check iF Negative
                actualAch = await checkIfNegativeValue(actualAch)
                wtAch = await checkIfNegativeValue(wtAch)


                if (selectedQuarter === "1") {
                    month1 = "m4_actual_value"
                    month2 = "m5_actual_value"
                    month3 = "m6_actual_value"
                } else if (selectedQuarter === "2") {
                    month1 = "m7_actual_value"
                    month2 = "m8_actual_value"
                    month3 = "m9_actual_value"
                } else if (selectedQuarter === "3") {
                    month1 = "m10_actual_value"
                    month2 = "m11_actual_value"
                    month3 = "m12_actual_value"
                } else if (selectedQuarter === "4") {
                    month1 = "m1_actual_value"
                    month2 = "m2_actual_value"
                    month3 = "m3_actual_value"
                }

                if (parameterResponses) {
                    let data = {
                        "target_detail_id": currentItem.id,
                        "financial_year_id": FYResponse.value,
                        "financial_year": FYResponse.label,
                        "parameter_id": currentItem.parameter_id,
                        "parameter_name": parameterResponses.name,
                        "parameter_type": parameterResponses.type === "A" ? "$" : parameterResponses.type === "D" ? "Days" : parameterResponses.type === "P" ? "%" : parameterResponses.type === "N" ? "Number" : "",
                        "target_value": currentItem.target_value ? currentItem.target_value : 0,
                        "actualPerformance": actualPerformance !== undefined ? actualPerformance : 0,
                        "actualAch": actualAch ? actualAch + "%" : "0%",
                        "wtAch": wtAch ? wtAch + "%" : "0%",
                        "isMarkAsComplete": currentItem.isMarkAsComplete,
                        "is_accepted_by_employee": currentItem.is_accepted_by_employee,
                        "is_approve": currentItem.is_approve,
                        "is_revision_by_employee": currentItem.is_revision_by_employee,
                        "isSendForRevisionByReportingManage": currentItem.isSendForRevisionByReportingManage,
                        "parameter_weightage": getWeightage.length > 0 ? getWeightage[0].weightage : "-",
                        "isPlanMandatory": getWeightage.length > 0 ? 1 : 0,
                        "status": status,
                        "q1_target_value": currentItem.q1_target_value,
                        "q2_target_value": currentItem.q2_target_value,
                        "q3_target_value": currentItem.q3_target_value,
                        "q4_target_value": currentItem.q4_target_value,
                        "q1_actual_value": getActualValue(currentItem, "Quarter 1", parameterBaseType),
                        "q2_actual_value": getActualValue(currentItem, "Quarter 2", parameterBaseType),
                        "q3_actual_value": getActualValue(currentItem, "Quarter 3", parameterBaseType),
                        "q4_actual_value": getActualValue(currentItem, "Quarter 4", parameterBaseType),
                        [month1]: Math.round(currentItem[month1]),
                        [month2]: Math.round(currentItem[month2]),
                        [month3]: Math.round(currentItem[month3]),
                    }
                    _tempArray.push(data)
                }

                if (i === currentTargetDetails.length - 1) {
                    // console.log(JSON.stringify(_tempArray))
                    let sortArray = _tempArray.sort((a, b) => (a.parameter_name > b.parameter_name ? 1 : -1))
                    setPerformanceSummaryList(sortArray)

                }
            })
        } else {
            toast.error('Data not found.');
            setViewPopUp(0);
            setAssignPopUp(0)
        }
    }

    const actualTargetActions = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number" min="1"
                    // disabled={isDisableMode}
                    value={row.targetCount}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                // onChange={(e) => { updateTargetValue(row, e.target.value) }}
                />
                <div className='w-14'>
                    {row.type}
                </div>
            </div>
        )
    }

    useEffect(() => {
        onLoad();
        bindList()
    }, []);

    useEffect(() => {
        if (initialYear.value) {
            bindList()
            myRequests(initialYear);
        }
    }, [initialYear])


    const showTarget = (rowData) => {
        if (rowData.target_value !== "") {
            return (
                <React.Fragment>
                    {
                        rowData.parameter_type === PARAMETERS_TYPES_NAMES.A ?
                            <label>{rowData.parameter_type}{commaSeparated(parseFloat(rowData.target_value).toFixed(2))} </label>
                            :
                            rowData.parameter_type === PARAMETERS_TYPES_NAMES.P ?
                                <label>{parseFloat(rowData.target_value).toFixed(2)}{rowData.parameter_type}</label>
                                :
                                <label>{parseFloat(rowData.target_value).toFixed(2)} {rowData.parameter_type}</label>
                    }
                </React.Fragment>
            )
        }
    }

    const showActualTarget = (rowData) => {
        if (rowData.actualPerformance !== "") {
            return (
                <React.Fragment>
                    {
                        rowData.parameter_type === PARAMETERS_TYPES_NAMES.A ? <label>{rowData.parameter_type}{commaSeparated(parseFloat(rowData.actualPerformance).toFixed(2))} </label> :
                            rowData.parameter_type === PARAMETERS_TYPES_NAMES.P ? <label>{rowData.actualPerformance}{rowData.parameter_type}</label> :
                                <label>{(rowData.actualPerformance)} {rowData.parameter_type}</label>
                    }
                </React.Fragment>
            )
        }
    }

    const TargetActions = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number"

                    value={row.actualPerformance}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value) }}
                />
                <div className='w-14'>
                    {row.parameter_type}
                </div>
            </div>
        )
    }

    // ------------------------------------ monthly actuals value ------------------------------------
    const m4ActualValueEditor = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number"

                    value={row.m4_actual_value}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value, "m4_actual_value") }}
                />
                <div className='w-14'>
                    {row.parameter_type}
                </div>
            </div>
        )
    }

    const m5ActualValueEditor = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number"

                    value={row.m5_actual_value}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value, "m5_actual_value") }}
                />
                <div className='w-14'>
                    {row.parameter_type}
                </div>
            </div>
        )
    }

    const m6ActualValueEditor = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number"

                    value={row.m6_actual_value}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value, "m6_actual_value") }}
                />
                <div className='w-14'>
                    {row.parameter_type}
                </div>
            </div>
        )
    }

    const m7ActualValueEditor = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number"

                    value={row.m7_actual_value}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value, "m7_actual_value") }}
                />
                <div className='w-14'>
                    {row.parameter_type}
                </div>
            </div>
        )
    }
    const m8ActualValueEditor = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number"

                    value={row.m8_actual_value}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value, "m8_actual_value") }}
                />
                <div className='w-14'>
                    {row.parameter_type}
                </div>
            </div>
        )
    }
    const m9ActualValueEditor = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number"

                    value={row.m9_actual_value}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value, "m9_actual_value") }}
                />
                <div className='w-14'>
                    {row.parameter_type}
                </div>
            </div>
        )
    }

    const m10ActualValueEditor = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number"

                    value={row.m10_actual_value}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value, "m10_actual_value") }}
                />
                <div className='w-14'>
                    {row.parameter_type}
                </div>
            </div>
        )
    }
    const m11ActualValueEditor = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number"

                    value={row.m11_actual_value}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value, "m11_actual_value") }}
                />
                <div className='w-14'>
                    {row.parameter_type}
                </div>
            </div>
        )
    }
    const m12ActualValueEditor = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number"

                    value={row.m12_actual_value}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value, "m12_actual_value") }}
                />
                <div className='w-14'>
                    {row.parameter_type}
                </div>
            </div>
        )
    }
    const m1ActualValueEditor = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number"

                    value={row.m1_actual_value}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value, "m1_actual_value") }}
                />
                <div className='w-14'>
                    {row.parameter_type}
                </div>
            </div>
        )
    }
    const m2ActualValueEditor = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number"

                    value={row.m2_actual_value}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value, "m2_actual_value") }}
                />
                <div className='w-14'>
                    {row.parameter_type}
                </div>
            </div>
        )
    }
    const m3ActualValueEditor = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number"

                    value={row.m3_actual_value}
                    className="w-fit text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value, "m3_actual_value") }}
                />
                <div className='w-14'>
                    {row.parameter_type}
                </div>
            </div>
        )
    }
    // ------------------------------------ monthly actuals value ------------------------------------

    const updateTargetValue = async (rowData, targetCount, changeValueOf) => {
        let findObj = [...performanceSummaryList]
        for (let i = 0; i < findObj.length; i++) {
            if (findObj[i].target_detail_id === rowData.target_detail_id) {
                findObj[i][changeValueOf] = targetCount;
                // var actualAch = await calculateConsolidatedScore(targetCount, findObj[i].target_value)
                // var wtAch = await  calculateWeightageAchievement(findObj[i].parameter_weightage, actualAch)
                // findObj[i].actualAch = actualAch;
                // findObj[i].wtAch = wtAch;
                break; // Exit the loop after finding the matching target_detail_id
            }
        }
        setPerformanceSummaryList(findObj)
    }


    const updateActuals = async () => {
        if (quarter === "1") {
            alertify.confirm('Confirmation', 'Do you want to update actuals?', async function () {
                for (var i = 0; i < performanceSummaryList.length; i++) {
                    // let originalObj = await DataStore.query(TargetDetails, performanceSummaryList[i].target_detail_id);
                    let originalObj = await graphQLFindRecordById('getTargetDetails', performanceSummaryList[i].target_detail_id);

                    // await DataStore.save(
                    //     TargetDetails.copyOf(originalObj, (updated) => {
                    //         updated.m4_actual_value = String(performanceSummaryList[i]["m4_actual_value"]);
                    //         updated.m5_actual_value = String(performanceSummaryList[i]["m5_actual_value"]);
                    //         updated.m6_actual_value = String(performanceSummaryList[i]["m6_actual_value"]);
                    //     })
                    // );
                    await API.graphql({
                        query: mutations.updateTargetDetails,
                        variables: {
                            input: {
                                id: originalObj.id,
                                m4_actual_value: String(performanceSummaryList[i]["m4_actual_value"]),
                                m5_actual_value: String(performanceSummaryList[i]["m5_actual_value"]),
                                m6_actual_value: String(performanceSummaryList[i]["m6_actual_value"]),
                                _version: originalObj._version,
                            }
                        }
                    });
                    if (i === performanceSummaryList.length - 1) {
                        // let originalObj = await DataStore.query(ChangeRequest, employeeRequestorId);
                        let originalObj = await graphQLFindRecordById('getChangeRequest', employeeRequestorId);

                        // await DataStore.save(
                        //     ChangeRequest.copyOf(originalObj, (updated) => {
                        //         updated.hrRequestStatus = HR_CHANGE_REQUEST_STATUS.Approved;
                        //     })
                        // );

                        await API.graphql({
                            query: mutations.updateChangeRequest,
                            variables: {
                                input: {
                                    id: originalObj.id,
                                    hrRequestStatus: HR_CHANGE_REQUEST_STATUS.Approved,
                                    _version: originalObj._version,
                                }
                            }
                        });
                        toast.success("Saved.")
                        clearAll()
                        myRequests(selectedYear)
                    }
                }
            }
                , function () { });
        }

        if (quarter === "2") {
            alertify.confirm('Confirmation', 'Do you want to update actuals?', async function () {
                for (var i = 0; i < performanceSummaryList.length; i++) {
                    // let originalObj = await DataStore.query(TargetDetails, performanceSummaryList[i].target_detail_id);
                    let originalObj = await graphQLFindRecordById('getTargetDetails', performanceSummaryList[i].target_detail_id);

                    // await DataStore.save(
                    //     TargetDetails.copyOf(originalObj, (updated) => {
                    //         updated.m7_actual_value = String(performanceSummaryList[i]["m7_actual_value"]);
                    //         updated.m8_actual_value = String(performanceSummaryList[i]["m8_actual_value"]);
                    //         updated.m9_actual_value = String(performanceSummaryList[i]["m9_actual_value"]);
                    //     })
                    // );

                    await API.graphql({
                        query: mutations.updateTargetDetails,
                        variables: {
                            input: {
                                id: originalObj.id,
                                m7_actual_value: String(performanceSummaryList[i]["m7_actual_value"]),
                                m8_actual_value: String(performanceSummaryList[i]["m8_actual_value"]),
                                m9_actual_value: String(performanceSummaryList[i]["m9_actual_value"]),
                                _version: originalObj._version,
                            }
                        }
                    });


                    if (i === performanceSummaryList.length - 1) {
                        // let originalObj = await DataStore.query(ChangeRequest, employeeRequestorId);
                        let originalObj = await graphQLFindRecordById('getChangeRequest', employeeRequestorId);


                        // await DataStore.save(
                        //     ChangeRequest.copyOf(originalObj, (updated) => {
                        //         updated.hrRequestStatus = HR_CHANGE_REQUEST_STATUS.Approved;
                        //     })
                        // );
                        await API.graphql({
                            query: mutations.updateChangeRequest,
                            variables: {
                                input: {
                                    id: originalObj.id,
                                    hrRequestStatus: HR_CHANGE_REQUEST_STATUS.Approved,
                                    _version: originalObj._version,
                                }
                            }
                        });
                        toast.success("Saved.")
                        clearAll()
                        myRequests(selectedYear)
                    }
                }
            }
                , function () { });
        }
        if (quarter === "3") {
            alertify.confirm('Confirmation', 'Do you want to update actuals?', async function () {
                for (var i = 0; i < performanceSummaryList.length; i++) {
                    // let originalObj = await DataStore.query(TargetDetails, performanceSummaryList[i].target_detail_id);
                    let originalObj = await graphQLFindRecordById('getTargetDetails', performanceSummaryList[i].target_detail_id);

                    // await DataStore.save(
                    //     TargetDetails.copyOf(originalObj, (updated) => {
                    //         updated.m10_actual_value = String(performanceSummaryList[i]["m10_actual_value"]);
                    //         updated.m11_actual_value = String(performanceSummaryList[i]["m11_actual_value"]);
                    //         updated.m12_actual_value = String(performanceSummaryList[i]["m12_actual_value"]);
                    //     })
                    // );

                    await API.graphql({
                        query: mutations.updateTargetDetails,
                        variables: {
                            input: {
                                id: originalObj.id,
                                m10_actual_value: String(performanceSummaryList[i]["m10_actual_value"]),
                                m11_actual_value: String(performanceSummaryList[i]["m11_actual_value"]),
                                m12_actual_value: String(performanceSummaryList[i]["m12_actual_value"]),
                                _version: originalObj._version,
                            }
                        }
                    });

                    if (i === performanceSummaryList.length - 1) {
                        // let originalObj = await DataStore.query(ChangeRequest, employeeRequestorId);
                        let originalObj = await graphQLFindRecordById('getChangeRequest', employeeRequestorId);

                        // await DataStore.save(
                        //     ChangeRequest.copyOf(originalObj, (updated) => {
                        //         updated.hrRequestStatus = HR_CHANGE_REQUEST_STATUS.Approved;
                        //     })
                        // );
                        await API.graphql({
                            query: mutations.updateChangeRequest,
                            variables: {
                                input: {
                                    id: originalObj.id,
                                    hrRequestStatus: HR_CHANGE_REQUEST_STATUS.Approved,
                                    _version: originalObj._version,
                                }
                            }
                        });
                        toast.success("Saved.")
                        clearAll()
                        myRequests(selectedYear)
                    }
                }
            }
                , function () { });
        }
        if (quarter === "4") {
            alertify.confirm('Confirmation', 'Do you want to update actuals?', async function () {
                for (var i = 0; i < performanceSummaryList.length; i++) {
                    // let originalObj = await DataStore.query(TargetDetails, performanceSummaryList[i].target_detail_id);
                    let originalObj = await graphQLFindRecordById('getTargetDetails', performanceSummaryList[i].target_detail_id);

                    // await DataStore.save(
                    //     TargetDetails.copyOf(originalObj, (updated) => {
                    //         updated.m1_actual_value = String(performanceSummaryList[i]["m1_actual_value"]);
                    //         updated.m2_actual_value = String(performanceSummaryList[i]["m2_actual_value"]);
                    //         updated.m3_actual_value = String(performanceSummaryList[i]["m3_actual_value"]);
                    //     })
                    // );

                    await API.graphql({
                        query: mutations.updateTargetDetails,
                        variables: {
                            input: {
                                id: originalObj.id,
                                m1_actual_value: String(performanceSummaryList[i]["m1_actual_value"]),
                                m2_actual_value: String(performanceSummaryList[i]["m2_actual_value"]),
                                m3_actual_value: String(performanceSummaryList[i]["m3_actual_value"]),
                                _version: originalObj._version,
                            }
                        }
                    });

                    if (i === performanceSummaryList.length - 1) {
                        // let originalObj = await DataStore.query(ChangeRequest, employeeRequestorId);
                        let originalObj = await graphQLFindRecordById('getChangeRequest', employeeRequestorId);

                        // await DataStore.save(
                        //     ChangeRequest.copyOf(originalObj, (updated) => {
                        //         updated.hrRequestStatus = HR_CHANGE_REQUEST_STATUS.Approved;
                        //     })
                        // );

                        await API.graphql({
                            query: mutations.updateChangeRequest,
                            variables: {
                                input: {
                                    id: originalObj.id,
                                    hrRequestStatus: HR_CHANGE_REQUEST_STATUS.Approved,
                                    _version: originalObj._version,
                                }
                            }
                        });

                        toast.success("Saved.")
                        clearAll()
                        myRequests(selectedYear)
                    }
                }
            }
                , function () { });
        }
    }

    const declineRequest = async () => {
        alertify.confirm('Confirmation', 'Do you want to decline this request?', async function () {
            // let originalObj = await DataStore.query(ChangeRequest, employeeRequestorId);
            let originalObj = await graphQLFindRecordById('getChangeRequest', employeeRequestorId);

            // await DataStore.save(
            //     ChangeRequest.copyOf(originalObj, (updated) => {
            //         updated.hrRequestStatus = HR_CHANGE_REQUEST_STATUS.Declined;
            //     })
            // );
            await API.graphql({
                query: mutations.updateChangeRequest,
                variables: {
                    input: {
                        id: originalObj.id,
                        hrRequestStatus: HR_CHANGE_REQUEST_STATUS.Declined,
                        _version: originalObj._version,
                    }
                }
            });
            toast.success("Saved.")
            clearAll()
            myRequests(selectedYear)
        }
            , function () { });
    }


    //UI Part
    return (
        isAuthenticated ? (
            <div className="pt-16">
                <TopNavBar />
                <LeftSideBar />
                <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Change Request</div>
                        </div>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/hr/changerequest/planningperiod" >Planning Period</Link></li>
                                    <li><Link className={"active"}>Override Actuals</Link></li>
                                </ul>
                            </div>

                            <div className="flex justify-end  items-center">
                                <Select
                                    options={financialYearList}
                                    value={financialYear}
                                    onChange={e => {
                                        setMyRequestList([]);
                                        myRequests(e)
                                        setFinancialYear(e)
                                        reactLocalStorage.setObject('selectedYear', e);
                                    }}
                                    className="mt-2 mb-2 my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                        </div>

                        <div className=" rounded-lg mt-5 ">
                            {myRequestList.length > 0 ?
                                <DataTable
                                    paginator value={myRequestList} responsiveLayout="scroll" style={{ width: '93vw' }}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                >
                                    <Column field="requestNo" header="Request ID" sortable filter filterPlaceholder="Search by Request ID"></Column>
                                    <Column field="EmployeeID" header="Employee ID" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                    <Column field="Name" header="Name" sortable filter filterPlaceholder="Search by Name"></Column>
                                    <Column field="Role" header="Role" sortable filter filterPlaceholder="Search by Role"></Column>
                                    <Column field="vertical" header="Vertical" sortable filter filterPlaceholder="Search by Vertical"></Column>
                                    <Column field="SBU" header="SBU" sortable filter filterPlaceholder="Search by SBU"></Column>
                                    <Column field="ReportingManager" header="Reporting Manager" sortable filter filterPlaceholder="Search by Reporting Manager"></Column>
                                    <Column field='DateSubmitted' header="Date Submitted" filter filterPlaceholder="Search by Submitted Date" ></Column>
                                    <Column field='DateApproved' header="Date Approved" filter filterPlaceholder="Search by Approved Date"  ></Column>
                                    <Column field='selectedQuarter' header="Quarter" ></Column>
                                    <Column field='Status' header="Status" body={requestsStatus} filter filterPlaceholder="Search by Status" ></Column>
                                    <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnRoles} ></Column>
                                </DataTable> :
                                <div className='text-center py-8' ><ProgressSpinner /></div>
                            }
                        </div>
                    </div>


                    {/* VIEW OA REQUEST */}
                    <div id="selector" className={isViewPopUp === 1 ? 'overlay' : ''}></div>
                    {
                        isViewPopUp === 1 ?
                            <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                                <div >
                                    <img src={popupHeaderImg} alt="" style={{ width: "50vw" }} />
                                    <div className="absolute right-0 left-0 top-0 w-full text-white">
                                        <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                            <div className="text-md"><h6 className='text-[white]'>Change Request</h6></div>
                                            <div><Link onClick={() => setViewPopUp(0)} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                        </div>

                                        <div className="px-6 mt-8">
                                            <span>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <div className="flex items-center align-center">
                                                        <Menu.Button className='flex items-center'>
                                                        </Menu.Button>
                                                    </div>
                                                </Menu>
                                                <div className="text-sm text-[white]">Request</div>
                                                <div className="text-2xl">View Change Request
                                                    <div className="float-right text-sm mt-2">Request ID: {employeeRequestorCode} </div>
                                                </div>
                                            </span>
                                        </div>

                                    </div>
                                    <div className="p-6 height-custtom ">
                                        <div className="bg-[#EEF8F4] my-4 text-sm p-4 rounded-md dark:bg-[#252423] ">
                                            <div className="grid grid-cols-2 gap-3">
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px]">Requestor Name</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{requestorName}</div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px] px-12">Employee ID</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{employeeNo}</div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px] ">Reporting Manager</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{reportingManagerName}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="flex justify-between planning_period  mt-5">
                                            <li className="text-center">
                                                <div className="text-sm text-[#344054] mb-3 min-w-[100px] text-center dark:text-[white]">Requested</div>
                                                <div className="bg-[#029046] w-7 h-7 rounded-full text-white mx-auto flex justify-center items-center">
                                                </div>
                                                <div className="text-sm mt-2 text-[#344054]  dark:text-[white]">{requestedDate}</div>

                                            </li>
                                            <li className="text-center">
                                                <div className="text-sm text-[#344054] mb-3 min-w-[100px] text-center  dark:text-[white]">
                                                    Approval</div>
                                                <div className="bg-[#029046] w-7 h-7 rounded-full text-white mx-auto flex justify-center items-center">
                                                </div>
                                                <div className="text-sm mt-2 text-[#344054] dark:text-[white]">{reportingManagerApprovedDate}</div>

                                            </li>
                                            <li className="text-center">
                                                <div className="text-sm text-[#344054] mb-3 min-w-[100px] text-center  dark:text-[white]">HR Review/Action</div>
                                                <div className="bg-[#029046] w-7 h-7 rounded-full text-white mx-auto flex justify-center items-center">
                                                </div>
                                                <div className="text-sm mt-2 text-[#344054] dark:text-[white]">{hRApproveDate}</div>
                                            </li>
                                        </ul>

                                        <div className="border mt-10 py-6 px-10 rounded-md">

                                        </div>

                                        <div className="text-md mt-8  dark:text-[white] font-semibold text-md mb-2">HR Admin Action</div>
                                        <div className='flex'>
                                            <input type="checkbox" onChange={() => checkBoxChecked()} />
                                            <div className="text-sm mt-1 text-[#344054] font-[Inter]  dark:text-[white] px-2 pb-1">Override Actuals</div>
                                        </div>

                                        {
                                            openOASummary ?
                                                <div className='pt-5 flex grid   align-center  '>
                                                    <h3 className=" dark:text-[white]"> Performance Summary</h3>

                                                    <DataTable
                                                        style={{ width: '47vw' }}
                                                        className="mt-3"
                                                        value={performanceSummaryList}
                                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                                    >
                                                        <Column field="parameter_name" header="Key Parameters"></Column>
                                                        <Column field="parameter_weightage" header="Weight(%)"></Column>
                                                        <Column field="target_value" header="Annual Target" body={showTarget} style={{ width: '12%' }}></Column>
                                                        {/* <Column field="actualPerformance" header="Actual" body={TargetActions} style={{ width: '5%' }}></Column> */}
                                                        {/* <Column field="actualPerformance" header="Actual" body={showActualTarget} style={{ width: '5%' }}></Column>
                                                        <Column field="actualAch" header="Achievement" style={{ width: '5%' }}></Column>
                                                        <Column field="wtAch" header="Wt_Achievement" style={{ width: '8%' }}></Column> */}

                                                        {/* <Column field="actualPerformance" header="Actual" body={TargetActions} style={{ width: '35%' }}></Column> */}
                                                        {/* --------------------- Q1 --------------------- */}
                                                        {
                                                            quarter === "1" &&
                                                            <Column header="Apr" field='m4_actual_value' ></Column>
                                                        }
                                                        {
                                                            quarter === "1" &&
                                                            <Column header="May" field='m5_actual_value'  ></Column>
                                                        }
                                                        {
                                                            quarter === "1" &&
                                                            <Column header="Jun" field='m6_actual_value' ></Column>
                                                        }
                                                        {/* --------------------- Q1 --------------------- */}

                                                        {/* --------------------- Q2 --------------------- */}
                                                        {
                                                            quarter === "2" &&
                                                            <Column header="Jul" field='m7_actual_value' ></Column>
                                                        }
                                                        {
                                                            quarter === "2" &&
                                                            <Column header="Aug" field='m8_actual_value'></Column>
                                                        }
                                                        {
                                                            quarter === "2" &&
                                                            <Column header="Sept" field='m9_actual_value'></Column>
                                                        }
                                                        {/* --------------------- Q2 --------------------- */}


                                                        {/* --------------------- Q3 --------------------- */}

                                                        {
                                                            quarter === "3" &&
                                                            <Column header="Oct" field='m10_actual_value'></Column>
                                                        }
                                                        {
                                                            quarter === "3" &&
                                                            <Column header="Nov" field='m11_actual_value' ></Column>
                                                        }
                                                        {
                                                            quarter === "3" &&
                                                            <Column header="Dec" field='m12_actual_value' ></Column>
                                                        }

                                                        {/* --------------------- Q3 --------------------- */}


                                                        {/* --------------------- Q4 --------------------- */}
                                                        {
                                                            quarter === "4" &&
                                                            <Column header="Jan" field='m1_actual_value' ></Column>
                                                        }
                                                        {
                                                            quarter === "4" &&
                                                            <Column header="Feb" field='m2_actual_value' ></Column>
                                                        }
                                                        {
                                                            quarter === "4" &&
                                                            <Column header="Mar" field='m3_actual_value'></Column>
                                                        }
                                                        {/* --------------------- Q4 --------------------- */}


                                                    </DataTable>
                                                </div> : null
                                        }
                                        {/* <div className="border mt-10 py-6 px-10 rounded-mds">
                                            <div className="flex space-x-2">
                                                <div className="w-20 mx-auto">
                                                    <img src={profilepicture1} className="rounded-full w-14 h-14 mx-auto" alt="" />
                                                </div>
                                                <div className="bg-[#F2F4F7] p-4 rounded-lg w-full dark:bg-[#252423] dark:text-[white]">
                                                    <div className="flex items-center justify-between ">
                                                        <div className="font-medium text-md mb-2 text-[#101828]  dark:text-[white]">Requestor Remarks, </div>
                                                        <div className="text-[#667085] text-xs  dark:text-[white]">12 hrs ago</div>
                                                    </div>
                                                    <div className="text-sm text-[#344054]  dark:text-[white]">Sample comment about the target value and quarter plan... Sample comment about
                                                        the target value and quarter plan... Sample comment about the target value and quarter plan...
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>



                                    <div className="lg:relative left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                                        <div className="flex justify-end gap-3">

                                            <div><Link
                                                className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2" onClick={() => setViewPopUp(0)}>Close</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null
                    }


                    {/* EDIT OA REQUEST */}
                    <div id="selector" className={isEditPopUp === 1 ? 'overlay' : ''}></div>
                    {
                        isEditPopUp === 1 ?
                            <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                                <div className="">
                                    <img src={popupHeaderImg} alt="" style={{ width: "50vw" }} />
                                    <div className="absolute right-0 left-0 top-0 w-full text-white">
                                        <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                            <div className="text-md"><h6 className='text-[white]'>Edit</h6></div>
                                            <div><Link onClick={() => setAssignPopUp(0)} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                        </div>

                                        <div className="px-6 mt-8">
                                            <span>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <div className="flex items-center align-center">
                                                        <Menu.Button className='flex items-center'>
                                                        </Menu.Button>
                                                    </div>
                                                </Menu>
                                                <div className="text-sm text-[white] ">Request</div>
                                                <div className="text-2xl">Manage Change Request
                                                    <div className="float-right text-sm mt-2">Request ID: {employeeRequestorCode} </div>
                                                </div>

                                            </span>
                                        </div>

                                    </div>
                                    <div className="p-6 height-custtom ">
                                        <div className="bg-[#EEF8F4] my-4 text-sm p-4 rounded-md dark:bg-[#252423] ">
                                            <div className="grid grid-cols-2 gap-3">
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px]">Requestor Name</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{requestorName}</div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px] px-12">Employee ID</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{employeeNo}</div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px] ">Reporting Manager</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{reportingManagerName}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <ul className="flex justify-between planning_period  mt-5">
                                            <li className="text-center">
                                                <div className="text-sm text-[#344054] mb-3 min-w-[100px] text-center">Requested</div>
                                                <div className="bg-[#029046] w-7 h-7 rounded-full text-white mx-auto flex justify-center items-center">
                                                </div>
                                                <div className="text-sm mt-2 text-[#344054]  dark:text-[white]">{requestedDate}</div>

                                            </li>
                                            <li className="text-center">
                                                <div className="text-sm text-[#344054] mb-3 min-w-[100px] text-center">
                                                    Approval</div>
                                                <div className="bg-[#029046] w-7 h-7 rounded-full text-white mx-auto flex justify-center items-center">
                                                </div>
                                                <div className="text-sm mt-2 text-[#344054] dark:text-[white]">{reportingManagerApprovedDate}</div>

                                            </li>
                                            <li className="text-center">
                                                <div className="text-sm text-[#344054] mb-3 min-w-[100px] text-center">HR Review/Action</div>
                                                <div className="bg-[#B3DDC7] w-7 h-7 rounded-full text-white mx-auto flex justify-center items-center">
                                                </div>
                                                <div className="text-sm mt-2 text-[#344054] dark:text-[white]">{hRApproveDate}</div>
                                            </li>
                                        </ul>



                                        <div className="border mt-10 py-6 px-10 rounded-md">

                                        </div>
                                        <div className="text-md mt-8  dark:text-[white] font-semibold text-md mb-2">HR Admin Action</div>
                                        <div className='flex'>
                                            <input type="checkbox" onChange={() => checkBoxChecked()} />
                                            <div className="text-sm mt-1 text-[#344054] font-[Inter]  dark:text-[white] px-2 pb-1">Override Actuals</div>
                                        </div>

                                        {
                                            openOASummary ?
                                                <div className='pt-5 flex grid   align-center  '>
                                                    <h3 className=" dark:text-[white]"> Performance Summary</h3>

                                                    <DataTable
                                                        className="mt-3"
                                                        value={performanceSummaryList}
                                                    >
                                                        <Column field="parameter_name" header="Key Parameters" style={{ width: '5%' }} ></Column>
                                                        <Column field="parameter_weightage" header="Weight(%)" style={{ width: '5%' }}></Column>
                                                        <Column field="target_value" header="Annual Target" body={showTarget} ></Column>
                                                        {/* <Column field="actualPerformance" header="Actual" body={TargetActions} style={{ width: '35%' }}></Column> */}
                                                        {/* --------------------- Q1 --------------------- */}
                                                        {
                                                            quarter === "1" &&
                                                            <Column header="Apr" body={m4ActualValueEditor} style={{ width: '100%' }} ></Column>
                                                        }
                                                        {
                                                            quarter === "1" &&
                                                            <Column header="May" body={m5ActualValueEditor} style={{ width: '75%' }} ></Column>
                                                        }
                                                        {
                                                            quarter === "1" &&
                                                            <Column header="Jun" body={m6ActualValueEditor} style={{ width: '75%' }}></Column>
                                                        }
                                                        {/* --------------------- Q1 --------------------- */}

                                                        {/* --------------------- Q2 --------------------- */}
                                                        {
                                                            quarter === "2" &&
                                                            <Column header="Jul" body={m7ActualValueEditor} style={{ width: '75%' }} ></Column>
                                                        }
                                                        {
                                                            quarter === "2" &&
                                                            <Column header="Aug" body={m8ActualValueEditor} style={{ width: '75%' }} ></Column>
                                                        }
                                                        {
                                                            quarter === "2" &&
                                                            <Column header="Sept" body={m9ActualValueEditor} style={{ width: '75%' }}></Column>
                                                        }
                                                        {/* --------------------- Q2 --------------------- */}


                                                        {/* --------------------- Q3 --------------------- */}

                                                        {
                                                            quarter === "3" &&
                                                            <Column header="Oct" body={m10ActualValueEditor} style={{ width: '75%' }} ></Column>
                                                        }
                                                        {
                                                            quarter === "3" &&
                                                            <Column header="Nov" body={m11ActualValueEditor} style={{ width: '75%' }} ></Column>
                                                        }
                                                        {
                                                            quarter === "3" &&
                                                            <Column header="Dec" body={m12ActualValueEditor} style={{ width: '75%' }}></Column>
                                                        }

                                                        {/* --------------------- Q3 --------------------- */}


                                                        {/* --------------------- Q4 --------------------- */}
                                                        {
                                                            quarter === "4" &&
                                                            <Column header="Jan" body={m1ActualValueEditor} style={{ width: '75%' }} ></Column>
                                                        }
                                                        {
                                                            quarter === "4" &&
                                                            <Column header="Feb" body={m2ActualValueEditor} style={{ width: '75%' }} ></Column>
                                                        }
                                                        {
                                                            quarter === "4" &&
                                                            <Column header="Mar" body={m3ActualValueEditor} style={{ width: '75%' }}></Column>
                                                        }
                                                        {/* --------------------- Q4 --------------------- */}

                                                    </DataTable>
                                                </div> : null
                                        }

                                        <div className='mt-5 mb-2'>
                                            <textarea
                                                // value={definition}
                                                rows="5"
                                                // onChange={e => setDefinition(e.target.value)}
                                                type="text" className="text-[16px] rounded-lg   flex-1  border border-[#C6CBD2] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]"

                                                placeholder="Add Comment..." />
                                        </div>

                                    </div>
                                    <div className="lg:relative left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                                        <div className="flex justify-end gap-3">
                                            <div><Link onClick={() => declineRequest()} className="bg-white 
                                                dark:text-[white] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                            >Decline</Link></div>
                                            <div><Link
                                                className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2" onClick={() => updateActuals()}>Update Actuals</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }


                    {/* APPROVE POPUP */}
                    <div id="selector" className={isViewApprovePopUp === 1 ? 'overlay' : ''}></div>
                    {
                        isViewApprovePopUp === 1 ?
                            <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="displayPopupCont" className="sidenav">
                                <div className="popup_display_img"><img src={imageDeployedLarge} className="mx-auto mt-8" alt="" /></div>
                                <div className="text-xl text-center my-6 dark:text-white">Actuals Updated successfully!</div>
                                <div className="px-10 2xl:px-14">
                                    <div className="block text-center my-6">
                                        <Link onClick={() => clearAll()} to="/hr/changerequest/overrideperiod" className="bg-[#029046] text-white rounded-md px-6 py-2 text-sm shadow-md inline-block removeClass">Close</Link>
                                    </div>
                                </div>
                            </div> : null

                    }

                    {/* DECLINED POPUP */}
                    <div id="selector" className={isViewDeclinedPopUp === 1 ? 'overlay' : ''}></div>
                    {
                        isViewDeclinedPopUp === 1 ?
                            <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="displayPopupCont" className="sidenav">
                                <div className="popup_display_img"><img src={imageDeployedLarge} className="mx-auto mt-8" alt="" /></div>
                                <div className="text-xl text-center my-6 dark:text-white">The Override Actuals Change Request has been Declined!</div>
                                <div className="px-10 2xl:px-14">
                                    <div className="block text-center my-6">
                                        <Link onClick={() => clearAll()} to="/hr/changerequest/overrideperiod" className="bg-[#029046] text-white rounded-md px-6 py-2 text-sm shadow-md inline-block removeClass">Close</Link>
                                    </div>
                                </div>
                            </div> : null

                    }


                </div>
            </div>
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}
