import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import planningImg from "../../assets/images/planing1.png";
import LeftSideBar from '../../components/common/employee/leftnav.component';
import TopNavBar from '../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from "../../components/helper/validateRole";
import EmpLogin from "../../setup/auth/emp.component";

export default function EvaluationPeriod() {

    const [isAuthenticated, userHasAuthenticated] = useState(true);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
        
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="sm:flex items-center space-x-8">
                        <div className="text-2xl font-semibold dark:text-white">Evaluation</div>
                    </div>
                    <div className="lg:flex justify-between my-6">
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link className="active">My Evaluation</Link></li>
                            </ul>
                        </div>
                        <div className="mt-5 lg:mt-0">
                        </div>
                    </div>

                </div>
                <div className="flex justify-center opacity-50 mt-10">

                    <img src={planningImg} alt="" />


                </div>
            </div>

            <div id="selector"></div>


        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}