import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import moment from "moment";
import "primeicons/primeicons.css";
import { Calendar } from 'primereact/calendar';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import imageDeployedLarge from '../../assets/images/deployedImg.png';
import planningImg from "../../assets/images/planning1.png";
import planImageCurrentYear from "../../assets/images/planning2.png";
import popupPlanningHeader from '../../assets/images/planning_header_img.png';
import imageDeployed from '../../assets/svg/check_small_icon.svg';
import LeftSideBar from '../../components/common/hr/leftnav.component';
import TopNavBar from '../../components/common/hr/topnav.component';
import NoDataFound from '../../components/common/nodatafound';
import { ConvertResponseForSelect, checkDuplicates } from '../../components/helper/commonfunctions';
import { Check_TodaysDate_isAfter } from '../../components/helper/getEvaluationStatus';
import { ValidateHRRole } from '../../components/helper/validateRole';
import { FinancialYear, InitiatePlanning, Planning, Relationship } from "../../models";
import HRLogin from '../../setup/auth/hr.component';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../src/components/helper/graphQLFunctions'


export default function HrPlanning() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    const [isShowCreatePopUp, setShowCreatePopUp] = useState(0);

    // variables for data set
    const [financialYear, setFinancialYear] = useState("");
    const [financialYearList, setFinancialYearList] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [last_Updated_Date, setLast_Updated_Date] = useState(null);

    const [plannings, setPlannings] = useState([]);
    const [planningId, setPlanningId] = useState("");

    const [isDisableMode, setDisableMode] = useState(false);
    const [isUpdateMode, setUpdateMode] = useState(false);

    //deployed
    const [isViewDeployedPopUp, setViewDeployedPopUp] = useState(0);

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
        BindFinancialYear()
        bindList()
    }, []);

    async function BindFinancialYear() {

        //set Last updated date
        let currentDate = moment().format('YYYY-MM-DD')
        setLast_Updated_Date(currentDate)

        //set financial year list 
        // var relationshipResponses = await DataStore.query(Relationship);
        var relationshipResponses = await graphQLGetAllData('listRelationships')
        let financialYearIds = relationshipResponses.filter((response) => response.isDeployed).map((response) => response.financial_year_id)
        let checkDuplicate = checkDuplicates(financialYearIds)

        // var planningResponses = await DataStore.query(Planning);
        var planningResponses = await graphQLGetAllData('listPlannings')

        let planningArray = planningResponses.map((value) => value.financial_year_id)

        let differenceArray = checkDuplicate.filter(x => !planningArray.includes(x)).concat(planningArray.filter(x => !checkDuplicate.includes(x)));

        if (differenceArray) {
            var myArray = [];
            for (let i = 0; i < differenceArray.length; i++) {
                // let totalFinancialYear = await DataStore.query(FinancialYear, differenceArray[i], {
                //     sort: s => s.name(SortDirection.ASCENDING)
                // })
                let totalFinancialYear = await graphQLFindRecordById('getFinancialYear', differenceArray[i]);
                myArray.push(totalFinancialYear)

                if (i === differenceArray.length - 1) {
                    var myFinalArray = []; myFinalArray = ConvertResponseForSelect(myArray); setFinancialYearList(myFinalArray);
                }
            }
        } else { setFinancialYearList([]) }

        // if (planningResponses.length > 0) {
        //     var myArray = [];
        //     let planningArray = planningResponses.map((value) => value.financial_year_id)
        //     var getFinancialYear = await DataStore.query(FinancialYear, Predicates.ALL, {
        //         sort: s => s.name(SortDirection.ASCENDING)
        //     });
        //     let financialYearArray = getFinancialYear.map((value) => value.id)
        //     let differenceArray = planningArray.filter(x => !financialYearArray.includes(x)).concat(financialYearArray.filter(x => !planningArray.includes(x)));
        //     for (let i = 0; i < differenceArray.length; i++) {
        //         let totalFinancialYear = await DataStore.query(FinancialYear, differenceArray[i], {
        //             sort: s => s.name(SortDirection.ASCENDING)
        //         })
        //         myArray.push(totalFinancialYear)
        //     }
        //     var myFinalArray = []; myFinalArray = ConvertResponseForSelect(myArray); setFinancialYearList(myFinalArray);
        // }
        // else {
        //     var responses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //         sort: s => s.name(SortDirection.ASCENDING)
        //     });
        //     var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYearList(myArray);
        // }
    }


    const bindList = async () => {
        // var PlanningResponses = await DataStore.query(Planning, Predicates.ALL, {
        //     sort: s => s.createdAt(SortDirection.DESCENDING)
        // });
        var PlanningResponses = await graphQLGetAllData('listPlannings')
        // PlanningResponses = PlanningResponses.sort((a, b) => a.name.localeCompare(b.name));

        var newResponse = [];
        if (PlanningResponses.length > 0) {
            for (let i = 0; i < PlanningResponses.length; i++) {
                //check todays date for edit option
                let checkTodaysDateForEdit = Check_TodaysDate_isAfter(PlanningResponses[i].planning_to_date, moment().format('MM/DD/YYYY'))

                // var financialYearResponse = await DataStore.query(FinancialYear, PlanningResponses[i].financial_year_id)
                var financialYearResponse = await graphQLFindRecordById('getFinancialYear', PlanningResponses[i].financial_year_id);


                // var initPlanningRes = await DataStore.query(InitiatePlanning, (c) => c.planning_id.eq(PlanningResponses[i].id), {
                //     sort: s => s.updatedAt(SortDirection.DESCENDING)
                // })
                var initPlanningRes = await graphQLGetAllData('listInitiatePlannings', [{ planning_id: { eq: PlanningResponses[i].id } }]);


                //get current year
                let currentYear = moment().format('YYYY');
                let previousYear = moment().subtract(1, 'years').format('YYYY');
                let year_Name = previousYear + "-" + currentYear

                let financial_year_Profile = ""
                if (financialYearResponse.name === String(year_Name)) { financial_year_Profile = planImageCurrentYear } else { financial_year_Profile = planningImg }

                let data = {
                    "id": PlanningResponses[i].id,
                    "financial_year_id": PlanningResponses[i].financial_year_id,
                    "financial_year_name": financialYearResponse.name,
                    "planning_from_date": PlanningResponses[i].planning_from_date,
                    "planning_to_date": PlanningResponses[i].planning_to_date,
                    "is_completed": PlanningResponses[i].is_completed,
                    "FinancialYearStart": moment(PlanningResponses[i].planning_from_date).format('YYYY'),
                    "financial_year_Profile": financial_year_Profile,
                    'CheckTodaysDateForEdit': checkTodaysDateForEdit,
                    "updatedAt": PlanningResponses[i].last_Updated_Date,
                    // "updatedAt": initPlanningRes.length > 0 ? initPlanningRes[0].updatedAt : PlanningResponses[0].updatedAt,
                }

                newResponse.push(data);
                if (i === PlanningResponses.length - 1) {
                    let sortedArray = newResponse.sort(function (a, b) { return b.FinancialYearStart - a.FinancialYearStart });
                    setPlannings(sortedArray)
                }
            }
        }
        else {
            setPlannings([])
        }
    }

    const savePlanning = async () => {
        if (financialYear === "") { toast.error("Please select financial year."); return; }
        else if (startDate === "") { toast.error("Please select start date."); return; }
        else if (endDate === "") { toast.error("Please select end date."); return; }
        if (isUpdateMode) {
            // var selectedPlanningObj = await DataStore.query(Planning, planningId);
            var selectedPlanningObj = await graphQLFindRecordById('getPlanning', planningId);

            // await DataStore.save(
            //     Planning.copyOf(selectedPlanningObj, (updated) => {
            //         updated.financial_year_id = financialYear.value;
            //         updated.planning_from_date = moment(startDate).format('YYYY-MM-DD');
            //         updated.planning_to_date = moment(endDate).format('YYYY-MM-DD');
            //         updated.last_Updated_Date = last_Updated_Date
            //     })
            // );
            await API.graphql({
                query: mutations.updatePlanning,
                variables: {
                    input: {
                        id: selectedPlanningObj.id,
                        financial_year_id: financialYear.value,
                        planning_from_date: moment(startDate).format('YYYY-MM-DD'),
                        planning_to_date: moment(endDate).format('YYYY-MM-DD'),
                        last_Updated_Date: last_Updated_Date,
                        _version: selectedPlanningObj._version,
                    }
                }
            });
            // toast.success("updated");
        } else {
            // var checkIfPlanningExits = await DataStore.query(Planning, (c) => c.financial_year_id.eq(financialYear.value));
            var checkIfPlanningExits = await graphQLGetAllData('listPlannings', [{ financial_year_id: { eq: financialYear.value } }]);

            if (checkIfPlanningExits.length > 0) {
                toast.error("Planning is already initiated for " + financialYear.label);
            } else {
                // await DataStore.save(
                //     new Planning({
                //         financial_year_id: financialYear.value,
                //         planning_from_date: moment(startDate).format('YYYY-MM-DD'),
                //         planning_to_date: moment(endDate).format('YYYY-MM-DD'),
                //         last_Updated_Date: last_Updated_Date,
                //         is_completed: false
                //     })
                // );
                await API.graphql(
                    graphqlOperation(mutations.createPlanning, {
                        input: {
                            financial_year_id: financialYear.value,
                            planning_from_date: moment(startDate).format('YYYY-MM-DD'),
                            planning_to_date: moment(endDate).format('YYYY-MM-DD'),
                            last_Updated_Date: last_Updated_Date,
                            is_completed: false
                        }
                    })
                );
                // toast.success("Saved");
            }
        }
        bindList()
        setShowCreatePopUp(0)
        setViewDeployedPopUp(1)
    }


    const onFinancialSelectChange = async (e) => {

        setFinancialYear({ label: e.label, value: e.value })
        // var getFinancialYear = await DataStore.query(FinancialYear, e.value)
        var getFinancialYear = await graphQLFindRecordById('getFinancialYear', e.value);

        var fromDate = (getFinancialYear.fromDate && getFinancialYear.fromDate) || '';
        var endDate = (getFinancialYear.toDate && getFinancialYear.toDate) || '';

        fromDate = moment(fromDate).format("MM/DD/YYYY").toString();
        endDate = moment(endDate).format("MM/DD/YYYY").toString();

        setStartDate(moment(fromDate).toDate())
        setEndDate(moment(endDate).toDate())

    }

    const viewEditPlanning = async (type, id) => {
        setPlanningId(id)
        // var selectedPlanningDetails = await DataStore.query(Planning, id);
        var selectedPlanningDetails = await graphQLFindRecordById('getPlanning', id);

        // var financialYearResponse = await DataStore.query(FinancialYear, selectedPlanningDetails.financial_year_id)
        var financialYearResponse =  await graphQLFindRecordById('getFinancialYear',  selectedPlanningDetails.financial_year_id);
        setShowCreatePopUp(1)
        if (type === "v") { setDisableMode(true) } else { setDisableMode(false); setUpdateMode(true) }
        setFinancialYear({ label: financialYearResponse.name, value: selectedPlanningDetails.financial_year_id })
        setStartDate(moment(selectedPlanningDetails.planning_from_date).toDate())
        setEndDate(moment(selectedPlanningDetails.planning_to_date).toDate())
    }

    const completePlanning = async (id) => {
        // var selectedPlanningObj = await DataStore.query(Planning, id);
        // alertify.confirm('Confirmation', 'Do you want to complete?', async function () {
        //     await DataStore.save(
        //         Planning.copyOf(selectedPlanningObj, (updated) => {
        //             updated.is_completed = true;
        //         })
        //     );
        //     bindList()
        //     toast.success("Updated");
        // }
        //     , function () { });
    }

    return (
        isAuthenticated ? (
            <div className="pt-16">
                <TopNavBar />
                <LeftSideBar />

                <div className="planning-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Goals Setting</div>
                        </div>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link className="active">Initiate Planning</Link></li>
                                    <li><Link to="/hr/planning/assigntarget" className="">Assign Targets</Link></li>
                                </ul>

                            </div>
                            <div className="mt-5 lg:mt-0">
                                <Link onClick={() => {
                                    setUpdateMode(false)
                                    setFinancialYear("")
                                    setStartDate(null)
                                    setEndDate(null)
                                    setDisableMode(false); setShowCreatePopUp(1)
                                }}
                                    className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" >Create New Plan</Link>
                            </div>
                        </div>

                        {
                            plannings.length > 0 ?
                                <div className="grid grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 gap-4">
                                    {
                                        plannings.map((item) => {
                                            return (
                                                <div key={item.id} className="bg-white dark:bg-[#2A2E32] py-4 px-3 rounded-xl dashbord_box_shape" style={{ boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.1)" }}>
                                                    <div className="flex justify-between">
                                                        <div className="px-4">
                                                            <div className="text-xl font-semibold mb-2 dark:text-white">Financial Year<br />{item.financial_year_name}</div>
                                                            <div className="text-[#667085] text-sm mb-0 dark:text-[#98A2B3]">Planning Period:</div>
                                                            <div className="text-[#667085] text-sm mb-2 dark:text-[#98A2B3]">{moment(item.planning_from_date).format("Do MMM, YYYY")} - {moment(item.planning_to_date).format("Do MMM, YYYY")} </div>
                                                        </div>
                                                        <div className='flex justify-end'>
                                                            <img className='w-44' src={item.financial_year_Profile} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="sm:flex items-center justify-between px-2 xl:px-2 2xl:px-4 mt-6">
                                                        <div className="box_btns">
                                                            <ul className="flex gap-2">
                                                                <li ><Link to={"/hr/planning/viewinitiateplanning/" + item.financial_year_id + "/" + item.id} className="addclassName flex text-[13px] text-[#344054] border-[#C6CBD2] dark:bg-[#44494E] dark:text-white dark:border-[#44494E]">
                                                                    <i className="icon eye_small_icon"></i> View</Link></li>
                                                                {
                                                                    !item.CheckTodaysDateForEdit ?
                                                                        <li onClick={() => viewEditPlanning("e", item.id)}>
                                                                            <Link className="addclassName flex text-[13px] text-[#344054] border-[#C6CBD2] dark:bg-[#44494E] dark:text-white dark:border-[#44494E]">
                                                                                <i className="icon edit_small_icon"></i> Edit</Link>
                                                                        </li>

                                                                        : null
                                                                }
                                                                {
                                                                    !item.CheckTodaysDateForEdit ?
                                                                        <li onClick={() => completePlanning(item.id)} className="mt-1" >
                                                                            <label className="text-sm px-2 py-1.5 border rounded-md border-[#C6CBD2] text-[#C6CBD2] dark:text-[#C6CBD2] "><i className="icon check_diseble"></i>   Open</label>
                                                                        </li>
                                                                        : <li><Link className="bg-[#029046] flex text-[13px] text-[#fff] active addclassName opacity-40"><img src={imageDeployed} className="inline-block mr-0.5" alt="" /> Completed</Link></li>
                                                                }
                                                            </ul>
                                                        </div>

                                                        <div className="text-xs text-[#667085] text-end md:mt-0">Last Updated on: {moment(item.updatedAt).format("DD MMM, YYYY")}</div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                : <NoDataFound />
                        }


                    </div>
                </div>

                <div id="selector" className={isShowCreatePopUp === 1 ? 'overlay' : ''}></div>

                {
                    isShowCreatePopUp == 1 ?
                        <div style={{ width: "44.271vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="editPopupCont" className="sidenav">
                            <div className="popup_header relative">
                                <img src={popupPlanningHeader} alt="" />
                                <div className="absolute right-0 left-0 top-0 w-full text-white">
                                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                        <div className="text-md">Planning</div>
                                        <div><Link onClick={() => setShowCreatePopUp(0)} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                    </div>
                                    <div className="px-6 mt-8">
                                        <div className="text-sm">Initiate Planning</div>
                                        <div className="2xl:text-[32px] xl:text-[28px] lg:xl:text-[20px] md:xl:text-[18px]">{isDisableMode ? "Record" : isUpdateMode ? "Edit Planning Period" : "Creating New Planning Period"}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-6">
                                <div>
                                    <label className="text-[#344054] text-[13px] font-medium dark:text-white">Financial Year</label>
                                    <Select
                                        isDisabled={isUpdateMode === true ? true : false}
                                        value={financialYear}
                                        options={financialYearList}
                                        onChange={e => { onFinancialSelectChange(e) }}
                                        className="mt-2 my-react-select-container"
                                        classNamePrefix="my-react-select"
                                    />
                                </div>
                                <div className="grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mt-5">
                                    <div>
                                        <label className="text-[#344054] text-[13px] font-medium dark:text-white mb-2">Planning Period</label>
                                    </div>

                                    <div>
                                        <label className="text-[#344054] text-[13px] font-medium dark:text-white mb-2">Start Date</label>
                                        <Calendar placeholder="Start Date" className="mt-2" disabled={isDisableMode} value={startDate} onChange={(e) => setStartDate(e.value)} id="icon" showIcon />
                                    </div>

                                    <div style={{ flexDirection: "column" }}>
                                        <label className="text-[#344054] text-[13px] font-medium dark:text-white mb-2">End Date</label>
                                        <Calendar placeholder="End Date" className="mt-2" disabled={isDisableMode} value={endDate} minDate={startDate} onChange={(e) => setEndDate(e.value)} id="icon" showIcon />
                                    </div>
                                </div>
                                <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">
                                    {
                                        isDisableMode ?
                                            <div className="flex justify-end gap-3">
                                                <div><Link onClick={() => setShowCreatePopUp(0)} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Close</Link></div>
                                            </div>
                                            :
                                            <div className="flex justify-end gap-3">
                                                <div><Link onClick={() => setShowCreatePopUp(0)} className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Cancel</Link></div>
                                                <div><Link onClick={() => savePlanning()} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">{isUpdateMode ? "Update" : "Create"}</Link></div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div> : null
                }

                {/* Thank you popup */}
                <div id="selector" className={isViewDeployedPopUp === 1 ? 'overlay' : ''}></div>
                {
                    isViewDeployedPopUp === 1 ?
                        <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="displayPopupCont" className="sidenav">
                            <div className="popup_display_img"><img src={imageDeployedLarge} className="mx-auto mt-8" alt="" /></div>
                            {
                                isUpdateMode === true ?
                                    <div className="text-xl text-center my-6 dark:text-white">Successfully Updated</div> :
                                    <div className="text-xl text-center my-6 dark:text-white">Planning period for Financial Year {financialYear.label} has been successfully created.</div>

                            }
                            <div className="px-10 2xl:px-14">

                                <div className="grid grid-cols-2 xl:grid-cols-3 successfully_box py-3 gap-y-3 xl:gap-y-0">
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">Financial Year</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{financialYear.label}</div>
                                    </div>
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">Start Date</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{moment(startDate).format("DD MMM, YYYY")}</div>
                                    </div>
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">End Date</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{moment(endDate).format("DD MMM,YYYY")}</div>
                                    </div>
                                </div>
                                <div className="block text-center my-6">
                                    <Link to="/hr/planning" onClick={() => {
                                        setViewDeployedPopUp(0);
                                        setStartDate(""); setEndDate("")
                                    }} className="bg-[#029046] text-white rounded-md px-6 py-2 text-sm shadow-md inline-block removeClass">Close</Link>
                                </div>
                            </div>
                        </div> : null
                }

            </div >
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}