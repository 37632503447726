import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import AWS from "aws-sdk";
import moment from 'moment';
import 'primeicons/primeicons.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';
import imageDeployedLarge from '../../assets/images/deployedImg.png';
import parameterComplete from '../../assets/images/parameterComplete.png';
import parameterPending from '../../assets/images/parameterPending.png';
import popupHeaderImg from "../../assets/images/popup_header_img.png";
import imageDeployed from '../../assets/svg/check_small_icon.svg';
import RemoveButton from '../../components/common/buttons/RemoveButton';
import LeftSideBar from '../../components/common/hr/leftnav.component';
import TopNavBar from '../../components/common/hr/topnav.component';
import NoDataFound from '../../components/common/nodatafound';
import { ConvertResponseForSelect } from '../../components/helper/commonfunctions';
import { Check_TodaysDate_inBetween, Check_TodaysDate_isAfter } from '../../components/helper/getEvaluationStatus';
import { ValidateHRRole } from '../../components/helper/validateRole';
import { FinancialParameterDetails, FinancialParameters, FinancialYear, Parameters } from '../../models';
import HRLogin from '../../setup/auth/hr.component';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../src/components/helper/graphQLFunctions'


export default function HrParameters() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    let loggedHREmployeeId = reactLocalStorage.get('loggedHREmployeeId')
    //SET AWS Configuration
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
        secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey,
        region: process.env.REACT_APP_AWS_Region,
    });

    let emptyItem = {
        id: null,
        name: '',
        definition: '',
        criteria: ''
    };

    //List
    const [financialParametersList, SetFinancialParametersList] = useState([]);
    const [dbParameters, setDBParameters] = useState([])
    const [financialYear, setFinancialYear] = useState([])
    const [fromFinancialYearList, setFromFinancialYearList] = useState([]);

    //Create New record states
    const [selectedFinancialYear, setSelectedFinancialYear] = useState(null)
    const [selectedSelectedParameter, setSelectedParameter] = useState()
    const [financialYearStartDate, setDisplayFinancialYearStartDate] = useState('MM/DD/YYYY')
    const [financialYearEndDate, setDisplayFinancialYearEndDate] = useState('MM/DD/YYYY')
    const [isShowAddDialog, setShowAddDialog] = useState(false);
    const [isDisableMode, setDisableMode] = useState(false);
    const [isPreviousFinancialYear, setIsPreviousFinancialYear] = useState(false);
    const [isUpdateMsg, setIsUpdateMsg] = useState(false);
    const [isClickDeploy, setIsClickDeploy] = useState(false);
    const [fromFinancialYear, setFromFinancialYear] = useState('MM/DD/YYYY')

    //Parameters items array
    const [items, setItems] = useState([]);
    const [item, setItem] = useState(emptyItem);

    //popup
    const [isShowCreatePopUp, setShowCreatePopUp] = useState(0);
    const [isShowEditPopUp, setShowEditPopUp] = useState(0);
    const [isShowViewPopUp, setShowViewPopUp] = useState(0);
    const [isViewDeployedPopUp, setViewDeployedPopUp] = useState(0);

    //Table remove button
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link onClick={(e) => deleteItem(rowData)} ><RemoveButton /></Link>
            </React.Fragment>
        );
    }

    //Get parameter list
    async function BindFinancialParametersList() {
        // var financialParametersResponses = await DataStore.query(FinancialParameters);
        var financialParametersResponses = await graphQLGetAllData('listFinancialParameters');
        if (financialParametersResponses.length > 0) {
            var response = [];
            Object.keys(financialParametersResponses).map(async (currentItem, i) => {
                let loop_financial_parameter_id = financialParametersResponses[currentItem]['id'];
                let loop_financial_year_id = financialParametersResponses[currentItem]['financial_year_id'];
                let loop_isDeployed = financialParametersResponses[currentItem]['isDeployed'];
                let loop_user_id = financialParametersResponses[currentItem]['user_id'];

                //Get Financial Information
                // let loop_financial_year = await DataStore.query(FinancialYear, loop_financial_year_id);
                let loop_financial_year = await graphQLFindRecordById('getFinancialYear', loop_financial_year_id);

                let loop_financial_year_name = moment(loop_financial_year.fromDate).format('YYYY') + "-" + moment(loop_financial_year.toDate).format('YYYY')
                let loop_financial_year_period = '1st Apr, ' + moment(loop_financial_year.fromDate).format('YYYY') + " - 31st Mar, " + moment(loop_financial_year.toDate).format('YYYY')
                let loop_financial_year_Start = moment(loop_financial_year.fromDate).format('YYYY')

                //check todays date for edit option
                let checkTodaysDateForEdit = Check_TodaysDate_isAfter(moment(loop_financial_year.toDate).format('MM/DD/YYYY'), moment().format('MM/DD/YYYY'))

                //Get Parameters Information
                // let totalParameters = await DataStore.query(FinancialParameterDetails, (c) => c.financial_parameter_id.eq(loop_financial_parameter_id));

                let totalParameters = await graphQLGetAllData('listFinancialParameterDetails', [{ financial_parameter_id: { eq: loop_financial_parameter_id } }]);


                var newResponse = [];
                newResponse['ManageParameterId'] = loop_financial_parameter_id;
                newResponse['FinancialYearName'] = loop_financial_year_name;
                newResponse['isDeployed'] = loop_isDeployed;
                newResponse['FinancialYearPeriod'] = loop_financial_year_period;
                newResponse['TotalParameters'] = totalParameters.length;
                newResponse['LastUpdatedDate'] = moment(totalParameters[0].updatedAt).format('Do MMM, YYYY');
                newResponse['FinancialYearStart'] = loop_financial_year_Start;
                newResponse['CheckTodaysDateForEdit'] = checkTodaysDateForEdit

                response.push(newResponse);


                if (i === financialParametersResponses.length - 1) {
                    let sortedArray = response.sort(function (a, b) { return b.FinancialYearStart - a.FinancialYearStart });
                    SetFinancialParametersList(sortedArray);
                }
            })

        } else {
            SetFinancialParametersList([]);
        }

    }

    //get financial year list
    async function BindFinancialYear() {
        // var financialParametersResponses = await DataStore.query(FinancialParameters);
        var financialParametersResponses = await graphQLGetAllData('listFinancialParameters')
        if (financialParametersResponses.length > 0) {
            var myArray = [];
            let financialParametersArray = financialParametersResponses.map((value) => value.financial_year_id)

            // var getFinancialYear = await DataStore.query(FinancialYear, Predicates.ALL, {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });

            var getFinancialYear = await graphQLGetAllData('listFinancialYears')
            getFinancialYear = getFinancialYear.sort((a, b) => a.name.localeCompare(b.name));

            let financialYearArray = getFinancialYear.map((value) => value.id)
            let differenceArray = financialParametersArray.filter(x => !financialYearArray.includes(x)).concat(financialYearArray.filter(x => !financialParametersArray.includes(x)));
            for (let i = 0; i < differenceArray.length; i++) {
                // let totalFinancialYear = await DataStore.query(FinancialYear, differenceArray[i], {
                //     sort: s => s.name(SortDirection.ASCENDING)
                // })
                let totalFinancialYear = await graphQLFindRecordById('getFinancialYear', differenceArray[i]);
                myArray.push(totalFinancialYear)
            }
            var myFinalArray = []; myFinalArray = ConvertResponseForSelect(myArray); setFinancialYear(myFinalArray);
        }
        else {
            // var responses = await DataStore.query(FinancialYear, Predicates.ALL, {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });

            var responses = await graphQLGetAllData('listFinancialYears')
            responses = responses.sort((a, b) => a.name.localeCompare(b.name));
            var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYear(myArray);
        }
    }

    //bind parameters
    async function BindParameters() {
        // var responses = await DataStore.query(Parameters, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var responses = await graphQLGetAllData('listParameters')
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));

        var myArray = []; myArray = ConvertResponseForSelect(responses); setDBParameters(myArray);
    }

    //Financial select change
    const onFinancialSelectChange = async (e) => {

        setSelectedFinancialYear({ label: e.label, value: e.value });
        // var getFinancialYear = await DataStore.query(FinancialYear, e.value)
        var getFinancialYear = await graphQLFindRecordById('getFinancialYear', e.value);


        var fromDate = (getFinancialYear.fromDate && getFinancialYear.fromDate) || '';
        var endDate = (getFinancialYear.toDate && getFinancialYear.toDate) || '';

        fromDate = moment(fromDate).format("MM/DD/YYYY").toString();
        endDate = moment(endDate).format("MM/DD/YYYY").toString();

        setDisplayFinancialYearStartDate(fromDate);
        setDisplayFinancialYearEndDate(endDate);
    }

    //get previous year List 
    const LoadDataForCloneMapping = async () => {
        let todaysDate = moment().format('YYYY-MM-DD')
        // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.lt(todaysDate));
        var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { lt: todaysDate } }]);

        var myArray = []; myArray = ConvertResponseForSelect(responses); setFromFinancialYearList(myArray);
    }

    //parameter select
    const onParameterSelectChange = async (e) => {
        // var responses = await DataStore.query(Parameters, e.value);
        var responses = await graphQLFindRecordById('getParameters', e.value);


        const id = (responses.id && responses.id) || '';
        const name = (responses.name && responses.name) || '';
        const definition = (responses.definition && responses.definition) || '';
        const criteria = (responses.criteria && responses.criteria) || '';

        let _item = { ...item };
        _item['id'] = id
        _item['name'] = name;
        _item['criteria'] = criteria;
        _item['definition'] = definition;
        setItem(_item);
        setSelectedParameter({ label: e.label, value: e.value });
    }

    //on input change 
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _item = { ...item };
        _item[`${name}`] = val;
        setItem(_item);
    }

    //on select previous financial year list
    const onFromFinancialSelectChange = async (e) => {

        setFromFinancialYear({ label: e.label, value: e.value });
        // var getFinancialList = await DataStore.query(FinancialParameters, (c) => c.financial_year_id.eq(e.value))
        var getFinancialList = await graphQLGetAllData('listFinancialParameters', [{ financial_year_id: { eq: e.value } }]);


        // let getFinancialParameterDetails = await DataStore.query(FinancialParameterDetails, (c) => c.financial_parameter_id.eq(getFinancialList[0].id));
        let getFinancialParameterDetails = await graphQLGetAllData('listFinancialParameterDetails', [{ financial_parameter_id: { eq: getFinancialList[0].id } }]);

        let parameterViewList = []

        // let parameterDetails = await DataStore.query(Parameters, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        let parameterDetails = await graphQLGetAllData('listParameters')
        parameterDetails = parameterDetails.sort((a, b) => a.name.localeCompare(b.name));

        parameterDetails.map(async (value) => {
            let parameterData = getFinancialParameterDetails.filter((filterData) => filterData.parameter_id === value.id)
            if (parameterData.length > 0) {
                let data = {
                    "id": value.id,
                    "name": value.name,
                    "definition": value.definition,
                    "criteria": value.criteria
                }
                parameterViewList.push(data)
            }
        })
        setItems(parameterViewList)
    }

    //save items
    const saveItem = () => {

        if (item.id === null) {
            toast.error('Please select Parameter.');
            return;
        }
        else if (item.definition === "") {
            toast.error('Please enter Definition.');
            return;
        }
        else if (item.criteria === "") {
            toast.error('Please enter Criteria.');
            return;
        }

        if (item.id.trim()) {
            var currentItems = items;
            let checkForDuplicate = currentItems.find(data => data.id === item.id.trim())
            if (checkForDuplicate !== undefined) { toast.error('Parameter already added.'); }
            else {
                currentItems.push(item); setItems(currentItems);
                setItem(emptyItem);
                setSelectedParameter('');
            }
        }

    }

    //delete items
    const deleteItem = (rowData) => {
        let currentItems = items.filter(val => val.id !== rowData.id);
        setItems(currentItems);
    }

    //save parameter
    const saveParameter = async (event) => {
        if (selectedFinancialYear === null) {
            toast.error('Please select Financial Year.');
            return;
        }
        else if (items.length === 0) {
            toast.error('Please add Parameters.');
            return;
        }

        //Save FinancialParameters
        // var newFinancialParameterId = await DataStore.save(
        //     new FinancialParameters({
        //         "financial_year_id": selectedFinancialYear.value,
        //         "user_id": loggedHREmployeeId,
        //         "isDeployed": false
        //     })
        // );

        var newFinancialParameterId = await API.graphql(
            graphqlOperation(mutations.createFinancialParameters, {
                input: {
                    financial_year_id: selectedFinancialYear.value,
                    user_id: loggedHREmployeeId,
                    isDeployed: false
                }
            })
        );
        var newFinancialParameterId = newFinancialParameterId.data.createFinancialParameters;


        if (newFinancialParameterId.id != null) {
            //Save Parameters Details
            Object.keys(items).map(async (value, i) => {
                // await DataStore.save(
                //     new FinancialParameterDetails({
                //         "financial_parameter_id": newFinancialParameterId.id,
                //         "parameter_id": items[value]['id'],
                //         "parameter_defination": items[value]['definition'],
                //         "parameter_criteria": items[value]['criteria']
                //     })
                // ).then(async (response) => { })

                await API.graphql(
                    graphqlOperation(mutations.createFinancialParameterDetails, {
                        input: {
                            financial_parameter_id: newFinancialParameterId.id,
                            parameter_id: items[value]['id'],
                            parameter_defination: items[value]['definition'],
                            parameter_criteria: items[value]['criteria']
                        }
                    })
                );
                await BindFinancialParametersList();
                BindFinancialYear();
                BindParameters();


            })
        }

        setViewDeployedPopUp(1);
        setSelectedParameter('');
        setShowCreatePopUp(0);
        setShowAddDialog(false);
    }

    //view parameters
    const viewParameter = async (type, id) => {
        let remainingParameters = []
        // let totalParameters = await DataStore.query(FinancialParameterDetails, (c) => c.financial_parameter_id.eq(id), {
        //     sort: s => s.parameter_criteria(SortDirection.ASCENDING)
        // });

        let totalParameters = await graphQLGetAllData('listFinancialParameterDetails', [{ financial_parameter_id: { eq: id } }]);
        totalParameters = totalParameters.sort((a, b) => a.parameter_criteria.localeCompare(b.parameter_criteria));

        let parameterViewList = []

        // let parameterDetails = await DataStore.query(Parameters, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        let parameterDetails = await graphQLGetAllData('listParameters')
        parameterDetails = parameterDetails.sort((a, b) => a.name.localeCompare(b.name));

        parameterDetails.map(async (value) => {
            let parameterData = totalParameters.filter((filterData) => filterData.parameter_id === value.id)
            if (parameterData.length > 0) {
                let data = {
                    "id": value.id,
                    "name": value.name,
                    "definition": value.definition,
                    "criteria": parameterData[0].parameter_criteria.length > 0 ? parameterData[0].parameter_criteria : ""
                }
                parameterViewList.push(data)
            }
            else {
                remainingParameters.push({ "id": value.id, "name": value.name })
            }

        })
        var myArray = []; myArray = ConvertResponseForSelect(remainingParameters); setDBParameters(myArray);
        setItems(parameterViewList)

        // var selectedParameterDetailsDetails = await DataStore.query(FinancialParameters, id);
        var selectedParameterDetailsDetails = await graphQLFindRecordById('getFinancialParameters', id);


        // var financialYearDetails = await DataStore.query(FinancialYear, selectedParameterDetailsDetails.financial_year_id);

        var financialYearDetails = await graphQLFindRecordById('getFinancialYear', selectedParameterDetailsDetails.financial_year_id);


        var fromDate = moment(financialYearDetails.fromDate).format("MM/DD/YYYY").toString();
        var endDate = moment(financialYearDetails.toDate).format("MM/DD/YYYY").toString();

        setDisplayFinancialYearStartDate(fromDate);
        setDisplayFinancialYearEndDate(endDate);


        setSelectedFinancialYear({ label: financialYearDetails.name, value: financialYearDetails.id })

        if (type === "VD") {
            setViewDeployedPopUp(1)
        }
    }

    //update parameter
    const updateParameter = async (event) => {

        if (selectedFinancialYear === null) {
            toast.error('Please select Financial Year.');
            return;
        }
        else if (items.length === 0) {
            toast.error('Please add Parameters.');
            return;
        }

        let ParameterID = ""
        // var financialParametersResponses = await DataStore.query(FinancialParameters);
        var financialParametersResponses = await graphQLGetAllData('listFinancialParameters')

        financialParametersResponses.map((response) => {
            if (response.financial_year_id === selectedFinancialYear.value) {
                ParameterID = response.id;
            }
        })

        if (ParameterID !== "") {
            // var original = await DataStore.query(FinancialParameters, ParameterID);
            var original = await graphQLFindRecordById('getFinancialParameters', ParameterID);

            // await DataStore.save(
            //     FinancialParameters.copyOf(original, (updated) => {
            //         updated.isDeployed = false;
            //     })
            // ).then((response) => { })

            await API.graphql({
                query: mutations.updateFinancialParameters,
                variables: {
                    input: {
                        id: original.id,
                        isDeployed: false,
                        _version: original._version,
                    }
                }
            });

            // DataStore.delete(FinancialParameterDetails, (c) => c.financial_parameter_id.eq(ParameterID)).then((response) => {  });
            let toDeleteFinancialParameterDetails = await graphQLGetAllData('listFinancialParameterDetails', [{ financial_parameter_id: { eq: ParameterID } }]);


            for (var i = 0; i < toDeleteFinancialParameterDetails.length; i++) {
                await API.graphql({
                    query: mutations.deleteFinancialParameterDetails,
                    variables: {
                        input: {
                            id: toDeleteFinancialParameterDetails[i].id,
                            _version: toDeleteFinancialParameterDetails[i]._version,
                        }
                    }
                });
            }


            //Save FinancialParameters
            if (ParameterID != null) {
                //Save Parameters Details
                Object.keys(items).map(async (value, i) => {
                    // await DataStore.save(
                    //     new FinancialParameterDetails({
                    //         "financial_parameter_id": ParameterID,
                    //         "parameter_id": items[value]['id'],
                    //         "parameter_defination": items[value]['definition'],
                    //         "parameter_criteria": items[value]['criteria']
                    //     })
                    // ).then((response) => { })

                    await API.graphql(
                        graphqlOperation(mutations.createFinancialParameterDetails, {
                            input: {
                                financial_parameter_id: ParameterID,
                                parameter_id: items[value]['id'],
                                parameter_defination: items[value]['definition'],
                                parameter_criteria: items[value]['criteria']
                            }
                        })
                    );
                    BindFinancialParametersList();
                })
            }




        }

        setSelectedParameter('');
        setShowEditPopUp(0);
        setIsUpdateMsg(true)
        setViewDeployedPopUp(1);
        setShowAddDialog(false);
    }

    //update parameter status
    const updateStatus = async (financialYearID) => {
        // const original = await DataStore.query(FinancialParameters, financialYearID);
        const original = await graphQLFindRecordById('getFinancialParameters', financialYearID);

        // await DataStore.save(
        //     FinancialParameters.copyOf(original, (updated) => {
        //         updated.isDeployed = true;
        //     })
        // )
        await API.graphql({
            query: mutations.updateFinancialParameters,
            variables: {
                input: {
                    id: original.id,
                    isDeployed: true,
                    _version: original._version,
                }
            }
        });

        BindFinancialParametersList();
    }

    //load page method
    async function loadPage() {
        await BindFinancialParametersList();
        BindFinancialYear();
        BindParameters();
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //useEffect Method
    useEffect(() => {
        onLoad();
        loadPage();
        LoadDataForCloneMapping()
    }, [isShowCreatePopUp]);

    //UI Part
    return (
        isAuthenticated ? (
            <div className="pt-16 ">
                <TopNavBar />
                <LeftSideBar />

                <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Parameters</div>
                        </div>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link className={"active"}>Manage Parameters</Link></li>
                                    <li><Link to="/hr/assignparameters">Assign Parameters</Link></li>
                                </ul>
                            </div>
                            <div className="mt-5 lg:mt-0">
                                <Link onClick={() => {
                                    setShowCreatePopUp(1)
                                    setDisableMode(false)
                                    setSelectedFinancialYear(null)
                                    setDisplayFinancialYearStartDate('MM/DD/YYYY')
                                    setDisplayFinancialYearEndDate('MM/DD/YYYY')
                                    setShowAddDialog(false)
                                    setIsUpdateMsg(false)
                                    setItems([])
                                }}
                                    className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" >Create New Record</Link>
                            </div>
                        </div>

                        {
                            financialParametersList.length > 0 ?
                                <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                                    {

                                        financialParametersList.map(function (item, i) {
                                            return <div key={i}>
                                                <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-xl px-4 dashbord_box_shape" style={{ boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.1)" }}>
                                                    <div className="flex justify-between">
                                                        <div className="px-2">
                                                            <div className="text-xl font-semibold mb-1 dark:text-white">Financial Year<br /> {item['FinancialYearName']}</div>
                                                            <div className="text-[#667085] text-xs mb-2 dark:text-[#98A2B3]">Period: {item['FinancialYearPeriod']}</div>
                                                            <div className="inline-block border border-[#4FB155] rounded-md text-md font-semibold text-[#4FB155] px-3 py-1 mb-3 ">{item['TotalParameters']} Parameters</div>

                                                        </div>
                                                        <div>
                                                            <img src={item['CheckTodaysDateForEdit'] === true ? parameterComplete : parameterPending} className="w-52" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="sm:flex items-center justify-between px-1 mt-3 ">
                                                        <div className="box_btns">
                                                            <ul className="flex gap-2">
                                                                {
                                                                    item['isDeployed'] === true ?
                                                                        <li><Link className="text-[#029046] flex text-[13px] outlined "><i className="icon check_circle_green_icon_small"> </i>Deployed</Link></li> :
                                                                        <li><Link onClick={() => {

                                                                            alertify.confirm('Confirmation', 'Do you want to deploy this parameters?', async function () {
                                                                                viewParameter('VD', item['ManageParameterId'])
                                                                                updateStatus(item['ManageParameterId'])
                                                                                setIsClickDeploy(true)
                                                                                setIsUpdateMsg(false)
                                                                            }
                                                                                , function () { });

                                                                        }} className="bg-[#029046] flex text-[13px] text-[#fff] active addclassName"><img src={imageDeployed} className="inline-block mr-1" alt="" /> Deploy</Link></li>
                                                                }
                                                                <li><Link onClick={() => {
                                                                    setShowViewPopUp(1)
                                                                    viewParameter('v', item['ManageParameterId'])
                                                                    setIsUpdateMsg(false)
                                                                    setItem(emptyItem);
                                                                    setIsClickDeploy(false)
                                                                    setItems([]);
                                                                }}
                                                                    className="addclassName flex text-[13px] text-[#344054] border-[#C6CBD2] dark:bg-[#44494E] dark:text-white dark:border-[#44494E]"><i className="icon eye_small_icon"></i> View</Link></li>

                                                                {
                                                                    //item['isDeployed'] === false ?
                                                                    !item.CheckTodaysDateForEdit &&
                                                                    <li><Link onClick={() => {
                                                                        setShowEditPopUp(1)
                                                                        setIsClickDeploy(false)
                                                                        viewParameter('E', item['ManageParameterId'])
                                                                    }} className="addclassName flex text-[13px] text-[#344054] border-[#C6CBD2] dark:bg-[#44494E] dark:text-white dark:border-[#44494E]"><i className="icon edit_small_icon"></i>  Edit</Link></li>
                                                                    //: null

                                                                }

                                                            </ul>
                                                        </div>
                                                        <div className="text-xs text-[#667085] text-end mt-3 md:mt-0">Last Updated on: {item['LastUpdatedDate']}</div>
                                                    </div>
                                                </div>


                                            </div>
                                        })
                                    }
                                </div> : <NoDataFound />
                        }

                    </div>
                </div>

                <div id="selector" className={isShowCreatePopUp === 1 ? 'overlay' : ''}></div>

                {
                    isShowCreatePopUp === 1 ?
                        <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                            <div className="relative">
                                <img src={popupHeaderImg} alt="" className='w-full' />
                                <div className="absolute right-0 left-0 top-0 w-full text-white">
                                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                        <div className="text-md">Parameters</div>
                                        <div><Link onClick={() => setShowCreatePopUp(0)} className="closebtn text-2xl leading-none removeClass" >&times;</Link></div>
                                    </div>
                                    <div className="px-6 mt-8">
                                        <div className="text-sm">Parameters</div>
                                        <div className="2xl:text-[32px] xl:text-[28px] lg:xl:text-[20px] md:xl:text-[18px]">Create New Record</div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-6 height-custtom">
                                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-6">
                                    <div>
                                        <label className="text-[#344054] dark:text-[#fff] text-[14px] font-medium">Financial Year</label>
                                        <Select
                                            isDisabled={isDisableMode}
                                            options={financialYear}
                                            onChange={e => onFinancialSelectChange(e)}
                                            value={selectedFinancialYear}
                                            className="mt-2 my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />

                                    </div>
                                    <div>
                                        <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">Start Date</label>
                                        <div className="mt-1 bg-[#f9f9f9] block w-full flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm text-[16px] focus:outline-none focus:ring-1 focus:ring-blue-500 mt-2 placeholder:text-[#667085] rounded-lg dark:bg-[#2A2E32] dark:text-white dark:border-[#4E5668]">
                                            <div className="flex items-center justify-between">
                                                <div>{financialYearStartDate}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">End Date</label>
                                        <div className="mt-1 bg-[#f9f9f9] block w-full flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm text-[16px] focus:outline-none focus:ring-1 focus:ring-blue-500 mt-2 placeholder:text-[#667085] rounded-lg dark:bg-[#2A2E32] dark:text-white dark:border-[#4E5668]">
                                            <div className="flex items-center justify-between">
                                                <div>{financialYearEndDate}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    <div className="flex items-center justify-between bg-[#EEF8F4] my-6 text-[#344054] text-sm p-4 rounded-lg dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white h-16">
                                        <div>
                                            <input type="checkbox" onChange={() => {
                                                setIsPreviousFinancialYear(!isPreviousFinancialYear)
                                            }} className="rounded mr-1 border-[#C8CBD0] h-4 w-4" /> Would you Like to copy the parameters from previous Financial Year?
                                        </div>
                                        {
                                            isPreviousFinancialYear === true ?
                                                <Select
                                                    options={fromFinancialYearList}
                                                    onChange={e => onFromFinancialSelectChange(e)}
                                                    value={fromFinancialYear}
                                                    className="my-react-select-container w-36"
                                                    classNamePrefix="my-react-select"
                                                /> : null
                                        }

                                    </div>
                                }
                                <div className='flex justify-between'>
                                    <div className='my-2 text-left dark:text-white'>Parameters</div>

                                    {!isDisableMode ?
                                        <div className='my-2 text-right'><Link onClick={() => setShowAddDialog(true)} className="py-3 px-4 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium focus:outline-none leading-6  justify-center rounded-md" ><i className="pi pi-plus mr-2"></i>Add Parameter</Link></div>
                                        : ''}

                                </div>

                                {isShowAddDialog ?
                                    <div className='my-6 '>
                                        <div className="px-0 mt-10">
                                            <div className="flex flex-1 flex-wrap flex-row align-center grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-x-4  mb-2 ">
                                                <div className="mb-4">
                                                    <label className="text-[#344054] text-[14px] font-medium  dark:text-[#fff] ">
                                                        Parameter <label className="text-[#FF0000] text-[14px] font-medium">
                                                            * </label></label>
                                                    <Select
                                                        options={dbParameters}
                                                        onChange={(e) => {
                                                            onParameterSelectChange(e)
                                                        }}
                                                        value={selectedSelectedParameter}
                                                        className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select"
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">
                                                        Definition <label className="text-[#FF0000] text-[14px] font-medium">
                                                            * </label></label>
                                                    <input value={item.definition} onChange={(e) => onInputChange(e, 'definition')} type="text" className="text-[15px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] " required placeholder="Definition" />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">
                                                        Criteria <label className="text-[#FF0000] text-[14px] font-medium">
                                                            * </label></label>
                                                    <input value={item.criteria} onChange={(e) => onInputChange(e, 'criteria')} type="text" className="text-[15px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] " required placeholder="Criteria" />
                                                </div>
                                            </div>
                                            <div className="grow flex justify-center gap-3 mb-3">
                                                <Link onClick={() => setShowAddDialog(false)} className=" py-1.5 px-3 bg-white text-[#344054] transition ease-in duration-200 text-[12px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white">Cancel</Link>
                                                <button onClick={() => saveItem()} type="button" className=" py-1.5 px-3 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[12px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg rounded-md">Add to List</button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }

                                <div className='mt-4'>
                                    <DataTable value={items} emptyMessage=" " >
                                        <Column field="name" header="Name" ></Column>
                                        <Column field="definition" header="Definition"></Column>
                                        <Column field="criteria" header="Criteria"></Column>
                                        <Column headerStyle={{ textAlign: 'center' }} style={{ textAlign: 'center' }} header="Action" body={actionBodyTemplate} ></Column>
                                    </DataTable>
                                </div>


                            </div>
                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">


                                <div className="flex justify-end gap-3">
                                    <div><Link onClick={() => {
                                        setShowCreatePopUp(0)
                                        setIsPreviousFinancialYear(false)
                                    }} className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Cancel</Link></div>
                                    <div><Link onClick={() => saveParameter()} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Create New</Link></div>
                                </div>


                            </div>
                        </div> : null
                }



                <div id="selector" className={isShowViewPopUp === 1 ? 'overlay' : ''}></div>
                {
                    isShowViewPopUp === 1 ?
                        <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                            <div className="relative">
                                <img src={popupHeaderImg} alt="" className='w-full' />
                                <div className="absolute right-0 left-0 top-0 w-full text-white">
                                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                        <div className="text-md">Parameters</div>
                                        <div><Link onClick={() => setShowViewPopUp(0)} className="closebtn text-2xl leading-none removeClass" >&times;</Link></div>
                                    </div>
                                    <div className="px-6 mt-8">
                                        <div className="text-sm">Parameters</div>
                                        <div className="2xl:text-[32px] xl:text-[28px] lg:xl:text-[20px] md:xl:text-[18px]">View Record</div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-6 height-custtom">
                                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-6">
                                    <div>
                                        <label className="text-[#344054] dark:text-[#fff] text-[14px] font-medium">Financial Year</label>
                                        <Select
                                            isDisabled={true}
                                            options={financialYear}
                                            onChange={e => onFinancialSelectChange(e)}
                                            value={selectedFinancialYear}
                                            className="mt-2 my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />

                                    </div>
                                    <div>
                                        <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">Start Date</label>
                                        <div className="mt-1 bg-[#f9f9f9] block w-full flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm text-[16px] focus:outline-none focus:ring-1 focus:ring-blue-500 mt-2 placeholder:text-[#667085] rounded-lg dark:bg-[#2A2E32] dark:text-white dark:border-[#4E5668]">
                                            <div className="flex items-center justify-between">
                                                <div>{financialYearStartDate}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">End Date</label>
                                        <div className="mt-1 bg-[#f9f9f9] block w-full flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm text-[16px] focus:outline-none focus:ring-1 focus:ring-blue-500 mt-2 placeholder:text-[#667085] rounded-lg dark:bg-[#2A2E32] dark:text-white dark:border-[#4E5668]">
                                            <div className="flex items-center justify-between">
                                                <div>{financialYearEndDate}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between'>
                                    <div className='my-2 text-left dark:text-white'>Parameters</div>

                                </div>
                                <div className='mt-4'>
                                    <DataTable value={items} emptyMessage=" " >
                                        <Column field="name" header="Name" ></Column>
                                        <Column field="definition" header="Definition"></Column>
                                        <Column field="criteria" header="Criteria"></Column>
                                    </DataTable>
                                </div>

                            </div>
                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">


                                <div className="flex justify-end gap-3">
                                    <div><Link onClick={() => setShowViewPopUp(0)} className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Close</Link></div>
                                </div>

                            </div>
                        </div> : null
                }

                <div id="selector" className={isShowEditPopUp === 1 ? 'overlay' : ''}></div>
                {
                    isShowEditPopUp === 1 ?
                        <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                            <div className="relative">
                                <img src={popupHeaderImg} alt="" className='w-full' />
                                <div className="absolute right-0 left-0 top-0 w-full text-white">
                                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                        <div className="text-md">Parameters</div>
                                        <div><Link onClick={() => setShowEditPopUp(0)} className="closebtn text-2xl leading-none removeClass" >&times;</Link></div>
                                    </div>
                                    <div className="px-6 mt-8">
                                        <div className="text-sm">Parameters</div>
                                        <div className="text-2xl">Edit Parameters</div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-6 height-custtom">
                                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-6">
                                    <div>
                                        <label className="text-[#344054] dark:text-[#fff] text-[14px] font-medium">Financial Year</label>
                                        <Select
                                            isDisabled={true}
                                            options={financialYear}
                                            onChange={e => onFinancialSelectChange(e)}
                                            value={selectedFinancialYear}
                                            className="mt-2 my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />

                                    </div>
                                    <div className='' >
                                        <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">Start Date</label>
                                        <div className="mt-1 bg-[#f9f9f9] block w-full flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm text-[16px] focus:outline-none focus:ring-1 focus:ring-blue-500 mt-2 placeholder:text-[#667085] rounded-lg dark:bg-[#2A2E32] dark:text-white dark:border-[#4E5668]">
                                            <div className="flex items-center justify-between">
                                                <div>{financialYearStartDate}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">End Date</label>
                                        <div className="mt-1 bg-[#f9f9f9] block w-full flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm text-[16px] focus:outline-none focus:ring-1 focus:ring-blue-500 mt-2 placeholder:text-[#667085] rounded-lg dark:bg-[#2A2E32] dark:text-white dark:border-[#4E5668]">
                                            <div className="flex items-center justify-between">
                                                <div>{financialYearEndDate}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between'>
                                    <div className='my-2 text-left dark:text-white'>Parameters</div>
                                    {!isDisableMode ?
                                        <div className='my-2 text-right'><Link onClick={() => setShowAddDialog(true)} className="py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg rounded-md" >Add Parameter</Link></div>
                                        : ''}
                                </div>

                                {isShowAddDialog ?
                                    <div className='my-6 '>
                                        <div className="px-0 mt-10">
                                            <div className="flex flex-1 flex-wrap flex-row align-center grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-x-4  mb-2 ">
                                                <div className="mb-4">
                                                    <label className="text-[#344054] text-[14px] font-medium  dark:text-[#fff] ">
                                                        Parameter <label className="text-[#FF0000] text-[14px] font-medium">
                                                            * </label></label>
                                                    <Select
                                                        options={dbParameters}
                                                        onChange={(e) => {
                                                            onParameterSelectChange(e)
                                                        }}
                                                        value={selectedSelectedParameter}
                                                        className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select"
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">
                                                        Definition <label className="text-[#FF0000] text-[14px] font-medium">
                                                            * </label></label>
                                                    <input value={item.definition} onChange={(e) => onInputChange(e, 'definition')} type="text" className="text-[15px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] " required placeholder="Definition" />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="text-[#344054] text-[14px] font-medium dark:text-[#fff] ">
                                                        Criteria <label className="text-[#FF0000] text-[14px] font-medium">
                                                            * </label></label>
                                                    <input value={item.criteria} onChange={(e) => onInputChange(e, 'criteria')} type="text" className="text-[15px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] " required placeholder="Criteria" />
                                                </div>
                                            </div>
                                            <div className="grow flex justify-center gap-3 mb-3">
                                                <Link onClick={() => setShowAddDialog(false)} className=" py-1.5 px-3 bg-white text-[#344054] transition ease-in duration-200 text-[12px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white">Cancel</Link>
                                                <button onClick={() => saveItem()} type="button" className=" py-1.5 px-3 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[12px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg rounded-md">Add to List</button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }

                                <div className='mt-4'>
                                    <DataTable value={items} emptyMessage=" " >
                                        <Column field="name" header="Name" ></Column>
                                        <Column field="definition" header="Definition"></Column>
                                        <Column field="criteria" header="Criteria"></Column>
                                        <Column headerStyle={{ textAlign: 'center' }} style={{ textAlign: 'center' }} header="Action" body={actionBodyTemplate} ></Column>
                                    </DataTable>
                                </div>

                            </div>
                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">


                                <div className="flex justify-end gap-3">
                                    <div><Link onClick={() => setShowEditPopUp(0)} className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Cancel</Link></div>
                                    <div><Link onClick={() => updateParameter()} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Update Record</Link></div>
                                </div>


                            </div>
                        </div> : null
                }

                {/* Thank you popup */}
                <div id="selector" className={isViewDeployedPopUp === 1 ? 'overlay' : ''}></div>
                {
                    isViewDeployedPopUp === 1 ?
                        <div style={{ width: "45vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="displayPopupCont" className="sidenav">
                            <div className="popup_display_img"><img src={imageDeployedLarge} className="mx-auto mt-8" alt="" /></div>
                            <div className="text-xl text-center my-6 dark:text-white"> {isUpdateMsg === true ? "Record has been updated successfully" : isClickDeploy === true ? "The Performance Parameters have been deployed successfully!" : "New Record Created Successfully."}</div>
                            <div className="px-10 2xl:px-14">

                                <div className="grid grid-cols-2 xl:grid-cols-4 successfully_box py-3 gap-y-3 xl:gap-y-0">
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">Financial Year</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{selectedFinancialYear.label}</div>
                                    </div>
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">Parameter</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{items.length}</div>
                                    </div>
                                    <div className="bdr text-center ">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">Start Date</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{moment(financialYearStartDate).format("DD MMM, YYYY")}</div>
                                    </div>
                                    <div className="bdr text-center">
                                        <div className="text-sm 2xl:text-lg text-[#4DB17D]">End Date</div>
                                        <div className="text-sm 2xl:text-md dark:text-white">{moment(financialYearEndDate).format("DD MMM,YYYY")}</div>
                                    </div>
                                </div>
                                <div className="block text-center my-6">
                                    <Link to="/hr/manageparameters" onClick={() => {
                                        setViewDeployedPopUp(0);
                                        setSelectedFinancialYear(null)
                                        setIsPreviousFinancialYear(false)
                                        setDisplayFinancialYearStartDate('MM/DD/YYYY');
                                        setDisplayFinancialYearEndDate('MM/DD/YYYY')
                                        setItem(emptyItem);
                                        setItems([]);
                                    }} className="bg-[#029046] text-white rounded-md px-6 py-2 text-sm shadow-md inline-block removeClass">Go to Manage Parameters</Link>
                                </div>
                            </div>
                        </div> : null
                }

            </div>
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}
