

// async function findObjectWithCheckParameters(data) {
//     let filteredData = [];
//     filteredData = data.filter((obj) => {
//         if (obj.checkParameters === "1") {
//             return true;
//         } else if (obj.children && obj.children.length > 0) {
//             let filteredChildren = obj.children.filter((childObj) => {
//                 return childObj.checkParameters === "1";
//             });
//             return filteredChildren.length > 0;
//         }
//         return false;
//     });
//     return filteredData;
// }
// export { findObjectWithCheckParameters }


// async function findObjectWithCheckParameters(employees) {
//     const filteredEmployees = [];
//     employees.forEach((employee) => {
//         if (hasChildWithCheckParameter(employee)) {
//             filteredEmployees.push(employee);
//         }
//     });

//     function hasChildWithCheckParameter(employee) {
//         if (employee.checkParameters === "1") {
//             return true;
//         }
//         for (let i = 0; i < employee.children.length; i++) {
//             if (hasChildWithCheckParameter(employee.children[i])) {
//                 return true;
//             }
//         }
//         return false;
//     }

//     return filteredEmployees;
// }

async function findEmployeesWithCheckParameterOne(employees, result = []) {
    for (let i = 0; i < employees.length; i++) {
        if (employees[i].checkParameters === "1") {
            result.push(employees[i]);
        }
        if (employees[i].children.length > 0) {
            await findEmployeesWithCheckParameterOne(employees[i].children, result);
        }
    }
    return result;
}


export { findEmployeesWithCheckParameterOne }

