import { DataStore } from "aws-amplify";
import { Roles } from "../../models";


function ConvertResponseForSelectUniqueItems(responses, defaultArray = []) {

    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }

    let uniqueItems = [...new Set(responses.map(x => x.name))];
    uniqueItems.sort();

    for (var i = 0; i < uniqueItems.length; i++) {
        myArray.push({ label: uniqueItems[i], value: uniqueItems[i] });
    }

    return myArray;
};


function ConvertResponseForSelectFromKey(myArray, key) {

    var returnArray = [];
    for (var i = 0; i < myArray.length; i++) {
        returnArray.push({ label: myArray[i][key], value: myArray[i][key] });
    }
    return returnArray

};

function ConvertResponseForSelect(responses, defaultArray = []) {
    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key, value]) => {
        myArray.push({ label: value.name, value: value.id });
    })
    return myArray;
};

function ConvertResponseForSelectTwoNames(responses, defaultArray = []) {
    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(async ([key, value]) => {
        let roleName = await DataStore.query(Roles, value.role_id)
        myArray.push({ label: value.firstName + " " + value.lastName + " (" + roleName?.name + ")", value: value.id });
    })
    return myArray;
};

function ConvertResponseForSelectWithRefCode(responses, defaultArray = []) {
    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(async ([key, value]) => {
        myArray.push({ label: value.name + " (" + value.REF_CODE + ")", value: value.id });
    })
    return myArray;
};

function ConvertResponseForTwoNamesUsingDataModal(responses, dataModal, defaultArray = []) {
    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(async ([key, value]) => {
        let roleName = await DataStore.query(Roles, value.role_id)
        myArray.push({ label: value.firstName + " " + value.lastName + " (" + roleName.name + ")", value: value.id });
    })
    return myArray;
};

function ConvertResponseForSelectTwoWithCode(responses, defaultArray = []) {
    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(async ([key, value]) => {
        myArray.push({ label: value.name + " (" + value.id + ")", value: value.id });
    })
    return myArray;
};

function ConvertResponseForSelectAttributes(responses, defaultArray = []) {
    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key, value]) => {
        var myItem = [];
        for (const [attributeKey, attributeValue] of Object.entries(value)) {

            if (attributeKey === 'name') {
                myItem['label'] = attributeValue;
                myItem[attributeKey] = attributeValue;
            }
            else {
                myItem[attributeKey] = attributeValue;
            }
        }
        myArray.push(myItem);
    })
    return myArray;
};


function ObjectToArray(object) {
    let data = [];
    for (const [key, value] of Object.entries(object)) {
        data[key] = value;
    }
    return data;
}

function ConvertEnumToArray(responses, defaultArray = []) {
    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key]) => {
        myArray.push({ label: key, value: key });
    })
    return myArray;
};

function ConvertEnumToArrayKeyValue(responses, defaultArray = []) {
    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key, value]) => {
        myArray.push({ label: value, value: key });
    })
    return myArray;
};

function GetSingleValueFromResponse(responses, keyName) {

    var returnValue = '';
    var myObject = Object.entries(responses);
    myObject.forEach(([key, value]) => {
        for (const [attributeKey, attributeValue] of Object.entries(value)) {
            if (attributeKey === keyName) {
                returnValue = attributeValue;
                break;
            }
        }
    })

    return returnValue;


}

function GetValueFromArray(myArray, keyName) {
    for (var key in myArray) {
        if (myArray[key]['Name'] === keyName) {
            return myArray[key]['Value'];
        }
    }
}


function ObjectLength(object) {
    var length = 0;
    for (var key in object) {
        if (object.hasOwnProperty(key)) {
            ++length;
        }
    }
    return length;
};

function ConvertResponseForId(responses, Id) {
    let id = ""
    var myObject = Object.entries(responses);
    myObject.forEach(([key, value]) => {
        if (Id === value["id"]) {
            id = value["id"]
        }
    })
    return id
};



function GetArrayItemIndexFromKeyValue(myArray, key, keyValue) {


    var returnArrayItemIndex = 0;
    for (var i = 0; i < myArray.length; i++) {
        if (myArray[i][key] === keyValue) {
            returnArrayItemIndex = i;
            break;
        }
    }
    return returnArrayItemIndex

};

function GetArrayItemFromKeyValue(myArray, key, keyValue) {



    var returnArrayItem = [];
    for (var i = 0; i < myArray.length; i++) {
        if (myArray[i][key] === keyValue) {
            returnArrayItem = myArray[i];
            break;
        }
    }
    return returnArrayItem

};

function GetArrayPreviousItemFromKeyValue(myArray, key, keyValue) {


    var returnArrayItem = [];
    for (var i = 0; i < myArray.length; i++) {
        if (myArray[i][key] === keyValue) {
            var previousItemIndex = i - 1;
            if (myArray[previousItemIndex] != null) {
                returnArrayItem = myArray[previousItemIndex];
                break;
            }
        }
    }
    return returnArrayItem

};


function GetArrayNextItemFromKeyValue(myArray, key, keyValue) {


    var returnArrayItem = [];
    for (var i = 0; i < myArray.length; i++) {
        if (myArray[i][key] === keyValue) {
            var nextItemIndex = i + 1;
            if (myArray[nextItemIndex] != null) {
                returnArrayItem = myArray[nextItemIndex];
                break;
            }
        }
    }
    return returnArrayItem

};


function RemoveItemFromArrayByIdValue(myArray, compareValue) {

    for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === compareValue) {
            myArray.splice(i, 1);
            break;
        }
    }
    return myArray

};

function SetBuAggregateValueFromPlanArray(planData) {
    let result = [];

    planData.forEach(function (a) {
        if (a.buId !== '') {
            if (!this[a.buId]) {
                this[a.buId] = { buId: a.buId, targetValue: 0 };
                result.push(this[a.buId]);
            }
            this[a.buId].targetValue = parseFloat(this[a.buId].targetValue) + parseFloat(a.targetValue);
        }
    }, Object.create(null));

    return result;

};


function SetRegionAggregateValueFromPlanArray(planData) {
    let result = [];

    planData.forEach(function (a) {
        if (a.regionId !== '') {
            if (!this[a.regionId]) {
                this[a.regionId] = { regionId: a.regionId, targetValue: 0 };
                result.push(this[a.regionId]);
            }
            this[a.regionId].targetValue = parseFloat(this[a.regionId].targetValue) + parseFloat(a.targetValue);
        }
    }, Object.create(null));

    return result;
};


function SetCountryAggregateValueFromPlanArray(planData) {
    let result = [];

    planData.forEach(function (a) {
        if (a.countryId !== '') {
            if (!this[a.countryId]) {
                this[a.countryId] = { countryId: a.countryId, targetValue: 0 };
                result.push(this[a.countryId]);
            }
            this[a.countryId].targetValue = parseFloat(this[a.countryId].targetValue) + parseFloat(a.targetValue);
        }

    }, Object.create(null));

    return result;

};


function SetBrandAggregateValueFromPlanArray(planData) {
    let result = [];

    planData.forEach(function (a) {
        if (a.brandId !== '') {
            if (!this[a.brandId]) {
                this[a.brandId] = { brandId: a.brandId, targetValue: 0 };
                result.push(this[a.brandId]);
            }
            this[a.brandId].targetValue = parseFloat(this[a.brandId].targetValue) + parseFloat(a.targetValue);
        }
    }, Object.create(null));

    return result;
};

function SetGTMAggregateValueFromPlanArray(planData) {
    let result = [];
    planData.forEach(function (a) {
        if (a.gtmId !== '' && a.gtmId !== null) {
            if (!this[a.gtmId]) {
                this[a.gtmId] = { gtmId: a.gtmId, targetValue: 0 };
                result.push(this[a.gtmId]);
            }
            this[a.gtmId].targetValue = parseFloat(this[a.gtmId].targetValue) + parseFloat(a.targetValue);
        }
    }, Object.create(null));
    return result;
}

;
function SetSubGTMAggregateValueFromPlanArray(planData) {
    let result = [];
    planData.forEach(function (a) {
        if (a.subGtmId !== '' && a.subGtmId !== null) {
            if (!this[a.subGtmId]) {
                this[a.subGtmId] = { subGtmId: a.subGtmId, targetValue: 0 };
                result.push(this[a.subGtmId]);
            }
            this[a.subGtmId].targetValue = parseFloat(this[a.subGtmId].targetValue) + parseFloat(a.targetValue);
        }
    }, Object.create(null));
    return result;
};

async function SetBrandAggregateValueFromPlanDashBoard(planData) {
    let result = [];

    planData.forEach(function (a) {
        if (a.brand_id !== '') {
            if (!this[a.brand_id]) {
                this[a.brand_id] = { brandId: a.brand_id, targetValue: 0 };
                result.push(this[a.brand_id]);
            }
            this[a.brand_id].targetValue = parseFloat(this[a.brand_id].targetValue) + parseFloat(a.target_value);
        }
    }, Object.create(null));

    return result;

};

function SetProductGroupAggregateValueFromPlanArray(planData) {
    let result = [];

    planData.forEach(function (a) {
        if (a.productGroupId !== '') {
            if (!this[a.productGroupId]) {
                this[a.productGroupId] = { productGroupId: a.productGroupId, targetValue: 0 };
                result.push(this[a.productGroupId]);
            }
            this[a.productGroupId].targetValue = parseFloat(this[a.productGroupId].targetValue) + parseFloat(a.targetValue);
        }
    }, Object.create(null));

    return result;

};


function SetCustomerSegmentAggregateValueFromPlanArray(planData) {
    let result = [];

    planData.forEach(function (a) {
        if (a.customerGroupId !== '') {
            if (!this[a.customerGroupId]) {
                this[a.customerGroupId] = { customerGroupId: a.customerGroupId, targetValue: 0 };
                result.push(this[a.customerGroupId]);
            }
            this[a.customerGroupId].targetValue = parseFloat(this[a.customerGroupId].targetValue) + parseFloat(a.targetValue);
        }
    }, Object.create(null));

    return result;

};


function SetCustomerAggregateValueFromPlanArray(planData) {
    let result = [];

    planData.forEach(function (a) {
        if (a.customerId !== '') {
            if (!this[a.customerId]) {
                this[a.customerId] = { customerId: a.customerId, targetValue: 0 };
                result.push(this[a.customerId]);
            }
            this[a.customerId].targetValue = parseFloat(this[a.customerId].targetValue) + parseFloat(a.targetValue);
        }
    }, Object.create(null));

    return result;

};

async function GetTotalTargetValueFromPlanArray(currentArray) {
    let totalValue = 0;
    for (var i = 0; i < currentArray.length; i++) {
        totalValue = totalValue + parseFloat(currentArray[i]['targetValue']);
    }
    return totalValue
};

function GetTotalPercentageFromPlanArray(currentArray) {

    let totalValue = 0;

    for (var i = 0; i < currentArray.length; i++) {
        totalValue = totalValue + parseFloat(currentArray[i]['Percentage']);
    }
    totalValue = totalValue.toFixed(0) + '%'
    return totalValue

};

//remove duplicate item from array
function checkDuplicates(currentArray) {
    return currentArray.filter((item,
        index) => currentArray.indexOf(item) === index && item !== "");
};


//convert Array to object
function convertArrayToObject(currentArray) {

    let finalArray = []
    for (var i = 0; i < currentArray.length; i++) {
        let arrayOfArray = currentArray[i];
        for (var j = 0; j < arrayOfArray.length; j++) {
            finalArray.push(arrayOfArray[j])
        }
    }
    return finalArray
};

//convert Number
function convertNumberToShortNumber(number) {

    var shortNumber = '';
    // if(number > 1000000000){
    //     shortNumber = number/1000000000+'B'
    // }
    if (number > 1000000) {
        shortNumber = number / 1000000
        shortNumber = shortNumber.toFixed(2) + 'M'
    }
    else if (number > 1000) {
        shortNumber = number / 1000
        shortNumber = shortNumber.toFixed(2) + 'K'
    }
    else {
        shortNumber = number
    }

    return shortNumber
};

//Get Current Quater
function getCurrentQuater() {
    const currentMonthNumber = new Date().getMonth() + 1;
    var currentQuater = 4;
    if (currentMonthNumber > 9) {
        currentQuater = 3;
    }
    else if (currentMonthNumber > 6) {
        currentQuater = 2;
    }
    else if (currentMonthNumber > 3) {
        currentQuater = 1;
    }
    return currentQuater
};

function ConvertEnumteamToArray(responses, defaultArray = []) {
    var myArray = [];
    var keys = 'B';
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key]) => {
        if (key === 'B') {
            keys = 'Business'
        } else {
            keys = 'Sales'
        }
        myArray.push({ label: keys, value: key });
    })
    return myArray;
};
function validateFileExtension(file_field, allowedExtensions) {
    let flag = false;
    let extension = file_field.substr(file_field.lastIndexOf('.') + 1).toLowerCase();
    if (allowedExtensions.indexOf(extension) === -1) {
        flag = true;
    }
    return flag;
}

//*Get response from key
const getResponseFromKey = async (currentArray, KeyArray) => {

    let finalArray = []
    for (var i = 0; i < KeyArray.length; i++) {

        let getCurrentResponseFromKey = currentArray.filter((item) => item.code === KeyArray[i])
        let data = {
            "targetId": KeyArray[i],
            "targetIdResponse": getCurrentResponseFromKey
        }
        finalArray.push(data)

        if (i === KeyArray.length - 1) {
            return finalArray
        }
    }
};

export {
    ConvertResponseForSelectWithRefCode,
    ConvertResponseForSelectUniqueItems, ConvertResponseForSelect, ConvertResponseForSelectAttributes,
    ConvertResponseForId, ConvertEnumToArray, GetValueFromArray,
    GetSingleValueFromResponse, ObjectLength, ObjectToArray, ConvertResponseForSelectTwoNames, RemoveItemFromArrayByIdValue, ConvertResponseForSelectFromKey,
    GetArrayItemIndexFromKeyValue, GetArrayItemFromKeyValue, GetArrayPreviousItemFromKeyValue, GetArrayNextItemFromKeyValue,
    SetRegionAggregateValueFromPlanArray,
    SetCountryAggregateValueFromPlanArray,
    SetBrandAggregateValueFromPlanArray,
    SetProductGroupAggregateValueFromPlanArray,
    SetCustomerSegmentAggregateValueFromPlanArray,
    SetCustomerAggregateValueFromPlanArray, SetBrandAggregateValueFromPlanDashBoard,
    GetTotalPercentageFromPlanArray, ConvertEnumToArrayKeyValue,
    GetTotalTargetValueFromPlanArray, checkDuplicates, SetBuAggregateValueFromPlanArray,
    convertArrayToObject, convertNumberToShortNumber, getCurrentQuater, ConvertEnumteamToArray, validateFileExtension,
    getResponseFromKey, ConvertResponseForSelectTwoWithCode,
    SetGTMAggregateValueFromPlanArray, SetSubGTMAggregateValueFromPlanArray
};
