import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import bulbImg from "../../assets/images/blub-img.png";
import evaluationImg from "../../assets/images/evaluation-img.png";
import exploreImg from "../../assets/images/explore-img.png";
import logo from '../../assets/images/logo-landing.png';
import planningImg from "../../assets/images/planing-img.png";
import reportImg from "../../assets/images/report-img.png";
import { ValidateHRRole } from '../../components/helper/validateRole';
import { TeamType } from '../../models';
import HRLogin from '../../setup/auth/hr.component';
import moment from 'moment';
import { graphQLGetAllData } from '../../components/helper/graphQLFunctions';

export default function HRLanding(props) {
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [renderItem, setRenderItem] = useState(false);
    const [employeesCount, setEmployeesCount] = useState("");
    const [roleCount, setRoleCount] = useState("");
    const [roleSaleCount, setRoleSaleCount] = useState("");
    const [roleBusinessCount, setRoleBusinessCount] = useState("");

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {

        async function bindData() {
            // var employeeObj = await DataStore.query(Employee);
            var employeeObj = await graphQLGetAllData("listEmployees")
            setEmployeesCount(employeeObj.length)

            // var rolesObj = await DataStore.query(Roles);
            var rolesObj = await graphQLGetAllData("listRoles")
            setRoleCount(rolesObj.length)

            let counter = 1;
            let counterBusiness = 1;

            for (let j = 0; j < employeeObj.length; j++) {
                // var roleSaleObj = await DataStore.query(Roles, employeeObj[j]['role_id']);
                var roleSaleObj = rolesObj.find(o => o.id === employeeObj[j]['role_id'])

                if (roleSaleObj) {
                    if (roleSaleObj.teamType === TeamType.S) {
                        setRoleSaleCount(counter++)
                    } else if (roleSaleObj.teamType === TeamType.B) {
                        setRoleBusinessCount(counterBusiness++)
                    }
                    // if (j === employeeObj.length - 1) {
                    //     setRenderItem(true)
                    // }
                }
                if (j === employeeObj.length - 1) {
                    setRenderItem(true)
                }
            }
        }

        onLoad();
        bindData();

    }, []);
    return isAuthenticated ?
        <div>
            {renderItem && <div
                className="min-h-screen flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:grid-cols-1 gap-0 landing landing-hr">
                <div className="flex flex-wrap flex-row justify-center auto-rows-max left-side-bg ">
                    <div className="max-w-xl w-full p-3 pt-10">

                        <div className="mb-10">
                            <img className="w-60" src={logo} alt="" />
                        </div>
                        <div className="flex  mb-8">
                            <p
                                className="text-[26px] xl:text-[26px] 2xl:text-[30px] text-[#101828] leading-12  font-medium w-[500px] dark:text-white">
                                Redington’s Goal Sheet Evaluation Application, an extensible solution for planning and managing your sales performance</p>
                        </div>
                        <div className="quotes px-5 w-[420px] mb-6">
                            <h6 className="text-[28px] text-[#01813F] leading-10 font-nomal dark:text-[#98A2B3]">Setting goals is the first step to
                                turning the invisible into visible</h6>
                            <p className="text-[#029046] font-light mt-3 dark:text-[#98A2B3] ">~ Tony Robbins</p>
                        </div>


                        <div className="2xl:fixed 2xl:bottom-0 mb-3 flex mt-[10rem] gap-5">
                            <Link className="text-[12px] mt-8 text-[#98A2B3]">Conditions of Use</Link>
                            <Link className="text-[12px] mt-8 text-[#98A2B3]">Privacy Notice</Link>
                            <p className="text-[12px] mt-8 text-[#98A2B3]">Copyright @{moment().format("YYYY")} Redington</p>
                        </div>

                    </div>
                </div>
                <div className="landing-hr-right h-full flex-row flex flex-wrap align-center justify-center">
                    <div className="pt-5 px-6">
                        <p className="text-center text-[#fff] leading-7 font-medium text-[20px] 2xl:[text-25px] px-20 mb-4 2xl:mb-6">
                            Data driven holistic solution, to plan, track, analyze and optimize your sales performance
                        </p>

                        <div className="hrrightside-bg p-5 2xl:p-6 py-6 text-center rounded-md dark:bg-[#181b21] dark:border dark:border-[#4E5668]">
                            <Link className="bg-[#1B9B58] text-[#fff] rounded-full px-8 py-2 2xl:px-10 2xl:py-2 2xl:text-[20px] mb-4">I would like to</Link>

                            <div
                                className="flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-3 mt-4 feature">
                                <div className="bg-[#fff] rounded p-2.5 py-4 box dark:bg-[#2A2E32] relative">
                                    <div className="grid grid-cols-2 gap-4 items-center ">
                                        <div>
                                            <img className="m-auto" src={exploreImg} alt="" />
                                        </div>

                                        <div className="text-left">
                                            <h4
                                                className="text-[18px] font-semibold 2xl-[30px] xl-[30px] 3xl-[30px] text-[#000] leading-tight font-medium mb-3 leading-tight dark:text-white group">
                                                Manage Parameters
                                                <div className="absolute tooltippostion">
                                                    <div className="flex flex-col gap-0 text-left items-center justify-end hidden mb-6 group-hover:flex w-48 rounded-md font-light">
                                                        <div className="relative z-10 p-2 pb-0 text-[12px] text-left leading-3 text-white whitespace-no-wrap bg-[#101828] shadow-lg w-48 rounded-t-md font-medium">Manage Parameters</div>
                                                        <span className="relative z-10 px-2 py-1.5 text-[12px] leading-2 text-white whitespace-no-wrap bg-[#101828] shadow-lg text-left w-48 rounded-b-md">Allows you to add, edit and delete parameters and manage R2P relationships</span>
                                                        <div className="w-3 h-3 -mt-2 rotate-45 bg-[#101828]"></div>
                                                    </div>
                                                </div>
                                            </h4>
                                            <Link to="/hr/manageparameters"
                                                className="text-[#1B9B58] text-[10px] rounded-md px-3 py-2 uppercase border border-[#029046] border-1 font-medium">More
                                                Details</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-[#fff] rounded p-2.5 py-4 box dark:bg-[#2A2E32] relative">
                                    <div className="grid grid-cols-2 gap-4 items-center">
                                        <div>
                                            <img className="m-auto" src={planningImg} alt="" />
                                        </div>

                                        <div className="text-left">
                                            <h4
                                                className="text-[18px] 2xl-[30px] font-semibold xl-[30px] 3xl-[30px] text-[#000] font-medium mb-3 leading-tight dark:text-white group">
                                                Manage Goals Setting
                                                <div className="absolute tooltippostion">
                                                    <div className="flex flex-col gap-0 text-left items-center justify-end hidden mb-6 group-hover:flex w-48 rounded-md font-light">
                                                        <div className="relative z-10 p-2 pb-0 text-[12px] text-left leading-3 text-white whitespace-no-wrap bg-[#101828] shadow-lg w-48 rounded-t-md font-medium"> Manage Goals Setting</div>
                                                        <span className="relative z-10 px-2 py-1.5 text-[12px] leading-2 text-white whitespace-no-wrap bg-[#101828] shadow-lg text-left w-48 rounded-b-md">Allows you to initiate and manage the planning cycle</span>
                                                        <div className="w-3 h-3 -mt-2 rotate-45 bg-[#101828]"></div>
                                                    </div>
                                                </div>

                                            </h4>
                                            <Link
                                                to="/hr/planning"
                                                className="text-[#1B9B58] text-[10px] rounded-md px-3 py-2 uppercase border border-[#029046] border-1 font-medium">More
                                                Details</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-[#fff] rounded p-2.5 py-4 box dark:bg-[#2A2E32] relative">
                                    <div className="grid grid-cols-2 gap-4 items-center">
                                        <div>
                                            <img className="m-auto" src={evaluationImg} alt="" />
                                        </div>

                                        <div className="text-left">
                                            <h4
                                                className="text-[18px] font-semibold 2xl-[30px] xl-[30px] 3xl-[30px] text-[#000] font-medium mb-3 leading-tight dark:text-white group">
                                                Manage Goals Evaluation
                                                <div className="absolute tooltippostion">
                                                    <div className="flex flex-col gap-0 text-left items-center justify-end hidden mb-6 group-hover:flex w-48 rounded-md font-light">
                                                        <div className="relative z-10 p-2 pb-0 text-[12px] text-left leading-3 text-white whitespace-no-wrap bg-[#101828] shadow-lg w-48 rounded-t-md">Manage Goals Evaluation</div>
                                                        <span className="relative z-10 px-2 py-1.5 text-[12px] leading-2 text-white whitespace-no-wrap bg-[#101828] shadow-lg text-left w-48 rounded-b-md">Allows you to initiate and manage the evaluation cycle</span>
                                                        <div className="w-3 h-3 -mt-2 rotate-45 bg-[#101828]"></div>
                                                    </div>
                                                </div>


                                            </h4>
                                            <Link
                                                to={'/hr/evaluation'}
                                                className="text-[#1B9B58] text-[10px] rounded-md px-3 py-2 uppercase border border-[#029046] border-1 font-medium">More
                                                Details</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-[#fff] rounded p-2.5 py-4 box dark:bg-[#2A2E32] relative">
                                    <div className="grid grid-cols-2 gap-4 items-center">
                                        <div>
                                            <img className="m-auto" src={reportImg} alt="" />
                                        </div>

                                        <div className="text-left">
                                            <h4
                                                className="text-[18px] font-semibold 2xl-[30px] xl-[30px] 3xl-[30px] text-[#000] leading-tight font-semibold font-medium mb-3 dark:text-white group">
                                                Manage Change Requests
                                                <div className="absolute tooltippostion">
                                                    <div className="flex flex-col gap-0 text-left items-center justify-end hidden mb-6 group-hover:flex w-48 rounded-md font-light">
                                                        <div className="relative z-10 font-semibold p-2 pb-0 text-[12px] text-left leading-3 text-white whitespace-no-wrap bg-[#101828] shadow-lg w-48 rounded-t-md font-medium">Manage Change Requests</div>
                                                        <span className="relative z-10 px-2 py-1.5 text-[12px] leading-2 text-white whitespace-no-wrap bg-[#101828] shadow-lg text-left w-48 rounded-b-md">Allows you to view and act upon change requests related to planning period, evaluation period and override actual</span>
                                                        <div className="w-3 h-3 -mt-2 rotate-45 bg-[#101828]"></div>
                                                    </div>
                                                </div>


                                            </h4>
                                            <Link to="/hr/changerequest/planningperiod"
                                                className="text-[#1B9B58] text-[10px] rounded-md px-3 py-2 uppercase border border-[#029046] border-1 font-medium">More
                                                Details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {<div
                            className="flex-row grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-3 mt-10">
                            <div className="cols-span-1 bg-[#131622] rounded-md p-3 text-center dark:bg-[#2A2E32]">
                                <h3 className="text-[#fff] text-[16px] font-meduim mb-3">Finger Tips Facts</h3>
                                <img className="m-auto w-28" src={bulbImg} alt="" />
                            </div>
                            <div className="col-span-2">
                                <div className="grid grid-cols-2 gap-y-3 gap-3 items-center">
                                    <div className="hr-ftf-bg rounded-md p-2 2xl:p-5 xl:p-2 lg:p-2 xl:py-4 lg:py-4 text-center h-24 dark:bg-[#2A2E32]">
                                        <div className="flex gap-3 mt-4 px-2">
                                            <i className="icon total_emp_icon"></i>
                                            <div className="text-left">
                                                <h6 className="text-[#667085] text-[14px] dark:text-[#98A2B3]">Total Employees</h6>
                                                <h4 className="text-[18px] text-[#333] font-semibold dark:text-white">{employeesCount}</h4>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="hr-ftf-bg rounded-md p-2 2xl:p-5 xl:p-2 lg:p-2 xl:py-4 lg:py-4 text-center h-24">
                                        <div className="flex gap-3 mt-4 px-2">
                                            <i className="icon role_icon"></i>
                                            <div className="text-left">
                                                <h6 className="text-[#667085] text-[14px] dark:text-[#98A2B3]">Roles</h6>
                                                <h4 className="text-[18px] text-[#333] font-semibold dark:text-white">{roleCount}</h4>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="hr-ftf-bg rounded-md p-2 2xl:p-5 xl:p-2 lg:p-2 xl:py-4 lg:py-4 text-center h-24">
                                        <div className="flex gap-3 mt-4 px-2">
                                            <i className="icon sales_icon"></i>
                                            <div className="text-left">
                                                <h6 className="text-[#667085] text-[14px] dark:text-[#98A2B3] ">Sales Team</h6>
                                                <h4 className="text-[18px] text-[#333] font-semibold dark:text-white">{roleSaleCount}</h4>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="hr-ftf-bg rounded-md p-2 2xl:p-5 xl:p-2 lg:p-2 xl:py-4 lg:py-4 text-center h-24">
                                        <div className="flex gap-3 mt-4 px-2">
                                            <i className="icon brand_icon"></i>
                                            <div className="text-left">
                                                <h6 className="text-[#667085] text-[14px] dark:text-[#98A2B3]">Business Team</h6>
                                                <h4 className="text-[18px] text-[#333] font-semibold dark:text-white">{roleBusinessCount}</h4>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        <div className="2xl:fixed 2xl:bottom-0 flex mt-8 mb-3 justify-center text-center">
                            <p className="text-[#B3DDC7] font-light text-[11px] dark:text-[#98A2B3] 2xl:ml-20 xl:ml-6 lg:ml-12">Redington Gulf Representative Office, Level 16, Burjuman Business Tower ,
                                Thiru – Sheikh Khalifa Bin Zayed St – Al Mankhool – Dubai</p>
                        </div>

                    </div>


                </div>
            </div>}


        </div>
        : <HRLogin sessionExpired={1} />
}