import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Menu } from "@headlessui/react";
import { Avatar } from "@mui/material";
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { Auth, Storage } from 'aws-amplify';
import moment from "moment";
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import { reactLocalStorage } from 'reactjs-localstorage';
import "../../../../src/App.css";
import popupHeaderImg from "../../../assets/images/business-people-casual-meeting 1.png";
import imageDeployedLarge from '../../../assets/images/deployedImg.png';
import EditButton from '../../../components/common/buttons/EditButton';
import ViewButton from '../../../components/common/buttons/ViewButton';
import LeftSideBar from '../../../components/common/hr/leftnav.component';
import TopNavBar from "../../../components/common/hr/topnav.component";
import { ConvertResponseForSelect, ObjectToArray } from '../../../components/helper/commonfunctions';
import { CHANGE_REQUEST_STATUS, CHANGE_REQUEST_TYPES, HR_CHANGE_REQUEST_STATUS } from "../../../components/helper/enum";
import { ValidateHRRole } from '../../../components/helper/validateRole';
import { ChangeRequest, ChangeRequestComment, ChangeRequestFiles, Employee, FinancialYear, Roles } from "../../../models";
import HRLogin from '../../../setup/auth/hr.component';
import noCommentsImg from '../../../assets/images/noComment.png'
import { sendNotification } from '../../../components/helper/notification';
import { NOTIFICATIONS_TYPES, EMAIL_SUBJECTS } from '../../../components/helper/enum'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { GetAWSObjectURL, UploadFileOnS3 } from '../../../components/helper/aws';
import { downloadFile } from '../../../components/helper/downloadFile';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries'
import * as mutations from '../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../src/components/helper/graphQLFunctions'
import { ProgressSpinner } from 'primereact/progressspinner';




export default function PlanningPeriod() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //state
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [financialYear, setFinancialYear] = useState("");
    const [financialYearList, setFinancialYearList] = useState([]);


    //list
    const [myRequestList, setMyRequestList] = useState([]);
    const [reporteeAndRMChat, setReporteeAndRMChat] = useState([]);
    const [hRAndRMChat, setHRAndRMChat] = useState([]);


    //checkbox
    const [selectedPlanningPeriod, setSelectedPlanningPeriod] = useState(null);

    //popup
    const [isShowCreatePopUp] = useState(0);
    const [isEditPopUp, setEditPopUp] = useState(0);
    const [isApprovedPopUp, setApprovedPopUp] = useState(0);
    const [isDeclinePopUp, setDeclinePopUp] = useState(0);
    const [isViewPopUp, setViewPopUp] = useState(0);

    //Tab
    const [selectedTab, setSelectedTab] = useState(1);

    //get Local Storage data
    let loggedHREmployeeId = reactLocalStorage.get('loggedHREmployeeId')
    let selectedYear = reactLocalStorage.getObject('selectedYear');

    //popup state
    const [requestorName, setRequestorName] = useState("");
    const [reportingManagerName, setReportingManagerName] = useState("");
    const [employeeNo, setEmployeeNo] = useState("");
    const [employeeRequestorId, setEmployeeRequestorId] = useState("");
    const [employeeRequestorCode, setEmployeeRequestorCode] = useState("");
    const [isOpenAndExtend, setIsOpenAndExtend] = useState(false);
    const [extendStartDate, setExtendStartDate] = useState("");
    const [extendEndDate, setExtendEndDate] = useState("");
    const [requestedDate, setRequestedDate] = useState("");
    const [hRApproveDate, setHRApproveDate] = useState("");
    const [reportingManagerApprovedDate, setReportingManagerApprovedDate] = useState("");
    const [comments, setComments] = useState("");
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isShowHrApproveDateValidation, setIsShowHrApproveDateValidation] = useState(false);
    const [renderItems, setRenderItems] = useState(false);
    const [uploadedFiles_HR, setUploadedFiles_HR] = useState([]);



    //Messaging
    const [messageList, setMessageList] = useState([]);
    const [message, setMessage] = useState('');
    const [commentsData, setCommentsData] = useStateWithCallbackLazy({ changeRequestId: "", requestedEmployeeId: "", reportingManagerEmployeeId: "" });


    //Notifications
    const [notificationEmployeeId, setNotificationEmployeeId] = useState('');
    const [notificationRP, setNotificationRP] = useState('');

    //document
    const [docList, setDocList] = useState([]);
    const [docFinalList, setDocFinalList] = useState([]);

    //select Documents
    async function selectDocuments(e) {
        let file = e.target.files;
        setDocList([])
        if (file.length > 1) {
            for (let i = 0; i < file.length; i++) {
                let data = {
                    "documentId": employeeRequestorId,
                    "file": file[i],
                    "filename": file[i].name
                }
                docFinalList.push(data);
            }
        }
        else {
            for (let i = 0; i < file.length; i++) {
                let data = {
                    "documentId": employeeRequestorId,
                    "file": file[i],
                    "filename": file[i].name
                }
                docFinalList.push(data);
            }
        }
        let finalData = [...docFinalList]
        setDocList(finalData)
    }

    //Remove Document from array
    const onClickRemoveDocuments = (index) => {
        let data = [...docFinalList];
        data.splice(index, 1)
        setDocFinalList(data)
        setDocList(data)
    }

    //Clear state
    const clearAll = () => {
        setEditPopUp(0);
        setApprovedPopUp(0);
        setDeclinePopUp(0);
        setViewPopUp(0);
        bindList()
        // myRequests(selectedYear)
        setRequestorName("")
        setReportingManagerName("")
        setEmployeeNo("")
        setExtendStartDate("")
        setExtendEndDate("")
        setComments("")
        setCommentsData({ changeRequestId: "", requestedEmployeeId: "", reportingManagerEmployeeId: "" })
        setIsOpenAndExtend(false)
    }

    //Edit View Button
    const actionsOnRoles = (row) => {
        return (
            <React.Fragment >
                <div className="flex gap-8 ">
                    <Link
                        onClick={() => {
                            onClickView(row)
                        }}
                    ><ViewButton /></Link>
                    {
                        row.Status === HR_CHANGE_REQUEST_STATUS.Approved || row.Status === HR_CHANGE_REQUEST_STATUS.Declined ?
                            <Link className="disabledCursor opacity-50" ><EditButton /></Link> :
                            <Link onClick={() => {
                                setEditPopUp(1)
                                onClickEdit(row)
                            }}  ><EditButton /></Link>
                    }

                </div>

            </React.Fragment>
        );
    }

    //onclick edit
    async function onClickEdit(row) {
        let requestedId = row.id
        setEmployeeRequestorId(requestedId)
        setEmployeeRequestorCode(row.requestNo)
        setNotificationEmployeeId(row.requestedEmployeeId)
        setNotificationRP(row.reportingManagerEmployeeId)
        setRequestorName(row.Name)
        setReportingManagerName(row.ReportingManager)
        setEmployeeNo(row.EmployeeID)
        setIsReadOnly(false)
        setHRApproveDate(row.DateApproved)
        if (row.extendStartPeriod !== null) {
            setExtendStartDate(moment(row.extendStartPeriod).toDate())
        }
        if (row.extendEndPeriod !== null) {
            setExtendEndDate(moment(row.extendEndPeriod).toDate())
        }
        // setComments(row.comments)
        setRequestedDate(row.DateSubmitted)
        setReportingManagerApprovedDate(row.reportingManagerApprovedDate)

        //get comments data
        setCommentsData({ changeRequestId: row.id, requestedEmployeeId: row.requestedEmployeeId, reportingManagerEmployeeId: row.reportingManagerEmployeeId }, (chatData) => {
            if (chatData.changeRequestId !== "" && chatData.requestedEmployeeId !== "" && chatData.reportingManagerEmployeeId !== "") {
                loadMessages(chatData)
            }
        });
    }

    //onclick View
    async function onClickView(row) {
        let requestedId = row.id
        setEmployeeRequestorId(requestedId)
        setEmployeeRequestorCode(row.requestNo)
        setNotificationEmployeeId(row.requestedEmployeeId)
        setNotificationRP(row.reportingManagerEmployeeId)
        setIsReadOnly(true)
        setHRApproveDate(row.DateApproved)
        setRequestorName(row.Name)
        setReportingManagerName(row.ReportingManager)
        setEmployeeNo(row.EmployeeID)
        setExtendStartDate(moment(row.extendStartPeriod).toDate())
        setExtendEndDate(moment(row.extendEndPeriod).toDate())
        // setComments(row.comments)
        setRequestedDate(row.DateSubmitted)
        setReportingManagerApprovedDate(row.reportingManagerApprovedDate)
        if (row.Status === HR_CHANGE_REQUEST_STATUS.Approved || row.Status === HR_CHANGE_REQUEST_STATUS.Declined) {
            setIsShowHrApproveDateValidation(true)
        } else {
            setIsShowHrApproveDateValidation(false)
        }
        setRequestedDate(row.DateSubmitted)
        setReportingManagerApprovedDate(row.reportingManagerApprovedDate)

        //get comments data
        setCommentsData({ changeRequestId: row.id, requestedEmployeeId: row.requestedEmployeeId, reportingManagerEmployeeId: row.reportingManagerEmployeeId }, (chatData) => {
            if (chatData.changeRequestId !== "" && chatData.requestedEmployeeId !== "" && chatData.reportingManagerEmployeeId !== "") {
                loadMessages(chatData)
            }
        });

        // HR Uploaded Documents
        // var ChangeRequestFilesArray_HR = await DataStore.query(ChangeRequestFiles, (c) => c.and(c => [
        //     c.change_request_id.eq(row.id),
        //     c.upload_type.eq("H")
        // ]));

        var ChangeRequestFilesArray_HR = await graphQLGetAllData('listChangeRequestFiles', [{ change_request_id: { eq: row.id } }, { upload_type: { eq: "H" } }]);


        var filesDataOfRequest = []
        Object.keys(ChangeRequestFilesArray_HR).map(async (currentItem, i) => {
            let loopItem = ChangeRequestFilesArray_HR[currentItem];
            let data = ObjectToArray(loopItem);

            let getSignedUrl = await GetAWSObjectURL(data["fileName"])
            data["fileUrl"] = getSignedUrl

            filesDataOfRequest.push(data)

            if (i === ChangeRequestFilesArray_HR.length - 1) {
                setUploadedFiles_HR(filesDataOfRequest)
            }
        })


        // Reportee Uploaded Documents
        // var ChangeRequestFilesArray_Reportee = await DataStore.query(ChangeRequestFiles, (c) => c.and(c => [
        //     c.change_request_id.eq(row.id),
        //     c.upload_type.eq("R")
        // ]));
        var ChangeRequestFilesArray_Reportee = await graphQLGetAllData('listChangeRequestFiles', [
            { change_request_id: { eq: row.id } },
            { upload_type: { eq: "R" } }
        ]);


        var ReporteeFiles = []
        // Object.keys(ChangeRequestFilesArray_HR).map(async (currentItem, i) => {
        //     let loopItem = ChangeRequestFilesArray_HR[currentItem];
        //     let data = ObjectToArray(loopItem);

        //     let getSignedUrl = await GetAWSObjectURL(data["fileName"])
        //     data["fileUrl"] = getSignedUrl

        //     filesDataOfRequest.push(data)

        //     if (i === ChangeRequestFilesArray_HR.length - 1) {
        //         setUploadedFiles_HR(filesDataOfRequest)
        //     }
        // })


        setViewPopUp(1)
    }

    //load msg list
    const loadMessages = async (chatData) => {
        // var ChangeRequestMessageResponses = await DataStore.query(ChangeRequestComment, (c) => c.changeRequestId.eq(chatData.changeRequestId), {
        //     sort: s => s.messageDateTime(SortDirection.ASCENDING)
        // })
        var ChangeRequestMessageResponses = await graphQLGetAllData('listChangeRequestComments', [{ changeRequestId: { eq: chatData.changeRequestId } }]);
        ChangeRequestMessageResponses = ChangeRequestMessageResponses.sort((a, b) => a.messageDateTime.localeCompare(b.messageDateTime));


        if (ChangeRequestMessageResponses.length > 0) {
            let reportee_And_RM_Response = []
            let hR_And_RM_Response = []
            for (var i = 0; i < ChangeRequestMessageResponses.length; i++) {

                let senderProfilePic = ""
                let receiverProfilePic = ""

                // var senderResponse = await DataStore.query(Employee, ChangeRequestMessageResponses[i].sender_id);
                var senderResponse = await graphQLFindRecordById('getEmployee', ChangeRequestMessageResponses[i].sender_id);

                // var receiverResponse = await DataStore.query(Employee, ChangeRequestMessageResponses[i].receiver_id);
                var receiverResponse = await graphQLFindRecordById('getEmployee', ChangeRequestMessageResponses[i].receiver_id);

                if (senderResponse.profilePic) {
                    await Storage.get(senderResponse.profilePic).then(result => { senderProfilePic = result });
                }

                if (receiverResponse.profilePic) {
                    await Storage.get(receiverResponse.profilePic).then(result => { receiverProfilePic = result });
                }

                if ((ChangeRequestMessageResponses[i].sender_id === chatData.requestedEmployeeId && ChangeRequestMessageResponses[i].receiver_id === chatData.reportingManagerEmployeeId) ||
                    (ChangeRequestMessageResponses[i].sender_id === chatData.reportingManagerEmployeeId && ChangeRequestMessageResponses[i].receiver_id === chatData.requestedEmployeeId)) {
                    let data = {
                        "id": ChangeRequestMessageResponses[i].id,
                        "sender_id": ChangeRequestMessageResponses[i].sender_id,
                        "receiver_id": ChangeRequestMessageResponses[i].receiver_id,
                        "sender_Name": senderResponse.firstName + " " + senderResponse.lastName,
                        "receiver_Name": receiverResponse.firstName + " " + receiverResponse.lastName,
                        "sender_Profile": senderProfilePic,
                        "receiver_Profile": receiverProfilePic,
                        "changeRequestId": ChangeRequestMessageResponses[i].changeRequestId,
                        "messageDateTime": ChangeRequestMessageResponses[i].messageDateTime,
                        "isRead": ChangeRequestMessageResponses[i].isRead,
                        "message": ChangeRequestMessageResponses[i].message,
                        "hr_Id": "333d0615-8cef-4d64-a2be-093c00d2f006",
                        "rM_Id": chatData.reportingManagerEmployeeId,
                        "reportee_Id": chatData.requestedEmployeeId
                    }
                    reportee_And_RM_Response.push(data)
                }
                else if ((ChangeRequestMessageResponses[i].sender_id === "333d0615-8cef-4d64-a2be-093c00d2f006" && ChangeRequestMessageResponses[i].receiver_id === chatData.reportingManagerEmployeeId) ||
                    (ChangeRequestMessageResponses[i].sender_id === chatData.reportingManagerEmployeeId && ChangeRequestMessageResponses[i].receiver_id === "333d0615-8cef-4d64-a2be-093c00d2f006")) {
                    let data = {
                        "id": ChangeRequestMessageResponses[i].id,
                        "sender_id": ChangeRequestMessageResponses[i].sender_id,
                        "receiver_id": ChangeRequestMessageResponses[i].receiver_id,
                        "sender_Name": senderResponse.firstName + " " + senderResponse.lastName,
                        "receiver_Name": receiverResponse.firstName + " " + receiverResponse.lastName,
                        "sender_Profile": senderProfilePic,
                        "receiver_Profile": receiverProfilePic,
                        "changeRequestId": ChangeRequestMessageResponses[i].changeRequestId,
                        "messageDateTime": ChangeRequestMessageResponses[i].messageDateTime,
                        "isRead": ChangeRequestMessageResponses[i].isRead,
                        "message": ChangeRequestMessageResponses[i].message,
                        "hr_Id": "333d0615-8cef-4d64-a2be-093c00d2f006",
                        "rM_Id": chatData.reportingManagerEmployeeId,
                        "reportee_Id": chatData.requestedEmployeeId
                    }
                    hR_And_RM_Response.push(data)
                }

                if (i === ChangeRequestMessageResponses.length - 1) {
                    setReporteeAndRMChat(reportee_And_RM_Response)
                    setHRAndRMChat(hR_And_RM_Response)
                    setRenderItems(true)
                }
            }
        } else {
            setReporteeAndRMChat([])
            setHRAndRMChat([])
            setRenderItems(false)
        }
    }

    //send Message
    async function SendMessage() {
        // Check Blank - message
        if (message === "") {
            toast.error('Please Enter Message.');
            return;
        }
        // await DataStore.save(
        //     new ChangeRequestComment({
        //         "changeRequestId": employeeRequestorId,
        //         "receiver_id": notificationRP,
        //         "sender_id": loggedHREmployeeId,
        //         "messageDateTime": moment().toDate().toISOString(),
        //         "isRead": "0",
        //         "message": message
        //     })
        // );
        await API.graphql(
            graphqlOperation(mutations.createChangeRequestComment, {
                input: {
                    changeRequestId: employeeRequestorId,
                    receiver_id: notificationRP,
                    sender_id: loggedHREmployeeId,
                    messageDateTime: moment().toDate().toISOString(),
                    isRead: "0",
                    message: message
                }
            })
        );
        setMessage("")
        loadMessages(commentsData)
    }

    //Change status
    async function changeStatus(status) {


        if (status === HR_CHANGE_REQUEST_STATUS.Approved) {
            if (isOpenAndExtend === false) { toast.error("Select dates"); return true }
            else if (extendStartDate === "" || extendStartDate === "") { toast.error("Select start date."); return true }
            else if (extendEndDate === "" || extendEndDate === "") { toast.error("Select end date."); return true }

            alertify.confirm('Confirmation', 'Do you want to ' + status + "?", async function () {
                if (docList.length > 0) {
                    try {
                        for (var i = 0; i < docList.length; i++) {
                            const fileName = 'public/changerequest/' + employeeRequestorId + '/HR/' + docList[i].filename;
                            await UploadFileOnS3(docList[i].file, fileName).catch(error => toast.error(error));
                            // await DataStore.save(
                            //     new ChangeRequestFiles({
                            //         "change_request_id": employeeRequestorId,
                            //         "upload_type": "H",
                            //         "fileName": fileName
                            //     })
                            // );

                            await API.graphql(
                                graphqlOperation(mutations.createChangeRequestFiles, {
                                    input: {
                                        change_request_id: employeeRequestorId,
                                        upload_type: "H",
                                        fileName: fileName
                                    }
                                })
                            );
                        }

                    } catch (error) {
                        console.log("Error uploading file: ", error);
                    }
                }
                // let original = await DataStore.query(ChangeRequest, employeeRequestorId)
                let original = await graphQLFindRecordById('getChangeRequest', employeeRequestorId);

                // await DataStore.save(
                //     ChangeRequest.copyOf(original, (updated) => {
                //         updated.hrRequestStatus = status;
                //         updated.hrManagerApprovedDate = moment().format("YYYY-MM-DD");
                //         updated.ExtendStartPeriod = moment(extendStartDate).format('YYYY-MM-DD');
                //         updated.ExtendEndPeriod = moment(extendEndDate).format('YYYY-MM-DD');
                //         updated.comments = comments
                //     })
                // ).then(async () => { })

                await API.graphql({
                    query: mutations.updateChangeRequest,
                    variables: {
                        input: {
                            id: original.id,
                            hrRequestStatus: status,
                            hrManagerApprovedDate: moment().format("YYYY-MM-DD"),
                            ExtendStartPeriod: moment(extendStartDate).format('YYYY-MM-DD'),
                            ExtendEndPeriod: moment(extendEndDate).format('YYYY-MM-DD'),
                            comments: comments,
                            _version: original._version,
                        }
                    }
                });

                if (notificationEmployeeId) {
                    let requestTypeName = NOTIFICATIONS_TYPES.ChangeRequestApproveFromHR
                    let emailSubject = EMAIL_SUBJECTS.ChangeRequestApprove
                    let notificationMsg = requestorName + " requested for Planning Period has been Approved"
                    await sendNotification("333d0615-8cef-4d64-a2be-093c00d2f006", notificationEmployeeId, requestTypeName, notificationMsg, emailSubject);
                }
                if (notificationRP) {
                    let requestTypeName = NOTIFICATIONS_TYPES.ChangeRequestApproveFromHR
                    let emailSubject = EMAIL_SUBJECTS.ChangeRequestApprove
                    let notificationMsg = reportingManagerName + " requested for Planning Period has been Approved"
                    await sendNotification("333d0615-8cef-4d64-a2be-093c00d2f006", notificationRP, requestTypeName, notificationMsg, emailSubject);
                }

                bindList()
                myRequests(selectedYear)
            }
                , function () { });
        }
        else {
            alertify.confirm('Confirmation', 'Would you like to ' + status + " the change request?", async function () {
                // let original = await DataStore.query(ChangeRequest, employeeRequestorId)
                let original = await graphQLFindRecordById('getChangeRequest', employeeRequestorId);

                // await DataStore.save(
                //     ChangeRequest.copyOf(original, (updated) => {
                //         updated.hrRequestStatus = status;
                //         updated.hrManagerApprovedDate = moment().format("YYYY-MM-DD");
                //         updated.comments = comments
                //     })
                // ).then(async (response) => {})

                await API.graphql({
                    query: mutations.updateChangeRequest,
                    variables: {
                        input: {
                            id: original.id,
                            hrRequestStatus: status,
                            hrManagerApprovedDate: moment().format("YYYY-MM-DD"),
                            comments: comments,
                            _version: original._version,
                        }
                    }
                });

                if (notificationEmployeeId) {
                    let requestTypeName = NOTIFICATIONS_TYPES.ChangeRequestApproveFromHR
                    let emailSubject = EMAIL_SUBJECTS.ChangeRequestApprove
                    let notificationMsg = requestorName + " requested for Planning Period has been Rejected"
                    await sendNotification("333d0615-8cef-4d64-a2be-093c00d2f006", notificationEmployeeId, requestTypeName, notificationMsg, emailSubject);
                }
                if (notificationRP) {
                    let requestTypeName = NOTIFICATIONS_TYPES.ChangeRequestApproveFromHR
                    let emailSubject = EMAIL_SUBJECTS.ChangeRequestApprove
                    let notificationMsg = reportingManagerName + " requested for Planning Period has been Rejected"
                    await sendNotification("333d0615-8cef-4d64-a2be-093c00d2f006", notificationRP, requestTypeName, notificationMsg, emailSubject);
                }

            }
                , function () { });
        }
        myRequests(selectedYear)
        clearAll()
    }

    //On click decline
    async function onClickDecline() {
        setEditPopUp(0)
        setIsReadOnly(false)
        setDeclinePopUp(1)
        setRequestorName("")
        setReportingManagerName("")
        setEmployeeNo("")
        setExtendStartDate("")
        setExtendEndDate("")
        setComments("")
        setIsOpenAndExtend(false)
    }


    //TABLES  - Show status 
    const requestsStatus = (rowData) => {
        return (
            rowData.Status === null ?
                <p className="bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1  justify-center"> <div className='w-2 h-2 bg-[#1B55AF] rounded-full'></div>{HR_CHANGE_REQUEST_STATUS.Open}</p>
                : rowData.Status === HR_CHANGE_REQUEST_STATUS.Approved ?
                    <p className="bg-[#E5F3EC] text-[#4FB155] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center"> <div className='w-2 h-2 bg-[#4FB155] rounded-full'></div>{rowData.Status}</p>
                    :
                    <p className="bg-[#FEF3F2] text-[#FDA29B] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center "> <div className='w-2 h-2 bg-[#FDA29B] rounded-full'></div>{rowData.Status}</p>
        )
    }

    const bindList = async () => {

        // if (Object.keys(selectedYear).length !== 0) {
        //     setFinancialYear(selectedYear)
        // }
        // else { 
            // below part was written in else upto setFinancialYear()
        //}
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

            setFinancialYear({ label: responses[0].name, value: responses[0].id })
       

        //get financial year
        // var responses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var responses = await graphQLGetAllData('listFinancialYears');
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));
        var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYearList(myArray);
    }

    //get my request
    const myRequests = async (financialYear) => {

        //get logged user request
        // var responses = await DataStore.query(ChangeRequest, (c) => c.and(c => [
        //     c.financial_year_id.eq(financialYear.value),
        //     //c.reportingManagerEmployeeId.eq(""),
        //     c.requestType.eq(CHANGE_REQUEST_TYPES.PlanningPeriod),
        //     c.requestStatus.eq(CHANGE_REQUEST_STATUS.REPORTING_MANAGER)
        // ]));
        var responses = await graphQLGetAllData('listChangeRequests', [
            { financial_year_id: { eq: financialYear.value } },
            // { requestType: { eq: CHANGE_REQUEST_TYPES.PlanningPeriod } },
            // { requestStatus: { eq: CHANGE_REQUEST_STATUS.REPORTING_MANAGER } }
        ]);

        if (responses.length > 0) {
            // var responses2 = await DataStore.query(ChangeRequest, (c) => c.and(c => [
            //     c.financial_year_id.eq(financialYear.value),
            //     c.requestStatus.contains("Approved"),
            // ]));
            // let responses = Object.assign(responses1, responses2);

            //var responses = await DataStore.query(ChangeRequest, (c) => c.financial_year_id.eq(financialYear.value));
            let finalRequestData = []
            for (let i = 0; i < responses.length; i++) {
                //get current employee 
                let employeeId = ""
                let employeeName = ""
                if (responses[i].requestedEmployeeId) {
                    // var employeeResponses = await DataStore.query(Employee, (c) => c.id.eq(responses[i].requestedEmployeeId));
                    var employeeResponses = await graphQLFindRecordById('getEmployee', responses[i].requestedEmployeeId);


                    employeeId = employeeResponses ? employeeResponses.employeeId : '-';
                    employeeName = employeeResponses ? employeeResponses.firstName + " " + employeeResponses.lastName : '-';
                } else {
                    employeeId = "-"
                    employeeName = "-"
                }

                //get current role 
                let role = ""
                if (responses[i].requestedEmployeeRoleId) {
                    // var roleResponses = await DataStore.query(Roles, (c) => c.id.eq(responses[i].requestedEmployeeRoleId));
                    var roleResponses = await graphQLFindRecordById('getRoles', responses[i].requestedEmployeeRoleId);

                    role = roleResponses ? roleResponses.name : '';
                } else {
                    role = "-"
                }

                //get current Reporting Manager 
                let reportingManager = ""
                if (responses[i].reportingManagerEmployeeId) {
                    // var reportingManagerResponses = await DataStore.query(Employee, (c) => c.id.eq(responses[i].reportingManagerEmployeeId));
                    var reportingManagerResponses = await graphQLFindRecordById('getEmployee', responses[i].reportingManagerEmployeeId);

                    reportingManager = reportingManager ? reportingManagerResponses.firstName + " " + reportingManagerResponses.lastName : '';
                } else {
                    reportingManager = "-"
                }

                //bind data
                let data = {
                    "id": responses[i].id,
                    "requestNo": responses[i].requestNo,
                    "EmployeeID": employeeId,
                    "requestedEmployeeId": responses[i].requestedEmployeeId,
                    "reportingManagerEmployeeId": responses[i].reportingManagerEmployeeId,
                    "Name": employeeName,
                    "Role": role,
                    "vertical": "Distribution",
                    "SBU": "Value MEA",
                    "ReportingManager": reportingManager,
                    "DateSubmitted": responses[i].requestedDate === null ? "-" : moment(responses[i].requestedDate).format("Do MMM, YYYY"),
                    "DateApproved": responses[i].hrManagerApprovedDate === null ? "-" : moment(responses[i].hrManagerApprovedDate).format("Do MMM, YYYY"),
                    "reportingManagerApprovedDate": responses[i].reportingManagerApprovedDate === null ? "-" : moment(responses[i].reportingManagerApprovedDate).format("Do MMM, YYYY"),
                    "extendStartPeriod": responses[i].ExtendStartPeriod,
                    "extendEndPeriod": responses[i].ExtendEndPeriod,
                    "comments": responses[i].comments,
                    "Status": responses[i].hrRequestStatus,
                    "Action": "Action",
                    "lastDate": moment(responses[i].requestedDate).format('YYYY-MM-DD')
                }
                finalRequestData.push(data)

                if (i === responses.length - 1) {
                    let sortedArray = finalRequestData.sort(function (a, b) { return b.lastDate - a.lastDate });
                    setMyRequestList(sortedArray)
                }
                // }
            }
        }
        else {
            setMyRequestList([])
        }

    }

    const [initialYear, setInitialYear] = useState({});

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }

        var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

        setInitialYear({ label: responses[0].name, value: responses[0].id });

    }

    useEffect(() => {
        onLoad();
        bindList()
    }, [isShowCreatePopUp]);

    useEffect(() => {
        if (initialYear.value) {
            bindList()
            myRequests(initialYear);
        }
    }, [initialYear])

    //UI Part
    return (
        isAuthenticated ? (
            <div className="pt-16">
                <TopNavBar />
                <LeftSideBar />
                <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Change Request</div>
                        </div>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link className={"active"}>Planning Period</Link></li>
                                    {/* <li><Link to="/hr/changerequest/evaluationperiod" onClick={() => setSelectedTab(2)} className={selectedTab == 2 ? "active" : null}>Evaluation Period</Link></li> */}
                                    <li><Link to="/hr/changerequest/overrideperiod" onClick={() => setSelectedTab(3)} className={selectedTab == 3 ? "active" : null}>Override Actuals</Link></li>
                                </ul>
                            </div>

                            <div className="flex justify-end  items-center">
                                <div className="text-sm mr-3">Financial Year:</div>
                                <Select
                                    options={financialYearList}
                                    value={financialYear}
                                    onChange={e => {
                                        myRequests(e)
                                        setFinancialYear(e)
                                        reactLocalStorage.setObject('selectedYear', e);
                                    }}
                                    className="mt-2 mb-2 my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                        </div>


                        <div id="selector" className={isEditPopUp === 1 ? 'overlay' : ''}></div>
                        {
                            isEditPopUp === 1 ?
                                <div style={{ width: "45vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                                    <div >
                                        <img src={popupHeaderImg} alt="" style={{ width: "50vw" }} />
                                        <div className="absolute right-0 left-0 top-0 w-full text-white">
                                            <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                                <div className="text-md"><h6 className='text-[white]'>Change Request</h6></div>
                                                <div><Link onClick={() => clearAll()} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                            </div>

                                            <div className="px-6 mt-8">
                                                <span>
                                                    <Menu as="div" className="relative inline-block text-left">
                                                        <div className="flex items-center align-center">
                                                            <Menu.Button className='flex items-center'>
                                                            </Menu.Button>
                                                        </div>
                                                    </Menu>
                                                    <div className="text-sm text-[white]">Request</div>
                                                    <div className="2xl:text-[32px] xl:text-[28px] lg:xl:text-[20px] md:xl:text-[18px]">Manage Change Request
                                                        <div className="float-right text-sm mt-2"> Request ID: {employeeRequestorCode} </div></div>
                                                </span>
                                            </div>

                                        </div>
                                        <div className="py-2 px-6  height-custtom">
                                            <div className="bg-[#EEF8F4] my-4 text-sm p-4 rounded-md dark:bg-[#252423]">
                                                <div className="grid grid-cols-2 gap-3">
                                                    <div className="flex items-center">
                                                        <div className="text-xs text-[#98A2B3] min-w-[150px]">Requestor Name</div>
                                                        <div className="text-sm text-[#101828] dark:text-[#FFFF]">{requestorName}</div>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <div className="text-xs text-[#98A2B3] min-w-[150px]    px-12">Employee ID</div>
                                                        <div className="text-sm text-[#101828] dark:text-[#FFFF]">{employeeNo}</div>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <div className="text-xs text-[#98A2B3] min-w-[150px] ">Reporting Manager</div>
                                                        <div className="text-sm text-[#101828] dark:text-[#FFFF]">{reportingManagerName}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="flex justify-between planning_period  my-10">
                                                <li className="text-center">
                                                    <div className="text-sm text-[#344054] mb-3 min-w-[100px] text-center">Requested</div>
                                                    <div className="bg-[#029046] w-6 h-6 rounded-full text-white mx-auto flex justify-center items-center">
                                                    </div>
                                                    <div className="text-sm mt-2 text-[#344054]  dark:text-[white]">{requestedDate}</div>

                                                </li>
                                                <li className="text-center">
                                                    <div className="text-sm text-[#344054] mb-3 min-w-[100px] text-center">
                                                        Approval</div>
                                                    <div className="bg-[#029046] w-6 h-6  rounded-full text-white mx-auto flex justify-center items-center">
                                                    </div>
                                                    <div className="text-sm mt-2 text-[#344054] dark:text-[white]">{reportingManagerApprovedDate}</div>

                                                </li>
                                                <li className="text-center">
                                                    <div className="text-sm text-[#344054] mb-3 min-w-[100px] text-center">HR Review/Action</div>
                                                    <div className="bg-[#B3DDC7] w-6 h-6  rounded-full text-white mx-auto flex justify-center items-center">
                                                    </div>
                                                    <div className="text-sm mt-2 text-[#344054] dark:text-[white]">{hRApproveDate === "-" ? "" : hRApproveDate}</div>
                                                </li>
                                            </ul>

                                            {
                                                reporteeAndRMChat.length > 0 ?
                                                    <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                                        <div className="py-6 px-5 rounded-md h-64 overflow-auto" >
                                                            {
                                                                reporteeAndRMChat.map((item) => (
                                                                    <div className="rounded-lg space-y-6 mt-5 '">

                                                                        {
                                                                            item.sender_id !== item.rM_Id ?
                                                                                <div className="flex space-x-2 justify-start">
                                                                                    <div className="w-20 text-center">
                                                                                        <Avatar
                                                                                            style={{ width: 50, height: 50 }}
                                                                                            alt={item.sender_Name}
                                                                                            src={item.sender_Profile}
                                                                                            className=" mx-auto object-cover"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="bg-[#F2F4F7] max-w-xl p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]">
                                                                                        <div className="flex items-center justify-between ">
                                                                                            <div className="font-medium text-md mb-2 text-[#101828] dark:text-white">{item.sender_Name}</div>
                                                                                            <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                        </div>
                                                                                        <div className="text-sm  text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="flex space-x-2 justify-end">
                                                                                    <div className="bg-[#F2F4F7] max-w-xl  p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]" >
                                                                                        <div className="flex items-center justify-between">
                                                                                            <div className="font-medium text-md text-[#101828] mb-2 dark:text-white">{item.sender_Name}</div>
                                                                                            <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                        </div>
                                                                                        <div className="text-sm text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                    </div>
                                                                                    <div className="w-20 text-center">
                                                                                        <Avatar
                                                                                            style={{ width: 50, height: 50 }}
                                                                                            alt={item.sender_Name}
                                                                                            src={item.sender_Profile} />
                                                                                    </div>
                                                                                </div>
                                                                        }
                                                                    </div>

                                                                ))

                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                                        <img src={noCommentsImg} className="m-auto" alt="" style={{ width: "20vw" }} />
                                                    </div>

                                            }


                                            <div className="text-md mt-10  dark:text-[white] font-semibold text-md mb-2">HR Admin Action</div>
                                            <div className='flex items-center mb-3'>
                                                <input type="checkbox" id="openPlanningPeriod" className='w-4 h-4' onChange={(e) => {
                                                    setIsOpenAndExtend(!isOpenAndExtend)
                                                }} />
                                                <label htmlFor='openPlanningPeriod' className="text-sm  text-[#344054] dark:text-[white] ml-2">Open/Extend Planning Period</label>
                                            </div>
                                            {
                                                isOpenAndExtend &&
                                                <div>
                                                    <div className='flex grid flex-row flex-wrap flex-1  align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4 mt-5'>
                                                        <div className="relative">
                                                            <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                                Start Date <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                                </label></label>
                                                            <Calendar placeholder="Start Date" className="mt-2" value={extendStartDate} onChange={(e) => setExtendStartDate(e.value)} id="icon" showIcon />
                                                        </div>
                                                        <div className="relative">
                                                            <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                                End Date <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                                </label></label>
                                                            <Calendar placeholder="End Date" className="mt-2" value={extendEndDate} onChange={(e) => setExtendEndDate(e.value)} id="icon" showIcon />
                                                        </div>
                                                    </div>
                                                    {/* {
                                                        <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                                            <div className="py-6 px-5 rounded-md h-64 overflow-auto" >
                                                                {
                                                                    hRAndRMChat.map((item) => (
                                                                        <div className="rounded-lg space-y-6 mt-5 '">
                                                                            {
                                                                                item.sender_id !== item.hr_Id ?
                                                                                    <div className="flex space-x-2 justify-start">
                                                                                        <div className="w-20 text-center">
                                                                                            <Avatar
                                                                                                style={{ width: 50, height: 50 }}
                                                                                                alt={item.sender_Name}
                                                                                                src={item.sender_Profile}
                                                                                                className=" mx-auto object-cover"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="bg-[#F2F4F7] max-w-xl p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]">
                                                                                            <div className="flex items-center justify-between ">
                                                                                                <div className="font-medium text-md mb-2 text-[#101828] dark:text-white">{item.sender_Name}</div>
                                                                                                <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                            </div>
                                                                                            <div className="text-sm  text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="flex space-x-2 justify-end">
                                                                                        <div className="bg-[#F2F4F7] max-w-xl  p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]" >
                                                                                            <div className="flex items-center justify-between">
                                                                                                <div className="font-medium text-md text-[#101828] mb-2 dark:text-white">{item.sender_Name}</div>
                                                                                                <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                            </div>
                                                                                            <div className="text-sm text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                        </div>
                                                                                        <div className="w-20 text-center">
                                                                                            <Avatar
                                                                                                style={{ width: 50, height: 50 }}
                                                                                                alt={item.sender_Name}
                                                                                                src={item.sender_Profile} />
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    ))

                                                                }
                                                            </div>
                                                            <div className='relative px-5'>
                                                                <textarea
                                                                    type="text"
                                                                    value={(message)}
                                                                    onChange={event => setMessage(event.target.value)}
                                                                    placeholder='Type here...'
                                                                    className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-3 pl-4 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"></textarea>
                                                                <Link onClick={() => {
                                                                    SendMessage()
                                                                }} className='absolute right-[30px] top-[25px] text-[16px] bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block'>
                                                                    <i className="pi pi-send mr-2 relative top-0.5"></i>Send </Link>

                                                            </div>
                                                        </div>
                                                    } */}
                                                    <div className='mt-5 mb-2'>
                                                        <textarea
                                                            value={comments}
                                                            rows="5"
                                                            onChange={e => setComments(e.target.value)}
                                                            type="text" className="text-[16px] rounded-lg   flex-1  border border-[#C6CBD2] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]"
                                                            placeholder="Add Comment..." />
                                                    </div>

                                                    {/* <div className='my-5 relative'>
                                                        <div className=''>
                                                            <input
                                                                onChange={(e) => { selectDocuments(e,) }}
                                                                multiple="multiple"
                                                                type="file"
                                                                placeholder='Type here...'
                                                                className="pi pi-paperclip"
                                                            />
                                                        </div>
                                                    </div> */}

                                                    {
                                                        docList.map((item, index) => {
                                                            return (
                                                                <div key={index} className='block mb-3'>
                                                                    <div className='flex gap-2 border cursor-pointer border-[#C6CBD2] p-4 rounded-lg viewfile justify-between'>
                                                                        <div className='flex gap-2 items-center'>
                                                                            <i className='pi pi-file' style={{ fontSize: 20 }}></i>
                                                                            <p className='text-[15px] font-medium text-[#344054]'>{item.filename}</p>
                                                                        </div>
                                                                        <div className='flex gap-2'>
                                                                            <i onClick={() => { onClickRemoveDocuments(index) }} className='pi pi-times-circle' style={{ fontSize: 20 }}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }

                                        </div>
                                        <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                                            <div className="flex justify-end gap-3">
                                                <div><Link onClick={() => clearAll(0)} className="bg-white 
                                                dark:text-[white] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                                >Close</Link></div>
                                                <div><Link onClick={() => changeStatus(HR_CHANGE_REQUEST_STATUS.Declined)} className="bg-white 
                                                dark:text-[white] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                                >Decline</Link></div>
                                                <div><Link onClick={() => changeStatus(HR_CHANGE_REQUEST_STATUS.Approved)}
                                                    className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2 text-center">Accept</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                        }



                        <div id="selector" className={isViewPopUp ? 'overlay' : ''}></div>
                        {
                            isViewPopUp === 1 ?
                                <div style={{ width: "45vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                                    <div >
                                        <img src={popupHeaderImg} alt="" style={{ width: "50vw" }} />
                                        <div className="absolute right-0 left-0 top-0 w-full text-white">
                                            <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                                <div className="text-md"><h6 className='text-[white]'>View Request</h6></div>
                                                <div><Link onClick={() => clearAll()} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                            </div>
                                            <div className="px-6 mt-8">
                                                <span>
                                                    <Menu as="div" className="relative inline-block text-left">
                                                        <div className="flex items-center align-center">
                                                            <Menu.Button className='flex items-center'>
                                                            </Menu.Button>
                                                        </div>
                                                    </Menu>
                                                    <div className="text-sm text-[white]">Request</div>
                                                    <div className="text-2xl">View Change Request
                                                        <div className="float-right text-sm mt-2">Request ID: {employeeRequestorCode} </div>
                                                    </div>
                                                </span>
                                            </div>

                                        </div>

                                        <div className='height-custtom'>
                                            <div className="px-5 mt-8">
                                                <div className="bg-[#EEF8F4] my-4 text-sm p-4 rounded-md dark:bg-[#252423] ">
                                                    <div className="grid grid-cols-2 gap-3">
                                                        <div className="flex items-center">
                                                            <div className="text-xs text-[#98A2B3] min-w-[150px]">Requestor Name</div>
                                                            <div className="text-sm text-[#101828] dark:text-[#FFFF]">{requestorName}</div>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <div className="text-xs text-[#98A2B3] min-w-[150px]    px-12">Employee ID</div>
                                                            <div className="text-sm text-[#101828] dark:text-[#FFFF]">{employeeNo}</div>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <div className="text-xs text-[#98A2B3] min-w-[150px] ">Reporting Manager</div>
                                                            <div className="text-sm text-[#101828] dark:text-[#FFFF]">{reportingManagerName}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="flex justify-between planning_period  mt-5 my-10">
                                                    <li className="text-center">
                                                        <div className="text-sm text-[#344054] mb-3 min-w-[100px] text-center">Requested</div>
                                                        <div className="bg-[#029046] w-6 h-6 rounded-full text-white mx-auto flex justify-center items-center">
                                                        </div>
                                                        <div className="text-sm mt-2 text-[#344054]  dark:text-[white]">{requestedDate}</div>

                                                    </li>
                                                    <li className="text-center">
                                                        <div className="text-sm text-[#344054] mb-3 min-w-[100px] text-center">
                                                            Approval</div>
                                                        <div className="bg-[#029046] w-6 h-6 rounded-full text-white mx-auto flex justify-center items-center">
                                                        </div>
                                                        <div className="text-sm mt-2 text-[#344054] dark:text-[white]">{reportingManagerApprovedDate}</div>

                                                    </li>
                                                    <li className="text-center">
                                                        <div className="text-sm text-[#344054] mb-3 min-w-[100px] text-center">HR Review/Action</div>
                                                        {
                                                            isShowHrApproveDateValidation ?
                                                                <div className="bg-[#029046] w-6 h-6 rounded-full text-white mx-auto flex justify-center items-center">
                                                                </div> :
                                                                <div className="bg-[#B3DDC7] w-6 h-6 rounded-full text-white mx-auto flex justify-center items-center">
                                                                </div>
                                                        }
                                                        <div className="text-sm mt-2 text-[#344054] dark:text-[white]">{hRApproveDate === "-" ? "" : hRApproveDate}</div>
                                                    </li>
                                                </ul>
                                                {
                                                    reporteeAndRMChat.length > 0 ?
                                                        <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                                            <div className="py-6 px-5 rounded-md h-64 overflow-auto" >
                                                                {
                                                                    reporteeAndRMChat.map((item) => (
                                                                        <div className="rounded-lg space-y-6 mt-5 '">

                                                                            {
                                                                                item.sender_id !== item.rM_Id ?
                                                                                    <div className="flex space-x-2 justify-start">
                                                                                        <div className="w-20 text-center">
                                                                                            <Avatar
                                                                                                style={{ width: 50, height: 50 }}
                                                                                                alt={item.sender_Name}
                                                                                                src={item.sender_Profile}
                                                                                                className=" mx-auto object-cover"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="bg-[#F2F4F7] max-w-xl p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]">
                                                                                            <div className="flex items-center justify-between ">
                                                                                                <div className="font-medium text-md mb-2 text-[#101828] dark:text-white">{item.sender_Name}</div>
                                                                                                <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                            </div>
                                                                                            <div className="text-sm  text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="flex space-x-2 justify-end">
                                                                                        <div className="bg-[#F2F4F7] max-w-xl  p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]" >
                                                                                            <div className="flex items-center justify-between">
                                                                                                <div className="font-medium text-md text-[#101828] mb-2 dark:text-white">{item.sender_Name}</div>
                                                                                                <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                            </div>
                                                                                            <div className="text-sm text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                        </div>
                                                                                        <div className="w-20 text-center">
                                                                                            <Avatar
                                                                                                style={{ width: 50, height: 50 }}
                                                                                                alt={item.sender_Name}
                                                                                                src={item.sender_Profile} />
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div>

                                                                    ))

                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                                            <img src={noCommentsImg} className="m-auto" alt="" style={{ width: "20vw" }} />
                                                        </div>

                                                }
                                                <div className='my-5'>
                                                    <div className="text-md mt-2  dark:text-[white] font-semibold text-md mb-2">HR Admin Action</div>
                                                    <div className="text-sm mt-1 text-[#C6CBD2] font-[Inter]  dark:text-[white] pb-1">Open/Extend Planning Period</div>
                                                </div>
                                                <div className='flex grid flex-row flex-wrap flex-1  align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4 my-5'>
                                                    <div className="relative">
                                                        <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                            Start Date <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                            </label></label>
                                                        <Calendar disabled="true" placeholder="--Select--" className="mt-2" value={extendStartDate} onChange={(e) => setFromDate(e.value)} id="icon" showIcon />
                                                    </div>
                                                    <div className="relative">
                                                        <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                            End Date <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                            </label></label>
                                                        <Calendar disabled="true" placeholder="--Select--" className="mt-2" value={extendEndDate} onChange={(e) => setToDate(e.value)} id="icon" showIcon />
                                                    </div>
                                                </div>
                                                {/* {
                                                <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                                    <div className="py-6 px-5 rounded-md h-64 overflow-auto" >
                                                        {
                                                            hRAndRMChat.map((item) => (
                                                                <div className="rounded-lg space-y-6 mt-5 '">
                                                                    {
                                                                        item.sender_id !== item.hr_Id ?
                                                                            <div className="flex space-x-2 justify-start">
                                                                                <div className="w-20 text-center">
                                                                                    <Avatar
                                                                                        style={{ width: 50, height: 50 }}
                                                                                        alt={item.sender_Name}
                                                                                        src={item.sender_Profile}
                                                                                        className=" mx-auto object-cover"
                                                                                    />
                                                                                </div>
                                                                                <div className="bg-[#F2F4F7] max-w-xl p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]">
                                                                                    <div className="flex items-center justify-between ">
                                                                                        <div className="font-medium text-md mb-2 text-[#101828] dark:text-white">{item.sender_Name}</div>
                                                                                        <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                    </div>
                                                                                    <div className="text-sm  text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className="flex space-x-2 justify-end">
                                                                                <div className="bg-[#F2F4F7] max-w-xl  p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]" >
                                                                                    <div className="flex items-center justify-between">
                                                                                        <div className="font-medium text-md text-[#101828] mb-2 dark:text-white">{item.sender_Name}</div>
                                                                                        <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                    </div>
                                                                                    <div className="text-sm text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                </div>
                                                                                <div className="w-20 text-center">
                                                                                    <Avatar
                                                                                        style={{ width: 50, height: 50 }}
                                                                                        alt={item.sender_Name}
                                                                                        src={item.sender_Profile} />
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            ))

                                                        }
                                                    </div>
                                                    <div className='relative px-5'>
                                                        <textarea
                                                            type="text"
                                                            value={(message)}
                                                            onChange={event => setMessage(event.target.value)}
                                                            placeholder='Type here...'
                                                            className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-3 pl-4 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"></textarea>
                                                        <Link onClick={() => {
                                                            SendMessage()
                                                        }} className='absolute right-[30px] top-[25px] text-[16px] bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block'>
                                                            <i className="pi pi-send mr-2 relative top-0.5"></i>Send </Link>

                                                    </div>
                                                </div>
                                            } */}
                                                {/* <div className='mt-5 mb-2'>
                                                <textarea
                                                    value={comments}
                                                    rows="5"
                                                    onChange={e => setComments(e.target.value)}
                                                    type="text" className="text-[16px] rounded-lg   flex-1  border border-[#C6CBD2] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]"
                                                    placeholder="Add Comment..." />
                                            </div> */}

                                                {
                                                    uploadedFiles_HR.length > 0 &&
                                                    <div>
                                                        <label className='text-[16px] font-medium mb-3'> HR Documents</label>
                                                        {
                                                            uploadedFiles_HR.map((item, index) => {
                                                                return (
                                                                    <div key={index.toString()} className='block mb-3'>
                                                                        <div className='flex gap-2 border cursor-pointer border-[#C6CBD2] p-4 rounded-lg viewfile justify-between'>
                                                                            <div className='flex gap-2 items-center'>
                                                                                <i className='pi pi-file' style={{ fontSize: 20 }}></i>
                                                                                <p className='text-[15px] font-medium text-[#344054]'>{item.fileName.split("/").pop()}</p>
                                                                            </div>
                                                                            <div className='flex gap-2'>
                                                                                <i onClick={() => { downloadFile(item) }} className='pi pi-download' style={{ fontSize: 20 }}></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }

                                            </div>

                                        </div>
                                        <div className="lg:relative left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                                            <div className="flex justify-end gap-3">
                                                <div><Link onClick={() => clearAll(0)} className="bg-white 
                                                dark:text-[white] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                                >Close</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                        }
                        <div id="selector" className={isApprovedPopUp === 1 ? 'overlay' : ''}></div>
                        {
                            isApprovedPopUp === 1 ?
                                <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="displayPopupCont" className="sidenav">
                                    <div className="popup_display_img"><img src={imageDeployedLarge} className="mx-auto mt-8" alt="" /></div>
                                    <div className="text-xl text-center my-6 dark:text-white">Planning Period successfully extended !</div>
                                    <div className="block text-center my-6">
                                        <Link onClick={() => clearAll()} to="/hr/changerequest/planningperiod" className="bg-[#029046] text-white rounded-md px-6 py-2 text-sm shadow-md inline-block removeClass">Close</Link>
                                    </div>
                                </div> : null
                        }
                        <div id="selector" className={isDeclinePopUp === 1 ? 'overlay' : ''}></div>
                        {
                            isDeclinePopUp === 1 ?
                                <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="displayPopupCont" className="sidenav">
                                    <div className="popup_display_img"><img src={imageDeployedLarge} className="mx-auto mt-8" alt="" /></div>
                                    <div className="text-xl text-center my-6 dark:text-white">The Change Request has been Declined !</div>
                                    <div className="block text-center my-6">
                                        <Link onClick={() => clearAll()} to="/hr/changerequest/planningperiod" className="bg-[#029046] text-white rounded-md px-6 py-2 text-sm shadow-md inline-block removeClass">Close</Link>
                                    </div>
                                </div> : null
                        }
                        <div className=" rounded-lg mt-5 ">
                            {myRequestList.length > 0 ? <DataTable
                                // selection={selectedPlanningPeriod}
                                // onSelectionChange={e => setSelectedPlanningPeriod(e.value)}
                                paginator value={myRequestList} responsiveLayout="scroll" style={{ width: '93vw' }}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                            >
                                {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
                                <Column field="requestNo" header="Request ID" sortable filter filterPlaceholder="Search by Request ID"></Column>
                                <Column field="EmployeeID" header="Employee ID" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                <Column field="Name" header="Name" sortable filter filterPlaceholder="Search by Name"></Column>
                                <Column field="Role" header="Role" sortable filter filterPlaceholder="Search by Role"></Column>
                                <Column field="vertical" header="Vertical" sortable filter filterPlaceholder="Search by Vertical"></Column>
                                <Column field="SBU" header="SBU" sortable filter filterPlaceholder="Search by SBU"></Column>
                                <Column field="ReportingManager" header="Reporting Manager" sortable filter filterPlaceholder="Search by Reporting Manager"></Column>
                                <Column field='DateSubmitted' header="Date Submitted" filter filterPlaceholder="Search by Submitted Date" ></Column>
                                <Column field='DateApproved' header="Date Approved" filter filterPlaceholder="Search by Approved Date"  ></Column>
                                <Column field='Status' header="Status" body={requestsStatus} filter filterPlaceholder="Search by Status" ></Column>
                                <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnRoles} ></Column>
                            </DataTable> :
                                <div className='text-center py-8' ><ProgressSpinner /></div>
                            }

                        </div>


                    </div>
                </div>
            </div>
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}
