import { DataStore } from "aws-amplify";
import { Notifications } from "../../models";
import moment from "moment";
import { SendEmail } from "../../components/helper/mail"
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

const sendNotification = async (senderId, receiverId, notificationType, notification, notificationSubject) => {

    console.log("notification11111111---" + notification)

    await API.graphql(
        graphqlOperation(mutations.createNotifications, {
            input: {
                senderId: senderId,
                noticationDateTime: moment().format(),
                notification: notification,
                notificationType: notificationType,
                receiverId: receiverId,
                isRead: false
            }
        })
    );

    // await DataStore.save(
    //     new Notifications({
    //         "senderId": senderId,
    //         "noticationDateTime": moment().format(),
    //         "notification": notification,
    //         "notificationType": notificationType,
    //         "receiverId": receiverId,
    //         "isRead": false
    //     })
    // ).then((response) => {
    //     // console.log("response----" + JSON.stringify(response))
    // })
    // //send Email 
    SendEmail("ankit@edbrix.com", notificationSubject, notification)

}

export { sendNotification }
