import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify, DataStore } from "aws-amplify";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import awsExports from "./aws-exports";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import store from './store/store'
Amplify.configure(awsExports);
// DataStore.configure({
//   maxRecordsToSync: 100000,
//   syncPageSize: 2000
// });
Amplify.addPluggable(new AmazonAIPredictionsProvider());
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Authenticator.Provider> 
    {/* <React.StrictMode> */}
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <ReduxProvider store={store}>
          <App />
        </ReduxProvider>
        </MsalProvider>
      </BrowserRouter>
    {/* </React.StrictMode> */}
  </Authenticator.Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
