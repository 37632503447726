import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppLogoWhite from '../../assets/images/logo.png'

export default function VerifyUser_CompleteRegistration() {
    // Get data(Email) which is passed through navigation
    const location = useLocation();

    const [code, setCode] = useState("");
    let navigateTo = useNavigate();

    const resendCode = async () => {
        try {
            await Auth.resendSignUp(location.state.email);
            toast.success('Verification code has been sent to your email.');
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }

    const verifyCode_CompleteRegistration = () => {
        if (code === "") { toast.error('Enter code.'); return; }
        Auth.confirmSignUp(location.state.email, code)
            .then(data => {
                if (data) {
                    toast.success("User verified.")
                    navigateTo("/hr")
                }
            }).catch(e => {
                switch (e.message) {
                    case 'CodeMismatchException: Invalid verification code provided, please try again.':
                        toast.error(e.message);
                        break;
                    default:
                        toast.error(e.message);
                }
            })
    }

    return (
        <div className="bg-body-blue flex items-center min-h-screen p-4 bg-gray-100 lg:justify-center">
            <div className="flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md">
                <div className="p-4 py-6 text-white md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly dark:bg-[#252423]">
                    <div className="my-3 text-4xl font-bold tracking-wider text-center ">
                        <img src={AppLogoWhite} />
                    </div>
                </div>
                <div className="py-10 px-5 bg-white md:flex-1 dark:bg-[#252423]">
                    <h3 className="my-4  mb-1 text-2xl font-semibold text-gray-700 dark:text-[#FFFFFF]">Confirm your registration.</h3>
                    <h6 className="font-normal text-gray-700 text-sm dark:text-[#FFFFFF]">Please check your email for verification code.</h6>

                    <form action="#" className="flex flex-col mt-4">
                        <div className="flex flex-col space-y-1">
                            <input
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                type="number"
                                className="text-[15px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                            />
                        </div>

                        <button
                            type="button"
                            className="px-2 mt-3 text-[#1570EF] text-[14px] text-right font-medium"
                            onClick={() => resendCode()}

                        >Resend</button>
                        <div className="grow flex justify-center gap-3">
                            <Link
                                to={"/hr"}
                                className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                            >
                                Cancel
                            </Link>
                            <button
                                type="button"
                                onClick={() => verifyCode_CompleteRegistration()}
                                className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                            >
                                {"Submit"}
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}