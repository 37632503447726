import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import popupHeader from '../../assets/images/popup_header_img.png';
import BrandICON from '../../assets/svg/Brand.svg';
import BUICON from '../../assets/svg/BU.svg';
import { default as CountryICON, default as CustomerCountryICON } from '../../assets/svg/Country.svg';
import CustomerICON from '../../assets/svg/Customer.svg';
import MyReportICON from '../../assets/svg/MyReport.svg';
import RegionICON from '../../assets/svg/Region.svg';
import ReporteeICON from '../../assets/svg/Reportee.svg';
import SBUICON from '../../assets/svg/SBU.svg';
import LeftSideBar from '../../components/common/employee/leftnav.component';
import TopNavBar from '../../components/common/employee/topnav.component';
import { ConvertResponseForSelect, ConvertResponseForSelectTwoNames } from '../../components/helper/commonfunctions';
import { ValidateEmployeeRole } from "../../components/helper/validateRole";
import { BIUnit, Brand, Country, Customer, CustomerCategory, Employee, FinancialYear, Parameters, ProductGroup, Region, Roles, SBU } from '../../models';
import EmpLogin from "../../setup/auth/emp.component";
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from "react-toastify";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../src/components/helper/graphQLFunctions'


export default function EmployeeReports() {

    let navigateTo = useNavigate();
    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
    let isReportingManager = reactLocalStorage.get('isReportingManager')

    //state
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [isShowCreatePopUp, setShowCreatePopUp] = useState(0);
    const [financialYear, setFinancialYear] = useState("");
    const [BrandName, setBrandName] = useState();
    const [productGroupName, setProductGroupName] = useState();
    const [customerCategoryName, setCustomerCategoryName] = useState();
    const [customerName, setCustomerName] = useState();
    const [sbuName, setSBUName] = useState({ label: "All SBU", value: "0" });
    const [buName, setBUName] = useState({ label: "All BU", value: "0" });
    const [regionName, setRegionName] = useState();
    const [countryName, setCountryName] = useState();
    const [parameterName, setParameterName] = useState();
    const [rolesName, setRolesName] = useState();
    const [employeeName, setEmployeeName] = useState({ label: "All Employee", value: "0" });
    const [quarter, setQuarter] = useState("");
    const [financialYearName, setFinancialYearName] = useState("");
    const [financialYearId, setFinancialYearId] = useState("");
    const [selectedEmployeeId, setSelectedEmployeeId] = useState("All");


    //list
    const [sbuDropdown, setSbuDropdown] = useState([]);
    const [buDropdown, setBuDropdown] = useState([]);
    const [regionDropdown, setRegionDropdown] = useState([]);
    const [countryDropdown, setCountryDropdown] = useState([]);
    const [BrandDropdown, setBrandDropdown] = useState([]);
    const [productGroupDropdown, setProductGroupDropdown] = useState([]);
    const [customerCategoryDropdown, setCustomerCategoryDropdown] = useState([]);
    const [customerDropdown, setCustomerDropdown] = useState([]);
    const [parameterDropdown, setParameterDropdown] = useState([]);
    const [rolesDropdown, setRolesDropdown] = useState([]);
    const [employeeDropdown, setEmployeeDropdown] = useState([]);
    const [financialYearDropdown, setFinancialYearDropdown] = useState([]);


    const [showChildReport, setShowChildReport] = useState(false);



    //quarterList
    var quarterList = [
        { label: "Q1", value: "1" },
        { label: "Q2", value: "2" },
        { label: "Q3", value: "3" },
        { label: "Q4", value: "4" },
    ]


    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }

        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //onClick cancel
    async function onClickCancel() {
        setShowCreatePopUp(0)
        setFinancialYearName("")
        setFinancialYearId("")
        setEmployeeName("")
        setSelectedEmployeeId("")
    }

    //onClick Navigate
    async function onClickNavigate() {
        if (isReportingManager === "1") {
            if (financialYearId === "") { toast.error('Please select Financial Year.'); return; }
            if (showChildReport) {
                if (selectedEmployeeId === "") { toast.error('Please select Employee.'); return; }
                navigateTo("/employee/generatereport/" + financialYearId + "/" + selectedEmployeeId, { state: { sbuId: sbuName.value } })
            } else {
                navigateTo("/employee/generatereport/" + financialYearId + "/" + loggedEmployeeId, { state: { sbuId: sbuName.value } })
            }

        } else if (isReportingManager === "0") {
            if (financialYearId === "") { toast.error('Please select Financial Year.'); return; }
            navigateTo("/employee/generatereport/" + financialYearId,{ state: { sbuId: sbuName.value } })
        }
        onClickCancel()
    }

    //Master List
    const masterList = [
        { key: 1, title: "My Report", navigateTo: "", icon: MyReportICON },
    ];
    const masterListForReporteeManager = [
        { key: 1, title: "My Report", navigateTo: "", icon: MyReportICON },
        { key: 2, title: "Reportee's", navigateTo: "", icon: ReporteeICON },
    ];

    //onclick master list
    const Models = ({ value, icons }) => {
        return <div className="bg-[#EEF8F4] dark:bg-[#2A2E32] py-3 px-6 reportshadow rounded-lg mb-3">
            <Link onClick={() => {
                let check = value.localeCompare("Reportee's")
                if (check === 0 && isReportingManager === "1") {
                    setShowChildReport(true)
                } else {
                    setShowChildReport(false)
                }
                setShowCreatePopUp(1)
            }}>
                <div className="flex items-center justify-center">
                    <img src={icons} alt="" className='w-16' />
                </div>
                <div className="flex items-center justify-center mt-5">
                    <h3 className="text-[20px] text-[#101828] dark:text-white font-medium">{value}</h3>
                </div>
            </Link>
        </div>
    };

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    async function BindList() {

        // Financial Year dropdown code 
        // var financialYear = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var financialYear = await graphQLGetAllData('listFinancialYears');
        financialYear = financialYear.sort((a,b)=>a.name.localeCompare(b.name));
        var myFinancialYearArray = []; myFinancialYearArray = ConvertResponseForSelect(financialYear);
        setFinancialYearDropdown(myFinancialYearArray)


        // // sbu dropdownn code 
        // var sbuList = await DataStore.query(SBU, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var sbuList = await graphQLGetAllData('listSBUS');
        sbuList = sbuList.sort((a,b)=>a.name.localeCompare(b.name));


        var mySbuArray = [{ label: "All", value: "All" }]; mySbuArray = ConvertResponseForSelect(sbuList);
        setSbuDropdown(mySbuArray)

        // // Business unit dropdown code 
        // var BU = await DataStore.query(BIUnit, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var BU = await graphQLGetAllData('listBIUnits')
        BU = BU.sort((a,b)=>a.name.localeCompare(b.name));
        var myBuArray = []; myBuArray = ConvertResponseForSelect(BU);
        setBuDropdown(myBuArray)

        // // Region  dropdown code 
        // var region = await DataStore.query(Region, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        // var myRegionArray = []; myRegionArray = ConvertResponseForSelect(region);
        // setRegionDropdown(myRegionArray)

        // // Country  dropdown code 
        // var country = await DataStore.query(Country, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        // var myCountryArray = []; myCountryArray = ConvertResponseForSelect(country);
        // setCountryDropdown(myCountryArray)

        // // Brand  dropdown code 
        // var brand = await DataStore.query(Brand, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        // var myBrandArray = []; myBrandArray = ConvertResponseForSelect(brand);
        // setBrandDropdown(myBrandArray)

        // // Product Group  dropdown code 
        // var productGroup = await DataStore.query(ProductGroup, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        // var myProductGroupArray = []; myProductGroupArray = ConvertResponseForSelect(productGroup);
        // setProductGroupDropdown(myProductGroupArray)

        // // Customer Category  dropdown code 
        // var customerCategory = await DataStore.query(CustomerCategory, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        // var myCustomerCategoryArray = []; myCustomerCategoryArray = ConvertResponseForSelect(customerCategory);
        // setCustomerCategoryDropdown(myCustomerCategoryArray)

        // // customer  dropdown code 
        // var customer = await DataStore.query(Customer, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        // var myCustomerArray = []; myCustomerArray = ConvertResponseForSelect(customer);
        // setCustomerDropdown(myCustomerArray)

        // // parameter dropdown code 
        // var parameter = await DataStore.query(Parameters, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        // var myParameterArray = []; myParameterArray = ConvertResponseForSelect(parameter);
        // setParameterDropdown(myParameterArray)

        // // Roles dropdown code 
        // var roles = await DataStore.query(Roles, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        // var myRolesArray = []; myRolesArray = ConvertResponseForSelect(roles);
        // setRolesDropdown(myRolesArray)

        // Employee dropdown code 
        // var employee = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(loggedEmployeeId), {
        //     sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
        // });
        var employee = await graphQLGetAllData('listEmployees', [{ parentEmployeeId: { eq: loggedEmployeeId } }]);

        employee = employee.sort((a,b)=> a.firstName.localeCompare(b.firstName) ||  a.lastName.localeCompare(b.lastName));

        var myEmployeeArray = [{ label: "All Employee", value: "0" }]; myEmployeeArray = ConvertResponseForSelectTwoNames(employee);
        setEmployeeDropdown(myEmployeeArray)

    }


    useEffect(() => {
        onLoad();
        BindList();
    }, []);


    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            <div className="pl-16 dashboard-main-wrapper setting-wrapper" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="items-center space-x-8 sm:flex">
                        <div className="text-2xl font-semibold dark:text-white">Reports</div>
                    </div>
                    <div
                        className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 setting-wrapper"
                    >
                        {
                            isReportingManager === "1" ?
                                masterListForReporteeManager.map(v => (
                                    <Models key={v.title} value={v.title} navigateTo={v.navigateTo} highlight="" icons={v.icon} tabValue={1} />
                                )) :
                                masterList.map(v => (
                                    <Models key={v.title} value={v.title} navigateTo={v.navigateTo} highlight="" icons={v.icon} tabValue={1} />
                                ))

                        }
                    </div>
                </div>
                <div id="selector" className={isShowCreatePopUp === 1 ? 'overlay' : ''}></div>
                {
                    isShowCreatePopUp === 1 ?
                        <div style={{ width: "44.271vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="editPopupCont" className="sidenav">
                            <div className="popup_header relative ">
                                <img src={popupHeader} alt="" />
                                <div className="absolute right-0 left-0 top-0 w-full text-white">
                                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                        <div className="text-md">Reports</div>
                                        <div><Link onClick={() => setShowCreatePopUp(0)} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                    </div>
                                    <div className="px-6 mt-2">
                                        <div className="text-sm text-[#80C7A2]">Reports</div>
                                        <div className="text-2xl mt-6">{"Select Filter"}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-6 height-custtom  " >

                                <div
                                    className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-5 setting-wrapper  "
                                >
                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Period <label className="text-[#FF0000] text-[14px] font-medium">
                                        * </label></label>
                                    <Select
                                        value={financialYearName}
                                        options={financialYearDropdown}
                                        onChange={e => {
                                            setFinancialYearName(e);
                                            setFinancialYearId(e.value);
                                            reactLocalStorage.set('financialYearId',e.value);
                                        }}
                                        placeholder={"FY"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                </div>
                                {
                                    isReportingManager === "1" && showChildReport &&
                                    <div className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-5 setting-wrapper">
                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Employee </label>

                                        <Select
                                            value={employeeName}
                                            options={employeeDropdown}
                                            onChange={e => {
                                                setEmployeeName(e)
                                                setSelectedEmployeeId(e.value);
                                            }}
                                            placeholder={"Employee Name"}
                                            className="my-react-select-container col-span-2"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>

                                }


                                {
                                    isReportingManager === "1" && showChildReport &&
                                    <div className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-5 setting-wrapper">
                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">SBU</label>
                                        {
                                            <Select
                                                value={sbuName}
                                                options={sbuDropdown}
                                                onChange={e => { setSBUName(e) }}
                                                placeholder={"SBU"}
                                                className="my-react-select-container col-span-2"
                                                classNamePrefix="my-react-select"
                                            />}
                                    </div>}

                                {/* <div
                                    className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-1 setting-wrapper"
                                >
                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Category</label>
                                    <Select
                                        value={regionName}
                                        options={regionDropdown}
                                        onChange={e => { setRegionName(e) }}
                                        placeholder={"Region"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    <Select
                                        value={countryName}
                                        options={countryDropdown}
                                        onChange={e => { setCountryName(e) }}
                                        placeholder={"Country"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                </div> */}
                                {/* <div
                                    className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-1 setting-wrapper"
                                >
                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Business</label>
                                    <Select
                                        value={BrandName}
                                        options={BrandDropdown}
                                        onChange={e => { setBrandName(e) }}
                                        placeholder={"Brand"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    <Select
                                        value={productGroupName}
                                        options={productGroupDropdown}
                                        onChange={e => { setProductGroupName(e) }}
                                        placeholder={"Product Group"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                </div> */}
                                {/* <div
                                    className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-1 setting-wrapper"
                                >
                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Sales</label>
                                    <Select
                                        value={customerCategoryName}
                                        options={customerCategoryDropdown}
                                        onChange={e => { setCustomerCategoryName(e) }}
                                        placeholder={"Customer Category"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    <Select
                                        value={customerName}
                                        options={customerDropdown}
                                        onChange={e => { setCustomerName(e) }}
                                        placeholder={"Customer "}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                </div> */}
                                {/* <div
                                    className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-1 setting-wrapper"
                                >
                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white col-span-1">Parameter</label>
                                    <Select
                                        value={parameterName}
                                        options={parameterDropdown}
                                        onChange={e => { setParameterName(e) }}
                                        placeholder={"Parameter"}
                                        className="my-react-select-container col-span-4"
                                        classNamePrefix="my-react-select"
                                    />
                                </div> */}
                                {/* <div
                                    className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-1 setting-wrapper"
                                >
                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Roles</label>
                                    <Select
                                        value={rolesName}
                                        options={rolesDropdown}
                                        onChange={e => { setRolesName(e) }}
                                        placeholder={"Roles"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    <Select
                                        value={employeeName}
                                        options={employeeDropdown}
                                        onChange={e => { setEmployeeName(e) }}
                                        placeholder={"Employee Name"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                </div> */}
                            </div>
                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">
                                <ul className="flex justify-end gap-3">
                                    <li><button onClick={() => {
                                        onClickCancel()
                                    }} className="bg-[#FFFFFF] border border-[#C6CBD2] py-2.5 px-3 text-black text-[15px] rounded-md active addClass dark:bg-[#44494E] dark:text-[#FFFFFF] dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white">Cancel</button></li>
                                    <li><button onClick={() => {
                                        onClickNavigate()
                                    }} className="bg-[#029046] py-2.5 px-3 text-white text-[15px] rounded-md active addClass">Generate Report</button></li>
                                </ul>
                            </div>
                        </div> : null
                }

            </div>
            <div id="selector"></div>
        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}


