import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { reactLocalStorage } from 'reactjs-localstorage';
import BoxPlanning from '../../../../assets/images/dashboard_box_planning.png';
import LeftNav from '../../../../components/common/employee/leftnav.component';
import TopNav from '../../../../components/common/employee/topnav.component';
import { ConvertResponseForSelect } from '../../../../components/helper/commonfunctions';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { FinancialYear, Parameters, Relationship, RelationshipDetails, TargetDetails, Targets, Employee, Planning } from "../../../../models";
import EmpLogin from "../../../../setup/auth/emp.component";
import { ShowInMillion } from '../../../../components/helper/maths';
import { PARAMETERS_TYPES } from '../../../../components/helper/enum';
import NoDataFound from '../../../../components/common/nodatafound';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../src/components/helper/graphQLFunctions'


export default function TeamManageCommit() {
    let isReportingManager = reactLocalStorage.get('isReportingManager')
    let navigateTo = useNavigate();

    const [financialYear, setFinancialYear] = useState("");
    const [financialYearList, setFinancialYearList] = useState([]);
    const [planningPeriod, setPlanningPeriod] = useState([]);

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //Manage Targets
    const [targetsList, setTargetsList] = useState([]);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //onclick financial year
    const onFinancialSelectChange = async (e) => {
        setFinancialYear({ label: e.label, value: e.value })
    }

    const bindList = async () => {
        //set financial year
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        if (Object.keys(selectedYear).length !== 0) {
            setFinancialYear(selectedYear)
            getTargets(selectedYear)
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

            getTargets({ label: responses[0].name, value: responses[0].id })
            setFinancialYear({ label: responses[0].name, value: responses[0].id })
        }
        //get financial year
        // var responses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var responses = await graphQLGetAllData('listFinancialYears')
        responses = responses.sort((a,b)=>a.name.localeCompare(b.name));
        var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYearList(myArray);
    }

    //get targets
    const getTargets = async (selectedYear) => {
        let getEmployeeId = reactLocalStorage.get("loggedEmployeeId")
        // let financialYearResponses = await DataStore.query(FinancialYear, (c) => c.name.eq(selectedYear.label));
        let financialYearResponses = await graphQLGetAllData('listFinancialYears', [{ name: { eq: selectedYear.label } }]);

        // let planningResponses = await DataStore.query(Planning, (c) => c.financial_year_id.eq(selectedYear.value));
        
        let planningResponses = await graphQLGetAllData('listPlannings', [{ financial_year_id: { eq: selectedYear.value } }]);

        if (planningResponses.length > 0) {
            setPlanningPeriod(moment(planningResponses[0].planning_from_date).format("Do MMM YY") + " - " + moment(planningResponses[0].planning_to_date).format("Do MMM YY"))
        } else { setPlanningPeriod("") }
        try {

            // var currentEmployee = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(getEmployeeId))
            var currentEmployee = await graphQLGetAllData('listEmployees', [{ parentEmployeeId: { eq: getEmployeeId } }]);

            let targetsResponses = ""
            if (currentEmployee.length) {
                // targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
                //     c.employee_id.eq(getEmployeeId),
                //     c.report_manager_employee_id.eq(getEmployeeId),
                //     c.financial_year_id.eq(selectedYear.value),
                //     c.isAddedByHRAdmin.eq(false),
                //     c.isAssignedToEmployee.eq(true)
                // ]));
                targetsResponses =await graphQLGetAllData('listTargets', [
                    { employee_id: { eq: getEmployeeId } },
                    { report_manager_employee_id: { eq: getEmployeeId } },
                    { financial_year_id: { eq: selectedYear.value } },
                    { isAddedByHRAdmin: { eq: false } },
                    { isAssignedToEmployee: { eq: true } }
                ]);
            } else {
                // targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
                //     c.employee_id.eq(getEmployeeId),
                //     //c.report_manager_employee_id.eq(getEmployeeId),
                //     c.financial_year_id.eq(selectedYear.value),
                //     c.isAddedByHRAdmin.eq(false),
                //     c.isAssignedToEmployee.eq(true)
                // ]));
                targetsResponses = await graphQLGetAllData('listTargets', [
                    { employee_id: { eq: getEmployeeId } },
                    { financial_year_id: { eq: selectedYear.value } },
                    { isAddedByHRAdmin: { eq: false } },
                    { isAssignedToEmployee: { eq: true } }
                ]);
            }

            console.log(targetsResponses)



            //get parameters weightage
            // var relationshipResponse = await DataStore.query(Relationship, (c) => c.and(c => [
            //     c.role_id.eq(targetsResponses[0].role_id),
            //     c.financial_year_id.eq(selectedYear.value)
            // ]));
            
            var relationshipResponse = await graphQLGetAllData('listRelationships', [
            { role_id: { eq: targetsResponses[0].role_id } },
            { financial_year_id: { eq: selectedYear.value } }
        ]);

            var relationshipDetailResponses = []
            if (relationshipResponse.length > 0) {
                // relationshipDetailResponses = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationshipResponse[0].id));
                relationshipDetailResponses = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: relationshipResponse[0].id } }]);

            }
            var isPlanMandatory = 0;
            if (relationshipDetailResponses.length > 0) {
                isPlanMandatory = 1;
            }
            var _tempArray = [];

            //Target Details
            // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
            //     c.target_id.eq(targetsResponses[0].id),
            //     c.is_approve.eq(true)
            // ]));
            
            var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                { target_id: { eq: targetsResponses[0].id } },
                { is_approve: { eq: true } }
            ]);



            console.log(currentTargetDetails)
            currentTargetDetails.forEach(async (currentItem, i) => {
                //get parameters details
                // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(currentItem.parameter_id));
                var parameterResponses = await graphQLFindRecordById('getParameters', currentItem.parameter_id);

                var getWeightage = relationshipDetailResponses.filter((e) => e.parameter_id === currentItem.parameter_id)
                let data = {
                    "financial_year_id": selectedYear.value,
                    "financial_year": selectedYear.label,
                    "parameter_id": currentItem.parameter_id,
                    "parameter_name":parameterResponses.name,
                    "parameter_type":parameterResponses.type,
                    "target_value":parameterResponses.type === PARAMETERS_TYPES.Days ? Math.floor(currentItem.target_value) :parameterResponses.type === PARAMETERS_TYPES.Amount ? ShowInMillion(parseInt(currentItem.target_value)) : currentItem.target_value,
                    "parameter_weightage": getWeightage.length > 0 ? getWeightage[0].weightage : "",
                    "isPlanMandatory": getWeightage.length > 0 ? 1 : 0,
                    "planning_period": moment(financialYearResponses[0].fromDate).format("Do MMM YY") + " - " + moment(financialYearResponses[0].toDate).format("Do MMM YY")
                }
                _tempArray.push(data)
                if (i === currentTargetDetails.length - 1) {
                    let sortArray = _tempArray.sort((a, b) => (a.parameter_name > b.parameter_name ? 1 : -1))
                    setTargetsList(sortArray)
                }
            })
        } catch (e) {

        }
    }


    useEffect(() => {
        bindList()
        onLoad();
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNav />
            <LeftNav />

            <div className="dashboard-main-wrapper pl-16 target-main-wrapper" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="sm:flex items-center space-x-8">
                        <div className="text-2xl font-semibold dark:text-white">Goals Setting</div>
                        <div className="tabs_one mt-5 sm:mt-0">
                            <ul className="flex">
                                <li><Link className='active' >My Team</Link></li>
                                <li><Link to="/employee/planning/self/manageplan" >Myself</Link></li>
                            </ul>
                        </div>
                    </div>

                    <>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/employee/planning/team/managetarget">Manage Targets</Link></li>
                                    <li><Link to="/employee/planning/team/assigntarget" >Assign Target</Link></li>
                                    <li><Link to="/employee/planning/team/reviewcommit" >Review Commit</Link></li>
                                    <li><Link className='active' >Manage Commit</Link></li>
                                </ul>
                            </div>
                            <div className="mt-5 lg:mt-0 flex items-center gap-4">
                                <div>
                                    {
                                        planningPeriod !== "" ?
                                            <label className='text-sm px-2 py-1.5 border rounded-md border-[#4FB155] text-[#4FB155] dark:text-[#4FB155]'>Goal Setting Timeline: {planningPeriod} </label>
                                            : ""
                                    }
                                </div>
                                <div className='flex items-center'>
                                    <div className="text-md min-w-fit dark:text-white mr-2">Financial Year</div>
                                    <div className="w-full min-w-[200px]">
                                        <Select
                                            options={financialYearList}
                                            value={financialYear}
                                            onChange={e => {
                                                onFinancialSelectChange(e)
                                                reactLocalStorage.setObject('selectedYear', e);
                                            }}
                                            className="mt-2 my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />

                                    </div>
                                </div>

                            </div>
                        </div>


                        {
                            targetsList.length > 0 ?
                                <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3 gap-4">
                                    {
                                        targetsList.map((item) => {
                                            return (
                                                <div key={item.parameter_id.toString()} className="bg-white py-4 rounded-xl dashbord_box_shape boxShadow boxH relative dark:bg-[#2A2E32]">
                                                    <div className="grid grid-cols-1">
                                                        <div className="px-4">
                                                            <div className="text-[#344054] text-md mb-1 dark:text-[#98A2B3]">{item.parameter_name}</div>
                                                            {
                                                                item.parameter_type === "D" ?
                                                                    <div className="text-2xl font-semibold mb-1  dark:text-[#FFFF]">{item.target_value} Days</div> :
                                                                    item.parameter_type === "A" ?
                                                                        <div className="text-2xl font-semibold mb-1  dark:text-[#FFFF]">${item.target_value}</div> :
                                                                        item.parameter_type === "P" ?
                                                                            <div className="text-2xl font-semibold mb-1  dark:text-[#FFFF]">{item.target_value}%</div> :
                                                                            <div className="text-2xl font-semibold mb-1  dark:text-[#FFFF]">{item.target_value}</div>
                                                            }
                                                            <div className="text-[#344054] text-xs dark:text-[#98A2B3] mb-2 mt-2">Weightage: {item.parameter_weightage}%</div>
                                                            <div className="text-[#98A2B3] text-xs mb-5 dark:text-[#98A2B3]">Planning Period: {item.planning_period}</div>

                                                        </div>
                                                    </div>
                                                    <div className="px-4 mt-8">
                                                        <div className="">
                                                            <ul className="flex xl:mt-14 2xl:mt-20">
                                                                <li><button

                                                                    onClick={() => navigateTo("/employee/planning/team/managecommit/annualtarget/" + item.financial_year_id + "/" + item.parameter_id,)}
                                                                    className="bg-[#029046] py-2 px-5 text-white text-[16px] rounded-md active addClass">View</button></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="absolute top-3 right-2 bottom-0 flex items-center dashbord_box_img1">
                                                        <img src={BoxPlanning} alt="" />
                                                        <div
                                                            className="absolute top-0 right-2">{item.isPlanMandatory == 1 ? <i className='icon s_icon'></i> : <i className='icon t_icon'></i>}</div>
                                                    </div>
                                                </div>
                                            )

                                        })
                                    }
                                </div>
                                :
                                <NoDataFound />
                        }

                    </>


                </div>
            </div>

        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}