import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { themeAction } from '../../../store/actions/theme';
import AppLogo from '../AppLogo';
import HRProfile from "./profile.component";
import { DataStore, SortDirection } from 'aws-amplify';
import { Notifications } from "../../../models";
import moment from "moment";
import { reactLocalStorage } from 'reactjs-localstorage';


export default function TopNav() {
    const currentThemeValue = useSelector((state) => state.currentTheme.value);
    const dispatch = useDispatch();

    //Notifications
    const [notificationList, setNotificationList] = useState([]);
    const [notificationCount, setNotificationCount] = useState("");

    //Read All Notifications
    async function onClickMarkAsRead() {
        notificationList.map(async (response) => {
            const original = await DataStore.query(Notifications, response.id);
            await DataStore.save(
                Notifications.copyOf(original, (updated) => {
                    updated.isRead = true;
                })
            ).then((response) => {
                BindList();
            })
        })
    }

    //Read Single Notifications
    async function onClickCancel(notificationId) {
        const original = await DataStore.query(Notifications, notificationId);
        await DataStore.save(
            Notifications.copyOf(original, (updated) => {
                updated.isRead = true;
            })
        ).then((response) => {
            BindList();
        })
    }

    async function BindList() {

        //get Notifications
        var currentEmpId = reactLocalStorage.get('loggedHREmployeeId');
        var notificationsResponse = await DataStore.query(Notifications, (c) => c.and(c => [
            c.receiverId.eq(currentEmpId),
            c.isRead.eq(false),
        ]), {
            sort: s => s.noticationDateTime(SortDirection.DESCENDING)
        });
        setNotificationList(notificationsResponse);
        //get notification count
        var isUnReadCount = notificationsResponse.filter((response) => response.isRead === false).map((item) => item)
        setNotificationCount(isUnReadCount.length);
    }

    useEffect(() => {
        // onLoad();
        // BindFinancialYearList();
        BindList();
    }, []);



    return (
        <div className="w-full text-gray-700 bg-white shadow-md fixed top-0 z-10 headar-wrapper dark:bg-[#232528] dark:shadow-md">
            <div x-data="{ open: false }" className="flex flex-col px-2 mx-auto md:items-center md:justify-between md:flex-row">
                <div className="flex flex-row items-center justify-between p-2 px-2">
                    <Link to="/hr/dashboard/planning" className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">
                        <AppLogo />
                    </Link>
                    <button className="rounded-lg md:hidden focus:outline-none focus:shadow-outline">
                        <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                            <path x-show="!open" fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd"></path>
                            <path x-show="open" fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                        </svg>
                    </button>
                </div>
                <nav className="flex-col flex-grow hidden pb-4 md:flex md:items-center md:pb-0 md:justify-end md:flex-row h-16">
                    <div className="items-center justify-center flex-1 md:flex md:justify-end">
                        <div className="notification">
                            <div className="relative px-3 py-2 mt-2 mr-3 text-sm font-semibold text-gray-900 notBtn md:flex md:mt-0 hover:text-gray-900 focus:text-gray-900 focus:outline-none" >
                                <i className="icon notification_icon "></i>
                                {
                                    notificationCount > 0 &&
                                    <span className="w-5 h-5 rounded-full bg-[#029046] text-center text-[10px] text-[#fff] absolute right-0">{notificationCount}</span>
                                }

                                <div className="box">
                                    {
                                        notificationList.length > 0 ?
                                            <div className="p-3 display">
                                                <div className='flex justify-between'>
                                                    <h6 className='text-[#101828] text-[15px] font-medium dark:text-white'> Your notifications</h6>
                                                    {
                                                        notificationCount > 0 &&
                                                        <Link onClick={() => {
                                                            onClickMarkAsRead();
                                                        }} className='text-[#01813F] text-[12px] font-medium'>Mark all as read</Link>
                                                    }
                                                </div>
                                                <div className='h-42'>
                                                    {
                                                        notificationList.map((item, index) => {
                                                            if (index >= 2) {
                                                                return null
                                                            } else {
                                                                return (

                                                                    <div key={index} className='flex items-center justify-between gap-3 mt-3 mb-3 notification-block'>
                                                                        <i className='icon warn_icon'></i>
                                                                        <div>
                                                                            <h6 className='text-[#101828] text-[12px] font-medium dark:text-white'>{item.notification}</h6>
                                                                            <p className='text-[#98A2B3] text-[12px] font-normal'>{moment(item.noticationDateTime).locale('it').fromNow()}</p>
                                                                        </div>
                                                                        <i onClick={() => {
                                                                            onClickCancel(item.id)
                                                                        }}
                                                                            className='icon close_icon_notification'></i>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <Link to='/hr/viewnotification' className='mt-3 mb-2 flex w-full border border-[#E4E7EC] text-[#01813F] py-2 p-2 rounded-md justify-center text-[12px] font-normal dark:bg-[#44494E] dark:text-white dark:border-[#44494E]'>View More</Link>
                                            </div> :
                                            <div className='nodata mt-5'>
                                                <i className='icon bell_icon'></i>
                                                <h1 >No Notification Right Now!</h1>
                                                <Link to='/hr/viewnotification'
                                                    className='mt-3 mb-2 flex w-full text-[#01813F] py-2 p-2 rounded-md justify-center text-[12px] font-normal dark:bg-[#44494E] dark:text-white dark:border-[#44494E]'>View All Notification</Link>

                                            </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <Link onClick={() => currentThemeValue === undefined ? dispatch(themeAction("D")) : currentThemeValue === "W" ? dispatch(themeAction("D")) : dispatch(themeAction("W"))} className="relative px-3 py-2 mt-2 text-sm font-semibold text-gray-900 md:flex md:mt-0 hover:text-gray-900 focus:text-gray-900 focus:outline-none" >
                            <i className="icon sun_icon mt-1.5 "></i>
                        </Link>
                    </div>

                    <div className="relative ml-5" x-data="{ open: false }">
                        <HRProfile />
                    </div>
                </nav>
            </div>
        </div>
    )

}