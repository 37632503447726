import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import { SBU } from '../../../../../models'
import HRLogin from '../../../../../setup/auth/hr.component';
import { DataStore } from "@aws-amplify/datastore";
import capitalizeFirst from "../../../../../components/common/capitalizeFirst";
import { ValidateHRRole } from "../../../../../components/helper/validateRole";
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

export default function CreateLanguage() {
    let navigateTo = useNavigate();

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);

    const { editSBUId } = useParams();
    const [isEditMode, setEditMode] = useState(false);

    //State
    const [sbuName, setSbuName] = useState("");
    const [sbuId, setSbuId] = useState("");
    const [refCode, SetRefCode] = useState("");


    //Create Record
    const saveSBU = async () => {
        if (sbuName === "") {
            toast.error("Please enter SBU.");
            return;
        }

        setPleaseWaitButton(true);
        if (isEditMode) {
            // const original = await DataStore.query(SBU, sbuId);
            const original = await graphQLFindRecordById('getSBU', sbuId);
            // await DataStore.save(
            //     SBU.copyOf(original, (updated) => {
            //         updated.name = capitalizeFirst(sbuName);
            //         updated.REF_CODE=capitalizeFirst(refCode);
            //     })
            // );

            console.log("original - before udpate", original);
           let updatedData = await API.graphql({
                query: mutations.updateSBU,
                variables: {
                    input: {
                        id: original.id,
                        name: capitalizeFirst(sbuName),
                        REF_CODE: capitalizeFirst(refCode),
                        _version: original._version,
                    }
                }
            });

            console.log("original - after udpate", updatedData.data.updateSBU);
            let originalagain = await graphQLFindRecordById('getSBU', sbuId);

            console.log("original - again", originalagain);

            toast.success('Updated');
        } else {
            // await DataStore.save(new SBU({ name: capitalizeFirst(sbuName), REF_CODE: capitalizeFirst(refCode) }));
            await API.graphql(
                graphqlOperation(mutations.createSBU, {
                    input: {
                        name: capitalizeFirst(sbuName),
                        REF_CODE: capitalizeFirst(refCode)
                    }
                })
            );
            toast.success('Added');
        }

        
        setPleaseWaitButton(false);
        navigateTo("/hr/setting/dimensions/sbu");
    };

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }

            //Edit existing record
            if (editSBUId != null) {
                setSbuId(editSBUId);

                // const original = await DataStore.query(SBU, editSBUId);
                const original = await graphQLFindRecordById('getSBU', editSBUId);
                if (original != null) {
                    setSbuName(original.name);
                    SetRefCode(original.REF_CODE);
                    setEditMode(true);
                }
                else {
                    toast.error('Invalid record.');
                    navigateTo("/hr/setting/dimensions/sbu");
                }

            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        isAuthenticated ? (
            <div>
                <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full ">
                    <TopNavBar />
                    <LeftSideBar />

                    <div className="pl-16">
                        <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                            <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                                <div className="p-3 py-5 bg-white rounded-md shadow dark:bg-[#252423]">
                                    <div className="flex justify-center mt-3 mb-2">
                                        <h2 className="page-title">
                                            {isEditMode ? "Edit" : "Create"} SBU
                                        </h2>
                                    </div>
                                    <div x-data="{tab: 1}"></div>
                                    <div className={"block w-full"}>
                                        <div className="px-4" x-show="tab === 1">
                                            <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-x-4">
                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        SBU
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *{" "}
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(sbuName)}
                                                        onChange={(e) => setSbuName(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter SBU"
                                                    />
                                                </div>
                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Ref_Code
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >

                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(refCode)}
                                                        onChange={(e) => SetRefCode(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Ref Code"
                                                    />
                                                </div>
                                            </div>
                                            <div className="grow flex justify-center gap-3 mb-3">
                                                <Link
                                                    to="/hr/setting/dimensions/sbu"
                                                    className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:border-[#4A5056] dark:bg-[#4A5056] dark:text-white"
                                                >
                                                    Cancel
                                                </Link>
                                                <button
                                                    onClick={() => saveSBU()}
                                                    className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                                                >
                                                    {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <HRLogin sessionExpired={1} />
        )

    );
}
