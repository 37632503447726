const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
        return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
}

const ShowInMillion = (current_value, precision = 3) => {
    current_value = current_value / 1000000;
    return current_value.toPrecision(precision).toString() + 'M';
}

var sumFromArray = (propertyName, array) => {
    let sum = 0;
    array.forEach(item => {
        if(!isNaN(item[propertyName])){
            sum += Number(item[propertyName]) ?? 0;
        }
    });
    return sum;
};


var commaSeparated = (value) => {
    var x=value;
    x=x.toString();
    var afterPoint = '';
    if(x.indexOf('.') > 0)
       afterPoint = x.substring(x.indexOf('.'),x.length);
    x = Math.floor(x);
    x=x.toString();
    var lastThree = x.substring(x.length-3);
    var otherNumbers = x.substring(0,x.length-3);
    if(otherNumbers != '')
        lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    return res
}

export { isPositiveInteger, ShowInMillion, sumFromArray,commaSeparated };
