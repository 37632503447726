import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataTableCustomStyles } from '../../../../../components/helper/dataTable.config';
import { ProductGroup, Brand } from '../../../../../models'
import HRLogin from '../../../../../setup/auth/hr.component';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import EditButton from '../../../../../components/common/buttons/EditButton';
import DeleteButton from '../../../../../components/common/buttons/DeleteButton';
import { PredicateAll } from '@aws-amplify/datastore/lib-esm/predicates';
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import { Auth } from 'aws-amplify';
import ImportDimension from '../../../../../components/common/import/dimensionsimport.component';
import { ProgressSpinner } from 'primereact/progressspinner';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';

export default function ProductGroups() {

    const [isAuthenticated, userHasAuthenticated] = useState(true);

    // //State
    const [productGroupList, setProductGroupList] = useState([]);
    const [productGroupFilterList, setProductGroupFilterList] = useState([]);
    const [isShowImportPopup, setIsShowImportPopup] = useState(false);
    const [isPageLoaded, setPageLoaded] = useState(false);
    const [brands,setBrands] = useState([]);
    const [isDataLoaded, setIsDataLoaded]=useState(false);

    let navigateTo = useNavigate();

    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/hr/settings/createproductgroups/' + id);
    };

    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        // const objDeleteProductGroup = await DataStore.query(ProductGroup, id);
        const objDeleteProductGroup = await graphQLFindRecordById('getProductGroup', id);

        alertify.confirm('Confirmation', 'Do you want to delete this record?', async function () {
            //Delete language
            // DataStore.delete(objDeleteProductGroup);
            await API.graphql({
                query: mutations.deleteProductGroup,
                variables: {
                    input: {
                        id: objDeleteProductGroup.id,
                        _version: objDeleteProductGroup._version,
                    }
                }
            });

            BindList();
            toast.success("Deleted");
            navigateTo('/hr/setting/productgroups');
        }
            , function () { });

    };

    // //*Column Name
    const DataTableColumns = [
        {
            name: 'Product Group',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Ref Code',
            selector: row => row.REF_CODE,
            sortable: true,
        },
        {
            name: 'Brand',
            selector: row => row.brand,
            sortable: true,
        },

        {
            name: 'Action',
            cell: (row) => (
                <>
                    <div className="flex gap-8">

                        <Link onClick={(e) => handleEditClick(e, row.id)} ><EditButton /></Link>
                        <Link onClick={(e) => handleDeleteClick(e, row.id)} ><DeleteButton /></Link>
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true
        }
    ];

    //Search Product Group
    const searchProductGroup = (searchedText) => {
        if (searchedText.length > 1) {
            let data = [...productGroupFilterList]
            let filteredData = data.filter(e =>
                e.name.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.REF_CODE.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.brand.toLowerCase().includes(searchedText.toLowerCase())
            )
            setProductGroupList(filteredData)
        } else {
            let data = [...productGroupFilterList]
            setProductGroupList(data)
        }
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //onClick Close popup
    async function onClickCloseImportPopup() {
        setIsShowImportPopup(false);
        BindList();
    }

    const getMasterData = async ()=>{
        setIsDataLoaded(false);
        var BrandResponses = await graphQLGetAllData('listBrands')
        //BrandResponses = BrandResponses.sort((a,b)=>a.name.localeCompare(b.name));
        setBrands(BrandResponses);
        console.log('brands',brands)
        setIsDataLoaded(true); 
    }

    async function BindList() {

        let finalData = []
        // var ProductResponse = await DataStore.query(ProductGroup, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var ProductResponse =  await graphQLGetAllData('listProductGroups');
        ProductResponse = ProductResponse.sort((a,b)=>a.name.localeCompare(b.name));

        for (let i = 0; i < ProductResponse.length; i++) {
            if(i===0){ console.log('started')};
            let brand = "";
            if (ProductResponse[i].brand_id) {
                // var BrandResponses = await DataStore.query(Brand, (c) => c.id.eq(ProductResponse[i].brand_id));
                let BrandResponses = brands.find(b=>b.id === ProductResponse[i].brand_id );

                if (BrandResponses) {
                    brand = BrandResponses.name + " (" + BrandResponses.REF_CODE + ")"
                }  
            } else { brand = ""; }
            let data = {
                "id": ProductResponse[i].id,
                "name": ProductResponse[i].name,
                "REF_CODE": ProductResponse[i].REF_CODE,
                "brand": brand
            }

            finalData.push(data)

            if (i === ProductResponse.length - 1) {
               { console.log('ended')};

                setProductGroupList(finalData)
                setProductGroupFilterList(finalData)
                setPageLoaded(true)
            }
        }
    }

    useEffect(() => {
        onLoad();
        getMasterData()
        BindList();
    }, []);

    useEffect(()=>{

        if( isDataLoaded === true){
            BindList();
        }
    },[isDataLoaded])
   

    return (
        isAuthenticated ? (

            <div>
                <div className="pt-16">
                    <TopNavBar />
                    <LeftSideBar />
                    <div className="dashboard-main-wrapper pl-20 pr-4 mt-3">
                        <div className="flex flex-1 flex-wrap p-5 pb-4 px-0 top-action-wrapper">
                            <div className="md:flex xs:w-full sm:w-full md:w-auto flex-row align-center">
                                <div className="md:flex block md:col-span-2 mb-4">
                                </div>
                                <div className="md:flex block mb-4">
                                    <h2 className="page-title">Product Groups</h2>
                                </div>
                            </div>

                            <div className="md:flex flex-1 flex-row xs:w-full sm:w-full md:w-auto align-center justify-end md:ml-auto gap-3 right-side">
                                <div className="relative">
                                    <input onChange={(e) => searchProductGroup(e.target.value)} type="text" id="required-search" className="rounded-md border border-[#D0D5DD] focus:border-transparent w-full py-2.5 pl-3 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#403E3C] dark:text-[#FFFFFF] dark:border-[#403E3C]" placeholder="Search..." />
                                </div>
                                <div className="md:flex block mb-4 gap-4">
                                    <Link to="/hr/settings" className="flex py-2 px-5 bg-[#FFF] hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-black transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] dark:border-[#4A5056] dark:bg-[#4A5056] dark:text-white">
                                        Back
                                    </Link>
                                    <Link onClick={() => {
                                        setIsShowImportPopup(true);
                                    }} className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] bg-black">
                                        Import
                                    </Link>
                                    <Link to="/hr/settings/createproductgroups" className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] bg-black">
                                        Create
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div>
                            <div className={"block w-full pt-5"}>
                                {
                                    isPageLoaded && isDataLoaded &&
                                    <DataTable
                                        columns={DataTableColumns}
                                        data={productGroupList}
                                        customStyles={DataTableCustomStyles}
                                        pagination
                                        highlightOnHover
                                        paginationPerPage={10}
                                    />
                                }
                            </div>
                            {
                                !isPageLoaded &&
                                <div className='text-center py-10' >
                                    <ProgressSpinner style={{ width: '80px', height: '80px', zIndex: '9999' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".8s" />
                                </div>
                            }
                        </div>
                    </div>
                    {
                        isShowImportPopup &&
                        <ImportDimension
                            closePopup={onClickCloseImportPopup}
                            type={"PG"}
                        />
                    }
                </div>
            </div>

        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}