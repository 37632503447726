import { DataStore } from "aws-amplify";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import {
    TargetDetails, Targets,
    Plan, Parameters, Relationship, RelationshipDetails, InitiateEvaluation, Employee, CompanyActuals
} from "../../models";
import { getTargetBasedOnParameterType, getTargetBasedOnParameterTypeForEvalPopup } from '../common/getTargetBasedOnParameterType';
import { getActualValue } from '../../pages/employee/evaluation/getActualValue';
import { calculateConsolidatedScore, calculateWeightageAchievement, checkIfNegativeValue } from "../helper/dashboardCalCulations"
import { commaSeparated } from '../helper/maths';
import { PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES, PARAMETER_IDs } from '../helper/enum'
import { graphQLGetAllData, graphQLFindRecordById } from "./graphQLFunctions";

//*Planning Filter
const calculateIndividualScore = async (financialYearId, employeeId, quarterValue, reportManagerEmployeeId) => {
    let quarterNo;
    let finalValue;
    if (quarterValue === "1") { quarterNo = "Quarter 1" }
    else if (quarterValue === "2") { quarterNo = "Quarter 2" }
    else if (quarterValue === "3") { quarterNo = "Quarter 3" }
    else if (quarterValue === "4") { quarterNo = "Quarter 4" }

    //get parameters
    // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
    //     c.employee_id.eq(employeeId),
    //     c.financial_year_id.eq(financialYearId),
    //     // c.report_manager_employee_id.eq(reportManagerEmployeeId)
    // ]));

    var targetsResponses = await graphQLGetAllData('listTargets', [
        { employee_id: { eq: employeeId } },
        { financial_year_id: { eq: financialYearId } }
    ]);

    if (targetsResponses.length > 0) {

        //get parameters weightage
        // var relationshipResponse = await DataStore.query(Relationship, (c) => c.and(c => [
        //     c.role_id.eq(targetsResponses[0].role_id),
        //     c.financial_year_id.eq(financialYearId)
        // ]));

        var relationshipResponse = await graphQLGetAllData('listRelationships', [
            { role_id: { eq: targetsResponses[0].role_id } },
            { financial_year_id: { eq: financialYearId } }
        ]);

        var relationshipDetailResponses = []
        if (relationshipResponse.length > 0) {
            // relationshipDetailResponses = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationshipResponse[0].id));
            relationshipDetailResponses = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: relationshipResponse[0].id } }]);


        }

        var _tempArray = [];

        // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
        //     c.target_id.eq(targetsResponses[0].id),
        //     c.parameter_id.notContains(PARAMETER_IDs.DIO),
        //     c.parameter_id.notContains(PARAMETER_IDs.DPO),
        //     c.parameter_id.notContains(PARAMETER_IDs.DVRO),
        //     c.parameter_id.notContains(PARAMETER_IDs.DSO),
        // ]));

        var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
            { target_id: { eq: targetsResponses[0].id } },
            { parameter_id: { notContains: PARAMETER_IDs.DIO } },
            { parameter_id: { notContains: PARAMETER_IDs.DPO } },
            { parameter_id: { notContains: PARAMETER_IDs.DVRO } },
            { parameter_id: { notContains: PARAMETER_IDs.DSO } }
        ]);


        for (let i = 0; i < currentTargetDetails.length; i++) {
            let status = ""
            if (currentTargetDetails[i].is_accepted_by_employee && !currentTargetDetails[i].is_approve) { status = "Awaiting approval" }
            else if (currentTargetDetails[i].isSendForRevisionByReportingManage) { status = "Revision" }
            else if (currentTargetDetails[i].is_approve) { status = "Approved" }
            else if (currentTargetDetails[i].is_revision_by_employee) { status = "Requested for Revision" }
            else { status = "Open" }

            //get parameters details
            // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(currentTargetDetails[i].parameter_id));

            if (currentTargetDetails[i].parameter_id === "" || currentTargetDetails[i].parameter_id === undefined) {
                continue;
            }

            var parameterResponses = await graphQLGetAllData('listParameters', [{ id: { eq: currentTargetDetails[i].parameter_id } }]);


            var getWeightage = relationshipDetailResponses.filter((e) => e.parameter_id === currentTargetDetails[i].parameter_id)

            var weightageForWtAch = getWeightage.length > 0 ? getWeightage[0].weightage : 1;

            var parameterBaseType = parameterResponses[0].type === PARAMETERS_TYPES.Days ? PARAMETERS_TYPES_NAMES.D : parameterResponses[0].type === PARAMETERS_TYPES.Amount ? PARAMETERS_TYPES_NAMES.A : parameterResponses[0].type === PARAMETERS_TYPES.Number ? PARAMETERS_TYPES_NAMES.N : PARAMETERS_TYPES_NAMES.P

            var actualPerformance = 0; var actualAch = 0; var wtAch = 0;

            var planActual = getActualValue(currentTargetDetails[i], quarterNo, parameterBaseType)

            if (planActual) { actualPerformance = parseFloat(planActual).toFixed(2) } else { actualPerformance = 0 }

            // var getTargetOnParameterType = getTotalTarget(currentItem, quarterValue, parameterBaseType)
            // var getPlanDetails = await DataStore.query(Plan, (c) => c.target_detail_id.eq(currentTargetDetails[i].id));
            var getPlanDetails = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: currentTargetDetails[i].id } }]);

            // var getTargetOnParameterType = getTargetBasedOnParameterType(getPlanDetails, quarterNo, parameterBaseType)
            var getTargetOnParameterType = getTargetBasedOnParameterTypeForEvalPopup(getPlanDetails, quarterNo, parameterBaseType, currentTargetDetails[i])

            actualAch = await calculateConsolidatedScore(planActual, getTargetOnParameterType)
            wtAch = await calculateWeightageAchievement(weightageForWtAch, actualAch)

            let originalWtAch = wtAch
            //Check if Negative
            actualAch = await checkIfNegativeValue(actualAch)
            wtAch = await checkIfNegativeValue(wtAch)


            if (parameterResponses[0]) {

                let targetValue = parameterResponses[0]?.type === "A" || parameterResponses[0]?.type === "D" ? getTargetOnParameterType : parseFloat(getTargetOnParameterType).toFixed(2);
                let actualValue = actualPerformance !== undefined ? parameterResponses[0]?.type === "A" ? (actualPerformance) : actualPerformance : 0;
                actualValue = actualValue.toString().replace(/[\(\)]/g, "")

                let actualPercentage = 0;

                if (currentTargetDetails[i].parameter_id === PARAMETER_IDs["NWC DAYS"]) {
                    actualPercentage = parseInt(targetValue) ? (100 * ((parseInt(targetValue) * 2) - parseInt(actualValue))) / parseInt(targetValue) : 0;
                }
                else if (currentTargetDetails[i].parameter_id === PARAMETER_IDs['Gross Margin %'] || currentTargetDetails[i].parameter_id === PARAMETER_IDs['Gross Margin $']) {
                    actualPercentage = targetValue ? (100 * parseInt(actualValue) * -1) / parseInt(targetValue) : 0;
                }
                else {
                    actualPercentage = targetValue ? (100 * parseInt(actualValue)) / parseInt(targetValue) : 0;
                }

                if (actualPercentage > 120) {
                    actualPercentage = 120;
                } else if (actualPercentage < 50) {
                    actualPercentage = 0;
                }

                let weightPercentage = (weightageForWtAch * actualPercentage) / 100;

                if (!weightPercentage) {
                    weightPercentage = 0;
                }
                // if (getWeightage.length > 0) {

                if (parameterResponses.length > 0) {
                    let data = {

                        "target_detail_id": currentTargetDetails[i].id,
                        "parameter_id": currentTargetDetails[i].parameter_id,
                        "parameter_name": parameterResponses[0].name,
                        "parameter_type": parameterResponses[0].type === "A" ? "$" : parameterResponses[0].type === "D" ? "Days" : parameterResponses[0].type === "P" ? "%" : parameterResponses[0].type === "N" ? "Number" : "",
                        "target_value": targetValue,
                        "actualPerformance": parseFloat(actualValue).toFixed(2),
                        "actualPerformanceValue": actualValue,
                        "actualAch": actualAch ? actualAch + "%" : "0%",
                        "wtAch": isFinite(wtAch) ? wtAch + "%" : "0%",
                        "isMarkAsComplete": currentTargetDetails[i].isMarkAsComplete,
                        "is_accepted_by_employee": currentTargetDetails[i].is_accepted_by_employee,
                        "is_approve": currentTargetDetails[i].is_approve,
                        "is_revision_by_employee": currentTargetDetails[i].is_revision_by_employee,
                        "isSendForRevisionByReportingManage": currentTargetDetails[i].isSendForRevisionByReportingManage,
                        "parameter_weightage": weightageForWtAch ? weightageForWtAch : 0,
                        "isPlanMandatory": getWeightage.length > 0 ? 1 : 0,
                        "status": status,
                        "q1_target_value": currentTargetDetails[i].q1_target_value,
                        "q2_target_value": currentTargetDetails[i].q2_target_value,
                        "q3_target_value": currentTargetDetails[i].q3_target_value,
                        "q4_target_value": currentTargetDetails[i].q4_target_value,
                        "q1_actual_value": getActualValue(currentTargetDetails[i], "Quarter 1", parameterBaseType),
                        "q2_actual_value": getActualValue(currentTargetDetails[i], "Quarter 2", parameterBaseType),
                        "q3_actual_value": getActualValue(currentTargetDetails[i], "Quarter 3", parameterBaseType),
                        "q4_actual_value": getActualValue(currentTargetDetails[i], "Quarter 4", parameterBaseType),
                        "totalWeightage": actualAch ? parseFloat(actualAch).toFixed(2) : 0,
                        "originalWtAch": originalWtAch,
                        "weightPercentage": parseFloat(weightPercentage).toFixed(2) + '%',
                        "actualPercentage": parseFloat(actualPercentage).toFixed(2) + '%',
                        // "financial_year_id": financialYearId,
                    }

                    _tempArray.push(data)
                }
                // }

            }

            if (i === currentTargetDetails.length - 1) {
                let sortArray = _tempArray.sort((a, b) => (a.parameter_name > b.parameter_name ? 1 : -1))
                // let filteredArray = sortArray.filter(e => e.parameter_id === PARAMETER_IDs.Revenue || e.parameter_id === PARAMETER_IDs['NWC DAYS'] || e.parameter_id === PARAMETER_IDs['Gross Margin %']);

                let dollerValue = sortArray.find(o => o.parameter_id === PARAMETER_IDs["Gross Margin $"])
                let revenueValue = sortArray.find(o => o.parameter_id === PARAMETER_IDs.Revenue)
                let gmPercentageValue = revenueValue.actualPerformanceValue ? (parseInt(dollerValue ? dollerValue?.actualPerformanceValue :0) / parseInt(revenueValue.actualPerformanceValue)) * 100 : 0;
                let filteredData = sortArray.filter(e => e.isPlanMandatory)

                let objIndex = filteredData.findIndex((obj => obj.parameter_id == PARAMETER_IDs["Gross Margin %"]));

                if (objIndex >= 0) {
                    filteredData[objIndex].actualPerformance = gmPercentageValue.toFixed(2) * -1;
                    // filteredData[objIndex].actualPercentage = showPlanActual_valuePercentage(filteredData[objIndex]);
                    // filteredData[objIndex].weightPercentage = showAchiievementWeightPercentage(filteredData[objIndex]);
                    filteredData[objIndex].q1_actual_value = filteredData[objIndex].q1_actual_value * -1;
                    filteredData[objIndex].q2_actual_value = filteredData[objIndex].q2_actual_value * -1;
                    filteredData[objIndex].q3_actual_value = filteredData[objIndex].q3_actual_value * -1;
                    filteredData[objIndex].q4_actual_value = filteredData[objIndex].q4_actual_value * -1;
                }

                let objIndexforGM$ = filteredData.findIndex((obj => obj.parameter_id == PARAMETER_IDs["Gross Margin $"]));

                if (objIndexforGM$ >= 0) {
                    filteredData[objIndexforGM$].actualPerformance = filteredData[objIndexforGM$].actualPerformance * -1;
                    filteredData[objIndexforGM$].actualPerformanceValue = filteredData[objIndexforGM$].actualPerformanceValue * -1;
                    // filteredData[objIndexforGM$].actualPercentage = showPlanActual_valuePercentage(filteredData[objIndexforGM$]);
                    // filteredData[objIndexforGM$].weightPercentage = showAchiievementWeightPercentage(filteredData[objIndexforGM$]);
                    filteredData[objIndexforGM$].q1_actual_value = filteredData[objIndexforGM$].q1_actual_value * -1;
                    filteredData[objIndexforGM$].q2_actual_value = filteredData[objIndexforGM$].q2_actual_value * -1;
                    filteredData[objIndexforGM$].q3_actual_value = filteredData[objIndexforGM$].q3_actual_value * -1;
                    filteredData[objIndexforGM$].q4_actual_value = filteredData[objIndexforGM$].q4_actual_value * -1;
                }

                filteredData = filteredData.map((item) => ({
                    ...item,
                    weightPercentage: showAchiievementWeightPercentage(item),
                    actualPercentage: showPlanActual_valuePercentage(item),
                }))

                let totalConsolidatedScore = filteredData.reduce((acc, book) => acc + parseFloat(book.weightPercentage), 0); // originalWtAch replaced with weightPercentage
                totalConsolidatedScore = !isNaN(parseFloat(totalConsolidatedScore).toFixed(2)) ? parseFloat(totalConsolidatedScore).toFixed(2) : 0

                let totalConsolidatedValue = filteredData.reduce((acc, book) => acc + book.parameter_weightage, 0);

                totalConsolidatedValue = !isNaN(parseFloat(totalConsolidatedValue).toFixed(2)) ? parseFloat(totalConsolidatedValue).toFixed(2) : 0

                let finalValue = { "totalConsolidatedValue": totalConsolidatedValue, "totalConsolidatedScore": totalConsolidatedScore }
                return finalValue
            }
        }

    } else {
        finalValue = { "totalConsolidatedValue": 0, "totalConsolidatedScore": 0 }
    }

    if (finalValue === undefined) {
        return { "totalConsolidatedValue": 0, "totalConsolidatedScore": 0 };
    } else {
        return finalValue;
    }

}


const consolidatedScoreFormatter = (rowData) => {
    let actualPerformance = rowData.actualPerformance.toString().replace(/[\(\)]/g, "");
    let targetValue = rowData.target_value;

    let achievementPercentatage = 0;
    if (rowData.parameter_id === PARAMETER_IDs["NWC DAYS"]) {
        achievementPercentatage = parseInt(targetValue) ? (100 * ((parseInt(targetValue) * 2) - parseInt(actualPerformance))) / parseInt(targetValue) : 0;
    }
    else {
        achievementPercentatage = targetValue ? (100 * parseInt(actualPerformance)) / parseInt(targetValue) : 0;
    }

    if (achievementPercentatage > 120) {
        achievementPercentatage = 120;
    } else if (achievementPercentatage < 50) {
        achievementPercentatage = 0;
    }

    let weightPercentage = (rowData.parameter_weightage * achievementPercentatage) / 100;

    if (!weightPercentage) {
        weightPercentage = 0;
    }
    //parseFloat(NWCDaysValue).toFixed(2)
    return parseFloat(weightPercentage).toFixed(2) + '%';
}

const showPlanActual_valuePercentage = (rowData) => {

    let actualPerformance = rowData.actualPerformance.toString().replace(/[\(\)]/g, "");
    let targetValue = rowData.target_value;

    let achievementPercentatage = 0;

    if (rowData.parameter_id === PARAMETER_IDs["NWC DAYS"]) {
        achievementPercentatage = parseFloat(targetValue) ? (100 * ((parseFloat(targetValue) * 2) - parseFloat(actualPerformance))) / parseFloat(targetValue) : 0;
    }
    else {
        achievementPercentatage = targetValue ? (100 * parseFloat(actualPerformance)) / parseFloat(targetValue) : 0;
    }

    if (rowData.parameter_id === PARAMETER_IDs["Overdue Receivable"]) {
        if (achievementPercentatage <= 0) {
            achievementPercentatage = 120
        } else if (achievementPercentatage <= 5) {
            achievementPercentatage = 100
        } else if (achievementPercentatage > 5 && achievementPercentatage < 10) {
            achievementPercentatage = 80
        } else {
            achievementPercentatage = 0
        }

    }
    else {
        if (achievementPercentatage > 200) {
            achievementPercentatage = 200;
        } else if (achievementPercentatage < 50) {
            achievementPercentatage = 0;
        }
    }


    if (!achievementPercentatage) {
        achievementPercentatage = 0;
    }

    return parseFloat(achievementPercentatage).toFixed(2) + '%';
};

const showAchiievementWeightPercentage = (rowData) => {
    let actualPerformance = rowData.actualPerformance.toString().replace(/[\(\)]/g, "");
    let targetValue = rowData.target_value;

    let achievementPercentatage = 0;
    if (rowData.parameter_id === PARAMETER_IDs["NWC DAYS"]) {
        achievementPercentatage = parseFloat(targetValue) ? (100 * ((parseFloat(targetValue) * 2) - parseFloat(actualPerformance))) / parseFloat(targetValue) : 0;
    }
    else {
        achievementPercentatage = targetValue ? (100 * parseFloat(actualPerformance)) / parseFloat(targetValue) : 0;
    }

    if (achievementPercentatage > 200) {
        achievementPercentatage = 200;
    } else if (achievementPercentatage < 50) {
        achievementPercentatage = 0;
    }

    let weightPercentage = (rowData.parameter_weightage * achievementPercentatage) / 100;

    if (!weightPercentage) {
        weightPercentage = 0;
    }
    //parseFloat(NWCDaysValue).toFixed(2)
    return parseFloat(weightPercentage).toFixed(2) + '%'

}

const calculateEligibleEmployeeOfQtr = async (Array) => {

    let employeeCount = 0;
    for (let i = 0; i < Array.length; i++) {
        let initiateEvaluationResponses = await API.graphql(graphqlOperation(queries.listInitiateEvaluations, { filter: { employee_id: { eq: Array[0].id } }, limit: 10000 }));
        var existingInitiateEvaluationResponses = initiateEvaluationResponses.data.listInitiateEvaluations.items;
        if (existingInitiateEvaluationResponses.length > 0) {
            employeeCount = employeeCount + 1
        }
        if (i === Array.length - 1) {
            return employeeCount
        }
    }
}

const calculateTotalSBUActual = async (Array, financialYearId, quarterNo) => {
    var totalSBUActuals = []; var totalSBUCappedActuals = []; var totalCompanyPerformance = []; var totalSbuPerformanceValue = [];
    var equalHundred = 0; var ninetyToHundred = 0; var eightyToNinety = 0; var fiftyToEighty = 0; var belowFifty = 0;
    var aboveTwoHundred = 0; var aboveOneFiftyToTwoHundred = 0; var aboveOneHundredToOneFifty = 0;
    for (let i = 0; i < Array.length; i++) {
        let employeeResponse = await API.graphql(graphqlOperation(queries.listEmployees, { filter: { id: { eq: Array[i].id } }, }));
        var existingEmployeeResponse = employeeResponse.data.listEmployees.items;
        const individualScore = await calculateIndividualScore(financialYearId, Array[i].id, quarterNo, existingEmployeeResponse[0].parentEmployeeId);
        totalSBUActuals.push(Number(individualScore.totalConsolidatedValue));
        totalSBUCappedActuals.push(Number(individualScore.totalConsolidatedScore))

        let companyActualsResponses = await API.graphql(graphqlOperation(queries.listCompanyActuals, {
            filter: {
                and: [
                    { financial_year_id: { eq: financialYearId } },
                    { quarterNo: { eq: quarterNo } },
                ],
            },
        }));
        var existingCompanyActualsResponses = companyActualsResponses.data.listCompanyActuals.items
        // var companyActualsResponses = await DataStore.query(CompanyActuals, (c) => c.and(c => [
        //     c.financial_year_id.eq(financialYearId),
        //     c.quarterNo.eq(Number(quarterNo))
        // ]));

        let totalValue = parseInt(individualScore.totalConsolidatedScore) + parseInt(existingCompanyActualsResponses[0].companyPerformance) + parseInt(existingCompanyActualsResponses[0].sbuPerformanceValue)
        if (totalValue === 100) { equalHundred = equalHundred + 1 }
        else if (totalValue < 100 && totalValue > 90) { ninetyToHundred = ninetyToHundred + 1 }
        else if (totalValue < 90 && totalValue > 80) { eightyToNinety = eightyToNinety + 1 }
        else if (totalValue < 80 && totalValue > 50) { fiftyToEighty = fiftyToEighty + 1; }
        else if (totalValue <= 50) { belowFifty = belowFifty + 1 }
        else if (totalValue > 200) { aboveTwoHundred = aboveTwoHundred + 1 }
        else if (totalValue < 200 && totalValue > 150) { aboveOneFiftyToTwoHundred = aboveOneFiftyToTwoHundred + 1 }
        else if (totalValue < 150 && totalValue > 100) { aboveOneHundredToOneFifty = aboveOneHundredToOneFifty + 1 }

        if (existingCompanyActualsResponses.length) { totalCompanyPerformance.push(existingCompanyActualsResponses[0].companyPerformance); totalSbuPerformanceValue.push(existingCompanyActualsResponses[0].sbuPerformanceValue); }

        if (i === Array.length - 1) {
            let totalSBUActualsValue = totalSBUActuals.reduce((a, b) => a + b, 0)
            let totalSBUCappedActualsValue = totalSBUCappedActuals.reduce((a, b) => a + b, 0)
            let totalCompanyPerformanceValue = totalCompanyPerformance.reduce((acc, val) => acc + parseInt(val), 0);
            let totalSbuPerformanceValueArray = totalSbuPerformanceValue.reduce((acc, val) => acc + parseInt(val), 0);
            let totalAvgScore = totalSBUCappedActualsValue + totalCompanyPerformanceValue + totalSbuPerformanceValueArray
            // let totalAvgSBUActualsValue = (totalSBUActualsValue / totalSBUActuals.length) * 100
            let totalAvgSBUActualsValue = (totalSBUActualsValue / totalSBUActuals.length)
            // let totalAvgSBUCappedActualsValue = totalSBUCappedActualsValue / totalSBUCappedActuals.length * 100
            let totalAvgSBUCappedActualsValue = totalSBUCappedActualsValue / totalSBUCappedActuals.length
            // let totalAvgScoreValue = totalAvgScore / 3 * 100
            let totalAvgScoreValue = totalAvgScore / 3
            return {
                "totalSBUActualsValue": totalAvgSBUActualsValue.toFixed(2), "totalSBUCappedActualsValue": totalAvgSBUCappedActualsValue.toFixed(2), "totalAvgScore": totalAvgScoreValue.toFixed(2),
                "equalHundred": equalHundred, "ninetyToHundred": ninetyToHundred, "eightyToNinety": eightyToNinety, "fiftyToEighty": fiftyToEighty,
                "belowFifty": belowFifty, "aboveTwoHundred": aboveTwoHundred, "aboveOneFiftyToTwoHundred": aboveOneFiftyToTwoHundred, "aboveOneHundredToOneFifty": aboveOneHundredToOneFifty,
            }
        }
    }
}

export { calculateIndividualScore, calculateEligibleEmployeeOfQtr, calculateTotalSBUActual }
