import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { DataStore, SortDirection } from 'aws-amplify';
import { Notifications } from "../../models";
import moment from "moment";
import LeftNav from '../../components/common/employee/leftnav.component';
import TopNav from '../../components/common/employee/topnav.component';

export default function EmployeeViewNotification() {
    var myNavigation = useNavigate();

    //Notifications
    const [notificationList, setNotificationList] = useState([]);

    async function BindList() {
        //get Notifications
        var currentEmpId = reactLocalStorage.get('loggedEmployeeId');
        var notificationsResponse = await DataStore.query(Notifications, (c) => c.and(c => [
            c.receiverId.eq(currentEmpId),
        ]), {
            sort: s => s.noticationDateTime(SortDirection.DESCENDING)
        });
        setNotificationList(notificationsResponse);
    }
    useEffect(() => {
        BindList();
    }, []);

    return (
        <div className="pt-16">
            <TopNav />
            <LeftNav />
            <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="sm:flex items-center space-x-8">
                        <div className="text-2xl font-semibold dark:text-white">Notifications</div>
                    </div>
                    <div className="notification-details mt-5">
                        {
                            notificationList.map((item, index) => {
                                return (
                                    <div className="w-full text-sm font-semibold text-gray-900 notBtn md:mt-0 hover:text-gray-900 focus:text-gray-900 focus:outline-none" >
                                        <div className="w-full shadow-md rounded-lg border-[#E4E7EC] h-20 px-5 bg-white mb-2">

                                            <div className='flex items-center gap-3 py-3 notification-block'>
                                                <i className='icon bell_icon '></i>
                                                <div>
                                                    <h6 className='text-[#101828] text-[14px] font-medium dark:text-white'>{item.notification}</h6>
                                                    <p className='text-[#98A2B3] text-[12px] font-normal'>{moment(item.noticationDateTime).locale('it').fromNow()}</p>
                                                </div>
                                                {/* <i className='icon close_icon_notification'></i> */}
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>


        </div>
    )

}