import AWS from 'aws-sdk'


async function GetAWSObjectURL(filePath) {

    const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
        signatureVersion: 'v4',
        region: process.env.REACT_APP_AWS_Region,
        secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey
    });

    const signedUrlExpireSeconds = 60 * 10000
    const url = s3.getSignedUrl('getObject', {
        Bucket: "redingtoneval134121-staging",
        //Bucket: "process.env.REACT_APP_AWS_Bucket",
        Key: filePath,
        Expires: signedUrlExpireSeconds
    })
    return url;

};

async function UploadFileOnS3(objFile, fileSavePath) {

    let isFileUploaded = false;
    const myS3UploadBucket = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
        signatureVersion: 'v4',
        region: process.env.REACT_APP_AWS_Region,
        secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey,
        params: { Bucket: "redingtoneval134121-staging" }
        // params: { //Bucket: "process.env.REACT_APP_AWS_Bucket",}
    });
    const params = { ACL: 'public-read', Body: objFile, Key: fileSavePath };
    myS3UploadBucket.putObject(params)
        .send(async (err) => {
            if (err) console.log(err);
            else { isFileUploaded = true }
        })

    return isFileUploaded;

};

async function deleteFileFromS3(filePath) {

    const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
        signatureVersion: 'v4',
        region: process.env.REACT_APP_AWS_Region,
        secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey
    });

    var params = {
        Bucket: "redingtoneval134121-staging",
        Key: filePath
    };

    const url = s3.deleteObject(params, function (err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else console.log(data);           // successful response
    });
    return url;
};

export { GetAWSObjectURL, UploadFileOnS3,deleteFileFromS3 };
