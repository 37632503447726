// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TeamType = {
  "B": "B",
  "S": "S"
};

const { NWCDaysUploadLogs, SubGTM, ActualDetails, GTM, ChangeRequestFiles, Materials, CustomerSegment, InitiateEvaluation, InitiatePlanning, EmployeeParameters, CompanyActuals, Notifications, FinanceActuals, Division, PlantStorageLocation, ProfitCenter, Company, ChangeRequestComment, ChangeRequest, Verticals, TargetMessage, Plan, HRAssignTargetDetails, HRAssigTargets, BIUnit, FinancialParameterDetails, Evaluation, TargetDetails, Targets, Planning, Employee, RelationshipDetails, Relationship, Customer, CustomerCategory, Country, Region, ProductGroup, Brand, SBU, Roles, FinancialParameters, Parameters, FinancialYear } = initSchema(schema);

export {
  NWCDaysUploadLogs,
  SubGTM,
  ActualDetails,
  GTM,
  ChangeRequestFiles,
  Materials,
  CustomerSegment,
  InitiateEvaluation,
  InitiatePlanning,
  EmployeeParameters,
  CompanyActuals,
  Notifications,
  FinanceActuals,
  Division,
  PlantStorageLocation,
  ProfitCenter,
  Company,
  ChangeRequestComment,
  ChangeRequest,
  Verticals,
  TargetMessage,
  Plan,
  HRAssignTargetDetails,
  HRAssigTargets,
  BIUnit,
  FinancialParameterDetails,
  Evaluation,
  TargetDetails,
  Targets,
  Planning,
  Employee,
  RelationshipDetails,
  Relationship,
  Customer,
  CustomerCategory,
  Country,
  Region,
  ProductGroup,
  Brand,
  SBU,
  Roles,
  FinancialParameters,
  Parameters,
  FinancialYear,
  TeamType
};