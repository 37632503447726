import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import arrowRightDot from '../../assets/images/arrow-right-dot.png';
import blubImage from '../../assets/images/blub-img.png';
import evaluationImage from '../../assets/images/evaluation-img.png';
import exportImage from '../../assets/images/explore-img.png';
import logoLanding from '../../assets/images/logo-landing.png';
import planningImage from '../../assets/images/planing-img.png';
import reportImage from '../../assets/images/report-img.png';
import { ValidateEmployeeRole } from "../../components/helper/validateRole";
import EmpLogin from "../../setup/auth/emp.component";
import { reactLocalStorage } from 'reactjs-localstorage';
import { Employee, FinancialYear, Parameters, Plan, Planning, Relationship, RelationshipDetails, TargetDetails, Targets } from "../../models";
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES, PARAMETER_IDs } from "../../components/helper/enum";
import { getActualValue } from "./evaluation/getActualValue";
import { calculateConsolidatedScore, calculateWeightageAchievement, checkIfNegativeValue } from "../../components/helper/dashboardCalCulations";
import { commaSeparated } from "../../components/helper/maths";
import { getTargetBasedOnParameterType, getTargetBasedOnParameterTypeForEvalPopup } from "../../components/common/getTargetBasedOnParameterType";
import { checkEmployeeTeamType } from "./helper";
import { graphQLGetAllData, graphQLFindRecordById } from '../../components/helper/graphQLFunctions';

export default function EmpLanding() {
  const [isAuthenticated, userHasAuthenticated] = useState(true);

  let isReportingManager = reactLocalStorage.get('isReportingManager')
  let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
  let loggedEmployeeReportingManagerId = reactLocalStorage.get('loggedEmployeeReportingManagerId')

  async function onLoad() {
    try {
      if (!ValidateEmployeeRole()) {
        userHasAuthenticated(false);
      }
    } catch (e) {
      userHasAuthenticated(false);
    }
  }

  //Set values
  const [annualTarget, setAnnualTarget] = useState(0);
  const [annualTargetActual, setAnnualTargetActual] = useState(0);
  const [consolidatedScore, setConsolidatedScore] = useState(0);
  const [performance, setPerformance] = useState("-");

  async function getLoggedEmployeeTargets() {
    let selectedYear = reactLocalStorage.getObject('selectedYear');
    if (Object.keys(selectedYear).length !== 0) {
      getTargets(selectedYear)
    }
    else {
      // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
      var responses = await graphQLGetAllData("listFinancialYears", [{ fromDate: { contains: moment().format("YYYY") } }])

      getTargets({ label: responses[0]?.name, value: responses[0]?.id })
    }

  }


  const showPlanActual_valuePercentage = (rowData) => {

    let actualPerformance = rowData.actualPerformance.toString().replace(/[\(\)]/g, "");
    let targetValue = rowData.target_value;

    let achievementPercentatage = 0;

    if (rowData.parameter_id === PARAMETER_IDs["NWC DAYS"]) {
      achievementPercentatage = parseFloat(targetValue) ? (100 * ((parseFloat(targetValue) * 2) - parseFloat(actualPerformance))) / parseFloat(targetValue) : 0;
    }
    else {
      achievementPercentatage = targetValue ? (100 * parseFloat(actualPerformance)) / parseFloat(targetValue) : 0;
    }

    if (rowData.parameter_id === PARAMETER_IDs["Overdue Receivable"]) {
      if (achievementPercentatage <= 0) {
        achievementPercentatage = 120
      } else if (achievementPercentatage <= 5) {
        achievementPercentatage = 100
      } else if (achievementPercentatage > 5 && achievementPercentatage < 10) {
        achievementPercentatage = 80
      } else {
        achievementPercentatage = 0
      }

    }
    else {
      if (achievementPercentatage > 200) {
        achievementPercentatage = 200;
      } else if (achievementPercentatage < 50) {
        achievementPercentatage = 0;
      }
    }


    if (!achievementPercentatage) {
      achievementPercentatage = 0;
    }

    return parseFloat(achievementPercentatage).toFixed(2) + '%';
  };

  const showAchiievementWeightPercentage = (rowData) => {
    let actualPerformance = rowData.actualPerformance.toString().replace(/[\(\)]/g, "");
    let targetValue = rowData.target_value;

    let achievementPercentatage = 0;
    if (rowData.parameter_id === PARAMETER_IDs["NWC DAYS"]) {
      achievementPercentatage = parseFloat(targetValue) ? (100 * ((parseFloat(targetValue) * 2) - parseFloat(actualPerformance))) / parseFloat(targetValue) : 0;
    }
    else {
      achievementPercentatage = targetValue ? (100 * parseFloat(actualPerformance)) / parseFloat(targetValue) : 0;
    }

    if (achievementPercentatage > 200) {
      achievementPercentatage = 200;
    } else if (achievementPercentatage < 50) {
      achievementPercentatage = 0;
    }

    let weightPercentage = (rowData.parameter_weightage * achievementPercentatage) / 100;

    if (!weightPercentage) {
      weightPercentage = 0;
    }
    //parseFloat(NWCDaysValue).toFixed(2)
    return parseFloat(weightPercentage).toFixed(2) + '%'

  }

  //get targets
  const getTargets = async (selectedYear) => {
    try {
      // var targetsResponses = []
      // targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
      //   c.employee_id.eq(loggedEmployeeId),
      //   c.report_manager_employee_id.eq(loggedEmployeeId),
      //   c.financial_year_id.eq(selectedYear.value),
      //   c.isAddedByHRAdmin.eq(false),
      //   c.isAssignedToEmployee.eq(true)
      // ]));

      // var targetsResponses = await graphQLGetAllData("listTargets", [{ employee_id: { eq: loggedEmployeeId }, report_manager_employee_id: { eq: loggedEmployeeReportingManagerId }, financial_year_id: { eq: selectedYear.value }, isAddedByHRAdmin: { eq: false }, isAssignedToEmployee: { eq: true } }])

      var targetsResponses = await graphQLGetAllData("listTargets", [{ employee_id: { eq: loggedEmployeeId }, financial_year_id: { eq: selectedYear.value } }])

      if (targetsResponses) {
        targetsResponses.forEach(async (target) => {
          //Target Details
          // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
          //   c.target_id.eq(target.id),
          //   c.parameter_id.eq(PARAMETER_IDs.Revenue)
          // ]));

          // setAnnualTarget(commaSeparated(parseFloat(Math.round(currentTargetDetails[0].target_value))))

          // var targetActuals = getActualValue(currentTargetDetails[0], "Quarter 4", "$")    
          // setAnnualTargetActual(commaSeparated(Math.round(targetActuals)))

          // var actualAch = await calculateConsolidatedScore(targetActuals, currentTargetDetails[0].target_value)
          // setConsolidatedScore(Math.round(actualAch))

          // if(parseInt(actualAch) < 20 && parseInt(actualAch) > 10){
          //   setPerformance("Poor")
          // }else if(parseInt(actualAch) < 50 && parseInt(actualAch) > 20){
          //   setPerformance("Average")
          // }
          // else if(parseInt(actualAch) < 80 && parseInt(actualAch) > 50){
          //   setPerformance("Good")
          // }
          // else if(parseInt(actualAch) > 80){
          //   setPerformance("Excellent")
          // }

          // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
          //   c.target_id.eq(target.id),
          //   c.parameter_id.notContains(PARAMETER_IDs.DIO),
          //   c.parameter_id.notContains(PARAMETER_IDs.DPO),
          //   c.parameter_id.notContains(PARAMETER_IDs.DVRO),
          //   c.parameter_id.notContains(PARAMETER_IDs.DSO),
          // ]));

          var currentTargetDetails = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: target.id }, parameter_id: { notContains: PARAMETER_IDs.DIO }, parameter_id: { notContains: PARAMETER_IDs.DPO }, parameter_id: { notContains: PARAMETER_IDs.DVRO }, parameter_id: { notContains: PARAMETER_IDs.DSO } }])

          var _tempArray = []


          //get parameters weightage
          // var relationshipResponse = await DataStore.query(Relationship, (c) => c.and(c => [
          //   c.role_id.eq(target.role_id),
          //   c.financial_year_id.eq(selectedYear.value)
          // ]));

          var relationshipResponse = await graphQLGetAllData("listRelationships", [{ role_id: { eq: target.role_id }, financial_year_id: { eq: selectedYear.value } }])

          var relationshipDetailResponses = []
          if (relationshipResponse.length > 0) {
            // relationshipDetailResponses = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationshipResponse[0].id));
            relationshipDetailResponses = await graphQLGetAllData("listRelationshipDetails", [{ relationship_id: { eq: relationshipResponse[0].id } }])
          }

          // currentTargetDetails.forEach(async (currentItem, i) => {
          for (let i = 0; i < currentTargetDetails.length; i++) {
            // currentTargetDetails.forEach(async (currentItem, i) => {
            //Status
            let status = ""
            if (currentTargetDetails[i].is_accepted_by_employee && !currentTargetDetails[i].is_approve) { status = "Awaiting approval" }
            else if (currentTargetDetails[i].isSendForRevisionByReportingManage) { status = "Revision" }
            else if (currentTargetDetails[i].is_approve) { status = "Approved" }
            else if (currentTargetDetails[i].is_revision_by_employee) { status = "Requested for Revision" }
            else { status = "Open" }

            //get parameters details
            // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(currentItem.parameter_id));
            var parameterResponses = parametersList.find(o => o.id === currentTargetDetails[i].parameter_id);

            var getWeightage = relationshipDetailResponses.filter((e) => e.parameter_id === currentTargetDetails[i].parameter_id)

            var weightageForWtAch = getWeightage.length > 0 ? parseInt(getWeightage[0]?.weightage) : 1;

            var parameterBaseType = parameterResponses?.type === PARAMETERS_TYPES.Days ? PARAMETERS_TYPES_NAMES.D : parameterResponses?.type === PARAMETERS_TYPES.Amount ? PARAMETERS_TYPES_NAMES.A : parameterResponses?.type === PARAMETERS_TYPES.Number ? PARAMETERS_TYPES_NAMES.N : PARAMETERS_TYPES_NAMES.P

            var actualPerformance = 0; var actualAch = 0; var wtAch = 0;

            var planActual = getActualValue(currentTargetDetails[i], "Quarter 1", parameterBaseType)

            if (planActual) { actualPerformance = parseFloat(planActual).toFixed(2) } else { actualPerformance = 0 }

            // var getPlanDetails = await DataStore.query(Plan, (c) => c.target_detail_id.eq(currentItem.id));
            var getPlanDetails = await graphQLGetAllData("listPlans", [{ target_detail_id: { eq: currentTargetDetails[i].id } }])

            var getTargetOnParameterType = getTargetBasedOnParameterTypeForEvalPopup(getPlanDetails, "Quarter 1", parameterBaseType, currentTargetDetails[i])
            actualAch = await calculateConsolidatedScore(planActual, getTargetOnParameterType)

            wtAch = await calculateWeightageAchievement(weightageForWtAch, actualAch)

            let originalWtAch = wtAch
            //Check if Negative
            actualAch = await checkIfNegativeValue(actualAch)
            wtAch = await checkIfNegativeValue(wtAch)
            // actualPerformance = await checkIfNegativeValue(actualPerformance)

            // if (getWeightage.length > 0) {
            if (1) {

              if (parameterResponses) {
                let targetValue = parameterResponses?.type === "A" || parameterResponses?.type === "D" ? getTargetOnParameterType : parseFloat(getTargetOnParameterType).toFixed(2);

                let actualValue = actualPerformance !== undefined ? parameterResponses?.type === "A" ? (parseFloat(actualPerformance)) : parseFloat(actualPerformance) : 0;

                // actualValue = actualValue.toString().replace(/[\(\)]/g, "")
                let actualPercentage = 0;

                if (currentTargetDetails[i].parameter_id === PARAMETER_IDs["NWC DAYS"]) {
                  actualPercentage = parseInt(targetValue) ? (100 * ((parseInt(targetValue) * 2) - parseInt(actualValue))) / parseInt(targetValue) : 0;
                }
                else if (currentTargetDetails[i].parameter_id === PARAMETER_IDs['Gross Margin %'] || currentTargetDetails[i].parameter_id === PARAMETER_IDs['Gross Margin $']) {
                  actualPercentage = targetValue ? (100 * parseInt(actualValue) * -1) / parseInt(targetValue) : 0;
                }
                else {
                  actualPercentage = targetValue ? (100 * parseInt(actualValue)) / parseInt(targetValue) : 0;
                }

                if (actualPercentage > 120) {
                  actualPercentage = 120;
                } else if (actualPercentage < 50) {
                  actualPercentage = 0;
                }

                let weightPercentage = (weightageForWtAch * actualPercentage) / 100;

                if (!weightPercentage) {
                  weightPercentage = 0;
                }

                let data = {
                  "target_detail_id": currentTargetDetails[i].id,
                  "parameter_id": currentTargetDetails[i].parameter_id,
                  "parameter_name": parameterResponses.name,
                  "parameter_type": parameterResponses?.type === "A" ? "$" : parameterResponses?.type === "D" ? "Days" : parameterResponses.type === "P" ? "%" : parameterResponses.type === "N" ? "Number" : "",
                  "target_value": targetValue,
                  "actualPerformance": parseFloat(actualValue).toFixed(2),
                  "actualPerformanceValue": actualValue,
                  // "actualAch": actualAch ? parseFloat(actualAch).toFixed(2) + "%" : "0%",
                  // "wtAch": wtAch ? parseFloat(wtAch).toFixed(2) + "%" : parseFloat(0).toFixed(2) + "%",
                  "actualAch": actualAch ? actualAch + "%" : "0%",
                  "wtAch": isFinite(wtAch) ? wtAch + "%" : "0%",
                  "isMarkAsComplete": currentTargetDetails[i].isMarkAsComplete,
                  "is_accepted_by_employee": currentTargetDetails[i].is_accepted_by_employee,
                  "is_approve": currentTargetDetails[i].is_approve,
                  "is_revision_by_employee": currentTargetDetails[i].is_revision_by_employee,
                  "isSendForRevisionByReportingManage": currentTargetDetails[i].isSendForRevisionByReportingManage,
                  "parameter_weightage": weightageForWtAch ? weightageForWtAch : 0,
                  "isPlanMandatory": getWeightage.length > 0 ? 1 : 0,
                  //"planning_period": moment(financialYearResponses[0].fromDate).format("Do MMM YY") + " - " + moment(financialYearResponses[0].toDate).format("Do MMM YY")
                  "status": status,
                  "q1_target_value": currentTargetDetails[i].q1_target_value,
                  "q2_target_value": currentTargetDetails[i].q2_target_value,
                  "q3_target_value": currentTargetDetails[i].q3_target_value,
                  "q4_target_value": currentTargetDetails[i].q4_target_value,
                  "q1_actual_value": getActualValue(currentTargetDetails[i], "Quarter 1", parameterBaseType),
                  "q2_actual_value": getActualValue(currentTargetDetails[i], "Quarter 2", parameterBaseType),
                  "q3_actual_value": getActualValue(currentTargetDetails[i], "Quarter 3", parameterBaseType),
                  "q4_actual_value": getActualValue(currentTargetDetails[i], "Quarter 4", parameterBaseType),
                  "totalWeightage": actualAch ? parseFloat(actualAch).toFixed(2) : 0,
                  "originalWtAch": originalWtAch,
                  "weightPercentage": parseFloat(weightPercentage).toFixed(2) + '%',
                  "actualPercentage": parseFloat(actualPercentage).toFixed(2) + '%'
                }
                _tempArray.push(data)
              }
            }
            // if (i === currentTargetDetails.length - 1) {

            // }
          }

          let sortArray = _tempArray.sort((a, b) => (a.parameter_name > b.parameter_name ? 1 : -1))
          let filteredArray = sortArray.filter(e => e.parameter_id === PARAMETER_IDs.Revenue || e.parameter_id === PARAMETER_IDs['NWC DAYS'] || e.parameter_id === PARAMETER_IDs['Gross Margin %'] || e.parameter_id === PARAMETER_IDs['Gross Margin $']);

          actualAch = filteredArray.reduce((acc, book) => acc + parseFloat(book.weightPercentage), 0);

          let filteredArrayForRevenue = sortArray.filter(e => e.parameter_id === PARAMETER_IDs.Revenue);

          setAnnualTarget(!isNaN(parseFloat(filteredArrayForRevenue[0]?.target_value)) ? filteredArrayForRevenue[0]?.target_value : 0);
          setAnnualTargetActual(commaSeparated(Math.round(filteredArrayForRevenue[0].q4_actual_value)))
          if (parseInt(actualAch) < 20 && parseInt(actualAch) > 10) {
            setPerformance("Poor")
          } else if (parseInt(actualAch) < 50 && parseInt(actualAch) > 20) {
            setPerformance("Average")
          }
          else if (parseInt(actualAch) < 80 && parseInt(actualAch) > 50) {
            setPerformance("Good")
          }
          else if (parseInt(actualAch) > 80) {
            setPerformance("Excellent")
          }


          let dollerValue = sortArray.find(o => o.parameter_id === PARAMETER_IDs["Gross Margin $"])
          let revenueValue = sortArray.find(o => o.parameter_id === PARAMETER_IDs.Revenue)
          let gmPercentageValue = 0;
          if (dollerValue) {
             gmPercentageValue = revenueValue.actualPerformanceValue ? (parseInt(dollerValue ? dollerValue?.actualPerformanceValue :0) / parseInt(revenueValue.actualPerformanceValue)) * 100 : 0;
          }
          // let gmPercentageValue = revenueValue.actualPerformance ? (parseInt(dollerValue.actualPerformanceValue) / parseInt(revenueValue.actualPerformanceValue)) * 100 : 0;
          let filteredData = sortArray.filter(e => e.isPlanMandatory)

          let objIndex = filteredData.findIndex((obj => obj.parameter_id == PARAMETER_IDs["Gross Margin %"]));

          if (objIndex >= 0) {
            filteredData[objIndex].actualPerformance = gmPercentageValue.toFixed(2) * -1;
            // filteredData[objIndex].actualPercentage = showPlanActual_valuePercentage(filteredData[objIndex]);
            // filteredData[objIndex].weightPercentage = showAchiievementWeightPercentage(filteredData[objIndex]);
            filteredData[objIndex].q1_actual_value = filteredData[objIndex].q1_actual_value * -1;
            filteredData[objIndex].q2_actual_value = filteredData[objIndex].q2_actual_value * -1;
            filteredData[objIndex].q3_actual_value = filteredData[objIndex].q3_actual_value * -1;
            filteredData[objIndex].q4_actual_value = filteredData[objIndex].q4_actual_value * -1;
          }

          let objIndexforGM$ = filteredData.findIndex((obj => obj.parameter_id == PARAMETER_IDs["Gross Margin $"]));

          if (objIndexforGM$ >= 0) {
            filteredData[objIndexforGM$].actualPerformance = filteredData[objIndexforGM$].actualPerformance * -1;
            filteredData[objIndexforGM$].actualPerformanceValue = filteredData[objIndexforGM$].actualPerformanceValue * -1;
            // filteredData[objIndexforGM$].actualPercentage = showPlanActual_valuePercentage(filteredData[objIndexforGM$]);
            // filteredData[objIndexforGM$].weightPercentage = showAchiievementWeightPercentage(filteredData[objIndexforGM$]);
            filteredData[objIndexforGM$].q1_actual_value = filteredData[objIndexforGM$].q1_actual_value * -1;
            filteredData[objIndexforGM$].q2_actual_value = filteredData[objIndexforGM$].q2_actual_value * -1;
            filteredData[objIndexforGM$].q3_actual_value = filteredData[objIndexforGM$].q3_actual_value * -1;
            filteredData[objIndexforGM$].q4_actual_value = filteredData[objIndexforGM$].q4_actual_value * -1;
          }


          filteredData = filteredData.map((item) => ({
            ...item,
            weightPercentage: showAchiievementWeightPercentage(item),
            actualPercentage: showPlanActual_valuePercentage(item),
          }))


          let totalConsolidatedScore = filteredData.reduce((acc, book) => acc + parseFloat(book.weightPercentage), 0); // originalWtAch replaced with weightPercentage

          setConsolidatedScore(!isNaN(parseFloat(totalConsolidatedScore).toFixed(2)) ? parseFloat(totalConsolidatedScore).toFixed(2) : 0)
        })
      }
    } catch (e) { }
  }

  const getMasterData = async () => {
    setDataLoaded(false)
    let parameterResponses = await graphQLGetAllData("listParameters")
    SetParametersList(parameterResponses)
    setDataLoaded(true)
  }

  const [parametersList, SetParametersList] = useState([]);
  const [isDataLoaded, setDataLoaded] = useState(false);


  useEffect(() => {
    if (isDataLoaded === true) {
      onLoad();
      getLoggedEmployeeTargets()
    }
  }, [isDataLoaded]);


  useEffect(() => {
    getMasterData()
  }, [])

  return isAuthenticated ? (
    <div className="min-h-screen flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:grid-cols-1 gap-0 landing">
      <div className="flex flex-wrap flex-row justify-center auto-rows-max left-side-bg ">
        <div className="max-w-xl w-full p-3 pt-10">
          <div className="mb-10">
            <img className="w-60" src={logoLanding} alt="" />
          </div>
          <div className="flex  mb-8">
            <p className="text-[26px] xl:text-[26px] 2xl:text-[30px] text-[#101828] leading-12  font-medium w-[500px] dark:text-white">
              Redington’s Goal Sheet Evaluation Application, an extensible solution for planning and managing your sales performance
            </p>
          </div>
          <div className="quotes px-5 w-[420px] mb-6">
            <h6 className="text-[28px] text-[#01813F] leading-10 font-nomal dark:text-[#98A2B3]">
              Setting goals is the first step to turning the invisible into
              visible
            </h6>
            <p className="text-[#029046] font-light mt-3 dark:text-[#98A2B3]">~ Tony Robbins</p>
          </div>

          <div className="2xl:fixed 2xl:bottom-0 mb-3 flex mt-[10rem] gap-5">
            <Link className="text-[12px] text-[#98A2B3] dark:text-[#98A2B3]">
              Conditions of Use
            </Link>
            <Link className="text-[12px] text-[#98A2B3] dark:text-[#98A2B3]">
              Privacy Notice
            </Link>
            <p className="text-[12px] text-[#98A2B3] dark:text-[#98A2B3]">
              Copyright @{moment().format("YYYY")}, Redington
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#002B15] h-full flex-row flex flex-wrap align-center justify-center dark:bg-[#2A2E32]">
        <div className="pt-5 px-6">
          <p className="text-center text-[#fff] leading-7 font-medium text-[20px] px-20 mb-4">
            Data driven holistic solution, to plan, track, analyze and optimize
            your sales performance
          </p>

          <div className="bg-[#eef8f4] p-5 bg-opacity-5 py-6 text-center rounded-md dark:bg-[#181b21] dark:border dark:border-[#4E5668]">
            <Link className="bg-[#1B9B58] text-[#fff] rounded-full px-8 py-2">
              I would like to
            </Link>

            <div className="flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-3 mt-4 feature">
              <div className="bg-[#fff] rounded p-2.5 py-4 box dark:bg-[#2A2E32] relative ">
                <div className="grid grid-cols-2 gap-4 items-center ">
                  <div>
                    <img
                      className="m-auto"
                      src={exportImage}
                      alt=""
                    />
                  </div>

                  <div className="text-left">
                    <h4 className="text-[18px] 2xl-[30px] xl-[30px] 3xl-[30px] text-[#000] font-bold mb-3 dark:text-white group ">
                      Explore
                      <div className="absolute tooltippostion">
                        <div className="flex flex-col gap-0 text-left items-center justify-end hidden mb-6 group-hover:flex w-48 rounded-md font-light">
                          <div className="relative z-10 p-2 pb-0 text-[12px] text-left leading-3 text-white whitespace-no-wrap bg-[#101828] shadow-lg w-48 rounded-t-md">Explore</div>
                          <span className="relative z-10 px-2 py-1.5 text-[12px] leading-2 text-white whitespace-no-wrap bg-[#101828] shadow-lg text-left w-48 rounded-b-md">Allows you to track performance in real time.</span>
                          <div className="w-3 h-3 -mt-2 rotate-45 bg-[#101828]"></div>
                        </div>
                      </div>
                    </h4>
                    <Link
                      to={isReportingManager == 0 ? "/employee/dashboard/business" : checkEmployeeTeamType() ? "/employee/dashboard/business/planning" : "/employee/dashboard/sales/planning"}
                      className="text-[#1B9B58] text-[10px] rounded-md px-3 py-2 uppercase border border-[#029046]  border-1 font-medium"
                    >
                      More Details
                    </Link>
                  </div>
                </div>

              </div>
              <div className="bg-[#fff] rounded p-2.5 py-4 box dark:bg-[#2A2E32] relative">
                <div className="grid grid-cols-2 gap-4 items-center">
                  <div>
                    <img
                      className="m-auto"
                      src={planningImage}
                      alt=""
                    />
                  </div>

                  <div className="text-left">
                    <h4 className="text-[18px] 2xl-[30px] xl-[30px] 3xl-[30px] text-[#000] font-bold mb-3 dark:text-white group ">
                      Goals Settings
                      <div className="absolute tooltippostion">
                        <div className="flex flex-col gap-0 text-left items-center justify-end hidden mb-6 group-hover:flex w-48 rounded-md font-light">
                          <div className="relative z-10 p-2 pb-0 text-[12px] text-left leading-3 text-white whitespace-no-wrap bg-[#101828] shadow-lg w-48 rounded-t-md">Goals Settings</div>
                          <span className="relative z-10 px-2 py-1.5 text-[12px] leading-2 text-white whitespace-no-wrap bg-[#101828] shadow-lg text-left w-48 rounded-b-md">Allows you to manage targets and plan.</span>
                          <div className="w-3 h-3 -mt-2 rotate-45 bg-[#101828]"></div>
                        </div>
                      </div>
                    </h4>
                    <Link
                      to={isReportingManager === "0" ? "/employee/planning/self/manageplan" : "/employee/planning/team/managetarget"}
                      className="text-[#1B9B58] text-[10px] rounded-md px-3 py-2 uppercase border border-[#029046] border-1 font-medium"
                    >
                      More Details
                    </Link>
                  </div>
                </div>
              </div>

              <div className="bg-[#fff] rounded p-2.5 py-4 box dark:bg-[#2A2E32] relative">
                <div className="grid grid-cols-2 gap-4 items-center">
                  <div>
                    <img
                      className="m-auto"
                      src={evaluationImage}
                      alt=""
                    />
                  </div>

                  <div className="text-left">
                    <h4 className="text-[18px] 2xl-[30px] xl-[30px] 3xl-[30px] text-[#000] font-bold mb-3 dark:text-white group">
                      Goals Evaluation
                      <div className="absolute tooltippostion">
                        <div className="flex flex-col gap-0 text-left items-center justify-end hidden mb-6 group-hover:flex w-48 rounded-md font-light">
                          <div className="relative z-10 p-2 pb-0 text-[12px] text-left leading-3 text-white whitespace-no-wrap bg-[#101828] shadow-lg w-48 rounded-t-md">Goals Evaluation</div>
                          <span className="relative z-10 px-2 py-1.5 text-[12px] leading-2 text-white whitespace-no-wrap bg-[#101828] shadow-lg text-left w-48 rounded-b-md">Allows you to evaluate the performance against assigned targets.</span>
                          <div className="w-3 h-3 -mt-2 rotate-45 bg-[#101828]"></div>
                        </div>
                      </div>
                    </h4>
                    <Link
                      to={isReportingManager === "0" ? "/employee/evaluation/self/currentyear" : "/employee/evaluation/team/currentyear"}
                      className="text-[#1B9B58] text-[10px] rounded-md px-3 py-2 uppercase border border-[#029046] border-1 font-medium"
                    >
                      More Details
                    </Link>
                  </div>
                </div>
              </div>
              <div className="bg-[#fff] rounded p-2.5 py-4 box dark:bg-[#2A2E32] relative">
                <div className="grid grid-cols-2 gap-4 items-center">
                  <div>
                    <img
                      className="m-auto"
                      src={reportImage}
                      alt=""
                    />
                  </div>

                  <div className="text-left">
                    <h4 className="text-[18px] 2xl-[30px] xl-[30px] 3xl-[30px] text-[#000] font-bold mb-3 dark:text-white group">
                      Reports
                      <div className="absolute tooltippostion">
                        <div className="flex flex-col gap-0 text-left items-center justify-end hidden mb-6 group-hover:flex w-48 rounded-md font-light">
                          <div className="relative z-10 p-2 pb-0 text-[12px] text-left leading-3 text-white whitespace-no-wrap bg-[#101828] shadow-lg w-48 rounded-t-md">Reports</div>
                          <span className="relative z-10 px-2 py-1.5 text-[12px] leading-2 text-white whitespace-no-wrap bg-[#101828] shadow-lg text-left w-48 rounded-b-md">Allows you to customize and generate insightful reports.</span>
                          <div className="w-3 h-3 -mt-2 rotate-45 bg-[#101828]"></div>
                        </div>
                      </div>
                    </h4>
                    <Link
                      to="/employee/reports"
                      className="text-[#1B9B58] text-[10px] rounded-md px-3 py-2 uppercase border border-[#029046] border-1 font-medium"
                    >
                      More Details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-3 mt-10">
            <div className="grid grid-cols-3 gap-0 items-center">
              <div className="col-span-1">
                <img
                  className="m-auto w-36"
                  src={blubImage}
                  alt=""
                />
              </div>
              <div className="-rotate-90">
                <span className="bg-[#1B9B58] text-[#fff] text-[14px] rounded-md px-2 py-1.5 border border-[#029046] flex justify-center item-center w-32 relative right-2">
                  Finger Tips Facts
                </span>
              </div>

              <div>
                <img
                  className="m-auto w-20"
                  src={arrowRightDot}
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className="grid grid-cols-2 gap-y-3 gap-3 items-center">

                <div className="bg-[#001C0E] p-2 2xl:p-5 xl:p-2 lg:p-2 xl:py-4 lg:py-4 text-center dark:bg-[#1E2420] ">

                  <h6 className="text-[12px] text-[#fff]">Annual Revenue Target</h6>
                  <h4 className="text-[18px] text-[#fff] font-semibold">${commaSeparated(annualTarget)}</h4>

                </div>

                <div className="bg-[#001C0E] p-2 2xl:p-5 xl:p-2 lg:p-2 xl:py-4 lg:py-4 text-center dark:bg-[#1E2420]">
                  <h6 className="text-[12px] text-[#fff]">Actual Revenue</h6>
                  <h4 className="text-[18px] text-[#fff] font-semibold">${annualTargetActual}</h4>
                </div>
                <div className="bg-[#001C0E] p-2 2xl:p-5 xl:p-2 lg:p-2 xl:py-4 lg:py-4 text-center dark:bg-[#1E2420]">
                  <h6 className="text-[12px] text-[#fff]">Consolidated Score</h6>
                  <h4 className="text-[18px] text-[#fff] font-semibold">{consolidatedScore}%</h4>
                </div>
                <div className="bg-[#001C0E] p-2 2xl:p-5 xl:p-2 lg:p-2 xl:py-4 lg:py-4 text-center dark:bg-[#1E2420]">
                  <h6 className="text-[12px] text-[#fff]">Performance Rating</h6>
                  <h4 className="text-[18px] text-[#fff] font-semibold">{performance}</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="2xl:fixed 2xl:bottom-0 flex mt-8 mb-3 justify-center text-center">
            <p className="text-[#B3DDC7] font-light text-[11px] dark:text-[#98A2B3] 2xl:ml-20 xl:ml-6 lg:ml-12">Redington Gulf Representative Office, Level 16, Burjuman Business Tower ,
              Thiru – Sheikh Khalifa Bin Zayed St – Al Mankhool – Dubai</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <EmpLogin sessionExpired={1} />
  );
}
