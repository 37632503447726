import React, { useState, useEffect, } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Country, Region } from '../../../../../models'
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { ConvertResponseForSelectWithRefCode, ConvertResponseForSelectTwoWithCode } from '../../../../../components/helper/commonfunctions'
import Select from 'react-select'
import { Auth } from "aws-amplify";
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import HRLogin from "../../../../../setup/auth/hr.component";
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import capitalizeFirst from '../../../../../components/common/capitalizeFirst';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';

export default function HrCreateCountry() {

    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [country, setCountry] = useState("");
    const [isEditMode, setEditMode] = useState(false);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editcountryid } = useParams();
    const [regionList, setRegionList] = useState([]);
    const [regionName, setRegionName] = useState("");
    const [regionID, setRegionId] = useState("");
    const [countryID, setCountryId] = useState("");
    const [refCode, SetRefCode] = useState("");
    const [vat, SetVAT] = useState("");


    let navigateTo = useNavigate();
    async function BindList() {
        // var regionList = await DataStore.query(Region, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        var regionList = await graphQLGetAllData('listRegions');
        regionList = regionList.sort((a, b) => a.name.localeCompare(b.name));
        var myRegionArray = []; myRegionArray = ConvertResponseForSelectWithRefCode(regionList);
        setRegionList(myRegionArray)
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }

            //Edit existing record
            if (editcountryid != null) {
                setCountryId(editcountryid);
                // const original = await DataStore.query(Country, editcountryid);
                const original = await graphQLFindRecordById('getCountry', editcountryid);

                if (original != null) {
                    // const Region_original = await DataStore.query(Region, original.region_id);
                    const Region_original = await graphQLFindRecordById('getRegion', original.region_id);


                    setRegionName({ label: Region_original.name + " (" + Region_original.REF_CODE + ")", value: Region_original.id })
                    setRegionId(original.region_id)
                    setCountry(original.name);
                    setEditMode(true);
                    SetRefCode(original.REF_CODE);
                    original.VAT ? SetVAT(original.VAT) : SetVAT(0);
                }
                else {
                    toast.error('Invalid record.');
                    navigateTo("/hr/setting/dimensions/country");
                }

            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }
    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    const saveBrand = async () => {

        if (country.trim() === "") { toast.error("Please enter Country."); return; }
        if (regionID === "") { toast.error("Please select Region."); return; }

        setPleaseWaitButton(true);
        if (isEditMode) {
            // const original = await DataStore.query(Country, countryID);
            const original = await graphQLFindRecordById('getCountry', countryID);

            // await DataStore.save(
            //     Country.copyOf(original, (updated) => {
            //         updated.name = country;
            //         updated.region_id = regionID;
            //         updated.REF_CODE = refCode;
            //         updated.VAT = vat;
            //     })
            // );

            await API.graphql({
                query: mutations.updateCountry,
                variables: {
                    input: {
                        id: original.id,
                        name: country,
                        region_id: regionID,
                        REF_CODE: refCode,
                        VAT: vat,
                        _version: original._version,
                    }
                }
            });

            toast.success('Updated');
        } else {
            // await DataStore.save(new Country({ 
            // name: country, 
            // region_id: regionID, 
            // REF_CODE: capitalizeFirst(refCode), 
            // VAT: vat }));

            await API.graphql(
                graphqlOperation(mutations.createCountry, {
                    input: {
                        name: country, 
                        region_id: regionID, 
                        REF_CODE: capitalizeFirst(refCode), 
                        VAT: vat
                    }
                })
            );
            toast.success('Added');
        }
        setPleaseWaitButton(false);
        navigateTo("/hr/setting/dimensions/country");
    };
    return (
        isAuthenticated ? (

            <div>
                <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full">
                    <TopNavBar />
                    <LeftSideBar />
                    <div className="pl-16">
                        <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                            <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                                <div className="p-3 py-5 bg-white rounded-md shadow dark:bg-[#252423] ">
                                    <div className="flex justify-center mt-3 mb-2">
                                        <h2 className="page-title   ">
                                            {isEditMode ? "Edit" : "Create"} Country
                                        </h2>
                                    </div>
                                    <div x-data="{tab: 1}"></div>
                                    <div className={"block w-full"}>
                                        <div className="px-4" x-show="tab === 1">
                                            <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                                                <div className="relative mb-4 ">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium"
                                                    >
                                                        Name
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *{" "}
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(country)}
                                                        onChange={(e) => setCountry(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]  dark:border-[#333231]  dark:bg-[#333231]"
                                                        placeholder="Enter Country"
                                                    />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium ">
                                                        Cluster <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                            * </label></label>
                                                    <Select placeholder='Select' options={regionList} value={regionName} onChange={e => {
                                                        setRegionName(e)
                                                        setRegionId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>
                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Ref_Code
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >

                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(refCode)}
                                                        onChange={(e) => SetRefCode(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Ref Code"
                                                    />
                                                </div>
                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        VAT
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(vat)}
                                                        onChange={(e) => SetVAT(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="VAT %"
                                                    />
                                                </div>
                                            </div>
                                            <div className="grow flex justify-center gap-3 mb-3">
                                                <Link
                                                    to="/hr/setting/dimensions/country"
                                                    className=" py-2 px-5 bg-white transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-mddark:border-[#333231]  dark:border-[#333231]  dark:bg-[#4A5056] dark:text-white "
                                                >
                                                    Cancel
                                                </Link>
                                                <button
                                                    onClick={() => saveBrand()}
                                                    className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md "
                                                >
                                                    {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        ) : (
            <HRLogin sessionExpired={1} />
        )

    )
}