//***** HR ******** /
import VerifyUserCompleteRegistration from "./auth/confirmregistration.component";
import HrLogin from "./auth/hr.component";
import HRCreatePassword from "./auth/hr.createpassword.component";
import HRForgotPassword from "./auth/hrforgotpassword.component";
import HRResetPassword from "./auth/hrresetpassword.component";

//***** Masters ******** /
import HrCreateUser from "../pages/hr/setting/masters/user/createuser.component";
import HrUsers from "../pages/hr/setting/masters/user/user.component";

import CreateParameter from "../pages/hr/setting/masters/parameters/createparameter.component";
import ParametersList from "../pages/hr/setting/masters/parameters/parameters.component";

import HrCreateRole from "../pages/hr/setting/masters/role/createrole.component";
import HrRole from "../pages/hr/setting/masters/role/role.component";

import HrCreateEmployee from "../pages/hr/setting/masters/employee/createemployee.component";
import HrEmployees from "../pages/hr/setting/masters/employee/employee.component";

import HrCreateFinancialYear from "../pages/hr/setting/masters/financialyear/createfinancialyear.component";
import HrFinancialYear from "../pages/hr/setting/masters/financialyear/financialyear.component";

import CreateVertical from "../pages/hr/setting/dimensions/vertical/create.component";
import Vertical from "../pages/hr/setting/dimensions/vertical/vertical.component";



import HrAssignParameters from "../pages/hr/assignparameters.component";
import HRChangePassword from "../pages/hr/changepassword.component";
import HrChangeRequest from "../pages/hr/changerequest.component";
import HrEvaluationDashboard from "../pages/hr/dashboard/evaluation.component";
import HrPlanningDashboard from "../pages/hr/dashboard/planning.component";
import HrEvaluation from "../pages/hr/evaluation/evaluation.component";
import ViewInitiateEvaluation from "../pages/hr/evaluation/viewinitiateevaluation.component";
import HrLanding from "../pages/hr/landing.component";
import HrManageParameters from "../pages/hr/manageparameters.component";
import HrMessage from "../pages/hr/message.component";
import HrPlanning from "../pages/hr/planning.component";
import HrPlanning_AssignTargets from "../pages/hr/planningassigntarget.component";
import HRProfile from "../pages/hr/profile.component";
import { default as HRSettings, default as Settings } from "../pages/hr/settings.component";
import HrPlanning_ViewInitiatePlanning from "../pages/hr/viewinitiateplanning.component";


//***** Employee ******** /
import EmployeeChangePassword from "../pages/employee/changepassword.component";
import ImportParametersDetail from "../pages/employee/importparametersDetails.component";
import EmployeeLanding from "../pages/employee/landing.component";
import EmployeeParameters from "../pages/employee/parameters.component";
import EmployeePlanningTeamAnnualTarget from "../pages/employee/planning/team/annualtarget.component";
import EmployeePlanningTeamAssignTarget from "../pages/employee/planning/team/assigntarget.component";
import EmployeePlanningAssignTarget_AnnualTarget from "../pages/employee/planning/team/assigntarget_annualtarget.component";
import EmployeePlanningTeamManageChangeRequest from "../pages/employee/planning/team/managechangerequest.component";
import EmployeePlanningTeamManageTarget from "../pages/employee/planning/team/managetarget.component";
import EmployeePlanningTeamManage_AnnualTarget from "../pages/employee/planning/team/managetarget_annualtarget.component";
import EmployeePlanningTeamViewTarget from "../pages/employee/planning/team/viewtarget.component";
import EmployeeProfile from "../pages/employee/profile.component";
import EmployeeLogin from "./auth/emp.component";
import EmployeeLoginForce from "./auth/empForceLogin.component";

import ManageCommit_Team from "../pages/employee/planning/team/reviewcommit.component";
import ManageCommit_AnnualTarget from "../pages/employee/planning/team/reviewcommit_annualtarget.component";
import ManageCommit_AnnualTarget_PlanReview from "../pages/employee/planning/team/reviewcommit_annualtarget_planreview.component";

import EmployeePlanningSelfManageChangeRequest from "../pages/employee/planning/self/managechangerequest.component";
import EmployeePlanningSelfManagePlan from "../pages/employee/planning/self/manageplan.component";

import EmployeeTeamManageCommit from "../pages/employee/planning/team/managecommit.component";
import EmployeeTeamManageCommitAnnualTarget from "../pages/employee/planning/team/managecommit_annualtarget.component";

import EmployeeViewNotification from "../../src/pages/employee/viewnotification.component";
import EmployeeMessage from "../pages/employee/message.component";

//Employee Evaluation//
import EvaluationSelfCurrentYear from "../pages/employee/evaluation/self/currentyear.component";
import EvaluationSelfCurrentYearReport from "../pages/employee/evaluation/self/currenyearreport.component";
import EvaluationSelfHistorical from "../pages/employee/evaluation/self/historical.component";
import EvaluationTeamCurrentYear from "../pages/employee/evaluation/team/currentyear.component";
import EvaluationTeamCurrentYear_TeamReport from "../pages/employee/evaluation/team/currenyearreport.component";
import EvaluationTeamHistorical from "../pages/employee/evaluation/team/historical.component";

import EmployeeEvaluation from "../pages/employee/evaluation.component";
import EmployeeGenerateReport from "../pages/employee/generatereport.component";
import EmployeeReports from "../pages/employee/reports.component";
import EmployeeSettings from "../pages/employee/settings.component";

//Employee Dashboard//
import BusinessEvaluationDashboard from "../pages/employee/dashboard/Business/businessevaluation.component";
import BusinessPerformanceDashboard from "../pages/employee/dashboard/Business/businessperformance.component";
import BusinessPlanningDashboard from "../pages/employee/dashboard/Business/businessplanning.component";
import ReporteeBusiness from "../pages/employee/dashboard/ReporteeDashboard/business.component";
import ReporteeSales from "../pages/employee/dashboard/ReporteeDashboard/sales.component";
import SalesEvaluationDashboard from "../pages/employee/dashboard/Sales/salesevaluation.component";
import SalesPerformanceDashboard from "../pages/employee/dashboard/Sales/salesperformance.component";
import SalesPlanningDashboard from "../pages/employee/dashboard/Sales/salesplanning.component";

//Common
import PageNotFound from '../pages/404Page';
import HomePage from '../pages/homePage';
import CreateProductGroup from "../pages/hr/setting/dimensions/productgroup/create.components";
import ProductGroups from "../pages/hr/setting/dimensions/productgroup/productgroup.component";

import { Route, Routes } from "react-router-dom";
import Compony_SBU_Actual from "../pages/hr/evaluation/evalution.compony.component";
import NWC_Days_Actuals from "../pages/hr/evaluation/evaluation.nwcdaysactuals.component"
import View_NWC_Days_Actuals from "../pages/hr/evaluation/evaluation.viewnwcdaysactuals.component";
import CreateCompanyActual from "../pages/hr/evaluation/evalution.createcompanyactuals.component";
import Brands from "../pages/hr/setting/dimensions/brand/brand.component";
import CreateBrand from "../pages/hr/setting/dimensions/brand/createbrand.components";
import BussinessUnits from "../pages/hr/setting/dimensions/bussinessUnits/bussinessUnits.component";
import CreateBussinessUnits from "../pages/hr/setting/dimensions/bussinessUnits/createbussinessUnits.components";
import HrCountry from "../pages/hr/setting/dimensions/country/country.component";
import HrCountryList from "../pages/hr/setting/dimensions/country/createcountry.components";
import CreateCustomer from "../pages/hr/setting/dimensions/customer/create.components";
import CustomerList from "../pages/hr/setting/dimensions/customer/customer.component";
import CreateCustomerCategory from "../pages/hr/setting/dimensions/customerCategory/create.components";
import CustomerCategoryList from "../pages/hr/setting/dimensions/customerCategory/customerCategory.component";
import CreateCustomerSegment from "../pages/hr/setting/dimensions/customerSegment/create.components";
import CustomerSegmentList from "../pages/hr/setting/dimensions/customerSegment/customerSegment.component";
import CreateRegions from "../pages/hr/setting/dimensions/region/create.componant";
import Regions from "../pages/hr/setting/dimensions/region/region.componant";
import CreateSBU from "../pages/hr/setting/dimensions/sbu/create.components";
import SBU from "../pages/hr/setting/dimensions/sbu/sbu.component";

import CreatePlantStorageLocation from "../pages/hr/setting/dimensions/plantStorageLocation/create.components";
import PlantStorageLocations from "../pages/hr/setting/dimensions/plantStorageLocation/plantStorageLocation.component";

import CreateMaterial from "../pages/hr/setting/dimensions/material/create.components";
import Material from "../pages/hr/setting/dimensions/material/material.component";

import CreateDivisions from "../pages/hr/setting/dimensions/division/create.components";
import Divisions from "../pages/hr/setting/dimensions/division/division.component";

import { Create_EBIT_PBT } from "../pages/hr/evaluation/evalution.createebitpbt.component";
import { EBIT_PBT } from "../pages/hr/evaluation/evalution.ebitpbt.component";


import CreateGtm from "../pages/hr/setting/masters/gtm/create.componant";
import Gtm from "../pages/hr/setting/masters/gtm/gtm.componant";

import CreateSubGtm from "../pages/hr/setting/masters/subgtm/create.componant";
import SubGtm from "../pages/hr/setting/masters/subgtm/subgtm.componant";

//  {hr changerequest}
import EvaluationPeriod from "../pages/hr/changerequest/evaluationperiod.component";
import OverrideActuals from "../pages/hr/changerequest/overrideactuals.component";
import PlanningPeriod from "../pages/hr/changerequest/planningperiod.component";
import OrganizationCharts from "../pages/hr/setting/masters/employee/organizationchart.component";

import HrViewNotification from "../pages/hr/viewnotification.component";

//  employee changerequest
import MyselfChangeRequest from "../pages/employee/changerequest/self/myselfchangerequest.componant";
import MyTeamChangeRequest from "../pages/employee/changerequest/team/myteamchangerequest.component";

// HR Reports
import ReportsGoalEvaluationDetailed from "../pages/hr/reports/GoalEvalution/detailed.component";
import ReportsGoalEvaluationSummary from "../pages/hr/reports/GoalEvalution/summery.component";
import ReportsGoalSettingSummary from "../pages/hr/reports/GoalSetting/summary.components";

import ReportsGoalSettingDetailed from "../pages/hr/reports/GoalSetting/detailed.components";

//ProConnect
import CompanyList from "../pages/hr/setting/dimensions/company/company.component";
import { CreateCompany } from "../pages/hr/setting/dimensions/company/createcompany.components";
import { CreateProfitCenter } from "../pages/hr/setting/dimensions/profilecenter/createprofitcenter.component";
import ProfitCenterList from "../pages/hr/setting/dimensions/profilecenter/profitcenter.component";
import Test from "./auth/test.component";

export default function RouteComponent() {
    return (
        <Routes>
            {/* {/ HR /} */}
            <Route exact path="/test" element={<Test />} />
            <Route exact path="/hr" element={<HrLogin />} />
            <Route exact path="/hr/createpassword" element={<HRCreatePassword />} />
            <Route exact path="/hr/confirmregistration" element={<VerifyUserCompleteRegistration />} />
            <Route exact path="/hr/forgotpassword" element={<HRForgotPassword />} />
            <Route exact path="/hr/resetpassword" element={<HRResetPassword />} />

            <Route exact path="/hr/landing" element={<HrLanding />} />
            <Route exact path="/hr/dashboard/planning" element={<HrPlanningDashboard />} />
            <Route exact path="/hr/dashboard/evaluation" element={<HrEvaluationDashboard />} />
            <Route exact path="/hr/manageparameters" element={<HrManageParameters />} />
            <Route exact path="/hr/assignparameters" element={<HrAssignParameters />} />
            <Route exact path="/hr/planning" element={<HrPlanning />} />
            <Route exact path="/hr/planning/assigntarget" element={<HrPlanning_AssignTargets />} />
            <Route exact path="/hr/planning/viewinitiateplanning/:financialInitiateId/:planningId" element={<HrPlanning_ViewInitiatePlanning />} />
            <Route exact path="/hr/evaluation" element={<HrEvaluation />} />
            <Route exact path="/hr/evaluation/companysbu" element={<Compony_SBU_Actual />} />
            <Route exact path="/hr/evaluation/createcompanyactuals" element={<CreateCompanyActual />} />
            <Route exact path="/hr/evaluation/createcompanyactuals/:editCompanyActualId" element={<CreateCompanyActual />} />
            <Route exact path="/hr/evaluation/viewinitiateevaluation/:financialInitiateId/:quarterId/:evaluationId" element={<ViewInitiateEvaluation />} />
            <Route exact path="/hr/evaluation/nwcdaysactuals" element={<NWC_Days_Actuals />} />
            <Route exact path="/hr/evaluation/viewnwcdaysactuals/:financial_year_id" element={<View_NWC_Days_Actuals />} />
            <Route exact path="/hr/evaluation/ebitpbt" element={<EBIT_PBT />} />
            <Route exact path="/hr/evaluation/createebitpbt" element={<Create_EBIT_PBT />} />
            <Route exact path="/hr/evaluation/createebitpbt/:editFinancialActualId" element={<Create_EBIT_PBT />} />
            <Route exact path="/hr/changerequest" element={<HrChangeRequest />} />
            <Route exact path="/hr/message" element={<HrMessage />} />
            <Route exact path="/hr/settings" element={<HRSettings />} />
            <Route exact path="/hr/settings" element={<Settings />} />
            <Route exact path="/hr/profile" element={<HRProfile />} />
            <Route exact path="/hr/changepassword" element={<HRChangePassword />} />
            <Route exact path="/hr/viewnotification" element={<HrViewNotification />} />

            {/* HR Reports */}
            <Route exact path="/hr/reports/goalsetting/summary" element={<ReportsGoalSettingSummary />} />
            <Route exact path="/hr/reports/goalsetting/detailed" element={<ReportsGoalSettingDetailed />} />
            <Route exact path="/hr/reports/goalevaluation/summary" element={<ReportsGoalEvaluationSummary />} />
            <Route exact path="/hr/reports/goalevaluation/detailed" element={<ReportsGoalEvaluationDetailed />} />



            {/* { Change Request} */}
            <Route exact path="/hr/changerequest/planningperiod" element={<PlanningPeriod />} />
            <Route exact path="/hr/changerequest/evaluationperiod" element={<EvaluationPeriod />} />
            <Route exact path="/hr/changerequest/overrideperiod" element={<OverrideActuals />} />



            {/* {/ Masters /} */}
            <Route exact path="/hr/settings/users" element={<HrUsers />} />
            <Route exact path="/hr/settings/createuser" element={<HrCreateUser />} />

            <Route exact path="/hr/settings/parameters" element={<ParametersList />} />
            <Route exact path="/hr/settings/createparameter" element={<CreateParameter />} />
            <Route exact path="/hr/settings/createparameter/:editParameterId" element={<CreateParameter />} />

            <Route exact path="/hr/settings/role" element={<HrRole />} />
            <Route exact path="/hr/settings/createrole" element={<HrCreateRole />} />
            <Route exact path="/hr/settings/createrole/:editRoleId" element={<HrCreateRole />} />

            <Route exact path="/hr/settings/employee" element={<HrEmployees />} />
            <Route exact path="/hr/settings/createemployee" element={<HrCreateEmployee />} />
            <Route exact path="/hr/settings/createemployee/:editEmployeeId" element={<HrCreateEmployee />} />
            <Route exact path="/hr/settings/organizationchart" element={<OrganizationCharts />} />

            <Route exact path="/hr/settings/financialyear" element={<HrFinancialYear />} />
            <Route exact path="/hr/settings/createfinancialyear" element={<HrCreateFinancialYear />} />
            <Route exact path="/hr/settings/createfinancialyear/:editFinancialYearId" element={<HrCreateFinancialYear />} />


            {/* {/ Employee /} */}
            {/* Masters */}
            <Route exact path="/hr/settings/users" element={<HrUsers />} />
            <Route exact path="/hr/settings/createuser" element={<HrCreateUser />} />

            {/* Product Group */}
            <Route exact path="/hr/settings/createproductgroups" element={<CreateProductGroup />} />
            <Route exact path="/hr/setting/productgroups" element={<ProductGroups />} />
            <Route exact path="/hr/settings/createproductgroups/:productgroupid" element={<CreateProductGroup />} />

            {/* company  */}
            <Route exact path="/hr/settings/createcompany" element={<CreateCompany />} />
            <Route exact path="/hr/setting/company" element={<CompanyList />} />
            <Route exact path="/hr/settings/createcompany/:companyid" element={<CreateCompany />} />

            {/* ProfileCenter  */}
            <Route exact path="/hr/settings/createprofitcenter" element={<CreateProfitCenter />} />
            <Route exact path="/hr/setting/profitcenter" element={<ProfitCenterList />} />
            <Route exact path="/hr/settings/createprofitcenter/:profitcenterid" element={<CreateProfitCenter />} />

            {/* Bussiness Units */}
            <Route exact path="/hr/settings/createbussinessunits" element={<CreateBussinessUnits />} />
            <Route exact path="/hr/setting/bussinessunits" element={<BussinessUnits />} />
            <Route exact path="/hr/settings/createbussinessunits/:businessUnitId" element={<CreateBussinessUnits />} />

            {/* Region*/}
            <Route exact path="/hr/setting/createregions" element={<CreateRegions />} />
            <Route exact path="/hr/setting/regions" element={<Regions />} />
            <Route exact path="/hr/setting/createregions/:regionid" element={<CreateRegions />} />

            {/* {/ SBU /} */}
            <Route path="/hr/setting/dimensions/sbu" element={<SBU />} />
            <Route path="/hr/setting/dimensions/sbu/createsbu" element={<CreateSBU />} />
            <Route exact path="/hr/setting/dimensions/sbu/createsbu/:editSBUId" element={<CreateSBU />} />

            {/* Customer */}
            <Route path="/hr/setting/dimensions/customer" element={<CustomerList />} />
            <Route path="/hr/setting/dimensions/customer/createCustomer" element={<CreateCustomer />} />
            <Route exact path="/hr/setting/dimensions/customer/createCustomer/:editCustomerId" element={<CreateCustomer />} />

            {/* {/ Customer Segment /} */}
            <Route path="/hr/setting/dimensions/customerSegment" element={<CustomerSegmentList />} />
            <Route path="/hr/setting/dimensions/customerSegment/createCustomerSegment" element={<CreateCustomerSegment />} />
            <Route exact path="/hr/setting/dimensions/customerSegment/createCustomerSegment/:editCustomerSegmentId" element={<CreateCustomerSegment />} />


            {/* {/ Customer Category /} */}
            <Route path="/hr/setting/dimensions/customercategory" element={<CustomerCategoryList />} />
            <Route path="/hr/setting/dimensions/customerCategory/createCustomerCategory" element={<CreateCustomerCategory />} />
            <Route exact path="/hr/setting/dimensions/customerCategory/createCustomerCategory/:editCustomerCategoryId" element={<CreateCustomerCategory />} />

            {/* brand */}
            <Route path="/hr/setting/dimensions/brand" element={<Brands />} />
            <Route path="/hr/settings/dimensions/createbrand" element={<CreateBrand />} />
            <Route exact path="/hr/settings/dimensions/createbrand/:editBrandId" element={<CreateBrand />} />

            {/* {Country} */}
            <Route path="/hr/setting/dimensions/country" element={<HrCountry />} />
            <Route path="/hr/settings/dimensions/createcountry" element={<HrCountryList />} />
            <Route exact path="/hr/settings/dimensions/createcountry/:editcountryid" element={<HrCountryList />} />

            {/* {Vertical Type} */}
            <Route exact path="/hr/settings/dimensions/verticals" element={<Vertical />} />
            <Route exact path="/hr/settings/dimensions/createverticals" element={<CreateVertical />} />
            <Route exact path="/hr/settings/dimensions/createverticals/:editverticalid" element={<CreateVertical />} />

            {/* { Plant Storage Location } */}
            <Route exact path="/hr/settings/dimensions/plantstoragelocation" element={<PlantStorageLocations />} />
            <Route exact path="/hr/settings/dimensions/createplantstoragelocation" element={<CreatePlantStorageLocation />} />
            <Route exact path="/hr/settings/dimensions/createplantstoragelocation/:editplantstoragelocationid" element={<CreatePlantStorageLocation />} />

            {/* { Material } */}
            <Route exact path="/hr/settings/dimensions/material" element={<Material />} />
            <Route exact path="/hr/settings/dimensions/creatematerial" element={<CreateMaterial />} />
            <Route exact path="/hr/settings/dimensions/creatematerial/:editMaterailid" element={<CreateMaterial />} />

            {/* {Division} */}
            <Route exact path="/hr/settings/dimensions/division" element={<Divisions />} />
            <Route exact path="/hr/settings/dimensions/createdivision" element={<CreateDivisions />} />
            <Route exact path="/hr/settings/dimensions/createdivision/:editdivisionid" element={<CreateDivisions />} />

            {/* GTM*/}
            <Route exact path="/hr/settings/creategtm" element={<CreateGtm />} />
            <Route exact path="/hr/settings/gtm" element={<Gtm />} />
            <Route exact path="/hr/settings/creategtm/:gtmid" element={<CreateGtm />} />

            {/* Sub GTM*/}
            <Route exact path="/hr/settings/subgtm" element={<SubGtm />} />
            <Route exact path="/hr/settings/createsubgtm" element={<CreateSubGtm />} />
            <Route exact path="/hr/settings/createsubgtm/:subgtmid" element={<CreateSubGtm />} />

            {/* {Dashboard} */}
            <Route exact path="/employee/dashboard/business/planning" element={<BusinessPlanningDashboard />} />
            <Route exact path="/employee/dashboard/business/evaluation" element={<BusinessEvaluationDashboard />} />
            <Route exact path="/employee/dashboard/business/performance" element={<BusinessPerformanceDashboard />} />
            <Route exact path="/employee/dashboard/sales/planning" element={<SalesPlanningDashboard />} />
            <Route exact path="/employee/dashboard/sales/evaluation" element={<SalesEvaluationDashboard />} />
            <Route exact path="/employee/dashboard/sales/performance" element={<SalesPerformanceDashboard />} />
            <Route exact path="/employee/dashboard/business" element={<ReporteeBusiness />} />
            <Route exact path="/employee/dashboard/sales" element={<ReporteeSales />} />

            {/* Employee */}
            <Route exact path="/employee" element={<EmployeeLogin />} />
            <Route exact path="/employee/login" element={<EmployeeLoginForce />} />
            <Route exact path="/employee/landing" element={<EmployeeLanding />} />
            <Route exact path="/employee/profile" element={<EmployeeProfile />} />
            <Route exact path="/employee/parameters" element={<EmployeeParameters />} />
            <Route exact path="/employee/importparametersdetails" element={<ImportParametersDetail />} />
            <Route exact path="/employee/changepassword" element={<EmployeeChangePassword />} />
            <Route exact path="/employee/planning/team/managetarget" element={<EmployeePlanningTeamManageTarget />} />
            <Route exact path="/employee/planning/team/managetarget/annualtarget/:financialYearId/:parameterId/:team" element={<EmployeePlanningTeamManage_AnnualTarget />} />
            <Route exact path="/employee/planning/team/assigntarget" element={<EmployeePlanningTeamAssignTarget />} />
            <Route exact path="/employee/planning/team/assigntarget/annualtarget/:financialYearId/:parameterId" element={<EmployeePlanningAssignTarget_AnnualTarget />} />
            <Route exact path="/employee/planning/team/annualtarget" element={<EmployeePlanningTeamAnnualTarget />} />
            <Route exact path="/employee/planning/team/viewtarget" element={<EmployeePlanningTeamViewTarget />} />
            <Route exact path="/employee/planning/team/reviewcommit" element={<ManageCommit_Team />} />
            <Route exact path="/employee/planning/team/managechangerequest" element={<EmployeePlanningTeamManageChangeRequest />} />
            <Route exact path="/employee/planning/team/reviewcommit/annualtarget/:financialYearId/:parameterId" element={<ManageCommit_AnnualTarget />} />
            <Route exact path="/employee/planning/team/reviewcommit/annualtarget/planreview/:financialYearId/:parameterId/:employeeId/:targetId" element={<ManageCommit_AnnualTarget_PlanReview />} />

            <Route exact path="/employee/planning/team/managecommit" element={<EmployeeTeamManageCommit />} />
            <Route exact path="/employee/planning/team/managecommit/annualtarget/:financialYearId/:parameterId" element={<EmployeeTeamManageCommitAnnualTarget />} />

            <Route exact path="/employee/planning/self/manageplan" element={<EmployeePlanningSelfManagePlan />} />
            <Route exact path="/employee/planning/self/managechangerequest" element={<EmployeePlanningSelfManageChangeRequest />} />


            <Route exact path="/employee/viewnotification" element={<EmployeeViewNotification />} />

            {/* Employee change Request  */}
            <Route exact path="/employee/myself/changerequest" element={<MyselfChangeRequest />} />
            <Route exact path="/employee/myteam/changerequest" element={<MyTeamChangeRequest />} />


            <Route exact path="/employee/evaluation/team/currentyear" element={<EvaluationTeamCurrentYear />} />
            <Route exact path="/employee/evaluation/team/historical" element={<EvaluationTeamHistorical />} />
            <Route exact path="/employee/evaluation/team/currentyear/report" element={<EvaluationTeamCurrentYear_TeamReport />} />
            <Route exact path="/employee/evaluation/self/currentyear" element={<EvaluationSelfCurrentYear />} />
            <Route exact path="/employee/evaluation/self/currentyear/report" element={<EvaluationSelfCurrentYearReport />} />
            <Route exact path="/employee/evaluation/self/historical" element={<EvaluationSelfHistorical />} />


            <Route exact path="/employee/reports" element={<EmployeeReports />} />
            <Route exact path="/employee/generatereport/:financialYearId/:employeeId" element={<EmployeeGenerateReport />} />
            <Route exact path="/employee/generatereport/:reporteeFinancialYearId" element={<EmployeeGenerateReport />} />
            <Route exact path="/employee/message" element={<EmployeeMessage />} />

            <Route exact path="/employee/evaluation" element={<EmployeeEvaluation />} />
            <Route exact path="/employee/settings" element={<EmployeeSettings />} />


            {/* {/ Common /} */}
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<PageNotFound />} />



        </Routes>
    );
}
