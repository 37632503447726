import { useNavigate, useLocation } from 'react-router-dom'
import React from 'react';
import redingToneImg from '../../../assets/svg/redington_word.png'
import { reactLocalStorage } from 'reactjs-localstorage';


export default function LeftSideBar() {
    const navigateTo = useNavigate()

    //Active Class Name
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    async function logOut() {
        reactLocalStorage.clear();
        navigateTo('/hr');
        navigateTo(0);
    }

    return (
        // < !--Sidebar -- >
        <div>
            <div className="area"></div>


            <div className="flex flex-shrink-0 transition-all fixed left-wrapper z-10" id="main_menu ">
                {/* <!-- Left mini bar --> */}
                <nav aria-label="Options" className="z-20  py-0 h-screen top-0 h-screen bg-[#fff] dark:bg-[#2D2B29] sm:flex" id="menu_nav">
                    <div className="main-menu shadow-lg">
                        {/* <!-- Menu button --> */}
                        <ul>
                            <li className="mb-3"><button
                                type="button"
                                onClick={() => navigateTo('/hr/landing')}
                                className="focus:outline-none  menu">
                                <i className="icon home_icon "></i>
                                <span className="nav-text">
                                    Home
                                </span>
                            </button>
                            </li>

                            <li className={splitLocation[2] === "dashboard" ? "mb-3 active" : "mb-3"}><button
                                type="button"
                                onClick={() => navigateTo('/hr/dashboard/planning')}
                                className="focus:outline-none  menu">
                                <i className="icon dashboard_icon "></i>
                                <span className="nav-text">
                                    Dashboard
                                </span>
                            </button>
                            </li>

                            <li className={(splitLocation[2] === "manageparameters" || splitLocation[2] === "assignparameters") ? "mb-3 active" : "mb-3"} >
                                <button
                                    type="button"
                                    onClick={() => navigateTo('/hr/manageparameters')}
                                    className="focus:outline-none menu">
                                    <i className="icon parameter_icon "></i>
                                    <span className="nav-text">
                                        Parameter
                                    </span>
                                </button>
                            </li>

                            <li className={splitLocation[2] === "planning" ? "mb-3 active" : "mb-3"} >
                                <button
                                    type="button"
                                    onClick={() => {
                                        navigateTo('/hr/planning')
                                    }}
                                    className="focus:outline-none  menu">
                                    <i className="icon planing_icon "></i>
                                    <span className="nav-text">
                                        Goals Setting
                                    </span>
                                </button>
                            </li>
                            <li className={splitLocation[2] === "evaluation" ? "mb-3 active" : "mb-3"} >
                                <button
                                    type="button"
                                    onClick={() => navigateTo('/hr/evaluation')}
                                    className="focus:outline-none menu">
                                    <i className="icon task_square "></i>
                                    <span className="nav-text">
                                        Goals Evaluation
                                    </span>
                                </button>
                            </li>
                            <li className={splitLocation[2] === "changerequest" ? "mb-3 active" : "mb-3"} >
                                <button
                                    type="button"
                                    onClick={() => navigateTo('/hr/changerequest/planningperiod')}
                                    className="focus:outline-none menu">
                                    <i className="icon change_request_icon"></i>
                                    <span className="nav-text">
                                        Change Request
                                    </span>
                                </button>
                            </li>
                            <li className={splitLocation[2] === "message" ? "mb-3 active" : "mb-3"} >
                                <button
                                    type="button"
                                    onClick={() => navigateTo('/hr/message')}
                                    className="focus:outline-none menu">
                                    <i className="icon comment_icon"></i>
                                    <span className="nav-text">
                                        Messaging
                                    </span>
                                </button>
                            </li>
                            <li className={splitLocation[2] === "reports" ? "mb-3 active" : "mb-3"}><button
                                type="button"
                                onClick={() => navigateTo('/hr/reports/goalsetting/summary')}
                                className="focus:outline-none menu">
                                <i className="icon reports_icon"></i>
                                <span className="nav-text">
                                    Reports
                                </span>
                            </button>
                            </li>

                            {/* <li><button type="button"
                                onClick={() => {
                                    localStorage.setItem('tab', 1)
                                    navigateTo('/hr/settings')
                                }}
                                className="focus:outline-none p-2 menu">
                                <i className="flex flex-wrap icon settings_icon"></i>
                            </button>
                            </li> */}
                        </ul>
                        <ul className="logout">
                            <li className={(splitLocation[2] === "settings" || splitLocation[2] === "setting") ? "mb-3 active" : "mb-2"} >
                                <button onClick={() => {
                                    localStorage.setItem('selectedTab', 1)
                                    navigateTo('/hr/settings')
                                }} className="menu">
                                    <i className="icon support_icon"></i>
                                    <span className="nav-text">
                                        Settings
                                    </span>
                                </button>
                            </li>
                            <li className="mb-3">
                                <button
                                    onClick={logOut}
                                    className="menu">
                                    <i className="icon logout_icon"></i>
                                    <span className="nav-text">
                                        Logout
                                    </span>
                                </button>
                            </li>
                            <li>
                                <div className="menu">
                                    <i className="icon small_logo"></i>
                                    <span className="nav-text">
                                        <img className="" src={redingToneImg} alt="" />
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
                {/* <CustomizeFormPopup
                isOpen={customizeFormModal}
                onRequestClose={() => setCustomizeFormModal(!customizeFormModal)}
            /> */}
            </div>
        </div>
    )
}