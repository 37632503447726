
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppLogoWhite from '../../assets/images/logo.png'

export default function HRResetPassword() {
    // Get data(Email) which is passed through navigation
    const location = useLocation();

    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    let navigateTo = useNavigate();

    async function forgotPasswordSubmit (event){
        event.preventDefault();
        if (code === "") { toast.error('Enter code.'); return; }
        if (newPassword === "") { toast.error('Enter new password.'); return; }

        setPleaseWaitButton(true)
        Auth.forgotPasswordSubmit(location.state.email, code, newPassword)
            .then(data => {
                if (data) {
                    toast.success("Password reset successfully.")
                    setPleaseWaitButton(false)
                    navigateTo("/hr")
                }
            })
            .catch(err => {
                setPleaseWaitButton(false)
                toast.error(err)
            });
    }

    return (

        <div className="bg-body-blue flex items-center min-h-screen p-4 bg-gray-100 lg:justify-center">
            <div
                className="flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md"
            >
                <div
                    className="p-4 py-6 text-white md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly dark:bg-[#252423]"
                >
                    <div className="my-3 text-4xl font-bold tracking-wider text-center">
                        <img src={AppLogoWhite} />
                    </div>

                </div>
                <div className="py-10 px-5 bg-white md:flex-1 dark:bg-[#252423]">
                    <h3 className="my-4  mb-1 text-2xl font-semibold text-gray-700 dark:text-[#FFFFFF]">Create your password</h3>
                    <h6 className="font-normal text-gray-700 text-sm dark:text-[#FFFFFF]">Please check your email for verification code to create your password.</h6>

                    <form action="#" onSubmit={forgotPasswordSubmit}  className="flex flex-col space-y-5 mt-4">
                        <div className="flex flex-col space-y-1">
                            <label for="email" className="text-sm font-semibold text-gray-500 dark:text-[#FFFFFF]">Code</label>
                            <input
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                type="number"
                                className="text-[15px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                            />
                        </div>
                        <div className="flex flex-col space-y-1">
                            <div className="flex items-center justify-between">
                                <label for="password" className="text-sm font-semibold text-gray-500 dark:text-[#FFFFFF]">Create Password</label>
                            </div>
                            <input
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)} type="password"
                                id="required-password"
                                className="text-[15px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                            />
                        </div>

                        <div>
                            <button
                                type="submit" 
                                className="w-full px-4 py-3 mt-6 text-md font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
                            >
                                {showPleaseWaitButton ? 'Please wait...' : 'Submit'}
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}