import { Menu } from "@headlessui/react";
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "../../../src/App.css";
import popupHeaderImg from "../../assets/images/business-people-casual-meeting 1.png";
import profilepicture1 from "../../assets/images/Ellipse 68.png";
import profilepicture2 from "../../assets/images/Ellipse 69.png";
import EditButton from '../../components/common/buttons/EditButton';
import ViewButton from '../../components/common/buttons/ViewButton';
import LeftSideBar from '../../components/common/hr/leftnav.component';
import TopNavBar from '../../components/common/hr/topnav.component';
import { Auth, Storage } from 'aws-amplify';
import { ValidateHRRole } from '../../components/helper/validateRole';
import HRLogin from '../../setup/auth/hr.component';

export default function HrChangeRequest() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //state 
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    //list
    const [sampleData, setSampleData] = useState([]);

    //popup
    const [isShowCreatePopUp] = useState(0);
    const [isAssignPopUp, setAssignPopUp] = useState(0);

    //tab
    const [selectedTab, setSelectedTab] = useState(1);

    //data
    var demoData = [
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Olivia Rhye", "Role": "Manager", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Open", "Action": <div className="flex gap-8 ">
                <Link ><ViewButton /></Link>
                <Link onClick={() => {
                    // console.log("success")
                    setAssignPopUp(1)
                }}  ><EditButton /></Link>
            </div>
        },
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Phoenix Baker", "Role": "Sales Manager", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Closed", "Action": <div className="flex gap-8">
                <Link ><ViewButton /></Link>
            </div>
        },
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Lana Steiner", "Role": "Brand Manager", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Closed", "Action": <div className="flex gap-8">
                <Link><ViewButton /></Link>
            </div>
        },
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Region Manager", "Role": "Region Manager", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Closed", "Action": <div className="flex gap-8">
                <Link><ViewButton /></Link>
            </div>
        },
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Candice Wu", "Role": "Sales Executive", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Closed", "Action": <div className="flex gap-8">
                <Link><ViewButton /></Link>
            </div>
        },
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Natali Craig", "Role": "Sales Vice President", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Closed", "Action": <div className="flex gap-8">
                <Link><ViewButton /></Link>
            </div>
        },
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Drew Cano", "Role": "Marketing Manager", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Closed", "Action": <div className="flex gap-8">
                <Link><ViewButton /></Link>
            </div>
        },
    ]

    async function onLoad() {
        try {
            await Auth.currentSession();
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
        setSampleData(demoData);
    }, [isShowCreatePopUp]);


    return (
        isAuthenticated ? (
            <div className="pt-16">
                <TopNavBar />
                <LeftSideBar />
                <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Change Request</div>
                        </div>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link onClick={() => setSelectedTab(1)} className={selectedTab == 1 ? "active" : null}>Planning Period</Link></li>
                                    <li><Link onClick={() => setSelectedTab(2)} className={selectedTab == 2 ? "active" : null}>Evaluation Period</Link></li>
                                    <li><Link to="/hr/changerequest/overrideperiod" onClick={() => setSelectedTab(3)} className={selectedTab == 3 ? "active" : null}>Override Actuals</Link></li>
                                </ul>
                            </div>

                            <div id="selector" className={isAssignPopUp === 1 ? 'overlay' : ''}></div>
                            {
                                isAssignPopUp === 1 ?
                                    <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                                        <div >
                                            <img src={popupHeaderImg} alt="" style={{ width: "50vw" }} />
                                            <div className="absolute right-0 left-0 top-0 w-full text-white">
                                                <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                                    <div className="text-md"><h6 className='text-[white]'>Request</h6></div>
                                                    <div><Link onClick={() => setAssignPopUp(0)} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                                </div>

                                                <div className="px-6 mt-8">
                                                    <span>
                                                        <Menu as="div" className="relative inline-block text-left">
                                                            <div className="flex items-center align-center">
                                                                <Menu.Button className='flex items-center'>
                                                                </Menu.Button>
                                                            </div>
                                                        </Menu>
                                                        <div className="text-sm text-[white]">Request</div>
                                                        <div className="text-2xl">Courtney Head</div>
                                                    </span>
                                                </div>

                                            </div>
                                            <div className="bg-[#EEF8F4] my-6  text-sm p-4 rounded-lg dark:bg-[#252423] px-14">
                                                <div className="grid grid-cols-2 gap-3">
                                                    <div className="flex items-center">
                                                        <div className="text-xs text-[#98A2B3] min-w-[150px]">Requestor Name</div>
                                                        <div className="text-sm text-[#101828] dark:text-[#FFFF]">Darrel Steward</div>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <div className="text-xs text-[#98A2B3] min-w-[150px]    px-12">Employee ID</div>
                                                        <div className="text-sm text-[#101828] dark:text-[#FFFF]">APPID0001</div>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <div className="text-xs text-[#98A2B3] min-w-[150px] ">Reporting Manager</div>
                                                        <div className="text-sm text-[#101828] dark:text-[#FFFF]">Bryan Adams</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="flex justify-between planning_period px-11">
                                                <li>
                                                    <div className="text-sm text-[#667085] mb-3 min-w-[100px] text-center">Requested</div>
                                                    <div className="bg-[#029046] w-7 h-7 rounded-full text-white mx-auto flex justify-center items-center">1
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="text-sm text-[#667085] mb-3 min-w-[100px] text-center">Approved</div>
                                                    <div className="bg-[#029046] w-7 h-7 rounded-full text-white mx-auto flex justify-center items-center">2
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="text-sm text-[#667085] mb-3 min-w-[100px] text-center">HR Review/Action</div>
                                                    <div className="bg-[#B3DDC7] w-7 h-7 rounded-full text-white mx-auto flex justify-center items-center">3
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="text-sm mt-2  dark:text-[white] px-11">20th Nov 2022</div>
                                            <div className="rounded-lg mt-5 p-5 space-y-4 px-10">
                                                <div className="flex space-x-2">
                                                    <div className="w-20 mx-auto">
                                                        <img src={profilepicture1} className="rounded-full w-14 h-14 mx-auto" alt="" />
                                                    </div>
                                                    <div className="bg-[#F2F4F7] p-4 rounded-lg w-full dark:bg-[#252423] dark:text-[white]">
                                                        <div className="flex items-center justify-between ">
                                                            <div className="font-semibold text-md mb-2 ">Requestor Remarks, </div>
                                                            <div className="text-[#667085] text-xs">12 hrs ago</div>
                                                        </div>
                                                        <div className="text-sm ">Sample comment about the target value and quarter plan... Sample comment about
                                                            the target value and quarter plan... Sample comment about the target value and quarter plan...
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex space-x-2">
                                                    <div className="bg-[#F2F4F7] p-4 rounded-lg w-full dark:bg-[#252423] dark:text-[white]" >
                                                        <div className="flex items-center justify-between">
                                                            <div className="font-semibold text-md mb-2">Reporting Manager Remarks,</div>
                                                            <div className="text-[#667085] text-xs">5 mins ago</div>
                                                        </div>
                                                        <div className="text-sm">Sample comment about the target value and quarter plan... Sample comment about
                                                            the target value and quarter plan... Sample comment about the target value and quarter plan...
                                                        </div>
                                                    </div>
                                                    <div className="w-20">
                                                        <img src={profilepicture2} className="rounded-full w-14 h-14 mx-auto" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="text-md mt-2  dark:text-[white] px-7 font-semibold text-md mb-2">HR Admin Action</div>
                                            <div className="text-sm mt-1 text-[#344054] font-[Inter]  dark:text-[white] px-7 mb-5">Open/Extend Planning Period</div>
                                            <div className='flex grid flex-row flex-wrap flex-1  align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4 p-4 px-6'>
                                                <div className="relative">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        Start Date <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        </label></label>
                                                    <Calendar placeholder="End Date" className="mt-2" value={fromDate} onChange={(e) => setFromDate(e.value)} id="icon" showIcon />
                                                </div>
                                                <div className="relative">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        End Date <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        </label></label>
                                                    <Calendar placeholder="End Date" className="mt-2" value={toDate} onChange={(e) => setToDate(e.value)} id="icon" showIcon />
                                                </div>

                                            </div>
                                            <div className='px-8 mb-2'>
                                                <textarea
                                                    // value={definition}
                                                    rows="5"
                                                    // onChange={e => setDefinition(e.target.value)}
                                                    type="text" className="text-[16px] rounded-lg   flex-1  border border-[#C6CBD2] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]"

                                                    placeholder="Add Comment..." />
                                            </div>
                                            <div className="lg:relative left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                                                <div className="flex justify-end gap-3">
                                                    <div><Link onClick={() => setAssignPopUp(0)} className="bg-white 
                                                dark:text-[white] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                                    >Cancel</Link></div>
                                                    <div><Link
                                                        className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Approve</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }
                        </div>
                        <div className=" rounded-lg mt-5 ">
                            <DataTable paginator value={sampleData} responsiveLayout="scroll" style={{ width: '93vw' }}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                            >
                                <Column field="Request ID" header="Request ID" sortable filter filterPlaceholder="Search by Request ID"></Column>
                                <Column field="Employee ID" header="Employee ID" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                <Column field="Name" header="Name" sortable filter filterPlaceholder="Search by Name"></Column>
                                <Column field="Role" header="Role" sortable filter filterPlaceholder="Search by Role"></Column>
                                <Column field="vertical" header="Vertical" sortable filter filterPlaceholder="Search by Vertical"></Column>
                                <Column field="SBU" header="SBU" sortable filter filterPlaceholder="Search by SBU"></Column>
                                <Column field="Reporting Manager" header="Reporting Manager" sortable filter filterPlaceholder="Search by Reporting Manager"></Column>
                                <Column field='Date Submitted' header="Date Submitted" filter filterPlaceholder="Search by Submitted Date" ></Column>
                                <Column field='Date Approved' header="Date Approved" filter filterPlaceholder="Search by Approved Date"  ></Column>
                                <Column field='Status' header="Status" filter filterPlaceholder="Search by Status" ></Column>
                                <Column field='Action' header="Action" ></Column>
                            </DataTable>
                        </div>


                    </div>
                </div>
            </div>
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}