import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import capitalizeFirst from '../../../../../components/common/capitalizeFirst';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import { ConvertResponseForSelectWithRefCode } from '../../../../../components/helper/commonfunctions';
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import { BIUnit, Brand, SBU } from '../../../../../models';
import HRLogin from "../../../../../setup/auth/hr.component";
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';

export default function CreateBrand() {

    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [brand, setBrand] = useState("");
    const [isEditMode, setEditMode] = useState(false);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editBrandId } = useParams();
    const [sbuList, setSbuList] = useState([]);
    const [sbuName, setSBUName] = useState("");
    const [sbuId, setSbuId] = useState("");
    const [brandId, setBrandId] = useState("");
    const [refCode, SetRefCode] = useState("");

    const [buList, setBuList] = useState([]);
    const [buName, setBUName] = useState("");
    const [buId, setbuId] = useState("");





    let navigateTo = useNavigate();

    async function BindList() {
        //set Sbu List
        // var sbuList = await DataStore.query(SBU, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var sbuList = await graphQLGetAllData('listSBUS');
        sbuList = sbuList.sort((a, b) => a.name.localeCompare(b.name));
        var mySbuArray = []; mySbuArray = ConvertResponseForSelectWithRefCode(sbuList);
        setSbuList(mySbuArray)

        //set bu List
        // var buList = await DataStore.query(BIUnit, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        var buList = await graphQLGetAllData('listBIUnits');
        buList = buList.sort((a, b) => a.name.localeCompare(b.name));


        var mybuArray = []; mybuArray = ConvertResponseForSelectWithRefCode(buList);
        setBuList(mybuArray)
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }

            //Edit existing record
            if (editBrandId != null) {
                setBrandId(editBrandId);
                // const original = await DataStore.query(Brand, editBrandId);
                const original = await graphQLFindRecordById('getBrand', editBrandId);

                if (original != null) {
                    if (original.SBU_id) {
                        // const SBU_original = await DataStore.query(SBU, original.SBU_id);
                        const SBU_original = await graphQLFindRecordById('getSBU', original.SBU_id);
                        if (SBU_original) {
                            setSBUName({ label: SBU_original.name + " (" + SBU_original.REF_CODE + ")", value: SBU_original.id })
                            setSbuId(SBU_original.id)
                        }
                        else {
                            setSBUName({ label: "", value: "" })
                            setSbuId("")
                        }
                    } else {
                        setSBUName({ label: "", value: "" })
                        setSbuId("")
                    }

                    if (original.bu_id) {
                        // const BU_original = await DataStore.query(BIUnit, original.bu_id);
                        const BU_original = await graphQLFindRecordById('getBIUnit', original.bu_id);

                        if (BU_original) {
                            setBUName({ label: BU_original.name + " (" + BU_original.REF_CODE + ")", value: BU_original.id })
                            setbuId(BU_original.id)
                        }
                        else {
                            setBUName({ label: "", value: "" })
                            setSbuId("")
                        }
                    } else {
                        setBUName({ label: "", value: "" })
                        setSbuId("")
                    }
                    setBrand(original.name);
                    SetRefCode(original.REF_CODE)
                    setEditMode(true);
                }
                else {
                    toast.error('Invalid record.');
                    navigateTo("/hr/setting/dimensions/brand");
                }
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }
    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    const saveBrand = async () => {
        if (brand.trim() === "") { toast.error("Please enter Brand."); return; }
        else if (sbuId === "") { toast.error("Please select SBU."); return; }
        else if (buId === "") { toast.error("Please select BU."); return; }

        setPleaseWaitButton(true);
        if (isEditMode) {
            // const original = await DataStore.query(Brand, brandId);
            const original = await graphQLFindRecordById('getBrand', brandId);

            // await DataStore.save(
            //     Brand.copyOf(original, (updated) => {
            //         updated.name = brand !== null ? capitalizeFirst(brand) : "";
            //         updated.SBU_id = sbuId;
            //         updated.bu_id = buId;
            //         updated.REF_CODE = refCode;
            //     })
            // ).then((response=>{
            // }))

            await API.graphql({
                query: mutations.updateBrand,
                variables: {
                    input: {
                        id: original.id,
                        name: brand !== null ? capitalizeFirst(brand) : "",
                        SBU_id: sbuId,
                        bu_id: buId,
                        REF_CODE: refCode,
                        _version: original._version
                    }
                }
            });

            toast.success("Updated");
        } else {
            // await DataStore.save(new Brand({
            //     name: brand,
            //     SBU_id: sbuId,
            //     bu_id: buId,
            //     REF_CODE: capitalizeFirst(refCode)

            // }));

            await API.graphql(
                graphqlOperation(mutations.createBrand, {
                    input: {
                        name: brand,
                        SBU_id: sbuId,
                        bu_id: buId,
                        REF_CODE: capitalizeFirst(refCode)

                    }
                })
            );
            toast.success("Added");
        }
        setPleaseWaitButton(false);
        navigateTo("/hr/setting/dimensions/brand");
    };

    return (
        isAuthenticated ? (

            <div>
                <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full">
                    <TopNavBar />
                    <LeftSideBar />
                    <div className="pl-16">
                        <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                            <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                                <div className="p-3 py-5 bg-white rounded-md shadow  dark:bg-[#252423]">
                                    <div className="flex justify-center mt-3 mb-2">
                                        <h2 className="page-title mb-4  ">
                                            {isEditMode ? "Edit" : "Create"} Brand
                                        </h2>
                                    </div>
                                    <div x-data="{tab: 1}"></div>
                                    <div className={"block w-full"}>
                                        <div className="px-4" x-show="tab === 1">
                                            <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                                                <div className="relative mb-4 ">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium"
                                                    >
                                                        Name
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *{" "}
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(brand)}
                                                        onChange={(e) => setBrand(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]  dark:border-[#333231]  dark:bg-[#333231]"
                                                        placeholder="Enter Brand"
                                                    />
                                                </div>



                                                <div className="relative mb-4">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        SBUs<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                            * </label></label>
                                                    <Select placeholder='Select' options={sbuList} value={sbuName} onChange={e => {
                                                        setSBUName(e)
                                                        setSbuId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        Business Unit<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                            * </label></label>
                                                    <Select placeholder='Select' options={buList} value={buName} onChange={e => {
                                                        setBUName(e)
                                                        setbuId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Ref_Code
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >

                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(refCode)}
                                                        onChange={(e) => SetRefCode(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Ref Code"
                                                    />
                                                </div>
                                            </div>
                                            <div className="grow flex justify-center gap-3 mb-3">
                                                <Link
                                                    to="/hr/setting/dimensions/brand"
                                                    className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:border-[#333231] dark:text-white  dark:bg-[#4A5056] "
                                                >
                                                    Cancel
                                                </Link>
                                                <button
                                                    onClick={() => saveBrand()}
                                                    className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                                                >
                                                    {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}



