import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import LeftSideBar from '../../components/common/hr/leftnav.component';
import TopNavBar from '../../components/common/hr/topnav.component';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import HRLogin from '../../setup/auth/hr.component';
import { ValidateHRRole } from '../../components/helper/validateRole';

export default function HRChangePassword() {

    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    //*onClick Change Password Button
    async function changeAdminPassword(event) {
        event.preventDefault();
        if (oldPassword === "" || oldPassword === null) {
            toast.error('Enter old password.');
            return;
        } else if (newPassword === "" || newPassword === null) {
            toast.error('Enter new password.');
            return;
        }
        else if (confirmPassword === "" || confirmPassword === null) {
            toast.error('Enter confirm password.');
            return;
        }
        //Validate new and confirm password
        else if (newPassword !== confirmPassword) {
            toast.error('Confirm password must match with new password.');
            return;
        } else {
            setPleaseWaitButton(true);
            Auth.currentAuthenticatedUser()
                .then(user => {
                    setPleaseWaitButton(false);
                    return Auth.changePassword(user, oldPassword, newPassword);
                })
                .then(data => {
                    setPleaseWaitButton(false);
                    toast.success('Password updated successfully.'); setOldPassword(""); setNewPassword(""); setConfirmPassword("");
                })
                .catch(err => { setPleaseWaitButton(false); toast.error(err.message) });
        }
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        isAuthenticated ? (
            <div className="pt-16">
                <TopNavBar />
                <LeftSideBar />
                <div
                    className="flex flex-1 flex-wrap flex-row justify-center align-center grid 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 gap-4 pl-5 pr-5 contract-grid-wrapper mt-5 p-5 h-screen">

                    <div className="col-start-4 lg:col-start-4 xl:col-start-4 2xl:col-start-3 col-span-4">
                        <div className="bg-white dark:bg-[#232528] rounded-md shadow p-3 py-5 ">
                            <form action="#" onSubmit={changeAdminPassword} autoComplete="off">
                                <div className="flex justify-center mb-10  mt-3  mb-3">
                                    <h2 className="page-title">Change Password</h2>
                                </div>
                                <div className="px-4 mt-10">
                                    <div className="flex flex-1 flex-wrap flex-row align-center grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4  mb-6 ">
                                        <div className="mb-4 col-span-2">
                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                Old Password <label className="text-[#FF0000] text-[14px] font-medium">
                                                    * </label></label>
                                            <input type="password" onChange={e => setOldPassword(e.target.value)} className="text-[15px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:border-[#333231]  " required placeholder="Old Password" />
                                        </div>
                                        <div className="mb-4 col-span-2">
                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                New Password <label className="text-[#FF0000] text-[14px] font-medium ">
                                                    * </label></label>
                                            <input type="password" onChange={e => setNewPassword(e.target.value)} className="text-[15px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:border-[#333231] " required placeholder="New Password" />
                                        </div>
                                        <div className="mb-4 col-span-2">
                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                Confirm Password <label className="text-[#FF0000] text-[14px] font-medium ">
                                                    * </label></label>
                                            <input type="password" onChange={e => setConfirmPassword(e.target.value)} className="text-[16px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:border-[#333231]  " required placeholder="Confirm Password" />
                                        </div>
                                    </div>
                                    <div className="grow flex justify-center gap-3 mb-3">
                                        <Link to="/hr/dashboard/planning" className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#333231] dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-[#FFFFFF] ">Cancel</Link>
                                        <button type="submit" className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">{showPleaseWaitButton ? 'Please wait...' : 'Submit'}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )

}