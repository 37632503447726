import { DataStore } from '@aws-amplify/datastore';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import BoxPlanning from '../../../../assets/images/dashboard_box_planning.png';
import UserImage from '../../../../assets/images/user.png';
import LeftNav from '../../../../components/common/employee/leftnav.component';
import TopNav from '../../../../components/common/employee/topnav.component';
import NoDataFound from '../../../../components/common/nodatafound';
import ProgressBac_CircularProgressWithChildren from '../../../../components/common/progressbar/CircularProgressbarWithChildren';
import { ObjectToArray } from '../../../../components/helper/commonfunctions';
import { EVALUATION_STATUS } from '../../../../components/helper/enum';
import { EvaluationStatus } from '../../../../components/helper/getEvaluationStatus';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { Employee, Evaluation, FinancialYear, InitiateEvaluation } from "../../../../models";
import EmpLogin from "../../../../setup/auth/emp.component";
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../src/components/helper/graphQLFunctions'

export default function EvaluationTeamCurrentYear() {
    var loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId');

    const navigateTo = useNavigate()

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //list
    const [evaluationList, setEvaluationList] = useState([]);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    const bindList = async () => {
        //check Current Quarter Year
        let date = moment().format("MM-DD")
        let currentYear = moment().year()
        let previousYear = moment().year() - 1
        let nextYear = moment().year() + 1
        let FinalYearResult = ""
        if ("03-31" < date) {
            FinalYearResult = currentYear + "-" + nextYear
        }
        else {
            FinalYearResult = previousYear + "-" + currentYear
        }

        // var currentYearResponses = await DataStore.query(FinancialYear, (c) => c.name.eq(FinalYearResult));
        // console.log('currentYearResponses',currentYearResponses);
         var currentYearResponses = await graphQLGetAllData('listFinancialYears', [{ name: { eq: FinalYearResult } }]);
        // var evaluationResponses = await DataStore.query(Evaluation, (c) => c.and(c => [
        //     c.financial_year_id.eq(currentYearResponses[0].id),
        //     // c.id.eq(data["evaluation_id"]),
        // ]));

       var evaluationResponses = await graphQLGetAllData('listEvaluations', [{ financial_year_id: { eq: currentYearResponses[0].id } }]);

        if (evaluationResponses.length > 0) {
            var newResponse = [];
            for (var j = 0; j < evaluationResponses.length; j++) {
                let getStatus = EvaluationStatus(moment(evaluationResponses[j].startDate).format("YYYY-MM-DD"), moment(evaluationResponses[j].endDate).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))
               // var financialYearResponses = await DataStore.query(FinancialYear, evaluationResponses[j].financial_year_id);
                var financialYearResponses=  await graphQLFindRecordById('getFinancialYear', evaluationResponses[j].financial_year_id);

                let data = {
                    "financial_year_id": evaluationResponses[j]["financial_year_id"],
                    "financial_year_name": financialYearResponses.name,
                    "quarterName": "Quarter" + " " + evaluationResponses[j]["quaterNo"],
                    "quaterNo": evaluationResponses[j]["quaterNo"],
                    "startDate": evaluationResponses[j]["startDate"],
                    "endDate": evaluationResponses[j]["endDate"],
                    "status": getStatus
                }
                newResponse.push(data)

                if (j === evaluationResponses.length - 1) {
                    let sortArray = newResponse.sort((a, b) => (b.quaterNo > a.quaterNo ? 1 : -1))
                    console.log(sortArray)
                    setEvaluationList(sortArray)
                }
            }
        } else {
            setEvaluationList([])
        }
    }

    useEffect(() => {
        bindList()
        onLoad();
    }, []);


    const selectQuarter = (quarter) => {
        reactLocalStorage.set("selectedTabEvaluation", "Current Year")
        reactLocalStorage.setObject('selectedYear', { label: quarter.financial_year_name, value: quarter.financial_year_id });
        reactLocalStorage.setObject("selectedQuarter", { label: quarter.quarterName, value: quarter.quarterNo })
        navigateTo("/employee/evaluation/team/currentyear/report")
        navigateTo(0)
    }

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNav />
            <LeftNav />

            <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="sm:flex items-center space-x-8">
                        <div className="text-2xl font-semibold dark:text-white">Goals Evaluation</div>
                        <div className="tabs_one mt-5 sm:mt-0">
                            <ul className="flex">
                                <li><Link to="/employee/evaluation/team/currentyear" className='active' >My Team</Link></li>
                                <li><Link to="/employee/evaluation/self/currentyear"  >Myself</Link></li>
                            </ul>
                        </div>
                    </div>

                    <>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/employee/evaluation/team/currentyear" className='active'  >Current Year</Link></li>
                                    <li><Link to="/employee/evaluation/team/historical" >Historical</Link></li>
                                </ul>
                            </div>
                        </div>
                        {
                            evaluationList.length > 0 ?
                                <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3 gap-2">
                                    {
                                        evaluationList.map((item, index) => {
                                            return (
                                                item.status !== EVALUATION_STATUS[3] ?
                                                    <div key={index.toString()} className="bg-white py-4 rounded-xl dashbord_box_shape boxShadow boxH relative dark:border-[green] dark:text-white dark:bg-[#2A2E32]">
                                                        <div className="grid grid-cols-1">
                                                            <div className="px-4">
                                                                <div className="flex items-center space-x-3 text-[#667085] mb-2">
                                                                    <div className="text-xs border border-[#E4E7EC] rounded p-1 dark:text-[#667085] dark:border-[#667085]">FY: {item.financial_year_name}</div>

                                                                </div>
                                                                <div className="text-2xl font-semibold mb-1">{item.quarterName}</div>
                                                                <div className="text-[#344054] text-xs mb-5 dark:text-[#98A2B3]">Evaluation Period: {moment(item.startDate).format("Do MMM, YY") + " - " + moment(item.endDate).format("Do MMM, YY")}</div>
                                                                {
                                                                    EVALUATION_STATUS[1] === item.status ?
                                                                        <label className=" text-sm px-2 py-1.5 border rounded-md border-[#1B55AF] text-[#1B55AF] dark:border-[green] dark:text-white dark:bg-[#029046]">Status: {item.status}</label>
                                                                        :
                                                                        EVALUATION_STATUS[2] === item.status ?

                                                                            <label className=" text-sm px-2 py-1.5 border rounded-md border-[#FDB022] rounded-md text-[#FDB022]  dark:border-[green] dark:text-white dark:bg-[#029046]">Status: {item.status}</label> :
                                                                            <label className=" text-sm px-2 py-1.5 border rounded-md border-[#029046] text-[#029046] dark:border-[green] dark:text-white dark:bg-[#029046]">Status: {item.status}</label>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="px-4 absolute left-0 bottom-[30px]">
                                                            <div className="">
                                                                <ul className="flex gap-2 items-center">
                                                                    {
                                                                        EVALUATION_STATUS[2] !== item.status ?
                                                                            <li><button type='button' onClick={() => selectQuarter(item)} className="bg-[#029046] py-2.5 px-3 text-white text-[15px] rounded-md active addClass">View</button></li>
                                                                            : ""
                                                                    }
                                                                    {
                                                                        EVALUATION_STATUS[2] !== item.status && EVALUATION_STATUS[3] !== item.status ?
                                                                            <li><button type='button' onClick={() => selectQuarter(item)} className="bg-[#FFFFFF] border border-[#C6CBD2] py-2.5 px-3 text-black text-[15px] rounded-md active addClass dark:bg-[#44494E] dark:text-[#FFFFFF]">Edit</button></li>
                                                                            : ""
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="absolute top-0 right-0 bottom-0 flex items-center dashbord_box_img1">
                                                            <img src={BoxPlanning} alt="" />
                                                        </div>
                                                    </div> :
                                                    <div className="bg-white py-4 rounded-xl boxShadow boxH relative dark:border-[green] dark:text-white dark:bg-[#2A2E32]">
                                                        <div className="grid grid-cols-12">
                                                            <div className="col-span-7">
                                                                <div className="px-4">
                                                                    <div className="flex items-center space-x-3 text-[#667085] mb-2">
                                                                        <div className="text-xs border border-[#E4E7EC] rounded p-1 dark:text-[#667085] dark:border-[#667085]">FY: {item.financial_year_name}</div>
                                                                    </div>
                                                                    <div className="flex items-center gap-1">
                                                                        <div className="text-2xl font-semibold mb-1">{item.quarterName}</div>
                                                                    </div>
                                                                    <div className="text-[#344054] text-xs mb-5 dark:text-[#98A2B3]">Evaluation Period: {moment(item.startDate).format("Do MMM, YY") + " - " + moment(item.endDate).format("Do MMM, YY")}</div>
                                                                    <Link to="" className=" text-sm px-2 py-1.5 border rounded-md border-[#4FB155] text-[#4FB155] ">Status: {item.status}</Link>
                                                                </div>
                                                            </div>
                                                            {
                                                                item.status === EVALUATION_STATUS[3] &&
                                                                <div className="col-span-5 border-l border-[#E4E7EC]">
                                                                    <div className='mx-auto' style={{ width: 120, height: 120 }}>
                                                                        <ProgressBac_CircularProgressWithChildren value="100" text={EVALUATION_STATUS[3]} />
                                                                    </div>

                                                                    <div className="flex items-center gap-2 justify-center mt-3">
                                                                        <div>
                                                                            <ul className="flex tag_i">
                                                                                <li><img src={UserImage} alt="" /></li>
                                                                                <li><img src={UserImage} alt="" /></li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="text-xs text-[#667085]">{item.team_size} Employee</div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="p-4 mt-1">
                                                            <div className="bg-[#F2F4F7] rounded-lg px-4 py-2 mt-3 flex items-center justify-between dark:border-[green] dark:text-white dark:bg-[#212529]">
                                                                <button type='button' onClick={() => selectQuarter(item)} className="bg-[#fff] rounded border border-[#C6CBD2] p-3 py-2  text-sm text-[#344054] dark:border-[green] dark:text-white dark:bg-[#029046]">View</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                            )
                                        })
                                    }
                                </div>
                                :
                                <NoDataFound />
                        }

                    </>
                </div>
            </div>

        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}