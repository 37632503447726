/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNWCDaysUploadLogs = /* GraphQL */ `
  query GetNWCDaysUploadLogs($id: ID!) {
    getNWCDaysUploadLogs(id: $id) {
      id
      financial_year_id
      quarterNo
      last_Updated_Date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNWCDaysUploadLogs = /* GraphQL */ `
  query ListNWCDaysUploadLogs(
    $filter: ModelNWCDaysUploadLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNWCDaysUploadLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        financial_year_id
        quarterNo
        last_Updated_Date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNWCDaysUploadLogs = /* GraphQL */ `
  query SyncNWCDaysUploadLogs(
    $filter: ModelNWCDaysUploadLogsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNWCDaysUploadLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        financial_year_id
        quarterNo
        last_Updated_Date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubGTM = /* GraphQL */ `
  query GetSubGTM($id: ID!) {
    getSubGTM(id: $id) {
      id
      name
      REF_CODE
      gtm_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSubGTMS = /* GraphQL */ `
  query ListSubGTMS(
    $filter: ModelSubGTMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubGTMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        REF_CODE
        gtm_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSubGTMS = /* GraphQL */ `
  query SyncSubGTMS(
    $filter: ModelSubGTMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubGTMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        REF_CODE
        gtm_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getActualDetails = /* GraphQL */ `
  query GetActualDetails($id: ID!) {
    getActualDetails(id: $id) {
      id
      target_details_id
      financial_year_id
      monthNo
      parameter_id
      SBU
      BU
      region
      country
      brand
      productGroup
      profitCenter
      customerGroupCode
      customerCode
      actual_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listActualDetails = /* GraphQL */ `
  query ListActualDetails(
    $filter: ModelActualDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActualDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        target_details_id
        financial_year_id
        monthNo
        parameter_id
        SBU
        BU
        region
        country
        brand
        productGroup
        profitCenter
        customerGroupCode
        customerCode
        actual_value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncActualDetails = /* GraphQL */ `
  query SyncActualDetails(
    $filter: ModelActualDetailsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActualDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        target_details_id
        financial_year_id
        monthNo
        parameter_id
        SBU
        BU
        region
        country
        brand
        productGroup
        profitCenter
        customerGroupCode
        customerCode
        actual_value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGTM = /* GraphQL */ `
  query GetGTM($id: ID!) {
    getGTM(id: $id) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGTMS = /* GraphQL */ `
  query ListGTMS(
    $filter: ModelGTMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGTMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGTMS = /* GraphQL */ `
  query SyncGTMS(
    $filter: ModelGTMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGTMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getChangeRequestFiles = /* GraphQL */ `
  query GetChangeRequestFiles($id: ID!) {
    getChangeRequestFiles(id: $id) {
      id
      change_request_id
      upload_type
      fileName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChangeRequestFiles = /* GraphQL */ `
  query ListChangeRequestFiles(
    $filter: ModelChangeRequestFilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChangeRequestFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        change_request_id
        upload_type
        fileName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChangeRequestFiles = /* GraphQL */ `
  query SyncChangeRequestFiles(
    $filter: ModelChangeRequestFilesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChangeRequestFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        change_request_id
        upload_type
        fileName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMaterials = /* GraphQL */ `
  query GetMaterials($id: ID!) {
    getMaterials(id: $id) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMaterials = /* GraphQL */ `
  query ListMaterials(
    $filter: ModelMaterialsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaterials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMaterials = /* GraphQL */ `
  query SyncMaterials(
    $filter: ModelMaterialsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMaterials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomerSegment = /* GraphQL */ `
  query GetCustomerSegment($id: ID!) {
    getCustomerSegment(id: $id) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCustomerSegments = /* GraphQL */ `
  query ListCustomerSegments(
    $filter: ModelCustomerSegmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerSegments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomerSegments = /* GraphQL */ `
  query SyncCustomerSegments(
    $filter: ModelCustomerSegmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomerSegments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInitiateEvaluation = /* GraphQL */ `
  query GetInitiateEvaluation($id: ID!) {
    getInitiateEvaluation(id: $id) {
      id
      evaluation_id
      employee_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInitiateEvaluations = /* GraphQL */ `
  query ListInitiateEvaluations(
    $filter: ModelInitiateEvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitiateEvaluations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        evaluation_id
        employee_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInitiateEvaluations = /* GraphQL */ `
  query SyncInitiateEvaluations(
    $filter: ModelInitiateEvaluationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInitiateEvaluations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        evaluation_id
        employee_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInitiatePlanning = /* GraphQL */ `
  query GetInitiatePlanning($id: ID!) {
    getInitiatePlanning(id: $id) {
      id
      planning_id
      employee_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInitiatePlannings = /* GraphQL */ `
  query ListInitiatePlannings(
    $filter: ModelInitiatePlanningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitiatePlannings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        planning_id
        employee_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInitiatePlannings = /* GraphQL */ `
  query SyncInitiatePlannings(
    $filter: ModelInitiatePlanningFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInitiatePlannings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        planning_id
        employee_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmployeeParameters = /* GraphQL */ `
  query GetEmployeeParameters($id: ID!) {
    getEmployeeParameters(id: $id) {
      id
      employee_id
      company_id
      profile_center_id
      plant_storage_location_id
      division_id
      customer_group_id
      customer_id
      country_id
      region_id
      company_REF_CODE
      profit_center_REF_CODE
      division_REF_CODE
      customer_group_REF_CODE
      customer_REF_CODE
      country_REF_CODE
      region_REF_CODE
      plan_storage_location_REF_CODE
      sbu_REF_CODE
      customer_segment_REF_CODE
      product_group_REF_CODE
      brand_REF_CODE
      material_id
      material_REF_CODE
      bu_id
      bu_REF_CODE
      gtm_id
      gtm_REF_CODE
      sub_gtm_id
      sub_gtm_REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEmployeeParameters = /* GraphQL */ `
  query ListEmployeeParameters(
    $filter: ModelEmployeeParametersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeParameters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employee_id
        company_id
        profile_center_id
        plant_storage_location_id
        division_id
        customer_group_id
        customer_id
        country_id
        region_id
        company_REF_CODE
        profit_center_REF_CODE
        division_REF_CODE
        customer_group_REF_CODE
        customer_REF_CODE
        country_REF_CODE
        region_REF_CODE
        plan_storage_location_REF_CODE
        sbu_REF_CODE
        customer_segment_REF_CODE
        product_group_REF_CODE
        brand_REF_CODE
        material_id
        material_REF_CODE
        bu_id
        bu_REF_CODE
        gtm_id
        gtm_REF_CODE
        sub_gtm_id
        sub_gtm_REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEmployeeParameters = /* GraphQL */ `
  query SyncEmployeeParameters(
    $filter: ModelEmployeeParametersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmployeeParameters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        employee_id
        company_id
        profile_center_id
        plant_storage_location_id
        division_id
        customer_group_id
        customer_id
        country_id
        region_id
        company_REF_CODE
        profit_center_REF_CODE
        division_REF_CODE
        customer_group_REF_CODE
        customer_REF_CODE
        country_REF_CODE
        region_REF_CODE
        plan_storage_location_REF_CODE
        sbu_REF_CODE
        customer_segment_REF_CODE
        product_group_REF_CODE
        brand_REF_CODE
        material_id
        material_REF_CODE
        bu_id
        bu_REF_CODE
        gtm_id
        gtm_REF_CODE
        sub_gtm_id
        sub_gtm_REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCompanyActuals = /* GraphQL */ `
  query GetCompanyActuals($id: ID!) {
    getCompanyActuals(id: $id) {
      id
      financial_year_id
      quarterNo
      companyPerformance
      sbuPerformanceValue
      sbuPerformanceVolume
      sbuPerformanceTelco
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCompanyActuals = /* GraphQL */ `
  query ListCompanyActuals(
    $filter: ModelCompanyActualsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyActuals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        financial_year_id
        quarterNo
        companyPerformance
        sbuPerformanceValue
        sbuPerformanceVolume
        sbuPerformanceTelco
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCompanyActuals = /* GraphQL */ `
  query SyncCompanyActuals(
    $filter: ModelCompanyActualsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanyActuals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        financial_year_id
        quarterNo
        companyPerformance
        sbuPerformanceValue
        sbuPerformanceVolume
        sbuPerformanceTelco
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($id: ID!) {
    getNotifications(id: $id) {
      id
      senderId
      noticationDateTime
      notification
      notificationType
      receiverId
      isRead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        senderId
        noticationDateTime
        notification
        notificationType
        receiverId
        isRead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        senderId
        noticationDateTime
        notification
        notificationType
        receiverId
        isRead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFinanceActuals = /* GraphQL */ `
  query GetFinanceActuals($id: ID!) {
    getFinanceActuals(id: $id) {
      id
      financial_year_id
      sbu_id
      bu_id
      grossMargin
      badDebts
      inventoryProvision
      arProvision
      forexProvision
      totalProvision
      grossMarginAfterProvision
      opex
      forex
      ebdita
      depreciation
      ebit
      cashDiscountReceived
      interestCost
      pbt
      quarterNo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFinanceActuals = /* GraphQL */ `
  query ListFinanceActuals(
    $filter: ModelFinanceActualsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinanceActuals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        financial_year_id
        sbu_id
        bu_id
        grossMargin
        badDebts
        inventoryProvision
        arProvision
        forexProvision
        totalProvision
        grossMarginAfterProvision
        opex
        forex
        ebdita
        depreciation
        ebit
        cashDiscountReceived
        interestCost
        pbt
        quarterNo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFinanceActuals = /* GraphQL */ `
  query SyncFinanceActuals(
    $filter: ModelFinanceActualsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFinanceActuals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        financial_year_id
        sbu_id
        bu_id
        grossMargin
        badDebts
        inventoryProvision
        arProvision
        forexProvision
        totalProvision
        grossMarginAfterProvision
        opex
        forex
        ebdita
        depreciation
        ebit
        cashDiscountReceived
        interestCost
        pbt
        quarterNo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDivision = /* GraphQL */ `
  query GetDivision($id: ID!) {
    getDivision(id: $id) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDivisions = /* GraphQL */ `
  query ListDivisions(
    $filter: ModelDivisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDivisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDivisions = /* GraphQL */ `
  query SyncDivisions(
    $filter: ModelDivisionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDivisions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlantStorageLocation = /* GraphQL */ `
  query GetPlantStorageLocation($id: ID!) {
    getPlantStorageLocation(id: $id) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPlantStorageLocations = /* GraphQL */ `
  query ListPlantStorageLocations(
    $filter: ModelPlantStorageLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlantStorageLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPlantStorageLocations = /* GraphQL */ `
  query SyncPlantStorageLocations(
    $filter: ModelPlantStorageLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlantStorageLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProfitCenter = /* GraphQL */ `
  query GetProfitCenter($id: ID!) {
    getProfitCenter(id: $id) {
      id
      name
      REF_CODE
      product_group_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProfitCenters = /* GraphQL */ `
  query ListProfitCenters(
    $filter: ModelProfitCenterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfitCenters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        REF_CODE
        product_group_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProfitCenters = /* GraphQL */ `
  query SyncProfitCenters(
    $filter: ModelProfitCenterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProfitCenters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        REF_CODE
        product_group_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCompanies = /* GraphQL */ `
  query SyncCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getChangeRequestComment = /* GraphQL */ `
  query GetChangeRequestComment($id: ID!) {
    getChangeRequestComment(id: $id) {
      id
      changeRequestId
      receiver_id
      sender_id
      messageDateTime
      isRead
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChangeRequestComments = /* GraphQL */ `
  query ListChangeRequestComments(
    $filter: ModelChangeRequestCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChangeRequestComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        changeRequestId
        receiver_id
        sender_id
        messageDateTime
        isRead
        message
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChangeRequestComments = /* GraphQL */ `
  query SyncChangeRequestComments(
    $filter: ModelChangeRequestCommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChangeRequestComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        changeRequestId
        receiver_id
        sender_id
        messageDateTime
        isRead
        message
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getChangeRequest = /* GraphQL */ `
  query GetChangeRequest($id: ID!) {
    getChangeRequest(id: $id) {
      id
      requestedEmployeeId
      requestedDate
      requestType
      comments
      reportingManagerEmployeeId
      requestStatus
      hrRequestStatus
      financial_year_id
      requestedEmployeeRoleId
      reportingManagerApprovedDate
      hrManagerApprovedDate
      ExtendStartPeriod
      ExtendEndPeriod
      requestNo
      selectedQuarter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChangeRequests = /* GraphQL */ `
  query ListChangeRequests(
    $filter: ModelChangeRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChangeRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        requestedEmployeeId
        requestedDate
        requestType
        comments
        reportingManagerEmployeeId
        requestStatus
        hrRequestStatus
        financial_year_id
        requestedEmployeeRoleId
        reportingManagerApprovedDate
        hrManagerApprovedDate
        ExtendStartPeriod
        ExtendEndPeriod
        requestNo
        selectedQuarter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChangeRequests = /* GraphQL */ `
  query SyncChangeRequests(
    $filter: ModelChangeRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChangeRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        requestedEmployeeId
        requestedDate
        requestType
        comments
        reportingManagerEmployeeId
        requestStatus
        hrRequestStatus
        financial_year_id
        requestedEmployeeRoleId
        reportingManagerApprovedDate
        hrManagerApprovedDate
        ExtendStartPeriod
        ExtendEndPeriod
        requestNo
        selectedQuarter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVerticals = /* GraphQL */ `
  query GetVerticals($id: ID!) {
    getVerticals(id: $id) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listVerticals = /* GraphQL */ `
  query ListVerticals(
    $filter: ModelVerticalsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerticals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVerticals = /* GraphQL */ `
  query SyncVerticals(
    $filter: ModelVerticalsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVerticals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTargetMessage = /* GraphQL */ `
  query GetTargetMessage($id: ID!) {
    getTargetMessage(id: $id) {
      id
      target_detail_id
      sender_id
      receiver_id
      messageDateTime
      isRead
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTargetMessages = /* GraphQL */ `
  query ListTargetMessages(
    $filter: ModelTargetMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTargetMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        target_detail_id
        sender_id
        receiver_id
        messageDateTime
        isRead
        message
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTargetMessages = /* GraphQL */ `
  query SyncTargetMessages(
    $filter: ModelTargetMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTargetMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        target_detail_id
        sender_id
        receiver_id
        messageDateTime
        isRead
        message
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      target_detail_id
      sbu_id
      region_id
      country_id
      brand_id
      product_group_id
      customer_group_id
      customer_id
      target_value
      q1_target_value
      q2_target_value
      q3_target_value
      q4_target_value
      m1_target_value
      m2_target_value
      m3_target_value
      m4_target_value
      m5_target_value
      m6_target_value
      m7_target_value
      m8_target_value
      m9_target_value
      m10_target_value
      m11_target_value
      m12_target_value
      sub_REF_CODE
      region_REF_CODE
      country_REF_CODE
      brand_REF_CODE
      product_group_REF_CODE
      customer_group_REF_CODE
      customer_REF_CODE
      m1_plan_actual_value
      m2_plan_actual_value
      m3_plan_actual_value
      m4_plan_actual_value
      m5_plan_actual_value
      m6_plan_actual_value
      m7_plan_actual_value
      m8_plan_actual_value
      m9_plan_actual_value
      m10_plan_actual_value
      m11_plan_actual_value
      m12_plan_actual_value
      bu_id
      bu_REF_CODE
      gtm_Id
      sub_gtm_id
      gtm_REF_CODE
      sub_gtm_REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        target_detail_id
        sbu_id
        region_id
        country_id
        brand_id
        product_group_id
        customer_group_id
        customer_id
        target_value
        q1_target_value
        q2_target_value
        q3_target_value
        q4_target_value
        m1_target_value
        m2_target_value
        m3_target_value
        m4_target_value
        m5_target_value
        m6_target_value
        m7_target_value
        m8_target_value
        m9_target_value
        m10_target_value
        m11_target_value
        m12_target_value
        sub_REF_CODE
        region_REF_CODE
        country_REF_CODE
        brand_REF_CODE
        product_group_REF_CODE
        customer_group_REF_CODE
        customer_REF_CODE
        m1_plan_actual_value
        m2_plan_actual_value
        m3_plan_actual_value
        m4_plan_actual_value
        m5_plan_actual_value
        m6_plan_actual_value
        m7_plan_actual_value
        m8_plan_actual_value
        m9_plan_actual_value
        m10_plan_actual_value
        m11_plan_actual_value
        m12_plan_actual_value
        bu_id
        bu_REF_CODE
        gtm_Id
        sub_gtm_id
        gtm_REF_CODE
        sub_gtm_REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPlans = /* GraphQL */ `
  query SyncPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        target_detail_id
        sbu_id
        region_id
        country_id
        brand_id
        product_group_id
        customer_group_id
        customer_id
        target_value
        q1_target_value
        q2_target_value
        q3_target_value
        q4_target_value
        m1_target_value
        m2_target_value
        m3_target_value
        m4_target_value
        m5_target_value
        m6_target_value
        m7_target_value
        m8_target_value
        m9_target_value
        m10_target_value
        m11_target_value
        m12_target_value
        sub_REF_CODE
        region_REF_CODE
        country_REF_CODE
        brand_REF_CODE
        product_group_REF_CODE
        customer_group_REF_CODE
        customer_REF_CODE
        m1_plan_actual_value
        m2_plan_actual_value
        m3_plan_actual_value
        m4_plan_actual_value
        m5_plan_actual_value
        m6_plan_actual_value
        m7_plan_actual_value
        m8_plan_actual_value
        m9_plan_actual_value
        m10_plan_actual_value
        m11_plan_actual_value
        m12_plan_actual_value
        bu_id
        bu_REF_CODE
        gtm_Id
        sub_gtm_id
        gtm_REF_CODE
        sub_gtm_REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHRAssignTargetDetails = /* GraphQL */ `
  query GetHRAssignTargetDetails($id: ID!) {
    getHRAssignTargetDetails(id: $id) {
      id
      hrAssign_Target_id
      parameter_id
      targetValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHRAssignTargetDetails = /* GraphQL */ `
  query ListHRAssignTargetDetails(
    $filter: ModelHRAssignTargetDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHRAssignTargetDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hrAssign_Target_id
        parameter_id
        targetValue
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHRAssignTargetDetails = /* GraphQL */ `
  query SyncHRAssignTargetDetails(
    $filter: ModelHRAssignTargetDetailsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHRAssignTargetDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        hrAssign_Target_id
        parameter_id
        targetValue
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHRAssigTargets = /* GraphQL */ `
  query GetHRAssigTargets($id: ID!) {
    getHRAssigTargets(id: $id) {
      id
      financial_year_id
      employee_id
      isAssigned
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHRAssigTargets = /* GraphQL */ `
  query ListHRAssigTargets(
    $filter: ModelHRAssigTargetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHRAssigTargets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        financial_year_id
        employee_id
        isAssigned
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHRAssigTargets = /* GraphQL */ `
  query SyncHRAssigTargets(
    $filter: ModelHRAssigTargetsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHRAssigTargets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        financial_year_id
        employee_id
        isAssigned
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBIUnit = /* GraphQL */ `
  query GetBIUnit($id: ID!) {
    getBIUnit(id: $id) {
      id
      name
      product_group_id
      REF_CODE
      sbu_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBIUnits = /* GraphQL */ `
  query ListBIUnits(
    $filter: ModelBIUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBIUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        product_group_id
        REF_CODE
        sbu_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBIUnits = /* GraphQL */ `
  query SyncBIUnits(
    $filter: ModelBIUnitFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBIUnits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        product_group_id
        REF_CODE
        sbu_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFinancialParameterDetails = /* GraphQL */ `
  query GetFinancialParameterDetails($id: ID!) {
    getFinancialParameterDetails(id: $id) {
      id
      financial_parameter_id
      parameter_id
      parameter_defination
      parameter_criteria
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFinancialParameterDetails = /* GraphQL */ `
  query ListFinancialParameterDetails(
    $filter: ModelFinancialParameterDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinancialParameterDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        financial_parameter_id
        parameter_id
        parameter_defination
        parameter_criteria
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFinancialParameterDetails = /* GraphQL */ `
  query SyncFinancialParameterDetails(
    $filter: ModelFinancialParameterDetailsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFinancialParameterDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        financial_parameter_id
        parameter_id
        parameter_defination
        parameter_criteria
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEvaluation = /* GraphQL */ `
  query GetEvaluation($id: ID!) {
    getEvaluation(id: $id) {
      id
      financial_year_id
      quaterNo
      startDate
      endDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEvaluations = /* GraphQL */ `
  query ListEvaluations(
    $filter: ModelEvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvaluations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        financial_year_id
        quaterNo
        startDate
        endDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEvaluations = /* GraphQL */ `
  query SyncEvaluations(
    $filter: ModelEvaluationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvaluations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        financial_year_id
        quaterNo
        startDate
        endDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTargetDetails = /* GraphQL */ `
  query GetTargetDetails($id: ID!) {
    getTargetDetails(id: $id) {
      id
      target_id
      parameter_id
      target_value
      target_assigned_date
      is_accepted_by_employee
      revision_value
      is_revision_by_employee
      revision_by_employee_date
      is_approve
      approved_date
      accepted_by_employee_date
      isMarkAsComplete
      isMarkAsComplete_AssignTarget
      isSendForRevisionByReportingManage
      m1_actual_value
      m2_actual_value
      m3_actual_value
      m4_actual_value
      m5_actual_value
      m6_actual_value
      m7_actual_value
      m8_actual_value
      m9_actual_value
      m10_actual_value
      m11_actual_value
      m12_actual_value
      q1_target_value
      q2_target_value
      q3_target_value
      q4_target_value
      q1_achievement
      q2_achievement
      q3_achievement
      q4_achievement
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTargetDetails = /* GraphQL */ `
  query ListTargetDetails(
    $filter: ModelTargetDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTargetDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        target_id
        parameter_id
        target_value
        target_assigned_date
        is_accepted_by_employee
        revision_value
        is_revision_by_employee
        revision_by_employee_date
        is_approve
        approved_date
        accepted_by_employee_date
        isMarkAsComplete
        isMarkAsComplete_AssignTarget
        isSendForRevisionByReportingManage
        m1_actual_value
        m2_actual_value
        m3_actual_value
        m4_actual_value
        m5_actual_value
        m6_actual_value
        m7_actual_value
        m8_actual_value
        m9_actual_value
        m10_actual_value
        m11_actual_value
        m12_actual_value
        q1_target_value
        q2_target_value
        q3_target_value
        q4_target_value
        q1_achievement
        q2_achievement
        q3_achievement
        q4_achievement
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTargetDetails = /* GraphQL */ `
  query SyncTargetDetails(
    $filter: ModelTargetDetailsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTargetDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        target_id
        parameter_id
        target_value
        target_assigned_date
        is_accepted_by_employee
        revision_value
        is_revision_by_employee
        revision_by_employee_date
        is_approve
        approved_date
        accepted_by_employee_date
        isMarkAsComplete
        isMarkAsComplete_AssignTarget
        isSendForRevisionByReportingManage
        m1_actual_value
        m2_actual_value
        m3_actual_value
        m4_actual_value
        m5_actual_value
        m6_actual_value
        m7_actual_value
        m8_actual_value
        m9_actual_value
        m10_actual_value
        m11_actual_value
        m12_actual_value
        q1_target_value
        q2_target_value
        q3_target_value
        q4_target_value
        q1_achievement
        q2_achievement
        q3_achievement
        q4_achievement
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTargets = /* GraphQL */ `
  query GetTargets($id: ID!) {
    getTargets(id: $id) {
      id
      financial_year_id
      employee_id
      report_manager_employee_id
      role_id
      isAddedByHRAdmin
      isSubmittedByEmployee
      isAssignedToEmployee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTargets = /* GraphQL */ `
  query ListTargets(
    $filter: ModelTargetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTargets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        financial_year_id
        employee_id
        report_manager_employee_id
        role_id
        isAddedByHRAdmin
        isSubmittedByEmployee
        isAssignedToEmployee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTargets = /* GraphQL */ `
  query SyncTargets(
    $filter: ModelTargetsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTargets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        financial_year_id
        employee_id
        report_manager_employee_id
        role_id
        isAddedByHRAdmin
        isSubmittedByEmployee
        isAssignedToEmployee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlanning = /* GraphQL */ `
  query GetPlanning($id: ID!) {
    getPlanning(id: $id) {
      id
      financial_year_id
      planning_from_date
      planning_to_date
      is_completed
      last_Updated_Date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPlannings = /* GraphQL */ `
  query ListPlannings(
    $filter: ModelPlanningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlannings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        financial_year_id
        planning_from_date
        planning_to_date
        is_completed
        last_Updated_Date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPlannings = /* GraphQL */ `
  query SyncPlannings(
    $filter: ModelPlanningFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlannings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        financial_year_id
        planning_from_date
        planning_to_date
        is_completed
        last_Updated_Date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      role_id
      firstName
      lastName
      email
      parentEmployeeId
      address1
      address2
      phoneNo
      city
      zip
      state
      country
      employeeId
      profilePic
      isAssignDirectTarget
      param_sbu_id
      IsHRAccess
      IsFinanceAccess
      joining_date
      confirmation_date
      employee_class
      employee_type
      grade
      band
      lwd
      password
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role_id
        firstName
        lastName
        email
        parentEmployeeId
        address1
        address2
        phoneNo
        city
        zip
        state
        country
        employeeId
        profilePic
        isAssignDirectTarget
        param_sbu_id
        IsHRAccess
        IsFinanceAccess
        joining_date
        confirmation_date
        employee_class
        employee_type
        grade
        band
        lwd
        password
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEmployees = /* GraphQL */ `
  query SyncEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmployees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        role_id
        firstName
        lastName
        email
        parentEmployeeId
        address1
        address2
        phoneNo
        city
        zip
        state
        country
        employeeId
        profilePic
        isAssignDirectTarget
        param_sbu_id
        IsHRAccess
        IsFinanceAccess
        joining_date
        confirmation_date
        employee_class
        employee_type
        grade
        band
        lwd
        password
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRelationshipDetails = /* GraphQL */ `
  query GetRelationshipDetails($id: ID!) {
    getRelationshipDetails(id: $id) {
      id
      relationship_id
      parameter_id
      weightage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRelationshipDetails = /* GraphQL */ `
  query ListRelationshipDetails(
    $filter: ModelRelationshipDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRelationshipDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relationship_id
        parameter_id
        weightage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRelationshipDetails = /* GraphQL */ `
  query SyncRelationshipDetails(
    $filter: ModelRelationshipDetailsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRelationshipDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        relationship_id
        parameter_id
        weightage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRelationship = /* GraphQL */ `
  query GetRelationship($id: ID!) {
    getRelationship(id: $id) {
      id
      financial_year_id
      role_id
      isDeployed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRelationships = /* GraphQL */ `
  query ListRelationships(
    $filter: ModelRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRelationships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        financial_year_id
        role_id
        isDeployed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRelationships = /* GraphQL */ `
  query SyncRelationships(
    $filter: ModelRelationshipFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRelationships(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        financial_year_id
        role_id
        isDeployed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      customer_category_id
      country_id
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        customer_category_id
        country_id
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomers = /* GraphQL */ `
  query SyncCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        customer_category_id
        country_id
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomerCategory = /* GraphQL */ `
  query GetCustomerCategory($id: ID!) {
    getCustomerCategory(id: $id) {
      id
      name
      REF_CODE
      customer_segment_id
      gtm_i
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCustomerCategories = /* GraphQL */ `
  query ListCustomerCategories(
    $filter: ModelCustomerCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        REF_CODE
        customer_segment_id
        gtm_i
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomerCategories = /* GraphQL */ `
  query SyncCustomerCategories(
    $filter: ModelCustomerCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomerCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        REF_CODE
        customer_segment_id
        gtm_i
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      name
      region_id
      REF_CODE
      VAT
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCountries = /* GraphQL */ `
  query ListCountries(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        region_id
        REF_CODE
        VAT
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCountries = /* GraphQL */ `
  query SyncCountries(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCountries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        region_id
        REF_CODE
        VAT
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRegion = /* GraphQL */ `
  query GetRegion($id: ID!) {
    getRegion(id: $id) {
      id
      name
      SBU_id
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRegions = /* GraphQL */ `
  query ListRegions(
    $filter: ModelRegionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        SBU_id
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRegions = /* GraphQL */ `
  query SyncRegions(
    $filter: ModelRegionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRegions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        SBU_id
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProductGroup = /* GraphQL */ `
  query GetProductGroup($id: ID!) {
    getProductGroup(id: $id) {
      id
      name
      brand_id
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProductGroups = /* GraphQL */ `
  query ListProductGroups(
    $filter: ModelProductGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        brand_id
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProductGroups = /* GraphQL */ `
  query SyncProductGroups(
    $filter: ModelProductGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProductGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        brand_id
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      name
      SBU_id
      REF_CODE
      bu_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        SBU_id
        REF_CODE
        bu_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBrands = /* GraphQL */ `
  query SyncBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBrands(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        SBU_id
        REF_CODE
        bu_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSBU = /* GraphQL */ `
  query GetSBU($id: ID!) {
    getSBU(id: $id) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSBUS = /* GraphQL */ `
  query ListSBUS(
    $filter: ModelSBUFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSBUS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSBUS = /* GraphQL */ `
  query SyncSBUS(
    $filter: ModelSBUFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSBUS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRoles = /* GraphQL */ `
  query GetRoles($id: ID!) {
    getRoles(id: $id) {
      id
      name
      isReportee
      sortOrder
      teamType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRolesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        isReportee
        sortOrder
        teamType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRoles = /* GraphQL */ `
  query SyncRoles(
    $filter: ModelRolesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        isReportee
        sortOrder
        teamType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFinancialParameters = /* GraphQL */ `
  query GetFinancialParameters($id: ID!) {
    getFinancialParameters(id: $id) {
      id
      financial_year_id
      user_id
      isDeployed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFinancialParameters = /* GraphQL */ `
  query ListFinancialParameters(
    $filter: ModelFinancialParametersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinancialParameters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        financial_year_id
        user_id
        isDeployed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFinancialParameters = /* GraphQL */ `
  query SyncFinancialParameters(
    $filter: ModelFinancialParametersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFinancialParameters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        financial_year_id
        user_id
        isDeployed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getParameters = /* GraphQL */ `
  query GetParameters($id: ID!) {
    getParameters(id: $id) {
      id
      name
      definition
      criteria
      type
      base_type
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listParameters = /* GraphQL */ `
  query ListParameters(
    $filter: ModelParametersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParameters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        definition
        criteria
        type
        base_type
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncParameters = /* GraphQL */ `
  query SyncParameters(
    $filter: ModelParametersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncParameters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        definition
        criteria
        type
        base_type
        REF_CODE
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFinancialYear = /* GraphQL */ `
  query GetFinancialYear($id: ID!) {
    getFinancialYear(id: $id) {
      id
      fromDate
      toDate
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFinancialYears = /* GraphQL */ `
  query ListFinancialYears(
    $filter: ModelFinancialYearFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinancialYears(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fromDate
        toDate
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFinancialYears = /* GraphQL */ `
  query SyncFinancialYears(
    $filter: ModelFinancialYearFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFinancialYears(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        fromDate
        toDate
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
