import React, { useState, useEffect, } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import { Auth } from "aws-amplify";
import { ValidateHRRole } from '../../../../src/components/helper/validateRole';
import HRLogin from "../../../setup/auth/hr.component";
import LeftSideBar from '../../../../src/components/common/hr/leftnav.component';
import TopNavBar from '../../../../src/components/common/hr/topnav.component';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { FinancialYear, BIUnit, SBU, FinanceActuals } from '../../../models';
import { ConvertResponseForSelect } from '../../../components/helper/commonfunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries'
import * as mutations from '../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../src/components/helper/graphQLFunctions'


export const Create_EBIT_PBT = () => {

    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [isEditMode, setEditMode] = useState(false);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editFinancialActualId } = useParams();
    const [financeActualId, setFinanceActualId] = useState("");

    //list
    const [financialYearList, setFinancialYearList] = useState([]);
    const [sBUList, setSBUList] = useState([]);
    const [bUList, setBUList] = useState([]);
    const [quarterList, setQuarterList] = useState([
        {
            "label": "Q1",
            "value": "1"
        },
        {
            "label": "Q2",
            "value": "2"
        },
        {
            "label": "Q3",
            "value": "3"
        },
        {
            "label": "Q4",
            "value": "4"
        }

    ]);

    //state
    const [financialYearId, setFinancialYearId] = useState("");
    const [financialYearName, setFinancialYearName] = useState("");
    const [quarter, setQuarter] = useState("");
    const [quarterName, setQuarterName] = useState("");
    const [sBUId, setSBUId] = useState("");
    const [sBUName, setSBUName] = useState("");
    const [bUId, setBUId] = useState("");
    const [bUName, setBUName] = useState("");
    const [grossMargin, setGrossMargin] = useState("");
    const [badDebts, setBadDebts] = useState("");
    const [inventoryProvision, setInventoryProvision] = useState("");
    const [aRProvision, setARProvision] = useState("");
    const [forexProvision, setForexProvision] = useState("");
    const [totalProvision, setTotalProvision] = useState("");
    const [grossMarginAfterProvision, setGrossMarginAfterProvision] = useState("");
    const [opex, setOpex] = useState("");
    const [forex, setForex] = useState("");
    const [eBDITA, setEBDITA] = useState("");
    const [depreciation, setDepreciation] = useState("");
    const [eBIT, setEBIT] = useState("");
    const [cashDiscountRecent, setCashDiscountRecent] = useState("");
    const [interestCost, setInterestCost] = useState("");
    const [pBT, setPBT] = useState("");

    let navigateTo = useNavigate();

    //CalCulate Function
    async function CalculateTotals(event, number) {
        //get value
        var value = event.target.value
        //set local states
        var sumTotalProvision = 0
        var grossMarginAfterProvisionTotal = 0
        var EBDITATotal = 0
        var totalEBDIT = 0
        var eBITTotal = 0
        var pBTTotal = 0

        switch (number) {
            case 1:
                setGrossMargin(value)
                sumTotalProvision = Number(inventoryProvision) + Number(aRProvision) + Number(forexProvision)
                grossMarginAfterProvisionTotal = Number(value) - sumTotalProvision
                setTotalProvision(String(sumTotalProvision))
                setGrossMarginAfterProvision(String(grossMarginAfterProvisionTotal))
                EBDITATotal = Number(opex) + Number(forex)
                totalEBDIT = grossMarginAfterProvisionTotal - EBDITATotal
                setEBDITA(String(totalEBDIT))
                eBITTotal = totalEBDIT - Number(depreciation)
                setEBIT(String(eBITTotal))
                pBTTotal = eBITTotal + Number(cashDiscountRecent) - Number(interestCost)
                setPBT(String(pBTTotal))
                break;
            case 2:
                setBadDebts(value)
                break;
            case 3:
                setInventoryProvision(value)
                sumTotalProvision = Number(value) + Number(aRProvision) + Number(forexProvision)
                grossMarginAfterProvisionTotal = Number(grossMargin) - sumTotalProvision
                setTotalProvision(String(sumTotalProvision))
                setGrossMarginAfterProvision(String(grossMarginAfterProvisionTotal))
                EBDITATotal = Number(opex) + Number(forex)
                totalEBDIT = grossMarginAfterProvisionTotal - EBDITATotal
                setEBDITA(String(totalEBDIT))
                eBITTotal = totalEBDIT - Number(depreciation)
                setEBIT(String(eBITTotal))
                pBTTotal = eBITTotal + Number(cashDiscountRecent) - Number(interestCost)
                setPBT(String(pBTTotal))
                break;
            case 4:
                setARProvision(value)
                sumTotalProvision = Number(inventoryProvision) + Number(value) + Number(forexProvision)
                grossMarginAfterProvisionTotal = Number(grossMargin) - sumTotalProvision
                setTotalProvision(String(sumTotalProvision))
                setGrossMarginAfterProvision(String(grossMarginAfterProvisionTotal))
                EBDITATotal = Number(opex) + Number(forex)
                totalEBDIT = grossMarginAfterProvisionTotal - EBDITATotal
                setEBDITA(String(totalEBDIT))
                eBITTotal = totalEBDIT - Number(depreciation)
                setEBIT(String(eBITTotal))
                pBTTotal = eBITTotal + Number(cashDiscountRecent) - Number(interestCost)
                setPBT(String(pBTTotal))
                break;
            case 5:
                setForexProvision(value)
                sumTotalProvision = Number(inventoryProvision) + Number(aRProvision) + Number(value)
                grossMarginAfterProvisionTotal = Number(grossMargin) - sumTotalProvision
                setTotalProvision(String(sumTotalProvision))
                setGrossMarginAfterProvision(String(grossMarginAfterProvisionTotal))
                EBDITATotal = Number(opex) + Number(forex)
                totalEBDIT = grossMarginAfterProvisionTotal - EBDITATotal
                setEBDITA(String(totalEBDIT))
                eBITTotal = totalEBDIT - Number(depreciation)
                setEBIT(String(eBITTotal))
                pBTTotal = eBITTotal + Number(cashDiscountRecent) - Number(interestCost)
                setPBT(String(pBTTotal))
                break;
            case 7:
                setOpex(value)
                EBDITATotal = Number(value) + Number(forex)
                totalEBDIT = Number(grossMarginAfterProvision) - EBDITATotal
                setEBDITA(String(totalEBDIT))
                eBITTotal = totalEBDIT - Number(depreciation)
                setEBIT(String(eBITTotal))
                pBTTotal = eBITTotal + Number(cashDiscountRecent) - Number(interestCost)
                setPBT(String(pBTTotal))
                break;
            case 8:
                setForex(value)
                EBDITATotal = Number(opex) + Number(value)
                totalEBDIT = Number(grossMarginAfterProvision) - EBDITATotal
                setEBDITA(String(totalEBDIT))
                eBITTotal = totalEBDIT - Number(depreciation)
                setEBIT(String(eBITTotal))
                pBTTotal = eBITTotal + Number(cashDiscountRecent) - Number(interestCost)
                break;
            case 9:
                setDepreciation(value)
                eBITTotal = Number(eBDITA) - Number(value)
                setEBIT(String(eBITTotal))
                pBTTotal = eBITTotal + Number(cashDiscountRecent) - Number(interestCost)
                setPBT(String(pBTTotal))
                break;
            case 10:
                setCashDiscountRecent(value)
                pBTTotal = Number(eBIT) + Number(value) - Number(interestCost)
                setPBT(String(pBTTotal))
                break;
            case 11:
                setInterestCost(value)
                pBTTotal = Number(eBIT) + Number(cashDiscountRecent) - Number(value)
                setPBT(String(pBTTotal))
                break;
        }
    }

    const SaveFinanceActual = async () => {

        if (financialYearId === "") { toast.error("Please select Financial Year."); return; }
        else if (quarter === "") { toast.error("Please select Quarter."); return; }
        else if (sBUId === "") { toast.error("Please select SBU."); return; }
        else if (bUId === "") { toast.error("Please select BU."); return; }
        else if (grossMargin === "") { toast.error("Please enter Gross Margin."); return; }
        else if (badDebts === "") { toast.error("Please enter Bad Debts."); return; }
        else if (inventoryProvision === "") { toast.error("Please enter Inventory Provision."); return; }
        else if (aRProvision === "") { toast.error("Please enter AR Provision."); return; }
        else if (forexProvision === "") { toast.error("Please enter Forex Provision."); return; }
        else if (totalProvision === "") { toast.error("Please enter Total Provision."); return; }
        else if (grossMarginAfterProvision === "") { toast.error("Please enter Gross Margin After Provision."); return; }
        else if (opex === "") { toast.error("Please enter Opex."); return; }
        else if (forex === "") { toast.error("Please enter Forex."); return; }
        else if (eBDITA === "") { toast.error("Please enter EBDITA."); return; }
        else if (depreciation === "") { toast.error("Please enter Depreciation."); return; }
        else if (eBIT === "") { toast.error("Please enter EBIT."); return; }
        else if (cashDiscountRecent === "") { toast.error("Please enter Cash Discount Recent."); return; }
        else if (interestCost === "") { toast.error("Please enter Interest Cost."); return; }
        else if (pBT === "") { toast.error("Please enter PBT."); return; }

        setPleaseWaitButton(true);

        if (isEditMode) {
            // const original = await DataStore.query(FinanceActuals, financeActualId);
            const original = await graphQLFindRecordById('getFinanceActuals', financeActualId);

            // await DataStore.save(
            //     FinanceActuals.copyOf(original, (updated) => {
            //         updated.financial_year_id = financialYearId;
            //         updated.quarterNo = Number(quarter);
            //         updated.sbu_id = sBUId;
            //         updated.bu_id = bUId;
            //         updated.grossMargin = parseFloat(grossMargin);
            //         updated.badDebts = parseFloat(badDebts);
            //         updated.inventoryProvision = parseFloat(inventoryProvision);
            //         updated.arProvision = parseFloat(aRProvision);
            //         updated.forexProvision = parseFloat(forexProvision);
            //         updated.totalProvision = parseFloat(totalProvision);
            //         updated.grossMarginAfterProvision = parseFloat(grossMarginAfterProvision);
            //         updated.opex = parseFloat(opex);
            //         updated.forex = parseFloat(forex);
            //         updated.ebdita = parseFloat(eBDITA);
            //         updated.depreciation = parseFloat(depreciation);
            //         updated.ebit = parseFloat(eBIT);
            //         updated.cashDiscountReceived = parseFloat(cashDiscountRecent);
            //         updated.interestCost = parseFloat(interestCost);
            //         updated.pbt = parseFloat(pBT);
            //     })
            // );

            await API.graphql({
                query: mutations.updateFinanceActuals,
                variables: {
                    input: {
                        id: original.id,
                        financial_year_id: financialYearId,
                        quarterNo: Number(quarter),
                        sbu_id: sBUId,
                        bu_id: bUId,
                        grossMargin: parseFloat(grossMargin),
                        badDebts: parseFloat(badDebts),
                        inventoryProvision: parseFloat(inventoryProvision),
                        arProvision: parseFloat(aRProvision),
                        forexProvision: parseFloat(forexProvision),
                        totalProvision: parseFloat(totalProvision),
                        grossMarginAfterProvision: parseFloat(grossMarginAfterProvision),
                        opex: parseFloat(opex),
                        forex: parseFloat(forex),
                        ebdita: parseFloat(eBDITA),
                        depreciation: parseFloat(depreciation),
                        ebit: parseFloat(eBIT),
                        cashDiscountReceived: parseFloat(cashDiscountRecent),
                        interestCost: parseFloat(interestCost),
                        pbt: parseFloat(pBT),
                        _version: original._version,
                    }
                }
            });
            toast.success("Updated");
        }
        else {

            // await DataStore.save(
            //     new FinanceActuals({
            //         "financial_year_id": financialYearId,
            //         "quarterNo": Number(quarter),
            //         "sbu_id": sBUId,
            //         "bu_id": bUId,
            //         "grossMargin": parseFloat(grossMargin),
            //         "badDebts": parseFloat(badDebts),
            //         "inventoryProvision": parseFloat(inventoryProvision),
            //         "arProvision": parseFloat(aRProvision),
            //         "forexProvision": parseFloat(forexProvision),
            //         "totalProvision": parseFloat(totalProvision),
            //         "grossMarginAfterProvision": parseFloat(grossMarginAfterProvision),
            //         "opex": parseFloat(opex),
            //         "forex": parseFloat(forex),
            //         "ebdita": parseFloat(eBDITA),
            //         "depreciation": parseFloat(depreciation),
            //         "ebit": parseFloat(eBIT),
            //         "cashDiscountReceived": parseFloat(cashDiscountRecent),
            //         "interestCost": parseFloat(interestCost),
            //         "pbt": parseFloat(pBT)
            //     })
            // ).then((response) => { })
            await API.graphql(
                graphqlOperation(mutations.createFinanceActuals, {
                    input: {
                        financial_year_id: financialYearId,
                        quarterNo: Number(quarter),
                        sbu_id: sBUId,
                        bu_id: bUId,
                        grossMargin: parseFloat(grossMargin),
                        badDebts: parseFloat(badDebts),
                        inventoryProvision: parseFloat(inventoryProvision),
                        arProvision: parseFloat(aRProvision),
                        forexProvision: parseFloat(forexProvision),
                        totalProvision: parseFloat(totalProvision),
                        grossMarginAfterProvision: parseFloat(grossMarginAfterProvision),
                        opex: parseFloat(opex),
                        forex: parseFloat(forex),
                        ebdita: parseFloat(eBDITA),
                        depreciation: parseFloat(depreciation),
                        ebit: parseFloat(eBIT),
                        cashDiscountReceived: parseFloat(cashDiscountRecent),
                        interestCost: parseFloat(interestCost),
                        pbt: parseFloat(pBT)
                    }
                })
            );
            toast.success("Added");
        }
        setPleaseWaitButton(false);
        navigateTo("/hr/evaluation/ebitpbt");
    };

    async function BindList() {

        //get Financial Year List
        // var financialYearResponse = await DataStore.query(FinancialYear);
        var financialYearResponse = await graphQLGetAllData('listFinancialYears');
        let sortArray = financialYearResponse.sort((a, b) => (a.name > b.name ? 1 : -1))
        var myArray = []; myArray = ConvertResponseForSelect(sortArray); setFinancialYearList(myArray);

        //set SBU List
        // var sBUList = await DataStore.query(SBU, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var sBUList = await graphQLGetAllData('listSBUS')
        sBUList = sBUList.sort((a,b)=>a.name.localeCompare(b.name));

        var mySBUArray = []; mySBUArray = ConvertResponseForSelect(sBUList); setSBUList(mySBUArray)

        //set BU List
        // var bUList = await DataStore.query(BIUnit, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var bUList = await graphQLGetAllData('listBIUnits')
        bUList = bUList.sort((a,b)=>a.name.localeCompare(b.name));
        var myBUArray = []; myBUArray = ConvertResponseForSelect(bUList); setBUList(myBUArray)

    }

    async function loadExitingData() {

        if (editFinancialActualId != null) {
            setFinanceActualId(editFinancialActualId)
            // var financeActualResponse = await DataStore.query(FinanceActuals, editFinancialActualId);
            var financeActualResponse = await graphQLFindRecordById('getFinanceActuals', editFinancialActualId);

            if (financeActualResponse.financial_year_id) {
                // var financeYearResponse = await DataStore.query(FinancialYear, financeActualResponse.financial_year_id);
                var financeYearResponse = await graphQLFindRecordById('getFinancialYear', financeActualResponse.financial_year_id);

                if (financeYearResponse) {
                    setFinancialYearName({ "label": financeYearResponse.name, "value": financeYearResponse.id })
                    setFinancialYearId(financeActualResponse.financial_year_id)
                }
            }
            else {
                setFinancialYearName({ "label": "", "value": "" })
                setFinancialYearId("")

            }

            //set quarter
            if (financeActualResponse.quarterNo) {
                console.log(financeActualResponse.quarterNo)
                let filterValue = quarterList.filter((response) => String(financeActualResponse.quarterNo) === response.value)
                if (filterValue) {
                    setQuarterName({ "label": filterValue[0].label, "value": filterValue[0].value })
                    setQuarter(filterValue[0].value)
                }
            } else {
                setQuarterName({ "label": "", "value": "" })
                setQuarter("")
            }

            //set SBU
            if (financeActualResponse.sbu_id) {
                // var sBUResponse = await DataStore.query(SBU, financeActualResponse.sbu_id);
                var sBUResponse = await graphQLFindRecordById('getSBU', financeActualResponse.sbu_id);

                if (sBUResponse) {
                    setSBUName({ "label": sBUResponse.name, "value": sBUResponse.id })
                    setSBUId(financeActualResponse.sbu_id)
                }
            }
            else {
                setSBUName({ "label": "", "value": "" })
                setSBUId("")

            }

            //set BU
            if (financeActualResponse.bu_id) {
                // var bUResponse = await DataStore.query(BIUnit, financeActualResponse.bu_id);
                var bUResponse = await graphQLFindRecordById('getBIUnit', financeActualResponse.bu_id);

                if (bUResponse) {
                    setBUName({ "label": bUResponse.name, "value": bUResponse.id })
                    setBUId(financeActualResponse.bu_id)
                }
            }
            else {
                setBUName({ "label": "", "value": "" })
                setBUId("")

            }

            setGrossMargin(financeActualResponse.grossMargin)
            setBadDebts(financeActualResponse.badDebts)
            setInventoryProvision(financeActualResponse.inventoryProvision)
            setARProvision(financeActualResponse.arProvision)
            setForexProvision(financeActualResponse.forexProvision)
            setTotalProvision(financeActualResponse.totalProvision)
            setGrossMarginAfterProvision(financeActualResponse.grossMarginAfterProvision)
            setOpex(financeActualResponse.opex)
            setForex(financeActualResponse.forex)
            setEBDITA(financeActualResponse.ebdita)
            setDepreciation(financeActualResponse.depreciation)
            setEBIT(financeActualResponse.ebit)
            setCashDiscountRecent(financeActualResponse.cashDiscountReceived)
            setInterestCost(financeActualResponse.interestCost)
            setPBT(financeActualResponse.pbt)
            setEditMode(true)
        }
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }

        } catch (e) {
            userHasAuthenticated(false);
        }
    }
    useEffect(() => {
        onLoad();
        BindList();
        loadExitingData();
    }, []);



    return (
        isAuthenticated ? (

            <div>
                <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full">
                    <TopNavBar />
                    <LeftSideBar />
                    <div className="pl-16">
                        <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                            <div className="col-span-4 col-start-5 lg:col-start-4 xl:col-start-4 2xl:col-start-3">
                                <div className="p-3 py-5 bg-white rounded-md shadow  dark:bg-[#252423]">
                                    <div className="flex justify-center mt-3 mb-2">
                                        <h2 className="page-title mb-4  ">
                                            {isEditMode ? "Edit" : "Create"} EBIT/PBT/Bad Debts Actuals
                                        </h2>
                                    </div>
                                    <div x-data="{tab: 1}"></div>
                                    <div className={"block w-full"}>
                                        <div className="px-4" x-show="tab === 1">
                                            <div className="flex grid flex-row flex-wrap flex-1 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                                                <div className="relative mb-4">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        Financial Year<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                            * </label></label>
                                                    <Select placeholder='Select' options={financialYearList} value={financialYearName} onChange={e => {
                                                        setFinancialYearName(e)
                                                        setFinancialYearId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        Quarter <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                            * </label></label>
                                                    <Select placeholder='Select' options={quarterList} value={quarterName} onChange={e => {
                                                        setQuarterName(e)
                                                        setQuarter(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        SBU <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                            * </label></label>
                                                    <Select placeholder='Select' options={sBUList} value={sBUName} onChange={e => {
                                                        setSBUName(e)
                                                        setSBUId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        Business Unit <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                            * </label></label>
                                                    <Select placeholder='Select' options={bUList} value={bUName} onChange={e => {
                                                        setBUName(e)
                                                        setBUId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>



                                                <div className="relative mb-4 col-span-2">
                                                    <div className='block'>
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                        >
                                                            Bad Debts($)
                                                            <span
                                                                htmlFor=""
                                                                className="text-[#FF0000] text-[14px] font-medium "
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <input
                                                        value={(badDebts)}
                                                        onChange={(e) => {
                                                            CalculateTotals(e, 2)
                                                        }}
                                                        type="number"

                                                        className="text-[16px] rounded-lg w-56   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter Bad Debits"
                                                    />
                                                </div>

                                                <div className="relative mb-4 col-span-2">
                                                    <div className='block'>
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                        >
                                                            Gross Margin($)
                                                            <span
                                                                htmlFor=""
                                                                className="text-[#FF0000] text-[14px] font-medium "
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <input
                                                        value={(grossMargin)}
                                                        onChange={(e) => {
                                                            CalculateTotals(e, 1)
                                                        }}
                                                        type="number"

                                                        className="text-[16px] rounded-lg w-56  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter Gross Margin"
                                                    />
                                                </div>



                                            </div>

                                            <div className="flex grid flex-row flex-wrap flex-1 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                                                <div className="relative mb-4 ">
                                                    <label htmlFor="" className="text-[#344054] text-[18px] font-medium ">
                                                        Provision</label>
                                                </div>
                                            </div>

                                            <div className="flex grid flex-row flex-wrap flex-1  align-center 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-x-4">
                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Inventory Provision($)
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(inventoryProvision)}
                                                        onChange={(e) => {
                                                            CalculateTotals(e, 3)
                                                        }}
                                                        type="number"

                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter Inventory Provision"
                                                    />
                                                </div>


                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        AR Provision($)
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(aRProvision)}
                                                        onChange={(e) => {
                                                            CalculateTotals(e, 4)
                                                        }}
                                                        type="number"

                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter AR Provision"
                                                    />
                                                </div>
                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Forex Provision($)
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(forexProvision)}
                                                        onChange={(e) => {
                                                            CalculateTotals(e, 5)
                                                        }}
                                                        type="number"

                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter Forex Provision"
                                                    />

                                                </div>
                                            </div>

                                            <div className="flex grid flex-row flex-wrap flex-1  align-center 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-x-4">

                                                <div className="relative mb-8 ">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Total Provision($)
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >

                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(totalProvision)}
                                                        type="text" disabled
                                                        className="text-[16px]  bg-[#f9f9f9] disabled rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    />
                                                </div>

                                                <div className="relative mb-4 ">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Gross Margin After Provision($)
                                                    </label>
                                                    <input
                                                        value={(grossMarginAfterProvision)}
                                                        type="text" disabled
                                                        className="text-[16px] bg-[#f9f9f9] disabled rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    />
                                                </div>


                                            </div>

                                            <hr />

                                            <div className="flex grid flex-row flex-wrap flex-1 mt-8 align-center 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-x-4">
                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Opex($)(-)
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(opex)}
                                                        onChange={(e) => {
                                                            CalculateTotals(e, 7)
                                                        }}
                                                        type="number"

                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter Opex"
                                                    />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Forex($)(-)
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(forex)}
                                                        onChange={(e) => {
                                                            CalculateTotals(e, 8)
                                                        }}
                                                        type="number"

                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter Forex"
                                                    />
                                                </div>

                                                <div className="relative mb-4 ">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        EBDITA($)
                                                    </label>

                                                    <input
                                                        value={(eBDITA)}
                                                        type="text" disabled
                                                        className="text-[16px] bg-[#f9f9f9] disabled rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    />

                                                </div>
                                            </div>

                                            <div className="flex grid flex-row flex-wrap flex-1 align-center 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-x-4">

                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Depreciation($)(-)
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(depreciation)}
                                                        onChange={(e) => {
                                                            CalculateTotals(e, 9)
                                                        }}
                                                        type="number"

                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter Depreciation"
                                                    />
                                                </div>

                                                <div className="relative mb-4 ">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        EBIT($)
                                                    </label>


                                                    <input
                                                        value={(eBIT)}
                                                        type="text" disabled
                                                        className="text-[16px] bg-[#f9f9f9] disabled rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex grid flex-row flex-wrap flex-1  align-center 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-x-4">
                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Cash Discount Received($)
                                                    </label>
                                                    <input
                                                        value={(cashDiscountRecent)}
                                                        onChange={(e) => {
                                                            CalculateTotals(e, 10)
                                                        }}
                                                        type="number"

                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter Cash Discount Received"
                                                    />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Interest Cost($)(-)
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(interestCost)}
                                                        onChange={(e) => {
                                                            CalculateTotals(e, 11)
                                                        }}
                                                        type="number"

                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter Interest Cost"
                                                    />
                                                </div>

                                                <div className="relative mb-4 ">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        PBT($)
                                                    </label>


                                                    <input
                                                        value={pBT}
                                                        type="text" disabled
                                                        className="text-[16px] bg-[#f9f9f9] disabled rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    />
                                                </div>
                                            </div>


                                            <div className="grow flex justify-center gap-3 mb-3 mt-2">
                                                <Link
                                                    to="/hr/evaluation/ebitpbt"
                                                    className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:border-[#333231] dark:text-white  dark:bg-[#4A5056] "
                                                >
                                                    Cancel
                                                </Link>
                                                <button

                                                    onClick={() => SaveFinanceActual()}
                                                    className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                                                >
                                                    {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}



