import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Menu } from "@headlessui/react";
import { Avatar } from "@mui/material";
import { Storage } from 'aws-amplify';
import moment from "moment";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import { reactLocalStorage } from 'reactjs-localstorage';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import "../../../../../src/App.css";
import noCommentsImg from '../../../../assets/images/noComment.png';
import selfPopular from '../../../../assets/images/selfpopupimg.png';
import EditButton from '../../../../components/common/buttons/EditButton';
import ViewButton from '../../../../components/common/buttons/ViewButton';
import LeftNav from '../../../../components/common/employee/leftnav.component';
import TopNav from '../../../../components/common/employee/topnav.component';
import { GetAWSObjectURL, UploadFileOnS3, deleteFileFromS3 } from '../../../../components/helper/aws';
import { ConvertResponseForSelect } from '../../../../components/helper/commonfunctions';
import { downloadFile } from '../../../../components/helper/downloadFile';
import { CHANGE_REQUEST_STATUS, CHANGE_REQUEST_TYPES, EMAIL_SUBJECTS, HR_CHANGE_REQUEST_STATUS } from "../../../../components/helper/enum";
import { sendNotification } from '../../../../components/helper/notification';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { ChangeRequest, ChangeRequestComment, ChangeRequestFiles, Employee, FinancialYear, Targets } from "../../../../models";
import EmpLogin from "../../../../setup/auth/emp.component";
import { API, graphqlOperation } from 'aws-amplify';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../components/helper/graphQLFunctions';
import * as mutations from '../../../../graphql/mutations';

const MySelfChangeRequest = () => {

    var quarterList = [
        { label: "Select", value: "0" },
        { label: "Quarter 1", value: "1" },
        { label: "Quarter 2", value: "2" },
        { label: "Quarter 3", value: "3" },
        { label: "Quarter 4", value: "4" },
    ]

    // states 
    const [myRequestList, setMyRequestList] = useState([]);
    const [renderItems, setRenderItems] = useState(false);

    const [financialYearList, setFinancialYearList] = useState([]);
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [isViewPopUp, setViewPopUp] = useState(0);
    const [viewChangeRequestPopUp, setViewChangeRequestPopUp] = useState(0);
    const [financialYear, setFinancialYear] = useState("");
    const [quarter, setQuarter] = useState({ label: "Select", value: "0" });


    const [isDisabled, setDisabled] = useState(false);
    //set logged Employee Details
    const [profilePic, setProfilePic] = useState("");
    const [employeeName, setEmployeeName] = useState("");
    const [reportingManagerName, setReportingManagerName] = useState("");
    const [reportingManagerEmail, setReportingManagerNameEmail] = useState("");
    const [reportingManagerId, setReportingManagerId] = useState("");
    const [employeeCode, setEmployeeCode] = useState("");
    const [requestComment, setRequestComment] = useState("");
    const [requestedDate, setRequestedDate] = useState("");
    const [requestId, setRequestId] = useState("");
    const [comment, setComment] = useState("");
    const [typeName, setTypeName] = useState('');
    const [isButtonDisable, setIsButtonDisable] = useState(false);

    //checkboxes
    const [isCheckedPlanningPeriod, setCheckedPlanningPeriod] = useState(false);
    const [isCheckedOverrideActualPeriod, setCheckedOverrideActualPeriod] = useState(false);
    const [requestType, setRequestType] = useState("");

    //get Local Storage data
    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
    let isReportingManager = reactLocalStorage.get('isReportingManager')
    let selectedYear = reactLocalStorage.getObject('selectedYear');
    var loggedEmployeeProfilePic = reactLocalStorage.get('loggedEmployeeProfilePic');
    var loggedEmployeeIsAssignByHR = reactLocalStorage.get("loggedEmployeeIsAssignByHR");

    //Messaging
    const [messageList, setMessageList] = useState([]);
    const [hRAndRMChat, setHRAndRMChat] = useState([]);
    const [reporteeAndRMChat, setReporteeAndRMChat] = useState([]);
    const [receiverEmployeeId, setReceiverEmployeeId] = useState('');
    const [message, setMessage] = useState('');
    const [receiverName, setReceiverName] = useState('');
    const [receiverProfile, setReceiverProfile] = useState('');
    const [commentsData, setCommentsData] = useStateWithCallbackLazy({ changeRequestId: "", requestedEmployeeId: "", reportingManagerEmployeeId: "" });

    //document
    const [docList, setDocList] = useState([]);
    const [docFinalList, setDocFinalList] = useState([]);

    //select Documents
    async function selectDocuments(e) {
        let file = e.target.files;
        if (docList.length > 0) {
            let fileData = [...docList]
            for (let i = 0; i < file.length; i++) {
                let data = {
                    "documentId": requestId,
                    "file": file[i],
                    "filename": 'public/changerequest/' + requestId + '/reportee/' + file[i].filename,
                    "fileUrl": ""
                }
                fileData.push(data)
                if (i === file.length - 1) {
                    setDocFinalList(fileData)
                    setDocList(fileData)
                }
            }
        }
        else {
            setDocList([])
            if (file.length > 1) {
                for (let i = 0; i < file.length; i++) {
                    let data = {
                        "documentId": requestId,
                        "file": file[i],
                        "filename": file[i].name,
                        "fileUrl": ""
                    }
                    docFinalList.push(data);
                }
            }
            else {
                for (let i = 0; i < file.length; i++) {
                    let data = {
                        "documentId": requestId,
                        "file": file[i],
                        "filename": file[i].name,
                        "fileUrl": ""
                    }
                    docFinalList.push(data);
                }
            }
            let finalData = [...docFinalList]
            setDocList(finalData)
        }

    }

    //Remove Document from array
    const onClickRemoveDocuments = (index) => {
        let data = [...docFinalList];
        data.splice(index, 1)
        setDocFinalList(data)
        setDocList(data)
    }

    const bindList = async () => {
        setProfilePic(loggedEmployeeProfilePic)

        // var employeeDetail = await DataStore.query(Employee, loggedEmployeeId);
        var employeeDetail = await graphQLFindRecordById('getEmployee', loggedEmployeeId);
        setEmployeeName(employeeDetail.firstName + " " + employeeDetail.lastName)
        setEmployeeCode(employeeDetail.employeeId)
        if (employeeDetail.parentEmployeeId) {
            setReportingManagerId(employeeDetail.parentEmployeeId)
            // var employeeDetail = await DataStore.query(Employee, employeeDetail.parentEmployeeId);
            var employeeDetail = await graphQLFindRecordById('getEmployee', employeeDetail.parentEmployeeId);
            setReportingManagerName(employeeDetail.firstName + " " + employeeDetail.lastName)
            setReportingManagerNameEmail(employeeDetail.email)
        }

        if (Object.keys(selectedYear).length !== 0) {
            setFinancialYear(selectedYear)
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);
            setFinancialYear({ label: responses[0].name, value: responses[0].id })
        }
        //get financial year
        // var responses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var responses = await graphQLGetAllData('listFinancialYears')
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));
        var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYearList(myArray);
        setRenderItems(true)



    }

    //get my request
    const myRequests = async (financialYear) => {
        //check button enable and disable
        // var targetResponses = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.employee_id.eq(loggedEmployeeId),
        //     c.financial_year_id.eq(financialYear.value)
        // ]));

        var targetResponses = [];
        try {
            targetResponses = await graphQLGetAllData('listTargets', [
                { employee_id: { eq: loggedEmployeeId } },
                { financial_year_id: { eq: financialYear.value } },
            ]);
        } catch (error) {
            console.log('error', error);
        }

        if (targetResponses.length > 0) { setIsButtonDisable(true); }
        else { setIsButtonDisable(false); }

        //get logged user request
        // var responses = await DataStore.query(ChangeRequest, (c) => c.and(c => [
        //     c.requestedEmployeeId.eq(loggedEmployeeId),
        //     c.financial_year_id.eq(financialYear.value)
        // ]));

        var responses = [];

        try {
            responses = await graphQLGetAllData('listChangeRequests', [
                { requestedEmployeeId: { eq: loggedEmployeeId } },
                { financial_year_id: { eq: financialYear.value } }
            ]);
        } catch (error) {
            console.log('error', error);
        }

        let newResponse = [];
        if (responses.length > 0) {
            for (let i = 0; i < responses.length; i++) {
                let data = {
                    "id": responses[i].id, "requestedEmployeeId": responses[i].requestedEmployeeId, "requestedDate": responses[i].requestedDate,
                    "requestType": responses[i].requestType,
                    "requestTypeName": responses[i].requestType === "OA" ? "Override Actuals" : "Planning Period",
                    "comments": responses[i].comments, "reportingManagerEmployeeId": responses[i].reportingManagerEmployeeId, "requestStatus": responses[i].requestStatus,
                    "hrRequestStatus": responses[i].hrRequestStatus, "financial_year_id": responses[i].financial_year_id, "requestedEmployeeRoleId": responses[i].requestedEmployeeRoleId, "reportingManagerApprovedDate": responses[i].reportingManagerApprovedDate,
                    "hrManagerApprovedDate": responses[i].hrManagerApprovedDate, "ExtendStartPeriod": responses[i].ExtendStartPeriod, "ExtendEndPeriod": responses[i].ExtendEndPeriod, "requestNo": responses[i].requestNo,
                    "selectedQuarter": responses[i].selectedQuarter
                }
                newResponse.push(data)

                if (i === responses.length - 1) {
                    setMyRequestList(newResponse)
                }
            }
        } else {
            setMyRequestList([])
        }
    }

    const clearAll = () => {
        setViewChangeRequestPopUp(0);
        setViewPopUp(0);
        setRequestType("")
        setCheckedOverrideActualPeriod(false)
        setCheckedPlanningPeriod(false)
        myRequests(selectedYear)
        setMessage("")
        setComment("")
        setDocList([])
        setDocFinalList([])
        setQuarter({ label: "Select", value: "0" });
    }


    const actionsOnRequest = (rowData) => {
        return (
            <React.Fragment >
                <div className="flex gap-8 justify-center ">
                    <Link onClick={() => { setDisabled(false); openRequest(rowData); setTypeName("View") }}><ViewButton /></Link>
                    {
                        // rowData.requestStatus !== CHANGE_REQUEST_STATUS.REPORTING_MANAGER ?
                        <Link onClick={() => { setDisabled(true); openRequest(rowData); setTypeName("Edit") }}  ><EditButton /></Link>
                        // :
                        // <Link className="opacity-50" ><EditButton /></Link>
                    }
                </div>

            </React.Fragment>
        );
    }


    const openRequest = async (requestDetails) => {
        setQuarter({ label: "Quarter " + requestDetails.selectedQuarter, "value": requestDetails.selectedQuarter })
        setDocFinalList([])
        setDocList([])
        setRequestId(requestDetails.id)
        setReceiverEmployeeId(requestDetails.reportingManagerEmployeeId)
        setRequestedDate(requestDetails.requestedDate)
        if (requestDetails.requestType === CHANGE_REQUEST_TYPES.PlanningPeriod) {
            setCheckedPlanningPeriod(true)
            setRequestType(CHANGE_REQUEST_TYPES.PlanningPeriod)
        } else if (requestDetails.requestType === CHANGE_REQUEST_TYPES.OverrideActual) {
            setCheckedOverrideActualPeriod(true)
            setRequestType(CHANGE_REQUEST_TYPES.OverrideActual)
        }
        setRequestComment(requestDetails.comments)
        //get comments data
        setCommentsData({ changeRequestId: requestDetails.id, requestedEmployeeId: requestDetails.requestedEmployeeId, reportingManagerEmployeeId: requestDetails.reportingManagerEmployeeId }, (chatData) => {
            if (chatData.changeRequestId !== "" && chatData.requestedEmployeeId !== "" && chatData.reportingManagerEmployeeId !== "") {
                loadMessages(chatData)
            }
        });

        //get documents
        // var ChangeRequestFilesArray = await DataStore.query(ChangeRequestFiles,
        //     (c) => c.and(c => [
        //         c.change_request_id.eq(requestDetails.id),
        //         c.upload_type.eq("R")
        //     ]));

        console.log(' requestDetails.id', requestDetails.id);
        var ChangeRequestFilesArray = [];
        try {
            ChangeRequestFilesArray = await graphQLGetAllData(' listChangeRequestFiles', [
                { change_request_id: { eq: requestDetails.id.toString() } },
                { upload_type: { eq: "R" } }
            ]);
        } catch (e) {
            console.log('e', e);
        }


        if (ChangeRequestFilesArray.length > 0) {
            for (let i = 0; i < ChangeRequestFilesArray.length; i++) {
                let getSignedUrl = await GetAWSObjectURL(ChangeRequestFilesArray[i].fileName)
                let data = {
                    "documentId": requestDetails.id,
                    "file": getSignedUrl,
                    "filename": ChangeRequestFilesArray[i].fileName.split("/").pop(),
                    "fileUrl": getSignedUrl
                }
                docFinalList.push(data);
                if (i === ChangeRequestFilesArray.length - 1) {
                    let finalData = [...docFinalList]
                    setDocList(finalData)
                }
            }
        } else {
            setDocList([])
        }
        setViewPopUp(1)
    }



    //load msg list
    const loadMessages = async (chatData) => {
        // var ChangeRequestMessageResponses = await DataStore.query(ChangeRequestComment, (c) => c.changeRequestId.eq(chatData.changeRequestId), {
        //     sort: s => s.messageDateTime(SortDirection.ASCENDING)
        // })

        var ChangeRequestMessageResponses = await graphQLGetAllData('listChangeRequestComments', [{ changeRequestId: { eq: chatData.changeRequestId } }]);
        ChangeRequestMessageResponses = ChangeRequestMessageResponses.sort((a, b) => a.messageDateTime.localeCompare(b.messageDateTime));

        if (ChangeRequestMessageResponses.length > 0) {
            let reportee_And_RM_Response = []
            let hR_And_RM_Response = []
            for (var i = 0; i < ChangeRequestMessageResponses.length; i++) {

                let senderProfilePic = ""
                let receiverProfilePic = ""

                // var senderResponse = await DataStore.query(Employee, ChangeRequestMessageResponses[i].sender_id);
                // var receiverResponse = await DataStore.query(Employee, ChangeRequestMessageResponses[i].receiver_id);
                var senderResponse = await graphQLFindRecordById('getEmployee', ChangeRequestMessageResponses[i].sender_id);

                var receiverResponse = await graphQLFindRecordById('getEmployee', ChangeRequestMessageResponses[i].receiver_id);

                if (senderResponse.profilePic) {
                    await Storage.get(senderResponse.profilePic).then(result => { senderProfilePic = result });
                }

                if (receiverResponse.profilePic) {
                    await Storage.get(receiverResponse.profilePic).then(result => { receiverProfilePic = result });
                }

                if ((ChangeRequestMessageResponses[i].sender_id === chatData.requestedEmployeeId && ChangeRequestMessageResponses[i].receiver_id === chatData.reportingManagerEmployeeId) ||
                    (ChangeRequestMessageResponses[i].sender_id === chatData.reportingManagerEmployeeId && ChangeRequestMessageResponses[i].receiver_id === chatData.requestedEmployeeId)) {
                    let data = {
                        "id": ChangeRequestMessageResponses[i].id,
                        "sender_id": ChangeRequestMessageResponses[i].sender_id,
                        "receiver_id": ChangeRequestMessageResponses[i].receiver_id,
                        "sender_Name": senderResponse.firstName + " " + senderResponse.lastName,
                        "receiver_Name": receiverResponse.firstName + " " + receiverResponse.lastName,
                        "sender_Profile": senderProfilePic,
                        "receiver_Profile": receiverProfilePic,
                        "changeRequestId": ChangeRequestMessageResponses[i].changeRequestId,
                        "messageDateTime": ChangeRequestMessageResponses[i].messageDateTime,
                        "isRead": ChangeRequestMessageResponses[i].isRead,
                        "message": ChangeRequestMessageResponses[i].message,
                        "hr_Id": "333d0615-8cef-4d64-a2be-093c00d2f006",
                        "rM_Id": chatData.reportingManagerEmployeeId,
                        "reportee_Id": chatData.requestedEmployeeId
                    }
                    reportee_And_RM_Response.push(data)
                }
                else if ((ChangeRequestMessageResponses[i].sender_id === "333d0615-8cef-4d64-a2be-093c00d2f006" && ChangeRequestMessageResponses[i].receiver_id === chatData.reportingManagerEmployeeId) ||
                    (ChangeRequestMessageResponses[i].sender_id === chatData.reportingManagerEmployeeId && ChangeRequestMessageResponses[i].receiver_id === "333d0615-8cef-4d64-a2be-093c00d2f006")) {
                    let data = {
                        "id": ChangeRequestMessageResponses[i].id,
                        "sender_id": ChangeRequestMessageResponses[i].sender_id,
                        "receiver_id": ChangeRequestMessageResponses[i].receiver_id,
                        "sender_Name": senderResponse.firstName + " " + senderResponse.lastName,
                        "receiver_Name": receiverResponse.firstName + " " + receiverResponse.lastName,
                        "sender_Profile": senderProfilePic,
                        "receiver_Profile": receiverProfilePic,
                        "changeRequestId": ChangeRequestMessageResponses[i].changeRequestId,
                        "messageDateTime": ChangeRequestMessageResponses[i].messageDateTime,
                        "isRead": ChangeRequestMessageResponses[i].isRead,
                        "message": ChangeRequestMessageResponses[i].message,
                        "hr_Id": "333d0615-8cef-4d64-a2be-093c00d2f006",
                        "rM_Id": chatData.reportingManagerEmployeeId,
                        "reportee_Id": chatData.requestedEmployeeId
                    }
                    hR_And_RM_Response.push(data)
                }

                if (i === ChangeRequestMessageResponses.length - 1) {
                    setReporteeAndRMChat(reportee_And_RM_Response)
                    setHRAndRMChat(hR_And_RM_Response)
                    setRenderItems(true)
                }
            }
        } else {
            setReporteeAndRMChat([])
            setHRAndRMChat([])
            setRenderItems(true)
        }
    }


    //TABLES  - Show data 
    const requestedDateFormat = (row) => {
        return (
            <React.Fragment >
                <label>{moment(row.requestedDate).format("Do MMM, YYYY")}</label>
            </React.Fragment>
        );
    }

    //send Msg to reportee
    const sendMessage = async () => {
        // Check Blank - message
        if (message === "") {
            toast.error('Please Enter Message.');
            return;
        }
        // await DataStore.save(
        //     new ChangeRequestComment({
        //         changeRequestId: requestId,
        //         receiver_id: receiverEmployeeId,
        //         sender_id: loggedEmployeeId,
        //         message: message,
        //         messageDateTime: moment().toDate().toISOString(),
        //     })
        // ).then(() => { })
        await API.graphql(
            graphqlOperation(mutations.createChangeRequestComment, {
                input: {
                    changeRequestId: requestId,
                    receiver_id: receiverEmployeeId,
                    sender_id: loggedEmployeeId,
                    message: message,
                    messageDateTime: moment().toDate().toISOString(),
                }
            })
        );
        loadMessages(commentsData)
        setMessage("")

    }


    async function SendMessage() {
        // Check Blank - message
        if (message === "") {
            toast.error('Please Enter Message.');
            return;
        }
        // await DataStore.save(
        //     new ChangeRequestComment({
        //         "changeRequestId": requestId,
        //         "receiver_id": "333d0615-8cef-4d64-a2be-093c00d2f006",
        //         "sender_id": loggedEmployeeId,
        //         "messageDateTime": moment().toDate().toISOString(),
        //         "isRead": "0",
        //         "message": message
        //     })
        // );
        await API.graphql(
            graphqlOperation(mutations.createChangeRequestComment, {
                input: {
                    changeRequestId: requestId,
                    receiver_id: "333d0615-8cef-4d64-a2be-093c00d2f006",
                    sender_id: loggedEmployeeId,
                    messageDateTime: moment().toDate().toISOString(),
                    isRead: 0,
                    message: message,
                }
            })
        );
        loadMessages(commentsData)
        setMessage("")

    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
        bindList();
        myRequests(selectedYear)
    }, []);


    //Checkbox functionality Planning Period
    const setPlanningPeriod = (e) => {
        setRenderItems(false)
        if (e.target.checked) {
            setRequestType(CHANGE_REQUEST_TYPES.PlanningPeriod)
            setCheckedOverrideActualPeriod(false)
            setCheckedPlanningPeriod(true)
        } else {
            setCheckedPlanningPeriod(false)
        }
        setRenderItems(true)
    }


    //Checkbox functionality Override Actual
    const setOverrideActual = (e) => {
        setRenderItems(false)
        if (e.target.checked) {
            setRequestType(CHANGE_REQUEST_TYPES.OverrideActual)
            setCheckedPlanningPeriod(false)
            setCheckedOverrideActualPeriod(true)
        } else {
            setCheckedOverrideActualPeriod(false)
        }
        setRenderItems(true)
    }

    //submit request
    const submitRequest = async () => {
        //Req_01092023_[Count]
        let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
        let loggedEmployeeRoleId = reactLocalStorage.get('loggedEmployeeRoleId')
        // let changeRequestList = await DataStore.query(ChangeRequest);
        let changeRequestList = await graphQLGetAllData('listChangeRequests')

        let count = changeRequestList.length + 1

        if (isCheckedPlanningPeriod || (isCheckedOverrideActualPeriod && quarter.value !== "0")) {
            // await DataStore.save(
            //     new ChangeRequest({
            //         requestNo: "Req_" + moment().format("MMDDYYYY_") + count,
            //         financial_year_id: financialYear.value,
            //         requestedEmployeeId: loggedEmployeeId,
            //         reportingManagerEmployeeId: reportingManagerId,
            //         requestedEmployeeRoleId: loggedEmployeeRoleId,
            //         requestedDate: moment().toDate().toISOString(),
            //         requestType: requestType,
            //         requestStatus: CHANGE_REQUEST_STATUS.REPORTEE,
            //         comments: comment,
            //         selectedQuarter: quarter.value
            //     })
            // ).then(async (e) => {})

            let returnData = await API.graphql(
                graphqlOperation(mutations.createChangeRequest, {
                    input: {
                        requestNo: "Req_" + moment().format("MMDDYYYY_") + count,
                        financial_year_id: financialYear.value,
                        requestedEmployeeId: loggedEmployeeId,
                        reportingManagerEmployeeId: reportingManagerId,
                        requestedEmployeeRoleId: loggedEmployeeRoleId,
                        requestedDate: moment().toDate().toISOString(),
                        requestType: requestType,
                        requestStatus: CHANGE_REQUEST_STATUS.REPORTEE,
                        comments: comment,
                        selectedQuarter: quarter.value
                    }
                })
            );
            returnData = returnData.data.createChangeRequest;
            if (returnData.id) {
                if (loggedEmployeeIsAssignByHR === "true") {
                    // const original = await DataStore.query(ChangeRequest, returnData.id);
                    const original = await graphQLFindRecordById('getChangeRequest', returnData.id);

                    // await DataStore.save(
                    //     ChangeRequest.copyOf(original, (updated) => {
                    //         updated.reportingManagerApprovedDate = moment().format('YYYY-MM-DD');
                    //         updated.requestStatus = CHANGE_REQUEST_STATUS.REPORTING_MANAGER;
                    //     })
                    // ).then((response) => {
                    // })

                    await API.graphql({
                        query: mutations.updateChangeRequest,
                        variables: {
                            input: {
                                id: original.id,
                                reportingManagerApprovedDate: moment().format('YYYY-MM-DD'),
                                requestStatus: CHANGE_REQUEST_STATUS.REPORTING_MANAGER,
                                _version: original._version,
                            }
                        }
                    });
                }
                toast.success("Saved.")
                myRequests(selectedYear);
                let requestTypeName = requestType === "PP" ? "Planning Period" : "Override Actual"
                let emailSubject = EMAIL_SUBJECTS.ChangeRequest
                let notificationMsg = employeeName + " (" + employeeCode + ") " + "has been requested for " + requestTypeName + " " + financialYear.label
                await sendNotification(loggedEmployeeId, reportingManagerId, requestType, notificationMsg, emailSubject);
                clearAll()
            }

        } else {
            toast.error("Select type or quarter.")
        }
    }

    const setEmail = async () => {
        await sendNotification();
    }

    const updateRequest = async () => {
        // var original = await DataStore.query(ChangeRequest, requestId)
        var original = await graphQLFindRecordById('getChangeRequest', requestId);
        if (isCheckedPlanningPeriod || isCheckedOverrideActualPeriod) {
            // await DataStore.save(ChangeRequest.copyOf(original, item => {
            //     item.requestType = requestType;
            //     item.selectedQuarter = quarter.value
            // })).then(() => { })
            await API.graphql({
                query: mutations.updateChangeRequest,
                variables: {
                    input: {
                        id: original.id,
                        requestType: requestType,
                        selectedQuarter: quarter.value,
                        _version: original._version,
                    }
                }
            });
            toast.success("Updated.")
            myRequests(selectedYear)
            clearAll()

        } else {
            toast.error("Select type.")
        }


        //upload documents
        if (docList.length > 0) {
            //check existing data length 
            // var ChangeRequestFilesArray = await DataStore.query(ChangeRequestFiles,
            //     (c) => c.and(c => [
            //         c.change_request_id.eq(requestId),
            //         c.upload_type.eq("R")
            //     ]));

            var ChangeRequestFilesArray = await graphQLGetAllData('listChangeRequestFiles', [{ change_request_id: { eq: requestId } }, { upload_type: { eq: "R" } }]);


            if (ChangeRequestFilesArray.length > 0) {
                // await DataStore.delete(ChangeRequestFiles, (c) => c.change_request_id.eq(requestId)).then(async (response) => {
                //     //save origin list
                //     for (var i = 0; i < docList.length; i++) {
                //         deleteFileFromS3(docList[i].filename)
                //     }
                // })

                const originals = await graphQLGetAllData('listChangeRequestFiles', [{ change_request_id: { eq: requestId } }]);

                for (var i = 0; i < originals.length; i++) {
                    await API.graphql({
                        query: mutations.deleteChangeRequestFiles,
                        variables: {
                            input: {
                                id: originals[i].id,
                                _version: originals[i]._version,
                            }
                        }
                    });
                }

                for (var i = 0; i < docList.length; i++) {
                    deleteFileFromS3(docList[i].filename)
                }

            }
            else {
                try {
                    for (var i = 0; i < docList.length; i++) {
                        const fileName = 'public/changerequest/' + requestId + '/reportee/' + docList[i].filename;
                        await UploadFileOnS3(docList[i].file, fileName).catch(error => toast.error(error));
                        // await DataStore.save(
                        //     new ChangeRequestFiles({
                        //         "change_request_id": requestId,
                        //         "upload_type": "R",
                        //         "fileName": fileName,
                        //     })
                        // ).then((response) => {})
                        await API.graphql(
                            graphqlOperation(mutations.createChangeRequestFiles, {
                                input: {
                                    change_request_id: requestId,
                                    upload_type: "R",
                                    fileName: fileName,
                                }
                            })
                        );
                        setDocList([])
                        setDocFinalList([])

                    }
                } catch (error) {
                    console.log("Error uploading file: ", error);
                }
            }
        }
        else {
        }
    }

    const requestsStatus = (rowData) => {
        return (
            rowData.hrRequestStatus === HR_CHANGE_REQUEST_STATUS.Declined ?
                <p className=" bg-[#FEF3F2] text-[#FDA29B] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center "> <div className='w-2 h-2 bg-[#FDA29B] rounded-full'></div>{HR_CHANGE_REQUEST_STATUS.Declined}</p>
                :
                (rowData.hrRequestStatus === HR_CHANGE_REQUEST_STATUS.Approved) ?
                    <p className="bg-[#E5F3EC] text-[#4FB155] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center w-24"> <div className='w-2 h-2 bg-[green] rounded-full'></div>{HR_CHANGE_REQUEST_STATUS.Approved}</p>
                    : rowData.requestStatus === CHANGE_REQUEST_STATUS.REPORTEE ?
                        <p className="bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center w-20" > <div className='w-2 h-2 bg-[#1B55AF] rounded-full'></div>{CHANGE_REQUEST_STATUS.REPORTEE}</p>
                        :
                        <p className="bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center w-20" > <div className='w-2 h-2 bg-[#1B55AF] rounded-full'></div>{"Awaiting Approve"}</p>
        )
    }

    return isAuthenticated ? (
        renderItems &&
        <div className="pt-16">
            <TopNav />
            <LeftNav />

            <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="sm:flex items-center space-x-8">
                        <div className="text-2xl font-semibold dark:text-white">Change Request</div>
                        <div className="tabs_one mt-5 sm:mt-0">
                            {
                                isReportingManager === "0" ?
                                    <ul className="flex">
                                        <li><Link className='active'  >Myself</Link></li>
                                    </ul>
                                    :
                                    <div className="tabs_one mt-5 sm:mt-0">
                                        <ul className="flex">
                                            <li><Link to="/employee/myteam/changerequest" >My Team</Link></li>
                                            <li><Link className='active'  >Myself</Link></li>
                                        </ul>
                                    </div>

                            }
                            {/* <ul className="flex">
                                <li><Link className='active'  >Myself</Link></li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="  flex justify-between">
                        <div />
                        <div className=" lg:mt-0 flex  items-center gap-4">
                            <div className="relative">
                            </div>
                            <div className="w-full min-w-[200px]">
                                <Select
                                    options={financialYearList}
                                    value={financialYear}
                                    onChange={e => {
                                        myRequests(e)
                                        setFinancialYear(e)
                                        reactLocalStorage.setObject('selectedYear', e);
                                    }}
                                    className="mt-2 mb-2 my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div  >
                            {
                                isButtonDisable === true &&
                                <div className="w-full min-w-[200px]" >
                                    <button onClick={() => setViewChangeRequestPopUp(1)} className="border bg-[#029046] py-1.5 px-5 h-10 text-white text-[15px] rounded-md active addClass dark:border-[#029046]">Create New Request</button>
                                </div>

                            }
                            {/* <div className="w-full min-w-[200px]" >
                                <button onClick={() => setEmail()} className="border bg-[#029046] py-1.5 px-5 h-10 text-white text-[15px] rounded-md active addClass dark:border-[#029046]">Create</button>
                            </div> */}

                        </div>
                    </div>

                    <div id="selector" className={viewChangeRequestPopUp === 1 ? 'overlay' : ''}></div>
                    {
                        viewChangeRequestPopUp === 1 ?
                            <div style={{ width: "42vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                                <div >
                                    <img src={selfPopular} alt="" style={{ width: "50vw" }} />
                                    <div className="absolute right-0 left-0 top-0 w-full text-white">
                                        <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                            <div className="text-md"><h6 className='text-[white]'>Change Request - New</h6></div>
                                            <div><Link onClick={() => setViewChangeRequestPopUp(0)} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                        </div>

                                        <div className='flex justify-between items-center mt-3'>
                                            <div className="px-6 mt-3 flex gap-3  items-center pb-5 ">
                                                <div>
                                                    <Menu as="div" className="relative inline-block text-left">
                                                        <div className="">
                                                            <Menu.Button className='flex items-center'>
                                                                <Avatar alt="" className='imgprofile' src={profilePic} />
                                                            </Menu.Button>
                                                        </div>
                                                    </Menu>
                                                </div>
                                                <div>
                                                    <div className="text-sm text-[#80C7A2] dark:text-white">{reactLocalStorage.get('loggedEmployeeRoleName')}</div>
                                                    <div className="text-xl dark:text-white">{employeeName}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mr-8">
                                                <div className="text-sm text-[#80C7A2] dark:text-white">Date</div>
                                                <div className="text-xl dark:text-white">{moment().format("Do MMM, YYYY")}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-2 px-5 height-custtom">
                                        <div className="bg-[#EEF8F4] my-4 text-sm p-4 rounded-md dark:bg-[#252423]">
                                            <div className="grid grid-cols-2 gap-3">
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px]">Requestor Name</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{employeeName}</div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px]    px-12">Employee ID</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{employeeCode}</div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px] ">Reporting Manager</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{reportingManagerName}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex my-5 gap-5">
                                            <h1 className="text-Inter mt-2 text-18px dark:text-[#FFFF]">Requested Type:</h1>
                                            <div className="flex justify-between items-center">
                                                <input onChange={setPlanningPeriod} checked={isCheckedPlanningPeriod} defaultChecked={isCheckedPlanningPeriod} className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2" type="checkbox" />
                                                <p className="text-Inter  text-14px dark:text-[#FFFF]">Planning Period</p>
                                            </div>

                                            <div className="flex justify-between items-center">
                                                <input onChange={setOverrideActual} checked={isCheckedOverrideActualPeriod} defaultChecked={isCheckedOverrideActualPeriod} className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2" type="checkbox" htmlFor='Actuals' />
                                                <label htmlFor='Actuals' className="text-Inter  text-14px dark:text-[#FFFF]">Override Actuals</label>
                                            </div>
                                            {
                                                isCheckedOverrideActualPeriod &&
                                                <Select
                                                    options={quarterList}
                                                    value={quarter}
                                                    onChange={e => { setQuarter(e) }}
                                                    className="my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            }

                                        </div>

                                        <div className='mt-5'>
                                            <textarea
                                                value={comment}
                                                rows="5"
                                                onChange={e => setComment(e.target.value)}
                                                type="text" className="text-[16px] rounded-lg   flex-1  border border-[#C6CBD2] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]"
                                                placeholder="Add Comment..." />
                                        </div>
                                    </div>
                                    <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">
                                        <div className="flex justify-end gap-3">
                                            <div><Link onClick={() => clearAll()} className="bg-white 
                                                dark:text-[white] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                            >Close</Link></div>
                                            <div><Link onClick={() => submitRequest()}
                                                className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Submit</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }


                    <div id="selector" className={isViewPopUp === 1 ? 'overlay' : ''}></div>
                    {
                        isViewPopUp === 1 ?
                            <div style={{ width: "42vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                                <div >
                                    <img src={selfPopular} alt="" style={{ width: "50vw" }} />
                                    <div className="absolute right-0 left-0 top-0 w-full text-white">
                                        <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                            <div className="text-md"><h6 className='text-[white]'>Change Request - {typeName}</h6></div>
                                            <div><Link onClick={() => clearAll()} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                        </div>

                                        <div className='flex justify-between items-center mt-5'>
                                            <div className="px-6 mt-3 flex gap-3  items-center pb-5 ">
                                                <div>
                                                    <Menu as="div" className="relative inline-block text-left">
                                                        <div className="">
                                                            <Menu.Button className='flex items-center'>
                                                                <Avatar alt="" className='imgprofile' src={profilePic} />
                                                            </Menu.Button>
                                                        </div>
                                                    </Menu>
                                                </div>
                                                <div>
                                                    <div className="text-sm text-[#80C7A2] dark:text-white">{reactLocalStorage.get('loggedEmployeeRoleName')}</div>
                                                    <div className="text-xl dark:text-white">{employeeName}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mr-8">
                                                <div className="text-sm text-[#80C7A2] dark:text-white">Date</div>
                                                <div className="text-xl dark:text-white">{moment().format("Do MMM, YYYY")}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-2 px-6 height-custtom">

                                        <div className="bg-[#EEF8F4] my-4 text-sm p-4 rounded-md dark:bg-[#252423]">
                                            <div className="grid grid-cols-2 gap-3">
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px]">Requestor Name</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{employeeName}</div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px]    px-12">Employee ID</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{employeeCode}</div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px] ">Reporting Manager</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{reportingManagerName}</div>
                                                </div>
                                            </div>
                                            <div className="flex items-center mt-3">
                                                <div className="text-xs text-[#98A2B3] min-w-[150px] ">Comment</div>
                                                <div className="text-sm text-[#101828] dark:text-[#FFFF]">{requestComment ? requestComment : "-"}</div>
                                            </div>
                                        </div>
                                        <div className="flex " style={{ alignItems: "center" }}>
                                            <h1 className="text-Inter  text-18px dark:text-[#FFFF]">Requested Type:</h1>
                                            <div className="flex  w-40 justify-between">
                                                <input disabled={!isDisabled ? true : false} onChange={setPlanningPeriod} checked={isCheckedPlanningPeriod} defaultChecked={isCheckedPlanningPeriod} className="ml-4" type="checkbox" />
                                                <p className="text-Inter  text-14px dark:text-[#FFFF]">Planning Period</p>
                                            </div>

                                            <div className="flex  w-40 justify-between mr-5">
                                                <input disabled={!isDisabled ? true : false} onChange={setOverrideActual} checked={isCheckedOverrideActualPeriod} defaultChecked={isCheckedOverrideActualPeriod} className="ml-4" type="checkbox" />
                                                <p className="text-Inter  text-14px dark:text-[#FFFF]">Override Actuals</p>
                                            </div>
                                            {
                                                isCheckedOverrideActualPeriod &&
                                                <Select
                                                    isDisabled={!isDisabled ? true : false}
                                                    options={quarterList}
                                                    value={quarter}
                                                    onChange={e => { setQuarter(e) }}
                                                    className="my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            }

                                        </div>
                                        {
                                            renderItems === true ?
                                                <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                                    <div className="py-6 px-5 rounded-md h-64 overflow-auto" >
                                                        {
                                                            reporteeAndRMChat.map((item) => (
                                                                <div className="rounded-lg space-y-6 mt-5 '">

                                                                    {
                                                                        item.sender_id !== item.rM_Id ?
                                                                            <div className="flex space-x-2 justify-start">
                                                                                <div className="w-20 text-center">
                                                                                    <Avatar
                                                                                        style={{ width: 50, height: 50 }}
                                                                                        alt={item.sender_Name}
                                                                                        src={item.sender_Profile}
                                                                                        className=" mx-auto object-cover"
                                                                                    />
                                                                                </div>
                                                                                <div className="bg-[#F2F4F7] max-w-xl p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]">
                                                                                    <div className="flex items-center justify-between ">
                                                                                        <div className="font-medium text-md mb-2 text-[#101828] dark:text-white">{item.sender_Name}</div>
                                                                                        <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                    </div>
                                                                                    <div className="text-sm  text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className="flex space-x-2 justify-end">
                                                                                <div className="bg-[#F2F4F7] max-w-xl  p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]" >
                                                                                    <div className="flex items-center justify-between">
                                                                                        <div className="font-medium text-md text-[#101828] mb-2 dark:text-white">{item.sender_Name}</div>
                                                                                        <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                    </div>
                                                                                    <div className="text-sm text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                </div>
                                                                                <div className="w-20 text-center">
                                                                                    <Avatar
                                                                                        style={{ width: 50, height: 50 }}
                                                                                        alt={item.sender_Name}
                                                                                        src={item.sender_Profile} />
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>

                                                            ))

                                                        }
                                                    </div>
                                                    <div className='relative px-5'>
                                                        <textarea
                                                            type="text"
                                                            value={(message)}
                                                            onChange={event => setMessage(event.target.value)}
                                                            placeholder='Type here...'
                                                            className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-3 pl-4 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"></textarea>
                                                        <Link onClick={() => {
                                                            sendMessage()
                                                        }} className='absolute right-[30px] top-[25px] text-[16px] bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block'>
                                                            <i className="pi pi-send mr-2 relative top-0.5"></i>Send </Link>

                                                    </div>
                                                </div>
                                                :
                                                <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                                    <img src={noCommentsImg} className="m-auto" alt="" style={{ width: "20vw" }} />
                                                </div>

                                        }

                                        {
                                            isDisabled &&
                                            <div className='my-5 relative'>
                                                <input
                                                    onChange={(e) => { selectDocuments(e,) }}
                                                    multiple="multiple" type="file"
                                                    placeholder='Type here...'
                                                    className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 pl-4 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" />

                                                <div className='absolute right-3 top-3'>
                                                    <Link
                                                        onChange={(e) => { selectDocuments(e,) }}
                                                        multiple="multiple"
                                                    ><i className='pi pi-paperclip'></i></Link>
                                                </div>
                                            </div>

                                        }

                                        {
                                            docList.map((item, index) => {
                                                return (
                                                    <div key={index} className='block'>
                                                        <div className='flex gap-2 border cursor-pointer border-[#C6CBD2] p-4 rounded-lg viewfile justify-between mt-1'>
                                                            <div className='flex gap-2'>
                                                                <i className='pi pi-file' style={{ fontSize: 20 }}></i>
                                                                <div>
                                                                    <p className='text-[15px] font-medium text-[#344054]'>{item.filename}</p>

                                                                </div>
                                                            </div>
                                                            {
                                                                !isDisabled ?
                                                                    <div className='flex gap-2'>
                                                                        <i onClick={() => { downloadFile(item) }} className='pi pi-download' style={{ fontSize: 20 }}></i>
                                                                    </div> :
                                                                    <div className='flex gap-2'>
                                                                        <i onClick={() => { onClickRemoveDocuments(index) }} className='pi pi-times-circle' style={{ fontSize: 20 }}></i>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>


                                    <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">
                                        <div className="flex justify-end gap-3">
                                            <div><Link onClick={() => clearAll()} className="bg-white 
                                        dark:text-[white] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                            >Close</Link></div>
                                            {
                                                isDisabled ?
                                                    <div><Link onClick={() => updateRequest()}
                                                        className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Update</Link>
                                                    </div> : ""
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }



                    <div className=" rounded-lg mt-5 ">
                        <DataTable
                            paginator value={myRequestList} responsiveLayout="scroll" style={{ width: '93vw' }}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                        >
                            <Column field="requestNo" header="Request No." sortable filter></Column>
                            <Column field="requestTypeName" header="Request Type" sortable filter></Column>
                            <Column field="requestedDate" header="Requested Date" body={requestedDateFormat} sortable filter ></Column>
                            <Column field='selectedQuarter' header="Quarter" filter ></Column>
                            <Column field='requestStatus' header="Status" filter body={requestsStatus} ></Column>
                            <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnRequest} ></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

        </div>



    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}


export default MySelfChangeRequest;


