import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import LeftSideBar from '../../components/common/employee/leftnav.component';
import TopNavBar from '../../components/common/employee/topnav.component';
import { RemoveItemFromArrayByIdValue } from '../../components/helper/commonfunctions';
import { ValidateEmployeeRole } from "../../components/helper/validateRole";
import EmpLogin from "../../setup/auth/emp.component";


export default function ImportParametersDetail() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const navigateTo = useNavigate()
    const location = useLocation();

    //states
    const TotalRecord = reactLocalStorage.get('importParametersTotalLength');
    const [acceptedRecord, setAcceptedRecord] = useState("");
    const [skipRecord, setSkipRecord] = useState("");


    const buBodyTemplate = (rowData) => {

        return <label className={rowData.strike === 1 ? "strike" : ""}>{rowData.buText}</label>
    }

    const companyCodeTemplate = (rowData) => {
        return <label className={rowData.strike === 1 ? "strike" : ""}>{rowData.companyText}</label>
    }

    const regionTemplate = (rowData) => {
        return <label className={rowData.strike === 1 ? "strike" : ""}>{rowData.regionText}</label>
    }

    const countryTemplate = (rowData) => {
        return <label className={rowData.strike === 1 ? "strike" : ""}>{rowData.countryText}</label>
    }

    const brandTemplate = (rowData) => {
        return <label className={rowData.strike === 1 ? "strike" : ""}>{rowData.brand_REF_Name}</label>
    }

    const productGroupTemplate = (rowData) => {
        return <label className={rowData.strike === 1 ? "strike" : ""}>{rowData.product_group_Name}</label>
    }


    const profitCenterTemplate = (rowData) => {
        return <label className={rowData.strike === 1 ? "strike" : ""}>{rowData.profitCenterText}</label>
    }

    const plantStorageLocationTemplate = (rowData) => {
        return <label className={rowData.strike === 1 ? "strike" : ""}>{rowData.plantlocationText}</label>
    }

    const divisionTemplate = (rowData) => {
        return <label className={rowData.strike === 1 ? "strike" : ""}>{rowData.divisionText}</label>
    }

    const custGroupTemplate = (rowData) => {
        return <label className={rowData.strike === 1 ? "strike" : ""}>{rowData.customerCategoryText}</label>
    }

    const customerTemplate = (rowData) => {
        return <label className={rowData.strike === 1 ? "strike" : ""}>{rowData.customerText}</label>
    }

    const materialTemplate = (rowData) => {
        return <label className={rowData.strike === 1 ? "strike" : ""}>{rowData.materialText}</label>
    }


    async function BindList() {
        //set parameter Final data
        let parameterDetailsFinalData = location.state
        let acceptedRecord = parameterDetailsFinalData.filter((item) => item.strike === 0)
        setAcceptedRecord(acceptedRecord.length)
        let skipRecord = parameterDetailsFinalData.filter((item) => item.strike === 1)
        setSkipRecord(skipRecord.length)
        setImportParameterData(parameterDetailsFinalData);
    }

    const [importParameterData, setImportParameterData] = useState([]);

    const deleteBodyTemplate = (rowData) => {
        return <i onClick={(e) => removeItem(rowData.id)} style={{ cursor: 'pointer' }} className='icon delete_icon'></i>;
    }

    const removeItem = (removeId) => {
        let _parameterData = [...importParameterData];
        let _newParameterData = RemoveItemFromArrayByIdValue(_parameterData, removeId);
        setImportParameterData(_newParameterData);
    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad()
        BindList();
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            <div className="target-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div
                        className="flex flex-1 flex-wrap flex-row justify-center align-center grid 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 gap-4 pl-5 pr-5 contract-grid-wrapper mt-5 p-5">
                        <div className="col-span-12">
                            <div className="bg-white dark:bg-[#2A2E32] rounded-md shadow p-5 py-5 ">
                                <form autoComplete="off">
                                    <div className="flex justify-between items-center mb-10  mt-3  mb-3">
                                        <h2 className="page-title">My Parameters Preview</h2>
                                        <div className="text-md min-w-fit dark:text-white mr-2">Total Records: {TotalRecord}</div>
                                        <div className="text-md min-w-fit dark:text-white mr-2">Accepted Records: {acceptedRecord}</div>
                                        <div className="text-md min-w-fit dark:text-white mr-2">Skip Parameters: {skipRecord}</div>
                                    </div>


                                    <div className="dashboard-main-wrapper rounded-lg mt-5 divSmallTableControls ">

                                        {
                                            <React.Fragment>
                                                <DataTable value={importParameterData} showGridlines responsiveLayout="scroll">
                                                    <Column field="buText" header="BU" body={buBodyTemplate} style={{ width: '12%' }}></Column>
                                                    <Column field="companyText" header="Company Code" body={companyCodeTemplate} style={{ width: '12%' }}></Column>
                                                    <Column field="regionText" header="Region" body={regionTemplate} style={{ width: '12%' }}></Column>
                                                    <Column field="countryText" header="Country" body={countryTemplate} style={{ width: '12%' }}></Column>
                                                    <Column field="brand_REF_Name" header="Brand" body={brandTemplate} style={{ width: '12%' }}></Column>
                                                    <Column field="product_group_Name" header="Product Group" body={productGroupTemplate} style={{ width: '12%' }}></Column>
                                                    <Column field="profitCenterText" header="Profit Center" body={profitCenterTemplate} style={{ width: '12%' }}></Column>
                                                    <Column field="plantlocationText" header="Plant Storage Location" body={plantStorageLocationTemplate} style={{ width: '12%' }}></Column>
                                                    <Column field="divisionText" header="Division" body={divisionTemplate} style={{ width: '12%' }}></Column>
                                                    <Column field="customerCategoryText" header="Customer Group" body={custGroupTemplate} style={{ width: '12%' }}></Column>
                                                    <Column field="customerText" header="Customer" body={customerTemplate} style={{ width: '12%' }}></Column>
                                                    <Column field="materialText" header="Material" body={materialTemplate} style={{ width: '12%' }}></Column>

                                                    {
                                                        <Column header="Delete" headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} body={deleteBodyTemplate} />
                                                    }
                                                </DataTable>
                                            </React.Fragment>
                                        }
                                    </div>
                                </form>
                            </div>

                            <div className="grow flex justify-center gap-3 mb-3 mt-5 ">
                                <Link to='/employee/parameters' className="py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:border-[#4A5056] dark:bg-[#4A5056] dark:text-white">Cancel</Link>
                                <button onClick={async () => {
                                    let parameterDetailsFinalData = importParameterData.filter((item) => item.strike === 0)
                                    navigateTo("/employee/parameters", { state: parameterDetailsFinalData })
                                }} type="button" className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">Import</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );

}