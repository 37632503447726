import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import AnnualTargetImg from '../../../../assets/images/annual_target_img.png';
import LeftNav from '../../../../components/common/employee/leftnav.component';
import TopNav from '../../../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import EmpLogin from "../../../../setup/auth/emp.component";

export default function ViewTarget() {

    const [sampleData, setSampleData] = useState([]);

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    var demoData = [
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },
        { "id": "EMP5249", "name": "John Smith", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "4%", "annualtargetrevenue": "$100M" },
        { "id": "EMP5245", "name": "Barry Walker", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "5%", "annualtargetrevenue": "$30M" },
        { "id": "EMP5223", "name": "Marry Pert", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$40M" },
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },
        { "id": "EMP5249", "name": "John Smith", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },
        { "id": "EMP5245", "name": "Barry Walker", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },
        { "id": "EMP5223", "name": "Marry Pert", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },
        { "id": "EMP5249", "name": "John Smith", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },
        { "id": "EMP5245", "name": "Barry Walker", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },
        { "id": "EMP5223", "name": "Marry Pert", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },
        { "id": "EMP5244", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa", "percentage": "10%", "annualtargetrevenue": "$130M" },

    ]

    useEffect(() => {
        onLoad()
        setSampleData(demoData);
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNav />
            <LeftNav />
            <div className="target-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="flex justify-between">
                        <div className="sm:flex items-center space-x-4">
                            <Link to="/employee/planning/team/reviewcommit" className="text-[#344054] text-[14px] dark:text-white py-1.5 px-3 rounded-md border border-[#D0D5DD]"><i className="icon arrow_left_double mr-6 "></i>Back</Link>
                            <div className="text-2xl font-semibold dark:text-white">View Target</div>
                            <div className="text-xs border text-[#667085] border-[#667085] rounded p-1">FY: 2022 - 2023</div>
                        </div>
                        <div className="mt-5 lg:mt-0 flex items-center gap-4">
                            <div className="text-md min-w-fit dark:text-white">Select Parameter</div>
                            <div className="w-full min-w-[200px]">
                                <Select
                                    className="mt-2 my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bg-white rounded-lg mt-10 p-4 px-3  dark:bg-[#2A2E32]">
                        <div className="grid grid-cols-10 lg:grid-cols-10 2xl:grid-cols-10 flex flex-1 content-center items-center gap-2">

                            <div className="col-span-12">

                                <div className="grid grid-cols-12 lg:grid-cols-12 2xl:grid-cols-12 flex flex-1 content-center items-center gap-3">
                                    <div className="col-span-2">
                                        <img src={AnnualTargetImg} alt=""></img>
                                    </div>
                                    <div className="col-span-10">
                                        <div className="grid grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 gap-6 h-auto">
                                            <div className="bg-[#fff] border border-[#E4E7EC] rounded-lg p-16 content-center flex annual_target_bg relative shadow-md  dark:bg-[#212529] dark:border-[#2A2E32]">
                                                <div className="absolute right-[20%] top-[25%]">
                                                    <h6 className="text-[#344054] text-[16px] leading-tight  dark:text-[#FFFFFF]">Financial Year</h6>
                                                    <h2 className="text-[#101828] text-[28px] font-semibold  dark:text-[#FFFFFF]">2022-23</h2>
                                                </div>
                                            </div>
                                            <div className="bg-[#fff] border border-[#E4E7EC] rounded-lg p-16 content-center flex annual_target_bg relative shadow-md  dark:bg-[#212529] dark:border-[#2A2E32]">
                                                <div className="absolute right-[20%] top-[25%]">
                                                    <h6 className="text-[#344054] text-[16px] leading-tight  dark:text-[#FFFFFF]">Parameter</h6>
                                                    <h2 className="text-[#101828] text-[28px] font-semibold  dark:text-[#FFFFFF]">Revenue</h2>
                                                </div>
                                            </div>
                                            <div className="bg-[#fff] border border-[#E4E7EC] rounded-lg p-16 content-center flex annual_target_bg relative shadow-md  dark:bg-[#212529] dark:border-[#2A2E32]">
                                                <div className="absolute right-[20%] top-[25%]">
                                                    <h6 className="text-[#344054] text-[16px] leading-tight  dark:text-[#FFFFFF]">Annual Target</h6>
                                                    <h2 className="text-[#101828] text-[28px] font-semibold  dark:text-[#FFFFFF]"> $1,000M </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <!--manage plan annual target --> */}

                    <div className="rounded-lg mt-5 dark:bg-[#2A2E30]">
                        <DataTable paginator value={sampleData} responsiveLayout="scroll" style={{ width: '93vw' }}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                            <Column field="id" header="Employee ID" sortable></Column>
                            <Column field="name" header="Name" sortable></Column>
                            <Column field="role" header="Role" sortable></Column>
                            <Column field="vertical" header="Vertical" sortable></Column>
                            <Column field="sbu" header="SBU" sortable></Column>
                            <Column field="region" header="Region" sortable></Column>
                            <Column field="percentage" header="Percentage" sortable></Column>
                            <Column field="annualtargetrevenue" header="Annual Target Revenue(M)" sortable></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}