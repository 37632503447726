function filterArrayOnQuarter(arr, quarter, month) {
    if (quarter === 'Quarter 1') {
        return arr.filter(record => record.month >= 4 && record.month <= 6);
    } else if (quarter === 'Quarter 2') {
        return arr.filter(record => record.month >= 4 && record.month <= 9);
    } else if (quarter === 'Quarter 3') {
        return arr.filter(record => record.month >= 4 && record.month <= 12);
    } else {
        return arr;
    }
}

export { filterArrayOnQuarter }

