import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { Link, useNavigate } from "react-router-dom";
import { DataStore } from '@aws-amplify/datastore';
import { Employee, Roles } from '../../models';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { reactLocalStorage } from 'reactjs-localstorage';
import { USER_TYPES } from '../../components/helper/enum';
import { ValidateEmployeeRole } from '../../components/helper/validateRole';
import EmpDashboard from '../../pages/employee/landing.component';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import loginImage from '../../assets/images/login-img.png';
import logo from '../../assets/images/logo-landing.png';
import ValidateEmail from '../../components/common/ValidateEmail';
import DefaultProfilePic from '../../assets/images/user.png'
import microsoftLoginImage from '../../assets/images/microsoft_icon.png'
import { graphQLGetAllData, graphQLFindRecordById } from '../../components/helper/graphQLFunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';

export default function EmpLogin(props) {
    let navigateTo = useNavigate();

    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const isMicrosoftAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const [employeeList, setEmployeeList] = useState([]);

    const loadEmployees = async () => {
        // let found = await DataStore.query(Employee).then((res) => {
        //     if (res.length === 0) {
        //         loadEmployees()
        //     }
        //     else {
        //         setPleaseWaitButton(false)
        //     }
        // });
    }

    let isEmployeeRole = ValidateEmployeeRole();

    if (isEmployeeRole === true) {
        navigateTo('/employee/landing');
    }

    async function doAuth() {
        await instance.loginPopup().then(async (response) => {

            const userEmail = response.account.username.toLocaleLowerCase();
            let getLoginEmployee = employeeList.find(o => o.email === userEmail.toLocaleLowerCase());
            if (getLoginEmployee) {
                // let isReportingManager = await graphQLGetAllData("listEmployees", [{ parentEmployeeId: { eq: getLoginEmployee.id } }]);
                let isReportingManager = employeeList.filter(c => c.parentEmployeeId === getLoginEmployee.id)
                
                //console.log(isReportingManager)
                if (isReportingManager.length > 0) {
                    reactLocalStorage.set('isReportingManager', "1");
                } else {
                    reactLocalStorage.set('isReportingManager', "0");
                }

                reactLocalStorage.set('loggedUserRole', USER_TYPES.Employee);
                reactLocalStorage.set('loggedEmployeeId', getLoginEmployee.id);
                reactLocalStorage.set('loggedEmployeeReportingManagerId', getLoginEmployee.parentEmployeeId);
                reactLocalStorage.set('loggedEmployeeFirstName', getLoginEmployee.firstName);
                reactLocalStorage.set('loggedEmployeeLastName', getLoginEmployee.lastName);
                reactLocalStorage.set('loggedEmployeeProfilePic', DefaultProfilePic);


                //Get Role
                // let getEmployeeRole = await DataStore.query(Roles, (c) => c.id.eq( getLoginEmployee.role_id));
                let getEmployeeRole = await graphQLFindRecordById("getRoles", getLoginEmployee.role_id)

                reactLocalStorage.set('loggedEmployeeRoleId', getLoginEmployee.role_id);
                reactLocalStorage.set('loggedEmployeeRoleName', getEmployeeRole.name);
                reactLocalStorage.set('loggedEmployeeTeamType', getEmployeeRole.teamType);

                //Profile Pic Path
                if (getLoginEmployee.profilePic) {
                    await Storage.get(getLoginEmployee.profilePic)
                        .then(result => {
                            reactLocalStorage.set('loggedEmployeeProfilePic', result);
                        });
                }

                reactLocalStorage.set('loggedEmployeeIsAssignByHR', getLoginEmployee.isAssignDirectTarget);
                
                // await instance.acquireTokenSilent({
                //     scopes: ["User.read"],
                //     account: response.account,
                // })

                // navigateTo('/employee/landing');
            }
            else {
                toast.error("User not found.");
                doAuthLogout();
            }

        });  
    }

    const doAuthLogout = () => {
        reactLocalStorage.clear();
        instance.logoutPopup();
    }


    async function onLoad() {
        // try {
        //     if (!ValidateEmployeeRole()) { userHasAuthenticated(false); }

        let employeeResponses = await graphQLGetAllData('listEmployees');
        setEmployeeList(employeeResponses);

        // } catch (e) {
        //     userHasAuthenticated(false);
        // }
    }

     async function ValidateEmpLogin(event) {
        event.preventDefault();
        if (email === "") { toast.error('Enter email.'); return; }
        else if (!ValidateEmail(email)) { toast.error('Invalid email.'); return; }
        else if (password === "") { toast.error('Enter password.'); return; }

        try {
            //reactLocalStorage.clear();
            setPleaseWaitButton(true);

            //Validate Employee in table
            // var getEmployee = await DataStore.query(Employee, (c) => c.and(c => [
            //     c.email.eq(email)
            // ]));

            let getEmployee = await graphQLGetAllData("listEmployees", [{ email: { eq: email } }])

            setPleaseWaitButton(false);
            if (getEmployee && getEmployee.length > 0) {
                // let isReportingManager = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(getEmployee[0].id));
                let isReportingManager = await graphQLGetAllData("listEmployees", [{ parentEmployeeId: { eq: getEmployee[0].id } }])
                //console.log(isReportingManager)
                if (isReportingManager.length > 0) {
                    reactLocalStorage.set('isReportingManager', "1");
                } else {
                    reactLocalStorage.set('isReportingManager', "0");
                }

                reactLocalStorage.set('loggedUserRole', USER_TYPES.Employee);
                reactLocalStorage.set('loggedEmployeeId', getEmployee[0].id);
                reactLocalStorage.set('loggedEmployeeReportingManagerId', getEmployee[0].parentEmployeeId);
                reactLocalStorage.set('loggedEmployeeFirstName', getEmployee[0].firstName);
                reactLocalStorage.set('loggedEmployeeLastName', getEmployee[0].lastName);
                reactLocalStorage.set('loggedEmployeeProfilePic', DefaultProfilePic);


                //Get Role
                // let getEmployeeRole = await DataStore.query(Roles, (c) => c.id.eq(getEmployee[0].role_id));
                let getEmployeeRole = await graphQLFindRecordById("getRoles", getEmployee[0].role_id)

                reactLocalStorage.set('loggedEmployeeRoleId', getEmployee[0].role_id);
                reactLocalStorage.set('loggedEmployeeRoleName', getEmployeeRole.name);
                reactLocalStorage.set('loggedEmployeeTeamType', getEmployeeRole.teamType);

                //Profile Pic Path
                if (getEmployee[0].profilePic) {
                    await Storage.get(getEmployee[0].profilePic)
                        .then(result => {
                            reactLocalStorage.set('loggedEmployeeProfilePic', result);
                        });
                }

                reactLocalStorage.set('loggedEmployeeIsAssignByHR', getEmployee[0].isAssignDirectTarget);

                navigateTo('/employee/landing');
                //navigateTo(0);
            }
            else {
                toast.error("Invalid Email or Password.");
            }

        } catch (e) {
            setPleaseWaitButton(false);
            toast.error(e.message);
        }

    }

    useEffect(() => {
        loadEmployees()
        onLoad();
    }, []);

    return (
        // isAuthenticated ? (
        //     <EmpDashboard />
        // ) : (
        <div>
            <div
                className="min-h-screen flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:grid-cols-1 gap-0">
                <div className="flex flex-wrap flex-row justify-center auto-rows-max login bg-white dark:bg-[#1E2124]">
                    <div className="max-w-md 2xl:max-w-lg w-full p-2">

                        <div className="mb-20 md:mb-8 2xl:mb-30 lg:mb-22 xl:mb-30 xl:mt-8">
                            <img className="2xl:h-16 h-10" src={logo} alt="" />
                        </div>
                        <div className="mb-2">
                            <h2 className="text-[26px] font-semibold text-[#101828] dark:text-white">Login As User
                            </h2>
                        </div>

                        <div className="flex items-center justify-between align-center mb-4 mt-4">

                        </div>
                        {/* Temporary Login starts*/}
                        <div className="max-w-md 2xl:max-w-lg w-full p-2">
                            <form onSubmit={ValidateEmpLogin} autoComplete="off">
                                
                                <Link onClick={() => doAuth()} className="px-[20px] flex gap-3 justify-center py-[10px] xl:px-[1.563vw] xl:py-[0.521vw] bg-[#029046] hover:bg-[#19AA5F] focus:ring-green-500 focus:ring-offset-green-800 text-white w-full transition ease-in duration-200 text-center text-[16px] xl:text-[0.938vw] font-semibold shadow-md focus:outline-none focus:ring-1 focus:ring-offset-2 rounded-lg dark:text-white dark:border-[#4E5668]">
                                    <img className="mr-2 w-5" src={microsoftLoginImage} alt="" />
                                    Sign in with Microsoft
                                </Link>
                                <div className="flex flex-wrap flex-row justify-evenly space-x-2 w-100 mb-4 mx-5 mt-5">
                                    <span className="bg-[#E4E7EC] h-px flex-grow t-2 relative mt-3"></span>
                                    <span className="flex-none text-sm font-light text-[#98A2B3]">Or Sign in with Email</span>
                                    <span className="bg-[#E4E7EC] h-px flex-grow t-2 relative mt-3"></span>
                                </div>


                                {
                                    props.sessionExpired === 1 ?
                                        <div className="mb-5 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                            <span className="text-sm text-red-600">Session expired. Please login again.</span>
                                        </div>
                                        : ''
                                }

                                <div className="relative mb-6">
                                    <label htmlFor="required-email" className="text-[#344054] text-[14px] font-medium">
                                        Email
                                        <span className="text-red-500 required-dot">
                                            *
                                        </span>
                                    </label>
                                    <input
                                        onChange={(e) => setEmail(e.target.value)} value={email} type="email" id="required-email"
                                        className="text-[16px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-2 placeholder:text-[#667085] dark:placeholder:text-[#333]"
                                    />
                                </div>
                                <div className="relative mb-6">
                                    <label htmlFor="required-password" className="text-[#344054] text-[14px] font-medium">
                                        Password
                                        <span className="text-red-500 required-dot">
                                            *
                                        </span>
                                    </label>
                                    <input onChange={(e) => setPassword(e.target.value)} value={password} type={showPassword ? "text" : "password"} id="required-password"
                                        className="text-[16px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-2 placeholder:text-[#667085] dark:placeholder:text-[#333]"
                                    />
                                    {
                                        showPassword ?
                                            <Link onClick={() => setShowPassword(!showPassword)} > <i className="pi pi-eye-slash eyes_style"></i></Link>
                                            :
                                            <Link onClick={() => setShowPassword(!showPassword)} > <i className="pi pi-eye eyes_style"></i></Link>
                                    }
                                </div>
                                <div className="flex items-center justify-between align-center mb-4 mt-8">
                                    {/* <div className="flex flex-row items-center">

                                    </div>
                                    <div>
                                        <Link className="text-[16px] font-normal text-[#029046] hover:underline" >Forgot password?</Link>
                                    </div> */}
                                </div>
                                <div className="flex w-full mb-2">
                                    <div className="flex w-full mb-4">
                                        {
                                            showPleaseWaitButton ?
                                                <button type="button" className="py-2.5 px-5 bg-[#029046] hover:bg-[#19AA5F] focus:ring-green-500 focus:ring-offset-green-800 text-white w-full transition ease-in duration-200 text-center text-base font-normal shadow-md focus:outline-none focus:ring-1 focus:ring-offset-2  rounded-lg ">
                                                    {'Please wait...'}
                                                </button>
                                                :
                                                <button onClick={ValidateEmpLogin} type="submit" className="py-2.5 px-5 bg-[#029046] hover:bg-[#19AA5F] focus:ring-green-500 focus:ring-offset-green-800 text-white w-full transition ease-in duration-200 text-center text-base font-normal shadow-md focus:outline-none focus:ring-1 focus:ring-offset-2  rounded-lg ">
                                                    {'Sign In'}
                                                </button>
                                        }
                                    </div>
                                </div>
                                {/* <div
                                    className="flex items-center justify-center mb-8 items-center text-sm font-normal text-center   text-[#555555]">
                                    Not Registered Yet?
                                    <Link
                                        className="inline-flex items-center text-sm font-semibold text-center text-[#029046] hover:text-[#19AA5F]">
                                        <span className="ml-2">
                                            Create account.
                                        </span>
                                    </Link>
                                </div> */}
                            </form>
                        </div>
                        {/* Temporary Login ends */}
                        {/* <div className="flex w-full mb-2">
                            <div className="flex w-full mb-4">
                                {
                                    <>
                                        <button onClick={() => doAuth()} className="py-2.5 px-5 bg-[#f65314] hover:bg-[#ffbb00] focus:ring-green-500 focus:ring-offset-green-800 text-white w-full transition ease-in duration-200 text-center text-base font-normal shadow-md focus:outline-none focus:ring-1 focus:ring-offset-2  rounded-lg ">
                                            Sign In with Microsoft
                                        </button>
                                    </>
                                }

                            </div>
                         
                        </div> */}
                    </div>
                </div>
                <div className="login-bg flex-row flex flex-wrap align-center justify-center content-center">

                    <div className=" py-10 px-16">
                        <div className="auto-rows-max flex-row">
                            <img src={loginImage} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    // )
}