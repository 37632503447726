import React, { useState, useEffect } from 'react';
import noInternet from '../../assets/brands/nointernet.gif'
import logo from '../../assets/brands/logo.png'

const NoInternetConnection = (props) => {
    const [isOnline, setOnline] = useState(true);

    window.addEventListener('online', () => { setOnline(true) });

    window.addEventListener('offline', () => { setOnline(false) });

    if (isOnline) {
        return (
            props.children
        )
    } else {
        return (
            <div className="pt-16 bg-[#fff] h-screen justify-center" >
                {/* <div className="flex flex-wrap flex-row align-center justify-center content-center auto-rows-max ">
                        <img src={logo} alt="" width={220} height={56} />
                    </div> */}
                <div className="flex mt-5 flex-wrap flex-row align-center justify-center content-center auto-rows-max">
                    <img src={logo} height={400} width={400} className="mx-auto" alt="" />
                </div>
                <h3 className="font-bold text-[30px] text-[#525252] text-center">No Internet Connection.</h3>
            </div>
        )
    }
}

export default NoInternetConnection;