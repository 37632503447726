import { PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES } from "../../components/helper/enum";
import { sumFromArray } from "../helper/maths";

const getTargetBasedOnParameterType = (data, quarter, parameterType,currentItem) => {
    
    try {
        let returnActual = ""
        switch (quarter) {
            case "Quarter 1":
                if (data.id !== "") {
                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N) {
                        // returnActual = sumFromArray('q1_target_value', data);
                        return currentItem.q1_target_value
                    } else {
                        returnActual = sumFromArray('q1_target_value', data) / data.length;
                    }
                }
                return returnActual;

            case "Quarter 2":
                if (data.id !== "") {
                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N) {
                        // var q1 = sumFromArray('q1_target_value', data); var q2 = sumFromArray('q2_target_value', data);
                        // returnActual = (q1 + q2)
                        return parseFloat(currentItem.q1_target_value)+parseFloat(currentItem.q2_target_value);
                    } else {
                        var q1 = sumFromArray('q1_target_value', data); var q2 = sumFromArray('q2_target_value', data);
                        returnActual = (((q1 + q2) / (data.length)) / 2)
                    }
                }
                return returnActual;

            case "Quarter 3":
                if (data.id !== "") {
                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N) {
                        // var q1 = sumFromArray('q1_target_value', data); var q2 = sumFromArray('q2_target_value', data); var q3 = sumFromArray('q3_target_value', data);
                        // returnActual = (q1 + q2 + q3)
                        return parseFloat(currentItem.q1_target_value)+parseFloat(currentItem.q2_target_value)+ parseFloat(currentItem.q3_target_value);
                    } else if (parameterType === PARAMETERS_TYPES_NAMES.P){
                        return currentItem.q3_target_value < 1 ? currentItem.q3_target_value * 100: currentItem.q3_target_value
                    }
                     else {
                        var q1 = sumFromArray('q1_target_value', data); var q2 = sumFromArray('q2_target_value', data); var q3 = sumFromArray('q3_target_value', data);
                        returnActual = (((q1 + q2 + q3) / (data.length)) / 3)
                    }
                }
                return returnActual;

            case "Quarter 4":
                if (data.id !== "") {
                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N) {
                        // var q1 = sumFromArray('q1_target_value', data); var q2 = sumFromArray('q2_target_value', data); var q3 = sumFromArray('q3_target_value', data); var q4 = sumFromArray('q4_target_value', data);
                        // returnActual = (q1 + q2 + q3 + q4)
                        return parseFloat(currentItem.q1_target_value)+parseFloat(currentItem.q2_target_value)+ parseFloat(currentItem.q3_target_value)+parseFloat(currentItem.q4_target_value);
                    } else if (parameterType === PARAMETERS_TYPES_NAMES.P){
                        return currentItem.q3_target_value < 1 ? currentItem.q3_target_value * 100: currentItem.q3_target_value
                    }
                    else {
                        var q1 = sumFromArray('q1_target_value', data); var q2 = sumFromArray('q2_target_value', data); var q3 = sumFromArray('q3_target_value', data); var q4 = sumFromArray('q4_target_value', data);
                        returnActual = (((q1 + q2 + q3 + q4) / (data.length)) / 4)
                    }
                }
                return returnActual;
            default:
            // code block
        }
    }
    catch (e) {

    }


};

const getTargetBasedOnParameterTypeForEvalPopup = (data, quarter, parameterType,currentItem) => {
    
    try {
        let returnActual = ""
        switch (quarter) {
            case "Quarter 1":
                if (data.id !== "") {
                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N) {
                        // returnActual = sumFromArray('q1_target_value', data);
                        return currentItem.q1_target_value
                    } else {
                        returnActual = sumFromArray('q1_target_value', data) / 1;
                    }
                }
                return returnActual;

            case "Quarter 2":
                if (data.id !== "") {
                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N) {
                        // var q1 = sumFromArray('q1_target_value', data); var q2 = sumFromArray('q2_target_value', data);
                        // returnActual = (q1 + q2)
                        return parseFloat(currentItem.q1_target_value)+parseFloat(currentItem.q2_target_value);
                    } else {
                        var q1 = sumFromArray('q1_target_value', data); var q2 = sumFromArray('q2_target_value', data);
                        returnActual = (((q1 + q2) / (1)) / 2)
                    }
                }
                return returnActual;

            case "Quarter 3":
                if (data.id !== "") {
                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N) {
                        // var q1 = sumFromArray('q1_target_value', data); var q2 = sumFromArray('q2_target_value', data); var q3 = sumFromArray('q3_target_value', data);
                        // returnActual = (q1 + q2 + q3)
                        return parseFloat(currentItem.q1_target_value)+parseFloat(currentItem.q2_target_value)+ parseFloat(currentItem.q3_target_value);
                    } else if (parameterType === PARAMETERS_TYPES_NAMES.P){
                        return currentItem.q3_target_value < 1 ? currentItem.q3_target_value * 100: currentItem.q3_target_value
                    }
                     else {
                        var q1 = sumFromArray('q1_target_value', data); var q2 = sumFromArray('q2_target_value', data); var q3 = sumFromArray('q3_target_value', data);
                        returnActual = (((q1 + q2 + q3) / (1)) / 3)
                    }
                }
                return returnActual;

            case "Quarter 4":
                if (data.id !== "") {
                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N) {
                        // var q1 = sumFromArray('q1_target_value', data); var q2 = sumFromArray('q2_target_value', data); var q3 = sumFromArray('q3_target_value', data); var q4 = sumFromArray('q4_target_value', data);
                        // returnActual = (q1 + q2 + q3 + q4)
                        return parseFloat(currentItem.q1_target_value)+parseFloat(currentItem.q2_target_value)+ parseFloat(currentItem.q3_target_value)+parseFloat(currentItem.q4_target_value);
                    } else if (parameterType === PARAMETERS_TYPES_NAMES.P){
                        return currentItem.q3_target_value < 1 ? currentItem.q3_target_value * 100: currentItem.q3_target_value
                    }
                    else {
                        var q1 = sumFromArray('q1_target_value', data); var q2 = sumFromArray('q2_target_value', data); var q3 = sumFromArray('q3_target_value', data); var q4 = sumFromArray('q4_target_value', data);
                        returnActual = (((q1 + q2 + q3 + q4) / (1)) / 4)
                    }
                }
                return returnActual;
            default:
            // code block
        }
    }
    catch (e) {

    }


};


const getTotalTarget = (data, quarter, parameterType) => {
    // console.log(data)
    try {
        let returnActual = ""
        switch (quarter) {
            case "Quarter 1":
                if(parameterType === PARAMETERS_TYPES_NAMES.P)
                    return data.q1_target_value < 1 ? parseInt(data.q1_target_value * 100):data.q1_target_value;
                
                return data.q1_target_value;
                if (data.id !== "") {
                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N) {
                        returnActual = parseFloat(data.q1_target_value)
                    } else {
                        returnActual = (parseFloat(data.q1_target_value)) / 1
                    }
                }
                return returnActual;

            case "Quarter 2":
                if(parameterType === PARAMETERS_TYPES_NAMES.P)
                    return data.q2_target_value < 1 ? parseInt(data.q2_target_value * 100):data.q2_target_value;
                
                return data.q2_target_value;
                if (data.id !== "") {
                    let arr = [parseFloat(data.q1_target_value), parseFloat(data.q2_target_value)]
                    let sum = 0;
                    for (let i = 0; i < arr.length; i++) {
                        let value = parseFloat(arr[i]);
                        if (!isNaN(value)) {
                            sum += value;
                        }
                    }

                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N) {
                        return parseFloat(sum).toFixed(2);
                    } else {
                        return parseFloat(sum).toFixed(2) / 2;
                    }
                }
                return returnActual;

            case "Quarter 3":
                if(parameterType === PARAMETERS_TYPES_NAMES.P)
                    return data.q3_target_value < 1 ? parseInt(data.q3_target_value * 100):data.q3_target_value;
                return data.q3_target_value;
                if (data.id !== "") {
                    let arr = [parseFloat(data.q1_target_value), parseFloat(data.q2_target_value), parseFloat(data.q3_target_value)]
                    let sum = 0;
                    for (let i = 0; i < arr.length; i++) {
                        let value = parseFloat(arr[i]);
                        if (!isNaN(value)) {
                            sum += value;
                        }
                    }

                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N) {
                        return parseFloat(sum).toFixed(2);
                    } else {
                        return parseFloat(sum).toFixed(2) / 3;
                    }
                }
                return returnActual;

            case "Quarter 4":
                if(parameterType === PARAMETERS_TYPES_NAMES.P)
                    return data.q4_target_value < 1 ? parseInt(data.q4_target_value * 100):data.q4_target_value;
                return data.q4_target_value;
                if (data.id !== "") {
                    let arr = [parseFloat(data.q1_target_value), parseFloat(data.q2_target_value), parseFloat(data.q3_target_value), parseFloat(data.q4_target_value)]
                    let sum = 0;
                    for (let i = 0; i < arr.length; i++) {
                        let value = parseFloat(arr[i]);
                        if (!isNaN(value)) {
                            sum += value;
                        }
                    }

                    if (parameterType === PARAMETERS_TYPES_NAMES.A || parameterType === PARAMETERS_TYPES_NAMES.N) {
                        return parseFloat(sum).toFixed(2);
                    } else {
                        return parseFloat(sum).toFixed(2) / 4;
                    }
                }
                return returnActual;
            default:
            // code block
        }
    }
    catch (e) {

    }


};

export { getTargetBasedOnParameterType, getTotalTarget,getTargetBasedOnParameterTypeForEvalPopup }
