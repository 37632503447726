import React, { useState, useEffect, } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import { Auth } from "aws-amplify";
import { ValidateHRRole } from '../../../components/helper/validateRole';
import HRLogin from "../../../setup/auth/hr.component";
import LeftSideBar from '../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../components/common/hr/topnav.component';
import { DataStore } from '@aws-amplify/datastore';
import { FinancialYear, CompanyActuals } from '../../../models';
import { ConvertResponseForSelect } from '../../../components/helper/commonfunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries'
import * as mutations from '../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../src/components/helper/graphQLFunctions'

export default function CreateCompanyActual() {

    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [isEditMode, setEditMode] = useState(false);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editCompanyActualId } = useParams();
    const [companySBUActualId, setCompanySBUActualId] = useState("");

    //list
    const [financialYearList, setFinancialYearList] = useState([]);
    const [companyPerformance, setCompanyPerformance] = useState("");
    const [sbuPerformanceValue, setSBUPerformanceValue] = useState("");
    const [sbuPerformanceVolume, setSBUPerformanceVolume] = useState("");
    const [sbuPerformanceTelco, setSBUPerformanceTelco] = useState("");
    const [quarterList, setQuarterList] = useState([
        {
            "label": "Q1",
            "value": "1"
        },
        {
            "label": "Q2",
            "value": "2"
        },
        {
            "label": "Q3",
            "value": "3"
        },
        {
            "label": "Q4",
            "value": "4"
        }

    ]);

    //state
    const [financialYearId, setFinancialYearId] = useState("");
    const [financialYearName, setFinancialYearName] = useState("");
    const [quarter, setQuarter] = useState("");
    const [quarterName, setQuarterName] = useState("");
    let navigateTo = useNavigate();

    const SaveCompanySBU = async () => {

        if (financialYearId === "") { toast.error("Please select Financial Year."); return; }
        else if (quarter === "") { toast.error("Please select Quarter."); return; }
        else if (companyPerformance === "") { toast.error("Please enter Company Performance."); return; }
        else if (sbuPerformanceValue === "") { toast.error("Please enter SBU Performance Value."); return; }
        else if (sbuPerformanceVolume === "") { toast.error("Please enter SBU Performance Volume."); return; }
        else if (sbuPerformanceTelco === "") { toast.error("Please enter SBU Performance Telco."); return; }

        setPleaseWaitButton(true);

        if (isEditMode) {
            // const original = await DataStore.query(CompanyActuals, companySBUActualId);
            const original = await graphQLFindRecordById('getCompanyActuals', companySBUActualId);
            // await DataStore.save(
            //     CompanyActuals.copyOf(original, (updated) => {
            //         updated.financial_year_id = financialYearId;
            //         updated.quarterNo = Number(quarter);
            //         updated.companyPerformance = companyPerformance;
            //         updated.sbuPerformanceValue = sbuPerformanceValue;
            //         updated.sbuPerformanceVolume = sbuPerformanceVolume;
            //         updated.sbuPerformanceTelco = sbuPerformanceTelco;
            //     })
            // );
            await API.graphql({
                query: mutations.updateCompanyActuals,
                variables: {
                    input: {
                        id: original.id,
                        financial_year_id: financialYearId,
                        quarterNo: Number(quarter),
                        companyPerformance: companyPerformance,
                        sbuPerformanceValue: sbuPerformanceValue,
                        sbuPerformanceVolume: sbuPerformanceVolume,
                        sbuPerformanceTelco: sbuPerformanceTelco,
                        _version: original._version,
                    }
                }
            });
            toast.success("Updated");
        }
        else {

            // await DataStore.save(
            //     new CompanyActuals({
            //         "financial_year_id": financialYearId,
            //         "quarterNo": Number(quarter),
            //         "companyPerformance": companyPerformance,
            //         "sbuPerformanceValue": sbuPerformanceValue,
            //         "sbuPerformanceVolume": sbuPerformanceVolume,
            //         "sbuPerformanceTelco": sbuPerformanceTelco
            //     })
            // ).then((response) => { })
            await API.graphql(
                graphqlOperation(mutations.createCompanyActuals, {
                    input: {
                        financial_year_id: financialYearId,
                        quarterNo: Number(quarter),
                        companyPerformance: companyPerformance,
                        sbuPerformanceValue: sbuPerformanceValue,
                        sbuPerformanceVolume: sbuPerformanceVolume,
                        sbuPerformanceTelco: sbuPerformanceTelco,
                    }
                })
            );
            toast.success("Added");
        }
        setPleaseWaitButton(false);
        navigateTo("/hr/evaluation/companysbu");
    };

    async function BindList() {
        //get Financial Year List
        // var financialYearResponse = await DataStore.query(FinancialYear);
        var financialYearResponse = await graphQLGetAllData('listFinancialYears');

        let sortArray = financialYearResponse.sort((a, b) => (a.name > b.name ? 1 : -1))
        var myArray = []; myArray = ConvertResponseForSelect(sortArray); setFinancialYearList(myArray);

    }

    async function loadExitingData() {

        if (editCompanyActualId != null) {
            setCompanySBUActualId(editCompanyActualId)
            // var actualResponse = await DataStore.query(CompanyActuals, editCompanyActualId);
            var actualResponse = await graphQLFindRecordById('getCompanyActuals', editCompanyActualId);


            if (actualResponse.financial_year_id) {
                // var financeYearResponse = await DataStore.query(FinancialYear, actualResponse.financial_year_id);
                var financeYearResponse = await graphQLFindRecordById('getFinancialYear', actualResponse.financial_year_id);

                if (financeYearResponse) {
                    setFinancialYearName({ "label": financeYearResponse.name, "value": financeYearResponse.id })
                    setFinancialYearId(actualResponse.financial_year_id)
                }
            }
            else {
                setFinancialYearName({ "label": "", "value": "" })
                setFinancialYearId("")
            }

            //set quarter
            if (actualResponse.quarterNo) {
                console.log(actualResponse.quarterNo)
                let filterValue = quarterList.filter((response) => String(actualResponse.quarterNo) === response.value)
                if (filterValue) {
                    setQuarterName({ "label": filterValue[0].label, "value": filterValue[0].value })
                    setQuarter(filterValue[0].value)
                }
            } else {
                setQuarterName({ "label": "", "value": "" })
                setQuarter("")
            }

            setCompanyPerformance(actualResponse.companyPerformance)
            setSBUPerformanceValue(actualResponse.sbuPerformanceValue)
            setSBUPerformanceVolume(actualResponse.sbuPerformanceVolume)
            setSBUPerformanceTelco(actualResponse.sbuPerformanceTelco)

            setEditMode(true)
        }
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }

        } catch (e) {
            userHasAuthenticated(false);
        }
    }
    useEffect(() => {
        onLoad();
        BindList();
        loadExitingData();
    }, []);



    return (
        isAuthenticated ? (

            <div>
                <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full">
                    <TopNavBar />
                    <LeftSideBar />
                    <div className="pl-16">
                        <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                            <div className="col-start-2 lg:col-start-3 2xl:col-start-3 xl:col-start-4 2xl:col-start-3 col-span-4 xl:col-span-4">
                                <div className="p-3 py-5 bg-white rounded-md shadow  dark:bg-[#252423]">
                                    <div className="flex justify-center mt-3 mb-2">
                                        <h2 className="page-title mb-4  ">
                                            {isEditMode ? "Edit" : "Add"} Company & SBU Actuals
                                        </h2>
                                    </div>
                                    <div x-data="{tab: 1}"></div>
                                    <div className={"block w-full"}>
                                        <div className="px-4" x-show="tab === 1">
                                            <div className="flex grid flex-row flex-wrap flex-1 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                                                <div className="relative mb-4">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        Financial Year<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                            * </label></label>
                                                    <Select placeholder='Select' options={financialYearList} value={financialYearName} onChange={e => {
                                                        setFinancialYearName(e)
                                                        setFinancialYearId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>



                                                <div className="relative mb-4">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        Quarter <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                            * </label></label>
                                                    <Select placeholder='Select' options={quarterList} value={quarterName} onChange={e => {
                                                        setQuarterName(e)
                                                        setQuarter(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>




                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Company Performance(%)
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(companyPerformance)}
                                                        onChange={(e) => {
                                                            setCompanyPerformance(e.target.value)
                                                        }}
                                                        type="number"
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Wt. Achievement %"
                                                    />
                                                </div>

                                                <div className="relative mb-4">
                                                    &nbsp;
                                                </div>


                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        SBU Performance - Value(%)
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(sbuPerformanceValue)}
                                                        onChange={(e) => {
                                                            setSBUPerformanceValue(e.target.value)
                                                        }}
                                                        type="number"
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Wt. Achievement %"
                                                    />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        SBU Performance - Volume(%)
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(sbuPerformanceVolume)}
                                                        onChange={(e) => {
                                                            setSBUPerformanceVolume(e.target.value)
                                                        }}
                                                        type="number"
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Wt. Achievement %"
                                                    />
                                                </div>


                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        SBU Performance - Telco(%)
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(sbuPerformanceTelco)}
                                                        onChange={(e) => {
                                                            setSBUPerformanceTelco(e.target.value)
                                                        }}
                                                        type="number"
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Wt. Achievement %"
                                                    />
                                                </div>



                                            </div>







                                            <div className="grow flex justify-center gap-3 mb-3 mt-5">
                                                <Link
                                                    to="/hr/evaluation/companysbu"
                                                    className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:border-[#333231] dark:text-white  dark:bg-[#4A5056] "
                                                >
                                                    Cancel
                                                </Link>
                                                <button

                                                    onClick={() => SaveCompanySBU()}
                                                    className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                                                >
                                                    {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}



