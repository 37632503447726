import { DataStore } from '@aws-amplify/datastore';
import { Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Autocomplete from "react-google-autocomplete";
import 'react-phone-input-2/lib/style.css';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';
import defaultImagePath from '../../assets/images/defaultProfile.jpg';
import LeftSideBar from '../../components/common/hr/leftnav.component';
import TopNavBar from '../../components/common/hr/topnav.component';
import { Google_API_KEY } from '../../components/helper/enum';
import { getArea, getCity, getCountry, getState, getZipCode } from '../../components/helper/getAutoCompleteAddress';
import { Employee, Roles } from "../../models";
import { ValidateHRRole } from '../../components/helper/validateRole';
import HRLogin from '../../setup/auth/hr.component';


export default function HRProfile() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [profilePicPath, setProfilePicPath] = useState("");
    const [localPicturePath, setLocalPicturePath] = useState("");
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const [roleName, setRoleName] = useState("");
    const [roleId, setRoleId] = useState("");
    const [parentEmployeeName, setParentEmployeeName] = useState("");
    const [parentEmployeeId, setParentEmployeeId] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [employeeId, setEmployeeId] = useState("");

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //Get exiting employee data
    async function getEmployeeExitingData() {

        //get Employee Existing Data
        let loggedHREmployeeId = reactLocalStorage.get('loggedHREmployeeId')
        const original = await DataStore.query(Employee, loggedHREmployeeId);
        if (original != null) {
            if (original.profilePic) {
                await Storage.get(original.profilePic)
                    .then(result => {
                        setProfilePicPath(result)
                    });
            }
            var roleResponses = await DataStore.query(Roles, original.role_id);
            setRoleName({ label: roleResponses.name, value: roleResponses.id })
            setRoleId(roleResponses.id)
            if (original.parentEmployeeId !== "" && original.parentEmployeeId !== null) {
                var parentEmployeeResponses = await DataStore.query(Employee, original.parentEmployeeId);
                setParentEmployeeId(original.parentEmployeeId);
                setParentEmployeeName({ label: parentEmployeeResponses.firstName + parentEmployeeResponses.lastName, value: parentEmployeeResponses.id })
            }
            setFirstName(original.firstName);
            setLastName(original.lastName);
            setEmail(original.email);
            setAddressOne(original.address1);
            setAddressTwo(original.address2);
            setPhone(original.phoneNo);
            setCity(original.city)
            setZip(original.zip);
            setState(original.state);
            setCountry(original.country);
            setEmployeeId(original.employeeId)
        }

    }

    //select profile picture
    const browseProfilePic = () => {
        document.getElementById('fileProfilePic').click()
    }

    //change profile picture
    async function changeProfilePicture(e) {
        let file = e.target.files[0];
        setProfilePicPath(file)
        setLocalPicturePath(URL.createObjectURL(file))
    }

    //profile picture style
    const myProfileImageStyle = {
        borderRadius: "50%",
        width: '125px',
        height: '125px',

    };

    //update profile
    const updateProfile = async () => {
        setPleaseWaitButton(true);
        let loggedHREmployeeId = reactLocalStorage.get('loggedHREmployeeId')
        //update exiting data
        const original = await DataStore.query(Employee, loggedHREmployeeId);
        await DataStore.save(
            Employee.copyOf(original, (updated) => {
                updated.role_id = roleId;
                updated.address1 = addressOne;
                updated.address2 = addressTwo;
                updated.phoneNo = phone;
                updated.city = city;
                updated.zip = zip;
                updated.state = state;
                updated.country = country;
                updated.employeeId = employeeId;
            })
        ).then(() => {
            //Update Storage
            updateProfilePicture()
            toast.success('Profile updated successfully.');
        })
        setPleaseWaitButton(false);
    }

    const updateProfilePicture = async () => {
        let loggedHREmployeeId = reactLocalStorage.get('loggedHREmployeeId')
        if (profilePicPath) {
            try {
                const fileName = 'employee/' + loggedHREmployeeId + '/profile.jpeg';

                Storage.put(fileName, profilePicPath, {
                    resumable: true,
                    completeCallback: async () => {
                        const original = await DataStore.query(Employee, loggedHREmployeeId);
                        await DataStore.save(
                            Employee.copyOf(original, (updated) => {
                                updated.profilePic = fileName
                            })
                        ).then(async () => {

                            var getFileName = 'employee/' + loggedHREmployeeId + '/profile.jpeg';
                            await Storage.get(getFileName)
                                .then(result => {
                                    reactLocalStorage.set('loggedHREmployeeProfilePic', result);
                                    window.location.reload();
                                });

                        })
                    },
                    errorCallback: (err) => {
                        toast.error(err.message)
                    }
                })
            } catch (error) {
                console.log("Error uploading file: ", error);
            }
        }
    }


    //Auto Address 
    const onPlaceSelected = (place) => {

        const addressArray = place.address_components;
        const myAddress = getArea(addressArray);
        const MyCity = getCity(addressArray);
        const MyCountry = getCountry(addressArray);
        const MyZipCode = getZipCode(addressArray);
        const MyState = getState(addressArray);

        // Set these values in the state.
        setAddressOne(myAddress)
        setCity(MyCity)
        setState(MyState)
        setZip(MyZipCode)
        setCountry(MyCountry)

    };

    useEffect(() => {
        onLoad()
        getEmployeeExitingData()
    }, []);

    return (
        isAuthenticated ? (
            <div className="pt-16">
                <TopNavBar />
                <LeftSideBar />
                <div
                    className="flex flex-1 flex-wrap flex-row justify-center align-center grid 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 gap-4 pl-5 pr-5 contract-grid-wrapper mt-5 p-5">
                    <div className="col-start-2 lg:col-start-3 2xl:col-start-3 xl:col-start-4 2xl:col-start-3 col-span-4 xl:col-span-4">
                        <div className="bg-white dark:bg-[#2A2E32] rounded-md shadow p-3 py-5 ">
                            <form
                                // action="#" 
                                // onSubmit={updateProfile}
                                autoComplete="off"
                            >
                                <div className="flex justify-center mb-10  mt-3  mb-3">
                                    <h2 className="page-title">My Profile</h2>

                                </div>
                                <div className="flex justify-center mb-1 mt-1">
                                    <label htmlFor="" className="text-[#344054] text-[18px] font-bold">{employeeId}</label>
                                </div>
                                <div className="flex justify-center relative mt-5">
                                    <div className="circle dark:border-[green] dark:text-white">
                                        <img alt="" src={localPicturePath ? localPicturePath : profilePicPath ? profilePicPath : defaultImagePath} style={myProfileImageStyle} />
                                        <div className="p-image dark:bg-[green]" onClick={browseProfilePic} >
                                            <i className="upload_icon upload-button"></i>
                                        </div>
                                        <input hidden id="fileProfilePic" type="file" onChange={changeProfilePicture} />
                                    </div>
                                </div>
                                <div className="px-4 mt-10">
                                    <div className="flex flex-1 flex-wrap flex-row align-center grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4  mb-6 ">
                                        <div className="mb-4">
                                            <label htmlFor="" className="text-[#344054] text-[14px] font-medium ">
                                                First Name </label>
                                            <div className="text-[#344054] text-[18px] font-bold  dark:text-white">{firstName}</div>
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="" className="text-[#344054] text-[14px] font-medium ">
                                                Last Name </label>
                                            <div className="text-[#344054] text-[18px] font-bold  dark:text-white">{lastName}</div>
                                        </div>

                                        <div className="mb-4">
                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                Email
                                            </label>
                                            <div className="text-[#344054] text-[18px] font-bold  dark:text-white">{email}</div>

                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="" className="text-[#344054] text-[14px] font-medium ">
                                                Phone </label>
                                            <div className="text-[#344054] text-[18px] font-bold  dark:text-white">{phone}</div>

                                        </div>

                                        <div className="relative mb-4">
                                            <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                Address 1
                                            </label>
                                            <Autocomplete
                                                defaultValue={addressOne}
                                                apiKey={Google_API_KEY}
                                                onPlaceSelected={(place) => onPlaceSelected(place)}
                                                options={{
                                                    types: ["establishment"],
                                                    componentRestrictions: { country: ["fr", "ch", "be", "us", "in"] },
                                                }}
                                                className="text-[16px] rounded-lg   flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]  dark:text-[#FFFFFF]"
                                            />
                                        </div>

                                        <div className="relative mb-4">
                                            <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                Address 2
                                            </label>
                                            <input
                                                value={addressTwo}
                                                onChange={(e) => setAddressTwo(e.target.value)}
                                                type="text"
                                                className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:text-[#FFFFFF]"
                                                placeholder="Address 2"
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                City</label>
                                            <input
                                                style={{ textTransform: "capitalize" }}
                                                type="text"
                                                value={(city)}
                                                onChange={event => setCity(event.target.value)}
                                                placeholder="City"
                                                className="text-[16px] rounded-lg   flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] " />
                                        </div>
                                        <div className="mb-4">
                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                Zip</label>
                                            <input type="text" value={zip} onChange={e => setZip(e.target.value)} className="text-[16px] rounded-lg   flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] " placeholder="Zip" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                Country</label>
                                            <CountryDropdown
                                                value={country}
                                                onChange={(val) => setCountry(val)}
                                                classNamePrefix="my-react-select"
                                                className="my-react-select-container rounded-lg border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 dark:bg-[#2A2E32] dark:border-[#4A5056] dark:text-white"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="text-[#344054] text-[14px] font-medium ">
                                                State</label>

                                            <RegionDropdown
                                                defaultOptionLabel="Select State"
                                                country={country}
                                                value={state}
                                                onChange={(val) => setState(val)}
                                                classNamePrefix="my-react-select"
                                                className="my-react-select-container  before:rounded-lg border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 dark:bg-[#2A2E32] dark:border-[#4A5056] dark:text-white"

                                            />
                                        </div>
                                    </div>
                                    <div className="grow flex justify-center gap-3 mb-3">

                                        <Link to='/hr/dashboard/planning' className="py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:border-[#4A5056] dark:bg-[#4A5056] dark:text-white">Cancel</Link>
                                        <button type="button" onClick={updateProfile} className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">{showPleaseWaitButton ? 'Please wait...' : 'Save'}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )

}