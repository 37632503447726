import React, { useState, useEffect, } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BIUnit, Brand, ProductGroup, SBU } from '../../../../../models'
import { ConvertResponseForSelect, ConvertResponseForSelectWithRefCode } from '../../../../../components/helper/commonfunctions'
import Select from 'react-select'
import { Auth } from "aws-amplify";
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import HRLogin from "../../../../../setup/auth/hr.component";
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import capitalizeFirst from '../../../../../components/common/capitalizeFirst';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

export default function CreateBusinessUnits() {

    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [businessUnitName, setBusinessUnitName] = useState("");
    const [isEditMode, setEditMode] = useState(false);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { businessUnitId } = useParams();
    const [brandList, setBrandList] = useState([]);
    const [brand, setBrand] = useState([]);

    const [sBUList, setSBUList] = useState([]);
    const [sBU, setSBU] = useState("");
    const [sBUId, setSBUId] = useState("");

    const [productGroup, setProductGroup] = useState([]);
    const [productGroupList, setProductGroupList] = useState([]);
    const [refCode, SetRefCode] = useState("");


    let navigateTo = useNavigate();

    const setBrandAndBindUpdateGroup = async (e) => {
        console.log('e',e)
        setBrand(e)
        setProductGroup([])
        let brandWiseProductGroup = [];
        //var productGroupList = await DataStore.query(ProductGroup, (c) => c.brand_id.eq(e.value));
        var productGroupList = await graphQLGetAllData('listProductGroups', [{ brand_id: { eq: e.value } }]);

        console.log('productGroupList',productGroupList)


        for (var i = 0; i < productGroupList.length; i++) {
            brandWiseProductGroup.push({ 'id': productGroupList[i].id, 'name': productGroupList[i].name });
        }
        var myProductGroupArray = []; myProductGroupArray = ConvertResponseForSelect(brandWiseProductGroup);
        setProductGroupList(myProductGroupArray);
    }

    async function BindList() {
        //brand List
        // var brandList = await DataStore.query(Brand, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var brandList = await graphQLGetAllData('listBrands');
        brandList = brandList.sort((a, b) => a.name.localeCompare(b.name))

        var myBrandListArray = []; myBrandListArray = ConvertResponseForSelect(brandList);
        setBrandList(myBrandListArray)

        //SBU List
        // var sBUList = await DataStore.query(SBU, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var sBUList = await graphQLGetAllData('listSBUS');
        sBUList = sBUList.sort((a, b) => a.name.localeCompare(b.name));

        var mySBUListArray = []; mySBUListArray = ConvertResponseForSelectWithRefCode(sBUList);
        setSBUList(mySBUListArray)
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }

            //Edit existing record
            try {
                if (businessUnitId != null) {
                    //const original = await DataStore.query(BIUnit, businessUnitId);
                    const original = await graphQLFindRecordById('getBIUnit', businessUnitId);

                    console.log('original',original)
                    if (original != null) {
                        var productGroupOriginal = ""
                        var brandOriginal = ""
                        var SbuOriginal = ""
                        if (original.product_group_id !== null) {
                            //productGroupOriginal = await DataStore.query(ProductGroup, original.product_group_id); 

                            productGroupOriginal = await graphQLFindRecordById('getProductGroup', original.product_group_id);

                            console.log('productGroupOriginal',productGroupOriginal)

                        }
                        if (productGroupOriginal.length > 0) {
                            console.log('productGroupOriginal',productGroupOriginal)
                            //brandOriginal = await DataStore.query(Brand, productGroupOriginal.br);
                            brandOriginal = await graphQLFindRecordById('getBrand', productGroupOriginal.br);

                            console.log('brandOriginal',brandOriginal)

                        }
                        if (original.sbu_id !== null) {
                            //SbuOriginal = await DataStore.query(SBU, original.sbu_id); 
                            SbuOriginal = await graphQLFindRecordById('getSBU', original.sbu_id);
                        }
                        setBrand({ label: brandOriginal.name, value: brandOriginal.id })
                        // setBrandAndBindUpdateGroup({ label: brandOriginal.name, value: brandOriginal.id })
                        setProductGroup({ label: productGroupOriginal.name, value: productGroupOriginal.id })
                        setSBU({ label: SbuOriginal.name + " (" + SbuOriginal.REF_CODE + ")", value: SbuOriginal.id })
                        setSBUId(SbuOriginal.id)
                        setBusinessUnitName(original.name);
                        setEditMode(true);
                        SetRefCode(original.REF_CODE);
                    }
                    else {
                        toast.error('Invalid record.');
                        navigateTo("/hr/setting/dimensions/brand");
                    }
                }
            } catch (e) {

            }

        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    const saveBusinessUnits = async () => {
        if (businessUnitName.trim() === "") { toast.error("Please enter Business Unit."); return; }
        else if (sBUId === "") { toast.error("Please select SBU."); return; }
        // else if (brand.length === 0) { toast.error("Please select Brand."); return; }
        // else if (productGroup.length === 0) { toast.error("Please select Product Group."); return; }

        setPleaseWaitButton(true);
        if (isEditMode) {
            //const original = await DataStore.query(BIUnit, businessUnitId);
            const original = await graphQLFindRecordById('getBIUnit', businessUnitId);
            // await DataStore.save(
            //     BIUnit.copyOf(original, (updated) => {
            //         updated.name = businessUnitName !== null ? capitalizeFirst(businessUnitName) : "";
            //         // updated.product_group_id = productGroup.value;
            //         updated.sbu_id = sBUId;
            //         updated.REF_CODE = refCode;
            //     })
            // );

            await API.graphql({
                query: mutations.updateBIUnit,
                variables: {
                    input: {
                        id: original.id,
                        name: businessUnitName !== null ? capitalizeFirst(businessUnitName) : "",
                        //product_group_id: productGroup.value,
                        sbu_id: sBUId,
                        REF_CODE: refCode,
                        _version: original._version,
                    }
                }
            });
            toast.success("Updated");
        } else {
            // await DataStore.save(new BIUnit({
            //     name: businessUnitName,
            //     // product_group_id: productGroup.value,
            //     sbu_id: sBUId,
            //     REF_CODE: capitalizeFirst(refCode),

            // }));


            await API.graphql(
                graphqlOperation(mutations.createBIUnit, {
                    input: {
                        name: businessUnitName,
                        // product_group_id: productGroup.value,
                        sbu_id: sBUId,
                        REF_CODE: capitalizeFirst(refCode),
                    }
                })
            );

            toast.success("Added");
        }
        setPleaseWaitButton(false);
        navigateTo("/hr/setting/bussinessunits");
    };

    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    return (
        isAuthenticated ? (
            <div>
                <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full">
                    <TopNavBar />
                    <LeftSideBar />
                    <div className="pl-16">
                        <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                            <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                                <div className="p-3 py-5 bg-white rounded-md shadow  dark:bg-[#252423]">
                                    <div className="flex justify-center mt-3 mb-2">
                                        <h2 className="page-title   ">
                                            {isEditMode ? "Edit" : "Create"} Business Unit
                                        </h2>
                                    </div>
                                    <div x-data="{tab: 1}"></div>
                                    <div className={"block w-full"}>
                                        <div className="px-4" x-show="tab === 1">
                                            <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                                                <div className="relative mb-4 ">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium"
                                                    >
                                                        Name
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *{" "}
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(businessUnitName)}
                                                        onChange={(e) => setBusinessUnitName(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]  dark:border-[#333231]  dark:bg-[#333231]"
                                                        placeholder="Enter Business Unit"
                                                    />
                                                </div>
                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Ref_Code
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >

                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(refCode)}
                                                        onChange={(e) => SetRefCode(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Ref Code"
                                                    />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        SBU <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                            * </label></label>
                                                    <Select placeholder='Select SBU' options={sBUList} value={sBU} onChange={e => {
                                                        setSBU(e)
                                                        setSBUId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>


                                                {/* <div className="relative mb-4">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        Brand </label>
                                                    <Select placeholder='Select Brand' options={brandList} value={brand} onChange={e => {
                                                        setBrandAndBindUpdateGroup(e)


                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        Product Group </label>
                                                    <Select placeholder='Select Product Group'
                                                        options={productGroupList}
                                                        value={productGroup}
                                                        onChange={e => {
                                                            setProductGroup(e)
                                                        }}
                                                        className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div> */}
                                            </div>
                                            <div className="grow flex justify-center gap-3 mb-3">
                                                <Link
                                                    to="/hr/setting/bussinessunits"
                                                    className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:border-[#333231] dark:text-white  dark:bg-[#4A5056] "
                                                >
                                                    Cancel
                                                </Link>
                                                <button
                                                    onClick={() => saveBusinessUnits()}
                                                    className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                                                >
                                                    {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}