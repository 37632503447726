import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { reactLocalStorage } from 'reactjs-localstorage';
import { USER_TYPES } from '../../components/helper/enum';
import { ValidateEmployeeRole } from '../../components/helper/validateRole';
import { useMsal } from "@azure/msal-react";
import logo from '../../assets/images/logo-landing.png';
import DefaultProfilePic from '../../assets/images/user.png'
import { graphQLGetAllData, graphQLFindRecordById } from '../../components/helper/graphQLFunctions';

export default function EmpLogin(props) {
    let navigateTo = useNavigate();

    const { instance } = useMsal();
    const [employeeList, setEmployeeList] = useState([]);
    const [isListUpdated, setIsListUpdated] = useState(false);

    let isEmployeeRole = ValidateEmployeeRole();

    // if (isEmployeeRole === true) {
    //     navigateTo('/employee/landing');
    // }

    async function doAuth() {

        try {
            // instance.loginRedirect().then(async (response) => {


            // });

            console.log("end");

            await instance.loginPopup().then(async (response) => {
                const userEmail = response.account.username.toLocaleLowerCase();
                console.log("userEmail", userEmail);
                console.log("employeeList", employeeList);
                let getLoginEmployee = employeeList.find(o => o.email === userEmail.toLocaleLowerCase());
                if (getLoginEmployee) {
                    // let isReportingManager = await graphQLGetAllData("listEmployees", [{ parentEmployeeId: { eq: getLoginEmployee.id } }]);
                    let isReportingManager = employeeList.filter(c => c.parentEmployeeId === getLoginEmployee.id)

                    if (isReportingManager.length > 0) {
                        reactLocalStorage.set('isReportingManager', "1");
                    } else {
                        reactLocalStorage.set('isReportingManager', "0");
                    }

                    reactLocalStorage.set('loggedUserRole', USER_TYPES.Employee);
                    reactLocalStorage.set('loggedEmployeeId', getLoginEmployee.id);
                    reactLocalStorage.set('loggedEmployeeReportingManagerId', getLoginEmployee.parentEmployeeId);
                    reactLocalStorage.set('loggedEmployeeFirstName', getLoginEmployee.firstName);
                    reactLocalStorage.set('loggedEmployeeLastName', getLoginEmployee.lastName);
                    reactLocalStorage.set('loggedEmployeeProfilePic', DefaultProfilePic);


                    //Get Role
                    // let getEmployeeRole = await DataStore.query(Roles, (c) => c.id.eq( getLoginEmployee.role_id));
                    let getEmployeeRole = await graphQLFindRecordById("getRoles", getLoginEmployee.role_id)

                    reactLocalStorage.set('loggedEmployeeRoleId', getLoginEmployee.role_id);
                    reactLocalStorage.set('loggedEmployeeRoleName', getEmployeeRole.name);
                    reactLocalStorage.set('loggedEmployeeTeamType', getEmployeeRole.teamType);

                    //Profile Pic Path
                    if (getLoginEmployee.profilePic) {
                        await Storage.get(getLoginEmployee.profilePic)
                            .then(result => {
                                reactLocalStorage.set('loggedEmployeeProfilePic', result);
                            });
                    }

                    reactLocalStorage.set('loggedEmployeeIsAssignByHR', getLoginEmployee.isAssignDirectTarget);

                    navigateTo('/employee/landing');
                }
                else {
                    toast.error("User not found.");
                    doAuthLogout();
                }

            });
        }
        catch (e) {
            console.log("captured", e)
        }
    }

    const doAuthLogout = () => {
        reactLocalStorage.clear();
        instance.logoutPopup();
    }


    async function onLoad() {
        let employeeResponses = await graphQLGetAllData('listEmployees');
        setEmployeeList(employeeResponses);
        setIsListUpdated(true);
    }


    useEffect(() => {
        onLoad();
    }, []);

    useEffect(() => {
        if (isListUpdated === true) {
            doAuth();
        }
    }, [isListUpdated]);

    return (
        <div>
            <div
                className="min-h-screen flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:grid-cols-1 gap-0">
                <div className="flex flex-wrap flex-row justify-center auto-rows-max login bg-white dark:bg-[#1E2124]">
                    <div className="max-w-md 2xl:max-w-lg w-full p-2">

                        <div className="mb-20 md:mb-8 2xl:mb-30 lg:mb-22 xl:mb-30 xl:mt-8">
                            <img className="2xl:h-16 h-10" src={logo} alt="" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}