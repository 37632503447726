import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import TopNav from "../../../../components/common/employee/topnav.component";
import LeftNav from "../../../../components/common/employee/leftnav.component";
import quarterIcon from "../../../../assets/svg/quarter_icon.svg";
import AnnualSaleIcon from "../../../../assets/svg/annual_sale_icon.svg";
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import EmpLogin from "../../../../setup/auth/emp.component";
import popupHeaderImg from "../../../../assets/images/popup_header_img.png";
import {
  PARAMETERS_TYPES,
  PARAMETERS_TYPES_NAMES,
} from "../../../../components/helper/enum";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import {
  ConvertResponseForSelectWithRefCode,
  RemoveItemFromArrayByIdValue,
  GetSingleValueFromResponse,
  GetArrayItemIndexFromKeyValue,
  GetArrayPreviousItemFromKeyValue,
  GetArrayNextItemFromKeyValue,
  SetRegionAggregateValueFromPlanArray,
  SetCountryAggregateValueFromPlanArray,
  SetBrandAggregateValueFromPlanArray,
  SetProductGroupAggregateValueFromPlanArray,
  SetCustomerSegmentAggregateValueFromPlanArray,
  SetCustomerAggregateValueFromPlanArray,
  GetTotalPercentageFromPlanArray,
  SetBuAggregateValueFromPlanArray,
  SetGTMAggregateValueFromPlanArray,
  SetSubGTMAggregateValueFromPlanArray,
} from "../../../../components/helper/commonfunctions";
import {
  commaSeparated,
  isPositiveInteger,
  ShowInMillion,
  sumFromArray,
} from "../../../../components/helper/maths";

import { toast } from "react-toastify";
import uniqid from "uniqid";
import { reactLocalStorage } from "reactjs-localstorage";

import { Carousel } from "primereact/carousel";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Avatar } from "@mui/material";
import * as alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { sendNotification } from "../../../../components/helper/notification";
import {
  NOTIFICATIONS_TYPES,
  EMAIL_SUBJECTS,
} from "../../../../components/helper/enum";
import { ProgressSpinner } from "primereact/progressspinner";
import noCommentsImg from "../../../../assets/images/noComment.png";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import defaultImagePath from "../../../../assets/images/defaultProfile.jpg";
import popupHeader from "../../../../assets/images/popup_header_img.png";
import { checkEmployeeTeamType } from "../../helper";
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../components/helper/graphQLFunctions';
import * as mutations from '../../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { Storage } from 'aws-amplify';



export default function ManageTarget_AnnualTarget() {
  let navigateTo = useNavigate();
  let { financialYearId, parameterId, team } = useParams();
  const [isPageLoaded, setPageLoaded] = useState(false);
  const [isShowConfirmationPopUp, setIsShowConfirmationPopUp] = useState(0);
  const [markAsIncompleteParameters, setMarkAsIncompleteParameters] =
    useState(0);
  const [prevParameterId, setPrevParameterId] = useState("");
  const [nextParameterId, setNextParameterId] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [checkType, setCheckType] = useState("");

  var loggedEmployeeId = reactLocalStorage.get("loggedEmployeeId");
  var loggedEmployeeRoleId = reactLocalStorage.get("loggedEmployeeRoleId");
  var loggedEmployeeReportingManagerId = reactLocalStorage.get("loggedEmployeeReportingManagerId");
  const [isShowRemark, setIsShowRemark] = useState(true);
  const [parametersCarousel, setParametersCarousel] = useState([]);
  const [carouselActivePageNo, setCarouselActivePageNo] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);

  const parametersCarouselResponsiveOptions = [
    {
      breakpoint: "1600px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "1280px",
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  //Validate User Logged In
  const [isAuthenticated, userHasAuthenticated] = useState(true);

  async function onLoad() {
    try {
      if (!ValidateEmployeeRole()) {
        userHasAuthenticated(false);
      }
    } catch (e) {
      console.log("error", e)
      userHasAuthenticated(false);
    }
  }

  //Plan Table State
  const [planData, setPlanData] = useState([]);
  const [isPlanUpdated, setPlanUpdated] = useState(false);

  //SBU
  //----------------------

  //----------------------

  //BU
  //----------------------
  const [buList, SetBuList] = useState([]);
  const [allBuList, SetAllBU] = useState([]);
  const [buDropdownList, setBuDropdownList] = useState([]);

  // const getBULabel = (id) => {
  //   // let _buList = [...allBuList];
  //   var returnRecord = allBuList.find((o) => o.id === id);
  //   if (returnRecord != null) {
  //     return returnRecord.name;
  //   }
  // };

  const buBodyTemplate = (rowData) => {
    var returnRecord = allBuList.find((o) => o.id === rowData.buId);
    return returnRecord ? returnRecord.name : '-';

  };

  //----------------------

  //Region
  //----------------------
  const [regionList, SetRegionList] = useState([]);
  const [allRegionList, SetAllRegionList] = useState([]);
  const [regionDropdownList, setRegionDropdownList] = useState([]);


  // const getRegionLabel = (id) => {
  //   // let _regionList = [...allRegionList];
  //   var returnRecord = allRegionList.find((o) => o.id === id);
  //   if (returnRecord != null) {
  //     return returnRecord.name;
  //   }
  // };

  const regionBodyTemplate = (rowData) => {
    var returnRecord = allRegionList.find((o) => o.id === rowData.regionId);
    return returnRecord ? returnRecord.name : '-';
  };
  //----------------------

  //Country
  //----------------------
  const [countryList, SetCountryList] = useState([]);
  const [selectCountryList, SetSelectCountryList] = useState([]);
  const [countryDropdownList, setCountryDropdownList] = useState([]);



  const countryBodyTemplate = (rowData) => {
    var returnRecord = selectCountryList.find((o) => o.id === rowData.countryId);
    return returnRecord ? returnRecord.name : '-';
  };
  //----------------------

  //Brand
  //----------------------
  const [brandList, SetBrandList] = useState([]);
  const [selectBrandList, SetSelectBrandList] = useState([]);
  const [brandDropdownList, setBrandDropdownList] = useState([]);

  const brandBodyTemplate = (rowData) => {
    var returnRecord = selectBrandList.find((o) => o.id === rowData.brandId);
    return returnRecord ? returnRecord.name : '-';
  };
  //----------------------

  //Product Group
  //----------------------
  const [productGroupList, SetProductGroupList] = useState([]);
  const [selectProductGroupList, SetSelectProductGroupList] = useState([]);
  const [productGroupDropdownList, setProductGroupDropdownList] = useState([]);


  const getProductGroupLabel = (id) => {
    let _productGroupList = [...selectProductGroupList];
    var returnRecord = _productGroupList.find((o) => o.id === id);
    if (returnRecord != null) {
      return returnRecord.name;
    }
  };

  const productGroupBodyTemplate = (rowData) => {
    return getProductGroupLabel(rowData.productGroupId);
  };
  //----------------------

  //Customer Group
  //----------------------
  const [customerGroupList, SetCustomerGroupList] = useState([]);
  const [customerGroupDropdownList, setCustomerGroupDropdownList] = useState(
    [],
  );


  const getCustomerGroupLabel = (id) => {
    let _customerGroupList = [...customerGroupList];
    var returnRecord = _customerGroupList.find((o) => o.id === id);
    if (returnRecord != null) {
      return returnRecord.name;
    }
  };

  const customerGroupBodyTemplate = (rowData) => {
    return getCustomerGroupLabel(rowData.customerGroupId);
  };
  //----------------------

  //Customer
  //----------------------
  const [customerList, SetCustomerList] = useState([]);
  const [selectCustomerList, SetSelectCustomerList] = useState([]);
  const [customerDropdownList, setCustomerDropdownList] = useState([]);

  const getCustomerLabel = (id) => {
    let _customerList = [...selectCustomerList];
    var returnRecord = _customerList.find((o) => o.id === id);
    if (returnRecord != null) {
      return returnRecord.name;
    }
  };

  const customerBodyTemplate = (rowData) => {
    return getCustomerLabel(rowData.customerId);
  };

  //----------------------

  //GTM
  //----------------------

  const [GTMDropdownList, setGTMDropdownList] = useState([]);

  // const getGTMLabel = (id) => {
  //   // let _GTMList = [...GTMDropdownList];
  //   var returnRecord = _GTMList.find((o) => o.value === id);
  //   if (returnRecord != null) {
  //     return returnRecord.label;
  //   }
  // };

  const GTMBodyTemplate = (rowData) => {
    var returnRecord = GTMDropdownList.find((o) => o.value === rowData.gtmId);
    return returnRecord ? returnRecord.label : '-';
  };
  //----------------------

  //SubGTM
  //----------------------
  const [SubGTMDropdownList, setSubGTMDropdownList] = useState([]);
  const [allSubGTMList, SetAllSubGTMList] = useState([]);

  // const getSubGTMLabel = (id) => {
  //   let _SubGTMList = [...allSubGTMList];
  //   var returnRecord = _SubGTMList.find((o) => o.id === id);
  //   if (returnRecord != null) {
  //     return returnRecord.name;
  //   }
  // };

  const SubGTMBodyTemplate = (rowData) => {
    var returnRecord = allSubGTMList.find((o) => o.id === rowData.subGtmId);
    return returnRecord ? returnRecord.name : '-';
  };
  //----------------------

  //Target Value Calculation
  //----------------------
  const targetValueEditor = (options) => {
    return (
      <input
        type="number"
        disabled={isDisabled ? isDisabled : false}
        className="text-[16px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white 
        text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500  placeholder:text-[#667085] "
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  //Dis Equally
  //----------------------
  const disEquallyList = [
    { name: "Yes", id: 1 },
    { name: "No", id: 0 },
  ];
  const disEquallyEditor = (options) => {
    return (
      <Dropdown
        disabled={isDisabled ? isDisabled : false}
        value={options.value}
        options={disEquallyList}
        optionLabel="name"
        optionValue="id"
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Select"
        itemTemplate={(option) => {
          return <span>{option.name}</span>;
        }}
      />
    );
  };

  const getDisEquallyLabel = (disEqually) => {
    switch (disEqually) {
      case 1:
        return "Yes";
      case 0:
        return "No";
      default:
        return "No";
    }
  };

  const disEquallyBodyTemplate = (rowData) => {
    return getDisEquallyLabel(rowData.disEqually);
  };
  //----------------------

  const onCellEditComplete = (e) => {
    let _planData = [...planData];
    let { newRowData, field, rowIndex, newValue, originalEvent: event } = e;

    switch (field) {
      case "targetValue":
      case "Q1TargetValue":
      case "Q2TargetValue":
      case "Q3TargetValue":
      case "Q4TargetValue":
        if (isPositiveInteger(newValue)) newRowData[field] = newValue;
        else event.preventDefault();
        break;
      default:
        break;
    }

    if (field === "disEqually") {
      if (newRowData.disEqually === 0) {
        let currentTargetValue = parseFloat(newRowData.targetValue);
        if (
          currentTargetValue > 0 &&
          (parameterType === "A" || parameterType === "N")
        ) {
          let equalQuarterValue = currentTargetValue / 4;
          equalQuarterValue = equalQuarterValue.toFixed(2);
          newRowData.Q1TargetValue = equalQuarterValue;
          newRowData.Q2TargetValue = equalQuarterValue;
          newRowData.Q3TargetValue = equalQuarterValue;
          newRowData.Q4TargetValue = equalQuarterValue;
        } else if (
          currentTargetValue > 0 &&
          (parameterType === "P" || parameterType === "D")
        ) {
          newRowData.Q1TargetValue = currentTargetValue;
          newRowData.Q2TargetValue = currentTargetValue;
          newRowData.Q3TargetValue = currentTargetValue;
          newRowData.Q4TargetValue = currentTargetValue;
        }

        // newRowData.Q1TargetValue = "";
        // newRowData.Q2TargetValue = "";
        // newRowData.Q3TargetValue = "";
        // newRowData.Q4TargetValue = "";
      }
    } else if (field === "targetValue") {
      if (newRowData.disEqually === 1) {
        let currentTargetValue = parseFloat(newRowData.targetValue);
        if (
          currentTargetValue > 0 &&
          (parameterType === "A" || parameterType === "N")
        ) {
          let equalQuarterValue = currentTargetValue / 4;
          equalQuarterValue = equalQuarterValue.toFixed(2);
          newRowData.Q1TargetValue = equalQuarterValue;
          newRowData.Q2TargetValue = equalQuarterValue;
          newRowData.Q3TargetValue = equalQuarterValue;
          newRowData.Q4TargetValue = equalQuarterValue;
        } else if (
          currentTargetValue > 0 &&
          (parameterType === "P" || parameterType === "D")
        ) {
          newRowData.Q1TargetValue = currentTargetValue;
          newRowData.Q2TargetValue = currentTargetValue;
          newRowData.Q3TargetValue = currentTargetValue;
          newRowData.Q4TargetValue = currentTargetValue;
        }
      }
    } else if (
      (field === "Q1TargetValue" ||
        field === "Q2TargetValue" ||
        field === "Q3TargetValue" ||
        field === "Q4TargetValue") &&
      newRowData.disEqually === 1
    ) {
      if (
        newRowData.Q1TargetValue !== "" ||
        newRowData.Q2TargetValue !== "" ||
        newRowData.Q3TargetValue !== "" ||
        newRowData.Q4TargetValue !== ""
      ) {
        let currentQ1TargetValue = parseFloat(newRowData.Q1TargetValue);
        let currentQ2TargetValue = parseFloat(newRowData.Q2TargetValue);
        let currentQ3TargetValue = parseFloat(newRowData.Q3TargetValue);
        let currentQ4TargetValue = parseFloat(newRowData.Q4TargetValue);

        if (parameterType === "A" || parameterType === "N") {
          let equalQuarterValue =
            currentQ1TargetValue +
            currentQ2TargetValue +
            currentQ3TargetValue +
            currentQ4TargetValue;
          if (equalQuarterValue > 0) {
            equalQuarterValue = equalQuarterValue.toFixed(2);
            newRowData.targetValue = equalQuarterValue;
          }
        } else if (parameterType === "P" || parameterType === "D") {
          let equalQuarterValue =
            currentQ1TargetValue +
            currentQ2TargetValue +
            currentQ3TargetValue +
            currentQ4TargetValue;
          if (equalQuarterValue > 0) {
            equalQuarterValue = equalQuarterValue.toFixed(2) / 4;
            //Old Code --- time being hide
            //equalQuarterValue = equalQuarterValue.toFixed(2);
            newRowData.targetValue = equalQuarterValue;
          }
        }
      }
    }

    _planData[rowIndex] = newRowData;
    setPlanData(_planData);
    setPlanUpdated(true);
  };

  //----------------------

  //Work on Plan Tables

  const [sbuId, setSbuId] = useState("");

  const [sbuDropdownId, setSbuDropdownId] = useState("");
  const [sbuDropdown, setSbuDropdown] = useState("");

  const [buDropdownId, setBuDropdownId] = useState("");
  const [buDropdown, setBuDropdown] = useState("");

  const [regionDropdownId, setRegionDropdownId] = useState("");
  const [regionDropdown, setRegionDropdown] = useState("");

  const [countryDropdownId, setCountryDropdownId] = useState("");
  const [countryDropdown, setCountryDropdown] = useState("");

  const [brandDropdownId, setBrandDropdownId] = useState("");
  const [brandDropdown, setBrandDropdown] = useState("");

  const [productGroupDropdownId, setProductGroupDropdownId] = useState("");
  const [productGroupDropdown, setProductGroupDropdown] = useState("");

  const [customerGroupDropdownId, setCustomerGroupDropdownId] = useState("");
  const [customerGroupDropdown, setCustomerGroupDropdown] = useState("");

  const [customerDropdownId, setCustomerDropdownId] = useState("");
  const [customerDropdown, setCustomerDropdown] = useState("");

  const [GTMdropdownId, setGTMdropdownId] = useState("");
  const [GTMdropdown, setGTMdropdown] = useState("");

  const [SubGTMdropdownId, setSubGTMdropdownId] = useState("");
  const [SubGTMdropdown, setSubGTMdropdown] = useState("");

  const [targetValueInput, setTargetValueInput] = useState("");

  const deleteBodyTemplate = (rowData) => {
    return (
      <i
        onClick={(e) => removePlanItem(rowData.id)}
        style={{ cursor: "pointer" }}
        className="icon delete_icon"
      ></i>
    );
  };

  const removePlanItem = (removeId) => {
    let _planData = [...planData];
    let _newPlanData = RemoveItemFromArrayByIdValue(_planData, removeId);
    setPlanData(_newPlanData);
    setPlanUpdated(true);
  };

  const addPlanItem = () => {
    if (buDropdownId === "") {
      toast.error("Please select BU.");
      return;
    }

    let _planData = [...planData];

    if (_planData.length > 0) {
      let check_parameterData = _planData.every(
        (e) =>
          e.buId !== buDropdownId ||
          e.regionId !== regionDropdownId ||
          e.countryId !== countryDropdownId ||
          e.brandId !== brandDropdownId ||
          e.productGroupId !== productGroupDropdownId ||
          e.customerGroupId !== customerGroupDropdownId,
      );
      if (check_parameterData === true) {
        _planData.push({
          id: uniqid(),
          sbuId: sbuId,
          buId: buDropdownId,
          regionId: regionDropdownId,
          countryId: countryDropdownId,
          brandId: brandDropdownId,
          productGroupId: productGroupDropdownId,
          customerGroupId: customerGroupDropdownId,
          customerId: customerDropdownId,
          gtmId: GTMdropdownId,
          subGtmId: SubGTMdropdownId,
          targetValue: targetValueInput,
          Q1TargetValue: "",
          Q2TargetValue: "",
          Q3TargetValue: "",
          Q4TargetValue: "",
          disEqually: 1,
        });
      } else {
        toast.error("Duplicate Parameters.");
        return;
      }
    } else {
      _planData.push({
        id: uniqid(),
        sbuId: sbuId,
        buId: buDropdownId,
        regionId: regionDropdownId,
        countryId: countryDropdownId,
        brandId: brandDropdownId,
        productGroupId: productGroupDropdownId,
        customerGroupId: customerGroupDropdownId,
        customerId: customerDropdownId,
        gtmId: GTMdropdownId,
        subGtmId: SubGTMdropdownId,
        targetValue: targetValueInput,
        Q1TargetValue: "0",
        Q2TargetValue: "0",
        Q3TargetValue: "0",
        Q4TargetValue: "0",
        disEqually: 1,
      });
    }

    setPlanData(_planData);
    setPlanUpdated(true);

    setSbuDropdownId("");
    setSbuId("");
    setBuDropdownId("");
    setRegionDropdownId("");
    setCountryDropdownId("");
    setBrandDropdownId("");
    setProductGroupDropdownId("");
    setCustomerGroupDropdownId("");
    setCustomerDropdownId("");
    setTargetValueInput("");
    setSbuDropdown("");
    setBuDropdown("");
    setRegionDropdown("");
    setCountryDropdown("");
    setBrandDropdown("");
    setProductGroupDropdown("");
    setCustomerGroupDropdown("");
    setCustomerDropdown("");
    setCountryDropdownList([]);
    setBrandDropdownList([]);
    setProductGroupDropdownList([]);
    setCustomerDropdownList([]);
    setGTMdropdownId("");
    setGTMdropdown("");
    setSubGTMdropdownId("");
    setSubGTMdropdown("");
  };

  const onPlanItemSelectedChange = async (type, e) => {
    if (type === "S") {
      if (e != null) {
        setSbuDropdownId(e.value);
        setSbuDropdown(e);
      } else {
        setSbuDropdownId("");
        setSbuDropdown("");
      }
    } else if (type === "BU") {
      if (e != null) {
        setBuDropdownId(e.value);
        setBuDropdown(e);
        onClickBU(e);
      } else {
        setBuDropdownId("");
        setBuDropdown("");
      }
    } else if (type === "R") {
      if (e != null) {
        setRegionDropdownId(e.value);
        setRegionDropdown(e);
        onClickRegion(e);
      } else {
        setRegionDropdownId("");
        setRegionDropdown("");
      }
    } else if (type === "C") {
      if (e != null) {
        setCountryDropdownId(e.value);
        setCountryDropdown(e);
      } else {
        setCountryDropdownId("");
        setCountryDropdown("");
      }
    } else if (type === "B") {
      if (e != null) {
        setBrandDropdownId(e.value);
        setBrandDropdown(e);
        onClickBrand(e);
      } else {
        setBrandDropdownId("");
        setBrandDropdown("");
      }
    } else if (type === "PG") {
      if (e != null) {
        setProductGroupDropdownId(e.value);
        setProductGroupDropdown(e);
      } else {
        setProductGroupDropdownId("");
        setProductGroupDropdown("");
      }
    } else if (type === "CG") {
      if (e != null) {
        setCustomerGroupDropdownId(e.value);
        setCustomerGroupDropdown(e);
        onClickCustomerGroup(e);
      } else {
        setCustomerGroupDropdownId("");
        setCustomerGroupDropdown("");
      }
    } else if (type === "Cus") {
      if (e != null) {
        setCustomerDropdownId(e.value);
        setCustomerDropdown(e);
      } else {
        setCustomerDropdownId("");
        setCustomerDropdown("");
      }
    } else if (type === "Gtm") {
      if (e != null) {
        setGTMdropdownId(e.value);
        setGTMdropdown(e);
        onClickGTM(e);
      } else {
        setGTMdropdownId("");
        setGTMdropdown("");
      }
    } else if (type === "SubGtm") {
      if (e != null) {
        setSubGTMdropdownId(e.value);
        setSubGTMdropdown(e);
      } else {
        setSubGTMdropdownId("");
        setSubGTMdropdown("");
      }
    }
  };

  //Calculate Dimension
  const [buDimensionData, setBuDimensionData] = useState([]);
  const [buDimensionFooterTotal, setBuDimensionFooterTotal] = useState("");

  const [regionDimensionData, setRegionDimensionData] = useState([]);
  const [regionDimensionFooterTotal, setRegionDimensionFooterTotal] =
    useState("");

  const [countryDimensionData, setCountryDimensionData] = useState([]);
  const [countryDimensionFooterTotal, setCountryDimensionFooterTotal] =
    useState("");

  const [brandDimensionData, setBrandDimensionData] = useState([]);
  const [brandDimensionFooterTotal, setBrandDimensionFooterTotal] =
    useState("");

  const [gtmDimensionData, setGTMDimensionData] = useState([]);
  const [gtmDimensionFooterTotal, setGTMDimensionFooterTotal] = useState("");

  const [subGtmDimensionData, setSubGTMDimensionData] = useState([]);
  const [subGtmDimensionFooterTotal, setSubGTMDimensionFooterTotal] =
    useState("");

  const [productGroupDimensionData, setProductGroupDimensionData] = useState(
    [],
  );
  const [
    productGroupDimensionFooterTotal,
    setProductGroupDimensionFooterTotal,
  ] = useState("");

  const [customerSegmentDimensionData, setCustomerSegmentDimensionData] =
    useState([]);
  const [
    customerSegmentDimensionFooterTotal,
    setCustomerSegmentDimensionFooterTotal,
  ] = useState("");

  const [customerDimensionData, setCustomerDimensionData] = useState([]);
  const [customerDimensionFooterTotal, setCustomerDimensionFooterTotal] =
    useState("");

  //Plan Footer
  const [planTotalTargetFooterTotal, setPlanTotalTargetFooterTotal] =
    useState("");
  const [planQ1TotalTargetFooterTotal, setPlanQ1TotalTargetFooterTotal] =
    useState("");
  const [planQ2TotalTargetFooterTotal, setPlanQ2TotalTargetFooterTotal] =
    useState("");
  const [planQ3TotalTargetFooterTotal, setPlanQ3TotalTargetFooterTotal] =
    useState("");
  const [planQ4TotalTargetFooterTotal, setPlanQ4TotalTargetFooterTotal] =
    useState("");

  const CalculateDimension = () => {
    if (isPlanUpdated === false) {
      return;
    }

    //Get Target Total
    var totalAddedTarget = 0,
      q1Total = 0,
      q2Total = 0,
      q3Total = 0,
      q4Total = 0;
    var q1_avg = 0,
      q2_avg = 0,
      q3_avg = 0,
      q4_avg = 0,
      total_avg = 0;
    planData.map((currentItem) => {
      if (parseFloat(currentItem.targetValue) > 0) {
        totalAddedTarget =
          totalAddedTarget + parseFloat(currentItem.targetValue);
      }
      if (parseFloat(currentItem.Q1TargetValue) > 0) {
        q1Total = q1Total + parseFloat(currentItem.Q1TargetValue);
      }
      if (parseFloat(currentItem.Q2TargetValue) > 0) {
        q2Total = q2Total + parseFloat(currentItem.Q2TargetValue);
      }
      if (parseFloat(currentItem.Q3TargetValue) > 0) {
        q3Total = q3Total + parseFloat(currentItem.Q3TargetValue);
      }
      if (parseFloat(currentItem.Q4TargetValue) > 0) {
        q4Total = q4Total + parseFloat(currentItem.Q4TargetValue);
      }
      totalAddedTarget = q1Total + q2Total + q3Total + q4Total;
      return currentItem;
    });

    if (
      parameterType === PARAMETERS_TYPES.Days ||
      parameterType === PARAMETERS_TYPES.Percentage
    ) {
      total_avg = totalAddedTarget / planData.length;
      q1_avg = q1Total / planData.length;
      q2_avg = q2Total / planData.length;
      q3_avg = q3Total / planData.length;
      q4_avg = q4Total / planData.length;
      if (parseFloat(q1_avg.toFixed(2)) > 0) {
        parameterType === PARAMETERS_TYPES.Days ?

          setParameterQ1Target(parseFloat(q1_avg.toFixed(0))) :
          setParameterQ1Target(parseFloat(q1_avg.toFixed(2)));
      } else {
        setParameterQ1Target(0);
      }
      if (parseFloat(q2_avg.toFixed(2)) > 0) {
        parameterType === PARAMETERS_TYPES.Days ?
          setParameterQ2Target(parseFloat(q2_avg.toFixed(0))) :
          setParameterQ2Target(parseFloat(q2_avg.toFixed(2)));
      } else {
        setParameterQ2Target(0);
      }
      if (parseFloat(q3_avg.toFixed(2)) > 0) {
        parameterType === PARAMETERS_TYPES.Days ?
          setParameterQ3Target(parseFloat(q3_avg.toFixed(0))) :
          setParameterQ3Target(parseFloat(q3_avg.toFixed(2)));
      } else {
        setParameterQ3Target(0);
      }
      if (parseFloat(q4_avg.toFixed(2)) > 0) {
        parameterType === PARAMETERS_TYPES.Days ?
          setParameterQ4Target(parseFloat(q4_avg.toFixed(0))) :
          setParameterQ4Target(parseFloat(q4_avg.toFixed(2)));
      } else {
        setParameterQ4Target(0);
      }
    } else {
      setParameterQ1Target(q1Total.toFixed(2));
      setParameterQ2Target(q2Total.toFixed(2));
      setParameterQ3Target(q3Total.toFixed(2));
      setParameterQ4Target(q4Total.toFixed(2));
    }

    //Calculate Percentage
    let totalMatchPer = 0;
    let q1Per = (q1Total * 100) / parameterAnnualTarget;
    if (q1Per > 0) {
      q1Per = parseFloat(q1Per).toFixed(2);
      setParameterQ1Percentage(q1Per);
    }
    let q2Per = (q2Total * 100) / parameterAnnualTarget;
    if (q2Per > 0) {
      q2Per = parseFloat(q2Per).toFixed(2);
      setParameterQ2Percentage(q2Per);
    }
    let q3Per = (q3Total * 100) / parameterAnnualTarget;
    if (q3Per > 0) {
      q3Per = parseFloat(q3Per).toFixed(2);
      setParameterQ3Percentage(q3Per);
    }
    let q4Per = (q4Total * 100) / parameterAnnualTarget;
    if (q4Per > 0) {
      q4Per = parseFloat(q4Per).toFixed(2);
      setParameterQ4Percentage(q4Per);
    }

    if (totalAddedTarget > 0) {
      if (
        parameterType === PARAMETERS_TYPES.Days ||
        parameterType === PARAMETERS_TYPES.Percentage
      ) {
        totalMatchPer = (totalAddedTarget * 100) / parameterAnnualTarget;
        totalMatchPer = parseFloat(totalMatchPer).toFixed(2) / planData.length;
      } else {
        totalMatchPer = (totalAddedTarget * 100) / parameterAnnualTarget;
        totalMatchPer = parseFloat(totalMatchPer).toFixed(2);
      }
    }
    setParameterMatchPer(totalMatchPer);

    //calculate avg for Days and Percentage
    if (
      parameterType === PARAMETERS_TYPES.Days ||
      parameterType === PARAMETERS_TYPES.Percentage
    ) {
      total_avg = totalAddedTarget / planData.length;
      q1_avg = q1Total / planData.length;
      q2_avg = q2Total / planData.length;
      q3_avg = q3Total / planData.length;
      q4_avg = q4Total / planData.length;

      if (parseFloat(total_avg.toFixed(2)) > 0) {
        setPlanTotalTargetFooterTotal(
          parseFloat(total_avg.toFixed(2)),
        );
      }
      if (parseFloat(q1_avg.toFixed(2)) > 0) {
        parameterType === PARAMETERS_TYPES.Days ?
          setPlanQ1TotalTargetFooterTotal(
            commaSeparated(Math.round(q1_avg))) :
          setPlanQ1TotalTargetFooterTotal(
            commaSeparated(parseFloat(q1_avg.toFixed(2)))
          );
      }
      if (parseFloat(q2_avg.toFixed(2)) > 0)
        parameterType === PARAMETERS_TYPES.Days ?
          setPlanQ2TotalTargetFooterTotal(
            commaSeparated(Math.round(q2_avg))) :
          setPlanQ2TotalTargetFooterTotal(
            commaSeparated(parseFloat(q2_avg.toFixed(2)))
          );
      if (parseFloat(q3_avg.toFixed(2)) > 0)
        parameterType === PARAMETERS_TYPES.Days ?
          setPlanQ3TotalTargetFooterTotal(
            commaSeparated(Math.round(q3_avg))) :
          setPlanQ3TotalTargetFooterTotal(
            commaSeparated(parseFloat(q3_avg.toFixed(2)))
          );
      if (parseFloat(q4_avg.toFixed(2)) > 0)
        parameterType === PARAMETERS_TYPES.Days ?
          setPlanQ4TotalTargetFooterTotal(
            commaSeparated(Math.round(q4_avg))) :
          setPlanQ4TotalTargetFooterTotal(
            commaSeparated(parseFloat(q4_avg.toFixed(2)))
          );
    } else {
      //Calculate Plan Total Footers
      setPlanTotalTargetFooterTotal(
        parseFloat(totalAddedTarget.toFixed(2)),
      );
      setPlanQ1TotalTargetFooterTotal(
        commaSeparated(parseFloat(q1Total.toFixed(2))),
      );
      setPlanQ2TotalTargetFooterTotal(
        commaSeparated(parseFloat(q2Total.toFixed(2))),
      );
      setPlanQ3TotalTargetFooterTotal(
        commaSeparated(parseFloat(q3Total.toFixed(2))),
      );
      setPlanQ4TotalTargetFooterTotal(
        commaSeparated(parseFloat(q4Total.toFixed(2))),
      );
    }

    //Get Bu Dimension
    var buDimension = SetBuAggregateValueFromPlanArray(planData);
    var sumOfRegion = sumFromArray("targetValue", buDimension);
    var buDimensionArray = [];
    buDimension.map((currentItem) => {
      let _buList = [...buList];
      var returnRecord = _buList.find((o) => o.id === currentItem.buId);
      if (returnRecord != null && parseFloat(sumOfRegion) > 0) {
        let Percentage = (currentItem.targetValue * 100) / sumOfRegion;
        buDimensionArray.push({
          BU: returnRecord.name,
          Percentage: Percentage,
          DisplayPercentage: Percentage.toFixed(2) + "%",
        });
      }
      return currentItem;
    });
    setBuDimensionData(buDimensionArray);
    setBuDimensionFooterTotal(
      GetTotalPercentageFromPlanArray(buDimensionArray),
    );

    //Get Region Dimension
    var regionDimension = SetRegionAggregateValueFromPlanArray(planData);
    var sumOfRegion = sumFromArray("targetValue", regionDimension);

    var regionDimensionArray = [];
    regionDimension.map((currentItem) => {
      let _regionList = [...regionList];
      var returnRecord = _regionList.find((o) => o.id === currentItem.regionId);
      if (returnRecord != null && parseFloat(sumOfRegion) > 0) {
        let Percentage = (currentItem.targetValue * 100) / sumOfRegion;
        regionDimensionArray.push({
          Region: returnRecord.name,
          Percentage: Percentage,
          DisplayPercentage: Percentage.toFixed(2) + "%",
        });
      }
      return currentItem;
    });
    setRegionDimensionData(regionDimensionArray);
    setRegionDimensionFooterTotal(
      GetTotalPercentageFromPlanArray(regionDimensionArray),
    );

    //Get Country Dimension
    var countryDimension = SetCountryAggregateValueFromPlanArray(planData);
    var sumOfCountry = sumFromArray("targetValue", countryDimension);
    var countryDimensionArray = [];
    countryDimension.map((currentItem) => {
      let _countryList = [...selectCountryList];
      var returnRecord = _countryList.find(
        (o) => o.id === currentItem.countryId,
      );
      if (returnRecord != null) {
        let Percentage = 100;
        if (countryDimension.length > 1 && parseFloat(sumOfCountry) > 0) {
          Percentage = (currentItem.targetValue * 100) / sumOfCountry;
        }
        countryDimensionArray.push({
          Country: returnRecord.name,
          Percentage: Percentage,
          DisplayPercentage: Percentage.toFixed(2) + "%",
        });
      }

      return currentItem;
    });
    setCountryDimensionData(countryDimensionArray);
    setCountryDimensionFooterTotal(
      GetTotalPercentageFromPlanArray(countryDimensionArray),
    );

    //Get Brand Dimension
    var brandDimension = SetBrandAggregateValueFromPlanArray(planData);
    var sumOfBrand = sumFromArray("targetValue", brandDimension);
    var brandDimensionArray = [];
    brandDimension.map((currentItem) => {
      let _brandList = [...selectBrandList];
      var returnRecord = _brandList.find((o) => o.id === currentItem.brandId);
      if (returnRecord != null && parseFloat(sumOfBrand) > 0) {
        let Percentage = (currentItem.targetValue * 100) / sumOfBrand;
        brandDimensionArray.push({
          Brand: returnRecord.name,
          Percentage: Percentage,
          DisplayPercentage: Percentage.toFixed(2) + "%",
        });
      }
      return currentItem;
    });
    setBrandDimensionData(brandDimensionArray);
    setBrandDimensionFooterTotal(
      GetTotalPercentageFromPlanArray(brandDimensionArray),
    );

    //Get GTM Dimension
    var GtmDimension = SetGTMAggregateValueFromPlanArray(planData);
    var sumOfGTM = sumFromArray("targetValue", GtmDimension);
    var gtmDimensionArray = [];
    GtmDimension.map((currentItem) => {
      let _GTMList = [...GTMDropdownList];
      var returnRecord = _GTMList.find((o) => o.value === currentItem.gtmId);
      if (returnRecord != null && parseFloat(sumOfGTM) > 0) {
        let Percentage = (currentItem.targetValue * 100) / sumOfGTM;
        gtmDimensionArray.push({
          GTM: returnRecord.label,
          Percentage: Percentage,
          DisplayPercentage: Percentage.toFixed(2) + "%",
        });
      }
      return currentItem;
    });
    setGTMDimensionData(gtmDimensionArray);
    setGTMDimensionFooterTotal(
      GetTotalPercentageFromPlanArray(gtmDimensionArray),
    );

    //Get Sub GTM Dimension
    var SubGtmDimension = SetSubGTMAggregateValueFromPlanArray(planData);
    var sumOfSubGTM = sumFromArray("targetValue", SubGtmDimension);
    var subGtmDimensionArray = [];
    SubGtmDimension.map((currentItem) => {
      let _SubGTMList = [...allSubGTMList];
      var returnRecord = _SubGTMList.find((o) => o.id === currentItem.subGtmId);
      if (returnRecord != null && parseFloat(sumOfSubGTM) > 0) {
        let Percentage = (currentItem.targetValue * 100) / sumOfSubGTM;
        subGtmDimensionArray.push({
          SubGTM: returnRecord.name,
          Percentage: Percentage,
          DisplayPercentage: Percentage.toFixed(2) + "%",
        });
      }
      return currentItem;
    });
    setSubGTMDimensionData(subGtmDimensionArray);
    setSubGTMDimensionFooterTotal(
      GetTotalPercentageFromPlanArray(subGtmDimensionArray),
    );

    //Get Product Group Dimension
    var productGroupDimension =
      SetProductGroupAggregateValueFromPlanArray(planData);
    var sumOfPG = sumFromArray("targetValue", productGroupDimension);
    var pgDimensionArray = [];
    productGroupDimension.map((currentItem) => {
      let _productGroupList = [...selectProductGroupList];
      var returnRecord = _productGroupList.find(
        (o) => o.id === currentItem.productGroupId,
      );
      if (returnRecord != null && parseFloat(sumOfPG) > 0) {
        let Percentage = (currentItem.targetValue * 100) / sumOfPG;
        pgDimensionArray.push({
          ProductGroup: returnRecord.name,
          Percentage: Percentage,
          DisplayPercentage: Percentage.toFixed(2) + "%",
        });
      }
      return currentItem;
    });
    setProductGroupDimensionData(pgDimensionArray);
    setProductGroupDimensionFooterTotal(
      GetTotalPercentageFromPlanArray(pgDimensionArray),
    );

    //Get Customer Segment Dimension
    var customerSegmentDimension =
      SetCustomerSegmentAggregateValueFromPlanArray(planData);
    var sumOfCustomerSegment = sumFromArray(
      "targetValue",
      customerSegmentDimension,
    );
    var customerSegmentDimensionArray = [];
    customerSegmentDimension.map((currentItem) => {
      let _customerGroupList = [...customerGroupList];
      var returnRecord = _customerGroupList.find(
        (o) => o.id === currentItem.customerGroupId,
      );
      if (returnRecord != null && parseFloat(sumOfCustomerSegment) > 0) {
        let Percentage = (currentItem.targetValue * 100) / sumOfCustomerSegment;
        customerSegmentDimensionArray.push({
          CustomerSegment: returnRecord.name,
          Percentage: Percentage,
          DisplayPercentage: Percentage.toFixed(2) + "%",
        });
      }
      return currentItem;
    });
    setCustomerSegmentDimensionData(customerSegmentDimensionArray);
    setCustomerSegmentDimensionFooterTotal(
      GetTotalPercentageFromPlanArray(customerSegmentDimensionArray),
    );

    //Get Customer  Dimension
    var customerDimension = SetCustomerAggregateValueFromPlanArray(planData);
    var sumOfCustomer = sumFromArray("targetValue", customerDimension);
    var customerDimensionArray = [];
    customerDimension.map((currentItem) => {
      let _customerList = [...customerList];
      var returnRecord = _customerList.find(
        (o) => o.id === currentItem.customerId,
      );
      if (returnRecord != null && parseFloat(sumOfCustomer) > 0) {
        let Percentage = (currentItem.targetValue * 100) / sumOfCustomer;
        customerDimensionArray.push({
          Customer: returnRecord.name,
          Percentage: Percentage,
          DisplayPercentage: Percentage.toFixed(2) + "%",
        });
      }
      return currentItem;
    });
    setCustomerDimensionData(customerDimensionArray);
    setCustomerDimensionFooterTotal(
      GetTotalPercentageFromPlanArray(customerDimensionArray),
    );

    setPlanUpdated(false);
  };

  //On Load Functions
  const [financialYear, setFinancialYear] = useState("");
  const [currentTargetId, setCurrentTargetId] = useState("");
  const [currentTargetDetailId, setCurrentTargetDetailId] =
    useStateWithCallbackLazy("");
  const [currentTargetAddedByAdmin, setCurrentTargetAddedByHRAdmin] =
    useState(false);
  const [currentTargetStatus, setCurrentTargetStatus] = useState("");

  const [parameterOriginalAnnualTarget, setParameterOriginalAnnualTarget] =
    useState("");
  const [parameterAnnualTarget, setParameterAnnualTarget] = useState("");
  const [requestRevisionValue, setRequestRevisionValue] = useState("");
  const [parameterMatchPer, setParameterMatchPer] = useState(0);
  const [parameterQ1Target, setParameterQ1Target] = useState("");
  const [parameterQ1Percentage, setParameterQ1Percentage] = useState(0);
  const [parameterQ2Target, setParameterQ2Target] = useState("");
  const [parameterQ2Percentage, setParameterQ2Percentage] = useState(0);
  const [parameterQ3Target, setParameterQ3Target] = useState("");
  const [parameterQ3Percentage, setParameterQ3Percentage] = useState(0);
  const [parameterQ4Target, setParameterQ4Target] = useState("");
  const [parameterQ4Percentage, setParameterQ4Percentage] = useState(0);

  const [parameterType, setParameterType] = useState("");
  const [requestType, setRequestType] = useState("");
  const [isPlanMandatory, setIsPlanMandatory] = useState(0);
  const [markAsComplete, setMarkAsComplete] = useState(false);
  const [isShowRequestForRevisionPopUp, setShowRequestForRevisionPopUp] =
    useState(0);

  const [checkAllAreApprovedOrNot, setCheckAllAreApprovedOrNot] =
    useState(false);

  const ChangeRequestType = (requestType) => {
    setRequestType(requestType);
    if (requestType === "R") {
      //Revision
      setShowRequestForRevisionPopUp(1);
    } //Accept
    else {
      setRequestRevisionValue("");
      setShowRequestForRevisionPopUp(0);
      setParameterAnnualTarget(parameterOriginalAnnualTarget);
      setPlanUpdated(true);
    }
  };

  const saveRequestForRevision = () => {
    if (requestRevisionValue === "") {
      toast.error("Please enter Request Revision value.");
      return;
    }
    setRequestType("R");
    setShowRequestForRevisionPopUp(0);
    setParameterAnnualTarget(requestRevisionValue);
    setPlanUpdated(true);
  };

  async function LoadPageData() {
    //check Remarks enable & disable
    /* var currentEmployeeResponses = await DataStore.query(
      Employee,
      loggedEmployeeId,
    ); */

    var currentEmployeeResponses = await graphQLFindRecordById('getEmployee', loggedEmployeeId)

    if (currentEmployeeResponses.isAssignDirectTarget) {
      setIsShowRemark(false);
      setEmployeeName(
        currentEmployeeResponses.firstName +
        " " +
        currentEmployeeResponses.lastName,
      );
      setEmployeeCode(currentEmployeeResponses.employeeId);
    }

    //Financial Year
    /* const currentFinancialYear = await DataStore.query(
      FinancialYear,
      financialYearId,
    ); */

    var currentFinancialYear = await graphQLFindRecordById('getFinancialYear', financialYearId)

    if (!currentFinancialYear) {
      toast.error("Invalid record.");
      return;
    }
    setFinancialYear(currentFinancialYear.name);

    //Parameter
    // const currentParameters = await DataStore.query(Parameters, parameterId);
    var currentParameters = await graphQLFindRecordById('getParameters', parameterId)

    if (!currentParameters) {
      toast.error("Invalid record.");
      return;
    }
    setParameterType(currentParameters.type);

    //Load All Parameters
    //---------------------

    var currentTarget = "";
    //Target
    if (team === "team") {

      try {
        // loggedEmployeeReportingManagerId replaced with loggedEmployeeId 25th OCT 2023 17:50
        currentTarget = await graphQLGetAllData('listTargets', [{ employee_id: { eq: loggedEmployeeId }, report_manager_employee_id: { ne: loggedEmployeeId }, financial_year_id: { eq: financialYearId } }])
      }
      catch (error) {

        console.log("error", error)
      }

      /* currentTarget = await DataStore.query(Targets, (c) =>
        c.and((c) => [
          c.financial_year_id.eq(financialYearId),
          c.employee_id.eq(loggedEmployeeId),
          c.report_manager_employee_id.notContains(loggedEmployeeId),
        ]),
      ); */

      //Check Assigned by HR Admin

      var isTargetAddedByHRAdmin = GetSingleValueFromResponse(
        currentTarget,
        "isAddedByHRAdmin",
      );
      setCurrentTargetAddedByHRAdmin(isTargetAddedByHRAdmin);
    } else {
      // if employee having parent employee then using report_manager_employee_id as parent employee id else using self id
      let checkEmployeeId = loggedEmployeeReportingManagerId ? loggedEmployeeReportingManagerId : loggedEmployeeId;

      // report_manager_employee_id set to loggedEmployeeId because it was not working for second level user: Pratap 29th OCT 2023 19:19
      // report_manager_employee_id set to checkEmployeeId because it was not working for third level user: Pratap 29th OCT 2023 19:29
      // currentTarget = await graphQLGetAllData('listTargets', [{ employee_id: { eq: loggedEmployeeId } }, { report_manager_employee_id: { eq: checkEmployeeId } }, { financial_year_id: { eq: financialYearId } }])

      if (loggedEmployeeReportingManagerId) {
        currentTarget = await graphQLGetAllData('listTargets', [{ employee_id: { eq: loggedEmployeeId } }, { report_manager_employee_id: { eq: loggedEmployeeReportingManagerId } }, { financial_year_id: { eq: financialYearId } }])
      }


      if (currentTarget.length === 0) {
        currentTarget = await graphQLGetAllData('listTargets', [{ employee_id: { eq: loggedEmployeeId } }, { report_manager_employee_id: { eq: loggedEmployeeId } }, { financial_year_id: { eq: financialYearId } }])
      }


      /* currentTarget = await DataStore.query(Targets, (c) =>
        c.and((c) => [
          c.financial_year_id.eq(financialYearId),
          c.employee_id.eq(loggedEmployeeId),
          c.report_manager_employee_id.eq(loggedEmployeeId),
        ]),
      ); */
      setCurrentTargetAddedByHRAdmin(true);
    }

    if (!currentTarget) {
      toast.error("Invalid record.");
      return;
    }
    // var myTargetId = currentTarget.length ? currentTarget[0].id : '';
    // var myTargetId = GetSingleValueFromResponse(currentTarget, "id");
    var myTargetId = currentTarget[0].id;
    setCurrentTargetId(myTargetId);

    //Receiver Employee id
    var reportingManagerEmployeeId = GetSingleValueFromResponse(
      currentTarget,
      "report_manager_employee_id",
    );
    if (reportingManagerEmployeeId !== "") {
      setReceiverEmployeeId(reportingManagerEmployeeId);
    }

    //Target Details
    var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: myTargetId } }])

    /* var currentTargetDetails = await DataStore.query(TargetDetails, (c) =>
      c.target_id.eq(myTargetId),
    ); */

    let checkIfAllTargetApproved = await currentTargetDetails.every(
      (item) => item.is_approve === true,
    );
    setCheckAllAreApprovedOrNot(checkIfAllTargetApproved);

    //Get Relationship
    var currentRelationship = await graphQLGetAllData('listRelationships', [{ financial_year_id: { eq: financialYearId }, role_id: { eq: loggedEmployeeRoleId } }])


    /* var currentRelationship = await DataStore.query(Relationship, (c) =>
      c.and((c) => [
        c.financial_year_id.eq(financialYearId),
        c.role_id.eq(loggedEmployeeRoleId),
      ]),
    ); */

    var relationship_id = GetSingleValueFromResponse(currentRelationship, "id");

    var _tempArray = [];
    let _tempInCompleteParamArray = [];

    if (!currentTargetDetails.length) {
      setPageLoaded(true);
    }

    currentTargetDetails.map(
      async (currentItem, currentIndex, currentArray) => {

        var loopParameters = await graphQLFindRecordById('getParameters', currentItem.parameter_id)

        /* const loopParameters = await DataStore.query(
          Parameters,
          currentItem.parameter_id,
        ); */

        //Now we are going to check is this parameter belongs to this role and financial year
        //------------------------------------
        var isPlanMandatory = 0;
        if (relationship_id !== "") {

          var currentRelationshipDetails = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: relationship_id }, parameter_id: { eq: currentItem.parameter_id } }])


          /* var currentRelationshipDetails = await DataStore.query(
            RelationshipDetails,
            (c) =>
              c.and((c) => [
                c.relationship_id.eq(relationship_id),
                c.parameter_id.eq(currentItem.parameter_id),
              ]),
          ); */

          if (currentRelationshipDetails.length > 0) {
            isPlanMandatory = 1;
          }
        }
        //------------------------------------

        if (currentItem.parameter_id === parameterId) {
          setCurrentTargetDetailId(currentItem.id, (currentTargetDetailId) => {
            if (currentTargetDetailId !== "") {
              BindMessageList(
                currentTargetDetailId,
                reportingManagerEmployeeId,
              );
            }
          });
          // setCurrentTargetDetailId(currentItem.id);
          setParameterAnnualTarget(currentItem.target_value);
          setParameterOriginalAnnualTarget(currentItem.target_value);

          if (currentItem.is_accepted_by_employee && !currentItem.is_approve) {
            setCurrentTargetStatus("Accepted");
          } else if (
            currentItem.is_approve &&
            currentItem.is_accepted_by_employee
          ) {
            setCurrentTargetStatus("Approved");
            setIsDisabled(true);
          } else if (currentItem.isSendForRevisionByReportingManage) {
            setCurrentTargetStatus("Send for revision");
          } else {
            setCurrentTargetStatus("Open");
          }

          if (currentItem.isMarkAsComplete != null) {
            setMarkAsComplete(currentItem.isMarkAsComplete);
          }

          if (currentItem.is_accepted_by_employee === true) {
            setRequestType("A");
            setIsPlanMandatory(isPlanMandatory);
          } else if (currentItem.is_revision_by_employee === true) {
            setRequestType("R");
            setIsPlanMandatory(isPlanMandatory);
            setRequestRevisionValue(currentItem.revision_value);
          }

          //Bind Plan
          //---------------------
          var currentRelationshipDetails = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: currentItem.id } }])

          /* await DataStore.query(Plan, (c) =>
            c.target_detail_id.eq(currentItem.id),
          ).then((existingPlanData) => { */
          if (currentRelationshipDetails.length > 0) {
            // 
            var existingFormatData = [];

            currentRelationshipDetails.map(async (currentPlanItem) => {
              existingFormatData.push({
                id: uniqid(),
                sbuId: currentPlanItem.sbu_id,
                buId: currentPlanItem.bu_id,
                regionId: currentPlanItem.region_id,
                countryId: currentPlanItem.country_id,
                brandId: currentPlanItem.brand_id,
                productGroupId: currentPlanItem.product_group_id,
                customerGroupId: currentPlanItem.customer_group_id,
                customerId: currentPlanItem.customer_id,
                targetValue: currentParameters.type === 'P' || currentParameters.type === 'D' ? (parseFloat(currentPlanItem.q1_target_value) + parseFloat(currentPlanItem.q2_target_value) + parseFloat(currentPlanItem.q3_target_value) + parseFloat(currentPlanItem.q4_target_value)) / 4 : (parseInt(currentPlanItem.q1_target_value) + parseInt(currentPlanItem.q2_target_value) + parseInt(currentPlanItem.q3_target_value) + parseInt(currentPlanItem.q4_target_value)),
                Q1TargetValue: currentParameters.type === 'P' && currentPlanItem.q1_target_value < 1 ? parseFloat(currentPlanItem.q1_target_value * 100).toFixed(2) : currentPlanItem.q1_target_value,
                Q2TargetValue: currentParameters.type === 'P' && currentPlanItem.q1_target_value < 1 ? parseFloat(currentPlanItem.q2_target_value * 100).toFixed(2) : currentPlanItem.q2_target_value,
                Q3TargetValue: currentParameters.type === 'P' && currentPlanItem.q1_target_value < 1 ? parseFloat(currentPlanItem.q3_target_value * 100).toFixed(2) : currentPlanItem.q3_target_value,
                Q4TargetValue: currentParameters.type === 'P' && currentPlanItem.q1_target_value < 1 ? parseFloat(currentPlanItem.q4_target_value * 100).toFixed(2) : currentPlanItem.q4_target_value,
                disEqually: 0,
                gtmId: currentPlanItem.gtm_Id,
                subGtmId: currentPlanItem.sub_gtm_id,
              });
            });

            setPlanData(existingFormatData);
            setPlanUpdated(true);
          }
          //---------------------
        }

        //Incomplete Param
        if (
          currentItem.isMarkAsComplete == null ||
          currentItem.isMarkAsComplete === false
        ) {
          _tempInCompleteParamArray.push({
            parameterName: loopParameters.name,
            status: "Open",
            openLink:
              "/employee/planning/team/managetarget/annualtarget/" +
              financialYearId +
              "/" +
              currentItem.parameter_id,
          });
        }

        //Status
        let status = "";
        if (currentItem.is_accepted_by_employee) {
          status = "A";
        }
        if (currentItem.is_revision_by_employee) {
          status = "R";
        }
        _tempArray.push({
          parameterIndex: currentIndex,
          parameterId: currentItem.parameter_id,
          parameterName: loopParameters?.name,
          targetValue:
            loopParameters?.type === PARAMETERS_TYPES.Amount
              ? ShowInMillion(currentItem.target_value)
              : currentItem.target_value,
          parameterUnit: loopParameters?.type,
          isPlanMandatory: isPlanMandatory,
          status: status,
        });
        //Check for last Record
        if (_tempArray.length === currentArray.length) {
          _tempArray.sort(function (a, b) {
            return a.parameterName
              ?.toLowerCase()
              .localeCompare(b.parameterName?.toLowerCase());
          });


          setParametersCarousel(_tempArray);

          _tempInCompleteParamArray.sort(function (a, b) {
            return a.parameterName
              .toLowerCase()
              .localeCompare(b.parameterName.toLowerCase());
          });
          setMarkAsIncompleteParameters(_tempInCompleteParamArray);

          //Set Previous
          var currentPreviousParameter = GetArrayPreviousItemFromKeyValue(
            _tempArray,
            "parameterId",
            parameterId,
          );
          if (currentPreviousParameter["parameterId"] != null) {
            setPrevParameterId(currentPreviousParameter["parameterId"]);
          }

          //Set Next
          var currentNextParameter = GetArrayNextItemFromKeyValue(
            _tempArray,
            "parameterId",
            parameterId,
          );
          if (currentNextParameter["parameterId"] != null) {
            setNextParameterId(currentNextParameter["parameterId"]);
          }

          //Set Selected Carousel Page
          var currentParameterArrayItem = GetArrayItemIndexFromKeyValue(
            _tempArray,
            "parameterId",
            parameterId,
          );
          if (currentParameterArrayItem > 2) {
            //var activePageNo = Math.floor((_tempArray.length + currentParameterArrayItem) / 3);
            setCarouselActivePageNo(currentParameterArrayItem - 2);
          }
          setPageLoaded(true);
        }
        return currentItem;
      },
    );
    //---------------------
  }

  async function BindList() {
    //SBU
    // var SBUResponses = await DataStore.query(SBU, Predicates.ALL, {
    //     sort: s => s.name(SortDirection.ASCENDING)
    // });
    // SetSbuList(SBUResponses)
    // var myArray = []; myArray = ConvertResponseForSelect(SBUResponses); setSbuDropdownList(myArray);

    //get logged Employee Id
    let loggedEmployeeId = reactLocalStorage.get("loggedEmployeeId");
    // var loggedEmployeeResponses = await DataStore.query(
    //   Employee,
    //   loggedEmployeeId,
    // );

    var loggedEmployeeResponses = await graphQLFindRecordById('getEmployee', loggedEmployeeId);

    // var allBuResponses = await DataStore.query(BIUnit); 

    var allBuResponses = await graphQLGetAllData('listBIUnits')
    SetAllBU(allBuResponses);

    // var allRegionResponses = await DataStore.query(Region); 
    var allRegionResponses = await graphQLGetAllData('listRegions')
    SetAllRegionList(allRegionResponses);

    var GTMResponses = await graphQLGetAllData('listGTMS')
    GTMResponses = GTMResponses.sort((a, b) => a.name.localeCompare(b.name));

    myArray = [];
    myArray = ConvertResponseForSelectWithRefCode(GTMResponses);
    setGTMDropdownList(myArray);

    //SubGTM
    var GTMResponses = await graphQLGetAllData('listSubGTMS')
    GTMResponses = GTMResponses.sort((a, b) => a.name.localeCompare(b.name));

    // var GTMResponses = await DataStore.query(SubGTM, Predicates.ALL);
    SetAllSubGTMList(GTMResponses);

    if (loggedEmployeeResponses.param_sbu_id) {
      setSbuId(loggedEmployeeResponses.param_sbu_id);


      //get BU
      /* var buResponses = await DataStore.query(BIUnit, (c) => c.sbu_id.eq(loggedEmployeeResponses.param_sbu_id), { sort: (s) => s.name(SortDirection.ASCENDING), },); */

      var buResponses = await graphQLGetAllData('listBIUnits', [{ sbu_id: { eq: loggedEmployeeResponses.param_sbu_id } }])
      buResponses = buResponses.sort((a, b) => a.name.localeCompare(b.name));

      SetBuList(buResponses);

      var myArray = [];
      myArray = ConvertResponseForSelectWithRefCode(buResponses);
      setBuDropdownList(myArray);

      //Region
      /* var regionResponses = await DataStore.query(
        Region,
        (c) => c.SBU_id.eq(loggedEmployeeResponses.param_sbu_id),
        {
          sort: (s) => s.name(SortDirection.ASCENDING),
        },
      ); */

      var regionResponses = await graphQLGetAllData('listRegions', [{ SBU_id: { eq: loggedEmployeeResponses.param_sbu_id } }])
      buResponses = buResponses.sort((a, b) => a.name.localeCompare(b.name));

      SetRegionList(regionResponses);
      myArray = [];
      myArray = ConvertResponseForSelectWithRefCode(regionResponses);
      setRegionDropdownList(myArray);
    } else {
      SetBuList([]);
      setBuDropdownList([]);
      SetRegionList([]);
      setRegionDropdownList([]);
    }

    // //Region
    // var regionResponses = await DataStore.query(Region, Predicates.ALL, {
    //     sort: s => s.name(SortDirection.ASCENDING)
    // });
    // SetRegionList(regionResponses)
    // myArray = []; myArray = ConvertResponseForSelect(regionResponses); setRegionDropdownList(myArray);

    //Country
    /* var countryResponses = await DataStore.query(Country, Predicates.ALL, {
      sort: (s) => s.name(SortDirection.ASCENDING),
    }); */

    var countryResponses = await graphQLGetAllData('listCountries')
    countryResponses = countryResponses.sort((a, b) => a.name.localeCompare(b.name));

    SetSelectCountryList(countryResponses);

    // Brand
    /* var brandResponses = await DataStore.query(Brand, Predicates.ALL, {
      sort: (s) => s.name(SortDirection.ASCENDING),
    }); */

    var brandResponses = await graphQLGetAllData('listBrands')
    brandResponses = brandResponses.sort((a, b) => a.name.localeCompare(b.name));

    SetSelectBrandList(brandResponses);

    //Product Group
    var pGResponses = await graphQLGetAllData('listProductGroups')
    pGResponses = pGResponses.sort((a, b) => a.name.localeCompare(b.name));
    SetSelectProductGroupList(pGResponses);

    /* var pGResponses = await DataStore.query(ProductGroup, Predicates.ALL, {
      sort: (s) => s.name(SortDirection.ASCENDING),
    }); */

    //Customer Group
    /* var customerGroupResponses = await DataStore.query(
      CustomerCategory,
      Predicates.ALL,
      {
        sort: (s) => s.name(SortDirection.ASCENDING),
      },
    ); */

    var customerGroupResponses = await graphQLGetAllData('listCustomerCategories')
    customerGroupResponses = customerGroupResponses.sort((a, b) => a.name.localeCompare(b.name));

    SetCustomerGroupList(customerGroupResponses);
    myArray = [];
    myArray = ConvertResponseForSelectWithRefCode(customerGroupResponses);
    setCustomerGroupDropdownList(myArray);

    //Customer
    /* var customerResponses = await DataStore.query(Customer, Predicates.ALL, {
      sort: (s) => s.name(SortDirection.ASCENDING),
    }); */

    var customerResponses = await graphQLGetAllData('listCustomers')
    customerResponses = customerResponses.sort((a, b) => a.name.localeCompare(b.name));

    SetSelectCustomerList(customerResponses);

    //GTM
    /* var GTMResponses = await DataStore.query(GTM, Predicates.ALL, {
      sort: (s) => s.name(SortDirection.ASCENDING),
    }); */


  }

  //load Countries
  async function onClickRegion(e) {
    //Country

    /*  var countryResponses = await DataStore.query(
       Country,
       (c) => c.region_id.eq(e.value),
       {
         sort: (s) => s.name(SortDirection.ASCENDING),
       },
     ); */

    var countryResponses = await graphQLGetAllData('listCountries', [{ region_id: { eq: e.value } }])
    countryResponses = countryResponses.sort((a, b) => a.name.localeCompare(b.name));

    //check Empty
    if (countryResponses.length > 0) {
      SetCountryList(countryResponses);
      var myArray = [];
      myArray = ConvertResponseForSelectWithRefCode(countryResponses);
      setCountryDropdownList(myArray);
    } else {
      SetCountryList([]);
      setCountryDropdownList([]);
    }
  }

  //load Brand
  async function onClickBU(e) {
    //Brand
    var brandResponses = await graphQLGetAllData('listBrands', [{ bu_id: { eq: e.value } }])
    brandResponses = brandResponses.sort((a, b) => a.name.localeCompare(b.name));

    /* var brandResponses = await DataStore.query(
      Brand,
      (c) => c.bu_id.eq(e.value),
      {
        sort: (s) => s.name(SortDirection.ASCENDING),
      },
    ); */

    //check Empty
    if (brandResponses.length > 0) {
      SetBrandList(brandResponses);
      var myArray = [];
      myArray = ConvertResponseForSelectWithRefCode(brandResponses);
      setBrandDropdownList(myArray);
    } else {
      SetBrandList([]);
      setBrandDropdownList([]);
    }
  }

  //load PG
  async function onClickBrand(e) {
    //Brand
    /* var pGResponses = await DataStore.query(
      ProductGroup,
      (c) => c.brand_id.eq(e.value),
      {
        sort: (s) => s.name(SortDirection.ASCENDING),
      },
    ); */

    var pGResponses = await graphQLGetAllData('listProductGroups', [{ brand_id: { eq: e.value } }])
    pGResponses = pGResponses.sort((a, b) => a.name.localeCompare(b.name));

    //check Empty
    if (pGResponses.length > 0) {
      SetProductGroupList(pGResponses);
      var myArray = [];
      myArray = ConvertResponseForSelectWithRefCode(pGResponses);
      setProductGroupDropdownList(myArray);
    } else {
      SetProductGroupList([]);
      setProductGroupDropdownList([]);
    }
  }

  //load Customers
  async function onClickCustomerGroup(e) {

    var customerResponses = await graphQLGetAllData('listCustomers', [{ customer_category_id: { eq: e.value } }])
    customerResponses = customerResponses.sort((a, b) => a.name.localeCompare(b.name));

    /* var customerResponses = await DataStore.query(
      Customer,
      (c) => c.customer_category_id.eq(e.value),
      {
        sort: (s) => s.name(SortDirection.ASCENDING),
      },
    ); */

    //check Empty
    if (customerResponses.length > 0) {
      SetCustomerList(customerResponses);
      var myArray = [];
      myArray = ConvertResponseForSelectWithRefCode(customerResponses);
      setCustomerDropdownList(myArray);
    } else {
      SetCustomerList([]);
      setCustomerDropdownList([]);
    }
  }

  //load Customers
  async function onClickGTM(e) {

    try {
      var gtmResponse = await graphQLGetAllData('listSubGTMS', [{ gtm_id: { eq: e.value } }])
      gtmResponse = gtmResponse.sort((a, b) => a.name.localeCompare(b.name));

      /* var gtmResponse = await DataStore.query(
        SubGTM,
        (c) => c.gtm_id.eq(e.value),
        { sort: (s) => s.name(SortDirection.ASCENDING) },
      ); */
      //check Empty
      if (gtmResponse.length > 0) {
        var myArray = [];
        myArray = ConvertResponseForSelectWithRefCode(gtmResponse);
        setSubGTMDropdownList(myArray);
      } else {
        setSubGTMDropdownList([]);
      }
    }
    catch (e) {
      console.log("error", e)
    }
  }

  //save plan
  const SavePlan = async () => {

    // Below code is not being used hence commented
    // var currentTarget = await graphQLGetAllData('listTargets', [{ financial_year_id: { eq: financialYearId }, employee_id: { eq: loggedEmployeeId }, report_manager_employee_id: { ne: loggedEmployeeId } }])
    // currentTarget = currentTarget.sort((a, b) => a.name.localeCompare(b.name));

    /*  const currentTarget = await DataStore.query(Targets, (c) =>
       c.and((c) => [
         c.financial_year_id.eq(financialYearId),
         c.employee_id.eq(loggedEmployeeId),
         c.report_manager_employee_id.notContains(loggedEmployeeId),
       ]),
     ); */

    var compareParameterMatchPer = parseInt(parameterMatchPer);

    if (currentTargetId === "") {
      //Check Current Target
      toast.error("Invalid target.");
      return;
    } else if (requestType === "") {
      //Check Request Type
      toast.error("Please accept target.");
      return;
    } else if (planData.length === 0 && isPlanMandatory === 1) {
      toast.error("Please setup your plan.");
      return;
    } else if (compareParameterMatchPer < 100 && isPlanMandatory === 1) {
      toast.error("Please check your target.");
      return;
    }

    // Validation for Target Value
    if (isPlanMandatory === 1) {
      var isValidPlan = true;
      planData.map(async (currentItem) => {
        var targetValue = parseFloat(currentItem.targetValue);
        var Q1TargetValue = parseFloat(currentItem.Q1TargetValue);
        var Q2TargetValue = parseFloat(currentItem.Q2TargetValue);
        var Q3TargetValue = parseFloat(currentItem.Q3TargetValue);
        var Q4TargetValue = parseFloat(currentItem.Q4TargetValue);

        if (!targetValue > 0) {
          isValidPlan = false;
          return;
        } else if (
          !Q1TargetValue > 0 &&
          !Q2TargetValue > 0 &&
          !Q3TargetValue > 0 &&
          !Q4TargetValue > 0
        ) {
          isValidPlan = false;
          return;
        }
      });

      if (isValidPlan === false) {
        toast.error("Plan can't be empty.");
        return;
      }
    }

    //SaveTargets Details
    //Update Existing Record for TargetId + Parameter
    const currentTargetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: currentTargetId }, parameter_id: { eq: parameterId } }])

    /* const currentTargetDetails = await DataStore.query(TargetDetails, (c) =>
      c.and((c) => [
        c.target_id.eq(currentTargetId),
        c.parameter_id.eq(parameterId),
      ]),
    ); */

    if (currentTargetDetails != null) {
      if (requestType === "A") {

        // const original1 = await graphQLFindRecordById('getTargetDetails', currentTargetDetails[0].id)


        /* const original = await DataStore.query(
          TargetDetails,
          currentTargetDetails[0],
        ); */

        // await DataStore.save(
        //   TargetDetails.copyOf(original, (item) => {
        //     item.isMarkAsComplete = markAsComplete;
        //     item.is_accepted_by_employee = true;
        //     item.accepted_by_employee_date = moment().format();
        //     item.is_revision_by_employee = false;
        //     item.revision_value = "";
        //     item.isSendForRevisionByReportingManage = false;
        //     item.q1_target_value = String(parameterQ1Target);
        //     item.q2_target_value = String(parameterQ2Target);
        //     item.q3_target_value = String(parameterQ3Target);
        //     item.q4_target_value = String(parameterQ4Target);
        //   })
        // );

        // await API.graphql({
        //   query: mutations.deleteTargetDetails,
        //   variables: {
        //     input: {
        //       id: original1.id,
        //       _version: original1._version
        //     }
        //   }
        // });

        // await API.graphql(
        //   graphqlOperation(mutations.createTargetDetails, {
        //     input: {
        //       isMarkAsComplete: markAsComplete,
        //       is_accepted_by_employee: true,
        //       accepted_by_employee_date: moment().format(),
        //       is_revision_by_employee: false,
        //       revision_value: "testing",
        //       isSendForRevisionByReportingManage: false,
        //       q1_target_value: String(parameterQ1Target),
        //       q2_target_value: String(parameterQ2Target),
        //       q3_target_value: String(parameterQ3Target),
        //       q4_target_value: String(parameterQ4Target),
        //     }
        //   })
        // );

        const newRecord = await API.graphql({
          query: mutations.updateTargetDetails,
          variables: {
            input: {
              id: currentTargetDetails[0].id,
              _version: currentTargetDetails[0]._version,
              isMarkAsComplete: markAsComplete,
              is_accepted_by_employee: true,
              accepted_by_employee_date: moment().format(),
              is_revision_by_employee: false,
              revision_value: "",
              isSendForRevisionByReportingManage: false,
              q1_target_value: String(parameterQ1Target),
              q2_target_value: String(parameterQ2Target),
              q3_target_value: String(parameterQ3Target),
              q4_target_value: String(parameterQ4Target),
            }
          }
        });


      } else if (requestType === "R") {
        const original = await graphQLFindRecordById('getTargetDetails', currentTargetDetails[0].id)
        // const original = await API.graphql({ query: queries.getTargetDetails, variables: { id: currentTargetDetails[0].id } });
        /* const original = await DataStore.query(
          TargetDetails,
          currentTargetDetails[0],
        ); */
        let updatedRecord = await API.graphql({
          query: mutations.updateTargetDetails,
          variables: {
            input: {
              id: original.id,
              _version: original._version,
              isMarkAsComplete: markAsComplete,
              is_accepted_by_employee: false,
              revision_value: requestRevisionValue,
              is_revision_by_employee: true,
              revision_by_employee_date: moment().format(),
              isSendForRevisionByReportingManage: false,
              q1_target_value: String(parameterQ1Target),
              q2_target_value: String(parameterQ2Target),
              q3_target_value: String(parameterQ3Target),
              q4_target_value: String(parameterQ4Target)
            }
          }
        });


        /* await DataStore.save(
          TargetDetails.copyOf(original, (item) => {
            item.isMarkAsComplete = markAsComplete;
            item.is_accepted_by_employee = false;
            item.revision_value = requestRevisionValue;
            item.is_revision_by_employee = true;
            item.revision_by_employee_date = moment().format();
            item.isSendForRevisionByReportingManage = false;
            item.q1_target_value = String(parameterQ1Target);
            item.q2_target_value = String(parameterQ2Target);
            item.q3_target_value = String(parameterQ3Target);
            item.q4_target_value = String(parameterQ4Target);
          }),
        ); */
      }

      if (isPlanMandatory === 0) {
        toast.success("Saved");
        LoadPageData();
        return;
      }

      //Now SavePlan
      var currentTargetDetailId = currentTargetDetails[0].id;
      // return;
      //Delete Existing Plan

      var targetDetails = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: currentTargetDetailId } }])

      for (let i = 0; i < targetDetails.length; i++) {
        // const original = await graphQLFindRecordById('getTargetDetails', targetDetails[i].id)

        const originalDeleted = await API.graphql({
          // query: mutations.deleteTargetDetails,
          query: mutations.deletePlan,
          variables: {
            input: {
              id: targetDetails[i].id,
              _version: targetDetails[i]._version,
            }
          }
        });


      }

      /*  await DataStore.delete(Plan, (c) =>
         c.target_detail_id.eq(currentTargetDetailId),
       ).then(() => { */


      planData.map(async (currentItem) => {
        //Get REF_Code
        // let currentResponse = await DataStore.query(SBU, currentItem.sbuId);

        let currentResponse = await graphQLFindRecordById('getSBU', currentItem.sbuId)
        let sbu_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

        // currentResponse = await DataStore.query(BIUnit, currentItem.buId);
        currentResponse = await graphQLFindRecordById('getBIUnit', currentItem.buId)
        let bu_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

        // currentResponse = await DataStore.query(Region, currentItem.regionId);
        currentResponse = await graphQLFindRecordById('getRegion', currentItem.regionId)
        let region_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

        // currentResponse = await DataStore.query(Country,currentItem.countryId);

        currentResponse = await graphQLFindRecordById('getCountry', currentItem.countryId)
        let country_REF_CODE = currentResponse
          ? currentResponse.REF_CODE
          : "";

        // currentResponse = await DataStore.query(Brand, currentItem.brandId);
        currentResponse = await graphQLFindRecordById('getBrand', currentItem.brandId)
        let brand_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

        currentResponse = await graphQLFindRecordById('getProductGroup', currentItem.productGroupId)

        /* currentResponse = await DataStore.query(
          ProductGroup,
          currentItem.productGroupId,
        ); */

        let product_group_REF_CODE = currentResponse
          ? currentResponse.REF_CODE
          : "";

        currentResponse = await graphQLFindRecordById('getCustomerCategory', currentItem.customerGroupId)

        /* currentResponse = await DataStore.query(
          CustomerCategory,
          currentItem.customerGroupId,
        ); */

        let customer_group_REF_CODE = currentResponse
          ? currentResponse.REF_CODE
          : "";

        currentResponse = await graphQLFindRecordById('getCustomer', currentItem.customerId)

        /* currentResponse = await DataStore.query(
          Customer,
          currentItem.customerId,
        ); */

        let customer_REF_CODE = currentResponse
          ? currentResponse.REF_CODE
          : "";
        // currentResponse = await DataStore.query(GTM, currentItem.gtmId);

        currentResponse = await graphQLFindRecordById('getGTM', currentItem.gtmId)
        let gtm_REF_CODE = currentResponse ? currentResponse.REF_CODE : "";

        currentResponse = await graphQLFindRecordById('getSubGTM', currentItem.subGtmId)

        // currentResponse = await DataStore.query(SubGTM, currentItem.subGtmId);

        let sub_gtm_REF_CODE = currentResponse
          ? currentResponse.REF_CODE
          : "";


        let createdNew = await API.graphql(
          graphqlOperation(mutations.createPlan, {
            input: {
              target_detail_id: currentTargetDetailId,
              sbu_id: currentItem.sbuId,
              region_id: currentItem.regionId,
              country_id: currentItem.countryId,
              brand_id: currentItem.brandId,
              product_group_id: currentItem.productGroupId,
              customer_group_id: currentItem.customerGroupId,
              customer_id: currentItem.customerId,
              bu_id: currentItem.buId,
              gtm_Id: currentItem.gtmId,
              sub_gtm_id: currentItem.subGtmId,

              sub_REF_CODE: sbu_REF_CODE,
              region_REF_CODE: region_REF_CODE,
              country_REF_CODE: country_REF_CODE,
              brand_REF_CODE: brand_REF_CODE,
              product_group_REF_CODE: product_group_REF_CODE,
              customer_group_REF_CODE: customer_group_REF_CODE,
              customer_REF_CODE: customer_REF_CODE,
              bu_REF_CODE: bu_REF_CODE,
              gtm_REF_CODE: gtm_REF_CODE,
              sub_gtm_REF_CODE: sub_gtm_REF_CODE,

              target_value: currentItem.targetValue.toString(),
              q1_target_value: currentItem.Q1TargetValue.toString(),
              q2_target_value: currentItem.Q2TargetValue.toString(),
              q3_target_value: currentItem.Q3TargetValue.toString(),
              q4_target_value: currentItem.Q4TargetValue.toString(),
            }
          })
        );


        /* await DataStore.save(
          new Plan({
            target_detail_id: currentTargetDetailId,
            sbu_id: currentItem.sbuId,
            region_id: currentItem.regionId,
            country_id: currentItem.countryId,
            brand_id: currentItem.brandId,
            product_group_id: currentItem.productGroupId,
            customer_group_id: currentItem.customerGroupId,
            customer_id: currentItem.customerId,
            bu_id: currentItem.buId,
            gtm_Id: currentItem.gtmId,
            sub_gtm_id: currentItem.subGtmId,

            sub_REF_CODE: sbu_REF_CODE,
            region_REF_CODE: region_REF_CODE,
            country_REF_CODE: country_REF_CODE,
            brand_REF_CODE: brand_REF_CODE,
            product_group_REF_CODE: product_group_REF_CODE,
            customer_group_REF_CODE: customer_group_REF_CODE,
            customer_REF_CODE: customer_REF_CODE,
            bu_REF_CODE: bu_REF_CODE,
            gtm_REF_CODE: gtm_REF_CODE,
            sub_gtm_REF_CODE: sub_gtm_REF_CODE,

            target_value: currentItem.targetValue.toString(),
            q1_target_value: currentItem.Q1TargetValue.toString(),
            q2_target_value: currentItem.Q2TargetValue.toString(),
            q3_target_value: currentItem.Q3TargetValue.toString(),
            q4_target_value: currentItem.Q4TargetValue.toString(),
          }),
        ); */
      });
      // });

      toast.success("Saved");
      LoadPageData();
      return;
    } else {
      toast.error("Invalid record.");
      return;
    }
  };

  const ShowPreviousParameter = () => {
    if (prevParameterId !== "" && team === "team") {
      var navigateURL =
        "/employee/planning/team/managetarget/annualtarget/" +
        financialYearId +
        "/" +
        prevParameterId +
        "/team";
      navigateTo(navigateURL);
      navigateTo(0);
    } else {
      if (prevParameterId !== "") {
        var navigateURL =
          "/employee/planning/team/managetarget/annualtarget/" +
          financialYearId +
          "/" +
          prevParameterId +
          "/self";
        navigateTo(navigateURL);
        navigateTo(0);
      }
    }
  };

  const ShowNextParameter = async () => {
    if (nextParameterId === "") {
      //Show Confirmation Popup
      if (markAsIncompleteParameters.length > 0) {
        //SaveAll Targets
        setIsShowConfirmationPopUp(1); //Show Popup..
      } else {
        if (checkAllAreApprovedOrNot) {
          if (team === "team") {
            var navigateURL = "/employee/planning/team/managetarget/";
          } else {
            var navigateURL = "/employee/planning/self/manageplan/";
          }
          navigateTo(navigateURL);
          navigateTo(0);
        } else {
          // if (team === "self") {
          //     var navigateURL = "/employee/planning/self/manageplan/";
          //     navigateTo(navigateURL);
          //     navigateTo(0);
          // }
          // else {
          alertify.confirm(
            "Confirmation",
            "Do you want to submit?",
            async function () {
              //Set as all completed
              // This is not working for lower level employee: Pratap
              // if (currentTargetAddedByAdmin === true) {
              if (1) {
                //Admin Assign auto approve
                //Target Details
                var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: currentTargetId } }])
               
                for (let j = 0; j < currentTargetDetails.length; j++) {
                  // var getTargetDetails = await API.graphql({ query: queries.getTargetDetails, variables: { id: currentItem.id } });
                  // Update record
                  let updatedNew = await API.graphql({
                    query: mutations.updateTargetDetails,
                    variables: {
                      input: {
                        id: currentTargetDetails[j].id,
                        _version: currentTargetDetails[j]._version,
                        is_approve: true,
                        approved_date: moment().format()
                      }
                    }
                  });

                  /* await DataStore.save(
                    TargetDetails.copyOf(currentItem, (item) => {
                      item.is_approve = true;
                      item.approved_date = moment().format();
                    }),
                  ); */
                }
                // currentTargetDetails.map(async (currentItem) => {

                //   var getTargetDetails = await graphQLFindRecordById('getTargetDetails', currentItem.id)
                //   // var getTargetDetails = await API.graphql({ query: queries.getTargetDetails, variables: { id: currentItem.id } });

                //   // Update record

                //   await API.graphql({
                //     query: mutations.updateTargetDetails,
                //     variables: {
                //       input: {
                //         id: getTargetDetails.id,
                //         _version: getTargetDetails._version,
                //         is_approve: true,
                //         approved_date: moment().format()

                //       }
                //     }
                //   });

                //   /* await DataStore.save(
                //     TargetDetails.copyOf(currentItem, (item) => {
                //       item.is_approve = true;
                //       item.approved_date = moment().format();
                //     }),
                //   ); */

                // });
              }

              //Update status for employee submitted
              var currentTarget = await graphQLFindRecordById('getTargets', currentTargetId)

              // Update record
              let udpatedTarget = await API.graphql({
                query: mutations.updateTargets,
                variables: {
                  input: {
                    id: currentTarget.id,
                    _version: currentTarget._version,
                    isSubmittedByEmployee: true,
                  }
                }
              });

              /* var currentTarget = await DataStore.query(
                Targets,
                currentTargetId,
              ); 

              await DataStore.save(
                Targets.copyOf(currentTarget, (item) => {
                  item.isSubmittedByEmployee = true;
                }),
              );*/

              var employeeResponse = await graphQLFindRecordById('getEmployee', loggedEmployeeId)

              /*  var employeeResponse = await DataStore.query(
                 Employee,
                 loggedEmployeeId,
               ); */

              if (employeeResponse.parentEmployeeId) {
                let requestTypeName = NOTIFICATIONS_TYPES.SubmitPlan;
                let emailSubject = EMAIL_SUBJECTS.SubmitPlan;
                let notificationMsg =
                  "The Plan has been Submitted by " +
                  employeeResponse.firstName +
                  " " +
                  employeeResponse.lastName +
                  "(" +
                  employeeResponse.employeeId +
                  ")" +
                  " for " +
                  financialYear;
                await sendNotification(
                  loggedEmployeeId,
                  employeeResponse.parentEmployeeId,
                  requestTypeName,
                  notificationMsg,
                  emailSubject,
                );
              }

              toast.success("Target Submitted");
              // var navigateURL = "/employee/planning/team/managetarget/";
              navigateTo("/employee/planning/team/managetarget/");
              // navigateTo(0);
              //Show popup - all parm with target value

              //Redirect to Manage Target Page
            },
            function () { },
          );
          // }
        }
      }
    } else if (nextParameterId !== "" && team === "team") {
      var navigateURL =
        "/employee/planning/team/managetarget/annualtarget/" +
        financialYearId +
        "/" +
        nextParameterId +
        "/team";
      navigateTo(navigateURL);
      navigateTo(0);
    } else {
      var navigateURL =
        "/employee/planning/team/managetarget/annualtarget/" +
        financialYearId +
        "/" +
        nextParameterId +
        "/self";
      navigateTo(navigateURL);
      navigateTo(0);
    }

    // var employeeResponse = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(loggedEmployeeId));
    // if (employeeResponse.length > 0) {
    //     for (var i = 0; i < employeeResponse.length; i++) {
    //         let requestTypeName = NOTIFICATIONS_TYPES.SubmitPlan
    //         let notificationMsg = "The Plan has been Submitted by " + employeeName + "(" + employeeCode + ")" + " for " + financialYear
    //         await sendNotification(loggedEmployeeId, employeeResponse[0].id, requestTypeName, notificationMsg)
    //     }
    // }
  };

  //Message
  //--------------------------------------------
  const [messageList, setMessageList] = useState([]);
  const [receiverEmployeeId, setReceiverEmployeeId] = useState("");
  const [message, setMessage] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverProfile, setReceiverProfile] = useState("");
  const [msgUnreadCount, setMsgUnreadCount] = useState("");
  const [renderItems, setRenderItems] = useState(false);

  async function BindMessageList(currentTargetDetailId, receiverEmployeeId) {
    //Target Message list

    var targetMessageResponses = await graphQLGetAllData('listTargetMessages', [{ target_detail_id: { eq: currentTargetDetailId } }])
    targetMessageResponses = targetMessageResponses.sort((a, b) => a.messageDateTime.localeCompare(b.messageDateTime));

    /* var targetMessageResponses = await DataStore.query(
      TargetMessage,
      (c) => c.target_detail_id.eq(currentTargetDetailId),
      {
        sort: (s) => s.messageDateTime(SortDirection.ASCENDING),
      },
    ); */

    setMessageList(targetMessageResponses);

    //set Unread Count
    let arrayCount = targetMessageResponses.filter(
      (response) =>
        response.receiver_id === loggedEmployeeId && !response.isRead,
    );
    setMsgUnreadCount(arrayCount.length);

    //Receiver Employee Response
    let profilePic = "";
    const currentReceiverEmployee = await graphQLFindRecordById('getEmployee', receiverEmployeeId)

    /*  const currentReceiverEmployee = await DataStore.query(
       Employee,
       receiverEmployeeId,
     ); */

    if (currentReceiverEmployee !== undefined) {
      await Storage.get(currentReceiverEmployee.profilePic).then((result) => {
        profilePic = result;
      });
      setReceiverProfile(profilePic);
      setReceiverName(
        currentReceiverEmployee.firstName +
        " " +
        currentReceiverEmployee.lastName,
      );
    } else {
      setReceiverProfile(defaultImagePath);
      setReceiverName("Admin");
    }
  }

  async function SendMessage() {
    //Check Blank - message
    if (message === "") {
      toast.error("Please Enter Message.");
      return;
    }

    // Create record
    let createdTargetMsg = await API.graphql(
      graphqlOperation(mutations.createTargetMessage, {
        input: {
          target_detail_id: currentTargetDetailId,
          sender_id: loggedEmployeeId,
          receiver_id: receiverEmployeeId,
          messageDateTime: moment().toDate().toISOString(),
          isRead: false,
          message: message
        }
      })
    );


    /* await DataStore.save(
      new TargetMessage({
        target_detail_id: currentTargetDetailId,
        sender_id: loggedEmployeeId,
        receiver_id: receiverEmployeeId,
        messageDateTime: moment().toDate().toISOString(),
        isRead: false,
        message: message,
      }),
    ); */
    setMessage("");
    BindMessageList(currentTargetDetailId, receiverEmployeeId);
  }

  //--------------------------------------------

  //_________________________________ Parameters import from Excel ________________________________________//

  const [parametersData, setParametersData] = useState([]);
  const [parametersDropDownArray, SetParametersDropDownArray] = useState([]);
  const [transformParametersData, setTransformParametersData] = useState([]);
  const [isShowImportPopup, setIsShowImportPopup] = useState("false");

  const [importParameterSBU, setImportParameterSBU] = useState("");
  const [importParameterSBUId, setImportParameterSBUId] = useState("");

  const [importParameterBU, setImportParameterBU] = useState("");
  const [importParameterBUId, setImportParameterBUId] = useState("");

  const [importParameterRegion, setImportParameterRegion] = useState("");
  const [importParameterRegionId, setImportParameterRegionId] = useState("");

  const [importParameterCountry, setImportParameterCountry] = useState("");
  const [importParameterCountryId, setImportParameterCountryId] = useState("");

  const [importParameterBrand, setImportParameterBrand] = useState("");
  const [importParameterBrandId, setImportParameterBrandId] = useState("");

  const [importParameterProductGroup, setImportParameterProductGroup] =
    useState("");
  const [importParameterProductGroupId, setImportParameterProductGroupId] =
    useState("");

  const [importParameterCustomerGroup, setImportParameterCustomerGroup] =
    useState("");
  const [importParameterCustomerGroupId, setImportParameterCustomerGroupId] =
    useState("");

  const [importParameterCustomer, setImportParameterCustomer] = useState("");
  const [importParameterCustomerId, setImportParameterCustomerId] =
    useState("");

  //onClick Select Doc
  async function fileHandler(event) {
    let fileObj = event.target.files[0];
    //just pass the fileObj as parameter
    if (fileObj.size === 0) {
      toast.error("Do not select empty file.");
    } else {
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {

        } else {
          var parameterData = resp.rows;
          var newParameterArray = parameterData.slice(1);
          setParametersData(newParameterArray);
          let paramArray = resp.rows[0];
          let finalData = [];
          for (let i = 0; i < paramArray.length; i++) {
            finalData.push({ label: paramArray[i], value: i });
            if (i === paramArray.length - 1) {
              let sortArray = finalData.sort((a, b) =>
                a.label > b.label ? 1 : -1,
              );
              SetParametersDropDownArray(sortArray);
              setIsShowImportPopup(true);
            }
          }
        }
      });
    }
  }

  /*   async function CheckIdsInModel(modelName, findName) {
  
      let responses = await DataStore.query(modelName, (c) =>
        c.name.eq(findName),
      );
      if (responses.length > 0) {
        return responses[0].id;
      }
    } */

  //onClick Preview
  async function onClickParameterPreview() {
    let paramData = parametersData;
    let finalResponseData = [...planData];
    for (let i = 0; i < paramData.length; i++) {
      // debugger;
      let buId = "";
      let regionId = "";
      let countryId = "";
      let brandId = "";
      let pGId = "";
      let customerGroupId = "";
      let customerId = "";
      let buArray = [];
      let regionArray = [];
      let countryArray = [];
      let brandArray = [];
      let pGArray = [];
      let customerGroupArray = [];
      let customerArray = [];
      if (importParameterBUId !== "") {
        if (importParameterBUId !== "" && importParameterBU) {
          // if (paramData[i][importParameterBUId] === undefined) {
          //     buId = ""
          // } else {

          var BUResponses = await graphQLGetAllData('listBIUnits', [{ name: { eq: paramData[i][importParameterBUId] } }])

          /* var BUResponses = await DataStore.query(BIUnit, (c) =>
            c.name.eq(paramData[i][importParameterBUId]),
          ); */

          if (BUResponses.length > 0) {
            buId = BUResponses[0].id;
            buArray.push(BUResponses[0].id);

            if (importParameterRegionId && importParameterRegion) {

              var regionResponses = await graphQLGetAllData('listRegions', [{ name: { eq: paramData[i][importParameterRegionId] } }])

              /* var regionResponses = await DataStore.query(Region, (c) =>
                c.name.eq(paramData[i][importParameterRegionId]),
              ); */

              if (regionResponses.length > 0) {
                regionId = regionResponses[0].id;
                regionArray.push(regionResponses[0].id);
              } else {
                regionId = "";
              }
            }

            if (importParameterCountryId && importParameterCountry) {

              var countryResponses = await graphQLGetAllData('listCountries', [{ name: { eq: paramData[i][importParameterCountryId] } }])

              /*  var countryResponses = await DataStore.query(Country, (c) =>
                 c.name.eq(paramData[i][importParameterCountryId]),
               ); */

              if (countryResponses.length > 0) {
                countryId = countryResponses[0].id;
                countryArray.push(countryResponses[0].id);
              } else {
                countryId = "";
              }
            }

            if (importParameterBrandId && importParameterBrand) {
              var brandResponses = await graphQLGetAllData('listBrands', [{ name: { eq: paramData[i][importParameterBrandId] } }])

              /* var brandResponses = await DataStore.query(Brand, (c) =>
                c.name.eq(paramData[i][importParameterBrandId]),
              ); */

              if (brandResponses.length > 0) {
                brandId = brandResponses[0].id;
                brandArray.push(brandResponses[0].id);
              } else {
                brandId = "";
              }
            }

            if (importParameterProductGroupId && importParameterProductGroup) {

              var pGResponses = await graphQLGetAllData('listProductGroups', [{ name: { eq: paramData[i][importParameterProductGroupId] } }])

              /* var pGResponses = await DataStore.query(ProductGroup, (c) =>
                c.name.eq(paramData[i][importParameterProductGroupId]),
              ); */

              if (pGResponses.length > 0) {
                pGId = pGResponses[0].id;
                pGArray.push(pGResponses[0].id);
              } else {
                pGId = "";
              }
            }

            if (
              importParameterCustomerGroupId &&
              importParameterCustomerGroup
            ) {

              /*  var custGroupResponses = await DataStore.query(
                 CustomerCategory,
                 (c) => c.name.eq(paramData[i][importParameterCustomerGroupId]),
               ); */

              var custGroupResponses = await graphQLGetAllData('listCustomerCategories', [{ name: { eq: paramData[i][importParameterCustomerGroupId] } }])

              if (custGroupResponses.length > 0) {
                customerGroupId = custGroupResponses[0].id;
                customerGroupArray.push(custGroupResponses[0].id);
              } else {
                customerGroupId = "";
              }
            }

            if (importParameterCustomerId && importParameterCustomer) {

              var customerResponses = await graphQLGetAllData('listCustomers', [{ name: { eq: paramData[i][importParameterCustomerId] } }])

              /*  var customerResponses = await DataStore.query(Customer, (c) =>
                 c.name.eq(paramData[i][importParameterCustomerId]),
               ); */

              if (customerResponses.length > 0) {
                customerId = customerResponses[0].id;
                customerArray.push(customerResponses[0].id);
              } else {
                customerId = "";
              }
            }
          }
          // else { buId = "" }
          // }
        }

        //______________________________ do not remove this _____________________//

        // console.log("----------------------" + i)
        // console.log("buId---" + buId)
        // console.log("regionId----" + regionId)
        // console.log("countryId---" + countryId)
        // console.log("brandId---" + brandId)
        // console.log("pGId--" + pGId)
        // console.log("customerGroupId--" + customerGroupId)
        // console.log("customerId---" + customerId)
        // console.log("----------------------" + i)

        //______________________________ do not remove this console _____________________//

        if (
          buId === "" &&
          regionId === "" &&
          countryId === "" &&
          brandId === "" &&
          pGId === "" &&
          customerGroupId === "" &&
          customerId === ""
        ) {
        } else {
          let _finalResponseData = [...finalResponseData];
          let check_parameterData = _finalResponseData.every(
            (e) =>
              e.buId !== buId ||
              e.regionId !== regionId ||
              e.regionId !== regionId ||
              e.countryId !== countryId ||
              e.brandId !== brandId ||
              e.productGroupId !== pGId ||
              e.customerGroupId !== customerGroupId ||
              e.customerId !== customerId,
          );
          if (check_parameterData === true) {
            finalResponseData.push({
              id: uniqid(),
              sbuId: sbuId,
              buId: buId,
              regionId: regionId,
              countryId: countryId,
              brandId: brandId,
              productGroupId: pGId,
              customerGroupId: customerGroupId,
              customerId: customerId,
              targetValue: targetValueInput,
              Q1TargetValue: "",
              Q2TargetValue: "",
              Q3TargetValue: "",
              Q4TargetValue: "",
              disEqually: 1,
            });
          }
        }

        if (i === paramData.length - 1) {
          //______________________________ do not remove this console _____________________//
          // console.log("buId.length---" + buId.length)
          // console.log("regionArray.length---" + regionArray.length)
          // console.log("countryArray.length---" + countryArray.length)
          // console.log("brandArray.length---" + brandArray.length)
          // console.log("pGArray.length---" + pGArray.length)
          // console.log("customerGroupArray.length---" + customerGroupArray.length)
          // console.log("customerArray.length---" + customerArray.length)
          //______________________________ do not remove this console _____________________//

          //check empty
          if (
            buId.length === 0 &&
            regionArray.length === 0 &&
            countryArray.length === 0 &&
            brandArray.length === 0 &&
            pGArray.length === 0 &&
            customerGroupArray.length === 0 &&
            customerArray.length === 0
          ) {
            toast.error("No Record Match.");
            finalResponseData = [];
            setIsShowImportPopup(false);
            onClickClearParameter();
          } else {

            setPlanData(finalResponseData);
            setIsShowImportPopup(false);
            onClickClearParameter();
          }
        }
      } else {
        toast.error("BU should not be empty field.");
        return false;
      }

      // if (importParameterSBUId !== "" || importParameterRegionId !== "" || importParameterCountryId !== "" || importParameterBrandId !== "" ||
      //     importParameterProductGroupId !== "" || importParameterCustomerGroupId !== "" || importParameterCustomerId !== "") {

      //__________back up code_______________//

      // if (paramData[i][importParameterSBUId] !== "") {

      //     var bUResponses = await DataStore.query(BIUnit, (c) => c.name.eq(paramData[i][importParameterBUId]));
      //     let bU_Id = "";
      //     let buArray = [];
      //     if (bUResponses.length > 0) {
      //         bU_Id = bUResponses[0].id;
      //         buArray.push(bU_Id)
      //         let pG_Id = "";
      //         let region_Id = "";
      //         let country_Id = "";
      //         let brand_Id = "";
      //         let customerCategory_Id = "";
      //         let customer_Id = "";
      //         let regionArray = []; let countryArray = []; let brandArray = []; let pGArray = []; let customerGroupArray = []; let customerArray = [];

      //         //Check region
      //         if (paramData[i][importParameterRegionId] === undefined) { region_Id = "" }
      //         else if (paramData[i][importParameterRegionId] !== "") {
      //             let data = await CheckIdsInModel(Region, paramData[i][importParameterRegionId])
      //             if (data) { region_Id = data; regionArray.push(data) } else { continue }
      //         }

      //         //Check Country
      //         if (paramData[i][importParameterCountryId] === undefined) { country_Id = "" }
      //         else if (paramData[i][importParameterCountryId] !== undefined) {
      //             let data = await CheckIdsInModel(Country, paramData[i][importParameterCountryId])
      //             if (data === undefined) { continue; }
      //             else { country_Id = data; countryArray.push(data) }
      //         }

      //         //Check Brand
      //         if (paramData[i][importParameterBrandId] === undefined) { brand_Id = "" }
      //         else if (paramData[i][importParameterBrandId] !== undefined) {
      //             let data = await CheckIdsInModel(Brand, paramData[i][importParameterBrandId])
      //             if (data === undefined) { continue; }
      //             else { brand_Id = data; brandArray.push(data) }
      //         }

      //         //Check ProductGroup
      //         if (paramData[i][importParameterProductGroupId] === undefined) { pG_Id = "" }
      //         else if (paramData[i][importParameterProductGroupId] !== undefined) {
      //             let data = await CheckIdsInModel(ProductGroup, paramData[i][importParameterProductGroupId])
      //             if (data === undefined) { continue; }
      //             else { pG_Id = data; pGArray.push(data) }
      //         }

      //         //Check CustomerCategory
      //         if (paramData[i][importParameterCustomerGroupId] === undefined) { customerCategory_Id = "" }
      //         else if (paramData[i][importParameterCustomerGroupId] !== undefined) {
      //             let data = await CheckIdsInModel(CustomerCategory, paramData[i][importParameterCustomerGroupId])
      //             if (data === undefined) { continue; }
      //             else { customerCategory_Id = data; customerGroupArray.push(data) }
      //         }

      //         //Check Customer
      //         if (paramData[i][importParameterCustomerId] === undefined) { customer_Id = "" }
      //         else if (paramData[i][importParameterCustomerId] !== undefined) {
      //             let data = await CheckIdsInModel(Customer, paramData[i][importParameterCustomerId])
      //             if (data === undefined) { continue; }
      //             else { customer_Id = data; customerArray.push(customerArray) }
      //         }

      //         finalResponseData.push({
      //             "id": uniqid(), "sbuId": sbuId, "buId": bU_Id, "regionId": region_Id, "countryId": country_Id,
      //             "brandId": brand_Id, "productGroupId": pG_Id,
      //             "customerGroupId": customerCategory_Id, "customerId": customer_Id, "targetValue": targetValueInput,
      //             "Q1TargetValue": '', "Q2TargetValue": '', "Q3TargetValue": '', "Q4TargetValue": '', "disEqually": 1
      //         });

      //         //check empty
      //         // if (buArray.length === 0 && regionArray.length === 0 && countryArray.length === 0 && brandArray.length > 0
      //         //     && pGArray.length === 0 && customerGroupArray.length === 0 && customerArray.length === 0) {
      //         //     toast.error('No Record Found.');
      //         //     finalResponseData = [];
      //         //     setIsShowImportPopup(false)
      //         //     onClickClearParameter()
      //         // }
      //         // else {
      //             setIsShowImportPopup(false)
      //             onClickClearParameter()
      //         // }
      //     }
      // } else {
      //     toast.error('Please select atleast one parameter.');
      //     return true;
      // }
      // }

      //__________back up code_______________//

      // } else {
      //     toast.error('Please select atleast one parameter.');
      //     //return true;
      // }
    }
  }

  //clear parameter
  async function onClickClearParameter() {
    setImportParameterBUId("");
    setImportParameterSBUId("");
    setImportParameterRegionId("");
    setImportParameterCountryId("");
    setImportParameterBrandId("");
    setImportParameterProductGroupId("");
    setImportParameterCustomerGroupId("");
    setImportParameterCustomerId("");

    setImportParameterBU(null);
    setImportParameterSBU(null);
    setImportParameterRegion(null);
    setImportParameterCountry(null);
    setImportParameterBrand(null);
    setImportParameterProductGroup(null);
    setImportParameterCustomerGroup(null);
    setImportParameterCustomer(null);
  }

  //_________________________________ Parameters import from Excel ________________________________________//

  //Parameters Carousel
  var parametersCarouselIndex = 0;
  const parametersCarouselTemplate = (currentRequestParameterList) => {


    parametersCarouselIndex++;
    return (
      <div className="product-item">
        <div className="product-item-content relative ">
          {
            <div key={currentRequestParameterList["parameterId"]}>
              <div
                className={`annual_target_box justify-between items-center rounded-lg p-3 content-center flex ${currentRequestParameterList["parameterId"] === parameterId
                  ? "activegreen shadow-md"
                  : "bg-[#fff] shadow-md dark:bg-[#212529] dark:border-[#2A2E32]"
                  }`}
              >
                <div className="flex-col">
                  <div className="flex items-center gap-3">
                    <div>
                      <div className="flex gap-3">
                        <h6
                          className={`${currentRequestParameterList["parameterId"] ===
                            parameterId
                            ? "text-white text-[18px] text-left"
                            : "text-[#667085] text-[18px] font-meduim text-left"
                            }`}
                        >
                          {currentRequestParameterList["parameterName"]}
                        </h6>
                        {/* { <div className="flex items-center gap-2 p-1.5 px-2 text-[12px] rounded-full text-[#029046] bg-[#E5F3EC]"><div className="h-2 w-2 rounded-full bg-[#029046]"></div>Approved</div>
                                            <div className="flex items-center gap-2 p-1.5 px-2 text-[12px] rounded-full text-[#FF4537] bg-[#FEE4E2]"><div className="h-2 w-2 rounded-full bg-[#FF4537]"></div>Revision</div>
                                            <div className="flex items-center gap-2 p-1.5 px-2 text-[12px] rounded-full text-[#1B55AF] bg-[#e6f0ff]"><div className="h-2 w-2 rounded-full bg-[#1B55AF]"></div>Open</div>} */}
                        {currentRequestParameterList["parameterId"] ===
                          parameterId
                          ? showStatus()
                          : ""}
                      </div>
                      <h2
                        className={`${currentRequestParameterList["parameterId"] ===
                          parameterId
                          ? "text-white text-[22px] font-medium text-left"
                          : "text-[#101828] text-[22px] font-medium dark:text-white text-left"
                          }`}
                      >
                        {currentRequestParameterList["parameterUnit"] === "A"
                          ? PARAMETERS_TYPES_NAMES[
                          currentRequestParameterList["parameterUnit"]
                          ]
                          : ""}
                        {currentRequestParameterList["parameterUnit"] === "P" && currentRequestParameterList["targetValue"] < 1 ? (currentRequestParameterList["targetValue"] * 100).toFixed(2) : currentRequestParameterList["targetValue"]}
                        {currentRequestParameterList["parameterUnit"] !== "A"
                          ? PARAMETERS_TYPES_NAMES[
                          currentRequestParameterList["parameterUnit"]
                          ]
                          : ""}
                      </h2>
                    </div>
                  </div>
                  {
                    //currentRequestParameterList['parameterId'] === parameterId ?
                    <div className="mt-5">
                      <div className="bg-[#fff] p-2 flex gap-3 rounded-lg dark:bg-[#1c1d1e]">
                        <div
                          className={
                            currentRequestParameterList["parameterId"] ===
                              parameterId
                              ? "bg-[#ffffff] text-[#101828]"
                              : "bg-[#EEF8F4] text-[#101828] p-2 flex rounded-lg dark:bg-[#1c1d1e]"
                          }
                        >
                          <input
                            className={
                              currentRequestParameterList["parameterId"] ===
                                parameterId
                                ? "ml-1 text-[#344054] text-[14px]"
                                : "ml-1 text-[#344054] text-[14px] opacity-100 pointer-events-none"
                            }
                            onChange={(e) => {
                              ChangeRequestType("A");
                              setIsPlanMandatory(
                                currentRequestParameterList["isPlanMandatory"],
                              );
                            }}
                            type="radio"
                            checked={
                              requestType === "A" &&
                                currentRequestParameterList["parameterId"] ===
                                parameterId
                                ? "checked"
                                : currentRequestParameterList["status"] === "A"
                                  ? "checked"
                                  : ""
                            }
                            id={
                              "chkAccept_" +
                              currentRequestParameterList["parameterId"]
                            }
                            name={
                              "chkGroupAcceptRevision" +
                              [parametersCarouselIndex]
                            }
                          />
                          <label
                            className={
                              currentRequestParameterList["parameterId"] ===
                                parameterId
                                ? "ml-1 text-[#344054] text-[14px]"
                                : "ml-1 text-[#344054] text-[14px] opacity-100 pointer-events-none"
                            }
                            htmlFor={
                              "chkAccept_" +
                              currentRequestParameterList["parameterId"]
                            }
                          >
                            Accept
                          </label>
                        </div>
                        {currentTargetAddedByAdmin === false ? (
                          <div
                            className={
                              currentRequestParameterList["parameterId"] ===
                                parameterId
                                ? "bg-[#ffffff] text-[#101828]"
                                : "bg-[#EEF8F4] text-[#101828] p-2 flex rounded-lg dark:bg-[#1c1d1e]"
                            }
                          >
                            <input
                              className={
                                currentRequestParameterList["parameterId"] ===
                                  parameterId
                                  ? "ml-1 text-[#344054] text-[14px]"
                                  : "ml-1 text-[#344054] text-[14px] opacity-100 pointer-events-none"
                              }
                              onChange={(e) => {
                                setShowRequestForRevisionPopUp(1);
                                setIsPlanMandatory(
                                  currentRequestParameterList[
                                  "isPlanMandatory"
                                  ],
                                );
                              }}
                              type="radio"
                              checked={
                                requestType === "R" &&
                                  currentRequestParameterList["parameterId"] ===
                                  parameterId
                                  ? "checked"
                                  : currentRequestParameterList["status"] ===
                                    "R"
                                    ? "checked"
                                    : ""
                              }
                              id={
                                "chkRevision_" +
                                currentRequestParameterList["parameterId"]
                              }
                              name={
                                "chkGroupAcceptRevision" +
                                [parametersCarouselIndex]
                              }
                            />
                            <label
                              className={
                                currentRequestParameterList["parameterId"] ===
                                  parameterId
                                  ? "ml-1 text-[#344054] text-[14px]"
                                  : " ml-1 text-[#344054] text-[14px] opacity-100 pointer-events-none"
                              }
                              htmlFor={
                                "chkRevision_" +
                                currentRequestParameterList["parameterId"]
                              }
                            >
                              Request for Revision
                            </label>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    //  : ""
                  }
                </div>

                <div className="">
                  {currentRequestParameterList["parameterId"] ===
                    parameterId ? (
                    <div className="annual_target_img_green"></div>
                  ) : (
                    <div className="annual_target_img_green_regular"></div>
                  )}
                  <div className="absolute top-3 right-3 font-medium text-center text-[#fff]">
                    {currentRequestParameterList["isPlanMandatory"] === 1 ? (
                      <i className="icon s_icon"></i>
                    ) : (
                      <i className="icon t_icon"></i>
                    )}
                  </div>
                  {/* className="absolute top-0 right-2 rounded-full bg-[#333] h-6 w-6 font-medium text-center text-[#fff]">{currentRequestParameterList['isPlanMandatory'] === 1 ? "S" : "T"}</div> */}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  };

  useEffect(() => {
    BindList();
  }, [])

  useEffect(() => {
    onLoad();
    // BindList();
    LoadPageData();
  }, [carouselActivePageNo]);

  useEffect(() => {
    if (isPlanUpdated) {
      CalculateDimension();
    }
  }, [isPlanUpdated, isPageLoaded]);

  const navigateToScreen = () => {
    let isReportingManager = reactLocalStorage.get("isReportingManager");
    if (isReportingManager === "0") {
      navigateTo("/employee/planning/self/manageplan");
      navigateTo(0);
    } else {
      if (team !== "team") {
        navigateTo("/employee/planning/self/manageplan");
        navigateTo(0);
      } else {
        navigateTo("/employee/planning/team/managetarget");
        navigateTo(0);
      }
    }
  };

  const showStatus = () => {
    return (
      <div>
        {currentTargetStatus === "Accepted" ? (
          <div className="flex items-center gap-2 p-1.5 px-2 text-[12px] rounded-full text-[#029046] bg-[#E5F3EC]">
            <div className="h-2 w-2 rounded-full bg-[#029046]"></div>Accepted
          </div>
        ) : currentTargetStatus === "Approved" ? (
          <div className="flex items-center gap-2 p-1.5 px-2 text-[12px] rounded-full text-[#029046] bg-[#E5F3EC]">
            <div className="h-2 w-2 rounded-full bg-[#029046]"></div>Approved
          </div>
        ) : currentTargetStatus === "Open" ? (
          <div className="flex items-center gap-2 p-1.5 px-2 text-[12px] rounded-full text-[#1B55AF] bg-[#e6f0ff]">
            <div className="h-2 w-2 rounded-full bg-[#1B55AF]"></div>Open
          </div>
        ) : currentTargetStatus === "Send for revision" ? (
          <div className="flex items-center gap-2 p-1.5 px-2 text-[12px] rounded-full text-[#FF4537] bg-[#FEE4E2]">
            <div className="h-2 w-2 rounded-full bg-[#FF4537]"></div>Revision
          </div>
        ) : (
          ""
        )}
        {/* <div className="flex items-center gap-2 p-1.5 px-2 text-[12px] rounded-full text-[#FF4537] bg-[#FEE4E2]"><div className="h-2 w-2 rounded-full bg-[#FF4537]"></div>Revision</div> */}
        {/* <div className="flex items-center gap-2 p-1.5 px-2 text-[12px] rounded-full text-[#1B55AF] bg-[#e6f0ff]"><div className="h-2 w-2 rounded-full bg-[#1B55AF]"></div>Open</div> */}
      </div>
    );
  };

  const openLink = (rowData) => {
    return (
      <button
        onClick={() => openIncompleteParameter(rowData)}
      //onClick={() => navigateTo("/employee/planning/team/reviewcommit/annualtarget/planreview/" + financialYearId + "/" + parameterId + "/" + rowData.employeeId + "/" + rowData.targetId)}
      >
        <p className="bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center ">
          {" "}
          <div className="w-2 h-2 bg-[#1B55AF] rounded-full"></div>
          {"Open"}
        </p>
      </button>
    );
  };

  const openIncompleteParameter = (data) => {
    if (team === "team") {
      var navigateURL = data.openLink + "/team";
      navigateTo(navigateURL);
      navigateTo(0);
    } else {
      var navigateURL = data.openLink + "/self";
      // var navigateURL = "/employee/planning/team/managetarget/annualtarget/" + financialYearId + "/" + prevParameterId + "/self";
      navigateTo(navigateURL);
      navigateTo(0);
    }
  };

  const avgValue = (options) => {
    let total = 0;
    let length = options.props.value.length;
    options.props.value.map((e) => {
      total += e.targetValue;
    })
    let calculatedValue = total / length;
    // console.log("test123", parseFloat(calculatedValue).toFixed(2));
    return isNaN(calculatedValue) ? "0.00" : calculatedValue.toFixed(2);
  }
  return isAuthenticated ? (
    <div className="pt-16">
      <TopNav />
      <LeftNav />

      <div className="target-main-wrapper pl-16" x-data="{tab: 1}">
        <div className="p-5">
          <div className="flex justify-between">
            <div className="sm:flex items-center space-x-4">
              <Link
                onClick={() => navigateToScreen()}
                className="text-[#344054] text-[14px] dark:text-white py-1.5 px-3 rounded-md border border-[#D0D5DD]"
              >
                <i className="icon arrow_left_double mr-6 "></i>Back
              </Link>
              <div className="text-2xl font-semibold dark:text-white">
                {" "}
                Annual Target
              </div>
              <div className="text-xs border text-[#667085] border-[#667085] rounded p-1">
                FY: {financialYear}
              </div>
            </div>
            <div className="mt-5 lg:mt-0 flex items-center gap-4"></div>
          </div>

          <div className="col-span-9 mt-1 silder_managetarget">
            <div className="carousel-demo">
              <div className="card">
                {isPageLoaded ? (
                  <Carousel
                    value={parametersCarousel}
                    page={carouselActivePageNo}
                    numVisible={3}
                    numScroll={1}
                    responsiveOptions={parametersCarouselResponsiveOptions}
                    itemTemplate={parametersCarouselTemplate}
                  />
                ) : (
                  <div className="text-center py-8">
                    <ProgressSpinner />
                  </div>
                )}
              </div>
            </div>
          </div>

          {requestType !== "" ? (
            <div>
              <div className="bg-white rounded-lg mt-2 border border-[#E4E7EC] p-4 px-3 dark:bg-[#2A2E32] dark:border-[#2A2E32]">
                <div className="grid grid-cols-8 lg:grid-cols-10 2xl:grid-cols-10 flex flex-1 content-center items-center gap-3">
                  <div className="col-span-10">
                    <div className="grid grid-cols-5 lg:grid-cols-5 2xl:grid-cols-5 gap-3 h-auto">
                      <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                        <div className="flex justify-between items-center gap-3">
                          <div className="bg-white rounded-lg p-2 border border-dashed border-[#4FB155] 2xl:w-14 md:w-12 xl:w-10 sm:w-10">
                            <img src={AnnualSaleIcon} alt=""></img>
                          </div>
                          <div>
                            <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]mb-2">
                              {requestType === "R"
                                ? "Request Annual Target"
                                : "Annual Target"}
                            </h6>
                            <div className="flex gap-3">
                              <h2 className="text-[#101828] xl:text-[16px] 2xl:text-[16px] lg:text-[15px] font-medium dark:text-[#FFFFFF]">
                                {parameterType === "A"
                                  ? PARAMETERS_TYPES_NAMES[parameterType]
                                  : ""}
                                {requestType !== "R"
                                  ? parseFloat(parameterAnnualTarget) < 1 && parameterType === "P" ? commaSeparated(parseFloat(parameterAnnualTarget * 100).toFixed(2,),) : parameterType === "P" ? commaSeparated(parseFloat(parameterAnnualTarget).toFixed(2,),) : commaSeparated(parseFloat(parameterAnnualTarget).toFixed(0,),) : commaSeparated(requestRevisionValue)}
                                {parameterType !== "A"
                                  ? " " + PARAMETERS_TYPES_NAMES[parameterType]
                                  : ""}
                              </h2>
                              {parameterType === "A" ? (
                                parameterMatchPer < 100 ? (
                                  <div className="bg-[#FEE4E2] py-1 px-2 rounded-md text-[#FF4537]">
                                    {parameterMatchPer}%
                                  </div>
                                ) : (
                                  <div className="bg-[#029046] py-1 px-2 rounded-md text-[#FFF] xl:text-[12px] 2xl:text-[14px] md:text-[12px]">
                                    {parameterMatchPer}%
                                  </div>
                                )
                              ) : (
                                <div className="bg-[#fff] py-1 px-2 rounded-md text-[#029046] xl:text-[12px] 2xl:text-[14px] md:text-[12px]">
                                  N/A
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                        <div className="flex justify-between items-center gap-3">
                          <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155] 2xl:w-14 md:w-12 xl:w-10 sm:w-10">
                            <img src={quarterIcon} alt=""></img>
                          </div>
                          <div>
                            <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">
                              Quarter 1
                            </h6>
                            <div className="flex gap-3">
                              <h2 className="text-[#101828] xl:text-[16px] 2xl:text-[16px] lg:text-[15px] font-medium dark:text-[#FFFFFF]">
                                {parameterType === "A"
                                  ? PARAMETERS_TYPES_NAMES[parameterType]
                                  : ""}
                                {commaSeparated(parameterType === 'P' ? parseFloat(parameterQ1Target).toFixed(2) : parameterQ1Target)}
                                {parameterType !== "A"
                                  ? " " + PARAMETERS_TYPES_NAMES[parameterType]
                                  : ""}
                              </h2>
                              <div className="bg-[#fff] py-1 px-2 rounded-md text-[#029046] xl:text-[12px] 2xl:text-[14px] md:text-[12px]">
                                {parameterType === "A"
                                  ? parameterQ1Percentage
                                  : ""}
                                {parameterType === "A" ? "%" : "N/A"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                        <div className="flex justify-between items-center gap-3">
                          <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155] 2xl:w-14 md:w-12 xl:w-10 sm:w-10">
                            <img src={quarterIcon} alt=""></img>
                          </div>
                          <div>
                            <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">
                              Quarter 2
                            </h6>
                            <div className="flex gap-3">
                              <h2 className="text-[#101828] xl:text-[16px] 2xl:text-[16px] lg:text-[15px]font-medium dark:text-[#FFFFFF] ">
                                {parameterType === "A"
                                  ? PARAMETERS_TYPES_NAMES[parameterType]
                                  : ""}
                                {commaSeparated(parameterType === 'P' ? parseFloat(parameterQ2Target).toFixed(2) : parameterQ2Target)}
                                {parameterType !== "A"
                                  ? " " + PARAMETERS_TYPES_NAMES[parameterType]
                                  : ""}
                              </h2>
                              <div className="bg-[#fff] py-1 px-2 rounded-md text-[#029046] xl:text-[12px] 2xl:text-[14px] md:text-[12px]">
                                {parameterType === "A"
                                  ? parameterQ2Percentage
                                  : ""}
                                {parameterType === "A" ? "%" : "N/A"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                        <div className="flex justify-between items-center gap-3">
                          <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155] 2xl:w-14 md:w-12 xl:w-10 sm:w-10">
                            <img src={quarterIcon} alt=""></img>
                          </div>
                          <div>
                            <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">
                              Quarter 3
                            </h6>
                            <div className="flex gap-3">
                              <h2 className="text-[#101828] xl:text-[16px] 2xl:text-[16px] lg:text-[15px] font-medium dark:text-[#FFFFFF]">
                                {parameterType === "A"
                                  ? PARAMETERS_TYPES_NAMES[parameterType]
                                  : ""}
                                {commaSeparated(parameterType === 'P' ? parseFloat(parameterQ3Target).toFixed(2) : parameterQ3Target)}
                                {parameterType !== "A"
                                  ? " " + PARAMETERS_TYPES_NAMES[parameterType]
                                  : ""}
                              </h2>
                              <div className="bg-[#fff] py-1 px-2 rounded-md text-[#029046] xl:text-[12px] 2xl:text-[14px] md:text-[12px]">
                                {parameterType === "A"
                                  ? parameterQ3Percentage
                                  : ""}
                                {parameterType === "A" ? "%" : "N/A"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex  dark:bg-[#212529] dark:border-[#2A2E32]">
                        <div className="flex justify-between items-center gap-3">
                          <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155] 2xl:w-14 md:w-12 xl:w-10 sm:w-10">
                            <img src={quarterIcon} alt=""></img>
                          </div>
                          <div>
                            <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">
                              Quarter 4
                            </h6>
                            <div className="flex gap-3">
                              <h2 className="text-[#101828] xl:text-[16px] 2xl:text-[16px] lg:text-[15px] font-medium dark:text-[#FFFFFF]">
                                {parameterType === "A"
                                  ? PARAMETERS_TYPES_NAMES[parameterType]
                                  : ""}
                                {commaSeparated(parameterType === 'P' ? parseFloat(parameterQ4Target).toFixed(2) : parameterQ4Target)}
                                {parameterType !== "A"
                                  ? " " + PARAMETERS_TYPES_NAMES[parameterType]
                                  : ""}
                              </h2>
                              <div className="bg-[#fff] py-1 px-2 rounded-md text-[#029046] xl:text-[12px] 2xl:text-[14px] md:text-[12px]">
                                {parameterType === "A"
                                  ? parameterQ4Percentage
                                  : ""}
                                {parameterType === "A" ? "%" : "N/A"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-5 accordionsingle">
                <Accordion activeIndex={1}>
                  <AccordionTab header="Dimensions">
                    <div className="grid grid-cols-6 lg:grid-cols-6 2xl:grid-cols-6 flex flex-1 content-start items-start gap-2">
                      <DataTable
                        value={buDimensionData}
                        scrollable
                        scrollHeight="flex"
                        resizableColumns
                        columnResizeMode="fit"
                        emptyMessage="&nbsp;"
                      >
                        <Column
                          field="BU"
                          sortable
                          header="BU"
                          footer="Total"
                        ></Column>
                        <Column
                          field="DisplayPercentage"
                          header=""
                          footer={buDimensionFooterTotal}
                          align="right"
                        ></Column>
                      </DataTable>
                      <DataTable
                        value={regionDimensionData}
                        scrollable
                        scrollHeight="flex"
                        resizableColumns
                        columnResizeMode="fit"
                        emptyMessage="&nbsp;"
                      >
                        <Column
                          field="Cluster"
                          sortable
                          header="Cluster"
                          footer="Total"
                        ></Column>
                        <Column
                          field="DisplayPercentage"
                          header=""
                          footer={regionDimensionFooterTotal}
                          align="right"
                        ></Column>
                      </DataTable>
                      <DataTable
                        value={countryDimensionData}
                        scrollable
                        scrollHeight="flex"
                        resizableColumns
                        columnResizeMode="fit"
                        emptyMessage="&nbsp;"
                      >
                        <Column
                          field="Country"
                          sortable
                          header="Country"
                          footer="Total"
                        ></Column>
                        <Column
                          field="DisplayPercentage"
                          footer={countryDimensionFooterTotal}
                          align="right"
                        ></Column>
                      </DataTable>
                      <DataTable
                        value={brandDimensionData}
                        scrollable
                        scrollHeight="flex"
                        resizableColumns
                        emptyMessage="&nbsp;"
                      >
                        <Column
                          field="Brand"
                          sortable
                          header="Brand"
                          footer="Total"
                        ></Column>
                        <Column
                          field="DisplayPercentage"
                          header=""
                          footer={brandDimensionFooterTotal}
                          align="right"
                        ></Column>
                      </DataTable>
                      {!checkEmployeeTeamType() && (
                        <DataTable
                          value={gtmDimensionData}
                          scrollable
                          scrollHeight="flex"
                          resizableColumns
                          emptyMessage="&nbsp;"
                        >
                          <Column
                            field="GTM"
                            sortable
                            header="GTM"
                            footer="Total"
                          ></Column>
                          <Column
                            field="DisplayPercentage"
                            header=""
                            footer={gtmDimensionFooterTotal}
                            align="right"
                          ></Column>
                        </DataTable>
                      )}
                      {!checkEmployeeTeamType() && (
                        <DataTable
                          value={subGtmDimensionData}
                          scrollable
                          scrollHeight="flex"
                          resizableColumns
                          emptyMessage="&nbsp;"
                        >
                          <Column
                            field="SubGTM"
                            sortable
                            header="Sub GTM"
                            footer="Total"
                          ></Column>
                          <Column
                            field="DisplayPercentage"
                            header=""
                            footer={subGtmDimensionFooterTotal}
                            align="right"
                          ></Column>
                        </DataTable>
                      )}
                      <DataTable
                        value={productGroupDimensionData}
                        scrollable
                        scrollHeight="flex"
                        resizableColumns
                        columnResizeMode="fit"
                        emptyMessage="&nbsp;"
                      >
                        <Column
                          field="ProductGroup"
                          sortable
                          header="Product Group"
                          footer="Total"
                        ></Column>
                        <Column
                          field="DisplayPercentage"
                          header=""
                          footer={productGroupDimensionFooterTotal}
                          align="right"
                        ></Column>
                      </DataTable>
                      {checkEmployeeTeamType() && (
                        <DataTable
                          value={customerSegmentDimensionData}
                          scrollable
                          scrollHeight="flex"
                          resizableColumns
                          columnResizeMode="fit"
                          emptyMessage="&nbsp;"
                        >
                          <Column
                            field="CustomerSegment"
                            sortable
                            header="Cust. Group"
                            footer="Total"
                          ></Column>
                          <Column
                            field="DisplayPercentage"
                            header=""
                            footer={customerSegmentDimensionFooterTotal}
                            align="right"
                          ></Column>
                        </DataTable>
                      )}
                      {checkEmployeeTeamType() && (
                        <DataTable
                          value={customerDimensionData}
                          scrollable
                          scrollHeight="flex"
                          resizableColumns
                          columnResizeMode="fit"
                          emptyMessage="&nbsp;"
                        >
                          <Column
                            field="Customer"
                            sortable
                            header="Customer"
                            footer="Total"
                          ></Column>
                          <Column
                            field="DisplayPercentage"
                            header=""
                            footer={customerDimensionFooterTotal}
                            align="right"
                          ></Column>
                        </DataTable>
                      )}
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>

              <div className="bg-white rounded-lg mt-5 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                {
                  <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold dark:text-white">
                      Plan
                    </h2>

                    {isPlanMandatory ? (
                      <div
                        className="w-32 py-1.5 px-4 border border-[#4FB155] text-[#4FB155] transition ease-in duration-200 text-[14px] text-center font-medium  
                                focus:outline-none leading-6  justify-center dark:border-[#4FB155] rounded-lg cursor-pointer relative"
                      >
                        <div className="absolute cursor-pointer left-0 top-0  opacity-0 w-32 h-[40px] ">
                          <input
                            onChange={(e) => {
                              fileHandler(e);
                            }}
                            accept=".csv"
                            multiple="multiple"
                            type="file"
                            className="w-32 cursor-pointer"
                          />
                        </div>
                        <i className="btnbtn import_icon mr-6"></i> Import
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                }
                {!isDisabled && (
                  <div className="grid grid-cols-6 mt-5 lg:grid-cols-6 2xl:grid-cols-6 gap-4 h-auto divSmallControls">
                    <Select
                      isClearable={true}
                      value={buDropdown}
                      options={buDropdownList}
                      onChange={(e) => {
                        onPlanItemSelectedChange("BU", e);
                      }}
                      placeholder={"BU"}
                      className="my-react-select-container  w-full"
                      classNamePrefix="my-react-select"
                    />

                    <Select
                      isClearable={true}
                      value={regionDropdown}
                      options={regionDropdownList}
                      onChange={(e) => {
                        onPlanItemSelectedChange("R", e);
                      }}
                      placeholder={"Cluster"}
                      className="my-react-select-container  w-full"
                      classNamePrefix="my-react-select"
                    />

                    <Select
                      isClearable={true}
                      value={countryDropdown}
                      options={countryDropdownList}
                      onChange={(e) => {
                        onPlanItemSelectedChange("C", e);
                      }}
                      placeholder={"Country"}
                      className="my-react-select-container  w-full"
                      classNamePrefix="my-react-select"
                    />

                    <Select
                      isClearable={true}
                      value={brandDropdown}
                      options={brandDropdownList}
                      onChange={(e) => {
                        onPlanItemSelectedChange("B", e);
                      }}
                      placeholder={"Brand"}
                      className="my-react-select-container  w-full"
                      classNamePrefix="my-react-select"
                    />

                    <Select
                      isClearable={true}
                      value={productGroupDropdown}
                      options={productGroupDropdownList}
                      onChange={(e) => {
                        onPlanItemSelectedChange("PG", e);
                      }}
                      placeholder={"Product Group"}
                      className="my-react-select-container  w-full"
                      classNamePrefix="my-react-select"
                    />

                    <Select
                      isClearable={true}
                      value={customerGroupDropdown}
                      options={customerGroupDropdownList}
                      onChange={(e) => {
                        onPlanItemSelectedChange("CG", e);
                      }}
                      placeholder={"Cust. Group"}
                      className="my-react-select-container  w-full"
                      classNamePrefix="my-react-select"
                    />

                    <Select
                      isClearable={true}
                      value={customerDropdown}
                      options={customerDropdownList}
                      onChange={(e) => {
                        onPlanItemSelectedChange("Cus", e);
                      }}
                      placeholder={"Customer"}
                      className="my-react-select-container  w-full"
                      classNamePrefix="my-react-select"
                    />

                    <Select
                      isClearable={true}
                      value={GTMdropdown}
                      options={GTMDropdownList}
                      onChange={(e) => {
                        onPlanItemSelectedChange("Gtm", e);
                      }}
                      placeholder={"GTM"}
                      className="my-react-select-container  w-full"
                      classNamePrefix="my-react-select"
                    />

                    <Select
                      isClearable={true}
                      value={SubGTMdropdown}
                      options={SubGTMDropdownList}
                      onChange={(e) => {
                        onPlanItemSelectedChange("SubGtm", e);
                      }}
                      placeholder={"Sub GTM"}
                      className="my-react-select-container  w-full"
                      classNamePrefix="my-react-select"
                    />



                    {isPlanMandatory === 0 ? (
                      <button
                        type="button"
                        disabled
                        className="disabled:opacity-50 py-1 px-2 text-[#4FB155] transition ease-in duration-200 width-[200] text-[14px] text-center font-medium focus:outline-none leading-6  justify-center rounded-lg border border-[#4FB155] rounded-md"
                      >
                        Add
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => addPlanItem()}
                        className="py-1 px-2 text-[#4FB155] transition ease-in duration-200 width-[200] text-[14px] text-center font-medium focus:outline-none leading-6  justify-center rounded-lg border border-[#4FB155] rounded-md"
                      >
                        Add
                      </button>
                    )}
                  </div>
                )}

                <div className="dashboard-main-wrapper rounded-lg mt-5 divSmallTableControls ">
                  <React.Fragment>
                    <DataTable
                      value={planData}
                      onLoadedData={CalculateDimension}
                      showGridlines
                      editMode="cell"
                      editable="true"
                      responsiveLayout="scroll"
                    >
                      <Column
                        field="buId"
                        header="BU"
                        body={buBodyTemplate}
                        style={{ width: "8%" }}
                      ></Column>
                      <Column
                        field="regionId"
                        header="Cluster"
                        body={regionBodyTemplate}
                        style={{ width: "8%" }}
                      ></Column>
                      <Column
                        field="countryId"
                        header="Country"
                        body={countryBodyTemplate}
                        style={{ width: "8%" }}
                      ></Column>
                      <Column
                        field="brandId"
                        header="Brand"
                        body={brandBodyTemplate}
                        style={{ width: "8%" }}
                      ></Column>
                      {!checkEmployeeTeamType() && (
                        <Column
                          field="gtmId"
                          header="GTM"
                          body={GTMBodyTemplate}
                          style={{ width: "8%" }}
                        ></Column>
                      )}
                      {!checkEmployeeTeamType() && (
                        <Column
                          field="subGtmId"
                          header="Sub GTM"
                          body={SubGTMBodyTemplate}
                          style={{ width: "8%" }}
                        ></Column>
                      )}
                      <Column
                        field="productGroupId"
                        header="Product Group"
                        body={productGroupBodyTemplate}
                        style={{ width: "8%" }}
                      ></Column>
                      {!checkEmployeeTeamType() && (
                        <Column
                          field="customerGroupId"
                          header="Cust. Group"
                          body={customerGroupBodyTemplate}
                          style={{ width: "8%" }}
                        ></Column>
                      )}
                      {!checkEmployeeTeamType() && (
                        <Column
                          field="customerId"
                          header="Customer"
                          footer="Total"
                          body={customerBodyTemplate}
                          style={{ width: "8%" }}
                        ></Column>
                      )}

                      <Column
                        field="targetValue"
                        onCellEditComplete={onCellEditComplete}
                        header="Annual Target"
                        footer={parameterType === PARAMETERS_TYPES.Days ||
                          parameterType === PARAMETERS_TYPES.Percentage ?
                          isNaN(avgValue) ? avgValue : commaSeparated(avgValue) : isNaN(planTotalTargetFooterTotal) ? "0" : commaSeparated(planTotalTargetFooterTotal)
                        }
                        editor={(options) => targetValueEditor(options)}
                        style={{ width: "8%" }}
                      ></Column>

                      <Column
                        field="disEqually"
                        onCellEditComplete={onCellEditComplete}
                        header={
                          parameterType === "A"
                            ? "Dis. Equally"
                            : "Apply Across"
                        }
                        body={disEquallyBodyTemplate}
                        editor={(options) => disEquallyEditor(options)}
                        headerStyle={{ textAlign: "center" }}
                        bodyStyle={{ textAlign: "center" }}
                        style={{ width: "8%" }}
                      ></Column>

                      <Column
                        field="Q1TargetValue"
                        onCellEditComplete={onCellEditComplete}
                        header="Q1"
                        footer={planQ1TotalTargetFooterTotal}
                        editor={(options) => targetValueEditor(options)}
                        style={{ width: "5%" }}
                        body={parameterType === PARAMETERS_TYPES.Days ?
                          (option) => parseFloat(option.Q1TargetValue).toFixed(0) :
                          (option) => parseFloat(option.Q1TargetValue).toFixed(2)}
                      ></Column>
                      <Column
                        field="Q2TargetValue"
                        onCellEditComplete={onCellEditComplete}
                        header="Q2"
                        footer={planQ2TotalTargetFooterTotal}
                        editor={(options) => targetValueEditor(options)}
                        style={{ width: "5%" }}
                        body={parameterType === PARAMETERS_TYPES.Days ?
                          (option) => parseFloat(option.Q2TargetValue).toFixed(0) :
                          (option) => parseFloat(option.Q2TargetValue).toFixed(2)}
                      ></Column>
                      <Column
                        field="Q3TargetValue"
                        onCellEditComplete={onCellEditComplete}
                        header="Q3"
                        footer={planQ3TotalTargetFooterTotal}
                        editor={(options) => targetValueEditor(options)}
                        style={{ width: "5%" }}
                        body={parameterType === PARAMETERS_TYPES.Days ?
                          (option) => parseFloat(option.Q3TargetValue).toFixed(0) :
                          (option) => parseFloat(option.Q3TargetValue).toFixed(2)}
                      ></Column>
                      <Column
                        field="Q4TargetValue"
                        onCellEditComplete={onCellEditComplete}
                        header="Q4"
                        footer={planQ4TotalTargetFooterTotal}
                        editor={(options) => targetValueEditor(options)}
                        style={{ width: "5%" }}
                        body={parameterType === PARAMETERS_TYPES.Days ?
                          (option) => parseFloat(option.Q4TargetValue).toFixed(0) :
                          (option) => parseFloat(option.Q4TargetValue).toFixed(2)}
                      ></Column>
                      {!isDisabled && (
                        <Column
                          header="Delete"
                          headerStyle={{ textAlign: "center" }}
                          bodyStyle={{ textAlign: "center" }}
                          body={deleteBodyTemplate}
                        />
                      )}
                    </DataTable>
                  </React.Fragment>
                </div>
              </div>

              {/*------------ Take this code for messaging ------------*/}

              {isShowRemark && (
                <div className="my-5 accordionsingle">
                  <Accordion activeIndex={1}>
                    <AccordionTab header="Remarks">
                      <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                        {messageList.length > 0 ? (
                          <div className="py-6 px-5 rounded-md chat-height overflow-auto">
                            {messageList.map((item) => (
                              <div className="rounded-lg space-y-6 mt-5 '">
                                {item.sender_id !== loggedEmployeeId ? (
                                  <div className="flex space-x-2 justify-start">
                                    <div className="w-20">
                                      <Avatar
                                        style={{ width: 50, height: 50 }}
                                        alt={receiverName}
                                        src={receiverProfile}
                                        className=" mx-auto object-cover"
                                      />
                                      {/* <img
                                                                                src={receiverProfile}
                                                                                className="rounded-full w-12 h-12 mx-auto object-cover" alt="" /> */}
                                    </div>
                                    <div className="bg-[#F2F4F7] max-w-xl p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]">
                                      <div className="flex items-center justify-between ">
                                        <div className="font-medium text-xs mb-2 text-[#667085] dark:text-white">
                                          {receiverName}
                                        </div>
                                        <div className="text-[#667085] text-xs">
                                          {moment(item.messageDateTime)
                                            .locale("it")
                                            .fromNow()}
                                        </div>
                                      </div>
                                      <div className="text-md  text-[#101828] dark:text-[#f2f2f2]">
                                        {item.message}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex space-x-2 justify-end">
                                    <div className="bg-[#F2F4F7] max-w-xl  p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]">
                                      <div className="flex items-center justify-between">
                                        <div className="font-medium text-xs text-[#344054] mb-2 dark:text-white">
                                          {reactLocalStorage.get(
                                            "loggedEmployeeFirstName",
                                          ) +
                                            " " +
                                            reactLocalStorage.get(
                                              "loggedEmployeeLastName",
                                            )}
                                        </div>
                                        <div className="text-[#667085] text-xs">
                                          {moment(item.messageDateTime)
                                            .locale("it")
                                            .fromNow()}
                                        </div>
                                      </div>
                                      <div
                                        className="text-md 
                                                                                text-[#101828] dark:text-[#f2f2f2]"
                                      >
                                        {item.message}
                                      </div>
                                    </div>
                                    <div className="w-20">
                                      <Avatar
                                        style={{ width: 50, height: 50 }}
                                        alt={
                                          reactLocalStorage.get(
                                            "loggedEmployeeFirstName",
                                          ) +
                                          " " +
                                          reactLocalStorage.get(
                                            "loggedEmployeeLastName",
                                          )
                                        }
                                        src={reactLocalStorage.get(
                                          "loggedEmployeeProfilePic",
                                        )}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                            <img
                              src={noCommentsImg}
                              className="m-auto"
                              alt=""
                              style={{ width: "20vw" }}
                            />
                          </div>
                        )}
                        <div className="relative px-5">
                          <textarea
                            type="text"
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                            placeholder="Type here..."
                            className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-3 pl-4 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                          ></textarea>
                          <Link
                            onClick={() => {
                              SendMessage();
                            }}
                            className="absolute right-[30px] top-[25px] text-[16px] bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block"
                          >
                            <i className="pi pi-send mr-2 relative top-0.5"></i>
                            Send{" "}
                          </Link>
                        </div>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </div>
              )}

              {/*------------ Take this code for messaging End------------*/}
            </div>
          ) : (
            ""
          )}

          <div className="flex flex-1 justify-end gap-3 mt-5 items-center">
            <div>
              {requestType !== "" ? (
                //&& !isDisabled
                <React.Fragment>
                  <input
                    disabled={isDisabled ? true : false}
                    type="checkbox"
                    className="h-4 w-4 relative top-0.5"
                    id="chkMarkAsComplete"
                    checked={markAsComplete === true ? "checked" : ""}
                    onChange={(e) => setMarkAsComplete(!markAsComplete)}
                  />
                  <label
                    htmlFor="chkMarkAsComplete"
                    className="ml-1 mr-3 text-[#344054] text-[14px]"
                  >
                    Mark as Completed
                  </label>
                  {/* <Link onClick={() => isDisabled ? "" : SavePlan()} className={ */}
                  <Link
                    onClick={() => (isDisabled ? "" : SavePlan())}
                    className={
                      isDisabled
                        ? "disabled bg-[#e1e1e1] text-[#344054] py-3 px-4 transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]"
                        : "py-3 px-4 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]"
                    }
                  >
                    Save
                  </Link>
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="flex flex-1 justify-between gap-3 mt-7 mb-5 ">
            {isPageLoaded ? (
              <React.Fragment>
                <div>
                  <Link
                    onClick={() => ShowPreviousParameter()}
                    className="py-3 px-4 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                  >
                    Previous
                  </Link>
                </div>

                <div className="flex flex-1 justify-end gap-3">
                  <div>
                    <Link
                      onClick={() => ShowNextParameter()}
                      className="py-3 px-4 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                    >
                      {nextParameterId === "" ? "Submit" : "Next"}
                    </Link>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>

          <div
            id="selector"
            className={
              isShowRequestForRevisionPopUp === 1 ||
                isShowConfirmationPopUp === 1
                ? "overlay"
                : ""
            }
          ></div>
          {isShowRequestForRevisionPopUp === 1 ? (
            <div
              style={{
                width: "40vw",
                animation: "popupDiv-slide-in 0.5s forwards",
              }}
              id="createRecordCont"
              className="sidenav dashboard-main-wrapper"
            >
              <div className="relative">
                <img src={popupHeaderImg} alt="" className="w-full" />
                <div className="absolute right-0 left-0 top-0 w-full text-white">
                  <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                    <div className="text-md">Annual Target</div>
                    <div>
                      <Link
                        onClick={() => setShowRequestForRevisionPopUp(0)}
                        className="closebtn text-2xl leading-none removeClass"
                      >
                        &times;
                      </Link>
                    </div>
                  </div>
                  <div className="px-6 mt-8">
                    <div className="text-sm">Request for Revision</div>
                  </div>
                </div>
              </div>
              <div className="p-6 height-custtom">
                <div className="grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-3 mb-6">
                  <div>
                    <label className="text-[#344054] dark:text-[#fff] text-[14px] font-medium">
                      Request Annual Target
                    </label>
                    <input
                      type="text"
                      value={requestRevisionValue}
                      onChange={(event) =>
                        setRequestRevisionValue(event.target.value)
                      }
                      placeholder="Revision Value"
                      className="text-[16px] rounded-lg   flex-1 appearance-none border border-[#D0D5DD] ml-5 py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                    />
                  </div>
                </div>
              </div>
              <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">
                <div className="flex justify-end gap-3">
                  <div>
                    <Link
                      onClick={() => {
                        setShowRequestForRevisionPopUp(0);
                      }}
                      className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white"
                    >
                      Cancel
                    </Link>
                  </div>

                  <div>
                    <Link
                      onClick={() => {
                        saveRequestForRevision();
                      }}
                      className={
                        "bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2"
                      }
                    >
                      Save
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {isShowConfirmationPopUp === 1 ? (
            <div
              style={{
                width: "40vw",
                animation: "popupDiv-slide-in 0.5s forwards",
              }}
              id="createRecordCont"
              className="sidenav dashboard-main-wrapper"
            >
              <div className="relative">
                <img src={popupHeaderImg} alt="" className="w-full" />
                <div className="absolute right-0 left-0 top-0 w-full text-white">
                  <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                    <div className="text-md">Annual Target</div>
                    <div>
                      <Link
                        onClick={() => setIsShowConfirmationPopUp(0)}
                        className="closebtn text-2xl leading-none removeClass"
                      >
                        &times;
                      </Link>
                    </div>
                  </div>
                  <div className="px-6 mt-8">
                    <div className="text-sm">
                      Below are the parameter that need to complete
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-1 inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                <div className="m-5 height-custtom">
                  <DataTable
                    value={markAsIncompleteParameters}
                    scrollable
                    scrollHeight="flex"
                    resizableColumns
                    columnResizeMode="fit"
                    emptyMessage="&nbsp;"
                  >
                    <Column
                      header="Parameter"
                      field="parameterName"
                      style={{ width: "70%" }}
                    ></Column>
                    {/* <Column header="Status" field='status' style={{ width: '30%' }}></Column> */}
                    <Column
                      header="Status"
                      field="status"
                      body={openLink}
                      style={{ width: "30%" }}
                    ></Column>
                  </DataTable>
                </div>

                <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">
                  <div className="flex justify-end gap-3">
                    {/* <div><Link onClick={() => setIsShowConfirmationPopUp(0)} className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Cancel</Link></div> */}
                    <div>
                      <Link
                        onClick={() => setIsShowConfirmationPopUp(0)}
                        className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2"
                      >
                        OK
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {isShowImportPopup === true ? (
            <div
              style={{
                width: "44.271vw",
                animation: "popupDiv-slide-in 0.5s forwards",
              }}
              id="editPopupCont"
              className="sidenav dashboard-main-wrapper"
            >
              <div className="popup_header relative ">
                <img src={popupHeader} alt="" />
                <div className="absolute right-0 left-0 top-0 w-full text-white">
                  <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                    <div className="text-md">Parameters</div>
                    <div>
                      <Link
                        onClick={() => {
                          setIsShowImportPopup(false);
                          onClickClearParameter();
                        }}
                        className="closebtn text-2xl leading-none removeClass"
                      >
                        &times;
                      </Link>
                    </div>
                  </div>
                  <div className="px-6 mt-2">
                    <div className="text-sm text-[#80C7A2]">Parameters</div>
                    <div className="text-2xl mt-6">{"Select Parameters"}</div>
                  </div>
                </div>
              </div>
              <div className="p-6 height-custtom  ">
                <div className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  ">
                  <div>
                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">
                      BU
                      <label className="text-[#FF0000] text-[14px] font-medium">
                        *{" "}
                      </label>
                    </label>
                  </div>
                  <div>
                    <Select
                      value={importParameterBU}
                      options={parametersDropDownArray}
                      onChange={(e) => {
                        setImportParameterBU(e);
                        setImportParameterBUId(e.value);
                        setCheckType("BU");
                      }}
                      placeholder={"Select BU"}
                      className="my-react-select-container col-span-2"
                      classNamePrefix="my-react-select"
                    />
                  </div>
                  <div>
                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">
                      Region
                    </label>
                  </div>
                  <div>
                    <Select
                      value={importParameterRegion}
                      options={parametersDropDownArray}
                      onChange={(e) => {
                        setImportParameterRegion(e);
                        setImportParameterRegionId(e.value);
                        setCheckType("Region");
                      }}
                      placeholder={"Select Region"}
                      className="my-react-select-container col-span-2"
                      classNamePrefix="my-react-select"
                    />
                  </div>

                  <div>
                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">
                      Country{" "}
                    </label>
                  </div>

                  <div>
                    <Select
                      value={importParameterCountry}
                      options={parametersDropDownArray}
                      onChange={(e) => {
                        setImportParameterCountry(e);
                        setImportParameterCountryId(e.value);
                      }}
                      placeholder={"Select Country"}
                      className="my-react-select-container col-span-2"
                      classNamePrefix="my-react-select"
                    />
                  </div>

                  <div>
                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">
                      Brand
                    </label>
                  </div>
                  <div>
                    <Select
                      value={importParameterBrand}
                      options={parametersDropDownArray}
                      onChange={(e) => {
                        setImportParameterBrand(e);
                        setImportParameterBrandId(e.value);
                        setCheckType("Brand");
                      }}
                      placeholder={"Select Brand"}
                      className="my-react-select-container col-span-2"
                      classNamePrefix="my-react-select"
                    />
                  </div>
                  <div>
                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">
                      Product Group
                    </label>
                  </div>

                  <div>
                    <Select
                      value={importParameterProductGroup}
                      options={parametersDropDownArray}
                      onChange={(e) => {
                        setImportParameterProductGroup(e);
                        setImportParameterProductGroupId(e.value);
                        setCheckType("Product_Group");
                      }}
                      placeholder={"Select Product Group"}
                      className="my-react-select-container col-span-2"
                      classNamePrefix="my-react-select"
                    />
                  </div>

                  <div>
                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">
                      Cust. Group
                    </label>
                  </div>
                  <div>
                    <Select
                      value={importParameterCustomerGroup}
                      options={parametersDropDownArray}
                      onChange={(e) => {
                        setImportParameterCustomerGroup(e);
                        setImportParameterCustomerGroupId(e.value);
                        setCheckType("Cust_Group");
                      }}
                      placeholder={"Select Cust. Group"}
                      className="my-react-select-container col-span-2"
                      classNamePrefix="my-react-select"
                    />
                  </div>

                  <div>
                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">
                      Customer
                    </label>
                  </div>

                  <div>
                    <Select
                      value={importParameterCustomer}
                      options={parametersDropDownArray}
                      onChange={(e) => {
                        setImportParameterCustomer(e);
                        setImportParameterCustomerId(e.value);
                        setCheckType("Customer");
                      }}
                      placeholder={"Select Customer"}
                      className="my-react-select-container col-span-2"
                      classNamePrefix="my-react-select"
                    />
                  </div>
                </div>
              </div>
              <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">
                <ul className="flex justify-end gap-3">
                  <li>
                    <button
                      onClick={() => {
                        setIsShowImportPopup(false);
                        onClickClearParameter();
                      }}
                      className="bg-[#FFFFFF] border border-[#C6CBD2] py-2.5 px-3 text-black text-[15px] rounded-md active addClass dark:bg-[#44494E] dark:text-[#FFFFFF] dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white"
                    >
                      Cancel
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        onClickParameterPreview();
                      }}
                      className="bg-btn-blue border border-[#C6CBD2] text-[#fff] py-2.5 px-3  text-[15px] rounded-md active addClass dark:bg-[#44494E] dark:text-[#FFFFFF] dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white"
                    >
                      Preview
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <EmpLogin sessionExpired={1} />
  );
}
