import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Select from 'react-select';
import TopNav from '../../../../components/common/employee/topnav.component';
import LeftNav from '../../../../components/common/employee/leftnav.component';
import quarterIcon from '../../../../assets/svg/quarter_icon.svg';
import AnnualTargetImg from '../../../../assets/images/annual_target_img.png';
import AnnualSaleIcon from '../../../../assets/svg/annual_sale_icon.svg';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import EmpLogin from "../../../../setup/auth/emp.component";

export default function AnnualTarget() {

    const [sampleData, setSampleData] = useState([]);

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }


    var demoData = [
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5249", "name": "John Smith", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5245", "name": "Barry Walker", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5223", "name": "Marry Pert", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5249", "name": "John Smith", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5245", "name": "Barry Walker", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5223", "name": "Marry Pert", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5249", "name": "John Smith", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5245", "name": "Barry Walker", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5223", "name": "Marry Pert", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5244", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },
        { "id": "EMP5248", "name": "Bamboo Watch", "role": "Sales Executive", "vertical": "Distribution", "sbu": "Value MEA", "region": "East Africa" },

    ]




    const actionPercentageTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="justify-center">
                    <div > <input type="text" className="text-[16px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] " name="" placeholder="%" /></div>
                </div>
            </React.Fragment>
        );
    }

    const actionAmountTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="justify-center">
                    <div > <input type="text" className="text-[16px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] " name="" placeholder="$" /></div>
                </div>
            </React.Fragment>
        );
    }



    useEffect(() => {
        onLoad()
        setSampleData(demoData);
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNav />
            <LeftNav />

            <div className="target-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="flex justify-between">
                        <div className="sm:flex items-center space-x-4">
                            <Link to="/employee/planning/team/assigntarget" className="text-[#344054] text-[14px] dark:text-white py-1.5 px-3 rounded-md border border-[#D0D5DD]"><i className="icon arrow_left_double mr-6 "></i>Back</Link>
                            <div className="text-2xl font-semibold dark:text-white">Annual Target</div>
                            <div className="text-xs border text-[#667085] border-[#667085] rounded p-1">FY: 2022 - 2023</div>
                        </div>
                        <div className="mt-5 lg:mt-0 flex items-center gap-4">
                            <div className="text-md min-w-fit dark:text-white">Select Parameter</div>
                            <div className="w-full min-w-[200px]">
                                <Select
                                    className="mt-2 my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />

                            </div>
                        </div>
                    </div>




                    <div className="bg-white rounded-lg mt-10 p-4 px-3 dark:bg-[#2A2E32]">
                        <div className="grid grid-cols-10 lg:grid-cols-10 2xl:grid-cols-10 flex flex-1 content-center items-center gap-3">
                            <div>
                                <img src={AnnualTargetImg} alt=""></img>
                            </div>
                            <div className="col-span-9">
                                <div className="grid grid-cols-5 lg:grid-cols-5 2xl:grid-cols-5 gap-3 h-auto">
                                    <div className="bg-[#4FB155] rounded-lg p-3 content-center flex ">
                                        <div className='flex justify-between items-center gap-3'>
                                            <div className="bg-white rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                <img src={AnnualSaleIcon} alt=""></img>
                                            </div>
                                            <div>
                                                <h6 className="text-white text-[15px]">Annual Target</h6>
                                                <h2 className="text-white text-[22px] font-medium">$1,000M</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                                        <div className='flex justify-between items-center gap-3'>
                                            <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                <img src={quarterIcon} alt=""></img>
                                            </div>
                                            <div>
                                                <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">Quarter 1</h6>
                                                <h2 className="text-[#101828] text-[22px] font-medium dark:text-[#FFFFFF]">$250M</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                                        <div className='flex justify-between items-center gap-3'>
                                            <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                <img src={quarterIcon} alt=""></img>
                                            </div>
                                            <div>
                                                <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">Quarter 2</h6>
                                                <h2 className="text-[#101828] text-[22px] font-medium dark:text-[#FFFFFF]">$250M</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                                        <div className='flex justify-between items-center gap-3'>
                                            <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                <img src={quarterIcon} alt=""></img>
                                            </div>
                                            <div>
                                                <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">Quarter 3</h6>
                                                <h2 className="text-[#101828] text-[22px] font-medium dark:text-[#FFFFFF]">$250M</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                                        <div className='flex justify-between items-center gap-3'>
                                            <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                <img src={quarterIcon} alt=""></img>
                                            </div>
                                            <div>
                                                <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">Quarter 4</h6>
                                                <h2 className="text-[#101828] text-[22px] font-medium dark:text-[#FFFFFF]">$250M</h2>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>


                    {/* <!--manage plan annual target --> */}

                    <div className=" rounded-lg mt-5 ">
                        <DataTable paginator value={sampleData} responsiveLayout="scroll" style={{ width: '93vw' }}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                            <Column field="id" header="Employee ID" sortable></Column>
                            <Column field="name" header="Name" sortable></Column>
                            <Column field="role" header="Role" sortable></Column>
                            <Column field="vertical" header="Vertical" sortable></Column>
                            <Column field="sbu" header="SBU" sortable></Column>
                            <Column field="region" header="Region" sortable></Column>
                            <Column alignHeader={'center'} header="Percentage" body={actionPercentageTemplate} ></Column>
                            <Column alignHeader={'center'} header="Annual Target Revenue(M)" body={actionAmountTemplate} ></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}