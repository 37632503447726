import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppLogoWhite from '../../assets/images/logo.png'

export default function HRCreatePassword() {
    // Get data(Email) which is passed through navigation
    const location = useLocation();

    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    let navigateTo = useNavigate();

    const createPassword = () => {
        if (password === "") { toast.error('Enter password.'); return; }
        else if (confirmPassword === "") { toast.error('Enter confirm password.'); return; }
        else if (password !== confirmPassword) { toast.error('Confirm password must match with new password.'); return; }
        else {
            setPleaseWaitButton(true);
            Auth.signIn(location.state.email, location.state.password)
                .then(user => {
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        Auth.completeNewPassword(
                            user,
                            confirmPassword,
                        ).then(user => {
                            if (user) {
                                toast.success("Password created successfully.")
                                navigateTo("/hr")
                                setPleaseWaitButton(false);
                            }
                        }).catch(e => {
                            console.log(e);
                        });
                    }
                }).catch(e => {
                    console.log(e);
                });
        }
    }

    return (

        <div className="bg-body-blue flex items-center min-h-screen p-4 bg-gray-100 lg:justify-center">
            <div className="flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md">
                <div className="p-4 py-6 text-white md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly">
                    <img src={AppLogoWhite} />
                </div>
                <div className="py-10 px-5 bg-white md:flex-1">
                    <h3 className="my-4  mb-1 text-2xl font-semibold text-gray-700">Create Your Password</h3>
                    <form action="#" className="flex flex-col mt-4">
                        <div className="relative mb-6">
                            <label htmlFor="required-password" className="text-[#344054] text-[14px] font-medium">
                                New Password
                                <span className="text-red-500 required-dot">
                                    *
                                </span>
                            </label>
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} type="password" id="required-password"
                                className="text-[15px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]"
                                placeholder="Password" />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="required-password" className="text-[#344054] text-[14px] font-medium">
                                Confirm Password
                                <span className="text-red-500 required-dot">
                                    *
                                </span>
                            </label>
                            <input
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type="password" id="required-password"
                                className="text-[15px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]"
                                placeholder="Confirm Password" />
                        </div>

                        <div>
                            <button type="button" onClick={createPassword} className="mt-10 py-2.5 px-5 bg-btn-blue hover:bg-btn-blue focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-1 focus:ring-offset-2  rounded-lg ">
                                {showPleaseWaitButton ? 'Please wait...' : 'Submit'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}