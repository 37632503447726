import AWS from "aws-sdk";

//SET AWS Configuration
const SESConfig = {
  accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
  secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey,
  region: process.env.REACT_APP_AWS_Region,
};


function SendEmail(to, subjectText, emailBody) {
  var params = {
    Source: 'ganesh@edbrix.com',
    Destination: {
      ToAddresses: ["ankit@edbrix.com","support@edbrix.com","amod@edbrix.com"]
    },
    Message: {
      Body: {
        Html: {
          Charset: "UTF-8",
          Data: emailBody
        }
      },
      Subject: {
        Charset: 'UTF-8',
        Data: subjectText
      }
    }
  };

  new AWS.SES(SESConfig).sendEmail(params).promise().then((res) => {
    //  console.log("res-----" + res);
  }).catch((err) => {
    //  console.error("err-----" + err);
  })

};


function SendWelcomeEmailToHRUser(newUserFirstName, newUserEmail, newUserPassword) {

  var defaultLoginURL = 'https://www.redington.edbrix.info/hr';

  var subjectText = 'Login details';
  var emailBody = "Hello " + newUserFirstName + ",<br>Below are your login details.<br><br>Login URL: " + defaultLoginURL + " <br>Email: " + newUserEmail + "<br>Password: " + newUserPassword + "<br><br><br>Note: After login please use verification code to confirm your registration. We will send you new email for verification code.";

  var params = {
    Source: 'ganesh@edbrix.com',
    Destination: {
      ToAddresses: [
        'ganesh@edbrix.com'//newUserEmail
      ]
    },
    Message: {
      Body: {
        Html: {
          Charset: "UTF-8",
          Data: emailBody
        }
      },
      Subject: {
        Charset: 'UTF-8',
        Data: subjectText
      }
    }
  };

  new AWS.SES(SESConfig).sendEmail(params).promise().then((res) => {
    // console.log(res);
  });

};


export { SendEmail, SendWelcomeEmailToHRUser };