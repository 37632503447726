import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Menu } from "@headlessui/react";
import { Avatar } from "@mui/material";
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { Storage } from 'aws-amplify';
import moment from "moment";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';
import "../../../../../src/App.css";
import BoxPlanning from '../../../../assets/images/dashboard_box_planning.png';
import profilepicture1 from "../../../../assets/images/Ellipse 68.png";
import selftPopularing from '../../../../assets/images/selfpopupimg.png';
import EditButton from '../../../../components/common/buttons/EditButton';
import ViewButton from '../../../../components/common/buttons/ViewButton';
import LeftNav from '../../../../components/common/employee/leftnav.component';
import TopNav from '../../../../components/common/employee/topnav.component';
import { ConvertResponseForSelect, ObjectToArray } from '../../../../components/helper/commonfunctions';
import { CHANGE_REQUEST_STATUS, CHANGE_REQUEST_TYPES, CHANGE_REQUEST_TYPES_NAMES } from "../../../../components/helper/enum";
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { ChangeRequest, ChangeRequestComment, Employee, FinancialYear, Roles, ChangeRequestFiles } from "../../../../models";
import EmpLogin from "../../../../setup/auth/emp.component";
import { NOTIFICATIONS_TYPES, EMAIL_SUBJECTS } from '../../../../components/helper/enum'
import { sendNotification } from '../../../../components/helper/notification';
import noCommentsImg from '../../../../assets/images/noComment.png'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { GetAWSObjectURL, UploadFileOnS3, deleteFileFromS3 } from '../../../../components/helper/aws';
import { downloadFile } from '../../../../components/helper/downloadFile';
import { API, graphqlOperation } from 'aws-amplify';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../components/helper/graphQLFunctions';
import * as mutations from '../../../../graphql/mutations';



const MyTeamChangeRequest = () => {

    // states 
    const [financialYearList, setFinancialYearList] = useState([]);
    const [myRequestList, setMyRequestList] = useState([]);


    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [isViewPopUp, setViewPopUp] = useState(0);
    const [isEditPopUp, setEditPopUp] = useState(0);
    const [isApprovedPopUp, setApprovedPopUp] = useState(0);
    const [nameType, setNameType] = useState("");

    const [financialYear, setFinancialYear] = useState("");


    //get Local Storage data
    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
    let selectedYear = reactLocalStorage.getObject('selectedYear');
    var loggedEmployeeProfilePic = reactLocalStorage.get('loggedEmployeeProfilePic');

    //Other
    const [isDisabled, setDisabled] = useState(false);
    const [type, setType] = useState("");

    //Edit/View popup Data
    const [profilePic, setProfilePic] = useState("");
    const [employeeName, setEmployeeName] = useState("");
    const [receiverName, setReceiverName] = useState("");
    const [employeeId, setEmployeeId] = useState("");
    const [reportingManagerName, setReportingManagerName] = useState("");
    const [requestComment, setRequestComment] = useState("");
    const [reportingManagerApprovedDate, setReportingManagerApprovedDate] = useState("");
    const [employeeCode, setEmployeeCode] = useState("");
    const [requestedDate, setRequestedDate] = useState("");
    const [requestId, setRequestId] = useState("");
    const [requestEmployeeId, setRequestEmployeeId] = useState("");
    const [requestRMEmployeeId, setRequestRMEmployeeId] = useState("");
    const [requestNo, setRequestNo] = useState("");
    const [requestStatus, setRequestStatus] = useState("");
    const [notificationFinancialYear, setNotificationFinancialYear] = useState("");
    const [renderItems, setRenderItems] = useState(false);

    //Comments
    const [comment, setComment] = useState("");
    const [reporteeAndRMChat, setReporteeAndRMChat] = useState([]);
    const [hRAndRMChat, setHRAndRMChat] = useState([]);
    const [message, setMessage] = useState('');
    const [hR_message, setHR_message] = useState('');
    const [commentsData, setCommentsData] = useStateWithCallbackLazy({ changeRequestId: "", requestedEmployeeId: "", reportingManagerEmployeeId: "" });

    //document
    const [docList, setDocList] = useState([]);
    const [docFinalList, setDocFinalList] = useState([]);
    const [showRMDocList, setShowRMDocList] = useState([]);
    const [showReporteeDocList, setShowReporteeDocList] = useState([]);
    const [onClickView, setOnClickView] = useState(false);

    //select Documents
    async function selectDocuments(e) {
        let file = e.target.files;
        // console.log(file)
        if (docList.length > 0) {
            let fileData = [...docList]
            for (let i = 0; i < file.length; i++) {
                let data = {
                    "documentId": requestId,
                    "file": file[i],
                    "filename": 'public/changerequest/' + requestId + '/reportee/' + file[i].filename,
                    "fileUrl": ""
                }
                fileData.push(data)
                if (i === file.length - 1) {
                    setDocFinalList(fileData)
                    setDocList(fileData)
                }
            }
        }
        else {
            setDocList([])
            if (file.length > 1) {
                for (let i = 0; i < file.length; i++) {
                    let data = {
                        "documentId": requestId,
                        "file": file[i],
                        "filename": file[i].name,
                        "fileUrl": ""
                    }
                    docFinalList.push(data);
                }
            }
            else {
                for (let i = 0; i < file.length; i++) {
                    let data = {
                        "documentId": requestId,
                        "file": file[i],
                        "filename": file[i].name,
                        "fileUrl": ""
                    }
                    docFinalList.push(data);
                }
            }
            let finalData = [...docFinalList]
            setDocList(finalData)
        }

    }

    //Remove Document from array
    const onClickRemoveDocuments = (index) => {
        let data = [...docFinalList];
        data.splice(index, 1)
        setDocFinalList(data)
        setDocList(data)
    }

    const bindList = async () => {
        //set financial year
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        if (Object.keys(selectedYear).length !== 0) {
            setFinancialYear(selectedYear)
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

            setFinancialYear({ label: responses[0].name, value: responses[0].id })
        }

        //get financial year
        // var responses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        var responses = await graphQLGetAllData('listFinancialYears')
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));

        var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYearList(myArray);
    }

    const onFinancialSelectChange = async (e) => {
        setFinancialYear({ label: e.label, value: e.value })
    }

    const clearAll = () => {
        setEditPopUp(0);
        setRequestId("")
        setRequestEmployeeId("")
        setEmployeeName("")
        setReceiverName("")
        setRequestedDate("")
        setReportingManagerName("")
        setEmployeeCode("")
        setCommentsData({ changeRequestId: "", requestedEmployeeId: "", reportingManagerEmployeeId: "" })
        setDocList([])
        setDocFinalList([])
        setReportingManagerApprovedDate(null)
    }

    //send Msg to reportee
    const sendMessage = async () => {
        // Check Blank - message
        if (message === "") {
            toast.error('Please Enter Message.');
            return;
        }
        // await DataStore.save(
        //     new ChangeRequestComment({
        //         changeRequestId: requestId,
        //         receiver_id: employeeId,
        //         sender_id: loggedEmployeeId,
        //         message: message,
        //         messageDateTime: moment().toDate().toISOString(),
        //     })
        // ).then(() => {})

        await API.graphql(
            graphqlOperation(mutations.createChangeRequestComment, {
                input: {
                    changeRequestId: requestId,
                    receiver_id: employeeId,
                    sender_id: loggedEmployeeId,
                    message: message,
                    messageDateTime: moment().toDate().toISOString(),
                }
            })
        );
        loadMessages(commentsData)
        setMessage("")

    }

    //send msg to hr
    async function SendMessage_To_Hr() {
        // Check Blank - message
        if (hR_message === "") {
            toast.error('Please Enter Message.');
            return;
        }
        // await DataStore.save(
        //     new ChangeRequestComment({
        //         "changeRequestId": requestId,
        //         "receiver_id": "333d0615-8cef-4d64-a2be-093c00d2f006",
        //         "sender_id": requestRMEmployeeId,
        //         "messageDateTime": moment().toDate().toISOString(),
        //         "isRead": "0",
        //         "message": hR_message
        //     })
        // );
        await API.graphql(
            graphqlOperation(mutations.createChangeRequestComment, {
                input: {
                    changeRequestId: requestId,
                    receiver_id: employeeId,
                    sender_id: loggedEmployeeId,
                    message: message,
                    messageDateTime: moment().toDate().toISOString(),
                }
            })
        );
        loadMessages(commentsData)
        setHR_message("")
    }

    const actionsOnRequest = (rowData) => {
        return (
            <React.Fragment >
                <div className="flex gap-8 justify-center ">
                    <Link onClick={() => { setDisabled(false); openRequest(rowData); setNameType("View"); setOnClickView(true) }}><ViewButton /></Link>
                    {
                        rowData.requestStatus !== CHANGE_REQUEST_STATUS.REPORTING_MANAGER ?
                            <Link onClick={() => { setDisabled(true); openRequest(rowData); setNameType("Approve"); setOnClickView(false) }}  ><EditButton /></Link>
                            :
                            <Link className="opacity-50" ><EditButton /></Link>
                    }
                </div>

            </React.Fragment>
        );
    }

    const requestsStatus = (rowData) => {
        return (
            rowData.requestStatus === CHANGE_REQUEST_STATUS.REPORTEE ?
                <p className="bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center "> <div className='w-2 h-2 bg-[#1B55AF] rounded-full'></div>{rowData.requestStatus}</p>
                : rowData.requestStatus === CHANGE_REQUEST_STATUS.REPORTING_MANAGER ?
                    <p className="bg-[#E5F3EC] text-[#4FB155] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center "> <div className='w-2 h-2 bg-[#4FB155] rounded-full'></div>{rowData.requestStatus}</p>
                    :
                    ""
        )
    }

    const openRequest = async (requestDetails) => {
        setRequestComment(requestDetails.comments)
        setRequestId(requestDetails.id)
        setRequestEmployeeId(requestDetails.requestedEmployeeId)
        setRequestRMEmployeeId(requestDetails.reportingManagerEmployeeId)
        //Receiver profile pic
        let profilePic = ""
        // const currentReceiverEmployee = await DataStore.query(Employee, requestDetails.requestedEmployeeId);
        const currentReceiverEmployee = await graphQLFindRecordById('getEmployee', requestDetails.requestedEmployeeId);
        await Storage.get(currentReceiverEmployee.profilePic).then(result => { profilePic = result });
        setProfilePic(profilePic)
        setRequestNo(requestDetails.requestNo)
        setType(requestDetails.requestedType)
        setEmployeeName(requestDetails.requestedEmployeeName)
        setReceiverName(currentReceiverEmployee.firstName + ' ' + currentReceiverEmployee.lastName)
        setRequestedDate(requestDetails.requestedDate)
        setReportingManagerName(requestDetails.reportingManagerEmployeeName)
        setEmployeeId(requestDetails.requestedEmployeeId)
        setEmployeeCode(requestDetails.requestedEmployeeCode)
        if (requestDetails.reportingManagerApprovedDate !== null) {
            setReportingManagerApprovedDate(moment(requestDetails.reportingManagerApprovedDate).format("Do MMM, YYYY"))
            setRequestStatus(CHANGE_REQUEST_STATUS.REPORTING_MANAGER)
        } else {
            setRequestStatus(CHANGE_REQUEST_STATUS.REPORTEE)
        }

        //get comments data
        setCommentsData({ changeRequestId: requestDetails.id, requestedEmployeeId: requestDetails.requestedEmployeeId, reportingManagerEmployeeId: requestDetails.reportingManagerEmployeeId }, (chatData) => {
            if (chatData.changeRequestId !== "" && chatData.requestedEmployeeId !== "" && chatData.reportingManagerEmployeeId !== "") {
                loadMessages(chatData)
            }
        });

        //get documents
        // var ChangeRequestFilesArray = await DataStore.query(ChangeRequestFiles, (c) => c.change_request_id.eq(requestDetails.id));

        var ChangeRequestFilesArray = await graphQLGetAllData('listChangeRequestFiles', [{ change_request_id: { eq: requestDetails.id } }]);

        // console.log(ChangeRequestFilesArray)

        if (ChangeRequestFilesArray.length > 0) {
            let reporteeDocResponse = []
            let rMDocResponse = []
            for (let i = 0; i < ChangeRequestFilesArray.length; i++) {
                let getSignedUrl = await GetAWSObjectURL(ChangeRequestFilesArray[i].fileName)

                if (ChangeRequestFilesArray[i].upload_type === "RM") {
                    let data = {
                        "documentId": requestDetails.id,
                        "file": getSignedUrl,
                        "filename": ChangeRequestFilesArray[i].fileName.split("/").pop(),
                        "fileUrl": getSignedUrl
                    }
                    rMDocResponse.push(data)

                } else if (ChangeRequestFilesArray[i].upload_type === "R") {
                    let data = {
                        "documentId": requestDetails.id,
                        "file": getSignedUrl,
                        "filename": ChangeRequestFilesArray[i].fileName.split("/").pop(),
                        "fileUrl": getSignedUrl
                    }
                    reporteeDocResponse.push(data)
                }

                if (i === ChangeRequestFilesArray.length - 1) {
                    console.log(rMDocResponse)
                    console.log(reporteeDocResponse)
                    setShowRMDocList(rMDocResponse)
                    setShowReporteeDocList(reporteeDocResponse)
                }
            }
        } else {
            setShowRMDocList([])
            setShowReporteeDocList([])
        }

        //set FY for notification
        // const currentFinancialYear = await DataStore.query(FinancialYear, requestDetails.financial_year_id);
        const currentFinancialYear = await graphQLFindRecordById('getFinancialYear', requestDetails.financial_year_id);
        if (currentFinancialYear) { setNotificationFinancialYear(currentFinancialYear.name) } else { setNotificationFinancialYear("") }
        setEditPopUp(1)

    }

    const approveRequest = () => {
        alertify.confirm('Confirmation', 'Do you want to approve?', async function () {

            // const original = await DataStore.query(ChangeRequest, requestId);
            const original = await graphQLFindRecordById('getChangeRequest', requestId);
            // await DataStore.save(ChangeRequest.copyOf(original, item => {
            //     item.requestStatus = CHANGE_REQUEST_STATUS.REPORTING_MANAGER;
            //     item.reportingManagerApprovedDate = moment().format('YYYY-MM-DD')
            // })).then(async () => { });
            await API.graphql({
                query: mutations.updateChangeRequest,
                variables: {
                    input: {
                        id: original.id,
                        requestStatus: CHANGE_REQUEST_STATUS.REPORTING_MANAGER,
                        reportingManagerApprovedDate: moment().format('YYYY-MM-DD'),
                        _version: original._version,
                    }
                }
            });

            //upload Documents
            if (docList.length > 0) {
                try {
                    for (var i = 0; i < docList.length; i++) {
                        const fileName = 'public/changerequest/' + requestId + '/RM/' + docList[i].filename;
                        // console.log(fileName);
                        await UploadFileOnS3(docList[i].file, fileName).catch(error => toast.error(error));
                        // await DataStore.save(
                        //     new ChangeRequestFiles({
                        //         "change_request_id": requestId,
                        //         "upload_type": "RM",
                        //         "fileName": fileName,
                        //     })
                        // ).then((response) => { })
                        await API.graphql(
                            graphqlOperation(mutations.createChangeRequestFiles, {
                                input: {
                                    change_request_id: requestId,
                                    upload_type: "RM",
                                    fileName: fileName,
                                }
                            })
                        );
                        setDocList([])
                        setDocFinalList([])

                    }
                } catch (error) { console.log("Error uploading file: ", error); }
            } else { }


            toast.success("Approved.")
            // send Notification
            let requestTypeName = NOTIFICATIONS_TYPES.ChangeRequestApproveFromRP
            let emailSubject = EMAIL_SUBJECTS.AssignTarget
            let notificationMsg = receiverName + " request has been approve " + notificationFinancialYear
            // console.log(notificationMsg)
            await sendNotification(loggedEmployeeId, "333d0615-8cef-4d64-a2be-093c00d2f006", requestTypeName, notificationMsg);

            myTeamRequests(selectedYear)
            clearAll()

        }
            , function () { });
    }

    //load msg list
    const loadMessages = async (chatData) => {
        // var ChangeRequestMessageResponses = await DataStore.query(ChangeRequestComment, (c) => c.changeRequestId.eq(chatData.changeRequestId), {
        //     sort: s => s.messageDateTime(SortDirection.ASCENDING)
        // })

        var ChangeRequestMessageResponses = await graphQLGetAllData(' listChangeRequestComments');
        ChangeRequestMessageResponses = ChangeRequestMessageResponses.sort((a, b) => a.messageDateTime.localeCompare(b.messageDateTime));

        if (ChangeRequestMessageResponses.length > 0) {
            let reportee_And_RM_Response = []
            let hR_And_RM_Response = []
            for (var i = 0; i < ChangeRequestMessageResponses.length; i++) {

                let senderProfilePic = ""
                let receiverProfilePic = ""

                // var senderResponse = await DataStore.query(Employee, ChangeRequestMessageResponses[i].sender_id);
                var senderResponse = await graphQLFindRecordById('getEmployee', ChangeRequestMessageResponses[i].sender_id);

                // var receiverResponse = await DataStore.query(Employee, ChangeRequestMessageResponses[i].receiver_id);
                var receiverResponse = await graphQLFindRecordById('getEmployee', ChangeRequestMessageResponses[i].receiver_id);

                if (senderResponse.profilePic) {
                    await Storage.get(senderResponse.profilePic).then(result => { senderProfilePic = result });
                }

                if (receiverResponse.profilePic) {
                    await Storage.get(receiverResponse.profilePic).then(result => { receiverProfilePic = result });
                }

                if ((ChangeRequestMessageResponses[i].sender_id === chatData.requestedEmployeeId && ChangeRequestMessageResponses[i].receiver_id === chatData.reportingManagerEmployeeId) ||
                    (ChangeRequestMessageResponses[i].sender_id === chatData.reportingManagerEmployeeId && ChangeRequestMessageResponses[i].receiver_id === chatData.requestedEmployeeId)) {
                    let data = {
                        "id": ChangeRequestMessageResponses[i].id,
                        "sender_id": ChangeRequestMessageResponses[i].sender_id,
                        "receiver_id": ChangeRequestMessageResponses[i].receiver_id,
                        "sender_Name": senderResponse.firstName + " " + senderResponse.lastName,
                        "receiver_Name": receiverResponse.firstName + " " + receiverResponse.lastName,
                        "sender_Profile": senderProfilePic,
                        "receiver_Profile": receiverProfilePic,
                        "changeRequestId": ChangeRequestMessageResponses[i].changeRequestId,
                        "messageDateTime": ChangeRequestMessageResponses[i].messageDateTime,
                        "isRead": ChangeRequestMessageResponses[i].isRead,
                        "message": ChangeRequestMessageResponses[i].message,
                        "hr_Id": "333d0615-8cef-4d64-a2be-093c00d2f006",
                        "rM_Id": chatData.reportingManagerEmployeeId,
                        "reportee_Id": chatData.requestedEmployeeId
                    }
                    reportee_And_RM_Response.push(data)
                }
                else if ((ChangeRequestMessageResponses[i].sender_id === "333d0615-8cef-4d64-a2be-093c00d2f006" && ChangeRequestMessageResponses[i].receiver_id === chatData.reportingManagerEmployeeId) ||
                    (ChangeRequestMessageResponses[i].sender_id === chatData.reportingManagerEmployeeId && ChangeRequestMessageResponses[i].receiver_id === "333d0615-8cef-4d64-a2be-093c00d2f006")) {
                    let data = {
                        "id": ChangeRequestMessageResponses[i].id,
                        "sender_id": ChangeRequestMessageResponses[i].sender_id,
                        "receiver_id": ChangeRequestMessageResponses[i].receiver_id,
                        "sender_Name": senderResponse.firstName + " " + senderResponse.lastName,
                        "receiver_Name": receiverResponse.firstName + " " + receiverResponse.lastName,
                        "sender_Profile": senderProfilePic,
                        "receiver_Profile": receiverProfilePic,
                        "changeRequestId": ChangeRequestMessageResponses[i].changeRequestId,
                        "messageDateTime": ChangeRequestMessageResponses[i].messageDateTime,
                        "isRead": ChangeRequestMessageResponses[i].isRead,
                        "message": ChangeRequestMessageResponses[i].message,
                        "hr_Id": "333d0615-8cef-4d64-a2be-093c00d2f006",
                        "rM_Id": chatData.reportingManagerEmployeeId,
                        "reportee_Id": chatData.requestedEmployeeId
                    }
                    hR_And_RM_Response.push(data)
                }

                if (i === ChangeRequestMessageResponses.length - 1) {
                    setReporteeAndRMChat(reportee_And_RM_Response)
                    setHRAndRMChat(hR_And_RM_Response)
                    setRenderItems(true)
                }
            }
        } else {
            setReporteeAndRMChat([])
            setHRAndRMChat([])
            setRenderItems(false)
        }
    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
        bindList();
        myTeamRequests(selectedYear)

    }, []);

    //get myTeam requests
    const myTeamRequests = async (financialYear) => {
        var newResponse = [];

        //get logged user - children request
        // var changeRequestsList = await DataStore.query(ChangeRequest, (c) => c.and(c => [
        //     c.reportingManagerEmployeeId.eq(loggedEmployeeId),
        //     c.financial_year_id.eq(financialYear.value)
        // ]));

        var changeRequestsList = [];

        try {
            changeRequestsList = await graphQLGetAllData('listChangeRequests', [
                { reportingManagerEmployeeId: { eq: loggedEmployeeId } },
                { financial_year_id: { eq: financialYear.value } }
            ]);
        } catch (error) {
            console.log('error', error);
        }

        if (changeRequestsList.length > 0) {
            Object.keys(changeRequestsList).map(async (currentItem, i) => {
                let loopRequestItem = changeRequestsList[currentItem];
                let data = ObjectToArray(loopRequestItem);

                //Role List
                // var roleResponse = await DataStore.query(Roles, changeRequestsList[i].requestedEmployeeRoleId)
                var roleResponse = await graphQLFindRecordById('getRoles', changeRequestsList[i].requestedEmployeeRoleId);

                data['role'] = roleResponse.name

                //Employee Name
                // var employeeResponse = await DataStore.query(Employee, changeRequestsList[i].requestedEmployeeId)
                var employeeResponse = await graphQLFindRecordById('getEmployee', changeRequestsList[i].requestedEmployeeId);

                data['requestedEmployeeName'] = employeeResponse.firstName + " " + employeeResponse.lastName
                data['requestedEmployeeCode'] = employeeResponse.employeeId

                // var reportingManagerEmployeeResponse = await DataStore.query(Employee, changeRequestsList[i].reportingManagerEmployeeId);
                var reportingManagerEmployeeResponse = await graphQLFindRecordById('getEmployee', changeRequestsList[i].reportingManagerEmployeeId);
                data['reportingManagerEmployeeName'] = reportingManagerEmployeeResponse.firstName + " " + reportingManagerEmployeeResponse.lastName


                data['requestedDate'] = moment(changeRequestsList[i].requestedDate).format("Do MMM, YYYY")
                data['requestedType'] = changeRequestsList[i].requestType === CHANGE_REQUEST_TYPES.PlanningPeriod ? CHANGE_REQUEST_TYPES_NAMES.PP : CHANGE_REQUEST_TYPES_NAMES.OA

                data["selectedQuarter"] = changeRequestsList[i].selectedQuarter > 0 ? changeRequestsList[i].selectedQuarter : "-"


                newResponse.push(data);

                if (i === changeRequestsList.length - 1) {
                    setMyRequestList(newResponse)
                }
            })
        } else {
            setMyRequestList([])
        }
    }



    return isAuthenticated ? (

        <div className="pt-16">
            <TopNav />
            <LeftNav />

            <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="sm:flex items-center space-x-8">
                        <div className="text-2xl font-semibold dark:text-white">Change Request</div>
                        <div className="tabs_one mt-5 sm:mt-0">
                            <ul className="flex">
                                <li><Link className='active' >My Team</Link></li>
                                <li><Link to="/employee/myself/changerequest" >Myself</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="  flex justify-between">
                        <div>
                            <h1 className="mt-6 font-Inter font-20 dark:text-[white]" ></h1>
                        </div>
                        <div className="mt-5 lg:mt-0 flex  items-center gap-4">
                            <div className="relative">
                                <input type="text" id="required-search" className=" rounded-lg border border-[#D0D5DD] focus:border-transparent w-250 py-2.5 pr-5 pl-9 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#2A2E32] dark:text-[#FFFFFF] dark:border-[#4E5668] dark:placeholder-[#ffff]" placeholder="Search..." />
                                <button type="submit" className="absolute left-3 top-1/2 translate-y-[-50%] dark ">
                                    <i className="icon search_icon "></i>
                                </button>
                            </div>
                            <div className="w-full min-w-[200px]">
                                <Select
                                    options={financialYearList}
                                    value={financialYear}
                                    onChange={e => {
                                        myTeamRequests(e)
                                        onFinancialSelectChange(e)
                                        reactLocalStorage.setObject('selectedYear', e);
                                    }}
                                    className="mt-2 mb-2 my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                        </div>

                    </div>


                    <div id="selector" className={isEditPopUp === 1 ? 'overlay' : ''}></div>
                    {
                        isEditPopUp === 1 ?
                            <div style={{ width: "42vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                                <div >
                                    <img src={selftPopularing} alt="" style={{ width: "50vw" }} />
                                    <div className="absolute right-0 left-0 top-0 w-full text-white">
                                        <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                            <div className="text-md"><h6 className='text-[white]'>{nameType} Request</h6></div>
                                            <div><Link onClick={() => clearAll()} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                        </div>

                                        <div className='flex justify-between items-center mt-2'>
                                            <div className="px-6 mt-3 flex gap-3  items-center pb-5 ">
                                                <div>
                                                    <Menu as="div" className="relative inline-block text-left">
                                                        <div className="">
                                                            <Menu.Button className='flex items-center'>
                                                                <Avatar alt="" className='imgprofile' src={profilePic} />
                                                            </Menu.Button>
                                                        </div>
                                                    </Menu>
                                                </div>
                                                <div>
                                                    <div className="text-sm text-[#80C7A2] dark:text-white">Requestor Name</div>
                                                    <div className="text-xl dark:text-white">{employeeName}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mr-10 pb-0 '>
                                                <div className='text-sm text-[#80C7A2]'>Date</div>
                                                <div className='text-lg font-semibold '>{requestedDate}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-2 px-5  height-custtom">

                                        <div className='flex pt-5'>

                                            <div className=" top-10 right-10 bottom-0 flex items-center dashbord_box_img1 mb-4 ">
                                                <img src={BoxPlanning} alt="" className=" w-20 h-20  " />
                                            </div>
                                            <div className='pt-5 pl-10'>
                                                <p className='text-sm text-[#667085] dark:text-white'>Change Requested No.</p>
                                                <h3 className='text-lg text-[#101828] font-semibold dark:text-white'>{requestNo}</h3>
                                            </div>
                                            <div className='pt-5 pl-20'>
                                                <p className='text-sm text-[#667085] dark:text-white'>Status </p>
                                                <h3 className='text-lg text-[#101828] font-semibold dark:text-white'>{requestStatus}</h3>
                                            </div>
                                        </div>

                                        <div className="bg-[#EEF8F4] my-4 text-sm p-4 rounded-md dark:bg-[#252423]">
                                            <div className="grid grid-cols-2 gap-3">
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px]">Requestor Name</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{employeeName}</div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px]    px-12">Employee ID</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{employeeCode}</div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="text-xs text-[#98A2B3] min-w-[150px] ">Reporting Manager</div>
                                                    <div className="text-sm text-[#101828] dark:text-[#FFFF]">{reportingManagerName}</div>
                                                </div>
                                            </div>
                                            <div className="flex items-center mt-3">
                                                <div className="text-xs text-[#98A2B3] min-w-[150px] ">Comment</div>
                                                <div className="text-sm text-[#101828] dark:text-[#FFFF]">{requestComment ? requestComment : "-"}</div>
                                            </div>
                                        </div>
                                        <div className="flex my-5">
                                            <h1 className="text-Inter  text-18px dark:text-[#FFFF]">Requested Type:  </h1>
                                            <p className="text-Inter  text-14px dark:text-[#FFFF]">{type}</p>
                                        </div>
                                        <ul className="flex justify-between planning_period  mt-5">
                                            <li className="text-center">
                                                <div className="text-sm text-[#667085] mb-3 min-w-[100px] text-center dark:text-[white]">Requested</div>
                                                <div className="bg-[#029046] w-7 h-7 rounded-full text-white mx-auto flex justify-center items-center">
                                                </div>
                                                <div className="text-sm mt-2 text-[#344054]  dark:text-[white]">{requestedDate}</div>

                                            </li>
                                            <li className="text-center">
                                                <div className="text-sm text-[#667085] mb-3 min-w-[100px] text-center dark:text-[white]">
                                                    Approval</div>
                                                {
                                                    reportingManagerApprovedDate === null ?
                                                        <div className="bg-[#B3DDC7] w-7 h-7 rounded-full text-white mx-auto flex justify-center items-center" />
                                                        :
                                                        <div>
                                                            <div className="bg-[#029046] w-7 h-7 rounded-full text-white mx-auto flex justify-center items-center" />
                                                            <div className="text-sm mt-2 text-[#344054]  dark:text-[white]">{reportingManagerApprovedDate}</div>
                                                        </div>
                                                }


                                            </li>
                                            <li className="text-center">
                                                <div className="text-sm text-[#667085] mb-3 min-w-[100px] text-center dark:text-[white]">HR Review/Action</div>
                                                <div className="bg-[#B3DDC7] w-7 h-7 rounded-full text-white mx-auto flex justify-center items-center">
                                                </div>

                                            </li>
                                        </ul>

                                        {
                                            reporteeAndRMChat.length === 0 &&
                                            <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                                <img src={noCommentsImg} className="m-auto" alt="" style={{ width: "20vw" }} />
                                            </div>

                                        }
                                        {
                                            reporteeAndRMChat.length > 0 &&
                                            <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                                <div className="py-6 px-5 rounded-md h-64 overflow-auto" >
                                                    {
                                                        reporteeAndRMChat.map((item) => (
                                                            <div className="rounded-lg space-y-6 mt-5 '">

                                                                {
                                                                    item.sender_id !== item.rM_Id ?
                                                                        <div className="flex space-x-2 justify-start">
                                                                            <div className="w-20 text-center">
                                                                                <Avatar
                                                                                    style={{ width: 50, height: 50 }}
                                                                                    alt={item.sender_Name}
                                                                                    src={item.sender_Profile}
                                                                                    className=" mx-auto object-cover"
                                                                                />
                                                                            </div>
                                                                            <div className="bg-[#F2F4F7] max-w-xl p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]">
                                                                                <div className="flex items-center justify-between ">
                                                                                    <div className="font-medium text-md mb-2 text-[#101828] dark:text-white">{item.sender_Name}</div>
                                                                                    <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                </div>
                                                                                <div className="text-sm  text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="flex space-x-2 justify-end">
                                                                            <div className="bg-[#F2F4F7] max-w-xl  p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]" >
                                                                                <div className="flex items-center justify-between">
                                                                                    <div className="font-medium text-md text-[#101828] mb-2 dark:text-white">{item.sender_Name}</div>
                                                                                    <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                </div>
                                                                                <div className="text-sm text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                            </div>
                                                                            <div className="w-20 text-center">
                                                                                <Avatar
                                                                                    style={{ width: 50, height: 50 }}
                                                                                    alt={item.sender_Name}
                                                                                    src={item.sender_Profile} />
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className='relative px-5 mt-10'>
                                            <textarea
                                                type="text"
                                                value={(message)}
                                                onChange={event => setMessage(event.target.value)}
                                                placeholder='Type here...'
                                                className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-3 pl-4 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"></textarea>
                                            <Link onClick={() => {
                                                sendMessage()
                                            }} className='absolute right-[30px] top-[25px] text-[16px] bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block'>
                                                <i className="pi pi-send mr-2 relative top-0.5"></i>Send </Link>

                                        </div>
                                        {/* {
                                            renderItems === true ?
                                                <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                                    <div className="py-6 px-5 rounded-md h-64 overflow-auto" >
                                                        {
                                                            hRAndRMChat.map((item) => (
                                                                <div className="rounded-lg space-y-6 mt-5 '">

                                                                    {
                                                                        item.sender_id !== item.rM_Id ?
                                                                            <div className="flex space-x-2 justify-start">
                                                                                <div className="w-20 text-center">
                                                                                    <Avatar
                                                                                        style={{ width: 50, height: 50 }}
                                                                                        alt={item.sender_Name}
                                                                                        src={item.sender_Profile}
                                                                                        className=" mx-auto object-cover"
                                                                                    />
                                                                                </div>
                                                                                <div className="bg-[#F2F4F7] max-w-xl p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]">
                                                                                    <div className="flex items-center justify-between ">
                                                                                        <div className="font-medium text-md mb-2 text-[#101828] dark:text-white">{item.sender_Name}</div>
                                                                                        <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                    </div>
                                                                                    <div className="text-sm  text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className="flex space-x-2 justify-end">
                                                                                <div className="bg-[#F2F4F7] max-w-xl  p-4 rounded-md shadow-sm w-full dark:bg-[#252423] dark:text-[white]" >
                                                                                    <div className="flex items-center justify-between">
                                                                                        <div className="font-medium text-md text-[#101828] mb-2 dark:text-white">{item.sender_Name}</div>
                                                                                        <div className="text-[#667085] text-xs">{moment(item.messageDateTime).locale('it').fromNow()}</div>
                                                                                    </div>
                                                                                    <div className="text-sm text-[#344054] dark:text-[#f2f2f2]">{item.message}</div>
                                                                                </div>
                                                                                <div className="w-20 text-center">
                                                                                    <Avatar
                                                                                        style={{ width: 50, height: 50 }}
                                                                                        alt={item.sender_Name}
                                                                                        src={item.sender_Profile} />
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>

                                                            ))

                                                        }
                                                    </div>
                                                    <div className='relative px-5'>
                                                        <textarea
                                                            type="text"
                                                            value={(hR_message)}
                                                            onChange={event => setHR_message(event.target.value)}
                                                            placeholder='Type here...'
                                                            className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-3 pl-4 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"></textarea>
                                                        <Link onClick={() => {
                                                            SendMessage_To_Hr()
                                                        }} className='absolute right-[30px] top-[25px] text-[16px] bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block'>
                                                            <i className="pi pi-send mr-2 relative top-0.5"></i>Send </Link>

                                                    </div>
                                                </div>
                                                :
                                                <div className="bg-white rounded-lg mt-3 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                                    <img src={noCommentsImg} className="m-auto" alt="" style={{ width: "20vw" }} />
                                                </div>
                                        } */}

                                        {/* <div className='relative mt-5'>

                                            <textarea
                                                value={comment}
                                                rows="3"
                                                onChange={e => setComment(e.target.value)}
                                                type="text" className="text-[16px] rounded-lg   flex-1  border border-[#C6CBD2] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] pr-20"
                                                placeholder="Add Comment..."

                                            />
                                            <Link onClick={() => {

                                            }} className='absolute right-[20px] top-[35px] text-[16px] bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block'>
                                                <i className="pi pi-send mr-2 relative top-0.5"></i>Send </Link>
                                        </div> */}

                                        {
                                            onClickView === false ?
                                                <div>
                                                    <div className='my-5 relative'>
                                                        <input
                                                            onChange={(e) => { selectDocuments(e,) }}
                                                            multiple="multiple" type="file"
                                                            placeholder='Type here...'
                                                            className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 pl-4 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" />

                                                        <div className='absolute right-3 top-3'>
                                                            <Link
                                                            ><i className='pi pi-paperclip'></i></Link>
                                                        </div>
                                                    </div>

                                                    {
                                                        docList.map((item, index) => {
                                                            return (
                                                                <div key={index} className='block'>
                                                                    <div className='flex gap-2 border cursor-pointer border-[#C6CBD2] p-4 rounded-lg viewfile justify-between'>
                                                                        <div className='flex gap-2'>
                                                                            <i className='pi pi-file' style={{ fontSize: 20 }}></i>
                                                                            <div>
                                                                                <p className='text-[15px] font-medium text-[#344054]'>{item.filename}</p>

                                                                            </div>
                                                                        </div>
                                                                        {/* <div className='flex gap-2'>
                                                                <i onClick={() => { downloadFile(item) }} className='pi pi-download' style={{ fontSize: 20 }}></i>
                                                            </div> */}
                                                                        <div className='flex gap-2'>
                                                                            <i onClick={() => { onClickRemoveDocuments(index) }} className='pi pi-times-circle' style={{ fontSize: 20 }}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div> : onClickView === true ?
                                                    <div>
                                                        {
                                                            showReporteeDocList.length > 0 ?
                                                                <div className="flex my-5">
                                                                    <h1 className="text-Inter  text-18px dark:text-[#FFFF]">Reportee Documents</h1>
                                                                </div> : ""
                                                        }
                                                        {
                                                            showReporteeDocList.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='block'>
                                                                        <div className='flex gap-2 border cursor-pointer border-[#C6CBD2] p-4 rounded-lg viewfile justify-between mt-1'>
                                                                            <div className='flex gap-2'>
                                                                                <i className='pi pi-file' style={{ fontSize: 20 }}></i>
                                                                                <div>
                                                                                    <p className='text-[15px] font-medium text-[#344054]'>{item.filename}</p>

                                                                                </div>
                                                                            </div>
                                                                            <div className='flex gap-2'>
                                                                                <i onClick={() => { downloadFile(item) }} className='pi pi-download' style={{ fontSize: 20 }}></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            showRMDocList.length > 0 ?
                                                                <div className="flex my-5">
                                                                    <h1 className="text-Inter  text-18px dark:text-[#FFFF]">Reporting Manager Documents</h1>
                                                                </div> : ""
                                                        }
                                                        {

                                                            showRMDocList.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='block'>
                                                                        <div className='flex gap-2 border cursor-pointer border-[#C6CBD2] p-4 rounded-lg viewfile justify-between mt-1'>
                                                                            <div className='flex gap-2'>
                                                                                <i className='pi pi-file' style={{ fontSize: 20 }}></i>
                                                                                <div>
                                                                                    <p className='text-[15px] font-medium text-[#344054]'>{item.filename}</p>

                                                                                </div>
                                                                            </div>
                                                                            <div className='flex gap-2'>
                                                                                <i onClick={() => { downloadFile(item) }} className='pi pi-download' style={{ fontSize: 20 }}></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </div> : null

                                        }


                                    </div>


                                    <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                                        <div className="flex justify-end gap-3">
                                            <div><Link onClick={() => clearAll()} className="bg-white 
                                                dark:text-[white] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                            >Close</Link></div>
                                            {
                                                isDisabled ?
                                                    <div><Link onClick={() => approveRequest()}
                                                        className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Approve</Link>
                                                    </div> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div> : null
                    }


                    {/* <div id="selector" className={isApprovedPopUp === 1 ? 'overlay' : ''}></div>
                    {
                        isApprovedPopUp === 1 ?
                            <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="displayPopupCont" className="sidenav">
                                <div className="popup_display_img"><img src={imageDeployedLarge} className="mx-auto mt-8" alt="" /></div>
                                <div className="text-xl text-center my-6 dark:text-white">Change Request Has Been Approved!!!</div>
                                <div className="block text-center my-6">
                                    <Link onClick={() => clearAll()} className="bg-[#029046] text-white rounded-md px-6 py-2 text-sm shadow-md inline-block removeClass">Close</Link>
                                </div>
                            </div> : null
                    } */}



                    <div className=" rounded-lg mt-5 ">
                        <DataTable
                            //selection={selectedPlanningPeriod}
                            //onSelectionChange={e => setSelectedPlanningPeriod(e.value)}
                            paginator value={myRequestList} responsiveLayout="scroll" style={{ width: '93vw' }}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                        >
                            <Column field="requestNo" header="Request No." sortable></Column>
                            <Column field="requestedEmployeeName" header="Requester Name" sortable filter ></Column>
                            <Column field="role" header="Role" sortable filter ></Column>
                            <Column field="requestedType" header="Requested Type" sortable filter  ></Column>
                            <Column field="requestedDate" header="Requested Date" sortable filter ></Column>
                            <Column field='selectedQuarter' header="Quarter" filter ></Column>
                            <Column field='requestStatus' header="Status" filter body={requestsStatus} ></Column>
                            <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnRequest}  ></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

        </div>



    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}


export default MyTeamChangeRequest;


