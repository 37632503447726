import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Menu } from "@headlessui/react";
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import DataTables from 'react-data-table-component';
import { ExcelRenderer } from 'react-excel-renderer';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Bussiness from "../../assets/svg/connectpro/business-people-casual-meeting 1.png";
import LeftSideBar from '../../components/common/hr/leftnav.component';
import TopNavBar from '../../components/common/hr/topnav.component';
import { GetAWSObjectURL } from '../../components/helper/aws';
import { checkDuplicates, getResponseFromKey, validateFileExtension } from "../../components/helper/commonfunctions";
import { DataTableCustomStyles } from '../../components/helper/dataTable.config';
import { PARAMETERS_TYPES } from '../../components/helper/enum';
import { sumFromArray } from '../../components/helper/maths';
import { ValidateHRRole } from '../../components/helper/validateRole';
import { BIUnit, Brand, Country, Customer, CustomerCategory, Employee, FinancialYear, HRAssigTargets, HRAssignTargetDetails, Parameters, Plan, ProductGroup, Region, Relationship, RelationshipDetails, Roles, SBU, TargetDetails, Targets } from "../../models";
import HRLogin from '../../setup/auth/hr.component';
import { graphQLGetAllData, graphQLFindRecordById } from '../../components/helper/graphQLFunctions'
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
const groupBy = require('group-by-with-sum');


export default function Settings() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    const [searchText, setSearchedText] = useState("")
    const [openTab, setOpenTab] = useState(1)

    //Master List
    const masterList = [
        { key: 2, title: "Employees", navigateTo: "/hr/settings/employee", icon: "icon emp_icon", className: "icon emp_icon" },
        { key: 3, title: "Roles", navigateTo: "/hr/settings/role", icon: "icon roles_icon", className: " icon roles_icon" },
        { key: 4, title: "Parameters", navigateTo: "/hr/settings/parameters", icon: " icon parameter_icon", className: " icon parameter_icon" },
        { key: 4, title: "Financial Years", navigateTo: "/hr/settings/financialyear", icon: "icon fy_icon", className: "icon fy_icon" },
    ];

    const [sbuList, SetSbuList] = useState([]);
    const [buList, SetBuList] = useState([]);
    const [regionList, SetRegionList] = useState([]);
    const [countryList, SetCountryList] = useState([]);
    const [brandList, SetBrandList] = useState([]);
    const [productGroupList, SetProductGroupList] = useState([]);
    const [financialYearList, setFinancialYearList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [SBUList, setSBUList] = useState([]);
    const [customerGroupList, SetCustomerGroupList] = useState([]);
    const [customerList, SetCustomerList] = useState([]);
    const [parameterList, SetParametersList] = useState([]);


    //Dimensions List
    const dimensionsList = [
        { key: 1, title: "SBUs", navigateTo: "/hr/setting/dimensions/sbu", icon: "icon sbu_icon" },
        { key: 3, title: "Business Units", navigateTo: "/hr/setting/bussinessunits", icon: "icon bu_icon" },
        { key: 4, title: "Brands", navigateTo: "/hr/setting/dimensions/brand", icon: "icon brand_icon" },
        { key: 2, title: "Product Groups", navigateTo: "/hr/setting/productgroups", icon: "icon pg_icon" },
        { key: 11, title: "Profit Center", navigateTo: "/hr/setting/profitcenter", icon: "icon pc_icon" },
        { key: 6, title: "Clusters", navigateTo: "/hr/setting/regions", icon: "icon region_icon" },
        { key: 5, title: "Countries", navigateTo: "/hr/setting/dimensions/country", icon: "icon country_icon" },
        { key: 10, title: "Company", navigateTo: "/hr/setting/company", icon: "icon company_icon" },
        { key: 6, title: "Customer Segment", navigateTo: "/hr/setting/dimensions/customerSegment", icon: "icon custSegment_icon" },
        { key: 7, title: "Customer Group", navigateTo: "/hr/setting/dimensions/customercategory", icon: "icon custGroup_icon" },
        { key: 8, title: "Customer", navigateTo: "/hr/setting/dimensions/customer", icon: "icon customer_icon" },
        { key: 9, title: "Verticals", navigateTo: "/hr/settings/dimensions/verticals", icon: "icon verticals_icon" },
        { key: 13, title: "Division", navigateTo: "/hr/settings/dimensions/division", icon: "icon division_icon" },
        { key: 12, title: "Plant Storage Location", navigateTo: "/hr/settings/dimensions/plantstoragelocation", icon: "icon pSL_icon" },
        { key: 13, title: "Material Description", navigateTo: "/hr/settings/dimensions/material", icon: "icon materials_icon" },
        { key: 14, title: "GTMs", navigateTo: "/hr/settings/gtm", icon: "icon fy_icon", className: "icon fy_icon" },
        { key: 15, title: "Sub GTM", navigateTo: "/hr/settings/subgtm", icon: "icon fy_icon", className: "icon fy_icon" },
    ];

    //Import Old Data
    const [isViewPopUp, setViewPopUp] = useState(0);
    const [isLoader, setIsLoader] = useState(false);
    const [validatedCounter, setIsValidatedCounter] = useState(0);
    const [popupTitle, setPopupTitle] = useState('Data');
    const [isShowPreviewDataTable, setIsShowPreviewDataTable] = useState(false);
    const [importData, setImportData] = useState([]);
    const [importSaveData, setImportSaveData] = useState([]);
    const [isShowBrowseFile, setIsShowBrowseFile] = useState(true);
    const [fileObj, setFileObj] = useState([]);

    const cancelImport = async () => {
        setIsLoader(false)
        setViewPopUp(0)
        setIsShowBrowseFile(true)
        setIsShowPreviewDataTable(false)
        setIsShowBrowseFile(true)
        setImportData([])
        setImportSaveData([])
        setFileObj([])
        setIsValidatedCounter(0)
    }

    //upload attachments
    async function changeAttachment(e) {
        const file = e.target.files[0];
        let file_field = file.name;
        let allowedExtensions = ['csv'];
        let flag = false;
        flag = validateFileExtension(file_field, allowedExtensions);

        if (flag) {
            e.target.value = null;
            toast.error('Please select valid file format. Only ' + allowedExtensions.join(', ') + ' are allowed.'); return;
        } else {
            setFileObj(file)
        }
    }

    async function downloadDocument(filePath) {
        let filePathURL = await GetAWSObjectURL(filePath);
        const link = document.createElement("a");
        link.href = filePathURL;
        link.download = "";
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    //// ------------------------------------------------------------ IMPORT OLD DATA **** START ***** --------------------------------------------------------------

    const saveData = async () => {
        setIsLoader(true)

        let counter = 0;

        let uniqueEmployees = [];

        let saveWeightage = groupBy(importSaveData, 'designation, parameter,financialYear,weightage');

        importSaveData.forEach(x => {
            if (!uniqueEmployees.some(y => JSON.stringify(y.code) === JSON.stringify(x.code))) {
                uniqueEmployees.push(x)
            }
        })


        const saveRelationshipDetails = async (relationshipId, roleId) => {
            let relationshipDetailsData = saveWeightage.filter((item) => item.designation === roleId)
            var relationshipDetailResponses = []
            if (relationshipId) {
                // relationshipDetailResponses = await DataStore.query(RelationshipDetails, (c) => c.and(c => [
                //     c.relationship_id.eq(relationshipId),
                // ]));

                relationshipDetailResponses = await graphQLGetAllData('getRelationshipDetails', [{ relationship_id: { eq: relationshipId } }]);

            }

            if (relationshipDetailResponses.length === 0 && relationshipId) {
                for (var r = 0; r < relationshipDetailsData.length; r++) {
                    let parameterId = getParameterId(relationshipDetailsData[r].parameter.trim())
                    if (parameterId) {
                        // await DataStore.save(
                        //     new RelationshipDetails({
                        //         "relationship_id": relationshipId,
                        //         "parameter_id": parameterId,
                        //         "weightage": relationshipDetailsData[r].weightage,
                        //     })
                        // ).then((response) => {
                        // })


                        await API.graphql(
                            graphqlOperation(mutations.createRelationshipDetails, {
                                input: {
                                    relationship_id: relationshipId,
                                    parameter_id: parameterId,
                                    weightage: relationshipDetailsData[r].weightage,
                                }
                            })
                        );
                    }
                }

            }
        }

        for (var k = 0; k < saveWeightage.length; k++) {
            let roleId = getRoleId(saveWeightage[k]['designation'])
            var isExistingRelationshipResponse = [];
            let financialYearId = getFinancialYearId(saveWeightage[k]['financialYear'])

            if (roleId && financialYearId) {
                // isExistingRelationshipResponse = await DataStore.query(Relationship, (c) => c.and(c => [
                //     c.role_id.eq(roleId),
                //     c.financial_year_id.eq(financialYearId)
                // ]));


                isExistingRelationshipResponse = await graphQLGetAllData('listRelationships', [{ role_id: { eq: roleId } }, { financial_year_id: { eq: financialYearId } }]);

            }

            if (isExistingRelationshipResponse.length === 0 && roleId && financialYearId) {
                // await DataStore.save(
                //     new Relationship({
                //         "role_id": roleId,
                //         "financial_year_id": financialYearId
                //     })
                // ).then((response) => {
                //     saveRelationshipDetails(response.id, saveWeightage[k]['designation'])
                // })


                let returnData = await API.graphql(
                    graphqlOperation(mutations.createRelationship, {
                        input: {
                            role_id: roleId,
                            financial_year_id: financialYearId
                        }
                    })
                );

                returnData = returnData.data.createRelationship;

                saveRelationshipDetails(returnData.id, saveWeightage[k]['designation'])

            }
        }

        // ADD EMPLOYEE if DOES not exists --- CHECKED WITH VINAYAK
        if (uniqueEmployees.length) {
            for (var i = 0; i < uniqueEmployees.length; i++) {

                // var isExistingEmployee = await DataStore.query(Employee, (c) => c.employeeId.eq(uniqueEmployees[i]['code']));

                var isExistingEmployee = await graphQLGetAllData('listEmployees', [{ employeeId: { eq: uniqueEmployees[i]['code'] } }]);


                if (isExistingEmployee.length === 0) {

                    let roleId = getRoleId(uniqueEmployees[i]['designation'])
                    let sbuId = getSBUId(uniqueEmployees[i]['sbu'])

                    if (roleId && sbuId) {
                        const fullName = uniqueEmployees[i]['name'] ? uniqueEmployees[i]['name'].split(" ") : uniqueEmployees[i]['name'];
                        // await DataStore.save(
                        //     new Employee({
                        //         "firstName": fullName[0],
                        //         "lastName": fullName[1],
                        //         "employeeId": uniqueEmployees[i]['code'],
                        //         "role_id": roleId,
                        //         "param_sbu_id": sbuId,
                        //         "email": uniqueEmployees[i]['code'] + '@redingtongroup.com',
                        //         "IsHRAccess": false,
                        //         "isAssignDirectTarget": false,
                        //         "IsFinanceAccess": false,
                        //         "country": uniqueEmployees[i]['country']
                        //     })
                        // ).then(async (response) => {
                        //     //Update employee Details Commented On 19-April-2023

                        //     let original = await DataStore.query(Employee, response.id)
                        //     if (uniqueEmployees[i]['managerCode']) {
                        //         var updateManageId = await DataStore.query(Employee, (c) => c.employeeId.contains(uniqueEmployees[i]['managerCode']));
                        //         if (updateManageId) {
                        //             await DataStore.save(
                        //                 Employee.copyOf(original, (updated) => {
                        //                     updated.parentEmployeeId = updateManageId[0].id;
                        //                     updated.param_sbu_id = sbuId
                        //                 })
                        //             ).then(async (response) => {
                        //             })
                        //         }
                        //     }
                        // })

                        let empData = await API.graphql(
                            graphqlOperation(mutations.createEmployee, {
                                input: {
                                    "firstName": fullName[0],
                                    "lastName": fullName[1],
                                    "employeeId": uniqueEmployees[i]['code'],
                                    "role_id": roleId,
                                    "param_sbu_id": sbuId,
                                    "email": uniqueEmployees[i]['code'] + '@redingtongroup.com',
                                    "IsHRAccess": false,
                                    "isAssignDirectTarget": false,
                                    "IsFinanceAccess": false,
                                    "country": uniqueEmployees[i]['country']
                                }
                            })
                        );

                        empData = empData.data.createEmployee
                        //Update employee Details Commented On 19-April-2023

                        // let original = await DataStore.query(Employee, response.id)
                        let original = await graphQLFindRecordById('getEmployee', empData.id);


                        if (uniqueEmployees[i]['managerCode']) {
                            // var updateManageId = await DataStore.query(Employee, (c) => c.employeeId.contains(uniqueEmployees[i]['managerCode']));
                            var updateManageId = await graphQLGetAllData('listEmployees', [{ employeeId: { contains: uniqueEmployees[i]['managerCode'] } }]);


                            if (updateManageId) {
                                // await DataStore.save(
                                //     Employee.copyOf(original, (updated) => {
                                //         updated.parentEmployeeId = updateManageId[0].id;
                                //         updated.param_sbu_id = sbuId
                                //     })
                                // ).then(async (response) => {
                                // })

                                await API.graphql({
                                    query: mutations.updateEmployee,
                                    variables: {
                                        input: {
                                            id: original.id,
                                            parentEmployeeId: updateManageId[0].id,
                                            param_sbu_id: sbuId,
                                            _version: original._version,
                                        }
                                    }
                                });
                            }
                        }
                    }

                    //     // Update employee Details Commented On 19-April-2023
                    if (i === uniqueEmployees.length - 1) {
                        for (var k = 0; k < uniqueEmployees.length; k++) {
                            if (isExistingEmployee.length > 0) {
                                // let original = await DataStore.query(Employee, isExistingEmployee[0].id);

                                let original = await graphQLFindRecordById('getEmployee', isExistingEmployee[0].id);

                                if (uniqueEmployees[k]['managerCode']) {
                                    // var updateManageId = await DataStore.query(Employee, (c) => c.employeeId.eq(uniqueEmployees[i]['managerCode']));

                                    var updateManageId = await graphQLGetAllData('listEmployees', [{ employeeId: { eq: uniqueEmployees[i]['managerCode'] } }]);


                                    if (updateManageId) {
                                        // await DataStore.save(
                                        //     Employee.copyOf(original, (updated) => {
                                        //         updated.parentEmployeeId = updateManageId[0].id;
                                        //         updated.param_sbu_id = sbuId
                                        //     })
                                        // ).then(async (response) => { })

                                        await API.graphql({
                                            query: mutations.updateEmployee,
                                            variables: {
                                                input: {
                                                    id: original.id,
                                                    parentEmployeeId: updateManageId[0].id,
                                                    param_sbu_id: sbuId,
                                                    _version: original._version,
                                                }
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    }
                }

                // Update employee Details Commented On 19-April-2023
                // if (isExistingEmployee.length > 0) {
                //     let sbuId = getSBUId(uniqueEmployees[i]['sbu'])
                //     let original = await DataStore.query(Employee, isExistingEmployee[0].id)
                //     if (uniqueEmployees[i]['managerCode']) {
                //         var updateManageId = await DataStore.query(Employee, (c) => c.employeeId.eq(uniqueEmployees[i]['managerCode']));
                //         if (updateManageId) {
                //             await DataStore.save(
                //                 Employee.copyOf(original, (updated) => {
                //                     updated.parentEmployeeId = updateManageId[0].id;
                //                     updated.param_sbu_id = sbuId
                //                 })
                //             ).then(async (response) => { })
                //         }
                //     }
                // }
            }
        }

        //Get Group By Response
        let returnGroupByResponse = groupBy(importSaveData, 'code, parameter,financialYear', 'annualTarget,Q1Target,Q2Target,Q3Target,Q4Target',);
        let getTargetIds = returnGroupByResponse.map((item) => item.code)
        let removeDuplicateArray = checkDuplicates(getTargetIds)
        const getResponse = await getResponseFromKey(returnGroupByResponse, removeDuplicateArray)

        for (var i = 0; i < getResponse.length; i++) {
            let targetId = getResponse[i].targetId
            let targetResponse = getResponse[i].targetIdResponse
            saveTargets(targetId, targetResponse, importSaveData)
        }
    }



    //CREATE TARGET 
    var saveTargets = async (targetId, targetResponse, originalImportedData,) => {

        let financialYearId = getFinancialYearId(targetResponse[0].financialYear)
        // var isExistingEmployeeDetails = await DataStore.query(Employee, (c) => c.employeeId.eq(targetId));

        var isExistingEmployeeDetails = await graphQLGetAllData('listEmployees', [{ employeeId: { eq: targetId } }]);

        let roleId = "";
        let employeeId = "";
        let parentEmployeeId = "";
        let isExistingTarget = [];
        let hrAccess = "";

        //-------------------------------------- SELF TARGET --------------------------------------
        if (isExistingEmployeeDetails.length > 0 && isExistingEmployeeDetails[0].role_id !== null && isExistingEmployeeDetails[0].role_id !== undefined) {
            roleId = isExistingEmployeeDetails[0].role_id;
            employeeId = isExistingEmployeeDetails[0].id;

            // let original = await DataStore.query(Employee, isExistingEmployeeDetails[0].parentEmployeeId);
            let original = await graphQLFindRecordById('getEmployee', isExistingEmployeeDetails[0].parentEmployeeId);


            if (original.IsHRAccess === true) {
                parentEmployeeId = "-"
                hrAccess = true

                if (roleId && financialYearId && employeeId) {
                    // isExistingTarget = await DataStore.query(Targets, (c) => c.and(c => [
                    //     c.role_id.eq(roleId), 
                    //     c.financial_year_id.eq(financialYearId), 
                    //     c.employee_id.eq(employeeId), 
                    //     c.report_manager_employee_id.eq(parentEmployeeId),
                    // ]));


                    isExistingTarget = await graphQLGetAllData('listTargets', [{ role_id: { eq: roleId } },
                    { financial_year_id: { eq: financialYearId } },
                    { employee_id: { eq: employeeId } },
                    { report_manager_employee_id: { eq: parentEmployeeId } }]);

                }

                if (isExistingTarget.length === 0 && roleId && financialYearId && employeeId) {
                    //* Save data for myself
                    // await DataStore.save(
                    //     new Targets({
                    //         "financial_year_id": financialYearId,
                    //         "employee_id": employeeId,
                    //         "role_id": roleId,
                    //         "report_manager_employee_id": employeeId,
                    //         "isAddedByHRAdmin": false,
                    //         "isAssignedToEmployee": true,
                    //         "isSubmittedByEmployee": true
                    //     })
                    // ).then((response) => {
                    //     saveTargetDetails(response.id, isExistingEmployeeDetails, targetResponse, originalImportedData, hrAccess,)
                    // })

                    let returnData = await API.graphql(
                        graphqlOperation(mutations.createTargets, {
                            input: {
                                financial_year_id: financialYearId,
                                employee_id: employeeId,
                                role_id: roleId,
                                report_manager_employee_id: employeeId,
                                isAddedByHRAdmin: false,
                                isAssignedToEmployee: true,
                                isSubmittedByEmployee: true
                            }
                        })
                    );

                    returnData = returnData.data.createTargets;
                    saveTargetDetails(returnData.id, isExistingEmployeeDetails, targetResponse, originalImportedData, hrAccess,)


                }

                //*save HR Assign Targets
                //*Update isAssignDirectTarget
                // await DataStore.save(
                //     Employee.copyOf(original, (updated) => {
                //         updated.isAssignDirectTarget = true;
                //     })
                // );

                await API.graphql({
                    query: mutations.updateEmployee,
                    variables: {
                        input: {
                            id: original.id,
                            isAssignDirectTarget: true,
                            _version: original._version,
                        }
                    }
                });


                // var hrAssignTargetsResponses = await DataStore.query(HRAssigTargets, (c) => c.and(c => [
                //     c.employee_id.eq(original.employeeId),
                //     c.financial_year_id.eq(financialYearId)
                // ]));

                var hrAssignTargetsResponses = await graphQLGetAllData('listHRAssigTargets', [
                    { employee_id: { eq: original.employeeId } },
                    { financial_year_id: { eq: financialYearId } }
                ]);



                if (hrAssignTargetsResponses.length === 0 && financialYearId && employeeId) {
                    await DataStore.save(
                        new HRAssigTargets({
                            "financial_year_id": financialYearId,
                            "employee_id": isExistingEmployeeDetails[0].parentEmployeeId,
                        })
                    ).then((response) => {
                        saveHRAssignTargetDetails(response.id, isExistingEmployeeDetails, targetResponse, originalImportedData)
                    })
                } else {
                    saveHRAssignTargetDetails(hrAssignTargetsResponses[0].id, isExistingEmployeeDetails, targetResponse, originalImportedData)
                }

            }
            else {
                hrAccess = true
                parentEmployeeId = isExistingEmployeeDetails[0].parentEmployeeId

                if (roleId && financialYearId && employeeId) {
                    // isExistingTarget = await DataStore.query(Targets, (c) => c.and(c => [
                    //     c.role_id.eq(roleId), 
                    // c.financial_year_id.eq(financialYearId), 
                    // c.employee_id.eq(employeeId), 
                    // c.report_manager_employee_id.eq(parentEmployeeId),
                    // ]));

                    isExistingTarget = await graphQLGetAllData('listTargets', [{ role_id: { eq: roleId } },
                    { financial_year_id: { eq: financialYearId } },
                    { employee_id: { eq: employeeId } },
                    { report_manager_employee_id: { eq: parentEmployeeId } }
                    ]);

                }

                if (isExistingTarget.length === 0 && roleId && financialYearId && employeeId) {
                    //* Save data for myself
                    // await DataStore.save(
                    //     new Targets({
                    //         "financial_year_id": financialYearId,
                    //         "employee_id": employeeId,
                    //         "role_id": roleId,
                    //         "report_manager_employee_id": employeeId,
                    //         "isAddedByHRAdmin": false,
                    //         "isAssignedToEmployee": true,
                    //         "isSubmittedByEmployee": true
                    //     })
                    // ).then((response) => {
                    //     saveTargetDetails(response.id, isExistingEmployeeDetails, targetResponse, originalImportedData, hrAccess,)
                    // })

                    let returnData = await API.graphql(
                        graphqlOperation(mutations.createTargets, {
                            input: {
                                financial_year_id: financialYearId,
                                employee_id: employeeId,
                                role_id: roleId,
                                report_manager_employee_id: employeeId,
                                isAddedByHRAdmin: false,
                                isAssignedToEmployee: true,
                                isSubmittedByEmployee: true
                            }
                        })
                    );

                    returnData = returnData.data.createTargets;

                    saveTargetDetails(returnData.id, isExistingEmployeeDetails, targetResponse, originalImportedData, hrAccess,)
                }
            }

        } else { roleId = ""; employeeId = ""; parentEmployeeId = ""; }


        //_______________________
        if (roleId && financialYearId && employeeId) {
            // isExistingTarget = await DataStore.query(Targets, (c) => c.and(c => [
            //     c.role_id.eq(roleId), 
            // c.financial_year_id.eq(financialYearId),
            //  c.employee_id.eq(employeeId),
            //   c.report_manager_employee_id.eq(parentEmployeeId),
            // ]));


            isExistingTarget = await graphQLGetAllData('listTargets', [{ role_id: { eq: roleId } },
            { financial_year_id: { eq: financialYearId } },
            { employee_id: { eq: employeeId } },
            { report_manager_employee_id: { eq: parentEmployeeId } }
            ]);

        }

        //*save Target
        //-------------------------------------- TEAM TARGET --------------------------------------
        if (isExistingTarget.length === 0 && roleId && financialYearId && employeeId) {
            hrAccess = false;

            // await DataStore.save(
            //     new Targets({
            //         "financial_year_id": financialYearId,
            //         "employee_id": employeeId,
            //         "role_id": roleId,
            //         "report_manager_employee_id": parentEmployeeId,
            //         "isAddedByHRAdmin": false,
            //         "isAssignedToEmployee": true,
            //         "isSubmittedByEmployee": true
            //     })
            // ).then((response) => {
            //     saveTargetDetails(response.id, isExistingEmployeeDetails, targetResponse, originalImportedData, hrAccess,)
            // })

            let returnData = await API.graphql(
                graphqlOperation(mutations.createTargets, {
                    input: {
                        financial_year_id: financialYearId,
                        employee_id: employeeId,
                        role_id: roleId,
                        report_manager_employee_id: parentEmployeeId,
                        isAddedByHRAdmin: false,
                        isAssignedToEmployee: true,
                        isSubmittedByEmployee: true
                    }
                })
            );

            returnData = returnData.data.createTargets;
            saveTargetDetails(returnData.id, isExistingEmployeeDetails, targetResponse, originalImportedData, hrAccess,)

        }
        else {
            saveTargetDetails(isExistingTarget[0].id, isExistingEmployeeDetails, targetResponse, originalImportedData, hrAccess,)
        }
    }

    //*Save HR-Assign Target
    var saveHRAssignTargetDetails = async (targetId, employeeDetails, targetData, originalImportedData) => {
        // var isHRAssignExistingTargetDetail = await DataStore.query(HRAssignTargetDetails, (c) => c.hrAssign_Target_id.eq(targetId));

        var isHRAssignExistingTargetDetail = await graphQLGetAllData('listHRAssignTargetDetails', [{ hrAssign_Target_id: { eq: targetId } }]);


        if (isHRAssignExistingTargetDetail.length === 0) {
            for (let i = 0; i < targetData.length; i++) {
                let parameterId = getParameterId(targetData[i].parameter.trim())
                if (parameterId) {
                    // await DataStore.save(
                    //     new HRAssignTargetDetails({
                    //         "hrAssign_Target_id": targetId,
                    //         "parameter_id": parameterId,
                    //         "target_value": String(targetData[i].annualTarget),
                    //     })
                    // ).then((response) => { })

                    await API.graphql(
                        graphqlOperation(mutations.createHRAssignTargetDetails, {
                            input: {
                                hrAssign_Target_id: targetId,
                                parameter_id: parameterId,
                                target_value: String(targetData[i].annualTarget),
                            }
                        })
                    );
                }
            }
        }

    }

    //*save Target
    var saveTargetDetails = async (targetId, employeeDetails, targetData, originalImportedData, isHrAccess,) => {

        // var isExistingTargetDetail = await DataStore.query(TargetDetails, (c) => c.target_id.eq(targetId));
        var isExistingTargetDetail = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: targetId } }]);


        // var reporteeDetails = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(employeeDetails[0].id));
        var reporteeDetails = await graphQLGetAllData('listEmployees', [{ parentEmployeeId: { eq: employeeDetails[0].id } }]);

        if (isExistingTargetDetail.length === 0) {
            for (let i = 0; i < targetData.length; i++) {
                let parameterId = getParameterId(targetData[i].parameter.trim())
                //*handle mySelf target
                let target_value = "";
                if (reporteeDetails.length > 0 && isHrAccess === true) {
                    // var parentEmployeeDetails = await DataStore.query(Employee, (c) => c.id.eq(reporteeDetails[0].parentEmployeeId));
                    var parentEmployeeDetails = await graphQLGetAllData('listEmployees', [{ id: { eq: reporteeDetails[0].parentEmployeeId } }]);


                    let parentEmployeeName = parentEmployeeDetails[0].firstName + " " + parentEmployeeDetails[0].lastName
                    let getAnnualTargetList = originalImportedData.filter((item) => String(item.managerName.trim()) === String(parentEmployeeName) && String(item.parameter.trim()) === String(targetData[i].parameter.trim())).map((data) => data.annualTarget)
                    const sumOfAnnualTarget = getAnnualTargetList.reduce((acc, currentValue) => { return acc + parseInt(currentValue); }, 0);
                    target_value = targetData[i].annualTarget - sumOfAnnualTarget

                    if (parameterId) {
                        // await DataStore.save(
                        //     new TargetDetails({
                        //         "target_id": targetId,
                        //         "parameter_id": parameterId,
                        //         "target_value": String(target_value),
                        //         "target_assigned_date": moment().format(),
                        //         "is_accepted_by_employee": true,
                        //         "is_approve": true,
                        //         "approved_date": moment().format(),
                        //         "accepted_by_employee_date": moment().format(),
                        //         "isMarkAsComplete": true,
                        //         "isMarkAsComplete_AssignTarget": true,
                        //         "q1_target_value": String(targetData[i].Q1Target),
                        //         "q2_target_value": String(targetData[i].Q2Target),
                        //         "q3_target_value": String(targetData[i].Q3Target),
                        //         "q4_target_value": String(targetData[i].Q4Target),
                        //     })
                        // ).then(async (response) => {
                        //     await savePlanOfTargetDetails(response.id, employeeDetails, targetData[i], originalImportedData,)
                        // })

                        let returnData = await API.graphql(
                            graphqlOperation(mutations.createTargetDetails, {
                                input: {
                                    target_id: targetId,
                                    parameter_id: parameterId,
                                    target_value: String(target_value),
                                    target_assigned_date: moment().format(),
                                    is_accepted_by_employee: true,
                                    is_approve: true,
                                    approved_date: moment().format(),
                                    accepted_by_employee_date: moment().format(),
                                    isMarkAsComplete: true,
                                    isMarkAsComplete_AssignTarget: true,
                                    q1_target_value: String(targetData[i].Q1Target),
                                    q2_target_value: String(targetData[i].Q2Target),
                                    q3_target_value: String(targetData[i].Q3Target),
                                    q4_target_value: String(targetData[i].Q4Target),
                                }
                            })
                        );

                        returnData = returnData.data.createTargetDetails;
                        await savePlanOfTargetDetails(returnData.id, employeeDetails, targetData[i], originalImportedData,)

                    }
                } else {
                    //save target Details
                    if (parameterId) {
                        // await DataStore.save(
                        //     new TargetDetails({
                        //         "target_id": targetId,
                        //         "parameter_id": parameterId,
                        //         "target_assigned_date": moment().format(),
                        //         "is_accepted_by_employee": true,
                        //         "is_approve": true,
                        //         "approved_date": moment().format(),
                        //         "accepted_by_employee_date": moment().format(),
                        //         "isMarkAsComplete": true,
                        //         "isMarkAsComplete_AssignTarget": true,
                        //         "target_value": String(targetData[i].annualTarget),
                        //         "q1_target_value": String(targetData[i].Q1Target),
                        //         "q2_target_value": String(targetData[i].Q2Target),
                        //         "q3_target_value": String(targetData[i].Q3Target),
                        //         "q4_target_value": String(targetData[i].Q4Target),
                        //     })
                        // ).then(async (response) => {
                        //     await savePlanOfTargetDetails(response.id, employeeDetails, targetData[i], originalImportedData,)
                        // })

                        let returnData = await API.graphql(
                            graphqlOperation(mutations.createTargetDetails, {
                                input: {
                                    target_id: targetId,
                                    parameter_id: parameterId,
                                    target_assigned_date: moment().format(),
                                    is_accepted_by_employee: true,
                                    is_approve: true,
                                    approved_date: moment().format(),
                                    accepted_by_employee_date: moment().format(),
                                    isMarkAsComplete: true,
                                    isMarkAsComplete_AssignTarget: true,
                                    target_value: String(targetData[i].annualTarget),
                                    q1_target_value: String(targetData[i].Q1Target),
                                    q2_target_value: String(targetData[i].Q2Target),
                                    q3_target_value: String(targetData[i].Q3Target),
                                    q4_target_value: String(targetData[i].Q4Target),
                                }
                            })
                        );

                        returnData = returnData.data.createTargetDetails;
                        await savePlanOfTargetDetails(returnData.id, employeeDetails, targetData[i], originalImportedData,)

                    }
                }
            }
        }
        else if (isExistingTargetDetail.length > 0) {
            for (let i = 0; i < targetData.length; i++) {
                let parameterId = getParameterId(targetData[i].parameter.trim())
                if (parameterId) {
                    // var originalTargetDetail = await DataStore.query(TargetDetails, isExistingTargetDetail[0].id);
                    var originalTargetDetail = await graphQLFindRecordById('getTargetDetails', isExistingTargetDetail[0].id);


                    // await DataStore.save(
                    //     TargetDetails.copyOf(originalTargetDetail, (updated) => {
                    //         updated.target_value = targetData[i].annualTarget.toFixed(2)
                    //     })
                    // ).then(async () => {
                    //     await savePlanOfTargetDetails(isExistingTargetDetail[0].id, employeeDetails, targetData[i], originalImportedData,)
                    // })

                    let returnData = await API.graphql({
                        query: mutations.updateTargetDetails,
                        variables: {
                            input: {
                                id: originalTargetDetail.id,
                                target_value: targetData[i].annualTarget.toFixed(2),
                                _version: originalTargetDetail._version,
                            }
                        }
                    });

                    returnData = returnData.data.updateTargetDetails;
                    await savePlanOfTargetDetails(isExistingTargetDetail[0].id, employeeDetails, targetData[i], originalImportedData,)
                }
            }
        }
    }

    //*Save Plan
    let totalItems = 0
    var savePlanOfTargetDetails = async (targetDetailId, employeeDetails, targetData, originalImportedData,) => {

        let getParameterTypeFromRes = getParameterType(targetData.parameter)
        let findData = originalImportedData.filter(e => e.code === targetData.code && e.parameter === targetData.parameter)

        // var planDetail = await DataStore.query(Plan, (c) => c.target_detail_id.eq(targetDetailId));
        //if data found ... delete data
        // if (planDetail.length > 0) { await DataStore.delete(Plan, (c) => c.target_detail_id.eq(targetDetailId)) }

        //adding new data
        if (findData.length > 0 && targetDetailId) {
            let batchOperations = [];

            for (var i = 0; i < findData.length; i++) {



                let sbuId; if (findData[i].sbu) { sbuId = getSBUId(findData[i].sbu) } else { sbuId = "" }

                let buId; let buREF_CODE;
                if (findData[i].bu) {
                    buId = getBUId(findData[i].bu)
                    buREF_CODE = getBuREF_CODE(findData[i].bu)
                } else { buId = ""; buREF_CODE = ""; }

                let regionId;
                if (findData[i].region) { regionId = getRegionId(sbuId, findData[i].region) } else { regionId = "" }

                let countryId;
                if (regionId) { countryId = getCountryId(regionId, findData[i].country) } else { countryId = "" }

                let brandId;
                if (findData[i].brand) { brandId = getBrandId(sbuId, buId, findData[i].brand) } else { brandId = "" }

                let groductGroupId;
                if (findData[i].productGroup) { groductGroupId = getProductGroupId(findData[i].productGroup) } else { groductGroupId = "" }

                batchOperations.push(
                    // DataStore.save(
                    //     new Plan({
                    //         "target_detail_id": targetDetailId,
                    //         "bu_REF_CODE": buREF_CODE,
                    //         "sbu_id": sbuId,
                    //         "bu_id": buId,
                    //         "region_id": regionId,
                    //         "country_id": countryId,
                    //         "brand_id": brandId,
                    //         "product_group_id": groductGroupId,
                    //         "customer_group_id": "0",
                    //         "customer_id": "0",
                    //         "target_value": String(findData[i].annualTarget),
                    //         "q1_target_value": String(findData[i].Q1Target),
                    //         "q2_target_value": String(findData[i].Q2Target),
                    //         "q3_target_value": String(findData[i].Q3Target),
                    //         "q4_target_value": String(findData[i].Q4Target),
                    //         "m1_target_value": getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q1Target) : String(Math.round(findData[i].Q1Target / 3)),
                    //         "m2_target_value": getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q1Target) : String(Math.round(findData[i].Q1Target / 3)),
                    //         "m3_target_value": getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q1Target) : String(Math.round(findData[i].Q1Target / 3)),
                    //         "m4_target_value": getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q2Target) : String(Math.round(findData[i].Q2Target / 3)),
                    //         "m5_target_value": getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q2Target) : String(Math.round(findData[i].Q2Target / 3)),
                    //         "m6_target_value": getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q2Target) : String(Math.round(findData[i].Q2Target / 3)),
                    //         "m7_target_value": getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q3Target) : String(Math.round(findData[i].Q2Target / 3)),
                    //         "m8_target_value": getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q3Target) : String(Math.round(findData[i].Q3Target / 3)),
                    //         "m9_target_value": getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q3Target) : String(Math.round(findData[i].Q3Target / 3)),
                    //         "m10_target_value": getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q4Target) : String(Math.round(findData[i].Q4Target / 3)),
                    //         "m11_target_value": getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q4Target) : String(Math.round(findData[i].Q4Target / 3)),
                    //         "m12_target_value": getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q4Target) : String(Math.round(findData[i].Q4Target / 3)),
                    //     })
                    // )

                    await API.graphql(
                        graphqlOperation(mutations.createPlan, {
                            input: {
                                target_detail_id: targetDetailId,
                                bu_REF_CODE: buREF_CODE,
                                sbu_id: sbuId,
                                bu_id: buId,
                                region_id: regionId,
                                country_id: countryId,
                                brand_id: brandId,
                                product_group_id: groductGroupId,
                                customer_group_id: 0,
                                customer_id: 0,
                                target_value: String(findData[i].annualTarget),
                                q1_target_value: String(findData[i].Q1Target),
                                q2_target_value: String(findData[i].Q2Target),
                                q3_target_value: String(findData[i].Q3Target),
                                q4_target_value: String(findData[i].Q4Target),
                                m1_target_value: getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q1Target) : String(Math.round(findData[i].Q1Target / 3)),
                                m2_target_value: getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q1Target) : String(Math.round(findData[i].Q1Target / 3)),
                                m3_target_value: getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q1Target) : String(Math.round(findData[i].Q1Target / 3)),
                                m4_target_value: getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q2Target) : String(Math.round(findData[i].Q2Target / 3)),
                                m5_target_value: getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q2Target) : String(Math.round(findData[i].Q2Target / 3)),
                                m6_target_value: getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q2Target) : String(Math.round(findData[i].Q2Target / 3)),
                                m7_target_value: getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q3Target) : String(Math.round(findData[i].Q2Target / 3)),
                                m8_target_value: getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q3Target) : String(Math.round(findData[i].Q3Target / 3)),
                                m9_target_value: getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q3Target) : String(Math.round(findData[i].Q3Target / 3)),
                                m10_target_value: getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q4Target) : String(Math.round(findData[i].Q4Target / 3)),
                                m11_target_value: getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q4Target) : String(Math.round(findData[i].Q4Target / 3)),
                                m12_target_value: getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days ? String(findData[i].Q4Target) : String(Math.round(findData[i].Q4Target / 3))
                            }
                        })
                    )
                )
            }

            const resultArray = await Promise.allSettled(batchOperations);

            for (let result of resultArray) {
                if (result.status === 'rejected') {
                    console.log("CHECKING  -- -- - - -  " + result.reason);
                }
            }
        }

        if (getParameterTypeFromRes.type === PARAMETERS_TYPES.Percentage || getParameterTypeFromRes.type === PARAMETERS_TYPES.Days) {
            var sumOfAnnualTarget = sumFromArray('annualTarget', findData);
            let updateTarget = sumOfAnnualTarget / findData.length
            // var originalTargetDetail = await DataStore.query(TargetDetails, targetDetailId);
            var originalTargetDetail = await graphQLFindRecordById('getTargetDetails', targetDetailId);


            // await DataStore.save(
            //     TargetDetails.copyOf(originalTargetDetail, (updated) => {
            //         updated.target_value = updateTarget.toFixed(2).toString();
            //         //11 MAY - comment by amod
            //         // updated.q1_target_value = updateTarget.toFixed(2).toString();
            //         // updated.q2_target_value = updateTarget.toFixed(2).toString();
            //         // updated.q3_target_value = updateTarget.toFixed(2).toString();
            //         // updated.q4_target_value = updateTarget.toFixed(2).toString();
            //     })
            // )

            await API.graphql({
                query: mutations.updateTargetDetails,
                variables: {
                    input: {
                        id: originalTargetDetail.id,
                        target_value: updateTarget.toFixed(2).toString(),
                        // q1_target_value: updateTarget.toFixed(2).toString(),
                        // q2_target_value: updateTarget.toFixed(2).toString(),
                        // q3_target_value: updateTarget.toFixed(2).toString(),
                        // q4_target_value: updateTarget.toFixed(2).toString(),
                        _version: originalTargetDetail._version,
                    }
                }
            });
        }
        else {
            // var originalTargetDetail = await DataStore.query(TargetDetails, targetDetailId);
            var originalTargetDetail = await graphQLFindRecordById('getTargetDetails', targetDetailId);

            var sumOfAnnualTarget = sumFromArray('annualTarget', findData);

            // await DataStore.save(
            //     TargetDetails.copyOf(originalTargetDetail, (updated) => {
            //         // updated.m1_actual_value = (targetData.Q1Target / 3).toFixed(2).toString();
            //         // updated.m2_actual_value = (targetData.Q1Target / 3).toFixed(2).toString();
            //         // updated.m3_actual_value = (targetData.Q1Target / 3).toFixed(2).toString();
            //         // updated.m4_actual_value = (targetData.Q2Target / 3).toFixed(2).toString();
            //         // updated.m5_actual_value = (targetData.Q2Target / 3).toFixed(2).toString();
            //         // updated.m6_actual_value = (targetData.Q2Target / 3).toFixed(2).toString();
            //         // updated.m7_actual_value = (targetData.Q3Target / 3).toFixed(2).toString();
            //         // updated.m8_actual_value = (targetData.Q3Target / 3).toFixed(2).toString();
            //         // updated.m9_actual_value = (targetData.Q3Target / 3).toFixed(2).toString();
            //         // updated.m10_actual_value = (targetData.Q4Target / 3).toFixed(2).toString();
            //         // updated.m11_actual_value = (targetData.Q4Target / 3).toFixed(2).toString();
            //         // updated.m12_actual_value = (targetData.Q4Target / 3).toFixed(2).toString();
            //     })
            // )
        }

        totalItems = totalItems + findData.length
        // console.log(totalItems + " -- - - - -" + ((originalImportedData.length) * 2))
        if (totalItems === ((originalImportedData.length) * 2)) {
            setIsLoader(false)
            setViewPopUp(0)
            setIsShowPreviewDataTable(false)
            setIsShowBrowseFile(true)
            setImportData([])
            setImportSaveData([])
            setFileObj([])
            setIsValidatedCounter(0)
            toast.success("Imported Successfully")
        }
    }
    //// ------------------------------------------------------------ IMPORT OLD DATA **** END ***** --------------------------------------------------------------


    //// ------------------------------------------------------------ MASTER DATA  **** START ***** --------------------------------------------------------------


    //*get SBUId________
    const getSBUId = (name) => {
        let _sbuList = [...sbuList];
        var returnRecord = _sbuList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*get BuId________
    const getBUId = (name) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*get Bu REF_CODE________
    const getBuREF_CODE = (name) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.REF_CODE; } else { return ""; }
    }

    //*get RegionId________
    const getRegionId = (sbuId, regionId) => {
        let _regionList = [...regionList];
        if (regionId) {
            var returnRecord = _regionList.find(o => (o.SBU_id.toLowerCase().trim() === sbuId.toLowerCase().trim() && o.name.toLowerCase().trim() === regionId.toLowerCase().trim()));
            if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
        }
    }

    //*get CountryId________
    const getCountryId = (regionId, name) => {
        let _countryList = [...countryList];
        var returnRecord = _countryList.find(o => o.region_id.toLowerCase().trim() === regionId.toLowerCase().trim() && o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*get CountryId________
    const getBrandId = (sbuId, buId, brandName) => {
        let _brandList = [...brandList];
        var returnRecord = _brandList.find(o => (o.SBU_id.toLowerCase().trim() === sbuId.toLowerCase().trim() && o.bu_id.toLowerCase().trim() === buId.toLowerCase().trim() && o.name.toLowerCase().trim() === brandName.toLowerCase().trim()));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //get Product Group________
    const getProductGroupId = (name) => {
        let _productGroupList = [...productGroupList];
        var returnRecord = _productGroupList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //get Financial Year_______
    const getRoleId = (name) => {
        let _roleList = [...roleList];
        var returnRecord = _roleList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //get Financial Year_______
    const getFinancialYearId = (name) => {
        let _financialYearList = [...financialYearList];
        var returnRecord = _financialYearList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //get Financial Year_______
    const getParameterId = (name) => {
        let _parameterList = [...parameterList];
        var returnRecord = _parameterList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    const getParameterType = (name) => {
        let _parameterList = [...parameterList];
        var returnRecord = _parameterList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord; } else { return ""; }
    }

    //// ------------------------------------------------------------ MASTER DATA  **** END ***** --------------------------------------------------------------

    const partnerDataColumns = [
        {
            name: 'Employee Id',
            selector: row => row.code,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.code}
                    </div>
                </>
            ),
        },
        {
            name: 'Employee Name',
            selector: row => row.name,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.name}
                    </div>
                </>
            ),
        },
        {
            name: 'Designation',
            selector: row => row.designation,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.designation}
                    </div>
                </>
            ),
        },
        {
            name: 'Manager Code',
            selector: row => row.managerCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.managerCode}
                    </div>
                </>
            ),
        },
        {
            name: 'Manager Name',
            selector: row => row.managerName,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.managerName}
                    </div>
                </>
            ),
        },
        {
            name: 'SBU',
            selector: row => row.sbu,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.sbu}
                    </div>
                </>
            ),
        },
        {
            name: 'BU',
            selector: row => row.bu,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.bu}
                    </div>
                </>
            ),
        },
        {
            name: 'Entriy',
            selector: row => row.entriy,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.entriy}
                    </div>
                </>
            ),
        },
        {
            name: 'Financial Year',
            selector: row => row.financialYear,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.financialYear}
                    </div>
                </>
            ),
        },
        {
            name: 'Region',
            selector: row => row.region,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.region}
                    </div>
                </>
            ),
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.country}
                    </div>
                </>
            ),
        },
        {
            name: 'Brand',
            selector: row => row.brand,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.brand}
                    </div>
                </>
            ),
        },
        {
            name: 'Product Group',
            selector: row => row.productGroup,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.productGroup}
                    </div>
                </>
            ),
        },
        {
            name: 'KRA Parameters',
            selector: row => row.parameter,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.parameter}
                    </div>
                </>
            ),
        },
        {
            name: 'Weightage',
            selector: row => row.weightage,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.weightage}
                    </div>
                </>
            ),
        },
        {
            name: 'Annual Target',
            selector: row => row.annualTarget,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.annualTarget}
                    </div>
                </>
            ),
        },
        {
            name: 'Q1- Target',
            selector: row => row.Q1Target,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.Q1Target}
                    </div>
                </>
            ),
        },
        {
            name: 'Q2- Target',
            selector: row => row.Q2Target,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.Q2Target}
                    </div>
                </>
            ),
        },
        {
            name: 'Q3- Target',
            selector: row => row.Q3Target,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.Q3Target}
                    </div>
                </>
            ),
        },
        {
            name: 'Q4- Target',
            selector: row => row.Q4Target,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.Q4Target}
                    </div>
                </>
            ),
        },
    ];


    const previewData = () => {
        //just pass the fileObj as parameter
        if (fileObj.length === 0) {
            toast.error('Please select file.');
        } else {

            setIsShowBrowseFile(false)

            ExcelRenderer(fileObj, async (err, resp) => {
                if (err) { }
                else {
                    setIsLoader(true)

                    var parameterData = resp.rows
                    var newParameterArray = parameterData.slice(1);
                    let importDataArray = [];
                    let importDataSaveArray = [];
                    var invalidRecord = 0;
                    var validRecord = 0;

                    for (let i = 0; i < newParameterArray.length; i++) {
                        var index = i;
                        var csvEmpCode = newParameterArray[i][0];
                        var csvEmpName = newParameterArray[i][1];
                        var csvDesignation = newParameterArray[i][2];
                        var csvManagerCode = newParameterArray[i][3];
                        var csvManagerName = newParameterArray[i][4];
                        var csvSBU = newParameterArray[i][5];
                        var csvBU = newParameterArray[i][6];
                        var csvEntriy = newParameterArray[i][7];
                        var csvFY = newParameterArray[i][8];
                        var csvRegion = newParameterArray[i][9];
                        var csvCountry = newParameterArray[i][10];
                        var csvBrand = newParameterArray[i][11];
                        var csvProductGroup = newParameterArray[i][12];
                        var csvParameters = newParameterArray[i][13];
                        var csvWeightage = newParameterArray[i][14];
                        var csvAnnualTarget = newParameterArray[i][15];
                        var csvQ1Target = newParameterArray[i][16];
                        var csvQ2Target = newParameterArray[i][17];
                        var csvQ3Target = newParameterArray[i][18];
                        var csvQ4Target = newParameterArray[i][19];

                        var strike = 1;

                        if (csvEmpCode && csvEmpName && csvSBU && csvBU && csvDesignation && csvFY && csvParameters && csvWeightage && csvAnnualTarget && csvProductGroup && csvBrand && csvCountry && csvRegion && csvManagerName && csvManagerCode && csvQ1Target && csvQ2Target && csvQ3Target && csvQ4Target) {
                            strike = 0;
                            importDataSaveArray.push({
                                index: i, code: newParameterArray[i][0].toString(), name: newParameterArray[i][1], designation: newParameterArray[i][2], managerCode: newParameterArray[i][3].toString(), managerName: newParameterArray[i][4], sbu: newParameterArray[i][5], bu: newParameterArray[i][6], entriy: newParameterArray[i][7], financialYear: newParameterArray[i][8], region: newParameterArray[i][9], country: newParameterArray[i][10], brand: newParameterArray[i][11], productGroup: newParameterArray[i][12], parameter: newParameterArray[i][13], weightage: newParameterArray[i][14] ? (newParameterArray[i][14]) * 100 : 0,
                                annualTarget: newParameterArray[i][15] ? parseFloat(newParameterArray[i][15]) : 0,
                                Q1Target: newParameterArray[i][16] ? parseFloat(newParameterArray[i][16]) : 0,
                                Q2Target: newParameterArray[i][17] ? parseFloat(newParameterArray[i][17]) : 0,
                                Q3Target: newParameterArray[i][18] ? parseFloat(newParameterArray[i][18]) : 0,
                                Q4Target: newParameterArray[i][19] ? parseFloat(newParameterArray[i][19]) : 0,
                                strike: strike
                            });
                        }

                        importDataArray.push({
                            index: i, code: newParameterArray[i][0], name: newParameterArray[i][1], designation: newParameterArray[i][2], managerCode: newParameterArray[i][3], managerName: newParameterArray[i][4], sbu: newParameterArray[i][5], bu: newParameterArray[i][6], entriy: newParameterArray[i][7], financialYear: newParameterArray[i][8], region: newParameterArray[i][9], country: newParameterArray[i][10], brand: newParameterArray[i][11], productGroup: newParameterArray[i][12], parameter: newParameterArray[i][13], weightage: newParameterArray[i][14] ? (newParameterArray[i][14]) * 100 : 0,
                            annualTarget: newParameterArray[i][15] ? ((newParameterArray[i][15])).toFixed(2) : 0,
                            Q1Target: newParameterArray[i][16] ? ((newParameterArray[i][16])).toFixed(2) : 0,
                            Q2Target: newParameterArray[i][17] ? ((newParameterArray[i][17])).toFixed(2) : 0,
                            Q3Target: newParameterArray[i][18] ? ((newParameterArray[i][18])).toFixed(2) : 0,
                            Q4Target: newParameterArray[i][19] ? ((newParameterArray[i][19])).toFixed(2) : 0,
                            strike: strike
                        });

                        if (i === newParameterArray.length - 1) {
                            setIsLoader(false)
                            setIsShowPreviewDataTable(true)
                            setImportData(importDataArray)
                            setImportSaveData(importDataSaveArray)
                        }
                    }

                    const importCount = importDataArray.reduce((acc, item) => {
                        if (item.strike === 0) {
                            return acc + 1;

                        } else {
                            return acc;
                        }
                    }, 0);

                    setIsValidatedCounter(importCount)
                }
            });
        }
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        bindData()
        onLoad();
        const selectedTab = localStorage.getItem('selectedTab')
        if (selectedTab) { setOpenTab(parseInt(selectedTab)) }
    }, []);

    //  -------------------------------------------- LOADED ALL MASTER DATA --------------------------------------------
    async function bindData() {
        //SBU
        // var SBUResponses = await DataStore.query(SBU, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });

        var SBUResponses = await graphQLGetAllData('listSBUS');
        SBUResponses = SBUResponses.sort((a,b)=>a.name.localeCompare(b.name));
        SetSbuList(SBUResponses)

      


        //Region
        // var regionResponses = await DataStore.query(Region, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        var regionResponses = await graphQLGetAllData('listRegions');
        regionResponses = regionResponses.sort((a,b)=>a.name.localeCompare(b.name));
        SetRegionList(regionResponses)

        //Country
        // var countryResponses = await DataStore.query(Country, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        var countryResponses = await graphQLGetAllData('listCountries');
        countryResponses = countryResponses.sort((a,b)=>a.name.localeCompare(b.name));
        SetCountryList(countryResponses)

        //Brand
        // var brandResponses = await DataStore.query(Brand, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        var brandResponses = await graphQLGetAllData('listBrands');
        brandResponses = brandResponses.sort((a,b)=>a.name.localeCompare(b.name));
        SetBrandList(brandResponses)

        //Product Group
        // var pGResponses = await DataStore.query(ProductGroup, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        var pGResponses = await graphQLGetAllData('listProductGroups');
        pGResponses = pGResponses.sort((a,b)=>a.name.localeCompare(b.name));
        SetProductGroupList(pGResponses)

        //Customer Group
        // var customerGroupResponses = await DataStore.query(CustomerCategory, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });

        var customerGroupResponses = await graphQLGetAllData('listCustomerCategories');
        customerGroupResponses = customerGroupResponses.sort((a,b)=>a.name.localeCompare(b.name));
        SetCustomerGroupList(customerGroupResponses)

        //Customer
        // var customerResponses = await DataStore.query(Customer, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        var customerResponses = await graphQLGetAllData('listCustomers');
        customerResponses = customerResponses.sort((a,b)=>a.name.localeCompare(b.name));
        SetCustomerList(customerResponses)

        // var buResponses = await DataStore.query(BIUnit, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        var buResponses = await graphQLGetAllData('listBIUnits');
        buResponses = buResponses.sort((a,b)=>a.name.localeCompare(b.name));
        SetBuList(buResponses)

        // var parameterResponses = await DataStore.query(Parameters, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        var parameterResponses = await graphQLGetAllData('listParameters');
        parameterResponses = parameterResponses.sort((a,b)=>a.name.localeCompare(b.name));
        SetParametersList(parameterResponses)

        // var financialYearResponses = await DataStore.query(FinancialYear, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        var financialYearResponses = await graphQLGetAllData('listFinancialYears');
        financialYearResponses = financialYearResponses.sort((a,b)=>a.name.localeCompare(b.name));
        setFinancialYearList(financialYearResponses)

        // var roleResponses = await DataStore.query(Roles, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        var roleResponses = await graphQLGetAllData('listRoles');
        roleResponses = roleResponses.sort((a,b)=>a.name.localeCompare(b.name));
        setRoleList(roleResponses)

        // var SUBResponses = await DataStore.query(SBU, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        var SUBResponses = await graphQLGetAllData('listSBUS');
        SUBResponses = SUBResponses.sort((a,b)=>a.name.localeCompare(b.name));
        setSBUList(SUBResponses)
    }

    //  -------------------------------------------- LOADED ALL MASTER DATA --------------------------------------------


    return (
        isAuthenticated ? (
            <div className="pt-16">
                <TopNavBar />
                <LeftSideBar />
                <div className="pl-16 dashboard-main-wrapper setting-wrapper" x-data="{tab: 1}">
                    <div className="p-6">
                        <div className="items-center space-x-8 sm:flex">
                            <div className="text-2xl font-semibold dark:text-white">Settings</div>
                        </div>
                        <div className="justify-between my-6 lg:flex">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link onClick={() => { localStorage.setItem("selectedTab", "1"); setOpenTab(1) }} className={openTab == 1 ? "active" : ""}>Masters</Link></li>
                                    <li><Link onClick={() => { localStorage.setItem("selectedTab", "2"); setOpenTab(2) }} className={openTab == 2 ? "active" : ""}>Dimensions</Link>

                                    </li>
                                </ul>
                            </div>
                            {/* {
                                openTab === 2 &&
                                <div className="tabs_two">
                                    <ul className="flex">
                                        <Link to="" onClick={() => setViewPopUp(1)} className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">Import Data</Link>
                                    </ul>
                                </div>
                            } */}
                        </div>

                        <div
                            className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 align-center 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 setting-wrapper"
                        >

                            {
                                openTab === 1 &&
                                masterList.map(v => (
                                    <Models key={v.title} value={v.title} navigateTo={v.navigateTo} highlight={searchText} icons={v.icon} tabValue={1} />
                                ))

                            }
                            {
                                openTab === 2 &&
                                dimensionsList.map(v => (
                                    <Models key={v.title} value={v.title} navigateTo={v.navigateTo} highlight={searchText} icons={v.icon} tabValue={1} />
                                ))

                            }

                        </div>
                    </div>
                </div>

                <div id="selector" className={isViewPopUp === 1 ? 'overlay' : ''}></div>
                {
                    isViewPopUp === 1 ?
                        <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                            <img src={Bussiness} alt="Image here" style={{ width: "50vw" }} />
                            <div className="absolute right-0 left-0 top-0 w-full text-white">
                                <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                    <div className="text-md"><h6 className='text-[white]'>Import</h6></div>
                                    {
                                        isLoader === false ?
                                            <div><Link to="#" onClick={() => cancelImport()} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                            : null
                                    }

                                </div>

                                <div className="px-6 mt-8">
                                    <span>
                                        <Menu as="div" className="relative inline-block text-left">
                                            <div className="flex items-center align-center">
                                                <Menu.Button className='flex items-center'>
                                                </Menu.Button>
                                            </div>
                                        </Menu>
                                        {/* <div className="text-sm text-[white]">Old</div> */}
                                        <div className="text-2xl">{popupTitle}
                                        </div>
                                    </span>
                                </div>

                            </div>


                            <div className='py-2 px-5 height-custtom'>

                                {
                                    validatedCounter > 0 ?
                                        <div className='record-validate float-left'>
                                            <p className='text-md'>Validated Record: <b>{validatedCounter}</b></p>
                                        </div> : null
                                }
                                {
                                    importData.length > 0 ?
                                        <div className='record-validate float-center'>
                                            <p className='text-md'>Invalidated Record: <b>{importData.length ? importData.length - validatedCounter : 0}</b></p>
                                        </div> : null
                                }
                                {
                                    importData.length > 0 ?
                                        <div className='record-validate float-right'>
                                            <p className='text-md'>Total Record: <b>{importData.length ? importData.length : 0}</b></p>
                                        </div> : null
                                }
                                {
                                    isShowPreviewDataTable ?

                                        <div className={"block w-full"}>
                                            {
                                                <DataTables
                                                    columns={partnerDataColumns}
                                                    data={importData}
                                                    customStyles={DataTableCustomStyles}
                                                    pagination
                                                    highlightOnHover
                                                />
                                            }
                                        </div>
                                        :

                                        isShowBrowseFile ?
                                            <>

                                                <div>
                                                    <div className='p-2 float-right'>
                                                        <a href="#" onClick={(e) => downloadDocument('public/importFile/EmployeeTargetTemplateV0.2.csv')}
                                                            className="text-sm text-[#0000FF] px-4 py-2 dark:text-white" rel="noreferrer">
                                                            Download Template </a>

                                                    </div><div className='p-2 mt-4 ml-4'>
                                                        <span>Browse File</span>
                                                        <div>
                                                            <button className="border text-[Black] flex bg-[white] rounded-md p-2  border-[#D0D5DD] pr-3 cursor-pointer">
                                                                <input
                                                                    onChange={(e) => { changeAttachment(e) }}
                                                                    accept=".csv" id="fileImport"
                                                                    multiple="multiple" type="file" className="cursor-pointer" />
                                                            </button>
                                                        </div>
                                                        <span className='text-muted font-size-muted'>Note: Please select .csv file</span>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                }


                            </div>
                            {
                                isLoader === false ?
                                    <div className="lg:relative left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                                        <div className="flex pl-2 justify-end gap-3">
                                            <div><Link to="#" onClick={() => cancelImport()} className="bg-white 
                                                    dark:text-[white] border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                            >Cancel</Link></div>

                                            {
                                                isShowPreviewDataTable ? <div><Link to="#" onClick={() => saveData()}
                                                    className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Import</Link>
                                                </div>
                                                    :
                                                    <div><Link to="#" onClick={() => previewData()}
                                                        className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Next</Link>
                                                    </div>
                                            }


                                        </div>
                                    </div>
                                    :
                                    isLoader &&
                                    <div className='fixed inset-0 flex items-center justify-center'>
                                        <ProgressSpinner style={{ width: '50px', height: '50px', zIndex: '9999' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                    </div>
                            }

                        </div> : null
                }
                <div>

                </div>

            </div>
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}

const Models = ({ highlight, value, navigateTo, icons, tabValue, }) => {
    return <div className="bg-[#fff] dark:bg-[#2A2E32] p-5 px-6 shadowisetting rounded-lg ">
        <Link onClick={() => { localStorage.setItem('tab', tabValue); }} to={navigateTo}>
            <div className="flex items-center justify-between">
                <h3 className="text-[16px] text-[#101828] dark:text-white font-medium">{getHighlightedText(value, highlight)}</h3>
                {/* <img src={icons} alt=""/> */}
                <i className={`${icons}`}></i>
            </div>
        </Link>
    </div>
    //return <Link to={navigateTo} className="col-span-2 -w-full py-10 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[25px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md" >{getHighlightedText(value, higlight)}</Link>;
};

function getHighlightedText(text, highlight) {
    // Split text on highlight term, include term itself into parts, ignore case
    var parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) => (
        <React.Fragment key={index}>
            {part.toLowerCase() === highlight.toLowerCase() ? (
                <b style={{ backgroundColor: "#029046" }}>{part}</b>
            ) : (
                part
            )}
        </React.Fragment>
    ));
}