import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { API, graphqlOperation } from 'aws-amplify';
import moment from "moment";
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { reactLocalStorage } from 'reactjs-localstorage';
import * as queries from '../../../../graphql/queries';
// import BoxPlanning from '../../../../assets/images/dashboard_box_planning.png';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import LeftSideBar from '../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../components/common/hr/topnav.component';
import { calculateEligibleEmployeeOfQtr, calculateTotalSBUActual } from '../../../../components/helper/calculateIndividualScore';
import { ConvertResponseForSelect } from '../../../../components/helper/commonfunctions';
import { ValidateHRRole } from '../../../../components/helper/validateRole';
import { FinancialYear, SBU } from "../../../../models";
import HRLogin from '../../../../setup/auth/hr.component';
import * as mutations from '../../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../src/components/helper/graphQLFunctions'

export default function ReportsGoalEvaluationSummary() {

    const [financialYear, setFinancialYear] = useState("");
    const [financialYearList, setFinancialYearList] = useState([]);
    const [showData, setShowData] = useState(false);
    const [summaryData, setSummaryData] = useState([]);
    const [totalHeadCount, setTotalHeadCount] = useState("");
    const [total_eleigiblity_per_qtr_end, setTotal_eleigiblity_per_qtr_end] = useState("");
    const [total_eleigiblity_per_qtr_endPer, setTotal_eleigiblity_per_qtr_endPer] = useState("");
    const [totalHundredCount, setTotalHundredCount] = useState("");
    const [totalHundredCountPer, setTotalHundredCountPer] = useState("");
    const [totalNinetyToHundredCount, setTotalNinetyToHundredCount] = useState("");
    const [totalNinetyToHundredCountPer, setTotalNinetyToHundredCountPer] = useState("");
    const [totalEightyToNinetyCount, setTotalEightyToNinetyCount] = useState("");
    const [totalEightyToNinetyCountPer, setTotalEightyToNinetyCountPer] = useState("");
    const [totalFiftyToEightyCount, setTotalFiftyToEightyCount] = useState("");
    const [totalFiftyToEightyCountPer, setTotalFiftyToEightyCountPer] = useState("");
    const [belowFiftyCount, setBelowFiftyCount] = useState("");
    const [belowFiftyCountPer, setBelowFiftyCountPerPer] = useState("");
    const [aboveTwoHundredCount, setAboveTwoHundredCount] = useState("");
    const [aboveTwoHundredCountPer, setAboveTwoHundredCountPerPer] = useState("");
    const [oneFiftyToTwoHundredCount, setOneFiftyToTwoHundredCount] = useState("");
    const [oneFiftyToTwoHundredCountPer, setOneFiftyToTwoHundredCountPer] = useState("");
    const [oneHundredToOneFiftyCount, setOneHundredToOneFiftyCount] = useState("");
    const [oneHundredToOneFiftyCountPer, setOneHundredToOneFiftyCountPer] = useState("");

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [quarter, setQuarter] = useState("");

    //quarterList
    var quarterList = [
        { label: "All Quarter", value: "4" },
        { label: "Quarter 1", value: "1" },
        { label: "Quarter 2", value: "2" },
        { label: "Quarter 3", value: "3" },
        { label: "Quarter 4", value: "4" },
    ]

    //Manage Targets

    async function onLoad() {
        try {
            if (!ValidateHRRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //onclick financial year
    const onFinancialSelectChange = async (e) => {
        reactLocalStorage.setObject('selectedYearEmployee', e);
        setFinancialYear({ label: e.label, value: e.value })
    }

    //     let financialYearResponses = await DataStore.query(FinancialYear, (c) => c.name.eq(selectedYear.label));
    //     let getEmployeeDetail = await DataStore.query(Employee, getEmployeeId);
    //     try {
    //         var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
    //             c.employee_id.eq(getEmployeeId),
    //             c.financial_year_id.eq(selectedYear.value)
    //         ]));

    //         //get parameters weightage
    //         var relationshipResponse = await DataStore.query(Relationship, (c) => c.and(c => [
    //             c.role_id.eq(getEmployeeDetail.role_id),
    //             c.financial_year_id.eq(selectedYear.value)
    //         ]));
    //         var relationshipDetailResponses = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationshipResponse[0].id));

    //         var myTargetId = GetSingleValueFromResponse(targetsResponses, 'id')

    //         //Target Details
    //         var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
    //             c.target_id.eq(myTargetId),
    //         ]));

    //         var _tempArray = [];
    //         currentTargetDetails.forEach(async (currentItem) => {
    //             //get parameters details
    //             var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(currentItem.parameter_id));
    //             var getWeightage = relationshipDetailResponses.filter((e) => e.parameter_id === currentItem.parameter_id)
    //             let data = {
    //                 "financial_year_id": selectedYear.value,
    //                 "financial_year": selectedYear.label,
    //                 "parameter_id": currentItem.parameter_id,
    //                 "parameter_name": parameterResponses[0].name,
    //                 "parameter_type": parameterResponses[0].type,
    //                 "target_value": currentItem.target_value,
    //                 "isMarkAsComplete": currentItem.isMarkAsComplete,
    //                 "is_accepted_by_employee": currentItem.is_accepted_by_employee,
    //                 "is_approve": currentItem.is_approve,
    //                 "is_revision_by_employee": currentItem.is_revision_by_employee,
    //                 "parameter_weightage": getWeightage[0].weightage,
    //                 "planning_period": moment(financialYearResponses[0].fromDate).format("Do MMM YY") + " - " + moment(financialYearResponses[0].toDate).format("Do MMM YY")
    //             }
    //             _tempArray.push(data)
    //         })

    //         setTimeout(() => {
    //             let sortArray = _tempArray.sort((a, b) => (a.parameter_name > b.parameter_name ? 1 : -1))
    //             setTargetsList(sortArray)
    //         }, 500);

    //     } catch (e) {

    //     }
    // }



    //get financial year
    const bindList = async () => {
        var financialYearId;
        //set financial year
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        if (Object.keys(selectedYear).length !== 0) {
            // getTargets(selectedYear)
            setQuarter({ label: "All Quarter", value: "4" })
            setFinancialYear(selectedYear)
            financialYearId = selectedYear.value
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);
            // getTargets({ label: responses[0].name, value: responses[0].id })
            setQuarter({ label: "All Quarter", value: "4" })
            setFinancialYear({ label: responses[0].name, value: responses[0].id })
            financialYearId = responses[0].id
        }

        // var responses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var responses = await graphQLGetAllData('listFinancialYears');
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));
        var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYearList(myArray);

        summaryDetails(financialYearId, "1")

    }

    const summaryDetails = async (financialYearId, quarterNo) => {

        setShowData(false);
        // var sBUResponses = await DataStore.query(SBU, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var sBUResponses = await graphQLGetAllData('listSBUS')
        sBUResponses = sBUResponses.sort((a,b)=>a.name.localeCompare(b.name));
        // console.log("sBUResponses",sBUResponses)
        var finalData = [];
        for (let i = 0; i < sBUResponses.length; i++) {

            let getFY = await API.graphql(graphqlOperation(queries.listFinancialYears, { filter: { id: { eq: financialYearId } }, }));
            var existingGetFY = getFY.data.listFinancialYears.items;
            let financialYear = existingGetFY[0].name
            let quarter = quarterNo === "4" ? "Q4" : quarterNo === "3" ? "Q3" : quarterNo === "2" ? "Q2" : "Q1"
            let sbu = sBUResponses[i].name
            let employeeResponse = await API.graphql(graphqlOperation(queries.listEmployees, { filter: { param_sbu_id: { eq: sBUResponses[i].id } }, }));
            var existingEmployeeResponse = employeeResponse.data.listEmployees.items;
            let totalHeadCount = existingEmployeeResponse.length
            var totalEligibleAsPerQtr;
            const calculateTotalEligibleAsPerQtr = await calculateEligibleEmployeeOfQtr(existingEmployeeResponse);
            if (calculateTotalEligibleAsPerQtr !== undefined) { totalEligibleAsPerQtr = calculateTotalEligibleAsPerQtr } else { totalEligibleAsPerQtr = 0; }
            var totalSBUActuals; var totalSBUCappedActuals; var totalAvgScore; var valueHundred; var ninetyToHundred; var eightyToNinety; var fiftyToEighty;
            var belowFifty; var aboveTwoHundred; var aboveOneFiftyToTwoHundred; var aboveOneHundredToOneFifty;
            const calculateTotalSBUActualAll = await calculateTotalSBUActual(existingEmployeeResponse, financialYearId, "1");
            if (calculateTotalSBUActualAll !== undefined) {
                // console.log("calculateTotalSBUActualAll",calculateTotalSBUActualAll)
                totalSBUActuals = calculateTotalSBUActualAll.totalSBUActualsValue; 
                totalSBUCappedActuals = calculateTotalSBUActualAll.totalSBUCappedActualsValue; 
                totalAvgScore = calculateTotalSBUActualAll.totalAvgScore;
                valueHundred = calculateTotalSBUActualAll.equalHundred; 
                ninetyToHundred = calculateTotalSBUActualAll.ninetyToHundred;
                eightyToNinety = calculateTotalSBUActualAll.eightyToNinety;
                fiftyToEighty = calculateTotalSBUActualAll.fiftyToEighty;
                belowFifty = calculateTotalSBUActualAll.belowFifty;
                aboveTwoHundred = calculateTotalSBUActualAll.aboveTwoHundred; 
                aboveOneFiftyToTwoHundred = calculateTotalSBUActualAll.aboveOneFiftyToTwoHundred; aboveOneHundredToOneFifty = calculateTotalSBUActualAll.aboveOneHundredToOneFifty;
            } else {
                totalSBUActuals = 0; totalSBUCappedActuals = 0; totalAvgScore = 0; eightyToNinety = 0; fiftyToEighty = 0; belowFifty = 0; aboveTwoHundred = 0; aboveOneFiftyToTwoHundred = 0; aboveOneHundredToOneFifty = 0;
            }
            var h1Average = 0;
            if (totalAvgScore > 100) { h1Average = 100; } else if (totalAvgScore > 80) { h1Average = totalAvgScore } else if (totalAvgScore < 80) { h1Average = 50 }

            finalData.push({
                "financialYear": financialYear, "quarter": quarter, "sbu": sbu, "totalHeadCount": totalHeadCount, "totalEligibleAsPerQtr": totalEligibleAsPerQtr,
                "totalSBUActuals": totalSBUActuals, "totalSBUCappedActuals": totalSBUCappedActuals, "totalAvgScore": totalAvgScore, "h1Average": h1Average, "valueHundred": valueHundred,
                "ninetyToHundred": ninetyToHundred, "eightyToNinety": eightyToNinety, "fiftyToEighty": fiftyToEighty, "belowFifty": belowFifty, "aboveTwoHundred": aboveTwoHundred,
                "aboveOneFiftyToTwoHundred": aboveOneFiftyToTwoHundred, "aboveOneHundredToOneFifty": aboveOneHundredToOneFifty
            })
            if (i === sBUResponses.length - 1) {
                // console.log("finalData",finalData)
                setSummaryData(finalData)
                setShowData(true);
                let totalHeadCountArray = finalData.reduce((acc, book) => acc + parseFloat(book.totalHeadCount), 0); setTotalHeadCount(totalHeadCountArray);
                let totalEligibleAsPerQtrArray = finalData.reduce((acc, book) => acc + parseFloat(book.totalEligibleAsPerQtr), 0); setTotal_eleigiblity_per_qtr_end(totalEligibleAsPerQtrArray);
                let totalValueHundredArray = finalData.reduce((acc, book) => acc + parseFloat(book.valueHundred=== undefined? 0 : book.valueHundred), 0);
                // console.log("totalValueHundredArray",totalValueHundredArray)
                setTotalHundredCount(totalValueHundredArray);
                let totalNinetyToHundredArray = finalData.reduce((acc, book) => acc + parseFloat(book.ninetyToHundred=== undefined ? 0 : book.ninetyToHundred), 0); setTotalNinetyToHundredCount(totalNinetyToHundredArray);
                let totalEightyToNinetyArray = finalData.reduce((acc, book) => acc + parseFloat(book.eightyToNinety), 0); setTotalEightyToNinetyCount(totalEightyToNinetyArray);
                let totalFiftyToEightyArray = finalData.reduce((acc, book) => acc + parseFloat(book.fiftyToEighty), 0); setTotalFiftyToEightyCount(totalFiftyToEightyArray);
                let totalBelowFiftyArray = finalData.reduce((acc, book) => acc + parseFloat(book.belowFifty), 0); setBelowFiftyCount(totalBelowFiftyArray);
                let totalAboveTwoHundredArray = finalData.reduce((acc, book) => acc + parseFloat(book.aboveTwoHundred), 0); setAboveTwoHundredCount(totalAboveTwoHundredArray);
                let totalAboveOneFiftyToTwoHundredArray = finalData.reduce((acc, book) => acc + parseFloat(book.aboveOneFiftyToTwoHundred), 0); setOneFiftyToTwoHundredCount(totalAboveOneFiftyToTwoHundredArray);
                let totalAboveOneHundredToOneFiftyArray = finalData.reduce((acc, book) => acc + parseFloat(book.aboveOneHundredToOneFifty), 0); setOneHundredToOneFiftyCount(totalAboveOneHundredToOneFiftyArray);
            }
        }
    }


    const footerTemplate = () => {
        return (
            <>
                <div>Total</div>
                {/* <div>Percentage</div> */}
            </>
        );
    };

    const totalEligibleAsPerQTRTemplate = () => {
        return (
            <>
                <div>{total_eleigiblity_per_qtr_end}</div>
                {/* <div>{total_eleigiblity_per_qtr_endPer}</div> */}
            </>
        );
    };

    const totalHundredCountTemplate = () => {
        return (
            <>
                <div>{totalHundredCount}</div>
                {/* <div>{totalHundredCountPer}</div> */}
            </>
        );
    };

    const totalNinetyToHundredCountTemplate = () => {
        return (
            <>
                <div>{totalNinetyToHundredCount}</div>
                {/* <div>{totalNinetyToHundredCountPer}</div> */}
            </>
        );
    };

    const totalEightyToNinetyCountTemplate = () => {
        return (
            <>
                <div>{totalEightyToNinetyCount}</div>
                {/* <div>{totalEightyToNinetyCountPer}</div> */}
            </>
        );
    };

    const totalFiftyToEightyCountTemplate = () => {
        return (
            <>
                <div>{totalFiftyToEightyCount}</div>
                {/* <div>{totalFiftyToEightyCountPer}</div> */}
            </>
        );
    };

    const totalBelowFiftyCountTemplate = () => {
        return (
            <>
                <div>{belowFiftyCount}</div>
                {/* <div>{belowFiftyCountPer}</div> */}
            </>
        );
    };

    const totalAboveTwoHundredCountTemplate = () => {
        return (
            <>
                <div>{aboveTwoHundredCount}</div>
                {/* <div>{aboveTwoHundredCountPer}</div> */}
            </>
        );
    };

    const totalOneFiftyToTwoHundredCountTemplate = () => {
        return (
            <>
                <div>{oneFiftyToTwoHundredCount}</div>
                {/* <div>{oneFiftyToTwoHundredCountPer}</div> */}
            </>
        );
    };

    const totalOneHundredToONeFiftyCountTemplate = () => {
        return (
            <>
                <div>{oneHundredToOneFiftyCount}</div>
                {/* <div>{oneHundredToOneFiftyCountPer}</div> */}
            </>
        );
    };


    useEffect(() => {
        bindList()
        onLoad()
    }, []);


    const downloadReport = () => {
        const unit = "pt";
        const size = "A2"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);

        //target Data
        const title = "Goals Evaluation - Summary";
        const headers = [["financialYear", "Quarter", "SBU/Functions", "Total Headcount", "Total Eligible as of Qtr End", "Avg. Total SBU Actual on 80", "Avg. Total SBU Score Capped on 80",
            "Avg. Score (Total)", "H1 Average Payout Score", "100%", "90 to 100%", "80 to 90%", "50 to 80%", "Below 50", "Above 200% (Individal + Company)",
            "Above 150 to 200% (Individual + Company)", "Above 100 to 150% (Individual + Company)"
        ]];
        const data = summaryData.map(elt => [elt.financialYear, elt.quarter, elt.sbu, elt.totalHeadCount, elt.totalEligibleAsPerQtr,
        elt.totalSBUActuals, elt.totalSBUCappedActuals, elt.totalAvgScore, elt.h1Average, elt.valueHundred, elt.ninetyToHundred,
        elt.eightyToNinety, elt.fiftyToEighty, elt.belowFifty, elt.aboveTwoHundred, elt.aboveOneFiftyToTwoHundred, elt.aboveOneHundredToOneFifty
        ]);
        let content = { startY: 50, head: headers, body: data };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Goals Evaluation - Summary")
    }



    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="sm:flex items-center space-x-8">
                        <div className="text-2xl font-semibold dark:text-white">Reports</div>
                        <div className="tabs_one mt-5 sm:mt-0">
                            <ul className="flex">
                                <li><Link to="/hr/reports/goalsetting/summary"  >Goal Setting</Link></li>
                                <li><Link className='active' >Goals Evaluation</Link></li>
                            </ul>
                        </div>
                    </div>
                    {
                        showData &&
                        <>
                            <div className="lg:flex justify-between my-6">
                                <div className="tabs_two">
                                    <ul className="flex">
                                        <li><Link className='active'>Summary</Link></li>
                                        <li><Link to="/hr/reports/goalevaluation/detailed" >Detailed</Link></li>
                                    </ul>
                                </div>
                                <div className="mt-5 lg:mt-0 flex items-center gap-4">
                                    <div className="text-md min-w-fit dark:text-white">Select Year</div>
                                    <div className="w-full min-w-[200px]">
                                        <Select
                                            options={financialYearList}
                                            value={financialYear}
                                            onChange={e => {
                                                setFinancialYear(e)
                                                summaryDetails(e.value, quarter.value)
                                            }}
                                            className="my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>
                                    <div className="w-full min-w-[200px]">
                                        <Select
                                            options={quarterList}
                                            value={quarter}
                                            onChange={(e) => {
                                                setQuarter(e)
                                                summaryDetails(financialYear.value, e.value)
                                            }}
                                            maxMenuHeight={200} placeholder="Select Quarter"
                                            className="my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* code here */}

                            <div className={"block w-full dark:border-none"}>

                                <div className="lg:flex justify-between my-6">
                                    <div className="tabs_two">
                                        <ul className="flex font-semibold dark:text-white">
                                            Goals Evaluation - Summary
                                        </ul>
                                    </div>
                                    <div className="mt-5 lg:mt-0 flex items-center gap-4">
                                        <Link onClick={() => window.print()} className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" ><i className="pi pi-print style={{'fontSize': '2px'}}"></i> Print</Link>
                                        <Link onClick={() => downloadReport()} className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" ><i className="pi pi-download style={{'fontSize': '2px'}}"></i> Download</Link>
                                    </div>
                                </div>
                                {
                                    <div className="flex overflow-auto rounded-lg mt-5 ">
                                        <DataTable paginator value={summaryData} style={{ width: '93vw' }}
                                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                        >
                                            <Column field="financialYear" header="Financial Year" sortable filter filterPlaceholder="Search by Employee ID" footer={footerTemplate} ></Column>
                                            <Column field="quarter" header="Quarter" sortable filter filterPlaceholder="Search by Name"></Column>
                                            <Column field="sbu" header="SBU/Functions" sortable filter filterPlaceholder="Search by Role"></Column>
                                            <Column field="totalHeadCount" header="Total Headcount" sortable filter filterPlaceholder="Search by Vertical" footer={totalHeadCount}></Column>
                                            <Column field="totalEligibleAsPerQtr" header="Total Eligible as of Qtr End" sortable filter filterPlaceholder="Search by SBU" footer={totalEligibleAsPerQTRTemplate}></Column>
                                            <Column field="totalSBUActuals" header="Avg. Total SBU Actual on 80" sortable filter filterPlaceholder="Search by Reporting Manager"></Column>
                                            <Column field='totalSBUCappedActuals' header="Avg. Total SBU Score Capped on 80" filter filterPlaceholder="Search by Submitted Date" ></Column>
                                            <Column field='totalAvgScore' header="Avg. Score (Total)" filter filterPlaceholder="Search by Approved Date"  ></Column>
                                            <Column field='h1Average' header="H1 Average Payout Score" filter filterPlaceholder="Search by Status" ></Column>
                                            <Column field='valueHundred' header="100%" footer={totalHundredCountTemplate}></Column>
                                            <Column field='ninetyToHundred' header="90 to 100%" footer={totalNinetyToHundredCountTemplate}></Column>
                                            <Column field='eightyToNinety' header="80 to 90%" footer={totalEightyToNinetyCountTemplate}></Column>
                                            <Column field='fiftyToEighty' header="50 to 80%" footer={totalFiftyToEightyCountTemplate}></Column>
                                            <Column field='belowFifty' header="Below 50" footer={totalBelowFiftyCountTemplate}></Column>
                                            <Column field='aboveTwoHundred' header="Above 200% (Individual + Company)" footer={totalAboveTwoHundredCountTemplate}></Column>
                                            <Column field='aboveOneFiftyToTwoHundred' header="Above 150 to 200% (Individual + Company)" footer={totalOneFiftyToTwoHundredCountTemplate}></Column>
                                            <Column field='aboveOneHundredToOneFifty' header="Above 100 to 150% (Individual + Company)" footer={totalOneHundredToONeFiftyCountTemplate}></Column>
                                        </DataTable>
                                    </div>
                                }
                            </div>
                        </>
                    }
                    {
                        !showData &&
                        <div className='text-center py-8' ><ProgressSpinner /></div>
                    }

                </div>
            </div>


        </div>
    )
        : (
            <HRLogin sessionExpired={1} />
        );
}