import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import moment from "moment";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Carousel } from 'primereact/carousel';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';
import BoxPlanning from '../../../../assets/images/dashboard_box_planning.png';
import AnnualSaleIcon from '../../../../assets/svg/annual_sale_icon.svg';
import quarterIcon from '../../../../assets/svg/quarter_icon.svg';
import AddButton from '../../../../components/common/buttons/AddButton';
import MinusButton from '../../../../components/common/buttons/MinusButton';
import LeftNav from '../../../../components/common/employee/leftnav.component';
import TopNav from '../../../../components/common/employee/topnav.component';
import {
    ConvertResponseForSelect, GetArrayItemIndexFromKeyValue, GetArrayNextItemFromKeyValue, GetArrayPreviousItemFromKeyValue, GetSingleValueFromResponse, GetTotalPercentageFromPlanArray,
    GetTotalTargetValueFromPlanArray, RemoveItemFromArrayByIdValue, SetBrandAggregateValueFromPlanArray, SetCountryAggregateValueFromPlanArray, SetCustomerAggregateValueFromPlanArray, SetCustomerSegmentAggregateValueFromPlanArray, SetProductGroupAggregateValueFromPlanArray, SetRegionAggregateValueFromPlanArray, SetBuAggregateValueFromPlanArray
} from '../../../../components/helper/commonfunctions';
import { PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES } from '../../../../components/helper/enum';
import { commaSeparated, isPositiveInteger, ShowInMillion } from '../../../../components/helper/maths';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { Brand, Country, Customer, CustomerCategory, Employee, FinancialYear, Parameters, Plan, ProductGroup, Region, Relationship, RelationshipDetails, SBU, TargetDetails, Targets, BIUnit } from '../../../../models';
import EmpLogin from "../../../../setup/auth/emp.component";
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../src/components/helper/graphQLFunctions'
import * as mutations from '../../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';


export default function Team_ManageCommit_AnnualTarget() {
    let navigateTo = useNavigate();
    let { financialYearId, parameterId, team } = useParams();
    const [isShowConfirmationPopUp, setIsShowConfirmationPopUp] = useState(0);
    const [markAsIncompleteParameters, setMarkAsIncompleteParameters] = useState(0);
    const [prevParameterId, setPrevParameterId] = useState("");
    const [nextParameterId, setNextParameterId] = useState("");

    var loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId');
    var loggedEmployeeRoleId = reactLocalStorage.get('loggedEmployeeRoleId');
    const [isShowRemark, setIsShowRemark] = useState(false);
    const [parametersCarousel, setParametersCarousel] = useState([]);
    const [carouselActivePageNo, setCarouselActivePageNo] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);


    const parametersCarouselResponsiveOptions = [
        {
            breakpoint: '1600px',
            numVisible: 3,
            numScroll: 1,
        },
        {
            breakpoint: '1280px',
            numVisible: 4,
            numScroll: 1,
        },
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 1,
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1,
        }

    ];


    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }


    //Plan Table State
    const [planData, setPlanData] = useState([]);
    const [isPageLoaded, setPageLoaded] = useState(false);


    //SBU
    //----------------------
    const [sbuList, SetSbuList] = useState([]);
    const [sbuDropdownList, setSbuDropdownList] = useState([]);

    const sbuEditor = (options) => {
        return (
            <Dropdown value={options.value} options={sbuList} optionLabel="name" optionValue="id"
                onChange={(e) => options.editorCallback(e.value)} placeholder="SBU"
                itemTemplate={(option) => {
                    return <span>{option.name}</span>
                }} />
        );
    }

    const getSBULabel = (id) => {
        let _sbuList = [...sbuList];
        var returnRecord = _sbuList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const sbuBodyTemplate = (rowData) => {
        return getSBULabel(rowData.sbuId);
    }

    //----------------------

    //BU
    //----------------------
    const [buList, SetBuList] = useState([]);
    const [buDropdownList, setBuDropdownList] = useState([]);

    const buEditor = (options) => {
        return (
            <Dropdown value={options.value} options={buList} optionLabel="name" optionValue="id"
                onChange={(e) => options.editorCallback(e.value)} placeholder="SBU"
                itemTemplate={(option) => {
                    return <span>{option.name}</span>
                }} />
        );
    }

    const getBULabel = (id) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const buBodyTemplate = (rowData) => {
        return getBULabel(rowData.buId);
    }

    //----------------------



    //Region
    //----------------------
    const [regionList, SetRegionList] = useState([]);
    const [regionDropdownList, setRegionDropdownList] = useState([]);


    const regionEditor = (options) => {
        return (
            <Dropdown value={options.value} options={regionList} optionLabel="name" optionValue="id"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Region"
                itemTemplate={(option) => {
                    return <span>{option.name}</span>
                }} />
        );
    }

    const getRegionLabel = (id) => {
        let _regionList = [...regionList];
        var returnRecord = _regionList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const regionBodyTemplate = (rowData) => {
        return getRegionLabel(rowData.regionId);
    }
    //----------------------



    //Country
    //----------------------
    const [countryList, SetCountryList] = useState([]);
    const [countryDropdownList, setCountryDropdownList] = useState([]);


    const countryEditor = (options) => {
        return (
            <Dropdown value={options.value} options={countryList} optionLabel="name" optionValue="id"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Country"
                itemTemplate={(option) => {
                    return <span>{option.name}</span>
                }} />
        );
    }

    const getCountryLabel = (id) => {
        let _countryList = [...countryList];
        var returnRecord = _countryList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const countryBodyTemplate = (rowData) => {
        return getCountryLabel(rowData.countryId);
    }
    //----------------------



    //Brand
    //----------------------
    const [brandList, SetBrandList] = useState([]);
    const [brandDropdownList, setBrandDropdownList] = useState([]);


    const brandEditor = (options) => {
        return (
            <Dropdown value={options.value} options={brandList} optionLabel="name" optionValue="id"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Brand"
                itemTemplate={(option) => {
                    return <span>{option.name}</span>
                }} />
        );
    }

    const getBrandLabel = (id) => {
        let _brandList = [...brandList];
        var returnRecord = _brandList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const brandBodyTemplate = (rowData) => {
        return getBrandLabel(rowData.brandId);
    }
    //----------------------



    //Product Group
    //----------------------
    const [productGroupList, SetProductGroupList] = useState([]);
    const [productGroupDropdownList, setProductGroupDropdownList] = useState([]);


    const productGroupEditor = (options) => {
        return (
            <Dropdown value={options.value} options={productGroupList} optionLabel="name" optionValue="id"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Product Group"
                itemTemplate={(option) => {
                    return <span>{option.name}</span>
                }} />
        );
    }

    const getProductGroupLabel = (id) => {
        let _productGroupList = [...productGroupList];
        var returnRecord = _productGroupList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const productGroupBodyTemplate = (rowData) => {
        return getProductGroupLabel(rowData.productGroupId);
    }
    //----------------------


    //Customer Group
    //----------------------
    const [customerGroupList, SetCustomerGroupList] = useState([]);
    const [customerGroupDropdownList, setCustomerGroupDropdownList] = useState([]);


    const customerGroupEditor = (options) => {
        return (
            <Dropdown value={options.value} options={customerGroupList} optionLabel="name" optionValue="id"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Customer Segment"
                itemTemplate={(option) => {
                    return <span>{option.name}</span>
                }} />
        );
    }

    const getCustomerGroupLabel = (id) => {
        let _customerGroupList = [...customerGroupList];
        var returnRecord = _customerGroupList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const customerGroupBodyTemplate = (rowData) => {
        return getCustomerGroupLabel(rowData.customerGroupId);
    }
    //----------------------



    //Customer
    //----------------------
    const [customerList, SetCustomerList] = useState([]);
    const [customerDropdownList, setCustomerDropdownList] = useState([]);


    const customerEditor = (options) => {
        return (
            <Dropdown value={options.value} options={customerList} optionLabel="name" optionValue="id"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Customer"
                itemTemplate={(option) => {
                    return <span>{option.name}</span>
                }} />
        );
    }

    const getCustomerLabel = (id) => {
        let _customerList = [...customerList];
        var returnRecord = _customerList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const customerBodyTemplate = (rowData) => {
        return getCustomerLabel(rowData.customerId);
    }
    //----------------------


    //Target Value Calculation
    //----------------------
    const targetValueEditor = (options) => {
        return <input type="number" className="text-[16px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white 
        text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500  placeholder:text-[#667085] "
            value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }


    //Dis Equally
    //----------------------
    const disEquallyList = [{ name: "Yes", id: 1 }, { name: "No", id: 0 }];
    const disEquallyEditor = (options) => {
        return (
            <Dropdown value={options.value} options={disEquallyList} optionLabel="name" optionValue="id"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Select"
                itemTemplate={(option) => {
                    return <span>{option.name}</span>
                }} />
        );
    }

    const onCellEditComplete = (e) => {

        let { newRowData, field, rowIndex, newValue, originalEvent: event } = e;

        switch (field) {
            case 'targetValue':
            case 'Q1TargetValue':
            case 'Q2TargetValue':
            case 'Q3TargetValue':
            case 'Q4TargetValue':
                if (isPositiveInteger(newValue))
                    newRowData[field] = newValue;
                else
                    event.preventDefault();
                break;
            default:
                break;
        }

        if (field === "disEqually") {
            if (newRowData.disEqually === 0) {
                let currentTargetValue = parseFloat(newRowData.targetValue);
                if (currentTargetValue > 0 && (parameterType === 'A' || parameterType === 'N')) {
                    let equalQuarterValue = currentTargetValue / 4; equalQuarterValue = equalQuarterValue.toFixed(0);
                    newRowData.Q1TargetValue = equalQuarterValue;
                    newRowData.Q2TargetValue = equalQuarterValue;
                    newRowData.Q3TargetValue = equalQuarterValue;
                    newRowData.Q4TargetValue = equalQuarterValue;
                }
                else if (currentTargetValue > 0 && (parameterType === 'P' || parameterType === 'D')) {
                    newRowData.Q1TargetValue = currentTargetValue;
                    newRowData.Q2TargetValue = currentTargetValue;
                    newRowData.Q3TargetValue = currentTargetValue;
                    newRowData.Q4TargetValue = currentTargetValue;
                }
            }
        }

        else if ((field === "currentM1TargetValue" || field === "currentM2TargetValue" || field === "currentM3TargetValue")) {
            let currentM1TargetValue = parseFloat(newRowData.currentM1TargetValue);
            let currentM2TargetValue = parseFloat(newRowData.currentM2TargetValue);
            let currentM3TargetValue = parseFloat(newRowData.currentM3TargetValue);

            let currentQ1TargetValue = parseFloat(newRowData.Q1TargetValue);
            let totalQ1MonthTarget = 0

            if (parameterType === PARAMETERS_TYPES.Days || parameterType === PARAMETERS_TYPES.Percentage) {
                totalQ1MonthTarget = (currentM1TargetValue + currentM2TargetValue + currentM3TargetValue) / 3
            } else {
                totalQ1MonthTarget = currentM1TargetValue + currentM2TargetValue + currentM3TargetValue
            }
            if (totalQ1MonthTarget < currentQ1TargetValue) {
                toast.error("Check your monthly target.", { autoClose: 3000, })
                newRowData.currentM1TargetValue = "";
                newRowData.currentM2TargetValue = "";
                newRowData.currentM3TargetValue = "";
            }
        }

        else if ((field === "currentM4TargetValue" || field === "currentM5TargetValue" || field === "currentM6TargetValue")) {
            let currentM4TargetValue = parseFloat(newRowData.currentM4TargetValue);
            let currentM5TargetValue = parseFloat(newRowData.currentM5TargetValue);
            let currentM6TargetValue = parseFloat(newRowData.currentM6TargetValue);

            let currentQ2TargetValue = parseFloat(newRowData.Q2TargetValue);
            let totalQ2MonthTarget = 0
            if (parameterType === PARAMETERS_TYPES.Days || parameterType === PARAMETERS_TYPES.Percentage) {
                totalQ2MonthTarget = currentM4TargetValue + currentM5TargetValue + currentM6TargetValue
            } else {
                totalQ2MonthTarget = (currentM4TargetValue + currentM5TargetValue + currentM6TargetValue) / 3
            }
            if (totalQ2MonthTarget < currentQ2TargetValue) {
                toast.error("Check your monthly target.", { autoClose: 3000, })
                newRowData.currentM4TargetValue = "";
                newRowData.currentM5TargetValue = "";
                newRowData.currentM6TargetValue = "";
            }
        }

        else if ((field === "currentM7TargetValue" || field === "currentM8TargetValue" || field === "currentM9TargetValue")) {
            let currentM7TargetValue = parseFloat(newRowData.currentM7TargetValue);
            let currentM8TargetValue = parseFloat(newRowData.currentM8TargetValue);
            let currentM9TargetValue = parseFloat(newRowData.currentM9TargetValue);

            let currentQ3TargetValue = parseFloat(newRowData.Q3TargetValue);

            let totalQ3MonthTarget = 0
            if (parameterType === PARAMETERS_TYPES.Days || parameterType === PARAMETERS_TYPES.Percentage) {
                totalQ3MonthTarget = (currentM7TargetValue + currentM8TargetValue + currentM9TargetValue) / 3
            } else {
                totalQ3MonthTarget = currentM7TargetValue + currentM8TargetValue + currentM9TargetValue
            }
            if (totalQ3MonthTarget < currentQ3TargetValue) {
                toast.error("Check your monthly target.", { autoClose: 3000, })
                newRowData.currentM7TargetValue = "";
                newRowData.currentM8TargetValue = "";
                newRowData.currentM9TargetValue = ""
            }
        }


        else if ((field === "currentM10TargetValue" || field === "currentM11TargetValue" || field === "currentM12TargetValue")) {
            let currentM10TargetValue = parseFloat(newRowData.currentM10TargetValue);
            let currentM11TargetValue = parseFloat(newRowData.currentM11TargetValue);
            let currentM12TargetValue = parseFloat(newRowData.currentM12TargetValue);

            let currentQ4TargetValue = parseFloat(newRowData.Q4TargetValue);

            let totalQ4MonthTarget = 0
            if (parameterType === PARAMETERS_TYPES.Days || parameterType === PARAMETERS_TYPES.Percentage) {
                totalQ4MonthTarget = currentM10TargetValue + currentM11TargetValue + currentM12TargetValue
            } else {
                totalQ4MonthTarget = currentM10TargetValue + currentM11TargetValue + currentM12TargetValue
            }
            if (totalQ4MonthTarget < currentQ4TargetValue) {
                toast.error("Check your monthly target.", { autoClose: 3000, })
                newRowData.currentM10TargetValue = "";
                newRowData.currentM11TargetValue = "";
                newRowData.currentM12TargetValue = "";
            }

        }

        let _planData = [...planData];
        _planData[rowIndex] = newRowData;
        setPlanData(_planData);
        CalculateDimension();
    }

    //----------------------




    //Work on Plan Tables

    const [sbuDropdownId, setSbuDropdownId] = useState("");
    const [sbuDropdown, setSbuDropdown] = useState("");

    const [regionDropdownId, setRegionDropdownId] = useState("");
    const [regionDropdown, setRegionDropdown] = useState("");

    const [countryDropdownId, setCountryDropdownId] = useState("");
    const [countryDropdown, setCountryDropdown] = useState("");

    const [brandDropdownId, setBrandDropdownId] = useState("");
    const [brandDropdown, setBrandDropdown] = useState("");

    const [productGroupDropdownId, setProductGroupDropdownId] = useState("");
    const [productGroupDropdown, setProductGroupDropdown] = useState("");

    const [customerGroupDropdownId, setCustomerGroupDropdownId] = useState("");
    const [customerGroupDropdown, setCustomerGroupDropdown] = useState("");

    const [customerDropdownId, setCustomerDropdownId] = useState("");
    const [customerDropdown, setCustomerDropdown] = useState("");

    const [targetValueInput, setTargetValueInput] = useState("");


    const deleteBodyTemplate = (rowData) => {
        return <i onClick={(e) => removePlanItem(rowData.id)} className='icon delete_icon'></i>;
    }

    const removePlanItem = (removeId) => {
        let _planData = [...planData];
        let _newPlanData = RemoveItemFromArrayByIdValue(_planData, removeId);
        setPlanData(_newPlanData);
        CalculateDimension();
    }


    //Calculate Dimension

    const [buDimensionData, setBuDimensionData] = useState([]);
    const [buDimensionFooterTotal, setBuDimensionFooterTotal] = useState('');

    const [regionDimensionData, setRegionDimensionData] = useState([]);
    const [regionDimensionFooterTotal, setRegionDimensionFooterTotal] = useState('');

    const [countryDimensionData, setCountryDimensionData] = useState([]);
    const [countryDimensionFooterTotal, setCountryDimensionFooterTotal] = useState('');

    const [brandDimensionData, setBrandDimensionData] = useState([]);
    const [brandDimensionFooterTotal, setBrandDimensionFooterTotal] = useState('');

    const [productGroupDimensionData, setProductGroupDimensionData] = useState([]);
    const [productGroupDimensionFooterTotal, setProductGroupDimensionFooterTotal] = useState('');

    const [customerSegmentDimensionData, setCustomerSegmentDimensionData] = useState([]);
    const [customerSegmentDimensionFooterTotal, setCustomerSegmentDimensionFooterTotal] = useState('');

    const [customerDimensionData, setCustomerDimensionData] = useState([]);
    const [customerDimensionFooterTotal, setCustomerDimensionFooterTotal] = useState('');

    //Plan Footer
    const [planTotalTargetFooterTotal, setPlanTotalTargetFooterTotal] = useState('');
    const [planQ1TotalTargetFooterTotal, setPlanQ1TotalTargetFooterTotal] = useState('');
    const [planQ2TotalTargetFooterTotal, setPlanQ2TotalTargetFooterTotal] = useState('');
    const [planQ3TotalTargetFooterTotal, setPlanQ3TotalTargetFooterTotal] = useState('');
    const [planQ4TotalTargetFooterTotal, setPlanQ4TotalTargetFooterTotal] = useState('');

    const CalculateDimension = () => {

        if (isPageLoaded === false) {
            return;
        }

        //Get Target Total
        var totalAddedTarget = 0, q1Total = 0, q2Total = 0, q3Total = 0, q4Total = 0;
        planData.map(currentItem => {
            if (parseFloat(currentItem.targetValue) > 0) { totalAddedTarget = totalAddedTarget + parseFloat(currentItem.targetValue); }
            if (parseFloat(currentItem.Q1TargetValue) > 0) { q1Total = q1Total + parseFloat(currentItem.Q1TargetValue); }
            if (parseFloat(currentItem.Q2TargetValue) > 0) { q2Total = q2Total + parseFloat(currentItem.Q2TargetValue); }
            if (parseFloat(currentItem.Q3TargetValue) > 0) { q3Total = q3Total + parseFloat(currentItem.Q3TargetValue); }
            if (parseFloat(currentItem.Q4TargetValue) > 0) { q4Total = q4Total + parseFloat(currentItem.Q4TargetValue); }
            return currentItem;
        });
        setParameterQ1Target(q1Total.toFixed(2));
        setParameterQ2Target(q2Total.toFixed(2));
        setParameterQ3Target(q3Total.toFixed(2));
        setParameterQ4Target(q4Total.toFixed(2));


        //Calculate Percentage
        let totalMatchPer = 0;
        let q1Per = (q1Total * 100) / parameterAnnualTarget; if (q1Per > 0) { q1Per = parseFloat(q1Per).toFixed(2); setParameterQ1Percentage(q1Per) };
        let q2Per = (q2Total * 100) / parameterAnnualTarget; if (q2Per > 0) { q2Per = parseFloat(q2Per).toFixed(2); setParameterQ2Percentage(q2Per) };
        let q3Per = (q3Total * 100) / parameterAnnualTarget; if (q3Per > 0) { q3Per = parseFloat(q3Per).toFixed(2); setParameterQ3Percentage(q3Per) };
        let q4Per = (q4Total * 100) / parameterAnnualTarget; if (q4Per > 0) { q4Per = parseFloat(q4Per).toFixed(2); setParameterQ4Percentage(q4Per) };

        if (totalAddedTarget > 0) {
            totalMatchPer = (totalAddedTarget * 100) / parameterAnnualTarget;
            totalMatchPer = parseFloat(totalMatchPer).toFixed(2)
        }
        setParameterMatchPer(totalMatchPer);


        //Calculate Plan Total Footers
        setPlanTotalTargetFooterTotal(parseFloat(totalAddedTarget));
        setPlanQ1TotalTargetFooterTotal(parseFloat(q1Total));
        setPlanQ2TotalTargetFooterTotal(parseFloat(q2Total));
        setPlanQ3TotalTargetFooterTotal(parseFloat(q3Total));
        setPlanQ4TotalTargetFooterTotal(parseFloat(q4Total));


        //Get Bu Dimension
        var buDimension = SetBuAggregateValueFromPlanArray(planData)
        var sumOfRegion = GetTotalTargetValueFromPlanArray(buDimension);
        var buDimensionArray = [];
        buDimension.map(currentItem => {

            let _buList = [...buList];
            var returnRecord = _buList.find(o => o.id === currentItem.buId);
            if (returnRecord != null && parseFloat(sumOfRegion) > 0) {
                let Percentage = (currentItem.targetValue * 100) / sumOfRegion;
                buDimensionArray.push({ "BU": returnRecord.name, "Percentage": Percentage, "DisplayPercentage": Percentage.toFixed(2) + '%' })
            }
            return currentItem;
        });
        setBuDimensionData(buDimensionArray)
        setBuDimensionFooterTotal(GetTotalPercentageFromPlanArray(buDimensionArray))

        //Get Region Dimension
        var regionDimension = SetRegionAggregateValueFromPlanArray(planData)
        var sumOfRegion = GetTotalTargetValueFromPlanArray(regionDimension)
        var regionDimensionArray = [];
        regionDimension.map(currentItem => {

            let _regionList = [...regionList];
            var returnRecord = _regionList.find(o => o.id === currentItem.regionId);
            if (returnRecord != null) {
                let Percentage = (currentItem.targetValue * 100) / sumOfRegion;
                regionDimensionArray.push({ "Region": returnRecord.name, "Percentage": Percentage, "DisplayPercentage": Percentage.toFixed(2) + '%' })
            }
            return currentItem;
        });
        setRegionDimensionData(regionDimensionArray)
        setRegionDimensionFooterTotal(GetTotalPercentageFromPlanArray(regionDimensionArray))



        //Get Country Dimension
        var countryDimension = SetCountryAggregateValueFromPlanArray(planData)
        var sumOfCountry = GetTotalTargetValueFromPlanArray(countryDimension)
        var countryDimensionArray = [];
        countryDimension.map(currentItem => {

            let _countryList = [...countryList];
            var returnRecord = _countryList.find(o => o.id === currentItem.countryId);
            if (returnRecord != null) {
                let Percentage = 100
                if (countryDimension.length > 1) {
                    Percentage = (currentItem.targetValue * 100) / sumOfCountry;
                }
                countryDimensionArray.push({ "Country": returnRecord.name, "Percentage": Percentage, "DisplayPercentage": Percentage.toFixed(2) + '%' })
            }

            return currentItem;
        });
        setCountryDimensionData(countryDimensionArray)
        setCountryDimensionFooterTotal(GetTotalPercentageFromPlanArray(countryDimensionArray))



        //Get Brand Dimension
        var brandDimension = SetBrandAggregateValueFromPlanArray(planData)
        var sumOfBrand = GetTotalTargetValueFromPlanArray(brandDimension)
        var brandDimensionArray = [];
        brandDimension.map(currentItem => {

            let _brandList = [...brandList];
            var returnRecord = _brandList.find(o => o.id === currentItem.brandId);
            if (returnRecord != null) {
                let Percentage = (currentItem.targetValue * 100) / sumOfBrand;
                brandDimensionArray.push({ "Brand": returnRecord.name, "Percentage": Percentage, "DisplayPercentage": Percentage.toFixed(2) + '%' })
            }
            return currentItem;
        });
        setBrandDimensionData(brandDimensionArray)
        setBrandDimensionFooterTotal(GetTotalPercentageFromPlanArray(brandDimensionArray))


        //Get Product Group Dimension
        var productGroupDimension = SetProductGroupAggregateValueFromPlanArray(planData)
        var sumOfPG = GetTotalTargetValueFromPlanArray(productGroupDimension)
        var pgDimensionArray = [];
        productGroupDimension.map(currentItem => {

            let _productGroupList = [...productGroupList];
            var returnRecord = _productGroupList.find(o => o.id === currentItem.productGroupId);
            if (returnRecord != null) {
                let Percentage = (currentItem.targetValue * 100) / sumOfPG;
                pgDimensionArray.push({ "ProductGroup": returnRecord.name, "Percentage": Percentage, "DisplayPercentage": Percentage.toFixed(2) + '%' })
            }
            return currentItem;
        });
        setProductGroupDimensionData(pgDimensionArray)
        setProductGroupDimensionFooterTotal(GetTotalPercentageFromPlanArray(pgDimensionArray))


        //Get Customer Segment Dimension
        var customerSegmentDimension = SetCustomerSegmentAggregateValueFromPlanArray(planData)
        var sumOfCustomerSegment = GetTotalTargetValueFromPlanArray(customerSegmentDimension)
        var customerSegmentDimensionArray = [];
        customerSegmentDimension.map(currentItem => {

            let _customerGroupList = [...customerGroupList];
            var returnRecord = _customerGroupList.find(o => o.id === currentItem.customerGroupId);
            if (returnRecord != null) {
                let Percentage = (currentItem.targetValue * 100) / sumOfCustomerSegment;
                customerSegmentDimensionArray.push({ "CustomerSegment": returnRecord.name, "Percentage": Percentage, "DisplayPercentage": Percentage.toFixed(2) + '%' })
            }
            return currentItem;
        });
        setCustomerSegmentDimensionData(customerSegmentDimensionArray)
        setCustomerSegmentDimensionFooterTotal(GetTotalPercentageFromPlanArray(customerSegmentDimensionArray))


        //Get Customer  Dimension
        var customerDimension = SetCustomerAggregateValueFromPlanArray(planData)
        var sumOfCustomer = GetTotalTargetValueFromPlanArray(customerDimension)
        var customerDimensionArray = [];
        customerDimension.map(currentItem => {

            let _customerList = [...customerList];
            var returnRecord = _customerList.find(o => o.id === currentItem.customerId);
            if (returnRecord != null) {
                let Percentage = (currentItem.targetValue * 100) / sumOfCustomer;
                customerDimensionArray.push({ "Customer": returnRecord.name, "Percentage": Percentage, "DisplayPercentage": Percentage.toFixed(2) + '%' })
            }
            return currentItem;
        });
        setCustomerDimensionData(customerDimensionArray)
        setCustomerDimensionFooterTotal(GetTotalPercentageFromPlanArray(customerDimensionArray))



    }


    //On Load Functions
    const [financialYear, setFinancialYear] = useState("");
    const [currentTargetId, setCurrentTargetId] = useState("");
    const [currentTargetDetailId, setCurrentTargetDetailId] = useState("");
    const [currentTargetAddedByAdmin, setCurrentTargetAddedByHRAdmin] = useState(false);

    const [parameterOriginalAnnualTarget, setParameterOriginalAnnualTarget] = useState("");
    const [parameterAnnualTarget, setParameterAnnualTarget] = useState("");
    const [requestRevisionValue, setRequestRevisionValue] = useState("");
    const [parameterMatchPer, setParameterMatchPer] = useState(0);
    const [parameterQ1Target, setParameterQ1Target] = useState(''); const [parameterQ1Percentage, setParameterQ1Percentage] = useState(0);
    const [parameterQ2Target, setParameterQ2Target] = useState(''); const [parameterQ2Percentage, setParameterQ2Percentage] = useState(0);
    const [parameterQ3Target, setParameterQ3Target] = useState(''); const [parameterQ3Percentage, setParameterQ3Percentage] = useState(0);
    const [parameterQ4Target, setParameterQ4Target] = useState(''); const [parameterQ4Percentage, setParameterQ4Percentage] = useState(0);

    const [parameterType, setParameterType] = useState("");
    const [requestType, setRequestType] = useState("");
    const [isPlanMandatory, setIsPlanMandatory] = useState(0);
    const [markAsComplete, setMarkAsComplete] = useState(false);
    const [isShowRequestForRevisionPopUp, setShowRequestForRevisionPopUp] = useState(0);


    const ChangeRequestType = (requestType) => {

        setRequestType(requestType);
        if (requestType === 'R') //Revision
        {
            setShowRequestForRevisionPopUp(1);
        }
        else  //Accept
        {
            setRequestRevisionValue('');
            setShowRequestForRevisionPopUp(0);
            setParameterAnnualTarget(parameterOriginalAnnualTarget);
            CalculateDimension();
        }

    }


    async function LoadPageData() {
        //Financial Year
        // const currentFinancialYear = await DataStore.query(FinancialYear, financialYearId);
        const currentFinancialYear = await graphQLFindRecordById('getFinancialYear', financialYearId);

        if (!currentFinancialYear) {
            toast.error('Invalid record.');
            return;
        }
        setFinancialYear(currentFinancialYear.name)


        //Parameter
        // const currentParameters = await DataStore.query(Parameters, parameterId);
        const currentParameters = await graphQLFindRecordById('getParameters', parameterId);

        if (!currentParameters) {
            toast.error('Invalid record.');
            return;
        }
        setParameterType(currentParameters.type);


        //Load All Parameters
        //---------------------

        var currentTarget = ""
        //Target
        // currentTarget = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.financial_year_id.eq(financialYearId),
        //     c.employee_id.eq(loggedEmployeeId),
        //     c.report_manager_employee_id.eq(loggedEmployeeId)
        // ]));

        var currentTarget = await graphQLGetAllData('listTargets', [
            { employee_id: { eq: loggedEmployeeId } },
            { report_manager_employee_id: { eq: loggedEmployeeId } },
            { financial_year_id: { eq: financialYearId } },
        ]);

        if (!currentTarget) {
            toast.error('Invalid record.');
            return;
        }
        var myTargetId = GetSingleValueFromResponse(currentTarget, 'id')
        setCurrentTargetId(myTargetId);

        //Check Assigned by HR Admin
        var isTargetAddedByHRAdmin = GetSingleValueFromResponse(currentTarget, 'isAddedByHRAdmin');
        setCurrentTargetAddedByHRAdmin(isTargetAddedByHRAdmin);

        //Target Details
        // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.target_id.eq(myTargetId));

        var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: myTargetId } }]);

        //Get Relationship
        // var currentRelationship = await DataStore.query(Relationship, (c) => c.and(c => [
        //     c.financial_year_id.eq(financialYearId),
        //     c.role_id.eq(loggedEmployeeRoleId)
        // ]));

        var currentRelationship = await graphQLGetAllData('listRelationships', [
            { financial_year_id: { eq: financialYearId } },
            { role_id: { eq: loggedEmployeeRoleId } }
        ]);

        var relationship_id = GetSingleValueFromResponse(currentRelationship, 'id');

        var _tempArray = []; let _tempInCompleteParamArray = [];
        currentTargetDetails.map(async (currentItem, currentIndex, currentArray) => {

            // const loopParameters = await DataStore.query(Parameters, currentItem.parameter_id);
            var loopParameters = await graphQLFindRecordById('getParameters', currentItem.parameter_id)

            if (currentItem.parameter_id === parameterId) {
                setCurrentTargetDetailId(currentItem.id);
                setParameterAnnualTarget(currentItem.target_value);
                setParameterOriginalAnnualTarget(currentItem.target_value)


                if (currentItem.isMarkAsComplete != null) {
                    setMarkAsComplete(currentItem.isMarkAsComplete);
                    if (currentItem.isMarkAsComplete === true) {
                        setIsDisabled(true);
                    }
                }

                if (currentItem.is_accepted_by_employee === true) { setRequestType('A'); }
                else if (currentItem.is_revision_by_employee === true) { setRequestType('R'); setRequestRevisionValue(currentItem.revision_value) }


                //Bind Plan
                //---------------------
                // await DataStore.query(Plan, (c) => c.target_detail_id.eq(currentItem.id))

                var existingPlanData = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: currentItem.id } }]);

                var existingFormatData = [];
                existingPlanData.map(async currentPlanItem => {
                    existingFormatData.push({
                        "id": currentPlanItem.id, "sbuId": currentPlanItem.sbu_id, "buId": currentPlanItem.bu_id, "regionId": currentPlanItem.region_id, "countryId": currentPlanItem.country_id,
                        "brandId": currentPlanItem.brand_id, "productGroupId": currentPlanItem.product_group_id,
                        "customerGroupId": currentPlanItem.customer_group_id, "customerId": currentPlanItem.customer_id, "targetValue": currentPlanItem.target_value,
                        "Q1TargetValue": currentPlanItem.q1_target_value, "Q2TargetValue": currentPlanItem.q2_target_value, "Q3TargetValue": currentPlanItem.q3_target_value,
                        "Q4TargetValue": currentPlanItem.q4_target_value, "disEqually": 0,
                        "currentM1TargetValue": currentPlanItem.m1_target_value, "currentM2TargetValue": currentPlanItem.m2_target_value, "currentM3TargetValue": currentPlanItem.m3_target_value,
                        "currentM4TargetValue": currentPlanItem.m4_target_value, "currentM5TargetValue": currentPlanItem.m5_target_value, "currentM6TargetValue": currentPlanItem.m6_target_value,
                        "currentM7TargetValue": currentPlanItem.m7_target_value, "currentM8TargetValue": currentPlanItem.m8_target_value, "currentM9TargetValue": currentPlanItem.m9_target_value,
                        "currentM10TargetValue": currentPlanItem.m10_target_value, "currentM11TargetValue": currentPlanItem.m11_target_value, "currentM12TargetValue": currentPlanItem.m12_target_value,
                    });
                });
                setPlanData(existingFormatData);
                setPageLoaded(true);
                CalculateDimension();

                //---------------------
            }

            //Now we are going to check is this parameter belongs to this role and financial year
            //------------------------------------
            var isPlanMandatory = 0;
            if (relationship_id !== '') {
                // var currentRelationshipDetails = await DataStore.query(RelationshipDetails, (c) => c.and(c => [
                //     c.relationship_id.eq(relationship_id),
                //     c.parameter_id.eq(currentItem.parameter_id)
                // ]));

                var currentRelationshipDetails = await graphQLGetAllData('listRelationshipDetails', [
                    { relationship_id: { eq: relationship_id } },
                    { parameter_id: { eq: currentItem.parameter_id } },
                ]);

                if (currentRelationshipDetails.length > 0) {
                    isPlanMandatory = 1;
                }
            }
            //------------------------------------

            //Incomplete Param
            if (currentItem.isMarkAsComplete == null || currentItem.isMarkAsComplete === false) {
                _tempInCompleteParamArray.push({ 'parameterName': loopParameters.name, 'status': 'Open' })
            }

            _tempArray.push({
                'parameterIndex': currentIndex, 'parameterId': currentItem.parameter_id, 'parameterName': loopParameters.name,
                'targetValue': loopParameters.type === PARAMETERS_TYPES.Amount ? ShowInMillion(currentItem.target_value) : currentItem.target_value,
                'parameterUnit': loopParameters.type, 'isPlanMandatory': isPlanMandatory
            })

            //Check for last Record
            if (_tempArray.length === currentArray.length) {
                _tempArray.sort(function (a, b) {
                    return a.parameterName.toLowerCase().localeCompare(b.parameterName.toLowerCase());
                });
                setParametersCarousel(_tempArray);

                _tempInCompleteParamArray.sort(function (a, b) {
                    return a.parameterName.toLowerCase().localeCompare(b.parameterName.toLowerCase());
                });
                setMarkAsIncompleteParameters(_tempInCompleteParamArray);

                //Set Previous 
                var currentPreviousParameter = GetArrayPreviousItemFromKeyValue(_tempArray, "parameterId", parameterId)
                if (currentPreviousParameter['parameterId'] != null) {
                    setPrevParameterId(currentPreviousParameter['parameterId'])
                }

                //Set Next 
                var currentNextParameter = GetArrayNextItemFromKeyValue(_tempArray, "parameterId", parameterId)
                if (currentNextParameter['parameterId'] != null) {
                    setNextParameterId(currentNextParameter['parameterId'])
                }

                //Set Selected Carousel Page
                var currentParameterArrayItem = GetArrayItemIndexFromKeyValue(_tempArray, "parameterId", parameterId);
                if (currentParameterArrayItem > 2) {
                    //var activePageNo = Math.floor((_tempArray.length + currentParameterArrayItem) / 3);
                    setCarouselActivePageNo(currentParameterArrayItem - 2);
                }
            }

            return currentItem;

        })
        //---------------------


    }

    async function BindList() {
        //SBU
        // var SBUResponses = await DataStore.query(SBU, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var SBUResponses = await graphQLGetAllData('listSBUS')
        SBUResponses = SBUResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetSbuList(SBUResponses)
        var myArray = []; myArray = ConvertResponseForSelect(SBUResponses); setSbuDropdownList(myArray);

        //Region
        // var regionResponses = await DataStore.query(Region, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var regionResponses = await graphQLGetAllData('listRegions')
        regionResponses = regionResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetRegionList(regionResponses)
        myArray = []; myArray = ConvertResponseForSelect(regionResponses); setRegionDropdownList(myArray);

        //Country
        // var countryResponses = await DataStore.query(Country, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var countryResponses = await graphQLGetAllData('listCountries')
        countryResponses = countryResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetCountryList(countryResponses)
        myArray = []; myArray = ConvertResponseForSelect(countryResponses); setCountryDropdownList(myArray);

        //Brand
        // var brandResponses = await DataStore.query(Brand, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var brandResponses = await graphQLGetAllData('listBrands')
        brandResponses = brandResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetBrandList(brandResponses)
        myArray = []; myArray = ConvertResponseForSelect(brandResponses); setBrandDropdownList(myArray);

        //Product Group
        // var pGResponses = await DataStore.query(ProductGroup, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var pGResponses = await graphQLGetAllData('listProductGroups')
        pGResponses = pGResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetProductGroupList(pGResponses)
        myArray = []; myArray = ConvertResponseForSelect(pGResponses); setProductGroupDropdownList(myArray);

        //Customer Group
        // var customerGroupResponses = await DataStore.query(CustomerCategory, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var customerGroupResponses = await graphQLGetAllData('listCustomerCategories')
        customerGroupResponses = customerGroupResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetCustomerGroupList(customerGroupResponses)
        myArray = []; myArray = ConvertResponseForSelect(customerGroupResponses); setCustomerGroupDropdownList(myArray);

        //Customer
        // var customerResponses = await DataStore.query(Customer, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var customerResponses = await graphQLGetAllData('listCustomers')
        customerResponses = customerResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetCustomerList(customerResponses)
        myArray = []; myArray = ConvertResponseForSelect(customerResponses); setCustomerDropdownList(myArray);

        //Bu
        // var buResponses = await DataStore.query(BIUnit, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var buResponses = await graphQLGetAllData('listBIUnits')
        buResponses = buResponses.sort((a, b) => a.name.localeCompare(b.name));
        SetBuList(buResponses)
        var myArray = []; myArray = ConvertResponseForSelect(buResponses); setBuDropdownList(myArray);

        //check Remarks enable & disable 
        // var currentEmployeeResponses = await DataStore.query(Employee, loggedEmployeeId);
        var currentEmployeeResponses = await graphQLFindRecordById('getEmployee', loggedEmployeeId);

        setIsShowRemark(currentEmployeeResponses.isAssignDirectTarget)

    }


    const UpdatePlan = async () => {
        let checkM1Target = planData.every((e) => e.currentM1TargetValue === "" || e.currentM1TargetValue === null)
        let checkM2Target = planData.every((e) => e.currentM2TargetValue === "" || e.currentM2TargetValue === null)
        let checkM3Target = planData.every((e) => e.currentM3TargetValue === "" || e.currentM3TargetValue === null)
        let checkM4Target = planData.every((e) => e.currentM4TargetValue == "" || e.currentM4TargetValue === null)
        let checkM5Target = planData.every((e) => e.currentM5TargetValue === "" || e.currentM5TargetValue === null)
        let checkM6Target = planData.every((e) => e.currentM6TargetValue == "" || e.currentM6TargetValue === null)
        let checkM7Target = planData.every((e) => e.currentM7TargetValue === "" || e.currentM7TargetValue === null)
        let checkM8Target = planData.every((e) => e.currentM8TargetValue === "" || e.currentM8TargetValue === null)
        let checkM9Target = planData.every((e) => e.currentM9TargetValue === "" || e.currentM9TargetValue === null)
        let checkM10Target = planData.every((e) => e.currentM10TargetValue === "" || e.currentM10TargetValue === null)
        let checkM11Target = planData.every((e) => e.currentM11TargetValue === "" || e.currentM11TargetValue === null)
        let checkM12Target = planData.every((e) => e.currentM12TargetValue === "" || e.currentM12TargetValue === null)


        //Update Plan
        if (checkM1Target || checkM2Target || checkM3Target) { toast.error('Please check Q1 monthly target.'); return; }
        else if (checkM4Target || checkM5Target || checkM6Target) { toast.error('Please check Q2 monthly target.'); return; }
        else if (checkM7Target || checkM8Target || checkM9Target) { toast.error('Please check Q3 monthly target.'); return; }
        else if (checkM10Target || checkM11Target || checkM12Target) { toast.error('Please check Q4 monthly target.'); return; }
        else {
            planData.forEach(async (plans) => {
                // let originalPlan = await DataStore.query(Plan, plans.id)
                let originalPlan = await graphQLFindRecordById('getPlan', plans.id);

                // await DataStore.save(Plan.copyOf(originalPlan, item => {
                //     item.m1_target_value = plans.currentM1TargetValue.toString();
                //     item.m2_target_value = plans.currentM2TargetValue.toString();
                //     item.m3_target_value = plans.currentM3TargetValue.toString();
                //     item.m4_target_value = plans.currentM4TargetValue.toString();
                //     item.m5_target_value = plans.currentM5TargetValue.toString();
                //     item.m6_target_value = plans.currentM6TargetValue.toString();
                //     item.m7_target_value = plans.currentM7TargetValue.toString();
                //     item.m8_target_value = plans.currentM8TargetValue.toString();
                //     item.m9_target_value = plans.currentM9TargetValue.toString();
                //     item.m10_target_value = plans.currentM10TargetValue.toString();
                //     item.m11_target_value = plans.currentM11TargetValue.toString();
                //     item.m12_target_value = plans.currentM12TargetValue.toString();
                // })).then(() => {
                //     toast.success("Saved.")
                // })

                await API.graphql({
                    query: mutations.updatePlan,
                    variables: {
                        input: {
                            id: originalPlan.id,
                            m1_target_value: plans.currentM1TargetValue.toString(),
                            m2_target_value: plans.currentM2TargetValue.toString(),
                            m3_target_value: plans.currentM3TargetValue.toString(),
                            m4_target_value: plans.currentM4TargetValue.toString(),
                            m5_target_value: plans.currentM5TargetValue.toString(),
                            m6_target_value: plans.currentM6TargetValue.toString(),
                            m7_target_value: plans.currentM7TargetValue.toString(),
                            m8_target_value: plans.currentM8TargetValue.toString(),
                            m9_target_value: plans.currentM9TargetValue.toString(),
                            m10_target_value: plans.currentM10TargetValue.toString(),
                            m11_target_value: plans.currentM11TargetValue.toString(),
                            m12_target_value: plans.currentM12TargetValue.toString(),
                            _version: originalPlan._version,
                        }
                    }
                });

            })
        }
    }

    const ShowPreviousParameter = () => {
        if (prevParameterId !== '') {
            var navigateURL = "/employee/planning/team/managecommit/annualtarget/" + financialYearId + "/" + prevParameterId;
            navigateTo(navigateURL);
            navigateTo(0);
        }
    }

    const ShowNextParameter = async () => {
        if (nextParameterId === "") //Show Confirmation Popup
        {
            if (markAsIncompleteParameters.length > 0) //Save All Targets
            {
                setIsShowConfirmationPopUp(1); //Show Popup..
            }
            else {
                alertify.confirm('Confirmation', 'Do you want to submit?', async function () {
                    //Set as all completed 
                    if (currentTargetAddedByAdmin === true) //Admin Assign auto approve
                    {
                        //Target Details
                        // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.target_id.eq(currentTargetId));

                        var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: currentTargetId } }]);

                        currentTargetDetails.map(async (currentItem) => {
                            // await DataStore.save(TargetDetails.copyOf(currentItem, item => {
                            //     item.is_approve = true;
                            //     item.approved_date = moment().format();
                            // }));
                            await API.graphql({
                                query: mutations.updateTargetDetails,
                                variables: {
                                    input: {
                                        id: currentItem.id,
                                        is_approve: true,
                                        approved_date: moment().format(),
                                        _version: currentItem._version,
                                    }
                                }
                            });

                        });
                    }

                    //Update status for employee submitted
                    // var currentTarget = await DataStore.query(Targets, currentTargetId);
                    var currentTarget = await graphQLFindRecordById('getTargets', currentTargetId);

                    // await DataStore.save(Targets.copyOf(currentTarget, item => {
                    //     item.isSubmittedByEmployee = true;
                    // }));

                    await API.graphql({
                        query: mutations.updateTargets,
                        variables: {
                            input: {
                                id: currentTarget.id,
                                isSubmittedByEmployee : true,
                                _version: currentTarget._version,
                            }
                        }
                    });

                    toast.success('Saved')
                    var navigateURL = "/employee/planning/team/managecommit/";
                    navigateTo(navigateURL);
                    navigateTo(0);
                    //Show popup - all parm with target value
                }
                    , function () { });
            }
        }
        else {
            var navigateURL = "/employee/planning/team/managecommit/annualtarget/" + financialYearId + "/" + nextParameterId;
            navigateTo(navigateURL);
            navigateTo(0);
        }

    }


    //Parameters Carousel
    var parametersCarouselIndex = 0;
    const parametersCarouselTemplate = (currentRequestParameterList) => {
        parametersCarouselIndex++;

        return (
            <div className="product-item">
                <div className="product-item-content relative">
                    {
                        <div key={currentRequestParameterList['parameterId']}>

                            <div className={`annual_target_box justify-between items-center rounded-lg p-3 content-center flex ${currentRequestParameterList['parameterId'] === parameterId ? "activegreen" : "bg-[#fff] dark:bg-[#212529] dark:border-[#2A2E32]"}`} >
                                <div className='flex-col'>
                                    <div className='flex items-center gap-3'>
                                        <div className={`rounded-lg p-2 border border-dashed ${currentRequestParameterList['parameterId'] === parameterId ? "bg-white border-[#4FB155]" : "bg-[#EDF7EE] border-[#4FB155]"}`} >
                                            <img src={AnnualSaleIcon} alt=""></img>
                                        </div>
                                        <div>
                                            <h6 className={`${currentRequestParameterList['parameterId'] === parameterId ? "text-white text-[15px] text-left" : "text-[#667085] text-[15px] font-light text-left"}`} >{currentRequestParameterList['parameterName']}</h6>
                                            <h2 className={`${currentRequestParameterList['parameterId'] === parameterId ? "text-white text-[22px] font-medium text-left" : "text-[#101828] text-[22px] font-medium dark:text-white text-left"}`} >

                                                {currentRequestParameterList['parameterUnit'] === 'A' ? PARAMETERS_TYPES_NAMES[currentRequestParameterList['parameterUnit']] : ""}{currentRequestParameterList['targetValue']}{currentRequestParameterList['parameterUnit'] !== 'A' ? PARAMETERS_TYPES_NAMES[currentRequestParameterList['parameterUnit']] : ""}
                                            </h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="">
                                    <div className="">
                                        {
                                            currentRequestParameterList['parameterId'] === parameterId ?
                                                <div className="annual_target_img_green"></div>
                                                :
                                                <div className="annual_target_img_green_regular"></div>
                                        }
                                    </div>
                                    <div
                                        className="absolute top-3 right-3  font-medium text-center text-[#fff]">{currentRequestParameterList['isPlanMandatory'] === 1 ? <i className='icon s_icon'></i> : <i className='icon t_icon'></i>}</div>
                                </div>
                            </div>
                        </div>

                    }

                </div>
            </div>
        );
    }

    useEffect(() => {
        onLoad();
        BindList();
        LoadPageData();
    }, [isPageLoaded, carouselActivePageNo]);



    // Q1 Header
    const [showQ1Months, setShowQ1Months] = useState(false);
    const Q1Header = () => {
        return (
            <div className='flex items-center justify-between'>
                <label>Q1</label>
                {
                    showQ1Months ?
                        <Link className='ml-3' onClick={() => setShowQ1Months(false)} >
                            <MinusButton />
                        </Link> :
                        <Link className='ml-3' onClick={() => setShowQ1Months(true)} >
                            <AddButton />
                        </Link>
                }
            </div>
        )
    }

    // Q2 Header
    const [showQ2Months, setShowQ2Months] = useState(false);
    const Q2Header = () => {
        return (
            <div className='flex items-center justify-between'>
                <label>Q2</label>
                {
                    showQ2Months ?
                        <Link className='ml-3' onClick={() => setShowQ2Months(false)} >
                            <MinusButton />
                        </Link> :
                        <Link className='ml-3' onClick={() => setShowQ2Months(true)} >
                            <AddButton />
                        </Link>
                }
            </div>
        )
    }

    // Q3 Header
    const [showQ3Months, setShowQ3Months] = useState(false);
    const Q3Header = () => {
        return (
            <div className='flex items-center justify-between'>
                <label>Q3</label>
                {
                    showQ3Months ?
                        <Link className='ml-3' onClick={() => setShowQ3Months(false)} >
                            <MinusButton />
                        </Link> :
                        <Link className='ml-3' onClick={() => setShowQ3Months(true)} >
                            <AddButton />
                        </Link>
                }
            </div>
        )
    }

    // Q4 Header
    const [showQ4Months, setShowQ4Months] = useState(false);
    const Q4Header = () => {
        return (
            <div className='flex items-center justify-between'>
                <label>Q4</label>
                {
                    showQ4Months ?
                        <Link className='ml-3' onClick={() => setShowQ4Months(false)} >
                            <MinusButton />
                        </Link> :
                        <Link className='ml-3' onClick={() => setShowQ4Months(true)} >
                            <AddButton />
                        </Link>
                }
            </div>
        )
    }

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNav />
            <LeftNav />

            <div className="target-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="flex justify-between">
                        <div className="sm:flex items-center space-x-4">
                            <Link to={"/employee/planning/team/managecommit/"} className="text-[#344054] text-[14px] dark:text-white py-1.5 px-3 rounded-md border border-[#D0D5DD]"><i className="icon arrow_left_double mr-6 "></i>Back</Link>
                            <div className="text-2xl font-semibold dark:text-white"> Annual Target</div>
                            <div className="text-xs border text-[#667085] border-[#667085] rounded p-1">FY: {financialYear}</div>
                        </div>
                        <div className="mt-5 lg:mt-0 flex items-center gap-4">

                        </div>
                    </div>

                    <div className="col-span-9 mt-1 silder_managetarget">
                        <div className="carousel-demo">
                            <div className="card">
                                <Carousel value={parametersCarousel} page={carouselActivePageNo} numVisible={3} numScroll={1} responsiveOptions={parametersCarouselResponsiveOptions}
                                    itemTemplate={parametersCarouselTemplate} />
                            </div>
                        </div>
                    </div>

                    {requestType !== '' ?
                        <div>

                            <div className="bg-white rounded-lg mt-2 border border-[#E4E7EC] p-4 px-3 dark:bg-[#2A2E32] dark:border-[#2A2E32]">
                                <div className="grid grid-cols-8 lg:grid-cols-10 2xl:grid-cols-10 flex flex-1 content-center items-center gap-3">
                                    <div className="col-span-10">
                                        <div className="grid grid-cols-5 lg:grid-cols-5 2xl:grid-cols-5 gap-3 h-auto">
                                            <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                                                <div className='flex justify-between items-center gap-3'>
                                                    <div className="bg-white rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                        <img src={AnnualSaleIcon} alt=""></img>
                                                    </div>
                                                    <div>
                                                        <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]mb-2">{requestType === 'R' ? "Request Annual Target" : "Annual Target"}</h6>
                                                        <div className='flex gap-3'>

                                                            <h2 className="text-[#101828] text-[22px] font-medium dark:text-[#FFFFFF]">

                                                                {parameterType === 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                                {commaSeparated(parseFloat(parameterAnnualTarget).toFixed(2))}
                                                                {parameterType !== 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}

                                                            </h2>
                                                            {
                                                                parameterType === 'A' ?
                                                                    parameterMatchPer < 100 ? <div className='bg-[#FEE4E2] py-1.5 px-2 rounded-md text-[#FF4537]'>{parameterMatchPer}%</div> : <div className='bg-[#029046] py-1.5 px-2 rounded-md text-[#FFF]'>{parameterMatchPer}%</div>
                                                                    : <div className='bg-[#fff] py-1.5 px-2 rounded-md text-[#029046]'>N/A</div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                                                <div className='flex justify-between items-center gap-3'>
                                                    <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                        <img src={quarterIcon} alt=""></img>
                                                    </div>
                                                    <div>
                                                        <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">Quarter 1</h6>
                                                        <div className='flex gap-3'>
                                                            <h2 className="text-[#101828] text-[22px] font-medium dark:text-[#FFFFFF]">
                                                                {parameterType === 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                                {commaSeparated(parameterQ1Target)}
                                                                {parameterType !== 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                            </h2>
                                                            <div className='bg-[#fff] py-1.5 px-2 rounded-md text-[#029046]'>{parameterType === 'A' ? parameterQ1Percentage : ""}{parameterType === 'A' ? "%" : "N/A"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                                                <div className='flex justify-between items-center gap-3'>
                                                    <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                        <img src={quarterIcon} alt=""></img>
                                                    </div>
                                                    <div>
                                                        <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">Quarter 2</h6>
                                                        <div className='flex gap-3'>
                                                            <h2 className="text-[#101828] text-[22px] font-medium dark:text-[#FFFFFF]">
                                                                {parameterType === 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                                {commaSeparated(parameterQ2Target)}
                                                                {parameterType !== 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                            </h2>
                                                            <div className='bg-[#fff] py-1.5 px-2 rounded-md text-[#029046]'>{parameterType === 'A' ? parameterQ2Percentage : ""}{parameterType === 'A' ? "%" : "N/A"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                                                <div className='flex justify-between items-center gap-3'>
                                                    <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                        <img src={quarterIcon} alt=""></img>
                                                    </div>
                                                    <div>
                                                        <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">Quarter 3</h6>
                                                        <div className='flex gap-3'>
                                                            <h2 className="text-[#101828] text-[22px] font-medium dark:text-[#FFFFFF]">
                                                                {parameterType === 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                                {commaSeparated(parameterQ3Target)}
                                                                {parameterType !== 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                            </h2>
                                                            <div className='bg-[#fff] py-1.5 px-2 rounded-md text-[#029046]'>{parameterType === 'A' ? parameterQ3Percentage : ""}{parameterType === 'A' ? "%" : "N/A"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex  dark:bg-[#212529] dark:border-[#2A2E32]">
                                                <div className='flex justify-between items-center gap-3'>
                                                    <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                        <img src={quarterIcon} alt=""></img>
                                                    </div>
                                                    <div>
                                                        <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">Quarter 4</h6>
                                                        <div className='flex gap-3'>
                                                            <h2 className="text-[#101828] text-[22px] font-medium dark:text-[#FFFFFF]">
                                                                {parameterType === 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                                {commaSeparated(parameterQ4Target)}
                                                                {parameterType !== 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                            </h2>
                                                            <div className='bg-[#fff] py-1.5 px-2 rounded-md text-[#029046]'>{parameterType === 'A' ? parameterQ4Percentage : ""}{parameterType === 'A' ? "%" : "N/A"}</div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='my-5 accordionsingle'>
                                <Accordion activeIndex={1}>
                                    <AccordionTab header="Dimensions">
                                        <div className='grid grid-cols-6 lg:grid-cols-6 2xl:grid-cols-6 flex flex-1 content-start items-start gap-2'>
                                            <DataTable value={buDimensionData} scrollable scrollHeight="flex" resizableColumns columnResizeMode="fit" emptyMessage="&nbsp;" >
                                                <Column field="BU" sortable header="BU" footer="Total" ></Column>
                                                <Column field="DisplayPercentage" header="" footer={buDimensionFooterTotal} align="right" ></Column>
                                            </DataTable>
                                            <DataTable value={regionDimensionData} scrollable scrollHeight="flex" resizableColumns columnResizeMode="fit" emptyMessage="&nbsp;" >
                                                <Column field="Region" sortable header="Region" footer="Total" ></Column>
                                                <Column field="DisplayPercentage" header="" footer={regionDimensionFooterTotal} align="right" ></Column>
                                            </DataTable>
                                            <DataTable value={countryDimensionData} scrollable scrollHeight="flex" resizableColumns columnResizeMode="fit" emptyMessage="&nbsp;">
                                                <Column field="Country" sortable header="Country" footer="Total" ></Column>
                                                <Column field="DisplayPercentage" footer={countryDimensionFooterTotal} align="right"  ></Column>
                                            </DataTable>
                                            <DataTable value={brandDimensionData} scrollable scrollHeight="flex" resizableColumns emptyMessage="&nbsp;" >
                                                <Column field="Brand" sortable header="Brand" footer="Total" ></Column>
                                                <Column field="DisplayPercentage" header="" footer={brandDimensionFooterTotal} align="right"  ></Column>
                                            </DataTable>
                                            <DataTable value={productGroupDimensionData} scrollable scrollHeight="flex" resizableColumns columnResizeMode="fit" emptyMessage="&nbsp;">
                                                <Column field="ProductGroup" sortable header="Product Group" footer="Total" ></Column>
                                                <Column field="DisplayPercentage" header="" footer={productGroupDimensionFooterTotal} align="right"  ></Column>
                                            </DataTable>
                                            <DataTable value={customerSegmentDimensionData} scrollable scrollHeight="flex" resizableColumns columnResizeMode="fit" emptyMessage="&nbsp;" >
                                                <Column field="CustomerSegment" sortable header="Cust. Group" footer="Total" ></Column>
                                                <Column field="DisplayPercentage" header="" footer={customerSegmentDimensionFooterTotal} align="right"  ></Column>
                                            </DataTable>
                                            <DataTable value={customerDimensionData} scrollable scrollHeight="flex" resizableColumns columnResizeMode="fit" emptyMessage="&nbsp;">
                                                <Column field="Customer" sortable header="Customer" footer="Total" ></Column>
                                                <Column field="DisplayPercentage" header="" footer={customerDimensionFooterTotal} align="right"  ></Column>
                                            </DataTable>

                                        </div>
                                    </AccordionTab>
                                </Accordion>
                            </div>


                            <div className="bg-white rounded-lg mt-5 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                                <div className="dashboard-main-wrapper rounded-lg mt-5 divSmallTableControls ">
                                    <React.Fragment>
                                        <DataTable value={planData} onLoadedData={CalculateDimension} showGridlines editMode="cell" editable="true" responsiveLayout="scroll">
                                            <Column field="buId" onCellEditComplete={onCellEditComplete} header="BU" body={buBodyTemplate} style={{ width: '8%' }}></Column>
                                            <Column field="regionId" onCellEditComplete={onCellEditComplete} header="Region" body={regionBodyTemplate} style={{ width: '8%' }}></Column>
                                            <Column field="countryId" onCellEditComplete={onCellEditComplete} header="Country" body={countryBodyTemplate} style={{ width: '8%' }}></Column>
                                            <Column field="brandId" onCellEditComplete={onCellEditComplete} header="Brand" body={brandBodyTemplate} style={{ width: '8%' }}></Column>
                                            <Column field="productGroupId" onCellEditComplete={onCellEditComplete} header="Product Group" body={productGroupBodyTemplate} style={{ width: '8%' }}></Column>
                                            <Column field="customerGroupId" onCellEditComplete={onCellEditComplete} header="Cust. Group" body={customerGroupBodyTemplate} style={{ width: '8%' }}></Column>
                                            <Column field="customerId" onCellEditComplete={onCellEditComplete} header="Customer" body={customerBodyTemplate} style={{ width: '8%' }}></Column>

                                            <Column field="targetValue" header="Annual Target" style={{ width: '8%' }}></Column>

                                            <Column field="Q1TargetValue" header={Q1Header} style={{ width: '5%' }}></Column>
                                            {
                                                showQ1Months &&
                                                <Column field="currentM1TargetValue" onCellEditComplete={onCellEditComplete} header="Apr" editor={(options) => targetValueEditor(options)} style={{ width: '5%' }}></Column>
                                            }
                                            {
                                                showQ1Months &&
                                                <Column field="currentM2TargetValue" onCellEditComplete={onCellEditComplete} header="May" editor={(options) => targetValueEditor(options)} style={{ width: '5%' }}></Column>
                                            }
                                            {
                                                showQ1Months &&
                                                <Column field="currentM3TargetValue" onCellEditComplete={onCellEditComplete} header="Jun" editor={(options) => targetValueEditor(options)} style={{ width: '5%' }}></Column>
                                            }


                                            <Column field="Q2TargetValue" header={Q2Header} style={{ width: '5%' }}></Column>
                                            {
                                                showQ2Months &&
                                                <Column field="currentM4TargetValue" onCellEditComplete={onCellEditComplete} header="Jul" editor={(options) => targetValueEditor(options)} style={{ width: '5%' }}></Column>
                                            }
                                            {
                                                showQ2Months &&
                                                <Column field="currentM5TargetValue" onCellEditComplete={onCellEditComplete} header="Aug" editor={(options) => targetValueEditor(options)} style={{ width: '5%' }}></Column>
                                            }
                                            {
                                                showQ2Months &&
                                                <Column field="currentM6TargetValue" onCellEditComplete={onCellEditComplete} header="Sept" editor={(options) => targetValueEditor(options)} style={{ width: '5%' }}></Column>
                                            }



                                            <Column field="Q3TargetValue" header={Q3Header} style={{ width: '5%' }}></Column>
                                            {
                                                showQ3Months &&
                                                <Column field="currentM7TargetValue" onCellEditComplete={onCellEditComplete} header="Oct" editor={(options) => targetValueEditor(options)} style={{ width: '5%' }}></Column>
                                            }
                                            {
                                                showQ3Months &&
                                                <Column field="currentM8TargetValue" onCellEditComplete={onCellEditComplete} header="Nov" editor={(options) => targetValueEditor(options)} style={{ width: '5%' }}></Column>
                                            }
                                            {
                                                showQ3Months &&
                                                <Column field="currentM9TargetValue" onCellEditComplete={onCellEditComplete} header="Dec" editor={(options) => targetValueEditor(options)} style={{ width: '5%' }}></Column>
                                            }

                                            <Column field="Q4TargetValue" header={Q4Header} style={{ width: '5%' }}></Column>
                                            {
                                                showQ4Months &&
                                                <Column field="currentM10TargetValue" onCellEditComplete={onCellEditComplete} header="Jan" editor={(options) => targetValueEditor(options)} style={{ width: '5%' }}></Column>
                                            }
                                            {
                                                showQ4Months &&
                                                <Column field="currentM11TargetValue" onCellEditComplete={onCellEditComplete} header="Feb" editor={(options) => targetValueEditor(options)} style={{ width: '5%' }}></Column>
                                            }
                                            {
                                                showQ4Months &&
                                                <Column field="currentM12TargetValue" onCellEditComplete={onCellEditComplete} header="Mar" editor={(options) => targetValueEditor(options)} style={{ width: '5%' }}></Column>
                                            }
                                        </DataTable>
                                    </React.Fragment>


                                </div>
                            </div>
                        </div>
                        : ""}

                    <div className="flex flex-1 justify-end gap-3 mt-5">
                        <div>
                            {
                                planData.length > 0 ?
                                    <React.Fragment>
                                        <Link onClick={() => UpdatePlan()} className="py-3 px-4 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]">Save</Link>
                                    </React.Fragment>
                                    : ""
                            }
                        </div>
                    </div>

                    <div className="flex flex-1 justify-between gap-3 mt-7 mb-5 ">
                        <div>
                            <Link onClick={() => ShowPreviousParameter()} className="py-3 px-4 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">Previous</Link>
                        </div>

                        <div className="flex flex-1 justify-end gap-3">
                            <div><Link onClick={() => ShowNextParameter()} className="py-3 px-4 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">{nextParameterId === '' ? "Submit" : "Next"}</Link></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}