import './App.css';
import './proConnect.css';
import RouteComponent from './setup/route.component';
import { ToastContainer} from 'react-toastify';
import { useSelector } from 'react-redux'; 
import NoInternetConnection from './components/common/NoInternectConnection';

function App() {
  const currentThemeValue = useSelector((state) => state.currentTheme.value);
  const showCurrentTheme = currentThemeValue ==="D" ? "dark" : "light"; 
  return (
    <>
    <NoInternetConnection>
      <RouteComponent />
      <ToastContainer theme={showCurrentTheme} autoClose={1000}  />
    </NoInternetConnection>
    </>
 
  );
}

export default App;
