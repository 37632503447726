

//*calculate Target Total
const calculateTargetTotal = async (array) => {
    var value = 0
    var finalValue = 0
    for (let i = 0; i < array.length; i++) {
        if (array[i] !== "NaNM" && array[i] !== NaN) {
            if (Number.isInteger(array[i])) {
                value = Number(array[i])
            } else {
                let finalNumber = ""
                const newNumber = array[i].replace("M", "");
                if (Number.isInteger(Number.parseFloat(newNumber))) {
                    finalNumber = Number(newNumber)
                } else {
                    const millionToNumberValue = parseFloat(newNumber) * 1000000;
                    finalNumber = Number(millionToNumberValue)
                }
                value = finalNumber
            }
            finalValue = finalValue + value
        }
    }
    return finalValue
}

//*calculate Consolidated Score
// console.log("target value: ", targetValue)
    // console.log("target value type: ", typeof targetValue)
    // console.log("actual value: ", actualValue)
    // console.log("actual value type: " , typeof actualValue)
    // console.log("consolidated score: ", ConsolidatedScore)
    // console.log("Consolidated Score type: ", typeof ConsolidatedScore)
const calculateConsolidatedScore = async (actualValue, targetValue) => {
    if(targetValue==0){
        let ConsolidatedScore =0;
        return ConsolidatedScore;
    }
    else{
    let ConsolidatedScore = (parseFloat(actualValue) / parseFloat(targetValue)) * 100
    return parseFloat(ConsolidatedScore).toFixed(2)
    }
}

const calculateWeightageAchievement = async (actualValue, targetValue) => {
    let ConsolidatedScore = ((parseFloat(actualValue) / 100) * (parseFloat(targetValue) / 100) * 100)
    return parseFloat(ConsolidatedScore).toFixed(2)
}

const calculatePercentage = async (valueOne, valueTwo) => {
    let totalValue = (valueOne / valueTwo * 100)
    return Math.round(Number(totalValue))
}


const checkIfNegativeValue = async (value) => {
    if (parseFloat(value) < 0) { return "(" + Math.abs(value) + ")"} 
    else { return Math.round(value) }
}


export { calculateTargetTotal, calculateConsolidatedScore, calculateWeightageAchievement, calculatePercentage, checkIfNegativeValue }
