/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNWCDaysUploadLogs = /* GraphQL */ `
  mutation CreateNWCDaysUploadLogs(
    $input: CreateNWCDaysUploadLogsInput!
    $condition: ModelNWCDaysUploadLogsConditionInput
  ) {
    createNWCDaysUploadLogs(input: $input, condition: $condition) {
      id
      financial_year_id
      quarterNo
      last_Updated_Date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNWCDaysUploadLogs = /* GraphQL */ `
  mutation UpdateNWCDaysUploadLogs(
    $input: UpdateNWCDaysUploadLogsInput!
    $condition: ModelNWCDaysUploadLogsConditionInput
  ) {
    updateNWCDaysUploadLogs(input: $input, condition: $condition) {
      id
      financial_year_id
      quarterNo
      last_Updated_Date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNWCDaysUploadLogs = /* GraphQL */ `
  mutation DeleteNWCDaysUploadLogs(
    $input: DeleteNWCDaysUploadLogsInput!
    $condition: ModelNWCDaysUploadLogsConditionInput
  ) {
    deleteNWCDaysUploadLogs(input: $input, condition: $condition) {
      id
      financial_year_id
      quarterNo
      last_Updated_Date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSubGTM = /* GraphQL */ `
  mutation CreateSubGTM(
    $input: CreateSubGTMInput!
    $condition: ModelSubGTMConditionInput
  ) {
    createSubGTM(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      gtm_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSubGTM = /* GraphQL */ `
  mutation UpdateSubGTM(
    $input: UpdateSubGTMInput!
    $condition: ModelSubGTMConditionInput
  ) {
    updateSubGTM(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      gtm_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSubGTM = /* GraphQL */ `
  mutation DeleteSubGTM(
    $input: DeleteSubGTMInput!
    $condition: ModelSubGTMConditionInput
  ) {
    deleteSubGTM(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      gtm_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createActualDetails = /* GraphQL */ `
  mutation CreateActualDetails(
    $input: CreateActualDetailsInput!
    $condition: ModelActualDetailsConditionInput
  ) {
    createActualDetails(input: $input, condition: $condition) {
      id
      target_details_id
      financial_year_id
      monthNo
      parameter_id
      SBU
      BU
      region
      country
      brand
      productGroup
      profitCenter
      customerGroupCode
      customerCode
      actual_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateActualDetails = /* GraphQL */ `
  mutation UpdateActualDetails(
    $input: UpdateActualDetailsInput!
    $condition: ModelActualDetailsConditionInput
  ) {
    updateActualDetails(input: $input, condition: $condition) {
      id
      target_details_id
      financial_year_id
      monthNo
      parameter_id
      SBU
      BU
      region
      country
      brand
      productGroup
      profitCenter
      customerGroupCode
      customerCode
      actual_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteActualDetails = /* GraphQL */ `
  mutation DeleteActualDetails(
    $input: DeleteActualDetailsInput!
    $condition: ModelActualDetailsConditionInput
  ) {
    deleteActualDetails(input: $input, condition: $condition) {
      id
      target_details_id
      financial_year_id
      monthNo
      parameter_id
      SBU
      BU
      region
      country
      brand
      productGroup
      profitCenter
      customerGroupCode
      customerCode
      actual_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGTM = /* GraphQL */ `
  mutation CreateGTM(
    $input: CreateGTMInput!
    $condition: ModelGTMConditionInput
  ) {
    createGTM(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGTM = /* GraphQL */ `
  mutation UpdateGTM(
    $input: UpdateGTMInput!
    $condition: ModelGTMConditionInput
  ) {
    updateGTM(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGTM = /* GraphQL */ `
  mutation DeleteGTM(
    $input: DeleteGTMInput!
    $condition: ModelGTMConditionInput
  ) {
    deleteGTM(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createChangeRequestFiles = /* GraphQL */ `
  mutation CreateChangeRequestFiles(
    $input: CreateChangeRequestFilesInput!
    $condition: ModelChangeRequestFilesConditionInput
  ) {
    createChangeRequestFiles(input: $input, condition: $condition) {
      id
      change_request_id
      upload_type
      fileName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChangeRequestFiles = /* GraphQL */ `
  mutation UpdateChangeRequestFiles(
    $input: UpdateChangeRequestFilesInput!
    $condition: ModelChangeRequestFilesConditionInput
  ) {
    updateChangeRequestFiles(input: $input, condition: $condition) {
      id
      change_request_id
      upload_type
      fileName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChangeRequestFiles = /* GraphQL */ `
  mutation DeleteChangeRequestFiles(
    $input: DeleteChangeRequestFilesInput!
    $condition: ModelChangeRequestFilesConditionInput
  ) {
    deleteChangeRequestFiles(input: $input, condition: $condition) {
      id
      change_request_id
      upload_type
      fileName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMaterials = /* GraphQL */ `
  mutation CreateMaterials(
    $input: CreateMaterialsInput!
    $condition: ModelMaterialsConditionInput
  ) {
    createMaterials(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMaterials = /* GraphQL */ `
  mutation UpdateMaterials(
    $input: UpdateMaterialsInput!
    $condition: ModelMaterialsConditionInput
  ) {
    updateMaterials(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMaterials = /* GraphQL */ `
  mutation DeleteMaterials(
    $input: DeleteMaterialsInput!
    $condition: ModelMaterialsConditionInput
  ) {
    deleteMaterials(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCustomerSegment = /* GraphQL */ `
  mutation CreateCustomerSegment(
    $input: CreateCustomerSegmentInput!
    $condition: ModelCustomerSegmentConditionInput
  ) {
    createCustomerSegment(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCustomerSegment = /* GraphQL */ `
  mutation UpdateCustomerSegment(
    $input: UpdateCustomerSegmentInput!
    $condition: ModelCustomerSegmentConditionInput
  ) {
    updateCustomerSegment(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCustomerSegment = /* GraphQL */ `
  mutation DeleteCustomerSegment(
    $input: DeleteCustomerSegmentInput!
    $condition: ModelCustomerSegmentConditionInput
  ) {
    deleteCustomerSegment(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInitiateEvaluation = /* GraphQL */ `
  mutation CreateInitiateEvaluation(
    $input: CreateInitiateEvaluationInput!
    $condition: ModelInitiateEvaluationConditionInput
  ) {
    createInitiateEvaluation(input: $input, condition: $condition) {
      id
      evaluation_id
      employee_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInitiateEvaluation = /* GraphQL */ `
  mutation UpdateInitiateEvaluation(
    $input: UpdateInitiateEvaluationInput!
    $condition: ModelInitiateEvaluationConditionInput
  ) {
    updateInitiateEvaluation(input: $input, condition: $condition) {
      id
      evaluation_id
      employee_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInitiateEvaluation = /* GraphQL */ `
  mutation DeleteInitiateEvaluation(
    $input: DeleteInitiateEvaluationInput!
    $condition: ModelInitiateEvaluationConditionInput
  ) {
    deleteInitiateEvaluation(input: $input, condition: $condition) {
      id
      evaluation_id
      employee_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInitiatePlanning = /* GraphQL */ `
  mutation CreateInitiatePlanning(
    $input: CreateInitiatePlanningInput!
    $condition: ModelInitiatePlanningConditionInput
  ) {
    createInitiatePlanning(input: $input, condition: $condition) {
      id
      planning_id
      employee_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInitiatePlanning = /* GraphQL */ `
  mutation UpdateInitiatePlanning(
    $input: UpdateInitiatePlanningInput!
    $condition: ModelInitiatePlanningConditionInput
  ) {
    updateInitiatePlanning(input: $input, condition: $condition) {
      id
      planning_id
      employee_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInitiatePlanning = /* GraphQL */ `
  mutation DeleteInitiatePlanning(
    $input: DeleteInitiatePlanningInput!
    $condition: ModelInitiatePlanningConditionInput
  ) {
    deleteInitiatePlanning(input: $input, condition: $condition) {
      id
      planning_id
      employee_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEmployeeParameters = /* GraphQL */ `
  mutation CreateEmployeeParameters(
    $input: CreateEmployeeParametersInput!
    $condition: ModelEmployeeParametersConditionInput
  ) {
    createEmployeeParameters(input: $input, condition: $condition) {
      id
      employee_id
      company_id
      profile_center_id
      plant_storage_location_id
      division_id
      customer_group_id
      customer_id
      country_id
      region_id
      company_REF_CODE
      profit_center_REF_CODE
      division_REF_CODE
      customer_group_REF_CODE
      customer_REF_CODE
      country_REF_CODE
      region_REF_CODE
      plan_storage_location_REF_CODE
      sbu_REF_CODE
      customer_segment_REF_CODE
      product_group_REF_CODE
      brand_REF_CODE
      material_id
      material_REF_CODE
      bu_id
      bu_REF_CODE
      gtm_id
      gtm_REF_CODE
      sub_gtm_id
      sub_gtm_REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEmployeeParameters = /* GraphQL */ `
  mutation UpdateEmployeeParameters(
    $input: UpdateEmployeeParametersInput!
    $condition: ModelEmployeeParametersConditionInput
  ) {
    updateEmployeeParameters(input: $input, condition: $condition) {
      id
      employee_id
      company_id
      profile_center_id
      plant_storage_location_id
      division_id
      customer_group_id
      customer_id
      country_id
      region_id
      company_REF_CODE
      profit_center_REF_CODE
      division_REF_CODE
      customer_group_REF_CODE
      customer_REF_CODE
      country_REF_CODE
      region_REF_CODE
      plan_storage_location_REF_CODE
      sbu_REF_CODE
      customer_segment_REF_CODE
      product_group_REF_CODE
      brand_REF_CODE
      material_id
      material_REF_CODE
      bu_id
      bu_REF_CODE
      gtm_id
      gtm_REF_CODE
      sub_gtm_id
      sub_gtm_REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEmployeeParameters = /* GraphQL */ `
  mutation DeleteEmployeeParameters(
    $input: DeleteEmployeeParametersInput!
    $condition: ModelEmployeeParametersConditionInput
  ) {
    deleteEmployeeParameters(input: $input, condition: $condition) {
      id
      employee_id
      company_id
      profile_center_id
      plant_storage_location_id
      division_id
      customer_group_id
      customer_id
      country_id
      region_id
      company_REF_CODE
      profit_center_REF_CODE
      division_REF_CODE
      customer_group_REF_CODE
      customer_REF_CODE
      country_REF_CODE
      region_REF_CODE
      plan_storage_location_REF_CODE
      sbu_REF_CODE
      customer_segment_REF_CODE
      product_group_REF_CODE
      brand_REF_CODE
      material_id
      material_REF_CODE
      bu_id
      bu_REF_CODE
      gtm_id
      gtm_REF_CODE
      sub_gtm_id
      sub_gtm_REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCompanyActuals = /* GraphQL */ `
  mutation CreateCompanyActuals(
    $input: CreateCompanyActualsInput!
    $condition: ModelCompanyActualsConditionInput
  ) {
    createCompanyActuals(input: $input, condition: $condition) {
      id
      financial_year_id
      quarterNo
      companyPerformance
      sbuPerformanceValue
      sbuPerformanceVolume
      sbuPerformanceTelco
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCompanyActuals = /* GraphQL */ `
  mutation UpdateCompanyActuals(
    $input: UpdateCompanyActualsInput!
    $condition: ModelCompanyActualsConditionInput
  ) {
    updateCompanyActuals(input: $input, condition: $condition) {
      id
      financial_year_id
      quarterNo
      companyPerformance
      sbuPerformanceValue
      sbuPerformanceVolume
      sbuPerformanceTelco
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCompanyActuals = /* GraphQL */ `
  mutation DeleteCompanyActuals(
    $input: DeleteCompanyActualsInput!
    $condition: ModelCompanyActualsConditionInput
  ) {
    deleteCompanyActuals(input: $input, condition: $condition) {
      id
      financial_year_id
      quarterNo
      companyPerformance
      sbuPerformanceValue
      sbuPerformanceVolume
      sbuPerformanceTelco
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNotifications = /* GraphQL */ `
  mutation CreateNotifications(
    $input: CreateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    createNotifications(input: $input, condition: $condition) {
      id
      senderId
      noticationDateTime
      notification
      notificationType
      receiverId
      isRead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNotifications = /* GraphQL */ `
  mutation UpdateNotifications(
    $input: UpdateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    updateNotifications(input: $input, condition: $condition) {
      id
      senderId
      noticationDateTime
      notification
      notificationType
      receiverId
      isRead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNotifications = /* GraphQL */ `
  mutation DeleteNotifications(
    $input: DeleteNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    deleteNotifications(input: $input, condition: $condition) {
      id
      senderId
      noticationDateTime
      notification
      notificationType
      receiverId
      isRead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFinanceActuals = /* GraphQL */ `
  mutation CreateFinanceActuals(
    $input: CreateFinanceActualsInput!
    $condition: ModelFinanceActualsConditionInput
  ) {
    createFinanceActuals(input: $input, condition: $condition) {
      id
      financial_year_id
      sbu_id
      bu_id
      grossMargin
      badDebts
      inventoryProvision
      arProvision
      forexProvision
      totalProvision
      grossMarginAfterProvision
      opex
      forex
      ebdita
      depreciation
      ebit
      cashDiscountReceived
      interestCost
      pbt
      quarterNo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFinanceActuals = /* GraphQL */ `
  mutation UpdateFinanceActuals(
    $input: UpdateFinanceActualsInput!
    $condition: ModelFinanceActualsConditionInput
  ) {
    updateFinanceActuals(input: $input, condition: $condition) {
      id
      financial_year_id
      sbu_id
      bu_id
      grossMargin
      badDebts
      inventoryProvision
      arProvision
      forexProvision
      totalProvision
      grossMarginAfterProvision
      opex
      forex
      ebdita
      depreciation
      ebit
      cashDiscountReceived
      interestCost
      pbt
      quarterNo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFinanceActuals = /* GraphQL */ `
  mutation DeleteFinanceActuals(
    $input: DeleteFinanceActualsInput!
    $condition: ModelFinanceActualsConditionInput
  ) {
    deleteFinanceActuals(input: $input, condition: $condition) {
      id
      financial_year_id
      sbu_id
      bu_id
      grossMargin
      badDebts
      inventoryProvision
      arProvision
      forexProvision
      totalProvision
      grossMarginAfterProvision
      opex
      forex
      ebdita
      depreciation
      ebit
      cashDiscountReceived
      interestCost
      pbt
      quarterNo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDivision = /* GraphQL */ `
  mutation CreateDivision(
    $input: CreateDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    createDivision(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDivision = /* GraphQL */ `
  mutation UpdateDivision(
    $input: UpdateDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    updateDivision(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDivision = /* GraphQL */ `
  mutation DeleteDivision(
    $input: DeleteDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    deleteDivision(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPlantStorageLocation = /* GraphQL */ `
  mutation CreatePlantStorageLocation(
    $input: CreatePlantStorageLocationInput!
    $condition: ModelPlantStorageLocationConditionInput
  ) {
    createPlantStorageLocation(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePlantStorageLocation = /* GraphQL */ `
  mutation UpdatePlantStorageLocation(
    $input: UpdatePlantStorageLocationInput!
    $condition: ModelPlantStorageLocationConditionInput
  ) {
    updatePlantStorageLocation(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePlantStorageLocation = /* GraphQL */ `
  mutation DeletePlantStorageLocation(
    $input: DeletePlantStorageLocationInput!
    $condition: ModelPlantStorageLocationConditionInput
  ) {
    deletePlantStorageLocation(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProfitCenter = /* GraphQL */ `
  mutation CreateProfitCenter(
    $input: CreateProfitCenterInput!
    $condition: ModelProfitCenterConditionInput
  ) {
    createProfitCenter(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      product_group_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProfitCenter = /* GraphQL */ `
  mutation UpdateProfitCenter(
    $input: UpdateProfitCenterInput!
    $condition: ModelProfitCenterConditionInput
  ) {
    updateProfitCenter(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      product_group_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProfitCenter = /* GraphQL */ `
  mutation DeleteProfitCenter(
    $input: DeleteProfitCenterInput!
    $condition: ModelProfitCenterConditionInput
  ) {
    deleteProfitCenter(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      product_group_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createChangeRequestComment = /* GraphQL */ `
  mutation CreateChangeRequestComment(
    $input: CreateChangeRequestCommentInput!
    $condition: ModelChangeRequestCommentConditionInput
  ) {
    createChangeRequestComment(input: $input, condition: $condition) {
      id
      changeRequestId
      receiver_id
      sender_id
      messageDateTime
      isRead
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChangeRequestComment = /* GraphQL */ `
  mutation UpdateChangeRequestComment(
    $input: UpdateChangeRequestCommentInput!
    $condition: ModelChangeRequestCommentConditionInput
  ) {
    updateChangeRequestComment(input: $input, condition: $condition) {
      id
      changeRequestId
      receiver_id
      sender_id
      messageDateTime
      isRead
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChangeRequestComment = /* GraphQL */ `
  mutation DeleteChangeRequestComment(
    $input: DeleteChangeRequestCommentInput!
    $condition: ModelChangeRequestCommentConditionInput
  ) {
    deleteChangeRequestComment(input: $input, condition: $condition) {
      id
      changeRequestId
      receiver_id
      sender_id
      messageDateTime
      isRead
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createChangeRequest = /* GraphQL */ `
  mutation CreateChangeRequest(
    $input: CreateChangeRequestInput!
    $condition: ModelChangeRequestConditionInput
  ) {
    createChangeRequest(input: $input, condition: $condition) {
      id
      requestedEmployeeId
      requestedDate
      requestType
      comments
      reportingManagerEmployeeId
      requestStatus
      hrRequestStatus
      financial_year_id
      requestedEmployeeRoleId
      reportingManagerApprovedDate
      hrManagerApprovedDate
      ExtendStartPeriod
      ExtendEndPeriod
      requestNo
      selectedQuarter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChangeRequest = /* GraphQL */ `
  mutation UpdateChangeRequest(
    $input: UpdateChangeRequestInput!
    $condition: ModelChangeRequestConditionInput
  ) {
    updateChangeRequest(input: $input, condition: $condition) {
      id
      requestedEmployeeId
      requestedDate
      requestType
      comments
      reportingManagerEmployeeId
      requestStatus
      hrRequestStatus
      financial_year_id
      requestedEmployeeRoleId
      reportingManagerApprovedDate
      hrManagerApprovedDate
      ExtendStartPeriod
      ExtendEndPeriod
      requestNo
      selectedQuarter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChangeRequest = /* GraphQL */ `
  mutation DeleteChangeRequest(
    $input: DeleteChangeRequestInput!
    $condition: ModelChangeRequestConditionInput
  ) {
    deleteChangeRequest(input: $input, condition: $condition) {
      id
      requestedEmployeeId
      requestedDate
      requestType
      comments
      reportingManagerEmployeeId
      requestStatus
      hrRequestStatus
      financial_year_id
      requestedEmployeeRoleId
      reportingManagerApprovedDate
      hrManagerApprovedDate
      ExtendStartPeriod
      ExtendEndPeriod
      requestNo
      selectedQuarter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createVerticals = /* GraphQL */ `
  mutation CreateVerticals(
    $input: CreateVerticalsInput!
    $condition: ModelVerticalsConditionInput
  ) {
    createVerticals(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateVerticals = /* GraphQL */ `
  mutation UpdateVerticals(
    $input: UpdateVerticalsInput!
    $condition: ModelVerticalsConditionInput
  ) {
    updateVerticals(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteVerticals = /* GraphQL */ `
  mutation DeleteVerticals(
    $input: DeleteVerticalsInput!
    $condition: ModelVerticalsConditionInput
  ) {
    deleteVerticals(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTargetMessage = /* GraphQL */ `
  mutation CreateTargetMessage(
    $input: CreateTargetMessageInput!
    $condition: ModelTargetMessageConditionInput
  ) {
    createTargetMessage(input: $input, condition: $condition) {
      id
      target_detail_id
      sender_id
      receiver_id
      messageDateTime
      isRead
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTargetMessage = /* GraphQL */ `
  mutation UpdateTargetMessage(
    $input: UpdateTargetMessageInput!
    $condition: ModelTargetMessageConditionInput
  ) {
    updateTargetMessage(input: $input, condition: $condition) {
      id
      target_detail_id
      sender_id
      receiver_id
      messageDateTime
      isRead
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTargetMessage = /* GraphQL */ `
  mutation DeleteTargetMessage(
    $input: DeleteTargetMessageInput!
    $condition: ModelTargetMessageConditionInput
  ) {
    deleteTargetMessage(input: $input, condition: $condition) {
      id
      target_detail_id
      sender_id
      receiver_id
      messageDateTime
      isRead
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      target_detail_id
      sbu_id
      region_id
      country_id
      brand_id
      product_group_id
      customer_group_id
      customer_id
      target_value
      q1_target_value
      q2_target_value
      q3_target_value
      q4_target_value
      m1_target_value
      m2_target_value
      m3_target_value
      m4_target_value
      m5_target_value
      m6_target_value
      m7_target_value
      m8_target_value
      m9_target_value
      m10_target_value
      m11_target_value
      m12_target_value
      sub_REF_CODE
      region_REF_CODE
      country_REF_CODE
      brand_REF_CODE
      product_group_REF_CODE
      customer_group_REF_CODE
      customer_REF_CODE
      m1_plan_actual_value
      m2_plan_actual_value
      m3_plan_actual_value
      m4_plan_actual_value
      m5_plan_actual_value
      m6_plan_actual_value
      m7_plan_actual_value
      m8_plan_actual_value
      m9_plan_actual_value
      m10_plan_actual_value
      m11_plan_actual_value
      m12_plan_actual_value
      bu_id
      bu_REF_CODE
      gtm_Id
      sub_gtm_id
      gtm_REF_CODE
      sub_gtm_REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      target_detail_id
      sbu_id
      region_id
      country_id
      brand_id
      product_group_id
      customer_group_id
      customer_id
      target_value
      q1_target_value
      q2_target_value
      q3_target_value
      q4_target_value
      m1_target_value
      m2_target_value
      m3_target_value
      m4_target_value
      m5_target_value
      m6_target_value
      m7_target_value
      m8_target_value
      m9_target_value
      m10_target_value
      m11_target_value
      m12_target_value
      sub_REF_CODE
      region_REF_CODE
      country_REF_CODE
      brand_REF_CODE
      product_group_REF_CODE
      customer_group_REF_CODE
      customer_REF_CODE
      m1_plan_actual_value
      m2_plan_actual_value
      m3_plan_actual_value
      m4_plan_actual_value
      m5_plan_actual_value
      m6_plan_actual_value
      m7_plan_actual_value
      m8_plan_actual_value
      m9_plan_actual_value
      m10_plan_actual_value
      m11_plan_actual_value
      m12_plan_actual_value
      bu_id
      bu_REF_CODE
      gtm_Id
      sub_gtm_id
      gtm_REF_CODE
      sub_gtm_REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      target_detail_id
      sbu_id
      region_id
      country_id
      brand_id
      product_group_id
      customer_group_id
      customer_id
      target_value
      q1_target_value
      q2_target_value
      q3_target_value
      q4_target_value
      m1_target_value
      m2_target_value
      m3_target_value
      m4_target_value
      m5_target_value
      m6_target_value
      m7_target_value
      m8_target_value
      m9_target_value
      m10_target_value
      m11_target_value
      m12_target_value
      sub_REF_CODE
      region_REF_CODE
      country_REF_CODE
      brand_REF_CODE
      product_group_REF_CODE
      customer_group_REF_CODE
      customer_REF_CODE
      m1_plan_actual_value
      m2_plan_actual_value
      m3_plan_actual_value
      m4_plan_actual_value
      m5_plan_actual_value
      m6_plan_actual_value
      m7_plan_actual_value
      m8_plan_actual_value
      m9_plan_actual_value
      m10_plan_actual_value
      m11_plan_actual_value
      m12_plan_actual_value
      bu_id
      bu_REF_CODE
      gtm_Id
      sub_gtm_id
      gtm_REF_CODE
      sub_gtm_REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHRAssignTargetDetails = /* GraphQL */ `
  mutation CreateHRAssignTargetDetails(
    $input: CreateHRAssignTargetDetailsInput!
    $condition: ModelHRAssignTargetDetailsConditionInput
  ) {
    createHRAssignTargetDetails(input: $input, condition: $condition) {
      id
      hrAssign_Target_id
      parameter_id
      targetValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHRAssignTargetDetails = /* GraphQL */ `
  mutation UpdateHRAssignTargetDetails(
    $input: UpdateHRAssignTargetDetailsInput!
    $condition: ModelHRAssignTargetDetailsConditionInput
  ) {
    updateHRAssignTargetDetails(input: $input, condition: $condition) {
      id
      hrAssign_Target_id
      parameter_id
      targetValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHRAssignTargetDetails = /* GraphQL */ `
  mutation DeleteHRAssignTargetDetails(
    $input: DeleteHRAssignTargetDetailsInput!
    $condition: ModelHRAssignTargetDetailsConditionInput
  ) {
    deleteHRAssignTargetDetails(input: $input, condition: $condition) {
      id
      hrAssign_Target_id
      parameter_id
      targetValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHRAssigTargets = /* GraphQL */ `
  mutation CreateHRAssigTargets(
    $input: CreateHRAssigTargetsInput!
    $condition: ModelHRAssigTargetsConditionInput
  ) {
    createHRAssigTargets(input: $input, condition: $condition) {
      id
      financial_year_id
      employee_id
      isAssigned
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHRAssigTargets = /* GraphQL */ `
  mutation UpdateHRAssigTargets(
    $input: UpdateHRAssigTargetsInput!
    $condition: ModelHRAssigTargetsConditionInput
  ) {
    updateHRAssigTargets(input: $input, condition: $condition) {
      id
      financial_year_id
      employee_id
      isAssigned
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHRAssigTargets = /* GraphQL */ `
  mutation DeleteHRAssigTargets(
    $input: DeleteHRAssigTargetsInput!
    $condition: ModelHRAssigTargetsConditionInput
  ) {
    deleteHRAssigTargets(input: $input, condition: $condition) {
      id
      financial_year_id
      employee_id
      isAssigned
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBIUnit = /* GraphQL */ `
  mutation CreateBIUnit(
    $input: CreateBIUnitInput!
    $condition: ModelBIUnitConditionInput
  ) {
    createBIUnit(input: $input, condition: $condition) {
      id
      name
      product_group_id
      REF_CODE
      sbu_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBIUnit = /* GraphQL */ `
  mutation UpdateBIUnit(
    $input: UpdateBIUnitInput!
    $condition: ModelBIUnitConditionInput
  ) {
    updateBIUnit(input: $input, condition: $condition) {
      id
      name
      product_group_id
      REF_CODE
      sbu_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBIUnit = /* GraphQL */ `
  mutation DeleteBIUnit(
    $input: DeleteBIUnitInput!
    $condition: ModelBIUnitConditionInput
  ) {
    deleteBIUnit(input: $input, condition: $condition) {
      id
      name
      product_group_id
      REF_CODE
      sbu_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFinancialParameterDetails = /* GraphQL */ `
  mutation CreateFinancialParameterDetails(
    $input: CreateFinancialParameterDetailsInput!
    $condition: ModelFinancialParameterDetailsConditionInput
  ) {
    createFinancialParameterDetails(input: $input, condition: $condition) {
      id
      financial_parameter_id
      parameter_id
      parameter_defination
      parameter_criteria
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFinancialParameterDetails = /* GraphQL */ `
  mutation UpdateFinancialParameterDetails(
    $input: UpdateFinancialParameterDetailsInput!
    $condition: ModelFinancialParameterDetailsConditionInput
  ) {
    updateFinancialParameterDetails(input: $input, condition: $condition) {
      id
      financial_parameter_id
      parameter_id
      parameter_defination
      parameter_criteria
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFinancialParameterDetails = /* GraphQL */ `
  mutation DeleteFinancialParameterDetails(
    $input: DeleteFinancialParameterDetailsInput!
    $condition: ModelFinancialParameterDetailsConditionInput
  ) {
    deleteFinancialParameterDetails(input: $input, condition: $condition) {
      id
      financial_parameter_id
      parameter_id
      parameter_defination
      parameter_criteria
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEvaluation = /* GraphQL */ `
  mutation CreateEvaluation(
    $input: CreateEvaluationInput!
    $condition: ModelEvaluationConditionInput
  ) {
    createEvaluation(input: $input, condition: $condition) {
      id
      financial_year_id
      quaterNo
      startDate
      endDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEvaluation = /* GraphQL */ `
  mutation UpdateEvaluation(
    $input: UpdateEvaluationInput!
    $condition: ModelEvaluationConditionInput
  ) {
    updateEvaluation(input: $input, condition: $condition) {
      id
      financial_year_id
      quaterNo
      startDate
      endDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEvaluation = /* GraphQL */ `
  mutation DeleteEvaluation(
    $input: DeleteEvaluationInput!
    $condition: ModelEvaluationConditionInput
  ) {
    deleteEvaluation(input: $input, condition: $condition) {
      id
      financial_year_id
      quaterNo
      startDate
      endDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTargetDetails = /* GraphQL */ `
  mutation CreateTargetDetails(
    $input: CreateTargetDetailsInput!
    $condition: ModelTargetDetailsConditionInput
  ) {
    createTargetDetails(input: $input, condition: $condition) {
      id
      target_id
      parameter_id
      target_value
      target_assigned_date
      is_accepted_by_employee
      revision_value
      is_revision_by_employee
      revision_by_employee_date
      is_approve
      approved_date
      accepted_by_employee_date
      isMarkAsComplete
      isMarkAsComplete_AssignTarget
      isSendForRevisionByReportingManage
      m1_actual_value
      m2_actual_value
      m3_actual_value
      m4_actual_value
      m5_actual_value
      m6_actual_value
      m7_actual_value
      m8_actual_value
      m9_actual_value
      m10_actual_value
      m11_actual_value
      m12_actual_value
      q1_target_value
      q2_target_value
      q3_target_value
      q4_target_value
      q1_achievement
      q2_achievement
      q3_achievement
      q4_achievement
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTargetDetails = /* GraphQL */ `
  mutation UpdateTargetDetails(
    $input: UpdateTargetDetailsInput!
    $condition: ModelTargetDetailsConditionInput
  ) {
    updateTargetDetails(input: $input, condition: $condition) {
      id
      target_id
      parameter_id
      target_value
      target_assigned_date
      is_accepted_by_employee
      revision_value
      is_revision_by_employee
      revision_by_employee_date
      is_approve
      approved_date
      accepted_by_employee_date
      isMarkAsComplete
      isMarkAsComplete_AssignTarget
      isSendForRevisionByReportingManage
      m1_actual_value
      m2_actual_value
      m3_actual_value
      m4_actual_value
      m5_actual_value
      m6_actual_value
      m7_actual_value
      m8_actual_value
      m9_actual_value
      m10_actual_value
      m11_actual_value
      m12_actual_value
      q1_target_value
      q2_target_value
      q3_target_value
      q4_target_value
      q1_achievement
      q2_achievement
      q3_achievement
      q4_achievement
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTargetDetails = /* GraphQL */ `
  mutation DeleteTargetDetails(
    $input: DeleteTargetDetailsInput!
    $condition: ModelTargetDetailsConditionInput
  ) {
    deleteTargetDetails(input: $input, condition: $condition) {
      id
      target_id
      parameter_id
      target_value
      target_assigned_date
      is_accepted_by_employee
      revision_value
      is_revision_by_employee
      revision_by_employee_date
      is_approve
      approved_date
      accepted_by_employee_date
      isMarkAsComplete
      isMarkAsComplete_AssignTarget
      isSendForRevisionByReportingManage
      m1_actual_value
      m2_actual_value
      m3_actual_value
      m4_actual_value
      m5_actual_value
      m6_actual_value
      m7_actual_value
      m8_actual_value
      m9_actual_value
      m10_actual_value
      m11_actual_value
      m12_actual_value
      q1_target_value
      q2_target_value
      q3_target_value
      q4_target_value
      q1_achievement
      q2_achievement
      q3_achievement
      q4_achievement
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTargets = /* GraphQL */ `
  mutation CreateTargets(
    $input: CreateTargetsInput!
    $condition: ModelTargetsConditionInput
  ) {
    createTargets(input: $input, condition: $condition) {
      id
      financial_year_id
      employee_id
      report_manager_employee_id
      role_id
      isAddedByHRAdmin
      isSubmittedByEmployee
      isAssignedToEmployee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTargets = /* GraphQL */ `
  mutation UpdateTargets(
    $input: UpdateTargetsInput!
    $condition: ModelTargetsConditionInput
  ) {
    updateTargets(input: $input, condition: $condition) {
      id
      financial_year_id
      employee_id
      report_manager_employee_id
      role_id
      isAddedByHRAdmin
      isSubmittedByEmployee
      isAssignedToEmployee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTargets = /* GraphQL */ `
  mutation DeleteTargets(
    $input: DeleteTargetsInput!
    $condition: ModelTargetsConditionInput
  ) {
    deleteTargets(input: $input, condition: $condition) {
      id
      financial_year_id
      employee_id
      report_manager_employee_id
      role_id
      isAddedByHRAdmin
      isSubmittedByEmployee
      isAssignedToEmployee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPlanning = /* GraphQL */ `
  mutation CreatePlanning(
    $input: CreatePlanningInput!
    $condition: ModelPlanningConditionInput
  ) {
    createPlanning(input: $input, condition: $condition) {
      id
      financial_year_id
      planning_from_date
      planning_to_date
      is_completed
      last_Updated_Date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePlanning = /* GraphQL */ `
  mutation UpdatePlanning(
    $input: UpdatePlanningInput!
    $condition: ModelPlanningConditionInput
  ) {
    updatePlanning(input: $input, condition: $condition) {
      id
      financial_year_id
      planning_from_date
      planning_to_date
      is_completed
      last_Updated_Date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePlanning = /* GraphQL */ `
  mutation DeletePlanning(
    $input: DeletePlanningInput!
    $condition: ModelPlanningConditionInput
  ) {
    deletePlanning(input: $input, condition: $condition) {
      id
      financial_year_id
      planning_from_date
      planning_to_date
      is_completed
      last_Updated_Date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      role_id
      firstName
      lastName
      email
      parentEmployeeId
      address1
      address2
      phoneNo
      city
      zip
      state
      country
      employeeId
      profilePic
      isAssignDirectTarget
      param_sbu_id
      IsHRAccess
      IsFinanceAccess
      joining_date
      confirmation_date
      employee_class
      employee_type
      grade
      band
      lwd
      password
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      role_id
      firstName
      lastName
      email
      parentEmployeeId
      address1
      address2
      phoneNo
      city
      zip
      state
      country
      employeeId
      profilePic
      isAssignDirectTarget
      param_sbu_id
      IsHRAccess
      IsFinanceAccess
      joining_date
      confirmation_date
      employee_class
      employee_type
      grade
      band
      lwd
      password
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      role_id
      firstName
      lastName
      email
      parentEmployeeId
      address1
      address2
      phoneNo
      city
      zip
      state
      country
      employeeId
      profilePic
      isAssignDirectTarget
      param_sbu_id
      IsHRAccess
      IsFinanceAccess
      joining_date
      confirmation_date
      employee_class
      employee_type
      grade
      band
      lwd
      password
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRelationshipDetails = /* GraphQL */ `
  mutation CreateRelationshipDetails(
    $input: CreateRelationshipDetailsInput!
    $condition: ModelRelationshipDetailsConditionInput
  ) {
    createRelationshipDetails(input: $input, condition: $condition) {
      id
      relationship_id
      parameter_id
      weightage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRelationshipDetails = /* GraphQL */ `
  mutation UpdateRelationshipDetails(
    $input: UpdateRelationshipDetailsInput!
    $condition: ModelRelationshipDetailsConditionInput
  ) {
    updateRelationshipDetails(input: $input, condition: $condition) {
      id
      relationship_id
      parameter_id
      weightage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRelationshipDetails = /* GraphQL */ `
  mutation DeleteRelationshipDetails(
    $input: DeleteRelationshipDetailsInput!
    $condition: ModelRelationshipDetailsConditionInput
  ) {
    deleteRelationshipDetails(input: $input, condition: $condition) {
      id
      relationship_id
      parameter_id
      weightage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRelationship = /* GraphQL */ `
  mutation CreateRelationship(
    $input: CreateRelationshipInput!
    $condition: ModelRelationshipConditionInput
  ) {
    createRelationship(input: $input, condition: $condition) {
      id
      financial_year_id
      role_id
      isDeployed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRelationship = /* GraphQL */ `
  mutation UpdateRelationship(
    $input: UpdateRelationshipInput!
    $condition: ModelRelationshipConditionInput
  ) {
    updateRelationship(input: $input, condition: $condition) {
      id
      financial_year_id
      role_id
      isDeployed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRelationship = /* GraphQL */ `
  mutation DeleteRelationship(
    $input: DeleteRelationshipInput!
    $condition: ModelRelationshipConditionInput
  ) {
    deleteRelationship(input: $input, condition: $condition) {
      id
      financial_year_id
      role_id
      isDeployed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      customer_category_id
      country_id
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      customer_category_id
      country_id
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      customer_category_id
      country_id
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCustomerCategory = /* GraphQL */ `
  mutation CreateCustomerCategory(
    $input: CreateCustomerCategoryInput!
    $condition: ModelCustomerCategoryConditionInput
  ) {
    createCustomerCategory(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      customer_segment_id
      gtm_i
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCustomerCategory = /* GraphQL */ `
  mutation UpdateCustomerCategory(
    $input: UpdateCustomerCategoryInput!
    $condition: ModelCustomerCategoryConditionInput
  ) {
    updateCustomerCategory(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      customer_segment_id
      gtm_i
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCustomerCategory = /* GraphQL */ `
  mutation DeleteCustomerCategory(
    $input: DeleteCustomerCategoryInput!
    $condition: ModelCustomerCategoryConditionInput
  ) {
    deleteCustomerCategory(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      customer_segment_id
      gtm_i
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry(
    $input: CreateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    createCountry(input: $input, condition: $condition) {
      id
      name
      region_id
      REF_CODE
      VAT
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry(
    $input: UpdateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    updateCountry(input: $input, condition: $condition) {
      id
      name
      region_id
      REF_CODE
      VAT
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry(
    $input: DeleteCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    deleteCountry(input: $input, condition: $condition) {
      id
      name
      region_id
      REF_CODE
      VAT
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRegion = /* GraphQL */ `
  mutation CreateRegion(
    $input: CreateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    createRegion(input: $input, condition: $condition) {
      id
      name
      SBU_id
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRegion = /* GraphQL */ `
  mutation UpdateRegion(
    $input: UpdateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    updateRegion(input: $input, condition: $condition) {
      id
      name
      SBU_id
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRegion = /* GraphQL */ `
  mutation DeleteRegion(
    $input: DeleteRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    deleteRegion(input: $input, condition: $condition) {
      id
      name
      SBU_id
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProductGroup = /* GraphQL */ `
  mutation CreateProductGroup(
    $input: CreateProductGroupInput!
    $condition: ModelProductGroupConditionInput
  ) {
    createProductGroup(input: $input, condition: $condition) {
      id
      name
      brand_id
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProductGroup = /* GraphQL */ `
  mutation UpdateProductGroup(
    $input: UpdateProductGroupInput!
    $condition: ModelProductGroupConditionInput
  ) {
    updateProductGroup(input: $input, condition: $condition) {
      id
      name
      brand_id
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProductGroup = /* GraphQL */ `
  mutation DeleteProductGroup(
    $input: DeleteProductGroupInput!
    $condition: ModelProductGroupConditionInput
  ) {
    deleteProductGroup(input: $input, condition: $condition) {
      id
      name
      brand_id
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      name
      SBU_id
      REF_CODE
      bu_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      name
      SBU_id
      REF_CODE
      bu_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      name
      SBU_id
      REF_CODE
      bu_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSBU = /* GraphQL */ `
  mutation CreateSBU(
    $input: CreateSBUInput!
    $condition: ModelSBUConditionInput
  ) {
    createSBU(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSBU = /* GraphQL */ `
  mutation UpdateSBU(
    $input: UpdateSBUInput!
    $condition: ModelSBUConditionInput
  ) {
    updateSBU(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSBU = /* GraphQL */ `
  mutation DeleteSBU(
    $input: DeleteSBUInput!
    $condition: ModelSBUConditionInput
  ) {
    deleteSBU(input: $input, condition: $condition) {
      id
      name
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRoles = /* GraphQL */ `
  mutation CreateRoles(
    $input: CreateRolesInput!
    $condition: ModelRolesConditionInput
  ) {
    createRoles(input: $input, condition: $condition) {
      id
      name
      isReportee
      sortOrder
      teamType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRoles = /* GraphQL */ `
  mutation UpdateRoles(
    $input: UpdateRolesInput!
    $condition: ModelRolesConditionInput
  ) {
    updateRoles(input: $input, condition: $condition) {
      id
      name
      isReportee
      sortOrder
      teamType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRoles = /* GraphQL */ `
  mutation DeleteRoles(
    $input: DeleteRolesInput!
    $condition: ModelRolesConditionInput
  ) {
    deleteRoles(input: $input, condition: $condition) {
      id
      name
      isReportee
      sortOrder
      teamType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFinancialParameters = /* GraphQL */ `
  mutation CreateFinancialParameters(
    $input: CreateFinancialParametersInput!
    $condition: ModelFinancialParametersConditionInput
  ) {
    createFinancialParameters(input: $input, condition: $condition) {
      id
      financial_year_id
      user_id
      isDeployed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFinancialParameters = /* GraphQL */ `
  mutation UpdateFinancialParameters(
    $input: UpdateFinancialParametersInput!
    $condition: ModelFinancialParametersConditionInput
  ) {
    updateFinancialParameters(input: $input, condition: $condition) {
      id
      financial_year_id
      user_id
      isDeployed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFinancialParameters = /* GraphQL */ `
  mutation DeleteFinancialParameters(
    $input: DeleteFinancialParametersInput!
    $condition: ModelFinancialParametersConditionInput
  ) {
    deleteFinancialParameters(input: $input, condition: $condition) {
      id
      financial_year_id
      user_id
      isDeployed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createParameters = /* GraphQL */ `
  mutation CreateParameters(
    $input: CreateParametersInput!
    $condition: ModelParametersConditionInput
  ) {
    createParameters(input: $input, condition: $condition) {
      id
      name
      definition
      criteria
      type
      base_type
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateParameters = /* GraphQL */ `
  mutation UpdateParameters(
    $input: UpdateParametersInput!
    $condition: ModelParametersConditionInput
  ) {
    updateParameters(input: $input, condition: $condition) {
      id
      name
      definition
      criteria
      type
      base_type
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteParameters = /* GraphQL */ `
  mutation DeleteParameters(
    $input: DeleteParametersInput!
    $condition: ModelParametersConditionInput
  ) {
    deleteParameters(input: $input, condition: $condition) {
      id
      name
      definition
      criteria
      type
      base_type
      REF_CODE
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFinancialYear = /* GraphQL */ `
  mutation CreateFinancialYear(
    $input: CreateFinancialYearInput!
    $condition: ModelFinancialYearConditionInput
  ) {
    createFinancialYear(input: $input, condition: $condition) {
      id
      fromDate
      toDate
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFinancialYear = /* GraphQL */ `
  mutation UpdateFinancialYear(
    $input: UpdateFinancialYearInput!
    $condition: ModelFinancialYearConditionInput
  ) {
    updateFinancialYear(input: $input, condition: $condition) {
      id
      fromDate
      toDate
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFinancialYear = /* GraphQL */ `
  mutation DeleteFinancialYear(
    $input: DeleteFinancialYearInput!
    $condition: ModelFinancialYearConditionInput
  ) {
    deleteFinancialYear(input: $input, condition: $condition) {
      id
      fromDate
      toDate
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
