import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";
import LeftSideBar from '../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../components/common/hr/topnav.component';
import Select from "react-select";
import { ValidateHRRole } from '../../../components/helper/validateRole';
import HRLogin from '../../../setup/auth/hr.component';
import CircularProgressBar from '../../../components/common/progressbar/CircularProgressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import EditButton from '../../../components/common/buttons/EditButton';
import ViewButton from '../../../components/common/buttons/ViewButton';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { ConvertResponseForSelect, ConvertResponseForSelectTwoNames, checkDuplicates } from '../../../components/helper/commonfunctions';
import { FinancialYear, SBU, BIUnit, Region, Employee, Roles, InitiateEvaluation, Evaluation, ChangeRequest } from "../../../models";
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from "moment";
import { calculatePercentage } from "../../../components/helper/dashboardCalCulations";
import { CHANGE_REQUEST_STATUS, CHANGE_REQUEST_TYPES, HR_CHANGE_REQUEST_STATUS } from "../../../components/helper/enum";
import { ProgressSpinner } from 'primereact/progressspinner';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../components/helper/graphQLFunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';






export default function HrEvaluationDashboard() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //state
    const [financialYear, setFinancialYear] = useState("");
    const [sBU, setSBU] = useState("");
    const [bU, setBU] = useState("");
    const [region, setRegion] = useState("");
    const [employee, setEmployee] = useState("");
    const [quarter, setQuarter] = useState("");
    const [quarterNo, setQuarterNo] = useState("1");
    const [eligibleEmployee, setEligibleEmployee] = useState("");
    const [eligibleEmployeeBusinessCount, setEligibleEmployeeBusinessCount] = useState("");
    const [eligibleEmployeeSalesCount, setEligibleEmployeeSalesCount] = useState("");
    const [eligibleEmployeeBusiness, setEligibleEmployeeBusiness] = useState("");
    const [eligibleEmployeeSales, setEligibleEmployeeSales] = useState("");
    const [evaluationInitiated, setEvaluationInitiated] = useState("");
    const [evaluationInitiatedBusinessCount, setEvaluationInitiatedBusinessCount] = useState("");
    const [evaluationInitiatedSalesCount, setEvaluationInitiatedSalesCount] = useState("");
    const [evaluationInitiatedBusiness, setEvaluationInitiatedBusiness] = useState("");
    const [evaluationInitiatedSales, setEvaluationInitiatedSales] = useState("");
    const [overrideActualRequest, setOverrideActualRequest] = useState("");
    const [overrideActualBusinessCount, setOverrideActualBusinessCount] = useState("");
    const [overrideActualSalesCount, setOverrideActualSalesCount] = useState("");
    const [overrideActualBusiness, setOverrideActualBusiness] = useState("");
    const [overrideActualSales, setOverrideActualSales] = useState("");
    const [isPageLoaded, setPageLoaded] = useState(false);
    const [roles, setRoles] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);



    //list
    const [financialYearList, setFinancialYearList] = useState([]);
    const [sBUList, setSBUList] = useState([]);
    const [bUList, setBUList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [evaluationData, setEvaluationData] = useState([]);

    //quarterList
    var quarterList = [
        { label: "All Quarter", value: "Quarter 4" },
        { label: "Quarter 1", value: "Quarter 1" },
        { label: "Quarter 2", value: "Quarter 2" },
        { label: "Quarter 3", value: "Quarter 3" },
        { label: "Quarter 4", value: "Quarter 4" },
    ]

    //Table Data
    var demoData = [
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Olivia Rhye", "Role": "Manager", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Open", "Action": "Action"
        },
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Phoenix Baker", "Role": "Sales Manager", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Closed", "Action": "Action"
        },
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Lana Steiner", "Role": "Brand Manager", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Closed", "Action": "Action"
        },
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Region Manager", "Role": "Region Manager", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Closed", "Action": "Action"
        },
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Candice Wu", "Role": "Sales Executive", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Closed", "Action": <div className="flex gap-8">
            </div>
        },
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Natali Craig", "Role": "Sales Vice President", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Closed", "Action": "Action"
        },
        {
            "Request ID": "REQ25478", "Employee ID": "EMP25478", "Name": "Drew Cano", "Role": "Marketing Manager", "vertical": "Distribution", "SBU": "Value MEA", "Reporting Manager": "Bryan Adams", "Date Submitted": "Jan 4, 2022", "Date Approved": "Jan 4, 2022", "Status": "Closed", "Action": "Action"
        },
    ]

    //Edit View Button
    const actionsOnRoles = () => {
        return (
            <React.Fragment >
                <div className="flex gap-8 ">
                    <Link
                        onClick={() => {
                            // setViewPopUp(1)
                        }}
                    ><ViewButton /></Link>
                    <Link onClick={() => {
                        // setEditPopUp(1)
                    }}  ><EditButton /></Link>
                </div>

            </React.Fragment>
        );
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    const loadEvaluationDetailedReport = async (financialYear, quaterNo) => {

        //*get Evaluation Response from financialYear and quaterNo
        // var EvaluationResponses = await DataStore.query(Evaluation, (c) => c.and(c => [
        //     c.financial_year_id.eq(financialYear),
        //     c.quaterNo.eq(quaterNo)
        // ]));

        var EvaluationResponses = await graphQLGetAllData('listEvaluations', [
            { financial_year_id: { eq: financialYear } },
            { quaterNo: { eq: quaterNo } }
        ]);
        if (EvaluationResponses.length > 0) {

            // var currentInitiateEvaluationDetails = await DataStore.query(InitiateEvaluation, (c) => c.and(c => [
            //     c.evaluation_id.eq(EvaluationResponses[0].id),
            // ]));

            var currentInitiateEvaluationDetails = await graphQLGetAllData('listInitiateEvaluations', [{ evaluation_id: { eq: EvaluationResponses[0].id } }]);

            let getTotalEligibleEmployees = await calculateEligibleEmployee()
            let getTotalEvaluationInitiated = await calculateEvaluationInitiated(EvaluationResponses[0].id)
            let finalData = [];
            let rank = 0;
            for (let i = 0; i < currentInitiateEvaluationDetails.length; i++) {
                //* get Reporting Manager
                let reportingManager = "";
                let totalReportee = "";
                let initiateEvaluationCount = "";
                let teamType = "";
                // let eligibleEmployeeBusiness = [];
                // let eligibleSalesBusiness = [];

                // var reportingManagerResponses = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(currentInitiateEvaluationDetails[i].employee_id));

                var reportingManagerResponses = await graphQLGetAllData('listEmployees', [{ parentEmployeeId: { eq: currentInitiateEvaluationDetails[i].employee_id } }]);

                if (reportingManagerResponses.length !== 0) {

                    rank = rank + 1

                    totalReportee = reportingManagerResponses.length
                    // var parentEmployeeResponse = await DataStore.query(Employee, currentInitiateEvaluationDetails[i].employee_id);
                    var parentEmployeeResponse = await graphQLFindRecordById('getEmployee', currentInitiateEvaluationDetails[i].employee_id);

                    if (parentEmployeeResponse) { reportingManager = parentEmployeeResponse.firstName + " " + parentEmployeeResponse.lastName } else { reportingManager = "" }

                    //*get Role
                    // var roleResponse = await DataStore.query(Roles, parentEmployeeResponse.role_id);
                    var roleResponse = await graphQLFindRecordById('getRoles', parentEmployeeResponse.role_id);
                    teamType = roleResponse.teamType

                    // var initiateResponses = await DataStore.query(InitiateEvaluation, (c) => c.employee_id.eq(currentInitiateEvaluationDetails[i].employee_id));
                    var initiateResponses = await graphQLGetAllData('listInitiateEvaluations', [{ employee_id: { eq: currentInitiateEvaluationDetails[i].employee_id } }]);

                    initiateEvaluationCount = initiateResponses.length

                    finalData.push({ "rank": rank, "reportingManager": reportingManager, "totalReportee": totalReportee, "eligible": "Yes", "initiated": initiateEvaluationCount, "reviewPending": "", "reviewCompleted": "", "completed": "", "teamType": teamType })
                }
                if (i === currentInitiateEvaluationDetails.length - 1) {
                    setEvaluationData(finalData);
                    //*set Eligible Employee
                    setEligibleEmployee(getTotalEligibleEmployees[0].totalEmployeeCount);
                    setEligibleEmployeeBusinessCount(getTotalEligibleEmployees[0].totalBusinessCount); setEligibleEmployeeSalesCount(getTotalEligibleEmployees[0].totalSalesCount);
                    setEligibleEmployeeBusiness(getTotalEligibleEmployees[0].getEligibleEmployeeBusinessPer);
                    setEligibleEmployeeSales(getTotalEligibleEmployees[0].getEligibleEmployeeSalesPer);
                    //*Evaluation Initiated
                    setEvaluationInitiated(getTotalEvaluationInitiated[0].totalEmployeeCount)
                    setEvaluationInitiatedBusinessCount(getTotalEvaluationInitiated[0].totalBusinessCount); setEvaluationInitiatedSalesCount(getTotalEvaluationInitiated[0].totalSalesCount);
                    setEvaluationInitiatedBusiness(getTotalEvaluationInitiated[0].getEligibleEmployeeBusinessPer); setEvaluationInitiatedSales(getTotalEvaluationInitiated[0].getEligibleEmployeeSalesPer)
                    loadOverRideActualsRequests(financialYear);
                    setPageLoaded(true);
                }
            }
        } else {
            setEvaluationData([]);
            setEligibleEmployee("0");
            setEligibleEmployeeBusinessCount("0"); setEligibleEmployeeSalesCount("0");
            setEligibleEmployeeBusiness("0");
            setEligibleEmployeeSales("0"); setEvaluationInitiated("0");
            setEvaluationInitiatedBusinessCount("0"); setEvaluationInitiatedSalesCount("0");
            setEvaluationInitiatedBusiness("0"); setEvaluationInitiatedSales("0");
            setPageLoaded(true);

        }
    }

    // //*get Employee For Eligible Count
    const calculateEvaluationInitiated = async (evaluationId) => {

        // var currentInitiateEvaluationDetails = await DataStore.query(InitiateEvaluation, (c) => c.and(c => [
        //     c.evaluation_id.eq(evaluationId),
        // ]));

        var currentInitiateEvaluationDetails = await graphQLGetAllData('listInitiateEvaluations', [{ evaluation_id: { eq: evaluationId } }]);

        let finalData = [];
        let totalEmployeeCount = 0;
        let totalBusinessCount = 0;
        let totalSalesCount = 0;

        for (let i = 0; i < currentInitiateEvaluationDetails.length; i++) {

            let teamType = "";

            if (currentInitiateEvaluationDetails[i].employee_id) { totalEmployeeCount = totalEmployeeCount + 1 }
            // var employeeResponses = await DataStore.query(Employee, (c) => c.id.eq(currentInitiateEvaluationDetails[i].employee_id));

            var employeeResponses = await graphQLFindRecordById('getEmployee', currentInitiateEvaluationDetails[i].employee_id);


            if (employeeResponses) {
                //*get Role
                // var roleResponse = await DataStore.query(Roles, employeeResponses.role_id);
                var roleResponse = await graphQLFindRecordById('getRoles', employeeResponses.role_id);

                if (roleResponse) {
                    teamType = roleResponse.teamType
                    if (roleResponse.teamType === "B") { totalBusinessCount = totalBusinessCount + 1 } else if (roleResponse.teamType === "S") { totalSalesCount = totalSalesCount + 1 }
                }

            }
            if (i === currentInitiateEvaluationDetails.length - 1) {
                let getEligibleEmployeeBusinessPer = await calculatePercentage(totalBusinessCount, totalEmployeeCount);
                let getEligibleEmployeeSalesPer = await calculatePercentage(totalSalesCount, totalEmployeeCount);
                finalData.push({ "totalEmployeeCount": totalEmployeeCount, "totalBusinessCount": totalBusinessCount, "totalSalesCount": totalSalesCount, "getEligibleEmployeeBusinessPer": getEligibleEmployeeBusinessPer, "getEligibleEmployeeSalesPer": getEligibleEmployeeSalesPer })
                return finalData
            }
        }
    }

    //*get Employee For Eligible Count
    const calculateEligibleEmployee = async () => {

        // var currentInitiateEmployee = await DataStore.query(Employee);
        var currentInitiateEmployee = await graphQLGetAllData('listEmployees');


        let finalData = [];
        let totalBusinessCount = 0;
        let totalSalesCount = 0;

        for (let i = 0; i < currentInitiateEmployee.length; i++) {
            let teamType = "";
            //*get Role
            // var roleResponse = await DataStore.query(Roles, currentInitiateEmployee[i].role_id);
            // var roleResponse = await graphQLFindRecordById('getRoles', currentInitiateEmployee[i].role_id);
            var roleResponse = roles.find( r => r.id === currentInitiateEmployee[i].role_id )

            if (roleResponse) {
                teamType = roleResponse.teamType
                if (roleResponse.teamType === "B") { totalBusinessCount = totalBusinessCount + 1 }
                else if (roleResponse.teamType === "S") { totalSalesCount = totalSalesCount + 1 }
            }


            if (i === currentInitiateEmployee.length - 1) {
                let getEligibleEmployeeBusinessPer = await calculatePercentage(totalBusinessCount, currentInitiateEmployee.length);
                let getEligibleEmployeeSalesPer = await calculatePercentage(totalSalesCount, currentInitiateEmployee.length);
                finalData.push({ "totalEmployeeCount": currentInitiateEmployee.length, "totalBusinessCount": totalBusinessCount, "totalSalesCount": totalSalesCount, "getEligibleEmployeeBusinessPer": getEligibleEmployeeBusinessPer, "getEligibleEmployeeSalesPer": getEligibleEmployeeSalesPer })
                return finalData
            }
        }

    }

    const loadOverRideActualsRequests = async (financialYear) => {

        // var response = await DataStore.query(ChangeRequest, (c) => c.and(c => [
        //     c.financial_year_id.eq(financialYear),
        //     c.requestType.eq(CHANGE_REQUEST_TYPES.OverrideActual),
        // ]));

        var response = await graphQLGetAllData('listChangeRequests', [
            { financial_year_id: { eq: financialYear } },
            { requestType: { eq: CHANGE_REQUEST_TYPES.OverrideActual } }
        ]);

        let newData = [];
        if (response.length > 0) {
            for (let i = 0; i < response.length; i++) {
                let teamType = "";
                if (response[i].requestedEmployeeId) {
                    // var employeeResponses = await DataStore.query(Employee, (c) => c.id.eq(response[i].requestedEmployeeId));
                    var employeeResponses = await graphQLFindRecordById('getEmployee', response[i].requestedEmployeeId);

                    if (employeeResponses) {
                        // var rolesResponses = await DataStore.query(Roles, (c) => c.id.eq(employeeResponses.role_id));
                        var rolesResponses = await graphQLFindRecordById('getRoles', employeeResponses.role_id);
                        teamType = rolesResponses.teamType;
                    } else {
                        teamType = "";
                    }
                }
                newData.push({
                    "id": response[i].id, "requestedEmployeeId": response[i].requestedEmployeeId, "requestedDate": response[i].requestedDate, "requestType": response[i].requestType,
                    "comments": response[i].comments, "reportingManagerEmployeeId": response[i].reportingManagerEmployeeId, "requestStatus": response[i].requestStatus, "hrRequestStatus": response[i].hrRequestStatus, "financial_year_id": response[i].financial_year_id,
                    "requestedEmployeeRoleId": response[i].requestedEmployeeRoleId, "reportingManagerApprovedDate": response[i].reportingManagerApprovedDate, "hrManagerApprovedDate": response[i].hrManagerApprovedDate, "ExtendStartPeriod": response[i].ExtendStartPeriod,
                    "ExtendEndPeriod": response[i].ExtendEndPeriod, "requestNo": response[i].requestNo, "teamType": teamType
                })

                if (i === response.length - 1) {
                    let totalRequestCount = newData.length;
                    setOverrideActualRequest(totalRequestCount);
                    let requestBusiness = newData.filter((data) => data.teamType === "B");
                    let requestSales = newData.filter((data) => data.teamType === "S");
                    setOverrideActualBusinessCount(requestBusiness.length); setOverrideActualSalesCount(requestSales.length);
                    let getRequestBusinessPer = await calculatePercentage(requestBusiness.length, newData.length);
                    let getRequestSalesPer = await calculatePercentage(requestSales.length, newData.length);
                    setOverrideActualBusiness(getRequestBusinessPer);
                    setOverrideActualSales(getRequestSalesPer);
                    setPageLoaded(true);
                }
            }
        } else {
            setOverrideActualRequest("0"); setOverrideActualBusinessCount("0"); setOverrideActualSalesCount("0");
            setOverrideActualBusiness("0"); setOverrideActualSales("0");
        }
    }

    const getMasterData = async () => {
        setDataLoaded(false);
        var rolesResponses = await graphQLGetAllData('listRoles');
        setRoles(rolesResponses);
        setDataLoaded(true);
    }

    async function bindList() {
        //set financial year
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        if (Object.keys(selectedYear).length !== 0) {
            setFinancialYear(selectedYear)
            setQuarter({label: 'Quarter 1', value: 'Quarter 1'})
            loadEvaluationDetailedReport(selectedYear.value, "1");
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

            setFinancialYear({ label: responses[0].name, value: responses[0].id })
            setQuarter({label: 'Quarter 1', value: 'Quarter 1'})
            loadEvaluationDetailedReport(responses[0].id, "1");
        }

        //get financial year
        // var financialYearResponses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var financialYearResponses = await graphQLGetAllData('listFinancialYears')
        financialYearResponses = financialYearResponses.sort((a, b) => a.name.localeCompare(b.name));
        var myArray = []; myArray = ConvertResponseForSelect(financialYearResponses); setFinancialYearList(myArray);

        //get SBU list
        // var sBUResponses = await DataStore.query(SBU, Predicates.ALL, {
        //     sort: s => s.createdAt(SortDirection.ASCENDING)
        // });
        var sBUResponses = await graphQLGetAllData('listSBUS');
        sBUResponses = sBUResponses.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        var mySBUArray = []; mySBUArray = ConvertResponseForSelect(sBUResponses);
        let zeroPositionParameterObject = { label: "All", value: 0 }; mySBUArray.unshift(zeroPositionParameterObject);
        setSBUList(mySBUArray);

        //get BU list
        // var bIUnitResponses = await DataStore.query(BIUnit, Predicates.ALL, {
        //     sort: s => s.createdAt(SortDirection.ASCENDING)
        // });

        var bIUnitResponses = await graphQLGetAllData('listBIUnits');
        bIUnitResponses = bIUnitResponses.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        var mybUArray = []; mybUArray = ConvertResponseForSelect(bIUnitResponses); setBUList(mybUArray);

        //get region list
        // var regionsResponses = await DataStore.query(Region, Predicates.ALL, {
        //     sort: s => s.createdAt(SortDirection.ASCENDING)
        // });

        var regionsResponses = await graphQLGetAllData('listRegions');
        regionsResponses = regionsResponses.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        var myregionArray = []; myregionArray = ConvertResponseForSelect(regionsResponses); setRegionList(myregionArray);

        //get employee list
        // var employeeResponses = await DataStore.query(Employee, Predicates.ALL, {
        //     sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
        // });

        var employeeResponses = await graphQLGetAllData('listEmployees');
        employeeResponses = employeeResponses.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));

        var myemployeeArray = []; myemployeeArray = ConvertResponseForSelectTwoNames(employeeResponses);
        let zeroPositionEmployeeObject = { label: "All", value: 0 }; myemployeeArray.unshift(zeroPositionEmployeeObject);
        setEmployeeList(myemployeeArray);


    }

    useEffect(() => {
        onLoad();
        getMasterData();
    }, []);

    useEffect(()=>{
        if(dataLoaded === true){
            bindList();
        }
    },[roles])

    return isAuthenticated ?
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            {
                isPageLoaded &&
                <div className="dashboard-main-wrapper dashboard-new pl-16" x-data="{tab: 1}">
                    <div className="p-5 pb-0">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Dashboard</div>
                        </div>
                        <div className="lg:flex justify-between my-6 items-center align-center">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/hr/dashboard/planning" >Planning</Link></li>
                                    <li><Link className="active">Evaluation</Link></li>
                                </ul>
                            </div>
                            <div className="mt-5 lg:mt-0 flex justify-end gap-2 items-center">
                                <div className="w-40">
                                    <Select
                                        options={financialYearList}
                                        value={financialYear}
                                        onChange={(e) => {
                                            setFinancialYear(e)
                                            loadEvaluationDetailedReport(e.value, quarterNo);
                                            loadOverRideActualsRequests(e.value);
                                        }}
                                        maxMenuHeight={200} placeholder="Select Year"
                                        className="my-react-select-container"
                                        classNamePrefix="my-react-select"
                                    />
                                </div>
                                <div className="w-50">
                                    <Select
                                        options={quarterList}
                                        value={quarter}
                                        onChange={(e) => {
                                            setQuarter(e)
                                            if (e.value === "Quarter 1") { loadEvaluationDetailedReport(financialYear.value, "1"); setQuarterNo("1"); }
                                            else if (e.value === "Quarter 2") { loadEvaluationDetailedReport(financialYear.value, "2"); setQuarterNo("2"); }
                                            else if (e.value === "Quarter 3") { loadEvaluationDetailedReport(financialYear.value, "3"); setQuarterNo("3"); }
                                            else if (e.value === "Quarter 4") { loadEvaluationDetailedReport(financialYear.value, "4"); setQuarterNo("4"); }
                                            loadOverRideActualsRequests(financialYear.value);

                                        }}
                                        maxMenuHeight={200} placeholder="Select Quarter"
                                        className="my-react-select-container"
                                        classNamePrefix="my-react-select"
                                    />
                                </div>
                            </div>
                        </div>


                        <div className='mt-10 flex-row grid 2xl:grid-cols-6 xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-1  gap-3 mb-10'>

                            <div className='bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'>
                                <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-5 dark:text-white'>Eligible Employee</h6>
                                <div className='flex gap-3'>
                                    <div className='min-h-[30px] mx-auto' style={{ width: 120, height: 120, }}>
                                        <CircularProgressBar value={eligibleEmployeeBusiness} text={eligibleEmployee} pathColor="#2D638C" trailColor="#9FBED6" />
                                    </div>
                                    <div className=''>
                                        <div className='bg-[#F9FAFB] p-2  border-b-4  border-[#2D638C] rounded-b-md mb-2 dark:bg-[#171717]'>
                                            <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                            <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>{eligibleEmployeeBusinessCount}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>{eligibleEmployeeBusiness}%</span></h3>
                                        </div>
                                        <div className='bg-[#F9FAFB] p-2 border-b-4  border-[#9FBED6] rounded-b-md mb-3 dark:bg-[#171717]'>
                                            <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                            <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>{eligibleEmployeeSalesCount}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>{eligibleEmployeeSales}%</span></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'>
                                <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-5 dark:text-white'>Evaluation Initiated</h6>
                                <div className='flex gap-3'>
                                    <div className='min-h-[30px] mx-auto' style={{ width: 120, height: 120, }}>
                                        <CircularProgressBar value={evaluationInitiatedBusiness} text={evaluationInitiated} pathColor="#F76208" trailColor="#F5DCCC" />
                                    </div>
                                    <div className=''>
                                        <div className='bg-[#F9FAFB] p-2 border-b-4 rounded-b-md border-[#F76208] mb-2 dark:bg-[#171717]'>
                                            <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                            <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>{evaluationInitiatedBusinessCount}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>{evaluationInitiatedBusiness}%</span></h3>
                                        </div>
                                        <div className='bg-[#F9FAFB] p-2 border-b-4 rounded-b-md border-[#F5DCCC] mb-1 dark:bg-[#171717]'>
                                            <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                            <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>{evaluationInitiatedSalesCount}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>{evaluationInitiatedSales}%</span></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='bg-[#fff] p-3 dashboard-box-shadow dark:bg-[#2A2E32]'>
             <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-5 dark:text-white'>Manager Review Pending</h6>
             <div className='flex gap-3'>
                 <div className='min-h-[30px] mx-auto' style={{ width: 120, height: 120, }}>
                     <CircularProgressBar value="50" text="800" pathColor="#EAADF3" trailColor="#AB4ABA" />
                 </div>
                 <div className=''>
                     <div className='bg-[#F9FAFB] p-1.5  border-b-4 rounded-b-md border-[#AB4ABA] mb-3  dark:bg-[#171717]'>
                         <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                         <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>550<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>70%</span></h3>
                     </div>
                     <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#EAADF3] mb-1 dark:bg-[#171717]'>
                         <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                         <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>250<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>30%</span></h3>
                     </div>
                 </div>
             </div>
         </div> */}
                            {/* <div className='bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'>
             <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-5 dark:text-white'>Manager Review Complete</h6>
             <div className='flex gap-3'>
                 <div className='min-h-[30px] mx-auto' style={{ width: 120, height: 120, }}>
                     <CircularProgressBar value="50" text="250" pathColor="#F3D8B2" trailColor="#FF9500" />
                 </div>
                 <div className=''>
                     <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#FF9500] mb-3 dark:bg-[#171717]'>
                         <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                         <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>550<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>70%</span></h3>
                     </div>
                     <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#F3D8B2] mb-1 dark:bg-[#171717]'>
                         <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                         <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>250<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>30%</span></h3>
                     </div>
                 </div>
             </div>
         </div> */}
                            <div className='bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]' >
                                <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-5 dark:text-white'>Override Actuals Request</h6>
                                <div className='flex gap-3'>
                                    <div className='min-h-[30px] mx-auto' style={{ width: 120, height: 120, }}>
                                        <CircularProgressBar value={overrideActualBusiness} text={overrideActualRequest} pathColor="#146A60" trailColor="#CCEBE7" />
                                    </div>
                                    <div className='mt-3'>
                                        <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md  border-[#146A60] mb-3 dark:bg-[#171717]'>
                                            <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                            <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>{overrideActualBusinessCount}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>{overrideActualBusiness}%</span></h3>
                                        </div>
                                        <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#CCEBE7] mb-1 dark:bg-[#171717]'>
                                            <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                            <h3 className='text-[#344054] text-[16px] font-semibold dark:text-white'>{overrideActualSalesCount}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-white'>{overrideActualSales}%</span></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'>
                                <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white '>Company/ SBU Actual</h6>

                                <div className='mt-3 text-center'>
                                    <div className='bg-[#E5F3EC] p-6 mb-3 rounded-md flex items-center gap-2 justify-center mt-14'>
                                        <h2 className='w-3 h-3 bg-[#029046] rounded-full'></h2>
                                        <p className='text-[#029046] text-[16px] font-medium my-2'>
                                            Completed</p>

                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="bg-white rounded-lg mt-10 p-3 px-3 dark:bg-[#2A2E32] mt-15  border border-[#E4E7EC] dark:border-[#344054]">
                            <div>
                                <div className="flex overflow-auto rounded-lg mt-5 ">
                                    {/* <DataTable paginator value={evaluationData} style={{ width: '93vw' }}
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                 >
                     <Column field="requestId" header="Request ID" sortable filter filterPlaceholder="Search by Request ID"></Column>
                     <Column field="employeeId" header="Employee ID" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                     <Column field="name" header="Name" sortable filter filterPlaceholder="Search by Name"></Column>
                     <Column field="role" header="Role" sortable filter filterPlaceholder="Search by Role"></Column>
                     <Column field="vertical" header="Vertical" sortable filter filterPlaceholder="Search by Vertical"></Column>
                     <Column field="SBU" header="SBU" sortable filter filterPlaceholder="Search by SBU"></Column>
                     <Column field="reportingManager" header="Reporting Manager" sortable filter filterPlaceholder="Search by Reporting Manager"></Column>
                     <Column field='dateSubmitted' header="Date Submitted" filter filterPlaceholder="Search by Submitted Date" ></Column>
                     <Column field='DateApproved' header="Date Approved" filter filterPlaceholder="Search by Approved Date"  ></Column>
                     <Column field='status' header="Status" filter filterPlaceholder="Search by Status" ></Column>
                     <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnRoles} ></Column>
                 </DataTable> */}

                                    <DataTable paginator value={evaluationData} style={{ width: '93vw' }}
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                    >
                                        <Column field="rank" header="Rank" sortable filter filterPlaceholder="Search by Request ID"></Column>
                                        <Column field="reportingManager" header="Reporting Manager" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                        <Column field="totalReportee" header="Total Reportee" sortable filter filterPlaceholder="Search by Name"></Column>
                                        <Column field="eligible" header="Eligible" sortable filter filterPlaceholder="Search by Role"></Column>
                                        <Column field="initiated" header="Initiated" sortable filter filterPlaceholder="Search by Vertical"></Column>
                                        {/* <Column field="reviewPending" header="Review Pending" sortable filter filterPlaceholder="Search by SBU"></Column>
                     <Column field="reviewCompleted" header="Review Completed" sortable filter filterPlaceholder="Search by Reporting Manager"></Column>
                     <Column field="completed" header="% Completed" filter filterPlaceholder="Search by Submitted Date" ></Column> */}
                                        {/* <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnRoles} ></Column> */}
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }



            {
                !isPageLoaded &&
                <div className='text-center py-8' ><ProgressSpinner /></div>
            }


            <div id="selector"></div>


        </div>
        : <HRLogin sessionExpired={1} />
}