
import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ValidateEmail from '../../components/common/ValidateEmail';
import AppLogoWhite from '../../assets/images/logo.png'
import loginImage from '../../assets/images/login-img.png';

export default function HRForgotPassword() {

    let navigateTo = useNavigate();
    const [email, setEmail] = useState("");
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);

    async function resetPassword(event) {
        event.preventDefault();
        if (email === "") { toast.error('Enter email.'); return; }
        if (!ValidateEmail(email)) { toast.error('Invalid email.'); return; }

        //Send confirmation code to user's email
        setPleaseWaitButton(true)
        Auth.forgotPassword(email)
            .then(data => {
                if (data) {
                    toast.success("Verification code sent to your Email!")
                    setPleaseWaitButton(false)
                    navigateTo("/hr/resetpassword", { state: { email: email } })
                }
            }).catch(e => {
                setPleaseWaitButton(false)
                switch (e.message) {
                    case 'Cannot reset password for the user as there is no registered/verified email or phone_number.':
                        toast.error(e.message);
                        break;
                    default:
                        toast.error(e.message);
                }
            })
    }
    return (
        <div className="min-h-screen flex-row align-center grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:grid-cols-1 gap-4">
            <div className="flex flex-wrap flex-row align-center justify-center top-90 auto-rows-max">
                <div className="max-w-md w-full p-2 mt-24">
                    <form action="#" onSubmit={resetPassword} autoComplete="off">
                        <div className="mb-10">
                            <img src={AppLogoWhite} />
                        </div>
                        <div className="mb-2">
                            <h2 className="text-[30px] font-semibold text-[#101828] dark:text-[#FFFFFF]">Reset Your Password</h2>
                        </div>

                        <div className="relative mb-6">
                            <label htmlFor="required-email" className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                Email
                                <span className="text-red-500 required-dot">
                                    *
                                </span>
                            </label>
                            <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" id="required-email" className="text-lg rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-indigo-600 mt-2 dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" placeholder="Enter email" />
                        </div>
                        <div className="flex w-full mb-8">
                            <button type="submit" className="py-2.5 px-5 bg-btn-blue hover:bg-btn-blue focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-1 focus:ring-offset-2  rounded-lg ">
                                {showPleaseWaitButton ? 'Please wait...' : 'Submit'}
                            </button>
                        </div>
                        <div className="flex align-center justify-center">
                            <Link
                                to={"/hr"}
                                className="dark:text-[#FFFFFF]"
                            >
                                Back to Sign in
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
            <div className="login-bg flex-row flex flex-wrap align-center justify-center content-center">
                <div className=" py-10 px-16">
                    <div className="auto-rows-max flex-row">
                        <img src={loginImage} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}