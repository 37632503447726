import { Menu, Transition } from "@headlessui/react";
import { Avatar } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, Link } from "react-router-dom";
import { Auth, Storage } from 'aws-amplify';
import { reactLocalStorage } from 'reactjs-localstorage';
import Bussiness from "../../../assets/svg/connectpro/business-people-casual-meeting 1.png";
import { ProgressSpinner } from 'primereact/progressspinner';
import DataTables from 'react-data-table-component';
import { DataTableCustomStyles } from '../../helper/dataTable.config';
import { GetAWSObjectURL } from '../../helper/aws';
import { validateFileExtension } from "../../helper/commonfunctions";
import { toast } from 'react-toastify';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import Select from 'react-select';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { SBU, Company, CustomerSegment, Verticals, Division, PlantStorageLocation, Materials, BIUnit, Region, Brand, ProductGroup, ProfitCenter, Country, CustomerCategory, Customer, GTM, SubGTM } from '../../../models';
import capitalizeFirst from "../../common/capitalizeFirst";



export default function ImportDimension(props) {

    let navigateTo = useNavigate();

    //*state
    const [isLoader, setIsLoader] = useState(false);
    const [isShowPreviewDataTable, setIsShowPreviewDataTable] = useState(false);
    const [popupTitle, setPopupTitle] = useState('Data');
    const [validatedCounter, setIsValidatedCounter] = useState(0);
    const [isShowBrowseFile, setIsShowBrowseFile] = useState(true);
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [validRecord, setValidRecord] = useState(0);
    const [inValidRecord, setInValidRecord] = useState(0);




    //*list
    const [importData, setImportData] = useState([]);
    const [fileObj, setFileObj] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [responseHeaderData, setResponseHeaderData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [partnerDataColumns, setPartnerDataColumns] = useState([]);

    //*SBU________
    const [sbu, setSbu] = useState("");
    const [sbuId, setSbuId] = useState("");
    const [sbuCode, setSbuCode] = useState("");
    const [sbuCodeId, setSbuCodeId] = useState("");
    const [sbuList, setSbuList] = useState([]);

    //*get SBUCode
    const getSbuCode = (REF_CODE) => {
        let _sbuList = [...sbuList];
        var returnRecord = _sbuList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*get SBU Name
    const getSbuId = (name) => {
        let _sbuList = [...sbuList];
        var returnRecord = _sbuList.find(o => o.name?.toLowerCase().trim() === name?.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord; } else { return false; }
    }

    //*data table
    const sbuDataColumns = [
        {
            name: 'SBU',
            selector: row => row.sbu,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike === 1 ? "strike" : ""}>
                        {row.sbu}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike === 1 ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
    ];

    //*End SBU________


    //*BU________
    const [bu, setBu] = useState("");
    const [buId, setBuId] = useState("");
    const [buCode, setBuCode] = useState("");
    const [buCodeId, setBuCodeId] = useState("");
    const [buSBU, setBuSBU] = useState("");
    const [buSBUId, setBuSBUId] = useState("");
    const [buPG, setBuPG] = useState("");
    const [buPGId, setBuPGId] = useState("");
    const [buList, setBuList] = useState([]);

    //*get getBuCode
    const getBuCode = (REF_CODE) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*get getBuName
    const getBuSBUName = (name) => {
        let _sbuList = [...sbuList];
        var returnRecord = _sbuList.find(o => o.name === String(name));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*get getBuSBUId
    const getBuSBUId = (name) => {
        let _sbuList = [...sbuList];
        var returnRecord = _sbuList.find(o => o.name?.toLowerCase() === name?.toLowerCase());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*data table
    const buDataColumns = [
        {
            name: 'Business Unit',
            selector: row => row.bu,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike === 1 ? "strike" : ""}>
                        {row.bu}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike === 1 ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
        {
            name: 'SBU',
            selector: row => row.sbu,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike === 1 ? "strike" : ""}>
                        {row.sbu}
                    </div>
                </>
            ),
        },
        // {
        //     name: 'Product Group',
        //     selector: row => row.pG,
        //     sortable: true,
        //     cell: (row) => (
        //         <>
        //             <div className={row.strike === 1 ? "strike" : ""}>
        //                 {row.pG}
        //             </div>
        //         </>
        //     ),
        // },
    ];

    //*End BU________

    //*Brand________
    const [brand, setBrand] = useState("");
    const [brandId, setBrandId] = useState("");
    const [brandCode, setBrandCode] = useState("");
    const [brandCodeId, setBrandCodeId] = useState("");
    const [brandSBU, setBrandSBU] = useState("");
    const [brandSBUId, setBrandSBUId] = useState("");
    const [brandBu, setBrandBu] = useState("");
    const [brandBuId, setBrandBuId] = useState("");
    const [brandList, setBrandList] = useState([]);

    //*get getBrandCode
    const getBrandCode = (REF_CODE) => {
        let _brandList = [...brandList];
        var returnRecord = _brandList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) {
            return true;
        } else {
            return false;
        }
    }

    //*get getBrandCode
    const getBrandBUName = (name) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.name === String(name));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*get getBrandBUId
    const getBrandBUId = (name) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.name?.toLowerCase() === name?.toLowerCase());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*data table
    const brandDataColumns = [
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
        {
            name: 'Brand',
            selector: row => row.brand,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike === 1 ? "strike" : ""}>
                        {row.brand}
                    </div>
                </>
            ),
        },
        {
            name: 'SBU',
            selector: row => row.sbu,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike === 1 ? "strike" : ""}>
                        {row.sbu}
                    </div>
                </>
            ),
        },
        {
            name: 'BU',
            selector: row => row.bu,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike === 1 ? "strike" : ""}>
                        {row.bu}
                    </div>
                </>
            ),
        },
    ];

    //*End Brand________

    //*PG________
    const [pG, setPG] = useState("");
    const [pGId, setPGId] = useState("");
    const [pGCode, setPGCode] = useState("");
    const [pGCodeId, setPGCodeId] = useState("");
    const [pGBrand, setPGBrand] = useState("");
    const [pGBrandId, setPGBrandId] = useState("");
    const [pGList, setPGList] = useState([]);
    const [pgSBU, setPgSBU] = useState("");
    const [pgSBUId, setPgSBUId] = useState("");
    const [pgBU, setPgBU] = useState("");
    const [pgBUId, setPgBUId] = useState("");

    //*get getPgCode
    const getPgCode = (REF_CODE) => {
        let _pGList = [...pGList];
        var returnRecord = _pGList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*get getPgBrandName
    const getPgBrandName = (name) => {
        let _brandList = [...brandList];
        var returnRecord = _brandList.find(o => o.name?.toLowerCase() === name?.toLowerCase());
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    const getPGBrandId = (name, sbu) => {
        let _sbuList = [...sbuList];
        var sbuRecord = _sbuList.find(o => o.name?.toLowerCase().trim() === String(sbu?.toLowerCase().trim()));

        let _brandList = [...brandList];
        var returnRecord = _brandList.find(o => o.name?.toLowerCase() === name?.toLowerCase() && o.SBU_id?.toLowerCase() === sbuRecord.id?.toLowerCase());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    const getPGBrandIdBasedOnSBU = (sbuId, name) => {
        let _brandList = [...brandList];
        var returnRecord = _brandList.find(o => o.name?.toLowerCase() === name?.toLowerCase());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*data table
    const pGDataColumns = [
        {
            name: 'Product Group',
            selector: row => row.pG,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.pG}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
        {
            name: 'SBU',
            selector: row => row.sbu,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.sbu}
                    </div>
                </>
            ),
        },
        {
            name: 'BU',
            selector: row => row.bu,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.bu}
                    </div>
                </>
            ),
        },
        {
            name: 'Brand',
            selector: row => row.brand,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.brand}
                    </div>
                </>
            ),
        },
    ];

    //*End PG________

    //*PC________
    const [pC, setPC] = useState("");
    const [pCId, setPCId] = useState("");
    const [pCCode, setPCCode] = useState("");
    const [pCCodeId, setPCCodeId] = useState("");
    const [pCPg, setPCPg] = useState("");
    const [pCPgId, setPCPgId] = useState("");
    const [pCList, setPCList] = useState([]);

    //*get getPcCode
    const getPcCode = (REF_CODE) => {
        let _pCList = [...pCList];
        var returnRecord = _pCList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*get getPcPGName
    const getPcPGName = (name) => {
        let _pGList = [...pGList];
        var returnRecord = _pGList.find(o => o.name?.toLowerCase() === name?.toLowerCase());
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    const getPcPgId = (name) => {
        let _pGList = [...pGList];
        var returnRecord = _pGList.find(o => o.name?.toLowerCase() === name?.toLowerCase());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*data table
    const pCDataColumns = [
        {
            name: 'Profit Center',
            selector: row => row.pC,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.pC}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
        {
            name: 'Product Group',
            selector: row => row.pG,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.pG}
                    </div>
                </>
            ),
        },
    ];

    //*End PC________

    //*Region________
    const [region, setRegion] = useState("");
    const [regionId, setRegionId] = useState("");
    const [regionCode, setRegionCode] = useState("");
    const [regionCodeId, setRegionCodeId] = useState("");
    const [regionSBU, setRegionSBU] = useState("");
    const [regionSBUId, setRegionSBUId] = useState("");
    const [regionList, setRegionList] = useState([]);

    //*get getRegionCode
    const getRegionCode = (REF_CODE) => {
        let _regionList = [...regionList];
        var returnRecord = _regionList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*data table
    const regionDataColumns = [
        {
            name: 'Cluster',
            selector: row => row.region,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.region}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
        {
            name: 'SBU',
            selector: row => row.sbu,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.sbu}
                    </div>
                </>
            ),
        },
    ];

    //*End Region________

    //*Country________
    const [country, setCountry] = useState("");
    const [countryId, setCountryId] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [countryCodeId, setCountryCodeId] = useState("");
    const [vat, setVat] = useState("");
    const [vatId, setVatId] = useState("");
    const [countryRegion, setCountryRegion] = useState("");
    const [countrySBU, setCountrySBU] = useState("");
    const [countrySBUId, setCountrySBUId] = useState("");
    const [countryRegionId, setCountryRegionId] = useState("");
    const [countryList, setCountryList] = useState([]);

    //*get getCountryCode
    const getCountryCode = (REF_CODE) => {
        let _countryList = [...countryList];
        var returnRecord = _countryList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*get getRegionName
    const getRegionName = (name) => {
        let _regionList = [...regionList];
        var returnRecord = _regionList.find(o => o.name?.toLowerCase() === String(name?.toLowerCase()));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*get RegionId________
    const getRegionIdBasedOnSbuId = (sbuId, regionId) => {
        let _regionList = [...regionList];
        if (regionId) {
            var returnRecord = _regionList.find(o => (o.SBU_id?.toLowerCase().trim() === sbuId?.toLowerCase().trim() && o.name?.toLowerCase().trim() === regionId?.toLowerCase().trim()));
            if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
        }
    }

    const getRegionId = (name, sbuName) => {
        let _regionList = [...regionList];
        let _sbuList = [...sbuList];

        var sbuRecord = _sbuList.find(o => o.name?.toLowerCase().trim() === String(sbuName?.toLowerCase().trim()));
        var returnRecord = _regionList.find(o => o.name?.toLowerCase().trim() === String(name?.toLowerCase().trim()) && o.SBU_id === sbuRecord.id);

        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*data table
    const countryDataColumns = [
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.country}
                    </div>
                </>
            ),
        },
        {
            name: 'Cluster',
            selector: row => row.region,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.region}
                    </div>
                </>
            ),
        },

        {
            name: 'SBU',
            selector: row => row.sbu,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.sbu}
                    </div>
                </>
            ),
        },

        {
            name: 'VAT',
            selector: row => row.vat,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.vat}
                    </div>
                </>
            ),
        },
    ];

    //*End Country________


    //*Company________
    const [company, setCompany] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [companyCode, setCompanyCode] = useState("");
    const [companyCodeId, setCompanyCodeId] = useState("");
    const [companyList, setCompanyList] = useState([]);

    //*get SBUCode
    const getCompanyCode = (REF_CODE) => {
        let _companyList = [...companyList];
        var returnRecord = _companyList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*data table
    const companyDataColumns = [
        {
            name: 'Company',
            selector: row => row.company,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.company}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
    ];

    //*End Company________


    //*Customer Segment________
    const [customerSegment, setCustomerSegment] = useState("");
    const [customerSegmentId, setCustomerSegmentId] = useState("");
    const [customerSegmentCode, setCustomerSegmentCode] = useState("");
    const [customerSegmentCodeId, setCustomerSegmentCodeId] = useState("");
    const [customerSegmentList, setCustomerSegmentList] = useState([]);

    //*get getCustomerSegmentCode
    const getCustomerSegmentCode = (REF_CODE) => {
        let _customerSegmentList = [...customerSegmentList];
        var returnRecord = _customerSegmentList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*data table
    const customerSegmentDataColumns = [
        {
            name: 'Segment',
            selector: row => row.customerSegment,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.customerSegment}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
    ];

    //*End Customer Segment________

    //*Customer Group________
    const [customerGroup, setCustomerGroup] = useState("");
    const [customerGroupId, setCustomerGroupId] = useState("");
    const [customerGroupCode, setCustomerGroupCode] = useState("");
    const [customerGroupCodeId, setCustomerGroupCodeId] = useState("");
    const [customerGroupCS, setCustomerGroupCS] = useState("");
    const [customerGroupCSId, setCustomerGroupCSId] = useState("");
    const [customerGroupGtm, setCustomerGroupGtm] = useState("");
    const [customerGroupGtmId, setCustomerGroupGtmId] = useState("");
    const [customerGroupList, setCustomerGroupList] = useState([]);

    //*get getCustomerGroupCode
    const getCustomerGroupCode = (REF_CODE) => {
        let _customerGroupList = [...customerGroupList];
        var returnRecord = _customerGroupList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*get getCustomerSegmentName
    const getCustomerSegmentName = (name) => {
        let _customerSegmentList = [...customerSegmentList];
        var returnRecord = _customerSegmentList.find(o => o.name?.toLowerCase() === name?.toLowerCase());
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    const getCustomerSegmentId = (name) => {
        let _customerSegmentList = [...customerSegmentList];
        var returnRecord = _customerSegmentList.find(o => o.name === String(name));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    const getCustomerGmtId = (name) => {
        let _gtmList = [...gtmList];
        var returnRecord = _gtmList.find(o => o.name === String(name));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*data table
    const customerGroupDataColumns = [
        {
            name: 'Group',
            selector: row => row.customerCategory,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.customerCategory}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
        {
            name: 'Customer Segment',
            selector: row => row.customerSegment,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.customerSegment}
                    </div>
                </>
            ),
        },
        {
            name: 'GTM',
            selector: row => row.gtm,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.gtm}
                    </div>
                </>
            ),
        },
    ];

    //*End Customer Group________

    //*Customer ________
    const [customer, setCustomer] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [customerCode, setCustomerCode] = useState("");
    const [customerCodeId, setCustomerCodeId] = useState("");
    const [customerCG, setCustomerCG] = useState("");
    const [customerCGId, setCustomerCGId] = useState("");
    const [customerCountry, setCustomerCountry] = useState("");
    const [customerCountryId, setCustomerCountryId] = useState("");
    const [customerList, setCustomerList] = useState([]);

    //*get getCustomerCode
    const getCustomerCode = (REF_CODE) => {
        let _customerList = [...customerList];
        var returnRecord = _customerList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*get getCustomerGroupName
    const getCustomerGroupName = (name) => {
        let _customerGroupList = [...customerGroupList];
        var returnRecord = _customerGroupList.find(o => o.name?.toLowerCase() === name?.toLowerCase());
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*get getCountryName
    const getCountryName = (name) => {
        let _countryList = [...countryList];
        var returnRecord = _countryList.find(o => o.name?.toLowerCase() === name?.toLowerCase());
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*get getCustomerGroupId
    const getCustomerGroupId = (name) => {
        let _customerGroupList = [...customerGroupList];
        var returnRecord = _customerGroupList.find(o => o.name === String(name));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*get getCustomerCountryId
    const getCustomerCountryId = (name) => {
        let _countryList = [...countryList];
        var returnRecord = _countryList.find(o => o.name === String(name));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*data table
    const customerDataColumns = [
        {
            name: 'Customer',
            selector: row => row.customer,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.customer}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
        {
            name: 'Group',
            selector: row => row.customerGroup,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.customerGroup}
                    </div>
                </>
            ),
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.country}
                    </div>
                </>
            ),
        },
    ];

    //*End Customer ________

    //*Vertical________
    const [vertical, setVertical] = useState("");
    const [verticalId, setVerticalId] = useState("");
    const [verticalCode, setVerticalCode] = useState("");
    const [verticalCodeId, setVerticalCodeId] = useState("");
    const [verticalList, setVerticalList] = useState([]);

    //*get getCustomerSegmentCode
    const getVerticalCode = (REF_CODE) => {
        let _verticalList = [...verticalList];
        var returnRecord = _verticalList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*data table
    const verticalDataColumns = [
        {
            name: 'Verticals',
            selector: row => row.vertical,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.vertical}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
    ];

    //*End Vertical________

    //*Division________
    const [division, setDivision] = useState("");
    const [divisionId, setDivisionId] = useState("");
    const [divisionCode, setDivisionCode] = useState("");
    const [divisionCodeId, setDivisionCodeId] = useState("");
    const [divisionList, setDivisionList] = useState([]);

    //*get getDivisionCode
    const getDivisionCode = (REF_CODE) => {
        let _divisionList = [...divisionList];
        var returnRecord = _divisionList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*data table
    const divisionDataColumns = [
        {
            name: 'Division',
            selector: row => row.division,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.division}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
    ];

    //*End Division________

    //*PlantStorageLocation________
    const [plantStorageLocation, setPlantStorageLocation] = useState("");
    const [plantStorageLocationId, setPlantStorageLocationId] = useState("");
    const [plantStorageLocationCode, setPlantStorageLocationCode] = useState("");
    const [plantStorageLocationCodeId, setPlantStorageLocationCodeId] = useState("");
    const [plantStorageLocationList, setPlantStorageLocationList] = useState([]);

    //*get getPlantStorageLocation
    const getPlantStorageLocationCode = (REF_CODE) => {
        let _plantStorageLocationList = [...plantStorageLocationList];
        var returnRecord = _plantStorageLocationList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*data table
    const plantStorageLocationDataColumns = [
        {
            name: 'Plant Storage Location',
            selector: row => row.plantStorageLocation,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.plantStorageLocation}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
    ];

    //*End PlantStorageLocation________

    //*Material________
    const [material, setMaterial] = useState("");
    const [materialId, setMaterialId] = useState("");
    const [materialCode, setMaterialCode] = useState("");
    const [materialCodeId, setMaterialCodeId] = useState("");
    const [materialList, setMaterialList] = useState([]);

    //*get getPlantStorageLocation
    const getMaterialCode = (REF_CODE) => {
        let _materialList = [...materialList];
        var returnRecord = _materialList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*data table
    const materialDataColumns = [
        {
            name: 'Material',
            selector: row => row.material,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.material}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
    ];

    //*End Material________

    //*GTM________
    const [gtm, setGtm] = useState("");
    const [gtmId, setGtmId] = useState("");
    const [gtmCode, setGtmCode] = useState("");
    const [gtmCodeId, setGtmCodeId] = useState("");
    const [gtmList, setGtmList] = useState([]);

    const [subGtmList, setSubGtmList] = useState([]);
    const [subGtm, setSubGtm] = useState("");
    const [subGtmId, setSubGtmId] = useState("");
    const [subGtmCode, setSubGtmCode] = useState("");
    const [subGtmCodeId, setSubGtmCodeId] = useState("");

    //*get getPlantStorageLocation
    const getGTMCode = (REF_CODE) => {
        let _gtmList = [...gtmList];
        var returnRecord = _gtmList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    const getSubGTMCode = (REF_CODE) => {
        let _subGtmList = [...subGtmList];
        var returnRecord = _subGtmList.find(o => o.REF_CODE === String(REF_CODE));
        if (returnRecord !== null && returnRecord !== undefined) { return true; } else { return false; }
    }

    //*data table
    const gtmDataColumns = [
        {
            name: 'GTM',
            selector: row => row.gtm,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.gtm}
                    </div>
                </>
            ),
        },
        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
    ];
    //*End GTM________

    const subGtmDataColumns = [
        {
            name: 'SUB GTM',
            selector: row => row.subGtm,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.subGtm}
                    </div>
                </>
            ),
        },

        {
            name: 'GTM',
            selector: row => row.gtmName,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.gtmName}
                    </div>
                </>
            ),
        },

        {
            name: 'Ref Code',
            selector: row => row.refCode,
            sortable: true,
            cell: (row) => (
                <>
                    <div className={row.strike ? "strike" : ""}>
                        {row.refCode}
                    </div>
                </>
            ),
        },
    ];
    //*End SUBGTM________

    //*download document
    async function downloadDocument() {
        var fileType  = props.type;
        var filePath = '';

        if(fileType == 'SBU') {filePath = 'public/samplefiles/sbuTemplate.csv'}
        else if(fileType == 'BU') {filePath = 'public/samplefiles/buTemplate.csv'}
        else if(fileType == 'Brand') {filePath = 'public/samplefiles/brandTemplate.csv'}
        else if(fileType == 'PG') {filePath = 'public/samplefiles/pgTemplate.csv'}
        else if(fileType == 'ProfitCenter') {filePath = 'public/samplefiles/profitCenterTemplate.csv'}
        else if(fileType == 'Region') {filePath = 'public/samplefiles/clusterTemplate.csv'}
        else if(fileType == 'Country') {filePath = 'public/samplefiles/countryTemplate.csv'}
        else if(fileType == 'Company') {filePath = 'public/samplefiles/companyTemplate.csv'}
        else if(fileType == 'CustomerSegment') {filePath = 'public/samplefiles/customerSegmentTemplate.csv'}
        else if(fileType == 'CustomerCategory') {filePath = 'public/samplefiles/customerCategoryTemplate.csv'}
        else if(fileType == 'Customer') {filePath = 'public/samplefiles/customerTemplate.csv'}
        else if(fileType == 'Vertical') {filePath = 'public/samplefiles/verticalTemplate.csv'}
        else if(fileType == 'Division') {filePath = 'public/samplefiles/divisionTemplate.csv'}
        else if(fileType == 'PlantStorageLocation') {filePath = 'public/samplefiles/plantStorageLocationTemplate.csv'}
        else if(fileType == 'Material') {filePath = 'public/samplefiles/materialTemplate.csv'}
        else if(fileType == 'GTM') {filePath = 'public/samplefiles/gtmTemplate.csv'}
        else if(fileType == 'SUBGTM') {filePath = 'public/samplefiles/subGtmTemplate.csv'}
        else if(fileType == '') {return;}

        let filePathURL = await GetAWSObjectURL(filePath);
        const link = document.createElement("a");
        link.href = filePathURL;
        link.download = "";
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    //*upload attachments
    async function changeAttachment(e) {
        let fileObj = e.target.files[0];
        //just pass the fileObj as parameter
        if (fileObj.size === 0) {
            toast.error('Do not select empty file.');
        } else {
            ExcelRenderer(fileObj, (err, resp) => {
                if (err) {
                    console.log(err);
                }
                else {
                    var responseData = resp.rows
                    var newResponseArray = responseData.slice(1);
                    setResponseData(newResponseArray)
                    let headersArray = resp.rows[0]
                    let finalData = [];
                    for (let i = 0; i < headersArray.length; i++) {

                        finalData.push({ label: headersArray[i], value: i })
                        if (i === headersArray.length - 1) {
                            let sortArray = finalData.sort((a, b) => (a.label > b.label ? 1 : -1))
                            setResponseHeaderData(sortArray)
                            setIsShowDropdown(true)
                        }
                    }
                }
            });
        }
    }

    //*Preview Data
    async function previewData() {
        if (props.type === "SBU") {
            mapSBU();
            setPartnerDataColumns(sbuDataColumns)
        }
        else if (props.type === "BU") {
            mapBU();
            setPartnerDataColumns(buDataColumns)
        }
        else if (props.type === "Brand") {
            mapBrand();
            setPartnerDataColumns(brandDataColumns)
        }
        else if (props.type === "PG") {
            mapPG();
            setPartnerDataColumns(pGDataColumns)
        }
        else if (props.type === "ProfitCenter") {
            mapPC();
            setPartnerDataColumns(pCDataColumns)
        }
        else if (props.type === "Region") {
            mapRegion();
            setPartnerDataColumns(regionDataColumns)
        }
        else if (props.type === "Country") {
            mapCountry();
            setPartnerDataColumns(countryDataColumns)
        }
        else if (props.type === "Company") {
            mapCompany();
            setPartnerDataColumns(companyDataColumns)
        }
        else if (props.type === "CustomerSegment") {
            mapCustomerSegment();
            setPartnerDataColumns(customerSegmentDataColumns)
        }
        else if (props.type === "CustomerCategory") {
            mapCustomerCategory();
            setPartnerDataColumns(customerGroupDataColumns)
        }
        else if (props.type === "Customer") {
            mapCustomer();
            setPartnerDataColumns(customerDataColumns)
        }
        else if (props.type === "Vertical") {
            mapVerticals();
            setPartnerDataColumns(verticalDataColumns)
        }
        else if (props.type === "Division") {
            mapDivision();
            setPartnerDataColumns(divisionDataColumns)
        }
        else if (props.type === "PlantStorageLocation") {
            mapPlantStorageLocation();
            setPartnerDataColumns(plantStorageLocationDataColumns)
        }
        else if (props.type === "Material") {
            mapMaterial();
            setPartnerDataColumns(materialDataColumns)
        }
        else if (props.type === "GTM") {
            mapGTM();
            setPartnerDataColumns(gtmDataColumns)
        }
        else if (props.type === "SUBGTM") {
            mapSubGTM();
            setPartnerDataColumns(subGtmDataColumns)
        }
    }

    //*Map SBU
    async function mapSBU() {

        let sbuData = responseData
        let finalResponseData = [];
        for (let i = 0; i < sbuData.length; i++) {

            if (sbuId !== "" && sbuCodeId !== "") {

                // if (sbuData[i][sbuId] !== undefined && sbuData[i][sbuCodeId] !== undefined) {

                var sbu = sbuData[i][sbuId];
                var refCode = sbuData[i][sbuCodeId];

                var strike = 0;
                let checkExiting = getSbuCode(refCode)
                //check Duplicates
                let _finalResponseData = [...finalResponseData];
                let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)
                if (checkExiting === true || check_parameterData === false || sbu === undefined || refCode === undefined) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "sbu": sbu, "refCode": refCode, "strike": strike
                });

                // }

            } else {
                toast.error('SBU and Ref Code should not be empty field.');
                return false;
            }

            if (i === sbuData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }
        }

    }

    //*Map BU
    async function mapBU() {

        let buData = responseData
        let finalResponseData = [];
        for (let i = 0; i < buData.length; i++) {

            if (buId !== "" && buCodeId !== "" && buSBUId !== "") {

                // if (buData[i][buId] !== undefined && buData[i][buCodeId] !== undefined && buData[i][buSBUId] !== undefined) {

                var bu = buData[i][buId];
                var refCode = buData[i][buCodeId];
                var sbu = buData[i][buSBUId];
                //var pG = buData[i][buPGId] === undefined ? "-" : buData[i][buPGId]

                var strike = 0;
                let checkExitingCode = getBuCode(refCode)
                //check Duplicates
                let _finalResponseData = [...finalResponseData];

                // BU reference code can be duplicate
                // let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)
                if (bu === undefined || refCode === undefined || sbu === undefined) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    // "bu": bu, "refCode": refCode, "sbu": sbu, "pG": pG, "strike": strike
                    "bu": bu, "refCode": refCode, "sbu": sbu, "strike": strike
                });
                // }

            } else {
                toast.error('BU, Ref Code And SBU should not be empty field.');
                return false;
            }

            if (i === buData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }
        }

    }

    //*Map Brand
    async function mapBrand() {

        let brandData = responseData
        let finalResponseData = [];
        for (let i = 0; i < brandData.length; i++) {
            if (brandId !== "" && brandCodeId !== "" && brandSBUId !== "" && brandBuId !== "") {

                // if (brandData[i][brandId] !== undefined && brandData[i][brandCodeId] !== undefined && brandData[i][brandSBUId] !== undefined && brandData[i][brandBuId] !== undefined) {

                var brand = brandData[i][brandId];
                var refCode = brandData[i][brandCodeId];
                var sbu = brandData[i][brandSBUId];
                var bu = brandData[i][brandBuId];

                var strike = 0;
                let checkExitingCode = getBrandCode(refCode)
                // let checkExistingSBU = getBuSBUName(sbu)
                // let checkExitingBU = getBrandBUName(bu)
                //check Duplicates
                let _finalResponseData = [...finalResponseData];
                let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)

                // based on Requirement code changed on 04-05-2023 ---- REMOVE DUPLICATE REF CODE (Confirmation from Ganesh Sir)
                // if (checkExitingCode === true || check_parameterData === false || brand === undefined || refCode === undefined || sbu === undefined || bu === undefined) { strike = 1 } else (strike = 0)

                if (brand === undefined || refCode === undefined || sbu === undefined || bu === undefined) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "brand": brand, "refCode": refCode, "sbu": sbu, "bu": bu, "strike": strike
                });
                // }

            } else {
                toast.error('Brand, Ref Code ,SBU and BU should not be empty field.');
                return false;
            }

            if (i === brandData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }

        }

    }

    //*Map PG
    async function mapPG() {

        let pGData = responseData
        let finalResponseData = [];
        for (let i = 0; i < pGData.length; i++) {

            if (pGId !== "" && pGCodeId !== "" && pGBrandId !== "" && pgSBUId !== "") {

                // if (pGData[i][pGId] !== undefined && pGData[i][pGCodeId] !== undefined && pGData[i][pGBrandId] !== undefined) {

                var pG = pGData[i][pGId];
                var refCode = pGData[i][pGCodeId];
                var brand = pGData[i][pGBrandId] === undefined ? "" : pGData[i][pGBrandId];
                var bu = pGData[i][pgBUId];
                var sbu = pGData[i][pgSBUId];

                var strike = 0;
                let checkExitingCode = getPgCode(refCode)
                let checkExistingBrand = getPgBrandName(brand)
                let checkSBU = getSbuId(pGData[i][pgSBUId])

                // let checkBrandBasedOnSBU = getPGBrandIdBasedOnSBU()
                let duplicateRecord = finalResponseData.some(entry => entry.pG === pG && entry.brand === brand && entry.sbu === sbu && entry.refCode === refCode);

                //check Duplicates
                let _finalResponseData = [...finalResponseData];
                // let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)
                if (checkExitingCode === true || duplicateRecord === true ||
                    pG === undefined || refCode === undefined || brand === undefined || sbu === false || checkExistingBrand === false
                ) { strike = 1 } else (strike = 0)
                finalResponseData.push({
                    "pG": pG, "refCode": refCode, "sbu": sbu, "bu": bu, "brand": brand, "strike": strike
                });
                // }

            } else {
                toast.error('Product Group, Ref Code, SBU and Brand should not be empty field.');
                return false;
            }

            if (i === pGData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }

        }

    }

    //*Map PC
    async function mapPC() {

        let pCData = responseData
        let finalResponseData = [];
        for (let i = 0; i < pCData.length; i++) {
            if (pCId !== "" && pCCodeId !== "" && pCPgId !== "") {

                // if (pCData[i][pCId] !== undefined && pCData[i][pCCodeId] !== undefined && pCData[i][pCPgId] !== undefined) {

                var pC = pCData[i][pCId];
                var refCode = pCData[i][pCCodeId];
                var pG = pCData[i][pCPgId];

                var strike = 0;
                let checkExitingCode = getPcCode(refCode)
                let checkExistingPg = getPcPGName(pG)

                //check Duplicates
                let _finalResponseData = [...finalResponseData];
                let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)

                // based on Requirement code changed on 04-05-2023 ---- REMOVE DUPLICATE REF CODE (Confirmation from Ganesh Sir)
                // if (checkExitingCode === true || check_parameterData === false || pC === undefined || refCode === undefined || pG === undefined || checkExistingPg === false) { strike = 1 } else (strike = 0)
                if (pC === undefined || refCode === undefined || pG === undefined || checkExistingPg === false) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "pC": pC, "refCode": refCode, "pG": pG, "strike": strike
                });
                // }

            } else {
                toast.error('Profit Center, Ref Code and Product Group should not be empty field.');
                return false;
            }

            if (i === pCData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }

        }

    }

    //*Map Region
    async function mapRegion() {

        let regionData = responseData

        let finalResponseData = [];
        for (let i = 0; i < regionData.length; i++) {

            if (regionId !== "" && regionCodeId !== "" && regionSBUId !== "") {

                // if (regionData[i][regionId] !== undefined && regionData[i][regionCodeId] !== undefined && regionData[i][regionSBUId] !== undefined) {

                var region = regionData[i][regionId];
                var refCode = regionData[i][regionCodeId];
                var sbu = regionData[i][regionSBUId];

                var strike = 0;
                let checkExitingCode = getRegionCode(refCode)
                //check Duplicates
                let _finalResponseData = [...finalResponseData];

                // Region refCode can be duplicate
                // let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)

                let duplicateRecord = finalResponseData.some(entry => entry.sbu === sbu && entry.region === region);

                // based on Requirement code changed on 04-05-2023 ---- REMOVE DUPLICATE REF CODE (Confirmation from Ganesh Sir)
                // if (checkExitingCode === true || check_parameterData === false || region === undefined || refCode === undefined || sbu === undefined) { strike = 1 } else (strike = 0)
                if (duplicateRecord === true || region === undefined || refCode === undefined || sbu === undefined) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "region": region, "refCode": refCode, "sbu": sbu, "strike": strike
                });
                // }

            } else {
                toast.error('BU, Ref Code And SBU should not be empty field.');
                return false;
            }

            if (i === regionData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }
        }

    }

    //*Map Country
    async function mapCountry() {

        let countryData = responseData



        let finalResponseData = [];
        for (let i = 0; i < countryData.length; i++) {

            if (countryId !== "" && countryCodeId !== "" && countryRegionId !== "" && countryData[i][countrySBUId] !== "") {

                // if (countryData[i][countryId] !== undefined && countryData[i][countryCodeId] !== undefined && countryData[i][countryRegionId] !== undefined) {

                var country = countryData[i][countryId];
                var refCode = countryData[i][countryCodeId];
                var sbuId = countryData[i][countrySBUId];
                var region = countryData[i][countryRegionId] === undefined ? "" : countryData[i][countryRegionId];

                var strike = 0;
                var vat = 0;
                let checkExitingCode = getCountryCode(refCode)
                let checkSBU = getSbuId(countryData[i][countrySBUId])
                // let checkExistingRegion = getRegionName(region)
                // let checkRegionBasedOnSBU = getRegionIdBasedOnSbuId(checkSBU.id, region)

                //check Duplicates
                let _finalResponseData = [...finalResponseData];
                let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)

                let duplicateRecord = _finalResponseData.some(entry => entry.country === country && entry.region === region && entry.sbu === sbuId && entry.refCode === refCode);
                // based on Requirement code changed on 04-05-2023 ---- REMOVE DUPLICATE REF CODE (Confirmation from Ganesh Sir)
                // if (checkExitingCode === true || check_parameterData === false ||
                if (duplicateRecord === true) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "country": country, "refCode": refCode, "region": region, "vat": vat, "strike": strike, "sbu": countryData[i][countrySBUId]
                });
                // }

            } else {
                toast.error('Country, Ref Code And Cluster should not be empty field.');
                return false;
            }

            if (i === countryData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }
        }

    }

    //*Map Company
    async function mapCompany() {

        let companyData = responseData
        let finalResponseData = [];
        for (let i = 0; i < companyData.length; i++) {

            if (companyId !== "" && companyCodeId !== "") {

                // if (companyData[i][companyId] !== undefined && companyData[i][companyCodeId] !== undefined) {

                var company = companyData[i][companyId];
                var refCode = companyData[i][companyCodeId];

                var strike = 0;
                var vat = 0;
                let checkExiting = getCompanyCode(refCode)
                let _finalResponseData = [...finalResponseData];
                //check Duplicates
                let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)
                if (checkExiting === true || check_parameterData === false || company === undefined || refCode === undefined) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "company": company, "refCode": refCode, "vat": vat, "strike": strike
                });

                // }

            } else {
                toast.error('Company Code and Ref Code should not be empty field.');
                return false;
            }

            if (i === companyData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }
        }

    }

    //*Map Customer Segment
    async function mapCustomerSegment() {

        let customerSegmentData = responseData
        let finalResponseData = [];
        for (let i = 0; i < customerSegmentData.length; i++) {

            if (customerSegmentId !== "" && customerSegmentCodeId !== "") {

                // if (customerSegmentData[i][customerSegmentId] !== undefined && customerSegmentData[i][customerSegmentCodeId] !== undefined) {

                var customerSegment = customerSegmentData[i][customerSegmentId];
                var refCode = customerSegmentData[i][customerSegmentCodeId];

                var strike = 0;
                let checkExiting = getCustomerSegmentCode(refCode)
                //check Duplicates
                let _finalResponseData = [...finalResponseData];
                let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)
                if (checkExiting === true || check_parameterData === false || customerSegment === undefined || refCode === undefined) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "customerSegment": customerSegment, "refCode": refCode, "strike": strike
                });
                // }

            } else {
                toast.error('Customer Segment and Ref Code should not be empty field.');
                return false;
            }

            if (i === customerSegmentData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }
        }

    }

    //*Map Customer Segment
    async function mapCustomerCategory() {

        let customerCategoryData = responseData
        let finalResponseData = [];
        for (let i = 0; i < customerCategoryData.length; i++) {

            if (customerGroupId !== "" && customerGroupCodeId !== "" && customerGroupCSId !== "") {

                // if (customerCategoryData[i][customerGroupId] !== undefined && customerCategoryData[i][customerGroupCodeId] !== undefined && customerCategoryData[i][customerGroupCSId] !== undefined) {

                var customerCategory = customerCategoryData[i][customerGroupId];
                var refCode = customerCategoryData[i][customerGroupCodeId];
                var customerSegment = customerCategoryData[i][customerGroupCSId] === undefined ? "" : customerCategoryData[i][customerGroupCSId];
                var gtm = customerCategoryData[i][customerGroupGtmId]

                var strike = 0;
                let checkExitingCode = getCustomerGroupCode(refCode)
                let checkExistingCS = getCustomerSegmentName(customerSegment)

                //check Duplicates
                let _finalResponseData = [...finalResponseData];

                // Ref code can be duplicate
                // let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)

                let duplicateRecord = finalResponseData.some(entry => entry.customerCategory === customerCategory && entry.refCode === refCode && entry.customerSegment === customerSegment && entry.gtm === gtm);

                if (duplicateRecord === true || checkExitingCode === true || customerCategory === undefined || refCode === undefined || customerSegment === undefined || checkExistingCS === false
                ) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "customerCategory": customerCategory, "refCode": refCode, "customerSegment": customerSegment, "gtm": gtm, "strike": strike
                });
                // }

            } else {
                toast.error('Customer Group, Ref Code And Customer Segment should not be empty field.');
                return false;
            }

            if (i === customerCategoryData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }
        }
    }

    //*Map Customer Segment
    async function mapCustomer() {

        let customerData = responseData
        let finalResponseData = [];
        for (let i = 0; i < customerData.length; i++) {
            if (customerId !== "" && customerCodeId !== "" && customerCGId !== "" && customerCountryId !== "") {

                // if (customerData[i][customerId] !== undefined && customerData[i][customerCodeId] !== undefined && customerData[i][customerCGId] !== undefined && customerData[i][customerCountryId] !== undefined) {

                var customer = customerData[i][customerId];
                var refCode = customerData[i][customerCodeId];
                var customerGroup = customerData[i][customerCGId] === undefined ? "" : customerData[i][customerCGId];
                var country = customerData[i][customerCountryId] === undefined ? "" : customerData[i][customerCountryId];

                var strike = 0;
                let checkExitingCode = getCustomerCode(refCode)
                let checkExistingCustomerGroup = getCustomerGroupName(customerGroup)
                let checkExitingCountry = getCountryName(country)

                //check Duplicates
                let _finalResponseData = [...finalResponseData];
                let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)
                if (checkExitingCode === true || check_parameterData === false ||
                    customer === undefined || refCode === undefined || customerGroup === undefined || country === undefined || checkExistingCustomerGroup === false || checkExitingCountry === false
                ) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "customer": customer, "refCode": refCode, "customerGroup": customerGroup, "country": country, "strike": strike
                });
                // }

            } else {
                toast.error('Customer, Ref Code ,Customer Group and Country should not be empty field.');
                return false;
            }

            if (i === customerData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }

        }

    }

    //*Map Verticals
    async function mapVerticals() {

        let verticalsData = responseData
        let finalResponseData = [];
        for (let i = 0; i < verticalsData.length; i++) {

            if (verticalId !== "" && verticalCodeId !== "") {

                // if (verticalsData[i][verticalId] !== undefined && verticalsData[i][verticalCodeId] !== undefined) {

                var vertical = verticalsData[i][verticalId];
                var refCode = verticalsData[i][verticalCodeId];

                var strike = 0;
                let checkExiting = getVerticalCode(refCode)
                //check Duplicates
                let _finalResponseData = [...finalResponseData];
                let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)
                if (checkExiting === true || check_parameterData === false ||
                    vertical === undefined || refCode === undefined
                ) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "vertical": vertical, "refCode": refCode, "strike": strike
                });
                // }

            } else {
                toast.error('Vertical and Ref Code should not be empty field.');
                return false;
            }

            if (i === verticalsData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }
        }

    }

    //*Map Division
    async function mapDivision() {

        let divisionData = responseData
        let finalResponseData = [];
        for (let i = 0; i < divisionData.length; i++) {

            if (divisionId !== "" && divisionCodeId !== "") {

                // if (divisionData[i][divisionId] !== undefined && divisionData[i][divisionCodeId] !== undefined) {

                var division = divisionData[i][divisionId];
                var refCode = divisionData[i][divisionCodeId];

                var strike = 0;
                let checkExiting = getDivisionCode(refCode)
                //check Duplicates
                let _finalResponseData = [...finalResponseData];
                let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)
                if (checkExiting === true || check_parameterData === false ||
                    division === undefined || refCode === undefined
                ) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "division": division, "refCode": refCode, "strike": strike
                });

                // }

            } else {
                toast.error('Division and Ref Code should not be empty field.');
                return false;
            }

            if (i === divisionData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }
        }

    }

    //*Map PlantStorageLocation
    async function mapPlantStorageLocation() {

        let plantStorageLocationData = responseData
        let finalResponseData = [];
        for (let i = 0; i < plantStorageLocationData.length; i++) {

            if (plantStorageLocationId !== "" && plantStorageLocationCodeId !== "") {

                // if (plantStorageLocationData[i][plantStorageLocationId] !== undefined && plantStorageLocationData[i][plantStorageLocationCodeId] !== undefined) {

                var plantStorageLocation = plantStorageLocationData[i][plantStorageLocationId];
                var refCode = plantStorageLocationData[i][plantStorageLocationCodeId];

                var strike = 0;
                let checkExiting = getPlantStorageLocationCode(refCode)
                //check Duplicates
                let _finalResponseData = [...finalResponseData];
                let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)
                if (checkExiting === true || check_parameterData === false ||
                    plantStorageLocation === undefined || refCode === undefined
                ) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "plantStorageLocation": plantStorageLocation, "refCode": refCode, "strike": strike
                });

                // }

            } else {
                toast.error('Plant Storage Location and Ref Code should not be empty field.');
                return false;
            }

            if (i === plantStorageLocationData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }
        }

    }

    //*Map Material
    async function mapMaterial() {

        let materialData = responseData
        let finalResponseData = [];
        for (let i = 0; i < materialData.length; i++) {

            if (materialId !== "" && materialCodeId !== "") {

                // if (materialData[i][materialId] !== undefined && materialData[i][materialCodeId] !== undefined) {

                var material = materialData[i][materialId];
                var refCode = materialData[i][materialCodeId];

                var strike = 0;
                let checkExiting = getMaterialCode(refCode)
                //check Duplicates
                let _finalResponseData = [...finalResponseData];
                let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)
                if (checkExiting === true || check_parameterData === false || material === undefined || refCode === undefined) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "material": material, "refCode": refCode, "strike": strike
                });
                // }

            } else {
                toast.error('Material and Ref Code should not be empty field.');
                return false;
            }

            if (i === materialData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }
        }

    }

    //*Map GTM
    async function mapGTM() {

        let gtmData = responseData
        let finalResponseData = [];
        for (let i = 0; i < gtmData.length; i++) {

            if (gtmId !== "" && gtmCodeId !== "") {

                // if (materialData[i][materialId] !== undefined && materialData[i][materialCodeId] !== undefined) {

                var gtm = gtmData[i][gtmId];
                var refCode = gtmData[i][gtmCodeId];

                var strike = 0;
                let checkExiting = getGTMCode(refCode)
                //check Duplicates
                let _finalResponseData = [...finalResponseData];
                let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)
                if (checkExiting === true || check_parameterData === false || gtm === undefined || refCode === undefined) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "gtm": gtm, "refCode": refCode, "strike": strike
                });
                // }

            } else {
                toast.error('GTM and Ref Code should not be empty field.');
                return false;
            }

            if (i === gtmData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }
        }

    }

    //*Map SUBGTM
    async function mapSubGTM() {
        let subGtmData = responseData;
        let finalResponseData = [];
        for (let i = 0; i < subGtmData.length; i++) {

            if (subGtmId !== "" && subGtmCodeId !== "" && gtmId !== "") {

                // if (materialData[i][materialId] !== undefined && materialData[i][materialCodeId] !== undefined) {

                var subGtmName = subGtmData[i][subGtmId];
                var refCode = subGtmData[i][subGtmCodeId];
                var gtmName = subGtmData[i][gtmId];
                let getGmtId = getCustomerGmtId(gtmName)

                var strike = 0;
                let checkExiting = getSubGTMCode(refCode)
                //check Duplicates
                let _finalResponseData = [...finalResponseData];
                let check_parameterData = _finalResponseData.every((e) => e.refCode !== refCode)
                if (checkExiting === true || check_parameterData === false || !getGmtId || refCode === undefined) { strike = 1 } else (strike = 0)

                finalResponseData.push({
                    "gtmId": getGmtId, "gtmName": gtmName, "subGtm": subGtmName, "refCode": refCode, "strike": strike
                });
                // }

            } else {
                toast.error('SUB GTM, GTM and Ref Code should not be empty field.');
                return false;
            }

            if (i === subGtmData.length - 1) {
                setTableData(finalResponseData)
                let invalidResponse = finalResponseData.filter((item) => item.strike === 1)
                setInValidRecord(invalidResponse.length)
                let validResponse = finalResponseData.filter((item) => item.strike === 0)
                setValidRecord(validResponse.length)
                setTotalRecord(finalResponseData.length)
                setIsShowPreviewDataTable(true);
            }
        }

    }

    //*Save Data
    async function saveData() {
        if (props.type === "SBU") {
            saveSBU();
        }
        else if (props.type === "BU") {
            saveBU();
        }
        else if (props.type === "Brand") {
            saveBrand();
        }
        else if (props.type === "PG") {
            savePG();
        }
        else if (props.type === "ProfitCenter") {
            savePC();
        }
        else if (props.type === "Region") {
            saveRegion();
        }
        else if (props.type === "Country") {
            saveCountry();
        }
        else if (props.type === "Company") {
            saveCompany();
        }
        else if (props.type === "CustomerSegment") {
            saveCustomerSegment();
        }
        else if (props.type === "CustomerCategory") {
            saveCustomerCategory();
        }
        else if (props.type === "Customer") {
            saveCustomer();
        }
        else if (props.type === "Vertical") {
            saveVertical();
        }
        else if (props.type === "Division") {
            saveDivision();
        }
        else if (props.type === "PlantStorageLocation") {
            savePlantStorageLocation();
        }
        else if (props.type === "Material") {
            saveMaterial();
        }
        else if (props.type === "GTM") {
            saveGTM();
        }
        else if (props.type === "SUBGTM") {
            saveSubGTM();
        }
    }

    //*Save SBU
    async function saveSBU() {
        setIsLoader(true);
        let sbuData = tableData
        for (let i = 0; i < sbuData.length; i++) {
            if (sbuData[i].strike === 0) {
                await DataStore.save(new SBU({ name: capitalizeFirst(String(sbuData[i].sbu)), REF_CODE: capitalizeFirst(String(sbuData[i].refCode)) }));
            }

            if (i === sbuData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/setting/dimensions/sbu");
                cancelImport();
            }
        }
    }

    //*Save BU
    async function saveBU() {
        setIsLoader(true);
        let buData = tableData
        for (let i = 0; i < buData.length; i++) {
            let checkSBU = getBuSBUName(buData[i].sbu)
            if (buData[i].strike === 0) {
                if (checkSBU === false) {
                    await DataStore.save(new SBU({ name: capitalizeFirst(String(buData[i].sbu)), REF_CODE: capitalizeFirst(String(buData[i].refCode)) })).then(async (response) => {
                        await DataStore.save(new BIUnit({
                            name: capitalizeFirst(String(buData[i].bu)),
                            //product_group_id: String(item.pG),
                            sbu_id: response.id,
                            REF_CODE: capitalizeFirst(String(buData[i].refCode)),
                        }));
                    })
                }
                else {
                    let getSBUId = getBuSBUId(buData[i].sbu)
                    await DataStore.save(new BIUnit({
                        name: capitalizeFirst(String(buData[i].bu)),
                        //product_group_id: String(item.pG),
                        sbu_id: getSBUId,
                        REF_CODE: capitalizeFirst(String(buData[i].refCode)),
                    }));
                }
            }

            if (i === buData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/setting/bussinessunits");
                cancelImport();
            }
        }
    }

    //*Save Brand
    async function saveBrand() {
        setIsLoader(true);
        let brandData = tableData
        for (let i = 0; i < brandData.length; i++) {

            if (brandData[i].strike === 0) {
                let getSBUId = getBuSBUId(brandData[i].sbu)
                let getBUId = getBrandBUId(brandData[i].bu)
                await DataStore.save(new Brand({
                    name: capitalizeFirst(String(brandData[i].brand)),
                    SBU_id: getSBUId,
                    bu_id: getBUId,
                    REF_CODE: capitalizeFirst(String(brandData[i].refCode))
                }));
            }
            if (i === brandData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/setting/dimensions/brand");
                cancelImport();
            }
        }
    }

    //*Save PG
    async function savePG() {
        setIsLoader(true);
        let pGData = tableData
        for (let i = 0; i < pGData.length; i++) {
            if (pGData[i].strike === 0) {
                let getBrandId = getPGBrandId(pGData[i].brand, pGData[i].sbu)
               
                await DataStore.save(new ProductGroup({ 
                    name: capitalizeFirst(String(pGData[i].pG)), 
                    brand_id: getBrandId, 
                    REF_CODE: capitalizeFirst(String(pGData[i].refCode)) 
                }));
            }
            if (i === pGData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/setting/productgroups");
                cancelImport();
            }
        }
    }

    //*Save PC
    async function savePC() {
        setIsLoader(true);
        let pCData = tableData
        for (let i = 0; i < pCData.length; i++) {
            if (pCData[i].strike === 0) {
                let getPgId = getPcPgId(pCData[i].pG)
                await DataStore.save(new ProfitCenter({ name: capitalizeFirst(String(pCData[i].pC)), product_group_id: getPgId, REF_CODE: capitalizeFirst(String(pCData[i].refCode)) }));
            }
            if (i === pCData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/setting/profitcenter");
                cancelImport();
            }
        }
    }

    //*Save BU
    async function saveRegion() {
        setIsLoader(true);
        let regionData = tableData
        for (let i = 0; i < regionData.length; i++) {
            let checkSBU = getBuSBUName(regionData[i].sbu)
            if (regionData[i].strike === 0) {
                if (checkSBU === false) {
                    await DataStore.save(new SBU({ name: capitalizeFirst(String(regionData[i].sbu)), REF_CODE: capitalizeFirst(String(regionData[i].refCode)) })).then(async (response) => {
                        await DataStore.save(new Region({
                            name: capitalizeFirst(String(regionData[i].region)),
                            SBU_id: response.id,
                            REF_CODE: capitalizeFirst(String(regionData[i].refCode)),
                        }));
                    })
                }
                else {
                    let getSBUId = getBuSBUId(regionData[i].sbu)
                    await DataStore.save(new Region({
                        name: capitalizeFirst(String(regionData[i].region)),
                        SBU_id: getSBUId,
                        REF_CODE: capitalizeFirst(String(regionData[i].refCode)),
                    }));
                }
            }

            if (i === regionData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/setting/regions");
                cancelImport();
            }
        }
    }

    //*Save Country
    async function saveCountry() {
        setIsLoader(true);
        let countryData = tableData


        for (let i = 0; i < countryData.length; i++) {
            if (countryData[i].strike === 0) {
                let getPgId = getRegionId(countryData[i].region, countryData[i].sbu)
                await DataStore.save(new Country({ name: capitalizeFirst(String(countryData[i].country)), region_id: getPgId, REF_CODE: capitalizeFirst(String(countryData[i].refCode)), VAT: capitalizeFirst(String(countryData[i].vat)) }));
            }

            if (i === countryData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/setting/dimensions/country");
                cancelImport();
            }
        }
    }

    //*Save Company
    async function saveCompany() {
        setIsLoader(true);
        let companyData = tableData
        for (let i = 0; i < companyData.length; i++) {
            if (companyData[i].strike === 0) {
                await DataStore.save(new Company({ name: capitalizeFirst(String(companyData[i].company)), REF_CODE: capitalizeFirst(String(companyData[i].refCode)) }));
            }
            if (i === companyData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/setting/company");
                cancelImport();
            }
        }
    }

    //*Save Customer Segment
    async function saveCustomerSegment() {
        setIsLoader(true);
        let customerSegmentData = tableData
        for (let i = 0; i < customerSegmentData.length; i++) {
            if (customerSegmentData[i].strike === 0) {
                await DataStore.save(new CustomerSegment({ name: capitalizeFirst(String(customerSegmentData[i].customerSegment)), REF_CODE: capitalizeFirst(String(customerSegmentData[i].refCode)) }));
            }
            if (i === customerSegmentData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/setting/dimensions/customerSegment");
                cancelImport();
            }
        }
    }

    //*Save Customer Category
    async function saveCustomerCategory() {
        setIsLoader(true);
        let customerGroupData = tableData
        for (let i = 0; i < customerGroupData.length; i++) {
            if (customerGroupData[i].strike === 0) {
                let getCSId = getCustomerSegmentId(customerGroupData[i].customerSegment)
                let getGmtId = getCustomerGmtId(customerGroupData[i].gtm)
                await DataStore.save(new CustomerCategory({ name: capitalizeFirst(String(customerGroupData[i].customerCategory)), gtm_i: getGmtId, customer_segment_id: getCSId, REF_CODE: capitalizeFirst(String(customerGroupData[i].refCode)) }));
            }

            if (i === customerGroupData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/setting/dimensions/customercategory");
                cancelImport();
            }
        }
    }

    //*Save Customer 
    async function saveCustomer() {
        setIsLoader(true);
        let customerData = tableData
        for (let i = 0; i < customerData.length; i++) {
            if (customerData[i].strike === 0) {
                let getCGId = getCustomerGroupId(customerData[i].customerGroup)
                let getCountryId = getCustomerCountryId(customerData[i].country)
                await DataStore.save(new Customer({ name: capitalizeFirst(String(customerData[i].customer)), customer_category_id: getCGId, country_id: getCountryId, REF_CODE: capitalizeFirst(String(customerData[i].refCode)) })).then((response) => {
                })
            }
            if (i === customerData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/setting/dimensions/customer");
                cancelImport();
            }
        }
    }

    //*Save Verticals
    async function saveVertical() {
        setIsLoader(true);
        let verticalData = tableData
        for (let i = 0; i < verticalData.length; i++) {
            if (verticalData[i].strike === 0) {
                await DataStore.save(new Verticals({ name: capitalizeFirst(String(verticalData[i].vertical)), REF_CODE: capitalizeFirst(String(verticalData[i].refCode)) }));
            }
            if (i === verticalData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/settings/dimensions/verticals");
                cancelImport();
            }
        }
    }

    //*Save Division
    async function saveDivision() {
        setIsLoader(true);
        let divisionData = tableData
        for (let i = 0; i < divisionData.length; i++) {
            if (divisionData[i].strike === 0) {
                await DataStore.save(new Division({ name: capitalizeFirst(String(divisionData[i].division)), REF_CODE: capitalizeFirst(String(divisionData[i].refCode)) }));
            }
            if (i === divisionData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/settings/dimensions/division");
                cancelImport();
            }
        }
    }

    //*Save PlantStorageLocation
    async function savePlantStorageLocation() {
        setIsLoader(true);
        let plantStorageLocationData = tableData
        for (let i = 0; i < plantStorageLocationData.length; i++) {
            if (plantStorageLocationData[i].strike === 0) {
                await DataStore.save(new PlantStorageLocation({ name: capitalizeFirst(String(plantStorageLocationData[i].plantStorageLocation)), REF_CODE: capitalizeFirst(String(plantStorageLocationData[i].refCode)) }));
            }
            if (i === plantStorageLocationData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/settings/dimensions/plantstoragelocation");
                cancelImport();
            }
        }
    }

    //*Save Material
    async function saveMaterial() {
        setIsLoader(true);
        let materialData = tableData
        for (let i = 0; i < materialData.length; i++) {
            if (materialData[i].strike === 0) {
                await DataStore.save(new Materials({ name: capitalizeFirst(String(materialData[i].material)), REF_CODE: capitalizeFirst(String(materialData[i].refCode)) }));
            }
            if (i === materialData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/settings/dimensions/material");
                cancelImport();
            }
        }
    }

    //*Save GTM
    async function saveGTM() {
        setIsLoader(true);
        let gtmData = tableData
        for (let i = 0; i < gtmData.length; i++) {
            if (gtmData[i].strike === 0) {
                await DataStore.save(new GTM({ name: capitalizeFirst(String(gtmData[i].gtm)), REF_CODE: capitalizeFirst(String(gtmData[i].refCode)) }));
            }
            if (i === gtmData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/settings/gtm");
                cancelImport();
            }
        }
    }
    // "gtmId": getGmtId, "gtmName": gtmName, "subGtm": subGtmName, "refCode": refCode, "strike": strike

    //*Save SUBGTM
    async function saveSubGTM() {
        setIsLoader(true);
        let gtmData = tableData
        for (let i = 0; i < gtmData.length; i++) {
            if (gtmData[i].strike === 0) {
                await DataStore.save(new SubGTM({ name: capitalizeFirst(String(gtmData[i].subGtm)), gtm_id: gtmData[i].gtmId, REF_CODE: capitalizeFirst(String(gtmData[i].refCode)) }));
            }
            if (i === gtmData.length - 1) {
                toast.success('Data imported successfully!');
                setIsLoader(false);
                navigateTo("/hr/settings/subgtm");
                cancelImport();
            }
        }
    }

    //*onclick cancel import
    const cancelImport = async () => {
        // setIsLoader(false)
        props.closePopup();
    }

    async function BindList() {

        //SBU
        var SBUResponses = await DataStore.query(SBU, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setSbuList(SBUResponses)

        //BU
        var BUResponses = await DataStore.query(BIUnit, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setBuList(BUResponses)

        //Brand
        var brandResponses = await DataStore.query(Brand, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setBrandList(brandResponses)

        //Pg
        var pGResponses = await DataStore.query(ProductGroup, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setPGList(pGResponses)

        //PC
        var pCResponses = await DataStore.query(ProfitCenter, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setPCList(pCResponses)

        //Region
        var regionResponses = await DataStore.query(Region, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setRegionList(regionResponses)

        //Country
        var countryResponses = await DataStore.query(Country, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setCountryList(countryResponses)

        //company
        var companyResponses = await DataStore.query(Company, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setCompanyList(companyResponses)

        //customer segment
        var customerSegmentResponses = await DataStore.query(CustomerSegment, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setCustomerSegmentList(customerSegmentResponses)

        //customer Category
        var customerCategoryResponses = await DataStore.query(CustomerCategory, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setCustomerGroupList(customerCategoryResponses)

        //customer 
        var customerResponses = await DataStore.query(Customer, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setCustomerList(customerResponses)

        //Verticals
        var verticalsResponses = await DataStore.query(Verticals, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setVerticalList(verticalsResponses)

        //Division
        var divisionResponses = await DataStore.query(Division, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setDivisionList(divisionResponses)

        //PlantStorageLocation
        var plantStorageLocationResponses = await DataStore.query(PlantStorageLocation, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setPlantStorageLocationList(plantStorageLocationResponses)

        //Materials
        var materialsResponses = await DataStore.query(Materials, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setMaterialList(materialsResponses)

        //GTM
        var gtmResponses = await DataStore.query(GTM, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setGtmList(gtmResponses)

        //Sub GTM
        var subGtmResponses = await DataStore.query(SubGTM, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        setSubGtmList(subGtmResponses)

    }

    useEffect(() => {
        BindList();
    }, []);

    return (

        <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
            <img src={Bussiness} alt="Image here" style={{ width: "50vw" }} />
            <div className="absolute right-0 left-0 top-0 w-full text-white">
                <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                    <div className="text-md"><h6 className='text-[white]'>Import</h6></div>
                    {
                        isLoader === false ?
                            <div><Link to="#" onClick={() => cancelImport()} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                            : null
                    }

                </div>

                <div className="px-6 mt-8">
                    <span>
                        <Menu as="div" className="relative inline-block text-left">
                            <div className="flex items-center align-center">
                                <Menu.Button className='flex items-center'>
                                </Menu.Button>
                            </div>
                        </Menu>
                        <div className="text-sm text-[white]">Master</div>
                        <div className="text-2xl">{popupTitle}
                        </div>
                    </span>
                </div>

            </div>
            {
                isLoader === true ?
                    <>
                        <div className='absolute top-[26%] inset-0 flex items-center justify-center bg-[#00000059] z-50'>
                            <ProgressSpinner style={{ width: '100px', height: '100px', zIndex: '9999' }} strokeWidth="6" fill="var(--surface-ground)" animationDuration=".8s" />
                        </div>
                    </>
                    : null
            }


            <div className='p-6 height-custtom'>

                {
                    isShowPreviewDataTable &&
                    <div className='record-validate float-left'>
                        <p className='text-md'>Validated Record: <b>{validRecord}</b></p>
                    </div>

                }
                {
                    isShowPreviewDataTable &&
                    <div className='record-validate float-center'>
                        <p className='text-md'>Invalidated Record: <b>{inValidRecord}</b></p>
                    </div>

                }
                {
                    isShowPreviewDataTable &&
                    <div className='record-validate float-right'>
                        <p className='text-md'>Total Record: <b>{totalRecord}</b></p>
                    </div>

                }
                {
                    isShowPreviewDataTable ?

                        <div className={"block w-full pt-5"}>
                            {
                                <DataTables
                                    columns={partnerDataColumns}
                                    data={tableData}
                                    customStyles={DataTableCustomStyles}
                                    pagination
                                    highlightOnHover
                                />
                            }
                        </div>
                        :

                        isShowDropdown ?

                            <div className="p-6 height-custtom" >
                                {
                                    props.type === "SBU" ?
                                        <div
                                            className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                        >
                                            <div>
                                                <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">SBU <label className="text-[#FF0000] text-[14px] font-medium">
                                                    * </label>
                                                </label>
                                            </div>
                                            <div>
                                                <Select
                                                    value={sbu}
                                                    options={responseHeaderData}
                                                    onChange={e => {
                                                        setSbu(e);
                                                        setSbuId(e.value)
                                                    }}
                                                    placeholder={"Select SBU"}
                                                    className="my-react-select-container col-span-2"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>
                                            <div>
                                                <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                    * </label></label>
                                            </div>
                                            <div>
                                                <Select
                                                    value={sbuCode}
                                                    options={responseHeaderData}
                                                    onChange={e => {
                                                        setSbuCode(e);
                                                        setSbuCodeId(e.value);
                                                    }}
                                                    placeholder={"Select Ref Code"}
                                                    className="my-react-select-container col-span-2"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>
                                        </div> :
                                        props.type === "BU" ?
                                            <div
                                                className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                            >
                                                <div>
                                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Business Unit<label className="text-[#FF0000] text-[14px] font-medium">
                                                        * </label>
                                                    </label>
                                                </div>
                                                <div>
                                                    <Select
                                                        value={bu}
                                                        options={responseHeaderData}
                                                        onChange={e => {
                                                            setBu(e);
                                                            setBuId(e.value)
                                                        }}
                                                        placeholder={"Select BU"}
                                                        className="my-react-select-container col-span-2"
                                                        classNamePrefix="my-react-select"
                                                    />
                                                </div>
                                                <div>
                                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                        * </label></label>
                                                </div>
                                                <div>
                                                    <Select
                                                        value={buCode}
                                                        options={responseHeaderData}
                                                        onChange={e => {
                                                            setBuCode(e);
                                                            setBuCodeId(e.value);
                                                        }}
                                                        placeholder={"Select Ref Code"}
                                                        className="my-react-select-container col-span-2"
                                                        classNamePrefix="my-react-select"
                                                    />
                                                </div>
                                                <div>
                                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">SBU<label className="text-[#FF0000] text-[14px] font-medium">
                                                        * </label></label>
                                                </div>
                                                <div>
                                                    <Select
                                                        value={buSBU}
                                                        options={responseHeaderData}
                                                        onChange={e => {
                                                            setBuSBU(e);
                                                            setBuSBUId(e.value);
                                                        }}
                                                        placeholder={"Select SBU"}
                                                        className="my-react-select-container col-span-2"
                                                        classNamePrefix="my-react-select"
                                                    />
                                                </div>
                                                {/* <div>
                                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Product Group<label className="text-[#FF0000] text-[14px] font-medium">
                                                    </label></label>
                                                </div>
                                                <div>
                                                    <Select
                                                        value={buPG}
                                                        options={responseHeaderData}
                                                        onChange={e => {
                                                            setBuPG(e);
                                                            setBuPGId(e.value);
                                                        }}
                                                        placeholder={"Select Product Group"}
                                                        className="my-react-select-container col-span-2"
                                                        classNamePrefix="my-react-select"
                                                    />
                                                </div> */}
                                            </div> :
                                            props.type === "Brand" ?
                                                <div
                                                    className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                >
                                                    <div>
                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                            * </label></label>
                                                    </div>
                                                    <div>
                                                        <Select
                                                            value={brandCode}
                                                            options={responseHeaderData}
                                                            onChange={e => {
                                                                setBrandCode(e);
                                                                setBrandCodeId(e.value);
                                                            }}
                                                            placeholder={"Select Ref Code"}
                                                            className="my-react-select-container col-span-2"
                                                            classNamePrefix="my-react-select"
                                                        />
                                                    </div>

                                                    <div>
                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Brand<label className="text-[#FF0000] text-[14px] font-medium">
                                                            * </label>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <Select
                                                            value={brand}
                                                            options={responseHeaderData}
                                                            onChange={e => {
                                                                setBrand(e);
                                                                setBrandId(e.value)
                                                            }}
                                                            placeholder={"Select Brand"}
                                                            className="my-react-select-container col-span-2"
                                                            classNamePrefix="my-react-select"
                                                        />
                                                    </div>

                                                    <div>
                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">SBU<label className="text-[#FF0000] text-[14px] font-medium">
                                                            * </label></label>
                                                    </div>
                                                    <div>
                                                        <Select
                                                            value={brandSBU}
                                                            options={responseHeaderData}
                                                            onChange={e => {
                                                                setBrandSBU(e);
                                                                setBrandSBUId(e.value);
                                                            }}
                                                            placeholder={"Select SBU"}
                                                            className="my-react-select-container col-span-2"
                                                            classNamePrefix="my-react-select"
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">BU<label className="text-[#FF0000] text-[14px] font-medium">
                                                            * </label><label className="text-[#FF0000] text-[14px] font-medium">
                                                            </label></label>
                                                    </div>
                                                    <div>
                                                        <Select
                                                            value={brandBu}
                                                            options={responseHeaderData}
                                                            onChange={e => {
                                                                setBrandBu(e);
                                                                setBrandBuId(e.value);
                                                            }}
                                                            placeholder={"Select BU"}
                                                            className="my-react-select-container col-span-2"
                                                            classNamePrefix="my-react-select"
                                                        />
                                                    </div>
                                                </div> :
                                                props.type === "PG" ?
                                                    <div
                                                        className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                    >
                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                * </label></label>
                                                        </div>
                                                        <div>
                                                            <Select
                                                                value={pGCode}
                                                                options={responseHeaderData}
                                                                onChange={e => {
                                                                    setPGCode(e);
                                                                    setPGCodeId(e.value);
                                                                }}
                                                                placeholder={"Select Ref Code"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>

                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Product Group<label className="text-[#FF0000] text-[14px] font-medium">
                                                                * </label>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <Select
                                                                value={pG}
                                                                options={responseHeaderData}
                                                                onChange={e => {
                                                                    setPG(e);
                                                                    setPGId(e.value)
                                                                }}
                                                                placeholder={"Select Product Group"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>

                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">SBU<label className="text-[#FF0000] text-[14px] font-medium">
                                                                * </label></label>
                                                        </div>
                                                        <div>
                                                            <Select
                                                                value={pgSBU}
                                                                options={responseHeaderData}
                                                                onChange={e => {
                                                                    setPgSBU(e);
                                                                    setPgSBUId(e.value);
                                                                }}
                                                                placeholder={"Select SBU"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>

                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Bu</label>
                                                        </div>
                                                        <div>
                                                            <Select
                                                                value={pgBU}
                                                                options={responseHeaderData}
                                                                onChange={e => {
                                                                    setPgBU(e);
                                                                    setPgBUId(e.value);
                                                                }}
                                                                placeholder={"Select BU"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>

                                                        <div>
                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Brand<label className="text-[#FF0000] text-[14px] font-medium">
                                                                * </label></label>
                                                        </div>
                                                        <div>
                                                            <Select
                                                                value={pGBrand}
                                                                options={responseHeaderData}
                                                                onChange={e => {
                                                                    setPGBrand(e);
                                                                    setPGBrandId(e.value);
                                                                }}
                                                                placeholder={"Select Brand"}
                                                                className="my-react-select-container col-span-2"
                                                                classNamePrefix="my-react-select"
                                                            />
                                                        </div>


                                                    </div> :
                                                    props.type === "ProfitCenter" ?
                                                        <div
                                                            className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                        >
                                                            <div>
                                                                <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Profit Center<label className="text-[#FF0000] text-[14px] font-medium">
                                                                    * </label>
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <Select
                                                                    value={pC}
                                                                    options={responseHeaderData}
                                                                    onChange={e => {
                                                                        setPC(e);
                                                                        setPCId(e.value)
                                                                    }}
                                                                    placeholder={"Select Profit Center"}
                                                                    className="my-react-select-container col-span-2"
                                                                    classNamePrefix="my-react-select"
                                                                />
                                                            </div>
                                                            <div>
                                                                <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                    * </label></label>
                                                            </div>
                                                            <div>
                                                                <Select
                                                                    value={pCCode}
                                                                    options={responseHeaderData}
                                                                    onChange={e => {
                                                                        setPCCode(e);
                                                                        setPCCodeId(e.value);
                                                                    }}
                                                                    placeholder={"Select Ref Code"}
                                                                    className="my-react-select-container col-span-2"
                                                                    classNamePrefix="my-react-select"
                                                                />
                                                            </div>
                                                            <div>
                                                                <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Product Group<label className="text-[#FF0000] text-[14px] font-medium">
                                                                    * </label></label>
                                                            </div>
                                                            <div>
                                                                <Select
                                                                    value={pCPg}
                                                                    options={responseHeaderData}
                                                                    onChange={e => {
                                                                        setPCPg(e);
                                                                        setPCPgId(e.value);
                                                                    }}
                                                                    placeholder={"Select Product Group"}
                                                                    className="my-react-select-container col-span-2"
                                                                    classNamePrefix="my-react-select"
                                                                />
                                                            </div>
                                                        </div> :
                                                        props.type === "Region" ?
                                                            <div
                                                                className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                            >
                                                                <div>
                                                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Cluster<label className="text-[#FF0000] text-[14px] font-medium">
                                                                        * </label>
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <Select
                                                                        value={region}
                                                                        options={responseHeaderData}
                                                                        onChange={e => {
                                                                            setRegion(e);
                                                                            setRegionId(e.value)
                                                                        }}
                                                                        placeholder={"Select Cluster"}
                                                                        className="my-react-select-container col-span-2"
                                                                        classNamePrefix="my-react-select"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                        * </label></label>
                                                                </div>
                                                                <div>
                                                                    <Select
                                                                        value={regionCode}
                                                                        options={responseHeaderData}
                                                                        onChange={e => {
                                                                            setRegionCode(e);
                                                                            setRegionCodeId(e.value);
                                                                        }}
                                                                        placeholder={"Select Ref Code"}
                                                                        className="my-react-select-container col-span-2"
                                                                        classNamePrefix="my-react-select"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">SBU<label className="text-[#FF0000] text-[14px] font-medium">
                                                                        * </label></label>
                                                                </div>
                                                                <div>
                                                                    <Select
                                                                        value={regionSBU}
                                                                        options={responseHeaderData}
                                                                        onChange={e => {
                                                                            setRegionSBU(e);
                                                                            setRegionSBUId(e.value);
                                                                        }}
                                                                        placeholder={"Select SBU"}
                                                                        className="my-react-select-container col-span-2"
                                                                        classNamePrefix="my-react-select"
                                                                    />
                                                                </div>
                                                            </div> :
                                                            props.type === "Country" ?
                                                                <div
                                                                    className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                                >
                                                                    <div>
                                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                            * </label></label>
                                                                    </div>
                                                                    <div>
                                                                        <Select
                                                                            value={countryCode}
                                                                            options={responseHeaderData}
                                                                            onChange={e => {
                                                                                setCountryCode(e);
                                                                                setCountryCodeId(e.value);
                                                                            }}
                                                                            placeholder={"Select Ref Code"}
                                                                            className="my-react-select-container col-span-2"
                                                                            classNamePrefix="my-react-select"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Country<label className="text-[#FF0000] text-[14px] font-medium">
                                                                            * </label>
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <Select
                                                                            value={country}
                                                                            options={responseHeaderData}
                                                                            onChange={e => {
                                                                                setCountry(e);
                                                                                setCountryId(e.value)
                                                                            }}
                                                                            placeholder={"Select Country"}
                                                                            className="my-react-select-container col-span-2"
                                                                            classNamePrefix="my-react-select"
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Cluster<label className="text-[#FF0000] text-[14px] font-medium">
                                                                            * </label></label>
                                                                    </div>
                                                                    <div>
                                                                        <Select
                                                                            value={countryRegion}
                                                                            options={responseHeaderData}
                                                                            onChange={e => {
                                                                                setCountryRegion(e);
                                                                                setCountryRegionId(e.value);
                                                                            }}
                                                                            placeholder={"Select Cluster"}
                                                                            className="my-react-select-container col-span-2"
                                                                            classNamePrefix="my-react-select"
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">SBU<label className="text-[#FF0000] text-[14px] font-medium">
                                                                            * </label></label>
                                                                    </div>
                                                                    <div>
                                                                        <Select
                                                                            value={countrySBU}
                                                                            options={responseHeaderData}
                                                                            onChange={e => {
                                                                                setCountrySBU(e);
                                                                                setCountrySBUId(e.value);
                                                                            }}
                                                                            placeholder={"Select SBU"}
                                                                            className="my-react-select-container col-span-2"
                                                                            classNamePrefix="my-react-select"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">VAT</label>
                                                                    </div>
                                                                    <div>
                                                                        <Select
                                                                            value={vat}
                                                                            options={responseHeaderData}
                                                                            onChange={e => {
                                                                                setVat(e);
                                                                                setVatId(e.value);
                                                                            }}
                                                                            placeholder={"Select VAT"}
                                                                            className="my-react-select-container col-span-2"
                                                                            classNamePrefix="my-react-select"
                                                                        />
                                                                    </div>

                                                                </div> :
                                                                props.type === "Company" ?
                                                                    <div
                                                                        className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                                    >
                                                                        <div>
                                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Company <label className="text-[#FF0000] text-[14px] font-medium">
                                                                                * </label>
                                                                            </label>
                                                                        </div>
                                                                        <div>
                                                                            <Select
                                                                                value={company}
                                                                                options={responseHeaderData}
                                                                                onChange={e => {
                                                                                    setCompany(e);
                                                                                    setCompanyId(e.value)
                                                                                }}
                                                                                placeholder={"Select Company"}
                                                                                className="my-react-select-container col-span-2"
                                                                                classNamePrefix="my-react-select"
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                * </label></label>
                                                                        </div>
                                                                        <div>
                                                                            <Select
                                                                                value={companyCode}
                                                                                options={responseHeaderData}
                                                                                onChange={e => {
                                                                                    setCompanyCode(e);
                                                                                    setCompanyCodeId(e.value);
                                                                                }}
                                                                                placeholder={"Select Ref Code"}
                                                                                className="my-react-select-container col-span-2"
                                                                                classNamePrefix="my-react-select"
                                                                            />
                                                                        </div>
                                                                    </div> :
                                                                    props.type === "CustomerSegment" ?
                                                                        <div
                                                                            className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                                        >
                                                                            <div>
                                                                                <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Customer Segment<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                    * </label>
                                                                                </label>
                                                                            </div>
                                                                            <div>
                                                                                <Select
                                                                                    value={customerSegment}
                                                                                    options={responseHeaderData}
                                                                                    onChange={e => {
                                                                                        setCustomerSegment(e);
                                                                                        setCustomerSegmentId(e.value)
                                                                                    }}
                                                                                    placeholder={"Select Customer Segment"}
                                                                                    className="my-react-select-container col-span-2"
                                                                                    classNamePrefix="my-react-select"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                    * </label></label>
                                                                            </div>
                                                                            <div>
                                                                                <Select
                                                                                    value={customerSegmentCode}
                                                                                    options={responseHeaderData}
                                                                                    onChange={e => {
                                                                                        setCustomerSegmentCode(e);
                                                                                        setCustomerSegmentCodeId(e.value);
                                                                                    }}
                                                                                    placeholder={"Select Ref Code"}
                                                                                    className="my-react-select-container col-span-2"
                                                                                    classNamePrefix="my-react-select"
                                                                                />
                                                                            </div>
                                                                        </div> :
                                                                        props.type === "CustomerCategory" ?
                                                                            <div
                                                                                className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                                            >
                                                                                <div>
                                                                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Customer Group<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                        * </label>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <Select
                                                                                        value={customerGroup}
                                                                                        options={responseHeaderData}
                                                                                        onChange={e => {
                                                                                            setCustomerGroup(e);
                                                                                            setCustomerGroupId(e.value)
                                                                                        }}
                                                                                        placeholder={"Customer Group"}
                                                                                        className="my-react-select-container col-span-2"
                                                                                        classNamePrefix="my-react-select"
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                        * </label></label>
                                                                                </div>
                                                                                <div>
                                                                                    <Select
                                                                                        value={customerGroupCode}
                                                                                        options={responseHeaderData}
                                                                                        onChange={e => {
                                                                                            setCustomerGroupCode(e);
                                                                                            setCustomerGroupCodeId(e.value);
                                                                                        }}
                                                                                        placeholder={"Select Ref Code"}
                                                                                        className="my-react-select-container col-span-2"
                                                                                        classNamePrefix="my-react-select"
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Customer Segment<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                        * </label></label>
                                                                                </div>
                                                                                <div>
                                                                                    <Select
                                                                                        value={customerGroupCS}
                                                                                        options={responseHeaderData}
                                                                                        onChange={e => {
                                                                                            setCustomerGroupCS(e);
                                                                                            setCustomerGroupCSId(e.value);
                                                                                        }}
                                                                                        placeholder={"Select Customer Segment"}
                                                                                        className="my-react-select-container col-span-2"
                                                                                        classNamePrefix="my-react-select"
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">GTM</label>
                                                                                </div>
                                                                                <div>
                                                                                    <Select
                                                                                        value={customerGroupGtm}
                                                                                        options={responseHeaderData}
                                                                                        onChange={e => {
                                                                                            setCustomerGroupGtm(e);
                                                                                            setCustomerGroupGtmId(e.value);
                                                                                        }}
                                                                                        placeholder={"Select GTM"}
                                                                                        className="my-react-select-container col-span-2"
                                                                                        classNamePrefix="my-react-select"
                                                                                    />
                                                                                </div>
                                                                            </div> :
                                                                            props.type === "Customer" ?
                                                                                <div
                                                                                    className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                                                >
                                                                                    <div>
                                                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Customer<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                            * </label>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Select
                                                                                            value={customer}
                                                                                            options={responseHeaderData}
                                                                                            onChange={e => {
                                                                                                setCustomer(e);
                                                                                                setCustomerId(e.value)
                                                                                            }}
                                                                                            placeholder={"Select Customer"}
                                                                                            className="my-react-select-container col-span-2"
                                                                                            classNamePrefix="my-react-select"
                                                                                        />
                                                                                    </div>
                                                                                    <div>
                                                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                            * </label></label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Select
                                                                                            value={customerCode}
                                                                                            options={responseHeaderData}
                                                                                            onChange={e => {
                                                                                                setCustomerCode(e);
                                                                                                setCustomerCodeId(e.value);
                                                                                            }}
                                                                                            placeholder={"Select Ref Code"}
                                                                                            className="my-react-select-container col-span-2"
                                                                                            classNamePrefix="my-react-select"
                                                                                        />
                                                                                    </div>
                                                                                    <div>
                                                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Customer Group<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                            * </label>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Select
                                                                                            value={customerCG}
                                                                                            options={responseHeaderData}
                                                                                            onChange={e => {
                                                                                                setCustomerCG(e);
                                                                                                setCustomerCGId(e.value)
                                                                                            }}
                                                                                            placeholder={"Customer Group"}
                                                                                            className="my-react-select-container col-span-2"
                                                                                            classNamePrefix="my-react-select"
                                                                                        />
                                                                                    </div>
                                                                                    <div>
                                                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Country<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                            * </label></label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Select
                                                                                            value={customerCountry}
                                                                                            options={responseHeaderData}
                                                                                            onChange={e => {
                                                                                                setCustomerCountry(e);
                                                                                                setCustomerCountryId(e.value);
                                                                                            }}
                                                                                            placeholder={"Select Country"}
                                                                                            className="my-react-select-container col-span-2"
                                                                                            classNamePrefix="my-react-select"
                                                                                        />
                                                                                    </div>
                                                                                </div> :
                                                                                props.type === "Vertical" ?
                                                                                    <div
                                                                                        className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                                                    >
                                                                                        <div>
                                                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Verticals<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                                * </label>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <Select
                                                                                                value={vertical}
                                                                                                options={responseHeaderData}
                                                                                                onChange={e => {
                                                                                                    setVertical(e);
                                                                                                    setVerticalId(e.value)
                                                                                                }}
                                                                                                placeholder={"Select Vertical"}
                                                                                                className="my-react-select-container col-span-2"
                                                                                                classNamePrefix="my-react-select"
                                                                                            />
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                                * </label></label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <Select
                                                                                                value={verticalCode}
                                                                                                options={responseHeaderData}
                                                                                                onChange={e => {
                                                                                                    setVerticalCode(e);
                                                                                                    setVerticalCodeId(e.value);
                                                                                                }}
                                                                                                placeholder={"Select Ref Code"}
                                                                                                className="my-react-select-container col-span-2"
                                                                                                classNamePrefix="my-react-select"
                                                                                            />
                                                                                        </div>
                                                                                    </div> :
                                                                                    props.type === "Division" ?
                                                                                        <div
                                                                                            className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                                                        >
                                                                                            <div>
                                                                                                <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Division<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                                    * </label>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div>
                                                                                                <Select
                                                                                                    value={division}
                                                                                                    options={responseHeaderData}
                                                                                                    onChange={e => {
                                                                                                        setDivision(e);
                                                                                                        setDivisionId(e.value)
                                                                                                    }}
                                                                                                    placeholder={"Select Division"}
                                                                                                    className="my-react-select-container col-span-2"
                                                                                                    classNamePrefix="my-react-select"
                                                                                                />
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                                    * </label></label>
                                                                                            </div>
                                                                                            <div>
                                                                                                <Select
                                                                                                    value={divisionCode}
                                                                                                    options={responseHeaderData}
                                                                                                    onChange={e => {
                                                                                                        setDivisionCode(e);
                                                                                                        setDivisionCodeId(e.value);
                                                                                                    }}
                                                                                                    placeholder={"Select Ref Code"}
                                                                                                    className="my-react-select-container col-span-2"
                                                                                                    classNamePrefix="my-react-select"
                                                                                                />
                                                                                            </div>
                                                                                        </div> :
                                                                                        props.type === "PlantStorageLocation" ?
                                                                                            <div
                                                                                                className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                                                            >
                                                                                                <div>
                                                                                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Plant Storage Location<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                                        * </label>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <Select
                                                                                                        value={plantStorageLocation}
                                                                                                        options={responseHeaderData}
                                                                                                        onChange={e => {
                                                                                                            setPlantStorageLocation(e);
                                                                                                            setPlantStorageLocationId(e.value)
                                                                                                        }}
                                                                                                        placeholder={"Select Plant Storage Location"}
                                                                                                        className="my-react-select-container col-span-2"
                                                                                                        classNamePrefix="my-react-select"
                                                                                                    />
                                                                                                </div>
                                                                                                <div>
                                                                                                    <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                                        * </label></label>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <Select
                                                                                                        value={plantStorageLocationCode}
                                                                                                        options={responseHeaderData}
                                                                                                        onChange={e => {
                                                                                                            setPlantStorageLocationCode(e);
                                                                                                            setPlantStorageLocationCodeId(e.value);
                                                                                                        }}
                                                                                                        placeholder={"Select Ref Code"}
                                                                                                        className="my-react-select-container col-span-2"
                                                                                                        classNamePrefix="my-react-select"
                                                                                                    />
                                                                                                </div>
                                                                                            </div> :
                                                                                            props.type === "Material" ?
                                                                                                <div
                                                                                                    className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                                                                >
                                                                                                    <div>
                                                                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Materials<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                                            * </label>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <Select
                                                                                                            value={material}
                                                                                                            options={responseHeaderData}
                                                                                                            onChange={e => {
                                                                                                                setMaterial(e);
                                                                                                                setMaterialId(e.value)
                                                                                                            }}
                                                                                                            placeholder={"Select Material"}
                                                                                                            className="my-react-select-container col-span-2"
                                                                                                            classNamePrefix="my-react-select"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                                            * </label></label>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <Select
                                                                                                            value={materialCode}
                                                                                                            options={responseHeaderData}
                                                                                                            onChange={e => {
                                                                                                                setMaterialCode(e);
                                                                                                                setMaterialCodeId(e.value);
                                                                                                            }}
                                                                                                            placeholder={"Select Ref Code"}
                                                                                                            className="my-react-select-container col-span-2"
                                                                                                            classNamePrefix="my-react-select"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div> :
                                                                                                props.type === "GTM" ?
                                                                                                    <div
                                                                                                        className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                                                                    >
                                                                                                        <div>
                                                                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">GTM<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                                                * </label>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <Select
                                                                                                                value={gtm}
                                                                                                                options={responseHeaderData}
                                                                                                                onChange={e => {
                                                                                                                    setGtm(e);
                                                                                                                    setGtmId(e.value)
                                                                                                                }}
                                                                                                                placeholder={"Select GTM"}
                                                                                                                className="my-react-select-container col-span-2"
                                                                                                                classNamePrefix="my-react-select"
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                                                * </label></label>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <Select
                                                                                                                value={gtmCode}
                                                                                                                options={responseHeaderData}
                                                                                                                onChange={e => {
                                                                                                                    setGtmCode(e);
                                                                                                                    setGtmCodeId(e.value);
                                                                                                                }}
                                                                                                                placeholder={"Select Ref Code"}
                                                                                                                className="my-react-select-container col-span-2"
                                                                                                                classNamePrefix="my-react-select"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div> :
                                                                                                    props.type === "SUBGTM" ?
                                                                                                    <div
                                                                                                        className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 mt-5 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 setting-wrapper  "
                                                                                                    >
                                                                                                         <div>
                                                                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">SUB GTM<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                                                * </label>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <Select
                                                                                                                value={subGtm}
                                                                                                                options={responseHeaderData}
                                                                                                                onChange={e => {
                                                                                                                    setSubGtm(e);
                                                                                                                    setSubGtmId(e.value)
                                                                                                                }}
                                                                                                                placeholder={"Select SUB GTM"}
                                                                                                                className="my-react-select-container col-span-2"
                                                                                                                classNamePrefix="my-react-select"
                                                                                                            />
                                                                                                        </div>

                                                                                                        <div>
                                                                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">GTM<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                                                * </label>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <Select
                                                                                                                value={gtm}
                                                                                                                options={responseHeaderData}
                                                                                                                onChange={e => {
                                                                                                                    setGtm(e);
                                                                                                                    setGtmId(e.value)
                                                                                                                }}
                                                                                                                placeholder={"Select GTM"}
                                                                                                                className="my-react-select-container col-span-2"
                                                                                                                classNamePrefix="my-react-select"
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <label className="text-[#344054] text-[13px] flex items-center font-medium dark:text-white">Ref Code<label className="text-[#FF0000] text-[14px] font-medium">
                                                                                                                * </label></label>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <Select
                                                                                                                value={subGtmCode}
                                                                                                                options={responseHeaderData}
                                                                                                                onChange={e => {
                                                                                                                    setSubGtmCode(e);
                                                                                                                    setSubGtmCodeId(e.value);
                                                                                                                }}
                                                                                                                placeholder={"Select Ref Code"}
                                                                                                                className="my-react-select-container col-span-2"
                                                                                                                classNamePrefix="my-react-select"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div> :
                                                                                                    null

                                }

                            </div> :

                            isShowBrowseFile ?
                                <>

                                    <div>
                                        <div className='p-2 float-right'>

                                            <a href="#" onClick={(e) => downloadDocument()}
                                                className="text-sm text-[#0000FF] px-4 py-2 dark:text-white" rel="noreferrer">
                                                Download Template </a>

                                        </div><div className='p-2 mt-4 ml-4'>
                                            <span>Browse File</span>
                                            <div>
                                                <button className="border text-[Black] flex bg-[white] rounded-md p-2  border-[#D0D5DD] pr-3 cursor-pointer">
                                                    <input
                                                        onChange={(e) => { changeAttachment(e) }}
                                                        accept=".csv" id="fileImport"
                                                        multiple="multiple" type="file" className="cursor-pointer" />
                                                </button>
                                            </div>
                                            <span className='text-muted font-size-muted'>Note: Please select .csv file</span>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                }

            </div>


            {
                isLoader === false ?
                    <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                        <div className="flex pl-2 justify-end gap-3">
                            <div><Link to="#" onClick={() => cancelImport()} className="bg-white 
                                                    dark:text-[white] border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                            >Cancel</Link></div>

                            {
                                isShowPreviewDataTable ? <div><Link to="#" onClick={() =>
                                    saveData()
                                }
                                    className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Import</Link>
                                </div>
                                    :
                                    <div><Link to="#" onClick={() =>
                                        previewData()
                                    }
                                        className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Next</Link>
                                    </div>
                            }


                        </div>
                    </div>
                    : null
            }

        </div>
    );
}
