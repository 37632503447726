import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { Employee, Roles } from "../../../../../models";
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import 'alertifyjs/build/css/alertify.css';
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import { Auth } from 'aws-amplify';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import { Link } from 'react-router-dom';
import { Avatar } from "@mui/material";
import { Storage } from 'aws-amplify';
import { ObjectToArray } from "../../../../../components/helper/commonfunctions";
import HRLogin from '../../../../../setup/auth/hr.component';
import OrgTree from 'react-org-tree';
import redingToneImg from '../../../../../assets/svg/RedingtonLogo.svg'


const OrganizationCharts = () => {

    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [organizationChartData, setOrganizationChartData] = useState([]);
    const [isOrganizationChartEnable, setIsOrganizationChartEnable] = useState(false);

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //recursive
    function toTree(data, pid = "") {
        return data.reduce((r, e) => {
            if (e.parentEmployeeId == pid) {
                const obj = { ...e };
                // console.log("obj :", obj)
                const children = toTree(data, e.id);
                if (children.length) {
                    obj.children = children;
                }
                r.push(obj);
            }
            return r;
        }, [])
    }

    //Get Employees List
    async function BindList() {
        var employeeResponse = await DataStore.query(Employee, Predicates.ALL, {
            sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
        });

        let chartData = []
        for (let i = 0; i < employeeResponse.length; i++) {
            var getRoles = await DataStore.query(Roles, (c) => c.id.eq(employeeResponse[i].role_id));
            let profilePic = ""
            let loopEmployeeItem = employeeResponse[i];
            let singleEmpData = ObjectToArray(loopEmployeeItem);
            await Storage.get(employeeResponse[i]["profilePic"]).then(result => { profilePic = result });
            singleEmpData['profilePic'] = profilePic

            let data = {
                "id": employeeResponse[i].id,
                "parentEmployeeId": employeeResponse[i].parentEmployeeId === null ? "" : employeeResponse[i].parentEmployeeId,
                "role": getRoles.length > 0 ? getRoles[0].name : "",
                "label": employeeResponse[i].firstName + " " + employeeResponse[i].lastName,
                "employeeId": employeeResponse[i].employeeId,
                "type": "person",
                "className": "p-person",
                "expanded": false,
                "avatar": profilePic,
                "children": []
            }
            chartData.push(data)

            if (i === employeeResponse.length - 1) {
                const result = toTree(chartData)
                setIsOrganizationChartEnable(true)
                setOrganizationChartData(result)
            }
        }
    }

    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    //chart Data
    const chartData = {
        id: 0,
        label: 'Redington',
        employeeId: "",
        role: 'Team',
        avatar: redingToneImg,
        children: [
            ...organizationChartData
        ]
    }


    return isAuthenticated ? (
        <div className="pt-16 ">
            <TopNavBar />
            <LeftSideBar />
            <div className="dashboard-main-wrapper pl-20 pr-4 mt-3">

                <div className="flex flex-1 flex-wrap p-5 pb-4 px-0 top-action-wrapper">
                    <div className="md:flex xs:w-full sm:w-full md:w-auto flex-row align-center">
                        <div className="md:flex block md:col-span-2 mb-4">
                        </div>
                        <div className="md:flex block mb-4">
                            <h2 className="page-title">Organization Chart</h2>
                        </div>
                    </div>

                    <div className="md:flex flex-1 flex-row xs:w-full sm:w-full md:w-auto align-center justify-end md:ml-auto gap-3 right-side">

                        <div className="md:flex block  gap-3 mb-3">
                            <Link to="/hr/settings/employee" className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]">Back</Link>
                        </div>

                    </div>
                </div>
                <div className='h-[50vw] w-full overflow-scroll flex justify-center '>
                <div className='mt-2 mx-20 pb-20 ' style={{ maxWidth: '100%', backgroundColor: "transparent" }}>
                    {
                        isOrganizationChartEnable === true ?

                            <OrgTree
                                data={chartData}
                                horizontal={false}
                                collapsable={true}
                                expandAll={false}
                                labelClassName={["'bg-red'"]}
                                // labelWidth={200}
                                // onClick={(e, data) => {
                                // }}

                                renderContent={(data) => {
                                    return (
                                        <div className="bg-[white] shadow-lg rounded-b-lg dark:bg-[#4A5056]" style={{ margin: '0', padding: '0' }} >
                                            <div className="p-2 text-[15px] text-[#FFFFFF] bg-[#029046]  dark:border-[#333231] rounded-t-md">{data.role}</div>
                                            <div className="px-8 pb-3" >
                                                <div className="flex gap-4 items-center p-3" style={{ justifyContent: 'center' }}>
                                                    <Avatar
                                                        style={{ width: 50, height: 50 }}
                                                        alt={data.name}
                                                        src={data.avatar}
                                                    />
                                                </div>
                                                <div className='dark:text-[#FFFFFF] text-[#344054] text-[15px]'>{data.label}</div>
                                                {
                                                    data.employeeId !== "" ?
                                                        <div className='dark:text-[#FFFFFF]  text-xs text-[#667085] pb-2'>{data.employeeId === null ? "" : "(" + data.employeeId + ")"}</div>
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    )
                                }}
                            />
                            :
                            null
                    }
                </div>

                </div>

            </div>
        </div>
    ) : (
        <HRLogin sessionExpired={1} />
    )
}
export default OrganizationCharts;