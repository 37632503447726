import React, { useState, useEffect } from 'react';
import LeftSideBar from '../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../components/common/hr/topnav.component';
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ViewButton from '../../../components/common/buttons/ViewButton';
import EditButton from '../../../components/common/buttons/EditButton';
import DeleteButton from '../../../components/common/buttons/DeleteButton';
import Select from 'react-select';
import popupHeader from '../../../assets/images/Evaluation_header_img.png'
import imageDeployedLarge from '../../../assets/images/deployedImg.png';
import { Auth } from 'aws-amplify';
import { ValidateHRRole } from '../../../components/helper/validateRole';
import HRLogin from '../../../setup/auth/hr.component';
import { FinancialYear, BIUnit, SBU, FinanceActuals, Employee, Roles, InitiateEvaluation, Evaluation } from '../../../models';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { ObjectLength, ObjectToArray } from "../../../components/helper/commonfunctions";
import moment from 'moment';
import { toast } from "react-toastify";
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { sendNotification } from '../../../components/helper/notification';
import { NOTIFICATIONS_TYPES, EMAIL_SUBJECTS } from '../../../components/helper/enum'
import { reactLocalStorage } from 'reactjs-localstorage';
import * as queries from '../../../graphql/queries'
import * as mutations from '../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../src/components/helper/graphQLFunctions'
import { API, graphqlOperation } from 'aws-amplify';
import { ProgressSpinner } from 'primereact/progressspinner';


export default function ViewInitiateEvaluation() {

    let navigateTo = useNavigate();

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const { financialInitiateId, quarterId, evaluationId } = useParams();
    let loggedHREmployeeId = reactLocalStorage.get('loggedHREmployeeId')


    //state 
    const [financialYear, setFinancialYear] = useState("")
    const [startAndEndDate, setStartAndEndDate] = useState("")

    //list
    const [employeeList, setEmployeeList] = useState([])
    const [selectedEmployeeForInitiation, setSelectedEmployeeForInitiation] = useState([]);
    
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [allRoles, setAllRoles] = useState([]);
    const [allSBUs, setAllSBUs] = useState([]);
    const [allInitiateEvals, setAllInitiateEvals] = useState([]);


    //function for Actions
    const actionsOnComponySbu = (row) => {
        return (
            <React.Fragment >
                <div className="flex justify-center gap-8 ">
                    <Link onClick={(e) => {
                        handleEditClick(e, row.id)
                    }}  ><EditButton /></Link>
                    <Link onClick={(e) => handleDeleteClick(e, row.id)} ><DeleteButton /></Link>
                </div>

            </React.Fragment>
        );
    }

    // TABLES  - Show status 
    const requestsStatus = (rowData) => {
        return (
            rowData.status === "Initiated" ?
                <p className="bg-[#E5F3EC] text-[#4FB155] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center"> <div className='w-2 h-2 bg-[#4FB155] rounded-full'></div>{"Initiated"}</p>
                :
                <p className="bg-[#FEF3F2] text-[#FDA29B] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center "> <div className='w-2 h-2 bg-[#FDA29B] rounded-full'></div>{"Not Initiated"}</p>
        )
    }

    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        // const objDeleteRole = await DataStore.query(FinanceActuals, id);
        const objDeleteRole = await graphQLFindRecordById('getFinanceActuals', id);

        alertify.confirm('Confirmation', 'Do you want to delete this Finance Actual?', async function () {
            // //Delete Existing InterpreterRequest for this language
            // DataStore.delete(FinanceActuals, (c) => c.id("eq", id))
            // //Delete language
            // DataStore.delete(objDeleteRole);

            await API.graphql({
                query: mutations.deleteFinanceActuals,
                variables: {
                    input: {
                        id: objDeleteRole.id,
                        _version: objDeleteRole._version,
                    }
                }
            });
            BindList();
            toast.success("Deleted");
        }
            , function () { });

    };

    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/hr/evaluation/createebitpbt/' + id);
    };

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //Initiate Employee
    async function onclickInitiateEvaluation() {
        if (selectedEmployeeForInitiation.length === 0) { toast.error('Please select Employee.'); return; }

        alertify.confirm('Confirmation', 'Do you want to initiate evaluation for the selected employee(s)?', async function () {

            selectedEmployeeForInitiation.map(async (currentItem, i) => {

                // var currentInitiatePlanningDetails = await DataStore.query(InitiateEvaluation, (c) => c.and(c => [
                //     c.evaluation_id.eq(evaluationId),
                //     c.employee_id.eq(currentItem.id)
                // ]));

                var currentInitiatePlanningDetails = allInitiateEvals.filter(c => c.evaluation_id === evaluationId && c.employee_id === currentItem.id)


                if (currentInitiatePlanningDetails.length > 0) {
                    // const original = await DataStore.query(InitiateEvaluation, currentInitiatePlanningDetails[0].id);'
                    const original = await graphQLFindRecordById('getInitiateEvaluation', currentInitiatePlanningDetails[0].id);

                    // await DataStore.save(
                    //     InitiateEvaluation.copyOf(original, (updated) => {
                    //         updated.evaluation_id = evaluationId;
                    //         updated.employee_id = currentItem.id;
                    //     })
                    // ).then(async (response) => { })

                    await API.graphql({
                        query: mutations.updateInitiateEvaluation,
                        variables: {
                            input: {
                                id: original.id,
                                evaluation_id: evaluationId,
                                employee_id: currentItem.id,
                                _version: original._version,
                            }
                        }
                    });

                    let requestTypeName = NOTIFICATIONS_TYPES.InitiateEvaluationPlan
                    let emailSubject = EMAIL_SUBJECTS.InitiateEvaluationPlan
                    let notificationMsg = "The goal setting plan has been initialized for FY " + financialYear + ", Q" + quarterId + " has been initiated, Evaluation Period: " + startAndEndDate
                    // console.log(notificationMsg)
                    await sendNotification(loggedHREmployeeId, currentItem.id, requestTypeName, notificationMsg, emailSubject);
                    setSelectedEmployeeForInitiation([])
                    BindList();


                }
                else {
                    // await DataStore.save(
                    //     new InitiateEvaluation({
                    //         "evaluation_id": evaluationId,
                    //         "employee_id": currentItem.id
                    //     })
                    // ).then(async (response) => { })

                    await API.graphql(
                        graphqlOperation(mutations.createInitiateEvaluation, {
                            input: {
                                evaluation_id: evaluationId,
                                employee_id: currentItem.id
                            }
                        })
                    );

                    let requestTypeName = NOTIFICATIONS_TYPES.InitiateEvaluationPlan
                    let emailSubject = EMAIL_SUBJECTS.InitiateEvaluationPlan
                    let notificationMsg = "The goal setting plan has been initialized for FY " + financialYear + ", Q" + quarterId + " has been initiated, Evaluation Period: " + startAndEndDate
                    // console.log(notificationMsg)
                    await sendNotification(loggedHREmployeeId, currentItem.id, requestTypeName, notificationMsg, emailSubject);
                    setSelectedEmployeeForInitiation([])
                    BindList();

                }
            })
            toast.success('Goals evaluation for the selected employee(s) has been initiated.');

        }
            , function () { });
    }

    //Bind list
    async function BindList() {

        //set Financial Year
        // var financialYearResponse = await DataStore.query(FinancialYear, financialInitiateId);
        var financialYearResponse = await graphQLFindRecordById('getFinancialYear', financialInitiateId);
        if (financialYearResponse) { setFinancialYear(financialYearResponse.name) } else { setFinancialYear("") }

        //set start date and end date
        // var evaluationResponses = await DataStore.query(Evaluation, evaluationId);
        var evaluationResponses = await graphQLFindRecordById('getEvaluation', evaluationId);
        let start_And_END_Date = moment(evaluationResponses.startDate).format("Mo MMM YYYY") + " - " + moment(evaluationResponses.endDate).format("Mo MMM YYYY")
        setStartAndEndDate(start_And_END_Date)

        // var employeeResponse = await DataStore.query(Employee, Predicates.ALL);
        var employeeResponse = await graphQLGetAllData('listEmployees');
        if (employeeResponse.length > 0) {
            var newResponse = [];

            Object.keys(employeeResponse).map(async (currentItem, i) => {
                let loopEmployeeItem = employeeResponse[currentItem];
                let data = ObjectToArray(loopEmployeeItem);

                data['employeeName'] = employeeResponse[i].firstName + " " + employeeResponse[i].lastName

                //Role List
                // var roleResponse = await DataStore.query(Roles, employeeResponse[i].role_id)
                var roleResponse = allRoles.find(c => c.id === employeeResponse[i].role_id);
                data['role'] = roleResponse ? roleResponse.name : '';

                data['vertical'] = "Distribution"

                //get SBU
                if (employeeResponse[i].param_sbu_id) {
                    // var SBUResponse = await DataStore.query(SBU, employeeResponse[i].param_sbu_id);
                    var SBUResponse = allSBUs.find(c => c.id === employeeResponse[i].param_sbu_id);
                    data['sbuName'] = SBUResponse ? SBUResponse.name : '';
                }
                else {
                    data['sbuName'] = "-"
                }

                //Parent Employee List
                if (employeeResponse[i].parentEmployeeId !== "") {
                    // var parentResponse = await DataStore.query(Employee, (c) => c.id.eq(employeeResponse[i].parentEmployeeId))
                    // var parentResponse = await graphQLFindRecordById('getEmployee', employeeResponse[i].parentEmployeeId);
                    var parentResponse = employeeResponse.find(c => c.id === employeeResponse[i].parentEmployeeId);
                    if (parentResponse) {
                        data["parentEmployeeName"] = parentResponse.firstName + " " + parentResponse.lastName
                    } else {
                        data["parentEmployeeName"] = "-"
                    }
                }

                //check Status
                // var currentInitiateEvaluationDetails = await DataStore.query(InitiateEvaluation, (c) => c.and(c => [
                //     c.evaluation_id.eq(evaluationId),
                //     c.employee_id.eq(employeeResponse[i].id)
                // ]));
                var currentInitiateEvaluationDetails = allInitiateEvals.filter(c => c.evaluation_id === evaluationId && c.employee_id === employeeResponse[i].id);
                //set Status
                if (currentInitiateEvaluationDetails.length > 0) {
                    data["status"] = "Initiated"
                } else {
                    data["status"] = "Not Initiated"
                }

                data["eligibility"] = "Yes";
                data["dateOfJoining"] = moment(employeeResponse[i].createdAt).format("MMM D, YYYY");
                data["startDate"] = moment(evaluationResponses.startDate).format("MMM D, YYYY");
                data["endDate"] = moment(evaluationResponses.endDate).format("MMM D, YYYY");

                newResponse.push(data);

                //On Last Record assign to state
                if (i === employeeResponse.length - 1) {
                    let sortArray = newResponse.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
                    setEmployeeList(sortArray)
                }
            })
        } else {
            setEmployeeList([]);
        }
    }

    const getMasterData = async () => {
        setDataLoaded(false);

        var SBUResponses = await graphQLGetAllData('listSBUS');
        setAllSBUs(SBUResponses);

        var roleResponses = await graphQLGetAllData('listRoles');
        setAllRoles(roleResponses);

        var listInitiateEvaluationsResponses = await graphQLGetAllData('listInitiateEvaluations');
        setAllInitiateEvals(listInitiateEvaluationsResponses);

        setDataLoaded(true);
    }


    useEffect(() => {
        if (isDataLoaded === true) {
            onLoad();
            BindList();
        }
    }, [allInitiateEvals, allRoles, allSBUs]);

    useEffect(() => {
        getMasterData();
    }, [])

    return (
        isAuthenticated ? (
            <div className="pt-16 ">
                <TopNavBar />
                <LeftSideBar />

                <div className="dashboard-main-wrapper target-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className='flex justify-between'>
                            <div className="sm:flex items-center space-x-8 justify-between">
                                <div className="mt-5 lg:mt-0">
                                    <Link to={"/hr/evaluation"}
                                        className="py-2 px-3 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:border-[#4A5056] dark:bg-[#4A5056] dark:text-white" ><i className="icon arrow_left_double mr-6 "></i> Back</Link>
                                </div>
                                <div className="text-2xl font-semibold dark:text-white">Goals Evaluation, FY {financialYear}, Q{quarterId} </div>
                            </div>
                            <div className="mt-5 lg:mt-0">
                                <Link onClick={() => {
                                    onclickInitiateEvaluation();
                                }}
                                    className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" >Initiate</Link>
                            </div>

                        </div>


                        <div className=" rounded-lg mt-5 bg-white">
                            <div>
                                <h6 className="text-[#101828] text-[18px]   px-4 py-3 ">Total: {employeeList.length} Employee</h6>
                            </div>
                            {employeeList.length > 0 ?
                                <DataTable
                                    paginator
                                    value={employeeList}
                                    responsiveLayout="scroll"
                                    selection={selectedEmployeeForInitiation}
                                    onSelectionChange={e => setSelectedEmployeeForInitiation(e.value)}
                                    dataKey="employeeId"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                >
                                    <Column selectionMode="multiple"
                                        field="employeeName" header="" />
                                    <Column field="employeeId" header="Employee ID" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                    <Column field="employeeName" header="Name" sortable filter filterPlaceholder="Search by Name"></Column>
                                    <Column field="role" header="Role" sortable filter filterPlaceholder="Search by Role"></Column>
                                    <Column field="vertical" header="Vertical" sortable filter filterPlaceholder="Search by Vertical"></Column>
                                    <Column field="sbuName" header="SBU" sortable filter filterPlaceholder="Search by SBU"></Column>
                                    <Column field="dateOfJoining" header="DOJ"></Column>
                                    <Column field="parentEmployeeName" header="Reporting Manager" sortable filter filterPlaceholder="Search by SBU"></Column>
                                    <Column field="eligibility" alignHeader={'center'} header="Eligibility" sortable filter filterPlaceholder="Search by Eligibility"></Column>
                                    <Column field="startDate" header="Start Date" sortable filter filterPlaceholder="Search by Start Date"></Column>
                                    <Column field="endDate" header="End Date" sortable filter filterPlaceholder="Search by End Date"></Column>
                                    <Column field="status" alignHeader={'center'} body={requestsStatus} header="Status" sortable filter filterPlaceholder="Search by status"></Column>
                                </DataTable> :
                                <div className='text-center py-8' ><ProgressSpinner /></div>
                            }
                        </div>

                    </div>
                </div>
            </div >
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}
