import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import moment from "moment";
import "primeicons/primeicons.css";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import { reactLocalStorage } from 'reactjs-localstorage';
import popupHeader from '../../assets/images/popup_header_img.png';
import AddButton from '../../components/common/buttons/AddButton';
import ViewButton from '../../components/common/buttons/ViewButton';
import LeftSideBar from '../../components/common/hr/leftnav.component';
import TopNavBar from '../../components/common/hr/topnav.component';
import NoDataFound from '../../components/common/nodatafound';
import { ConvertResponseForSelect, ObjectToArray } from '../../components/helper/commonfunctions';
import { NOTIFICATIONS_TYPES, PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES, EMAIL_SUBJECTS } from '../../components/helper/enum';
import { sendNotification } from '../../components/helper/notification';
import { ValidateHRRole } from '../../components/helper/validateRole';
import { Employee, FinancialYear, HRAssignTargetDetails, HRAssigTargets, Parameters, Planning, Roles, TargetDetails, Targets, InitiatePlanning, FinancialParameterDetails, FinancialParameters } from "../../models";
import HRLogin from '../../setup/auth/hr.component';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../src/components/helper/graphQLFunctions'

export default function HrPlanning_AssignTargets() {

    var loggedHrEmployeeId = reactLocalStorage.get('loggedHREmployeeId');
    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [renderItems, setRenderItems] = useState(false);


    // variables for data set
    const [financialYear, setFinancialYear] = useState("");
    const [financialYearList, setFinancialYearList] = useState([]);

    const [isDisableMode, setDisableMode] = useState(false);

    //Target Functionality
    const [employeeList, setEmployeeList] = useState([]);
    const [isAssignViewTargetPopup, isViewAssignTargetPopup] = useState(0);
    const [assignTargetOnCheckbox] = useState([]);
    const [showAssignButtonForMultiple, setAssignButtonForMultiple] = useState(false);

    //Set Employee Details
    const [employeeId, setEmployeeId] = useState("");
    const [employeeName, setEmployeeName] = useState("");
    const [employeeCode, setEmployeeCode] = useState("");
    const [employeeRole, setEmployeeRole] = useState("");
    const [employeeParentId, setEmployeeParentId] = useState("");
    const [employeeRoleId, setEmployeeRoleId] = useState("");
    const [parameterWithWeightage, setParameterWithWeightage] = useState([]);

    const [enableAssignTarget, setEnableAssignTarget] = useState(true);

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
        BindFinancialYear()
    }, []);


    async function BindFinancialYear() {
        //set financial year
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        if (Object.keys(selectedYear).length !== 0) {

            onYearChange(selectedYear)
            setFinancialYear(selectedYear)
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

            onYearChange({ label: responses[0].name, value: responses[0].id })
            setFinancialYear({ label: responses[0].name, value: responses[0].id })
        }

        //get financial year
        // var PlanningRes = await DataStore.query(Planning, Predicates.ALL)
        var PlanningRes = await graphQLGetAllData('listPlannings');
        var getPlanningFY = PlanningRes.map((response) => response.financial_year_id)
        var newResponse = []
        for (let i = 0; i < getPlanningFY.length; i++) {
            // var responses = await DataStore.query(FinancialYear, getPlanningFY[i], {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });
            var responses = await graphQLFindRecordById('getFinancialYear', getPlanningFY[i]);

            newResponse.push(responses)
            if (i === getPlanningFY.length - 1) {
                let sortArray = newResponse.sort((a, b) => (a.name > b.name ? 1 : -1))
                var myArray = []; myArray = ConvertResponseForSelect(sortArray); setFinancialYearList(myArray);
            }
        }
    }


    const updateTargetValue = (rowData, targetCount) => {
        const updatedData = parameterWithWeightage.map(x => (x.parameterId === rowData.parameterId ? { ...x, targetCount: targetCount } : x));
        console.log("updatedData", updatedData)
        setParameterWithWeightage(updatedData)
    }

    //View Employee & its parameter based on role
    const handleViewEditClick = async (e, selectedEmpData, type) => {
        console.log(selectedEmpData.employeeRoleId)
        e.preventDefault();
        if (financialYear !== "") {
            if (type === "V") {
                setDisableMode(true)
            } else {
                setDisableMode(false)
            }
            setEmployeeId(selectedEmpData.employeeId);
            setEmployeeCode(selectedEmpData.employeeCode);
            setEmployeeName(selectedEmpData.employeeName);
            setEmployeeRole(selectedEmpData.employeeRole);
            setEmployeeRoleId(selectedEmpData.employeeRoleId);
            setEmployeeParentId(selectedEmpData.employeeParentId);

            let finalData_ParameterWithWeightage = []
            // var financialParameterResponses = await DataStore.query(FinancialParameters, (c) => c.financial_year_id.eq(financialYear.value));
            var financialParameterResponses = await graphQLGetAllData('listFinancialParameters', [{ financial_year_id: { eq: financialYear.value } }]);


            if (financialParameterResponses.length > 0) {

                // let totalParameters = await DataStore.query(FinancialParameterDetails, (c) => c.financial_parameter_id.eq(financialParameterResponses[0].id));
                let totalParameters = await graphQLGetAllData('listFinancialParameterDetails', [{ financial_parameter_id: { eq: financialParameterResponses[0].id } }]);

                for (let i = 0; i < totalParameters.length; i++) {
                    try {
                        // var hrAssignTargetsResponses = await DataStore.query(HRAssigTargets, (c) => c.and(c => [
                        //     c.employee_id.eq(selectedEmpData.employeeId),
                        //     c.financial_year_id.eq(financialYear.value)
                        // ]));
                        var hrAssignTargetsResponses = await graphQLGetAllData('listHRAssigTargets', [{ employee_id: { eq: selectedEmpData.employeeId } }, { financial_year_id: { eq: financialYear.value } }]);


                        // let getParameter = await DataStore.query(Parameters, totalParameters[i].parameter_id);
                        let getParameter = await graphQLFindRecordById('getParameters', totalParameters[i].parameter_id);

                        if (selectedEmpData.status === "Draft" || selectedEmpData.status === "Assigned") {
                            // var hrAssignTargetsDetailsResponses = await DataStore.query(HRAssignTargetDetails, (c) => c.hrAssign_Target_id.eq(hrAssignTargetsResponses[0].id));
                            var hrAssignTargetsDetailsResponses = await graphQLGetAllData('listHRAssignTargetDetails', [{ hrAssign_Target_id: { eq: hrAssignTargetsResponses[0].id } }]);

                            let targetCount = hrAssignTargetsDetailsResponses.filter((response) => response.parameter_id === getParameter.id && response.parameter_id !== 0 ? response : null)
                            if (type === "V") {
                                if (targetCount[0].targetValue > 0) {
                                    let data = {
                                        "parameterId": getParameter.id,
                                        "name": getParameter.name,
                                        "targetCount": targetCount.length > 0 ? targetCount[0].targetValue : "",
                                        "type": getParameter.type === PARAMETERS_TYPES.Days ? PARAMETERS_TYPES_NAMES.D : getParameter.type === PARAMETERS_TYPES.Percentage ? PARAMETERS_TYPES_NAMES.P : getParameter.type === PARAMETERS_TYPES.Amount ? PARAMETERS_TYPES_NAMES.A : PARAMETERS_TYPES_NAMES.N
                                    }
                                    finalData_ParameterWithWeightage.push(data)
                                }
                            } else {
                                let data = {
                                    "parameterId": getParameter.id,
                                    "name": getParameter.name,
                                    "targetCount": targetCount.length > 0 ? targetCount[0].targetValue : "",
                                    "type": getParameter.type === PARAMETERS_TYPES.Days ? PARAMETERS_TYPES_NAMES.D : getParameter.type === PARAMETERS_TYPES.Percentage ? PARAMETERS_TYPES_NAMES.P : getParameter.type === PARAMETERS_TYPES.Amount ? PARAMETERS_TYPES_NAMES.A : PARAMETERS_TYPES_NAMES.N
                                }
                                finalData_ParameterWithWeightage.push(data)
                            }
                        }
                        else {
                            let data = {
                                "parameterId": getParameter.id,
                                "name": getParameter.name,
                                "targetCount": "",
                                "type": getParameter.type === PARAMETERS_TYPES.Days ? PARAMETERS_TYPES_NAMES.D : getParameter.type === PARAMETERS_TYPES.Percentage ? PARAMETERS_TYPES_NAMES.P : getParameter.type === PARAMETERS_TYPES.Amount ? PARAMETERS_TYPES_NAMES.A : PARAMETERS_TYPES_NAMES.N
                            }
                            finalData_ParameterWithWeightage.push(data)
                        }

                    } catch (e) { }

                }
            }
            setTimeout(() => {
                let sortArray_finalData_ParameterWithWeightage = finalData_ParameterWithWeightage.sort((a, b) => a.name.localeCompare(b.name))
                setParameterWithWeightage(sortArray_finalData_ParameterWithWeightage)
                if (type === "V" || type === "E") {
                    isViewAssignTargetPopup(1)
                }
            }, 10);

        } else {
            toast.error("Select financial year")
        }
    };


    //Assign target count to the employee based on his role 
    const assignTarget = async () => {
        const checkIfTargetCountEmpty = parameterWithWeightage.some(item => item.targetCount > 0);

        // Below code is not being used
        /* Object.keys(parameterWithWeightage).map(async (value, i) => {
            if (parameterWithWeightage[value]['targetCount']) {
                console.log(parameterWithWeightage[value]['targetCount'])
            }
         }) */

        if (checkIfTargetCountEmpty) {
            alertify.confirm('Confirmation', 'Do you want to assign targets?', async function () {
                // var checkTargetAssigned = await DataStore.query(Targets, (c) => c.and(c => [
                //     c.employee_id.eq(employeeId),
                //     c.financial_year_id.eq(financialYear.value),
                //     c.role_id.eq(employeeRoleId)
                // ]));
                var checkTargetAssigned = await graphQLGetAllData('listTargets', [
                    { employee_id: { eq: employeeId } },
                    { financial_year_id: { eq: financialYear.value } },
                    { role_id: { eq: employeeRoleId } }
                ]);



                if (checkTargetAssigned.length == 0) {
                    saveTarget()
                }

                // var newTargetId = await DataStore.save(
                //     new Targets({
                //         "financial_year_id": financialYear.value,
                //         "employee_id": employeeId,
                //         "report_manager_employee_id": employeeParentId,
                //         "role_id": employeeRoleId,
                //         "isAddedByHRAdmin": true,
                //         "isAssignedToEmployee": true
                //     })
                // );

                var newTargetId = await API.graphql(
                    graphqlOperation(mutations.createTargets, {
                        input: {
                            financial_year_id: financialYear.value,
                            employee_id: employeeId,
                            report_manager_employee_id: employeeParentId,
                            role_id: employeeRoleId,
                            isAddedByHRAdmin: true,
                            isAssignedToEmployee: true,
                        }
                    })
                );

                newTargetId = newTargetId.data.createTargets;

                console.log("newTargetId", newTargetId)
                if (newTargetId) {
                    console.log("parameterWithWeightage", parameterWithWeightage)
                    Object.keys(parameterWithWeightage).map(async (value, i) => {
                        // await DataStore.save(
                        //     new TargetDetails({
                        //         "target_id": newTargetId.id,
                        //         "parameter_id": parameterWithWeightage[value]['parameterId'],
                        //         "target_value": parameterWithWeightage[value]['targetCount'],
                        //         "target_assigned_date": moment().format(),
                        //     })
                        // );

                        // Condition added if getting target value then only target details should save: Pratap 25th Oct 2023 as per discussing with Selva and Mangesh
                        // if (parameterWithWeightage[value]['targetCount'] > 0) {
                        await API.graphql(
                            graphqlOperation(mutations.createTargetDetails, {
                                input: {
                                    target_id: newTargetId.id,
                                    parameter_id: parameterWithWeightage[value]['parameterId'],
                                    target_value: parameterWithWeightage[value]['targetCount'] ? parameterWithWeightage[value]['targetCount'] : '0',
                                    target_assigned_date: moment().format(),
                                }
                            })
                        );
                        // }
                    })
                    // if (checkTargetAssigned.length == 0) {
                    //     toast.success('Target assign successfully.');
                    // }

                    onYearChange(financialYear)
                }

                //send a notification
                let requestTypeName = NOTIFICATIONS_TYPES.AssignTarget
                let emailSubject = EMAIL_SUBJECTS.AssignTarget
                let notificationMsg = "Your Annual Target for " + financialYear.label + " have been assigned."
                // console.log(notificationMsg)
                await sendNotification(loggedHrEmployeeId, employeeId, requestTypeName, notificationMsg, emailSubject);


                isViewAssignTargetPopup(0)
                onYearChange({ label: financialYear.label, value: financialYear.value })
            }
                , function () { });
        } else {
            toast.error("Enter target value.")
        }

    }

    //Save target count to the employee based on his role 
    const saveTarget = async () => {
        // var hrAssignTargetsResponses = await DataStore.query(HRAssigTargets, (c) => c.and(c => [
        //     c.employee_id.eq(employeeId),
        //     c.financial_year_id.eq(financialYear.value)
        // ]));
        var hrAssignTargetsResponses = await graphQLGetAllData('listHRAssigTargets', [
            { employee_id: { eq: employeeId } },
            { financial_year_id: { eq: financialYear.value } }
        ]);


        //var hrAssignTargetsResponses = await DataStore.query(HRAssigTargets, (c) => c.employee_id.eq(employeeId));
        if (hrAssignTargetsResponses.length > 0) {
            // await DataStore.delete(HRAssignTargetDetails, (c) => c.hrAssign_Target_id.eq(hrAssignTargetsResponses[0].id)).
            // then(() => { })
            let toDeleteData = await graphQLGetAllData('listHRAssignTargetDetails', [{ hrAssign_Target_id: { eq: hrAssignTargetsResponses[0].id } }]);

            for (var i = 0; i < toDeleteData.length; i++) {
                await API.graphql({
                    query: mutations.deleteHRAssignTargetDetails,
                    variables: {
                        input: {
                            id: toDeleteData[i].id,
                            _version: toDeleteData[i]._version,
                        }
                    }
                });
            }

            Object.keys(parameterWithWeightage).map(async (value, i) => {
                // await DataStore.save(
                //     new HRAssignTargetDetails({
                //         "hrAssign_Target_id": hrAssignTargetsResponses[0].id,
                //         "parameter_id": parameterWithWeightage[value]['parameterId'],
                //         "targetValue": parameterWithWeightage[value]['targetCount'],
                //     })
                // )

                // Check this condition
                if (parameterWithWeightage[value]['targetCount'] > 0) {
                    await API.graphql(
                        graphqlOperation(mutations.createHRAssignTargetDetails, {
                            input: {
                                hrAssign_Target_id: hrAssignTargetsResponses[0].id,
                                parameter_id: parameterWithWeightage[value]['parameterId'],
                                targetValue: parameterWithWeightage[value]['targetCount'],
                            }
                        })
                    );
                }

            })
            toast.success('Target saved successfully.');

        } else {
            // var newHrAssignTargetId = await DataStore.save(
            //     new HRAssigTargets({
            //         "financial_year_id": financialYear.value,
            //         "employee_id": employeeId,
            //         "isAssigned": "Open"
            //     })
            // );
            var newHrAssignTargetId = await API.graphql(
                graphqlOperation(mutations.createHRAssigTargets, {
                    input: {
                        financial_year_id: financialYear.value,
                        employee_id: employeeId,
                        isAssigned: "Open",
                    }
                })
            );
            newHrAssignTargetId = newHrAssignTargetId.data.createHRAssigTargets;
            if (newHrAssignTargetId) {
                Object.keys(parameterWithWeightage).map(async (value, i) => {
                    // await DataStore.save(
                    //     new HRAssignTargetDetails({
                    //         "hrAssign_Target_id": newHrAssignTargetId.id,
                    //         "parameter_id": parameterWithWeightage[value]['parameterId'],
                    //         "targetValue": parameterWithWeightage[value]['targetCount'],
                    //     })
                    // );

                    // Check this condition
                    if (parameterWithWeightage[value]['targetCount'] > 0) {
                        await API.graphql(
                            graphqlOperation(mutations.createHRAssignTargetDetails, {
                                input: {
                                    hrAssign_Target_id: newHrAssignTargetId.id,
                                    parameter_id: parameterWithWeightage[value]['parameterId'],
                                    targetValue: parameterWithWeightage[value]['targetCount'],
                                }
                            })
                        );
                    }

                })
                toast.success('Target assign successfully.');
            }
        }
        setTimeout(() => {
            onYearChange({ label: financialYear.label, value: financialYear.value })
        }, 100);
        isViewAssignTargetPopup(0)
    }


    const onYearChange = async (e) => {
        // var employeeResponses = await DataStore.query(Employee, (c) => c.isAssignDirectTarget.eq(true), {
        //     sort: s => s.firstName(SortDirection.ASCENDING)
        // });
        var employeeResponses = await graphQLGetAllData('listEmployees', [{ isAssignDirectTarget: { eq: true } }]);
        employeeResponses = employeeResponses.sort((a, b) => a.firstName.localeCompare(b.firstName));

        //Check Planning for Assign target
        // var checkPlanningInitiated = await DataStore.query(Planning, (c) => c.financial_year_id.contains(e.value));
        var checkPlanningInitiated = await graphQLGetAllData('listPlannings', [{ financial_year_id: { contains: e.value } }]);

        // var checkPlanningInitiatedResponse = await DataStore.query(InitiatePlanning, (c) => c.planning_id.eq(checkPlanningInitiated[0].id));
        var checkPlanningInitiatedResponse = await graphQLGetAllData('listInitiatePlannings', [{ planning_id: { eq: checkPlanningInitiated[0].id } }]);

        if (checkPlanningInitiatedResponse.length == 0) { setEnableAssignTarget(false) } else { setEnableAssignTarget(true) }
        // console.log('employeeResponses' + JSON.stringify(employeeResponses))

        if (employeeResponses.length > 0) {
            var newResponse = [];

            for (let i = 0; i < employeeResponses.length; i++) {
                // Object.keys(employeeResponses).map(async (currentItem, i) => {

                let loopEmployeeItem = employeeResponses[i];
                let data = ObjectToArray(loopEmployeeItem);

                data["employeeCode"] = data["employeeId"]
                data["employeeId"] = data["id"]
                data["employeeName"] = data["firstName"] + " " + data["lastName"]

                data["employeeRoleId"] = data["role_id"]
                // var roleResponses = await DataStore.query(Roles, data["role_id"]);
                var roleResponses = await graphQLFindRecordById('getRoles', data["role_id"]);
                if (roleResponses) { data["employeeRole"] = roleResponses.name }

                // var assignTargetsResponses = await DataStore.query(HRAssigTargets, (c) => c.and(c => [
                //     c.employee_id.eq(data["id"]),
                //     c.financial_year_id.eq(e.value)
                // ]));
                var assignTargetsResponses = await graphQLGetAllData('listHRAssigTargets', [
                    { employee_id: { eq: data["id"] } },
                    { financial_year_id: { eq: e.value } }
                ]);



                // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
                //     c.employee_id.eq(data["id"]),
                //     c.financial_year_id.eq(e.value)
                // ]));
                var targetsResponses = await graphQLGetAllData('listTargets', [
                    { employee_id: { eq: data["id"] } },
                    { financial_year_id: { eq: e.value } }
                ]);


                data["status"] = targetsResponses.length > 0 ? "Assigned" : assignTargetsResponses.length > 0 ? "Draft" : "Open"
                newResponse.push(data);

                if (i === employeeResponses.length - 1) {
                    setEmployeeList(newResponse)
                }

            }
        }
    }

    const checkedData = (confirmIfChecked, selectedData) => {
        if (confirmIfChecked.target.checked) {
            assignTargetOnCheckbox.push(selectedData)
        } else {
            let findSelectedDataIndex = assignTargetOnCheckbox.findIndex((e) => e.employeeCode === selectedData.employeeCode)
            assignTargetOnCheckbox.splice(findSelectedDataIndex, 1);
        }
        if (assignTargetOnCheckbox.length > 0) {
            setAssignButtonForMultiple(true)
        } else {
            setAssignButtonForMultiple(false)
        }
    }


    const assignTarget_onCheckBoxSelection = () => {
        setAssignButtonForMultiple(false)
        assignTargetOnCheckbox.map(async (item) => {
            // var hrAssignTargetsResponses = await DataStore.query(HRAssigTargets, (c) => c.and(c => [
            //     c.employee_id.eq(item.employeeId),
            //     c.financial_year_id.eq(financialYear.value)
            // ]));
            var hrAssignTargetsResponses = await graphQLGetAllData('listHRAssigTargets', [
                { employee_id: { eq: item.employeeId } },
                { financial_year_id: { eq: financialYear.value } }
            ]);


            // var hrAssignTargetsDetailsResponses = await DataStore.query(HRAssignTargetDetails, (c) => c.hrAssign_Target_id.eq(hrAssignTargetsResponses[0].id));
            var hrAssignTargetsDetailsResponses = await graphQLGetAllData('listHRAssignTargetDetails', [{ hrAssign_Target_id: { eq: hrAssignTargetsResponses[0].id } }]);

            const checkIfTargetCountEmpty = hrAssignTargetsDetailsResponses.every(item => parseInt(item.targetValue));

            if (checkIfTargetCountEmpty) {
                // var newTargetId = await DataStore.save(
                //     new Targets({
                //         "financial_year_id": financialYear.value,
                //         "employee_id": item.employeeId,
                //         "report_manager_employee_id": item.employeeParentId,
                //         "role_id": item.employeeRoleId,
                //         "isAddedByHRAdmin": true,
                //         "isAssignedToEmployee": true
                //     })
                // );
                var newTargetId = await API.graphql(
                    graphqlOperation(mutations.createTargets, {
                        input: {
                            financial_year_id: financialYear.value,
                            employee_id: item.employeeId,
                            report_manager_employee_id: item.employeeParentId,
                            role_id: item.employeeRoleId,
                            isAddedByHRAdmin: true,
                            isAssignedToEmployee: true
                        }
                    })
                );

                if (newTargetId) {
                    // let getParameter = await DataStore.query(Parameters);
                    let getParameter = await graphQLGetAllData('listParameters');

                    for (var i = 0; i < getParameter.length; i++) {
                        try {
                            // var hrAssignTargetsResponses = await DataStore.query(HRAssigTargets, (c) => c.and(c => [
                            //     c.employee_id.eq(item.employeeId),
                            //     c.financial_year_id.eq(financialYear.value)
                            // ]));
                            var hrAssignTargetsResponses = await graphQLGetAllData('listHRAssigTargets', [
                                { employee_id: { eq: item.employeeId } },
                                { financial_year_id: { eq: financialYear.value } }
                            ]);


                            if (item.status === "Draft") {
                                // var hrAssignTargetsDetailsResponses = await DataStore.query(HRAssignTargetDetails, (c) => c.hrAssign_Target_id.eq(hrAssignTargetsResponses[0].id));
                                var hrAssignTargetsDetailsResponses = await graphQLGetAllData('listHRAssignTargetDetails', [{ hrAssign_Target_id: { eq: hrAssignTargetsResponses[0].id } }]);


                                let targetCount = hrAssignTargetsDetailsResponses.filter((response) => response.parameter_id === getParameter[i].id && response.parameter_id !== 0 ? response : null)
                                // await DataStore.save(
                                //     new TargetDetails({
                                //         "target_id": newTargetId.id,
                                //         "parameter_id": getParameter[i].id,
                                //         "target_value": targetCount.length > 0 ? targetCount[0].targetValue : 0,
                                //         "target_assigned_date": moment().format(),
                                //     })
                                // );

                                await API.graphql(
                                    graphqlOperation(mutations.createTargetDetails, {
                                        input: {
                                            target_id: newTargetId.id,
                                            parameter_id: getParameter[i].id,
                                            target_value: targetCount.length > 0 ? targetCount[0].targetValue : 0,
                                            target_assigned_date: moment().format(),
                                        }
                                    })
                                );
                            }
                        } catch (e) { }
                    }
                    toast.success('Target assign successfully.');
                }
            } else {
                toast.error('Please check all targets are filled.');
                return
            }
        })

        onYearChange({ label: financialYear.label, value: financialYear.value })
    }


    const showStatus = (rowData) => {
        return (
            rowData.status === "Open" ?
                <p className="bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center w-16"> <div className='w-2 h-2 bg-[#1B55AF] rounded-full'></div>{rowData.status}</p>
                : rowData.status === "Assigned" ?
                    <p className="bg-[#E5F3EC] text-[#4FB155] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center w-24"> <div className='w-2 h-2 bg-[#4FB155] rounded-full'></div>{rowData.status}</p>
                    :
                    rowData.status === "Draft" &&
                    <p className="bg-[#FFF2E5] text-[#FF7F01] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center w-16"> <div className='w-2 h-2 bg-[#FF7F01] rounded-full'></div>{rowData.status}</p>

        )
    }


    const showActions = (row) => {
        return (
            <div className="flex gap-8 items-center">
                <Link onClick={(e) => row.status != "Open" ? handleViewEditClick(e, row, "V") : ""} className={row.status != "Open" ? "" : "opacity-40"} ><ViewButton /></Link>
                {
                    enableAssignTarget ?
                        <Link onClick={(e) => row.status !== "Assigned" ? handleViewEditClick(e, row, "E") : ""} className={row.status !== "Assigned" ? "" : "opacity-40"} ><AddButton /></Link>
                        :
                        <Link className={!enableAssignTarget ? "opacity-40" : ""} ><AddButton /></Link>

                }
            </div>
        )
    }

    const TargetActions = (row) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <input
                    placeholder='Type target'
                    type="number" min="1"
                    disabled={isDisableMode}
                    value={row.targetCount}
                    className="w-3/4 text-[14px] rounded-lg border border-[#D0D5DD] py-2 px-3 bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF] "
                    onChange={(e) => { updateTargetValue(row, e.target.value) }}
                />
                <div className='w-14'>
                    {row.type}
                </div>
            </div>
        )
    }

    const assignSelected = (row) => {
        return (
            <div>
                {
                    row.status === "Draft" ?
                        <input type="checkbox" onChange={(e) => checkedData(e, row, "")} />
                        : null

                }
            </div>
        )
    }


    return (
        isAuthenticated ? (
            <div className="pt-16">
                <TopNavBar />
                <LeftSideBar />

                <div className="dashboard-main-wrapper planning-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5 ">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Goals Setting</div>
                        </div>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/hr/planning" >Initiate Planning</Link></li>
                                    <li><Link className="active">Assign Targets</Link></li>
                                </ul>
                            </div>
                            <div className="mt-5 lg:mt-0 flex items-center gap-4">
                                <div>
                                    {
                                        showAssignButtonForMultiple ?
                                            <Link onClick={() => assignTarget_onCheckBoxSelection()} className={"bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2"}  >Assign Selected </Link>
                                            : null
                                    }
                                </div>

                                <div className="text-md min-w-fit dark:text-white">Select Year</div>

                                <Select
                                    value={financialYear}
                                    options={financialYearList}
                                    onChange={(e) => {
                                        setFinancialYear(e);
                                        onYearChange(e)
                                        reactLocalStorage.setObject('selectedYear', e);
                                    }}
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                    styles={{
                                        container: base => ({
                                            ...base,
                                            flex: 1
                                        })
                                    }}
                                />
                            </div>
                        </div>


                        {
                            employeeList.length > 0 ?
                                <div className={"block w-full pt-5 "}>
                                    <DataTable
                                        dataKey="employeeCode"
                                        value={employeeList}
                                        paginator
                                        rows={10} rowsPerPageOptions={[10, 20, 50]}
                                    >
                                        <Column style={{ width: "3%" }} body={assignSelected} />
                                        <Column field="employeeCode" header="Employee ID" />
                                        <Column field="employeeName" header="Name" filter />
                                        <Column field="employeeRole" header="Role" />
                                        <Column field="status" header="Status" body={showStatus} />
                                        <Column header="Actions" body={showActions} />
                                    </DataTable>
                                </div>
                                :
                                <NoDataFound />
                        }

                    </div>
                </div>

                <div id="selector" className={isAssignViewTargetPopup === 1 ? 'overlay' : ''}></div>

                {
                    isAssignViewTargetPopup == 1 &&
                    <div style={{ width: "44.271vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="editPopupCont" className="sidenav">
                        <div className="popup_header relative">
                            <img src={popupHeader} alt="" />
                            <div className="absolute right-0 left-0 top-0 w-full text-white">
                                <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                    <div className="text-md">Employee Details</div>
                                    <div><Link onClick={() => isViewAssignTargetPopup(0)} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                </div>
                                <div className="flex justify-between px-6 m mt-6 lg:mt-8 2xl:mt-5 xl:mt-3">
                                    <div>
                                        <div className="text-sm">Employee ID: {employeeCode}</div>
                                        <div className="text-sm">Role: {employeeRole}</div>
                                        <div className="text-[24px]">{employeeName}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-6 height-custtom">
                            <div>
                                <div className="text-sm mb-1  ">Selected Year</div>
                                <input
                                    disabled={true}
                                    value={financialYear.label}
                                    className="text-[16px] rounded-lg  disabled bg-[#f9f9f9] flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                />
                            </div>
                            <div className="text-semibold text-[18px] mt-5">{isDisableMode ? "Assigned Targets" : "Please Assign Target"}</div>

                            <div className='mt-4 grid grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 gap-0 '>
                                <DataTable
                                    dataKey="roleId"
                                    value={parameterWithWeightage} emptyMessage=" " >
                                    <Column field="name" header="Parameter" />
                                    <Column header="Target" body={TargetActions} style={{ width: '35%' }} />
                                </DataTable>
                            </div>
                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">
                                {
                                    isDisableMode ?
                                        <div className="flex justify-end gap-3">
                                            <div><Link onClick={() => isViewAssignTargetPopup(0)} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Close</Link></div>
                                        </div>
                                        :
                                        <div className="flex justify-end gap-2">
                                            <div><Link onClick={() => isViewAssignTargetPopup(0)} className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Cancel</Link></div>
                                            <div><Link onClick={() => saveTarget()} className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Save</Link></div>
                                            <div><Link onClick={() => assignTarget()} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Assign</Link></div>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                }
            </div >
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}