const DataTableCustomStyles = {
    rows: {
        style: {
            minHeight: '50px', // override the row height
        },
    },
    headCells: {
        style: {
            background: "#F9FAFB",
            color: "#344054",
            fontSize: "15px",
        },
    },
    cells: {
        style: {
            fontSize: "13px",
            borderColor:"#CCC"
        },
    },
    // pagination: {
    //     style: {
    //         background: "transparent",
    //         color: "#ffffff",
    //     }
    // }
};

export { DataTableCustomStyles };