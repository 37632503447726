
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import React, { useEffect, useState } from 'react';

const LineChart = (props) => {


    const [title, setTitle] = useState(props.title);
    const [labelArray, setLabelArray] = useState(props.title);

    const option = {
        title: {
            text: title
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Actual', 'Target']
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Quater 1', 'Quater 2', 'Quater 3', 'Quater 4']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: 'Actual',
                type: 'line',
                data: props.data.planActualForTeamChart
            },
            {
                name: 'Target',
                type: 'line',
                stack: 'Total',
                data: props.data.teamTrendsTarget
            }
        ]
    };

    return (
        <div>
            <ReactEcharts
                option={option}
            />
        </div>
    );

}
export default LineChart;