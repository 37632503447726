import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Select from 'react-select';
import TopNav from '../../../../components/common/employee/topnav.component';
import LeftNav from '../../../../components/common/employee/leftnav.component';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ViewButton from '../../../../components/common/buttons/ViewButton';
import EditButton from '../../../../components/common/buttons/EditButton';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import EmpLogin from "../../../../setup/auth/emp.component";
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';
import { FinancialYear } from "../../../../models";
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { ConvertResponseForSelect } from '../../../../components/helper/commonfunctions';

export default function ManageChangeRequest() {

    const [sampleData, setSampleData] = useState([]);
    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    const [financialYear, setFinancialYear] = useState("");
    const [financialYearList, setFinancialYearList] = useState([]);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //static data
    var demoData = [
        { "id": "1000", "code": "f230fh0g3", "name": "Bamboo Watch", "description": "Jan 4, 2022", "image": "bamboo-watch.jpg", "price": 65, "category": "Manager", "quantity": "Planning Period", "inventoryStatus": "INSTOCK", "rating": 5 },
        { "id": "1001", "code": "nvklal433", "name": "Black Watch", "description": "Jan 4, 2022", "image": "black-watch.jpg", "price": 72, "category": "Manager", "quantity": "Overide Actuals", "inventoryStatus": "INSTOCK", "rating": 4 },
        { "id": "1002", "code": "zz21cz3c1", "name": "Blue Band", "description": "Jan 4, 2022", "image": "blue-band.jpg", "price": 79, "category": "Sales Manager", "quantity": "Overide Actuals", "inventoryStatus": "LOWSTOCK", "rating": 3 },
        { "id": "1003", "code": "244wgerg2", "name": "Blue T-Shirt", "description": "Jan 4, 2022", "image": "blue-t-shirt.jpg", "price": 29, "category": "Region Manager", "quantity": "Overide Actuals", "inventoryStatus": "INSTOCK", "rating": 5 },
        { "id": "1004", "code": "h456wer53", "name": "Bracelet", "description": "Jan 4, 2022", "image": "bracelet.jpg", "price": 15, "category": "Manager", "quantity": "Overide Actuals", "inventoryStatus": "INSTOCK", "rating": 4 },
        { "id": "1005", "code": "av2231fwg", "name": "Brown Purse", "description": "Jan 4, 2022", "image": "brown-purse.jpg", "price": 120, "category": "Manager", "quantity": "Overide Actuals", "inventoryStatus": "OUTOFSTOCK", "rating": 4 },
        { "id": "1006", "code": "bib36pfvm", "name": "Chakra Bracelet", "description": "Jan 4, 2022", "image": "chakra-bracelet.jpg", "price": 32, "category": "Manager", "quantity": "Planning Period", "inventoryStatus": "LOWSTOCK", "rating": 3 },
        { "id": "1007", "code": "mbvjkgip5", "name": "Galaxy Earrings", "description": "Jan 4, 2022", "image": "galaxy-earrings.jpg", "price": 34, "category": "Manager", "quantity": "Planning Period", "inventoryStatus": "INSTOCK", "rating": 5 },
        { "id": "1008", "code": "vbb124btr", "name": "Game Controller", "description": "Jan 4, 2022", "image": "game-controller.jpg", "price": 99, "category": "Brand Manager", "quantity": "Planning Period", "inventoryStatus": "LOWSTOCK", "rating": 4 },
        { "id": "1009", "code": "cm230f032", "name": "Gaming Set", "description": "Jan 4, 2022", "image": "gaming-set.jpg", "price": 299, "category": "Electronics", "quantity": "Planning Period", "inventoryStatus": "INSTOCK", "rating": 3 },
        { "id": "1000", "code": "f230fh0g3", "name": "Bamboo Watch", "description": "Jan 4, 2022", "image": "bamboo-watch.jpg", "price": 65, "category": "Manager", "quantity": "Planning Period", "inventoryStatus": "INSTOCK", "rating": 5 },
        { "id": "1001", "code": "nvklal433", "name": "Black Watch", "description": "Jan 4, 2022", "image": "black-watch.jpg", "price": 72, "category": "Manager", "quantity": "Overide Actuals", "inventoryStatus": "INSTOCK", "rating": 4 },
        { "id": "1002", "code": "zz21cz3c1", "name": "Blue Band", "description": "Jan 4, 2022", "image": "blue-band.jpg", "price": 79, "category": "Sales Manager", "quantity": "Overide Actuals", "inventoryStatus": "LOWSTOCK", "rating": 3 },
        { "id": "1003", "code": "244wgerg2", "name": "Blue T-Shirt", "description": "Jan 4, 2022", "image": "blue-t-shirt.jpg", "price": 29, "category": "Region Manager", "quantity": "Overide Actuals", "inventoryStatus": "INSTOCK", "rating": 5 },
        { "id": "1004", "code": "h456wer53", "name": "Bracelet", "description": "Jan 4, 2022", "image": "bracelet.jpg", "price": 15, "category": "Manager", "quantity": "Overide Actuals", "inventoryStatus": "INSTOCK", "rating": 4 },
        { "id": "1005", "code": "av2231fwg", "name": "Brown Purse", "description": "Jan 4, 2022", "image": "brown-purse.jpg", "price": 120, "category": "Manager", "quantity": "Overide Actuals", "inventoryStatus": "OUTOFSTOCK", "rating": 4 },
        { "id": "1006", "code": "bib36pfvm", "name": "Chakra Bracelet", "description": "Jan 4, 2022", "image": "chakra-bracelet.jpg", "price": 32, "category": "Manager", "quantity": "Planning Period", "inventoryStatus": "LOWSTOCK", "rating": 3 },
        { "id": "1007", "code": "mbvjkgip5", "name": "Galaxy Earrings", "description": "Jan 4, 2022", "image": "galaxy-earrings.jpg", "price": 34, "category": "Manager", "quantity": "Planning Period", "inventoryStatus": "INSTOCK", "rating": 5 },
        { "id": "1008", "code": "vbb124btr", "name": "Game Controller", "description": "Jan 4, 2022", "image": "game-controller.jpg", "price": 99, "category": "Brand Manager", "quantity": "Planning Period", "inventoryStatus": "LOWSTOCK", "rating": 4 },
        { "id": "1009", "code": "cm230f032", "name": "Gaming Set", "description": "Jan 4, 2022", "image": "gaming-set.jpg", "price": 299, "category": "Electronics", "quantity": "Planning Period", "inventoryStatus": "INSTOCK", "rating": 3 },
        { "id": "1000", "code": "f230fh0g3", "name": "Bamboo Watch", "description": "Jan 4, 2022", "image": "bamboo-watch.jpg", "price": 65, "category": "Manager", "quantity": "Planning Period", "inventoryStatus": "INSTOCK", "rating": 5 },
        { "id": "1001", "code": "nvklal433", "name": "Black Watch", "description": "Jan 4, 2022", "image": "black-watch.jpg", "price": 72, "category": "Manager", "quantity": "Overide Actuals", "inventoryStatus": "INSTOCK", "rating": 4 },
        { "id": "1002", "code": "zz21cz3c1", "name": "Blue Band", "description": "Jan 4, 2022", "image": "blue-band.jpg", "price": 79, "category": "Sales Manager", "quantity": "Overide Actuals", "inventoryStatus": "LOWSTOCK", "rating": 3 },
        { "id": "1003", "code": "244wgerg2", "name": "Blue T-Shirt", "description": "Jan 4, 2022", "image": "blue-t-shirt.jpg", "price": 29, "category": "Region Manager", "quantity": "Overide Actuals", "inventoryStatus": "INSTOCK", "rating": 5 },
        { "id": "1004", "code": "h456wer53", "name": "Bracelet", "description": "Jan 4, 2022", "image": "bracelet.jpg", "price": 15, "category": "Manager", "quantity": "Overide Actuals", "inventoryStatus": "INSTOCK", "rating": 4 },
        { "id": "1005", "code": "av2231fwg", "name": "Brown Purse", "description": "Jan 4, 2022", "image": "brown-purse.jpg", "price": 120, "category": "Manager", "quantity": "Overide Actuals", "inventoryStatus": "OUTOFSTOCK", "rating": 4 },
        { "id": "1006", "code": "bib36pfvm", "name": "Chakra Bracelet", "description": "Jan 4, 2022", "image": "chakra-bracelet.jpg", "price": 32, "category": "Manager", "quantity": "Planning Period", "inventoryStatus": "LOWSTOCK", "rating": 3 },
        { "id": "1007", "code": "mbvjkgip5", "name": "Galaxy Earrings", "description": "Jan 4, 2022", "image": "galaxy-earrings.jpg", "price": 34, "category": "Manager", "quantity": "Planning Period", "inventoryStatus": "INSTOCK", "rating": 5 },
        { "id": "1008", "code": "vbb124btr", "name": "Game Controller", "description": "Jan 4, 2022", "image": "game-controller.jpg", "price": 99, "category": "Brand Manager", "quantity": "Planning Period", "inventoryStatus": "LOWSTOCK", "rating": 4 },
        { "id": "1009", "code": "cm230f032", "name": "Gaming Set", "description": "Jan 4, 2022", "image": "gaming-set.jpg", "price": 299, "category": "Electronics", "quantity": "Planning Period", "inventoryStatus": "INSTOCK", "rating": 3 }
    ]

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="flex justify-center gap-8 ">
                    <Link ><ViewButton /></Link>
                    <Link ><EditButton /></Link>
                </div>
            </React.Fragment>
        );
    }

    useEffect(() => {
        onLoad()
        setSampleData(demoData);
        bindList()
    }, []);

    //get financial year
    const bindList = async () => {
        let selectedYear = reactLocalStorage.getObject('selectedYearEmployee');
        if (Object.keys(selectedYear).length !== 0) {
            setFinancialYear(selectedYear)
        } else {
            var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            setFinancialYear({ label: responses[0].name, value: responses[0].id })
        }

        var responses = await DataStore.query(FinancialYear, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYearList(myArray);
    }

    //onclick financial year
    const onFinancialSelectChange = async (e) => {
        setFinancialYear({ label: e.label, value: e.value })
        reactLocalStorage.setObject('selectedYearEmployee', e);
    }

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNav />
            <LeftNav />

            <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="sm:flex items-center space-x-8">
                        <div className="text-2xl font-semibold dark:text-white">Goals Setting
                        </div>
                        <div className="tabs_one mt-5 sm:mt-0">
                            <ul className="flex">
                                <li><Link className='active' >My Team</Link></li>
                                <li><Link to="/employee/planning/self/manageplan" >Myself</Link></li>
                            </ul>
                        </div>
                    </div>

                    <>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/employee/planning/team/managetarget" >Manage Targets</Link></li>
                                    <li><Link to="/employee/planning/team/assigntarget" >Assign Target</Link></li>
                                </ul>
                            </div>
                            <div className="mt-5 lg:mt-0 flex items-center gap-4">
                                <div className="text-md min-w-fit dark:text-white">Select Year</div>
                                <div className="w-full min-w-[200px]">
                                    <Select
                                        options={financialYearList}
                                        value={financialYear}
                                        onChange={e => {
                                            onFinancialSelectChange(e)
                                            reactLocalStorage.setObject('selectedYear', e);
                                        }}
                                        className="my-react-select-container"
                                        classNamePrefix="my-react-select"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8">

                            <DataTable paginator value={sampleData} responsiveLayout="scroll" style={{ width: '92vw' }}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}

                            >
                                <Column field="code" header="Request ID" sortable></Column>
                                <Column field="name" header="Requester Name" sortable></Column>
                                <Column field="category" header="Role" sortable></Column>
                                <Column field="quantity" header="Request Type" sortable></Column>
                                <Column field="description" header="Requested Date" sortable></Column>
                                <Column alignHeader={'center'} header="Action" body={actionBodyTemplate} ></Column>
                            </DataTable>

                        </div>



                    </>


                </div>
            </div>

        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}