import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import { ProfitCenter, ProductGroup } from '../../../../../models'
import HRLogin from '../../../../../setup/auth/hr.component';
import Select from "react-select"
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import capitalizeFirst from "../../../../../components/common/capitalizeFirst";
import { ValidateHRRole } from "../../../../../components/helper/validateRole";
import { ConvertResponseForSelectWithRefCode } from '../../../../../components/helper/commonfunctions'
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';

export const CreateProfitCenter = () => {
    let navigateTo = useNavigate();

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);

    const { profitcenterid } = useParams();
    const [isEditMode, setEditMode] = useState(false);

    //State
    const [profitCenterName, setProfitCenterName] = useState("");
    const [companyId, setProfitCenterId] = useState("");

    const [refId, setRefId] = useState("");

    const [productGroupList, setProductGroupList] = useState([]);
    const [productGroupId, setProductGroupId] = useState("");
    const [productGroup, setProductGroup] = useState("");


    //Create Record
    const saveSBU = async () => {
        if (profitCenterName === "") {
            toast.error("Please enter Profit Center.");
            return;
        }

        if (productGroupId === "") {
            toast.error("Please select Product Group.");
            return;
        }

        setPleaseWaitButton(true);
        if (isEditMode) {
            // const original = await DataStore.query(ProfitCenter, companyId);
            const original = await graphQLFindRecordById('getProfitCenter', companyId);

            // await DataStore.save(
            //     ProfitCenter.copyOf(original, (updated) => {
            //         updated.name = capitalizeFirst(profitCenterName);
            //         updated.product_group_id = productGroupId;
            //         updated.REF_CODE = capitalizeFirst(refId);
            //     })
            // );

            await API.graphql({
                query: mutations.updateProfitCenter,
                variables: {
                    input: {
                        id: original.id,
                        name: capitalizeFirst(profitCenterName),
                        product_group_id: productGroupId,
                        REF_CODE: capitalizeFirst(refId),
                        _version: original._version,
                    }
                }
            });
            toast.success('Updated');
        } else {
            // await DataStore.save(new ProfitCenter({ 
            //     name: capitalizeFirst(profitCenterName), 
            //     product_group_id: productGroupId, 
            //     REF_CODE: capitalizeFirst(refId) }));


            await API.graphql(
                graphqlOperation(mutations.createProfitCenter, {
                    input: {
                        name: capitalizeFirst(profitCenterName),
                        product_group_id: productGroupId,
                        REF_CODE: capitalizeFirst(refId)
                    }
                })
            );
            toast.success('Added');
        }

        setPleaseWaitButton(false);
        navigateTo("/hr/setting/profitcenter");
    };


    async function BindList() {
        // var ProductGroupList = await DataStore.query(ProductGroup, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        var ProductGroupList = await graphQLGetAllData('listProductGroups');
        ProductGroupList = ProductGroupList.sort((a,b)=>a.name.localeCompare(b.name));

        var returnArray = []; returnArray = ConvertResponseForSelectWithRefCode(ProductGroupList);
        setProductGroupList(returnArray);
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }

            await BindList();

            //Edit existing record
            if (profitcenterid != null) {
                setProfitCenterId(profitcenterid);

                // const original = await DataStore.query(ProfitCenter, profitcenterid);
                // console.log(original)

                const original = await graphQLFindRecordById('getProfitCenter', profitcenterid);

                if (original != null) {

                    if (original.product_group_id !== null) {
                        // const Group_original = await DataStore.query(ProductGroup, original.product_group_id);

                        const Group_original = await graphQLFindRecordById('getProductGroup', original.product_group_id);

                        if (Group_original != null) {
                            setProductGroup({ label: Group_original.name + " (" + Group_original.REF_CODE + ")", value: Group_original.id });
                            setProductGroupId(Group_original.id)
                        }
                    }

                    setProfitCenterName(original.name);
                    setRefId(original.REF_CODE)
                    setEditMode(true);
                }
                else {
                    toast.error('Invalid record.');
                    navigateTo("/hr/setting/profitcenter");
                }

            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();

    }, []);

    return (
        isAuthenticated ? (
            <div>
                <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full ">
                    <TopNavBar />
                    <LeftSideBar />

                    <div className="pl-16">
                        <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                            <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                                <div className="p-3 py-5 bg-white rounded-md shadow dark:bg-[#252423]">
                                    <div className="flex justify-center mt-3 mb-2">
                                        <h2 className="page-title">
                                            {isEditMode ? "Edit" : "Create"} Profit Center
                                        </h2>
                                    </div>
                                    <div x-data="{tab: 1}"></div>
                                    <div className={"block w-full"}>
                                        <div className="px-4" x-show="tab === 1">
                                            <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Profit Center
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *{" "}
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(profitCenterName)}
                                                        onChange={(e) => setProfitCenterName(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter ProfitCenter"
                                                    />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Ref_Code
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >

                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(refId)}
                                                        onChange={(e) => setRefId(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Ref Code"
                                                    />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Product Group
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *{" "}
                                                        </label>
                                                    </label>
                                                    <Select placeholder='Select' options={productGroupList} value={productGroup} onChange={e => {
                                                        setProductGroup(e)
                                                        setProductGroupId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                            </div>
                                            <div className="grow flex justify-center gap-3 mb-3">
                                                <Link
                                                    to="/hr/setting/profitcenter"
                                                    className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:border-[#4A5056] dark:bg-[#4A5056] dark:text-white"
                                                >
                                                    Cancel
                                                </Link>
                                                <button
                                                    onClick={() => saveSBU()}
                                                    className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                                                >
                                                    {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <HRLogin sessionExpired={1} />
        )

    );
}
