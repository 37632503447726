import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { Auth } from 'aws-amplify';
import { toast } from "react-toastify";
import { DataTableCustomStyles } from '../../../../../components/helper/dataTable.config';
import { GTM } from '../../../../../models';
import HRLogin from '../../../../../setup/auth/hr.component';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import DeleteButton from '../../../../../components/common/buttons/DeleteButton';
import EditButton from '../../../../../components/common/buttons/EditButton';
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import { ConvertResponseForId } from '../../../../../components/helper/commonfunctions'
import ImportDimension from '../../../../../components/common/import/dimensionsimport.component';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

export default function Gtm() {
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    // GTM define
    const [gtmList, setGtmList] = useState([]);
    const [gtmFilterList, setGtmFilterList] = useState([]);
    const [isShowImportPopup, setIsShowImportPopup] = useState(false);


    let navigateTo = useNavigate();

    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/hr/settings/creategtm/' + id);
    };

    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        // const objDeleteGTM = await DataStore.query(GTM, id);
        const objDeleteGTM = await graphQLFindRecordById('getGTM', id);

        alertify.confirm('Confirmation', 'Do you want to delete this record?', async function () {
            //Delete language
            // DataStore.delete(objDeleteGTM);

            await API.graphql({
                query: mutations.deleteGTM,
                variables: {
                    input: {
                        id: objDeleteGTM.id,
                        _version: objDeleteGTM._version,
                    }
                }
            });
            BindList();
            toast.success("Deleted");
        }
            , function () { });

    };

    // //*Column Name
    const DataTableColumns = [
        {
            name: 'GTM',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Ref Code',
            selector: row => row.REF_CODE ? row.REF_CODE : '-',
            sortable: true,
        },

        {
            name: 'Name',
            cell: (row) => (
                <>
                    <div className="flex gap-8">
                        <Link onClick={(e) => handleEditClick(e, row.id)} ><EditButton /></Link>
                        <Link onClick={(e) => handleDeleteClick(e, row.id)} ><DeleteButton /></Link>
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true
        }
    ];

    //Search GTM
    const searchGtm = (searchedText) => {
        if (searchedText.length > 1) {
            let data = [...gtmFilterList]
            let filteredData = data.filter(e =>
                e.name.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.REF_CODE.toLowerCase().includes(searchedText.toLowerCase())
            )
            setGtmList(filteredData)
        } else {
            let data = [...gtmFilterList]
            setGtmList(data)
        }
    }

    //onClick Close popup
    async function onClickCloseImportPopup() {
        setIsShowImportPopup(false);
        BindList();
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    async function BindList() {

        let finalData = [];
        // var gtmResponse = await DataStore.query(GTM, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        var gtmResponse = await graphQLGetAllData('listGTMS');
        gtmResponse =  gtmResponse.sort((a,b)=>a.name.localeCompare(b.name)); 

        gtmResponse.forEach(async (item, i) => {
            let data = {
                "id": item.id,
                "name": item.name,
                "REF_CODE": item.REF_CODE,
            }

            finalData.push(data)
            if (i === gtmResponse.length - 1) {
                setGtmList(finalData)
                setGtmFilterList(finalData)
            }

        })
    }

    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    return (
        isAuthenticated ? (

            <div>
                <div className="pt-16">
                    <TopNavBar />
                    <LeftSideBar />
                    <div className="dashboard-main-wrapper pl-20 pr-4 mt-3">
                        <div className="flex flex-1 flex-wrap p-5 pb-4 px-0 top-action-wrapper">
                            <div className="md:flex xs:w-full sm:w-full md:w-auto flex-row align-center">
                                <div className="md:flex block md:col-span-2 mb-4">
                                </div>
                                <div className="md:flex block mb-4">
                                    <h2 className="page-title">GTMs</h2>
                                </div>
                            </div>

                            <div className="md:flex flex-1 flex-row xs:w-full sm:w-full md:w-auto align-center justify-end md:ml-auto gap-3 right-side">
                                <div className="relative">
                                    <input onChange={(e) => searchGtm(e.target.value)} type="text" id="required-search" className="rounded-md border border-[#D0D5DD] focus:border-transparent w-full py-2.5 pl-3 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#403E3C] dark:text-[#FFFFFF] dark:border-[#403E3C]" placeholder="Search..." />
                                </div>
                                <div className="md:flex block mb-4 gap-4">
                                    <Link to="/hr/settings" className="flex py-2 px-5 bg-[#FFF] hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-black transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] dark:border-[#4A5056] dark:bg-[#4A5056] dark:text-white">
                                        Back
                                    </Link>
                                    <Link onClick={() => {
                                        setIsShowImportPopup(true);
                                    }} className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] bg-black">
                                        Import
                                    </Link>
                                    <Link to="/hr/settings/creategtm" className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] bg-black">
                                        Create
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div>
                            <div className={"block w-full pt-5"}>
                                {
                                    <DataTable
                                        columns={DataTableColumns}
                                        data={gtmList}
                                        customStyles={DataTableCustomStyles}
                                        pagination
                                        highlightOnHover
                                        paginationPerPage={10}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    {
                        isShowImportPopup &&
                        <ImportDimension
                            closePopup={onClickCloseImportPopup}
                            type={"GTM"}
                        />
                    }
                </div>
            </div>

        ) : (
            <HRLogin sessionExpired={1} />
        )
    );

}
