import React, { useState, useEffect } from 'react';
import LeftSideBar from '../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../components/common/hr/topnav.component';
import { Link, useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Auth } from 'aws-amplify';
import { ValidateHRRole } from '../../../components/helper/validateRole';
import HRLogin from '../../../setup/auth/hr.component';
import 'alertifyjs/build/css/alertify.css';
import { graphQLGetAllData } from '../../../../src/components/helper/graphQLFunctions'
import { ProgressSpinner } from 'primereact/progressspinner';
import { PARAMETER_IDs } from '../../../components/helper/enum';
import { InputText } from 'primereact/inputtext';

export default function View_NWC_Days_Actuals() {

    let { financial_year_id } = useParams();

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [actualList, setActualList] = useState([])

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    const [nonFilteredData, setNonFiltededData] = useState([]);

    const findEmployee = (searchedText) => {

        let data = [...nonFilteredData];

        if (searchedText.length > 0) {
            let filterData = data.filter(e =>
                e.name.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.empId.includes(searchedText)
            )
            setActualList(filterData);
        } else {
            setActualList(nonFilteredData);
        }
    }

    async function BindList() {

        var targetDetailResponses = await graphQLGetAllData('listTargetDetails', [{ parameter_id: { eq: PARAMETER_IDs['NWC DAYS'] } }]);

        let targetResponses = await graphQLGetAllData('listTargets', [{ financial_year_id: { eq: financial_year_id.toString() } }]);

        let employeeResponses = await graphQLGetAllData('listEmployees');

        let newResponse = [];
        if (targetResponses.length > 0) {

            for (let i = 0; i < targetResponses.length; i++) {

                let empTargetDetails = targetDetailResponses.find(c => c.target_id === targetResponses[i].id);
                console.log('empTargetDetails',empTargetDetails);

                let employeeDetails = employeeResponses.find(c => c.id === targetResponses[i].employee_id)


                if (empTargetDetails) {
                    let obj = {};
                    obj.name = employeeDetails.firstName + " " + employeeDetails.lastName;
                    obj.email = employeeDetails.email;
                    obj.empId = employeeDetails.employeeId;

                    obj.m1_actual_value = isNaN(empTargetDetails.m1_actual_value) || empTargetDetails.m1_actual_value === "" || empTargetDetails.m1_actual_value === null ? "" : parseFloat(empTargetDetails.m1_actual_value).toFixed(2);

                    
                    obj.m2_actual_value = isNaN(empTargetDetails.m2_actual_value) || empTargetDetails.m2_actual_value === "" || empTargetDetails.m2_actual_value === null ? "" : parseFloat(empTargetDetails.m2_actual_value).toFixed(2);

                    obj.m3_actual_value = isNaN(empTargetDetails.m3_actual_value) || empTargetDetails.m3_actual_value === "" || empTargetDetails.m3_actual_value === null? "" : parseFloat(empTargetDetails.m3_actual_value).toFixed(2);

                    obj.m4_actual_value = isNaN(empTargetDetails.m4_actual_value) || empTargetDetails.m4_actual_value === "" || empTargetDetails.m3_actual_value === null? "" : parseFloat(empTargetDetails.m4_actual_value).toFixed(2);
                    console.log('isNan return', isNaN(empTargetDetails.m1_actual_value));

                    obj.m5_actual_value = isNaN(empTargetDetails.m5_actual_value) || empTargetDetails.m5_actual_value === "" || empTargetDetails.m3_actual_value === null? "" : parseFloat(empTargetDetails.m5_actual_value).toFixed(2);

                    obj.m6_actual_value = isNaN(empTargetDetails.m6_actual_value) || empTargetDetails.m6_actual_value === "" || empTargetDetails.m3_actual_value === null? "" : parseFloat(empTargetDetails.m6_actual_value).toFixed(2);

                    obj.m7_actual_value = isNaN(empTargetDetails.m7_actual_value) || empTargetDetails.m7_actual_value === "" || empTargetDetails.m3_actual_value === null? "" : parseFloat(empTargetDetails.m7_actual_value).toFixed(2);

                    obj.m8_actual_value = isNaN(empTargetDetails.m8_actual_value) || empTargetDetails.m8_actual_value === "" || empTargetDetails.m3_actual_value === null? "" : parseFloat(empTargetDetails.m8_actual_value).toFixed(2);

                    obj.m9_actual_value = isNaN(empTargetDetails.m9_actual_value) || empTargetDetails.m9_actual_value === "" || empTargetDetails.m3_actual_value === null? "" : parseFloat(empTargetDetails.m9_actual_value).toFixed(2);

                    obj.m10_actual_value = isNaN(empTargetDetails.m10_actual_value) || empTargetDetails.m10_actual_value === "" || empTargetDetails.m3_actual_value === null? "" : parseFloat(empTargetDetails.m10_actual_value).toFixed(2);

                    obj.m11_actual_value = isNaN(empTargetDetails.m11_actual_value) || empTargetDetails.m11_actual_value === "" || empTargetDetails.m3_actual_value === null? "" : parseFloat(empTargetDetails.m11_actual_value).toFixed(2);
                    
                    obj.m12_actual_value = isNaN(empTargetDetails.m12_actual_value) || empTargetDetails.m12_actual_value === "" || empTargetDetails.m3_actual_value === null? "" : parseFloat(empTargetDetails.m12_actual_value).toFixed(2);

                    newResponse.push(obj);
                }
            }
        }
        newResponse = newResponse.sort((a, b) => a.name.localeCompare(b.name));
        setActualList(newResponse);
        setNonFiltededData(newResponse);
    }
    const getFullName = (rowdata) => {
        return rowdata.firstName + " " + rowdata.lastName;
    }


    useEffect(() => {
        onLoad()
        BindList()
    }, []);



    return (
        isAuthenticated ? (
            <div className="pt-16 ">
                <TopNavBar />
                <LeftSideBar />

                <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className=' flex space-x-4 justify-between mt-4'>
                            <div className="py-2 sm:flex items-center space-x-4 target-main-wrapper">
                                <Link to="/hr/evaluation/nwcdaysactuals" className="bg-[#fff] text-[#344054] text-[14px] dark:text-white py-1.5 px-3 rounded-md border border-[#D0D5DD] dark:bg-[#344054]"><i className="icon arrow_left_double mr-6 my-auto "></i>Back</Link>
                                <div className="text-3xl font-semibold dark:text-white ml-2">NWC Days Actuals </div>
                            </div>
                            <div className=" px-auto">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search" onKeyUp={(e) => findEmployee(e.target.value)} className='dark:text-[white]' />
                                </span>
                            </div>
                        </div>

                        <div className=" rounded-lg mt-8">
                            {actualList.length > 0 ?
                                <DataTable
                                    paginator
                                    value={actualList}
                                    responsiveLayout="scroll"
                                    scrollable 
                                    // style={{ width: '93vw' }}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                >
                                    <Column field="empId" header="Employee Id" sortable frozen style={{ minWidth:"10rem"}}></Column>
                                    <Column field="name" header="Name" sortable frozen style={{ minWidth:"15rem"}}></Column>
                                    <Column field="email" header="Email" sortable frozen  style={{ minWidth:"20rem"}}></Column>
                                    <Column field="m1_actual_value" header="Month 1" sortable  style={{ minWidth:"10rem"}}></Column>
                                    <Column field="m2_actual_value" header="Month 2" sortable style={{ minWidth:"10rem"}} ></Column >
                                    <Column field="m3_actual_value" header="Month 3" sortable style={{ minWidth:"10rem"}}></Column>
                                    <Column field="m4_actual_value" header="Month 4" sortable style={{ minWidth:"10rem"}}></Column>
                                    <Column field="m5_actual_value" header="Month 5" sortable style={{ minWidth:"10rem"}}></Column>
                                    <Column field="m6_actual_value" header="Month 6" sortable style={{ minWidth:"10rem"}}></Column>
                                    <Column field="m7_actual_value" header="Month 7" sortable style={{ minWidth:"10rem"}}></Column>
                                    <Column field="m8_actual_value" header="Month 8" sortable style={{ minWidth:"10rem"}}></Column>
                                    <Column field="m9_actual_value" header="Month 9" sortable style={{ minWidth:"10rem"}}></Column>
                                    <Column field="m10_actual_value" header="Month 10" sortable style={{ minWidth:"10rem"}}></Column>
                                    <Column field="m11_actual_value" header="Month 11" sortable style={{ minWidth:"10rem"}}></Column>
                                    <Column field="m12_actual_value" header="Month 12" sortable style={{ minWidth:"10rem"}}></Column>


                                </DataTable> :
                                <div className='text-center my-auto py-8' ><ProgressSpinner /></div>
                            }
                        </div>

                    </div>
                </div>
            </div >
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}
