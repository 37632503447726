import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import moment from "moment";
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { reactLocalStorage } from 'reactjs-localstorage';
// import BoxPlanning from '../../../../assets/images/dashboard_box_planning.png';
import LeftSideBar from '../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../components/common/hr/topnav.component';
import { ConvertResponseForSelect, ObjectToArray } from '../../../../components/helper/commonfunctions';
import { ValidateHRRole } from '../../../../components/helper/validateRole';
import { Employee, FinancialYear, Roles, SBU, TargetDetails, Targets } from "../../../../models";
import HRLogin from '../../../../setup/auth/hr.component';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../../graphql/queries'
import * as mutations from '../../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../src/components/helper/graphQLFunctions'
import { InputText } from 'primereact/inputtext';

export default function ReportsGoalSettingDetailed() {

    const [financialYear, setFinancialYear] = useState("");
    const [financialYearList, setFinancialYearList] = useState([]);
    const [showData, setShowData] = useState(false);
    const [employeeTargetsList, SetEmployeeTargetList] = useState([]);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [allRoles, setAllRoles] = useState([]);
    const [allEmployees, setAllEmployees] = useState([]);
    const [allTargetDetails, setAllTargetDetails] = useState([]);
    const [allSBUs, setAllSBUs] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

   //Search field
    const filteredInfo = employeeTargetsList.filter((record) =>
        record.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );


    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    async function onLoad() {
        try {
            if (!ValidateHRRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //onclick financial year
    const onFinancialSelectChange = async (e) => {
        reactLocalStorage.setObject('selectedYearEmployee', e);
        setFinancialYear({ label: e.label, value: e.value })
        bindDetailedReport(e.value)
    }

    //get financial year
    const bindList = async () => {
        //set financial year
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        if (Object.keys(selectedYear).length !== 0) {
            setFinancialYear(selectedYear)
            bindDetailedReport(selectedYear.value)
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);
            setFinancialYear({ label: responses[0].name, value: responses[0].id })
            bindDetailedReport(responses[0].id)
        }

        // var responses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });
        var responses = await graphQLGetAllData('listFinancialYears');
        responses = responses.sort((a, b) => a.name.localeCompare(b.name));
        var myArray = []; myArray = ConvertResponseForSelect(responses); setFinancialYearList(myArray);
    }



    const getMasterData = async () => {
        setDataLoaded(false);

        var employeeResponses = await graphQLGetAllData('listEmployees');
        setAllEmployees(employeeResponses);

        var rolesResponses = await graphQLGetAllData('listRoles');
        setAllRoles(rolesResponses);

        var SBUResponses = await graphQLGetAllData('listSBUS');
        setAllSBUs(SBUResponses);

        var targetDetailResponses = await graphQLGetAllData('listTargetDetails');
        setAllTargetDetails(targetDetailResponses);

        setDataLoaded(true);
    };


    useEffect(() => {
        if (isDataLoaded === true) {
            bindList()
            onLoad()
        }
    }, [allRoles, allEmployees, allSBUs, allTargetDetails]);

    useEffect(() => {
        getMasterData()
    }, []);

    const bindDetailedReport = async (financialYearId) => {
        try {
            setShowData(false)

            // var employeeTargetsList = await DataStore.query(Targets, (c) => c.financial_year_id.eq(financialYearId));
            var employeeTargetsList = await graphQLGetAllData('listTargets', [{ financial_year_id: { eq: financialYearId } }]);


            if (employeeTargetsList.length) {
                // var newResponse = [];
                // Object.keys(employeeTargetsList).map(async (currentItem, i) => {
                //     ++counter;
                //     let loopItem = employeeTargetsList[currentItem];
                //     let data = ObjectToArray(loopItem);

                //     // var employeeResponse = await DataStore.query(Employee, data["employee_id"])
                //     var employeeResponse = await graphQLFindRecordById('getEmployee', data["employee_id"]);


                //     if (employeeResponse) {
                //         data["name"] = employeeResponse.firstName + " " + employeeResponse.lastName
                //         data["employeeId"] = employeeResponse.employeeId
                //         //Role List
                //         // var roleResponse = await DataStore.query(Roles, data["role_id"])
                //         var roleResponse = await graphQLFindRecordById('getRoles', data["role_id"]);

                //         data['role'] = roleResponse.name

                //         //Parent Employee List
                //         // var parentResponse = await DataStore.query(Employee, (c) => c.id.eq(data["report_manager_employee_id"]))
                //         var parentResponse = await graphQLFindRecordById('getEmployee', data["report_manager_employee_id"]);

                //         if (parentResponse.length > 0) {
                //             if (parentResponse.firstName !== null) {
                //                 data["reportingManager"] = parentResponse.firstName + " " + parentResponse.lastName
                //             } else {
                //                 data["reportingManager"] = "-"
                //             }
                //         }

                //         //SBU
                //         // var sbuRes = await DataStore.query(SBU, employeeResponse.param_sbu_id)
                //         var sbuRes = await graphQLFindRecordById('getSBU', employeeResponse.param_sbu_id);

                //         data['sbu'] = sbuRes ? sbuRes.name : "-"

                //         //Vertical
                //         data['vertical'] = "Distribution"

                //         data['isAddedByHr'] = data["isAddedByHRAdmin"] ? "Yes" : "-"

                //         //TargetDetails Table for status
                //         // var targetRes = await DataStore.query(TargetDetails, (c) => c.target_id.eq(data["id"]))
                //         var targetRes = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: data["id"] } }]);


                //         let checkStatus = targetRes.every((item) => item.is_approve)
                //         //let checkStatus = targetRes.every((item) => item.isMarkAsComplete_AssignTarget && item.is_approve)
                //         data["planningStatus"] = checkStatus ? "Complete" : "Open"
                //     }
                //     newResponse.push(data);

                //     console.log('employeeTargetsList',employeeTargetsList.length);
                //     if (i === employeeTargetsList.length - 1) {
                //         let sortArray = newResponse.sort((a, b) => (a.name > b.name ? 1 : -1))
                //         let filterDuplicates = sortArray.filter((a) => (a.employee_id !== a.report_manager_employee_id))
                //         SetEmployeeTargetList(filterDuplicates);
                //         setShowData(true)
                //     }
                // })

                var newResponse = [];

                for (let i = 0; i < Object.keys(employeeTargetsList).length; i++) {
                    let currentItem = Object.keys(employeeTargetsList)[i];
                    let loopItem = employeeTargetsList[currentItem];
                    let data = ObjectToArray(loopItem);

                    // var employeeResponse = await DataStore.query(Employee, data["employee_id"])
                    // var employeeResponse = await graphQLFindRecordById('getEmployee', data["employee_id"]);
                    var employeeResponse = allEmployees.find(c => c.id === data["employee_id"])

                    if (employeeResponse) {
                        data["name"] = employeeResponse.firstName + " " + employeeResponse.lastName;
                        data["employeeId"] = employeeResponse.employeeId;

                        //Role List
                        // var roleResponse = await DataStore.query(Roles, data["role_id"])
                        // var roleResponse = await graphQLFindRecordById('getRoles', data["role_id"]);
                        var roleResponse = allRoles.find(c => c.id === data["role_id"])
                        data['role'] = roleResponse.name;


                        //Parent Employee List
                        // var parentResponse = await DataStore.query(Employee, (c) => c.id.eq(data["report_manager_employee_id"]))
                        // var parentResponse = await graphQLFindRecordById('getEmployee', data["report_manager_employee_id"]);
                        var parentResponse = allEmployees.find(c => c.id === data["report_manager_employee_id"])

                        if (parentResponse) {
                            data["reportingManager"] = parentResponse.firstName + " " + parentResponse.lastName;
                        }
                        else {
                            data["reportingManager"] = "-";
                        }

                        //SBU
                        // var sbuRes = await DataStore.query(SBU, employeeResponse.param_sbu_id)
                        // var sbuRes = await graphQLFindRecordById('getSBU', employeeResponse.param_sbu_id);
                        var sbuRes = allSBUs.find(c => c.id === employeeResponse.param_sbu_id)
                        data['sbu'] = sbuRes ? sbuRes.name : "-";

                        //Vertical
                        data['vertical'] = "Distribution";
                        data['isAddedByHr'] = data["isAddedByHRAdmin"] ? "Yes" : "-";

                        //TargetDetails Table for status
                        // var targetRes = await DataStore.query(TargetDetails, (c) => c.target_id.eq(data["id"]))
                        // var targetRes = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: data["id"] } }]);
                        var targetRes = allTargetDetails.filter(c => c.target_id === data["id"])

                        let checkStatus = targetRes.every((item) => item.is_approve);
                        //let checkStatus = targetRes.every((item) => item.isMarkAsComplete_AssignTarget && item.is_approve);
                        data["planningStatus"] = checkStatus ? "Complete" : "Open";
                    }
                    newResponse.push(data);


                    if (i === Object.keys(employeeTargetsList).length - 1) {
                        let sortArray = newResponse.sort((a, b) => (a.name > b.name ? 1 : -1));
                        let filterDuplicates = sortArray.filter((a) => (a.employee_id !== a.report_manager_employee_id));
                        SetEmployeeTargetList(filterDuplicates);
                        setShowData(true);
                    }
                }

            } else {
                SetEmployeeTargetList([])
                setShowData(true)
            }
        } catch (e) {

        }
    }

    const downloadReport = async () => {
        const unit = "pt";
        const size = "A2"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);

        const title = "Goals Setting - Detailed";
        const headers = [["Emp. ID", "Name", "Role", "Vertical", "SBU", "Added by HR", "Reporting Manager", "Planning Completion Status"]];
        const data = employeeTargetsList.map(elt => [elt.employeeId, elt.name, elt.role, elt.vertical, elt.sbu, elt.isAddedByHr, elt.reportingManager, elt.planningStatus]);
        let content = { startY: 50, head: headers, body: data };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Goals Setting - Detailed")
    }

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            {
                showData &&
                < div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Reports</div>
                            <div className="tabs_one mt-5 sm:mt-0">
                                <ul className="flex">
                                    <li><Link className='active' >Goal Setting</Link></li>
                                    <li><Link to="/hr/reports/goalevaluation/summary" >Goals Evaluation</Link></li>
                                </ul>
                            </div>
                        </div>

                        <>
                            <div className="lg:flex justify-between my-6">
                                <div className="tabs_two">
                                    <ul className="flex">
                                        <li><Link to="/hr/reports/goalsetting/summary">Summary</Link></li>
                                        <li><Link className='active'>Detailed</Link></li>
                                    </ul>
                                </div>
                                <div className="mt-5 lg:mt-0 flex items-center gap-4">
                                    <div className="text-md min-w-fit dark:text-white">Select Year</div>
                                    <div className="w-full min-w-[200px]">
                                        <Select
                                            options={financialYearList}
                                            value={financialYear}
                                            onChange={e => {
                                                onFinancialSelectChange(e);
                                                reactLocalStorage.setObject('selectedYear', e);
                                            }}
                                            className="my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* code here */}

                            <div className={"block w-full pt-5"}>
                                <div className="lg:flex justify-between my-6">
                                    <div className="tabs_two">
                                        <ul className="flex font-semibold dark:text-white">
                                            Goals Setting - Detailed
                                        </ul>
                                    </div>

                                    {
                                        employeeTargetsList.length > 0 ?
                                            <div className="mt-5 lg:mt-0 flex items-center gap-4">
                                                <div>
                                                    <span className="p-input-icon-left">
                                                        <i className="pi pi-search" />
                                                        <InputText placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} type="text" id="required-search" className='dark:text-[white]' />
                                                    </span>
                                                </div>
                                                <Link onClick={() => window.print()} className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" ><i className="pi pi-print style={{'fontSize': '2px'}}"></i> Print</Link>
                                                <Link onClick={() => downloadReport()} className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-sm lg:text-md shadow-md inline-block addclassName" ><i className="pi pi-download style={{'fontSize': '2px'}}"></i> Download</Link>
                                            </div> : ""
                                    }
                                </div>

                                <DataTable
                                    paginator value={filteredInfo} responsiveLayout="scroll" style={{ width: '93vw' }}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={50} rowsPerPageOptions={[50, 100, 200]}
                                >
                                    <Column field="employeeId" header="Emp. ID" sortable ></Column>
                                    <Column field="name" header="Name" sortable></Column>
                                    <Column field="role" header="Role" sortable></Column>
                                    <Column field="vertical" header="Vertical" sortable></Column>
                                    <Column field="sbu" header="SBU" sortable></Column>
                                    <Column field="isAddedByHr" header="Added by HR" sortable></Column>
                                    <Column field="reportingManager" header="Reporting Manager" sortable></Column>
                                    <Column field="planningStatus" header="Planning Completion Status" sortable></Column>
                                </DataTable>

                            </div>
                        </>
                    </div>
                </div>
            }

            {
                !showData &&
                <div className='text-center py-8' ><ProgressSpinner /></div>
            }
        </div >
    )
        : (
            <HRLogin sessionExpired={1} />
        );
}