/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://l44ymzsumrdqfgkjjpoqos72om.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-yfczm44pf5f47frs442z7a5jru",
    "aws_cognito_identity_pool_id": "ap-south-1:311b749d-7b4d-40fe-a29b-f7a307e04188",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_mmXuyCWkv",
    "aws_user_pools_web_client_id": "1u2irm7q0kr8pn232st8t3dugt",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "redingtoneval134121-staging",
    "aws_user_files_s3_bucket_region": "ap-south-1",
    "predictions": {
        "identify": {
            "identifyText": {
                "proxy": false,
                "region": "ap-south-1",
                "defaults": {
                    "format": "ALL"
                }
            }
        }
    }
};


export default awsmobile;
