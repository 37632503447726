import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';
import BoxPlanning from '../../assets/images/dashboard_box_planning.png';
import AddButton from '../../components/common/buttons/AddButton';
import MinusButton from '../../components/common/buttons/MinusButton';
import LeftSideBar from '../../components/common/employee/leftnav.component';
import TopNavBar from '../../components/common/employee/topnav.component';
import { getTargetBasedOnParameterType } from '../../components/common/getTargetBasedOnParameterType';
import { calculateConsolidatedScore, calculateWeightageAchievement, checkIfNegativeValue } from '../../components/helper/dashboardCalCulations';
import { PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES } from '../../components/helper/enum';
import { commaSeparated, isPositiveInteger } from '../../components/helper/maths';
import { ValidateEmployeeRole } from "../../components/helper/validateRole";
import EmpLogin from "../../setup/auth/emp.component";
import { getActualValue } from './evaluation/getActualValue';
import { ProgressSpinner } from 'primereact/progressspinner';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../src/components/helper/graphQLFunctions'
import { ConvertResponseForSelect, convertArrayToObject } from '../../components/helper/commonfunctions';
import moment from "moment";
import * as alertify from 'alertifyjs';
import { CSVLink } from "react-csv";

export default function EmployeeGenerateReport() {

    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')

    const [isAuthenticated, userHasAuthenticated] = useState(true);
    // const [financialYearId,setFinancialYearId]=useState();

    const { reporteeFinancialYearId } = useParams();

    const location = useLocation();

    const [isLoader, setIsLoader] = useState(false);

    //list 
    const [reportsData, setReportsData] = useState([]);
    const [parametersData, setParametersData] = useState([]);

    //state
    const [currentEmployeeNo, setCurrentEmployeeNo] = useState("");
    const [employeeName, setEmployeeName] = useState("");
    const [employeeRole, setEmployeeRole] = useState("");
    const [reportingManager, setReportingManager] = useState("");

    //Region
    //----------------------
    const [regionList, SetRegionList] = useState([]);

    //SBU
    //----------------------
    const [sbuList, SetSbuList] = useState([]);

    //Country
    //----------------------
    const [countryList, SetCountryList] = useState([]);

    //Brand
    //----------------------
    const [brandList, SetBrandList] = useState([]);

    //Product Group
    //----------------------
    const [productGroupList, SetProductGroupList] = useState([]);

    //Customer Group
    //----------------------
    const [customerGroupList, SetCustomerGroupList] = useState([]);

    //Customer
    //----------------------
    const [customerList, SetCustomerList] = useState([]);
    const [isDataLoadedForDetailedView, setDataLoadedForDetailedView] = useState(false)

    //popup
    const [selectedOverrideActual, setSelectedOverrideActual] = useState(null);
    const [actualDetailsData, setActualDetailsData] = useState([]);
    const [financialYear, setFinancialYear] = useState("");
    const [loggedEmployeeTargets, setLoggedEmployeeTargets] = useState([]);
    const [parametersList, SetParametersList] = useState([]);
    const [parameterDetailViewList, setParameterDetailViewList] = useState([]);




    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    function getMonthName(monthNumber) {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        if (monthNumber >= 1 && monthNumber <= 12) {
            return monthNames[monthNumber - 1];
        } else {
            return "Invalid month number. Please provide a number between 1 and 12.";
        }
    }

    const intergrateDetailedViewData = async () => {

        let loggedEmployeeTargetList = await graphQLGetAllData("listTargets", [{ employee_id: { eq: loggedEmployeeId } }]);

        var targetsResponses = loggedEmployeeTargetList.find(o => o.financial_year_id === reporteeFinancialYearId);

        if (targetsResponses) {

            // var targetDetails = await DataStore.query(TargetDetails, (c) => c.target_id.eq(targetsResponses.id))
            var targetDetails = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: targetsResponses.id } }])

            let finalData = [];

            for (let i = 0; i < targetDetails.length; i++) {
                // let actualsDetails = await DataStore.query(ActualDetails, (c) => c.target_details_id.eq(targetDetails[i].id))
                let actualsDetails = actualDetailsData.filter(c => c.target_details_id === targetDetails[i].id)

                let final_ActualData = []
                if (actualsDetails.length > 0) {
                    for (let j = 0; j < actualsDetails.length; j++) {

                        let parameter = parametersData.find(p => p.id === targetDetails[i].parameter_id);
                        let data = {
                            "id": actualsDetails[j].id,
                            "buName": actualsDetails[j].BU,
                            "regionName": actualsDetails[j].region,
                            "countryName": actualsDetails[j].country, "brandName": actualsDetails[j].brand,
                            "pGName": actualsDetails[j].productGroup,
                            "custCatName": actualsDetails[j].customerGroupCode, "customerName": actualsDetails[j].customerGroupCode,
                            "target_Actual_value": Math.round(actualsDetails[j].actual_value),
                            "month": getMonthName(actualsDetails[j].monthNo),
                            "parameterName": parameter ? parameter.name : ''

                        }

                        final_ActualData.push(data)
                        if (j === actualsDetails.length - 1) {
                            finalData.push(final_ActualData)
                        }
                    }
                }

                // if (i === targetDetails.length - 1) {

                // }
            }
            let finalParameterData = convertArrayToObject(finalData);
            setParameterDetailViewList(finalParameterData)

        } else {
            setParameterDetailViewList([])
        }
    }

    const downloadData = parameterDetailViewList.map((c) => {

        let obj = { ...c }
        return obj;
    })

    const headers = [
        { label: "BU", key: "buName" },
        { label: "Region Name", key: "regionName" },
        { label: "CountryName", key: "countryName" },
        { label: "Brand Name", key: "brandName" },
        { label: "Product Group", key: "pGName" },
        { label: "Customer Category", key: "custCatName" },
        { label: "Cusomer ", key: "customerName" },
        { label: "Month", key: "month" },
        { label: "Parameters", key: "parameterName" },
        { label: "Actual Value", key: "target_Actual_value" },


    ];

    const csvReport = {
        data: downloadData,
        headers: headers,
        filename: 'Business-Report.csv'
    };

    const csvLinkRef = useRef(null);

    const downloadReport = () => {
        alertify.confirm('Confirmation', 'Do you want to download these partners?',
            async function () {
                csvLinkRef.current.link.click();
            }, function () { })
    }

    useEffect(() => {
        onLoad();
        bindList();
    }, []);

    useEffect(() => {
        if (isDataLoadedForDetailedView === true) {
            intergrateDetailedViewData();
        }
    }, [parametersData, actualDetailsData])

    const bindList = async () => {

        let financeYearId = reactLocalStorage.get('financialYearId');
        setFinancialYear(financeYearId);

        setDataLoadedForDetailedView(false)
        let parameters = await graphQLGetAllData("listParameters");
        setParametersData(parameters);
        let actualsDetails = await graphQLGetAllData("listActualDetails");
        setActualDetailsData(actualsDetails);
        setDataLoadedForDetailedView(true);
    }


    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            <div className="pl-16 dashboard-main-wrapper setting-wrapper" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="flex justify-between">
                        <div className="sm:flex items-center space-x-4 target-main-wrapper">
                            <Link to="/employee/reports" className="bg-[#fff] text-[#344054] text-[14px] dark:text-white py-1.5 px-3 rounded-md border border-[#D0D5DD] dark:bg-[#344054]"><i className="icon arrow_left_double mr-6 "></i>Back</Link>
                            <div className="text-2xl font-semibold dark:text-white">Reports</div>
                        </div>

                        {parameterDetailViewList.length > 0 ?

                            // isLoader &&
                            <div className='flex justify-between space-x-4'  >
                                <Link onClick={() => window.print()} className=" dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFF] py-2 px-6 bg-btn-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">Print</Link>

                                {/* <div> */}
                                <Link onClick={() => downloadReport()} className="  py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">Download</Link>
                                <CSVLink
                                    {...csvReport}
                                    ref={csvLinkRef}>
                                    <></>
                                </CSVLink>
                                {/* </div> */}


                            </div> : null
                        }

                    </div>
                </div>
                <div className="p-5">
                    {
                        isDataLoadedForDetailedView ?
                            <DataTable
                                paginator value={parameterDetailViewList} responsiveLayout="scroll" style={{ width: '93vw' }}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={50} rowsPerPageOptions={[50, 100, 200]}
                            >
                                {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
                                <Column field="buName" header="BU" sortable filter filterPlaceholder="Search by BU"></Column>
                                <Column field="regionName" header="Region Name" sortable filter filterPlaceholder="Search by Region Name"></Column>
                                <Column field="countryName" header="Country Name" sortable filter filterPlaceholder="Search by Country Name"></Column>
                                <Column field="brandName" header="Brand Name" sortable filter filterPlaceholder="Search by Brand Name"></Column>
                                <Column field="pGName" header="Product Group" sortable filter filterPlaceholder="Search by Product Group"></Column>
                                <Column field="custCatName" header="Customer Cat" sortable filter filterPlaceholder="Search by Customer Cat"></Column>
                                <Column field="customerName" header="Customer" sortable filter filterPlaceholder="Search by Customer"></Column>
                                <Column field="month" header="Month" sortable filter filterPlaceholder="Search by Month"></Column>
                                <Column field="parameterName" header="Parameter Name" sortable filter filterPlaceholder="Search by Parameter Name"></Column>
                                <Column field="target_Actual_value" header="Actual Value" sortable filter filterPlaceholder="Search by Actual"></Column>
                            </DataTable>

                            :

                            <div className='text-center py-8' ><ProgressSpinner /></div>
                    }
                </div>
            </div>
        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}



