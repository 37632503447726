import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function ProgressBar_CircularProgressBarWithChildren(props) {

    return (
        <CircularProgressbarWithChildren value={props.value} values={[0, 100]}
            styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 1,
                strokeLinecap: 'round',
                pathTransitionDuration: 0.5,
                pathColor: "#029046",
                trailColor: "#CCCCCC",
            })}
            >
            <div style={{  marginTop:0,fontSize:30 }}>
                <strong>{props.value}%</strong>
            </div>
            <div style={{ marginTop: 2,fontSize:12, color:"#8a92a2" }}>
                {props.text}
            </div>
        </CircularProgressbarWithChildren>
    )
}