import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import moment from "moment";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Select from "react-select";
import { reactLocalStorage } from 'reactjs-localstorage';
import ChatButton from '../../../../components/common/buttons/ChatButton';
import LeftSideBar from '../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../components/common/employee/topnav.component';
import CircularProgressBar from '../../../../components/common/progressbar/CircularProgressbar';
import { ConvertResponseForSelect, ConvertResponseForSelectTwoNames, ObjectToArray } from '../../../../components/helper/commonfunctions';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { BIUnit, Employee, FinancialYear, InitiatePlanning, Planning, Region, Roles, SBU, TargetDetails, Targets, TeamType } from "../../../../models";
import EmpLogin from "../../../../setup/auth/emp.component";
import { planningFilter } from "../../../../components/helper/getFilterData"
import { checkEmployeeTeamType } from '../../helper';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../components/helper/graphQLFunctions';


export default function BusinessPlanningDashboard() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //state
    const [financialYear, setFinancialYear] = useState("");
    const [sBU, setSBU] = useState("");
    const [bU, setBU] = useState("");
    const [region, setRegion] = useState("");
    const [employee, setEmployee] = useState("");
    const [isPlanUpdated, setPlanUpdated] = useState(false);

    //list
    const [financialYearList, setFinancialYearList] = useState([]);
    const [sBUList, setSBUList] = useState([]);
    const [bUList, setBUList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);

    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')


    //Edit View Button
    const actionsOnRoles = () => {
        return (
            <React.Fragment >
                <div className='content-center'>
                    <ChatButton />
                </div>
            </React.Fragment>
        );
    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
        async function bindList() {
            let typeResult = checkEmployeeTeamType()
            console.log('checkEmployeeTeamType',typeResult);

            //set financial year
            let selectedYear = reactLocalStorage.getObject('selectedYear');
            if (Object.keys(selectedYear).length !== 0) {
                setFinancialYear(selectedYear)
                bindPlanningReport(selectedYear.value, loggedEmployeeId)
            }
            else {
                // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));

                var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

                setFinancialYear({ label: responses[0].name, value: responses[0].id })
                bindPlanningReport(responses[0].id, loggedEmployeeId)
            }

            //get financial year
            // var financialYearResponses = await DataStore.query(FinancialYear, Predicates.ALL, {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });

             var financialYearResponses = await graphQLGetAllData('listFinancialYears');
            financialYearResponses = financialYearResponses.sort((a, b) => a.name.localeCompare(b.name));

            var myArray = []; myArray = ConvertResponseForSelect(financialYearResponses); setFinancialYearList(myArray);

            //get SBU list
            // var sBUResponses = await DataStore.query(SBU, Predicates.ALL, {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });

            var sBUResponses = await graphQLGetAllData('listSBUS');
            sBUResponses = sBUResponses.sort((a, b) => a.name.localeCompare(b.name));
            var mySBUArray = []; mySBUArray = ConvertResponseForSelect(sBUResponses);
            let zeroPositionSBUObject = { label: "All SBU", value: 0 }; mySBUArray.unshift(zeroPositionSBUObject);
            setSBU({ label: "All SBU", value: 0 })
            setSBUList(mySBUArray);

            //get BU list
            // var bIUnitResponses = await DataStore.query(BIUnit, Predicates.ALL, {
            //     sort: s => s.createdAt(SortDirection.ASCENDING)
            // });

            var bIUnitResponses = await graphQLGetAllData('listBIUnits');
            bIUnitResponses = bIUnitResponses.sort((a, b) => a.name.localeCompare(b.name));

            var mybUArray = []; mybUArray = ConvertResponseForSelect(bIUnitResponses); setBUList(mybUArray);

            //get region list
            // var regionsResponses = await DataStore.query(Region, Predicates.ALL, {
            //     sort: s => s.createdAt(SortDirection.ASCENDING)
            // });

            var regionsResponses = await graphQLGetAllData('listRegions')
            regionsResponses = regionsResponses.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
            var myregionArray = []; myregionArray = ConvertResponseForSelect(regionsResponses); setRegionList(myregionArray);

            //get employee list
             // ORCONDITION
            
             // employeeResponses = await DataStore.query(Employee, (c) => c.or(c => [
             //     c.id.eq(loggedEmployeeId),
             //     c.parentEmployeeId.eq(loggedEmployeeId)
             // ]), {
             //     sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
             // });
             var employeeResponses = [];
             employeeResponses = await graphQLGetAllData('listEmployees', [
                 { parentEmployeeId: { eq: loggedEmployeeId } }
             ]);
 
             let employeeSelfData = await graphQLFindRecordById('getEmployee', loggedEmployeeId);
             employeeResponses.push(employeeSelfData);
 
             employeeResponses = employeeResponses.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));
            var myemployeeArray = []; myemployeeArray = ConvertResponseForSelectTwoNames(employeeResponses);
            let zeroPositionEmployeeObject = { label: "All Employee", value: 0 }; myemployeeArray.unshift(zeroPositionEmployeeObject);
            setEmployee({ label: "All Employee", value: 0 })
            setEmployeeList(myemployeeArray);

        }
        bindList()
        // bindPlanningReport()
    }, []);


    const [employeeDefaultTargetsList, SetEmployeeDefaultTargetList] = useState([]);

    const [employeeTargetsList, SetEmployeeTargetList] = useState([]);
    const [countEmployeeResponse, SetCountEmployeeResponse] = useState(0);
    const [count_EligibleEmployee, SetCount_EligibleEmployee] = useState(0);
    const [count_PlanningInitiated, SetCount_PlanningInitiated] = useState(0);
    const [count_TargetAssigned, SetCount_TargetAssigned] = useState(0);
    const [count_YetToStart, SetCount_YetToStart] = useState(0);
    const [count_PlanningInDraft, SetCount_PlanningInDraft] = useState(0);
    const [count_PlanSubmitted, SetCount_PlanSubmitted] = useState(0);
    const [count_Approved, SetCount_Approved] = useState(0);
    const [count_PendingReview, SetCount_PendingReview] = useState(0);
    const [countEligibleSale, SetCountEligibleSale] = useState(0);
    const [countEligibleBusiness, SetCountEligibleBusiness] = useState(0);
    const [countPlanInitiatedBusiness, setPlanInitiatedBusinessCount] = useState(0);
    const [countPlanInitiatedSale, setPlanInitiatedSaleCount] = useState(0);
    const [countTargetAssignedSale, setCountTargetAssignedSale] = useState(0);
    const [countTargetAssignedBusiness, setCountTargetAssignedBusiness] = useState(0);
    const [countYetToStartSale, setCountYetToStartSale] = useState(0);
    const [countYetToStartBusiness, setCountYetToStartBusiness] = useState(0);
    const [countPlanInDraftBusiness, setCountPlanInDraftBusiness] = useState(0);
    const [countPlanInDraftSale, setCountPlanInDraftSale] = useState(0);
    const [countPlanSubmittedSale, setCountPlanSubmittedSale] = useState(0);
    const [countPlanSubmittedBusiness, setCountPlanSubmittedBusiness] = useState(0);
    const [countPendingReviewSale, setCountPendingReviewSale] = useState(0);
    const [countPendingReviewBusiness, setCountPendingReviewBusiness] = useState(0);
    const [countApprovedBusiness, setCountApprovedBusiness] = useState(0);
    const [countApprovedSale, setCountApprovedSale] = useState(0);
    const [planFilterInitiatedForActive, setIsPlanFilterInitiatedActive] = useState(false);
    const [isEligibleEmployeeForActive, setIsEligibleEmployeeActive] = useState(true);
    const [targetAssignedForActive, setTargetAssignedActive] = useState(false);
    const [isYetToStartForActive, setIsYetToStartActive] = useState(false);
    const [planInDraftForActive, setIsPlanInDraftActive] = useState(false);
    const [planSubmittedForActive, setIsPlanSubmittedActive] = useState(false);
    const [pendingReviewForActive, setPendingReviewActive] = useState(false);
    const [planApprovedForActive, setIsApprovedActive] = useState(false);

    const getDetailedReport = async (isPlanFilterInitiated = 0, isEligible = 0, isTargetAssigned = 0, isYetToStart = 0, isPlanInDraft = 0, isPlanSubmitted = 0, isPendingReview = 0, isApproved = 0) => {
        // if (isFiltered) {
        let counter = 1;
        let counterBusiness = 1;
        if (isPlanFilterInitiated === 1) {
            SetEmployeeTargetList(count_PlanningInitiated);
            let planningBusiness = 0
            let planningSales = 0
            for (let i = 0; i < count_PlanningInitiated.length; i++) {
                if (count_PlanningInitiated[i].roleTeamType) {
                    if (count_PlanningInitiated[i].roleTeamType === TeamType.S) {
                        planningSales = planningSales + 1;

                    } else if (count_PlanningInitiated[i].roleTeamType === TeamType.B) {
                        planningBusiness = planningBusiness + 1;

                    }
                }
            }
            setPlanInitiatedSaleCount(planningSales)
            setPlanInitiatedBusinessCount(planningBusiness)
            setIsPlanFilterInitiatedActive(true)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(false)
        }
        else if (isEligible === 1) {

            SetEmployeeTargetList(employeeDefaultTargetsList);
            let eligibleBusiness = 0
            let eligibleSales = 0
            for (let i = 0; i < employeeDefaultTargetsList.length; i++) {
                if (employeeDefaultTargetsList[i].roleTeamType) {
                    if (employeeDefaultTargetsList[i].roleTeamType === TeamType.S) {
                        eligibleSales = eligibleSales + 1
                    } else if (employeeDefaultTargetsList[i].roleTeamType === TeamType.B) {
                        eligibleBusiness = eligibleBusiness + 1
                    }
                }
            }
            SetCountEligibleSale(eligibleSales)
            SetCountEligibleBusiness(eligibleBusiness)
            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(true)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(false)
        }
        else if (isTargetAssigned === 1) {
            SetEmployeeTargetList(count_TargetAssigned);
            let targetAssignedBusiness = 0
            let targetAssignedSales = 0
            for (let i = 0; i < count_TargetAssigned.length; i++) {
                if (employeeDefaultTargetsList[i].roleTeamType) {
                    if (employeeDefaultTargetsList[i].roleTeamType === TeamType.S) {
                        targetAssignedSales = targetAssignedSales + 1;
                    } else if (employeeDefaultTargetsList[i].roleTeamType === TeamType.B) {
                        targetAssignedBusiness = targetAssignedBusiness + 1;
                    }
                }

            }
            setCountTargetAssignedSale(targetAssignedSales)
            setCountTargetAssignedBusiness(targetAssignedBusiness)
            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(true)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(false)
        }
        else if (isYetToStart === 1) {
            SetEmployeeTargetList(count_YetToStart);
            let yetToStartBusiness = 0
            let yetToStartSales = 0
            for (let i = 0; i < count_YetToStart.length; i++) {
                if (employeeDefaultTargetsList[i].roleTeamType) {
                    if (employeeDefaultTargetsList[i].roleTeamType === TeamType.S) {
                        yetToStartSales = yetToStartSales + 1;
                    } else if (employeeDefaultTargetsList[i].roleTeamType === TeamType.B) {
                        yetToStartBusiness = yetToStartBusiness + 1;
                    }
                }
            }
            setCountYetToStartSale(yetToStartSales)
            setCountYetToStartBusiness(yetToStartBusiness)
            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(true)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(false)
        }
        else if (isPlanInDraft === 1) {
            SetEmployeeTargetList(count_PlanningInDraft);
            let planInDraftBusiness = 0
            let planInDraftSales = 0
            for (let i = 0; i < count_PlanningInDraft.length; i++) {
                if (employeeDefaultTargetsList[i].roleTeamType) {
                    if (employeeDefaultTargetsList[i].roleTeamType === TeamType.S) {
                        planInDraftSales = planInDraftSales + 1;
                    } else if (employeeDefaultTargetsList[i].roleTeamType === TeamType.B) {
                        planInDraftBusiness = planInDraftBusiness + 1;
                    }
                }
            }
            setCountPlanInDraftSale(planInDraftSales)
            setCountPlanInDraftBusiness(planInDraftBusiness)
            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(true)
            setPendingReviewActive(false)
            setIsApprovedActive(false)

        }
        else if (isPlanSubmitted === 1) {
            SetEmployeeTargetList(count_PlanSubmitted);
            let planSubmittedBusiness = 0
            let planSubmittedSales = 0
            for (let i = 0; i < count_PlanSubmitted.length; i++) {
                if (employeeDefaultTargetsList[i].roleTeamType) {
                    if (employeeDefaultTargetsList[i].roleTeamType === TeamType.S) {
                        planSubmittedSales = planSubmittedSales + 1;
                    } else if (employeeDefaultTargetsList[i].roleTeamType === TeamType.B) {
                        planSubmittedBusiness = planSubmittedBusiness + 1;
                    }
                }
            }
            setCountPlanSubmittedSale(planSubmittedSales)
            setCountPlanSubmittedBusiness(planSubmittedBusiness)
            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(true)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(false)
        }
        else if (isPendingReview === 1) {
            SetEmployeeTargetList(count_PendingReview);
            let pendingReviewBusiness = 0
            let pendingReviewSales = 0
            for (let i = 0; i < count_PendingReview.length; i++) {
                if (employeeDefaultTargetsList[i].roleTeamType) {
                    if (employeeDefaultTargetsList[i].roleTeamType === TeamType.S) {
                        pendingReviewSales = pendingReviewSales + 1;
                    } else if (employeeDefaultTargetsList[i].roleTeamType === TeamType.B) {
                        pendingReviewBusiness = pendingReviewBusiness + 1;
                    }
                }
            }
            setCountPendingReviewSale(pendingReviewSales)
            setCountPendingReviewBusiness(pendingReviewBusiness)
            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(true)
            setIsApprovedActive(false)
        }
        else if (isApproved === 1) {
            SetEmployeeTargetList(count_Approved);
            let approvedBusiness = 0
            let approvedSales = 0
            for (let i = 0; i < count_Approved.length; i++) {
                if (employeeDefaultTargetsList[i].roleTeamType) {
                    if (employeeDefaultTargetsList[i].roleTeamType === TeamType.S) {
                        approvedSales = approvedSales + 1;
                    } else if (employeeDefaultTargetsList[i].roleTeamType === TeamType.B) {
                        approvedBusiness = approvedBusiness + 1;
                    }
                }
            }
            setCountApprovedSale(approvedSales)
            setCountApprovedBusiness(approvedBusiness)
            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(true)
        }
    }

    const bindPlanningReport = async (financialYearId, loggedEmployeeId) => {

        // console.log('financialYearId',financialYearId);
        // console.log('loggedEmployeeId',loggedEmployeeId);
        // console.log('reaching here')

        // var employeeResponse = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.report_manager_employee_id.eq(loggedEmployeeId),
        //     c.financial_year_id.eq(financialYearId)
        // ]));
        // console.log('employeeResponse',employeeResponse)
         var employeeResponse= await graphQLGetAllData('listTargets', [
            { report_manager_employee_id: { eq:loggedEmployeeId } 
             , financial_year_id: { eq:financialYearId} }
        ]);

        console.log('employeeResponse2',employeeResponse)


        let isPlanInitiated = false
        // var checkIsPlanInit = await DataStore.query(Planning, (c) => c.financial_year_id.eq(financialYearId))

        var checkIsPlanInit = await graphQLGetAllData('listPlannings', [{ financial_year_id: { eq: financialYearId } }]);
        if (checkIsPlanInit) { isPlanInitiated = true } else {
            SetCount_PlanningInitiated(0)
            SetCount_TargetAssigned(0)
            SetCount_YetToStart(0)
            SetCount_PlanningInDraft(0)
            SetCount_Approved(0)
            SetCount_PendingReview(0)
            SetCount_PlanSubmitted(0)
        }

        if (employeeResponse.length) {
            //employeeResponse = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(loggedEmployeeId));
            var newResponse = [];
            let counter = 1;
            let counterBusiness = 1;

            if (employeeResponse.length) {
                for (var i = 0; i < employeeResponse.length; i++) {
                    let employeeId = ""; let name = "";
                    // var empResponse = await DataStore.query(Employee, employeeResponse[i].employee_id);
                    var empResponse = await graphQLFindRecordById('getEmployee', employeeResponse[i].employee_id);
                    if (empResponse !== undefined) {

                        if (empResponse) { name = empResponse.firstName + " " + empResponse.lastName; employeeId = empResponse.employeeId } else { name = ""; employeeId = ""; }
                        let role = "";
                        let roleTeamType = "";
                        // var roleResponse = await DataStore.query(Roles, employeeResponse[i].role_id);
                        var roleResponse = await graphQLFindRecordById('getRoles', employeeResponse[i].role_id);

                        if (roleResponse) {
                            role = roleResponse.name;
                            roleTeamType = roleResponse.teamType;
                        } else { role = ""; roleTeamType = ""; }

                        let sbu = "";
                        // var sbuResponse = await DataStore.query(SBU, empResponse.param_sbu_id);
                        var sbuResponse = await graphQLFindRecordById('getSBU',  empResponse.param_sbu_id);

                        if (sbuResponse) { sbu = sbuResponse.name } else { sbu = ""; }


                        let reportingManagerId = employeeResponse[i].report_manager_employee_id
                        let RMemployeeId = employeeResponse[i].employee_id
                        let reportingManager = "";
                        var parentResponse;
                        if (reportingManagerId === RMemployeeId) {
                            // let employeeResponse = await DataStore.query(Employee, RMemployeeId);
                            // parentResponse = await DataStore.query(Employee, employeeResponse.parentEmployeeId);
                            let employeeResponse = await graphQLFindRecordById('getEmployee', RMemployeeId);
                            parentResponse = await graphQLFindRecordById('getEmployee',  employeeResponse.parentEmployeeId);
                        } else {
                            // parentResponse = await DataStore.query(Employee, employeeResponse[i].report_manager_employee_id);
                            parentResponse = await graphQLFindRecordById('getEmployee',  employeeResponse[i].report_manager_employee_id);
                        }
                        if (parentResponse) { reportingManager = parentResponse.firstName + " " + parentResponse.lastName; } else { reportingManager = ""; }

                        // var TargetDetailResponse = await DataStore.query(TargetDetails, (c) => c.target_id.eq(employeeResponse[i].id));
                        var TargetDetailResponse = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: employeeResponse[i].id } }]);
                        let checkIsPlanSubmitted = TargetDetailResponse.every((item) => item.isMarkAsComplete_AssignTarget)
                        let checkIsApproved = TargetDetailResponse.every((item) => item.is_approve)

                        let planSubmitted = checkIsPlanSubmitted ? "Yes" : "No"
                        let targetApproved = checkIsApproved ? "Yes" : "No"

                        let checkYetToStart = TargetDetailResponse.every((item) => !item.is_accepted_by_employee)
                        let yetToStart = checkYetToStart ? "Yes" : "No"

                        let checkPlanInDraft = TargetDetailResponse.every((item) => item.is_accepted_by_employee && item.isMarkAsComplete)
                        let planInDraft = checkPlanInDraft ? "Yes" : "No"

                        //PlanInit
                        let isPlanInitiatedForEmployee = 0
                        if (isPlanInitiated) {
                            // var checkIsPlanInit = await DataStore.query(InitiatePlanning, (c) => c.employee_id.eq(employeeResponse[i].employee_id))

                            var checkIsPlanInit = await graphQLGetAllData('listInitiatePlannings', [{ employee_id: { eq: employeeResponse[i].employee_id } }]);

                            if (checkIsPlanInit) { isPlanInitiatedForEmployee = checkIsPlanInit.length ? 1 : 0 }
                        } else {
                            isPlanInitiatedForEmployee = 0
                        }

                        let data = {
                            "id": employeeResponse[i].id,
                            "financial_year_id": employeeResponse[i].financial_year_id,
                            "employee_id": employeeResponse[i].employee_id,
                            "report_manager_employee_id": employeeResponse[i].report_manager_employee_id,
                            "role_id": employeeResponse[i].role_id,
                            "isAddedByHRAdmin": employeeResponse[i].isAddedByHRAdmin,
                            "isSubmittedByEmployee": employeeResponse[i].isSubmittedByEmployee,
                            "isAssignedToEmployee": employeeResponse[i].isAssignedToEmployee,
                            "createdAt": employeeResponse[i].createdAt,
                            "updatedAt": employeeResponse[i].updatedAt,
                            "name": name,
                            "employeeId": employeeId,
                            "role": role,
                            "roleTeamType": roleTeamType,
                            "sbu": sbu,
                            "vertical": "Distribution",
                            "reportingManager": reportingManager,
                            "targetAssigned": "Yes",
                            "planSubmitted": planSubmitted,
                            "targetApproved": targetApproved,
                            "yetToStart": yetToStart,
                            "planInDraft": planInDraft,
                            "isPlanInitiatedForEmployee": isPlanInitiatedForEmployee
                        }
                        newResponse.push(data)
                    }
                    if (i === employeeResponse.length - 1) {
                        let sortArray = newResponse.sort((a, b) => (a.index > b.index ? 1 : -1))
                        SetEmployeeTargetList(sortArray);
                        SetEmployeeDefaultTargetList([...sortArray]);

                        //*Calculate Eligible Employee
                        var countEligibleEmployeeBusiness = newResponse.filter((e) => e.roleTeamType == TeamType.B)
                        var countEligibleEmployeeSales = newResponse.filter((e) => e.roleTeamType == TeamType.S)
                        SetCount_EligibleEmployee(newResponse)
                        SetCountEligibleSale(countEligibleEmployeeSales.length)
                        SetCountEligibleBusiness(countEligibleEmployeeBusiness.length)

                        //*Calculate Planning Initiated
                        var countPlanningInitiated = newResponse.filter((e) => e.isPlanInitiatedForEmployee == 1)
                        SetCount_PlanningInitiated(countPlanningInitiated)
                        SetCountEmployeeResponse(employeeResponse.length)

                        let planningInitiatedBusiness = 0;
                        let planningInitiatedSales = 0;

                        for (let i = 0; i < countPlanningInitiated.length; i++) {
                            if (countPlanningInitiated[i].roleTeamType) {
                                if (countPlanningInitiated[i].roleTeamType === TeamType.S) {
                                    planningInitiatedSales = planningInitiatedSales + 1;
                                } else if (countPlanningInitiated[i].roleTeamType === TeamType.B) {
                                    planningInitiatedBusiness = planningInitiatedBusiness + 1;
                                }
                            }
                        }

                        setPlanInitiatedSaleCount(planningInitiatedSales)
                        setPlanInitiatedBusinessCount(planningInitiatedBusiness)

                        //*Calculate Target Assigned
                        var countTargetAssigned = newResponse.filter((e) => e.targetAssigned === "Yes")
                        SetCount_TargetAssigned(countTargetAssigned)
                        let targetBusiness = 0;
                        let targetSales = 0;
                        for (let i = 0; i < countTargetAssigned.length; i++) {
                            if (countTargetAssigned[i].roleTeamType) {
                                if (countTargetAssigned[i].roleTeamType === TeamType.S) {
                                    targetSales = targetSales + 1;
                                } else if (countTargetAssigned[i].roleTeamType === TeamType.B) {
                                    targetBusiness = targetBusiness + 1;
                                }
                            }

                            if (i === countTargetAssigned.length - 1) {
                                setCountTargetAssignedSale(targetSales)
                                setCountTargetAssignedBusiness(targetBusiness)
                            }
                        }

                        //*Calculate Yet To Start
                        var countYetToStart = newResponse.filter((e) => e.yetToStart === "Yes")
                        SetCount_YetToStart(countYetToStart)

                        for (let i = 0; i < countYetToStart.length; i++) {
                            if (countYetToStart[i].roleTeamType) {
                                if (countYetToStart[i].roleTeamType === TeamType.S) {
                                    setCountYetToStartSale(counter++)
                                } else if (countYetToStart[i].roleTeamType === TeamType.B) {
                                    setCountYetToStartBusiness(counterBusiness++)
                                }
                            }

                        }

                        //*Calculate Plan In Draft
                        var countPlanInDraft = newResponse.filter((e) => e.planInDraft === "Yes")
                        SetCount_PlanningInDraft(countPlanInDraft)
                        let planInBusiness = 0;
                        let planInSales = 0;
                        for (let i = 0; i < countPlanInDraft.length; i++) {
                            if (countPlanInDraft[i].roleTeamType) {
                                if (countPlanInDraft[i].roleTeamType === TeamType.S) {
                                    planInSales = planInSales + 1;
                                } else if (countPlanInDraft[i].roleTeamType === TeamType.B) {
                                    planInBusiness = planInBusiness + 1;
                                }
                            }
                            if (i === countPlanInDraft.length - 1) {
                                setCountPlanInDraftSale(planInSales);
                                setCountPlanInDraftBusiness(planInBusiness);
                            }
                        }

                        //*Calculate Plan Approved
                        var countPlanApproved = newResponse.filter((e) => e.targetApproved === "Yes")
                        SetCount_Approved(countPlanApproved)
                        let approveBusiness = 0;
                        let approveSales = 0;

                        for (let i = 0; i < countPlanApproved.length; i++) {
                            if (countPlanApproved[i].roleTeamType) {
                                if (countPlanApproved[i].roleTeamType === TeamType.S) {
                                    approveSales = approveSales + 1;
                                } else if (countPlanApproved[i].roleTeamType === TeamType.B) {
                                    approveBusiness = approveBusiness + 1;
                                }
                            }
                        }
                        setCountApprovedSale(approveSales)
                        setCountApprovedBusiness(approveBusiness)

                        //*Calculate Pending Review
                        var countPendingReview = newResponse.filter((e) => e.pendingReview === "Yes")
                        SetCount_PendingReview(countPendingReview)

                        for (let i = 0; i < countPendingReview.length; i++) {
                            if (countPendingReview[i].roleTeamType) {
                                if (countPendingReview[i].roleTeamType === TeamType.S) {
                                    setCountPendingReviewSale(counter++)
                                } else if (countPendingReview[i].roleTeamType === TeamType.B) {
                                    setCountPendingReviewBusiness(counterBusiness++)
                                }
                            }

                        }

                        //*Calculate Plan Submitted
                        var countPlanSubmitted = newResponse.filter((e) => e.planSubmitted === "Yes")
                        SetCount_PlanSubmitted(countPlanSubmitted)
                        let planSubmitBusiness = 0;
                        let planSubmitSales = 0;

                        for (let i = 0; i < countPlanSubmitted.length; i++) {
                            if (countPlanSubmitted[i].roleTeamType) {
                                if (countPlanSubmitted[i].roleTeamType === TeamType.S) {
                                    planSubmitSales = planSubmitSales + 1;
                                } else if (countPlanSubmitted[i].roleTeamType === TeamType.B) {
                                    planSubmitBusiness = planSubmitBusiness + 1;
                                }
                            }
                        }
                        setCountPlanSubmittedSale(planSubmitSales)
                        setCountPlanSubmittedBusiness(planSubmitBusiness)

                    }
                }
            }
        }
        else {
            SetEmployeeTargetList([])
            SetCount_TargetAssigned(0)
            SetCount_PendingReview(0)
            setCountTargetAssignedSale(0)
            SetCountEligibleBusiness(0)
            SetCountEligibleSale(0)
            SetCount_PlanningInitiated(0)
            setPlanInitiatedSaleCount(0)
            setPlanInitiatedBusinessCount(0)
            setCountTargetAssignedBusiness(0)
            SetCount_YetToStart(0)
            setCountYetToStartSale(0)
            setCountYetToStartBusiness(0)
            SetCount_PlanningInDraft(0)
            setCountPlanInDraftSale(0)
            setCountPlanInDraftBusiness(0)
            SetCount_Approved(0)
            setCountApprovedSale(0)
            setCountApprovedBusiness(0)
            setCountPendingReviewSale(0)
            setCountPendingReviewBusiness(0)
            SetCount_PlanSubmitted(0)
            setCountPlanSubmittedSale(0)
            setCountPlanSubmittedBusiness(0)
        }
    }

    //onClick Fy
    const handleClickFYDropdown = async (event) => {
        setFinancialYear(event);
        setIsEligibleEmployeeActive(true)
        setIsPlanFilterInitiatedActive(false)
        setTargetAssignedActive(false)
        setIsYetToStartActive(false)
        setIsPlanSubmittedActive(false)
        setIsPlanInDraftActive(false)
        setPendingReviewActive(false)
        setIsApprovedActive(false)

        let counter = 1;
        let counterBusiness = 1;

        reactLocalStorage.setObject('selectedYear', event);
        const data = await planningFilter(employee.value, region.value, sBU.value, event.value)
        const finalParameterResult = Array.from(data)
        if (finalParameterResult.length > 0) {

            var countEligibleEmployeeBusiness = finalParameterResult.filter((e) => e.roleTeamType == TeamType.B)
            var countEligibleEmployeeSales = finalParameterResult.filter((e) => e.roleTeamType == TeamType.S)
            SetCount_EligibleEmployee(finalParameterResult)
            SetCountEligibleSale(countEligibleEmployeeSales.length)
            SetCountEligibleBusiness(countEligibleEmployeeBusiness.length)

            var countPlanningInitiated = finalParameterResult.filter((e) => e.isPlanInitiatedForEmployee == 1)
            SetCount_PlanningInitiated(countPlanningInitiated)

            let planningInitiatedBusiness = 0;
            let planningInitiatedSales = 0;

            for (let i = 0; i < countPlanningInitiated.length; i++) {
                if (countPlanningInitiated[i].roleTeamType) {
                    if (countPlanningInitiated[i].roleTeamType === TeamType.S) {
                        planningInitiatedSales = planningInitiatedSales + 1
                    } else if (countPlanningInitiated[i].roleTeamType === TeamType.B) {
                        planningInitiatedBusiness = planningInitiatedBusiness + 1
                    }
                }
            }
            setPlanInitiatedSaleCount(planningInitiatedSales)
            setPlanInitiatedBusinessCount(planningInitiatedBusiness)

            var countTargetAssigned = finalParameterResult.filter((e) => e.targetAssigned === "Yes")
            SetCount_TargetAssigned(countTargetAssigned)

            let targetAssignedBusiness = 0;
            let targetAssignedSales = 0;

            for (let i = 0; i < countTargetAssigned.length; i++) {
                if (countTargetAssigned[i].roleTeamType) {
                    if (countTargetAssigned[i].roleTeamType === TeamType.S) {
                        targetAssignedSales = targetAssignedSales + 1;
                    } else if (countTargetAssigned[i].roleTeamType === TeamType.B) {
                        targetAssignedBusiness = targetAssignedBusiness + 1;
                    }
                }

            }
            setCountTargetAssignedSale(targetAssignedSales)
            setCountTargetAssignedBusiness(targetAssignedBusiness)

            var countYetToStart = finalParameterResult.filter((e) => e.yetToStart === "Yes")
            SetCount_YetToStart(countYetToStart)

            let yetToStartBusiness = 0;
            let yetToStartSales = 0;

            for (let i = 0; i < countYetToStart.length; i++) {
                if (countYetToStart[i].roleTeamType) {
                    if (countYetToStart[i].roleTeamType === TeamType.S) {
                        yetToStartSales = yetToStartSales + 1;
                    } else if (countYetToStart[i].roleTeamType === TeamType.B) {
                        yetToStartBusiness = yetToStartBusiness + 1;
                    }
                }

            }
            setCountYetToStartSale(yetToStartSales)
            setCountYetToStartBusiness(yetToStartBusiness)

            var countPlanInDraft = finalParameterResult.filter((e) => e.planInDraft === "Yes")
            SetCount_PlanningInDraft(countPlanInDraft)

            let planInDraftBusiness = 0;
            let planInDraftSales = 0;

            for (let i = 0; i < countPlanInDraft.length; i++) {
                if (countPlanInDraft[i].roleTeamType) {
                    if (countPlanInDraft[i].roleTeamType === TeamType.S) {
                        planInDraftSales = planInDraftSales + 1;

                    } else if (countPlanInDraft[i].roleTeamType === TeamType.B) {
                        planInDraftBusiness = planInDraftBusiness + 1;
                    }
                }

            }
            setCountPlanInDraftSale(planInDraftSales)
            setCountPlanInDraftBusiness(planInDraftBusiness)


            var countPlanApproved = finalParameterResult.filter((e) => e.targetApproved === "Yes")
            SetCount_Approved(countPlanApproved)

            let approveBusiness = 0;
            let approveSales = 0;

            for (let i = 0; i < countPlanApproved.length; i++) {
                if (countPlanApproved[i].roleTeamType) {
                    if (countPlanApproved[i].roleTeamType === TeamType.S) {
                        approveSales = approveSales + 1;
                    } else if (countPlanApproved[i].roleTeamType === TeamType.B) {
                        approveBusiness = approveBusiness + 1;
                    }
                }

            }
            setCountApprovedSale(approveSales)
            setCountApprovedBusiness(approveBusiness)

            var countPendingReview = finalParameterResult.filter((e) => e.pendingReview === "Yes")
            SetCount_PendingReview(countPendingReview)

            let pendingReviewBusiness = 0;
            let pendingReviewSales = 0;

            for (let i = 0; i < countPendingReview.length; i++) {
                if (countPendingReview[i].roleTeamType) {
                    if (countPendingReview[i].roleTeamType === TeamType.S) {
                        pendingReviewSales = pendingReviewSales + 1;
                    } else if (countPendingReview[i].roleTeamType === TeamType.B) {
                        pendingReviewBusiness = pendingReviewBusiness + 1;
                    }
                }

            }
            setCountPendingReviewSale(pendingReviewSales)
            setCountPendingReviewBusiness(pendingReviewBusiness)


            var countPlanSubmitted = finalParameterResult.filter((e) => e.planSubmitted === "Yes")
            SetCount_PlanSubmitted(countPlanSubmitted)

            let planSubmittedBusiness = 0;
            let planSubmittedSales = 0;

            for (let i = 0; i < countPlanSubmitted.length; i++) {
                if (countPlanSubmitted[i].roleTeamType) {
                    if (countPlanSubmitted[i].roleTeamType === TeamType.S) {
                        planSubmittedSales = planSubmittedSales + 1;
                    } else if (countPlanSubmitted[i].roleTeamType === TeamType.B) {
                        planSubmittedBusiness = planSubmittedBusiness + 1;
                    }
                }

            }
            setCountPlanSubmittedSale(planSubmittedSales)
            setCountPlanSubmittedBusiness(planSubmittedBusiness)


            SetEmployeeTargetList(finalParameterResult);
            SetCountEmployeeResponse(finalParameterResult.length)
        }
        else {
            SetEmployeeTargetList([]);
            SetCount_EligibleEmployee(0)
            SetCount_TargetAssigned(0)
            SetCount_PendingReview(0)
            setCountTargetAssignedSale(0)
            SetCountEligibleBusiness(0)
            SetCountEligibleSale(0)
            SetCount_PlanningInitiated(0)
            setPlanInitiatedSaleCount(0)
            setPlanInitiatedBusinessCount(0)
            setCountTargetAssignedBusiness(0)
            SetCount_YetToStart(0)
            setCountYetToStartSale(0)
            setCountYetToStartBusiness(0)
            SetCount_PlanningInDraft(0)
            setCountPlanInDraftSale(0)
            setCountPlanInDraftBusiness(0)
            SetCount_Approved(0)
            setCountApprovedSale(0)
            setCountApprovedBusiness(0)
            setCountPendingReviewSale(0)
            setCountPendingReviewBusiness(0)
            SetCount_PlanSubmitted(0)
            setCountPlanSubmittedSale(0)
            setCountPlanSubmittedBusiness(0)
        }
    }

    //onClick Region
    // const handleClickRegionDropdown = (event) => {
    //     setRegion(event)
    //     let data = planningFilter(employee.value, event.value, sBU.value, financialYear.value)
    // }

    //onClick SBU
    const handleClickSBUDropdown = async (event) => {
        setSBU(event)
        const data = await planningFilter(employee.value, region.value, event.value, financialYear.value)
        const finalParameterResult = Array.from(data)

        if (finalParameterResult.length > 0) {

            var countEligibleEmployeeBusiness = finalParameterResult.filter((e) => e.roleTeamType == TeamType.B)
            var countEligibleEmployeeSales = finalParameterResult.filter((e) => e.roleTeamType == TeamType.S)
            SetCount_EligibleEmployee(finalParameterResult)
            SetCountEligibleSale(countEligibleEmployeeSales.length)
            SetCountEligibleBusiness(countEligibleEmployeeBusiness.length)

            var countPlanningInitiated = finalParameterResult.filter((e) => e.isPlanInitiatedForEmployee == 1)
            SetCount_PlanningInitiated(countPlanningInitiated)

            let planningInitiatedBusiness = 0;
            let planningInitiatedSales = 0;

            for (let i = 0; i < countPlanningInitiated.length; i++) {
                if (countPlanningInitiated[i].roleTeamType) {
                    if (countPlanningInitiated[i].roleTeamType === TeamType.S) {
                        planningInitiatedSales = planningInitiatedSales + 1
                    } else if (countPlanningInitiated[i].roleTeamType === TeamType.B) {
                        planningInitiatedBusiness = planningInitiatedBusiness + 1
                    }
                }
            }
            setPlanInitiatedSaleCount(planningInitiatedSales)
            setPlanInitiatedBusinessCount(planningInitiatedBusiness)

            var countTargetAssigned = finalParameterResult.filter((e) => e.targetAssigned === "Yes")
            SetCount_TargetAssigned(countTargetAssigned)

            let targetAssignedBusiness = 0;
            let targetAssignedSales = 0;

            for (let i = 0; i < countTargetAssigned.length; i++) {
                if (countTargetAssigned[i].roleTeamType) {
                    if (countTargetAssigned[i].roleTeamType === TeamType.S) {
                        targetAssignedSales = targetAssignedSales + 1;
                    } else if (countTargetAssigned[i].roleTeamType === TeamType.B) {
                        targetAssignedBusiness = targetAssignedBusiness + 1;
                    }
                }

            }
            setCountTargetAssignedSale(targetAssignedSales)
            setCountTargetAssignedBusiness(targetAssignedBusiness)

            var countYetToStart = finalParameterResult.filter((e) => e.yetToStart === "Yes")
            SetCount_YetToStart(countYetToStart)

            let yetToStartBusiness = 0;
            let yetToStartSales = 0;

            for (let i = 0; i < countYetToStart.length; i++) {
                if (countYetToStart[i].roleTeamType) {
                    if (countYetToStart[i].roleTeamType === TeamType.S) {
                        yetToStartSales = yetToStartSales + 1;
                    } else if (countYetToStart[i].roleTeamType === TeamType.B) {
                        yetToStartBusiness = yetToStartBusiness + 1;
                    }
                }

            }
            setCountYetToStartSale(yetToStartSales)
            setCountYetToStartBusiness(yetToStartBusiness)

            var countPlanInDraft = finalParameterResult.filter((e) => e.planInDraft === "Yes")
            SetCount_PlanningInDraft(countPlanInDraft)

            let planInDraftBusiness = 0;
            let planInDraftSales = 0;

            for (let i = 0; i < countPlanInDraft.length; i++) {
                if (countPlanInDraft[i].roleTeamType) {
                    if (countPlanInDraft[i].roleTeamType === TeamType.S) {
                        planInDraftSales = planInDraftSales + 1;

                    } else if (countPlanInDraft[i].roleTeamType === TeamType.B) {
                        planInDraftBusiness = planInDraftBusiness + 1;
                    }
                }

            }
            setCountPlanInDraftSale(planInDraftSales)
            setCountPlanInDraftBusiness(planInDraftBusiness)


            var countPlanApproved = finalParameterResult.filter((e) => e.targetApproved === "Yes")
            SetCount_Approved(countPlanApproved)

            let approveBusiness = 0;
            let approveSales = 0;

            for (let i = 0; i < countPlanApproved.length; i++) {
                if (countPlanApproved[i].roleTeamType) {
                    if (countPlanApproved[i].roleTeamType === TeamType.S) {
                        approveSales = approveSales + 1;
                    } else if (countPlanApproved[i].roleTeamType === TeamType.B) {
                        approveBusiness = approveBusiness + 1;
                    }
                }

            }
            setCountApprovedSale(approveSales)
            setCountApprovedBusiness(approveBusiness)

            var countPendingReview = finalParameterResult.filter((e) => e.pendingReview === "Yes")
            SetCount_PendingReview(countPendingReview)

            let pendingReviewBusiness = 0;
            let pendingReviewSales = 0;

            for (let i = 0; i < countPendingReview.length; i++) {
                if (countPendingReview[i].roleTeamType) {
                    if (countPendingReview[i].roleTeamType === TeamType.S) {
                        pendingReviewSales = pendingReviewSales + 1;
                    } else if (countPendingReview[i].roleTeamType === TeamType.B) {
                        pendingReviewBusiness = pendingReviewBusiness + 1;
                    }
                }

            }
            setCountPendingReviewSale(pendingReviewSales)
            setCountPendingReviewBusiness(pendingReviewBusiness)


            var countPlanSubmitted = finalParameterResult.filter((e) => e.planSubmitted === "Yes")
            SetCount_PlanSubmitted(countPlanSubmitted)

            let planSubmittedBusiness = 0;
            let planSubmittedSales = 0;

            for (let i = 0; i < countPlanSubmitted.length; i++) {
                if (countPlanSubmitted[i].roleTeamType) {
                    if (countPlanSubmitted[i].roleTeamType === TeamType.S) {
                        planSubmittedSales = planSubmittedSales + 1;
                    } else if (countPlanSubmitted[i].roleTeamType === TeamType.B) {
                        planSubmittedBusiness = planSubmittedBusiness + 1;
                    }
                }

            }
            setCountPlanSubmittedSale(planSubmittedSales)
            setCountPlanSubmittedBusiness(planSubmittedBusiness)


            SetEmployeeTargetList(finalParameterResult);
            SetCountEmployeeResponse(finalParameterResult.length)
        }
        else {
            SetEmployeeTargetList([]);
            SetCount_EligibleEmployee(0)
            SetCount_TargetAssigned(0)
            SetCount_PendingReview(0)
            setCountTargetAssignedSale(0)
            SetCountEligibleBusiness(0)
            SetCountEligibleSale(0)
            SetCount_PlanningInitiated(0)
            setPlanInitiatedSaleCount(0)
            setPlanInitiatedBusinessCount(0)
            setCountTargetAssignedBusiness(0)
            SetCount_YetToStart(0)
            setCountYetToStartSale(0)
            setCountYetToStartBusiness(0)
            SetCount_PlanningInDraft(0)
            setCountPlanInDraftSale(0)
            setCountPlanInDraftBusiness(0)
            SetCount_Approved(0)
            setCountApprovedSale(0)
            setCountApprovedBusiness(0)
            setCountPendingReviewSale(0)
            setCountPendingReviewBusiness(0)
            SetCount_PlanSubmitted(0)
            setCountPlanSubmittedSale(0)
            setCountPlanSubmittedBusiness(0)
        }
    }

    //onClick Employee
    const handleClickEmployeeDropdown = async (event) => {
        setEmployee(event)

        setIsEligibleEmployeeActive(true)
        setIsPlanFilterInitiatedActive(false)
        setTargetAssignedActive(false)
        setIsYetToStartActive(false)
        setIsPlanSubmittedActive(false)
        setIsPlanInDraftActive(false)
        setPendingReviewActive(false)
        setIsApprovedActive(false)

        const data = await planningFilter(event.value, region.value, sBU.value, financialYear.value)
        const finalParameterResult = Array.from(data)
        if (finalParameterResult.length > 0) {

            var countEligibleEmployeeBusiness = finalParameterResult.filter((e) => e.roleTeamType == TeamType.B)
            var countEligibleEmployeeSales = finalParameterResult.filter((e) => e.roleTeamType == TeamType.S)
            SetCount_EligibleEmployee(finalParameterResult)
            SetCountEligibleSale(countEligibleEmployeeSales.length)
            SetCountEligibleBusiness(countEligibleEmployeeBusiness.length)

            var countPlanningInitiated = finalParameterResult.filter((e) => e.isPlanInitiatedForEmployee == 1)
            SetCount_PlanningInitiated(countPlanningInitiated)

            let planningInitiatedBusiness = 0;
            let planningInitiatedSales = 0;

            for (let i = 0; i < countPlanningInitiated.length; i++) {
                if (countPlanningInitiated[i].roleTeamType) {
                    if (countPlanningInitiated[i].roleTeamType === TeamType.S) {
                        planningInitiatedSales = planningInitiatedSales + 1
                    } else if (countPlanningInitiated[i].roleTeamType === TeamType.B) {
                        planningInitiatedBusiness = planningInitiatedBusiness + 1
                    }
                }
            }
            setPlanInitiatedSaleCount(planningInitiatedSales)
            setPlanInitiatedBusinessCount(planningInitiatedBusiness)

            var countTargetAssigned = finalParameterResult.filter((e) => e.targetAssigned === "Yes")
            SetCount_TargetAssigned(countTargetAssigned)

            let targetAssignedBusiness = 0;
            let targetAssignedSales = 0;

            for (let i = 0; i < countTargetAssigned.length; i++) {
                if (countTargetAssigned[i].roleTeamType) {
                    if (countTargetAssigned[i].roleTeamType === TeamType.S) {
                        targetAssignedSales = targetAssignedSales + 1;
                    } else if (countTargetAssigned[i].roleTeamType === TeamType.B) {
                        targetAssignedBusiness = targetAssignedBusiness + 1;
                    }
                }

            }
            setCountTargetAssignedSale(targetAssignedSales)
            setCountTargetAssignedBusiness(targetAssignedBusiness)

            var countYetToStart = finalParameterResult.filter((e) => e.yetToStart === "Yes")
            SetCount_YetToStart(countYetToStart)

            let yetToStartBusiness = 0;
            let yetToStartSales = 0;

            for (let i = 0; i < countYetToStart.length; i++) {
                if (countYetToStart[i].roleTeamType) {
                    if (countYetToStart[i].roleTeamType === TeamType.S) {
                        yetToStartSales = yetToStartSales + 1;
                    } else if (countYetToStart[i].roleTeamType === TeamType.B) {
                        yetToStartBusiness = yetToStartBusiness + 1;
                    }
                }

            }
            setCountYetToStartSale(yetToStartSales)
            setCountYetToStartBusiness(yetToStartBusiness)

            var countPlanInDraft = finalParameterResult.filter((e) => e.planInDraft === "Yes")
            SetCount_PlanningInDraft(countPlanInDraft)

            let planInDraftBusiness = 0;
            let planInDraftSales = 0;

            for (let i = 0; i < countPlanInDraft.length; i++) {
                if (countPlanInDraft[i].roleTeamType) {
                    if (countPlanInDraft[i].roleTeamType === TeamType.S) {
                        planInDraftSales = planInDraftSales + 1;

                    } else if (countPlanInDraft[i].roleTeamType === TeamType.B) {
                        planInDraftBusiness = planInDraftBusiness + 1;
                    }
                }

            }
            setCountPlanInDraftSale(planInDraftSales)
            setCountPlanInDraftBusiness(planInDraftBusiness)


            var countPlanApproved = finalParameterResult.filter((e) => e.targetApproved === "Yes")
            SetCount_Approved(countPlanApproved)

            let approveBusiness = 0;
            let approveSales = 0;

            for (let i = 0; i < countPlanApproved.length; i++) {
                if (countPlanApproved[i].roleTeamType) {
                    if (countPlanApproved[i].roleTeamType === TeamType.S) {
                        approveSales = approveSales + 1;
                    } else if (countPlanApproved[i].roleTeamType === TeamType.B) {
                        approveBusiness = approveBusiness + 1;
                    }
                }

            }
            setCountApprovedSale(approveSales)
            setCountApprovedBusiness(approveBusiness)

            var countPendingReview = finalParameterResult.filter((e) => e.pendingReview === "Yes")
            SetCount_PendingReview(countPendingReview)

            let pendingReviewBusiness = 0;
            let pendingReviewSales = 0;

            for (let i = 0; i < countPendingReview.length; i++) {
                if (countPendingReview[i].roleTeamType) {
                    if (countPendingReview[i].roleTeamType === TeamType.S) {
                        pendingReviewSales = pendingReviewSales + 1;
                    } else if (countPendingReview[i].roleTeamType === TeamType.B) {
                        pendingReviewBusiness = pendingReviewBusiness + 1;
                    }
                }

            }
            setCountPendingReviewSale(pendingReviewSales)
            setCountPendingReviewBusiness(pendingReviewBusiness)


            var countPlanSubmitted = finalParameterResult.filter((e) => e.planSubmitted === "Yes")
            SetCount_PlanSubmitted(countPlanSubmitted)

            let planSubmittedBusiness = 0;
            let planSubmittedSales = 0;

            for (let i = 0; i < countPlanSubmitted.length; i++) {
                if (countPlanSubmitted[i].roleTeamType) {
                    if (countPlanSubmitted[i].roleTeamType === TeamType.S) {
                        planSubmittedSales = planSubmittedSales + 1;
                    } else if (countPlanSubmitted[i].roleTeamType === TeamType.B) {
                        planSubmittedBusiness = planSubmittedBusiness + 1;
                    }
                }

            }
            setCountPlanSubmittedSale(planSubmittedSales)
            setCountPlanSubmittedBusiness(planSubmittedBusiness)


            SetEmployeeTargetList(finalParameterResult);
            SetCountEmployeeResponse(finalParameterResult.length)
        }
        else {
            SetEmployeeTargetList([]);
            SetCount_EligibleEmployee(0)
            SetCount_TargetAssigned(0)
            SetCount_PendingReview(0)
            setCountTargetAssignedSale(0)
            SetCountEligibleBusiness(0)
            SetCountEligibleSale(0)
            SetCount_PlanningInitiated(0)
            setPlanInitiatedSaleCount(0)
            setPlanInitiatedBusinessCount(0)
            setCountTargetAssignedBusiness(0)
            SetCount_YetToStart(0)
            setCountYetToStartSale(0)
            setCountYetToStartBusiness(0)
            SetCount_PlanningInDraft(0)
            setCountPlanInDraftSale(0)
            setCountPlanInDraftBusiness(0)
            SetCount_Approved(0)
            setCountApprovedSale(0)
            setCountApprovedBusiness(0)
            setCountPendingReviewSale(0)
            setCountPendingReviewBusiness(0)
            SetCount_PlanSubmitted(0)
            setCountPlanSubmittedSale(0)
            setCountPlanSubmittedBusiness(0)
        }
    }


    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            <div className="dashboard-main-wrapper dashboard-new pl-16" x-data="{tab: 1}">
                <div className="p-5 pb-5">
                    <div className="sm:flex items-center space-x-8">
                        <div className="text-2xl font-semibold dark:text-white">Dashboard</div>
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link className="active">Business</Link></li>
                                {!checkEmployeeTeamType() && <li><Link to="/employee/dashboard/sales/planning">Sales</Link></li>}
                            </ul>
                        </div>
                    </div>
                    <div className="lg:flex justify-between my-6 items-center align-center">
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link className="active">Planning</Link></li>
                                <li><Link to="/employee/dashboard/business/evaluation">Evaluation</Link></li>
                                <li><Link to="/employee/dashboard/business/performance">Performance</Link></li>
                            </ul>
                        </div>
                        <div className="mt-5 lg:mt-0 flex justify-end gap-2 items-center">
                            <div className="">
                                <Select
                                    options={employeeList}
                                    value={employee}
                                    onChange={(e) => {
                                        handleClickEmployeeDropdown(e)
                                    }}
                                    maxMenuHeight={200}
                                    placeholder="Employee Name"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                            {/* <div className="">
                                <Select options='' maxMenuHeight={200} placeholder="Type"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div> */}
                            {/* <div className="">
                                <Select
                                    options={regionList}
                                    value={region}
                                    onChange={(e) => {
                                        handleClickRegionDropdown(e)
                                    }}
                                    maxMenuHeight={200} placeholder="Region"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div> */}

                            <div className="w-40">
                                <Select
                                    options={sBUList}
                                    value={sBU}
                                    onChange={(e) => {
                                        handleClickSBUDropdown(e)
                                    }}
                                    maxMenuHeight={200} placeholder="SBU"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                            <div className="w-40">
                                <Select
                                    options={financialYearList}
                                    value={financialYear}
                                    onChange={(e) => {
                                        handleClickFYDropdown(e)
                                    }}
                                    maxMenuHeight={200} placeholder="Select Year"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                        </div>
                    </div>


                    <div className='mt-10 flex-row grid 2xl:grid-cols-8 xl:grid-cols-8 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1  gap-3'>
                        <div className={isEligibleEmployeeForActive ? 'bg-[#d5e1ea] border purple border-[#2D638C] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(0, 1, 0, 0, 0, 0, 0, 0)
                        }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white '>Eligible Employee</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countEligibleBusiness === 0 && countEligibleSale === 0 ? 50 : Math.round((countEligibleBusiness / employeeDefaultTargetsList.length) * 100)} text={count_EligibleEmployee.length ? count_EligibleEmployee.length : 0} pathColor="#2D638C" trailColor="#9FBED6" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5  border-b-4  border-[#2D638C] rounded-b-md mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countEligibleBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countEligibleBusiness ? Math.round((countEligibleBusiness / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4  border-[#9FBED6] rounded-b-md mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countEligibleSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countEligibleSale ? Math.round((countEligibleSale / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={planFilterInitiatedForActive ? 'bg-[#fceadf] border purple border-[#F76208] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(1, 0, 0, 0, 0, 0, 0, 0)
                        }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white' >Planning Initiated</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countPlanInitiatedBusiness === 0 && countPlanInitiatedSale === 0 ? 50 : Math.round((countPlanInitiatedBusiness / employeeDefaultTargetsList.length) * 100)} text={count_PlanningInitiated.length ? count_PlanningInitiated.length : 0} pathColor="#F76208" trailColor="#F5DCCC" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#F76208] mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff] '>{countPlanInitiatedBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPlanInitiatedBusiness ? Math.round((countPlanInitiatedBusiness / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#F5DCCC] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPlanInitiatedSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPlanInitiatedSale ? Math.round((countPlanInitiatedSale / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={targetAssignedForActive ? 'bg-[#FCE8FF] border purple border-[#EAADF3] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(0, 0, 1, 0, 0, 0, 0, 0)
                        }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3'>Target Assigned</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countTargetAssignedBusiness === 0 && countTargetAssignedSale === 0 ? 50 : Math.round((countTargetAssignedBusiness / employeeDefaultTargetsList.length) * 100)} text={count_TargetAssigned.length ? count_TargetAssigned.length : 0} pathColor="#AB4ABA" trailColor="#EAADF3" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5  border-b-4 rounded-b-md border-[#AB4ABA] mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countTargetAssignedBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countTargetAssignedBusiness ? Math.round((countTargetAssignedBusiness / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#EAADF3] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countTargetAssignedSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countTargetAssignedSale ? Math.round((countTargetAssignedSale / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={isYetToStartForActive ? 'bg-[#ffe9ca] border purple border-[#FF9500] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(0, 0, 0, 1, 0, 0, 0, 0)
                        }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3'>Yet to Start</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countYetToStartBusiness === 0 && countYetToStartSale === 0 ? 50 : Math.round((countYetToStartBusiness / employeeDefaultTargetsList.length) * 100)} text={count_YetToStart.length ? count_YetToStart.length : 0} pathColor="#F3D8B2" trailColor="#FF9500" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#FF9500] mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countYetToStartBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countYetToStartBusiness ? Math.round((countYetToStartBusiness / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#F3D8B2] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countYetToStartSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countYetToStartSale ? Math.round((countYetToStartSale / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={planInDraftForActive ? 'bg-[#f3fffd] border purple border-[#146A60] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'}
                            onClick={e => {
                                getDetailedReport(0, 0, 0, 0, 1, 0, 0, 0)
                            }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white'>Plan - In Draft</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countPlanInDraftBusiness === 0 && countPlanInDraftSale === 0 ? 50 : Math.round((countPlanInDraftBusiness / employeeDefaultTargetsList.length) * 100)} text={count_PlanningInDraft.length ? count_PlanningInDraft.length : 0} pathColor="#146A60" trailColor="#CCEBE7" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md  border-[#146A60] mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPlanInDraftBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>
                                        {countPlanInDraftBusiness ? Math.round((countPlanInDraftBusiness / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#CCEBE7] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPlanInDraftSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPlanInDraftSale ? Math.round((countPlanInDraftSale / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={planSubmittedForActive ? 'bg-[#fff1f1] border purple border-[#FF0000] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(0, 0, 0, 0, 0, 1, 0, 0)
                        }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white '>Plan Submitted</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countPlanSubmittedBusiness === 0 && countPlanSubmittedSale === 0 ? 50 : Math.round((countPlanSubmittedBusiness / employeeDefaultTargetsList.length) * 100)} text={count_PlanSubmitted.length ? count_PlanSubmitted.length : 0} pathColor="#FF0000" trailColor="#FCDADA" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#FF0000] mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPlanSubmittedBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPlanSubmittedBusiness ? Math.round((countPlanSubmittedBusiness / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#FCDADA] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPlanSubmittedSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPlanSubmittedSale ? Math.round((countPlanSubmittedSale / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={pendingReviewForActive ? 'bg-[#fffae7] border purple border-[#FFC700] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(0, 0, 0, 0, 0, 0, 1, 0)
                        }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white '>Pending Review</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countPendingReviewBusiness === 0 && countPendingReviewSale === 0 ? 50 : Math.round((countPendingReviewBusiness / employeeDefaultTargetsList.length) * 100)} text={count_PendingReview.length ? count_PendingReview.length : 0} pathColor="#FFF2C5" trailColor="#FFC700" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#FFC700] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPendingReviewBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPendingReviewBusiness ? Math.round((countPendingReviewBusiness / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#FFF2C5] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPendingReviewSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPendingReviewSale ? Math.round((countPendingReviewSale / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={planApprovedForActive ? 'bg-[#eaeaea] border purple border-[#2C2C2C] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(0, 0, 0, 0, 0, 0, 0, 1)
                        }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3  dark:text-white'>Approved</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countApprovedBusiness === 0 && countApprovedSale === 0 ? 50 : Math.round((countApprovedBusiness / employeeDefaultTargetsList.length) * 100)} text={count_Approved.length ? count_Approved.length : 0} pathColor="#2C2C2C" trailColor="#CCCCCC" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#2C2C2C] mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countApprovedBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countApprovedBusiness ? Math.round((countApprovedBusiness / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#CCCCCC] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countApprovedSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countApprovedSale ? Math.round((countApprovedSale / employeeDefaultTargetsList.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white rounded-lg mt-10 p-3 px-3 dark:bg-[#2A2E32] mt-15  border border-[#E4E7EC] dark:border-[#344054]  mb-10 pb-10 ">
                        <div>
                            <div className=" rounded-lg mt-5 ">
                                <DataTable paginator value={employeeTargetsList} responsiveLayout="scroll"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={50} rowsPerPageOptions={[50, 100, 150]}
                                >
                                    <Column field="employeeId" header="Employee ID" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                    <Column field="name" header="Name" sortable filter filterPlaceholder="Search by Name"></Column>
                                    <Column field="role" header="Role" sortable filter filterPlaceholder="Search by Role"></Column>
                                    <Column field="vertical" header="Vertical" sortable filter filterPlaceholder="Search by Vertical"></Column>
                                    <Column field="sbu" header="SBU" sortable filter filterPlaceholder="Search by SBU"></Column>
                                    <Column field="reportingManager" header="Reporting Manager" sortable filter filterPlaceholder="Search by Reporting Manager"></Column>
                                    <Column field='targetAssigned' header="Target Assigned" filter filterPlaceholder="Search by Submitted Date" ></Column>
                                    <Column field='planSubmitted' header="Plan Submitted" filter filterPlaceholder="Search by Approved Date"  ></Column>
                                    <Column field='targetApproved' header="Target Approved" filter filterPlaceholder="Search by Target Approved" ></Column>
                                    {/* <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnRoles} ></Column> */}
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="selector"></div>


        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}