import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SubGTM, GTM } from '../../../../../models'
import capitalizeFirst from "../../../../../components/common/capitalizeFirst";
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../../../components/common/hr/topnav.component';
import HRLogin from "../../../../../setup/auth/hr.component";
import Select from 'react-select';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { ConvertResponseForSelect } from "../../../../../components/helper/commonfunctions";
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

export default function CreateGtm() {
    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { subgtmid } = useParams();
    const [isEditMode, setEditMode] = useState(false);
    let navigateTo = useNavigate();

    //Save Fields
    const [refCode, SetRefCode] = useState("");
    const [gtmList, setGtmList] = useState([]);
    const [gtmId, setGtmId] = useState("");
    const [subGtmName, setSubGtmName] = useState("");


    //Create Record
    const SubSubGTM = async () => {
        if (subGtmName === "") { toast.error("Please enter Sub GTM."); return false; }

        setPleaseWaitButton(true);
        if (isEditMode) {
            // const original = await DataStore.query(SubGTM, subgtmid);
            const original = await graphQLFindRecordById('getSubGTM', subgtmid);

            // await DataStore.save(
            //     SubGTM.copyOf(original, (updated) => {
            //         updated.name = capitalizeFirst(subGtmName).toString();
            //         updated.REF_CODE = refCode;
            //         updated.gtm_id = gtmId.value;
            //     })
            // );

            await API.graphql({
                query: mutations.updateSubGTM,
                variables: {
                    input: {
                        id: original.id,
                        name: capitalizeFirst(subGtmName).toString(),
                        REF_CODE: refCode,
                        gtm_id: gtmId.value,
                        _version: original._version,
                    }
                }
            });
            toast.success("Updated");
        } else {
            // await DataStore.save(new SubGTM({ 
            //     name: capitalizeFirst(subGtmName).toString(), 
            //     REF_CODE: capitalizeFirst(refCode), 
            //     gtm_id: gtmId.value 
            // }));

            await API.graphql(
                graphqlOperation(mutations.createSubGTM, {
                    input: {
                            name: capitalizeFirst(subGtmName).toString(), 
                            REF_CODE: capitalizeFirst(refCode), 
                            gtm_id: gtmId.value   
                    }
                })
            );
            toast.success('Added');
        }

        setPleaseWaitButton(false);
        navigateTo("/hr/settings/subgtm");
    };


    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
            //Edit existing record
            if (subgtmid != null) {
                setGtmId(subgtmid);
                // const original = await DataStore.query(SubGTM, subgtmid);
                const original = await graphQLFindRecordById('getSubGTM', subgtmid);

                if (original != null) {
                    // let parentGtm = await DataStore.query(GTM, original.gtm_id);
                    let parentGtm = await graphQLFindRecordById('getGTM', original.gtm_id);
                    setGtmId({ label: parentGtm.name, value: parentGtm.id })
                    setSubGtmName(original.name);
                    setEditMode(true);
                    SetRefCode(original.REF_CODE);
                }
                else {
                    toast.error('Invalid record.');
                    // navigateTo("/hr/settings/subgtm");
                }

            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        loadData()
        onLoad();
    }, []);

    const loadData = async () => {
        //Employee List
        // var gtmResponses = await DataStore.query(GTM, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });

        var gtmResponses = await graphQLGetAllData('listGTMS');
        gtmResponses = gtmResponses.sort((a,b)=>a.name.localeCompare(b.name));

        var myGTMArray = []; myGTMArray = ConvertResponseForSelect(gtmResponses);
        setGtmList(myGTMArray);
    }

    return (
        isAuthenticated ? (
            <div>
                <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full ">
                    <TopNavBar />
                    <LeftSideBar />

                    <div className="pl-16">
                        <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                            <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                                <div className="p-3 py-5 bg-white rounded-md shadow dark:bg-[#252423]">
                                    <div className="flex justify-center mt-3 mb-2">
                                        <h2 className="page-title">
                                            {isEditMode ? "Edit" : "Create"} Sub GTM
                                        </h2>
                                    </div>
                                    <div x-data="{tab: 1}"></div>
                                    <div className={"block w-full"}>
                                        <div className="px-4" x-show="tab === 1">
                                            <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Sub GTM
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *{" "}
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(subGtmName)}
                                                        onChange={(e) => setSubGtmName(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Enter Sub GTM name"
                                                    />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label className="text-[#344054] text-[14px] font-medium ">
                                                        GTM's
                                                    </label>

                                                    <Select
                                                        options={gtmList}
                                                        value={gtmId}
                                                        onChange={e => { setGtmId(e) }}
                                                        className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select"
                                                    />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]"
                                                    >
                                                        Ref_Code
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >

                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(refCode)}
                                                        onChange={(e) => SetRefCode(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                        placeholder="Ref Code"
                                                    />
                                                </div>

                                            </div>
                                            <div className="grow flex justify-center gap-3 mb-3">
                                                <Link
                                                    to="/hr/settings/subgtm"
                                                    className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:border-[#4A5056] dark:bg-[#4A5056] dark:text-white"
                                                >
                                                    Cancel
                                                </Link>
                                                <button
                                                    onClick={() => SubSubGTM()}
                                                    className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                                                >
                                                    {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <HRLogin sessionExpired={1} />
        )

    );
}