import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNav from '../../../../../components/common/hr/topnav.component';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataTableCustomStyles } from '../../../../../components/helper/dataTable.config';
import { Parameters } from '../../../../../models';
import HRLogin from '../../../../../setup/auth/hr.component';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import DeleteButton from '../../../../../components/common/buttons/DeleteButton';
import EditButton from '../../../../../components/common/buttons/EditButton';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../../graphql/mutations';
import * as queries from '../../../../../graphql/queries';

export default function ParametersList() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    let navigateTo = useNavigate();

    //list
    const [parametersList, setParametersList] = useState([]);
    const [parametersFilterList, setParametersFilterList] = useState([]);

    //onclick Edit

    //onclick Delete

    //*Table Column Name
    const DataTableColumns = [
        {
            name: 'Parameter',
            selector: row => row.name,
            sortable: true,
            style: {
                width: "20px",
            },
        },
        {
            name: 'Definition',
            selector: row => row.definition,
            sortable: true,
            style: {
                width: "55px",
            },
        },
        {
            name: 'Type',
            selector: row => row.base_type === "S" ? "Sale" : row.base_type === "B" ? "Business" : row.base_type === "S,B" ? "Sale,Business" : row.base_type === "B,S" ? "Sale,Business" : null,
            sortable: true,
            style: {
                width: "10px",
            },
        },
        {
            name: 'Unit',
            selector: row => row.type === "A" ? "Amount" : row.type === "P" ? "Percentage" : row.type === "D" ? "Days" : "Number",
            sortable: true,
            style: {
                width: "10px",
            },
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <div className="flex gap-8">
                        <Link onClick={(e) => handleEditClick(e, row.id)} ><EditButton /></Link>
                        {/* <Link onClick={(e) => handleDeleteClick(e, row.id)} ><DeleteButton /></Link> */}
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            style: {
                width: "5px",
            },
        }
    ];

    //onclick delete
    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        //  const objDeleteRole = await DataStore.query(Parameters, id);

        const objDeleteRole = await graphQLFindRecordById('getParameters', id);

        alertify.confirm('Confirmation', 'Do you want to delete this Parameter?', async function () {
            // //Delete Existing InterpreterRequest for this language
            // DataStore.delete(Parameters, (c) => c.id("eq", id))
            // //Delete language
            // DataStore.delete(objDeleteRole);

            await API.graphql({
                query: mutations.deleteParameters,
                variables: {
                    input: {
                        id: objDeleteRole.id,
                        _version: objDeleteRole._version,
                    }
                }
            });

            BindList();
            toast.success("Deleted");
        }
            , function () { });

    };

    //onclick edit
    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/hr/settings/createparameter/' + id);
    };

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //BindList
    async function BindList() {
        // var ParametersResponses = await DataStore.query(Parameters, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        var ParametersResponses = await graphQLGetAllData('listParameters');
        ParametersResponses = ParametersResponses.sort((a,b)=> a.name.localeCompare(b.name));

        setParametersList(ParametersResponses)
        setParametersFilterList(ParametersResponses)
    }

    //Search Parameters
    const searchParameters = (searchedText) => {
        if (searchedText.length > 1) {
            let data = [...parametersFilterList]
            let filteredData = data.filter(e =>
                e.name.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.type.toLowerCase().includes(searchedText.toLowerCase())
            )
            setParametersList(filteredData)
        } else {
            let data = [...parametersFilterList]
            setParametersList(data)
        }
    }

    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    return (
        isAuthenticated ? (
            <div className="pt-16">
                <TopNav />
                <LeftSideBar />

                <div className="dashboard-main-wrapper pl-20 pr-4 mt-3 ">
                    <div className="flex flex-1 flex-wrap p-5 pb-4 px-0 top-action-wrapper">
                        <div className="md:flex xs:w-full sm:w-full md:w-auto flex-row align-center">
                            <div className="md:flex block md:col-span-2 mb-4">
                            </div>
                            <div className="md:flex block mb-4">
                                <h2 className="page-title">Parameters</h2>
                            </div>
                        </div>

                        <div className="md:flex flex-1 flex-row xs:w-full sm:w-full md:w-auto align-center justify-end md:ml-auto gap-3 right-side">
                            <div className="relative">
                                <input onChange={(e) => searchParameters(e.target.value)} type="text" id="required-search" className="rounded-md border border-[#D0D5DD] focus:border-transparent w-full py-2.5 pl-3 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#403E3C] dark:text-[#FFFFFF] dark:border-[#403E3C]" placeholder="Search..." />
                            </div>
                            <div className="md:flex block mb-4 gap-4">
                                <Link to="/hr/settings" className="flex py-2 px-5 bg-[#FFF] hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-black transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]">
                                    Back
                                </Link>
                                <Link to="/hr/settings/createparameter" className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF]">
                                    Create
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div>
                        <div className={"block w-auto pt-5"}>
                            <DataTable
                                columns={DataTableColumns}
                                data={parametersList}
                                customStyles={DataTableCustomStyles}
                                pagination
                                highlightOnHover
                                paginationPerPage={10}
                            />
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <HRLogin sessionExpired={1} />
        )

    )
}



