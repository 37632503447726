import { DataStore, SortDirection } from '@aws-amplify/datastore';
import moment from 'moment';
import { Carousel } from 'primereact/carousel';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { json, Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';
import BoxPlanning from '../../../../assets/images/dashboard_box_planning.png';
import AnnualSaleIcon from '../../../../assets/svg/annual_sale_icon.svg';
import quarterIcon from '../../../../assets/svg/quarter_icon.svg';
import LeftNav from '../../../../components/common/employee/leftnav.component';
import TopNav from '../../../../components/common/employee/topnav.component';
import { GetArrayItemIndexFromKeyValue, GetArrayNextItemFromKeyValue, GetArrayPreviousItemFromKeyValue, GetSingleValueFromResponse, checkDuplicates, ObjectToArray } from "../../../../components/helper/commonfunctions";
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { Employee, Plan, FinancialYear, Parameters, Roles, TargetDetails, Targets, RelationshipDetails, Relationship, SBU, Region } from '../../../../models';
import EmpLogin from "../../../../setup/auth/emp.component";
import popupHeaderImg from "../../../../assets/images/popup_header_img.png";
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { commaSeparated, ShowInMillion } from '../../../../components/helper/maths';
import { PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES } from '../../../../components/helper/enum';
import { sendNotification } from '../../../../components/helper/notification';
import { NOTIFICATIONS_TYPES, EMAIL_SUBJECTS } from '../../../../components/helper/enum'
import { findEmployeesWithCheckParameterOne } from '../../../../components/helper/GetFilterOfEmployees';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getFinancialYear, getTargetDetails, listEmployees, listRelationshipDetails, listRelationships, listTargetDetails, listTargets } from '../../../../graphql/queries';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../src/components/helper/graphQLFunctions'
import * as mutations from '../../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';


export default function AssignTarget_AnnualTarget() {
    let navigateTo = useNavigate();

    let { financialYearId, parameterId } = useParams();
    var loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId');
    const [parametersCarousel, setParametersCarousel] = useState([]);

    const [employeeList, setEmployeeList] = useState([]);

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            console.log("error", e)
            userHasAuthenticated(false);
        }
    }


    //Plan Table State
    const [planData, setPlanData] = useState([]);
    const [renderData, setRenderData] = useState(false);
    const [isPlanUpdated, setPlanUpdated] = useState(false);
    const [updatedVersion, setUpdatedVersion] = useState(0);

    //Target Value Calculation
    //----------------------
    const percentageEditor = (options) => {
        return <input type="number" className="text-[16px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] py-2 px-4 bg-white 
        text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500  placeholder:text-[#667085]"
            disabled={isDisabled ? true : false}
            value={options.value}
            onChange={(e) =>
                options.editorCallback(e.target.value)}
        />;
    }

    const annualTargetEditor = (options) => {
        return <input className="text-[16px] rounded-lg flex-1 appearance-none border border-[#D0D5DD] py-2 px-4 bg-white 
        text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500  placeholder:text-[#667085] "
            disabled={isDisabled ? true : false}
            value={options.value}
            onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    //On Load Functions
    const [currentTargetId, setCurrentTargetId] = useState("");
    const [currentTargetDetailId, setCurrentTargetDetailId] = useState("");
    const [prevParameterId, setPrevParameterId] = useState("");
    const [nextParameterId, setNextParameterId] = useState("");

    const [carouselActivePageNo, setCarouselActivePageNo] = useState(0);

    const [financialYear, setFinancialYear] = useState("");
    const [parameterAnnualTarget, setParameterAnnualTarget] = useState(0);
    const [markAsIncompleteParameters, setMarkAsIncompleteParameters] = useState(0);
    const [isShowConfirmationPopUp, setIsShowConfirmationPopUp] = useState(0);
    const [selectedParameterLength, setSelectedParameterLength] = useState(0);


    const [parameterMatchPer, setParameterMatchPer] = useState(0);
    const [parameterQ1Target, setParameterQ1Target] = useState(''); const [parameterQ1Percentage, setParameterQ1Percentage] = useState(0);
    const [parameterQ2Target, setParameterQ2Target] = useState(''); const [parameterQ2Percentage, setParameterQ2Percentage] = useState(0);
    const [parameterQ3Target, setParameterQ3Target] = useState(0); const [parameterQ3Percentage, setParameterQ3Percentage] = useState(0);
    const [parameterQ4Target, setParameterQ4Target] = useState(''); const [parameterQ4Percentage, setParameterQ4Percentage] = useState(0);

    const [parameterType, setParameterType] = useState("");
    const [requestType, setRequestType] = useState("");
    const [currentRequestParameterList, setCurrentRequestParameterList] = useState([]);

    const [parameterName, setParameterName] = useState("");
    const [parameterNameList, setParameterNameList] = useState([]);
    const [urlParameterId, setUrlParameterId] = useState("");



    const parametersCarouselResponsiveOptions = [
        {
            breakpoint: '1600px',
            numVisible: 3,
            numScroll: 1,
        },
        {
            breakpoint: '1280px',
            numVisible: 4,
            numScroll: 1,
        },
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 1,
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1,
        }

    ];

    // CardData 
    const [cardData, setCardData] = useState([]);

    const CalculateDimension = () => {
        //Get Target Total
        var totalAddedTarget = 0, q1Total = 0, q2Total = 0, q3Total = 0, q4Total = 0;
        var q1_avg = 0, q2_avg = 0, q3_avg = 0, q4_avg = 0, total_avg = 0;
        cardData.map(currentItem => {
            if (parseFloat(currentItem.targetValue) > 0) { totalAddedTarget = totalAddedTarget + parseFloat(currentItem.targetValue); }
            if (parseFloat(currentItem.Q1TargetValue) > 0) { q1Total = q1Total + parseFloat(currentItem.Q1TargetValue); }
            if (parseFloat(currentItem.Q2TargetValue) > 0) { q2Total = q2Total + parseFloat(currentItem.Q2TargetValue); }
            if (parseFloat(currentItem.Q3TargetValue) > 0) { q3Total = q3Total + parseFloat(currentItem.Q3TargetValue); }
            if (parseFloat(currentItem.Q4TargetValue) > 0) { q4Total = q4Total + parseFloat(currentItem.Q4TargetValue); }
            return currentItem;
        });
        if (parameterType === PARAMETERS_TYPES.Days || parameterType === PARAMETERS_TYPES.Percentage) {
            total_avg = totalAddedTarget / cardData.length;
            q1_avg = q1Total / cardData.length;
            q2_avg = q2Total / cardData.length;
            q3_avg = q3Total / cardData.length;
            q4_avg = q4Total / cardData.length;

            if (parseFloat(q1_avg.toFixed(2)) > 0) { setParameterQ1Target(parseFloat(q1_avg.toFixed(2))); } else { setParameterQ1Target(0); }
            if (parseFloat(q2_avg.toFixed(2)) > 0) { setParameterQ2Target(parseFloat(q2_avg.toFixed(2))); } else { setParameterQ2Target(0); }
            if (parseFloat(q3_avg.toFixed(2)) > 0) { setParameterQ3Target(parseFloat(q3_avg.toFixed(2))); } else { setParameterQ3Target(0); }
            if (parseFloat(q4_avg.toFixed(2)) > 0) { setParameterQ4Target(parseFloat(q4_avg.toFixed(2))); } else { setParameterQ4Target(0); }
        }
        else {
            setParameterQ1Target(q1Total.toFixed(2));
            setParameterQ2Target(q2Total.toFixed(2));
            setParameterQ3Target(q3Total.toFixed(2));
            setParameterQ4Target(q4Total.toFixed(2));
        }

        //Calculate Percentage
        let totalMatchPer = 0;
        let q1Per = (q1Total * 100) / parameterAnnualTarget; if (q1Per > 0) { q1Per = parseFloat(q1Per).toFixed(2); setParameterQ1Percentage(q1Per) };
        let q2Per = (q2Total * 100) / parameterAnnualTarget; if (q2Per > 0) { q2Per = parseFloat(q2Per).toFixed(2); setParameterQ2Percentage(q2Per) };
        let q3Per = (q3Total * 100) / parameterAnnualTarget; if (q3Per > 0) { q3Per = parseFloat(q3Per).toFixed(2); setParameterQ3Percentage(q3Per) };
        let q4Per = (q4Total * 100) / parameterAnnualTarget; if (q4Per > 0) { q4Per = parseFloat(q4Per).toFixed(2); setParameterQ4Percentage(q4Per) };

        if (totalAddedTarget > 0) {
            if (parameterType === PARAMETERS_TYPES.Days || parameterType === PARAMETERS_TYPES.Percentage) {
                totalMatchPer = (totalAddedTarget * 100) / parameterAnnualTarget;
                totalMatchPer = parseFloat(totalMatchPer).toFixed(2) / cardData.length
            }
            else {
                totalMatchPer = (totalAddedTarget * 100) / parameterAnnualTarget;
                totalMatchPer = parseFloat(totalMatchPer).toFixed(2)
            }
        }
        setParameterMatchPer(totalMatchPer);
    }


    //Parameters Carousel
    let parametersCarouselIndex = 0;
    const parametersCarouselTemplate = (currentRequestParameterList) => {
        parametersCarouselIndex++;

        return (
            <div className="product-item">
                <div className="product-item-content relative">
                    {
                        <div key={currentRequestParameterList['parameterId']}>

                            <div className={`annual_target_box justify-between items-center rounded-lg p-3 content-center flex ${currentRequestParameterList['parameterId'] === parameterId ? "activegreen shadow-md" : "bg-[#fff] dark:bg-[#212529] dark:border-[#2A2E32]"}`} >
                                <div className='flex-col'>
                                    <div className='flex items-center gap-3'>
                                        {/* <div className={`rounded-lg p-2 border border-dashed ${currentRequestParameterList['parameterId'] === parameterId ? "bg-white border-[#4FB155]" : "bg-[#EDF7EE] border-[#4FB155]"}`} >
                                            <img src={AnnualSaleIcon} alt=""></img>
                                        </div> */}
                                        <div>
                                            <h6 className={`${currentRequestParameterList['parameterId'] === parameterId ? "text-white text-[18px] text-left" : "text-[#667085] text-[15px] font-light text-left"}`} >{currentRequestParameterList['parameterName']}</h6>
                                            <h2 className={`${currentRequestParameterList['parameterId'] === parameterId ? "text-white text-[22px] font-medium text-left" : "text-[#101828] text-[22px] font-medium dark:text-white text-left"}`} >

                                                {currentRequestParameterList['parameterUnit'] === 'A' ? PARAMETERS_TYPES_NAMES[currentRequestParameterList['parameterUnit']] : ""}{currentRequestParameterList['targetValue']}{currentRequestParameterList['parameterUnit'] !== 'A' ? PARAMETERS_TYPES_NAMES[currentRequestParameterList['parameterUnit']] : ""}
                                            </h2>
                                        </div>
                                    </div>

                                </div>

                                <div className="">
                                    {
                                        currentRequestParameterList['parameterId'] === parameterId ?
                                            <div className="annual_target_img_green"></div>
                                            : <div className="annual_target_img_green_regular"></div>

                                    }

                                    <div
                                        className="absolute top-3 right-3 font-medium text-center text-[#fff]">{currentRequestParameterList['isPlanMandatory'] === 1 ? <i className='icon s_icon'></i> : <i className='icon t_icon'></i>}</div>
                                    {/* <div className="absolute top-0 right-2 rounded-full bg-[#333] h-6 w-6 font-medium text-center text-[#fff]">{currentRequestParameterList['isPlanMandatory'] === 1 ? "S" : "T"}</div> */}
                                </div>
                            </div>
                        </div>

                    }

                </div>
            </div>
        );
    }


    //
    const [relationData, setRelationData] = useState([]);

    let getCollectionDetailsRelation = async () => {
        const myArray = []
        // var relationshipRes = await DataStore.query(Relationship, (c) => c.financial_year_id.eq(financialYearId));

        var relationshipRes = await graphQLGetAllData('listRelationships', [{ financial_year_id: { eq: financialYearId } }]);

        // 

        for (var i = 0; i < relationshipRes.length; i++) {
            // var checkRelationshipDetails = await DataStore.query(RelationshipDetails, (c) => c.and(c => [
            //     c.relationship_id.eq(relationshipRes[i].id),
            //     c.parameter_id.eq(parameterId)
            // ]));

            var checkRelationshipDetails = await graphQLGetAllData('listRelationshipDetails', [
                { relationship_id: { eq: relationshipRes[i].id } },
                { parameter_id: { eq: parameterId } }
            ]);


            if (checkRelationshipDetails.length > 0) {
                let data = {
                    "role_id": relationshipRes[i].role_id,
                    "isParameterAvailable": "1",
                    "parameterId": parameterId
                }
                myArray.push(data);
            }

            if (i === relationshipRes.length - 1) {
                setRelationData(myArray)
                LoadPageData(myArray)

            }
        }
    }

    //recursive
    // NOT USING --- WILL DELETE LATER (FOR BACKUP)
    function toTree(data, pid) {
        return data.reduce((update, e) => {
            if (e.parentEmployeeId == pid) {
                const obj = { ...e };
                const children = toTree(data, e.id);
                let findData = relationData.find(checkData => checkData.role_id === e.role_id)
                if (findData) { obj.checkParameters = "1"; }
                if (children.length > 0) { obj.children = children; }
                update.push(obj)
            }
            return update;
        }, [])
    }

    //recursive
    // NOT USING --- WILL DELETE LATER (FOR BACKUP)

    function createTree(employeeId, employees) {
        const root = employees.find(employee => employee.id === employeeId);
        const stack = [root];
        const tree = [];

        while (stack.length) {
            const node = stack.pop();
            const children = employees.filter(employee => employee.parentEmployeeId === node.id);
            let findData = relationData.find(checkData => checkData.role_id === node.role_id)
            if (findData) { node.checkParameters = "1"; }
            node.children = children;
            tree.push(node);
            stack.push(...children);
        }
        return tree;
    }


    // ============================================================================== 
    // USING --- 
    function buildTree(data, id) {
        const rootNode = data.find(node => node.id === id);

        if (!rootNode) {
            return [];
        }

        function buildSubTree(node) {
            const children = data.filter(child => child.parentEmployeeId === node.id);

            let findData = relationData.find(checkData => checkData.role_id === node.role_id)
            if (children.length === 0) {
                if (findData) {
                    return { ...node, checkParameters: "1" };
                }
                else {
                    return { ...node };
                }
            }

            const subtree = children.map(child => buildSubTree(child));
            if (findData) {
                return { ...node, checkParameters: "1", children: subtree, };
            } else {
                return { ...node, children: subtree };
            }
        }

        return [buildSubTree(rootNode)];
    }
    // ==============================================================================

    // ============= 
    const [planMandatory, setPlanMandatory] = useState(0);
    async function LoadPageData() {
        //Financial Year
        // const currentFinancialYear = await DataStore.query(FinancialYear, financialYearId);
        const currentFinancialYear = await graphQLFindRecordById('getFinancialYear', financialYearId);

        if (!currentFinancialYear) {
            toast.error('Invalid record.');
            return;
        }
        setFinancialYear(currentFinancialYear.name)

        //Parameter
        // const currentParameters = await DataStore.query(Parameters, parameterId)
        const currentParameters = await graphQLFindRecordById('getParameters', parameterId);
        ;
        if (!currentParameters) {
            toast.error('Invalid record.');
            return;
        }
        setParameterType(currentParameters.type);

        //---------------------
        //Target
        // var currentTarget = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.financial_year_id.eq(financialYearId),
        //     c.employee_id.eq(loggedEmployeeId),
        //     c.report_manager_employee_id.notContains(loggedEmployeeId)
        // ]));
        var currentTarget = await graphQLGetAllData('listTargets', [
            { employee_id: { eq: loggedEmployeeId } },
            { report_manager_employee_id: { ne: loggedEmployeeId } },
            { financial_year_id: { eq: financialYearId } }
        ]);
        //

        var myTargetId = GetSingleValueFromResponse(currentTarget, 'id')
        setCurrentTargetId(myTargetId);

        if (!currentTarget) {
            toast.error('Invalid record.');
            return;
        }

        // Old code commented: Pratap
        // var currentTargetId = GetSingleValueFromResponse(currentTarget, 'id')
        var currentTargetId = currentTarget.length > 0 ? currentTarget[0].id : '';

        //Target Details
        // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
        //     c.target_id.eq(currentTargetId),
        //     //c.parameter_id.eq(parameterId)
        // ]));

        var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: currentTargetId } }]);
        console.log("test123currentTargetDetails", currentTargetDetails)

        var _tempArray = [];
        let curretnTargetValue = 0;
        // currentTargetDetails.map(async (currentItem) => {

        let counter = 0;
        for (let i = 0; i < currentTargetDetails.length; i++) {
            // const loopParameters = await DataStore.query(Parameters, currentItem.parameter_id);
            const loopParameters = await graphQLFindRecordById('getParameters', currentTargetDetails[i].parameter_id);
            console.log("test123Parameter", parameterId)
            if (currentTargetDetails[i].parameter_id === parameterId) {
                curretnTargetValue = currentTargetDetails[i].target_value;
                setParameterAnnualTarget(currentTargetDetails[i].target_value);
            }
            _tempArray.push({ 'parameterId': currentTargetDetails[i].parameter_id, 'parameterName': loopParameters.name, 'targetValue': currentTargetDetails[i].target_value })

            counter++;
        }

        // })
        setCurrentRequestParameterList(_tempArray);

        //---------------------
        await GetEmployeeList(curretnTargetValue)
        await BindList();
    }

    async function GetEmployeeList(curretnTargetValue) {

        //NEED TO IMPLEMENT - ONLY SHOW - IF HR ASSIGN TARGET - FALSE
        var employeeList = []
        let empData = []

        // var employeeDetails = await DataStore.query(Employee);
        var employeeDetails = await graphQLGetAllData('listEmployees')

        let result = []
        let chartData = []
        for (let i = 0; i < employeeDetails.length; i++) {
            let data = {
                "id": employeeDetails[i].id,
                "employeeId": employeeDetails[i].employeeId,
                "name": employeeDetails[i].firstName + " " + employeeDetails[i].lastName,
                "role_id": employeeDetails[i].role_id,
                "param_sbu_id": employeeDetails[i].param_sbu_id,
                "parentEmployeeId": employeeDetails[i].parentEmployeeId,
                "children": [],
            }
            chartData.push(data)

            if (i == employeeDetails.length - 1) {
                result = buildTree(chartData, loggedEmployeeId);
            }
        }


        /// ------------------- GET HIERARCHY based on parameter ------------------- 
        // var getEmployeesOfLoggedEmployee = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(loggedEmployeeId));

        var getEmployeesOfLoggedEmployee = await graphQLGetAllData('listEmployees', [{ parentEmployeeId: { eq: loggedEmployeeId } }]);

        // var getLoggedEmployee = await DataStore.query(Employee, (c) => c.id.eq(loggedEmployeeId));
        var getLoggedEmployee = await graphQLFindRecordById('getEmployee', loggedEmployeeId);

        const combinedArray = getEmployeesOfLoggedEmployee.concat(getLoggedEmployee);

        // const employeesWithCheckParameterOne = findEmployeesWithCheckParameterOne(result);
        const employeesWithCheckParameterOne = await findEmployeesWithCheckParameterOne(result);

        const filteredArray = combinedArray.filter(item => { return employeesWithCheckParameterOne.some(filterItem => filterItem.id === item.id); });
        // const resultWithFilterData = filteredArray;

        const resultWithFilterData = combinedArray;

        /// ---------------------------------------------------------------------------- 
        console.log("resultWithFilterData", resultWithFilterData);
        if (resultWithFilterData.length) {
            for (var i = 0; i < resultWithFilterData.length; i++) {
                //get sbu
                let sbu = "-"
                if (resultWithFilterData[i].param_sbu_id !== "") {
                    // let getEmployeeSBU = await DataStore.query(SBU, resultWithFilterData[i].param_sbu_id)
                    let getEmployeeSBU = await graphQLFindRecordById('getSBU', resultWithFilterData[i].param_sbu_id);

                    sbu = getEmployeeSBU ? getEmployeeSBU.name : '-';
                }

                // var currentTarget = await DataStore.query(Targets, (c) => c.and(c => [
                //     c.financial_year_id.eq(financialYearId),
                //     c.employee_id.eq(resultWithFilterData[i].id),
                //     c.report_manager_employee_id.contains(loggedEmployeeId),
                // ]));

                var currentTarget = await graphQLGetAllData('listTargets', [
                    { employee_id: { eq: resultWithFilterData[i].id } },
                    // Not required this condition: Pratap 29th Oct 2023
                    { report_manager_employee_id: { eq: loggedEmployeeId } },
                    { financial_year_id: { eq: financialYearId } }
                ]);
                console.log("resultWithFilterData[i].id", resultWithFilterData[i].id)
                console.log("resultWithFilterData[i].loggedEmployeeId", loggedEmployeeId)
                console.log("currentTarget", currentTarget)

                // This is old code it is not woking properly - Pratap
                // var myTargetId = GetSingleValueFromResponse(currentTarget, 'id')
                var myTargetId = currentTarget.length ? currentTarget[0].id : ''


                var isAssignToEmployee = GetSingleValueFromResponse(currentTarget, 'isAssignedToEmployee')
                if (isAssignToEmployee) { setIsDisabled(true) }

                var currentTargetDetails = []
                let targetStatus = "Open"
                if (myTargetId) {
                    // currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
                    //     c.target_id.eq(myTargetId),
                    //     c.parameter_id.eq(parameterId),
                    // ]));

                    currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                        { target_id: { eq: myTargetId } },
                        { parameter_id: { eq: parameterId } }
                    ]);

                    console.log("myTargetId", myTargetId) //b08106bf-cebd-4c19-98d6-87937bc76422
                    console.log("parameterId", parameterId) // 
                    console.log("currentTargetDetails", currentTargetDetails)

                    if (currentTargetDetails.length > 0) {
                        if (currentTargetDetails[0].is_accepted_by_employee) {
                            targetStatus = "Accepted"
                        } else if (currentTargetDetails[0].is_revision_by_employee) {
                            targetStatus = "Requested for revision"
                        }
                    }
                }

                let percentageCal = 0

                if (currentTargetDetails.length == 1 && curretnTargetValue) {
                    percentageCal = (currentTargetDetails[0].target_value / curretnTargetValue) * 100
                }


                // var roleDetails = await DataStore.query(Roles, resultWithFilterData[i].role_id);

                let data = {
                    "currentTargetDetailId": currentTargetDetails.length == 1 ? currentTargetDetails[0].id : "",
                    "currentTargetId": currentTargetDetails.length == 1 ? currentTargetDetails[0].target_id : "",
                    "employeeId": resultWithFilterData[i].id,
                    "id": resultWithFilterData[i].employeeId,
                    "name": resultWithFilterData[i].firstName + " " + resultWithFilterData[i].lastName,
                    "role": "ABC",
                    "roleId": resultWithFilterData[i].role_id,
                    "vertical": "Distribution",
                    "sbu": sbu,
                    "percentage": currentTargetDetails.length == 1 ? percentageCal.toFixed(2) : "",
                    "annualtarget": currentTargetDetails.length == 1 && currentTargetDetails[0].target_value !== "NaN" ? parseFloat(currentTargetDetails[0].target_value).toFixed(2) : "",
                    "targetStatus": targetStatus
                }
                empData.push(data)

                if (i === resultWithFilterData.length - 1) {
                    var selectedDataLength = empData.filter(a => a.annualtarget !== "");
                    setSelectedParameterLength(selectedDataLength.length)

                    if (parameterType === PARAMETERS_TYPES.Days) {
                        let checkFilledTarget = empData.filter(data => data.annualtarget !== "")
                        var sumOfAnnualTarget = sumFromArray('annualtarget', empData);
                        sumOfAnnualTarget = sumOfAnnualTarget / checkFilledTarget.length
                        if (sumOfAnnualTarget) { setTotalAnnualTarget(commaSeparated(sumOfAnnualTarget.toFixed(2))) }
                    }
                    else if (parameterType === PARAMETERS_TYPES.Percentage) {
                        let checkFilledTarget = empData.filter(data => data.annualtarget !== "")
                        var sumOfAnnualTarget = sumFromArray('annualtarget', empData);
                        sumOfAnnualTarget = sumOfAnnualTarget / checkFilledTarget.length
                        if (sumOfAnnualTarget) { setTotalAnnualTarget(commaSeparated(sumOfAnnualTarget.toFixed(2))) }
                    }
                    else {
                        var sumOfAnnualTarget = sumFromArray('annualtarget', empData);
                        if (sumOfAnnualTarget) { setTotalAnnualTarget(commaSeparated(sumOfAnnualTarget.toFixed(2))) }
                    }

                    //percentage Calculator
                    let filterData = empData.filter(a => parseInt(a.annualtarget) > 0)
                    //percentage sum
                    let sumOfPercentage = sumFromArray("percentage", filterData)
                    setTotalOfPercentage(Math.round(sumOfPercentage))

                    console.log("empData", empData)
                    setPlanData(empData)
                    setRenderData(true)
                }
            }
        }

    }

    function findEmployeeWithCheckParameter(data) {
        // Check if current employee has checkParameters = 1
        if (data.checkParameters === "1") {
            // Return current employee and their children
            return data;
        }

        // Recursively search children for employee with checkParameters = 1
        for (let i = 0; i < data.children.length; i++) {
            const child = data.children[i];
            const result = findEmployeeWithCheckParameter(child);
            if (result) {
                return result;
            }
        }

        // No employee with checkParameters = 1 found in this subtree
        return null;
    }



    const [currentParameter, setCurrentParameter] = useState("")
    async function BindList() {
        let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
        var loggedEmployeeRoleId = reactLocalStorage.get('loggedEmployeeRoleId');

        // var currentTarget = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.financial_year_id.eq(financialYearId),
        //     c.employee_id.eq(loggedEmployeeId),
        //     c.report_manager_employee_id.notContains(loggedEmployeeId),
        //     //c.isAssignedToEmployee.eq(true)
        // ]));

        var currentTarget = await graphQLGetAllData('listTargets', [
            { employee_id: { eq: loggedEmployeeId } },
            { report_manager_employee_id: { ne: loggedEmployeeId } },
            { financial_year_id: { eq: financialYearId } }
        ]);


        // Old code commented Pratap
        // var myTargetId = GetSingleValueFromResponse(currentTarget, 'id')
        var myTargetId = currentTarget.length > 0 ? currentTarget[0].id : '';

        //Get Relationship
        // var currentRelationship = await DataStore.query(Relationship, (c) => c.and(c => [
        //     c.financial_year_id.eq(financialYearId),
        //     c.role_id.eq(loggedEmployeeRoleId)
        // ]));

        var currentRelationship = await graphQLGetAllData('listRelationships', [
            { financial_year_id: { eq: financialYearId } },
            { role_id: { eq: loggedEmployeeRoleId } }
        ]);

        var relationship_id = GetSingleValueFromResponse(currentRelationship, 'id');


        //Target Details
        // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [c.target_id.eq(myTargetId), c.is_approve.eq(true)]));

        // Pratap
        // var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: myTargetId } }, { is_approve: { eq: true } }]);
        var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: myTargetId } }]);

        console.log("currentTargetDetails12345", currentTargetDetails)
        var _tempArray = []; let _tempInCompleteParamArray = [];
        currentTargetDetails.map(async (currentItem, currentIndex, currentArray) => {

            // const loopParameters = await DataStore.query(Parameters, currentItem.parameter_id);
            const loopParameters = await graphQLFindRecordById('getParameters', currentItem.parameter_id);

            console.log("Pratap 1", currentItem.parameter_id);
            console.log("Pratap 2", parameterId);

            if (currentItem.parameter_id === parameterId) {
                console.log("Pratap test success")
                //Now we are going to check is this parameter belongs to this role and financial year
                //------------------------------------
                var isPlanMandatory = 0;
                if (relationship_id !== '') {
                    // var currentRelationshipDetails = await DataStore.query(RelationshipDetails, (c) => c.and(c => [
                    //     c.relationship_id.eq(relationship_id),
                    //     c.parameter_id.eq(currentItem.parameter_id)
                    // ]));

                    var currentRelationshipDetails = await graphQLGetAllData('listRelationshipDetails', [
                        { relationship_id: { eq: relationship_id } },
                        { parameter_id: { eq: currentItem.parameter_id } }
                    ]);



                    if (currentRelationshipDetails.length > 0) {
                        isPlanMandatory = 1;
                        setPlanMandatory(1)
                    }
                }
                //------------------------------------


                console.log("loopParameters", loopParameters)
                setCurrentTargetDetailId(currentItem.id);
                setCurrentParameter(loopParameters.name)
                // var currentTargetDetails = await DataStore.query(TargetDetails, currentItem.id);
                var currentTargetDetails = await graphQLFindRecordById('getTargetDetails', currentItem.id);

                if (currentTargetDetails.isMarkAsComplete_AssignTarget !== null) {
                    // if (currentTargetDetails.isMarkAsComplete_AssignTarget) {
                    //     setIsDisabled(true)
                    // }
                    setIsMarkedAsCompleted(currentTargetDetails.isMarkAsComplete_AssignTarget)
                }

                // await DataStore.query(Plan, (c) => c.target_detail_id.eq(currentItem.id)).then((existingPlanData) => {
                //     var existingFormatData = [];
                //     existingPlanData.map(async currentPlanItem => {
                //         existingFormatData.push({
                //             "id": currentPlanItem.id, "sbuId": currentPlanItem.sbu_id, "regionId": currentPlanItem.region_id, "countryId": currentPlanItem.country_id,
                //             "brandId": currentPlanItem.brand_id, "productGroupId": currentPlanItem.product_group_id,
                //             "customerGroupId": currentPlanItem.customer_group_id, "customerId": currentPlanItem.customer_id, "targetValue": currentPlanItem.target_value,
                //             "Q1TargetValue": currentPlanItem.q1_target_value, "Q2TargetValue": currentPlanItem.q2_target_value, "Q3TargetValue": currentPlanItem.q3_target_value,
                //             "Q4TargetValue": currentPlanItem.q4_target_value, "disEqually": 0
                //         });
                //     });
                //     setCardData(existingFormatData)
                //     setPlanUpdated(true);
                // })

                var existingPlanData = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: currentItem.id } }]);

                var existingFormatData = [];
                existingPlanData.map(async currentPlanItem => {
                    existingFormatData.push({
                        "id": currentPlanItem.id, "sbuId": currentPlanItem.sbu_id, "regionId": currentPlanItem.region_id, "countryId": currentPlanItem.country_id,
                        "brandId": currentPlanItem.brand_id, "productGroupId": currentPlanItem.product_group_id,
                        "customerGroupId": currentPlanItem.customer_group_id, "customerId": currentPlanItem.customer_id, "targetValue": currentPlanItem.target_value,
                        "Q1TargetValue": currentPlanItem.q1_target_value, "Q2TargetValue": currentPlanItem.q2_target_value, "Q3TargetValue": currentPlanItem.q3_target_value,
                        "Q4TargetValue": currentPlanItem.q4_target_value, "disEqually": 0
                    });
                });

                setCardData(existingFormatData)
                setPlanUpdated(true);
            }


            //Now we are going to check is this parameter belongs to this role and financial year
            //------------------------------------
            var isPlanMandatory = 0;
            if (relationship_id !== '') {
                // var currentRelationshipDetails = await DataStore.query(RelationshipDetails, (c) => c.and(c => [
                //     c.relationship_id.eq(relationship_id),
                //     c.parameter_id.eq(currentItem.parameter_id)
                // ]));

                var currentRelationshipDetails = await graphQLGetAllData('listRelationshipDetails', [
                    { relationship_id: { eq: relationship_id } },
                    { parameter_id: { eq: currentItem.parameter_id } }
                ]);

                if (currentRelationshipDetails.length > 0) {
                    isPlanMandatory = 1;
                }
            }
            //------------------------------------

            //Incomplete Param
            console.log("currentItem.isMarkAsComplete", currentItem.isMarkAsComplete)
            if (currentItem.isMarkAsComplete_AssignTarget == null || currentItem.isMarkAsComplete_AssignTarget === false) {
                _tempInCompleteParamArray.push({ 'parameterName': loopParameters.name, 'status': 'Open' })
            }

            _tempArray.push({
                'parameterIndex': currentIndex, 'parameterId': currentItem.parameter_id, 'parameterName': loopParameters.name,
                'targetValue': loopParameters.type === PARAMETERS_TYPES.Amount ? ShowInMillion(currentItem.target_value) : currentItem.target_value,
                'parameterUnit': loopParameters.type, 'isPlanMandatory': isPlanMandatory
            })

            //Check for last Record
            if (_tempArray.length === currentArray.length) {
                _tempArray.sort(function (a, b) {
                    return a.parameterName.toLowerCase().localeCompare(b.parameterName.toLowerCase());
                });
                setParametersCarousel(_tempArray);

                _tempInCompleteParamArray.sort(function (a, b) {
                    return a.parameterName.toLowerCase().localeCompare(b.parameterName.toLowerCase());
                });
                setMarkAsIncompleteParameters(_tempInCompleteParamArray);

                //Set Previous 
                var currentPreviousParameter = GetArrayPreviousItemFromKeyValue(_tempArray, "parameterId", parameterId)
                if (currentPreviousParameter['parameterId'] != null) {
                    setPrevParameterId(currentPreviousParameter['parameterId'])
                }

                //Set Next 
                var currentNextParameter = GetArrayNextItemFromKeyValue(_tempArray, "parameterId", parameterId)
                if (currentNextParameter['parameterId'] != null) {
                    setNextParameterId(currentNextParameter['parameterId'])
                }

                //Set Selected Carousel Page
                var currentParameterArrayItem = GetArrayItemIndexFromKeyValue(_tempArray, "parameterId", parameterId);
                if (currentParameterArrayItem > 2) {
                    //var activePageNo = Math.floor((_tempArray.length + currentParameterArrayItem) / 3);
                    setCarouselActivePageNo(currentParameterArrayItem - 2);
                }
            }

            return currentItem;
        })
    }



    var sumFromArray = (propertyName, array) => {
        let sum = 0;
        array.forEach(item => {
            sum += Number(item[propertyName]) ?? 0;
        });
        return sum;
    };

    const showStatus = (rowData) => {
        return (
            rowData.targetStatus === "Open" ?
                <span className="bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 "> <div className='w-2 h-2 bg-[#1B55AF] rounded-full'></div>{rowData.targetStatus}</span>
                : rowData.targetStatus === "Accepted" ?
                    <span className="bg-[#E5F3EC] text-[#4FB155] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 "> <div className='w-2 h-2 bg-[#4FB155] rounded-full'></div>{rowData.targetStatus}</span>
                    :
                    rowData.targetStatus === "Requested for revision" ?
                        <span className="bg-[#FFF2E5] text-[#FF7F01] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 "> <div className='w-2 h-2 bg-[#FF7F01] rounded-full'></div>{rowData.targetStatus}</span>
                        : ""
        )
    }


    // ------------ Set total --------------
    const [totalOfPercentage, setTotalOfPercentage] = useState("");
    const [totalOfAnnualTarget, setTotalAnnualTarget] = useState("");
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <input
                    defaultValue={rowData.percentage}
                    disabled={isDisabled ? true : false}
                    //value={rowData.percentage}
                    className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD]  py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                />
            </React.Fragment>
        );
    }

    const actionAnnualTargetTemplate = (rowData) => {
        return (
            <React.Fragment>
                <input
                    defaultValue={rowData.annualtarget}
                    disabled={isDisabled ? true : false}
                    //value={rowData.annualtarget}
                    className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD]  py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                />
            </React.Fragment>
        );
    }



    // Apply to all
    const applyToAll = (e) => {
        if (e.target.checked) {
            if (parameterType === PARAMETERS_TYPES.Number || parameterType === PARAMETERS_TYPES.Amount) {
                let distributeEquallyTarget = parameterAnnualTarget / planData.length
                let distributeEquallyPercentage = 100 / planData.length

                setTotalOfPercentage(Math.round(distributeEquallyPercentage * planData.length).toFixed(2))
                setTotalAnnualTarget(distributeEquallyTarget * planData.length)

                const _tempData = planData.map((obj, i) => ({ ...obj, annualtarget: parseFloat(distributeEquallyTarget).toFixed(2), percentage: parseFloat(distributeEquallyPercentage).toFixed(2) }));
                setPlanData(_tempData)
            } else {
                let distributeEquallyTarget = parameterAnnualTarget
                setTotalAnnualTarget(parseFloat(distributeEquallyTarget).toFixed(2))
                const _tempData = planData.map((obj, i) => ({ ...obj, annualtarget: parseFloat(distributeEquallyTarget).toFixed(2) }));
                setPlanData(_tempData)
            }
        }
        else {
            setTotalOfPercentage("")
            setTotalAnnualTarget("")
            const _tempData = planData.map((obj, i) => ({ ...obj, annualtarget: "", percentage: "" }));
            setPlanData(_tempData)
            setSelectedEmployees([])
        }
    }

    // Equally distribute
    const equallyDistributeSelected = (e) => {

        if (selectedEmployees.length > 0) {
            if (e.target.checked) {
                let _planData = [...planData];
                _planData = _planData.map((obj, i) => ({ ...obj, annualtarget: "", percentage: "" }));

                if (parameterType === PARAMETERS_TYPES.Number || parameterType === PARAMETERS_TYPES.Amount) {
                    let distributeEquallyTarget = parameterAnnualTarget / selectedEmployees.length
                    let distributeEquallyPercentage = 100 / selectedEmployees.length

                    selectedEmployees.forEach((selectedEmp) => {
                        let rowIndex = _planData.findIndex((e) => e.employeeId === selectedEmp.employeeId)
                        _planData[rowIndex].annualtarget = distributeEquallyTarget.toFixed(2);
                        _planData[rowIndex].percentage = distributeEquallyPercentage.toFixed(2);
                    })

                    setTotalOfPercentage(Math.round(distributeEquallyPercentage * selectedEmployees.length).toFixed(2))
                    setTotalAnnualTarget(distributeEquallyTarget * selectedEmployees.length)

                } else {
                    let distributeEquallyTarget = parameterAnnualTarget
                    setTotalAnnualTarget(parseFloat(distributeEquallyTarget).toFixed(2))
                    selectedEmployees.forEach((selectedEmp) => {
                        let rowIndex = _planData.findIndex((e) => e.employeeId === selectedEmp.employeeId)
                        _planData[rowIndex].annualtarget = parseFloat(distributeEquallyTarget).toFixed(2);
                    })
                }
                setPlanData(_planData)
            } else {
                setTotalOfPercentage("")
                setTotalAnnualTarget("")
                const _tempData = planData.map((obj, i) => ({ ...obj, annualtarget: "", percentage: "" }));
                setPlanData(_tempData)
                setSelectedEmployees([])
            }
        } else {
            toast.error("Select employees")
        }
    }


    //----------------------
    const onCellEditComplete = (e) => {
        let { newRowData, field, rowIndex, originalEvent: event } = e;
        if (field === "percentage") {
            let _planData = [...planData];
            //let setAnnualTarget = parameterAnnualTarget / parseInt(newRowData.percentage)
            if (parseInt(newRowData.percentage) > 0 && parseInt(newRowData.percentage) < 101) {
                let setAnnualTarget = Math.round((parameterAnnualTarget * parseFloat(newRowData.percentage).toFixed(2)) / 100)
                newRowData.percentage = parseFloat(newRowData.percentage).toFixed(2)
                newRowData.annualtarget = parseFloat(setAnnualTarget).toFixed(2)
                _planData[rowIndex] = newRowData;

                var sumOfPercentage = sumFromArray('percentage', _planData);
                setTotalOfPercentage(Math.round(sumOfPercentage).toFixed(2))
                var sumOfAnnualTarget = sumFromArray('annualtarget', _planData);
                setTotalAnnualTarget(sumOfAnnualTarget.toFixed(2))

                setPlanData(_planData);
            } else {
                toast.error("Percentage should be below 100.")
                newRowData.percentage = ""
                newRowData.annualtarget = ""
                _planData[rowIndex] = newRowData;

                setPlanData(_planData);
            }
        }
        else if (field === "annualtarget") {
            var sumOfAnnualTarget = 0;
            var sumOfPercentage = 0;
            let _planData = [...planData];

            if (parameterType === PARAMETERS_TYPES.Number || parameterType === PARAMETERS_TYPES.Amount) {
                _planData[rowIndex] = newRowData;
                sumOfAnnualTarget = sumFromArray('annualtarget', _planData);

                //Percentage 
                let setPercentage = (newRowData.annualtarget / parameterAnnualTarget) * 100
                newRowData.percentage = setPercentage.toFixed(2)
                //let setAnnualTarget = Math.round((parameterAnnualTarget ) / 100)

            } else {
                _planData[rowIndex] = newRowData;

                let checkFilledTarget = _planData.filter(data => data.annualtarget !== "")
                var sumOfAnnualTarget = sumFromArray('annualtarget', _planData);
                sumOfAnnualTarget = sumOfAnnualTarget / checkFilledTarget.length

                // var sumOfPercentage = sumFromArray('percentage', _planData);
                // setTotalOfPercentage(Math.round(sumOfPercentage))
            }

            var sumOfPercentage = sumFromArray('percentage', _planData);
            setTotalOfPercentage(Math.round(sumOfPercentage).toFixed(2))
            setTotalAnnualTarget(sumOfAnnualTarget.toFixed(2))

            setPlanData(_planData);
        }
    }

    //----------------------

    useEffect(() => {
        onLoad();
        getCollectionDetailsRelation()
        // LoadPageData();
    }, []);

    useEffect(() => {
        if (isPlanUpdated === true) {
            CalculateDimension()
        }
    }, [isPlanUpdated]);



    const [isMarkedAsCompleted, setIsMarkedAsCompleted] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    //Save/ Assign Annual Target to employee
    const save_Assign_AnnualTargets = async () => {
        console.log("planData", planData);
        if (parseInt(totalOfAnnualTarget) !== parseInt(parameterAnnualTarget)) {
            toast.error('Invalid target.');
        }
        else {
            let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')
            // var result = planData.filter(a => parseInt(a.annualtarget) > 0);
            // var result = planData
            // planData.forEach(async (emp) => {

            for (let k = 0; k < planData.length; k++) {
                // var currentTarget = await DataStore.query(Targets, (c) => c.and(c => [
                //     c.financial_year_id.eq(financialYearId),
                //     c.employee_id.eq(emp.employeeId),
                //     c.report_manager_employee_id.eq(loggedEmployeeId)
                // ]));
                var currentTarget = await graphQLGetAllData('listTargets', [
                    { employee_id: { eq: planData[k].employeeId } },
                    // Getting error Pratap
                    { report_manager_employee_id: { eq: loggedEmployeeId } },
                    { financial_year_id: { eq: financialYearId } }
                ]);

                console.log("currentTargetListTarget", currentTarget)
                // target found for the user
                if (currentTarget.length > 0) {
                    try {
                        // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
                        //     c.target_id.eq(currentTarget[0].id),
                        //     c.parameter_id.eq(parameterId)
                        // ]));

                        var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                            { target_id: { eq: currentTarget[0].id } },
                            { parameter_id: { eq: parameterId } }
                        ]);


                        console.log("fordelete", currentTargetDetails)
                        if (currentTargetDetails.length > 0) {
                            // await DataStore.delete(TargetDetails, currentTargetDetails[0].id).then(async (response) => {
                            //     let employeeAnnualTarget = parseFloat(emp.annualtarget);
                            //     if (employeeAnnualTarget > 0) {
                            //         await DataStore.save(
                            //             new TargetDetails({
                            //                 "target_id": currentTarget[0].id,
                            //                 "parameter_id": parameterId,
                            //                 "target_value": employeeAnnualTarget.toFixed(2).toString(),
                            //                 "target_assigned_date": moment().format(),
                            //             })
                            //         ).then((response) => { })
                            //     }
                            // })

                            // unwanted extra code removed
                            // let original = await graphQLFindRecordById('getTargetDetails', currentTargetDetails[0].id);

                            let returnData = await API.graphql({
                                query: mutations.deleteTargetDetails,
                                variables: {
                                    input: {
                                        id: currentTargetDetails[0].id,
                                        _version: currentTargetDetails[0]._version,
                                    }
                                }
                            });

                            console.log("mutations - deleteTargetDetails: ", returnData)

                        }

                        // Unwanted code
                        // else {
                        //     let employeeAnnualTarget = parseFloat(emp.annualtarget);
                        //     if (employeeAnnualTarget > 0) {
                        //         // await DataStore.save(
                        //         //     new TargetDetails({
                        //         //         "target_id": currentTarget[0].id,
                        //         //         "parameter_id": parameterId,
                        //         //         "target_value": employeeAnnualTarget.toFixed(2).toString(),
                        //         //         "target_assigned_date": moment().format(),
                        //         //     })
                        //         // ).then((response) => { })
                        //         await API.graphql(
                        //             graphqlOperation(mutations.createTargetDetails, {
                        //                 input: {
                        //                     target_id: currentTarget[0].id,
                        //                     parameter_id: parameterId,
                        //                     target_value: employeeAnnualTarget.toFixed(2).toString(),
                        //                     target_assigned_date: moment().format()
                        //                 }
                        //             })
                        //         );
                        //     }
                        // }

                        let employeeAnnualTarget = parseFloat(planData[k].annualtarget);
                        if (employeeAnnualTarget > 0) {
                            let createdRecord = await API.graphql(
                                graphqlOperation(mutations.createTargetDetails, {
                                    input: {
                                        target_id: currentTarget[0].id,
                                        parameter_id: parameterId,
                                        target_value: employeeAnnualTarget.toFixed(2).toString(),
                                        target_assigned_date: moment().format()
                                    }
                                })
                            );
                            console.log("mutations - createTargetDetails: ", createdRecord)
                        }

                    } catch (e) {
                        console.log("error", e)
                    }
                }
                // if target not found for the user then creating target
                else {
                    // var newTargetId = await DataStore.save(
                    //     new Targets({
                    //         "financial_year_id": financialYearId,
                    //         "employee_id": emp.employeeId,
                    //         "report_manager_employee_id": loggedEmployeeId,
                    //         "role_id": emp.roleId,
                    //     })
                    // );
                    var newTargetId = await API.graphql(
                        graphqlOperation(mutations.createTargets, {
                            input: {
                                financial_year_id: financialYearId,
                                employee_id: planData[k].employeeId,
                                report_manager_employee_id: loggedEmployeeId,
                                role_id: planData[k].roleId
                            }
                        })
                    );

                    console.log("mutations - createTargets: ", newTargetId)

                    newTargetId = newTargetId.data.createTargets;
                    if (newTargetId) {
                        try {
                            // await DataStore.save(
                            //     new TargetDetails({
                            //         "target_id": newTargetId.id,
                            //         "parameter_id": parameterId,
                            //         "target_value": parseFloat(emp.annualtarget).toFixed(2).toString(),
                            //         "target_assigned_date": moment().format(),
                            //     })
                            // );
                            let createdRecordNew = await API.graphql(
                                graphqlOperation(mutations.createTargetDetails, {
                                    input: {
                                        target_id: newTargetId.id,
                                        parameter_id: parameterId,
                                        target_value: parseFloat(planData[k].annualtarget).toFixed(2).toString(),
                                        target_assigned_date: moment().format(),
                                    }
                                })
                            );


                            console.log("mutations - createTargetDetails: ", createdRecordNew)

                        } catch (e) {
                            console.log("error", e)
                        }
                    }
                }
            }
            // })

            //isMarkedAsCompleted status
            // const original = await DataStore.query(TargetDetails, currentTargetDetailId);
            const original = await graphQLFindRecordById('getTargetDetails', currentTargetDetailId);

            // await DataStore.save(
            //     TargetDetails.copyOf(original, (updated) => {
            //         updated.isMarkAsComplete_AssignTarget = isMarkedAsCompleted
            //     })
            // )
            console.log("test - original", original)
            console.log("test - currentTargetDetailId", currentTargetDetailId)
            console.log("test - original.id", original.id)
            console.log("test - original._version", original._version)
            console.log("test - isMarkedAsCompleted", isMarkedAsCompleted)
            try {
                let updatedData = await API.graphql({
                    query: mutations.updateTargetDetails,
                    variables: {
                        input: {
                            id: original.id,
                            isMarkAsComplete_AssignTarget: isMarkedAsCompleted,
                            _version: original._version,
                        }
                    }
                });
                console.log("mutations - updateTargetDetails: ", updatedData)

                // setUpdatedVersion(updatedData.data.updateTargetDetails._version)
            }
            catch (e) {
                console.log("error", e)

                // await API.graphql({
                //     query: mutations.updateTargetDetails,
                //     variables: {
                //         input: {
                //             id: original.id,
                //             isMarkAsComplete_AssignTarget: isMarkedAsCompleted,
                //             _version: updatedVersion,
                //         }
                //     }
                // });
            }

            toast.success("Saved.")

            // This getting value blank because of late update in GraphQL: Pratap
            // LoadPageData();
        }
    }


    const ShowNextParameter = async () => {
        if (nextParameterId === "") {
            console.log("markAsIncompleteParameters.length", markAsIncompleteParameters.length);
            if (markAsIncompleteParameters.length > 0) //Save All Targets
            {
                setIsShowConfirmationPopUp(1); //Show Popup..
            } else {
                if (isDisabled) {
                    var navigateURL = "/employee/planning/team/assigntarget/";
                    navigateTo(navigateURL);
                    navigateTo(0);
                } else {
                    alertify.confirm('Confirmation', 'Do you want to submit?', async function () {
                        // var currentTarget = await DataStore.query(Targets, (c) => c.and(c => [
                        //     c.financial_year_id.eq(financialYearId),
                        //     c.report_manager_employee_id.eq(loggedEmployeeId)
                        // ]));
                        var currentTarget = await graphQLGetAllData('listTargets', [
                            { financial_year_id: { eq: financialYearId } },
                            { report_manager_employee_id: { eq: loggedEmployeeId } }
                        ]);


                        // currentTarget.forEach(async (e, i) => {
                        //     //var currentEmployee = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(e.employee_id))
                        //     // var originalData = await DataStore.query(Targets, e.id)
                        //     var originalData = await graphQLFindRecordById('getTargets',e.id);

                        //     await DataStore.save(
                        //         Targets.copyOf(originalData, (updated) => {
                        //             updated.isAddedByHRAdmin = false;
                        //             updated.isAssignedToEmployee = true
                        //         })
                        //     ).then(async () => {
                        //         if (i === currentTarget.length - 1) {
                        //             //send Notifications
                        //             var employeeResponse = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(loggedEmployeeId));
                        //             for (let i = 0; i < employeeResponse.length; i++) {
                        //                 let requestTypeName = NOTIFICATIONS_TYPES.AssignTarget
                        //                 let emailSubject = EMAIL_SUBJECTS.ChangeRequestApprove
                        //                 // let notificationMsg = "The Annual Target for " + financialYear + " from " + reactLocalStorage.get('loggedEmployeeFirstName') + ' ' + reactLocalStorage.get('loggedEmployeeLastName');
                        //                 let notificationMsg = "Your Annual Target for " + financialYear + " have been assigned."
                        //                 await sendNotification(loggedEmployeeId, employeeResponse[i].id, requestTypeName, notificationMsg, emailSubject)
                        //                 if (i === employeeResponse.length - 1) {
                        //                     //  var navigateURL = "/employee/planning/team/assigntarget/";
                        //                     navigateTo("/employee/planning/team/assigntarget/");
                        //                     //  navigateTo(0);
                        //                 }
                        //             }
                        //         }
                        //     })
                        // })


                        for (var i = 0; i < currentTarget.length; i++) {
                            //var currentEmployee = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(e.employee_id))
                            // var originalData = await DataStore.query(Targets, e.id)
                            var originalData = await graphQLFindRecordById('getTargets', currentTarget[i].id);

                            let updatedRecordNew = await API.graphql({
                                query: mutations.updateTargets,
                                variables: {
                                    input: {
                                        id: originalData.id,
                                        isAddedByHRAdmin: false,
                                        isAssignedToEmployee: true,
                                        _version: originalData._version,
                                    }
                                }
                            });

                            console.log("mutations - updateTargets: ", updatedRecordNew)

                            //send Notifications
                            // var employeeResponse = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(loggedEmployeeId));
                            var employeeResponse = await graphQLGetAllData('listEmployees', [{ parentEmployeeId: { eq: loggedEmployeeId } }]);

                            for (let i = 0; i < employeeResponse.length; i++) {
                                let requestTypeName = NOTIFICATIONS_TYPES.AssignTarget
                                let emailSubject = EMAIL_SUBJECTS.ChangeRequestApprove
                                // let notificationMsg = "The Annual Target for " + financialYear + " from " + reactLocalStorage.get('loggedEmployeeFirstName') + ' ' + reactLocalStorage.get('loggedEmployeeLastName');
                                let notificationMsg = "Your Annual Target for " + financialYear + " have been assigned."
                                await sendNotification(loggedEmployeeId, employeeResponse[i].id, requestTypeName, notificationMsg, emailSubject)
                                if (i === employeeResponse.length - 1) {
                                    //  var navigateURL = "/employee/planning/team/assigntarget/";
                                    navigateTo("/employee/planning/team/assigntarget/");
                                    //  navigateTo(0);
                                }
                            }
                        }
                        toast.success('Saved')
                    }
                        , function () { });
                }
            }
        } else {
            var navigateURL = "/employee/planning/team/assigntarget/annualtarget/" + financialYearId + "/" + nextParameterId;
            navigateTo(navigateURL);
            navigateTo(0);
        }
    }

    const ShowPreviousParameter = () => {
        if (prevParameterId !== '') {
            var navigateURL = "/employee/planning/team/assigntarget/annualtarget/" + financialYearId + "/" + prevParameterId;
            navigateTo(navigateURL);
            navigateTo(0);
        }
    }

    const openLink = (rowData) => {
        return (
            <button
                onClick={() => openIncompleteParameter(rowData)}
            >
                <span className="bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 justify-center "> <div className='w-2 h-2 bg-[#1B55AF] rounded-full'></div>{"Open"}</span>
            </button>
        )
    }

    const openIncompleteParameter = (data) => {
        var navigateURL = data.openLink;
        navigateTo(navigateURL);
        navigateTo(0);
    }

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNav />
            <LeftNav />

            <div className="target-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="flex justify-between">
                        <div className="sm:flex items-center space-x-4">
                            <Link to="/employee/planning/team/assigntarget" className="text-[#344054] text-[14px] dark:text-white py-1.5 px-3 rounded-md border border-[#D0D5DD]"><i className="icon arrow_left_double mr-6 "></i>Back</Link>
                            <div className="text-2xl font-semibold dark:text-white"> Annual Target</div>
                            <div className="text-xs border text-[#667085] border-[#667085] rounded p-1">FY: {financialYear}</div>
                        </div>
                        <div className="flex">
                            <div className='mr-2'>
                                {!isMarkedAsCompleted ?
                                    <span className="bg-[#eef2f8] text-[#1B55AF] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 "> <div className='w-2 h-2 bg-[#1B55AF] rounded-full'></div>Open</span>
                                    :
                                    <span className="bg-[#E5F3EC] text-[#4FB155] px-3 py-2 rounded-full text-[12px] flex items-center gap-1 "> <div className='w-2 h-2 bg-[#4FB155] rounded-full'></div>Completed</span>
                                }
                            </div>
                            <div className='bg-[#EEF8F4] px-4 lg:mt-0 flex items-center gap-4 font-semibold text-[18px]'>
                                {currentParameter}
                            </div>
                        </div>

                    </div>

                    <div style={{ display: "none" }} className="col-span-9 mt-1 silder_managetarget">
                        <div className="carousel-demo">
                            <div className="card">
                                <Carousel value={parametersCarousel} page={carouselActivePageNo} numVisible={3} numScroll={1} responsiveOptions={parametersCarouselResponsiveOptions}
                                    itemTemplate={parametersCarouselTemplate} />
                            </div>
                        </div>
                    </div>

                    <div className="bg-white rounded-lg mt-2 border border-[#E4E7EC] p-4 px-3 dark:bg-[#2A2E32] dark:border-[#2A2E32]">
                        <div className="grid grid-cols-8 lg:grid-cols-10 2xl:grid-cols-10 flex flex-1 content-center items-center gap-3">
                            <div className="col-span-10">
                                <div className="grid grid-cols-5 lg:grid-cols-5 2xl:grid-cols-5 gap-3 h-auto">
                                    <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                                        <div className='flex justify-between items-center gap-3'>
                                            <div className="bg-white rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                <img src={AnnualSaleIcon} alt=""></img>
                                            </div>
                                            <div>
                                                <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]mb-2">{requestType === 'R' ? "Request Annual Target" : "Annual Target"}</h6>
                                                <div className='flex gap-3'>

                                                    <h2 className="text-[#101828] xl:text-[16px] 2xl:text-[16px] lg:text-[15px] font-medium dark:text-[#FFFFFF]">

                                                        {parameterType === 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                        {commaSeparated(parseFloat(parameterAnnualTarget).toFixed(2))}
                                                        {parameterType !== 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}

                                                    </h2>
                                                    {
                                                        parameterType === 'A' ?
                                                            parameterMatchPer < 100 ? <div className='bg-[#FEE4E2] py-1 px-2 rounded-md text-[#FF4537]'>{parameterMatchPer}%</div> : <div className='bg-[#029046] py-1 px-2 rounded-md text-[#FFF] xl:text-[12px] 2xl:text-[14px] md:text-[12px]'>{parameterMatchPer}%</div>
                                                            : <div className='bg-[#fff] py-1 px-2 rounded-md text-[#029046] xl:text-[12px] 2xl:text-[14px] md:text-[12px]'>N/A</div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                                        <div className='flex justify-between items-center gap-3'>
                                            <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                <img src={quarterIcon} alt=""></img>
                                            </div>
                                            <div>
                                                <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">Quarter 1</h6>
                                                <div className='flex gap-3'>
                                                    <h2 className="text-[#101828] xl:text-[16px] 2xl:text-[16px] lg:text-[15px] font-medium dark:text-[#FFFFFF]">
                                                        {parameterType === 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                        {commaSeparated(parameterQ1Target)}
                                                        {parameterType !== 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                    </h2>
                                                    <div className='bg-[#fff] py-1 px-2 rounded-md text-[#029046] xl:text-[12px] 2xl:text-[14px] md:text-[12px]'>{parameterType === 'A' ? parameterQ1Percentage : ""}{parameterType === 'A' ? "%" : "N/A"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                                        <div className='flex justify-between items-center gap-3'>
                                            <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                <img src={quarterIcon} alt=""></img>
                                            </div>
                                            <div>
                                                <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">Quarter 2</h6>
                                                <div className='flex gap-3'>
                                                    <h2 className="text-[#101828] xl:text-[16px] 2xl:text-[16px] lg:text-[15px]font-medium dark:text-[#FFFFFF] ">
                                                        {parameterType === 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                        {commaSeparated(parameterQ2Target)}
                                                        {parameterType !== 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                    </h2>
                                                    <div className='bg-[#fff] py-1 px-2 rounded-md text-[#029046] xl:text-[12px] 2xl:text-[14px] md:text-[12px]'>{parameterType === 'A' ? parameterQ2Percentage : ""}{parameterType === 'A' ? "%" : "N/A"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex dark:bg-[#212529] dark:border-[#2A2E32]">
                                        <div className='flex justify-between items-center gap-3'>
                                            <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                <img src={quarterIcon} alt=""></img>
                                            </div>
                                            <div>
                                                <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">Quarter 3</h6>
                                                <div className='flex gap-3'>
                                                    <h2 className="text-[#101828] xl:text-[16px] 2xl:text-[16px] lg:text-[15px] font-medium dark:text-[#FFFFFF]">
                                                        {parameterType === 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                        {commaSeparated(parameterQ3Target)}
                                                        {parameterType !== 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                    </h2>
                                                    <div className='bg-[#fff] py-1 px-2 rounded-md text-[#029046] xl:text-[12px] 2xl:text-[14px] md:text-[12px]'>{parameterType === 'A' ? parameterQ3Percentage : ""}{parameterType === 'A' ? "%" : "N/A"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-[#EEF8F4] rounded-lg p-3 content-center flex  dark:bg-[#212529] dark:border-[#2A2E32]">
                                        <div className='flex justify-between items-center gap-3'>
                                            <div className="bg-[#EDF7EE] rounded-lg p-2 border border-dashed border-[#4FB155]">
                                                <img src={quarterIcon} alt=""></img>
                                            </div>
                                            <div>
                                                <h6 className="text-[#667085] text-[15px] font-light dark:text-[#FFFFFF]">Quarter 4</h6>
                                                <div className='flex gap-3'>
                                                    <h2 className="text-[#101828] xl:text-[16px] 2xl:text-[16px] lg:text-[15px] font-medium dark:text-[#FFFFFF]">
                                                        {parameterType === 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                        {commaSeparated(parameterQ4Target)}
                                                        {parameterType !== 'A' ? PARAMETERS_TYPES_NAMES[parameterType] : ""}
                                                    </h2>
                                                    <div className='bg-[#fff] py-1 px-2 rounded-md text-[#029046] xl:text-[12px] 2xl:text-[14px] md:text-[12px]'>{parameterType === 'A' ? parameterQ4Percentage : ""}{parameterType === 'A' ? "%" : "N/A"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        !renderData &&
                        <div className='text-center py-8' ><ProgressSpinner /></div>
                    }

                    {
                        planData.length > 0 && renderData &&
                        <div className="bg-white rounded-lg mt-5 p-4 px-3 dark:bg-[#2A2E32] border border-[#E4E7EC] dark:border-[#2A2E32]">
                            <div className='flex  items-center justify-between'>
                                <div className='flex'>
                                    <h2 className='text-[16px] text-[#101828] font-meduim dark:text-white'>Team Members</h2>
                                    <h2 className='bg-[#EEF8F3] text-[12px] text-[#01813F] font-meduim dark:text-white ml-5 px-2 rounded-full py-1 dark:bg-[#212529]'>{planData.length <= 1 ? planData.length + " " + "Employee" : planData.length + " " + "Employees"}</h2>
                                </div>
                                {
                                    !isDisabled ?
                                        <div className='flex items-center'>
                                            {
                                                (parameterType === PARAMETERS_TYPES.Amount || parameterType === PARAMETERS_TYPES.Number) &&
                                                <div className='flex items-center' ><input disabled={selectedEmployees.length == 0 ? true : false} id="distributeEqually" type={"checkbox"} className='h-4 w-4 relative' onChange={(e) => equallyDistributeSelected(e)} /><label htmlFor='distributeEqually' className='mr-2 ml-1'> Distribute Equally</label></div>
                                            }

                                            {
                                                (parameterType === PARAMETERS_TYPES.Percentage || parameterType === PARAMETERS_TYPES.Days) &&
                                                <div className='flex items-center' ><input id="applyAcross" disabled={selectedEmployees.length == 0 ? true : false} type={"checkbox"} className='h-4 w-4 relative' onChange={(e) => equallyDistributeSelected(e)} /><label htmlFor='applyAcross' className='mr-2 ml-1'> Apply Across</label></div>
                                            }
                                            <input disabled={selectedEmployees.length > 0 ? true : false} id="applyToAll" type={"checkbox"} onChange={(e) => applyToAll(e)} className=" h-4 w-4 relative " /><label htmlFor='applyToAll' className='ml-2'>Apply to All</label>
                                        </div>
                                        : ""
                                }
                            </div>


                            <div className="flex rounded-lg mt-5 dark:bg-[#2A2E30]">

                                <DataTable
                                    selection={selectedEmployees}
                                    onSelectionChange={e => setSelectedEmployees(e.value)}
                                    selectionMode="checkbox"
                                    paginator value={planData} responsiveLayout="scroll" style={{ width: '93vw' }}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                >
                                    <Column selectionMode={isDisabled ? "" : "multiple"} headerStyle={{ width: '3em' }}></Column>
                                    <Column field="id" header="Employee ID" sortable></Column>
                                    <Column field="name" header="Name" sortable></Column>
                                    <Column field="role" header="Role" sortable></Column>
                                    <Column field="vertical" header="Vertical" sortable></Column>
                                    <Column field="sbu" header="SBU" footer="Total/Average" sortable ></Column>
                                    {
                                        (parameterType === PARAMETERS_TYPES.Amount || parameterType === PARAMETERS_TYPES.Number) &&
                                        <Column field="percentage" footer={totalOfPercentage} onCellEditComplete={onCellEditComplete} editor={(options) => percentageEditor(options)} header="Percentage" > </Column>
                                    }
                                    <Column field="annualtarget" footer={totalOfAnnualTarget} onCellEditComplete={onCellEditComplete} editor={(options) => percentageEditor(options)} header="Annual Target"></Column>
                                    <Column field="targetStatus" header="Status" body={showStatus}></Column>
                                </DataTable>
                            </div>
                        </div>
                    }


                    {
                        renderData &&
                        <div className="rounded-lg mt-5  ">
                            <div className="flex flex-1 justify-end items-center gap-3 px-1">
                                {
                                    //!isDisabled &&
                                    <div>
                                        <input disabled={isDisabled ? true : false} id="chkMarkAsComplete" className='h-4 w-5 relative top-0.5 ' type={"checkbox"} checked={isMarkedAsCompleted === true ? "checked" : ""} onChange={e => setIsMarkedAsCompleted(e.target.checked)} /> <label htmlFor="chkMarkAsComplete" className='ml-1 mr-3 text-[#344054] text-[14px]'>Mark as Completed</label>
                                        <Link onClick={() => isDisabled ? "" : save_Assign_AnnualTargets()} className={
                                            isDisabled ?
                                                "disabled bg-[#e1e1e1] text-[#344054] py-3 px-4 transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]" :
                                                "py-3 px-4 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]"}>Save</Link>
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    {
                        renderData &&
                        <div className="flex flex-1 justify-between gap-3 px-1 mt-7 mb-5 ">
                            <div>
                                <Link onClick={() => ShowPreviousParameter()} className="py-3 px-4 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">Previous</Link>
                            </div>

                            <div className="flex flex-1 justify-end gap-3">

                                <div><Link onClick={() => ShowNextParameter()} className="py-3 px-4 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">{nextParameterId === '' ? "Submit" : "Next"}</Link></div>
                            </div>

                        </div>}



                </div>

                <div id="selector" className={isShowConfirmationPopUp === 1 ? 'overlay' : ''}></div>
                {
                    isShowConfirmationPopUp === 1 ?
                        <div style={{ width: "40vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                            <div className="relative">
                                <img src={popupHeaderImg} alt="" className='w-full' />
                                <div className="absolute right-0 left-0 top-0 w-full text-white">
                                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                        <div className="text-md">Annual Target</div>
                                        <div><Link onClick={() => setIsShowConfirmationPopUp(0)} className="closebtn text-2xl leading-none removeClass" >&times;</Link></div>
                                    </div>
                                    <div className="px-6 mt-8">
                                        <div className="text-sm">Below are the parameter that need to complete</div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-1 inline-block min-w-full shadow-md rounded-lg overflow-hidden'>
                                <div className='m-5 height-custtom'>
                                    <DataTable value={markAsIncompleteParameters} scrollable scrollHeight="flex" resizableColumns columnResizeMode="fit" emptyMessage="&nbsp;">
                                        <Column header="Parameter" field='parameterName' style={{ width: '70%' }}></Column>
                                        <Column header="Status" body={openLink} style={{ width: '30%' }}></Column>
                                    </DataTable>
                                </div>

                                <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-6">

                                    <div className="flex justify-end gap-3">
                                        {/* <div><Link onClick={() => setIsShowConfirmationPopUp(0)} className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Cancel</Link></div> */}
                                        <div><Link onClick={() => setIsShowConfirmationPopUp(0)} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">OK</Link></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        : null
                }

            </div>

        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}


