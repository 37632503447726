import ReactEcharts from 'echarts-for-react';

const BarChart = (props) => {
  let labelArray = [];
  let dataArray = [];
  let counter = 0;

  if (props.data.label) {
    for (let i = 0; i < props.data.label.length; i++) {
      if (counter < 5) {
        dataArray.push(props.data.data[i])
        labelArray.push(props.data.label[i])
      }
      counter++;
    }
  }


  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: labelArray
    },
    series: [
      {
        data: dataArray,
        type: 'bar',
      }
    ]
  };



  return (
    <div>
      <ReactEcharts
        option={option}
      />
    </div>
  );

}
export default BarChart;