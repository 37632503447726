import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import moment from "moment";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState, useCallback } from 'react';
import { json, Link } from "react-router-dom";
import Select from "react-select";
import { reactLocalStorage } from 'reactjs-localstorage';
import LeftSideBar from '../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../components/common/employee/topnav.component';
import CircularProgressBar from '../../../../components/common/progressbar/CircularProgressbar';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { BIUnit, Employee, FinancialYear, Region, Roles, SBU, Targets, Relationship, RelationshipDetails, Parameters, Plan, TargetDetails, Country, Brand, ProductGroup, CustomerCategory, Customer, TargetMessage } from "../../../../models";
import EmpLogin from "../../../../setup/auth/emp.component";
import { Storage } from 'aws-amplify';
import { commaSeparated, isPositiveInteger, ShowInMillion, sumFromArray } from '../../../../components/helper/maths';
import uniqId from 'uniqid';
import {
    ConvertResponseForSelect, GetTotalPercentageFromPlanArray, ConvertResponseForSelectTwoNames,
    GetTotalTargetValueFromPlanArray, RemoveItemFromArrayByIdValue, SetBrandAggregateValueFromPlanArray, SetCountryAggregateValueFromPlanArray, SetCustomerAggregateValueFromPlanArray, SetCustomerSegmentAggregateValueFromPlanArray, SetProductGroupAggregateValueFromPlanArray, SetRegionAggregateValueFromPlanArray, SetBuAggregateValueFromPlanArray, convertArrayToObject, GetSingleValueFromResponse, ObjectToArray, convertNumberToShortNumber, getCurrentQuater, SetBrandAggregateValueFromPlanDashBoard
} from '../../../../components/helper/commonfunctions';
import { PARAMETERS_TYPES, PARAMETERS_TYPES_NAMES, PARAMETER_IDs } from '../../../../components/helper/enum'
import { detailViewFilter, summaryFilter, drillDownFilter } from "../../../../components/helper/getFilterData"
import { calculateTargetTotal, calculateConsolidatedScore, calculateWeightageAchievement, checkIfNegativeValue } from "../../../../components/helper/dashboardCalCulations"
import { GetAWSObjectURL } from '../../../../components/helper/aws';

import { TabView, TabPanel } from 'primereact/tabview';
import { TabMenu } from 'primereact/tabmenu';
import UserImg from '../../../../assets/images/user.png';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import GaugeChart from '../../../../components/common/charts/gaugeChart';
import StackedLineChart from '../../../../components/common/charts/stackedLineChart';
import BarChart from '../../../../components/common/charts/barChart';
import SmoothLineChart from '../../../../components/common/charts/smoothLineChart';
import { ProgressBar } from 'primereact/progressbar';
import { getActualValue } from '../../evaluation/getActualValue';
import { getTargetBasedOnParameterType, getTotalTarget } from '../../../../components/common/getTargetBasedOnParameterType';
import { ProgressSpinner } from 'primereact/progressspinner';
import { toast } from 'react-toastify';
import { checkEmployeeTeamType } from '../../helper';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../components/helper/graphQLFunctions';
import * as queries from '../../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

export default function SalesPerformanceDashboard() {


    let isReportingManager = reactLocalStorage.get('isReportingManager')

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //state
    const [financialYear, setFinancialYear] = useState("");
    const [financialYearId, setFinancialYearId] = useState("");
    const [employee, setEmployee] = useState("");
    const [employeeId, setEmployeeId] = useState("");
    const [quarter, setQuarter] = useState("");
    const [loggedEmployeeName, setLoggedEmployeeName] = useState("");
    const [loggedEmployeeCode, setLoggedEmployeeCode] = useState("");
    const [reportingManagerId, setReportingManagerId] = useState("");
    const [loggedEmployeeProfile, setLoggedEmployeeProfile] = useState("");
    const [loggedEmployeeRole, setLoggedEmployeeRole] = useState("");
    const [sbu, setSbu] = useState("");
    const [parameter, setParameter] = useState("");
    const [parameterId, setParameterId] = useState("");
    const [renderItems, setRenderItems] = useState(false);
    const [isParameterDropdown, setIsParameterDropdown] = useState(false);
    const [allQCheck, setAllQCheck] = useState(true);
    const [Q1Check, setQ1Check] = useState(true);
    const [Q2Check, setQ2Check] = useState(true);
    const [Q3Check, setQ3Check] = useState(true);
    const [Q4Check, setQ4Check] = useState(true);
    const [parameterName, setParameterName] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [consolidatedScore, setConsolidatedScore] = useState("");
    const [consolidatedValue, setConsolidatedValue] = useState("");
    const [quarterDropdownValue, setQuarterDropdownValue] = useState("");
    const [employeeTeam, setEmployeeTeam] = useState([]);
    const [parameterAnalysisData, setParameterAnalysisData] = useState([]);
    const [employeeDropdownTeam, setEmployeeDropdownTeam] = useState([]);
    const [parameterAnalysisYearValue, setParameterAnalysisYearValue] = useState("");
    const [parameterAnalysisQuarterValue, setParameterAnalysisQuarterValue] = useState("");
    //list
    const [financialYearList, setFinancialYearList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [parameterList, setParameterList] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [parameterDetailViewList, setParameterDetailViewList] = useState([]);
    const [quarterTargetValue, setQuarterTargetValue] = useState([]);
    const [quarterActualsValue, setQuarterActualsValue] = useState([]);
    const [reporteeAlert, setReporteeAlert] = useState([]);

    const setTopList = [
        { label: 'All', value: "0" },
        { label: 'Top 5', value: "1" }
    ];

    let loggedEmployeeId = reactLocalStorage.get('loggedEmployeeId')

    //
    const [parametersForSelect, setParametersForSelect] = useState([]);
    const [selectedParameter, setSelectedParameter] = useState("");
    const [brandsData, setBrandsData] = useState([]);
    const [current_Target_Details, setCurrentTargetDetails] = useState([]);


    const [showParameterAnalysisData, setShowParameterAnalysisData] = useState(false);


    //quarterList
    var quarterList = [
        { label: "All Quarter", value: "Quarter 4" },
        { label: "Quarter 1", value: "Quarter 1" },
        { label: "Quarter 2", value: "Quarter 2" },
        { label: "Quarter 3", value: "Quarter 3" },
        { label: "Quarter 4", value: "Quarter 4" },
    ]


    //Level List
    var levelList = [
        { label: "Select", value: "" },
        { label: "BU", value: "BU" },
        { label: "Region", value: "Region" },
        { label: "Country", value: "Country" },
        { label: "Brand", value: "Brand" },
        { label: "Product Group", value: "ProductGroup" },
        // { label: "Cust. Group", value: "Cust. Group" },
        { label: "Customer", value: "Customer" },
    ]

    let adDATA = { "name": "d6841b40-ed44-444f-bb0d-938fc7474f15", "children": [{ "name": "e48749ff-696b-4ead-8ffb-8557286bf892", "children": [] }, { "name": "abf1e71b-7b69-4114-84c5-93a82be7a57c", "children": [] }] }


    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }
    const items = [
        { label: 'Top 3' },
        { label: 'Bottom 3' }

    ];

    async function loadSummaryData() {

        //load Logged employee response
        // var loggedEmployeeResponse = await DataStore.query(Employee, (c) => c.id.eq(loggedEmployeeId));


        var loggedEmployeeResponse = await graphQLFindRecordById('getEmployee', loggedEmployeeId);

        if (loggedEmployeeResponse) {

            setReportingManagerId(loggedEmployeeResponse.parentEmployeeId)
            let fullName = loggedEmployeeResponse.firstName + ' ' + loggedEmployeeResponse.lastName
            setLoggedEmployeeName(fullName)
            let employeeCode = loggedEmployeeResponse.employeeId === null ? "" : loggedEmployeeResponse.employeeId
            setLoggedEmployeeCode(employeeCode)


            //set Profile
            if (loggedEmployeeResponse.profilePic) {
                await Storage.get(loggedEmployeeResponse.profilePic)
                    .then(result => {
                        setLoggedEmployeeProfile(result)
                    });
            } else { setLoggedEmployeeProfile(UserImg) }

            //set role
            let employeeRole = ""
            if (loggedEmployeeResponse.role_id !== null) {
                // var loggedEmployeeRole = await DataStore.query(Roles, (c) => c.id.eq(loggedEmployeeResponse.role_id));
                var loggedEmployeeRole = await graphQLFindRecordById('getRoles', loggedEmployeeResponse.role_id);
                if (loggedEmployeeRole) { employeeRole = loggedEmployeeRole.name } else { employeeRole = "" }
            } else { employeeRole = "" }
            setLoggedEmployeeRole(employeeRole)

            //set SBU
            let employeeSBU = ""
            if (loggedEmployeeResponse.param_sbu_id !== null) {
                // var loggedEmployeeSbu = await DataStore.query(SBU, (c) => c.id.eq(loggedEmployeeResponse.param_sbu_id));
                var loggedEmployeeSbu = await graphQLFindRecordById('getSBU', loggedEmployeeResponse.param_sbu_id);

                if (loggedEmployeeSbu) { employeeSBU = loggedEmployeeSbu.name } else { employeeSBU = "" }
            } else { employeeSBU = "" }
            setSbu(employeeSBU)

        }

        //load Summary Parameters
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        setQuarter({ label: "All Quarter", value: "Quarter 4" })
        LoadSummaryParametersData(selectedYear.value, loggedEmployeeId, "Quarter 4", loggedEmployeeResponse.parentEmployeeId)
        getParameterAnalysisData(parameter, selectedYear, "Quarter 4")


        //load detail view data
        loadSummaryDetailViewData()

    }

    //Load Summary Parameters Data
    async function LoadSummaryParametersData(financialYearId, employeeId, quarterValue, reportManagerEmployeeId) {
        // if (employeeId === 0) { employeeId = loggedEmployeeId } else { employeeId = employeeId }

        if (financialYearId && employeeId && quarterValue) {

            //get FY
            // var FYResponse = await DataStore.query(FinancialYear, financialYearId);
            var FYResponse = await graphQLFindRecordById('getFinancialYear', financialYearId);

            //get parameters
            // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
            //     c.employee_id.eq(employeeId),
            //     c.financial_year_id.eq(financialYearId),
            //     c.report_manager_employee_id.eq(reportManagerEmployeeId)
            // ]));

            var targetsResponses = await graphQLGetAllData('listTargets', [
                {
                    employee_id: { eq: employeeId }
                    , financial_year_id: { eq: financialYearId },
                    report_manager_employee_id: { eq: reportManagerEmployeeId }
                }
            ]);

            // console.log('targetsResponses', targetsResponses)


            if (targetsResponses.length > 0) {

                //get parameters weightage
                // var relationshipResponse = await DataStore.query(Relationship, (c) => c.and(c => [
                //     c.role_id.eq(targetsResponses[0].role_id),
                //     c.financial_year_id.eq(financialYearId)
                // ])).then(response => response.reduce((prev, current) => {
                //     return prev._lastChangedAt > current._lastChangedAt ? prev : current;
                // }, {}));

                var relationshipResponse = await graphQLGetAllData('listRelationships', [
                    { role_id: { eq: targetsResponses[0].role_id } },
                    { financial_year_id: { eq: financialYearId } }
                ]);

                relationshipResponse = relationshipResponse.reduce((prev, current) => {
                    return prev._lastChangedAt > current._lastChangedAt ? prev : current;
                }, {});


                var relationshipDetailResponses = []
                if (relationshipResponse) {
                    // relationshipDetailResponses = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationshipResponse.id));
                    relationshipDetailResponses = await graphQLGetAllData('listRelationshipDetails', [{ relationship_id: { eq: relationshipResponse.id } }]);

                }

                var _tempArray = [];

                // //Target Details
                // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
                //     c.target_id.eq(targetsResponses[0].id),
                //     c.parameter_id.notContains(PARAMETER_IDs.DIO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DPO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DVRO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DSO),
                // ]));

                var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                    { target_id: { eq: targetsResponses[0].id } },
                    { parameter_id: { notContains: PARAMETER_IDs.DIO } },
                    { parameter_id: { notContains: PARAMETER_IDs.DPO } },
                    { parameter_id: { notContains: PARAMETER_IDs.DVRO } },
                    { parameter_id: { notContains: PARAMETER_IDs.DSO } }
                ]);


                currentTargetDetails.forEach(async (currentItem, i) => {
                    //Status
                    let status = ""
                    if (currentItem.is_accepted_by_employee && !currentItem.is_approve) { status = "Awaiting approval" }
                    else if (currentItem.isSendForRevisionByReportingManage) { status = "Revision" }
                    else if (currentItem.is_approve) { status = "Approved" }
                    else if (currentItem.is_revision_by_employee) { status = "Requested for Revision" }
                    else { status = "Open" }

                    //get parameters details
                    // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(currentItem.parameter_id));
                    var parameterResponses = await graphQLFindRecordById('getParameters', currentItem.parameter_id);


                    var getWeightage = relationshipDetailResponses.filter((e) => e.parameter_id === currentItem.parameter_id)

                    var weightageForWtAch = getWeightage.length > 0 ? getWeightage[0].weightage : 1;

                    var parameterBaseType = parameterResponses.type === PARAMETERS_TYPES.Days ? PARAMETERS_TYPES_NAMES.D : parameterResponses.type === PARAMETERS_TYPES.Amount ? PARAMETERS_TYPES_NAMES.A : parameterResponses.type === PARAMETERS_TYPES.Number ? PARAMETERS_TYPES_NAMES.N : PARAMETERS_TYPES_NAMES.P

                    var actualPerformance = 0; var actualAch = 0; var wtAch = 0;

                    var planActual = getActualValue(currentItem, quarterValue, parameterBaseType)

                    if (planActual) { actualPerformance = parseFloat(planActual).toFixed(0) } else { actualPerformance = 0 }

                    var getTargetOnParameterType = getTotalTarget(currentItem, quarterValue, parameterBaseType)

                    actualAch = await calculateConsolidatedScore(planActual, getTargetOnParameterType)
                    wtAch = await calculateWeightageAchievement(weightageForWtAch, actualAch)
                    let originalWtAch = wtAch

                    //Check if Negative
                    actualAch = await checkIfNegativeValue(actualAch)
                    wtAch = await checkIfNegativeValue(wtAch)
                    actualPerformance = await checkIfNegativeValue(actualPerformance)


                    if (getWeightage.length > 0) {

                        if (parameterResponses) {
                            let data = {
                                "target_detail_id": currentItem.id,
                                "financial_year_id": FYResponse.value,
                                "financial_year": FYResponse.label,
                                "parameter_id": currentItem.parameter_id,
                                "parameter_name": parameterResponses.name,
                                "parameter_type": parameterResponses.type === "A" ? "$" : parameterResponses.type === "D" ? "Days" : parameterResponses.type === "P" ? "%" : parameterResponses.type === "N" ? "Number" : "",
                                "target_value": commaSeparated(Math.round(getTargetOnParameterType)),
                                "actualPerformance": actualPerformance !== undefined ? parameterResponses.type === "A" ? commaSeparated(actualPerformance) : actualPerformance : 0,
                                // "actualAch": actualAch ? parseFloat(actualAch).toFixed(0) + "%" : "0%",
                                // "wtAch": wtAch ? parseFloat(wtAch).toFixed(0) + "%" : parseFloat(0).toFixed(0) + "%",
                                "actualAch": actualAch ? actualAch + "%" : "0%",
                                "wtAch": wtAch ? wtAch + "%" : "0%",
                                "isMarkAsComplete": currentItem.isMarkAsComplete,
                                "is_accepted_by_employee": currentItem.is_accepted_by_employee,
                                "is_approve": currentItem.is_approve,
                                "is_revision_by_employee": currentItem.is_revision_by_employee,
                                "isSendForRevisionByReportingManage": currentItem.isSendForRevisionByReportingManage,
                                "parameter_weightage": weightageForWtAch ? parseInt(weightageForWtAch) : "-",
                                "isPlanMandatory": getWeightage.length > 0 ? 1 : 0,
                                //"planning_period": moment(financialYearResponses[0].fromDate).format("Do MMM YY") + " - " + moment(financialYearResponses[0].toDate).format("Do MMM YY")
                                "status": status,
                                "q1_target_value": currentItem.q1_target_value,
                                "q2_target_value": currentItem.q2_target_value,
                                "q3_target_value": currentItem.q3_target_value,
                                "q4_target_value": currentItem.q4_target_value,
                                "q1_actual_value": getActualValue(currentItem, "Quarter 1", parameterBaseType),
                                "q2_actual_value": getActualValue(currentItem, "Quarter 2", parameterBaseType),
                                "q3_actual_value": getActualValue(currentItem, "Quarter 3", parameterBaseType),
                                "q4_actual_value": getActualValue(currentItem, "Quarter 4", parameterBaseType),
                                "totalWeightage": actualAch ? parseFloat(actualAch).toFixed(0) : 0,
                                "originalWtAch": originalWtAch
                            }

                            _tempArray.push(data)

                        }
                    }

                    if (i === currentTargetDetails.length - 1) {
                        let sortArray = _tempArray.sort((a, b) => (a.parameter_name > b.parameter_name ? 1 : -1))
                        // let getTargetTotalArray = sortArray.map((item) => item.target_value)
                        // let getFinalTargetTotalArray = getTargetTotalArray.filter(x => !Number.isNaN(x));
                        // const targetTotalArrayData = await calculateTargetTotal(getFinalTargetTotalArray)
                        // const calculateConsolidatedScoreData = await calculateConsolidatedScore(targetTotalArrayData, targetTotalArrayData)

                        let filteredArray = sortArray.filter(e => e.parameter_id === PARAMETER_IDs.Revenue || e.parameter_id === PARAMETER_IDs['NWC DAYS'] || e.parameter_id === PARAMETER_IDs['Gross Margin %'] || e.parameter_id === PARAMETER_IDs['Gross Margin $']);
                        let totalConsolidatedValue = filteredArray.reduce((acc, book) => acc + book.parameter_weightage, 0);
                        let totalConsolidatedScore = filteredArray.reduce((acc, book) => acc + parseFloat(book.originalWtAch), 0);
                        setConsolidatedScore(!isNaN(Math.round(totalConsolidatedScore)) ? Math.round(totalConsolidatedScore) : 0)

                        setConsolidatedValue(totalConsolidatedValue)
                        await LoadBrandsData(sortArray[0])

                        setSummaryData(sortArray)

                        setQuarterTargetValue([parseFloat(sortArray[0].q1_target_value).toFixed(0), parseFloat(sortArray[0].q2_target_value).toFixed(0), parseFloat(sortArray[0].q3_target_value).toFixed(0), parseFloat(sortArray[0].q4_target_value).toFixed(0)])
                        setQuarterActualsValue([parseFloat(sortArray[0].q1_actual_value).toFixed(0), parseFloat(sortArray[0].q2_actual_value).toFixed(0), parseFloat(sortArray[0].q3_actual_value).toFixed(0), parseFloat(sortArray[0].q4_actual_value).toFixed(0)])
                        setParameterName(sortArray[0].parameter_name)
                    }
                })
            }
            else {
                setSummaryData([]);
                setQuarterTargetValue([]);
                setQuarterActualsValue([]);
                setParameterName("");
                LoadBrandsData([]);
            }
        }
    }
    async function LoadBrandsData(data) {
        // let planData = await DataStore.query(Plan, (c) => c.target_detail_id.eq(data.target_detail_id))
        let planData = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: data.target_detail_id } }]);

        if (planData.length > 0) {
            var brandDimensionArray = [];
            var brandDimension = await SetBrandAggregateValueFromPlanDashBoard(planData)
            var sumOfBrand = await GetTotalTargetValueFromPlanArray(brandDimension)
            brandDimension.map(async currentItem => {
                let _brandList = []
                if (brandList.length > 0) {
                    _brandList = [...brandList]
                } else {
                    // _brandList = await DataStore.query(Brand, Predicates.ALL);
                    _brandList = await graphQLGetAllData('listBrands');
                }
                var returnRecord = _brandList.find(o => o.id === currentItem.brandId);
                // console.log(returnRecord);
                if (returnRecord != null) {
                    if (currentItem.targetValue == 0 || sumOfBrand == 0) {
                        let Percentage = 0;
                        //    console.log(returnRecord.name);
                        brandDimensionArray.push({ "Brand": returnRecord.name, "Percentage": Percentage.toFixed(0) })
                    }
                    else {
                        let Percentage = (currentItem.targetValue * 100) / sumOfBrand;
                        brandDimensionArray.push({ "Brand": returnRecord.name, "Percentage": Percentage.toFixed(0) })
                    }
                }
                return currentItem;
            });
            setBrandsData(brandDimensionArray);
        }
    }

    //Load Detail View Data
    let loadSummaryDetailViewData = async () => {

        let financialYearId = ""
        let employeeID = ""

        if (employee.value) { employeeID = employee.value } else { employeeID = loggedEmployeeId }
        if (financialYear.value) { financialYearId = financialYear.value } else {
            let selectedYear = reactLocalStorage.getObject('selectedYear');
            financialYearId = selectedYear.value
        }

        //get target response
        // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.employee_id.eq(employeeID),
        //     c.financial_year_id.eq(financialYearId),
        //     c.report_manager_employee_id.notContains(employeeID)
        // ]));

        var targetsResponses = [];
        if (employeeID && financialYearId) {
            targetsResponses = await graphQLGetAllData("listTargets", [{ employee_id: { eq: employeeID }, financial_year_id: { eq: financialYearId }, report_manager_employee_id: { notContains: employeeID } }]);
        }



        if (targetsResponses.length > 0) {

            // var targetDetails = await DataStore.query(TargetDetails, (c) => c.target_id.eq(targetsResponses[0].id))
            var targetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: targetsResponses[0].id } }]);

            let finalData = [];

            if (targetDetails.length > 0) {

                for (let i = 0; i < targetDetails.length; i++) {

                    // let planData = await DataStore.query(Plan, (c) => c.target_detail_id.eq(targetDetails[i].id))
                    let planData = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: targetDetails[i].id } }]);

                    let planDataArray = []
                    //bind plan data
                    if (planData.length > 0) {

                        for (let j = 0; j < planData.length; j++) {

                            //get BU
                            let buId = ""
                            let buName = ""
                            if (planData[j].bu_id !== null) {
                                // let buResponses = await DataStore.query(BIUnit, (c) => c.id.eq(planData[j].bu_id))
                                let buResponses = await graphQLFindRecordById('getBIUnit', planData[j].bu_id);

                                if (buResponses) { buId = buResponses.id; buName = buResponses.name } else { buId = ""; buName = ""; }
                            } else { buId = ""; buName = ""; }

                            //get Region
                            let regionId = ""
                            let regionName = ""
                            if (planData[j].region_id !== null) {
                                // let regionResponses = await DataStore.query(Region, (c) => c.id.eq(planData[j].region_id))
                                let regionResponses = await graphQLFindRecordById('getRegion', planData[j].region_id);

                                if (regionResponses) { regionId = regionResponses.id; regionName = regionResponses.name } else { regionId = ""; regionName = ""; }
                            } else { regionId = ""; regionName = ""; }

                            //get country
                            let countryId = ""
                            let countryName = ""
                            if (planData[j].country_id !== null) {
                                // let countryResponses = await DataStore.query(Country, (c) => c.id.eq(planData[j].country_id))
                                let countryResponses = await graphQLFindRecordById('getCountry', planData[j].country_id);

                                if (countryResponses) { countryId = countryResponses.id; countryName = countryResponses.name } else { countryId = ""; countryName = ""; }
                            } else { countryId = ""; countryName = ""; }

                            //get Brand
                            let brandId = ""
                            let brandName = ""
                            if (planData[j].brand_id !== null) {
                                // let brandResponses = await DataStore.query(Brand, (c) => c.id.eq(planData[j].brand_id))
                                let brandResponses = await graphQLFindRecordById('getBrand', planData[j].brand_id);

                                if (brandResponses) { brandId = brandResponses.id; brandName = brandResponses.name } else { brandId = ""; brandName = ""; }
                            } else { brandId = ""; brandName = ""; }

                            //get PG
                            let pGId = ""
                            let pGName = ""
                            if (planData[j].product_group_id !== null) {
                                // let productGroupResponses = await DataStore.query(ProductGroup, (c) => c.id.eq(planData[j].product_group_id))
                                let productGroupResponses = await graphQLFindRecordById('getProductGroup', planData[j].product_group_id);

                                if (productGroupResponses) { pGId = productGroupResponses.id; pGName = productGroupResponses.name } else { pGId = ""; pGName = ""; }
                            } else { pGId = ""; pGName = ""; }

                            //get customer Group
                            let custCatId = ""
                            let custCatName = ""
                            if (planData[j].customer_group_id !== null) {
                                // let customerGroupResponses = await DataStore.query(CustomerCategory, (c) => c.id.eq(planData[j].customer_group_id))
                                let customerGroupResponses = await graphQLFindRecordById('getCustomerCategory', planData[j].customer_group_id);

                                if (customerGroupResponses) { custCatId = customerGroupResponses.id; custCatName = customerGroupResponses.name } else { custCatId = ""; custCatName = ""; }
                            } else { custCatId = ""; custCatName = ""; }

                            //get Customer
                            let customerId = ""
                            let customerName = ""
                            if (planData[j].customer_id !== null) {
                                // let customerResponses = await DataStore.query(Customer, (c) => c.id.eq(planData[j].customer_id));
                                let customerResponses = await graphQLFindRecordById('getCustomer', planData[j].customer_id);

                                if (customerResponses) { customerId = customerResponses.id; customerName = customerResponses.name } else { customerId = ""; customerName = ""; }
                            } else { customerId = ""; customerName = ""; }

                            let data = {
                                "id": planData[j].id, "sbuId": planData[j].sbu_id, "buId": buId, "buName": buName, "regionId": regionId, "regionName": regionName,
                                "countryId": countryId, "countryName": countryName, "brandId": brandId, "brandName": brandName, "pGId": pGId, "pGName": pGName,
                                "custCatId": custCatId, "custCatName": custCatName, "customerId": customerId, "customerName": customerName,
                                "target_value": commaSeparated(planData[j].target_value), "target_Actual_value": "",
                                "target_Actual_value_Ach": "", "q1_target_value": commaSeparated(planData[j].q1_target_value), "q1_target_Actual_value": "", "q1_target_Actual_value_Ach": "", "q2_target_value": commaSeparated(planData[j].q2_target_value), "q2_target_Actual_value": "", "q2_target_Actual_value_Ach": "",
                                "q3_target_value": commaSeparated(planData[j].q3_target_value), "q3_target_Actual_value": "", "q3_target_Actual_value_Ach": "", "q4_target_value": commaSeparated(planData[j].q4_target_value), "q4_target_Actual_value": "", "q4_target_Actual_value_Ach": "",
                            }
                            planDataArray.push(data)

                            if (j === planData.length - 1) {
                                finalData.push(planDataArray)
                            }
                        }
                    }

                    if (i === targetDetails.length - 1) {
                        let finalParameterData = convertArrayToObject(finalData)
                        setParameterDetailViewList(finalParameterData)
                        setRenderItems(true)
                    }

                }
            } else {
                setParameterDetailViewList([])
            }
        } else {
            setParameterDetailViewList([])
        }
    }

    var loadEmployeeData = async (selectedEmployeeId) => {
        // var loggedEmployeeResponse = await DataStore.query(Employee, (c) => c.id.eq(selectedEmployeeId));
        var loggedEmployeeResponse = await graphQLFindRecordById('getEmployee', selectedEmployeeId);

        if (loggedEmployeeResponse) {

            let fullName = loggedEmployeeResponse.firstName + ' ' + loggedEmployeeResponse.lastName
            setLoggedEmployeeName(fullName)
            let employeeCode = loggedEmployeeResponse.employeeId === null ? "" : loggedEmployeeResponse.employeeId
            setLoggedEmployeeCode(employeeCode)

            //set Profile
            if (loggedEmployeeResponse.profilePic) {
                await Storage.get(loggedEmployeeResponse.profilePic)
                    .then(result => {
                        setLoggedEmployeeProfile(result)
                    });
            } else { setLoggedEmployeeProfile(UserImg) }

            //set role
            let employeeRole = ""
            if (loggedEmployeeResponse.role_id !== null) {
                // var loggedEmployeeRole = await DataStore.query(Roles, (c) => c.id.eq(loggedEmployeeResponse.role_id));
                var loggedEmployeeRole = await graphQLFindRecordById('getRoles', loggedEmployeeResponse.role_id);

                if (loggedEmployeeRole) { employeeRole = loggedEmployeeRole.name } else { employeeRole = "" }
            } else { employeeRole = "" }
            setLoggedEmployeeRole(employeeRole)

            //set SBU
            let employeeSBU = ""
            if (loggedEmployeeResponse.param_sbu_id !== null) {
                // var loggedEmployeeSbu = await DataStore.query(SBU, (c) => c.id.eq(loggedEmployeeResponse.param_sbu_id));
                var loggedEmployeeSbu = await graphQLFindRecordById('getSBU', loggedEmployeeResponse.param_sbu_id);
                if (loggedEmployeeSbu) { employeeSBU = loggedEmployeeSbu.name } else { employeeSBU = "" }
            } else { employeeSBU = "" }
            setSbu(employeeSBU)
        }
    }

    //onClick Employee
    const handleClickEmployeeDropdown = async (event) => {
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        setEmployee(event)
        setEmployeeId(event.value)

        let reportingManagerId = "";
        if (event.value === 0) {
            event.value = loggedEmployeeId
            // var loggedEmployeeResponse = await DataStore.query(Employee, (c) => c.id.eq(event.value));
            var loggedEmployeeResponse = await graphQLFindRecordById('getEmployee', event.value);

            reportingManagerId = loggedEmployeeResponse.parentEmployeeId
            setReportingManagerId(reportingManagerId)
        } else if (event.value === loggedEmployeeId) {
            event.value = event.value
            reportingManagerId = event.value
            setReportingManagerId(reportingManagerId)
        }
        else {
            event.value = event.value
            // var loggedEmployeeResponse = await DataStore.query(Employee, (c) => c.id.eq(event.value));
            var loggedEmployeeResponse = await graphQLFindRecordById('getEmployee', event.value);
            reportingManagerId = loggedEmployeeResponse.parentEmployeeId
            setReportingManagerId(reportingManagerId)
        }

        LoadSummaryParametersData(selectedYear.value, event.value, quarter.value, reportingManagerId)

        await loadEmployeeData(event.value)

        //_________________________set drilldown analysis_______________________//


        const drillDownData = await drillDownFilter(event.value, financialYear.value)
        setDrillDownData([])
        setLevel1("");
        setSelectedParameter("");
        setShowDrillDownChart(false);
        setLevel2("");
        setLevel3("");
        setLevel4("");

        setCurrentTargetDetails(drillDownData)

        let parametersForSelectedYear = []

        for (var i = 0; i < drillDownData.length; i++) {
            // var parameterResponses = await DataStore.query(Parameters, drillDownData[i]["parameter_id"])
            var parameterResponses = await graphQLFindRecordById('getParameters', drillDownData[i]["parameter_id"]);

            let data = { "label": parameterResponses.name, "value": parameterResponses.id }
            //* Hide NWC days  07-06-2023
            if (parameterResponses.name !== "NWC Days") {
                parametersForSelectedYear.push(data)
            }
            if (i === drillDownData.length - 1) {
                setParametersForSelect(parametersForSelectedYear)
            }
        }

        if (drillDownData.length === 0) {
            setParametersForSelect([])
        }

        //_________________________set drilldown analysis end_______________________//

        //_________________________set detail view_______________________//


        const data = await detailViewFilter(event.value, parameter.value, financialYear.value)
        const finalDetailViewResult = Array.from(data)
        if (finalDetailViewResult.length > 0) {
            setParameterDetailViewList(finalDetailViewResult)
        }
        else {
            setParameterDetailViewList([]);
        }
        //_________________________set detail view end_______________________//
    }

    //onClick Financial Year
    const handleClickFinanCialYearDropdown = async (event) => {
        // loadDrillDownData(event.value)
        reactLocalStorage.setObject('selectedYear', event);

        getParameterAnalysisData(parameter, event, "Quarter 4")
        LoadSummaryParametersData(event.value, employeeId, quarter.value, reportingManagerId)

        setFinancialYear(event);
        await loadEmployeeData(employeeId)

        //_________________________set summary data_______________________//

        const data = await detailViewFilter(employee.value, parameter.value, event.value)
        const finalDetailViewResult = Array.from(data)
        if (finalDetailViewResult.length > 0) {
            setParameterDetailViewList(finalDetailViewResult)
        }
        else {
            setParameterDetailViewList([]);
        }

        //_________________________set drilldown analysis_______________________//


        const drillDownData = await drillDownFilter(employee.value, event.value)
        setDrillDownData([])
        setLevel1("");
        setSelectedParameter("");
        setShowDrillDownChart(false);
        setLevel2("");
        setLevel3("");
        setLevel4("");

        setCurrentTargetDetails(drillDownData)

        let parametersForSelectedYear = []

        for (var i = 0; i < drillDownData.length; i++) {
            // var parameterResponses = await DataStore.query(Parameters, drillDownData[i]["parameter_id"])
            var parameterResponses = await graphQLFindRecordById('getParameters', drillDownData[i]["parameter_id"]);

            let data = { "label": parameterResponses.name, "value": parameterResponses.id }
            //* Hide NWC days  07-06-2023
            if (parameterResponses.name !== "NWC Days") {
                parametersForSelectedYear.push(data)
            }
            if (i === drillDownData.length - 1) {
                setParametersForSelect(parametersForSelectedYear)
            }
        }

        if (drillDownData.length === 0) {
            setParametersForSelect([])
        }

        //_________________________set drilldown analysis end_______________________//


    }

    //onClick Parameters
    const handleClickParameterDropdown = async (event) => {
        LoadSummaryParametersData(financialYear.value, employeeId, event.value)

        setParameter(event)
        const data = await detailViewFilter(employee.value, event.value, financialYear.value)
        const finalDetailViewResult = Array.from(data)
        if (finalDetailViewResult.length > 0) {
            setParameterDetailViewList(finalDetailViewResult)
        }
        else {
            setParameterDetailViewList([]);
        }
        getParameterAnalysisData(event, financialYear, "Quarter 4")
    }

    //onClick Financial Year
    const handleClickQuarterDropdown = async (event) => {
        getParameterAnalysisData(parameter, financialYear, event.value)
        LoadSummaryParametersData(financialYear.value, employeeId, event.value, reportingManagerId)
        await loadEmployeeData(employeeId)

        setRenderItems(false)
        setQuarterDropdownValue(event.label)
        if (event.label === "All") {
            setAllQCheck(true)
            setQ1Check(false)
            setQ2Check(false)
            setQ3Check(false)
            setQ4Check(false)
        }
        else if (event.label === "Q1") {
            setQ1Check(true)
            setQ2Check(false)
            setQ3Check(false)
            setQ4Check(false)
            setAllQCheck(false)
        } else if (event.label === "Q2") {
            setQ1Check(false)
            setQ2Check(true)
            setQ3Check(false)
            setQ4Check(false)
            setAllQCheck(false)

        } else if (event.label === "Q3") {
            setQ1Check(false)
            setQ2Check(false)
            setQ3Check(true)
            setQ4Check(false)
            setAllQCheck(false)

        } else if (event.label === "Q4") {
            setQ1Check(false)
            setQ2Check(false)
            setQ3Check(false)
            setQ4Check(true)
            setAllQCheck(false)
        }



    }

    // getParameterAnalysisData starts
    const getParameterAnalysisData = async (parameterData, financialYear, selectedQuarter) => {
        // console.log(JSON.stringify(parameterData))
        // console.log(JSON.stringify(financialYear))
        // console.log(JSON.stringify(selectedQuarter))
        setShowParameterAnalysisData(false)

        // var loggedEmployeeTeam = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(loggedEmployeeId))
        var loggedEmployeeTeam = await graphQLGetAllData('listEmployees', [{ parentEmployeeId: { eq: loggedEmployeeId } }]);


        var myArray = [];
        var myDataLabelArray = [];
        var myDataArray = [];
        var currentQuater = getCurrentQuater();

        try {
            for (let i = 0; i < loggedEmployeeTeam.length; i++) {
                // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
                //     c.employee_id.eq(loggedEmployeeTeam[i].id),
                //     c.financial_year_id.eq(financialYear.value),
                //     c.report_manager_employee_id.notContains(loggedEmployeeTeam[i].id),
                // ]));

                var targetsResponses = [];
                if (loggedEmployeeTeam[i].id && financialYear.value) {
                    targetsResponses = await graphQLGetAllData('listTargets', [
                        { employee_id: { eq: loggedEmployeeTeam[i].id } },
                        { financial_year_id: { eq: financialYear.value } },
                        { report_manager_employee_id: { notContains: loggedEmployeeTeam[i].id } }
                    ]);
                }

                var currentTargetDetails = [];
                if (targetsResponses.length > 0) {
                    // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
                    //     c.target_id.eq(targetsResponses[0].id),
                    //     c.parameter_id.eq(parameterData.value)
                    // ]));
                    var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                        {
                            target_id: { eq: targetsResponses[0].id },
                            parameter_id: { eq: parameterData.value }
                        }
                    ]);



                    myDataLabelArray.push(loggedEmployeeTeam[i].firstName + ' ' + loggedEmployeeTeam[i].lastName)
                    myDataArray.push(currentTargetDetails.length > 0 ? currentTargetDetails[0].target_value : 0)

                    var actualAch = 0; var wtAch = 0;
                    // let parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(parameterData.value));
                    let parameterResponses = await graphQLFindRecordById('getParameters', parameterData.value);

                    var parameterBaseType = parameterResponses.type === PARAMETERS_TYPES.Days ? PARAMETERS_TYPES_NAMES.D : parameterResponses.type === PARAMETERS_TYPES.Amount ? PARAMETERS_TYPES_NAMES.A : parameterResponses.type === PARAMETERS_TYPES.Number ? PARAMETERS_TYPES_NAMES.N : PARAMETERS_TYPES_NAMES.P


                    // var getPlanDetails = await DataStore.query(Plan, (c) => c.target_detail_id.eq(currentTargetDetails[0].id));
                    var getPlanDetails = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: currentTargetDetails[0].id } }]);

                    var getTargetParameterType = getTargetBasedOnParameterType(getPlanDetails, selectedQuarter, parameterBaseType)

                    var planActual = getActualValue(currentTargetDetails[0], selectedQuarter, parameterBaseType)
                    actualAch = await calculateConsolidatedScore(planActual, getTargetParameterType)

                    var empProgress = []
                    if (currentTargetDetails.length > 0) {
                        for (let i = 1; i < 5; i++) {
                            var getTargetParameterTypeForChart = getTargetBasedOnParameterType(getPlanDetails, "Quarter " + i, parameterBaseType)
                            var planActualForChart = getActualValue(currentTargetDetails[0], "Quarter " + i, parameterBaseType)
                            var actualAchForChart = await calculateConsolidatedScore(planActualForChart, getTargetParameterTypeForChart)
                            empProgress.push(Math.round(actualAchForChart))
                        }
                    }

                    myArray.push({
                        name: loggedEmployeeTeam[i].firstName + ' ' + loggedEmployeeTeam[i].lastName,
                        empId: loggedEmployeeTeam[i].employeeId,
                        empQuarterWiseProgress: empProgress,
                        parameterType: parameterResponses != '' ? parameterResponses.type : '',
                        thisYearTarget: currentTargetDetails.length > 0 ? Math.round(currentTargetDetails[0].target_value) : 0,
                        currentQuaterTarget: getTargetParameterType ? Math.round(getTargetParameterType) : 0,
                        planActual: planActual ? Math.round(planActual) : 0,
                        actualAch: actualAch ? Math.round(actualAch) : 0,
                    })


                    if (i === (loggedEmployeeTeam.length - 1)) {
                        setEmployeeTeam(myArray)
                    }
                } else {
                    setEmployeeTeam([])
                    setShowParameterAnalysisData(true)
                }
            }
        }
        catch (e) { }

        // //* get Team data
        // var myTargetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.employee_id.eq(loggedEmployeeId),
        //     c.financial_year_id.eq(financialYear.value),
        //     c.report_manager_employee_id.notContains(loggedEmployeeId),
        // ]));

        var myTargetsResponses = [];

        if (loggedEmployeeId && financialYear.value) {
            myTargetsResponses = await graphQLGetAllData('listTargets', [
                { employee_id: { eq: loggedEmployeeId } },
                { financial_year_id: { eq: financialYear.value } },
                { report_manager_employee_id: { notContains: loggedEmployeeId } }
            ]);
        }

        if (myTargetsResponses.length > 0) {
            // let myCurrentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
            //     c.target_id.eq(myTargetsResponses[0].id),
            //     c.parameter_id.eq(parameterData.value)
            // ]));
            var myCurrentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                { target_id: { eq: myTargetsResponses[0].id } },
                { parameter_id: { eq: parameterData.value } }
            ]);

            if (myCurrentTargetDetails.length > 0) {
                // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(myCurrentTargetDetails[0].parameter_id));
                var parameterResponses = await graphQLFindRecordById('getParameters', myCurrentTargetDetails[0].parameter_id);

                let yearTargetValue = parameterResponses.type === PARAMETERS_TYPES.Days ? Math.floor(myCurrentTargetDetails[0].target_value) : parameterResponses.type === PARAMETERS_TYPES.Amount ? (parseInt(myCurrentTargetDetails[0].target_value)) : myCurrentTargetDetails[0].target_value;
                setParameterAnalysisYearValue(yearTargetValue)
                if (currentQuater === 1) {
                    // var getPlanDetails = await DataStore.query(Plan, (c) => c.target_detail_id.eq(myCurrentTargetDetails[0].id));
                    var getPlanDetails = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: myCurrentTargetDetails[0].id } }]);

                    var getTargetParameterType = getTargetBasedOnParameterType(getPlanDetails, selectedQuarter, parameterBaseType)
                    setParameterAnalysisQuarterValue(Math.round(getTargetParameterType))
                } else if (currentQuater === 2) {
                    let TargetValue = parameterResponses.type === PARAMETERS_TYPES.Days ? Math.floor(myCurrentTargetDetails[0].q2_target_value) : parameterResponses.type === PARAMETERS_TYPES.Amount ? (parseInt(myCurrentTargetDetails[0].q2_target_value)) : myCurrentTargetDetails[0].q2_target_value;
                    setParameterAnalysisQuarterValue(TargetValue)
                } else if (currentQuater === 3) {
                    let TargetValue = parameterResponses.type === PARAMETERS_TYPES.Days ? Math.floor(myCurrentTargetDetails[0].q3_target_value) : parameterResponses.type === PARAMETERS_TYPES.Amount ? (parseInt(myCurrentTargetDetails[0].q3_target_value)) : myCurrentTargetDetails[0].q3_target_value;
                    setParameterAnalysisQuarterValue(TargetValue)
                } else if (currentQuater === 4) {
                    let TargetValue = parameterResponses.type === PARAMETERS_TYPES.Days ? Math.floor(myCurrentTargetDetails[0].q4_target_value) : parameterResponses.type === PARAMETERS_TYPES.Amount ? (parseInt(myCurrentTargetDetails[0].q4_target_value)) : myCurrentTargetDetails[0].q4_target_value;
                    setParameterAnalysisQuarterValue(TargetValue)
                }

            } else {
                setParameterAnalysisYearValue("");
                setParameterAnalysisQuarterValue("")
            }

            // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(parameterData.value));
            var parameterResponses = await graphQLFindRecordById('getParameters', parameterData.value);

            let linechartData = [];
            let linechartActualData = [];
            if (myCurrentTargetDetails.length > 0 && myCurrentTargetDetails[0] !== null) {
                for (let i = 1; i <= 4; i++) {
                    linechartData.push(myCurrentTargetDetails[0]['q' + i + '_target_value'])
                    linechartActualData.push(myCurrentTargetDetails[0]['q' + i + '_achievement'])
                }

                var empProgressForTeam = []
                for (let i = 1; i < 5; i++) {
                    // var getPlanDetails = await DataStore.query(Plan, (c) => c.target_detail_id.eq(myCurrentTargetDetails[0].id));
                    var getPlanDetails = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: myCurrentTargetDetails[0].id } }]);

                    var getTargetParameterTypeForChart = getTargetBasedOnParameterType(getPlanDetails, "Quarter " + i, parameterBaseType)
                    var planActualForChart = getActualValue(myCurrentTargetDetails[0], "Quarter " + i, parameterBaseType)
                    var actualAchForChart = await calculateConsolidatedScore(planActualForChart, getTargetParameterTypeForChart)
                    empProgressForTeam.push(Math.round(actualAchForChart))
                }

                var chartDataArary = [];
                chartDataArary['label'] = myDataLabelArray;
                chartDataArary['data'] = myDataArray;
                chartDataArary['parameter'] = parameterData.label;
                chartDataArary['teamTrendsTarget'] = linechartData;
                chartDataArary['teamTrendsActual'] = empProgressForTeam;
                chartDataArary['parameterType'] = parameterResponses.type;
                chartDataArary['myData'] = [{ thisYear: myCurrentTargetDetails ? myCurrentTargetDetails[0].target_value : 0, currentQuater: myCurrentTargetDetails[0]['q' + currentQuater + '_target_value'] }];
                setParameterAnalysisData(chartDataArary)
                setShowParameterAnalysisData(true)
            }
        }

    }
    // getParameterAnalysisData Ends

    useEffect(() => {
        onLoad();
        async function bindList() {

            //SBUResponses
            // var SBUResponses = await DataStore.query(SBU, Predicates.ALL, {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });
            var SBUResponses = await graphQLGetAllData('listSBUS')
            SBUResponses = SBUResponses.sort((a, b) => a.name.localeCompare(b.name));
            setSbuList(SBUResponses)

            //get BU
            // var buResponses = await DataStore.query(BIUnit, Predicates.ALL, {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });
            var buResponses = await graphQLGetAllData('listBIUnits')
            buResponses = buResponses.sort((a, b) => a.name.localeCompare(b.name));

            setBuList(buResponses)

            //get Region
            // var regionResponses = await DataStore.query(Region, Predicates.ALL, {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });
            var regionResponses = await graphQLGetAllData('listRegions')
            regionResponses = regionResponses.sort((a, b) => a.name.localeCompare(b.name));
            setRegionList(regionResponses)

            //get Country
            // var countryResponses = await DataStore.query(Country, Predicates.ALL, {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });
            var countryResponses = await graphQLGetAllData('listCountries')
            countryResponses = countryResponses.sort((a, b) => a.name.localeCompare(b.name));
            setCountryList(countryResponses)

            //get Brand
            // var brandResponses = await DataStore.query(Brand, Predicates.ALL, {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });
            var brandResponses = await graphQLGetAllData('listBrands')
            brandResponses = brandResponses.sort((a, b) => a.name.localeCompare(b.name));
            setBrandList(brandResponses)

            //get PG
            // var pgResponses = await DataStore.query(ProductGroup, Predicates.ALL, {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });
            var pgResponses = await graphQLGetAllData('listProductGroups')
            pgResponses = pgResponses.sort((a, b) => a.name.localeCompare(b.name));
            setProductGroupList(pgResponses)

            //get Customer Category
            // var customerCategoryResponse = await DataStore.query(CustomerCategory, Predicates.ALL, {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });
            var customerCategoryResponse = await graphQLGetAllData('listCustomerCategories')
            customerCategoryResponse = customerCategoryResponse.sort((a, b) => a.name.localeCompare(b.name));
            setCustomerCategoryList(customerCategoryResponse)

            //get Customer 
            // var customerResponse = await DataStore.query(Customer, Predicates.ALL, {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });
            var customerResponse = await graphQLGetAllData('listCustomers')
            customerResponse = customerResponse.sort((a, b) => a.name.localeCompare(b.name));
            setCustomerList(customerResponse)

            setEmployeeId(loggedEmployeeId)


            setEmployeeDropdownTeam(employeeTeam.length)
            //set financial year
            let selectedYear = reactLocalStorage.getObject('selectedYear');
            if (Object.keys(selectedYear).length !== 0) {
                setFinancialYear(selectedYear)
                setFinancialYearId(selectedYear.value)
                loadDrillDownData(selectedYear.value)
            }
            else {
                // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));

                var responses = await graphQLGetAllData('listFinancialYears', [{ fromDate: { contains: moment().format("YYYY") } }]);

                setFinancialYear({ label: responses[0].name, value: responses[0].id })
                setFinancialYearId(responses[0].id)
                loadDrillDownData(responses[0].id)
            }

            //get financial year
            // var financialYearResponses = await DataStore.query(FinancialYear, Predicates.ALL, {
            //     sort: s => s.name(SortDirection.ASCENDING)
            // });
            var financialYearResponses = await graphQLGetAllData('listFinancialYears');
            financialYearResponses = financialYearResponses.sort((a, b) => a.name.localeCompare(b.name));

            var myArray = []; myArray = ConvertResponseForSelect(financialYearResponses); setFinancialYearList(myArray);

            // get employee list
            // ORCONDITION
            // var employeeResponse = await DataStore.query(Employee, (c) => c.or(c => [
            //     c.id.eq(loggedEmployeeId),
            //     c.parentEmployeeId.eq(loggedEmployeeId)
            // ]), {
            //     sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
            // });

            var employeeResponse = [];
            employeeResponse = await graphQLGetAllData('listEmployees', [
               { parentEmployeeId: { eq: loggedEmployeeId } }
           ]);

           let employeeSelfData = await graphQLFindRecordById('getEmployee', loggedEmployeeId);
           employeeResponse.push(employeeSelfData);

           employeeResponse = employeeResponse.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));



            // var currenntEmployeeResponse = await DataStore.query(Employee, loggedEmployeeId);
            var currenntEmployeeResponse = await graphQLFindRecordById('getEmployee', loggedEmployeeId);


            var myEmployeeArray = []; myEmployeeArray = ConvertResponseForSelectTwoNames(employeeResponse);
            let zeroPositionEmployeeObject = { label: "All Employee", value: 0 }; myEmployeeArray.unshift(zeroPositionEmployeeObject);
            setEmployeeList(myEmployeeArray);
            setEmployee({ label: "All Employee", value: 0 })


            //get Parameters List
            // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
            //     c.employee_id.eq(loggedEmployeeId),
            //     c.report_manager_employee_id.notContains(loggedEmployeeId),
            //     c.financial_year_id.eq(selectedYear.value)
            // ]));

            var targetsResponses = [];
            if (loggedEmployeeId && selectedYear.value) {
                targetsResponses = await graphQLGetAllData('listTargets', [
                    { employee_id: { eq: loggedEmployeeId } },
                    { report_manager_employee_id: { notContains: loggedEmployeeId } },
                    { financial_year_id: { eq: selectedYear.value } }
                ]);

            }

            if (targetsResponses.length) {
                // var currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
                //     c.target_id.eq(targetsResponses[0].id),
                //     c.parameter_id.notContains(PARAMETER_IDs.DIO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DSO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DVRO),
                //     c.parameter_id.notContains(PARAMETER_IDs.DPO),
                // ]));

                var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                    { target_id: { eq: targetsResponses[0].id } },
                    { parameter_id: { notContains: PARAMETER_IDs.DIO } },
                    { parameter_id: { notContains: PARAMETER_IDs.DPO } },
                    { parameter_id: { notContains: PARAMETER_IDs.DVRO } },
                    { parameter_id: { notContains: PARAMETER_IDs.DSO } }
                ]);


                let _tempArray = [];
                if (currentTargetDetails.length > 0) {
                    currentTargetDetails.forEach(async (currentItem, i) => {
                        //get parameters details
                        // var parameterResponses = await DataStore.query(Parameters, (c) => c.id.eq(currentItem.parameter_id));

                        var parameterResponses = await graphQLFindRecordById('getParameters', currentItem.parameter_id);

                        let data = { "label": parameterResponses.name, "value": parameterResponses.id }
                        setParameter(data)

                        if (parameterResponses) {
                            let data = {
                                "id": currentItem.parameter_id,
                                "name": parameterResponses.name,
                            }
                            _tempArray.push(data)
                        }

                        if (i === currentTargetDetails.length - 1) {
                            let sortArray = _tempArray.sort((a, b) => (a.name > b.name ? 1 : -1))
                            var myParameterArray = []; myParameterArray = ConvertResponseForSelect(sortArray);
                            let zeroPositionParameterObject = { label: "Select Parameter", value: parameterResponses.id }; myParameterArray.unshift(zeroPositionParameterObject);
                            setParameterList(myParameterArray);
                        }
                    })

                } else {
                    setParameterList([])
                }

            }
        }
        bindList()
        loadSummaryData()
    }, [activeIndex]);


    const loadDrillDownData = async (financialYearId) => {
        setSelectedParameter("")

        // var targetsResponses = await DataStore.query(Targets, (c) => c.and(c => [
        //     c.employee_id.eq(loggedEmployeeId),
        //     c.report_manager_employee_id.notContains(loggedEmployeeId),
        //     c.financial_year_id.eq(financialYearId),
        //     c.isAssignedToEmployee.eq(true)
        // ]));

        var targetsResponses = [];
        if (loggedEmployeeId && financialYearId) {
            targetsResponses = await graphQLGetAllData('listTargets', [
                { employee_id: { eq: loggedEmployeeId } },
                { report_manager_employee_id: { notContains: loggedEmployeeId } },
                { financial_year_id: { eq: financialYearId } },
                { isAssignedToEmployee: { eq: true } }
            ]);

        }


        //Target Details
        var currentTargetDetails = [];
        if (targetsResponses.length > 0) {
            // currentTargetDetails = await DataStore.query(TargetDetails, (c) => c.and(c => [
            //     c.target_id.eq(targetsResponses[0].id),
            //     c.parameter_id.notContains(PARAMETER_IDs.DIO),
            //     c.parameter_id.notContains(PARAMETER_IDs.DSO),
            //     c.parameter_id.notContains(PARAMETER_IDs.DVRO),
            //     c.parameter_id.notContains(PARAMETER_IDs.DPO),
            // ]));

            var currentTargetDetails = await graphQLGetAllData('listTargetDetails', [
                { target_id: { eq: targetsResponses[0].id } },
                { parameter_id: { notContains: PARAMETER_IDs.DIO } },
                { parameter_id: { notContains: PARAMETER_IDs.DPO } },
                { parameter_id: { notContains: PARAMETER_IDs.DVRO } },
                { parameter_id: { notContains: PARAMETER_IDs.DSO } }
            ]);
            setCurrentTargetDetails(currentTargetDetails)
        }

        let parametersForSelectedYear = []
        if (currentTargetDetails.length) {
            for (var i = 0; i < currentTargetDetails.length; i++) {
                // var parameterResponses = await DataStore.query(Parameters, currentTargetDetails[i]["parameter_id"])
                var parameterResponses =  await graphQLFindRecordById('getParameters',  currentTargetDetails[i]["parameter_id"]);


                let data = { "label": parameterResponses.name, "value": parameterResponses.id }
                //* Hide NWC days  07-06-2023
                if (parameterResponses.name !== "NWC Days") {
                    parametersForSelectedYear.push(data)
                }
                if (i === currentTargetDetails.length - 1) {
                    setParametersForSelect(parametersForSelectedYear)
                }
            }
        }
    }

    //Show DrillDown Chart
    var [drillDownData, setDrillDownData] = useState([]);
    var [showDrillDownChart, setShowDrillDownChart] = useState(false);

    var [level1, setLevel1] = useState("");
    var [level2, setLevel2] = useState("");
    var [level3, setLevel3] = useState("");
    var [level4, setLevel4] = useState("");

    var excludeValues = [];

    if (level1) {
        excludeValues.push(level1.label);
    }
    if (level2) {
        excludeValues.push(level2.label);
    }
    if (level3) {
        excludeValues.push(level3.label);
    }
    if (level4) {
        excludeValues = [level4.label];
    }
    if (excludeValues) {
        let levelValue = excludeValues[0]
        if (levelValue !== "Select") {
            levelList = levelList.filter(function (option) {
                return excludeValues.indexOf(option.label) === -1;
            });
        }
    }

    //get sbu
    var [sbuList, setSbuList] = useState([]);
    const getSBULabel = (id) => {
        let _sbuList = [...sbuList];
        var returnRecord = _sbuList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get bu
    var [buList, setBuList] = useState([]);
    const getBULabel = (id) => {
        let _buList = [...buList];
        var returnRecord = _buList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get region 
    var [regionList, setRegionList] = useState([]);
    const getRegionLabel = (id) => {
        let _regionList = [...regionList];
        var returnRecord = _regionList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get country
    var [countryList, setCountryList] = useState([]);
    const getCountryLabel = (id) => {
        let _countryList = [...countryList];
        var returnRecord = _countryList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get Brand 
    var [brandList, setBrandList] = useState([]);
    const getBrandLabel = (id) => {
        let _brandList = [...brandList];
        var returnRecord = _brandList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get PG
    var [productGroupList, setProductGroupList] = useState([]);
    const getProductGroupLabel = (id) => {
        let _productGroupList = [...productGroupList];
        var returnRecord = _productGroupList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get Customer Category
    var [customerCategoryList, setCustomerCategoryList] = useState([]);
    const getCustomerCategoryLabel = (id) => {
        let _productGroupList = [...customerCategoryList];
        var returnRecord = _productGroupList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    //get customer
    var [customerList, setCustomerList] = useState([]);
    const getCustomerLabel = (id) => {
        let _customerList = [...customerList];
        var returnRecord = _customerList.find(o => o.id === id);
        if (returnRecord != null) {
            return returnRecord.name;
        }
    }

    const show_DrillDownChart = async () => {

        if (level1.value === "" || level1.value === undefined) {
            toast.error('Please Select Level One.');
            let convertTree = {
                "name": selectedParameter.label,
                "children": [],
            }
            setDrillDownData(convertTree)
            setShowDrillDownChart(true)
        } else {

            let getTargetDetailId = current_Target_Details.find(e => e.parameter_id === selectedParameter.value)

            // var getPlanDetails = await DataStore.query(Plan, (c) => c.target_detail_id.eq(getTargetDetailId.id))
            var getPlanDetails = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: getTargetDetailId.id } }]);


            // var loggedEmployeeResponse = await DataStore.query(Employee, (c) => c.id.eq(loggedEmployeeId));
             var loggedEmployeeResponse = await graphQLFindRecordById('getEmployee', loggedEmployeeId);

            if (loggedEmployeeResponse) {
                var param_sbu_id = loggedEmployeeResponse.param_sbu_id;
            }
            // console.log('getPlanDetails=>>>>>>>>>>>>'+JSON.stringify(getPlanDetails))
            let updateData = []

            // Object.keys(getPlanDetails).map(async (currentItem, i) => {
            //     let loopItem = getPlanDetails[currentItem];

            //     // console.log('loopItem=>>>>>>>>>>>>'+JSON.stringify(loopItem))

            //     let data = ObjectToArray(loopItem);

            //     var getSBU = await getSBULabel(param_sbu_id);
            //     if (getSBU !== null && getSBU !== "" && getSBU !== undefined) { data["SBU"] = getSBU }

            //     var getBU = await getBULabel(data["bu_id"]);
            //     if (getBU !== "" && getBU !== undefined) { data["BU"] = getBU }

            //     var getRegion = await getRegionLabel(data["region_id"]);
            //     if (getRegion !== "" && getRegion !== undefined) { data["Region"] = getRegion }

            //     var getCountry = await getCountryLabel(data["country_id"]);
            //     if (getCountry !== "" && getCountry !== undefined) { data["Country"] = getCountry }

            //     var getBrand = await getBrandLabel(data["brand_id"]);
            //     if (getBrand !== "" && getBrand !== undefined) { data["Brand"] = getBrand }

            //     var getProductGroup = await getProductGroupLabel(data["product_group_id"]);
            //     if (getProductGroup !== "" && getProductGroup !== undefined) { data["ProductGroup"] = getProductGroup }

            //     var getCustomerCategory = await getCustomerCategoryLabel(data["customer_group_id"]);
            //     if (getCustomerCategory !== "" && getCustomerCategory !== undefined) { data["CustomerGroup"] = getCustomerCategory }

            //     var getCustomer = await getCustomerLabel(data["customer_id"]);
            //     if (getCustomer !== "" && getCustomer !== undefined) { data["Customer"] = getCustomer }



            //     updateData.push(data)

            //     if (i === getPlanDetails.length - 1) {

            //         // console.log('updateData=>>>>>>>>>>>>'+JSON.stringify(data))

            //         const tree = buildTree(updateData, [level1.value, level2.value, level3.value, level4.value]);

            //         // console.log('tree=>>>>>>>>>>>>'+JSON.stringify(tree))

            //         let convertTree = {
            //             "name": selectedParameter.label,
            //             "children": tree
            //         }
            //         // console.log('convertTree=>>>>>>>>>>>>'+JSON.stringify(convertTree))

            //         setDrillDownData(convertTree)
            //         setShowDrillDownChart(true)
            //     }
            // })

            for (let i = 0; i < getPlanDetails.length; i++) {

                let SBU = ""
                var getSBU = await getSBULabel(param_sbu_id);
                if (getSBU !== null && getSBU !== "" && getSBU !== undefined) { SBU = getSBU }

                let BU = ""
                var getBU = await getBULabel(getPlanDetails[i].bu_id);
                if (getBU !== "" && getBU !== undefined) { BU = getBU }

                let Region = ""
                var getRegion = await getRegionLabel(getPlanDetails[i].region_id);
                if (getRegion !== "" && getRegion !== undefined) { Region = getRegion }

                let Country = ""
                var getCountry = await getCountryLabel(getPlanDetails[i].country_id);
                if (getCountry !== "" && getCountry !== undefined) { Country = getCountry }

                let Brand = ""
                var getBrand = await getBrandLabel(getPlanDetails[i].brand_id);
                if (getBrand !== "" && getBrand !== undefined) { Brand = getBrand }

                let ProductGroup = ""
                var getProductGroup = await getProductGroupLabel(getPlanDetails[i].product_group_id);
                if (getProductGroup !== "" && getProductGroup !== undefined) { ProductGroup = getProductGroup }

                let CustomerGroup = ""
                var getCustomerCategory = await getCustomerCategoryLabel(getPlanDetails[i].customer_group_id);
                if (getCustomerCategory !== "" && getCustomerCategory !== undefined) { CustomerGroup = getCustomerCategory }

                let Customer = ""
                var getCustomer = await getCustomerLabel(getPlanDetails[i].customer_id);
                if (getCustomer !== "" && getCustomer !== undefined) { Customer = getCustomer }

                let data = {
                    "id": getPlanDetails[i].id,
                    "target_detail_id": getPlanDetails[i].target_detail_id,
                    "sbu_id": getPlanDetails[i].sbu_id,
                    "region_id": getPlanDetails[i].region_id,
                    "country_id": getPlanDetails[i].country_id,
                    "brand_id": getPlanDetails[i].brand_id,
                    "product_group_id": getPlanDetails[i].product_group_id,
                    "customer_group_id": getPlanDetails[i].customer_group_id,
                    "customer_id": getPlanDetails[i].customer_id,
                    "target_value": getPlanDetails[i].target_value,
                    "q1_target_value": getPlanDetails[i].q1_target_value,
                    "q2_target_value": getPlanDetails[i].q2_target_value,
                    "q3_target_value": getPlanDetails[i].q3_target_value,
                    "q4_target_value": getPlanDetails[i].q4_target_value,
                    "m1_target_value": getPlanDetails[i].m1_target_value,
                    "m2_target_value": getPlanDetails[i].m2_target_value,
                    "m3_target_value": getPlanDetails[i].m3_target_value,
                    "m4_target_value": getPlanDetails[i].m4_target_value,
                    "m5_target_value": getPlanDetails[i].m5_target_value,
                    "m6_target_value": getPlanDetails[i].m6_target_value,
                    "m7_target_value": getPlanDetails[i].m7_target_value,
                    "m8_target_value": getPlanDetails[i].m8_target_value,
                    "m9_target_value": getPlanDetails[i].m9_target_value,
                    "m10_target_value": getPlanDetails[i].m10_target_value,
                    "m11_target_value": getPlanDetails[i].m11_target_value,
                    "m12_target_value": getPlanDetails[i].m12_target_value,
                    "sub_REF_CODE": getPlanDetails[i].sub_REF_CODE,
                    "region_REF_CODE": getPlanDetails[i].region_REF_CODE,
                    "country_REF_CODE": getPlanDetails[i].country_REF_CODE,
                    "brand_REF_CODE": getPlanDetails[i].brand_REF_CODE,
                    "product_group_REF_CODE": getPlanDetails[i].product_group_REF_CODE,
                    "customer_group_REF_CODE": getPlanDetails[i].customer_group_REF_CODE,
                    "customer_REF_CODE": getPlanDetails[i].customer_REF_CODE,
                    "m1_plan_actual_value": getPlanDetails[i].m1_plan_actual_value,
                    "m2_plan_actual_value": getPlanDetails[i].m2_plan_actual_value,
                    "m3_plan_actual_value": getPlanDetails[i].m3_plan_actual_value,
                    "m4_plan_actual_value": getPlanDetails[i].m4_plan_actual_value,
                    "m5_plan_actual_value": getPlanDetails[i].m5_plan_actual_value,
                    "m6_plan_actual_value": getPlanDetails[i].m6_plan_actual_value,
                    "m7_plan_actual_value": getPlanDetails[i].m7_plan_actual_value,
                    "m8_plan_actual_value": getPlanDetails[i].m8_plan_actual_value,
                    "m9_plan_actual_value": getPlanDetails[i].m9_plan_actual_value,
                    "m10_plan_actual_value": getPlanDetails[i].m10_plan_actual_value,
                    "m11_plan_actual_value": getPlanDetails[i].m11_plan_actual_value,
                    "m12_plan_actual_value": getPlanDetails[i].m12_plan_actual_value,
                    "bu_id": getPlanDetails[i].bu_id,
                    "bu_REF_CODE": getPlanDetails[i].bu_REF_CODE,
                    "SBU": SBU,
                    "BU": BU,
                    "Region": Region,
                    "Country": Country,
                    "Brand": Brand,
                    "ProductGroup": ProductGroup,
                    "CustomerGroup": CustomerGroup,
                    "Customer": Customer
                }
                updateData.push(data)

                if (i === getPlanDetails.length - 1) {
                    const tree = await buildTree(updateData, [level1.value, level2.value, level3.value, level4.value]);
                    let convertTree = {
                        "name": selectedParameter.label,
                        "children": tree,
                    }
                    setDrillDownData(convertTree)
                    setShowDrillDownChart(true)
                }
            }

        }


    }

    // function buildTree(data, keys) {
    //     const map = new Map();
    //     const root = { name: 'root', children: [] };

    //     // Group data by keys
    //     data.forEach(item => {
    //         let node = root;
    //         let drillDownDataColor;
    //         let drillDownDataColorItemStyle;

    //         keys.forEach(key => {
    //             console.log('key' + JSON.stringify(key))
    //             debugger;
    //             if (key === selectedParameter.label) {
    //                 drillDownDataColor = {
    //                     borderColor: '#FF7F01',
    //                     round: "full",
    //                     borderWidth: 2,
    //                     borderStyle: 'solid',
    //                     borderRadius: 20,
    //                     color: '#FF7F01',
    //                     symbolSize: 7,
    //                     lineStyle: {
    //                         width: 15,
    //                         curveness: 0.6,
    //                         opacity: 0.8,
    //                         color: '#FF7F01',
    //                         borderColor: '30px solid #FF7F01'
    //                     }
    //                 }

    //                 drillDownDataColorItemStyle = {
    //                     shadowColor: "#FF7F01",
    //                     shadowBlur: 10,
    //                     shadowOffsetX: 0,
    //                     shadowOffsetY: 0,
    //                     borderColor: '#FF7F01',
    //                     round: "full",
    //                     borderWidth: 2,
    //                     symbolSize: 7,
    //                     borderStyle: 'solid',
    //                     borderRadius: 20,
    //                     color: '#FF7F01',
    //                 }
    //             } else if (key === level1.value) {
    //                 drillDownDataColor = {
    //                     borderColor: '#1B55AF',
    //                     round: "full",
    //                     borderWidth: 2,
    //                     borderStyle: 'solid',
    //                     borderRadius: 20,
    //                     color: '#1B55AF',
    //                     symbolSize: 7,
    //                     lineStyle: {
    //                         width: 15,
    //                         curveness: 0.6,
    //                         opacity: 0.8,
    //                         color: '#1B55AF',
    //                         borderColor: '30px solid rgba(27, 85, 175, 0.8)'
    //                     }
    //                 }
    //                 drillDownDataColorItemStyle = {
    //                     shadowColor: "#1B55AF",
    //                     shadowBlur: 10,
    //                     shadowOffsetX: 0,
    //                     shadowOffsetY: 0,
    //                     borderColor: '#1B55AF',
    //                     round: "full",
    //                     borderWidth: 2,
    //                     symbolSize: 7,
    //                     borderStyle: 'solid',
    //                     borderRadius: 20,
    //                     color: '#1B55AF',
    //                 }
    //             } else if (key === level2.value) {
    //                 drillDownDataColor = {
    //                     borderColor: '#136EF8',
    //                     round: "full",
    //                     borderWidth: 2,
    //                     borderStyle: 'solid',
    //                     borderRadius: 20,
    //                     color: '#136EF8',
    //                     symbolSize: 7,
    //                     lineStyle: {
    //                         width: 15,
    //                         curveness: 0.6,
    //                         opacity: 0.8,
    //                         color: '#136EF8',
    //                         borderColor: '30px solid rgba(19, 110, 248, 0.75)'
    //                     }
    //                 }
    //                 drillDownDataColorItemStyle = {
    //                     shadowColor: "#136EF8",
    //                     shadowBlur: 10,
    //                     shadowOffsetX: 0,
    //                     shadowOffsetY: 0,
    //                     borderColor: '#136EF8',
    //                     round: "full",
    //                     borderWidth: 2,
    //                     symbolSize: 7,
    //                     borderStyle: 'solid',
    //                     borderRadius: 20,
    //                     color: '#136EF8',
    //                 }
    //             } else if (key === level3.value) {
    //                 drillDownDataColor = {
    //                     borderColor: '#F0074D',
    //                     round: "full",
    //                     borderWidth: 2,
    //                     borderStyle: 'solid',
    //                     borderRadius: 20,
    //                     symbolSize: 7,
    //                     color: '#F0074D',
    //                     lineStyle: {
    //                         width: 15,
    //                         curveness: 0.6,
    //                         opacity: 0.8,
    //                         color: '#F0074D',
    //                         borderColor: '20px solid rgba(240, 7, 77, 0.75)'
    //                     }
    //                 }

    //                 drillDownDataColorItemStyle = {
    //                     shadowColor: "#F0074D",
    //                     shadowBlur: 10,
    //                     shadowOffsetX: 0,
    //                     shadowOffsetY: 0,
    //                     borderColor: '#F0074D',
    //                     round: "full",
    //                     borderWidth: 2,
    //                     symbolSize: 7,
    //                     borderStyle: 'solid',
    //                     borderRadius: 20,
    //                     color: '#F0074D',
    //                 }

    //             } else if (key === level4.value) {
    //                 drillDownDataColor = {
    //                     borderColor: '#FF7F01',
    //                     round: "full",
    //                     borderWidth: 2,
    //                     symbolSize: 7,
    //                     borderStyle: 'solid',
    //                     borderRadius: 20,
    //                     color: '#FF7F01',
    //                     lineStyle: {
    //                         width: 15,
    //                         curveness: 0.6,
    //                         opacity: 0.8,
    //                         color: '#FF7F01',
    //                         borderColor: '30px solid #FF7F01'
    //                     }
    //                 }

    //                 drillDownDataColorItemStyle = {
    //                     shadowColor: "#FF7F01",
    //                     shadowBlur: 10,
    //                     shadowOffsetX: 0,
    //                     shadowOffsetY: 0,
    //                     borderColor: '#FF7F01',
    //                     round: "full",
    //                     borderWidth: 2,
    //                     symbolSize: 7,
    //                     borderStyle: 'solid',
    //                     borderRadius: 20,
    //                     color: '#FF7F01',
    //                     width: 20,
    //                 }

    //             }

    //             const value = item[key];
    //             // if (!map.has(value) && value) {
    //                 //!map.has(value)
    //                 const newNode = {
    //                     name: value,
    //                     itemStyle: drillDownDataColorItemStyle,
    //                     "lineStyle": drillDownDataColor,
    //                     label: {
    //                         normal: {
    //                             verticalAlign: 'middle',
    //                             fontSize: 14,
    //                             formatter: function (d) {
    //                                 if (d.value) {
    //                                     return (`asha`)
    //                                 }
    //                             }
    //                         }
    //                     },
    //                     children: []
    //                 };
    //                 map.set(value, newNode);
    //                 node.children.push(newNode);
    //             // }
    //             node = map.get(value);
    //         });
    //     });

    //     return root.children;
    // }

    //


    //First Level Tree
    const buildTree = async (treeData, keys) => {

        let newResponse = [];

        let drillDownDataColor = {
            borderColor: '#1B55AF',
            round: "full",
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#1B55AF',
            symbolSize: 7,
            lineStyle: {
                width: 15,
                curveness: 0.6,
                opacity: 0.8,
                color: '#1B55AF',
                borderColor: '30px solid rgba(27, 85, 175, 0.8)'
            }
        }
        let drillDownDataColorItemStyle = {
            shadowColor: "#1B55AF",
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            borderColor: '#1B55AF',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#1B55AF',
        }

        for (let i = 0; i < treeData.length; i++) {
            let checkDuplicate = [...newResponse]
            const response = treeData[i][level1.value]
            if (checkDuplicate.length > 0) {
                let check_duplicateData = checkDuplicate.every((e) => e.name !== response)

                if (check_duplicateData === true) {
                    let levelTwoResponse = await buildSecondTree(treeData, response)
                    let data = {
                        name: response,
                        totalTargetValue: "",
                        itemStyle: drillDownDataColorItemStyle,
                        "lineStyle": drillDownDataColor,
                        label: {
                            normal: {
                                verticalAlign: 'middle',
                                fontSize: 14,
                                formatter: function (d) {
                                    if (d.value) {
                                        return (`asha`)
                                    }
                                }
                            }
                        },
                        children: levelTwoResponse,

                    }
                    newResponse.push(data)
                }
            }
            else {
                let levelTwoResponse = await buildSecondTree(treeData, response)
                let data = {
                    name: response,
                    totalTargetValue: "",
                    itemStyle: drillDownDataColorItemStyle,
                    "lineStyle": drillDownDataColor,
                    label: {
                        normal: {
                            verticalAlign: 'middle',
                            fontSize: 14,
                            formatter: function (d) {
                                if (d.value) {
                                    return (`asha`)
                                }
                            }
                        }
                    },
                    children: levelTwoResponse,

                }
                newResponse.push(data)
            }
            if (i === treeData.length - 1) {

                newResponse.map((item, index) => {
                    let getChildData = item.children
                    let getChildDataValues = getChildData.map((data) => data.totalTargetValue)
                    let totalOfTargets = getChildDataValues.reduce((total, num) => total + parseInt(num), 0);
                    if ((level2.value === "" || level2.value === undefined) && (level3.value === "" || level3.value === undefined) && (level4.value === "" || level4.value === undefined)) {
                        item.children = [];
                        item.totalTargetValue = treeData[i].target_value;
                        item.name = item.name + " " + commaSeparated(Math.round(treeData[i].target_value))
                    } else {
                        item.children = item.children;
                        item.totalTargetValue = totalOfTargets;
                        item.name = item.name + " " + commaSeparated(Math.round(totalOfTargets))
                    }
                })

            }
        }
        return newResponse
    }

    //Second Level Tree
    const buildSecondTree = async (secondTreeData, levelOneValue) => {

        let drillDownDataColor = {
            borderColor: '#136EF8',
            round: "full",
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#136EF8',
            symbolSize: 7,
            lineStyle: {
                width: 15,
                curveness: 0.6,
                opacity: 0.8,
                color: '#136EF8',
                borderColor: '30px solid rgba(19, 110, 248, 0.75)'
            }
        }
        let drillDownDataColorItemStyle = {
            shadowColor: "#136EF8",
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            borderColor: '#136EF8',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#136EF8',
        }

        let newResponse = [];
        for (let i = 0; i < secondTreeData.length; i++) {
            let checkDuplicate = [...newResponse]
            const response = secondTreeData[i][level2.value]

            let firstLevelKeyValue;
            if (level1.value === "BU") { firstLevelKeyValue = secondTreeData[i].BU; } else if (level1.value === "Region") { firstLevelKeyValue = secondTreeData[i].Region; }
            else if (level1.value === "Country") { firstLevelKeyValue = secondTreeData[i].Country; } else if (level1.value === "Brand") { firstLevelKeyValue = secondTreeData[i].Brand; } else if (level1.value === "ProductGroup") { firstLevelKeyValue = secondTreeData[i].ProductGroup; }
            else if (level1.value === "CustomerGroup") { firstLevelKeyValue = secondTreeData[i].CustomerGroup; } else if (level1.value === "Customer") { firstLevelKeyValue = secondTreeData[i].Customer; }

            // const key = Object.keys(secondTreeData[i]).find(key => secondTreeData[i][key] === levelOneValue);

            if (firstLevelKeyValue === levelOneValue) {
                if (checkDuplicate.length > 0) {
                    let check_duplicateData = checkDuplicate.every((e) => e.name !== response)
                    if (check_duplicateData === true) {
                        let levelThreeResponse = await buildThirdTree(secondTreeData, levelOneValue, response)
                        let data = {
                            name: response,
                            totalTargetValue: "",
                            itemStyle: drillDownDataColorItemStyle,
                            "lineStyle": drillDownDataColor,
                            label: {
                                normal: {
                                    verticalAlign: 'middle',
                                    fontSize: 14,
                                    formatter: function (d) {
                                        if (d.value) {
                                            return (`asha`)
                                        }
                                    }
                                }
                            },
                            children: levelThreeResponse,

                        }
                        newResponse.push(data)
                    }
                }
                else {
                    let levelThreeResponse = await buildThirdTree(secondTreeData, levelOneValue, response)
                    let data = {
                        name: response,
                        totalTargetValue: "",
                        itemStyle: drillDownDataColorItemStyle,
                        "lineStyle": drillDownDataColor,
                        label: {
                            normal: {
                                verticalAlign: 'middle',
                                fontSize: 14,
                                formatter: function (d) {
                                    if (d.value) {
                                        return (`asha`)
                                    }
                                }
                            }
                        },
                        children: levelThreeResponse
                    }
                    newResponse.push(data)
                }
            }

            if (i === secondTreeData.length - 1) {

                newResponse.map((item, index) => {
                    let getChildData = item.children
                    let getChildDataValues = getChildData.map((data) => data.totalTargetValue)
                    let totalOfTargets = getChildDataValues.reduce((total, num) => total + parseInt(num), 0);
                    // item.name = item.name + "(" + totalOfTargets + ")"
                    item.totalTargetValue = totalOfTargets;
                    if ((level3.value === "" || level3.value === undefined) && (level4.value === "" || level4.value === undefined)) {
                        item.children = [];
                        item.totalTargetValue = secondTreeData[i].target_value;
                        item.name = item.name + " " + commaSeparated(Math.round(secondTreeData[i].target_value))
                    } else {
                        item.children = item.children;
                        item.totalTargetValue = totalOfTargets;
                        item.name = item.name + " " + commaSeparated(Math.round(totalOfTargets))
                    }
                })
            }
        }
        return newResponse
    }

    //Third Level Tree
    const buildThirdTree = async (thirdTreeData, levelOneValue, levelTwoValue) => {

        let drillDownDataColor = {
            borderColor: '#F0074D',
            round: "full",
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 20,
            symbolSize: 7,
            color: '#F0074D',
            lineStyle: {
                width: 15,
                curveness: 0.6,
                opacity: 0.8,
                color: '#F0074D',
                borderColor: '20px solid rgba(240, 7, 77, 0.75)'
            }
        }
        let drillDownDataColorItemStyle = {
            shadowColor: "#F0074D",
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            borderColor: '#F0074D',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#F0074D',
        }

        let newResponse = [];
        for (let i = 0; i < thirdTreeData.length; i++) {
            let checkDuplicate = [...newResponse]
            const response = thirdTreeData[i][level3.value]

            let firstLevelKeyValue;
            if (level1.value === "BU") { firstLevelKeyValue = thirdTreeData[i].BU; } else if (level1.value === "Region") { firstLevelKeyValue = thirdTreeData[i].Region; }
            else if (level1.value === "Country") { firstLevelKeyValue = thirdTreeData[i].Country; } else if (level1.value === "Brand") { firstLevelKeyValue = thirdTreeData[i].Brand; } else if (level1.value === "ProductGroup") { firstLevelKeyValue = thirdTreeData[i].ProductGroup; }
            else if (level1.value === "CustomerGroup") { firstLevelKeyValue = thirdTreeData[i].CustomerGroup; } else if (level1.value === "Customer") { firstLevelKeyValue = thirdTreeData[i].Customer; }

            let secondLevelKeyValue;
            if (level2.value === "BU") { secondLevelKeyValue = thirdTreeData[i].BU; } else if (level2.value === "Region") { secondLevelKeyValue = thirdTreeData[i].Region; }
            else if (level2.value === "Country") { secondLevelKeyValue = thirdTreeData[i].Country; } else if (level2.value === "Brand") { secondLevelKeyValue = thirdTreeData[i].Brand; } else if (level2.value === "ProductGroup") { secondLevelKeyValue = thirdTreeData[i].ProductGroup; }
            else if (level2.value === "CustomerGroup") { secondLevelKeyValue = thirdTreeData[i].CustomerGroup; } else if (level2.value === "Customer") { secondLevelKeyValue = thirdTreeData[i].Customer; }

            // const firstLevelKey = Object.keys(thirdTreeData[i]).find(key => thirdTreeData[i][key] === levelOneValue);
            // const secondLevelKey = Object.keys(thirdTreeData[i]).find(key => thirdTreeData[i][key] === levelTwoValue);

            if (firstLevelKeyValue === levelOneValue && secondLevelKeyValue === levelTwoValue) {
                if (checkDuplicate.length > 0) {
                    let check_duplicateData = checkDuplicate.every((e) => e.name !== response)
                    if (check_duplicateData === true) {
                        let levelForthResponse = await buildForthTree(thirdTreeData, levelOneValue, levelTwoValue, response)
                        let data = {
                            name: response,
                            totalTargetValue: "",
                            itemStyle: drillDownDataColorItemStyle,
                            "lineStyle": drillDownDataColor,
                            label: {
                                normal: {
                                    verticalAlign: 'middle',
                                    fontSize: 14,
                                    formatter: function (d) {
                                        if (d.value) {
                                            return (`asha`)
                                        }
                                    }
                                }
                            },
                            children: levelForthResponse,
                        }
                        newResponse.push(data)
                    }
                }
                else {
                    let levelForthResponse = await buildForthTree(thirdTreeData, levelOneValue, levelTwoValue, response)
                    let data = {
                        name: response,
                        totalTargetValue: "",
                        itemStyle: drillDownDataColorItemStyle,
                        "lineStyle": drillDownDataColor,
                        label: {
                            normal: {
                                verticalAlign: 'middle',
                                fontSize: 14,
                                formatter: function (d) {
                                    if (d.value) {
                                        return (`asha`)
                                    }
                                }
                            }
                        },
                        children: levelForthResponse,
                    }
                    newResponse.push(data)
                }
            }

            if (i === thirdTreeData.length - 1) {
                newResponse.map((item, index) => {
                    let getChildData = item.children
                    let getChildDataValues = getChildData.map((data) => data.totalTargetValue)
                    let totalOfTargets = getChildDataValues.reduce((total, num) => total + parseInt(num), 0);
                    // item.name = item.name + "(" + totalOfTargets + ")"
                    item.totalTargetValue = totalOfTargets;
                    if ((level4.value === "" || level4.value === undefined)) {
                        item.children = [];
                        item.totalTargetValue = thirdTreeData[i].target_value;
                        item.name = item.name + " " + commaSeparated(Math.round(thirdTreeData[i].target_value))
                    } else {
                        item.children = item.children;
                        item.totalTargetValue = totalOfTargets;
                        item.name = item.name + " " + commaSeparated(Math.round(totalOfTargets))
                    }
                })
            }
        }
        return newResponse
    }

    //Forth Level Tree
    const buildForthTree = async (forthTreeData, levelOneValue, levelTwoValue, levelThreeValue) => {

        let drillDownDataColor = {
            borderColor: '#FF7F01',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#FF7F01',
            lineStyle: {
                width: 15,
                curveness: 0.6,
                opacity: 0.8,
                color: '#FF7F01',
                borderColor: '30px solid #FF7F01'
            }
        }
        let drillDownDataColorItemStyle = {
            shadowColor: "#FF7F01",
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            borderColor: '#FF7F01',
            round: "full",
            borderWidth: 2,
            symbolSize: 7,
            borderStyle: 'solid',
            borderRadius: 20,
            color: '#FF7F01',
            width: 20,
        }

        let newResponse = [];

        for (let i = 0; i < forthTreeData.length; i++) {
            let checkDuplicate = [...newResponse]
            const response = forthTreeData[i][level4.value]

            let firstLevelKeyValue;
            if (level1.value === "BU") { firstLevelKeyValue = forthTreeData[i].BU; } else if (level1.value === "Region") { firstLevelKeyValue = forthTreeData[i].Region; }
            else if (level1.value === "Country") { firstLevelKeyValue = forthTreeData[i].Country; } else if (level1.value === "Brand") { firstLevelKeyValue = forthTreeData[i].Brand; } else if (level1.value === "ProductGroup") { firstLevelKeyValue = forthTreeData[i].ProductGroup; }
            else if (level1.value === "CustomerGroup") { firstLevelKeyValue = forthTreeData[i].CustomerGroup; } else if (level1.value === "Customer") { firstLevelKeyValue = forthTreeData[i].Customer; }

            let secondLevelKeyValue;
            if (level2.value === "BU") { secondLevelKeyValue = forthTreeData[i].BU; } else if (level2.value === "Region") { secondLevelKeyValue = forthTreeData[i].Region; }
            else if (level2.value === "Country") { secondLevelKeyValue = forthTreeData[i].Country; } else if (level2.value === "Brand") { secondLevelKeyValue = forthTreeData[i].Brand; } else if (level2.value === "ProductGroup") { secondLevelKeyValue = forthTreeData[i].ProductGroup; }
            else if (level2.value === "CustomerGroup") { secondLevelKeyValue = forthTreeData[i].CustomerGroup; } else if (level2.value === "Customer") { secondLevelKeyValue = forthTreeData[i].Customer; }

            let thirdLevelKeyValue;
            if (level3.value === "BU") { thirdLevelKeyValue = forthTreeData[i].BU; } else if (level3.value === "Region") { thirdLevelKeyValue = forthTreeData[i].Region; }
            else if (level3.value === "Country") { thirdLevelKeyValue = forthTreeData[i].Country; } else if (level3.value === "Brand") { thirdLevelKeyValue = forthTreeData[i].Brand; } else if (level3.value === "ProductGroup") { thirdLevelKeyValue = forthTreeData[i].ProductGroup; }
            else if (level3.value === "CustomerGroup") { thirdLevelKeyValue = forthTreeData[i].CustomerGroup; } else if (level3.value === "Customer") { thirdLevelKeyValue = forthTreeData[i].Customer; }


            if (firstLevelKeyValue === levelOneValue && secondLevelKeyValue === levelTwoValue && thirdLevelKeyValue === levelThreeValue) {
                if (checkDuplicate.length > 0) {
                    let check_duplicateData = checkDuplicate.every((e) => e.name !== response)
                    if (check_duplicateData === true) {
                        let data = {
                            name: response,
                            targetValue: [forthTreeData[i].target_value],
                            totalTargetValue: "",
                            itemStyle: drillDownDataColorItemStyle,
                            "lineStyle": drillDownDataColor,
                            label: {
                                normal: {
                                    verticalAlign: 'middle',
                                    fontSize: 14,
                                    formatter: function (d) {
                                        if (d.value) {
                                            return (`asha`)
                                        }
                                    }
                                }
                            },
                            children: [],

                        }
                        newResponse.push(data)
                    } else {
                        checkDuplicate[0].targetValue.push(forthTreeData[i].target_value)
                    }
                }
                else {
                    let data = {
                        name: response,
                        targetValue: [forthTreeData[i].target_value],
                        totalTargetValue: "",
                        itemStyle: drillDownDataColorItemStyle,
                        "lineStyle": drillDownDataColor,
                        label: {
                            normal: {
                                verticalAlign: 'middle',
                                fontSize: 14,
                                formatter: function (d) {
                                    if (d.value) {
                                        return (`asha`)
                                    }
                                }
                            }
                        },
                        children: [],
                    }
                    newResponse.push(data)
                }
            }

            if (i === forthTreeData.length - 1) {
                newResponse.map((item, index) => {
                    let totalOfTargets = item.targetValue.reduce((total, num) => total + parseInt(num), 0);
                    item.name = item.name + " " + commaSeparated(Math.round(totalOfTargets))
                    item.totalTargetValue = totalOfTargets;
                })
            }
        }
        return newResponse
    }

    const option = {
        tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
        },
        series: [
            {
                type: 'tree',
                data: [drillDownData],
                top: '1%',
                left: '7%',
                bottom: '1%',
                edgeShape: 'curve',
                right: '20%',
                symbolSize: 80,
                height: 500,
                label: {
                    normal: {
                        verticalAlign: 'middle',
                        align: 'middle',
                        fontSize: 14,
                        formatter: function (d) {
                            if (d.value) {
                                return (`asha`)
                            }
                        }
                    }
                },
                lineStyle: {
                    width: 20,
                    color: '#FF7F01CC'
                },
                leaves: {
                    label: {
                        position: 'right',
                        verticalAlign: 'middle',
                        align: 'left'
                    }
                },
                initialTreeDepth: 1,
                expandAndCollapse: true,
                animationDuration: 550,
                animationDurationUpdate: 750
            },

        ]
    };

    //Bar chart
    const barChartOption = {
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
        },
        yAxis: {
            type: 'category',
            data: ['Qtr 1', 'Qtr 2', 'Qtr 3', 'Qtr 4']
        },
        series: [
            {
                name: 'Target',
                type: 'bar',
                data: quarterTargetValue,
                itemStyle: {
                    color: '#4FB155' // Change the color to blue
                }
            },
            {
                name: 'Actuals',
                type: 'bar',
                data: quarterActualsValue,
                itemStyle: {
                    color: '#268AFF' // Change the color to blue
                }
            }
        ]
    };

    function onClickParameter(data) {
        setQuarterTargetValue([data.q1_target_value, data.q2_target_value, data.q3_target_value, data.q4_target_value])
        setQuarterActualsValue([data.q1_actual_value, data.q2_actual_value, data.q3_actual_value, data.q4_actual_value])
        setParameterName(data.parameter_name)
    }

    return isAuthenticated ? (
        <div className="pt-16">
            <React.StrictMode>
                <TopNavBar />
                <LeftSideBar />

                <div className="dashboard-main-wrapper dashboard-new pl-16" x-data="{tab: 1}">
                    <div className="p-5 pb-5">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Dashboard</div>
                            <div className="tabs_two">
                                <ul className="flex">
                                    {!checkEmployeeTeamType && <li><Link to="/employee/dashboard/business/planning" >Business</Link></li>}
                                    <li><Link className="active">Sales</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="lg:flex justify-between my-6 items-center align-center">
                            {
                                isReportingManager === "1" ?
                                    <div className="tabs_two">
                                        <ul className="flex">
                                            <li><Link to="/employee/dashboard/sales/planning" >Planning</Link></li>
                                            <li><Link to="/employee/dashboard/sales/evaluation" >Evaluation</Link></li>
                                            <li><Link to="/employee/dashboard/sales/performance" className="active">Performance</Link></li>
                                        </ul>
                                    </div> : <div className="tabs_two" />
                            }
                            <div className="mt-5 lg:mt-0 flex justify-end gap-2 items-center">
                                {
                                    activeIndex !== 1 &&
                                        isReportingManager === "1" ?
                                        <div className="w-40">
                                            <Select
                                                options={employeeList}
                                                value={employee}
                                                onChange={(e) => {
                                                    handleClickEmployeeDropdown(e);
                                                }}
                                                maxMenuHeight={200}
                                                placeholder="Employee Name"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div> : ""
                                }
                                {
                                    activeIndex === 1 || activeIndex === 3 ?
                                        <div className="w-38">
                                            <Select
                                                options={parameterList}
                                                value={parameter}
                                                onChange={(e) => {
                                                    handleClickParameterDropdown(e);
                                                }}
                                                maxMenuHeight={200} placeholder="Parameters"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div> : null
                                }
                                {

                                    <div className="w-38">
                                        <Select
                                            options={financialYearList}
                                            value={financialYear}
                                            onChange={(e) => {
                                                handleClickFinanCialYearDropdown(e);
                                            }}
                                            maxMenuHeight={200} placeholder="Select Year"
                                            className="my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>
                                }
                                {
                                    <div className="w-40">
                                        <Select
                                            options={quarterList}
                                            value={quarter}
                                            onChange={(e) => {
                                                setQuarter(e)
                                                handleClickQuarterDropdown(e)
                                            }}
                                            maxMenuHeight={200} placeholder="Select Quarter"
                                            className="my-react-select-container"
                                            classNamePrefix="my-react-select"
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                        <div className='circulartab'>
                            <TabView activeIndex={activeIndex} onTabChange={(e) => {
                                setActiveIndex(e.index)
                            }}>
                                <TabPanel header="Summary">
                                    <div className="bg-white rounded-lg p-3 px-5 dark:bg-[#2A2E32] shadow-lg">
                                        <div className="flex gap-8 h-auto divide-x-2 divide-[#E4E7EC] dark:divide-[#1b1f22]">
                                            <div className='flex  items-center gap-4'>
                                                <img src={loggedEmployeeProfile} alt="" className='w-14 h-14 rounded-full' />
                                                <div>
                                                    <p className="text-[#667085] text-[13px] font-normal"> Emp ID: {loggedEmployeeCode}</p>
                                                    <h3 className="text-[#101828] text-[16px] font-semibold dark:text-white">{loggedEmployeeName}</h3>
                                                </div>
                                            </div>
                                            <div className='flex  items-center gap-3 px-4'>
                                                <div>
                                                    <p className="text-[#667085] text-[13px] font-normal"> Role</p>
                                                    <h3 className="text-[#101828] text-[16px] font-semibold  dark:text-white">{loggedEmployeeRole}</h3>
                                                </div>
                                            </div>
                                            <div className='flex  items-center gap-3 px-4'>
                                                <div>
                                                    <p className="text-[#667085] text-[13px] font-normal"> SBU</p>
                                                    <h3 className="text-[#101828] text-[16px] font-semibold  dark:text-white">{sbu}</h3>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {
                                        summaryData.length > 0 ?
                                            <div>
                                                <div className="bg-white rounded-lg p-4 px-5 dark:bg-[#2A2E32] shadow-lg mt-5">
                                                    <div className="flex-row grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4">

                                                        <div className='border border-[#E4E7EC] py-4 px-5 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                            <h4 className='text-[#101828] text-[#101828] text-[16px] mb-2  font-medium dark:text-[#fff]'>{"Consolidated Score"}</h4>
                                                            <div className='flex mt-5 gap-6 items-center'>
                                                                <div className='grow'>
                                                                    <h3 className='text-[#344054] text-[#101828] text-[30px] font-semibold leading-none dark:text-[#fff]'>
                                                                        {consolidatedValue + "%"}
                                                                    </h3>
                                                                    <h6 className='text-[#667085] text-[14px] text-[14px] dark:text-[#f2f2f2]'>
                                                                        Weightage
                                                                    </h6>
                                                                </div>
                                                                <div className='w-24 h-24 mx-auto'>
                                                                    <CircularProgressBar value={parseInt(consolidatedScore)} text={consolidatedScore + "%"} pathColor="#1882FF" trailColor="#83C888" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            summaryData.map((item, index) => {
                                                                return (
                                                                    <Link id={index.toString()} onClick={() => { onClickParameter(item); }}
                                                                        className={
                                                                            item.parameter_name !== parameterName ?
                                                                                "border border-[#E4E7EC]  py-4 px-5 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]" :
                                                                                "border border-[green] border-2 bg-[#f3fffd] py-4 px-5 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]"
                                                                        }>
                                                                        <h4 className='  text-[16px] mb-2  font-medium dark:text-[#fff]'>{item.parameter_name}</h4>
                                                                        <div className='flex mt-5 gap-6 items-center'>
                                                                            <div className='grow'>
                                                                                <h3 className='text-[#344054]  text-[30px] font-semibold leading-none dark:text-[#fff]'>
                                                                                    {item.parameter_weightage === "-" ? "-" : item.parameter_weightage + "%"}
                                                                                </h3>
                                                                                <h6 className='text-[14px] dark:text-[#f2f2f2]'>
                                                                                    Weightage
                                                                                </h6>
                                                                            </div>
                                                                            <div className='w-24 h-24 mx-auto'>
                                                                                <CircularProgressBar value={item.originalWtAch} text={item.wtAch} pathColor={"#1882FF"} trailColor="#83C888" />
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                )

                                                            })

                                                        }
                                                    </div>
                                                </div>
                                                <div className='bg-white rounded-lg p-4 px-5 dark:bg-[#2A2E32] shadow-lg mt-5'>
                                                    <div className="flex-row grid 2xl:grid-cols-7 xl:grid-cols-7 lg:grid-cols-7 md:grid-cols-6 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4">
                                                        <div className='bg-white col-span-3 border border-[#E4E7EC] py-4 px-4 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                            <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'> Actual vs Target - {parameterName}</h6>
                                                            <div>
                                                                <ReactEcharts option={barChartOption} />
                                                            </div>
                                                        </div>
                                                        <div className='col-span-4 bg-white border border-[#E4E7EC] py-4 px-4 shadow-md rounded-md dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                            <div className='flex justify-between'>
                                                                <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'> Brands (Actual Ach.%) - {parameterName}</h6>
                                                                {/* <TabMenu model={items} /> */}
                                                            </div>

                                                            <div className={'flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap- gap-y-4'}>
                                                                {
                                                                    brandsData.map((item, index) => {
                                                                        if (index >= 3) {
                                                                            return null
                                                                        } else {
                                                                            return (
                                                                                <div>
                                                                                    <GaugeChart
                                                                                        key={index}
                                                                                        name={item.Brand}
                                                                                        value={item.Percentage}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='accordionmulti mt-5'>
                                                    <div className='bg-white rounded-lg p-4 px-5 dark:bg-[#2A2E32] shadow-lg mt-5'>
                                                        <div className='overflow-auto'>
                                                            <DataTable
                                                                paginator value={summaryData} responsiveLayout="scroll" style={{ width: '93vw' }}
                                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                                            >
                                                                {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
                                                                <Column field="parameter_name" footer="Total" header="Parameter" sortable filter filterPlaceholder="Search by Name"></Column>
                                                                <Column field="parameter_type" header="Unit" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                                                <Column field="parameter_weightage" header="Weightage" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                                                <Column field="target_value" header="Target" sortable filter filterPlaceholder="Search by Name"></Column>
                                                                <Column field="actualPerformance" header="Actual Performance" sortable filter filterPlaceholder="Search by Role"></Column>
                                                                <Column field="actualAch" header="Actual %Ach" sortable filter filterPlaceholder="Search by Role"></Column>
                                                                <Column field="wtAch" header="Wt Ach(%)" sortable filter filterPlaceholder="Search by Role"></Column>
                                                                {/* <Column field="" header="Score %" sortable filter filterPlaceholder="Search by Role"></Column> */}
                                                            </DataTable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ flex: 1, paddingTop: 20 }}>
                                                <h1 style={{ textAlign: "center" }}>No Data Found</h1>
                                            </div>
                                    }
                                </TabPanel>


                                <TabPanel header="Parameter Analysis">
                                    {
                                        showParameterAnalysisData &&
                                        <div className='rounded-lg p-4 px-2 dark:bg-[#2A2E32] mt-5'>
                                            {
                                                parameterAnalysisData !== "" ?
                                                    <div className="flex-row grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4">
                                                        <div className='shadow-lg bg-white border border-[#E4E7EC] py-4 px-4 pb-5  rounded-xl dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                            <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'>Team {parameterAnalysisData.parameter}</h6>

                                                            <div className='bg-white rounded-md border border-[#E4E7EC] p-2 px-3 dark:bg-[#2A2E32] teamrevenueboxshadow mt-5 mb-3'>
                                                                <div className='flex mt-3 gap-6 items-center mb-4'>
                                                                    <div className="">
                                                                        <h6 className='text-[#667085] text-[14px] text-[12px] font-medium dark:text-[#f2f2f2]'>
                                                                            This
                                                                            year
                                                                        </h6>
                                                                        <h3 className='text-[#344054] text-[#101828] text-[24px] font-semibold leading-none dark:text-[#fff]'>
                                                                            {parameterAnalysisData.parameterType === "A" ? "$" : parameterAnalysisData.parameterType === "D" ? "Days " : parameterAnalysisData.parameterType === "N" ? "Number " : ""}
                                                                            {commaSeparated(parameterAnalysisYearValue)}
                                                                            {parameterAnalysisData.parameterType === "P" ? " %" : ""}

                                                                        </h3>

                                                                    </div>
                                                                    <div className='w-full smoothlinechart'>
                                                                        <SmoothLineChart
                                                                            data={parameterAnalysisData.teamTrendsTarget}
                                                                        />
                                                                        {/* <SmoothLineChart
                                                                            data={[1, -2, 2, 5, 3, 2, 100]}
                                                                            markPointValue={parameterAnalysisData.myData ? convertNumberToShortNumber(parameterAnalysisData.myData[0].thisYear) : "0"} unit={parameterAnalysisData.parameterType === "A" ? "$" : parameterAnalysisData.parameterType === "D" ? "Days " : parameterAnalysisData.parameterType === "N" ? "Number " : ""} perUnit={parameterAnalysisData.parameterType === "P" ? " %" : ""} totalValue={parameterAnalysisData.myData ? convertNumberToShortNumber(parameterAnalysisData.myData[0].thisYear) : "0"}
                                                                        /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='bg-white rounded-md border border-[#E4E7EC] p-2 px-3 dark:bg-[#2A2E32] teamrevenueboxshadow mt-5 mb-0'>
                                                                <div className='flex mt-3 gap-6 items-center mb-4'>
                                                                    <div className="">
                                                                        <h6 className='text-[#667085] text-[14px] text-[12px] font-medium dark:text-[#f2f2f2]'>
                                                                            Current Quarter
                                                                        </h6>
                                                                        <h3 className='text-[#344054] text-[#101828] text-[24px] font-semibold leading-none dark:text-[#fff]'>
                                                                            {parameterAnalysisData.parameterType === "A" ? "$" : parameterAnalysisData.parameterType === "D" ? "Days " : parameterAnalysisData.parameterType === "N" ? "Number " : ""}
                                                                            {commaSeparated(parameterAnalysisQuarterValue)}
                                                                            {parameterAnalysisData.parameterType === "P" ? " %" : ""}
                                                                        </h3>
                                                                    </div>
                                                                    <div className='w-full smoothlinechart'>
                                                                        <SmoothLineChart
                                                                            data={parameterAnalysisData.teamTrendsActual}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className='col-span-2'>
                                                            <div className="flex-row grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4">
                                                                <div className='col-span-1 shadow-lg bg-white border border-[#E4E7EC] py-4 px-4  rounded-xl dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                                    {/* <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'>Team Trend</h6> */}
                                                                    <div>
                                                                        <StackedLineChart
                                                                            title='Team Trends'
                                                                            data={parameterAnalysisData}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-span-1 border shadow-lg bg-white border border-[#E4E7EC] py-4 px-4  rounded-xl dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                                    <div className=''>
                                                                        <h6 className='text-[#101828] text-[#101828] text-[18px] mb-2  font-medium dark:text-[#fff]'>Leaderboard</h6>

                                                                        <BarChart
                                                                            data={parameterAnalysisData}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ flex: 1 }}>
                                                        <h1 style={{ textAlign: "center" }}>No Data Found</h1>
                                                    </div>
                                            }

                                            {/* <div className="flex-row grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4 mt-5">
                                            <div className="">
                                                <Select
                                                    options={setTopList}
                                                    onChange={(e) => {
                                                        handleClickTopList(e);
                                                    }}
                                                    maxMenuHeight={200} placeholder="Select Year"
                                                    className="my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>
                                        </div> */}

                                            <div className="flex-row grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1 sm:grid-cols-1 gap-4 gap-y-4 mt-5">

                                                {
                                                    employeeTeam &&
                                                    employeeTeam.map((user, index) => {
                                                        return <div className='shadow-lg bg-white border border-[#E4E7EC] py-4 px-4 pb-5  rounded-lg dark:border-[#1b1f22] dark:bg-[#212529]'>
                                                            <div className="flex  items-center gap-4">
                                                                <img src={UserImg} alt="" className="w-12 h-12 rounded-full" />
                                                                <div>
                                                                    <h3 className="text-[#101828] text-[16px] font-semibold dark:text-white">{user.name}</h3>
                                                                    <p className="text-[#667085] text-[13px] font-normal"> Emp ID: {user.empId}</p>
                                                                </div>
                                                            </div>
                                                            <div className='bg-white rounded-md border border-[#E4E7EC] p-2 px-3 dark:bg-[#2A2E32] teamrevenueboxshadow mt-5 mb-2'>
                                                                <div className='flex mt-3 gap-6 items-center mb-4'>
                                                                    <div className="">
                                                                        <h6 className='text-[#667085] text-[14px] text-[12px] font-medium dark:text-[#f2f2f2]'>
                                                                            This year
                                                                        </h6>
                                                                        <h3 className='text-[#344054] text-[#101828] text-[24px] font-semibold leading-none dark:text-[#fff]'>
                                                                            {
                                                                                user.parameterType === PARAMETERS_TYPES.Amount ? "$" + commaSeparated(user.thisYearTarget) : user.thisYearTarget
                                                                            }
                                                                        </h3>
                                                                    </div>
                                                                    <div className='w-full smoothlinechart'>
                                                                        {
                                                                            user.parameterType === PARAMETERS_TYPES.Amount ? "$" + commaSeparated(user.planActual) : user.planActual
                                                                        }
                                                                        <ProgressBar value={user.actualAch}></ProgressBar></div>
                                                                </div>
                                                            </div>
                                                            <div className='bg-white rounded-md border border-[#E4E7EC] p-2 px-3 dark:bg-[#2A2E32] teamrevenueboxshadow mt-2 '>
                                                                <div className='flex mt-3 gap-6 items-center mb-4'>
                                                                    <div className="">
                                                                        <h6 className='text-[#667085] text-[14px] text-[12px] font-medium dark:text-[#f2f2f2]'>
                                                                            Current Quarter
                                                                        </h6>
                                                                        <h3 className='text-[#344054] text-[#101828] text-[24px] font-semibold leading-none dark:text-[#fff]'>
                                                                            {
                                                                                user.parameterType === PARAMETERS_TYPES.Amount ? "$" + commaSeparated(user.currentQuaterTarget) : user.currentQuaterTarget
                                                                            }
                                                                        </h3>
                                                                    </div>
                                                                    <div className='w-full smoothlinechart'>
                                                                        <SmoothLineChart
                                                                            data={user.empQuarterWiseProgress}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }

                                            </div>
                                        </div>
                                    }


                                    {
                                        !showParameterAnalysisData &&
                                        <div className='text-center py-8' ><ProgressSpinner /></div>
                                    }

                                </TabPanel>

                                <TabPanel header="Drilldown Analysis" >
                                    <div className='flex gap-2 justify-end'>
                                        <div className='width-20'>
                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'>Choose Parameter</label>
                                            {/* /*********** dummy select2 ********** */}
                                            <Select
                                                options={parametersForSelect}
                                                value={selectedParameter}
                                                onChange={e => { setSelectedParameter(e) }}
                                                maxMenuHeight={200}
                                                placeholder="Select Parameter"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>
                                        <div className='width-20'>
                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'> Level 1</label>
                                            <Select
                                                options={levelList}
                                                value={level1}
                                                onChange={e => { setLevel1(e) }}
                                                maxMenuHeight={200}
                                                placeholder="Select"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>

                                        <div className='width-20'>
                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'> Level 2</label>
                                            <Select
                                                options={levelList}
                                                value={level2}
                                                onChange={e => { setLevel2(e) }}
                                                maxMenuHeight={200}
                                                placeholder="Select"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>

                                        <div className='width-20'>
                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'> Level 3</label>
                                            <Select
                                                options={levelList}
                                                value={level3}
                                                onChange={e => { setLevel3(e) }}
                                                maxMenuHeight={200}
                                                placeholder="Select"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>

                                        <div className='width-20'>
                                            <label className=' text-[#344054] text-[12px] font-normal leading-none'> Level 4</label>
                                            <Select
                                                options={levelList}
                                                value={level4}
                                                onChange={e => { setLevel4(e) }}
                                                maxMenuHeight={200}
                                                placeholder="Select"
                                                className="my-react-select-container"
                                                classNamePrefix="my-react-select"
                                            />
                                        </div>

                                        <div className='mt-8'>
                                            <Link onClick={() => { show_DrillDownChart() }} className={"bg-[#029046] border border-[#029046] rounded-md text-white px-4 py-2"}>
                                                Go
                                            </Link>
                                        </div>

                                    </div>
                                    <div className='drilldown'>
                                        {
                                            showDrillDownChart &&
                                            <ReactEcharts option={option} />
                                        }
                                    </div>

                                </TabPanel>


                                {/* // LAST TAB */}
                                <TabPanel header="Detailed View">
                                    <div className='accordionmulti mt-5'>
                                        <div className='bg-white rounded-lg p-4 px-5 dark:bg-[#2A2E32] shadow-lg mt-5'>

                                            {
                                                <DataTable
                                                    paginator value={parameterDetailViewList} responsiveLayout="scroll" style={{ width: '93vw' }}
                                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={50} rowsPerPageOptions={[50, 100, 200]}
                                                >
                                                    {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
                                                    <Column field="buName" header="BU" sortable filter filterPlaceholder="Search by BU"></Column>
                                                    <Column field="regionName" header="Region" sortable filter filterPlaceholder="Search by Region Name"></Column>
                                                    <Column field="countryName" header="Country" sortable filter filterPlaceholder="Search by Country Name"></Column>
                                                    <Column field="brandName" header="Brand" sortable filter filterPlaceholder="Search by Brand Name"></Column>
                                                    <Column field="pGName" header="Product Group" sortable filter filterPlaceholder="Search by Product Group"></Column>
                                                    <Column field="custCatName" header="Customer Group" sortable filter filterPlaceholder="Search by Customer Cat"></Column>
                                                    <Column field="customerName" header="Customer" sortable filter filterPlaceholder="Search by Customer"></Column>

                                                    <Column field="target_value" header="Target" sortable filter filterPlaceholder="Search by Target"></Column>
                                                    <Column field="target_Actual_value" header="Actual" sortable filter filterPlaceholder="Search by Actual"></Column>
                                                    <Column field="target_Actual_value_Ach" header="% Ach." sortable filter filterPlaceholder="Search by % Ach."></Column>
                                                    {
                                                        Q1Check === true || allQCheck === true ?
                                                            <Column field="q1_target_value" header="Q1 Target" sortable filter filterPlaceholder="Search by Q1 Target"></Column> : null
                                                    }

                                                    {
                                                        Q1Check === true || allQCheck === true ?
                                                            <Column field="q1_target_Actual_value" header="Q1 Actual" sortable filter filterPlaceholder="Search by Q1 Actual"></Column> : null
                                                    }

                                                    {
                                                        Q1Check === true || allQCheck === true ?
                                                            <Column field="q1_target_Actual_value_Ach" header="% Ach." sortable filter filterPlaceholder="Search by % Ach."></Column> : null
                                                    }

                                                    {
                                                        Q2Check === true || allQCheck === true ?
                                                            <Column field="q2_target_value" header="Q2 Target" sortable filter filterPlaceholder="Search by Q2 Target"></Column> : null
                                                    }

                                                    {
                                                        Q2Check === true || allQCheck === true ?
                                                            <Column field="q2_target_Actual_value" header="Q2 Actual" sortable filter filterPlaceholder="Search by Q2 Actual"></Column> : null
                                                    }

                                                    {
                                                        Q2Check === true || allQCheck === true ?
                                                            <Column field="q2_target_Actual_value_Ach" header="% Ach." sortable filter filterPlaceholder="Search by % Ach."></Column> : null
                                                    }

                                                    {
                                                        Q3Check === true || allQCheck === true ?
                                                            <Column field="q3_target_value" header="Q3 Target" sortable filter filterPlaceholder="Search by Q3 Target"></Column> : null
                                                    }

                                                    {
                                                        Q3Check === true || allQCheck === true ?
                                                            <Column field="q3_target_Actual_value" header="Q3 Actual" sortable filter filterPlaceholder="Search by Q3 Actual"></Column> : null
                                                    }

                                                    {
                                                        Q3Check === true || allQCheck === true ?
                                                            <Column field="q3_target_Actual_value_Ach" header="% Ach." sortable filter filterPlaceholder="Search by % Ach."></Column> : null
                                                    }

                                                    {
                                                        Q4Check === true || allQCheck === true ?
                                                            <Column field="q4_target_value" header="Q4 Target" sortable filter filterPlaceholder="Search by Q4 Target"></Column> : null
                                                    }
                                                    {
                                                        Q4Check === true || allQCheck === true ?
                                                            <Column field="q4_target_Actual_value" header="Q4 Actual" sortable filter filterPlaceholder="Search by Q4 Actual"></Column> : null
                                                    }
                                                    {
                                                        Q4Check === true || allQCheck === true ?
                                                            <Column field="q4_target_Actual_value_Ach" header="% Ach." sortable filter filterPlaceholder="Search by % Ach."></Column> : null
                                                    }

                                                </DataTable>
                                            }

                                        </div>
                                    </div>
                                </TabPanel>
                            </TabView>
                        </div>


                    </div>
                </div>


            </React.StrictMode>
        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}