import { Menu, Transition } from "@headlessui/react";
import { Avatar } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, Link } from "react-router-dom";
import { Auth, Storage, DataStore } from 'aws-amplify';
import { reactLocalStorage } from 'reactjs-localstorage';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function HRProfile(props) {

    //Get Current User
    const [name, setName] = useState("");
    const [profilePic, setProfilePic] = useState("");
    var myNavigation = useNavigate();

    
    useEffect(() => {
        setName(reactLocalStorage.get('loggedHREmployeeFirstName') + " " + reactLocalStorage.get('loggedHREmployeeLastName') )
        setProfilePic(reactLocalStorage.get('loggedHREmployeeProfilePic'));
    }, []);

    async function logOut() {
        // await DataStore.clear();
        reactLocalStorage.clear();
        myNavigation('/hr');
        myNavigation(0);
    }

    return (
        <div>
            <span className="flex flex-wrap flex-grow">
                <div className="mr-3">

                    <div className="font-bold text-md dark:text-white">{name}</div>
                    <div className="text-xs font-extralight dark:text-white">HR Admin</div>
                </div>
                <Menu as="div" className="relative inline-block text-left">
                    <div className="flex items-center align-center">
                        <Menu.Button className='flex items-center'>
                            <Avatar alt={name} src={profilePic} />

                            <svg fill="currentColor" viewBox="0 0 20 20" className="inline w-5 h-5 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1 dark:text-white"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 z-10 w-56 mt-3 origin-top-right bg-white divide-y divide-gray-100 dark:divide-[#333] rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-[#232528]">
                            <div className="py-1">
                                <Menu.Item key="MenuItemProfile">
                                    {({ active }) => (
                                        <Link
                                            to={"/hr/profile"}
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900 dark:bg-[#333231] dark:text-[#F8F8F8]"
                                                    : "text-gray-700 dark:bg-[#232528] dark:text-[#F8F8F8]",
                                                "block px-4 py-2 text-sm"
                                            )}
                                        >
                                            <i className="mr-6 icon-size user_icon"></i> Profile
                                        </Link>
                                    )}
                                </Menu.Item>
                            </div>

                            <div className="py-1">
                                <Menu.Item key="MenuItemLogout">
                                    {({ active }) => (
                                        <Link
                                            onClick={logOut}
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900 dark:bg-[#333231] dark:text-[#F8F8F8]"
                                                    : "text-gray-700 dark:text-[#F8F8F8] dark:bg-[#232528]",
                                                "block px-4 py-2 text-sm cursor-pointer"
                                            )}
                                        >
                                            <i className="mr-6 icon-size logout_icon_small"></i> Logout
                                        </Link>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </span>
        </div>
    );



}
