export const msalConfig = {
    auth: {
      clientId: "8b41d97f-8301-4dd2-a4a7-a775f35ff99b",
      authority: "https://login.microsoftonline.com/e79918f6-a69b-4b33-8166-e897131f7346", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri:process.env.REACT_APP_MICROSOFT_REDIRECT_URL,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  // export const loginRequest = {
  //  scopes: ["User.Read","User.ReadBasic.All"]
  // };
  export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
   };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
      graphMeAllUsers: "https://graph.microsoft.com/v1.0/users?$top=25",
  };


  export async function callMsGraph(accessToken,endPoint,method="GET",requestBody="") {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    let options= [];
    if(method==="GET")
    {
       options = {
          method: method,
          headers: headers
      };
    }
    else
    {
      options = {
          method: method,
          headers: headers,
          body: JSON.stringify(requestBody)
      };
    }

    return fetch(endPoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}