import React, { useState, useEffect } from 'react';
import LeftSideBar from '../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../components/common/hr/topnav.component';
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ViewButton from '../../../components/common/buttons/ViewButton';
import EditButton from '../../../components/common/buttons/EditButton';
import DeleteButton from '../../../components/common/buttons/DeleteButton';
import Select from 'react-select';
import popupHeader from '../../../assets/images/Evaluation_header_img.png'
import imageDeployedLarge from '../../../assets/images/deployedImg.png';
import { Auth } from 'aws-amplify';
import { ValidateHRRole } from '../../../components/helper/validateRole';
import HRLogin from '../../../setup/auth/hr.component';
import { FinancialYear, BIUnit, SBU, FinanceActuals } from '../../../models';
import { DataStore } from '@aws-amplify/datastore';
import moment from 'moment';
import { toast } from "react-toastify";
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries'
import * as mutations from '../../../graphql/mutations';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../src/components/helper/graphQLFunctions'



export const EBIT_PBT = () => {

    let navigateTo = useNavigate();

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //list
    const [financeActualList, setFinanceActualList] = useState([])
    const [quarterList, setQuarterList] = useState([
        {
            "label": "Q1",
            "value": "1"
        },
        {
            "label": "Q2",
            "value": "2"
        },
        {
            "label": "Q3",
            "value": "3"
        },
        {
            "label": "Q4",
            "value": "4"
        }

    ]);

    //function for Actions
    const actionsOnComponySbu = (row) => {
        return (
            <React.Fragment >
                <div className="flex justify-center gap-8 ">
                    <Link onClick={(e) => {
                        handleEditClick(e, row.id)
                    }}  ><EditButton /></Link>
                    <Link onClick={(e) => handleDeleteClick(e, row.id)} ><DeleteButton /></Link>
                </div>

            </React.Fragment>
        );
    }

    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        // const objDeleteRole = await DataStore.query(FinanceActuals, id);
        const objDeleteRole = await graphQLFindRecordById('getFinanceActuals', id);

        alertify.confirm('Confirmation', 'Do you want to delete this Finance Actual?', async function () {
            //Delete Existing InterpreterRequest for this language
            // DataStore.delete(FinanceActuals, (c) => c.id("eq", id))
            // //Delete language
            // DataStore.delete(objDeleteRole);

            await API.graphql({
                query: mutations.deleteFinanceActuals,
                variables: {
                    input: {
                        id: objDeleteRole.id,
                        _version: objDeleteRole._version,
                    }
                }
            });
            BindList();
            toast.success("Deleted");
        }
            , function () { });

    };

    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/hr/evaluation/createebitpbt/' + id);
    };

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }
    async function BindList() {

        //get Financial Year List
        // var financeActualResponse = await DataStore.query(FinanceActuals);
        var financeActualResponse = await graphQLGetAllData('listFinanceActuals');
        let finalData = [];
        for (let i = 0; i < financeActualResponse.length; i++) {
            //set Financial Year
            let financialYearName = ""
            if (financeActualResponse[i].financial_year_id) {
                // var financialYearResponse = await DataStore.query(FinancialYear, financeActualResponse[i].financial_year_id);
                var financialYearResponse = await graphQLFindRecordById('getFinancialYear', financeActualResponse[i].financial_year_id);

                if (financialYearResponse) {
                    financialYearName = financialYearResponse.name
                } else { financialYearName = "" }
            } else { financialYearName = "" }

            //set month
            var monthValue = ""
            if (financeActualResponse[i].quarterNo) {
                let filterValue = quarterList.filter((response) => String(financeActualResponse[i].quarterNo) === response.value)
                monthValue = filterValue[0].label
            } else {
                monthValue = ""
            }

            //set SBU
            let sBUName = ""
            if (financeActualResponse[i].sbu_id) {
                // var sBUResponse = await DataStore.query(SBU, financeActualResponse[i].sbu_id);
                var sBUResponse = await graphQLFindRecordById('getSBU', financeActualResponse[i].sbu_id);

                if (sBUResponse) {
                    sBUName = sBUResponse.name
                } else { sBUName = "" }
            } else { sBUName = "" }

            //set BI Unit
            let bUName = ""
            if (financeActualResponse[i].bu_id) {
                // var bUResponse = await DataStore.query(BIUnit, financeActualResponse[i].bu_id);
                var bUResponse = await graphQLFindRecordById('getBIUnit',  financeActualResponse[i].bu_id);

                if (bUResponse) {
                    bUName = bUResponse.name
                } else { bUName = "" }
            } else { bUName = "" }

            let data = {
                "id": financeActualResponse[i].id,
                "financialYearName": financialYearName,
                "month": monthValue,
                "sBUName": sBUName,
                "bUName": bUName,
                "lastUpdatedOn": moment(financeActualResponse[i].updatedAt).format("Do MMM, YYYY")
            }
            finalData.push(data)
            if (i === financeActualResponse.length - 1) {
                setFinanceActualList(finalData)
            }
        }

    }

    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    return (
        isAuthenticated ? (
            <div className="pt-16 ">
                <TopNavBar />
                <LeftSideBar />

                <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                    <div className="p-5 height-custtom">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Goals Evaluation</div>
                        </div>
                        <div className="lg:flex justify-between my-6">
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/hr/evaluation">Initiate Evaluation</Link></li>
                                    <li><Link to="/hr/evaluation/nwcdaysactuals">NWC Days Actuals</Link></li>
                                    <li><Link to="/hr/evaluation/companysbu">Company & SBU Actuals</Link></li>
                                    <li><Link to="/hr/evaluation/ebitpbt" className={"active"}>EBIT/PBT/Bad Debts Actuals</Link></li>
                                </ul>
                            </div>

                            <div className="mt-5 lg:mt-0">
                                <Link to="/hr/evaluation/createebitpbt" className="bg-[#029046] text-white rounded-md px-4 py-2.5 lg:py-2.5 text-sm lg:text-md shadow-md addclassName" >Add New</Link>
                            </div>

                        </div>

                        <div className=" rounded-lg mt-5 ">
                            <DataTable
                                paginator
                                value={financeActualList}
                                responsiveLayout="scroll"

                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                            >
                                <Column field="financialYearName" header="Financial Year"></Column>
                                <Column field="month" header="Quarter"></Column>
                                <Column field="sBUName" header="SBU"></Column>
                                <Column field="bUName" header="Business Unit"></Column>
                                <Column field="lastUpdatedOn" header="Last Updated On"></Column>
                                <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnComponySbu} ></Column>
                            </DataTable>
                        </div>

                    </div>
                </div>
            </div >
        ) : (
            <HRLogin sessionExpired={1} />
        )
    )
}
