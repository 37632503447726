import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import LeftSideBar from '../../../components/common/hr/leftnav.component';
import TopNavBar from '../../../components/common/hr/topnav.component';
import Select from "react-select";
import { ValidateHRRole } from '../../../components/helper/validateRole';
import HRLogin from '../../../setup/auth/hr.component';
import CircularProgressBar from '../../../components/common/progressbar/CircularProgressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { ConvertResponseForSelect, ConvertResponseForSelectTwoNames, ObjectToArray } from '../../../components/helper/commonfunctions';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../components/helper/graphQLFunctions';
import { FinancialYear, SBU, BIUnit, Region, Employee, Targets, Roles, TargetDetails, InitiatePlanning, Plan, Planning, TeamType } from "../../../models";
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from "moment";
import { listInitiatePlannings, listTargetDetails, listTargets } from '../../../graphql/queries';

export default function PlanningDashboard() {

    //state
    const [financialYear, setFinancialYear] = useState("");
    const [sBU, setSBU] = useState("");
    const [region, setRegion] = useState("");
    const [employee, setEmployee] = useState("");

    const [isFiltered, setFiltered] = useState(true);

    //list
    const [financialYearList, setFinancialYearList] = useState([]);
    const [sBUList, setSBUList] = useState([]);
    const [bUList, setBUList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [allEmployeesArray, setAllEmployeesArray] = useState([]);
    const [allSbuArray, setAllSbuArray] = useState([]);
    const [quarter, setQuarter] = useState("");
    const [allRoleArray, setAllRoleArray] = useState([]);

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    var quarterList = [
        { label: "Q1", value: "1" },
        { label: "Q2", value: "2" },
        { label: "Q3", value: "3" },
        { label: "Q4", value: "4" },
    ]

    //Edit View Button
    const actionsOnRoles = () => {
        return (
            <React.Fragment >
                <div className='content-center'>

                </div>
            </React.Fragment>
        );
    }

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    async function bindList() {
        //set financial year
        let selectedYear = reactLocalStorage.getObject('selectedYear');
        if (Object.keys(selectedYear).length !== 0) {
            setFinancialYear(selectedYear)
            bindDetailedReport(selectedYear.value)
            setIsEligibleEmployeeActive(true)
            setIsPlanFilterInitiatedActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(false)
        }
        else {
            // var responses = await DataStore.query(FinancialYear, (c) => c.fromDate.contains(moment().format("YYYY")));
            var responses = await graphQLGetAllData("listFinancialYears", [{ fromDate: { contains: moment().format("YYYY") } }])

            setFinancialYear({ label: responses[0].name, value: responses[0].id })
            bindDetailedReport(responses[0].id)
            setIsEligibleEmployeeActive(true)
            setIsPlanFilterInitiatedActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(false)
        }
    }

    const [allTargets, setAllTargets] = useState([]);
    const [allInitiatePlannings, setAllInitiatePlannings] = useState([]);
    const [allTargetDetails, setAllTargetDetails] = useState([]);

    const getMasterData = async () => {
        //get financial year
        // var financialYearResponses = await DataStore.query(FinancialYear, Predicates.ALL, {
        //     sort: s => s.name(SortDirection.ASCENDING)
        // });

        var financialYearResponses = await graphQLGetAllData("listFinancialYears")
        financialYearResponses = financialYearResponses.sort((a, b) => a.name.localeCompare(b.name));

        var myArray = []; myArray = ConvertResponseForSelect(financialYearResponses); setFinancialYearList(myArray);

        //get SBU list
        // var sBUResponses = await DataStore.query(SBU, Predicates.ALL, {
        //     sort: s => s.createdAt(SortDirection.ASCENDING)
        // });
        var sBUResponses = await graphQLGetAllData("listSBUS")
        sBUResponses = sBUResponses.sort((a, b) => a.name.localeCompare(b.name));
        setAllSbuArray(sBUResponses)

        var mySBUArray = []; mySBUArray = ConvertResponseForSelect(sBUResponses);
        let zeroPositionParameterObject = { label: "All SBU", value: 0 }; mySBUArray.unshift(zeroPositionParameterObject);
        setSBU({ label: "All SBU", value: 0 })
        setSBUList(mySBUArray);

        //get BU list
        // var bIUnitResponses = await DataStore.query(BIUnit, Predicates.ALL, {
        //     sort: s => s.createdAt(SortDirection.ASCENDING)
        // });
        var bIUnitResponses = await graphQLGetAllData("listBIUnits")
        bIUnitResponses = bIUnitResponses.sort((a, b) => a.name.localeCompare(b.name));

        var mybUArray = []; mybUArray = ConvertResponseForSelect(bIUnitResponses); setBUList(mybUArray);

        //get region list
        // var regionsResponses = await DataStore.query(Region, Predicates.ALL, {
        //     sort: s => s.createdAt(SortDirection.ASCENDING)
        // });
        var regionsResponses = await graphQLGetAllData("listRegions")
        regionsResponses = regionsResponses.sort((a, b) => a.name.localeCompare(b.name));
        var myregionArray = []; myregionArray = ConvertResponseForSelect(regionsResponses); setRegionList(myregionArray);

        //get employee list
        // var employeeResponses = await DataStore.query(Employee, Predicates.ALL, {
        //     sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
        // });
        var employeeResponses = await graphQLGetAllData("listEmployees")
        employeeResponses = employeeResponses.sort((a, b) => a.firstName.localeCompare(b.firstName));
        setAllEmployeesArray(employeeResponses);

        var myemployeeArray = [];
        myemployeeArray = ConvertResponseForSelectTwoNames(employeeResponses);
        let zeroPositionEmployeeObject = { label: "All Employee", value: 0 }; myemployeeArray.unshift(zeroPositionEmployeeObject);
        setEmployeeList(myemployeeArray);
        setEmployee({ label: "All Employee", value: 0 })

        var roleResponse = await graphQLGetAllData("listRoles")
        setAllRoleArray(roleResponse)


        var targetResponses = await graphQLGetAllData("listTargets");
        setAllTargets(targetResponses);

        var initiatePlanningResponses = await graphQLGetAllData("listInitiatePlannings");
        setAllInitiatePlannings(initiatePlanningResponses);

        var targetDtailsResponses = await graphQLGetAllData("listTargetDetails");
        setAllTargetDetails(targetDtailsResponses);

        setIsDataLoaded(true)
    }

    useEffect(() => {
        onLoad();
        getMasterData();
    }, []);


    useEffect(() => {
        if (isDataLoaded === true) {
            bindList()
        }
    }, [isDataLoaded]);


    const [employeeTargetsList, SetEmployeeTargetList] = useState([]);
    const [employeeEligibleList, SetEmployeeEligibleList] = useState([]);
    const [activeTab, setTab] = useState(1);

    //
    const [count_PlanningInitiated, SetCount_PlanningInitiated] = useState(0);
    const [count_TargetAssigned, SetCount_TargetAssigned] = useState(0);
    const [count_YetToStart, SetCount_YetToStart] = useState(0);
    const [count_PlanningInDraft, SetCount_PlanningInDraft] = useState(0);
    const [count_PlanSubmitted, SetCount_PlanSubmitted] = useState(0);
    const [count_Approved, SetCount_Approved] = useState(0);
    const [count_PendingReview, SetCount_PendingReview] = useState(0);
    const [countEligibleSale, SetCountEligibleSale] = useState(0);
    const [countEligibleBusiness, SetCountEligibleBusiness] = useState(0);
    const [countPlanInitiatedBusiness, setPlanInitiatedBusinessCount] = useState(0);
    const [countPlanInitiatedSale, setPlanInitiatedSaleCount] = useState(0);
    const [countTargetAssignedSale, setCountTargetAssignedSale] = useState(0);
    const [countTargetAssignedBusiness, setCountTargetAssignedBusiness] = useState(0);
    const [countYetToStartSale, setCountYetToStartSale] = useState(0);
    const [countYetToStartBusiness, setCountYetToStartBusiness] = useState(0);
    const [countPlanInDraftBusiness, setCountPlanInDraftBusiness] = useState(0);
    const [countPlanInDraftSale, setCountPlanInDraftSale] = useState(0);
    const [countPlanSubmittedSale, setCountPlanSubmittedSale] = useState(0);
    const [countPlanSubmittedBusiness, setCountPlanSubmittedBusiness] = useState(0);
    const [countPendingReviewSale, setCountPendingReviewSale] = useState(0);
    const [countPendingReviewBusiness, setCountPendingReviewBusiness] = useState(0);
    const [countApprovedBusiness, setCountApprovedBusiness] = useState(0);
    const [countApprovedSale, setCountApprovedSale] = useState(0);
    const [planFilterInitiatedForActive, setIsPlanFilterInitiatedActive] = useState(false);
    const [isEligibleEmployeeForActive, setIsEligibleEmployeeActive] = useState(true);
    const [targetAssignedForActive, setTargetAssignedActive] = useState(false);
    const [isYetToStartForActive, setIsYetToStartActive] = useState(false);
    const [planInDraftForActive, setIsPlanInDraftActive] = useState(false);
    const [planSubmittedForActive, setIsPlanSubmittedActive] = useState(false);
    const [pendingReviewForActive, setPendingReviewActive] = useState(false);
    const [planApprovedForActive, setIsApprovedActive] = useState(false);

    const getDetailedReport = async (isPlanFilterInitiated, isEligible, isTargetAssigned, isYetToStart, isPlanInDraft, isPlanSubmitted, isPendingReview, isApproved) => {
        // if (isFiltered) {
        SetEmployeeTargetList([]);
        if (isPlanFilterInitiated === 1) {

            let planningInitiatedBusiness = 0;
            let planningInitiatedSales = 0;
            let newResponse = [];
            for (let i = 0; i < count_PlanningInitiated.length; i++) {
                if (count_PlanningInitiated[i].roleTeamType) {
                    if (count_PlanningInitiated[i].roleTeamType === TeamType.S) {
                        planningInitiatedSales = planningInitiatedSales + 1;
                    } else if (count_PlanningInitiated[i].roleTeamType === TeamType.B) {
                        planningInitiatedBusiness = planningInitiatedBusiness + 1;
                    }
                }
                let index = i + 1
                newResponse.push({
                    "id": count_PlanningInitiated[i].id, "role_id": count_PlanningInitiated[i].role_id, "firstName": count_PlanningInitiated[i].firstName, "lastName": count_PlanningInitiated[i].lastName,
                    "email": count_PlanningInitiated[i].email, "parentEmployeeId": count_PlanningInitiated[i].parentEmployeeId, "address1": count_PlanningInitiated[i].address1, "address2": count_PlanningInitiated[i].address2,
                    "phoneNo": count_PlanningInitiated[i].phoneNo, "city": count_PlanningInitiated[i].city, "zip": count_PlanningInitiated[i].zip, "state": count_PlanningInitiated[i].state, "country": count_PlanningInitiated[i].country,
                    "employeeId": count_PlanningInitiated[i].employeeId, "profilePic": count_PlanningInitiated[i].profilePic, "isAssignDirectTarget": count_PlanningInitiated[i].isAssignDirectTarget, "param_sbu_id": count_PlanningInitiated[i].param_sbu_id,
                    "IsHRAccess": count_PlanningInitiated[i].IsHRAccess, "IsFinanceAccess": count_PlanningInitiated[i].IsFinanceAccess, "index": index, "name": count_PlanningInitiated[i].name, "role": count_PlanningInitiated[i].role, "roleTeamType": count_PlanningInitiated[i].roleTeamType, "reportingManager": count_PlanningInitiated[i].reportingManager,
                    "sbu": count_PlanningInitiated[i].sbu, "vertical": count_PlanningInitiated[i].vertical, "targetAssigned": count_PlanningInitiated[i].targetAssigned, "targetDetailsRes": count_PlanningInitiated[i].targetDetailsRes, "planSubmitted": count_PlanningInitiated[i].planSubmitted, "targetApproved": count_PlanningInitiated[i].targetApproved, "planInDraft": count_PlanningInitiated[i].planInDraft, "yetToStart": count_PlanningInitiated[i].yetToStart,
                    "pendingReview": count_PlanningInitiated[i].pendingReview, "isPlanInitiatedForEmployee": count_PlanningInitiated[i].isPlanInitiatedForEmployee
                });
                if (i === count_PlanningInitiated.length - 1) {
                    SetEmployeeTargetList(newResponse)
                }
            }
            setPlanInitiatedSaleCount(planningInitiatedSales)
            setPlanInitiatedBusinessCount(planningInitiatedBusiness)

            setIsPlanFilterInitiatedActive(true)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(false)
        }
        else if (isEligible === 1) {
            SetEmployeeTargetList(employeeEligibleList);
            let employeeEligibleBusiness = 0;
            let employeeEligibleSales = 0;
            let newResponse = [];
            for (let i = 0; i < employeeEligibleList.length; i++) {
                if (employeeEligibleList[i].roleTeamType) {
                    if (employeeEligibleList[i].roleTeamType === TeamType.S) {
                        employeeEligibleSales = employeeEligibleSales + 1;
                    } else if (employeeEligibleList[i].roleTeamType === TeamType.B) {
                        employeeEligibleBusiness = employeeEligibleBusiness + 1;
                    }
                }
                let index = i + 1
                newResponse.push({
                    "id": employeeEligibleList[i].id, "role_id": employeeEligibleList[i].role_id, "firstName": employeeEligibleList[i].firstName, "lastName": employeeEligibleList[i].lastName,
                    "email": employeeEligibleList[i].email, "parentEmployeeId": employeeEligibleList[i].parentEmployeeId, "address1": employeeEligibleList[i].address1, "address2": employeeEligibleList[i].address2,
                    "phoneNo": employeeEligibleList[i].phoneNo, "city": employeeEligibleList[i].city, "zip": employeeEligibleList[i].zip, "state": employeeEligibleList[i].state, "country": employeeEligibleList[i].country,
                    "employeeId": employeeEligibleList[i].employeeId, "profilePic": employeeEligibleList[i].profilePic, "isAssignDirectTarget": employeeEligibleList[i].isAssignDirectTarget, "param_sbu_id": employeeEligibleList[i].param_sbu_id,
                    "IsHRAccess": employeeEligibleList[i].IsHRAccess, "IsFinanceAccess": employeeEligibleList[i].IsFinanceAccess, "index": index, "name": employeeEligibleList[i].name, "role": employeeEligibleList[i].role, "roleTeamType": employeeEligibleList[i].roleTeamType, "reportingManager": employeeEligibleList[i].reportingManager,
                    "sbu": employeeEligibleList[i].sbu, "vertical": employeeEligibleList[i].vertical, "targetAssigned": employeeEligibleList[i].targetAssigned, "targetDetailsRes": employeeEligibleList[i].targetDetailsRes, "planSubmitted": employeeEligibleList[i].planSubmitted, "targetApproved": employeeEligibleList[i].targetApproved, "planInDraft": employeeEligibleList[i].planInDraft, "yetToStart": employeeEligibleList[i].yetToStart,
                    "pendingReview": employeeEligibleList[i].pendingReview, "isPlanInitiatedForEmployee": employeeEligibleList[i].isPlanInitiatedForEmployee
                });
                if (i === employeeEligibleList.length - 1) {
                    SetEmployeeTargetList(newResponse)
                }

            }
            SetCountEligibleSale(employeeEligibleSales)
            SetCountEligibleBusiness(employeeEligibleBusiness)

            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(true)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(false)
        }
        else if (isTargetAssigned === 1) {

            SetEmployeeTargetList(count_TargetAssigned);
            let targetAssignedBusiness = 0;
            let targetAssignedSales = 0;
            let newResponse = [];
            for (let i = 0; i < count_TargetAssigned.length; i++) {
                if (count_TargetAssigned[i].roleTeamType) {
                    if (count_TargetAssigned[i].roleTeamType === TeamType.S) {
                        targetAssignedSales = targetAssignedSales + 1;
                    } else if (count_TargetAssigned[i].roleTeamType === TeamType.B) {
                        targetAssignedBusiness = targetAssignedBusiness + 1;
                    }
                }
                let index = i + 1
                newResponse.push({
                    "id": count_TargetAssigned[i].id, "role_id": count_TargetAssigned[i].role_id, "firstName": count_TargetAssigned[i].firstName, "lastName": count_TargetAssigned[i].lastName,
                    "email": count_TargetAssigned[i].email, "parentEmployeeId": count_TargetAssigned[i].parentEmployeeId, "address1": count_TargetAssigned[i].address1, "address2": count_TargetAssigned[i].address2,
                    "phoneNo": count_TargetAssigned[i].phoneNo, "city": count_TargetAssigned[i].city, "zip": count_TargetAssigned[i].zip, "state": count_TargetAssigned[i].state, "country": count_TargetAssigned[i].country,
                    "employeeId": count_TargetAssigned[i].employeeId, "profilePic": count_TargetAssigned[i].profilePic, "isAssignDirectTarget": count_TargetAssigned[i].isAssignDirectTarget, "param_sbu_id": count_TargetAssigned[i].param_sbu_id,
                    "IsHRAccess": count_TargetAssigned[i].IsHRAccess, "IsFinanceAccess": count_TargetAssigned[i].IsFinanceAccess, "index": index, "name": count_TargetAssigned[i].name, "role": count_TargetAssigned[i].role, "roleTeamType": count_TargetAssigned[i].roleTeamType, "reportingManager": count_TargetAssigned[i].reportingManager,
                    "sbu": count_TargetAssigned[i].sbu, "vertical": count_TargetAssigned[i].vertical, "targetAssigned": count_TargetAssigned[i].targetAssigned, "targetDetailsRes": count_TargetAssigned[i].targetDetailsRes, "planSubmitted": count_TargetAssigned[i].planSubmitted, "targetApproved": count_TargetAssigned[i].targetApproved, "planInDraft": count_TargetAssigned[i].planInDraft, "yetToStart": count_TargetAssigned[i].yetToStart,
                    "pendingReview": count_TargetAssigned[i].pendingReview, "isPlanInitiatedForEmployee": count_TargetAssigned[i].isPlanInitiatedForEmployee
                });
                if (i === count_TargetAssigned.length - 1) {
                    SetEmployeeTargetList(newResponse)
                }
            }
            setCountTargetAssignedBusiness(targetAssignedBusiness)
            setCountTargetAssignedSale(targetAssignedSales)

            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(true)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(false)
        }
        else if (isYetToStart === 1) {

            SetEmployeeTargetList(count_YetToStart);
            let yetToStartBusiness = 0;
            let yetToStartSales = 0;
            let newResponse = [];
            for (let i = 0; i < count_YetToStart.length; i++) {
                if (count_YetToStart[i].roleTeamType) {
                    if (count_YetToStart[i].roleTeamType === TeamType.S) {
                        yetToStartSales = yetToStartSales + 1;
                    } else if (count_YetToStart[i].roleTeamType === TeamType.B) {
                        yetToStartBusiness = yetToStartBusiness + 1;
                    }
                }
                let index = i + 1
                newResponse.push({
                    "id": count_YetToStart[i].id, "role_id": count_YetToStart[i].role_id, "firstName": count_YetToStart[i].firstName, "lastName": count_YetToStart[i].lastName,
                    "email": count_YetToStart[i].email, "parentEmployeeId": count_YetToStart[i].parentEmployeeId, "address1": count_YetToStart[i].address1, "address2": count_YetToStart[i].address2,
                    "phoneNo": count_YetToStart[i].phoneNo, "city": count_YetToStart[i].city, "zip": count_YetToStart[i].zip, "state": count_YetToStart[i].state, "country": count_YetToStart[i].country,
                    "employeeId": count_YetToStart[i].employeeId, "profilePic": count_YetToStart[i].profilePic, "isAssignDirectTarget": count_YetToStart[i].isAssignDirectTarget, "param_sbu_id": count_YetToStart[i].param_sbu_id,
                    "IsHRAccess": count_YetToStart[i].IsHRAccess, "IsFinanceAccess": count_YetToStart[i].IsFinanceAccess, "index": index, "name": count_YetToStart[i].name, "role": count_YetToStart[i].role, "roleTeamType": count_YetToStart[i].roleTeamType, "reportingManager": count_YetToStart[i].reportingManager,
                    "sbu": count_YetToStart[i].sbu, "vertical": count_YetToStart[i].vertical, "targetAssigned": count_YetToStart[i].targetAssigned, "targetDetailsRes": count_YetToStart[i].targetDetailsRes, "planSubmitted": count_YetToStart[i].planSubmitted, "targetApproved": count_YetToStart[i].targetApproved, "planInDraft": count_YetToStart[i].planInDraft, "yetToStart": count_YetToStart[i].yetToStart,
                    "pendingReview": count_YetToStart[i].pendingReview, "isPlanInitiatedForEmployee": count_YetToStart[i].isPlanInitiatedForEmployee
                });
                if (i === count_YetToStart.length - 1) {
                    SetEmployeeTargetList(newResponse)
                }

            }
            setCountYetToStartSale(yetToStartSales)
            setCountYetToStartBusiness(yetToStartBusiness)

            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(true)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(false)
        }
        else if (isPlanInDraft === 1) {
            SetEmployeeTargetList(count_PlanningInDraft);
            let planningInDraftBusiness = 0;
            let planningInDraftSales = 0;
            let newResponse = [];
            for (let i = 0; i < count_PlanningInDraft.length; i++) {
                if (count_PlanningInDraft[i].roleTeamType) {
                    if (count_PlanningInDraft[i].roleTeamType === TeamType.S) {
                        planningInDraftSales = planningInDraftSales + 1;
                    } else if (count_PlanningInDraft[i].roleTeamType === TeamType.B) {
                        planningInDraftBusiness = planningInDraftBusiness + 1;
                    }
                }
                let index = i + 1
                newResponse.push({
                    "id": count_PlanningInDraft[i].id, "role_id": count_PlanningInDraft[i].role_id, "firstName": count_PlanningInDraft[i].firstName, "lastName": count_PlanningInDraft[i].lastName,
                    "email": count_PlanningInDraft[i].email, "parentEmployeeId": count_PlanningInDraft[i].parentEmployeeId, "address1": count_PlanningInDraft[i].address1, "address2": count_PlanningInDraft[i].address2,
                    "phoneNo": count_PlanningInDraft[i].phoneNo, "city": count_PlanningInDraft[i].city, "zip": count_PlanningInDraft[i].zip, "state": count_PlanningInDraft[i].state, "country": count_PlanningInDraft[i].country,
                    "employeeId": count_PlanningInDraft[i].employeeId, "profilePic": count_PlanningInDraft[i].profilePic, "isAssignDirectTarget": count_PlanningInDraft[i].isAssignDirectTarget, "param_sbu_id": count_PlanningInDraft[i].param_sbu_id,
                    "IsHRAccess": count_PlanningInDraft[i].IsHRAccess, "IsFinanceAccess": count_PlanningInDraft[i].IsFinanceAccess, "index": index, "name": count_PlanningInDraft[i].name, "role": count_PlanningInDraft[i].role, "roleTeamType": count_PlanningInDraft[i].roleTeamType, "reportingManager": count_PlanningInDraft[i].reportingManager,
                    "sbu": count_PlanningInDraft[i].sbu, "vertical": count_PlanningInDraft[i].vertical, "targetAssigned": count_PlanningInDraft[i].targetAssigned, "targetDetailsRes": count_PlanningInDraft[i].targetDetailsRes, "planSubmitted": count_PlanningInDraft[i].planSubmitted, "targetApproved": count_PlanningInDraft[i].targetApproved, "planInDraft": count_PlanningInDraft[i].planInDraft, "yetToStart": count_PlanningInDraft[i].yetToStart,
                    "pendingReview": count_PlanningInDraft[i].pendingReview, "isPlanInitiatedForEmployee": count_PlanningInDraft[i].isPlanInitiatedForEmployee
                });
                if (i === count_PlanningInDraft.length - 1) {
                    SetEmployeeTargetList(newResponse)
                }

            }
            setCountPlanInDraftSale(planningInDraftSales)
            setCountPlanInDraftBusiness(planningInDraftBusiness)

            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(true)
            setPendingReviewActive(false)
            setIsApprovedActive(false)

        }
        else if (isPlanSubmitted === 1) {
            SetEmployeeTargetList(count_PlanSubmitted);
            let planSubmittedBusiness = 0;
            let planSubmittedSales = 0;
            let newResponse = [];
            for (let i = 0; i < count_PlanSubmitted.length; i++) {
                if (count_PlanSubmitted[i].roleTeamType) {
                    if (count_PlanSubmitted[i].roleTeamType === TeamType.S) {
                        planSubmittedSales = planSubmittedSales + 1;
                    } else if (count_PlanSubmitted[i].roleTeamType === TeamType.B) {
                        planSubmittedBusiness = planSubmittedBusiness + 1
                    }
                }
                let index = i + 1
                newResponse.push({
                    "id": count_PlanSubmitted[i].id, "role_id": count_PlanSubmitted[i].role_id, "firstName": count_PlanSubmitted[i].firstName, "lastName": count_PlanSubmitted[i].lastName,
                    "email": count_PlanSubmitted[i].email, "parentEmployeeId": count_PlanSubmitted[i].parentEmployeeId, "address1": count_PlanSubmitted[i].address1, "address2": count_PlanSubmitted[i].address2,
                    "phoneNo": count_PlanSubmitted[i].phoneNo, "city": count_PlanSubmitted[i].city, "zip": count_PlanSubmitted[i].zip, "state": count_PlanSubmitted[i].state, "country": count_PlanSubmitted[i].country,
                    "employeeId": count_PlanSubmitted[i].employeeId, "profilePic": count_PlanSubmitted[i].profilePic, "isAssignDirectTarget": count_PlanSubmitted[i].isAssignDirectTarget, "param_sbu_id": count_PlanSubmitted[i].param_sbu_id,
                    "IsHRAccess": count_PlanSubmitted[i].IsHRAccess, "IsFinanceAccess": count_PlanSubmitted[i].IsFinanceAccess, "index": index, "name": count_PlanSubmitted[i].name, "role": count_PlanSubmitted[i].role, "roleTeamType": count_PlanSubmitted[i].roleTeamType, "reportingManager": count_PlanSubmitted[i].reportingManager,
                    "sbu": count_PlanSubmitted[i].sbu, "vertical": count_PlanSubmitted[i].vertical, "targetAssigned": count_PlanSubmitted[i].targetAssigned, "targetDetailsRes": count_PlanSubmitted[i].targetDetailsRes, "planSubmitted": count_PlanSubmitted[i].planSubmitted, "targetApproved": count_PlanSubmitted[i].targetApproved, "planInDraft": count_PlanSubmitted[i].planInDraft, "yetToStart": count_PlanSubmitted[i].yetToStart,
                    "pendingReview": count_PlanSubmitted[i].pendingReview, "isPlanInitiatedForEmployee": count_PlanSubmitted[i].isPlanInitiatedForEmployee
                });
                if (i === count_PlanSubmitted.length - 1) {
                    SetEmployeeTargetList(newResponse)
                }
            }
            setCountPlanSubmittedSale(planSubmittedSales)
            setCountPlanSubmittedBusiness(planSubmittedBusiness)

            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(true)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(false)
        }
        else if (isPendingReview === 1) {
            SetEmployeeTargetList(count_PendingReview);
            let pendingReviewBusiness = 0;
            let pendingReviewSales = 0;
            let newResponse = [];
            for (let i = 0; i < count_PendingReview.length; i++) {
                if (count_PendingReview[i].roleTeamType) {
                    if (count_PendingReview[i].roleTeamType === TeamType.S) {
                        pendingReviewSales = pendingReviewSales + 1;
                    } else if (count_PendingReview[i].roleTeamType === TeamType.B) {
                        pendingReviewBusiness = pendingReviewBusiness + 1
                    }
                }
                let index = i + 1
                newResponse.push({
                    "id": count_PendingReview[i].id, "role_id": count_PendingReview[i].role_id, "firstName": count_PendingReview[i].firstName, "lastName": count_PendingReview[i].lastName,
                    "email": count_PendingReview[i].email, "parentEmployeeId": count_PendingReview[i].parentEmployeeId, "address1": count_PendingReview[i].address1, "address2": count_PendingReview[i].address2,
                    "phoneNo": count_PendingReview[i].phoneNo, "city": count_PendingReview[i].city, "zip": count_PendingReview[i].zip, "state": count_PendingReview[i].state, "country": count_PendingReview[i].country,
                    "employeeId": count_PendingReview[i].employeeId, "profilePic": count_PendingReview[i].profilePic, "isAssignDirectTarget": count_PendingReview[i].isAssignDirectTarget, "param_sbu_id": count_PendingReview[i].param_sbu_id,
                    "IsHRAccess": count_PendingReview[i].IsHRAccess, "IsFinanceAccess": count_PendingReview[i].IsFinanceAccess, "index": index, "name": count_PendingReview[i].name, "role": count_PendingReview[i].role, "roleTeamType": count_PendingReview[i].roleTeamType, "reportingManager": count_PendingReview[i].reportingManager,
                    "sbu": count_PendingReview[i].sbu, "vertical": count_PendingReview[i].vertical, "targetAssigned": count_PendingReview[i].targetAssigned, "targetDetailsRes": count_PendingReview[i].targetDetailsRes, "planSubmitted": count_PendingReview[i].planSubmitted, "targetApproved": count_PendingReview[i].targetApproved, "planInDraft": count_PendingReview[i].planInDraft, "yetToStart": count_PendingReview[i].yetToStart,
                    "pendingReview": count_PendingReview[i].pendingReview, "isPlanInitiatedForEmployee": count_PendingReview[i].isPlanInitiatedForEmployee
                });
                if (i === count_PendingReview.length - 1) {
                    SetEmployeeTargetList(newResponse)
                }
            }
            setCountPendingReviewSale(pendingReviewSales)
            setCountPendingReviewBusiness(pendingReviewBusiness)

            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(true)
            setIsApprovedActive(false)
        }
        else if (isApproved === 1) {
            SetEmployeeTargetList(count_Approved);
            let approvedBusiness = 0;
            let approvedSales = 0;
            let newResponse = [];
            for (let i = 0; i < count_Approved.length; i++) {
                if (count_Approved[i].roleTeamType) {
                    if (count_Approved[i].roleTeamType === TeamType.S) {
                        approvedSales = approvedSales + 1;
                    } else if (count_Approved[i].roleTeamType === TeamType.B) {
                        approvedBusiness = approvedBusiness + 1;
                    }
                }
                let index = i + 1
                newResponse.push({
                    "id": count_Approved[i].id, "role_id": count_Approved[i].role_id, "firstName": count_Approved[i].firstName, "lastName": count_Approved[i].lastName,
                    "email": count_Approved[i].email, "parentEmployeeId": count_Approved[i].parentEmployeeId, "address1": count_Approved[i].address1, "address2": count_Approved[i].address2,
                    "phoneNo": count_Approved[i].phoneNo, "city": count_Approved[i].city, "zip": count_Approved[i].zip, "state": count_Approved[i].state, "country": count_Approved[i].country,
                    "employeeId": count_Approved[i].employeeId, "profilePic": count_Approved[i].profilePic, "isAssignDirectTarget": count_Approved[i].isAssignDirectTarget, "param_sbu_id": count_Approved[i].param_sbu_id,
                    "IsHRAccess": count_Approved[i].IsHRAccess, "IsFinanceAccess": count_Approved[i].IsFinanceAccess, "index": index, "name": count_Approved[i].name, "role": count_Approved[i].role, "roleTeamType": count_Approved[i].roleTeamType, "reportingManager": count_Approved[i].reportingManager,
                    "sbu": count_Approved[i].sbu, "vertical": count_Approved[i].vertical, "targetAssigned": count_Approved[i].targetAssigned, "targetDetailsRes": count_Approved[i].targetDetailsRes, "planSubmitted": count_Approved[i].planSubmitted, "targetApproved": count_Approved[i].targetApproved, "planInDraft": count_Approved[i].planInDraft, "yetToStart": count_Approved[i].yetToStart,
                    "pendingReview": count_Approved[i].pendingReview, "isPlanInitiatedForEmployee": count_Approved[i].isPlanInitiatedForEmployee
                });
                if (i === count_Approved.length - 1) {
                    SetEmployeeTargetList(newResponse)
                }
            }
            setCountApprovedSale(approvedSales)
            setCountApprovedBusiness(approvedBusiness)

            setIsPlanFilterInitiatedActive(false)
            setIsEligibleEmployeeActive(false)
            setTargetAssignedActive(false)
            setIsYetToStartActive(false)
            setIsPlanSubmittedActive(false)
            setIsPlanInDraftActive(false)
            setPendingReviewActive(false)
            setIsApprovedActive(true)
        }
    }

    const bindDetailedReport = async (financialYearId) => {

        if (isFiltered) {

            var employeeId = "";
            var regionId = "";
            var sbuId = "";

            if (employee) { employeeId = employee.value }
            //if (region) { region = region.value }
            if (sBU) { sbuId = sBU.value }

            // if (employeeId > 0) {

            let employeeTargetsList = allEmployeesArray;

            if (sbuId) {
                employeeTargetsList = employeeTargetsList.filter(e => e.param_sbu_id.includes(sbuId))
            }
            if (employeeId) {
                employeeTargetsList = employeeTargetsList.filter(e => e.id.includes(employeeId))
            }

            // var employeeTargetsList = await DataStore.query(Employee,
            //     (c) => c.and(c => [
            //         c.id.contains(employeeId),
            //         c.param_sbu_id.contains(sbuId),
            //     ]));

            let isPlanInitiated = false
            // var checkIsPlanInit = await DataStore.query(Planning, (c) => c.financial_year_id.eq(financialYearId))
            var checkIsPlanInit = await graphQLGetAllData("listPlannings", [{ financial_year_id: { eq: financialYearId } }])

            if (checkIsPlanInit.length) { isPlanInitiated = true } else {
                SetCount_PlanningInitiated(0)
                SetCount_TargetAssigned(0)
                SetCount_YetToStart(0)
                SetCount_PlanningInDraft(0)
                SetCount_Approved(0)
                SetCount_PendingReview(0)
                SetCount_PlanSubmitted(0)
            }

            if (employeeTargetsList.length) {
                var newResponse = [];

                // Object.keys(employeeTargetsList).map(async (currentItem, i) => {
                for (var i = 0; i < employeeTargetsList.length; i++) {
                    let index = parseInt(i) + 1;
                    let name = employeeTargetsList[i].firstName + " " + employeeTargetsList[i].lastName;

                    //Role List
                    // var roleResponse = await DataStore.query(Roles, employeeTargetsList[i].role_id);
                    var roleResponse = allRoleArray.find(o => o.id === employeeTargetsList[i].role_id)

                    let role = roleResponse ? roleResponse.name : '';
                    let roleTeamType = roleResponse ? roleResponse.teamType : '';

                    //Parent Employee List
                    let reportingManager = "";
                    // var parentResponse = await DataStore.query(Employee, employeeTargetsList[i].parentEmployeeId);
                    var parentResponse = allEmployeesArray.find(o => o.id === employeeTargetsList[i].parentEmployeeId)

                    if (parentResponse) {
                        reportingManager = parentResponse.firstName + " " + parentResponse.lastName;
                    } else { reportingManager = ""; }

                    //SBU
                    let sbu = "";
                    // var sbuRes = await DataStore.query(SBU, employeeTargetsList[i].param_sbu_id);
                    var sbuRes = allSbuArray.find(o => o.id === employeeTargetsList[i].param_sbu_id)

                    sbu = sbuRes ? sbuRes.name : "-";

                    //Vertical
                    let vertical = "Distribution";

                    //Check target
                    // var employeeTarget = await DataStore.query(Targets, (c) => c.and(c => [
                    //     // c.employee_id.eq(employeeTargetsList[i].id),
                    //     c.financial_year_id.eq(financialYearId),
                    //     c.isAssignedToEmployee.eq(true),
                    //     c.report_manager_employee_id.ne(employeeTargetsList[i].id),
                    // ]));

                    // var employeeTarget = await graphQLGetAllData("listTargets", [{ financial_year_id: { eq: financialYearId }, isAssignedToEmployee: { eq: true }, report_manager_employee_id: { eq: employeeTargetsList[i].id } }])

                    var employeeTarget = allTargets.filter(c => c.financial_year_id === financialYearId && c.isAssignedToEmployee === true && c.report_manager_employee_id === employeeTargetsList[i].id)

                    let targetAssigned = employeeTarget.length ? "Yes" : "No"

                    let targetDetailsRes = []
                    let planSubmitted;
                    let targetApproved;
                    let planInDraft;
                    let yetToStart;
                    let pendingReview;
                    if (employeeTarget.length) {
                        // targetDetailsRes = await DataStore.query(TargetDetails, (c) => c.target_id.eq(employeeTarget[0].id))
                        targetDetailsRes = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: employeeTarget[0].id } }])

                        // targetDetailsRes = allTargetDetails.filter(c => c.target_id === employeeTarget[0].id);

                        let checkPlanSubmittedStatus = targetDetailsRes.every((item) => item.isMarkAsComplete_AssignTarget)
                        let checkIsApproveStatus = targetDetailsRes.every((item) => item.isMarkAsComplete_AssignTarget)

                        planSubmitted = checkPlanSubmittedStatus ? "Yes" : "No"
                        targetApproved = checkIsApproveStatus ? "Yes" : "No"

                        let checkPlanInDraft = targetDetailsRes.every((item) => item.is_accepted_by_employee && item.isMarkAsComplete)
                        planInDraft = checkPlanInDraft ? "Yes" : "No"

                        let checkYetToStart = targetDetailsRes.every((item) => !item.is_accepted_by_employee)
                        yetToStart = checkYetToStart ? "Yes" : "No"

                        let checkPendingReview = targetDetailsRes.every((item) => item.is_accepted_by_employee && item.isMarkAsComplete_AssignTarget && !item.is_approve)
                        pendingReview = checkPendingReview ? "Yes" : "No"

                    } else {
                        planSubmitted = "No"
                        targetApproved = "No"
                    }

                    //PlanInit
                    let isPlanInitiatedForEmployee;
                    if (isPlanInitiated) {
                        // var checkIsPlanInit = await DataStore.query(InitiatePlanning, (c) => c.employee_id.eq(employeeTargetsList[i].id))
                        // var checkIsPlanInit = await graphQLGetAllData("listInitiatePlannings", [{ employee_id: { eq: employeeTargetsList[i].id } }])

                        var checkIsPlanInit = allInitiatePlannings.filter(c => c.employee_id === employeeTargetsList[i].id)

                        isPlanInitiatedForEmployee = checkIsPlanInit.length ? 1 : 0
                    } else {
                        isPlanInitiatedForEmployee = 0;
                    }

                    newResponse.push({
                        "id": employeeTargetsList[i].id, "role_id": employeeTargetsList[i].role_id, "firstName": employeeTargetsList[i].firstName, "lastName": employeeTargetsList[i].lastName,
                        "email": employeeTargetsList[i].email, "parentEmployeeId": employeeTargetsList[i].parentEmployeeId, "address1": employeeTargetsList[i].address1, "address2": employeeTargetsList[i].address2,
                        "phoneNo": employeeTargetsList[i].phoneNo, "city": employeeTargetsList[i].city, "zip": employeeTargetsList[i].zip, "state": employeeTargetsList[i].state, "country": employeeTargetsList[i].country,
                        "employeeId": employeeTargetsList[i].employeeId, "profilePic": employeeTargetsList[i].profilePic, "isAssignDirectTarget": employeeTargetsList[i].isAssignDirectTarget, "param_sbu_id": employeeTargetsList[i].param_sbu_id,
                        "IsHRAccess": employeeTargetsList[i].IsHRAccess, "IsFinanceAccess": employeeTargetsList[i].IsFinanceAccess, "index": index, "name": name, "role": role, "roleTeamType": roleTeamType, "reportingManager": reportingManager,
                        "sbu": sbu, "vertical": vertical, "targetAssigned": targetAssigned, "targetDetailsRes": targetDetailsRes, "planSubmitted": planSubmitted, "targetApproved": targetApproved, "planInDraft": planInDraft, "yetToStart": yetToStart,
                        "pendingReview": pendingReview, "isPlanInitiatedForEmployee": isPlanInitiatedForEmployee
                    });

                    if (i === employeeTargetsList.length - 1) {
                        let sortArray = newResponse.sort((a, b) => (a.index > b.index ? 1 : -1))

                        SetEmployeeTargetList(sortArray);

                        //*Eligible Employee
                        SetEmployeeEligibleList(sortArray);
                        let planningEmployeeEligibleSales = sortArray.filter((e) => e.roleTeamType === TeamType.S)
                        let planningEmployeeEligibleBusiness = sortArray.filter((e) => e.roleTeamType === TeamType.B)
                        SetCountEligibleBusiness(planningEmployeeEligibleBusiness.length)
                        SetCountEligibleSale(planningEmployeeEligibleSales.length)

                        //*Planning Initiated
                        var countPlanningInitiated = newResponse.filter((e) => e.isPlanInitiatedForEmployee == 1)
                        let planningInitiatedBusiness = countPlanningInitiated.filter((e) => e.roleTeamType === TeamType.B)
                        let planningInitiatedSales = countPlanningInitiated.filter((e) => e.roleTeamType === TeamType.S)
                        SetCount_PlanningInitiated(countPlanningInitiated)
                        setPlanInitiatedBusinessCount(planningInitiatedBusiness.length)
                        setPlanInitiatedSaleCount(planningInitiatedSales.length)

                        //*Target Assigned
                        var countTargetAssigned = newResponse.filter((e) => e.targetAssigned === "Yes")
                        SetCount_TargetAssigned(countTargetAssigned)
                        let targetAssignBusiness = countTargetAssigned.filter((e) => e.roleTeamType === TeamType.B)
                        let targetAssignSales = countTargetAssigned.filter((e) => e.roleTeamType === TeamType.S)
                        setCountTargetAssignedSale(targetAssignSales.length)
                        setCountTargetAssignedBusiness(targetAssignBusiness.length)

                        //*Yet to Start
                        var countYetToStart = newResponse.filter((e) => e.yetToStart === "Yes")
                        SetCount_YetToStart(countYetToStart)
                        let yetToStartBusiness = countYetToStart.filter((e) => e.roleTeamType === TeamType.B)
                        let yetToStartSales = countYetToStart.filter((e) => e.roleTeamType === TeamType.S)
                        setCountYetToStartSale(yetToStartSales.length)
                        setCountYetToStartBusiness(yetToStartBusiness.length)

                        //*Plan - In Draft
                        var countPlanInDraft = newResponse.filter((e) => e.planInDraft === "Yes")
                        SetCount_PlanningInDraft(countPlanInDraft)
                        let planInDraftBusiness = countPlanInDraft.filter((e) => e.roleTeamType === TeamType.B)
                        let planInDraftSales = countPlanInDraft.filter((e) => e.roleTeamType === TeamType.S)
                        setCountPlanInDraftSale(planInDraftSales.length)
                        setCountPlanInDraftBusiness(planInDraftBusiness.length)

                        //*Plan Submitted
                        var countPlanSubmitted = newResponse.filter((e) => e.planSubmitted === "Yes")
                        SetCount_PlanSubmitted(countPlanSubmitted)
                        let planSubmittedBusiness = countPlanSubmitted.filter((e) => e.roleTeamType === TeamType.B)
                        let planSubmittedSales = countPlanSubmitted.filter((e) => e.roleTeamType === TeamType.S)
                        setCountPlanSubmittedSale(planSubmittedSales.length)
                        setCountPlanSubmittedBusiness(planSubmittedBusiness.length)

                        //*Pending Review
                        var countPendingReview = newResponse.filter((e) => e.pendingReview === "Yes")
                        SetCount_PendingReview(countPendingReview)
                        let pendingReviewBusiness = countPendingReview.filter((e) => e.roleTeamType === TeamType.B)
                        let pendingReviewSales = countPendingReview.filter((e) => e.roleTeamType === TeamType.S)
                        setCountPendingReviewSale(pendingReviewSales.length)
                        setCountPendingReviewBusiness(pendingReviewBusiness.length)

                        //*Plan Approved
                        var countPlanApproved = newResponse.filter((e) => e.targetApproved === "Yes")
                        SetCount_Approved(countPlanApproved)
                        let planApprovedBusiness = countPlanApproved.filter((e) => e.roleTeamType === TeamType.B)
                        let planApprovedSales = countPlanApproved.filter((e) => e.roleTeamType === TeamType.S)
                        setCountApprovedSale(planApprovedSales.length)
                        setCountApprovedBusiness(planApprovedBusiness.length)
                    }
                }
            } else {
                SetEmployeeTargetList([]); SetEmployeeEligibleList([]); SetCountEligibleBusiness(0); SetCountEligibleSale(0);
                SetCount_PlanningInitiated([]); setPlanInitiatedBusinessCount(0); setPlanInitiatedSaleCount(0); SetCount_TargetAssigned([])
                setCountTargetAssignedSale(0); setCountTargetAssignedBusiness(0); SetCount_YetToStart([]); setCountYetToStartSale(0); setCountYetToStartBusiness(0)
                SetCount_PlanningInDraft([]); setCountPlanInDraftSale(0); setCountPlanInDraftBusiness(0); SetCount_Approved([]); setCountApprovedSale(0); setCountApprovedBusiness(0)
                SetCount_PendingReview([]); setCountPendingReviewSale(0); setCountPendingReviewBusiness(0); SetCount_PlanSubmitted([]); setCountPlanSubmittedSale(0); setCountPlanSubmittedBusiness(0)
            }
        }
    }

    const [consolidatedReport, setConsolidatedReport] = useState([]);

    const bindConsolidatedReport = async (financialYearId) => {

        var newResponse = [];

        // var employeeTargetsList = await DataStore.query(Targets, (c) => c.financial_year_id.eq(financialYearId));
        var employeeTargetsList = await graphQLGetAllData("listTargets", [{ financial_year_id: { eq: financialYearId } }])

        // Object.keys(employeeTargetsList).map(async (currentItem, i) => {
        //     let loopItem = employeeTargetsList[currentItem];
        //     let data = ObjectToArray(loopItem);
        //     data["index"] = parseInt(i) + 1;

        //     if (data["report_manager_employee_id"] !== null && data["report_manager_employee_id"] !== undefined) {

        //         // var employeeResponse = await DataStore.query(Employee, data["report_manager_employee_id"]);
        //         var employeeResponse = allEmployeesArray.find(o => o.id === data["report_manager_employee_id"])

        //         data["name"] = employeeResponse ? employeeResponse.firstName + " " + employeeResponse.lastName : '';

        //         // var totalReportee = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(data["report_manager_employee_id"]));
        //         var totalReportee = employeeTargetsList.filter(e => e.parentEmployeeId && e.parentEmployeeId.includes(data["report_manager_employee_id"]))

        //         data["totalReportee"] = totalReportee.length
        //         data['targetAssigned'] = employeeTargetsList.length ? "Yes" : "No"

        //         let targetDetailsRes = []
        //         if (employeeTargetsList.length) {
        //             // targetDetailsRes = await DataStore.query(TargetDetails, (c) => c.target_id.eq(employeeTargetsList[0].id))
        //             targetDetailsRes = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: employeeTargetsList[0].id } }])

        //             // targetDetailsRes = allTargetDetails.filter(c => c.target_id === employeeTargetsList[0].id)

        //             let checkPlanSubmittedStatus = targetDetailsRes.every((item) => item.isMarkAsComplete_AssignTarget)
        //             let checkIsApproveStatus = targetDetailsRes.every((item) => item.isMarkAsComplete_AssignTarget)

        //             data['planSubmitted'] = checkPlanSubmittedStatus ? "Yes" : "No"
        //             data['targetApproved'] = checkIsApproveStatus ? "Yes" : "No"

        //             //
        //             let checkPlanInDraft = targetDetailsRes.every((item) => item.is_accepted_by_employee && item.isMarkAsComplete)
        //             data['planInDraft'] = checkPlanInDraft ? "Yes" : "No"

        //             let checkYetToStart = targetDetailsRes.every((item) => !item.is_accepted_by_employee)
        //             data["yetToStart"] = checkYetToStart ? "Yes" : "No"

        //             let checkPendingReview = targetDetailsRes.every((item) => item.is_accepted_by_employee && item.isMarkAsComplete_AssignTarget && !item.is_approve)
        //             data["pendingView"] = checkPendingReview ? "Yes" : "No"

        //         } else {
        //             data['planSubmitted'] = "No"
        //             data['targetApproved'] = "No"
        //         }

        //         newResponse.push(data);
        //         console.log('reaching here')
        //         if (i === employeeTargetsList.length - 1) {
        //             let sortArray = newResponse.sort((a, b) => (a.index > b.index ? 1 : -1))
        //             setConsolidatedReport(sortArray);
        //             setTab(2)
        //             console.log('setting tab 2 active')
        //         }
        //     }

        // })
            
         for (let i = 0; i < employeeTargetsList.length; i++) {
                const currentItem = employeeTargetsList[i];
                let loopItem = currentItem;
                let data = ObjectToArray(loopItem);
                data["index"] = i + 1;
        
                if (data["report_manager_employee_id"] !== null && data["report_manager_employee_id"] !== undefined) {
                    var employeeResponse = allEmployeesArray.find(o => o.id === data["report_manager_employee_id"]);
                    data["name"] = employeeResponse ? employeeResponse.firstName + " " + employeeResponse.lastName : '';
        
                    var totalReportee = employeeTargetsList.filter(e => e.parentEmployeeId && e.parentEmployeeId.includes(data["report_manager_employee_id"]));
                    data["totalReportee"] = totalReportee.length;
                    data['targetAssigned'] = employeeTargetsList.length ? "Yes" : "No";
        
                    let targetDetailsRes = [];
                    if (employeeTargetsList.length) {
                        targetDetailsRes = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: employeeTargetsList[0].id } }]);
        
                        let checkPlanSubmittedStatus = targetDetailsRes.every((item) => item.isMarkAsComplete_AssignTarget);
                        let checkIsApproveStatus = targetDetailsRes.every((item) => item.isMarkAsComplete_AssignTarget);
        
                        data['planSubmitted'] = checkPlanSubmittedStatus ? "Yes" : "No";
                        data['targetApproved'] = checkIsApproveStatus ? "Yes" : "No";
        
                        let checkPlanInDraft = targetDetailsRes.every((item) => item.is_accepted_by_employee && item.isMarkAsComplete);
                        data['planInDraft'] = checkPlanInDraft ? "Yes" : "No";
        
                        let checkYetToStart = targetDetailsRes.every((item) => !item.is_accepted_by_employee);
                        data["yetToStart"] = checkYetToStart ? "Yes" : "No";
        
                        let checkPendingReview = targetDetailsRes.every((item) => item.is_accepted_by_employee && item.isMarkAsComplete_AssignTarget && !item.is_approve);
                        data["pendingView"] = checkPendingReview ? "Yes" : "No";
                    } else {
                        data['planSubmitted'] = "No";
                        data['targetApproved'] = "No";
                    }
        
                    newResponse.push(data);
        
                    if (i === employeeTargetsList.length - 1) {
                        let sortArray = newResponse.sort((a, b) => (a.index > b.index ? 1 : -1));
                        // setConsolidatedReport(sortArray);
                         for (let i = 0; i < employeeTargetsList.length; i++) {
                const currentItem = employeeTargetsList[i];
                let loopItem = currentItem;
                let data = ObjectToArray(loopItem);
                data["index"] = i + 1;
        
                if (data["report_manager_employee_id"] !== null && data["report_manager_employee_id"] !== undefined) {
                    var employeeResponse = allEmployeesArray.find(o => o.id === data["report_manager_employee_id"]);
                    data["name"] = employeeResponse ? employeeResponse.firstName + " " + employeeResponse.lastName : '';
        
                    var totalReportee = employeeTargetsList.filter(e => e.parentEmployeeId && e.parentEmployeeId.includes(data["report_manager_employee_id"]));
                    data["totalReportee"] = totalReportee.length;
                    data['targetAssigned'] = employeeTargetsList.length ? "Yes" : "No";
        
                    let targetDetailsRes = [];
                    if (employeeTargetsList.length) {
                        targetDetailsRes = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: employeeTargetsList[0].id } }]);
        
                        let checkPlanSubmittedStatus = targetDetailsRes.every((item) => item.isMarkAsComplete_AssignTarget);
                        let checkIsApproveStatus = targetDetailsRes.every((item) => item.isMarkAsComplete_AssignTarget);
        
                        data['planSubmitted'] = checkPlanSubmittedStatus ? "Yes" : "No";
                        data['targetApproved'] = checkIsApproveStatus ? "Yes" : "No";
        
                        let checkPlanInDraft = targetDetailsRes.every((item) => item.is_accepted_by_employee && item.isMarkAsComplete);
                        data['planInDraft'] = checkPlanInDraft ? "Yes" : "No";
        
                        let checkYetToStart = targetDetailsRes.every((item) => !item.is_accepted_by_employee);
                        data["yetToStart"] = checkYetToStart ? "Yes" : "No";
        
                        let checkPendingReview = targetDetailsRes.every((item) => item.is_accepted_by_employee && item.isMarkAsComplete_AssignTarget && !item.is_approve);
                        data["pendingView"] = checkPendingReview ? "Yes" : "No";
                    } else {
                        data['planSubmitted'] = "No";
                        data['targetApproved'] = "No";
                    }
        
                    newResponse.push(data);
        
                    if (i === employeeTargetsList.length - 1) {
                        let sortArray = newResponse.sort((a, b) => (a.index > b.index ? 1 : -1));
                        // setConsolidatedReport(sortArray);
                        setConsolidatedReport([]);
                        setTab(2);
                        console.log('setting tab 2 active');
                    }
                }
            }
                        setTab(2);
                        console.log('setting tab 2 active');
                    }
                }
            }
    }


    return isAuthenticated ?
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            <div className="dashboard-main-wrapper dashboard-new pl-16" x-data="{tab: 1}">
                <div className="p-5 pb-5">
                    <div className="sm:flex items-center space-x-8">
                        <div className="text-2xl font-semibold dark:text-white">Dashboard</div>
                    </div>
                    <div className="lg:flex justify-between my-6 items-center align-center">
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link className="active">Planning</Link></li>
                                <li><Link to="/hr/dashboard/evaluation">Evaluation</Link></li>
                            </ul>
                        </div>
                        <div className="mt-5 lg:mt-0 flex justify-end gap-2 items-center">
                            <div className="">
                                <Select
                                    options={employeeList}
                                    value={employee}
                                    onChange={e => {
                                        setFiltered(true);
                                        setEmployee(e);
                                        bindDetailedReport(financialYear)
                                    }}
                                    maxMenuHeight={200}
                                    placeholder="Employee Name"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>

                            {/* <div className="">
                                <Select
                                    options={regionList}
                                    value={region}
                                    onChange={e => { setRegion(e) }}
                                    maxMenuHeight={200} placeholder="Region"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div> */}

                            <div className="">
                                <Select
                                    options={sBUList}
                                    value={sBU}
                                    onChange={e => {
                                        setFiltered(true)
                                        setSBU(e)
                                    }}
                                    maxMenuHeight={200} placeholder="SBU"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                            {/* <div className="">
                                <Select
                                    value={quarter}
                                    options={quarterList}
                                    onChange={e => { setQuarter(e) }}
                                    maxMenuHeight={200} placeholder="Quater"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div> */}
                            <div className="">
                                <Select
                                    options={financialYearList}
                                    value={financialYear}
                                    onChange={e => {
                                        setFinancialYear(e);
                                        setFiltered(true);
                                        reactLocalStorage.setObject('selectedYear', e);
                                        bindDetailedReport(e.value)
                                    }}
                                    maxMenuHeight={200} placeholder="Select Year"
                                    className="my-react-select-container"
                                    classNamePrefix="my-react-select"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='mt-10 flex-row grid 2xl:grid-cols-8 xl:grid-cols-8 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1  gap-3'>
                        <div className={isEligibleEmployeeForActive ? 'bg-[#d5e1ea] border purple border-[#2D638C] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(0, 1, 0, 0, 0, 0, 0, 0)
                        }}>

                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white '>Eligible Employee</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countEligibleBusiness === 0 && countEligibleSale === 0 ? 50 : Math.round((countEligibleBusiness / employeeEligibleList.length) * 100)} text={employeeEligibleList.length} pathColor="##2D638C" trailColor="#9FBED6" />
                            </div>

                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5  border-b-4  border-[#2D638C] rounded-b-md mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countEligibleBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countEligibleBusiness ? Math.round((countEligibleBusiness / employeeEligibleList.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4  border-[#9FBED6] rounded-b-md mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countEligibleSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countEligibleSale ? Math.round((countEligibleSale / employeeEligibleList.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={planFilterInitiatedForActive ? 'bg-[#fceadf] border purple border-[#F76208] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(1, 0, 0, 0, 0, 0, 0, 0)
                        }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white' >Planning Initiated</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countPlanInitiatedBusiness === 0 && countPlanInitiatedSale === 0 ? 50 : Math.round((countPlanInitiatedBusiness / count_PlanningInitiated.length) * 100)} text={count_PlanningInitiated.length ? count_PlanningInitiated.length : 0} pathColor="#F76208" trailColor="#F5DCCC" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#F76208] mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff] '>{countPlanInitiatedBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPlanInitiatedBusiness ? Math.round((countPlanInitiatedBusiness / count_PlanningInitiated.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#F5DCCC] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPlanInitiatedSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPlanInitiatedSale ? Math.round((countPlanInitiatedSale / count_PlanningInitiated.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={targetAssignedForActive ? 'bg-[#FCE8FF] border purple border-[#EAADF3] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(0, 0, 1, 0, 0, 0, 0, 0)
                        }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white'>Target Assigned</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countTargetAssignedBusiness === 0 && countTargetAssignedSale === 0 ? 50 : Math.round((countTargetAssignedBusiness / count_TargetAssigned.length) * 100)} text={count_TargetAssigned.length ? count_TargetAssigned.length : 0} pathColor="#AB4ABA" trailColor="#EAADF3" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5  border-b-4 rounded-b-md border-[#AB4ABA] mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countTargetAssignedBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countTargetAssignedBusiness ? Math.round((countTargetAssignedBusiness / count_TargetAssigned.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#EAADF3] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countTargetAssignedSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countTargetAssignedSale ? Math.round((countTargetAssignedSale / count_TargetAssigned.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={isYetToStartForActive ? 'bg-[#ffe9ca] border purple border-[#FF9500] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(0, 0, 0, 1, 0, 0, 0, 0)
                        }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white'>Yet to Start</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countYetToStartBusiness === 0 && countYetToStartSale === 0 ? 50 : Math.round((countYetToStartBusiness / count_YetToStart.length) * 100)} text={count_YetToStart.length ? count_YetToStart.length : 0} pathColor="#FF9500" trailColor="#F3D8B2" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#FF9500] mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countYetToStartBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countYetToStartBusiness ? Math.round((countYetToStartBusiness / count_YetToStart.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#F3D8B2] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countYetToStartSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countYetToStartSale ? Math.round((countYetToStartSale / count_YetToStart.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={planInDraftForActive ? 'bg-[#f3fffd] border purple border-[#146A60] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'}
                            onClick={e => {
                                getDetailedReport(0, 0, 0, 0, 1, 0, 0, 0)
                            }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white'>Plan - In Draft</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countPlanInDraftBusiness === 0 && countPlanInDraftSale === 0 ? 50 : Math.round((countPlanInDraftBusiness / count_PlanningInDraft.length) * 100)} text={count_PlanningInDraft.length ? count_PlanningInDraft.length : 0} pathColor="#146A60" trailColor="#CCEBE7" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md  border-[#146A60] mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPlanInDraftBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>
                                        {countPlanInDraftBusiness ? Math.round((countPlanInDraftBusiness / count_PlanningInDraft.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#CCEBE7] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPlanInDraftSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPlanInDraftSale ? Math.round((countPlanInDraftSale / count_PlanningInDraft.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={planSubmittedForActive ? 'bg-[#fff1f1] border purple border-[#FF0000] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(0, 0, 0, 0, 0, 1, 0, 0)
                        }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white '>Plan Submitted</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countPlanSubmittedBusiness === 0 && countPlanSubmittedSale === 0 ? 50 : Math.round((countPlanSubmittedBusiness / count_PlanSubmitted.length) * 100)} text={count_PlanSubmitted.length ? count_PlanSubmitted.length : 0} pathColor="#FF0000" trailColor="#FCDADA" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#FF0000] mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPlanSubmittedBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPlanSubmittedBusiness ? Math.round((countPlanSubmittedBusiness / count_PlanSubmitted.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#FCDADA] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPlanSubmittedSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPlanSubmittedSale ? Math.round((countPlanSubmittedSale / count_PlanSubmitted.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={pendingReviewForActive ? 'bg-[#fffae7] border purple border-[#FFC700] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(0, 0, 0, 0, 0, 0, 1, 0)
                        }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3 dark:text-white '>Pending Review</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countPendingReviewBusiness === 0 && countPendingReviewSale === 0 ? 50 : Math.round((countPendingReviewBusiness / count_PendingReview.length) * 100)} text={count_PendingReview.length ? count_PendingReview.length : 0} pathColor="#FFC700" trailColor="#FFF2C5" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#FFC700] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPendingReviewBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPendingReviewBusiness ? Math.round((countPendingReviewBusiness / count_PendingReview.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#FFF2C5] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countPendingReviewSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countPendingReviewSale ? Math.round((countPendingReviewSale / count_PendingReview.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className={planApprovedForActive ? 'bg-[#eaeaea] border purple border-[#2C2C2C] p-3 dashboard-box-shadow' : 'bg-white p-3 dashboard-box-shadow dark:bg-[#2A2E32]'} onClick={e => {
                            getDetailedReport(0, 0, 0, 0, 0, 0, 0, 1)
                        }}>
                            <h6 className='text-[#101828] text-[13px] xl:text-[14px] lg:text-[14px] sm:text-[13px] font-semibold text-left mb-3  dark:text-white'>Approved</h6>
                            <div className='min-h-[80px] mx-auto' style={{ width: 120, height: 120, padding: 8 }}>
                                <CircularProgressBar value={countApprovedBusiness === 0 && countApprovedSale === 0 ? 50 : Math.round((countApprovedBusiness / count_Approved.length) * 100)} text={count_Approved.length ? count_Approved.length : 0} pathColor="#2C2C2C" trailColor="#CCCCCC" />
                            </div>
                            <div className='mt-3'>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#2C2C2C] mb-3 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Business</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countApprovedBusiness}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countApprovedBusiness ? Math.round((countApprovedBusiness / count_Approved.length) * 100) : ''} %</span></h3>
                                </div>
                                <div className='bg-[#F9FAFB] p-1.5 border-b-4 rounded-b-md border-[#CCCCCC] mb-1 dark:bg-[#171717]'>
                                    <p className='text-[#344054] text-[11px] font-normal dark:text-[#f2f2f2]'> Sale</p>
                                    <h3 className='text-[#344054] text-[16px] font-semibold dark:text-[#fff]'>{countApprovedSale}<span className='text-[#344054] text-[12px] ml-0.5 font-normal dark:text-[#fff]'>{countApprovedSale ? Math.round((countApprovedSale / count_Approved.length) * 100) : ''} %</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white rounded-lg mt-10 p-3 px-3 dark:bg-[#2A2E32] mt-15  border border-[#E4E7EC] dark:border-[#344054]  mb-10 pb-10 ">
                        <div>
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link onClick={() => setTab(1)} className={activeTab == 1 ? "active" : ""} >Detailed</Link></li>
                                    <li><Link onClick={() => bindConsolidatedReport(financialYear.value)} className={activeTab == 2 ? "active" : ""} >Consolidated</Link></li>
                                </ul>
                            </div>
                            {
                                activeTab == 1 &&
                                <div className=" rounded-lg mt-5 ">
                                    <DataTable paginator value={employeeTargetsList} responsiveLayout="scroll"
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={50} rowsPerPageOptions={[50, 100, 150]}
                                    >
                                        <Column field="index" header="Index" sortable></Column>
                                        <Column field="employeeId" header="Employee ID" sortable filter filterPlaceholder="Search by Employee ID"></Column>
                                        <Column field="name" header="Name" sortable filter filterPlaceholder="Search by Name"></Column>
                                        <Column field="role" header="Role" sortable filter filterPlaceholder="Search by Role"></Column>
                                        <Column field="vertical" header="Vertical" sortable filter filterPlaceholder="Search by Vertical"></Column>
                                        <Column field="sbu" header="SBU" sortable filter filterPlaceholder="Search by SBU"></Column>
                                        <Column field="reportingManager" header="Reporting Manager" sortable filter filterPlaceholder="Search by Reporting Manager"></Column>
                                        <Column field='targetAssigned' header="Target Assigned" filter filterPlaceholder="Search by Submitted Date" ></Column>
                                        <Column field='planSubmitted' header="Plan Submitted" filter filterPlaceholder="Search by Approved Date"  ></Column>
                                        <Column field='targetApproved' header="Target Approved" filter filterPlaceholder="Search by Target Approved" ></Column>
                                        {/* <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnRoles} ></Column> */}
                                    </DataTable>
                                </div>
                            }

                            {
                                activeTab == 2 &&
                                <div className=" rounded-lg mt-5 ">
                                    <DataTable paginator value={consolidatedReport} responsiveLayout="scroll"
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={50} rowsPerPageOptions={[50, 100, 150]}
                                    >
                                        <Column field="index" header="Index" sortable></Column>
                                        <Column field="name" header="Name" sortable filter filterPlaceholder="Search by name"></Column>
                                        <Column field="totalReportee" header="Total Reportee" sortable filter filterPlaceholder="Search by total reportee"></Column>
                                        <Column field="totalReportee" header="Eligible" sortable filter filterPlaceholder="Search by total reportee"></Column>
                                        <Column field="totalReportee" header="Initiated" sortable filter filterPlaceholder="Search by Role"></Column>
                                        <Column field="totalReportee" header="Target Assigned" sortable filter filterPlaceholder="Search by Vertical"></Column>
                                        <Column field="yetToStart" header="Yet to start" sortable filter filterPlaceholder="Search by SBU"></Column>
                                        <Column field="planInDraft" header="Plan in draft" sortable filter filterPlaceholder="Search by Reporting Manager"></Column>
                                        <Column field="planSubmitted" header="Plan submitted" sortable></Column>
                                        <Column field='pendingView' header="Pending View" filter filterPlaceholder="Search by Submitted Date" ></Column>
                                        <Column field='targetApproved' header="Approved" filter filterPlaceholder="Search by Target Approved" ></Column>
                                        {/* <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnRoles} ></Column> */}
                                    </DataTable>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div id="selector"></div>


        </div>
        : <HRLogin sessionExpired={1} />
}